export const INITIAL_STATE_CONFIGURATIONS = {
  networks: {
    list: [],
    current: [],
  },
};

export default class ConfigurationsModel {
  color;
  hasIconPNG;
  hasIconSVG;
  id;
  nameLong;
  nameShort;
  networkId;
  routes;
  transportMode;
  transportOperatorRef;
  type;
  versionRef;
  isComposite;
  hasGovernanceScope;
  checked;
  constructor() {
    this.color = null;
    this.hasIconPNG = null;
    this.hasIconSVG = null;
    this.isComposite = null;
    this.hasGovernanceScope = null;
    this.id = null;
    this.nameLong = null;
    this.nameShort = null;
    this.networkId = null;
    this.routes = [];
    this.lines = [];
    this.transportMode = TransportMode;
    this.transportOperatorRef = TransportOperatorRefModel;
    this.type = null;
    this.checked = false;
    this.versionRef = VersionRef;
  }
  static fromREST(obj) {
    var conf = new ConfigurationsModel();

    //if (process.env.REACT_APP_STAGE === 'production') {
    conf.hasGovernanceScope = obj.hasGovernanceScope;
    conf.versionRef = obj.versionRef;
    conf.isComposite = obj.isComposite;
    conf.hasIconPNG = obj.hasIconPNG;
    conf.hasIconSVG = obj.hasIconSVG;
    conf.id = obj.id;
    conf.nameLong = obj.nameLong;
    conf.nameShort = obj.nameShort;
    conf.lines = obj.lines;
    conf.routes = RoutesConfModel.fromArray(obj.lines);
    /*  } else {
      conf.color = obj.color;
      conf.hasIconPNG = obj.hasIconPNG;
      conf.hasIconSVG = obj.hasIconSVG;
      conf.id = obj.id;
      conf.checked = obj.checked;
      conf.nameLong = obj.nameLong;
      conf.nameShort = obj.nameShort;
      conf.networkId = obj.networkId;
      conf.routes = RoutesConfModel.fromArray(obj.routes);
      conf.transportMode = TransportMode.from(obj.transportMode);
      conf.transportOperatorRef = TransportOperatorRefModel.from(
        obj.transportOperatorRef
      );
      conf.type = obj.type;
      conf.versionRef = VersionRef.from(obj.versionRef);
    } */

    return conf;
  }

  static fromArrayREST(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(ConfigurationsModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class RoutesConfModel {
  nameShort;
  id;
  constructor() {
    this.id = null;
    this.nameShort = null;
  }
  static from(obj) {
    var route = new RoutesConfModel();
    route.id = obj.id;
    route.nameShort = obj.nameShort;
    return route;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(RoutesConfModel.from).filter((item) => item !== null);
  }
}

export class TransportMode {
  code;
  description;
  isManaged;
  label;
  subMode;
  constructor() {
    this.code = null;
    this.description = null;
    this.isManaged = null;
    this.label = null;
    this.subMode = null;
  }
  static from(obj) {
    var transport = new TransportMode();
    if (obj) {
      if (obj.code) transport.code = obj.code;
      if (obj.description) transport.description = obj.description;
      if (obj.isManaged) transport.isManaged = obj.isManaged;
      if (obj.label) transport.label = obj.label;
      if (obj.subMode) transport.subMode = obj.subMode;
    }
    return transport;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(TransportMode.from).filter((item) => item !== null);
  }
}

export class VersionRef {
  fromDate;
  toDate;
  versionId;
  constructor() {
    this.fromDate = null;
    this.toDate = null;
    this.versionId = null;
  }
  static from(obj) {
    var transport = new VersionRef();
    if (obj) {
      transport.fromDate = obj.fromDate;
      transport.toDate = obj.toDate;
      transport.versionId = obj.versionId;
    }
    return transport;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(VersionRef.from).filter((item) => item !== null);
  }
}

export class TransportOperatorRefModel {
  nameShort;
  id;
  constructor() {
    this.id = null;
    this.nameShort = null;
  }
  static from(obj) {
    var trans = new TransportOperatorRefModel();
    if (obj) {
      trans.id = obj.id;
      trans.nameShort = obj.nameShort;
    }
    return trans;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(TransportOperatorRefModel.from)
      .filter((item) => item !== null);
  }
}
