export const PointsActions = {
  SET_LIST_POINTS: "GRAPH/SET_LIST_POINTS",
  SET_CURRENT_POINT: "GRAPH/SET_CURRENT_POINT",
  SET_CURRENT_POINT_MAP: "GRAPH/SET_CURRENT_POINT_MAP",
  FILTER_POINTS_BY_SEGMENT: "GRAPH/FILTER_POINTS_BY_SEGMENT",
  RESET_CURRENT_POINT_MAP: "GRAPH/RESET_CURRENT_POINT_MAP",
};

export const setPointsRdsTmcList = (list) => (dispatch) => {
  dispatch({
    type: PointsActions.SET_LIST_POINTS,
    payload: { list },
  });
};

export const setCurrentPointRdsTmc = (current) => (dispatch) => {
  dispatch({
    type: PointsActions.SET_CURRENT_POINT,
    payload: current,
  });
};
export const setCurrentPointMap = (currentMap) => (dispatch) => {
  dispatch({
    type: PointsActions.SET_CURRENT_POINT_MAP,
    payload: currentMap,
  });
};
export const filterPointsBySegment = (segment) => (dispatch) => {
  dispatch({
    type: PointsActions.FILTER_POINTS_BY_SEGMENT,
    payload: segment,
  });
};
export const resetCurrentPointMap = () => (dispatch) => {
  dispatch({
    type: PointsActions.RESET_CURRENT_POINT_MAP,
  });
};
