import { filterBounds } from "utils/utils";
import { INITIAL_STATE_STOPS } from "./stops.model";
import { StopsActions } from "./stops.actions";
import { NavTabActions } from "../ui/nav-tab/nav-tab.actions";
import { MapActions } from "reducers/map/map.actions";
import { LinesActions } from "reducers/lines/lines.actions";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";

function searchStops(searchText) {
  return (stop) =>
    stop.id.toLowerCase().includes(searchText) ||
    stop.nameLong.toLowerCase().includes(searchText) ||
    stop.nameShort.toLowerCase().includes(searchText);
}

export default function stopsReducer(state = INITIAL_STATE_STOPS, action) {
  switch (action.type) {
    case StopsActions.GET_STOPS:
      action.payload = action.payload.filter(
        (x) => !state.stops.find((l) => x.id === l.id)
      );
      const cityBus = action.payload.filter(
        (x) =>
          x.transportMode &&
          x.transportMode.includes(TYPE_TRANSPORT_MODE["urb"])
      );
      const regionBus = action.payload.filter(
        (x) =>
          x.transportMode &&
          x.transportMode.includes(TYPE_TRANSPORT_MODE["extraurb"])
      );

      return {
        ...state,
        stops: [...state.stops, ...action.payload],
        cityBus: [...state.cityBus, ...cityBus],
        regionBus: [...state.cityBus, ...regionBus],
        cityBusFiltered: [...state.cityBusFiltered, ...cityBus],
        regionBusFiltered: [...state.regionBusFiltered, ...regionBus],
        cityBusFilteredMap: [...state.cityBusFilteredMap, ...cityBus],
        regionBusFilteredMap: [...state.regionBusFilteredMap, ...regionBus],
        regionBusFilteredSearch: [
          ...state.regionBusFilteredSearch,
          ...regionBus,
        ],
        cityBusFilteredSearch: [...state.cityBusFilteredSearch, ...cityBus],
      };
    case StopsActions.RESET_STOPS:
      return INITIAL_STATE_STOPS;
    case StopsActions.SET_CURRENT_STOP:
      let isAlive =
        state.current &&
        state.current.id === action.payload.current.id &&
        ((action.payload.fromMap && !state.current.fromMap) ||
          (action.payload.fromMap === state.current.fromMap &&
            action.payload.fromMap === true));
      return {
        ...state,
        currentIdTripForStop: isAlive ? [] : state.currentIdTripForStop,
        currentIdRouteForStop: isAlive ? [] : state.currentIdRouteForStop,
        current: isAlive
          ? null
          : { ...action.payload.current, fromMap: action.payload.fromMap },
        cityBusActive:
          state.current &&
          state.current.id === action.payload.current.id &&
          // action.payload.fromMap === state.current.fromMap &&
          action.payload.fromMap === true
            ? []
            : [{ ...action.payload.current, fromMap: action.payload.fromMap }],
        regionBusActive:
          state.current &&
          state.current.id === action.payload.current.id &&
          // action.payload.fromMap === state.current.fromMap &&
          action.payload.fromMap === true
            ? []
            : [{ ...action.payload.current, fromMap: action.payload.fromMap }],
      };
    case StopsActions.RESET_CURRENT_STOP:
      return {
        ...state,
        current: null,
        cityBusActive: [],
        regionBusActive: [],
        currentIdRouteForStop: [],
        currentIdTripForStop: [],
      };
    case NavTabActions.SET_SEARCH: {
      if (action.payload && action.payload.type === "fermate") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        let regionBusFiltered = [...state.regionBus];
        let cityBusFiltered = [...state.cityBus];
        let regionBusFilteredMap = [...regionBusFiltered];
        let cityBusFilteredMap = [...cityBusFiltered];
        if (searchText !== "") {
          const searchFn = searchStops(searchText);
          regionBusFiltered = state.regionBus.filter(searchFn);
          cityBusFiltered = state.cityBus.filter(searchFn);
          regionBusFilteredMap = [...regionBusFiltered];
          cityBusFilteredMap = [...cityBusFiltered];
        }
        return {
          ...state,
          current: null,
          cityBusActive: [],
          regionBusActive: [],
          regionBusFiltered,
          cityBusFiltered,
          regionBusFilteredMap,
          cityBusFilteredMap,
          searchText,
          searchType: action.payload.type,
        };
      } else {
        return { ...state };
      }
    }
    case MapActions.FILTER_BY_BOUNDS: {
      const { searchText } = state;
      //const searchFn = searchStops(searchText);
      const { bounds } = action.payload;
      const filterFn = filterBounds(bounds);
      if (searchText === "") {
        let rb = state.regionBus.filter(filterFn);
        let cb = state.cityBus.filter(filterFn);
        return {
          ...state,
          bounds: [...bounds],
          regionBusFiltered: rb,
          cityBusFiltered: cb,
          regionBusFilteredMap: rb,
          cityBusFilteredMap: cb,
        };
      } else {
        return { ...state };
      } /* else {
        let rb = state.regionBus.filter(searchFn);
        let cb = state.cityBus.filter(searchFn);
        return {
          ...state,
          bounds: [...bounds],
          regionBusFiltered: rb,
          cityBusFiltered: cb,
          regionBusFilteredMap: rb,
          cityBusFilteredMap: cb,
        };
      } */
    }
    case StopsActions.FILTER_STOP_MAP:
      if (action.payload && action.payload.length > 0) {
        let region = [];
        let city = [];
        action.payload.forEach((stop) => {
          if (
            stop.transportMode &&
            stop.transportMode.includes(TYPE_TRANSPORT_MODE["urb"])
          ) {
            city.push(stop);
          }
          if (
            stop.transportMode &&
            stop.transportMode.includes(TYPE_TRANSPORT_MODE["extraurb"])
          ) {
            region.push(stop);
          }
        });
        return {
          ...state,
          regionBusFilteredMap: region,
          cityBusFilteredMap: city,
        };
      } else {
        const { searchText } = state;
        const searchFnt = searchStops(searchText);
        return {
          ...state,
          regionBusFilteredMap: state.regionBus.filter(searchFnt),
          cityBusFilteredMap: state.cityBus.filter(searchFnt),
        };
      }
    case LinesActions.RESET_ACTIVE_LINES:
      const { searchText } = state;
      const searchFn = searchStops(searchText);
      return {
        ...state,
        regionBusFilteredMap: state.regionBus.filter(searchFn),
        cityBusFilteredMap: state.cityBus.filter(searchFn),
      };
    case MapActions.SET_LAYOUT:
      return {
        ...state,
        stops: state.stops.map((stop) => {
          stop.layoutMap = action.payload;
          return stop;
        }),
        cityBus: state.cityBus.map((stop) => {
          stop.layoutMap = action.payload;
          return stop;
        }),
        regionBus: state.regionBus.map((stop) => {
          stop.layoutMap = action.payload;
          return stop;
        }),
        cityBusFiltered: state.cityBusFiltered.map((stop) => {
          stop.layoutMap = action.payload;
          return stop;
        }),
        regionBusFiltered: state.regionBusFiltered.map((stop) => {
          stop.layoutMap = action.payload;
          return stop;
        }),
        cityBusFilteredMap: state.cityBusFilteredMap.map((stop) => {
          stop.layoutMap = action.payload;
          return stop;
        }),
        regionBusFilteredMap: state.regionBusFilteredMap.map((stop) => {
          stop.layoutMap = action.payload;
          return stop;
        }),
        regionBusFilteredSearch: state.regionBusFilteredSearch.map((stop) => {
          stop.layoutMap = action.payload;
          return stop;
        }),
        cityBusFilteredSearch: state.cityBusFilteredSearch.map((stop) => {
          stop.layoutMap = action.payload;
          return stop;
        }),
      };

    case StopsActions.SET_CURRENT_ID_ROUTE_STOP:
      return {
        ...state,
        currentIdRouteForStop: action.payload,
      };
    case StopsActions.SET_CURRENT_ID_TRIP_STOP:
      return {
        ...state,
        currentIdTripForStop: action.payload,
      };

    default:
      return state;
  }
}

export const getStops = (state) => state.stops;
export const getCurrentStop = (state) => state.current;
export const setCurrentStop = (state) => state.current;
export const resetCurrentStop = (state) => state.current;
export const getCityBusStops = (state) => state.cityBus;
export const getRegionBusStops = (state) => state.regionBus;
export const getCityBusStopsFiltered = (state) => state.cityBusFiltered;
export const getRegionBusStopsFiltered = (state) => state.regionBusFiltered;
export const getCityBusStopsFilteredMap = (state) => state.cityBusFilteredMap;
export const getRegionBusStopsFilteredMap = (state) =>
  state.regionBusFilteredMap;
export const getCityBusStopsActive = (state) => state.cityBusActive;
export const getRegionBusStopsActive = (state) => state.regionBusActive;
export const getCurrentIdRouteForStop = (state) => state.currentIdRouteForStop;
export const getCurrentIdTripForStop = (state) => state.currentIdTripForStop;
