import * as moment from "moment";
import { tap, take, catchError, mergeMap } from "rxjs/operators";
import { of, merge, combineLatest } from "rxjs";
import { reduceDuplicatesInArrayForId } from "utils/utils";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";

//MODEL
import VehicleGeneralModel from "reducers/vehicles/vehicles.model";
import { VehicleRecords } from "reducers/trips/trips.model";

const notifDetailLoading = () => {
  return UIkit.notification({
    message:
      "<span uk-spinner='ratio: 0.5'></span>&nbsp;Caricamento Dettaglio...",
    status: "primary",
    timeout: 60000,
    group: "detail-group",
    pos: "top-right",
  });
};

export const notifDetailError = (message) => {
  return UIkit.notification({
    message: "<span class='uk-alert-danger'></span>&nbsp; " + message,
    status: "danger",
    pos: "top-right",
    group: "error-detail-group",
    timeout: 5000,
  });
};

export const loadFilterStop = (component, stop, fromMap) => {
  const {
    setCurrentIdRouteForStop,
    setCurrentStop,
    setCurrentIdTripForStop,
    setCurrentRoutesMapForStopSelected,
  } = component.props;
  if (!fromMap) component.setState({ isStopsDetailLoading: true });
  notifDetailLoading();
  component.subscriptions.push(
    component.apiService
      .getFilterPoint(stop.id)
      .pipe(
        take(1),
        tap((details) => {
          if (details && !details.error) {
            setCurrentIdTripForStop(details.mvjs ? details.mvjs : []);
            setCurrentIdRouteForStop(details.routes ? details.routes : []);
            setCurrentRoutesMapForStopSelected(
              details.routes ? details.routes : []
            );
          } else {
            console.log(details);
          }
        }),
        mergeMap((x) => merge(getDetailStop(component, stop, fromMap))),
        catchError((error) => {
          console.log(error);
          setCurrentStop(stop, fromMap);
          UIkit.notification.closeAll("detail-group");
          notifDetailError("Errore Server");
          if (!fromMap)
            component.setState({
              ...component.state,
              viewStopDetail: true,
              viewStopTimetable: false,
              stop: stop,
              isStopsDetailLoading: false,
            });
          return of(error);
        })
      )
      .subscribe()
  );
};

const getDetailStop = (component, stop, fromMap) => {
  const { setCurrentStop } = component.props;
  var timeEnd = moment().set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 59,
  });
  var before = moment().subtract(30, "minutes");
  var minutesForCall = timeEnd.diff(before, "minutes");
  if (stop && stop.id) {
    return component.apiService
      .getListaCorseFermate(stop.id, before, minutesForCall)
      .pipe(
        take(1),
        tap((x) => {
          if (x && !x.error) {
            stop["monitoredStopVisits"] = x.monitoredStopVisits;
            setCurrentStop(stop, fromMap);
            UIkit.notification.closeAll("detail-group");
            if (!fromMap)
              component.setState({
                ...component.state,
                viewStopDetail: true,
                viewStopTimetable: false,
                stop: stop,
                isStopsDetailLoading: false,
              });
          } else {
            console.log(x);
          }
        }),
        catchError((error) => {
          console.error(error);
          setCurrentStop(stop, false);
          UIkit.notification.closeAll("detail-group");
          notifDetailError("Errore Server");
          if (!fromMap)
            component.setState({
              ...component.state,
              viewStopDetail: true,
              viewStopTimetable: false,
              stop: stop,
              isStopsDetailLoading: false,
            });
          return of(error);
        })
      );
  }
};

export const loadFilterTrip = (component, trip, fromMap) => {
  const {
    setCurrentIdRouteForVehicle,
    setCurrentIdStopForVehicle,
    setCurrentTrip,
    setCurrentVehicle,
  } = component.props;
  notifDetailLoading();
  component.subscriptions.push(
    component.apiService
      .getFilterTrip(
        fromMap
          ? trip &&
              trip.vehicleActivityLocation &&
              trip.vehicleActivityLocation.monitoredVehicleJourney &&
              trip.vehicleActivityLocation.monitoredVehicleJourney
                .framedVehicleJourneyRef &&
              trip.vehicleActivityLocation.monitoredVehicleJourney
                .framedVehicleJourneyRef.datedVehicleJourneyRef
          : trip &&
              trip.monitoredVehicleJourney &&
              trip.monitoredVehicleJourney.framedVehicleJourneyRef &&
              trip.monitoredVehicleJourney.framedVehicleJourneyRef
                .datedVehicleJourneyRef
      )
      .pipe(
        take(1),
        tap((details) => {
          if (details && !details.error) {
            setCurrentIdRouteForVehicle(details.route ? [details.route] : []);
            setCurrentIdStopForVehicle(details.points ? details.points : []);
          } else {
            console.log(details);
          }
        }),
        mergeMap((x) => merge(getDetailTrip(component, trip, fromMap))),
        catchError((error) => {
          console.log(error);
          if (fromMap) {
            setCurrentVehicle(trip);
          } else {
            setCurrentTrip(trip);
          }
          UIkit.notification.closeAll("detail-group");
          notifDetailError("Errore Server");
          if (!fromMap)
            component.setState({
              ...component.state,
              viewDetailVehicle: true,
              viewDetailRoute: false,
            });
          return of(error);
        })
      )
      .subscribe()
  );
};

const getDetailTrip = (component, trip, fromMap) => {
  const { setCurrentTrip, setCurrentVehicle } = component.props;
  return component.apiService
    .getDettaglioVeicoli(
      fromMap
        ? trip &&
            trip.vehicleActivityLocation &&
            trip.vehicleActivityLocation.monitoredVehicleJourney &&
            trip.vehicleActivityLocation.monitoredVehicleJourney
              .framedVehicleJourneyRef &&
            trip.vehicleActivityLocation.monitoredVehicleJourney
              .framedVehicleJourneyRef.dataFrameRef
        : trip &&
            trip.monitoredVehicleJourney &&
            trip.monitoredVehicleJourney.framedVehicleJourneyRef &&
            trip.monitoredVehicleJourney.framedVehicleJourneyRef.dataFrameRef,
      fromMap
        ? trip &&
            trip.vehicleActivityLocation &&
            trip.vehicleActivityLocation.monitoredVehicleJourney &&
            trip.vehicleActivityLocation.monitoredVehicleJourney
              .framedVehicleJourneyRef &&
            trip.vehicleActivityLocation.monitoredVehicleJourney
              .framedVehicleJourneyRef.datedVehicleJourneyRef
        : trip &&
            trip.monitoredVehicleJourney &&
            trip.monitoredVehicleJourney.framedVehicleJourneyRef &&
            trip.monitoredVehicleJourney.framedVehicleJourneyRef
              .datedVehicleJourneyRef
    )
    .pipe(
      take(1),
      tap((x) => {
        if (x && !x.error) {
          let details = VehicleGeneralModel.fromREST(x);
          if (fromMap) {
            setCurrentVehicle(details);
          } else {
            setCurrentTrip(details);
          }
          UIkit.notification.closeAll("detail-group");
          if (!fromMap)
            component.setState({
              ...component.state,
              viewDetailVehicle: true,
              viewDetailRoute: false,
            });
        } else {
          console.log(x);
        }
      }),
      catchError((error) => {
        console.error(error);
        UIkit.notification.closeAll("detail-group");
        UIkit.notification({
          message:
            "<span class='uk-alert-danger'></span>&nbsp;Errore del server",
          status: "danger",
          pos: "top-right",
          timeout: 5000,
        });
        return of(error);
      })
    );
};

export const loadFilterRoute = (component, route, fromMap) => {
  const {
    setCurrentIdTripForRoute,
    setCurrentIdStopForRoute,
  } = component.props;
  notifDetailLoading();

  component.subscriptions.push(
    component.apiService
      .getFilterRoute(route.lineId, fromMap ? route.routeRef : route.id)
      .pipe(
        take(1),
        tap((details) => {
          if (details && !details.error) {
            setCurrentIdTripForRoute(details.mvjs ? details.mvjs : []);
            setCurrentIdStopForRoute(details.points ? details.points : []);
          } else {
            console.log(details);
          }
        }),
        mergeMap((x) =>
          merge(getDetailRoute(component, route, fromMap, x.points))
        ),
        catchError((error) => {
          console.log(error);
          UIkit.notification.closeAll("detail-group");
          notifDetailError("Errore Server");
          return of(error);
        })
      )
      .subscribe()
  );
};

export const getDetailRoute = (component, route, fromMap, points) => {
  const {
    setActiveLines,
    activeLines,
    filterStopMap,
    allStopsReducer,
  } = component.props;
  var timeEnd = moment().set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 59,
  });

  var startTimeBefore = moment().subtract(30, "minutes");
  var minutesForCall = timeEnd.diff(startTimeBefore, "minutes");
  return combineLatest([
    component.apiService.getListaCorse(
      "line",
      route.lineId,
      startTimeBefore,
      minutesForCall
    ),
    component.apiService.getListaRotte([{ id: route.lineId }]),
  ]).pipe(
    take(1),
    tap(([x, routes]) => {
      if (x && !x.error && routes && !routes.error) {
        let vehicles = VehicleRecords.fromArrayREST(x.vehicleRecords);
        let filteredVehicle = vehicles.filter(
          (vehicle) =>
            vehicle &&
            vehicle.monitoredVehicleJourney &&
            vehicle.monitoredVehicleJourney.journeyPatternRef ===
              (fromMap ? route.routeRef : route.id)
        );
        let routeDetails = routes[0].routesDetails.filter(
          (x) =>
            x.route.id === (fromMap ? route.routeRef : route.id) &&
            x.route.line &&
            x.route.line.id === route.lineId
        );
        //Per linearizzata (pin bus e fermate ordinate)
        route["vehicles"] = filteredVehicle;
        route["points"] = routeDetails[0] && routeDetails[0].orderedPoints;

        route["pointsNonOrdered"] =
          routeDetails[0] && routeDetails[0].orderedPoints;

        setActiveLines(route);

        let stopsList = [];
        let found = activeLines.find(
          (a) =>
            a.id === (fromMap ? route.routeRef : route.id) &&
            route.lineId === a.lineId
        );

        if (!found) {
          stopsList = [...route.pointsNonOrdered];
          activeLines.forEach((act) => stopsList.push(...act.pointsNonOrdered));
        } else {
          let arrRoutes = [...activeLines].filter(
            (act) => act.id !== (fromMap ? route.routeRef : route.id)
          );
          arrRoutes.forEach((act) => stopsList.push(...act.pointsNonOrdered));
        }
        stopsList = reduceDuplicatesInArrayForId(stopsList);
        filterStopMap(stopsList);
        UIkit.notification.closeAll("detail-group");
      } else {
        console.log(x);
      }
    }),
    catchError((error) => {
      console.error(error);
      let stopsList = [];
      let found = null;
      found =
        activeLines &&
        activeLines.length > 0 &&
        activeLines.find(
          (a) =>
            a.id === (fromMap ? route.routeRef : route.id) &&
            route.lineId === a.lineId
        );
      if (!found) {
        route["pointsNonOrdered"] = [...allStopsReducer].filter((p) =>
          points.find((point) => point.id === p.id)
        );
        stopsList = [...route.pointsNonOrdered];
        activeLines.forEach((act) => stopsList.push(...act.pointsNonOrdered));
      } else {
        let arrRoutes = [...activeLines].filter(
          (act) => act.id !== (fromMap ? route.routeRef : route.id)
        );
        arrRoutes.forEach((act) => stopsList.push(...act.pointsNonOrdered));
      }
      stopsList = reduceDuplicatesInArrayForId(stopsList);
      filterStopMap(stopsList);
      UIkit.notification.closeAll("detail-group");
      UIkit.notification({
        message: "<span class='uk-alert-danger'></span>&nbsp;Errore del server",
        status: "danger",
        pos: "top-right",
        timeout: 5000,
      });

      setActiveLines(route);
      return of(error);
    })
  );
};
