import React, { Component } from "react";
import { connect } from "react-redux";
//ASSETS
import acr_arc from "assets/icon/graph/acr-arc.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";
//UTILS
import {
  getArcTrafficDescriptionByLos,
  getArcColorByLos,
} from "utils/utils-traffic";
//STORE
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import { isGenericTableToggled } from "store";
class ArcTrafficDetailHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      isOpenNavTab,
      toggleArcDetails,
      trafficMeasure,
      name,
      direction,
      toggleGenericTable,
      isGenericTableToggled,
    } = this.props;

    return (
      <div
        id="floatingMenuEvent"
        className={`uk-flex uk-flex-between uk-flex-middle header inherit-transition ${
          isOpenNavTab ? "openNavtabWidth" : "closedNavtabWidth"
        }`}
      >
        <div className="parent uk-flex uk-flex-middle uk-flex-center">
          <div className="circle2"></div>
          <img
            alt="icn_diamond"
            src={icn_diamond}
            className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
          ></img>
          <img
            alt="acr_arc"
            src={acr_arc}
            className="image2  height-width-48 uk-flex uk-flex-middle uk-flex-center arch-icon"
            style={{
              transform: "rotate(" + (direction - 45) + "deg)",
            }}
          ></img>
        </div>
        <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-emphasis-arc">
          {name ? name : "-"}
          <div
            className={
              getArcColorByLos(trafficMeasure) + "-traffic-text uk-width-1-1"
            }
          >
            {trafficMeasure
              ? getArcTrafficDescriptionByLos(trafficMeasure)
              : getArcTrafficDescriptionByLos(null)}
          </div>
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            if (isGenericTableToggled) {
              toggleGenericTable("trafficTable");
            }
            toggleArcDetails(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isGenericTableToggled: isGenericTableToggled(state),
});

const mapDispatchToProps = { toggleGenericTable };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArcTrafficDetailHeader);
