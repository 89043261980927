import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//COMPONENT
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import WebcamsListComponent from "components/layout/webcams/webcams-list";
import WebcamsDetailComponent from "components/layout/webcams/webcams-detail";
import WebcamsAlarmComponent from "components/layout/webcams/webcams-alarm/webcams-alarm";
import BottomControlsIcons from "components/shared/bottom-controls/bottom-controls-icons";

import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import {
  getCurrentCamera,
  getFilteredCameras,
  getPinnedCameras,
  getBottombarTabs,
} from "store";

const mapStateToProps = (state) => ({
  selectedCamera: getCurrentCamera(state),
  filteredCameras: getFilteredCameras(state),
  pinnedCameras: getPinnedCameras(state),
  bottombarTabs: getBottombarTabs(state),
});

const mapDispatchToProps = {
  toggleGenericTable,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class WebCamsContainer extends Component {
  bwService;

  constructor(props) {
    super(props);
    this.state = {
      onWebcamDetailTab: true,
    };
  }

  handleClickSecondTab = () => {
    this.setState({ onWebcamDetailTab: !this.state.onWebcamDetailTab });
  };
  render() {
    const {
      selectedCamera,
      filteredCameras,
      pinnedCameras,
      pinCamera,
      toggleDetails,
      toggleGenericTable,
    } = this.props;
    const { onWebcamDetailTab } = this.state;

    const controlsList = [
      {
        icon: "acr-interface-list",
        tooltip: "Resoconto CCTV",
        className: "table-icon",
        toggle: (e) => {
          toggleGenericTable("cameraTable");
        },
      },
    ];

    return (
      <TabPanel className="overflow inherit-transition uk-height-1-1">
        {selectedCamera && onWebcamDetailTab ? (
          <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
            <div className="uk-height-1-1 overflow event-list-container">
              <ErrorBoundary>
                <WebcamsDetailComponent
                  handleClickSecondTab={this.handleClickSecondTab}
                  pinnedCameras={pinnedCameras}
                  pinCamera={pinCamera}
                  toggleDetails={toggleDetails}
                ></WebcamsDetailComponent>
              </ErrorBoundary>
            </div>
          </div>
        ) : selectedCamera && !onWebcamDetailTab ? (
          <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
            <div className="uk-height-1-1 overflow event-list-container">
              <ErrorBoundary>
                <WebcamsAlarmComponent
                  handleClickSecondTab={this.handleClickSecondTab}
                  pinCamera={pinCamera}
                  pinnedCameras={pinnedCameras}
                  toggleDetails={toggleDetails}
                ></WebcamsAlarmComponent>
              </ErrorBoundary>
            </div>
            <BottomControlsIcons controls={controlsList} />
          </div>
        ) : (
          <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
            <div className="uk-height-1-1 overflow event-list-container">
              <ErrorBoundary>
                <WebcamsListComponent
                  camerasList={filteredCameras}
                  pinnedCameras={pinnedCameras}
                  pinCamera={pinCamera}
                  toggleDetails={toggleDetails}
                ></WebcamsListComponent>
              </ErrorBoundary>
            </div>
            <ErrorBoundary>
              <BottomControlsIcons controls={controlsList} />
            </ErrorBoundary>
          </div>
        )}
      </TabPanel>
    );
  }
}

export default WebCamsContainer;
