import React, { Component } from "react";
import "./style.less";

class MultipleDistanceTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { object, x, y } = this.props;

    return (
      <div
        className="show-tooltip"
        style={{ left: x - 155, top: y - (220 + 20) }}
      >
        <div className="uk-flex uk-flex-column">
          <div className="tooltip-text-title font-big">
            Misurazione Distanza
          </div>
          <div className="uk-flex uk-flex-row  uk-flex-middle uk-margin-small-bottom">
            <div className="tooltip-text-large text-xxlarge">Latitudine: </div>
            <div className="tooltip-text-distance tooltip-text-important">
              {Math.round(object.coordsEnd[1] * 1000000) / 1000000}
            </div>
          </div>
          <div className="uk-flex uk-flex-row  uk-flex-middle uk-margin-small-bottom">
            <div className="tooltip-text-large text-xxlarge">Longitudine: </div>
            <div className="tooltip-text-distance tooltip-text-important">
              {Math.round(object.coordsEnd[0] * 1000000) / 1000000}
            </div>
          </div>
          <div className="uk-flex uk-flex-row  uk-flex-middle uk-margin-small-bottom multiline-distance">
            <div className="tooltip-text-large text-xxlarge">
              Distanza dal precedente:{" "}
            </div>
            <div className="tooltip-text-distance tooltip-text-important">
              {object.distanceFromPrevious ? object.distanceFromPrevious : 0} Km
            </div>
          </div>
          <div className="uk-flex uk-flex-row   uk-flex-middle uk-margin-small-bottom multiline-distance">
            <div className="tooltip-text-large text-xxlarge">
              Distanza totale:{" "}
            </div>
            <div className="tooltip-text-distance tooltip-text-important">
              {object.distanceTot ? object.distanceTot : 0} Km
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MultipleDistanceTooltip;
