import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { tap, catchError, take } from "rxjs/operators";
import { of } from "rxjs";

//STORE AND ACTIONS
import {
  getBoundingBox,
  isOpenNavTab,
  getNavTabSearch,
  getNavTabSelectedTab,
  isAllCamerasVisible,
  isWSCamerasStateOpen,
  isWSCamerasAlarmsOpen,
  getWsLastCameraAlarm,
  getWsLastCameraStatus,
  getCurrentWorkspace,
} from "store";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import {
  CameraFeatureCollectionModel,
  AlarmModel,
  CameraLastStateModel,
} from "reducers/installations/cameras/cameras.model";
import {
  setCamerasList,
  updateCameraStatesWS,
  updateCameraAlarmsWS,
  addNewPinnedCamera,
  resetPinnedCameras,
} from "reducers/installations/cameras/cameras.actions";
import { wsOpen } from "reducers/ui/ws/ws.actions";
import {
  setAllCamerasVisibility,
  setIsCctvLoading,
} from "reducers/ui/installations-menu/installations-menu.actions";
//UTILS
import { getBoundingBoxStringForBboxParamNoEpsg } from "components/pages/load.data/map.utils";
class LoadCameraData extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      boundingBox,
      setCamerasList,
      isWSCamerasStateOpen,
      isWSCamerasAlarmsOpen,
      isAllCamerasVisible,
      setIsCctvLoading,
    } = this.props;

    this.apiService = createApiService(this.context);
    if (isAllCamerasVisible) {
      setIsCctvLoading(true);
      const bbox = getBoundingBoxStringForBboxParamNoEpsg(boundingBox);

      this.subscriptions$.push(
        this.apiService
          .getCamerasListByBbox(bbox)
          .pipe(
            take(1),
            tap((data) => {
              setIsCctvLoading(false);
              let featureCollection = CameraFeatureCollectionModel.fromREST(
                data
              );
              let cameras = [];
              if (featureCollection.features) {
                cameras = [...featureCollection.features];
                setCamerasList(cameras);
              }
            }),
            catchError((error) => {
              setIsCctvLoading(false);
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }

    if (!isWSCamerasStateOpen) {
      this.wsCamerasState();
    }

    if (!isWSCamerasAlarmsOpen) {
      this.wsCamerasAlarm();
    }
  };

  wsCamerasState = () => {
    this.apiService.getCameraStatusRuntime(
      this.eventSourceMessageState,
      this.eventSourceErr,
      this.eventSourceOpen
    );
  };

  eventSourceOpen = (e) => {
    const { wsOpen, isWSCamerasStateOpen } = this.props;
    if (!isWSCamerasStateOpen) wsOpen("camerasState", true);
  };

  eventSourceErr = (e) => {
    const { wsOpen, isWSCamerasStateOpen } = this.props;
    this.apiService.closeCameraRuntime();
    if (isWSCamerasStateOpen) wsOpen("camerasState", false);
  };

  eventSourceMessageState = (e) => {
    const { updateCameraStatesWS } = this.props;
    if (e && e.data) {
      let cameraNotification = JSON.parse(e.data);
      if (
        cameraNotification &&
        (!cameraNotification.status || cameraNotification.status !== "alive")
      ) {
        let arrCameras = [];
        let cameraWs = [];
        !(cameraNotification instanceof Array)
          ? cameraWs.push(cameraNotification)
          : (cameraWs = cameraNotification);
        cameraWs.forEach((item) => {
          if (item.header && item.payload) {
            arrCameras.push(CameraLastStateModel.from(item.payload));
          }
        });
        if (arrCameras && arrCameras.length > 0) {
          updateCameraStatesWS(arrCameras);
        }
      }
    }
  };

  wsCamerasAlarm = () => {
    this.apiService.getCameraAlarmRuntime(
      this.eventSourceMessageAlarm,
      this.eventSourceErrAlarm,
      this.eventSourceOpenAlarm
    );
  };

  eventSourceOpenAlarm = (e) => {
    const { wsOpen, isWSCamerasAlarmsOpen } = this.props;
    if (!isWSCamerasAlarmsOpen) wsOpen("camerasAlarms", true);
  };

  eventSourceErrAlarm = (e) => {
    const { wsOpen, isWSCamerasAlarmsOpen } = this.props;
    if (isWSCamerasAlarmsOpen) wsOpen("camerasAlarms", false);
  };

  eventSourceMessageAlarm = (e) => {
    const { updateCameraAlarmsWS } = this.props;
    if (e && e.data) {
      let cameraNotification = JSON.parse(e.data);
      if (
        cameraNotification &&
        (!cameraNotification.status || cameraNotification.status !== "alive")
      ) {
        let arrCameras = [];
        let cameraWs = [];
        !(cameraNotification instanceof Array)
          ? cameraWs.push(cameraNotification)
          : (cameraWs = cameraNotification);
        cameraWs.forEach((item) => {
          if (item.header && item.payload) {
            arrCameras.push(AlarmModel.from(item.payload));
          }
        });
        if (arrCameras && arrCameras.length > 0) {
          updateCameraAlarmsWS(arrCameras);
        }
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      boundingBox,
      setCamerasList,
      isAllCamerasVisible,
      setIsCctvLoading,
    } = this.props;
    if (
      (prevProps.isAllCamerasVisible !== isAllCamerasVisible ||
        prevProps.boundingBox !== boundingBox) &&
      isAllCamerasVisible &&
      boundingBox
    ) {
      if (prevProps.boundingBox === boundingBox) {
        setIsCctvLoading(true);
      }
      const bbox = getBoundingBoxStringForBboxParamNoEpsg(boundingBox);

      this.subscriptions$.push(
        this.apiService
          .getCamerasListByBbox(bbox)
          .pipe(
            take(1),
            tap((data) => {
              setIsCctvLoading(false);
              if (data.features) {
                let featureCollection = CameraFeatureCollectionModel.fromREST(
                  data
                );
                let cameras = [];
                if (featureCollection.features) {
                  cameras = [...featureCollection.features];
                  setCamerasList(cameras);
                }
              }
            }),
            catchError((error) => {
              setIsCctvLoading(false);
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  render() {
    return <div></div>;
  }

  componentWillUnmount() {
    this.subscriptions$.forEach((x) => x.unsubscribe());
  }
}

LoadCameraData.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
  setCamerasList,
  wsOpen,
  updateCameraStatesWS,
  updateCameraAlarmsWS,
  addNewPinnedCamera,
  resetPinnedCameras,
  setAllCamerasVisibility,
  setIsCctvLoading,
};

const mapStateToProps = (state) => ({
  //UI
  menuNavTabSearch: getNavTabSearch(state),
  menuTabReducer: getNavTabSelectedTab(state),
  isOpenNavTab: isOpenNavTab(state),
  isAllCamerasVisible: isAllCamerasVisible(state),
  //BBOX
  boundingBox: getBoundingBox(state),
  //WS
  isWSCamerasStateOpen: isWSCamerasStateOpen(state),
  isWSCamerasAlarmsOpen: isWSCamerasAlarmsOpen(state),
  wsCameraAlarm: getWsLastCameraAlarm(state),
  wsCameraStatus: getWsLastCameraStatus(state),
  //WORKSPACE
  currentWorkspace: getCurrentWorkspace(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadCameraData)
);
