import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import NavTab from "components/layout/nav-tab";
import EnvironmentContext from "environment-context";

class HomePage extends Component {
  render() {
    return <NavTab />;
  }
}

HomePage.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
