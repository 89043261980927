import { combineReducers } from "redux";
import events from "./events/events.reducer";
import map from "./map/map.reducer";
import configurations from "./configurations/configurations.reducer";
import lines from "./lines/lines.reducer";
import stops from "./stops/stops.reducer";
import trips from "./trips/trips.reducer";
import routes from "./routes/routes.reducer";
import vehicles from "./vehicles/vehicles.reducer";
import installations from "./installations/installations.combine";
import ui from "./ui/ui.reducer";
import kpi from "./kpi/kpi.reducer";
import user from "./user/user.reducer";
import modal from "./modal/modal.reducer";
import graph from "./graph/graph.reducer";
import tableFilters from "./filter-table-selected/filter-table-selected.reducer";
import traffic from "./traffic/traffic.reducer";
import workspace from "./workspace/workspace.reducer";

export default combineReducers({
  events,
  map,
  configurations,
  lines,
  stops,
  trips,
  routes,
  vehicles,
  installations,
  ui,
  kpi,
  user,
  modal,
  graph,
  tableFilters,
  traffic,
  workspace,
});
