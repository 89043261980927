export function getTypeTranslation(currentEvent, selectModal) {
  return currentEvent &&
    currentEvent.situationRecordType &&
    selectModal &&
    selectModal.types &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ) &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ).typeNameIT
    ? selectModal.types.find(
        (x) => x.typeName === currentEvent.situationRecordType
      ).typeNameIT
    : currentEvent && currentEvent.situationRecordType
    ? currentEvent.situationRecordType
    : "-";
}

export function getSubtypeTranslation(currentEvent, selectModal) {
  return currentEvent &&
    currentEvent.situationRecordType &&
    selectModal &&
    selectModal.types &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ) &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ).subTypes &&
    selectModal.types
      .find((x) => x.typeName === currentEvent.situationRecordType)
      .subTypes.find(
        (y) => y.subTypeName === currentEvent.situationRecordSubType
      )
    ? selectModal.types
        .find((x) => x.typeName === currentEvent.situationRecordType)
        .subTypes.find(
          (y) => y.subTypeName === currentEvent.situationRecordSubType
        ).subTypeNameIT
    : currentEvent && currentEvent.situationRecordSubType
    ? currentEvent.situationRecordSubType
    : "-";
}
export function getTypeId(currentEvent, selectModal) {
  return currentEvent &&
    currentEvent.situationRecordType &&
    selectModal &&
    selectModal.types &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ) &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ).typeId
    ? selectModal.types.find(
        (x) => x.typeName === currentEvent.situationRecordType
      ).typeId
    : currentEvent && currentEvent.situationRecordType
    ? currentEvent.situationRecordType
    : null;
}

export function getSubtypeId(currentEvent, selectModal) {
  return currentEvent &&
    currentEvent.situationRecordType &&
    selectModal &&
    selectModal.types &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ) &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ).subTypes &&
    selectModal.types
      .find((x) => x.typeName === currentEvent.situationRecordType)
      .subTypes.find(
        (y) => y.subTypeName === currentEvent.situationRecordSubType
      )
    ? selectModal.types
        .find((x) => x.typeName === currentEvent.situationRecordType)
        .subTypes.find(
          (y) => y.subTypeName === currentEvent.situationRecordSubType
        ).subTypeId
    : currentEvent && currentEvent.situationRecordType
    ? currentEvent.situationRecordType
    : null;
}

export function getTypeName(currentEvent, selectModal) {
  return currentEvent &&
    currentEvent.situationRecordType &&
    selectModal &&
    selectModal.types &&
    selectModal.types.length > 0 &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ) &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ).typeName
    ? selectModal.types.find(
        (x) => x.typeName === currentEvent.situationRecordType
      ).typeName
    : currentEvent && currentEvent.situationRecordType
    ? currentEvent.situationRecordType
    : null;
}

export function getSubtypeName(currentEvent, selectModal) {
  return currentEvent &&
    currentEvent.situationRecordType &&
    selectModal &&
    selectModal.types &&
    selectModal.types.length > 0 &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ) &&
    selectModal.types.find(
      (x) => x.typeName === currentEvent.situationRecordType
    ).subTypes &&
    selectModal.types
      .find((x) => x.typeName === currentEvent.situationRecordType)
      .subTypes.find(
        (y) => y.subTypeName === currentEvent.situationRecordSubType
      )
    ? selectModal.types
        .find((x) => x.typeName === currentEvent.situationRecordType)
        .subTypes.find(
          (y) => y.subTypeName === currentEvent.situationRecordSubType
        ).subTypeName
    : currentEvent && currentEvent.situationRecordType
    ? currentEvent.situationRecordType
    : null;
}
