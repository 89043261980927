import React, { Component } from "react";
import "./style.less";

class BottomControlsIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { controls } = this.props;

    const length = controls.length;
    return (
      <div
        className={`${
          length === 0
            ? "bottom-controls-none"
            : length === 1
            ? "bottom-controls-single-icon "
            : length === 2
            ? "bottom-controls-two-icon"
            : length === 3
            ? "bottom-controls-three-icon"
            : length === 4
            ? "bottom-controls-four-icon"
            : "bottom-controls-five-icon"
        } uk-flex uk-flex-row uk-flex-middle space-evenly`}
      >
        {controls.map((item, index) => {
          return (
            <span
              key={index}
              uk-tooltip={`title: ${item.tooltip}`}
              className={`pointer height-width-35 uk-icon ${item.className}`}
              uk-icon={`icon: ${item.icon}`}
              onClick={item.toggle}
            ></span>
          );
        })}
      </div>
    );
  }
}

export default BottomControlsIcons;
