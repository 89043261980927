import React, { Component } from "react";
import "./style.less";
//ASSETS
import satellite from "assets/icon/map/satellite.png";
import mapLight from "assets/icon/map/mappaLight.png";
import mapDark from "assets/icon/map/mappaDark.png";
import mapStreet from "assets/icon/map/mapStreet.png";

class HookLayer extends Component {
  render() {
    const {
      handleSwitchLayer,
      toggleTabChangeLayer,
      layer,
      isControlOpen,
    } = this.props;

    return (
      <div>
        <div
          className={`container-legend ${
            !isControlOpen ? "container-move-to-right" : ""
          }`}
        >
          <div className="uk-width-1-1 uk-height-1-1 uk-card uk-card-primary uk-card-body uk-padding-small">
            <div className="uk-width-1-1 uk-flex uk-flex-row uk-flex-middle">
              <h5 className="uk-margin-remove uk-card-title-small">
                TIPO DI MAPPA
              </h5>
              <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
                {/*  <span
                  className="uk-margin-small-right cursor-pointer"
                  uk-icon="icon: acr-interface-new-window"
                ></span> */}
                <span
                  className="cursor-pointer"
                  uk-icon="icon: acr-interface-close"
                  onClick={(event) => {
                    event.preventDefault();
                    return toggleTabChangeLayer();
                  }}
                ></span>
              </div>
            </div>
            <div className="uk-width-1-1 uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-padding uk-padding-remove-right uk-padding-remove-left">
              <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-padding-small">
                <img
                  alt=""
                  src={mapStreet}
                  className="legend-map uk-padding-small cursor-pointer"
                  onClick={(event) => {
                    event.preventDefault();
                    toggleTabChangeLayer();
                    handleSwitchLayer("streets");
                  }}
                />
                <h5
                  className={
                    layer === "buildings"
                      ? "legend-text-map-current"
                      : "legend-text-map"
                  }
                >
                  Edifici 3D
                </h5>
              </div>
              <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-padding-small">
                <img
                  alt=""
                  src={satellite}
                  className="legend-map uk-padding-small cursor-pointer"
                  onClick={(event) => {
                    event.preventDefault();
                    toggleTabChangeLayer();
                    handleSwitchLayer("normal");
                  }}
                />
                <h5
                  className={
                    layer === "normal"
                      ? "legend-text-map-current"
                      : "legend-text-map"
                  }
                >
                  Satellitare
                </h5>
              </div>
              <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-padding-small">
                <img
                  alt=""
                  src={mapLight}
                  className="legend-map uk-padding-small cursor-pointer"
                  onClick={(event) => {
                    event.preventDefault();
                    toggleTabChangeLayer();
                    handleSwitchLayer("light");
                  }}
                />
                <h5
                  className={
                    layer === "light"
                      ? "legend-text-map-current"
                      : "legend-text-map"
                  }
                >
                  Chiara
                </h5>
              </div>
              <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-padding-small">
                <img
                  alt=""
                  src={mapDark}
                  className="legend-map uk-padding-small cursor-pointer"
                  onClick={(event) => {
                    event.preventDefault();
                    toggleTabChangeLayer();
                    handleSwitchLayer("dark");
                  }}
                />
                <h5
                  className={
                    layer === "dark"
                      ? "legend-text-map-current"
                      : "legend-text-map"
                  }
                >
                  Scura
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HookLayer;
