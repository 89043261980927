import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAllStops } from "store";
//COMPONENTS
import VehicleLabelOnLine from "./vehicle-label-online/index";
//ASSETS
import line_start from "assets/images/line/elements_line_start.svg";
import line_stop from "assets/images/line/elements_line_stop.svg";
import line_end from "assets/images/line/elements_line_end.svg";

class LineStop extends Component {
  render() {
    const { stop, index, length, vehicles, transportMode } = this.props;

    return (
      <li
        key={index}
        className="uk-flex uk-flex-column uk-flex-middle uk-stop-width-height"
      >
        {vehicles && vehicles.length > 0 && (
          <VehicleLabelOnLine
            transportMode={transportMode}
            vehicles={vehicles}
            stop={stop}
          />
        )}
        {index === 0 ? (
          <img alt="" src={line_start} key={index}></img>
        ) : index === length - 1 ? (
          <img alt="" src={line_end} key={index}></img>
        ) : (
          <img alt="" src={line_stop} key={index}></img>
        )}
        <h3 className="stop-text" key={index + 1}>
          {stop.nameShort}
        </h3>
      </li>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  stopsReducer: getAllStops(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LineStop)
);
