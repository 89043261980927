export const tableMenuActions = {
  TOGGLE_GENERIC_TABLE: "TOGGLE_GENERIC_TABLE",
  TOGGLE_SAVE_WORKSPACE: "USER/TOGGLE_SAVE_WORKSPACE",
};

export const toggleGenericTable = (type) => (dispatch) => {
  dispatch({
    type: tableMenuActions.TOGGLE_GENERIC_TABLE,
    payload: { type },
  });
};

export const toggleSaveWorkspace = (workspaces) => (dispatch) => {
  dispatch({
    type: tableMenuActions.TOGGLE_SAVE_WORKSPACE,
    payload: workspaces,
  });
};
