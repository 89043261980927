import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import "./style.less";

//UTILS
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";

//ACTIONS
import { getCurrentStop } from "store";

class StopTimeTable extends Component {
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  header = () => {
    const { handleResetSelected, current } = this.props;
    return (
      <div
        id="floatingMenu"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div
          uk-icon={
            current.transportMode && current.transportMode.length === 2
              ? "icon: acr-list-stops-exchange"
              : "icon: acr-list-stops"
          }
          className={
            current.transportMode && current.transportMode.length <= 1
              ? current.transportMode.find(
                  (t) => t === TYPE_TRANSPORT_MODE["extraurb"]
                )
                ? current.isParkingPoint
                  ? "stopSuburbanDetail isParkingPointDetail isParkingPointSubDetail"
                  : "stopSuburbanDetail stopSuburbanDetailBack"
                : current.isParkingPoint
                ? "stopUrbanDetail isParkingPointDetail isParkingPointUrbDetail"
                : "stopUrbanDetail stopUrbanDetailBack"
              : current.isParkingPoint
              ? "stopUrbSubDetail isParkingPointSubUrbDetail"
              : "stopUrbSubDetail"
          }
        ></div>

        <div className=" uk-margin-left uk-margin-right textMedium text-line-width">
          {current && current.nameShort}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            handleResetSelected();
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClickStopDetail } = this.props;

    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer text-title-tab"
            onClick={(event) => {
              event.preventDefault();
              handleClickStopDetail();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li className="pointer uk-active text-title-tab">
            <a href="/#">ORARI</a>
          </li>
        </ul>
      </div>
    );
  };

  list = () => {
    const { current } = this.props;

    return (
      <div className="contentBodyStop">
        {current &&
          current.monitoredStopVisits &&
          current.monitoredStopVisits.length > 0 &&
          current.monitoredStopVisits.map((vehicle, index) => {
            return (
              <li
                key={index}
                className="uk-flex uk-flex-row uk-flex-between uk-flex-middle item pointer"
              >
                <div className="uk-flex uk-flex-column uk-flex-middle">
                  <div
                    uk-icon="icon: acr-menu-routes-management"
                    className="imgBusStopTimeTable colorMuted"
                  ></div>
                  <div className="textSmall colorMuted">
                    {vehicle &&
                    vehicle.monitoredVehicleJourney &&
                    vehicle.monitoredVehicleJourney.vehicleJourneyName
                      ? vehicle.monitoredVehicleJourney.vehicleJourneyName
                      : "-"}
                  </div>
                </div>

                <div className="uk-flex uk-flex-column uk-flex-middle uk-margin-left uk-margin-right">
                  <div className="widthText textMedium">
                    Linea{" "}
                    {vehicle &&
                    vehicle.monitoredVehicleJourney &&
                    vehicle.monitoredVehicleJourney.publishedLineName
                      ? vehicle.monitoredVehicleJourney.publishedLineName
                      : "-"}
                  </div>
                  <div className="widthText textSmall">
                    {vehicle &&
                    vehicle.monitoredVehicleJourney &&
                    vehicle.monitoredVehicleJourney.destinationName
                      ? vehicle.monitoredVehicleJourney.destinationName
                      : "-"}
                  </div>
                </div>
                <div className="textMedium fontWeight">
                  {vehicle &&
                  vehicle.monitoredVehicleJourney &&
                  vehicle.monitoredVehicleJourney.monitoredCall &&
                  vehicle.monitoredVehicleJourney.monitoredCall
                    .aimedDepartureTime
                    ? moment(
                        vehicle.monitoredVehicleJourney.monitoredCall
                          .aimedDepartureTime
                      )
                        .format("HH:mm")
                        .toString()
                    : ""}
                </div>
              </li>
            );
          })}
      </div>
    );
  };

  render = () => {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.list()}
      </div>
    );
  };
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  stopsReducer: getCurrentStop(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StopTimeTable)
);
