import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  isOpenNavTab,
  isNetworkRdsTmcVisible,
  isOpenNavNetworkRdsTmc,
} from "store";

const mapStateToProps = (state) => ({
  isNetworkRdsTmcVisible: isNetworkRdsTmcVisible(state),
  isOpenNavTab: isOpenNavTab(state),
  isOpenNavNetworkRdsTmc: isOpenNavNetworkRdsTmc(state),
});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemNetworkReteRdsTmc extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isOpenNavNetworkRdsTmc,
      isNetworkRdsTmcVisible,
      clickOnReteRdsTmcLabel,
      clickOnReteRdsTmcCheckbox,
    } = this.props;
    return (
      <div
        className={
          isOpenNavNetworkRdsTmc
            ? "uk-checkbox-title uk-open acr-rete-open"
            : "uk-checkbox-title"
        }
      >
        <span>
          <input
            className="uk-checkbox uk-checkbox-large "
            type="checkbox"
            checked={isNetworkRdsTmcVisible || false}
            onChange={() => clickOnReteRdsTmcCheckbox()}
          />
        </span>
        <label
          className="checkbox-network-rds-tmc"
          onClick={() => clickOnReteRdsTmcLabel()}
        >
          Rete RDS-TMC
        </label>
      </div>
    );
  }
}

export default NavItemNetworkReteRdsTmc;
