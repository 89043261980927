import { INITIAL_STATE_TRIPS } from "./trips.model";
import { TripsActions } from "./trips.actions";
import { VehiclesActions } from "../vehicles/vehicles.actions";
import { NavTabActions } from "../ui/nav-tab/nav-tab.actions";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";
import * as R from "ramda";

export default function tripsReducer(state = INITIAL_STATE_TRIPS, action) {
  switch (action.type) {
    case TripsActions.GET_TRIPS:
      let listTrips = [...state.trips];
      let listNoDuplicates = action.payload;

      let ids = new Set(
        listTrips.map(
          (item) =>
            item.monitoredVehicleJourney.framedVehicleJourneyRef
              .datedVehicleJourneyRef
        )
      );
      listNoDuplicates = listNoDuplicates.filter(
        (item) =>
          !ids.has(
            item.monitoredVehicleJourney.framedVehicleJourneyRef
              .datedVehicleJourneyRef
          )
      );

      return {
        ...state,
        trips: [...state.trips, ...listNoDuplicates],
        filtered: [...state.filtered, ...listNoDuplicates],
        cityBus: [
          ...state.cityBus,
          ...listNoDuplicates.filter(
            (x) =>
              x.transportMode &&
              x.transportMode.subMode &&
              x.transportMode.subMode.code === TYPE_TRANSPORT_MODE["urb"]
          ),
        ],
        regionBus: [
          ...state.regionBus,
          ...listNoDuplicates.filter(
            (x) =>
              x.transportMode &&
              x.transportMode.subMode &&
              x.transportMode.subMode.code === TYPE_TRANSPORT_MODE["extraurb"]
          ),
        ],
        cityBusFiltered: [
          ...state.cityBusFiltered,
          ...listNoDuplicates.filter(
            (x) =>
              x.transportMode &&
              x.transportMode.subMode &&
              x.transportMode.subMode.code === TYPE_TRANSPORT_MODE["urb"]
          ),
        ],
        regionBusFiltered: [
          ...state.regionBusFiltered,
          ...listNoDuplicates.filter(
            (x) =>
              x.transportMode &&
              x.transportMode.subMode &&
              x.transportMode.subMode.code === TYPE_TRANSPORT_MODE["extraurb"]
          ),
        ],
      };
    case TripsActions.RESET_TRIPS:
      return INITIAL_STATE_TRIPS;
    case TripsActions.SET_CURRENT_TRIP:
      return {
        ...state,
        current: action.payload,
      };
    case VehiclesActions.SET_CURRENT_VEHICLE:
      let tripsList = [...state.trips];
      let currentTrip = null;
      if (
        (state.current &&
          state.current.monitoredVehicleJourney.framedVehicleJourneyRef
            .datedVehicleJourneyRef ===
            action.payload.monitoredVehicleJourney.framedVehicleJourneyRef
              .datedVehicleJourneyRef) ||
        (state.current &&
          action.payload.vehicleActivityLocation &&
          action.payload.vehicleActivityLocation.monitoredVehicleJourney
            .framedVehicleJourneyRef.datedVehicleJourneyRef ===
            state.current.monitoredVehicleJourney.vehicleJourneyName)
      ) {
        currentTrip = null;
      } else {
        tripsList.forEach((item) => {
          if (
            (item &&
              item.monitoredVehicleJourney.framedVehicleJourneyRef
                .datedVehicleJourneyRef ===
                action.payload.monitoredVehicleJourney.framedVehicleJourneyRef
                  .datedVehicleJourneyRef) ||
            (item &&
              action.payload.vehicleActivityLocation &&
              action.payload.vehicleActivityLocation.monitoredVehicleJourney
                .framedVehicleJourneyRef.datedVehicleJourneyRef ===
                item.monitoredVehicleJourney.vehicleJourneyName)
          ) {
            currentTrip = item;
          }
        });
      }
      return {
        ...state,
        current: currentTrip,
      };
    case TripsActions.RESET_CURRENT_TRIP:
      return {
        ...state,
        current: null,
      };
    case VehiclesActions.RESET_CURRENT_VEHICLE:
      return {
        ...state,
        current: null,
      };
    case VehiclesActions.SET_VEHICLES_EVENTS:
      let currentTrips = state.current ? { ...state.current } : null;
      let update_trips = [...state.trips];
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.map((element) => {
          const loc = element.vehicleActivityLocation;
          const journey = loc.monitoredVehicleJourney;
          let index = update_trips.findIndex(
            (item) =>
              (journey.framedVehicleJourneyRef &&
                journey.framedVehicleJourneyRef.datedVehicleJourneyRef ===
                  item.monitoredVehicleJourney.framedVehicleJourneyRef
                    .datedVehicleJourneyRef) ||
              (journey.framedVehicleJourneyRef &&
                journey.framedVehicleJourneyRef.datedVehicleJourneyRef ===
                  item.monitoredVehicleJourney.vehicleJourneyName)
          );
          if (index >= 0) {
            let concatValues = (k, l, r) => (r ? r : l);
            const stateLocation = update_trips[index].monitoredVehicleJourney;
            let merge = R.mergeDeepWithKey(
              concatValues,
              stateLocation,
              journey
            );
            merge = {
              ...merge,
              onwardCalls: journey.monitoredCall
                ? journey.onwardCalls
                : stateLocation.onwardCalls,
              previousCalls: journey.monitoredCall
                ? journey.previousCalls
                : stateLocation.previousCalls,
            };
            update_trips[index] = {
              ...update_trips[index],
              recordedAtTime: element.messageReceivedAtTime,
              progressBetweenStops: loc.progressBetweenStops
                ? loc.progressBetweenStops
                : update_trips[index].progressBetweenStops,
              monitoredVehicleJourney: { ...merge },
            };
            if (
              (currentTrips &&
                currentTrips.monitoredVehicleJourney.framedVehicleJourneyRef
                  .datedVehicleJourneyRef ===
                  journey.framedVehicleJourneyRef.datedVehicleJourneyRef) ||
              (currentTrips &&
                journey.framedVehicleJourneyRef.datedVehicleJourneyRef ===
                  currentTrips.monitoredVehicleJourney.vehicleJourneyName)
            ) {
              currentTrips = update_trips[index];
            }

            /* update_trips[index] = {
              ...update_trips[index],
              recordedAtTime: element.messageReceivedAtTime,
              progressBetweenStops: element.vehicleActivityLocation
                .progressBetweenStops
                ? element.vehicleActivityLocation.progressBetweenStops
                : update_trips[index].progressBetweenStops,
              monitoredVehicleJourney: {
                ...update_trips[index].monitoredVehicleJourney,
                vehicleLocation: {
                  ...update_trips[index].monitoredVehicleJourney
                    .vehicleLocation,
                  latitude: element.vehicleActivityLocation
                    .monitoredVehicleJourney.vehicleLocation.latitude
                    ? element.vehicleActivityLocation.monitoredVehicleJourney
                        .vehicleLocation.latitude
                    : update_trips[index].monitoredVehicleJourney
                        .vehicleLocation.latitude,
                  longitude: element.vehicleActivityLocation
                    .monitoredVehicleJourney.vehicleLocation.longitude
                    ? element.vehicleActivityLocation.monitoredVehicleJourney
                        .vehicleLocation.longitude
                    : update_trips[index].monitoredVehicleJourney
                        .vehicleLocation.longitude,
                },
              },
            };
            if (
              currentTrips &&
              currentTrips.monitoredVehicleJourney.framedVehicleJourneyRef
                .datedVehicleJourneyRef ===
                element.vehicleActivityLocation.monitoredVehicleJourney
                  .framedVehicleJourneyRef.datedVehicleJourneyRef
            ) {
              currentTrips = {
                ...currentTrips,
                recordedAtTime: element.messageReceivedAtTime,
                progressBetweenStops: update_trips[index].progressBetweenStops,
                monitoredVehicleJourney: {
                  ...currentTrips.monitoredVehicleJourney,
                  vehicleLocation:
                    update_trips[index].monitoredVehicleJourney.vehicleLocation,
                },
              };
            } */
          }
          return element;
        });
      }
      return {
        ...state,
        current: currentTrips,
        trips: update_trips,
      };
    // TODO: Fix reducer
    case NavTabActions.SET_SEARCH:
      if (
        action.payload.location &&
        action.payload.location.includes("tpl") &&
        action.payload.menuTab === 2
      ) {
        let searchString =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        let filtered = [...state.trips];
        let cityBusFiltered = [...state.cityBus];
        let regionBusFiltered = [...state.regionBus];
        if (searchString !== "") {
          filtered = [...state.trips].filter(
            (trip) =>
              trip.monitoredVehicleJourney.vehicleJourneyName
                .toLowerCase()
                .includes(searchString) ||
              trip.monitoredVehicleJourney.lineRef
                .toLowerCase()
                .includes(searchString) ||
              trip.monitoredVehicleJourney.publishedLineName
                .toLowerCase()
                .includes(searchString) ||
              trip.vehicleMonitoringRef.toLowerCase().includes(searchString)
          );
          cityBusFiltered = [...state.cityBus].filter(
            (trip) =>
              trip.monitoredVehicleJourney.vehicleJourneyName
                .toLowerCase()
                .includes(searchString) ||
              trip.monitoredVehicleJourney.lineRef
                .toLowerCase()
                .includes(searchString) ||
              trip.monitoredVehicleJourney.publishedLineName
                .toLowerCase()
                .includes(searchString) ||
              trip.vehicleMonitoringRef.toLowerCase().includes(searchString)
          );
          regionBusFiltered = [...state.regionBus].filter(
            (trip) =>
              trip.monitoredVehicleJourney.vehicleJourneyName
                .toLowerCase()
                .includes(searchString) ||
              trip.monitoredVehicleJourney.lineRef
                .toLowerCase()
                .includes(searchString) ||
              trip.monitoredVehicleJourney.publishedLineName
                .toLowerCase()
                .includes(searchString) ||
              trip.vehicleMonitoringRef.toLowerCase().includes(searchString)
          );
        }
        return {
          ...state,
          current: null,
          filtered,
          cityBusFiltered,
          regionBusFiltered,
        };
      } else {
        return { ...state };
      }
    default:
      return state;
  }
}

export const getTrips = (state) => state.trips;
export const getCurrentTrip = (state) => state.current;
export const setCurrentTrip = (state) => state.current;
export const resetCurrentTrip = (state) => state.current;
export const getFilteredTrips = (state) => state.filtered;

export const getCityBusTrips = (state) => state.cityBus;
export const getRegionBusTrips = (state) => state.regionBus;
export const getCityBusTripsFiltered = (state) => state.cityBusFiltered;
export const getRegionBusTripsFiltered = (state) => state.regionBusFiltered;
