export const TABLE_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: false,
    order: false,
  },
  beginning: {
    name: "Inizio",
    id: "beginning",
    show: true,
    order: false,
  },
  end: {
    name: "Fine",
    id: "end",
    show: true,
    order: false,
  },
  measure: {
    name: "Misura",
    id: "measure",
    show: true,
    order: false,
  },
  current: {
    name: "Corrente",
    id: "current",
    show: true,
    order: false,
  },
  forecast15: {
    name: "Previsto +15",
    id: "forecast15",
    show: true,
    order: false,
  },
  forecast30: {
    name: "Previsto +30",
    id: "forecast30",
    show: true,
    order: false,
  },
  forecast45: {
    name: "Previsto +45",
    id: "forecast45",
    show: true,
    order: false,
  },
  forecast60: {
    name: "Previsto +60",
    id: "forecast60",
    show: true,
    order: false,
  },
  profile: {
    name: "Profilo",
    id: "profile",
    show: true,
    order: false,
  },
  dayType: {
    name: "Tipo Giorno",
    id: "dayType",
    show: true,
    order: false,
  },
};
