import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";
import iconMapping from "assets/cluster/location-icon-mapping-2-events.json";
import IconClusterPmvLayer from "./icon-cluster-pmv-layer";
class PmvLayer extends CompositeLayer {
  renderLayers() {
    const { data, visible, onClick, isDrawingMode, viewport } = this.props;
    if (viewport.zoom <= 12 && data && data.length >= 1) {
      return new IconClusterPmvLayer(
        this.getSubLayerProps({
          id: "",
          data,
          pickable: false,
          getPosition: (d) => [
            d &&
              d.geometry &&
              d.geometry.coordinates &&
              d.geometry.coordinates[1],
            d &&
              d.geometry &&
              d.geometry.coordinates &&
              d.geometry.coordinates[0],
          ],
          iconAtlas: require("assets/cluster/location-icon-atlas-2-white.png"),
          iconMapping,
          sizeScale: 6,
        })
      );
    } else {
      return new IconLayer(
        this.getSubLayerProps({
          id: "icon-pmvs",
          data: data,
          pickable: isDrawingMode ? false : true,
          ...DeckLayerUtil.loadOptions(),
          getIcon: (obj) => ({
            url: DeckLayerUtil.getIconPmv(obj),
            width: 80,
            height: 80,
            anchorX: 54,
            anchorY: 90,
          }),
          sizeScale: 6,
          showCluster: false,
          visible: visible,
          onClick: onClick,
          getPosition: (d) => [
            d &&
              d.geometry &&
              d.geometry.coordinates &&
              d.geometry.coordinates[1],
            d &&
              d.geometry &&
              d.geometry.coordinates &&
              d.geometry.coordinates[0],
          ],
          getSize: (data) => (data && data.selected ? 12 : 8),
        })
      );
    }
  }
}

PmvLayer.layerName = "PmvLayer";

export default PmvLayer;
