import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.less";
import { getTypeModal, getTabModal, selectedEvents } from "store";
import { togglePopup } from "reducers/ui/modal/modal.actions";
import { getSelectedEvents } from "store";
// import { togglePopup } from "reducers/ui/modal/modal.actions";
// import closeEvent from "components/layout/modal/event/close-event/close-event";
class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getBody = () => {
    const {
      textBody,
      toggleClose,
      toggleFetch,
      iconTypeError,
      typeModal,
      clickOkToDelete,
      modalTabReducer,
      closeEvent,
      deletePractice,
      closePractice,
      togglePopup,
      selectedEvents,
    } = this.props;

    return (
      <div className="popup-body">
        <div className="uk-height-1-1 uk-width-1-1 uk-flex uk-flex-column uk-flex-middle uk-flex-center">
          <div className="uk-height-1-2 uk-width-1-1 uk-flex uk-flex-row uk-flex-left uk-flex-middle">
            <div
              uk-icon={
                iconTypeError
                  ? "acr-interface-close"
                  : "icon: acr-interface--info"
              }
              className="icon-info"
            ></div>
            <div className="uk-margin-medium-left uk-flex-column">
              { selectedEvents.length > 1 &&
                (
                  <p className="header-popup-tab-postRecords">
                    Attenzione:
                  </p>
                )}
              <div>{textBody || ""}</div>
            </div>
          </div>
          <div className="container-btn uk-flex uk-flex-bottom uk-flex-right">
            {typeModal === "modifyEvent" &&
            modalTabReducer === "general" &&
            !closeEvent ? (
              <div>
                <button
                  className="pointer btn-ok uk-margin-right"
                  onClick={(e) => {
                    e.preventDefault();
                    clickOkToDelete();
                  }}
                >
                  Ok
                </button>
                <button
                  className="pointer btn-ok"
                  onClick={(e) => {
                    e.preventDefault();
                    togglePopup();
                  }}
                >
                  Annulla
                </button>
              </div>
            ) : typeModal === "practiceManagement" && !closePractice ? (
              <div>
                <button
                  className="pointer btn-ok uk-margin-right"
                  onClick={(e) => {
                    e.preventDefault();
                    deletePractice();
                  }}
                >
                  Ok
                </button>
                <button
                  className="pointer btn-ok"
                  onClick={(e) => {
                    e.preventDefault();
                    togglePopup();
                  }}
                >
                  Annulla
                </button>
              </div>
            ) : selectedEvents.length > 1 ? (
              <div>
                <button
                  className="pointer btn-ok uk-margin-right"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleClose();
                  }}
                >
                  Annulla
                </button>
                <button
                  className="pointer btn-ok"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleFetch();
                  }}
                >
                  Ok
                </button>
              </div>
            ) : (
              <button
                className="pointer btn-ok"
                onClick={(e) => {
                  e.preventDefault();
                  toggleClose();
                }}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  getHeader = () => {
    const { titleHeader, toggleClose } = this.props;
    return (
      <div className="popup-header">
        <div className="uk-width-1-2">
          <ul className="uk-tab-page-secondary-large uk-tab uk-margin-remove">
            <li className="uk-active ">
              <a href="/#" className="header-popup-tab">
                {titleHeader || ""}
              </a>
            </li>
          </ul>
        </div>
        <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right uk-flex-middle">
          {/* <span
            className="uk-margin-small-right pointer uk-icon"
            uk-icon="icon: acr-interface-new-window"
          ></span> */}
          <span
            className="pointer uk-icon"
            uk-icon="icon: acr-interface-close"
            onClick={(e) => {
              e.preventDefault();
              toggleClose();
            }}
          ></span>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="popup-container">
        <div className="smooth-container"></div>
        {this.getHeader()}
        {this.getBody()}
      </div>
    );
  }
}

const mapDispatchToProps = { togglePopup };

const mapStateToProps = (state) => ({
  typeModal: getTypeModal(state),
  modalTabReducer: getTabModal(state),
  selectedEvents: getSelectedEvents(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Popup));
