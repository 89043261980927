export const LinesActions = {
  GET_LINES: "APP/GET_LINES",
  SET_ACTIVE_LINES: "APP/SET_ACTIVE_LINES",
  RESET_ACTIVE_LINES: "APP/RESET_ACTIVE_LINES",
  RESET_LINES: "APP/RESET_LINES",
  UPDATE_ACTIVE_LINES: "APP/UPDATE_ACTIVE_LINES",
};

export const getLines = (data) => (dispatch) => {
  dispatch({
    type: LinesActions.GET_LINES,
    payload: data,
  });
};

export const resetLines = () => (dispatch) => {
  dispatch({
    type: LinesActions.RESET_LINES,
  });
};

export const setActiveLines = (data) => (dispatch) => {
  dispatch({
    type: LinesActions.SET_ACTIVE_LINES,
    payload: data,
  });
};

export const resetActiveLines = (data) => (dispatch) => {
  dispatch({
    type: LinesActions.RESET_ACTIVE_LINES,
    payload: null,
  });
};

export const updateActiveLines = (data) => (dispatch) => {
  dispatch({
    type: LinesActions.UPDATE_ACTIVE_LINES,
    payload: data,
  });
};
