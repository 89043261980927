import ApiServiceProd from "./api.service.prod";
import ApiServiceNode from "./api.service.node";

export default (environment) => {
  switch (environment.name) {
    case "development_old":
      return new ApiServiceNode(environment);
    default:
      return new ApiServiceProd(environment);
  }
};
