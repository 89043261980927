import * as turf from "@turf/turf";
import * as CustomLayers from "utils/map-utils/layer-difinitions";
import { of, throwError } from "rxjs";
import { tap, catchError, concatMap, finalize } from "rxjs/operators";
import { ViewMode } from "nebula.gl";
import { _onMapLoad } from "../load-map/load-map";
import createApiService from "services/api.service";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";

//UTILS
import {
  loadFilterTrip,
  loadFilterStop,
  loadFilterRoute,
} from "utils/utils-filter-tpl";

// CLICK HANDLERS
export function onClickEvent(mapComponent, obj) {
  const {
    setCurrentEvent,
    history,
    toggleOpenNavTab,
    location,
    toggleNavEvents,
    setCurrentEventGroup,
    setNavTabMultitab,
  } = mapComponent.props;
  const { ctrlDown } = mapComponent.state;

  if (obj && obj.object && obj.object.situationRecordId) {
    setNavTabMultitab("Eventi");
    if (ctrlDown) {
      setCurrentEventGroup(obj.object);
    } else {
      if (!location.pathname.includes("events")) {
        history.push("/viability/events");
        toggleNavEvents(true);
      }
      toggleOpenNavTab(true);
      setCurrentEvent(obj.object);
    }
  }
}

export function onClickCentroid(mapComponent, object) {
  const {
    setCurrentCentroidMap,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    toggleNavGraph,
    setNavTabMultitab,
  } = mapComponent.props;

  if (object && object.object) {
    if (!location.pathname.includes("graph")) {
      history.push("/network/graph");
    }
    setNavTabMultitab("Grafo Stradale");
    toggleNavGraph(true);
    toggleOpenNavTab(true);
    setCurrentCentroidMap({ centroid: object.object });
    setNavTabSelectedTab(2);
  }
}

export function onClickArc(mapComponent, object) {
  const {
    setCurrentArcMap,
    setCurrentArc,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    toggleNavGraph,
    isArcVisible,
    setNavTabMultitab,
  } = mapComponent.props;

  if (object && object.object) {
    if (!location.pathname.includes("graph")) {
      if (isArcVisible) {
        history.push("/network/graph");
        setCurrentArc({ arc: object.object });
      }
    } else {
      setCurrentArcMap({ arc: object.object });
    }
    setNavTabMultitab("Grafo Stradale");
    toggleNavGraph(true);
    toggleOpenNavTab(true);
    setNavTabSelectedTab(0);
  }
}

export function onClickNode(mapComponent, object) {
  const {
    setCurrentNodeMap,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    toggleNavGraph,
    setNavTabMultitab,
  } = mapComponent.props;

  if (object && object.object) {
    if (!location.pathname.includes("graph")) {
      history.push("/network/graph");
    }
    setNavTabMultitab("Grafo Stradale");
    toggleNavGraph(true);
    toggleOpenNavTab(true);
    setCurrentNodeMap({ node: object.object });
    setNavTabSelectedTab(1);
  }
}

export function onClickTrafficArc(mapComponent, object) {
  const {
    setTrafficCurrentArc,
    setTrafficCurrentArcMap,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    allTrafficChecked,
    toggleNavTraffic,
    setNavTabMultitab,
  } = mapComponent.props;

  if (object && object.object) {
    if (!location.pathname.includes("traffic")) {
      if (allTrafficChecked) {
        history.push("/viability/traffic");
        setTrafficCurrentArc({ arc: object.object });
      }
    } else {
      setTrafficCurrentArcMap({ arc: object.object });
    }
    setNavTabMultitab("Traffico");
    toggleOpenNavTab(true);
    toggleNavTraffic(true);
    setNavTabSelectedTab(0);
  }
}
export function onClickStation(mapComponent, object) {
  const {
    setCurrentStation,
    toggleOpenNavTab,
    location,
    history,
    toggleNavStation,
    setNavTabSelectedTab,
    setNavTabMultitab,
  } = mapComponent.props;

  if (object && object.object) {
    if (!location.pathname.includes("stations")) {
      history.push("/installations/stations");
    }
    setNavTabMultitab("Stazioni di Misura");
    toggleNavStation(true);
    toggleOpenNavTab(true);
    setCurrentStation(object.object);
    setNavTabSelectedTab(0);
  }
}
export function onClickWebcam(mapComponent, object) {
  const {
    setCurrentCamera,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    toggleNavWebcams,
    setNavTabMultitab,
  } = mapComponent.props;

  if (object && object.object) {
    if (!location.pathname.includes("cctv")) {
      history.push("/installations/cctv");
    }
    setNavTabMultitab("Telecamere");
    toggleNavWebcams(true);
    toggleOpenNavTab(true);
    setCurrentCamera(object.object);
    setNavTabSelectedTab(0);
  }
}

export function onClickPMV(mapComponent, object) {
  const {
    setCurrentPmv,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    toggleNavWebcams,
    setNavTabMultitab,
    setCurrentPmvGroup,
  } = mapComponent.props;
  const { ctrlDown } = mapComponent.state;
  if (object && object.object) {
    if (ctrlDown) {
      setCurrentPmvGroup(object.object);
    } else {
      if (!location.pathname.includes("pmv")) {
        toggleNavWebcams(true);
        history.push("/installations/pmv");
      }
      setNavTabMultitab("Pannelli");
      toggleOpenNavTab(true);
      setCurrentPmv(object.object);
      setNavTabSelectedTab(0);
    }
  }
}

export function onClickParking(mapComponent, object) {
  const {
    setCurrentParking,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    setNavTabMultitab,
  } = mapComponent.props;

  if (object && object.object) {
    if (!location.pathname.includes("parking")) {
      history.push("/installations/parking");
    }
    setNavTabMultitab("Parcheggi");
    toggleOpenNavTab(true);
    setCurrentParking(object.object);
    setNavTabSelectedTab(0);
  }
}

export function onClickPoint(mapComponent, object) {
  const {
    setCurrentPointMap,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    toggleNavNetworkRDSTMC,
    setNavTabMultitab,
  } = mapComponent.props;

  if (object && object.object) {
    if (!location.pathname.includes("rete-rds-tmc")) {
      history.push("/network/rete-rds-tmc");
    }
    setNavTabMultitab("Rete RDS-TMC");
    toggleNavNetworkRDSTMC(true);
    toggleOpenNavTab(true);
    setCurrentPointMap({ point: object.object });
    setNavTabSelectedTab(0);
  }
}

export function onClickSegments(mapComponent, object) {
  const {
    setCurrentSegmentRdsTmcMap,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    toggleNavNetworkRDSTMC,
    setNavTabMultitab,
  } = mapComponent.props;
  if (object && object.object) {
    if (!location.pathname.includes("rete-rds-tmc")) {
      history.push("/network/rete-rds-tmc");
    }
    setNavTabMultitab("Rete RDS-TMC");
    toggleNavNetworkRDSTMC(true);
    toggleOpenNavTab(true);
    setCurrentSegmentRdsTmcMap({ segment: object.object });
    setNavTabSelectedTab(1);
  }
}

export function onClickStop(mapComponent, object) {
  const {
    setNavTabSelectedTab,
    history,
    location,
    toggleOpenNavTab,
    toggleNavTPL,
    setNavTabMultitab,
  } = mapComponent.props;

  if (object && object.object) {
    setNavTabMultitab("Trasporto Pubblico");
    if (!location.pathname.includes("tpl")) {
      toggleNavTPL(true);
      history.push("/viability/tpl");
    }
    toggleOpenNavTab(true);
    loadFilterStop(mapComponent, object.object, true);
    //setCurrentStop(object.object, true);
    setNavTabSelectedTab(1);

    const elemToScrollTo = document.getElementById(object.object.id);
    if (!!elemToScrollTo) {
      elemToScrollTo.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "start",
      });
    }
  }
}

export function onClickLine(
  type,
  obj,
  mapComponent,
  reduceDuplicatesInArrayForId
) {
  const {
    cityBusRoutes,
    regionBusRoutes,
    filterStopMap,
    activeLines,
    setNavTabSelectedTab,
    toggleOpenNavTab,
    location,
    history,
    toggleNavTPL,
    setNavTabMultitab,
  } = mapComponent.props;
  setNavTabMultitab("Trasporto Pubblico");

  const routes = type === "city" ? cityBusRoutes : regionBusRoutes;
  if (obj && obj.object && obj.object.properties && obj.object.properties.id) {
    let currentLine = routes.find(
      (item) => item.id === obj.object.properties.routeRef
    );
    if (currentLine) {
      if (!location.pathname.includes("tpl")) {
        history.push("/viability/tpl");
        toggleNavTPL(true);
      }
      loadFilterRoute(mapComponent, currentLine, true);
      toggleOpenNavTab(true);
      setNavTabSelectedTab(0);

      let stopsList = [];
      let found = activeLines.find((a) => a.id === currentLine.id);
      if (!found) {
        if (currentLine && currentLine.points)
          stopsList = [...currentLine.points];
        activeLines.forEach((act) => {
          if (act && act.points) stopsList.push(...act.points);
        });
      } else {
        let arrRoutes = [...activeLines].filter(
          (act) => act.id !== currentLine.id
        );
        arrRoutes.forEach((act) => {
          if (act && act.points) stopsList.push(...act.points);
        });
      }
      stopsList = reduceDuplicatesInArrayForId(stopsList);
      filterStopMap(stopsList);

      const elemToScrollTo = document.getElementById(
        obj.object.properties.routeRef
      );
      if (!!elemToScrollTo) {
        elemToScrollTo.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }
}

export function onLayerClick(mapComponent, info) {
  const { polygon } = mapComponent.state;
  const {
    setSelectedFeatureIndexes,
    resetSelectedFeatureIndexes,
    setPolygon,
    mapModeReducer,
  } = mapComponent.props;

  if (info) {
    mapComponent.setState({
      polygon: {
        center: info.coordinate,
        positionTooltip: { x: info.x, y: info.y },
      },
    });
  }

  if (
    info &&
    info.picked === undefined &&
    mapModeReducer === CustomLayers.circleSelectionLayer &&
    polygon.count === 0
  ) {
    setPolygon({
      radius: 0, //m
      center: info.coordinate,
    });
    mapComponent.setState({
      polygon: {
        center: info.coordinate,
        positionTooltip: { x: info.x, y: info.y },
        count: 1,
      },
    });
  }
  if (
    mapModeReducer === CustomLayers.circleSelectionLayer &&
    polygon.count === 1
  ) {
    mapComponent.setState({
      polygon: {
        count: 0,
      },
    });
  }
  if (mapModeReducer !== ViewMode) {
    // don't change selection while editing
    return;
  }

  if (info) {
    setSelectedFeatureIndexes([info.index]);
  } else {
    // open space was clicked, so stop editing
    resetSelectedFeatureIndexes();
  }
}

export function onClickBusTpl(object, x, y, mapComponent) {
  const {
    setNavTabSelectedTab,
    hoveredObjectsReducer,
    setHoveredBus,
    history,
    location,
    toggleOpenNavTab,
    toggleNavTPL,
  } = mapComponent.props;

  if (object) {
    if (!location.pathname.includes("tpl")) {
      history.push("/viability/tpl");
      toggleNavTPL(true);
    }
    toggleOpenNavTab(true);
    loadFilterTrip(mapComponent, object, true);
    setNavTabSelectedTab(2);
    if (
      object.vehicleActivityLocation &&
      object.vehicleActivityLocation.monitoredVehicleJourney &&
      object.vehicleActivityLocation.monitoredVehicleJourney
        .framedVehicleJourneyRef &&
      object.vehicleActivityLocation.monitoredVehicleJourney
        .framedVehicleJourneyRef.datedVehicleJourneyRef
    ) {
      const elemToScrollTo = document.getElementById(
        object.vehicleActivityLocation.monitoredVehicleJourney
          .framedVehicleJourneyRef.datedVehicleJourneyRef
      );
      if (!!elemToScrollTo) {
        elemToScrollTo.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "start",
        });
      }
    }
    setHoveredBus({
      object: object,
      x: x,
      y: y,
      toBeFollowed: !hoveredObjectsReducer.hoveredBus.toBeFollowed,
    });
  } else if (!object) {
    setHoveredBus({
      object: null,
      x: null,
      y: null,
      toBeFollowed: !hoveredObjectsReducer.hoveredBus.toBeFollowed,
    });
  }
}

export function onCircleClick(mapComponent) {
  const { polygon } = mapComponent.state;
  const { setPolygon, geoJson } = mapComponent.props;

  if (
    geoJson.features &&
    geoJson.features.length > 0 &&
    polygon &&
    polygon.center
  ) {
    var rad = 0;
    var distance = 0;
    var coord = [
      ...geoJson.features[geoJson.features.length - 1].geometry.coordinates[0],
    ];
    distance = turf.distance(
      turf.point([...polygon.center]),
      turf.point([...coord[0]]),
      { units: "kilometers" }
    );
    rad = (Math.round(distance * 1000) * 100) / 100;
    setPolygon({
      area: Math.round(rad * rad * 3.14 * 100) / 100, //m2
      radius: rad, //m
      center: polygon.center,
      positionTooltip: polygon.positionTooltip,
    });
  }
  if (polygon && !polygon.center) {
    mapComponent.setState({ errorDrawing: true });
    mapComponent.deleteLayer();
    setTimeout(() => {
      mapComponent.setState({ errorDrawing: false });
    }, 2000);
  }
}

export function onPolygonClick(mapComponent) {
  const { polygon } = mapComponent.state;
  const { setPolygon, geoJson } = mapComponent.props;
  if (geoJson.features && geoJson.features.length > 0) {
    var polygonTurf = turf.polygon([
      [
        ...geoJson.features[geoJson.features.length - 1].geometry
          .coordinates[0],
      ],
    ]);
    var area = turf.area(polygonTurf);
    setPolygon({
      area: Math.round(area * 100) / 100, //m2
      positionTooltip: polygon.positionTooltip,
      polygoncoordinates:
        geoJson.features[geoJson.features.length - 1].geometry.coordinates[0],
    });
  }
}

export function onMultipleDistanceClick(mapComponent) {
  const { setMultipleDistance, geoJson } = mapComponent.props;

  if (geoJson && geoJson.features.length > 0) {
    let mulDis = [];
    geoJson.features.forEach((feat) => {
      feat.geometry.coordinates.forEach((coord, index, array) => {
        let distance = 0;
        if (index !== 0) {
          distance = turf.distance(
            turf.point([...array[index - 1]]),
            turf.point([...coord]),
            { units: "kilometers" }
          );
          mulDis.push({
            coordsStart: array[index - 1],
            coordsEnd: coord,
            distanceFromPrevious: Math.round(distance * 1000) / 1000,
            distanceTot: 0,
          });
        } else {
          mulDis.push({
            coordsStart: null,
            coordsEnd: coord,
            distanceFromPrevious: 0,
            distanceTot: 0,
          });
        }
      });
    });
    mulDis.map((element, index, arr) => {
      if (index !== 0) {
        element.distanceTot =
          Math.round(
            (element.distanceFromPrevious + arr[index - 1].distanceTot) * 1000
          ) / 1000;
      }
      return element;
    });
    setMultipleDistance(mulDis);
  }
}

function notif(msg, pos) {
  const defMsg =
    "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Caricamento Percorso</label>";
  UIkit.notification({
    message: msg ? msg : defMsg,
    status: "primary",
    pos: pos ? pos : "top-right",
    group: "route-group",
  });
}

export function onShortestDistanceOnGraphClick(mapComponent) {
  const {
    shortestDistanceOnGraphReducer,
    setShortestDistanceOnGraph,
    geoJson,
    setGeoJsonObj,
    setSelectedFeatureIndexes,
  } = mapComponent.props;
  let apiService = createApiService(mapComponent.context);
  UIkit.notification.closeAll("route-group");
  notif(null, null);
  let newGeoJson = { ...geoJson };
  //CAN ONLY SHOW ONE SHORTEST DISTANCE AT A TIME
  //IF TWO ARE DRAWN, REMOVE THE FIRST ONE
  if (geoJson.features.length > 1) {
    newGeoJson = {
      features: [geoJson.features[1]],
      type: geoJson.type,
    };
    setGeoJsonObj(newGeoJson);
    setSelectedFeatureIndexes([0]);

    let shortestDistancePoints = [];

    newGeoJson.features.forEach((feat) => {
      let arrayOfCouples = [];
      let count = 0;
      let totDistance = 0;
      let totTravelTime = 0;

      //BUILD ARRAY OF COUPLES OF POINTS
      feat.geometry.coordinates.forEach((coord, index, array) => {
        if (index !== array.length - 1) {
          arrayOfCouples.push({
            sourceLong: coord[0],
            sourceLat: coord[1],
            targetLong: [...array[index + 1]][0],
            targetLat: [...array[index + 1]][1],
          });
        }
      });

      //CALL API FOR DISTANCE BETWEEN EVERY COUPLE OF POINTS
      //SAVE OBJECTS TO SAVE ON MAP AND ON TOOLTIP
      callDistanceApi(arrayOfCouples, apiService)
        .pipe(
          tap((x) => {
            arrayOfCouples.forEach((couple, index) => {
              if (index === count) {
                //FIRST "EMPTY" POINT
                if (index === 0) {
                  shortestDistancePoints.push({
                    coordinates: [couple.sourceLong, couple.sourceLat],
                    travelTimeFromPrevious: 0,
                    travelTime: 0,
                    distanceFromPrevious: 0,
                    distanceTot: 0,
                    points: [],
                  });
                }
                let distanceFromPrevious = 0;
                let travelTime = 0;
                let totPoints = [];
                x.features.forEach(
                  (item) =>
                    (distanceFromPrevious =
                      distanceFromPrevious + item.properties.cost)
                );
                totDistance = totDistance + distanceFromPrevious;
                x.features.forEach((item) => {
                  if (item.properties.fft) {
                    return (travelTime = travelTime + item.properties.fft);
                  }
                });
                totTravelTime = totTravelTime + travelTime;
                x.features.forEach(
                  (item) =>
                    (totPoints = totPoints.concat(item.geometry.coordinates))
                );
                //ALL OTHER POINTS
                shortestDistancePoints.push({
                  coordinates: [couple.targetLong, couple.targetLat],
                  travelTimeFromPrevious: travelTime,
                  travelTime: totTravelTime,
                  distanceFromPrevious: distanceFromPrevious
                    ? distanceFromPrevious
                    : 0,
                  distanceTot: totDistance,
                  points: totPoints,
                });
              }
            });
            count = count + 1;
          }),
          catchError((error) => {
            UIkit.notification.closeAll("route-group");
            console.error(error);
            return of(error);
          }),
          finalize(() => {
            setShortestDistanceOnGraph(shortestDistancePoints);
            UIkit.notification.closeAll("route-group");
          })
        )
        .subscribe();
    });
  }

  if (
    newGeoJson &&
    newGeoJson.features &&
    newGeoJson.features.length === 1 &&
    shortestDistanceOnGraphReducer.length === 0
  ) {
    let shortestDistancePoints = [];

    newGeoJson.features.forEach((feat) => {
      let arrayOfCouples = [];
      let count = 0;
      let totDistance = 0;
      let totTravelTime = 0;

      //BUILD ARRAY OF COUPLES OF POINTS
      feat.geometry.coordinates.forEach((coord, index, array) => {
        if (index !== array.length - 1) {
          arrayOfCouples.push({
            sourceLong: coord[0],
            sourceLat: coord[1],
            targetLong: [...array[index + 1]][0],
            targetLat: [...array[index + 1]][1],
          });
        }
      });

      //CALL API FOR DISTANCE BETWEEN EVERY COUPLE OF POINTS
      //SAVE OBJECTS TO SAVE ON MAP AND ON TOOLTIP
      callDistanceApi(arrayOfCouples, apiService)
        .pipe(
          tap((x) => {
            if (x) {
              arrayOfCouples.forEach((couple, index) => {
                if (index === count) {
                  //FIRST "EMPTY" POINT
                  if (index === 0) {
                    shortestDistancePoints.push({
                      coordinates: [couple.sourceLong, couple.sourceLat],
                      travelTimeFromPrevious: 0,
                      travelTime: 0,
                      distanceFromPrevious: 0,
                      distanceTot: 0,
                      points: [],
                    });
                  }
                  let distanceFromPrevious = 0;
                  let travelTime = 0;
                  let totPoints = [];
                  x.features.forEach(
                    (item) =>
                      (distanceFromPrevious =
                        distanceFromPrevious + item.properties.cost)
                  );
                  totDistance = totDistance + distanceFromPrevious;
                  x.features.forEach((item) => {
                    if (item.properties.fft) {
                      return (travelTime = travelTime + item.properties.fft);
                    }
                  });
                  totTravelTime = totTravelTime + travelTime;
                  x.features.forEach(
                    (item) =>
                      (totPoints = totPoints.concat(item.geometry.coordinates))
                  );
                  //ALL OTHER POINTS
                  shortestDistancePoints.push({
                    coordinates: [couple.targetLong, couple.targetLat],
                    travelTimeFromPrevious: travelTime,
                    travelTime: totTravelTime,
                    distanceFromPrevious: distanceFromPrevious
                      ? distanceFromPrevious
                      : 0,
                    distanceTot: totDistance,
                    points: totPoints,
                  });
                }
              });
              count = count + 1;
            }
          }),
          catchError((error) => {
            UIkit.notification.closeAll("route-group");
            arrayOfCouples.forEach((couple, index) => {
              shortestDistancePoints.push({
                coordinates: [couple.targetLong, couple.targetLat],
                travelTimeFromPrevious: "N/D",
                travelTime: "N/D",
                distanceFromPrevious: "N/D",
                distanceTot: "N/D",
                points: [],
              });
            });
            console.error(error);
            return of(error);
          }),
          finalize(() => {
            setShortestDistanceOnGraph(shortestDistancePoints);
            UIkit.notification.closeAll("route-group");
          })
        )
        .subscribe();
    });
  }
}

function callDistanceApi(arrayOfCouples, apiService) {
  return of(...arrayOfCouples).pipe(
    concatMap((couple) =>
      apiService
        .getShortestPathBetweenCoordinates(
          couple.sourceLong,
          couple.sourceLat,
          couple.targetLong,
          couple.targetLat
        )
        .pipe(
          catchError((error) => {
            console.error(error);
            return throwError(error);
          })
        )
    )
  );
}

export function mapFlyTo(mapComponent, lat, lon, zoom, dur) {
  mapComponent.setState({
    viewport: {
      ...mapComponent.state.viewport,
      longitude: lon,
      latitude: lat,
      zoom: zoom,
      transitionDuration: dur,
    },
  });
}

export function mapFitBounds(mapComponent, points) {
  if (points.length > 0) {
    let turfPoints = [];
    points.forEach((elem) => {
      turfPoints.push(
        turf.point([elem.coordinates.longitude, elem.coordinates.latitude])
      );
    });
    var features = turf.featureCollection(turfPoints);
    var center = turf.center(features);
    mapComponent.setState({
      viewport: {
        ...mapComponent.state.viewport,
        longitude: center.geometry.coordinates[0],
        latitude: center.geometry.coordinates[1],
        zoom: 9,
        transitionDuration: 2000,
      },
    });
  }
}

export function onEdit(contextObject, mapComponent) {
  const {
    setSelectedFeatureIndexes,
    setGeoJsonObj,
    selectedFeatureIndexes,
    mapModeReducer,
  } = mapComponent.props;
  const { updatedData, editType, editContext } = contextObject;

  let updatedSelectedFeatureIndexes = selectedFeatureIndexes;
  if (editType === "removePosition") {
    // && !this.state.pointsRemovable) {
    // This is a simple example of custom handling of edits
    // reject the edit
    return;
  }
  if (editType === "addFeature") {
    // && this.state.mode !== DuplicateMode) {
    const { featureIndexes } = editContext;
    // Add the new feature to the selection
    updatedSelectedFeatureIndexes = [
      ...selectedFeatureIndexes,
      ...featureIndexes,
    ];
  }
  setSelectedFeatureIndexes(updatedSelectedFeatureIndexes);
  if (
    updatedData.features.length > 1 &&
    mapModeReducer &&
    (mapModeReducer.id === "circle-layer" ||
      mapModeReducer.id === "polygon-layer")
  ) {
    let lastPolygon = {
      type: updatedData.type,
      features: [updatedData.features[updatedData.features.length - 1]],
    };
    setGeoJsonObj(lastPolygon);
  } else {
    setGeoJsonObj(updatedData);
  }

  if (mapModeReducer && mapModeReducer.id === "distance-layer")
    mapComponent.onMultipleDistanceClick();
  if (mapModeReducer && mapModeReducer.id === "circle-layer")
    mapComponent.onCircleClick();
  if (mapModeReducer && mapModeReducer.id === "polygon-layer")
    mapComponent.onPolygonClick();
  if (mapModeReducer && mapModeReducer.id === "route-layer") {
    mapComponent.onShortestDistanceOnGraphClick();
  }
}

export function handleSwitchLayer(mapComponent, event) {
  const { mapStyle } = mapComponent.context.map;
  const { setLayout } = mapComponent.props;

  switch (event) {
    case "streets":
      if (mapComponent.map) {
        mapComponent.map.setStyle(mapStyle.streets);
      }
      mapComponent.setState({
        style: mapStyle.streets,
        layoutMap: event,
      });
      _onMapLoad("streets", mapComponent);
      break;

    case "normal":
      if (mapComponent.map) {
        mapComponent.map.setStyle(mapStyle.normal);
      }
      mapComponent.setState({
        style: mapStyle.normal,
        layoutMap: event,
      });
      break;

    case "light":
      if (mapComponent.map) {
        mapComponent.map.setStyle(mapStyle.light);
      }
      mapComponent.setState({
        style: mapStyle.light,
        layoutMap: "light",
      });
      break;

    default:
      if (mapComponent.map) {
        mapComponent.map.setStyle(mapStyle.dark);
      }
      mapComponent.setState({
        style: mapStyle.dark,
        layoutMap: "dark",
      });
      break;
  }
  setLayout(event);
}
