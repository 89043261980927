import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import createApiService from "services/api.service";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import EnvironmentContext from "environment-context";
//STORE
import { withRouter } from "react-router-dom";
import { getCurrentSegmentRdsTmc } from "store";
//ASSETS
import acr_segment from "assets/icon/graph/acr-segment-green.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";
import line_start from "assets/images/line/elements_line_start_vertical.svg";
import line_stop from "assets/images/line/elements_line_stop_vertical.svg";
import line_end from "assets/images/line/elements_line_end_vertical.svg";

class SegmentRdsTmcRoute extends Component {
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      pointsOnTratta: [],
    };
  }

  componentDidMount = () => {
    const { currentSegment } = this.props;
    this.apiService = createApiService(this.context);
    this.subscriptions.push(
      this.apiService
        .getRouteDetailsOnTratta(
          currentSegment.properties.end_road,
          currentSegment.properties.road_lcd,
          0,
          0
        )
        .pipe(
          tap((data) => {
            this.setState({
              pointsOnTratta: [...data],
            });
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  header = () => {
    const { toggleSegmentDetail, currentSegment } = this.props;

    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="uk-flex uk-flex-middle uk-flex-center">
          <div className="parent uk-flex uk-flex-middle uk-flex-center">
            <div className="circle2"></div>

            <img
              alt="icn_diamond"
              src={icn_diamond}
              className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
            ></img>
            <img
              alt="acr_segment"
              src={acr_segment}
              className="image2 height-width-48 uk-flex uk-flex-middle uk-flex-center segment"
            ></img>
          </div>
        </div>
        <div className="uk-margin-small-left uk-margin-small-right text-line-width text-detail-emphasis-arc tratte-title-height">
          {currentSegment &&
          currentSegment.properties &&
          currentSegment.properties.name
            ? currentSegment.properties.name
            : "-"}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            toggleSegmentDetail(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClicksSegmentRoute } = this.props;
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClicksSegmentRoute();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Percorso</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { pointsOnTratta } = this.state;

    return (
      <div className="uk-flex uk-flex-middle uk-flex-row bodyContainer padding-bottom-60">
        <div className="uk-height1-1 uk-width1-1">
          {pointsOnTratta.map((point, index, elements) => {
            return (
              <li
                className="uk-flex uk-flex-row uk-height1-1 uk-flex-middle uk-width1-1 line-image-dimension"
                key={index}
              >
                {index === 0 ? (
                  <img alt="" src={line_start}></img>
                ) : pointsOnTratta.length === index + 1 ? (
                  <img alt="" src={line_end}></img>
                ) : (
                  <img alt="" src={line_stop} key={index}></img>
                )}
                <div className="uk-flex uk-margin-left text-detail-muted-arc-svincolo p-dot">
                  {point.name1}
                </div>
                <div className="uk-flex text-detail-muted-arc-km">
                  km{" "}
                  {point && point.distance_fe && point.distance_fe.toFixed(3)}
                </div>
              </li>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}
SegmentRdsTmcRoute.contextType = EnvironmentContext;

const mapStateToProps = (state) => ({
  currentSegment: getCurrentSegmentRdsTmc(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SegmentRdsTmcRoute)
);
