import * as moment from "moment";

export const filterDataByVehicle = (station, defaultMeasure) => {
  let vehiclesDataMeasure =
    station &&
    station.measures &&
    station.measures[0] &&
    station.measures.filter((item) => {
      return item.measureType === "EQUIVALENT";
    });

  const strArr =
    vehiclesDataMeasure &&
    vehiclesDataMeasure[0] &&
    vehiclesDataMeasure.map((obj) => JSON.stringify(obj));
  const uniq = [...new Set(strArr)].map((u) => JSON.parse(u));

  let vehiclesData =
    uniq &&
    uniq[0] &&
    uniq.map((item) => ({
      x: item.measureTimeUtc,
      y:
        item[
          defaultMeasure &&
            defaultMeasure.name &&
            defaultMeasure.name.toLowerCase()
        ],
    }));
  let vehicleDataArray = [
    {
      id: "Veicoli Equivalenti",
      color: "hsl(149, 70%, 50%)",
      data: vehiclesData ? vehiclesData : [],
    },
  ];

  return vehicleDataArray;
};
export function getTimeRange(dateStart, dateEnd) {
  let newDateStart2 = moment(dateStart);
  let newDateEnd2 = moment(dateEnd);
  let diff2 = newDateEnd2.diff(newDateStart2);
  let diffDuration2 = moment.duration(diff2);
  let differentTime2 =
    diffDuration2.days() * 60 * 24 +
    diffDuration2.hours() * 60 +
    diffDuration2.minutes();
  return differentTime2;
}
