import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";
import createApiService from "services/api.service";
// COMPONENTS
import Loading from "components/shared/loading/loading";
import InfiniteScroll from "react-infinite-scroll-component";
//ACTIONS
import { getAllActiveLines, getAllStops, getBottombarTabs } from "store";
import {
  closeBottombar,
  expandBottombar,
  setBottombarActiveTab,
  addBottombarTab,
  removeBottombarTab,
} from "reducers/ui/bottombar/bottombar.actions";
import { setActiveLines } from "reducers/lines/lines.actions";
import { filterStopMap } from "reducers/stops/stops.actions";
import {
  setCurrentIdStopForRoute,
  setCurrentIdTripForRoute,
} from "reducers/routes/routes.actions";
// ASSETS
import EnvironmentContext from "environment-context";
import { loadFilterRoute } from "utils/utils-filter-tpl";

const SCROLL_OFFSET = 50;

class LinesList extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {
      openDetail: false,
      currentOffset: SCROLL_OFFSET,
      nextRoutes: [],
      currentListLen: 0,
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  handleOpenDetail = (route) => {
    const {
      activeLines,
      closeBottombar,
      addBottombarTab,
      setBottombarActiveTab,
      expandBottombar,
      removeBottombarTab,
      bottombarTabs,
    } = this.props;

    if (activeLines.length === 0) {
      addBottombarTab("Linearizzata");
      setBottombarActiveTab("Linearizzata");
      expandBottombar();
    } else if (
      activeLines.length === 1 &&
      activeLines.find((line) => line.routeRef === route.routeRef)
    ) {
      let newTab =
        bottombarTabs.filter((item) => item !== "Linearizzata").length > 0
          ? bottombarTabs[0]
          : null;
      setBottombarActiveTab(newTab);
      if (!newTab) {
        closeBottombar();
      }
      removeBottombarTab("Linearizzata");
    }
    loadFilterRoute(this, route, false);
  };

  handleClickPdf = (link) => {
    //this.apiService.getPdf(link);
    this.apiService.goToSite(link);
  };

  fetchMoreData = () => {
    const { currentOffset } = this.state;
    const { routesList } = this.props;
    let newOffset = currentOffset + SCROLL_OFFSET;
    let fetchmore = routesList.slice(0, newOffset);
    this.setState({
      nextRoutes: fetchmore,
      currentOffset: newOffset,
    });
  };

  constructFetchedDOM = () => {
    const { nextRoutes } = this.state;
    const { activeLines } = this.props;

    return (
      <div className="line-list-container">
        <div className="uk-list">
          {nextRoutes &&
            nextRoutes.map((route, index) => {
              return (
                <div
                  className="uk-flex uk-flex-row uk-flex-middle uk-flex-between"
                  key={index}
                >
                  <li
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleOpenDetail(route);
                    }}
                    id={route.id}
                    className="uk-flex uk-flex-between uk-flex-middle"
                  >
                    <div
                      className={
                        route.transportMode &&
                        route.transportMode.subMode &&
                        route.transportMode.subMode.code &&
                        route.transportMode.subMode.code ===
                          TYPE_TRANSPORT_MODE["extraurb"]
                          ? activeLines &&
                            activeLines.length > 0 &&
                            activeLines.find((active) => active.id === route.id)
                            ? "numberLineSuburban"
                            : "numberLineSuburban numberInactiveSub"
                          : activeLines &&
                            activeLines.length > 0 &&
                            activeLines.find((active) => active.id === route.id)
                          ? "numberLineUrban"
                          : "numberLineUrban numberInactiveUrb"
                      }
                    >
                      {route && route.nameShort}
                    </div>
                    <div
                      className={
                        activeLines &&
                        activeLines.length > 0 &&
                        activeLines.find((active) => active.id === route.id)
                          ? "uk-width-1-1 uk-margin-left uk-margin-right active list-text"
                          : "uk-width-1-1 uk-margin-left uk-margin-right inactive list-text"
                      }
                    >
                      {route && route.nameLong}
                    </div>
                  </li>
                  <span
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleClickPdf("https://m.atv.verona.it/#/routes");
                    }}
                    uk-icon="icon: acr-interface-time"
                    className={
                      activeLines &&
                      activeLines.length > 0 &&
                      activeLines.find((active) => active.id === route.id)
                        ? "pointer active"
                        : "pointer inactive"
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { nextRoutes, currentOffset, currentListLen } = state;
    const { routesList } = props;
    let newState = {};

    if (currentListLen !== routesList.length && routesList.length === 0) {
      newState = {
        currentListLen: routesList.length,
        currentOffset: SCROLL_OFFSET,
        nextRoutes: [],
      };
    }
    if (currentListLen !== routesList.length && routesList.length > 0) {
      newState = {
        currentListLen: routesList.length,
        currentOffset: routesList.length - 1, //SCROLL_OFFSET,
        nextRoutes: routesList.slice(0, routesList.length), //routesList.slice(0, SCROLL_OFFSET),
      };
    }
    if (nextRoutes.length === 0 && currentListLen > 0) {
      newState = {
        ...state,
        nextRoutes: routesList.slice(
          0,
          currentListLen > SCROLL_OFFSET ? currentOffset : currentListLen
        ),
      };
    }

    return newState;
  }

  renderInfiniteScroll = () => {
    const { nextRoutes } = this.state;
    const { routesList } = this.props;
    return (
      <InfiniteScroll
        dataLength={nextRoutes.length || 0} //This is important field to render the next data
        next={this.fetchMoreData}
        hasMore={nextRoutes.length === routesList.length ? false : true}
        loader={
          <div>
            {routesList.length ? "" /* <Loading /> */ : "Nessun elemento..."}
          </div>
        }
        scrollableTarget={"tabpanel-0"}
        className="inner-infinite-scroll"
      >
        {this.constructFetchedDOM()}
      </InfiniteScroll>
    );
  };

  render() {
    const { isLinesLoading } = this.props;
    return (
      <div>{isLinesLoading ? <Loading /> : this.renderInfiniteScroll()}</div>
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

LinesList.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setActiveLines,
  filterStopMap,
  setCurrentIdStopForRoute,
  setCurrentIdTripForRoute,
  closeBottombar,
  expandBottombar,
  setBottombarActiveTab,
  addBottombarTab,
  removeBottombarTab,
};

const mapStateToProps = (state) => ({
  activeLines: getAllActiveLines(state),
  allStopsReducer: getAllStops(state),
  bottombarTabs: getBottombarTabs(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LinesList)
);
