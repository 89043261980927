import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import "./style.less";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { take, tap, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
//STORE
import {
  isOpenNavTab,
  getTrafficMeasures,
  getUsername,
  getUserRoles,
} from "store";
import { addActivity } from "reducers/user/user.actions";
//MODELS
import { UserActivityModel } from "reducers/user/user.model";
//COMPONENTS
import CustomisedStationDateTimePicker from "components/shared/customised-date-time-picker/customised-station-datetime-picker";
import graph_station from "assets/station-table/graph_station.svg";
import table_station from "assets/station-table/table_station.svg";
//UTILS
import { getTimeRange } from "utils/util-station-graph";
import { saveBlob } from "utils/hooks";

class TableStationTopFilters extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  downloadStationExcel = () => {
    const { dateStart, dateEnd, currentStation, defaultMeasure } = this.props;
    this.apiService = createApiService(this.context);
    let newDateStart = moment(dateStart);
    let newDateEnd = moment(dateEnd);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();

    this.apiService
      .fetchStationExcel(
        currentStation.stationId,
        dateStart.toISOString(),
        differentTime,
        defaultMeasure.name
      )
      .pipe(
        tap((blob) => {
          let filename =
            "station_table_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postStationDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postStationDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "MEASUREMENT_STATIONS",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Resoconto Stazioni di Misura"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  render = () => {
    const {
      isOpenNav,
      dateStart,
      dateEnd,
      changeDateStart,
      changeDateEnd,
      defaultMeasure,
      handleSelectMeasure,
      currentStation,
      isStartDate,
      isSameDate,
      isOpenTable,
      toggleTable,
      toggleStatistics,
      refreshData,
    } = this.props;

    let differentTime = getTimeRange(dateStart, new Date());
    let differentTimeStartEnd = getTimeRange(dateStart, dateEnd);

    return (
      <div className="select-time-style">
        <div className="select-style-top">
          <select
            className="uk-select select-style-sel"
            value={
              defaultMeasure && defaultMeasure.name ? defaultMeasure.name : ""
            }
            onChange={(e) => handleSelectMeasure(e.target.value)}
          >
            <option>{defaultMeasure && defaultMeasure.nameIt}</option>;
            {currentStation &&
              currentStation.supportedMeasure.map((item, index) => {
                return defaultMeasure && defaultMeasure.name !== item.name ? (
                  <option key={index} value={item.name}>
                    {item.nameIt}
                  </option>
                ) : (
                  ""
                );
              })}
          </select>
        </div>
        <div className="time-picker-style">
          <span className="center-label ">Dal:</span>
          <CustomisedStationDateTimePicker
            className="uk-form-small uk-padding-small select-style"
            value={dateStart}
            dateFormat={"dd/MM/yyyy HH:mm"}
            timeFormat={"HH:mm"}
            callbackFromParent={changeDateStart}
            iconRatio={isOpenNav ? "ratio: 0.8;" : "ratio: 1.5"}
            startDate={dateStart}
            endDate={dateEnd}
            isSameDate={isSameDate}
            isStartDate={true}
            startTime={dateStart}
            isTimeEnd={moment(dateStart).isSame(
              moment(new Date()).toDate(),
              "day"
            )}
          />
          <span className="center-label">al:</span>
          <CustomisedStationDateTimePicker
            className="uk-form-small uk-padding-small select-style"
            value={dateEnd}
            dateFormat={"dd/MM/yyyy HH:mm"}
            timeFormat={"HH:mm"}
            callbackFromParent={changeDateEnd}
            iconRatio={isOpenNav ? "ratio: 0.8;" : "ratio: 1.5"}
            startDate={dateStart}
            isSameDate={isSameDate}
            maxDate={moment(dateStart)
              .add(
                differentTime > 0 && differentTime <= 4320
                  ? moment(dateStart)
                      .add(differentTime / 60 / 24, "days")
                      .toDate()
                      .getDay() > new Date().getDay()
                    ? differentTime / 60 / 24 - 1
                    : moment(dateStart)
                        .add(differentTime / 60 / 24, "days")
                        .toDate()
                        .getDay() < new Date().getDay()
                    ? differentTime / 60 / 24 + 1
                    : differentTime / 60 / 24
                  : 3,
                "days"
              )
              .toDate()}
            isEndDate={isStartDate}
            startTime={dateStart}
            isTimeEnd={moment(dateEnd).isSame(
              moment(dateStart).add(3, "days").toDate(),
              "day"
            )}
            isTimeEndSameDay={moment(dateEnd).isSame(
              moment(new Date()).toDate(),
              "day"
            )}
            differentTime={differentTimeStartEnd}
            isStartDate={false}
          />
        </div>
        <div className="uk-button-group uk-margin-right height-button">
          <button
            className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
            type="button"
            onClick={() => toggleStatistics()}
          >
            <span uk-tooltip="Statistiche" className="filterBarIcons">
              <img
                className="station-icons"
                src={graph_station}
                alt="station-icons"
              ></img>
            </span>
          </button>
        </div>
        <div className="uk-button-group uk-margin-right height-button">
          <button
            className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
            type="button"
            // disabled={true}
            onClick={(e) => toggleTable()}
          >
            <span uk-tooltip="Tabella Stazioni" className="filterBarIcons">
              <img
                className="station-icons"
                src={table_station}
                alt="station-icons"
              ></img>
            </span>
          </button>
        </div>
        <div className="uk-button-group uk-margin-right height-button">
          <button
            className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
            onClick={() => refreshData()}
          >
            <span
              uk-icon="icon: refresh"
              uk-tooltip="Refresh statistiche stazioni"
              className="filterBarIcons"
            ></span>
          </button>
        </div>
        <div className="uk-button-group uk-margin-right height-button">
          <button className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right">
            {isOpenTable ? (
              <span
                uk-icon="icon: download "
                uk-tooltip="Download Excel "
                className="filterBarIcons "
                onClick={() => this.downloadStationExcel()}
              ></span>
            ) : (
              <span
                uk-icon="icon: download "
                uk-tooltip={
                  "Selezionare la vista tabellare per abilitare questa funzione"
                }
                className="filterBarIcons cursor-disabled"
              ></span>
            )}
          </button>
        </div>
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

TableStationTopFilters.contextType = EnvironmentContext;

const mapDispatchToProps = { addActivity };

const mapStateToProps = (state) => ({
  isOpenNav: isOpenNavTab(state),
  measures: getTrafficMeasures(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableStationTopFilters)
);
