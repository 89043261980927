import React, { Component } from "react";
import "./style.less";
import StateTimeSlider from "./state-time-slider/index";
import TypicalTimeSlider from "./typical-time-slider";
import {
  showContestDateAndHour,
  showTypicalDateandHour,
} from "utils/utils-traffic";

class TrafficWidget extends Component {
  handleOpenTimeSlider = () => {
    const { openTimeSlider } = this.props;
    openTimeSlider();
  };

  render() {
    const {
      dayType,
      time,
      isSliderOpen,
      currentOffSet,
      context,
      measure,
      isNetStateVisible,
      isTypicalVisible,
      trafficCurrentStartTime,
    } = this.props;

    return (
      <div className="traffic-widget-container-when-open uk-flex uk-flex-row">
        <div className="uk-height-1-1 uk-card uk-card-primary uk-flex uk-flex-row uk-flex-middle traffic-widget-card-padding traffic-widget-card traffic-width widget-border-light">
          <div className="uk-width-1-1 uk-flex uk-flex-column uk-flex-start">
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <h6 className="traffic-widget-text">Misura </h6>
              <h6 className="traffic-widget-text-important">
                {measure ? measure.measureName : "-"}
              </h6>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <h6 className="traffic-widget-text">Contesto </h6>
              <h6
                className={`traffic-widget-text-important ${
                  (context && context.value === "Previsto") ||
                  (context && context.value === "Storico") ||
                  (context && context.value === "Tipico")
                    ? "text-underline"
                    : "-"
                }`}
              >
                {context ? context.value : ""}
              </h6>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <h6 className="traffic-widget-text">Tipo-Giorno </h6>
              <h6 className="traffic-widget-text-important">
                {" "}
                {dayType ? dayType.nameIT : ""}{" "}
              </h6>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <h6 className="traffic-widget-text">Ora </h6>
              <h6 className="traffic-widget-text-important">
                {isNetStateVisible
                  ? showContestDateAndHour(
                      trafficCurrentStartTime,
                      time,
                      currentOffSet
                    )
                  : showTypicalDateandHour(time)}
              </h6>
            </div>
          </div>
          <div className="uk-divider-vertical traffic-widget-divider-vertical" />
          <div className="uk-flex uk-flex-middle">
            <button
              className="uk-button uk-button-default uk-flex uk-flex-middle button-hidden"
              onClick={(e) => {
                e.stopPropagation();
                this.handleOpenTimeSlider();
              }}
            >
              <span
                uk-icon={
                  isSliderOpen
                    ? "icon:  triangle-left; ratio: 1.5"
                    : "icon:  triangle-right; ratio: 1.5"
                }
              ></span>
            </button>
          </div>
        </div>
        {/* SLIEDER */}
        {isSliderOpen && isNetStateVisible ? (
          <StateTimeSlider />
        ) : isSliderOpen && isTypicalVisible ? (
          <TypicalTimeSlider />
        ) : null}
      </div>
    );
  }
}

export default TrafficWidget;
