import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { tap, catchError, take } from "rxjs/operators";
import { of } from "rxjs";
import * as moment from "moment";
//COMPONENTS
import NavTab from "components/layout/nav-tab";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import ArcList from "components/layout/arc-list";
import NodeList from "components/layout/node-list";
import CentroidList from "components/layout/centroid-list";
import ArcDetail from "components/layout/arc-list/arc-detail";
import CentroidDetail from "components/layout/centroid-list/centroid-detail";
import ArcEvent from "components/layout/arc-list/arc-event";
import NodeDetail from "components/layout/node-list/node-details";
import BottomControlsIcons from "components/shared/bottom-controls/bottom-controls-icons";
// REDUX
import {
  getNavTabSelectedTab,
  isArcVisible,
  isNodeVisible,
  isCentroidVisible,
  getCurrentArc,
  getCurrentCentroid,
  getCurrentNode,
  getFilteredCentroids,
  getFilteredArc,
  getFilteredNodes,
  getBoundingBox,
  getMapPolygon,
  isOpenNavTab,
  getShortestDistanceOnGraph,
  getCurrentArcMap,
  getCurrentNodeMap,
  getCurrentCentroidMap,
  getNavTabSearch,
  getCurrentZoom,
  getTrafficCurrentDayType,
  isArcsLoading,
  isNodesLoading,
  isCentroidsLoading,
} from "store";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { setCurrentArc, setArcsList } from "reducers/graph/arcs/arcs.actions";
import {
  setCurrentNode,
  setNodesList,
} from "reducers/graph/nodes/nodes.actions";
import {
  setCurrentCentroid,
  setCentroidsList,
} from "reducers/graph/centroids/centroids.actions";

// MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";
//UTILS
import { debounce } from "utils/utils";

class GraphPage extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {
      //TO SWITCH BETWEEN ARC TABS - DETAIL AND EVENT
      onArcEventTab: false,
      dayType: "",
      vehiclePerHours: [],
      currentDate: null,
      openDetailCentroid: false,
    };
    this.setListsDebounced = debounce(this.setLists, 650);
  }

  componentDidMount = () => {
    this.apiService = createApiService(this.context);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      currentArcMap,
      currentNodeMap,
      currentCentroidMap,
      currentCentroid,
      currentDayType,
    } = this.props;
    const { openDetailCentroid } = this.state;

    if (
      prevProps.currentArcMap !== currentArcMap &&
      currentArcMap.length !== 0
    ) {
      this.changeTabs(0);
    }
    if (
      prevProps.currentNodeMap !== currentNodeMap &&
      currentNodeMap &&
      currentNodeMap.properties.id === this.props.currentNode.properties.id
    ) {
      this.changeTabs(1);
    }
    if (
      prevProps.currentCentroidMap !== currentCentroidMap &&
      currentCentroidMap &&
      currentCentroidMap.id === this.props.currentCentroid.id
    ) {
      this.changeTabs(2);
    }
    //FILTER ARC AND NODES BY THEIR RELATIONSHIP
    if (
      prevProps.currentNode !== this.props.currentNode &&
      this.props.currentNode !== null
    ) {
      if (
        this.props.currentNode &&
        this.props.currentNode.properties &&
        this.props.currentNode.id
      ) {
        let filterArcsByNode =
          "a=" +
          this.props.currentNode.id +
          " OR " +
          "b=" +
          this.props.currentNode.id;
        this.getApiRoadSectionsList(null, filterArcsByNode);
      }
    }

    if (
      prevProps.currentArc !== this.props.currentArc &&
      this.props.currentArc !== null
    ) {
      if (
        this.props.currentArc &&
        this.props.currentArc.properties &&
        this.props.currentArc.properties.a &&
        this.props.currentArc.properties.b
      ) {
        let filterNodesByArc =
          "n=" +
          this.props.currentArc.properties.a +
          " OR " +
          "n=" +
          this.props.currentArc.properties.b;
        this.getApiNodesList(null, filterNodesByArc);
      }
    }
    if (
      (currentCentroid &&
        prevProps.currentCentroid &&
        currentCentroid.id !== prevProps.currentCentroid.id) ||
      (!prevProps.currentCentroid &&
        currentCentroid &&
        !prevState.openDetailCentroid &&
        !openDetailCentroid)
    ) {
      let currentDate = new Date();
      let vehicleClassesIds = ["equivalent"];
      let measuresIds = ["flow-IN", "flow-OUT"];

      this.subscriptions.push(
        this.apiService
          .getTrafficCentroidsData(
            currentCentroid.id,
            moment
              .utc(currentDate)
              .minute(0)
              .second(0)
              .millisecond(0)
              .format("HH:mm"),
            currentDayType && currentDayType.enum,
            vehicleClassesIds,
            measuresIds
          )
          .pipe(
            take(1),
            tap((data) => {
              this.setState({
                vehiclePerHours: data.centroids[0].profile[0].measures,
                dayType: currentDayType.nameIT,
                currentDate: currentDate,
              });
            }),
            catchError((error) => {
              console.error(error);
              this.setState({
                currentDate: currentDate,
                dayType: currentDayType.nameIT ? currentDayType.nameIT : "-",
              });
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  changeTabs = (index) => {
    const {
      setNavTabSelectedTab,
      setNavTabSearch,
      menuTabReducer,
    } = this.props;
    //EMPTY SEARCH FROM REDUCER
    setNavTabSearch(
      "",
      menuTabReducer === 0
        ? "archi"
        : menuTabReducer === 1
        ? "nodi"
        : "centroidi",
      null,
      null
    );
    //CHANGE TAB
    setNavTabSelectedTab(index);
  };

  toggleArcDetails = (arc) => {
    const { setCurrentArc } = this.props;
    setCurrentArc({ arc: arc });
  };

  toggleCentroidDetails = (centroid) => {
    const { setCurrentCentroid, currentDayType } = this.props;
    let currentDate = new Date();
    let vehicleClassesIds = ["equivalent"];
    let measuresIds = ["flow-IN", "flow-OUT"];

    if (centroid && centroid.id) {
      this.subscriptions.push(
        this.apiService
          .getTrafficCentroidsData(
            centroid.id,
            moment
              .utc(currentDate)
              .minute(0)
              .second(0)
              .millisecond(0)
              .format("HH:mm"),
            currentDayType && currentDayType.enum,
            vehicleClassesIds,
            measuresIds
          )
          .pipe(
            take(1),
            tap((data) => {
              this.setState({
                vehiclePerHours: data.centroids[0].profile[0].measures,
                openDetailCentroid: true,
                dayType: currentDayType.nameIT,
                currentDate: currentDate,
              });
              setCurrentCentroid({ centroid: centroid });
            }),
            catchError((error) => {
              this.setState({
                openDetailCentroid: true,
                currentDate: currentDate,
                dayType: currentDayType.nameIT ? currentDayType.nameIT : "-",
              });
              setCurrentCentroid({ centroid: centroid });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    } else {
      setCurrentCentroid({ centroid: centroid });
      this.setState({
        openDetailCentroid: false,
      });
    }
  };

  toggleNodeDetails = (node) => {
    const { setCurrentNode } = this.props;
    setCurrentNode({ node: node });
  };

  handleClickArcEvent = () => {
    this.setState({ onArcEventTab: !this.state.onArcEventTab });
  };

  getApiNodesList = (bbox, cqlFilter) => {
    const { setNodesList } = this.props;

    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures("nodes", null, null, null, null, bbox, cqlFilter)
        .pipe(
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "nodes"
            );
            let nodes = [];
            if (data.numberReturned > 0) {
              nodes = [...featureCollection.features];
            }
            setNodesList(nodes);
            return this.setState({
              ...this.state,
              isNodesLoading: false,
            });
          }),
          catchError((error) => {
            console.error(error);
            this.setState({
              ...this.state,
              isNodesLoading: false,
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  getApiRoadSectionsList = (bbox, cqlFilter) => {
    const { setArcsList } = this.props;

    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures(
          "road_sections",
          null,
          null,
          null,
          null,
          bbox,
          cqlFilter
        )
        .pipe(
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "road_sections"
            );
            let road_sections = [];
            if (data.numberReturned > 0) {
              road_sections = [...featureCollection.features];
            }
            setArcsList(road_sections);

            // return this.setState({
            //   ...this.state,
            //   isArcsLoading: false,
            // });
          }),
          catchError((error) => {
            console.error(error);
            // this.setState({
            //   ...this.state,
            //   isArcsLoading: false,
            // });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  handleChangeDate = (dateUpdate) => {
    const { currentCentroid, currentDayType } = this.props;
    const { currentDate } = this.state;

    if (
      currentDate &&
      dateUpdate &&
      moment(currentDate).format("HH") !== moment(dateUpdate).format("HH")
    ) {
      let vehicleClassesIds = ["equivalent"];
      let measuresIds = ["flow-IN", "flow-OUT"];
      this.subscriptions.push(
        this.apiService
          .getTrafficCentroidsData(
            currentCentroid.id,
            moment
              .utc(dateUpdate)
              .minute(0)
              .second(0)
              .millisecond(0)
              .format("HH:mm"),
            currentDayType && currentDayType.enum,
            vehicleClassesIds,
            measuresIds
          )
          .pipe(
            take(1),
            tap((data) => {
              this.setState({
                vehiclePerHours: data.centroids[0].profile[0].measures,
                dayType: currentDayType.nameIT,
                currentDate: dateUpdate,
              });
            }),
            catchError((error) => {
              console.error(error);
              this.setState({
                currentDate: dateUpdate,
                dayType: currentDayType.nameIT ? currentDayType.nameIT : "-",
                vehiclePerHours: [],
              });
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  injectItems = () => {
    const {
      menuTabReducer,
      isArcVisible,
      isNodeVisible,
      isCentroidVisible,
      currentArc,
      currentCentroid,
      currentNode,
      filteredCentroids,
      filteredArcs,
      filteredNodes,
      toggleGenericTable,
      currentArcMap,
      isArcsLoading,
      isNodesLoading,
      isCentroidsLoading,
    } = this.props;

    const { onArcEventTab, dayType, vehiclePerHours, currentDate } = this.state;
    let arcsList = [];
    let centroidsList = [];
    let nodesList = [];

    if (filteredArcs) {
      arcsList = [...filteredArcs];
    }
    if (filteredCentroids) {
      centroidsList = [...filteredCentroids];
    }
    if (filteredNodes) {
      nodesList = [...filteredNodes];
    }

    const lengthArcs = currentArc ? "1" : arcsList.length;
    const lengthNodes = currentNode ? "1" : nodesList.length;
    const lengthCentroids = currentCentroid ? "1" : centroidsList.length;

    const labelArchi = "Archi (" + lengthArcs + ")";
    const labelNodes = "Nodi (" + lengthNodes + ")";
    const labelCentroids = "Centroidi (" + lengthCentroids + ")";

    let controlsList = [];
    if (
      menuTabReducer === 0 &&
      (currentArc === null || currentArc === undefined)
    ) {
      controlsList.push({
        icon: "acr-interface-list",
        tooltip: "Tabella Archi",
        className: "table-icon",
        toggle: () => toggleGenericTable("arcsTable"),
      });
    }

    return (
      <div className="uk-height-1-1 uk-width-1-1 inherit-transition uk-position-relative">
        <ErrorBoundary>
          <TabPanel className="tabsContainerTpl inherit-transition">
            <ul className="uk-tab uk-flex-between">
              {isArcVisible ? (
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeTabs(0);
                  }}
                  className={menuTabReducer === 0 ? "uk-active" : ""}
                >
                  <a href="/#">{labelArchi}</a>
                </li>
              ) : null}
              {isNodeVisible ? (
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeTabs(1);
                  }}
                  className={menuTabReducer === 1 ? "uk-active" : ""}
                >
                  <a href="/#">{labelNodes}</a>
                </li>
              ) : null}
              {isCentroidVisible ? (
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeTabs(2);
                  }}
                  className={menuTabReducer === 2 ? "uk-active" : ""}
                >
                  <a href="/#">{labelCentroids}</a>
                </li>
              ) : null}
            </ul>
            {isArcVisible ? (
              <TabPanel
                className="overflow inherit-transition uk-height-1-1"
                value={menuTabReducer}
                index={0}
              >
                <ErrorBoundary>
                  {currentArc && !onArcEventTab ? (
                    <ArcDetail
                      toggleArcDetails={this.toggleArcDetails}
                      handleClickSecondTab={this.handleClickArcEvent}
                      currentArc={currentArc}
                      secondTab={"Eventi"}
                    />
                  ) : currentArc && onArcEventTab ? (
                    <ArcEvent
                      toggleArcDetails={this.toggleArcDetails}
                      handleClickSecondTab={this.handleClickArcEvent}
                      currentArc={currentArc}
                    />
                  ) : (
                    <ArcList
                      arcsList={arcsList}
                      toggleArcDetails={this.toggleArcDetails}
                      isArcsLoading={isArcsLoading}
                      currentArcMap={currentArcMap}
                      arcListType={"graph"}
                    />
                  )}
                </ErrorBoundary>
              </TabPanel>
            ) : null}
            {isNodeVisible ? (
              <TabPanel className="overflow" value={menuTabReducer} index={1}>
                <ErrorBoundary>
                  {!currentNode ? (
                    <NodeList
                      nodesList={nodesList}
                      toggleNodeDetails={this.toggleNodeDetails}
                      isNodesLoading={isNodesLoading}
                    />
                  ) : (
                    <NodeDetail
                      toggleNodeDetails={this.toggleNodeDetails}
                    ></NodeDetail>
                  )}
                </ErrorBoundary>
              </TabPanel>
            ) : null}
            {isCentroidVisible ? (
              <TabPanel className="overflow" value={menuTabReducer} index={2}>
                <ErrorBoundary>
                  {currentCentroid ? (
                    <CentroidDetail
                      toggleCentroidDetails={this.toggleCentroidDetails}
                      dayType={dayType}
                      vehiclePerHours={vehiclePerHours}
                      currentDate={currentDate}
                      handleChangeDate={this.handleChangeDate}
                    />
                  ) : (
                    <CentroidList
                      centroidsList={centroidsList}
                      toggleCentroidDetails={this.toggleCentroidDetails}
                      isCentroidsLoading={isCentroidsLoading}
                    />
                  )}
                </ErrorBoundary>
              </TabPanel>
            ) : null}
          </TabPanel>
        </ErrorBoundary>
        <BottomControlsIcons controls={controlsList} />
      </div>
    );
  };

  handleSearch = (value) => {
    const { setNavTabSearch, menuTabReducer } = this.props;

    setNavTabSearch(
      value,
      menuTabReducer === 0
        ? "archi"
        : menuTabReducer === 1
        ? "nodi"
        : "centroidi",
      null,
      null
    );
  };

  render() {
    const { menuTabReducer } = this.props;
    return (
      <NavTab
        isOpen={true}
        items={this.injectItems()}
        onSearch={this.handleSearch}
        currentTab={
          menuTabReducer === 0
            ? "archi"
            : menuTabReducer === 1
            ? "nodi"
            : "centroidi"
        }
      />
    );
  }

  componentWillUnmount() {
    const { setNavTabSearch, menuTabReducer } = this.props;
    this.subscriptions.forEach((x) => x.unsubscribe());
    setNavTabSearch(
      "",
      menuTabReducer === 0
        ? "archi"
        : menuTabReducer === 1
        ? "nodi"
        : "centroidi",
      null,
      null
    );
  }
}

GraphPage.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  setCurrentArc,
  setCurrentCentroid,
  setCurrentNode,
  setCentroidsList,
  setNodesList,
  setArcsList,
  toggleOpenNavTab,
  toggleGenericTable,
};

const mapStateToProps = (state) => ({
  menuTabReducer: getNavTabSelectedTab(state),
  isArcVisible: isArcVisible(state),
  isNodeVisible: isNodeVisible(state),
  isCentroidVisible: isCentroidVisible(state),
  currentArc: getCurrentArc(state),
  currentArcMap: getCurrentArcMap(state),
  currentNodeMap: getCurrentNodeMap(state),
  currentCentroidMap: getCurrentCentroidMap(state),
  currentCentroid: getCurrentCentroid(state),
  currentNode: getCurrentNode(state),
  filteredCentroids: getFilteredCentroids(state),
  filteredArcs: getFilteredArc(state),
  filteredNodes: getFilteredNodes(state),
  boundingBox: getBoundingBox(state),
  mapPolygonReducer: getMapPolygon(state),
  isOpenNavTab: isOpenNavTab(state),
  shortestDistanceOnGraphReducer: getShortestDistanceOnGraph(state),
  menuNavTabSearch: getNavTabSearch(state),
  currentMapZoom: getCurrentZoom(state),
  currentDayType: getTrafficCurrentDayType(state),
  //LOADING
  isArcsLoading: isArcsLoading(state),
  isNodesLoading: isNodesLoading(state),
  isCentroidsLoading: isCentroidsLoading(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GraphPage)
);
