import React, { Component } from "react";
import "./style.less";
import { getWorseStationSensorStateDetail } from "utils/utils";
import { getSelectModal } from "store";
import { withRouter } from "react-router";
import { connect } from "react-redux";

//IMG
import stations_img_red from "assets/icon/multitab-icns/Tab_StazioniMisuraRed.svg";
import stations_img_green from "assets/icon/multitab-icns/Tab_StazioniMisuraGreen.svg";
import stations_img_gray from "assets/icon/multitab-icns/Tab_StazioniMisuraGrey.svg";

class StationTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    this.getWidthHeight();
  }

  getWidthHeight = () => {
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ ...this.state, height: height, width: width });
  };

  render() {
    const { x, y, object } = this.props;
    const { height, width } = this.state;
    let stateSensor = getWorseStationSensorStateDetail(object);

    return (
      <div
        className="show-tooltip"
        style={{ left: x - width / 2, top: y - (height + 20) }}
        ref={(divElement) => {
          this.divElement = divElement;
        }}
      >
        <div className="uk-flex uk-flex-column uk-margin-small-top">
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              alt=""
              style={{ width: "40px", height: "40px" }}
              src={
                stateSensor && stateSensor === "NO_INFO"
                  ? stations_img_gray
                  : stateSensor === "DOWN"
                  ? stations_img_red
                  : stations_img_green
              }
            />
          </div>
          <div className="tooltip-text-important uk-flex uk-flex-center">
            STAZIONE DI MISURA
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
            <div className="tooltip-text-large">Descrizione: </div>
            <div className="tooltip-text-important">
              {" "}
              {object && object.archName && object.directionName
                ? object.archName + " direzione " + object.directionName
                : object && object.archName
                ? object.archName
                : "-"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  selectModal: getSelectModal(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StationTooltip)
);
