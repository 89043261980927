import { CompositeLayer } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
import Supercluster from "supercluster";
import parking_cluster from "assets/icon/parking/parking_cluster.svg";

function getIconName(size) {
  if (size === 0) {
    return "";
  }
  if (size < 10) {
    return `marker-${size}`;
  }
  if (size < 300) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  return "marker-300";
}

// function getIconSize(size) {
//   return Math.min(100, size) / 100 + 1;
// }

class IconClusterParkingLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 18, radius: 18 });
      index.load(
        props.data.map((d) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d.properties,
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== "hover") {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    const { iconAtlas, iconMapping, sizeScale } = this.props;

    const ICON_PARKING_MAPPING = {
      marker: {
        x: 0,
        y: 0,
        width: 1200,
        height: 1200,
        mask: false,
        anchorY: 500,
        anchorX: 100,
      },
    };

    return [
      new IconLayer(
        this.getSubLayerProps({
          id: "parking-icon",
          data,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: parking_cluster,
          iconMapping: ICON_PARKING_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "number-icon",
          data,
          iconAtlas,
          iconMapping,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) =>
            getIconName(d.properties.cluster ? d.properties.point_count : 1),
          getSize: (d) => 8,
        })
      ),
    ];
  }
}

IconClusterParkingLayer.layerName = "IconClusterParkingLayer";

export default IconClusterParkingLayer;
