import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//ACTIONS
import {
  getTypeModal,
  getCurrentEvent,
  getNewEvent,
  getSelectModal,
} from "store";
import { setTypeModal } from "reducers/ui/modal/modal.actions";
//UTIL
import { checkEventEnableUpdatesBySource } from "utils/utils";

class LeftComponentIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setTypeModal = (type) => {
    const { setTypeModal } = this.props;
    setTypeModal(type);
  };

  eventsLeftIcon = () => {
    const { typeModal, currentEvent, newEvent, selectModal } = this.props;

    return (
      <div className="left-list-container uk-flex uk-flex-column uk-flex-middle">
        <div
          onClick={(e) => {
            e.preventDefault();
            if (currentEvent && currentEvent.situationId) {
              this.setTypeModal("newEvent");
            }
          }}
          className={
            (typeModal === "newEvent"
              ? "icon-selected "
              : "icon-not-selected ") +
            (currentEvent && currentEvent.situationId
              ? "margin-top-medium uk-flex uk-flex-center uk-flex-middle cursor-pointer"
              : "margin-top-medium uk-flex uk-flex-center uk-flex-middle cursor-disabled")
          }
        >
          <span
            uk-tooltip="title: Inserisci Nuovo Evento"
            className="uk-icon"
            uk-icon="icon: acr-event-nuovo-evento"
          ></span>
        </div>
        <div
          onClick={(e) => {
            e.preventDefault();
            if (currentEvent) {
              this.setTypeModal("addAssociatedEvent");
            }
          }}
          className={
            (typeModal === "addAssociatedEvent"
              ? "icon-selected "
              : "icon-not-selected ") +
            (currentEvent
              ? "margin-top-medium uk-flex uk-flex-center uk-flex-middle cursor-pointer"
              : "margin-top-medium uk-flex uk-flex-center uk-flex-middle cursor-disabled")
          }
        >
          <span
            uk-tooltip="title: Aggiungi Evento Associato"
            className="uk-icon"
            uk-icon="icon: acr-event-nuova-pratica"
          ></span>
        </div>
        <div
          onClick={(e) => {
            e.preventDefault();
            if (
              currentEvent &&
              selectModal &&
              selectModal.sources &&
              checkEventEnableUpdatesBySource(currentEvent, selectModal.sources)
            ) {
              this.setTypeModal("modifyEvent");
            }
          }}
          className={
            (typeModal === "modifyEvent"
              ? "icon-selected "
              : "icon-not-selected ") +
            (currentEvent &&
            selectModal &&
            selectModal.sources &&
            checkEventEnableUpdatesBySource(currentEvent, selectModal.sources)
              ? "margin-top-medium uk-flex uk-flex-center uk-flex-middle cursor-pointer"
              : "margin-top-medium uk-flex uk-flex-center uk-flex-middle cursor-disabled")
          }
        >
          <span
            uk-tooltip="title: Modifica Evento"
            className="uk-icon"
            uk-icon="icon: acr-event-modifica-evento"
          ></span>
        </div>
        <div
          onClick={(e) => {
            e.preventDefault();
            if (
              currentEvent &&
              currentEvent.situationId &&
              currentEvent.situationRecordId &&
              newEvent &&
              newEvent.associatedEvents &&
              newEvent.associatedEvents.find(
                (item) =>
                  currentEvent.situationRecordId === item.situationRecordId
              ) &&
              selectModal &&
              selectModal.sources &&
              checkEventEnableUpdatesBySource(currentEvent, selectModal.sources)
            ) {
              this.setTypeModal("practiceManagement");
            }
          }}
          className={
            (typeModal === "practiceManagement"
              ? "icon-selected "
              : "icon-not-selected ") +
            (currentEvent &&
            currentEvent.situationId &&
            newEvent &&
            newEvent.associatedEvents &&
            newEvent.associatedEvents.find(
              (item) =>
                currentEvent.situationRecordId === item.situationRecordId
            ) &&
            selectModal &&
            selectModal.sources &&
            checkEventEnableUpdatesBySource(currentEvent, selectModal.sources)
              ? "margin-top-medium uk-flex uk-flex-center uk-flex-middle cursor-pointer"
              : "margin-top-medium uk-flex uk-flex-center uk-flex-middle cursor-disabled")
          }
        >
          <span
            uk-tooltip="title: Gestione Pratica"
            className="uk-icon"
            uk-icon="icon: acr-event-chiudi-pratica"
          ></span>
        </div>
      </div>
    );
  };

  render() {
    return this.eventsLeftIcon();
  }
}

const mapDispatchToProps = { setTypeModal };

const mapStateToProps = (state) => ({
  typeModal: getTypeModal(state),
  currentEvent: getCurrentEvent(state),
  newEvent: getNewEvent(state),
  selectModal: getSelectModal(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftComponentIcons)
);
