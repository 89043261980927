import React, { Component } from "react";
import "./style.less";
import * as moment from "moment";
import Slider from "@material-ui/core/Slider";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import { withStyles } from "@material-ui/core/styles";
import {
  setCurrentContextTimeOffSet,
  setCurrentTrafficTime,
  setTrafficCurrentContext,
  setCurrentTrafficStartTime,
} from "reducers/traffic/traffic.actions";
import {
  getTrafficContexts,
  getTrafficCurrentTime,
  getCurrentTimeOffSet,
  isNetStateVisible,
  getTrafficCurrentStartTime,
} from "store";
//UTILS
import {
  getContestoReteToShow,
  getDeltaTimeOffSet,
  months,
  trafficSliderMarks,
} from "utils/utils-traffic";

class StateTimeSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDate: null,
      dateOneHourBack: null,
      dateOneHourForward: null,
      valueDisplay: 0,
      timePickerIsOpen: false,
      currentDateCopy: null,
      initialDate: null,
    };
  }

  componentDidMount = () => {
    const { currentOffSet, currentTime } = this.props;

    let newDateMoreHour = new Date(currentTime.getTime() + 1 * 60 * 60 * 1000);
    let newDateLessHour = new Date(currentTime.getTime() - 1 * 60 * 60 * 1000);

    this.setState({
      currentDate: currentTime,
      currentDateCopy: currentTime,
      dateOneHourForward: newDateMoreHour,
      dateOneHourBack: newDateLessHour,
      valueDisplay: currentOffSet,
      initialDate: currentTime,
    });

    const slider = document.getElementById("state-slider");
    slider.value = currentOffSet;
  };

  componentDidUpdate = (prevProps) => {
    const { currentTime, trafficCurrentStartTime } = this.props;

    if (prevProps.currentTime !== currentTime) {
      this.setState({
        ...this.state,
        currentDate: currentTime,
        currentDateCopy: currentTime,
      });
    }

    if (prevProps.trafficCurrentStartTime !== trafficCurrentStartTime) {
      let newDateMoreHour = new Date(
        trafficCurrentStartTime.getTime() + 1 * 60 * 60 * 1000
      );
      let newDateLessHour = new Date(
        trafficCurrentStartTime.getTime() - 1 * 60 * 60 * 1000
      );
      this.setState({
        ...this.state,
        dateOneHourForward: newDateMoreHour,
        dateOneHourBack: newDateLessHour,
        initialDate: trafficCurrentStartTime,
      });
    }
  };

  timeChangeHandlerBack = () => {
    const { valueDisplay, currentDate } = this.state;
    const {
      setCurrentContextTimeOffSet,
      setTrafficCurrentContext,
      setCurrentTrafficTime,
      trafficCurrentStartTime,
    } = this.props;

    if (valueDisplay > -60) {
      let currentDateMinus = new Date(
        currentDate.getTime() - 0.25 * 60 * 60 * 1000
      );
      this.setState({
        valueDisplay: valueDisplay - 15,
        currentDate: currentDateMinus,
      });
      setCurrentContextTimeOffSet(valueDisplay - 15);
      setCurrentTrafficTime(currentDateMinus);

      const contestoRete = getContestoReteToShow(
        trafficCurrentStartTime,
        currentDateMinus
      );
      setTrafficCurrentContext(contestoRete);
    }
  };

  timeChangeHandlerForward = () => {
    const { valueDisplay, currentDate } = this.state;
    const {
      setCurrentContextTimeOffSet,
      setTrafficCurrentContext,
      setCurrentTrafficTime,
      trafficCurrentStartTime,
    } = this.props;

    if (valueDisplay < 60) {
      let currentDatePlus = new Date(
        currentDate.getTime() + 0.25 * 60 * 60 * 1000
      );

      this.setState({
        valueDisplay: valueDisplay + 15,
        currentDate: currentDatePlus,
      });
      setCurrentContextTimeOffSet(valueDisplay + 15);
      setCurrentTrafficTime(currentDatePlus);

      const contestoRete = getContestoReteToShow(
        trafficCurrentStartTime,
        currentDatePlus
      );
      setTrafficCurrentContext(contestoRete);
    }
  };

  handleChange = (event, newValue) => {
    const { valueDisplay, initialDate } = this.state;
    const {
      setCurrentContextTimeOffSet,
      setTrafficCurrentContext,
      setCurrentTrafficTime,
      trafficCurrentStartTime,
    } = this.props;

    let deltaTime = getDeltaTimeOffSet(newValue);

    if (newValue < 0 && newValue < valueDisplay && valueDisplay !== newValue) {
      let currentDateMinus = new Date(
        initialDate.getTime() - deltaTime * 60 * 60 * 1000
      );
      this.setState({
        valueDisplay: newValue,
        currentDate: currentDateMinus,
      });
      setCurrentContextTimeOffSet(newValue);
      setCurrentTrafficTime(currentDateMinus);

      const contestoRete = getContestoReteToShow(
        trafficCurrentStartTime,
        currentDateMinus
      );
      setTrafficCurrentContext(contestoRete);
    } else if (
      newValue < 0 &&
      newValue > valueDisplay &&
      valueDisplay !== newValue
    ) {
      let currentDatePlus = new Date(
        initialDate.getTime() - deltaTime * 60 * 60 * 1000
      );
      this.setState({
        valueDisplay: newValue,
        currentDate: currentDatePlus,
      });
      setCurrentContextTimeOffSet(newValue);
      setCurrentTrafficTime(currentDatePlus);

      const contestoRete = getContestoReteToShow(
        trafficCurrentStartTime,
        currentDatePlus
      );
      setTrafficCurrentContext(contestoRete);
    } else if (
      newValue > 0 &&
      newValue > valueDisplay &&
      valueDisplay !== newValue
    ) {
      let currentDatePlus = new Date(
        initialDate.getTime() + deltaTime * 60 * 60 * 1000
      );
      this.setState({
        valueDisplay: newValue,
        currentDate: currentDatePlus,
      });
      setCurrentContextTimeOffSet(newValue);
      setCurrentTrafficTime(currentDatePlus);

      const contestoRete = getContestoReteToShow(
        trafficCurrentStartTime,
        currentDatePlus
      );
      setTrafficCurrentContext(contestoRete);
    } else if (
      newValue > 0 &&
      newValue < valueDisplay &&
      valueDisplay !== newValue
    ) {
      let currentDateMinus = new Date(
        initialDate.getTime() + deltaTime * 60 * 60 * 1000
      );
      this.setState({
        valueDisplay: newValue,
        currentDate: currentDateMinus,
      });
      setCurrentContextTimeOffSet(newValue);
      setCurrentTrafficTime(currentDateMinus);

      const contestoRete = getContestoReteToShow(
        trafficCurrentStartTime,
        currentDateMinus
      );
      setTrafficCurrentContext(contestoRete);
    } else if (newValue === 0 && valueDisplay > 0) {
      this.setState({
        valueDisplay: newValue,
        currentDate: initialDate,
      });
      setCurrentContextTimeOffSet(newValue);
      setCurrentTrafficTime(initialDate);

      const contestoRete = getContestoReteToShow(
        trafficCurrentStartTime,
        initialDate
      );
      setTrafficCurrentContext(contestoRete);
    } else if (newValue === 0 && valueDisplay < 0) {
      this.setState({
        valueDisplay: newValue,
        currentDate: initialDate,
      });
      setCurrentContextTimeOffSet(newValue);
      setCurrentTrafficTime(initialDate);

      const contestoRete = getContestoReteToShow(
        trafficCurrentStartTime,
        initialDate
      );
      setTrafficCurrentContext(contestoRete);
    }
  };

  clockHandler = () => {
    const { timePickerIsOpen } = this.state;
    this.setState({
      timePickerIsOpen: !timePickerIsOpen,
    });
  };

  setDate = (date) => {
    const {
      setTrafficCurrentContext,
      trafficCurrentStartTime,
      setCurrentTrafficTime,
      setCurrentContextTimeOffSet,
    } = this.props;
    const { valueDisplay } = this.state;

    let newDateMoreHour = new Date(date.getTime() + 1 * 60 * 60 * 1000);
    let newDateLessHour = new Date(date.getTime() - 1 * 60 * 60 * 1000);
    let newDate;
    let deltaTime = getDeltaTimeOffSet(valueDisplay);
    if (valueDisplay > 0) {
      newDate = new Date(date.getTime() + deltaTime * 60 * 60 * 1000);
    } else if (valueDisplay < 0) {
      newDate = new Date(date.getTime() - deltaTime * 60 * 60 * 1000);
    } else {
      newDate = date;
    }

    setCurrentTrafficTime(date);
    setCurrentContextTimeOffSet(0);

    this.setState({
      currentDate: newDate,
      currentDateCopy: newDate,
      dateOneHourForward: newDateMoreHour,
      dateOneHourBack: newDateLessHour,
      initialDate: newDate,
      valueDisplay: 0,
    });

    const contestoRete = getContestoReteToShow(
      trafficCurrentStartTime,
      newDate
    );
    setTrafficCurrentContext(contestoRete);
  };

  realTimeClick = () => {
    const {
      setCurrentTrafficTime,
      setCurrentContextTimeOffSet,
      setTrafficCurrentContext,
      setCurrentTrafficStartTime,
    } = this.props;

    const newDate = new Date();
    const nowMoment = moment(newDate);

    //FROM CONFIG FILE
    const minutesOffset = this.context.minutesOffsetForCurrentTraffic;

    //ROUND TO QUARTER OF HOUR
    const roundedDownMinute = Math.floor(nowMoment.minute() / 15) * 15;
    const roundedDownMoment = nowMoment.clone();
    roundedDownMoment.minute(roundedDownMinute).second(0).millisecond(0);

    //IF THE DIFFERNECE BETWEEN NOW AND START OF QUARTER IS TOO SMALL, TAKE PREVIOUS QUARTER
    const duration = moment.duration(nowMoment.diff(roundedDownMoment));
    const minutes = duration.asMinutes();

    let newTrafficTimeAndDate;
    if (minutes >= minutesOffset) {
      newTrafficTimeAndDate = roundedDownMoment.toDate();
      newTrafficTimeAndDate = roundedDownMoment.toDate();
    } else {
      const newSubtractedMoment = nowMoment.clone();
      newSubtractedMoment.subtract(minutesOffset, "minutes");
      const newRoundedDownMinute =
        Math.floor(newSubtractedMoment.minute() / 15) * 15;
      const newRoundedDownMoment = nowMoment.clone();
      newRoundedDownMoment
        .hour(newSubtractedMoment.hour())
        .minute(newRoundedDownMinute)
        .second(0)
        .millisecond(0);
      newTrafficTimeAndDate = newRoundedDownMoment.toDate();
    }
    setCurrentTrafficStartTime(newTrafficTimeAndDate);
    setCurrentTrafficTime(newTrafficTimeAndDate);
    setCurrentContextTimeOffSet(0);

    const newDateMoreHour = new Date(
      newTrafficTimeAndDate.getTime() + 1 * 60 * 60 * 1000
    );
    const newDateLessHour = new Date(
      newTrafficTimeAndDate.getTime() - 1 * 60 * 60 * 1000
    );

    this.setState({
      currentDate: newTrafficTimeAndDate,
      currentDateCopy: newTrafficTimeAndDate,
      dateOneHourForward: newDateMoreHour,
      dateOneHourBack: newDateLessHour,
      valueDisplay: 0,
      initialDate: newTrafficTimeAndDate,
    });

    const contestoRete = getContestoReteToShow(
      newTrafficTimeAndDate,
      newTrafficTimeAndDate
    );
    setTrafficCurrentContext(contestoRete);
  };

  render() {
    const {
      valueDisplay,
      currentDate,
      dateOneHourForward,
      dateOneHourBack,
    } = this.state;
    const { currentTime } = this.props;

    const SliderCustom = withStyles({
      root: {
        height: 2,
        padding: "15px 0",
      },
      thumb: {
        height: 28,
        width: 28,
        backgroundColor: "yellow",
        marginTop: -14,
        marginLeft: -14,
        color: "yellow",
      },
      active: {},
      valueLabel: {
        left: "calc(-170% + 20px)",
        top: -13,
        "& *": {
          background: "transparent",
          color: "white",
          position: "absolute",
          width: "100px",
        },
      },
      track: {
        height: 2,
        backgroundImage: "linear-gradient(.30turn, yellow,yellow)",
      },
      rail: {
        height: 2,
        opacity: 4,
        backgroundImage: "linear-gradient(.30turn, yellow,yellow)",
      },
      mark: {
        height: 8,
        width: 1,
        marginTop: -3,
      },
      markActive: {
        backgroundColor: "currentColor",
      },
      markLabelActive: {
        color: "white",
        fontSize: "0.800rem",
      },
      markLabel: {
        color: "white",
        fontSize: "0.800rem",
      },
    })(Slider);
    const years = [
      moment().year() - 2,
      moment().year() - 1,
      moment().year(),
      moment().year() + 1,
      moment().year() + 2,
    ];

    return (
      <div className="uk-card uk-card-primary uk-animation-slide-left-medium scale-container-traffic-slider widget-border-light">
        <div className="container-buttons">
          <div className="date-back">
            {moment(dateOneHourBack).format("HH:mm, DD MMMM YYYY")}
          </div>
          <div className="clock-draw cursor-pointer">
            <DatePicker
              selected={currentDate}
              onChange={(date) => this.setDate(date)}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              timeFormat="HH:mm"
              showTimeSelect
              timeIntervals={15}
              customInput={
                <span
                  className="uk-icon-link uk-icon" //"clock-draw cursor-pointer"
                  uk-icon="icon: clock"
                  onClick={this.clockHandler}
                ></span>
              }
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {"<"}
                  </button>
                  <select
                    value={moment(date).year()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    value={months[moment(date).month()]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {">"}
                  </button>
                  <button onClick={this.realTimeClick}>Real Time</button>
                </div>
              )}
            />
          </div>
          <span
            className="uk-icon-link uk-icon clock-draw-left cursor-pointer"
            uk-icon="icon: chevron-left"
            onClick={this.timeChangeHandlerBack}
          ></span>
          <span
            className="uk-icon-link uk-icon clock-draw-right cursor-pointer"
            uk-icon="icon: chevron-right"
            onClick={this.timeChangeHandlerForward}
          ></span>
          <div className="date-forward">
            {moment(dateOneHourForward).format("HH:mm, DD MMMM YYYY")}
          </div>{" "}
        </div>
        <div className="time-width">
          <div className="time-slider">
            <SliderCustom
              defaultValue={0}
              valueLabelFormat={
                moment(currentTime).format("HH:mm") +
                " - " +
                moment(
                  new Date(
                    currentTime && currentTime.getTime() + 0.25 * 60 * 60 * 1000
                  )
                ).format("HH:mm")
              }
              aria-labelledby="discrete-slider-custom"
              step={15}
              marks={trafficSliderMarks}
              min={-60}
              max={60}
              onChange={this.handleChange}
              value={valueDisplay}
              valueLabelDisplay="on"
              id="state-slider"
            />
          </div>
        </div>
      </div>
    );
  }
}
StateTimeSlider.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setCurrentContextTimeOffSet,
  setCurrentTrafficTime,
  setTrafficCurrentContext,
  setCurrentTrafficStartTime,
};

const mapStateToProps = (state) => ({
  contexts: getTrafficContexts(state),
  currentTime: getTrafficCurrentTime(state),
  currentOffSet: getCurrentTimeOffSet(state),
  isNetStateVisible: isNetStateVisible(state),
  trafficCurrentStartTime: getTrafficCurrentStartTime(state),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StateTimeSlider)
);
