export const CentroidsActions = {
  SET_LIST_CENTROIDS: "GRAPH/SET_LIST_CENTROIDS",
  SET_CURRENT_CENTROID: "GRAPH/SET_CURRENT_CENTROID",
  SET_CURRENT_CENTROID_MAP: "GRAPH/SET_CURRENT_CENTROID_MAP",
  RESET_CURRENT_CENTROID_MAP: "GRAPH/RESET_CURRENT_CENTROID_MAP",
};

export const setCentroidsList = (list) => (dispatch) => {
  dispatch({
    type: CentroidsActions.SET_LIST_CENTROIDS,
    payload: { list },
  });
};

export const setCurrentCentroid = (current) => (dispatch) => {
  dispatch({
    type: CentroidsActions.SET_CURRENT_CENTROID,
    payload: current,
  });
};

export const setCurrentCentroidMap = (currentMap) => (dispatch) => {
  dispatch({
    type: CentroidsActions.SET_CURRENT_CENTROID_MAP,
    payload: currentMap,
  });
};

export const resetCurrentCentroidMap = () => (dispatch) => {
  dispatch({
    type: CentroidsActions.RESET_CURRENT_CENTROID_MAP,
  });
};
