import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  take,
  tap,
  catchError,
  switchMap,
  debounceTime,
  concatMap,
} from "rxjs/operators";
import { of } from "rxjs";
import * as moment from "moment";
import "../style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import { BehaviorSubject, Observable } from "rxjs";
import "./style.less";
//COMPONENTS
import Loading from "components/shared/loading/loading";
import TableHead from "components/shared/table-components/table-head";
import TableBody from "./table-body";
import TableFilters from "components/shared/table-top-filters/table-filters";
import PageNumbers from "components/shared/page-numbers/page-number";
import TableUpperTabs from "components/layout/tables/pmv-table/table-upper-tabs";
//STORE
import {
  isOpenNavTab,
  getSavedTableColumns,
  getSavedDateColumns,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedSearchText,
  getSavedFilters,
  getSavedToggledFilters,
  getPmvHistoryTableOpen,
  getUsername,
  getUserRoles,
} from "store";
import { togglePmvHistoryTable } from "reducers/ui/installations-menu/installations-menu.actions";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { addActivity } from "reducers/user/user.actions";
//UTILS
import {
  TABLE_COLUMNS,
  DATE_COLUMNS,
  initColumns,
} from "utils/table-utils/util-pmv-table";
import * as FilterUtils from "utils/filter-utils";
import { debounce } from "utils/utils";
import { getFiltersForPmvTable } from "utils/utils-pmv";
import {
  handleFilterStringChange,
  handleClickIncreasePage,
  handleClickDecreasePage,
  requestSortOrder,
  resetChildrenForHeaderDropdown,
  onDropdownCheckboxFilterClick,
  getCurrentPageNumber,
  resetFiltersState,
  initializeTableState,
  getTableStateSortConfig,
} from "utils/table-utils/util-general-tables";
import { saveBlob } from "utils/hooks";
//MODELS
import { PmvAnagraficaAndStatusModel } from "reducers/installations/pmv/pmv.model";
import { UserActivityModel } from "reducers/user/user.model";

const FILTER_TARGET = "pmvFilters";

class PmvListTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      ...initializeTableState,
      sortConfig: getTableStateSortConfig("pmv"),
      stateTableColumns: TABLE_COLUMNS,
      currentListPmvs: [],
    };
    this.getPmvListDebounced = debounce(this.getPmvList, 800);
    this.numberPage$ = new BehaviorSubject();
  }

  componentDidMount = () => {
    const { searchText } = this.props;
    this.apiService = createApiService(this.context);

    this.subscriptions.push(
      this.numberPage$
        .pipe(
          debounceTime(800),
          switchMap(
            (x) =>
              new Observable((observer) => {
                const { pageToDisplay } = this.state;
                if (pageToDisplay !== "") {
                  this.getPmvList();
                }
              })
          )
        )
        .subscribe()
    );

    if (searchText) {
      this.setState({
        ...this.state,
        filterStr: searchText,
        isTableDataLoading: true,
      });
    } else {
      this.setState({
        ...this.state,
        isTableDataLoading: true,
      });
    }
    this.tableConfigs();
  };

  tableConfigs = () => {
    const {
      tableColumns,
      dateColumns,
      saveTableFilters,
      searchText,
    } = this.props;

    const { columns, date } = initColumns(tableColumns, dateColumns);
    let sld = FilterUtils.constructFlatMultiSelect(columns);
    let obj = {};
    sld.forEach((x) => {
      obj[x.name] = { ...x };
    });
    saveTableFilters(
      {
        tableColumns: columns,
        dateColumns: date,
        dateFrom: null,
        dateTo: null,
        searchText: searchText || "",
        toggledFilters: obj || {},
      },
      FILTER_TARGET
    );
    this.setState({
      selected: sld,
      searchText: searchText || "",
    });
  };

  getPmvList = () => {
    const {
      count,
      currentPage,
      sortConfig,
      filterStr,
      stateTableColumns,
    } = this.state;

    const body = getFiltersForPmvTable(
      currentPage,
      count,
      stateTableColumns,
      sortConfig,
      filterStr
    );

    const jwt = sessionStorage.getItem("id_token");

    this.subscriptions.push(
      this.apiService
        .getPmvListStatus(body, jwt)
        .pipe(
          take(1),
          tap((data) => {
            let pmvs = PmvAnagraficaAndStatusModel.fromArray([...data.stati]);
            let pageMax = Math.ceil(data.totalHits / count);

            this.setState({
              ...this.state,
              isTableDataLoading: false,
              currentListPmvs: pmvs,
              pageMax: pageMax,
            });
          }),
          catchError((error) => {
            console.error(error);
            this.setState({
              ...this.state,
              isTableDataLoading: false,
              currentListPmvs: [],
              pageMax: 1,
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  //CALL API TO OBTAIN NEW ARC LIST
  refreshData = () => {
    this.setState({
      ...this.state,
      currentListPmvs: [],
      isTableDataLoading: true,
    });
    this.getPmvList();
  };

  downloadExcel = () => {
    const {
      count,
      sortConfig,
      currentPage,
      filterStr,
      stateTableColumns,
    } = this.state;

    const body = getFiltersForPmvTable(
      currentPage,
      count,
      stateTableColumns,
      sortConfig,
      filterStr
    );

    this.subscriptions.push(
      this.apiService
        .fetchPmvListExcel(body)
        .pipe(
          take(1),
          tap((blob) => {
            let filename =
              "pmv_resoconto_export_" +
              moment().format("DD-MM-YYYY HH:mm:ss") +
              ".csv";
            saveBlob(blob, filename);
          }),
          concatMap((data) => {
            return this.postPmvListDownloadActivity(data);
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  postPmvListDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "VMS",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Pannelli Resoconto"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  handleFilterChange = (e) => {
    handleFilterStringChange(this, e, FILTER_TARGET, this.getPmvListDebounced);
  };

  // GESTIONE CHECKBOX: VISUALIZZAZIONE COLONNE
  handleSelectChange = (visibleColumns) => {
    FilterUtils.handleSelectChange(this, visibleColumns, FILTER_TARGET);
  };

  //GESTIONE CHECKBOX FILTRI SU COLONNE
  onCheckboxFilterClick = (newTableColumns) => {
    this.setState({
      ...this.state,
      isTableDataLoading: true,
    });
    onDropdownCheckboxFilterClick(
      this,
      this.getPmvList,
      FILTER_TARGET,
      newTableColumns
    );
  };

  getPageNumber = (pageNumber) => {
    getCurrentPageNumber(this, pageNumber);
  };

  handleClickIncrease = () => {
    handleClickIncreasePage(this);
  };

  handleClickDecrease = () => {
    handleClickDecreasePage(this);
  };

  requestSort = (key) => {
    requestSortOrder(this, key, this.getPmvList);
  };

  resetFilters = (e) => {
    const { resetTableFilters, toggledFilters } = this.props;
    const { selected, stateTableColumns } = this.state;

    e.stopPropagation();

    let newStateTableColumns = resetChildrenForHeaderDropdown(
      stateTableColumns
    );

    resetTableFilters(
      {
        tableColumns: { ...newStateTableColumns },
        dateColumns: [...DATE_COLUMNS],
        toggledFilters: toggledFilters,
      },
      FILTER_TARGET
    );

    resetFiltersState(
      this,
      newStateTableColumns,
      selected,
      this.getPmvList,
      "pmv"
    );
  };

  toTableColumns = (pmvs) => {
    const {
      togglePmvHistoryTable,
      changeSelectedPmvAndTab,
      isPmvHistoryTableOpen,
    } = this.props;

    let newList = [];
    pmvs.forEach((panel) => {
      let newEl = {};
      if (panel) {
        const pmvGif =
          panel &&
          panel.stati[0].statoOperativo &&
          panel.stati[0].statoOperativo.gifBase64
            ? "data:image/gif;base64," + panel.stati[0].statoOperativo.gifBase64
            : "";
        newEl = {
          Nome:
            panel && panel.anagrafica && panel.anagrafica.nome
              ? panel.anagrafica.nome
              : "-",
          strada:
            panel && panel.anagrafica && panel.anagrafica.strada
              ? panel.anagrafica.strada
              : "-",

          statoFunzionamento:
            panel && panel.anagrafica && panel.anagrafica.stato
              ? panel.anagrafica.stato
              : "-",
          pmvGif: (
            <div className="uk-flex uk-flex-row uk-flex-center uk-flex-middle">
              <div
                className={
                  panel &&
                  panel.stati &&
                  panel.stati.length > 0 &&
                  panel.stati[0].statoOperativo &&
                  panel.stati[0].statoOperativo.lampeggiante &&
                  panel.stati[0].statoOperativo.lampeggiante === "Attivo"
                    ? "lampeggiante-giallo-table"
                    : panel &&
                      panel.stati &&
                      panel.stati.length > 0 &&
                      panel.stati[0].statoOperativo &&
                      panel.stati[0].statoOperativo.lampeggiante &&
                      panel.stati[0].statoOperativo.lampeggiante === "Disattivo"
                    ? "lampeggiante-grigio-table"
                    : ""
                }
              ></div>
              <img className="pmv-panel-table" alt="" src={pmvGif} />{" "}
              <div
                className={
                  panel &&
                  panel.stati &&
                  panel.stati.length > 0 &&
                  panel.stati[0].statoOperativo &&
                  panel.stati[0].statoOperativo.lampeggiante &&
                  panel.stati[0].statoOperativo.lampeggiante === "Attivo"
                    ? "lampeggiante-giallo-table"
                    : panel &&
                      panel.stati &&
                      panel.stati.length > 0 &&
                      panel.stati[0].statoOperativo &&
                      panel.stati[0].statoOperativo.lampeggiante &&
                      panel.stati[0].statoOperativo.lampeggiante === "Disattivo"
                    ? "lampeggiante-grigio-table"
                    : ""
                }
              ></div>
            </div>
          ),
          pmvMsgName:
            panel &&
            panel.stati &&
            panel.stati.length > 0 &&
            panel.stati[0].statoOperativo &&
            panel.stati[0].statoOperativo.nomeMessaggio
              ? panel.stati[0].statoOperativo.nomeMessaggio
              : "-",
          pmvExpositionLevel:
            panel &&
            panel.stati &&
            panel.stati.length > 0 &&
            panel.stati[0].statoOperativo &&
            panel.stati[0].statoOperativo.livelloEsposizione
              ? panel.stati[0].statoOperativo.livelloEsposizione
              : "-",
          pmvDescriptionExposition:
            panel &&
            panel.stati &&
            panel.stati.length > 0 &&
            panel.stati[0].statoOperativo &&
            panel.stati[0].statoOperativo.descrizioneEsposizione
              ? panel.stati[0].statoOperativo.descrizioneEsposizione
              : "-",
          pmvValidityFrom:
            panel &&
            panel.stati &&
            panel.stati.length > 0 &&
            panel.stati[0].statoOperativo &&
            panel.stati[0].statoOperativo.validita &&
            panel.stati[0].statoOperativo.validita.da
              ? moment(panel.stati[0].statoOperativo.validita.da).format(
                  "DD/MM/YYYY HH:mm"
                )
              : "-",
          pmvValidityTo:
            panel &&
            panel.stati &&
            panel.stati.length > 0 &&
            panel.stati[0].statoOperativo &&
            panel.stati[0].statoOperativo.validita &&
            panel.stati[0].statoOperativo.validita.a
              ? moment(panel.stati[0].statoOperativo.validita.a).format(
                  "DD/MM/YYYY HH:mm"
                )
              : "-",
          tipologia:
            panel && panel.anagrafica && panel.anagrafica.tipologia
              ? panel.anagrafica.tipologia
              : "-",
          pmvHistoryActions: (
            <div
              className="uk-icon pointer"
              uk-icon="icon: acr-interface-calendar"
              onClick={(e) => {
                e.stopPropagation();
                if (!isPmvHistoryTableOpen) {
                  togglePmvHistoryTable();
                }
                changeSelectedPmvAndTab(panel, "history");
              }}
            ></div>
          ),
        };
      }
      newList = [...newList, newEl];
    });

    return newList;
  };

  render = () => {
    const {
      sortConfig,
      filterStr,
      selected,
      isTableDataLoading,
      currentListPmvs,
      pageMax,
      pageToDisplay,
    } = this.state;
    const {
      isOpenNav,
      dateColumns,
      tableColumns,
      changeSelectedTab,
      changeSelectedPmvAndTab,
      selectedTab,
      selectedPmv,
    } = this.props;

    let listOfPmv = [];
    if (currentListPmvs) {
      //MAPPA PER VISUALIZZAZIONE SU TABELLA
      listOfPmv = this.toTableColumns(currentListPmvs);
    }

    return (
      <div className="uk-flex uk-flex-column uk-width-1-1 uk-height-1-1">
        <div className="navbar-placeholder"></div>
        <TableUpperTabs
          changeSelectedTab={changeSelectedTab}
          changeSelectedPmvAndTab={changeSelectedPmvAndTab}
          selectedTab={selectedTab}
          selectedPmv={selectedPmv}
        />
        <TableFilters
          isOpenNav={isOpenNav}
          dateStart={{ date: null, name: "dateStartEvent" }}
          dateEnd={{ date: null, name: "dateEndEvent" }}
          handleFilterChange={this.handleFilterChange}
          handleSelectChange={this.handleSelectChange}
          resetFilters={this.resetFilters}
          filterStr={filterStr}
          filtrableDateColumns={dateColumns}
          selected={selected}
          refreshData={this.refreshData}
          disabledDataFilter={true}
        ></TableFilters>
        <div className="uk-flex uk-flex-right button-bar">
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.refreshData}
            >
              <span
                uk-icon="icon: refresh"
                uk-tooltip="Refresh tabella Pannelli"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              disabled={false}
              onClick={this.downloadExcel}
            >
              <span
                uk-icon="icon: download"
                uk-tooltip="Download Excel"
                className="filterBarIcons borderWhite"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
              onClick={this.resetFilters}
            >
              <span
                uk-icon="icon: acr-filter-remove"
                uk-tooltip="Cancella Filtri"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-flex uk-flex-right button-bar uk-padding-remove-left">
            <PageNumbers
              handleClickDecrease={this.handleClickDecrease}
              handleClickIncrease={this.handleClickIncrease}
              getPageNumber={this.getPageNumber}
              currentPage={pageToDisplay}
              minVal={1}
              pageMax={pageMax}
            />
          </div>
        </div>
        <div className="uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left ">
          <table
            id="eventTable"
            className="uk-table uk-table-divider uk-table-hover"
          >
            <thead>
              <TableHead
                onCheckboxFilterClick={this.onCheckboxFilterClick}
                requestSort={this.requestSort}
                tableColumns={tableColumns}
                sortConfig={sortConfig}
              />
            </thead>
            <tbody>
              {isTableDataLoading ? (
                <tr>
                  <td>
                    <Loading />
                  </td>
                </tr>
              ) : (
                <TableBody list={listOfPmv} tableColumns={tableColumns} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

PmvListTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  saveTableFilters,
  resetTableFilters,
  togglePmvHistoryTable,
  addActivity,
};

const mapStateToProps = (state) => ({
  isOpenNav: isOpenNavTab(state),
  tableColumns: getSavedTableColumns(state, FILTER_TARGET),
  dateColumns: getSavedDateColumns(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
  searchText: getSavedSearchText(state, FILTER_TARGET),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  toggledFilters: getSavedToggledFilters(state, FILTER_TARGET),
  isPmvHistoryTableOpen: getPmvHistoryTableOpen(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PmvListTable)
);
