import { INITIAL_STATE_WORKSPACE } from "./workspace.model";
import { WorkspaceActions } from "./workspace.actions";
import * as FilterUtils from "utils/filter-utils";

export default function workspaceReducer(
  state = INITIAL_STATE_WORKSPACE,
  action
) {
  let workspacesListCopy = FilterUtils.deepCopyOfArraysOfObj(state.workspaces);

  switch (action.type) {
    case WorkspaceActions.SET_WORKSPACES:
      return {
        ...state,
        workspaces: action.payload.workspaces,
      };
    case WorkspaceActions.SET_CURRENT_WORKSPACE:
      return {
        ...state,
        currentWorkspace: action.payload.ws,
      };
    case WorkspaceActions.DELETE_WORKSPACE:
      let newCurrent = state.currentWorkspace;
      if (
        action.payload &&
        action.payload.ws &&
        action.payload.ws.preferenceId &&
        action.payload.ws.preferenceId === newCurrent.preferenceId
      ) {
        newCurrent = null;
      }
      let newList = workspacesListCopy;
      if (
        action.payload &&
        action.payload.ws &&
        action.payload.ws.preferenceId
      ) {
        newList = workspacesListCopy.filter(
          (item) => item.preferenceId !== action.payload.ws.preferenceId
        );
      }
      return {
        ...state,
        currentWorkspace: newCurrent,
        workspaces: newList,
      };
    case WorkspaceActions.ADD_WORKSPACE:
      return {
        ...state,
        workspaces: [...state.workspaces, action.payload.ws],
      };
    case WorkspaceActions.UPDATE_WORKSPACE:
      let newUpdatedCurrent = state.currentWorkspace;
      if (
        action.payload &&
        action.payload.ws &&
        action.payload.ws.preferenceId &&
        action.payload.ws.preferenceId === newUpdatedCurrent.preferenceId
      ) {
        newUpdatedCurrent = { ...action.payload.ws };
      }
      let newUpdatedList = workspacesListCopy;
      if (
        action.payload &&
        action.payload.ws &&
        action.payload.ws.preferenceId
      ) {
        newUpdatedList.forEach((item, index, arr) => {
          if (item.preferenceId === action.payload.ws.preferenceId) {
            arr[index] = {
              ...action.payload.ws,
            };
          }
        });
      }
      return {
        ...state,
        currentWorkspace: newUpdatedCurrent,
        workspaces: newUpdatedList,
      };

    default:
      return state;
  }
}

export const getWorkspaces = (state) => state.workspaces;
export const getCurrentWorkspace = (state) => state.currentWorkspace;
