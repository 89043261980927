import { from } from "rxjs";
import { webSocket } from "rxjs/webSocket";

import { saveBlob } from "utils/hooks";
import moment from "moment";

export default class ApiService {
  genericHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("access_token"),
    "Access-Control-Allow-Origin": "*",
  };
  downloadHeaders = {
    Accept: "*/*",
    "Content-Type": "application/pdf",
    "Content-Disposition": "attachment",
  };

  constructor(environment) {
    //FASE 1
    //NETWORK
    this.networksEndpoint = environment.endpoint + "networks";
    //CONFIG
    this.anagraficaTPLEndpoint = environment.endpoint + "getAnagraficaTPL";
    this.configurazioniTPLEndpoint = environment.endpoint + "confTpl";
    //CORSA
    this.dettaglioCorsaVeicoloEndpoint =
      environment.endpoint + "dettaglioCorsa/";
    this.listaCorseEndpoint = environment.endpoint + "listaCorse/__";
    //FERMATA
    this.listaCorseFermateEndpoint = environment.endpoint + "stopMonitoring/";
    this.listaFermateEndpoint = environment.endpoint + "listaFermate";
    this.dettaglioFermataEndpoint = environment.endpoint + "fermata/";
    //LINEA
    this.listaLineeEndpoint = environment.endpoint + "listaLinee";
    this.dettaglioLineaEndpoint = environment.endpoint + "linea/";
    this.geoJsonLineaEndpoint = environment.endpoint + "lines/";
    this.listaRotteEndpoint = environment.endpoint + "getRoutes";
    //KPI
    this.kpiEventiWSEndpoint = environment.endpointWS + "kpiEventi";
    this.kpiEventiEndpoint = environment.endpoint + "getKpiEventi";
    this.kpiTplWSEndpoint = environment.endpointWS + "kpiTpl";
    this.kpiTplEndpoint = environment.endpoint + "getKpiTpl";
    //GUASTI
    this.listaGuastiWSEndpoint = environment.endpointWS + "listaGuasti";
    this.listaGuastiEndpoint = environment.endpoint + "getListaGuasti";
    //VEICOLI
    this.dettaglioVeicoliEndpoint =
      environment.endpoint + "getDettaglioVeicoli/";
    this.vehicleEventsRuntimeWS = environment.endpointWS + "vehicle-realtime";
    this.storicoVeicoliEndpoint = environment.endpoint + "getStoricoVeicoli";
    this.listaVeicoliWSEndpoint = environment.endpointWS + "listaVeicoli";
    this.listaVeicoliEndpoint = environment.endpoint + "getListaVeicoli";
    //METEO
    this.meteoEndpoint = environment.endpoint + "meteo";
    //FASE 2
    //EVENTI
    this.situationRecords = environment.endpointGeneral + "event/0.0.1/";
    this.listaEventiAttiviEndpoint =
      environment.endpoint + "getListaEventiAttivi";
    this.listaEventiAttiviWSEndpoint =
      environment.endpointWS + "listaEventiAttivi";
    this.listaEventiWSEndpoint = environment.endpointWS + "listaEventi";
    this.listaEventiEndpoint = environment.endpoint + "getListaEventi";
    this.modificaEventoEndpoint = environment.endpoint + "event/";
    this.eliminaEventoEndpoint = environment.endpoint + "event/";
    this.aggiungiEventoEndpoint = environment.endpoint + "event";
    //TOUCHPOINT
    this.touchpointsConfiguration = environment.endpoint + "touchpoints";
    this.touchpointsStatus = environment.endpoint + "touchpoint-status";
    //DEVICE
    this.devicesConfiguration = environment.endpoint + "devices";
    this.devicesStatus = environment.endpoint + "device-status";
    //ROAD SITUATIONS
    this.roadSituations = environment.endpoint + "road-situations";
    this.dossier = environment.endpoint + "dossier";
    //PRATICHE
    this.listapraticheEndpoint = environment.endpoint + "getListaPratiche";
    //PANNELLI
    this.listaCircoscrizioneEndpoint =
      environment.endpoint + "getListaCircoscrizione";
    this.listaPalineTPLEndpoint = environment.endpoint + "getListaPalineTPL";
    this.dettaglioPalineTPLEndpoint = environment.endpoint + "getPalineTPL/";
    this.telecamereEndpoint = environment.endpoint + "getTelecamere";
    this.telecamereIdEndpoint = environment.endpoint + "getTelecamere/";
    this.listaPMVEndpoint = environment.endpoint + "getListaPMV";
    this.dettaglioPMVEndpoint = environment.endpoint + "getPMV/";
    this.listaZTLEndpoint = environment.endpoint + "getListaZTL";
    this.dettaglioZTLEndpoint = environment.endpoint + "getZTL/";
    this.panelEndpoint = environment.endpoint + "getPannelli";
    this.pannelIdEndpoint = environment.endpoint + "getPannelli/";
    this.setPannelliEndpoint = environment.endpoint + "setPannelli/";
    this.storicoPannelliEndpoint = environment.endpoint + "getStoricoPannelli";
    this.parcheggiEndpoint = environment.endpoint + "getListaParcheggi";
  }

  /*this. ********************** */
  /* REQUEST REST ENDPOINTS */
  /* ********************** */

   // INVIO ID EVENTI DA SELECT CHECKBOX
  postIdsRecord = (selectedEvents, inputField) => {
    let params = "idsRecord="
    for(let i in selectedEvents){
      if(i < selectedEvents.length - 1)
        params += selectedEvents[i] + ","
      else
        params += selectedEvents[i]
    }
    if(!inputField)
      inputField = "eventi_scenario"
      console.debug(inputField)
    return from (
      fetch(this.situationRecords + "situation-record/createEventsDbf?" + params +"&fileName=" + inputField, {
        method: "POST",
        headers: this.genericHeaders,
      })
      .then((data) => data.text().then(function (text) {
          alert("File " + text + " creato con successo!");
        }))
      .catch((error) => {
      throw error;
      })
    )
  }

  //GET NETWORKS
  getAllNetworks = () => {
    return from(
      fetch(this.networksEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETAILS NETWORK
  getNetworkDetail = (idNet) => {
    return from(
      fetch(this.networksEndpoint + "/" + idNet, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET TELECAMERE
  getTelecamere = () => {
    return from(
      fetch(this.telecamereEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET DETTAGLIO TELECAMERE
  getDettaglioTelecamereById = (id) => {
    return from(
      fetch(this.telecamereIdEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA CIRCOSCRIZIONE
  getListaCircoscrizione = () => {
    return from(
      fetch(this.listaCircoscrizioneEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA PANNELLI
  getListaPannelli = () => {
    return from(
      fetch(this.pannelEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET DETTAGLIO PANNELLI
  getDettaglioPannelli = (id) => {
    return from(
      fetch(this.pannelIdEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA PARCHEGGI
  getListaParcheggi = () => {
    return from(
      fetch(this.parcheggiEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA PANNELLI MESSAGGIO VARIABILE
  getListaPMV = () => {
    return from(
      fetch(this.listaPMVEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET DETTAGLIO PANNELLI MESSAGGIO VARIABILE
  getDettaglioPMV = (id) => {
    return from(
      fetch(this.dettaglioPMVEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA PANNELLI ZTL
  getListaZTL = () => {
    return from(
      fetch(this.listaZTLEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET DETTAGLIO ZTL
  getDettaglioZTL = (id) => {
    return from(
      fetch(this.dettaglioZTLEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA PALINE TPL
  getListaPalineTPL = () => {
    return from(
      fetch(this.listaPalineTPLEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA DETTAGLIO PALINE TPL
  getDettaglioPalineTPL = (id) => {
    return from(
      fetch(this.dettaglioPalineTPLEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET STORICO PANNELLI
  getStoricoPannelli = () => {
    return from(
      fetch(this.storicoPannelliEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // POST SET PANNELLI
  setPannelli = (data, id) => {
    return from(
      fetch(this.setPannelliEndpoint + id, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify({ pathFile: data }),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA EVENTI ATTIVI

  getListaEventiAttivi = () => {
    return from(
      fetch(this.listaEventiAttiviEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA EVENTI ATTIVI WS

  getListaEventiAttiviWS = () => {
    return from(
      fetch(this.listaEventiAttiviWSEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // GET LISTA GUASTI
  getListaGuasti = () => {
    return from(
      fetch(this.listaGuastiEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA GUASTI WS

  getListaGuastiWS = () => {
    return from(
      fetch(this.listaGuastiWSEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET KPI EVENTI

  getKpiEventi = () => {
    return from(
      fetch(this.kpiEventiEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET KPI LISTA EVENTI WS

  getKPIListaEventiWS = () => {
    return from(
      fetch(this.kpiEventiWSEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA EVENTI

  getListaEventi = () => {
    return from(
      fetch(this.listaEventiEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA EVENTI WS

  getListaEventiWS = () => {
    return from(
      fetch(this.listaEventiWSEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //POST AGGIUNGI EVENTO

  aggiungiEvento = (data) => {
    return from(
      fetch(this.aggiungiEventoEndpoint, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify({ pathFile: data }),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //PUT AGGIORNA EVENTO

  modificaEvento = (id, data) => {
    return from(
      fetch(this.modificaEventoEndpoint + id, {
        method: "PUT",
        headers: this.genericHeaders,
        body: JSON.stringify({ pathFile: data }),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //DELETE ELIMINA EVENTO

  eliminaEvento = (id) => {
    return from(
      fetch(this.eliminaEventoEndpoint + id, {
        method: "DELETE",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET KPI TPLI

  getKpiTpl = () => {
    return from(
      fetch(this.kpiTplEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET KPI TPL WS

  getKpiTplWS = () => {
    return from(
      fetch(this.kpiTplWSEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET ANAGRAFICA TPL
  getAnagraficaTPL = () => {
    return from(
      fetch(this.anagraficaTPLEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET STORICO VEICOLI
  getStoricoVeicoli = () => {
    return from(
      fetch(this.storicoVeicoliEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETTAGLIO VEICOLI
  getDettaglioVeicoli = (id) => {
    return from(
      fetch(this.dettaglioVeicoliEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA VEICOLI
  getListaVeicoli = () => {
    return from(
      fetch(this.listaVeicoliEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA VEICOLI WS
  getListaVeicoliWS = () => {
    return from(
      fetch(this.listaVeicoliWSEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET METEO
  getMeteo = (data) => {
    return from(
      fetch(this.meteoEndpoint, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify({ pathFile: data }),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET CONFIGURAZIONI TPL
  getConfigurazioniTpl = () => {
    return from(
      fetch(this.configurazioniTPLEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETTAGLIO LINEA

  getDettaglioLinea = (id) => {
    return from(
      fetch(this.dettaglioLineaEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA LINEE

  getListaLinee = () => {
    return from(
      fetch(this.listaLineeEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA FERMATE

  getListaFermate = () => {
    return from(
      fetch(this.listaFermateEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA CORSE

  getListaCorse = () => {
    return from(
      fetch(this.listaCorseEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETTAGLIOFERMATA

  getDettaglioFermata = (id) => {
    return from(
      fetch(this.dettaglioFermataEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET GEOJSON LINEA

  getGeoJsonLinea = (id) => {
    return from(
      fetch(this.geoJsonLineaEndpoint + id + "/geo", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA CORSE FERMATE

  getListaCorseFermate = (id) => {
    return from(
      fetch(this.listaCorseFermateEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETTAGLIO CORSE PER VEICOLO

  getDettaglioCorsaVeicolo = (id) => {
    return from(
      fetch(this.dettaglioCorsaVeicoloEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA ROTTE

  getListaRotte = (lines) => {
    return from(
      Promise.all(
        lines.map((line) => {
          if (line && line.id && line.versionRef) {
            return fetch(this.listaRotteEndpoint, {
              method: "GET",
              headers: this.genericHeaders,
            })
              .then((data) => this.handleErrors(data))
              .then((data) => data.json())
              .catch((error) => {
                throw error;
              });
          }

          return null;
        })
      )
    );
  };

  //GET LISTA PRATICHE

  getListaPratiche = () => {
    return from(
      fetch(this.listapraticheEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA TOUCHPOINTS

  getTouchpoints = (idList, searchingArea, touchpointScope, deviceCategory) => {
    let params = {
      idList: idList.toString(),
      searchingArea: searchingArea,
      touchpointScope: touchpointScope,
      deviceCategory: deviceCategory,
    };
    var queryString = Object.keys(params)
      .map((key) => (params[key] ? key + "=" + params[key] : null))
      .filter((x) => x !== null)
      .join("&");
    return from(
      fetch(this.touchpointsConfiguration + "?" + queryString, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA STATUS TOUCHPOINTS

  getStatoTouchpoints = (
    statusCode,
    searchingArea,
    touchpointCategory,
    publishingStatus
  ) => {
    let params = {
      statusCode: statusCode,
      searchingArea: searchingArea,
      touchpointCategory: touchpointCategory,
      publishingStatus: publishingStatus,
    };
    var queryString = Object.keys(params)
      .map((key) => (params[key] ? key + "=" + params[key] : null))
      .filter((x) => x !== null)
      .join("&");
    return from(
      fetch(this.touchpointsStatus + "?" + queryString, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //SET TOUCHPOINTS

  setTouchpoints = (body) => {
    return from(
      fetch(this.touchpointsConfiguration, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(body),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETAIL CONFIGURATION TOUCHPOINT

  getDettaglioTouchpoint = (id) => {
    return from(
      fetch(this.touchpointsConfiguration + "/" + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET STATUS TOUCHPOINTS/TOUCHPOINT DEVICE

  getDettaglioStatoTouchpoint = (id, boolDevice) => {
    let devices = "";
    if (boolDevice) devices = "/devices";
    return from(
      fetch(this.touchpointsStatus + "/" + id + devices, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA DEVICES

  getDevices = (idList, searchingArea, deviceCategory) => {
    let params = {
      idList: idList ? idList.toString() : null,
      searchingArea: searchingArea,
      deviceCategory: deviceCategory,
    };
    var queryString = Object.keys(params)
      .map((key) => (params[key] ? key + "=" + params[key] : null))
      .filter((x) => x !== null)
      .join("&");
    return from(
      fetch(this.devicesConfiguration + "?" + queryString, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA STATUS DEVICE

  getStatoDevices = (
    statusCode,
    searchingArea,
    deviceCategory,
    publishingStatus
  ) => {
    let params = {
      statusCode: statusCode,
      searchingArea: searchingArea,
      deviceCategory: deviceCategory,
      publishingStatus: publishingStatus,
    };
    var queryString = Object.keys(params)
      .map((key) => (params[key] ? key + "=" + params[key] : null))
      .filter((x) => x !== null)
      .join("&");
    return from(
      fetch(this.devicesStatus + "?" + queryString, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //SET DEVICES

  setDevices = (body) => {
    return from(
      fetch(this.devicesConfiguration, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(body),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETAIL CONFIGURATION DEVICES

  getDettaglioDevice = (id) => {
    return from(
      fetch(this.devicesConfiguration + "/" + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET STATUS TOUCHPOINTS/TOUCHPOINT DEVICE

  getDettaglioStatoDevice = (id) => {
    return from(
      fetch(this.devicesStatus + "/" + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET HISTORY DEVICE

  getVmsHistoryDevice = (deviceId) => {
    return from(
      fetch(this.vmsMessage + deviceId + "/history", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET VMS MESSAGE DEVICE

  getVmsMessageDevice = (deviceId) => {
    return from(
      fetch(this.vmsMessage + deviceId, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //SET VMS MESSAGE DEVICE

  setVmsMessageDevice = (deviceId, data) => {
    return from(
      fetch(this.vmsMessage + deviceId, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(data),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA EVENTI

  getRoadSituations = (validityStatus, searchingArea) => {
    let params = {
      validityStatus: validityStatus,
      searchingArea: searchingArea,
    };
    var queryString = Object.keys(params)
      .map((key) => (params[key] ? key + "=" + params[key] : null))
      .filter((x) => x !== null)
      .join("&");
    return from(
      fetch(this.roadSituations + "?" + queryString, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Creates a new Road Situation.

  createRoadSituations = (roadSituationElement) => {
    return from(
      fetch(this.roadSituations, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(roadSituationElement),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Delete a new Road Situation.

  deleteRoadSituations = (situationId, descriptionDeleting) => {
    return from(
      fetch(
        this.roadSituations + "/" + situationId + "/" + descriptionDeleting,
        {
          method: "DELETE",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET Dettaglio EVENTO

  getDetailsRoadSituations = (situationId) => {
    return from(
      fetch(this.roadSituations + "/" + situationId, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Creates a new Road Situation related at this specific Road Situation

  createRoadSituations = (situationId, roadSituationElement) => {
    return from(
      fetch(
        this.roadSituations +
          "/" +
          situationId +
          "/createRelatedDossierSituation",
        {
          method: "POST",
          headers: this.genericHeaders,
          body: JSON.stringify(roadSituationElement),
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Update Road Situation

  updateRoadSituations = (situationId, roadSituationElement) => {
    return from(
      fetch(this.roadSituations + "/" + situationId, {
        method: "PUT",
        headers: this.genericHeaders,
        body: JSON.stringify(roadSituationElement),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Changes th status of a specific Road Situation.

  updateStatusRoadSituations = (situationId, situationStatusTransition) => {
    return from(
      fetch(this.roadSituations + "/" + situationId, {
        method: "PATCH",
        headers: this.genericHeaders,
        body: JSON.stringify(situationStatusTransition),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Creates a new Dossier.

  createDossier = (dossier) => {
    return from(
      fetch(this.dossier, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(dossier),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Changes th status of a specific Dossier.

  updateStatusDossier = (dossierId, dossierStatusTransition) => {
    return from(
      fetch(this.dossier + "/" + dossierId, {
        method: "PATCH",
        headers: this.genericHeaders,
        body: JSON.stringify(dossierStatusTransition),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Update Dossier

  updateDossier = (dossierId, dossierUpdating) => {
    return from(
      fetch(this.dossier + "/" + dossierId, {
        method: "PUT",
        headers: this.genericHeaders,
        body: JSON.stringify(dossierUpdating),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };
  // downloadExcel = (data) => {

  //     var postData = JSON.stringify(data)
  //     var xhr = new XMLHttpRequest();
  //     xhr.open('POST', this.rilevationEndpoint + "excelReportByIds", true);
  //     xhr.setHeader('Content-Type', "application/json");
  //     xhr.responseType = 'blob';
  //     xhr.onload = function (e) {
  //         var blob = xhr.response;
  //         var contentDispo = xhr.getResponseHeader('Content-Disposition');
  //         saveBlob(blob, 'elenco-rilievi-' + moment().format("DD-MM-YYYY") + '.xlsx');
  //     }.bind(this)
  //     xhr.send(postData);

  // }

  downloadPdf = (data) => {
    var postData = JSON.stringify(data);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", this.rilevationEndpoint + "pdfReportByIds", true);
    xhr.setHeader("Content-Type", "application/json");
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      var blob = xhr.response;
      saveBlob(
        blob,
        "elenco-rilievi-" + moment().format("DD-MM-YYYY") + ".pdf"
      );
    };
    xhr.send(postData);
  };

  getPdf = (link) => {
    return window.open(link); //from(fetch(link, { method: "GET", headers: this.downloadHeaders }).then());
  };

  getVehicleEventsRuntime = () => {
    return webSocket(
      this.vehicleEventsRuntimeWS
    ); /*+ "?access_token=" + 
    this.ssoService.getAccessToken()) .pipe(
      map(VehicleEvent.fromREST),
      catchError(error => {
        console.error(error);
        return of(error);
      })
    ) */
  };
}
