export const TABLE_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: true,
    order: true,
  },
  name: {
    name: "Nome Tratta",
    id: "name",
    show: true,
    order: true,
  },
  tot_length: {
    name: "Lunghezza",
    id: "tot_length",
    show: true,
    order: true,
  },
  start_road: {
    name: "Direzione negativa",
    id: "start_road",
    show: true,
    order: true,
  },
  end_road: {
    name: "Direzione positiva",
    id: "end_road",
    show: true,
    order: true,
  },
};

export const DATE_COLUMNS = [];

export const initColumns = (tableColumns = null, dateColumns = null) => {
  let columns = {};
  let date = [];
  if (
    !tableColumns ||
    (tableColumns &&
      Object.keys(TABLE_COLUMNS)[1] !== Object.keys(tableColumns)[1])
  ) {
    columns = { ...TABLE_COLUMNS };
    date = [...DATE_COLUMNS];
  } else {
    columns = { ...tableColumns };
    date = dateColumns ? [...dateColumns] : [];
  }

  return { columns: columns, date: date };
};
