import React, { Component } from "react";
import "./style.less";
import OrderTableColumn from "components/shared/order-table-column/order-table-column";
import { getSelectedEvents } from 'store';
import { setSelectedEvents } from "reducers/events/events.actions";

class TableHead extends Component {
  onCheckboxFilterClick = (event, clickedParent, clickedItem) => {
    const { tableColumns, onCheckboxFilterClick } = this.props;

    let parentId = clickedParent.id;
    let parentNode = clickedParent;
    let newTableColumns = {
      ...tableColumns,
    };

    newTableColumns[parentId] = {
      ...parentNode,
      childrenForHeaderDropdown: [
        ...parentNode.childrenForHeaderDropdown.map((child) => {
          //IN CASE "ALL": SELECT/DESELECT ALL
          if (clickedItem.id.startsWith("all")) {
            return {
              ...child,
              checked: !clickedItem.checked,
            };
            //IN CASE "CHECKBOX": CHECK OTHER CHILDREN TO SELECT/DESELCT "ALL" FIELD
          } else if (
            child.id.startsWith("all") &&
            !clickedItem.id.startsWith("all")
          ) {
            return {
              ...child,
              checked:
                clickedParent.childrenForHeaderDropdown.filter(
                  (item) => item.checked === true
                ).length ===
                  clickedParent.childrenForHeaderDropdown.length - 2 &&
                clickedItem.checked === false &&
                clickedParent.childrenForHeaderDropdown.length - 2 !== 0
                  ? true
                  : false,
            };
            //SELECT/DESELECT CLICKED CHECKBOX
          } else if (child.id === clickedItem.id) {
            return {
              ...child,
              checked: !child.checked,
            };
          } else return child;
        }),
      ],
    };

    onCheckboxFilterClick(newTableColumns);
  };

  render = () => {
    const { tableColumns, requestSort, sortConfig, selectedEvents } = this.props;
    return (
      <tr>
        {tableColumns &&
          Object.values(tableColumns).map((t, index) => {
            if (index !== 0)
              return (
                <th
                  key={t.id}
                  className={
                    tableColumns &&
                    t.id &&
                    tableColumns[t.id] &&
                    !tableColumns[t.id].show
                      ? "displayNone"
                      : ""
                  }
                >
                  <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between">
                    { selectedEvents
                      ? <div>{t.name}</div>
                      : <div>{t.name === 'Selezione Eventi' ? delete tableColumns.checkbox : t.name}</div>
                    }
                    {t.childrenForHeaderDropdown ? (
                      <div
                        uk-dropdown="mode: hover"
                        className="uk-overflow-auto custom-dropdown-class"
                      >
                        {t.childrenForHeaderDropdown &&
                          t.childrenForHeaderDropdown.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" uk-flex uk-flex-row uk-flex-middle uk-margin-bottom uk-space-between"
                              >
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  checked={item.checked}
                                  onChange={(e) =>
                                    this.onCheckboxFilterClick(e, t, item)
                                  }
                                  className="uk-checkbox uk-checkbox-small "
                                />
                                <label className="uk-margin-left">
                                  {item.label}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    ) : null}
                    {t.order && (
                      <div>
                        <OrderTableColumn
                          requestSort={requestSort}
                          type={t.id}
                          sortConfig={sortConfig}
                        />
                      </div>
                    )}
                  </div>
                </th>
              );
            return null;
          })}
      </tr>
    );
  };
}

export default TableHead;
