/* import { EVENT_TYPES } from './../../settings/settings-list'; */
import { IconLayer, PathLayer, BitmapLayer, LineLayer } from "@deck.gl/layers";

/* import { RoadEvent } from '../model/road-event'; */
import { TYPE_TRANSPORT_MODE } from "../urbSub_definitions";
import { selectFields } from "enums/selectable-params";

import { SENSOR_STATUS } from "utils/util-cameras";
import { PMV_STATUS } from "utils/utils-pmv";

//ASSETS
import pointDraw from "assets/icon/pointDraw.svg";
import ATV_mappa_suburbana from "assets/images/ATV_mappa_suburbana.png";
//bus dark
import busImageSelectedRedLate from "assets/icon/bus/icn_map_pin_bus_late_ls_selected.svg";
import busImageSelectedBlueLate from "assets/icon/bus/icn_map_pin_bus_late_lex_selected.svg";
import busImageLate from "assets/icon/bus/icn_map_pin_bus_late.svg";
import darkBusImageBlue from "assets/icon/bus/dark_icn_map_pin_bus_lex.svg";
import darkBusImageRed from "assets/icon/bus/dark_icn_map_pin_bus_ls.svg";
import darkBusImageSelectedBlue from "assets/icon/bus/dark_icn_map_pin_bus_lex_selected.svg";
import darkBusImageSelectedRed from "assets/icon/bus/dark_icn_map_pin_bus_ls_selected.svg";
//bus light
import lightBusImageBlue from "assets/icon/bus/light_icn_map_pin_bus_lex.svg";
import lightBusImageRed from "assets/icon/bus/light_icn_map_pin_bus_ls.svg";
import lightBusImageSelectedBlue from "assets/icon/bus/light_icn_map_pin_bus_lex_selected.svg";
import lightBusImageSelectedRed from "assets/icon/bus/light_icn_map_pin_bus_ls_selected.svg";
//impianti
import panel_green from "assets/icon/installations/panel_green.svg";
import panel_yellow from "assets/icon/installations/panel_yellow.svg";
import panel_red from "assets/icon/installations/panel_red.svg";
import panel_black from "assets/icon/installations/panel_black.svg";
import panel_selected from "assets/icon/installations/panel_selected.svg";
import camera_green from "assets/icon/installations/camera_green.svg";
import camera_yellow from "assets/icon/installations/camera_yellow.svg";
import camera_red from "assets/icon/installations/camera_red.svg";
import camera_black from "assets/icon/installations/camera_black.svg";
import camera_selected from "assets/icon/installations/camera_selected.svg";
//fermate dark
import darkStopU from "assets/icon/stops/dark_icn_map_fermata_ls.svg";
import darkStopE from "assets/icon/stops/dark_icn_map_fermata_lex.svg";
import darkStopUE from "assets/icon/stops/dark_icn_map_fermata_scambio.svg";
import darkStopEcap from "assets/icon/stops/dark_icn_map_fermata_lex_capolinea.svg";
import darkStopUcap from "assets/icon/stops/dark_icn_map_fermata_ls_capolinea.svg";
import darkStopUEcap from "assets/icon/stops/dark_icn_map_fermata_scambio_capolinea.svg";
import darkSelectedStopU from "assets/icon/stops/dark_icn_map_fermata_ls_selected.svg";
import darkSelectedStopE from "assets/icon/stops/dark_icn_map_fermata_lex_selected.svg";
import darkSelectedStopUE from "assets/icon/stops/dark_icn_map_fermata_scambio_selected.svg";
import darkSelectedStopUcap from "assets/icon/stops/dark_icn_map_fermata_ls_capolinea_selected.svg";
import darkSelectedStopEcap from "assets/icon/stops/dark_icn_map_fermata_lex_capolinea_selected.svg";
import darkSelectedStopUEcap from "assets/icon/stops/dark_icn_map_fermata_scambio_capolinea_selected.svg";
//fermate light
import lightStopU from "assets/icon/stops/light_icn_map_fermata_ls.svg";
import lightStopE from "assets/icon/stops/light_icn_map_fermata_lex.svg";
import lightStopUE from "assets/icon/stops/light_icn_map_fermata_scambio.svg";
import lightStopEcap from "assets/icon/stops/light_icn_map_fermata_lex_capolinea.svg";
import lightStopUcap from "assets/icon/stops/light_icn_map_fermata_ls_capolinea.svg";
import lightStopUEcap from "assets/icon/stops/light_icn_map_fermata_scambio_capolinea.svg";
import lightSelectedStopU from "assets/icon/stops/light_icn_map_fermata_ls_selected.svg";
import lightSelectedStopE from "assets/icon/stops/light_icn_map_fermata_lex_selected.svg";
import lightSelectedStopUE from "assets/icon/stops/light_icn_map_fermata_scambio_selected.svg";
import lightSelectedStopUcap from "assets/icon/stops/light_icn_map_fermata_ls_capolinea_selected.svg";
import lightSelectedStopEcap from "assets/icon/stops/light_icn_map_fermata_lex_capolinea_selected.svg";
import lightSelectedStopUEcap from "assets/icon/stops/light_icn_map_fermata_scambio_capolinea_selected.svg";
//events alert
// import alert_icn_map_event_block from "assets/icon/events/alert_icn_map_event_block.svg";
// //import alert_icn_map_event_camera from "assets/icon/events/alert_icn_map_event_camera.svg";
// import alert_icn_map_event_car_fire from "assets/icon/events/alert_icn_map_event_car_fire.svg";
// import alert_icn_map_event_car_panne from "assets/icon/events/alert_icn_map_event_car_panne.svg";
// import alert_icn_map_event_coda from "assets/icon/events/alert_icn_map_event_coda.svg";
// import alert_icn_map_event_H from "assets/icon/events/alert_icn_map_event_H.svg";
// import alert_icn_map_event_lavori from "assets/icon/events/alert_icn_map_event_lavori.svg";
// import alert_icn_map_event_traffic from "assets/icon/events/alert_icn_map_event_traffic.svg";
// import alert_icn_map_event_weather from "assets/icon/events/alert_icn_map_event_weather.svg";
// //events dark
// import dark_icn_map_event_block from "assets/icon/events/dark_icn_map_event_block.svg";
// //import dark_icn_map_event_camera from "assets/icon/events/dark_icn_map_event_camera.svg";
// import dark_icn_map_event_car_fire from "assets/icon/events/dark_icn_map_event_car_fire.svg";
// import dark_icn_map_event_car_panne from "assets/icon/events/dark_icn_map_event_car_panne.svg";
// import dark_icn_map_event_coda from "assets/icon/events/dark_icn_map_event_coda.svg";
// import dark_icn_map_event_H from "assets/icon/events/dark_icn_map_event_H.svg";
// import dark_icn_map_event_lavori from "assets/icon/events/dark_icn_map_event_lavori.svg";
// import dark_icn_map_event_traffic from "assets/icon/events/dark_icn_map_event_traffic.svg";
// import dark_icn_map_event_weather from "assets/icon/events/dark_icn_map_event_weather.svg";
// //events light
// import light_icn_map_event_block from "assets/icon/events/light_icn_map_event_block.svg";
// //import light_icn_map_event_camera from "assets/icon/events/light_icn_map_event_camera.svg";
// import light_icn_map_event_car_fire from "assets/icon/events/light_icn_map_event_car_fire.svg";
// import light_icn_map_event_car_panne from "assets/icon/events/light_icn_map_event_car_panne.svg";
// import light_icn_map_event_coda from "assets/icon/events/light_icn_map_event_coda.svg";
// import light_icn_map_event_H from "assets/icon/events/light_icn_map_event_H.svg";
// import light_icn_map_event_lavori from "assets/icon/events/light_icn_map_event_lavori.svg";
// import light_icn_map_event_traffic from "assets/icon/events/light_icn_map_event_traffic.svg";
// import light_icn_map_event_weather from "assets/icon/events/light_icn_map_event_weather.svg";
//events selected
import selected_icn_map_event_block from "assets/icon/events/selected_icn_map_event_block.svg";
import selected_icn_map_event_lavori from "assets/icon/events/selected_icn_map_event_lavori.svg";
import selected_icn_map_event_traffic from "assets/icon/events/selected_icn_map_event_traffic.svg";
//import selected_icn_map_event_camera from "assets/icon/events/selected_icn_map_event_camera.svg";
import selected_icn_map_event_car_fire from "assets/icon/events/selected_icn_map_event_car_fire.svg";
import selected_icn_map_event_car_panne from "assets/icon/events/selected_icn_map_event_car_panne.svg";
import selected_icn_map_event_coda from "assets/icon/events/selected_icn_map_event_coda.svg";
import selected_icn_map_event_H from "assets/icon/events/selected_icn_map_event_H.svg";
import selected_icn_map_event_weather from "assets/icon/events/selected_icn_map_event_weather.svg";
//severity
import severity_red from "assets/icon/severity/severity-red.svg";
import severity_yellow from "assets/icon/severity/severity-yellow.svg";
import severity_green from "assets/icon/severity/severity-green.svg";
import severity_dark_green from "assets/icon/severity/severity-dark-green.svg";
import severity_orange from "assets/icon/severity/severity-orange.svg";
import severity_grey from "assets/icon/severity/severity-grey.svg";
//GRAPH
import centroids_icon from "assets/icon/graph/acr-centroid_mod.svg";
import centroids_icon_selected from "assets/icon/graph/acr_centroid_diamond_merge.svg";
import nodes_icon from "assets/icon/graph/icn-map-point.svg";
import nodes_icon_selected from "assets/icon/graph/icn_diamond_point_merge.svg";

//NETWORK RDS-TMS
import map_point from "assets/icon/graph/icn_map_pin_green.svg";
import map_point_selected from "assets/icon/graph/icn_diamond_pin_green_merge.svg";

//STATIONS
import stations_img_red from "assets/icon/multitab-icns/Tab_StazioniMisuraRed.svg";
import stations_img_green from "assets/icon/multitab-icns/Tab_StazioniMisuraGreen.svg";
import stations_img_gray from "assets/icon/multitab-icns/Tab_StazioniMisuraGrey.svg";
import { getWorseStationSensorStateDetail } from "utils/utils";

// const COLOR_SCALE = scaleThreshold()
//   .domain([0, 0.002, 0.005, 0.03, 0.1, 0.2, 0.3, 0.5, 0.9, 1])
//   .range([
//     [26, 152, 80],
//     [102, 189, 99],
//     [166, 217, 106],
//     [217, 239, 139],
//     [255, 255, 191],
//     [254, 224, 139],
//     [253, 174, 97],
//     [244, 109, 67],
//     [215, 48, 39],
//     [168, 0, 0]
//   ]);

// const WIDTH_SCALE = scaleLinear()
//   .clamp(true)
//   .domain([0, 1])
//   .range([2.6, 3.3]);

export default class DeckLayerUtil {
  // get road event icon
  /*  static getIconRoadEvent(type, status) {
    if(status===0){ return EVENT_TYPES.find(i => i.id === type).icon.replace(".","_red.")}
    if(status===2){ return EVENT_TYPES.find(i => i.id === type).icon.replace(".","_yellow.")}
    return EVENT_TYPES.find(i => i.id === type).icon
  } */

  // get marker road event
  static getMarkerRoadEvent(list, fnTooltip, fnClick) {
    return new IconLayer({
      id: `icon`,
      data: list,
      pickable: true,
      ...this.loadOptions(),
      getIcon: (data) => ({
        url: `assets/icon/road-events/marker_${this.getIconRoadEvent(
          data.type,
          data.status
        )}`,
        width: 144,
        height: 144,
      }),
      sizeScale: 8,
      showCluster: true,
      visible: true,
      getPosition: (d) => [d.pos.lon, d.pos.lat],
      getSize: () => 6,
      onHover: ({ object, x, y }) =>
        fnTooltip ? fnTooltip(object, x, y) : null,
      onClick: (object) => (fnClick ? fnClick(object.object) : null),
    });
  }

  static getIconInstallations(category, status, layoutMap) {
    if (category && category.toLowerCase() === "cctv") {
      switch (status && status.status && status.status.code) {
        case "WORKING":
          return layoutMap === "light" ? camera_green : camera_green;
        case "PARTIALLY_WORKING":
          return layoutMap === "light" ? camera_yellow : camera_yellow;
        case "NOT_WORKING":
          return layoutMap === "light" ? camera_red : camera_red;
        case "DAMAGED":
          return layoutMap === "light" ? camera_red : camera_red;
        case "NOT_SIGNAL":
        default:
          return layoutMap === "light" ? camera_black : camera_black;
      }
    }

    switch (status && status.status && status.status.code) {
      case "WORKING":
        return layoutMap === "light" ? panel_green : panel_green;
      case "PARTIALLY_WORKING":
        return layoutMap === "light" ? panel_yellow : panel_yellow;
      case "NOT_WORKING":
        return layoutMap === "light" ? panel_red : panel_red;
      case "DAMAGED":
        return layoutMap === "light" ? panel_red : panel_red;
      case "NOT_SIGNAL":
      default:
        return layoutMap === "light" ? panel_black : panel_black;
    }
  }

  static getSelectedIconInstallations(type, status, layoutMap) {
    switch (type && type.toLowerCase()) {
      case "cctv": //camera
        return camera_selected;
      case 9: //panel
      case 5: //stopsign
      case 3: //parking
      case 4: //vms
      case 6: //tpl
      default:
        return panel_selected;
    }
  }

  // get marker installations
  static getMarkerInstallations(list, selected, fnTooltip, fnClick) {
    return new IconLayer({
      id: `icon-installation`,
      data: list,
      pickable: true,
      ...this.loadOptions(),
      getIcon: (data) => ({
        url:
          selected.findIndex(
            (x) => x.id === data.id && x.category.type === data.category.type
          ) >= 0
            ? this.getSelectedIconInstallations(data.category.type)
            : this.getIconInstallations(data.category, data.deviceStatus),
        width: 144,
        height: 144,
      }),
      sizeScale: 8,
      showCluster: true,
      visible: true,
      getPosition: (d) => [d.location.longitude, d.location.latitude],
      getSize: () => 6,
      onHover: ({ object, x, y }) =>
        fnTooltip ? fnTooltip(object, x, y) : null,
      onClick: (object) => (fnClick ? fnClick(object.object) : null),
    });
  }

  static getIconSelectedEvents = (data) => {
    if (data && data.situationRecordType) {
      switch (data.situationRecordType.toLowerCase()) {
        case "accidenttype":
          return selected_icn_map_event_car_fire;
        case "roadsideassistance":
          return selected_icn_map_event_H;
        case "roadorcarriagewayorlanemanagementtype":
        case "constructionworktype":
          return selected_icn_map_event_lavori;
        case "abnormaltraffictype":
          return selected_icn_map_event_coda;
        case "vehicleobstructiontype":
          return selected_icn_map_event_car_panne;
        case "poorenvironmenttype":
        case "nonweatherrelatedroadconditions":
        case "weatherrelatedroadconditiontype":
          return selected_icn_map_event_weather;
        case "equipmentorsystemfaulttype":
          return selected_icn_map_event_traffic;
        case "authorityoperationtype":
        case "publiceventtype":
        case "animalpresenceobstructiontype":
        case "obstructiontype":
        default:
          return selected_icn_map_event_block;
      }
    } else {
      return null;
    }
  };

  static getIconCentroids = (data, layoutMap, centroidCurrentReducer) => {
    return (data && data.selected) ||
      (centroidCurrentReducer &&
        data.id === centroidCurrentReducer.id &&
        centroidCurrentReducer.selected)
      ? centroids_icon_selected
      : centroids_icon;
  };

  static getIconNodes = (data, layoutMap, nodeCurrentReducer) => {
    return (data && data.selected) ||
      (nodeCurrentReducer &&
        data.id === nodeCurrentReducer.id &&
        nodeCurrentReducer.selected)
      ? nodes_icon_selected
      : nodes_icon;
  };

  static getIconPointTmc = (data, layout, pointRdsTmcReducer) => {
    return (data && data.selected) ||
      (pointRdsTmcReducer &&
        data.id === pointRdsTmcReducer.id &&
        pointRdsTmcReducer.selected)
      ? map_point_selected
      : map_point;
  };

  static getIconStation = (data, layout, stationsCurrentReducer) => {
    let stateSensor = getWorseStationSensorStateDetail(data);

    return stateSensor === "NO_INFO"
      ? stations_img_gray
      : stateSensor === "DOWN"
      ? stations_img_red
      : stations_img_green;
  };

  static getIconWebcam = (data, layout, webcamsCurrentReducer) => {
    let findIcon = SENSOR_STATUS.find(
      (status) =>
        status.name ===
        data.properties.cctvWithLastStateAndOpenAlarms.last_state
          .diagnosticState
    );
    return findIcon ? findIcon.mapIcon : SENSOR_STATUS[2].mapIcon;
  };

  static getIconPmv = (data) => {
    let findIcon = PMV_STATUS.find(
      (status) =>
        data.properties &&
        data.properties.anagrafica &&
        typeof data.properties.anagrafica.stato === "string" &&
        status.name.toLowerCase() ===
          data.properties.anagrafica.stato.toLowerCase()
    );
    return findIcon ? findIcon.mapIcon : PMV_STATUS[2].mapIcon;
  };

  static getIconSeverityEvents = (data) => {
    if (data && data.severity) {
      switch (data.severity.toUpperCase()) {
        case selectFields.GRAVITA.properties[0].name:
          return severity_red;
        case selectFields.GRAVITA.properties[1].name:
          return severity_orange;
        case selectFields.GRAVITA.properties[3].name:
          return severity_green;
        case selectFields.GRAVITA.properties[4].name:
          return severity_dark_green;
        case selectFields.GRAVITA.properties[5].name:
          return severity_grey;
        case selectFields.GRAVITA.properties[2].name:
        default:
          return severity_yellow;
      }
    } else {
      return null;
    }
  };

  // get marker point distance
  static getMarkerPointsDistance(list, fnTooltip) {
    return new IconLayer({
      id: `point-draw-distance`,
      data: list,
      pickable: true,
      ...this.loadOptions(),
      getIcon: () => ({
        url: pointDraw,
        width: 50,
        height: 40,
        anchorX: 25,
        anchorY: 20,
      }),
      sizeScale: 4,
      visible: true,
      getPosition: (d) => [d.coordsEnd[0], d.coordsEnd[1]],
      getSize: () => 4,
      onHover: ({ object, x, y }) =>
        fnTooltip ? fnTooltip(object, x, y) : null,
    });
  }

  // get marker points draw
  static getMarkerPointsDraw(list) {
    return new IconLayer({
      id: `point-draw-polygon`,
      data: list,
      pickable: true,
      ...this.loadOptions(),
      getIcon: () => ({
        url: pointDraw,
        width: 50,
        height: 40,
        anchorX: 25,
        anchorY: list.length > 1 ? 20 : 40,
      }),
      sizeScale: 4,
      visible: true,
      getPosition: (d) => [d[0], d[1]],
      getSize: () => 4,
    });
  }

  // get line construction site
  static getLineContructionSite(list, fnTooltip, fnClick) {
    return new LineLayer({
      id: `line`,
      data: list,
      pickable: true,
      sizeScale: 8,
      showCluster: true,
      visible: true,
      getSourcePosition: (d) => [d.pos.lon, d.pos.lat],
      getTargetPosition: (d) => [d.pos_end.lon, d.pos_end.lat],
      getColor: (d) => [255, 0, 0],
      getSize: () => 6,
      getWidth: () => 5,
      onHover: ({ object, x, y }) =>
        fnTooltip ? fnTooltip(object, x, y) : null,
      onClick: (object) => (fnClick ? fnClick(object.object) : null),
    });
  }

  // get path layer for traffic
  static getPathLayer(data, fnTooltip, fnClick) {
    return new PathLayer({
      id: "path-layer",
      data: data,
      pickable: true,
      rounded: true,
      widthScale: 3,
      widthMinPixels: 1,
      getPath: (d) => d.path,
      getWidth: (d) => this.WIDTH_SCALE(d.traffic),
      getColor: (d) => this.COLOR_SCALE(d.traffic),
      onHover: ({ object, x, y }) =>
        fnTooltip ? fnTooltip(object, x, y) : null,
      onClick: (object) => (fnClick ? fnClick(object.object) : null),
    });
  }

  // get bitmap layer for tpl map
  static getBitmapLayer(mapBounds) {
    return new BitmapLayer({
      id: "bitmap-layer",
      bounds: mapBounds,
      image: ATV_mappa_suburbana,
      pickable: true,
    });
  }

  static iconBus = (obj, layoutMap) => {
    let icnBus = darkBusImageRed;
    switch (layoutMap) {
      case "light":
        if (
          obj.vehicleActivityLocation &&
          obj.vehicleActivityLocation.monitoredVehicleJourney &&
          obj.vehicleActivityLocation.monitoredVehicleJourney.delay &&
          !obj.vehicleActivityLocation.monitoredVehicleJourney.delay.advance &&
          obj.vehicleActivityLocation.monitoredVehicleJourney.delay.delay >= 1
        ) {
          icnBus = busImageLate;
        }
        if (
          obj.transportMode &&
          obj.transportMode.subMode &&
          obj.transportMode.subMode.code === TYPE_TRANSPORT_MODE["extraurb"]
        ) {
          obj.selected
            ? (icnBus = lightBusImageSelectedBlue)
            : (icnBus = lightBusImageBlue);
          if (
            obj.vehicleActivityLocation &&
            obj.vehicleActivityLocation.monitoredVehicleJourney &&
            obj.vehicleActivityLocation.monitoredVehicleJourney.delay &&
            !obj.vehicleActivityLocation.monitoredVehicleJourney.delay
              .advance &&
            obj.vehicleActivityLocation.monitoredVehicleJourney.delay.delay >= 1
          ) {
            obj.selected
              ? (icnBus = busImageSelectedRedLate)
              : (icnBus = busImageLate);
          }
        } else if (
          obj.transportMode &&
          obj.transportMode.subMode &&
          obj.transportMode.subMode.code === TYPE_TRANSPORT_MODE["urb"]
        ) {
          obj.selected
            ? (icnBus = lightBusImageSelectedRed)
            : (icnBus = lightBusImageRed);
          if (
            obj.vehicleActivityLocation &&
            obj.vehicleActivityLocation.monitoredVehicleJourney &&
            obj.vehicleActivityLocation.monitoredVehicleJourney.delay &&
            !obj.vehicleActivityLocation.monitoredVehicleJourney.delay
              .advance &&
            obj.vehicleActivityLocation.monitoredVehicleJourney.delay.delay >= 1
          ) {
            obj.selected
              ? (icnBus = busImageSelectedRedLate)
              : (icnBus = busImageLate);
          }
        }
        return icnBus;

      case "dark":
      default:
        if (
          obj.vehicleActivityLocation &&
          obj.vehicleActivityLocation.monitoredVehicleJourney &&
          obj.vehicleActivityLocation.monitoredVehicleJourney.delay &&
          !obj.vehicleActivityLocation.monitoredVehicleJourney.delay.advance &&
          obj.vehicleActivityLocation.monitoredVehicleJourney.delay.delay >= 1
        ) {
          icnBus = busImageLate;
        }
        if (
          obj.transportMode &&
          obj.transportMode.subMode &&
          obj.transportMode.subMode.code === TYPE_TRANSPORT_MODE["extraurb"]
        ) {
          obj.selected
            ? (icnBus = darkBusImageSelectedBlue)
            : (icnBus = darkBusImageBlue);
          if (
            obj.vehicleActivityLocation &&
            obj.vehicleActivityLocation.monitoredVehicleJourney &&
            obj.vehicleActivityLocation.monitoredVehicleJourney.delay &&
            !obj.vehicleActivityLocation.monitoredVehicleJourney.delay
              .advance &&
            obj.vehicleActivityLocation.monitoredVehicleJourney.delay.delay >= 1
          ) {
            obj.selected
              ? (icnBus = busImageSelectedBlueLate)
              : (icnBus = busImageLate);
          }
        } else if (
          obj.transportMode &&
          obj.transportMode.subMode &&
          obj.transportMode.subMode.code === TYPE_TRANSPORT_MODE["urb"]
        ) {
          obj.selected
            ? (icnBus = darkBusImageSelectedRed)
            : (icnBus = darkBusImageRed);
          if (
            obj.vehicleActivityLocation &&
            obj.vehicleActivityLocation.monitoredVehicleJourney &&
            obj.vehicleActivityLocation.monitoredVehicleJourney.delay &&
            !obj.vehicleActivityLocation.monitoredVehicleJourney.delay
              .advance &&
            obj.vehicleActivityLocation.monitoredVehicleJourney.delay.delay >= 1
          ) {
            obj.selected
              ? (icnBus = busImageSelectedRedLate)
              : (icnBus = busImageLate);
          }
        }
        return icnBus;
    }
  };

  static getIconStop = (type, isParkingPoint, layoutMap) => {
    let typeValue = "";
    if (type && type.length === 2) {
      typeValue = "bothUandE";
    } else if (type && type.length === 1) {
      typeValue = type[0];
    }
    switch (layoutMap) {
      case "light":
        switch (typeValue) {
          case TYPE_TRANSPORT_MODE["extraurb"]:
            return isParkingPoint ? lightStopEcap : lightStopE;
          case "bothUandE":
            return isParkingPoint ? lightStopUEcap : lightStopUE;
          case TYPE_TRANSPORT_MODE["urb"]:
            return isParkingPoint ? lightStopUcap : lightStopU;
          default:
            return lightStopU;
        }

      case "dark":
      default:
        switch (typeValue) {
          case TYPE_TRANSPORT_MODE["extraurb"]:
            return isParkingPoint ? darkStopEcap : darkStopE;
          case "bothUandE":
            return isParkingPoint ? darkStopUEcap : darkStopUE;
          case TYPE_TRANSPORT_MODE["urb"]:
            return isParkingPoint ? darkStopUcap : darkStopU;
          default:
            return darkStopU;
        }
    }
  };

  static getSelectedIconStop = (type, isParkingPoint, layoutMap) => {
    let typeValue = "";
    if (type.length === 2) {
      typeValue = "bothUandE";
    } else if (type.length === 1) {
      typeValue = type[0];
    }
    switch (layoutMap) {
      case "light":
        switch (typeValue) {
          case TYPE_TRANSPORT_MODE["extraurb"]:
            return isParkingPoint ? lightSelectedStopEcap : lightSelectedStopE;
          case "bothUandE":
            return isParkingPoint
              ? lightSelectedStopUEcap
              : lightSelectedStopUE;
          case TYPE_TRANSPORT_MODE["urb"]:
          default:
            return isParkingPoint ? lightSelectedStopUcap : lightSelectedStopU;
        }

      case "dark":
      default:
        switch (typeValue) {
          case TYPE_TRANSPORT_MODE["extraurb"]:
            return isParkingPoint ? darkSelectedStopEcap : darkSelectedStopE;
          case "bothUandE":
            return isParkingPoint ? darkSelectedStopUEcap : darkSelectedStopUE;
          case TYPE_TRANSPORT_MODE["urb"]:
          default:
            return isParkingPoint ? darkSelectedStopUcap : darkSelectedStopU;
        }
    }
  };

  static filterData = (type) => (data) => {
    return data && data.features
      ? {
          ...data,
          features: data.features.filter((item) => item.geometry.type === type),
        }
      : [];
  };

  static loadOptions = () => {
    return {
      loadOptions: {
        image: {
          type: "image",
        },
      },
    };
  };
}
