import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { take, tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import * as moment from "moment";
import "./style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";

//COMPONENTS
import StationModel from "reducers/installations/stations/stations.model";
import TableStationTopFilters from "components/layout/tables/station-table/table-station-top-filters";
import StationGraph from "components/layout/tables/station-table/station-graph/index";
import ListStationTable from "components/layout/tables/station-table/station-table-list/index.js";

//STORE
import { updateCurrentStation } from "reducers/installations/stations/stations.actions";
import {
  getSavedDateFrom,
  getSavedDateTo,
  isOpenNavTab,
  getCurrentStation,
  getSavedFilters,
} from "store";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
//UTILS
import { filterDataByVehicle } from "utils/util-station-graph";

const FILTER_TARGET = "stationFilters";

class StationsTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      prevData: null,
      measureType: "",
      defaultMeasure: null,
      currentMeasure: "",
      isOpenTable: false,
      tableCurrentStation: null,
    };
  }

  componentDidMount = () => {
    const { currentStation, dateFrom, dateTo } = this.props;
    this.apiService = createApiService(this.context);

    let defaultMeasureTrue = false;
    if (
      currentStation &&
      currentStation.supportedMeasure &&
      currentStation.supportedMeasure.length > 0 &&
      currentStation.supportedMeasure[0]
    ) {
      defaultMeasureTrue = currentStation.supportedMeasure[0];
    }
    let vehicleDataArray = [];

    if (dateFrom && dateTo) {
      this.setState({
        ...this.state,
        dateStart: dateFrom,
        dateEnd: dateTo,
      });

      let newDateStart = moment(dateFrom);
      let newDateEnd = moment(dateTo);
      let diff = newDateEnd.diff(newDateStart);
      let diffDuration = moment.duration(diff);
      let differentTime =
        diffDuration.days() * 60 * 24 +
        diffDuration.hours() * 60 +
        diffDuration.minutes();

      this.subscriptions.push(
        this.apiService
          .getStationDetail(
            currentStation && currentStation.stationId
              ? currentStation.stationId
              : null,
            newDateStart.toISOString(),
            differentTime > 4380 ? 4380 : differentTime
          )
          .pipe(
            take(1),
            tap((data) => {
              let stationsDetail = StationModel.from(data);

              if (!defaultMeasureTrue) {
                defaultMeasureTrue = stationsDetail.supportedMeasure[0];
              }

              const vehicleDataArray = filterDataByVehicle(
                stationsDetail,
                defaultMeasureTrue
              );
              this.setState({
                ...this.state,
                data: vehicleDataArray,
                defaultMeasure: defaultMeasureTrue,
                tableCurrentStation: stationsDetail,
              });
            }),
            catchError((error) => {
              console.error(error);
              this.setState({
                ...this.state,
                data: [],
                defaultMeasure: defaultMeasureTrue,
                tableCurrentStation: null,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    } else {
      const date = new Date();
      let newDateLessOneHour = new Date(date.getTime() - 1 * 60 * 60 * 1000);

      this.setState({
        ...this.state,
        dateStart: newDateLessOneHour,
        dateEnd: date,
      });

      this.subscriptions.push(
        this.apiService
          .getStationDetail(
            currentStation && currentStation.stationId
              ? currentStation.stationId
              : null,
            newDateLessOneHour.toISOString(),
            60
          )
          .pipe(
            take(1),
            tap((data) => {
              let stationsDetail = StationModel.from(data, 5);

              if (!defaultMeasureTrue) {
                defaultMeasureTrue = stationsDetail.supportedMeasure[0];
              }

              vehicleDataArray = filterDataByVehicle(
                stationsDetail,
                defaultMeasureTrue
              );

              this.setState({
                ...this.state,
                data: vehicleDataArray,
                defaultMeasure: defaultMeasureTrue,
                tableCurrentStation: stationsDetail,
              });
            }),
            catchError((error) => {
              this.setState({
                ...this.state,
                data: [],
                defaultMeasure: defaultMeasureTrue,
                tableCurrentStation: null,
              });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  componentDidUpdate = (prevProps) => {
    const { currentStation } = this.props;
    const { defaultMeasure, dateStart, dateEnd } = this.state;

    //UPDATE GRAPH WHEN NEW MEASURE FROM WS ARRIVES
    if (
      prevProps.currentStation &&
      currentStation &&
      prevProps.currentStation.id !== currentStation.id
    ) {
      let newDateStart = moment(dateStart);
      let newDateEnd = moment(dateEnd);
      let diff = newDateEnd.diff(newDateStart);
      let diffDuration = moment.duration(diff);
      let fromDaysToHoursInterval;
      let hoursInterval;
      fromDaysToHoursInterval =
        (diffDuration.days() * 60 * 24) / 15 +
        (diffDuration.hours() * 60) / 15 +
        diffDuration.minutes() / 15;
      hoursInterval = 1;
      let differentTime =
        diffDuration.days() * 60 * 24 +
        diffDuration.hours() * 60 +
        diffDuration.minutes();

      this.subscriptions.push(
        this.apiService
          .getStationDetail(
            currentStation && currentStation.stationId
              ? currentStation.stationId
              : null,
            dateStart.toISOString(),
            differentTime
          )
          .pipe(
            take(1),
            tap((data) => {
              let stationsDetail = StationModel.from(
                data,
                fromDaysToHoursInterval + hoursInterval
              );

              const vehicleDataArray = filterDataByVehicle(
                stationsDetail,
                defaultMeasure
              );
              this.setState({
                ...this.state,
                data: vehicleDataArray,
                tableCurrentStation: stationsDetail,
              });
            }),
            catchError((error) => {
              console.error(error);
              this.setState({
                ...this.state,
                data: [],
                tableCurrentStation: null,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  onChangeDateStart = (value) => {
    const { dateEnd, defaultMeasure } = this.state;
    const { currentStation, tableFilters, saveTableFilters } = this.props;

    let newDateStart = moment(value);
    let newDateEnd = moment(dateEnd);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);
    let fromDaysToHoursInterval;
    let hoursInterval;
    fromDaysToHoursInterval =
      (diffDuration.days() * 60 * 24) / 15 +
      (diffDuration.hours() * 60) / 15 +
      diffDuration.minutes() / 15;
    hoursInterval = 1;
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();

    if (differentTime <= 4320 && differentTime >= 0) {
      saveTableFilters(
        {
          ...tableFilters,
          dateFrom: moment(value).isAfter(new Date()) ? new Date() : value,
        },
        FILTER_TARGET
      );
      this.subscriptions.push(
        this.apiService
          .getStationDetail(
            currentStation && currentStation.stationId
              ? currentStation.stationId
              : null,
            value.toISOString(),
            differentTime
          )
          .pipe(
            take(1),
            tap((data) => {
              let stationsDetail = StationModel.from(
                data,
                fromDaysToHoursInterval + hoursInterval
              );

              const vehicleDataArray = filterDataByVehicle(
                stationsDetail,
                defaultMeasure
              );
              this.setState({
                ...this.state,
                data: vehicleDataArray,
                dateStart: moment(value).isAfter(new Date())
                  ? new Date()
                  : value,
                tableCurrentStation: stationsDetail,
              });
            }),
            catchError((error) => {
              console.error(error);
              this.setState({
                ...this.state,
                dateStart: value,
                data: [],
                tableCurrentStation: null,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    } else {
      let newDateMoreHour = new Date(value.getTime() + 1 * 60 * 60 * 1000);

      saveTableFilters(
        {
          ...tableFilters,
          dateFrom: value,
          dateTo: moment(value).isSame(moment(new Date()).toDate(), "day")
            ? moment(value).isAfter(new Date())
              ? new Date()
              : value
            : newDateMoreHour,
        },
        FILTER_TARGET
      );

      this.subscriptions.push(
        this.apiService
          .getStationDetail(
            currentStation && currentStation.stationId
              ? currentStation.stationId
              : null,
            value.toISOString(),
            60
          )
          .pipe(
            take(1),
            tap((data) => {
              let stationsDetail = StationModel.from(data, 5);

              const vehicleDataArray = filterDataByVehicle(
                stationsDetail,
                defaultMeasure
              );

              this.setState({
                ...this.state,
                data: vehicleDataArray,
                dateStart: value,
                dateEnd: moment(value).isSame(
                  moment(new Date()).toDate(),
                  "day"
                )
                  ? moment(value).isAfter(new Date())
                    ? new Date()
                    : value
                  : newDateMoreHour,
                tableCurrentStation: stationsDetail,
              });
            }),
            catchError((error) => {
              console.error(error);
              this.setState({ ...this.state, dateStart: value, data: [] });
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  onChangeDateEnd = (value) => {
    const { dateStart, defaultMeasure } = this.state;
    const { currentStation, tableFilters, saveTableFilters } = this.props;

    let newDateStart = moment(dateStart);
    let newDateEnd = moment(value);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);

    let fromDaysToHoursInterval;
    let hoursInterval;
    fromDaysToHoursInterval =
      (diffDuration.days() * 60 * 24) / 15 +
      (diffDuration.hours() * 60) / 15 +
      diffDuration.minutes() / 15;
    hoursInterval = 1;
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();

    if (differentTime <= 4320 && differentTime > 0) {
      saveTableFilters(
        {
          ...tableFilters,
          dateTo: value,
        },
        FILTER_TARGET
      );
      this.subscriptions.push(
        this.apiService
          .getStationDetail(
            currentStation && currentStation.stationId
              ? currentStation.stationId
              : null,
            dateStart.toISOString(),
            differentTime
          )
          .pipe(
            take(1),
            tap((data) => {
              let stationsDetail = StationModel.from(
                data,
                fromDaysToHoursInterval + hoursInterval
              );
              const vehicleDataArray = filterDataByVehicle(
                stationsDetail,
                defaultMeasure
              );

              this.setState({
                ...this.state,
                data: vehicleDataArray,
                dateEnd: value,
                tableCurrentStation: stationsDetail,
              });
            }),
            catchError((error) => {
              this.setState({
                ...this.state,
                dateEnd: value,
                data: [],
                tableCurrentStation: null,
              });

              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    } else {
      saveTableFilters(
        {
          ...tableFilters,
          dateTo: value,
        },
        FILTER_TARGET
      );
      this.setState({ dateEnd: value });
    }
  };

  handleSelectMeasure = (value) => {
    const { tableCurrentStation } = this.state;
    if (tableCurrentStation) {
      let newDefaultMeasure = tableCurrentStation.supportedMeasure.find(
        (item) => {
          return item.name === value;
        }
      );

      const vehicleDataArray = filterDataByVehicle(
        tableCurrentStation,
        newDefaultMeasure
      );

      this.setState({
        ...this.state,
        data: vehicleDataArray,
        defaultMeasure: newDefaultMeasure,
      });
    }
  };

  toggleTable = () => {
    this.setState({
      ...this.state,
      isOpenTable: true,
    });
  };

  toggleStatistics = () => {
    this.setState({
      ...this.state,
      isOpenTable: false,
    });
  };

  refreshData = () => {
    const { dateStart, dateEnd, defaultMeasure } = this.state;
    const { currentStation } = this.props;
    let newDateStart = moment(dateStart);
    let newDateEnd = moment(dateEnd);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);

    let fromDaysToHoursInterval;
    let hoursInterval;
    fromDaysToHoursInterval =
      (diffDuration.days() * 60 * 24) / 15 +
      (diffDuration.hours() * 60) / 15 +
      diffDuration.minutes() / 15;
    hoursInterval = 1;
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();

    this.subscriptions.push(
      this.apiService
        .getStationDetail(
          currentStation && currentStation.stationId
            ? currentStation.stationId
            : null,
          dateStart.toISOString(),
          differentTime
        )
        .pipe(
          take(1),
          tap((data) => {
            let stationsDetail = StationModel.from(
              data,
              fromDaysToHoursInterval + hoursInterval
            );

            const vehicleDataArray = filterDataByVehicle(
              stationsDetail,
              defaultMeasure
            );

            this.setState({
              ...this.state,
              data: vehicleDataArray,
              tableCurrentStation: stationsDetail,
            });
          }),
          catchError((error) => {
            this.setState({
              ...this.state,
              data: [],
              tableCurrentStation: null,
            });
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  render = () => {
    const {
      currentStation,
      openStationGraph,
      toggleGenericTable,
      isOpenNav,
    } = this.props;
    const {
      data,
      dateStart,
      dateEnd,
      defaultMeasure,
      isOpenTable,
    } = this.state;

    return (
      <div className="acr-table uk-flex uk-flex-column">
        <div className="navbar-placeholder"></div>
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <div className="grey-space"></div>
          <div className=" uk-width-1-2">
            <ul className=" uk-tab-page-secondary-large uk-tab uk-margin-remove">
              <li className="uk-active bottombar-title">
                {currentStation && currentStation.archName}
              </li>
            </ul>
          </div>
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={() => toggleGenericTable("stationsTable")}
            ></span>
          </div>
        </div>

        <TableStationTopFilters
          className="uk-form-small uk-padding-small select-style"
          dateStart={dateStart}
          dateFormat={"dd/MM/yyyy HH:mm"}
          timeFormat={"HH:mm"}
          iconRatio={isOpenNav ? "ratio: 0.8;" : "ratio: 1.5"}
          dateEnd={dateEnd}
          changeDateStart={(data) => this.onChangeDateStart(data, data)}
          changeDateEnd={(data) => this.onChangeDateEnd(data, data)}
          isSameDate={
            dateStart &&
            dateEnd &&
            dateStart.getDate() === dateEnd.getDate() &&
            dateStart.getMonth() === dateEnd.getMonth()
              ? true
              : null
          }
          isStartDate={true}
          defaultMeasure={defaultMeasure}
          handleSelectMeasure={this.handleSelectMeasure}
          currentStation={currentStation}
          openStationGraph={openStationGraph}
          toggleTable={this.toggleTable}
          toggleStatistics={this.toggleStatistics}
          isOpenTable={isOpenTable}
          refreshData={this.refreshData}
        />
        {isOpenTable ? (
          <ListStationTable
            dateStart={dateStart}
            dateEnd={dateEnd}
            defaultMeasure={defaultMeasure}
          />
        ) : (
          <StationGraph
            dateStart={dateStart}
            dateEnd={dateEnd}
            defaultMeasure={defaultMeasure}
            data={data}
          />
        )}
      </div>
    );
  };
}

StationsTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  saveTableFilters,
  resetTableFilters,
  updateCurrentStation,
  toggleGenericTable,
};

const mapStateToProps = (state) => ({
  currentStation: getCurrentStation(state),
  isOpenNav: isOpenNavTab(state),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StationsTable)
);
