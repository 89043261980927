import React, { Component } from "react";
import "./style.less";

class Scale extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { center, scale, meters } = this.props;

    return (
      <div className="scale-container">
        <div className="uk-width-1-1 uk-height-1-1 uk-card uk-card-primary uk-flex uk-flex-row uk-flex-middle">
          <div className="uk-width-1-2 uk-flex uk-flex-column uk-flex-middle">
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <h6 className="scale-text">Scala </h6>
              <h6 className="scale-text-important">
                1: {Math.round(scale * 1000) / 1000}
              </h6>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <h6 className="scale-text">Lat: </h6>
              <h6 className="scale-text-important">
                {center ? Math.round(center.lat * 1000000) / 1000000 : ""}
              </h6>
            </div>
          </div>
          <div className="uk-divider-vertical" />
          <div className="uk-width-1-2 uk-flex uk-flex-column uk-flex-middle">
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <h6 className="scale-text-important">
                {meters && meters > 1000
                  ? Math.round((Math.round(meters) / 1000) * 100) / 100 +
                    " Km  "
                  : Math.round(meters) + " m  "}
              </h6>
              <div className="scale-bar"></div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <h6 className="scale-text">Lon: </h6>
              <h6 className="scale-text-important">
                {center ? Math.round(center.lng * 1000000) / 1000000 : ""}
              </h6>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Scale;
