import { from } from "rxjs";
import EventSource from "eventsource";
import { switchMap, retryWhen, concatMap, delay } from "rxjs/operators";
import { of, iif, throwError } from "rxjs";

export default class ApiService {
  loadedEnvironment;
  evtSourceVehicle;
  evtSourceVehicleLocation;
  evtSourceCameraAlarm;
  evtSourceEvent;
  scopeType = "network";
  networkId = "TSPNET00000000000097";

  eventSourceInitDict = {
    headers: { "X-Auth-Token": sessionStorage.getItem("id_token") },
  };


  //GEOSERVER
  geoserverOutputFormat = "application/json";

  //HEADERS
  genericHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("access_token"),
    "X-Auth-Token": sessionStorage.getItem("id_token"),
    "Access-Control-Allow-Origin": "*",
  };
  downloadHeaders = {
    Accept: "application/octet-stream",
    "Content-Type": "application/json",
    //  "Content-Disposition": "attachment",
    "X-Auth-Token": sessionStorage.getItem("id_token"),
    "Access-Control-Allow-Origin": "*",
  };

  graphHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("access_token"),
    "Access-Control-Allow-Origin": "*",
  };
  userManagerHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Auth-Token": sessionStorage.getItem("id_token"),
    Authorization: "Bearer " + sessionStorage.getItem("access_token"),
    "Access-Control-Allow-Origin": "*",
  };
  pmvHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "X-Auth-Token": sessionStorage.getItem("id_token"),
    "Access-Control-Allow-Origin": "*",
    Authorization: "Basic YWxtYXZpdmFfYWNyOmFbTHhuVDVZTG1odTNTJEA=",
  };

  constructor(environment) {
    this.loadedEnvironment = environment;
    this.middlewareApi = environment.endpointMiddleware;

    //FASE 1
    //NETWORK
    this.networksEndpoint =
      environment.endpoint + environment.typedURIs.typeApiNetwork + "networks";
    //CONFIG
    this.anagraficaTPLEndpoint =
      environment.endpoint +
      environment.typedURIs.typeApiNetwork +
      `VehicleMonitoring/scope/${this.scopeType}/${this.networkId}`;
    //CORSA
    this.listaCorseEndpoint =
      environment.endpoint + environment.typedURIs.typeApiVehicle + "TimeFrameMonitoring/scope/";
    this.dettaglioCorsaVeicoloEndpoint =
      environment.endpoint + "dettaglioCorsa/";
    //LINEA
    this.dettaglioLineaCorseAttiveEndpoint =
      environment.endpoint +
      environment.typedURIs.typeApiNetwork +
      "/VehicleMonitoring/scope/line/";
    this.listaLineeEndpoint =
      environment.endpoint + environment.typedURIs.typeApiNetwork + "networks/";
    this.dettaglioLineaEndpoint =
      environment.endpoint + environment.typedURIs.typeApiNetwork + "lines/";
    this.geoJsonLineaEndpoint =
      environment.endpoint + environment.typedURIs.typeApiNetwork + "networks/";
    this.listaRotteEndpoint =
      environment.endpoint + environment.typedURIs.typeApiNetwork + "lines/";
    //FERMATA
    this.listaFermateEndpoint =
      environment.endpoint + environment.typedURIs.typeApiNetwork + "networks/";
    this.listaCorseFermateEndpoint =
      environment.endpoint + environment.typedURIs.typeApiVehicle + "StopMonitoring/";
    this.dettaglioFermataEndpoint =
      environment.endpoint + environment.typedURIs.typeApiNetwork + "points/";
    //VEICOLI
    this.dettaglioVeicoliEndpoint =
      environment.endpoint + environment.typedURIs.typeApiVehicle + "VehicleMonitoring/";
    this.vehicleEventsRuntimeLocationWS =
      environment.endpoint +
      environment.typedURIs.typeVehicleActivityLocation +
      "websocket?lineIds=";
    this.vehicleEventsRuntimeWS =
      environment.endpoint + environment.typedURIs.typeVehicleActivity + "websocket?lineIds=";
    this.storicoVeicoliEndpoint = environment.endpoint + "getStoricoVeicoli";
    this.listaVeicoliWSEndpoint = environment.endpointWS + "listaVeicoli";
    this.listaVeicoliEndpoint = environment.endpoint + "getListaVeicoli";
    //KPI
    this.kpiEndpoint = environment.endpoint + environment.typedURIs.typeApiKpi;
    this.kpiWSEndpoint = environment.endpointWS + environment.typedURIs.typeApiKpi + "kpiEventi";

    //FASE 2
    //EVENTI
    this.situationRecords = environment.endpointGeneral + "event/0.0.1/";
    this.configEvents = environment.endpointGeneral + "config/0.0.1/";
    this.associatedEvents = environment.endpointGeneral + "event/situation/";
    this.eventsRuntimeWS =
      environment.endpoint + environment.typedURIs.typeWsEvent + "situation-record";
    this.causeEvents = environment.endpointGeneral + "event/situation-record";
    this.mwEventExcel = this.middlewareApi + "events/situation-record/export";

    //mocked event
    // this.mockedEventLoginInternal = environment.roadEventsUris.loginInternalUri;
    // this.mockedEventLoginExternal = environment.roadEventsUris.loginExternalUri;
    // this.mockedEvent1 = environment.roadEventsUris.eventsInternalUri;
    // this.mockedEvent2 = environment.roadEventsUris.eventsExternalUri;

    //PANNELLI
    this.trafficEndpoint = environment.endpointGeneral + "traffic";
    this.pmvEndPoint = environment.endpointFamas;

    //GRAFO
    this.geoserverEndpoint =
      environment.endpointGeneral + "geos-api/geoserver/";
    this.routeDetailsOnTratta =
      environment.endpointGeneral +
      environment.geoRouting.geoRouting +
      "tmc/dettaglioPercorsoOnTratta";
    this.pointEventTratta =
      environment.endpointGeneral + environment.geoRouting.geoRouting + "tmc/pointEventOnTratta";
    this.segmentEventTratta =
      environment.endpointGeneral +
      environment.geoRouting.geoRouting +
      "tmc/segmentEventOnTratta";
    this.pathControllerSources =
      environment.endpointGeneral + environment.geoRouting.geoRouting + "path/trsp/latlng";
    this.pathControllerNodes =
      environment.endpointGeneral + environment.geoRouting.geoRouting + "path/trsp/node";
    this.routeControllerSources =
      environment.endpointGeneral + environment.geoRouting.geoRouting + "route/trsp/latlng";
    this.routeControllerNodes =
      environment.endpointGeneral + environment.geoRouting.geoRouting + "route/trsp/node";
    //GEO-ROUTING-ENGINE V2
    this.pointEventSection =
      environment.endpointGeneral +
      environment.geoRouting.geoRouting +
      "tmc/v2/pointEventOnSection";
    this.segmentEventSection =
      environment.endpointGeneral +
      environment.geoRouting.geoRouting +
      "tmc/v2/segmentEventOnSection";
    this.routDetailOnSection =
      environment.endpointGeneral +
      environment.geoRouting.geoRouting +
      "tmc/v2/routeDetailOnSection";

    //TRAFFIC
    this.stationStatusRuntimeWS =
      environment.endpoint + environment.typedURIs.typeWsTraffic + "sse/sensor-status";
    this.stationMeasuresRuntimeWS =
      environment.endpoint + environment.typedURIs.typeWsTraffic + "sse/sensor-measure";
    this.trafficNotificationRuntimeWS =
      environment.endpoint + environment.typedURIs.typeWsTraffic + "sse/simulation-notification";
    this.mwStationExcel = this.middlewareApi + "traffic/station/detail/";
    this.mwTrafficExcel = this.middlewareApi + "traffic/elaborated-data/arch/";

    //PARKING
    this.parkingRecordEndpoint = this.middlewareApi + "parking/parking-record/";
    this.parkingRecordStatusEndpoint =
      this.middlewareApi + "parking/parking-record-status/";
    this.parkingRecordStatusSSEEndpoint =
      this.middlewareApi + "parking/sse/parking-record-status";
    this.parkingContact = this.middlewareApi + "contact";
    this.mwGeoserverApi = this.middlewareApi + "geoserver/";

    //USER ACTIVITY
    this.userActivityEndpoint =
      environment.endpointGeneral + "client-elastic/v1/";
    this.userManagerEndpoint = environment.authConfig.userManager + "/";
    this.mwUserActvity =
      environment.endpointMiddleware + "activity-log/activity/";

    //CAMERA
    this.cameraEndpoint = environment.endpointGeneral + "infhub/road-cctv/";
    this.cameraAlarmSSEEndpoint =
      environment.endpointGeneral + "infhub/sse/road-cctv/alarm";
    this.cameraStatusSSEEndpoint =
      environment.endpointGeneral + "infhub/sse/road-cctv/status";
    this.mwCameraExcel = environment.endpointMiddleware + "cctv/all";

    //WORKSPACE
    this.workspaceEndpoint = environment.endpointGeneral + "infhub/workspace";
    //
    this.mwPmvExcel = environment.endpointMiddleware + "panels/";
  }

  encodeURIParams = (params) => {
    return Object.keys(params)
      .map((key) => {
        if (
          (typeof params[key] === "number" && params[key] >= 0) ||
          !!params[key]
        ) {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        }
        return null;
      })
      .filter((item) => item)
      .join("&");
  };

  handleErrors(response) {
    if (!response.ok) {
      if (
        response.status &&
        (response.status === 401 || response.status === 403)
      ) {
        //sessionStorage.clear();
        //location.reload();
        //window.location.replace(window.location.href);
      }
      if (response.headers.get('errore-message') || response.status === 500) {
        throw new Error(
          response.status
            ? alert(response.status.toString() + " - " + 'Error during ftp client initialization')
            : response.toString()
        );
      } else {
        throw new Error(
          response.status
            ? response.status.toString() + " - " + response.statusText
            : response.toString()
        );
      }
    }
    return response;
  }

  //EVENT MOCKED
  //GET LIST EVENT MOCKED
  // listEventMocked = (bearer) => {
  //   return from(
  //     fetch(this.mockedEvent1, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + bearer,
  //       },
  //     })
  //       .then((data) => this.handleErrors(data))
  //       .then((data) => data.json())
  //       .catch((error) => {
  //         throw error;
  //       })
  //   );
  // };

  // //GET LIST EVENT MOCKED2
  // listEventMocked2 = (bearer) => {
  //   return from(
  //     fetch(this.mockedEvent2, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + bearer,
  //       },
  //     })
  //       .then((data) => this.handleErrors(data))
  //       .then((data) => data.json())
  //       .catch((error) => {
  //         throw error;
  //       })
  //   );
  // };

  //LOGIN EVENT MOCKED
  // loginEventMocked = () => {
  //   return from(
  //     fetch(this.mockedEventLoginInternal, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((data) => this.handleErrors(data))
  //       .then((data) => data.json())
  //       .catch((error) => {
  //         throw error;
  //       })
  //   );
  // };

  /* ********************** */
  /* REQUEST REST ENDPOINTS */
  /* ********************** */

  //GET NETWORKS
  getAllNetworks = () => {
    return from(
      fetch(this.networksEndpoint + "?excludeLines=true", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETAILS NETWORK
  getNetworkDetail = (idNet) => {
    return from(
      fetch(this.networksEndpoint + "/" + idNet, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET KPI INDICATOR
  getKpiMonitoredIndicator = (networkId) => {
    return from(
      fetch(
        this.kpiEndpoint +
        "monitoredvehiclejourney-ontimeindicator/network/" +
        networkId +
        "?intervalDuration=60",
        {
          method: "GET",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET KPI OVERALL
  getKpiOverall = (networkId) => {
    return from(
      fetch(
        this.kpiEndpoint +
        "vehiclejourney-summary/network/" +
        networkId +
        "?intervalDuration=5",
        {
          method: "GET",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETTAGLIO VEICOLI
  getDettaglioVeicoli = (dataFrameId, dataVehicleJourneyId) => {
    return from(
      fetch(
        this.dettaglioVeicoliEndpoint +
        dataFrameId +
        "/" +
        dataVehicleJourneyId +
        "?VehicleMonitoringDetailLevel=full",
        {
          method: "GET",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA VEICOLI
  getListaVeicoli = () => {
    return from(
      fetch(this.listaVeicoliEndpoint, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA LINEE

  getListaLinee = (networkId) => {
    return from(
      fetch(this.listaLineeEndpoint + networkId + "/lines", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA FERMATE

  getListaFermate = (networkId) => {
    return from(
      fetch(this.listaFermateEndpoint + networkId + "/points", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET FILTER POINT
  getFilterPoint = (pointId) => {
    return from(
      fetch(this.kpiEndpoint + "filter/points/" + pointId, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };
  //GET FILTER ROUTE
  getFilterRoute = (lineId, routeId) => {
    return from(
      fetch(this.kpiEndpoint + "filter/routes/" + lineId + "/" + routeId, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };
  //GET FILTER CORSE
  getFilterTrip = (tripId) => {
    return from(
      fetch(this.kpiEndpoint + "filter/mvjs/" + tripId, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA CORSE

  getListaCorse = (scopeType, scopeId, startTime, minutes) => {
    return of(true).pipe(
      switchMap((x) =>
        fetch(
          this.listaCorseEndpoint +
          scopeType +
          "/" +
          scopeId +
          "?StartTime=" +
          startTime.toISOString() +
          "&PreviewInterval=PT" +
          minutes +
          "M&VehicleMonitoringDetailLevel=calls",
          {
            method: "GET",
            headers: this.genericHeaders,
          }
        )
          .then((data) => this.handleErrors(data))
          .then((data) => data.json())
          .catch((error) => {
            throw error;
          })
      ),
      retryWhen((errors) =>
        errors.pipe(
          // Use concat map to keep the errors in order and make sure they
          // aren't executed in parallel
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => i > 5,
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              of(e).pipe(delay(1000))
            )
          )
        )
      )
    );
  };

  //GET DETTAGLIOFERMATA

  getDettaglioFermata = (id) => {
    return from(
      fetch(this.dettaglioFermataEndpoint + id + "/extended", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET GEOJSON LINEA

  getGeoJsonLinea = (id) => {
    return from(
      fetch(this.geoJsonLineaEndpoint + id + "/geo?excludePoints=true", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA CORSE FERMATE

  getListaCorseFermate = (id, startTime, minutes) => {
    return from(
      fetch(
        this.listaCorseFermateEndpoint +
        id +
        "?StartTime=" +
        startTime.toISOString() +
        "&PreviewInterval=PT" +
        minutes +
        "M&VehicleMonitoringDetailLevel=normal",
        {
          method: "GET",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET DETTAGLIO CORSE PER VEICOLO

  getDettaglioCorsaVeicolo = (id) => {
    return from(
      fetch(this.dettaglioCorsaVeicoloEndpoint + id, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LISTA ROTTE
  getListaRotte = (lines) => {
    return from(
      Promise.all(
        lines.map((line) => {
          if (line && line.id) {
            return fetch(this.listaRotteEndpoint + line.id + "/extended", {
              method: "GET",
              headers: this.genericHeaders,
            })
              .then((data) => this.handleErrors(data))
              .then((data) => data.json())
              .catch((error) => {
                throw error;
              });
          }

          return null;
        })
      )
    );
  };

  //list SituationRecords

  getSituationRecords = (
    limit,
    offset,
    fromDateTime,
    stateRecords,
    toDateTime,
    filters,
    dateTarget,
    archIds,
    orderBy,
    orderType,
    search
  ) => {
    let url = this.situationRecords + "situation-record?";

    let params = {
      limit: limit,
      offset: offset,
      fromDateTime: fromDateTime,
      toDateTime: toDateTime,
      state: stateRecords,
      ...filters,
      dateFilterType: dateTarget,
      archIds: archIds,
      orderBy: orderBy,
      orderType: orderType,
      search: search,
    };

    Object.keys(params).forEach((param) => {
      if (
        !(
          (typeof params[param] === "number" && params[param] >= 0) ||
          !!params[param]
        )
      ) {
        delete params[param];
      }
    });

    let queryString = this.encodeURIParams(params);
    url += queryString;

    return of(true).pipe(
      switchMap((x) =>
        fetch(url, {
          method: "GET",
          headers: this.genericHeaders,
        })
          .then((data) => this.handleErrors(data))
          .then((data) => data.json())
          .catch((error) => {
            throw error;
          })
      ),
      retryWhen((errors) =>
        errors.pipe(
          // Use concat map to keep the errors in order and make sure they
          // aren't executed in parallel
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => i > 10,
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              of(e).pipe(delay(500))
            )
          )
        )
      )
    );
  };

  // INVIO ID EVENTI DA SELECT CHECKBOX
  postIdsRecord = (selectedEvents, inputField) => {
    let params = "idsRecord="
    for (let i in selectedEvents) {
      if (i < selectedEvents.length - 1)
        params += selectedEvents[i] + ","
      else
        params += selectedEvents[i]
    }
    if (!inputField)
      inputField = "cantieri"
    return from(
      fetch(this.situationRecords + "situation-record/createEventsDbf?" + params + "&fileName=" + inputField, {
        method: "POST",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.text().then(function (text) {
          alert("File " + text + " creato con successo!");
        }))
        .catch((error) => {
          throw error;
        })
    )
  }

  //add situation

  addSituation = (situation) => {
    return from(
      fetch(this.situationRecords + "situation", {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(situation),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //associeted situation with another situation

  associetedSituation = (situation) => {
    return from(
      fetch(this.situationRecords + "situation-record", {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(situation),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //list history SituationRecords by id

  getHistorySituationRecords = (
    situationRecordId,
    limit = 10,
    offset = 0,
    orderType
  ) => {
    return from(
      fetch(
        this.situationRecords +
        "history/" +
        situationRecordId +
        "?limit=" +
        limit +
        "&offset=" +
        offset +
        "&orderType=" +
        orderType,
        {
          method: "GET",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Update SituationRecords

  updateSituationRecord = (situationRecord) => {
    return from(
      fetch(this.situationRecords + "situation-record", {
        method: "PUT",
        headers: this.genericHeaders,
        body: JSON.stringify(situationRecord),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //Close SituationRecords

  closeSituationRecord = (bodySituation) => {
    return from(
      fetch(this.situationRecords + "situation-record/state", {
        method: "PATCH",
        headers: this.genericHeaders,
        body: JSON.stringify(bodySituation),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //config souces

  getConfigSources = () => {
    return from(
      fetch(this.configEvents + "sources", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //config types subtypes

  getConfigTypesSubtypes = (all) => {
    return from(
      fetch(
        this.configEvents +
        "type/subtypes" +
        (all ? "?withAllAttributes=true" : ""),
        {
          method: "GET",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //config attributes

  getConfigAttributes = (typeId, subTypeId) => {
    return from(
      fetch(
        this.configEvents +
        "type/" +
        typeId +
        "/subtype/" +
        subTypeId +
        "/attributes",
        {
          method: "GET",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //config impact

  getConfigImpact = (typeId, subTypeId, impactBody) => {
    return from(
      fetch(
        this.configEvents +
        "type/" +
        typeId +
        "/subtype/" +
        subTypeId +
        "/impact",
        {
          method: "POST",
          headers: this.genericHeaders,
          body: JSON.stringify(impactBody),
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //WS Event

  getEventsRuntime = (callback, error, open) => {
    this.evtSourceEvent = new EventSource(
      this.eventsRuntimeWS,
      this.eventSourceInitDict
    );
    this.evtSourceEvent.onerror = error;
    this.evtSourceEvent.onmessage = callback;
    this.evtSourceEvent.onopen = open;
  };

  closeEventsRuntime = () => {
    if (this.evtSourceEvent) this.evtSourceEvent.close();
  };

  //WS Vehicle

  getVehicleEventsRuntime = (arrayLineId, callback, error, open) => {
    this.evtSourceVehicle = new EventSource(
      this.vehicleEventsRuntimeWS + arrayLineId,
      this.eventSourceInitDict
    );
    this.evtSourceVehicle.onmessage = callback;
    this.evtSourceVehicle.onerror = error;
    this.evtSourceVehicle.onopen = open;
  };

  closeVehicleEventsRuntime = () => {
    if (this.evtSourceVehicle) this.evtSourceVehicle.close();
  };

  //WS Vehicle Location

  getVehicleEventsLocationRuntime = (arrayLineId, callback, error, open) => {
    this.evtSourceVehicleLocation = new EventSource(
      this.vehicleEventsRuntimeLocationWS + arrayLineId,
      this.eventSourceInitDict
    );
    this.evtSourceVehicleLocation.onmessage = callback;
    this.evtSourceVehicleLocation.onerror = error;
    this.evtSourceVehicleLocation.onopen = open;
  };

  closeVehicleEventsLocationRuntime = () => {
    if (this.evtSourceVehicleLocation) this.evtSourceVehicleLocation.close();
  };

  fetchEventExcel(
    exportFieldFilter,
    fromDateTime,
    toDateTime,
    state,
    filters,
    dateTarget,
    orderBy,
    orderType,
    search,
    limit,
    offset
  ) {
    let url = this.mwEventExcel + "/csv?";
    const params = {
      dateFilterType: dateTarget,
      limit: limit,
      offset: offset,
      orderBy: orderBy,
      orderType: orderType,
      fromDateTime: fromDateTime,
      toDateTime: toDateTime,
      state: state,
      ...filters,
      search: search,
    };

    const queryString = this.encodeURIParams(params);
    url += queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.downloadHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  }

  fetchEventCompleteHistoryExcel(
    exportFieldFilter,
    fromDateTime,
    toDateTime,
    state,
    filters,
    dateTarget,
    orderBy,
    orderType,
    search,
    limit,
    offset
  ) {
    let url = this.mwEventExcel + "/csv?";
    const params = {
      dateFilterType: dateTarget,
      limit: limit,
      offset: offset,
      orderBy: orderBy,
      orderType: orderType,
      fromDateTime: fromDateTime,
      toDateTime: toDateTime,
      state: state,
      ...filters,
      search: search,
    };

    const queryString = this.encodeURIParams(params);
    url += queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.downloadHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  }

  fetchEventDetailHistoryExcel(record, orderType, limit, offset) {
    let url = this.mwEventExcel + "/history/csv/" + record.situationRecordId + "?";
    const params = {
      limit: limit ? limit : 10,
      offset: offset ? offset : 0,
      orderType: orderType,
    };
    const queryString = this.encodeURIParams(params);
    url += queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.downloadHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  }

  getPdf = (link) => {
    let pdfString = "/12.pdf";
    window.open(pdfString, "_blank");
    return false;
    //return window.open(pdfString);//from(fetch(link, { method: "GET", headers: this.downloadHeaders }).then());
  };

  goToSite = (link) => {
    window.open(link);
    return false;
  };

  //GEOSERVER
  // la struttura dei dati contenuti in geoservice_ws e, per ciascun elemento, nome, se il campo è nillable ed i tipi degli attributi
  describeAllGeoserverFeatureTypes = () => {
    let request = "DescribeFeatureType";

    return from(
      fetch(
        this.geoserverEndpoint +
        "geoservice_ws/ows?service=" +
        this.loadedEnvironment.geoRouting.geoserverService +
        "&version=" +
        this.loadedEnvironment.geoRouting.geoserverVersion +
        "&request=" +
        request +
        "&outputFormat" +
        this.geoserverOutputFormat,
        {
          method: "GET",
          headers: this.graphHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // informazioni dei tipi per gli attributi di tutti i dati in geoserver_ws o di una singola origine dati
  describeGeoserverFeatureType = (typeName = "") => {
    let request = "DescribeFeatureType";
    let typeNameString = "";
    let typeServiceNameGis = "";

    if (typeName && (typeName === "points" || typeName === "roads")) {
      typeNameString = "geoservice_tmc_ws:" + typeName;
      typeServiceNameGis = "geoservice_tmc_ws/";
    } else if (typeName) {
      typeNameString = "geoservice_ws:" + typeName;
      typeServiceNameGis = "geoservice_ws/";
    }

    let params = {
      service: this.loadedEnvironment.geoRouting.geoserverService,
      version: this.loadedEnvironment.geoRouting.geoserverVersion,
      request: request,
      typeName: typeNameString,
    };

    let queryString = this.encodeURIParams(params);

    return from(
      fetch(
        this.geoserverEndpoint + typeServiceNameGis + "ows?" + queryString,
        {
          method: "GET",
          headers: this.graphHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // selezione di elementi da un'origine dati, inclusa la geometria e i valori degli attributi
  // può dare una lista o un elemento singolo
  getGeoserverFeatures = (
    typeName = "",
    featureID = "",
    maxFeatures = "",
    sortBy = "",
    propertyName = "",
    BBOX = "",
    cql_filter = "",
    startIndex = ""
  ) => {
    //NB maxFeatures diventa count dalla versione 2.0.0
    //NB service, version, request, typeNames sono obbligatori
    let request = "GetFeature";
    let typeNameString = "";
    let typeServiceNameGis = "";
    if (typeName && (typeName === "points" || typeName === "roads")) {
      typeNameString = "geoservice_tmc_ws:" + typeName;
      typeServiceNameGis = "geoservice_tmc_ws/";
    } else {
      typeNameString = "geoservice_ws:" + typeName;
      typeServiceNameGis = "geoservice_ws/";
    }

    let params = {
      service: this.loadedEnvironment.geoRouting.geoserverService,
      version: this.loadedEnvironment.geoRouting.geoserverVersion,
      request: request,
      typeName: typeNameString,
      featureID: featureID,
      maxFeatures: maxFeatures,
      sortBy: sortBy,
      propertyName: propertyName,
      BBOX: BBOX,
      outputFormat: this.geoserverOutputFormat,
      cql_filter: cql_filter,
      startIndex: startIndex,
      srsName: "EPSG:4326",
    };

    let queryString = this.encodeURIParams(params);

    return from(
      fetch(
        this.geoserverEndpoint + typeServiceNameGis + "ows?" + queryString,
        {
          method: "GET",
          headers: this.graphHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getRouteDetailsOnTratta = (direzione, idRoad) => {
    let params = {
      direzione: direzione,
      idRoad: idRoad,
      // idSegment: idSegment,
      // interrupted: interrupted,
    };
    var queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .filter((x) => x !== null)
      .join("&");

    return from(
      fetch(this.routeDetailsOnTratta + "?" + queryString, {
        method: "GET",
        headers: this.graphHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //V2
  getRouteDetailsOnSectionV2 = (direzione, idRoad) => {
    let params = {
      direction: direzione,
      roadId: idRoad,
      // idSegment: idSegment,
      // interrupted: interrupted,
    };
    var queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .filter((x) => x !== null)
      .join("&");

    return from(
      fetch(this.routDetailOnSection + "?" + queryString, {
        method: "GET",
        headers: this.graphHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getPointEventTratta = (direzione, distanza, idPoint, idRoad) => {
    let params = {
      direzione: direzione,
      distanza: distanza,
      idPoint: idPoint,
      idRoad: idRoad,
    };

    let queryString = this.encodeURIParams(params);

    return from(
      fetch(this.pointEventTratta + "?" + queryString, {
        method: "GET",
        headers: this.graphHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //V2
  getPointEventSectionV2 = (direzione, distanza, idPoint, idRoad) => {
    let params = {
      direction: direzione,
      offset: distanza,
      pointId: idPoint,
      // idRoad: idRoad,
    };

    let queryString = this.encodeURIParams(params);

    return from(
      fetch(this.pointEventSection + "?" + queryString, {
        method: "GET",
        headers: this.graphHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getSegmentEventTratta = (
    direzione = "",
    distanzaSource = "",
    distanzaTarget = "",
    idPointSource = "",
    idPointTarget = ""
  ) => {
    let params = {
      direzione: direzione,
      distanzaSource: distanzaSource,
      distanzaTarget: distanzaTarget,
      idPointSource: idPointSource,
      idPointTarget: idPointTarget,
    };
    var queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .filter((x) => x !== null)
      .join("&");

    return from(
      fetch(this.segmentEventTratta + "?" + queryString, {
        method: "GET",
        headers: this.graphHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //V2
  getSegmentEventSectionV2 = (
    direzione = "",
    distanzaSource = "",
    distanzaTarget = "",
    idPointSource = "",
    idPointTarget = ""
  ) => {
    let params = {
      direction: direzione,
      sourceOffset: distanzaSource,
      targetOffset: distanzaTarget,
      sourcePointId: idPointSource,
      targetPointId: idPointTarget,
    };
    var queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .filter((x) => x !== null)
      .join("&");

    return from(
      fetch(this.segmentEventSection + "?" + queryString, {
        method: "GET",
        headers: this.graphHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //dato longitudine e latitudine dei punti sorgente e destinazione, calcolail pecorso
  // minimo tra i due punti, tenendo conto delle svolte proibite
  getShortestPathBetweenCoordinates = (
    longSource,
    latSource,
    longDestination,
    latDestination
  ) => {
    let params = {
      source_x: longSource,
      source_y: latSource,
      target_x: longDestination,
      target_y: latDestination,
      reprojectTo4326: true,
    };

    let queryString = this.encodeURIParams(params);

    return from(
      fetch(this.routeControllerSources + "?" + queryString, {
        method: "GET",
        headers: this.graphHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  // dato gli ID dei nodi sorgente e destinazione, calcola il pecorso minimo tra i due punti, tenendo conto delle svolte proibite
  getShortestPathBetweenNodes = (idSource, idDestination) => {
    let params = {
      source: idSource,
      target: idDestination,
      reprojectTo4326: true,
    };

    let queryString = this.encodeURIParams(params);
    return of(true).pipe(
      switchMap((x) =>
        fetch(this.pathControllerNodes + "?" + queryString, {
          method: "GET",
          headers: this.graphHeaders,
        })
          .then((data) => this.handleErrors(data))
          .then((data) => data.json())
          .catch((error) => {
            throw error;
          })
      ),
      retryWhen((errors) =>
        errors.pipe(
          // Use concat map to keep the errors in order and make sure they
          // aren't executed in parallel
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => i > 10,
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              of(e).pipe(delay(500))
            )
          )
        )
      )
    );
  };

  // dato gli ID dei nodi sorgente e destinazione, calcola il pecorso minimo tra i due punti, tenendo conto delle svolte proibite
  getShortestPathRouteBetweenNodes = (idSource, idDestination) => {
    let params = {
      source: idSource,
      target: idDestination,
      reprojectTo4326: true,
    };

    let queryString = this.encodeURIParams(params);
    return of(true).pipe(
      switchMap((x) =>
        fetch(this.routeControllerNodes + "?" + queryString, {
          method: "GET",
          headers: this.graphHeaders,
        })
          .then((data) => this.handleErrors(data))
          .then((data) => data.json())
          .catch((error) => {
            throw error;
          })
      ),
      retryWhen((errors) =>
        errors.pipe(
          // Use concat map to keep the errors in order and make sure they
          // aren't executed in parallel
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => i > 10,
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              of(e).pipe(delay(500))
            )
          )
        )
      )
    );
  };

  fetchArcCsv = (
    maxFeatures = "",
    sortBy = "",
    cql_filter = "",
    startIndex = ""
  ) => {
    let params = {
      maxFeatures: maxFeatures,
      sortBy: sortBy,
      cql_filter: cql_filter,
      startIndex: startIndex,
    };
    let queryString = this.encodeURIParams(params);

    let url =
      this.mwGeoserverApi + "geoservice_ws/ows/arch/export/csv?" + queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  fetchPointCsv = (
    maxFeatures = "",
    sortBy = "",
    cql_filter = "",
    startIndex = ""
  ) => {
    let params = {
      maxFeatures: maxFeatures,
      sortBy: sortBy,
      cql_filter: cql_filter,
      startIndex: startIndex,
    };
    let queryString = this.encodeURIParams(params);

    let url =
      this.mwGeoserverApi +
      "geoservice_tmc_ws/ows/tmc_point/export/csv?" +
      queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  fetchTratteCsv = (
    maxFeatures = "",
    sortBy = "",
    cql_filter = "",
    startIndex = ""
  ) => {
    let params = {
      maxFeatures: maxFeatures,
      sortBy: sortBy,
      cql_filter: cql_filter,
      startIndex: startIndex,
    };
    let queryString = this.encodeURIParams(params);

    let url =
      this.mwGeoserverApi +
      "geoservice_tmc_ws/ows/tmc_road/export/csv?" +
      queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  getEventsAssociatedToSituationId = (situationId) => {
    return from(
      fetch(this.associatedEvents + situationId + "/situation-records", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  updateSingleEvent = (updateSituationRecordCause) => {
    return from(
      fetch(this.causeEvents, {
        method: "PUT",
        headers: this.genericHeaders,
        body: JSON.stringify(updateSituationRecordCause),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //config filters

  getFiltersConfig = () => {
    return from(
      fetch(this.configEvents + "filters", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //STATIONS

  //GET LIST STATION
  getStations = () => {
    return from(
      fetch(this.trafficEndpoint + "/station/all?sensorStatus=true", {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //GET LIST STATION WITH DETAILS
  getStationDetail = (stationId, startTime, duration) => {
    return from(
      fetch(
        this.trafficEndpoint +
        "/station/detail/" +
        stationId +
        "?startTime=" +
        startTime +
        "&duration=" +
        duration,
        {
          method: "GET",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };
  //GET DETAIL STATION TABLE
  getStationDetailTable = (stationId, startTime, duration, measureType) => {
    return from(
      fetch(
        this.trafficEndpoint +
        "/station/detail/" +
        stationId +
        "/table?startTime=" +
        startTime +
        "&duration=" +
        duration +
        "&measureType=" +
        measureType,
        {
          method: "GET",
          headers: this.genericHeaders,
        }
      )
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  fetchStationExcel = (stationId, startTime, duration, measureType) => {
    let url =
      this.mwStationExcel +
      stationId +
      "/table/export/csv?startTime=" +
      startTime +
      "&duration=" +
      duration +
      "&measureType=" +
      measureType;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  //WS STATION STATUS

  getStationStatusRuntime = (callback, error, open) => {
    this.evtSourceStationStatus = new EventSource(
      this.stationStatusRuntimeWS,
      this.eventSourceInitDict
    );
    this.evtSourceStationStatus.onerror = error;
    this.evtSourceStationStatus.onmessage = callback;
    this.evtSourceStationStatus.onopen = open;
  };

  closeStationStatusRuntime = () => {
    if (this.evtSourceStationStatus) this.evtSourceStationStatus.close();
  };

  //WS STATION STATUS

  getStationMeasuresRuntime = (callback, error, open) => {
    this.evtSourceStationMeasures = new EventSource(
      this.stationMeasuresRuntimeWS,
      this.eventSourceInitDict
    );
    this.evtSourceStationMeasures.onerror = error;
    this.evtSourceStationMeasures.onmessage = callback;
    this.evtSourceStationMeasures.onopen = open;
  };

  closeStationMeasuresRuntime = () => {
    if (this.evtSourceStationMeasures) this.evtSourceStationMeasures.close();
  };

  //WS TRAFFIC NOTIFICATION

  getTrafficNotificationRuntime = (callback, error, open) => {
    this.evtSpurceTrafficNotification = new EventSource(
      this.trafficNotificationRuntimeWS,
      this.eventSourceInitDict
    );
    this.evtSpurceTrafficNotification.onerror = error;
    this.evtSpurceTrafficNotification.onmessage = callback;
    this.evtSpurceTrafficNotification.onopen = open;
  };

  closeTrafficNotificationRuntime = () => {
    if (this.evtSpurceTrafficNotification)
      this.evtSpurceTrafficNotification.close();
  };

  // TRAFFIC

  fetchTrafficExcel = (
    archId,
    contextIds,
    interval,
    measuresIds,
    profile,
    startDateTime,
    vehicleClassesIds
  ) => {
    let url =
      this.mwTrafficExcel +
      archId +
      "/contexts/export/csv?contextIds=" +
      contextIds +
      "&interval=" +
      interval +
      "&measuresIds=" +
      measuresIds +
      "&profile=" +
      profile +
      "&startDateTime=" +
      startDateTime +
      "&vehicleClassesIds=" +
      vehicleClassesIds;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficContexts = () => {
    let url = this.trafficEndpoint + "/context/all";
    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficDays = (startTime, endTime) => {
    let url =
      this.trafficEndpoint +
      "/day/all?startTime=" +
      startTime +
      "&endTime=" +
      endTime;
    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficSpecificDayType = (date) => {
    let url = this.trafficEndpoint + "/day/" + date + "/day-type";

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficDayTypes = () => {
    let url = this.trafficEndpoint + "/day-type/all";

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficArchesOrientedElaboratedDataByContext = (
    contextId,
    timeValue,
    cql_filter,
    vehicleClassId,
    measuresIds,
    profile
  ) => {
    let params = {
      contextId: contextId,
      timeValue: timeValue,
      cql_filter: cql_filter,
      vehicleClassId: vehicleClassId,
      measuresIds: measuresIds,
      profile: profile,
    };

    let queryString = this.encodeURIParams(params);

    let url =
      this.trafficEndpoint +
      "/elaborated-data/context/" +
      contextId +
      "/archs/geo?" +
      queryString;
    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficArchesOrientedElaboratedDataProfile = (
    timeValue,
    cql_filter,
    dayType,
    vehicleClassesId,
    measuresIds
  ) => {
    let params = {
      timeValue: timeValue,
      cql_filter: cql_filter,
      dayType: dayType,
      vehicleClassId: vehicleClassesId,
      measuresIds: measuresIds,
    };

    let queryString = this.encodeURIParams(params);

    let url =
      this.trafficEndpoint +
      "/elaborated-data/profile/archs/geo?" +
      queryString;
    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficContextOrientedElaboratedByArch = (
    archId,
    startDateTime,
    interval,
    contextIds,
    vehicleClassesIds,
    measuresIds,
    profile
  ) => {
    let params = {
      startDateTime: startDateTime,
      interval: interval,
      contextIds: contextIds,
      vehicleClassesIds: vehicleClassesIds,
      measuresIds: measuresIds,
      profile: profile,
    };

    let queryString = this.encodeURIParams(params);

    let url =
      this.trafficEndpoint +
      "/elaborated-data/arch/" +
      archId +
      "/contexts?" +
      queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficProfileOrientedElaboratedByArch = (
    archId,
    starttimeValue,
    endTimeValue,
    dayType,
    vehicleClassesIds,
    measuresIds
  ) => {
    let params = {
      startTimeValue: starttimeValue,
      endTimeValue: endTimeValue,
      dayType: dayType,
      vehicleClassesIds: vehicleClassesIds,
      measuresIds: measuresIds,
    };

    let queryString = this.encodeURIParams(params);

    let url =
      this.trafficEndpoint +
      "/elaborated-data/profile/arch/" +
      archId +
      "?" +
      queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  postTrafficProfileOrientedElaboratedByArch = (
    archId,
    timeValue,
    dayType,
    vehicleClassesIds,
    measuresIds
  ) => {
    let params = {
      timeValue: timeValue,
      dayType: dayType,
      vehicleClassesIds: vehicleClassesIds,
      measuresIds: measuresIds,
    };

    let queryString = this.encodeURIParams(params);

    let url =
      this.trafficEndpoint + "/elaborated-data/profile/archs?" + queryString;

    return from(
      fetch(url, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify({ archIds: [archId] }),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  postTrafficContextOrientedElaboratedByArch = (
    archId,
    timeValue,
    contextId,
    vehicleClassesIds,
    measuresIds,
    profile
  ) => {
    let params = {
      timeValue: timeValue,
      vehicleClassesIds: vehicleClassesIds,
      measuresIds: measuresIds,
      profile: profile,
    };

    let queryString = this.encodeURIParams(params);

    let url =
      this.trafficEndpoint +
      "/elaborated-data/context/" +
      contextId +
      "/archs?" +
      queryString;

    return from(
      fetch(url, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify({ archIds: [archId] }),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficCentroidsData = (
    centroidId,
    timeValue,
    dayType,
    vehicleClassesIds,
    measuresIds
  ) => {
    let params = {
      timeValue: timeValue,
      dayType: dayType,
      vehicleClassesIds: vehicleClassesIds,
      measuresIds: measuresIds,
    };

    let queryString = this.encodeURIParams(params);

    let url =
      this.trafficEndpoint +
      "/elaborated-data/profile/centroids/" +
      centroidId +
      "?" +
      queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficVechicleClasses = () => {
    let url = this.trafficEndpoint + "/vehicle-class/all";
    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getTrafficMeasures = () => {
    let url = this.trafficEndpoint + "/measure/all";
    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //PARKINGS
  getAllParkingRecordsWithLatestStatus = (
    pageOffset,
    pageLimit,
    loadIdentifiableObjectsBy,
    parkingRecordType,
    parkingSiteType,
    parkingLayout,
    responsibleAuthoritiesIds,
    parkingCharge,
    parkingOperationalStatus,
    parkingOpeningStatus,
    searchByKeyword,
    searchOnFields,
    orderBy,
    orderType
  ) => {
    let params = {
      pageOffset: pageOffset,
      pageLimit: pageLimit,
      loadIdentifiableObjectsBy: loadIdentifiableObjectsBy,
      parkingRecordType: parkingRecordType,
      parkingSiteType: parkingSiteType,
      parkingLayout: parkingLayout,
      responsibleAuthoritiesIds: responsibleAuthoritiesIds,
      parkingCharge: parkingCharge,
      parkingSiteStatus: parkingOperationalStatus,
      parkingSiteOpeningStatus: parkingOpeningStatus,
      searchByKeyword: searchByKeyword,
      searchOnFields: searchOnFields,
      orderBy: orderBy.length > 0 ? orderBy : null,
      orderType: orderType,
    };

    let queryString = this.encodeURIParams(params);
    let url =
      this.parkingRecordEndpoint + "parking-record-status/all?" + queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getAllParkingRecordsWithLatestStatusByBbox = (
    bbox,
    loadIdentifiableObjectsBy
  ) => {
    let params = {
      bbox: bbox,
      loadIdentifiableObjectsBy: loadIdentifiableObjectsBy,
    };

    let queryString = this.encodeURIParams(params);
    let url =
      this.parkingRecordEndpoint + "parking-record-status/geo?" + queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getParkingRecordById = (id) => {
    let url = this.parkingRecordEndpoint + "id/" + id;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  fetchParkingCsv = (
    pageOffset,
    pageLimit,
    loadIdentifiableObjectsBy,
    parkingRecordType,
    parkingSiteType,
    parkingLayout,
    responsibleAuthoritiesIds,
    parkingCharge,
    parkingOperationalStatus,
    parkingOpeningStatus,
    searchByKeyword,
    searchOnFields,
    orderBy,
    orderType
  ) => {
    let params = {
      pageOffset: pageOffset,
      pageLimit: pageLimit,
      loadIdentifiableObjectsBy: loadIdentifiableObjectsBy,
      parkingRecordType: parkingRecordType,
      parkingSiteType: parkingSiteType,
      parkingLayout: parkingLayout,
      responsibleAuthoritiesIds: responsibleAuthoritiesIds,
      parkingCharge: parkingCharge,
      parkingSiteStatus: parkingOperationalStatus,
      parkingSiteOpeningStatus: parkingOpeningStatus,
      searchByKeyword: searchByKeyword,
      searchOnFields: searchOnFields,
      orderBy: orderBy.length > 0 ? orderBy : null,
      orderType: orderType,
    };

    let queryString = this.encodeURIParams(params);

    let url =
      this.parkingRecordEndpoint +
      "parking-record-status/export/csv?" +
      queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  getParkingRecordStatusById = (id, startTime, endTime) => {
    let params = {
      startTime: startTime,
      endTime: endTime,
    };

    let queryString = this.encodeURIParams(params);
    let url = this.parkingRecordStatusEndpoint + "id/" + id + "?" + queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.userManagerHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getParkingContacts = () => {
    return from(
      fetch(this.parkingContact, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //WS Parking
  getParkingRuntime = (callback, error, open) => {
    this.evtSourceParking = new EventSource(
      this.parkingRecordStatusSSEEndpoint,
      this.eventSourceInitDict
    );
    this.evtSourceParking.onerror = error;
    this.evtSourceParking.onmessage = callback;
    this.evtSourceParking.onopen = open;
  };

  closeParkingRuntime = () => {
    if (this.evtSourceParking) this.evtSourceParking.close();
  };

  //USER ACTIVITIES
  getUserActivity = (
    pageLimit,
    pageOffset,
    fromDateTime,
    toDateTime,
    dateTimeFilterOn,
    modules,
    usernames,
    categories,
    subCategories,
    tags,
    activityTypes,
    actionTypes,
    sources,
    searchByKeyword,
    searchOnFields,
    orderBy,
    orderType
  ) => {
    let params = {
      pageLimit: pageLimit,
      pageOffset: pageOffset,
      fromDateTime: fromDateTime,
      toDateTime: toDateTime,
      dateTimeFilterOn: dateTimeFilterOn,
      modules: modules,
      usernames: usernames,
      categories: categories,
      subCategories: subCategories,
      tags: tags,
      activityTypes: activityTypes,
      actionTypes: actionTypes,
      sources: sources,
      searchByKeyword: searchByKeyword,
      searchOnFields: searchOnFields,
      orderBy: orderBy.length > 0 ? orderBy : null,
      orderType: orderType,
    };

    let queryString = this.encodeURIParams(params);
    let url = this.userActivityEndpoint + "activity?" + queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  postUserActivity = (body) => {
    return from(
      fetch(this.userActivityEndpoint + "activity", {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(body),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  fetchUserActivityExcel = (
    numberOfRecords,
    pageOffset,
    dateTimeFilterOn,
    fromDateTime,
    toDateTime,
    modules,
    usernames,
    categories,
    subCategories,
    tags,
    activityTypes,
    actionTypes,
    sources,
    searchByKeyword,
    searchOnFields,
    orderBy,
    orderType
  ) => {
    let params = {
      numberOfRecords: numberOfRecords,
      pageOffset: pageOffset,
      dateTimeFilterOn: dateTimeFilterOn,
      fromDateTime: fromDateTime,
      toDateTime: toDateTime,
      modules: modules,
      usernames: usernames,
      categories: categories,
      subCategories: subCategories,
      tags: tags,
      activityTypes: activityTypes,
      actionTypes: actionTypes,
      sources: sources,
      searchByKeyword: searchByKeyword,
      searchOnFields: searchOnFields,
      orderBy: orderBy && orderBy.length > 0 ? orderBy : null,
      orderType: orderType,
    };

    let queryString = this.encodeURIParams(params);
    let url = this.mwUserActvity + "export?" + queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  getUserActivityById = (activityId) => {
    let url = this.userActivityEndpoint + "activity/" + activityId;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //CAMERAS
  getCamerasList = (
    limit,
    offset,
    diagnosticState,
    type,
    group,
    searchByKeyword,
    searchOnFields,
    orderBy,
    orderType
  ) => {
    let params = {
      limit: limit,
      offset: offset,
      diagnosticState: diagnosticState,
      type: type,
      group: group,
      searchByKeyword: searchByKeyword,
      searchOnFields: searchOnFields,
      orderBy: orderBy ? orderBy : null,
      orderType: orderType,
    };

    let queryString = this.encodeURIParams(params);

    let url = this.cameraEndpoint + "cctv/all/paged?" + queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getCamerasListByBbox = (bbox) => {
    let params = {
      bbox: bbox,
    };

    let queryString = this.encodeURIParams(params);
    let url = this.cameraEndpoint + "cctv/all/geo?" + queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getCameraById = (id) => {
    const url = this.cameraEndpoint + "cctv/" + id;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getCameraWithLastStateById = (id) => {
    const url =
      this.cameraEndpoint + "cctv/with-last-state-and-open-alarms/" + id;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getAlarmsByCameraId = (id) => {
    let url = this.cameraEndpoint + "alarm/" + id;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  fetchCameraListExcel = (
    pageOffset,
    pageLimit,
    searchByKeyword,
    searchOnFields,
    orderBy,
    orderType
  ) => {
    let params = {
      limit: pageLimit,
      offset: pageOffset,
      searchByKeyword: searchByKeyword,
      searchOnFields: searchOnFields,
      orderBy: orderBy.length > 0 ? orderBy : null,
      orderType: orderType,
    };

    let queryString = this.encodeURIParams(params);
    let url = this.mwCameraExcel + "/export/csv?" + queryString;

    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  //WS Camera
  getCameraStatusRuntime = (callback, error, open) => {
    this.evtSourceCamera = new EventSource(
      this.cameraStatusSSEEndpoint,
      this.eventSourceInitDict
    );

    this.evtSourceCamera.onerror = error;
    this.evtSourceCamera.onmessage = callback;
    this.evtSourceCamera.onopen = open;
  };

  closeCameraRuntime = () => {
    if (this.evtSourceCamera) this.evtSourceCamera.close();
  };

  getCameraAlarmRuntime = (callback, error, open) => {
    this.evtSourceCameraAlarm = new EventSource(
      this.cameraAlarmSSEEndpoint,
      this.eventSourceInitDict
    );
    this.evtSourceCameraAlarm.onerror = error;
    this.evtSourceCameraAlarm.onmessage = callback;
    this.evtSourceCameraAlarm.onopen = open;
  };

  closeCameraRuntime = () => {
    if (this.evtSourceCameraAlarm) this.evtSourceCameraAlarm.close();
  };

  startHlsStream = (url, streamProxy) => {
    return from(
      fetch(
        streamProxy + '/start', {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify({
          uri: url
        })
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  }

  stopHlsStream = (streamProxy, camId) => {
    return from(
      fetch(
        streamProxy + '/stop', {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify({
          id: camId,
          remove: true,
          wait: false
        })
      })
        .then((data) => this.handleErrors(data))
        .catch((error) => {
          throw error;
        })
    );

  }

  //PMV
  getPmvStatusById = (id, jwt) => {
    let params = {
      idPannello: id,
      // OAUTH_TOKEN: jwt,
    };

    let queryString = this.encodeURIParams(params);
    let url = this.pmvEndPoint + "StatiPannello?" + queryString;

    return from(
      fetch(url, {
        method: "POST",
        headers: this.pmvHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getPmvListByBbox = (bbox, jwt, tipologia) => {
    const url = this.pmvEndPoint + "AnagraficaPannelli";
    return from(
      fetch(url, {
        method: "POST",
        headers: this.pmvHeaders,
        body: JSON.stringify({ boundingBox: bbox, tipologie: tipologia }),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getPmvListStatus = (body, jwt) => {
    // let params = {
    //   OAUTH_TOKEN: jwt,
    // };
    // let queryString = this.encodeURIParams(params);

    let url = this.pmvEndPoint + "StatiPannelli";

    return from(
      fetch(url, {
        method: "POST",
        headers: this.pmvHeaders,
        body: JSON.stringify(body),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getPmvListStatusHistory = (idPannello, body, jwt) => {
    let params = {
      idPannello: idPannello,
      // OAUTH_TOKEN: jwt,
    };
    let queryString = this.encodeURIParams(params);
    let url = this.pmvEndPoint + "StoricoStatiPannello?" + queryString;

    return from(
      fetch(url, {
        method: "POST",
        headers: this.pmvHeaders,
        body: JSON.stringify(body),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  fetchPmvListExcel = (body) => {
    let url = this.mwPmvExcel + "StatiPannelli/export/csv";

    return from(
      fetch(url, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(body),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  fetchPmvHistoryExcel = (id, body) => {
    const params = {
      idPannello: id,
    };
    const queryString = this.encodeURIParams(params);
    const url =
      this.mwPmvExcel + "StoricoStatiPannello/export/csv?" + queryString;

    return from(
      fetch(url, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(body),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.blob())
        .catch((error) => {
          throw error;
        })
    );
  };

  //user manager
  getUserRolesByUsername = (username) => {
    const params = {
      username: username,
    };
    let queryString = this.encodeURIParams(params);

    const url = this.userManagerEndpoint + "users/roles?" + queryString;
    return from(
      fetch(url, {
        method: "GET",
        headers: this.userManagerHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getUsersList = () => {
    const params = {
      filter: "*",
    };
    let queryString = this.encodeURIParams(params);
    const url = this.userManagerEndpoint + "users?" + queryString;
    return from(
      fetch(url, {
        method: "GET",
        headers: this.userManagerHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getRolesList = () => {
    const url = this.userManagerEndpoint + "roles";
    return from(
      fetch(url, {
        method: "GET",
        headers: this.userManagerHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  //WORKSPACE
  saveWorkSpace = (body) => {
    const url = this.workspaceEndpoint + "/preference";
    return from(
      fetch(url, {
        method: "POST",
        headers: this.genericHeaders,
        body: JSON.stringify(body),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getWorkSpaces = (usernames) => {
    const params = {
      usernames: usernames,
    };
    let queryString = this.encodeURIParams(params);
    const url = this.workspaceEndpoint + "/preference?" + queryString;
    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  getWorkSpaceById = (id) => {
    let url = this.workspaceEndpoint + "/preference/id/" + id;
    return from(
      fetch(url, {
        method: "GET",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  updateWorkSpace = (id, body) => {
    let url = this.workspaceEndpoint + "/preference/id/" + id;
    return from(
      fetch(url, {
        method: "PUT",
        headers: this.genericHeaders,
        body: JSON.stringify(body),
      })
        .then((data) => this.handleErrors(data))
        .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };

  deleteWorkSpace = (id) => {
    let url = this.workspaceEndpoint + "/preference/id/" + id;
    return from(
      fetch(url, {
        method: "DELETE",
        headers: this.genericHeaders,
      })
        .then((data) => this.handleErrors(data))
        // .then((data) => data.json())
        .catch((error) => {
          throw error;
        })
    );
  };
}
