// ALL GEOSERVER ELEMENTS (points, nodes, centroids, road_sections) HAVE GEOJSON STRUCTURE

export class FeatureCollectionModel {
  type;
  features;
  totalFeatures;
  numberMatched;
  numberReturned;
  timestamp;

  constructor() {
    this.type = null;
    this.features = null;
    this.totalFeatures = null;
    this.numberMatched = null;
    this.numberReturned = null;
    this.timestamp = null;
  }

  static fromREST(obj, type) {
    var centroid = new FeatureCollectionModel();
    centroid.type = obj.type ? obj.type : null;
    centroid.features = obj.features
      ? FeaturesModel.fromArrayREST(obj.features, type)
      : [];
    centroid.totalFeatures = obj.totalFeatures ? obj.totalFeatures : null;
    centroid.numberMatched = obj.numberMatched ? obj.numberMatched : null;
    centroid.numberReturned = obj.numberReturned ? obj.numberReturned : null;
    centroid.timestamp = obj.timestamp ? obj.timestamp : null;
    return centroid;
  }

  static fromArrayREST(data) {
    return data
      .map(FeatureCollectionModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class FeaturesModel {
  type;
  id;
  geometry;
  geometry_name;
  properties;

  constructor() {
    this.type = null;
    this.id = null;
    this.geometry = null;
    this.geometry_name = null;
    this.properties = null;
  }

  static fromREST(obj, type) {
    var features = new FeaturesModel();
    features.type = obj.type ? obj.type : null;
    features.id = obj.id ? obj.id : null;
    features.geometry = obj.geometry
      ? geometryGeoJson.fromREST(obj.geometry)
      : null;
    features.geometry_name = obj.geometry_name ? obj.geometry_name : null;
    //NB properties mapping should change according to different features
    if (type === "centroids") {
      features.properties = CentroidPropertiesModel(obj.properties)
        ? obj.properties
        : null;
    } else if (type === "nodes") {
      features.properties = NodesPropertiesModel(obj.properties)
        ? obj.properties
        : null;
    } else if (type === "road_sections") {
      features.properties = obj.properties
        ? RoadSectionPropertiesModel.fromREST(obj.properties)
        : null;
    } else if (type === "points") {
      features.properties = PointPropertiesModel(obj.properties)
        ? obj.properties
        : null;
    } else if (type === "roads") {
      features.properties = RoadPropertiesModel(obj.properties)
        ? obj.properties
        : null;
    } else {
      features.properties = obj.properties ? obj.properties : null;
    }
    return features;
  }
  static fromArrayREST(data) {
    return data.map(FeaturesModel.fromREST).filter((item) => item !== null);
  }
}

export class geometryGeoJson {
  type;
  coordinates;

  constructor() {
    this.type = null;
    this.coordinates = null;
  }

  static fromREST(obj) {
    var geometry = new geometryGeoJson();
    geometry.type = obj.type ? obj.type : null;
    geometry.coordinates = obj.coordinates ? obj.coordinates : [];
    return geometry;
  }
}

export class RoadSectionPropertiesModel {
  a;
  b;
  ltype;
  urban;
  ztl;
  school;
  distance;
  frc;
  name;
  capacity;
  cap_lane;
  speed;
  measured;
  tunnel;
  bridge;
  tolling;
  brotherid;
  coeff1;
  fft;
  coeff2;
  oneway;
  areaid;
  arcid;
  id;
  azimuth;
  comune;
  comune_id;
  direction;
  frc_descr;
  lanes;
  area_name;
  nazione;
  nazione_id;
  net2class;
  net2class_descr;
  provincia;
  provincia_id;
  regione;
  regione_id;

  constructor() {
    this.a = null;
    this.b = null;
    this.ltype = null;
    this.urban = null;
    this.ztl = null;
    this.school = null;
    this.distance = null;
    this.frc = null;
    this.name = null;
    this.capacity = null;
    this.cap_lane = null;
    this.speed = null;
    this.measured = null;
    this.tunnel = null;
    this.bridge = null;
    this.tolling = null;
    this.brotherid = null;
    this.coeff1 = null;
    this.fft = null;
    this.coeff2 = null;
    this.oneway = null;
    this.areaid = null;
    this.arcid = null;
    this.id = null;
    this.azimuth = null;
    this.comune = null;
    this.comune_id = null;
    this.direction = null;
    this.frc_descr = null;
    this.lanes = null;
    this.area_name = null;
    this.nazione = null;
    this.nazione_id = null;
    this.net2class = null;
    this.net2class_descr = null;
    this.provincia = null;
    this.provincia_id = null;
    this.regione = null;
    this.regione_id = null;
  }

  static fromREST(obj) {
    var arc = new RoadSectionPropertiesModel();
    arc.a = obj.a ? obj.a : null;
    arc.b = obj.b ? obj.b : null;
    arc.ltype = obj.ltype ? obj.ltype : null;
    arc.urban = obj.urban ? obj.urban : null;
    arc.ztl = obj.ztl ? obj.ztl : null;
    arc.school = obj.school ? obj.school : null;
    arc.distance = obj.distance ? obj.distance : null;
    arc.frc = obj.frc ? obj.frc : null;
    arc.name = obj.name ? obj.name : null;
    arc.capacity = obj.capacity ? obj.capacity : null;
    arc.cap_lane = obj.cap_lane ? obj.cap_lane : null;
    arc.speed = obj.speed ? obj.speed : null;
    arc.measured = obj.measured ? obj.measured : null;
    arc.tunnel = obj.tunnel ? obj.tunnel : null;
    arc.bridge = obj.bridge ? obj.bridge : null;
    arc.tolling = obj.tolling ? obj.tolling : null;
    arc.brotherid = obj.brotherid ? obj.brotherid : null;
    arc.coeff1 = obj.coeff1 ? obj.coeff1 : null;
    arc.fft = obj.fft ? obj.fft : null;
    arc.coeff2 = obj.coeff2 ? obj.coeff2 : null;
    arc.oneway = obj.oneway ? obj.oneway : null;
    arc.areaid = obj.areaid ? obj.areaid : null;
    arc.arcid = obj.arcid ? obj.arcid : null;
    arc.id = obj.id ? obj.id : null;
    arc.azimuth = obj.azimuth ? obj.azimuth : null;
    arc.comune = obj.comune ? obj.comune : null;
    arc.comune_id = obj.comune_id ? obj.comune_id : null;
    arc.direction = obj.direction ? obj.direction : null;
    arc.frc_descr = obj.frc_descr ? obj.frc_descr : null;
    arc.lanes = obj.lanes ? obj.lanes : null;
    arc.area_name = obj.area_name ? obj.area_name : null;
    arc.nazione = obj.nazione ? obj.nazione : null;
    arc.nazione_id = obj.nazione_id ? obj.nazione_id : null;
    arc.net2class = obj.net2class ? obj.net2class : null;
    arc.net2class_descr = obj.net2class_descr ? obj.net2class_descr : null;
    arc.provincia = obj.provincia ? obj.provincia : null;
    arc.provincia_id = obj.provincia_id ? obj.provincia_id : null;
    arc.regione = obj.regione ? obj.regione : null;
    arc.regione_id = obj.regione_id ? obj.regione_id : null;
    return arc;
  }

  static fromArrayREST(data) {
    return data
      .map(RoadSectionPropertiesModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class NodesPropertiesModel {
  n;
  x;
  y;
  jncttyp;
  pos_lcd;
  neg_lcd;

  constructor() {
    this.n = null;
    this.x = null;
    this.y = null;
    this.jncttyp = null;
    this.pos_lcd = null;
    this.neg_lcd = false;
  }

  static fromREST(obj) {
    var node = new NodesPropertiesModel();
    node.n = obj.n ? obj.n : null;
    node.x = obj.x ? obj.x : null;
    node.y = obj.y ? obj.y : null;
    node.jncttyp = obj.jncttyp ? obj.jncttyp : null;
    node.pos_lcd = obj.pos_lcd ? obj.pos_lcd : null;
    node.neg_lcd = obj.neg_lcd ? obj.neg_lcd : false;
    return node;
  }

  static fromArrayREST(data) {
    return data
      .map(NodesPropertiesModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class CentroidPropertiesModel {
  n;
  x;
  y;
  name;

  constructor() {
    this.n = null;
    this.x = null;
    this.y = null;
    this.name = false;
  }

  static fromREST(obj) {
    var centroid = new CentroidPropertiesModel();
    centroid.n = obj.n ? obj.n : null;
    centroid.x = obj.x ? obj.x : null;
    centroid.y = obj.y ? obj.y : null;
    centroid.name = obj.name ? obj.name : false;
    return centroid;
  }

  static fromArrayREST(data) {
    return data
      .map(CentroidPropertiesModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class PointPropertiesModel {
  lcd;
  cid;
  tabcd;
  rds_class;
  tcd;
  stcd;
  junctionnumber;
  rnid;
  n1id;
  n2id;
  pol_lcd;
  oth_lcd;
  seg_lcd;
  road_lcd;
  roa_lcd;
  inpos;
  inneg;
  outpos;
  outneg;
  presentpos;
  presentneg;
  diversionpos;
  diversionneg;
  interruptsroad;
  urban;

  constructor() {
    //ID
    this.lcd = null;
    this.cid = null;
    this.tabcd = null;
    // riferimento alla Tabella TYPE per definire la tipologia di point
    this.rds_class = null;
    this.tcd = null;
    this.stcd = null;
    this.junctionnumber = null;
    this.rnid = null;
    //NAME ORIGIN (DESTINATION IN NEGATIVE DIRECTION)
    this.n1id = null;
    //NAME DESTINATION (POSITIVE DIRECTION)
    this.n2id = null;
    //ADMINISTRATIVE AREA
    this.pol_lcd = null;
    this.oth_lcd = null;
    //SEGMENT TO WHICH THE POINT IS ASSOCIATED (OPTIONAL)
    this.seg_lcd = null;
    //ROAD TO WHICH THE POINT IS ASSOCIATED (TO USE IF NO seg_lcd)
    this.road_lcd = null;
    this.roa_lcd = null;
    this.inpos = null;
    this.inneg = null;
    this.outpos = null;
    this.outneg = null;
    this.presentpos = null;
    this.presentneg = null;
    this.diversionpos = null;
    this.diversionneg = null;
    this.interruptsroad = null;
    this.urban = null;
  }

  static fromREST(obj) {
    var point = new PointPropertiesModel();
    point.lcd = obj.lcd ? obj.lcd : null;
    point.cid = obj.cid ? obj.cid : null;
    point.tabcd = obj.tabcd ? obj.tabcd : null;
    point.rds_class = obj.rds_class ? obj.rds_class : null;
    point.tcd = obj.tcd ? obj.tcd : null;
    point.stcd = obj.stcd ? obj.stcd : null;
    point.junctionnumber = obj.junctionnumber ? obj.junctionnumber : null;
    point.rnid = obj.rnid ? obj.rnid : null;
    point.n1id = obj.n1id ? obj.n1id : null;
    point.n2id = obj.n2id ? obj.n2id : null;
    point.pol_lcd = obj.pol_lcd ? obj.pol_lcd : null;
    point.oth_lcd = obj.oth_lcd ? obj.oth_lcd : null;
    point.seg_lcd = obj.seg_lcd ? obj.seg_lcd : null;
    point.roa_lcd = obj.roa_lcd ? obj.roa_lcd : null;
    point.road_lcd = obj.road_lcd ? obj.road_lcd : null;
    point.inpos = obj.inpos ? obj.inpos : null;
    point.inneg = obj.inneg ? obj.inneg : null;
    point.outpos = obj.outpos ? obj.outpos : null;
    point.outneg = obj.outneg ? obj.outneg : null;
    point.presentpos = obj.presentpos ? obj.presentpos : null;
    point.presentneg = obj.presentneg ? obj.presentneg : null;
    point.diversionpos = obj.diversionpos ? obj.diversionpos : null;
    point.diversionneg = obj.diversionneg ? obj.diversionneg : null;
    point.interruptsroad = obj.interruptsroad ? obj.interruptsroad : null;
    point.urban = obj.urban ? obj.urban : null;
    return point;
  }

  static fromArrayREST(data) {
    return data
      .map(PointPropertiesModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class RoadPropertiesModel {
  road_lcd;
  roadnumber;
  pol_lcd;
  start_road;
  end_road;
  name;
  lunghezza;
  geom;
  name_area;

  constructor() {
    //ID
    this.road_lcd = null;
    this.roadnumber = null;
    //ADMINISTRATIVE AREA
    this.pol_lcd = null;
    this.start_road = null;
    this.end_road = null;
    this.name = null;
    this.lunghezza = null;
    this.geom = null;
    this.name_area = null;
    this.urban = null;
  }

  static fromREST(obj) {
    var road = new RoadPropertiesModel();
    road.road_lcd = obj.lcd ? obj.lcd : null;
    road.roadnumber = obj.roadnumber ? obj.roadnumber : null;
    road.pol_lcd = obj.pol_lcd ? obj.pol_lcd : null;
    road.start_road = obj.start_road ? obj.start_road : null;
    road.end_road = obj.end_road ? obj.end_road : null;
    road.name = obj.name ? obj.name : null;
    road.lunghezza = obj.lunghezza ? obj.lunghezza : null;
    road.geom = obj.geom ? obj.geom : null;
    road.name_area = obj.name_area ? obj.name_area : null;
    return road;
  }

  static fromArrayREST(data) {
    return data
      .map(RoadPropertiesModel.fromREST)
      .filter((item) => item !== null);
  }
}
