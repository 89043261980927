import { TplMenuActions } from "./tpl-menu.actions";

const INITIAL_STATE = {
  cityBusVisible: false,
  regionBusVisible: false,
  isLinesLoading: false,
  isTripsLoading: false,
  isTripsAllLoading: false,
  isStopsLoading: false,
};

export default function tplMenuReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TplMenuActions.SET_TRANSPORT_VISIBILITY:
      return {
        ...state,
        allVisible: action.payload.visible.all,
        cityBusVisible: action.payload.visible.city,
        regionBusVisible: action.payload.visible.region,
      };
    case TplMenuActions.SET_CITY_BUS_VISIBILITY:
      return {
        ...state,
        cityBusVisible: action.payload.visible,
      };
    case TplMenuActions.SET_REGION_BUS_VISIBILITY:
      return {
        ...state,
        regionBusVisible: action.payload.visible,
      };
    case TplMenuActions.SET_IS_TPL_LOADING:
      return {
        ...state,
        isLinesLoading: action.payload.isLinesLoading,
        isTripsLoading: action.payload.isTripsLoading,
        isTripsAllLoading: action.payload.isTripsAllLoading,
        isStopsLoading: action.payload.isStopsLoading,
      };
    default:
      return state;
  }
}

export const areAllVisible = (state) => state.allVisible;
export const isCityBusVisible = (state) => state.cityBusVisible;
export const isRegionBusVisible = (state) => state.regionBusVisible;
export const isLinesLoading = (state) => state.isLinesLoading;
export const isTripsLoading = (state) => state.isTripsLoading;
export const isTripsAllLoading = (state) => state.isTripsAllLoading;
export const isStopsLoading = (state) => state.isStopsLoading;
