import { INITIAL_STATE_LINES } from "./lines.model";
import { LinesActions } from "./lines.actions";
import { NavTabActions } from "../ui/nav-tab/nav-tab.actions";
import { VehiclesActions } from "../vehicles/vehicles.actions";

export default function linesReducer(state = INITIAL_STATE_LINES, action) {
  switch (action.type) {
    case LinesActions.GET_LINES:
      return {
        ...state,
        lines: [...state.lines, ...action.payload],
        filtered: [...state.filtered, ...action.payload],
      };
    case LinesActions.RESET_LINES:
      return INITIAL_STATE_LINES;
    case LinesActions.UPDATE_ACTIVE_LINES:
      return {
        ...state,
        activeLines: action.payload,
      };
    case LinesActions.SET_ACTIVE_LINES:
      let activeLines = [];
      let index = state.activeLines.findIndex(
        (line) => line.routeRef === action.payload.routeRef
      );

      activeLines =
        index >= 0
          ? [
              ...state.activeLines.slice(0, index),
              ...state.activeLines.slice(index + 1, state.activeLines.length),
            ]
          : [...state.activeLines, action.payload];

      return {
        ...state,
        activeLines,
      };
    case LinesActions.RESET_ACTIVE_LINES:
      return {
        ...state,
        activeLines: [],
      };
    case NavTabActions.SET_SEARCH:
      if (action.payload && action.payload.type === "lines") {
        let searchString =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        let filtered = [...state.lines];
        if (searchString !== "") {
          filtered = [...state.lines].filter(
            (line) =>
              line.id.toLowerCase().includes(searchString) ||
              line.nameLong.toLowerCase().includes(searchString) ||
              line.nameShort.toLowerCase().includes(searchString)
          );
        }
        return {
          ...state,
          filtered: filtered,
        };
      } else {
        return { ...state };
      }
    case VehiclesActions.SET_VEHICLES_EVENTS:
      let activeRoutes = [];

      if (state.activeLines.length > 0) {
        activeRoutes = [...state.activeLines].map((route) => {
          if (
            action.payload &&
            Array.isArray(action.payload) &&
            action.payload.length > 0
          ) {
            action.payload.forEach((vehicle) => {
              if (
                vehicle &&
                vehicle.vehicleActivityLocation &&
                vehicle.vehicleActivityLocation.monitoredVehicleJourney &&
                vehicle.vehicleActivityLocation.monitoredVehicleJourney
                  .journeyPatternRef === route.id
              ) {
                route.vehicles &&
                  route.vehicles.length > 0 &&
                  route.vehicles.map((v) => {
                    if (
                      vehicle &&
                      vehicle.vehicleActivityLocation &&
                      vehicle.vehicleActivityLocation.monitoredVehicleJourney &&
                      vehicle.vehicleActivityLocation.monitoredVehicleJourney
                        .framedVehicleJourneyRef &&
                      vehicle.vehicleActivityLocation.monitoredVehicleJourney
                        .framedVehicleJourneyRef.datedVehicleJourneyRef ===
                        v.monitoredVehicleJourney.framedVehicleJourneyRef
                          .datedVehicleJourneyRef
                    ) {
                      if (
                        vehicle.vehicleActivityLocation.progressBetweenStops &&
                        vehicle.vehicleActivityLocation.progressBetweenStops
                          .percentage
                      ) {
                        v.monitoredVehicleJourney.progressRate =
                          vehicle.vehicleActivityLocation.progressBetweenStops.percentage;
                      }
                      if (
                        vehicle.vehicleActivityLocation.monitoredVehicleJourney
                          .monitoredCall &&
                        vehicle.vehicleActivityLocation.monitoredVehicleJourney
                          .monitoredCall.stopPointRef
                      ) {
                        v.monitoredVehicleJourney.monitoredCall =
                          vehicle.vehicleActivityLocation.monitoredVehicleJourney.monitoredCall;
                        v.monitoredVehicleJourney.previousCalls =
                          vehicle.vehicleActivityLocation.monitoredVehicleJourney.previousCalls;
                        v.monitoredVehicleJourney.onwardCalls =
                          vehicle.vehicleActivityLocation.monitoredVehicleJourney.onwardCalls;
                      }
                      if (v.transportMode && vehicle.transportMode) {
                        v.transportMode = vehicle.transportMode;
                      } else {
                        v["transportMode"] = vehicle.transportMode
                          ? vehicle.transportMode
                          : "07.70";
                      }
                    }

                    return v;
                  });
              }
            });
          }
          return route;
        });
      }

      return {
        ...state,
        activeLines: activeRoutes,
      };
    default:
      return state;
  }
}

export const getLines = (state) => state.lines;
export const getActiveLines = (state) => state.activeLines;
export const getFilteredLines = (state) => state.filtered;
