import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.less";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
//COMPONENTS
import Draggable from "components/layout/map/draggable-cameras-on-map/draggable";
import MapCameraPanel from "components/layout/panels/camera-panel/map-camera-panel";
//STORE
import {
  getCamerasOnMap,
  isOpenNavTab,
  isBottombarExpanded,
  isBottombarReduced,
  getCameraPositionOnMap,
} from "store";
import {
  removeCameraOnMap,
  moveCameraPositionOnMap,
} from "reducers/installations/cameras/cameras.actions";

class DraggableCamerasOnMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapHeight: 0,
      mapWidth: 0,
      isLoadingMap: false,
    };
  }

  componentDidMount() {
    const { camerasOnMap, cameraPositionOnMap } = this.props;

    const mapHeight = document.getElementById("draggable-container")
      .clientHeight;
    const mapWidth = document.getElementById("draggable-container").clientWidth;

    this.setState({
      ...this.state,
      mapHeight,
      mapWidth,
    });

    if (camerasOnMap.length > 0) {
      const mapHeight = document.getElementById("draggable-container")
        .clientHeight;
      const mapWidth = document.getElementById("draggable-container")
        .clientWidth;
      const offset = 20;
      const camHeight = 230;
      const camWidth = 330;

      camerasOnMap.forEach((item, index) => {
        let obj = { ...this.state };
        if (
          !obj[
            item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_X"
          ] &&
          !obj[
            item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_Y"
          ]
        ) {
          let { x, y } = this.getXYPosition(
            mapHeight,
            mapWidth,
            offset,
            index,
            camHeight,
            camWidth
          );
          const positionFromReducer = cameraPositionOnMap.find(
            (cam) =>
              cam.camId ===
              item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
          );
          if (positionFromReducer) {
            x = positionFromReducer.coordX;
            y = positionFromReducer.coordY;
          }
          obj[
            item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_X"
          ] = x;
          obj[
            item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_Y"
          ] = y;
          this.setState({
            ...obj,
            mapHeight,
            mapWidth,
          });
        }
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    const {
      camerasOnMap,
      isBottombarExpanded,
      isOpenNavTab,
      isBottombarReduced,
      moveCameraPositionOnMap,
      cameraPositionOnMap,
    } = this.props;
    const { mapHeight, mapWidth } = this.state;

    if (
      prevProps.camerasOnMap &&
      camerasOnMap &&
      prevProps.camerasOnMap.length !== camerasOnMap.length
    ) {
      //REMOVE LAST CAMERA
      if (camerasOnMap.length === 0) {
        this.setState({});
        //ADD NEW CAMERA
      } else if (prevProps.camerasOnMap.length < camerasOnMap.length) {
        const mapHeight = document.getElementById("draggable-container")
          .clientHeight;
        const mapWidth = document.getElementById("draggable-container")
          .clientWidth;
        const offset = 20;
        const camHeight = 230;
        const camWidth = 330;

        camerasOnMap.forEach((item, index) => {
          if (
            !prevProps.camerasOnMap.some(
              (prevCam) =>
                prevCam.properties.cctvWithLastStateAndOpenAlarms.cctv
                  .deviceId ===
                item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
            )
          ) {
            let obj = { ...this.state };
            if (
              !obj[
                item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId +
                  "_X"
              ] &&
              !obj[
                item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId +
                  "_Y"
              ]
            ) {
              const { x, y } = this.getXYPosition(
                mapHeight,
                mapWidth,
                offset,
                index,
                camHeight,
                camWidth
              );
              obj[
                item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId +
                  "_X"
              ] = x;
              obj[
                item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId +
                  "_Y"
              ] = y;
              this.setState({
                ...obj,
              });
              moveCameraPositionOnMap([
                ...cameraPositionOnMap,
                {
                  camId:
                    item.properties.cctvWithLastStateAndOpenAlarms.cctv
                      .deviceId,
                  coordX: x,
                  coordY: y,
                },
              ]);
            }
          }
        });
        //REMOVE CAMERA
      } else if (prevProps.camerasOnMap.length > camerasOnMap.length) {
        prevProps.camerasOnMap.forEach((item, index) => {
          if (
            !camerasOnMap.some(
              (cam) =>
                cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
                item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
            )
          ) {
            let obj = { ...this.state };
            obj[
              item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId +
                "_X"
            ] = null;
            obj[
              item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId +
                "_Y"
            ] = null;
            this.setState({
              ...obj,
            });
            moveCameraPositionOnMap([
              ...cameraPositionOnMap.filter(
                (camOnMap) =>
                  camOnMap.camId !==
                  item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
              ),
            ]);
          }
        });
      }
    }

    if (
      (prevProps.isBottombarExpanded !== isBottombarExpanded ||
        prevProps.isBottombarReduced !== isBottombarReduced ||
        prevProps.isOpenNavTab !== isOpenNavTab) &&
      camerasOnMap.length > 0
    ) {
      this.setState({
        ...this.state,
        isLoadingMap: true,
      });
      UIkit.notification.closeAll("loading");
      UIkit.notification({
        message: "Ricalcolo posizione pannelli telecamere",
        status: "primary",
        pos: "bottom-center",
        group: "loading",
        timeout: 500,
      });

      //wait for animation to finish to get new dimensions
      setTimeout(() => {
        let obj = { ...this.state };
        let newMapWidth = 0;
        let newMapHeight = 0;
        newMapWidth = document.getElementById("draggable-container")
          .clientWidth;
        newMapHeight = document.getElementById("draggable-container")
          .clientHeight;
        camerasOnMap.forEach((item) => {
          let oldX =
            obj[
              item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId +
                "_X"
            ];
          let oldY =
            obj[
              item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId +
                "_Y"
            ];

          const { x, y } = this.getNewXYPosition(
            newMapHeight,
            newMapWidth,
            mapHeight,
            mapWidth,
            oldX,
            oldY
          );

          obj[
            item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_X"
          ] = x;
          obj[
            item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_Y"
          ] = y;

          moveCameraPositionOnMap([
            ...cameraPositionOnMap.map((cam) => {
              if (
                item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
                cam.camId
              ) {
                return {
                  camId:
                    item.properties.cctvWithLastStateAndOpenAlarms.cctv
                      .deviceId,
                  coordX: x,
                  coordY: y,
                };
              } else {
                return cam;
              }
            }),
          ]);
        });

        this.setState({
          ...obj,
          mapHeight: newMapHeight,
          mapWidth: newMapWidth,
          isLoadingMap: false,
        });
      }, 500);
    }
  };

  getNewXYPosition = (
    newMapHeight,
    newMapWidth,
    mapHeight,
    mapWidth,
    oldX,
    oldY
  ) => {
    const camHeight = 230;
    const camWidth = 330;

    let newX = Math.floor(oldX * (newMapWidth / (mapWidth ? mapWidth : 1)));
    let newY = Math.floor(oldY * (newMapHeight / (mapHeight ? mapWidth : 1)));

    //CHECK IT DOES NOT GO OUT THE MAP
    if (newX + camWidth > newMapWidth) {
      newX = newMapWidth - camWidth - 5;
    }
    if (newY + camHeight > newMapHeight) {
      newY = newMapHeight - camHeight - 5;
    }

    return {
      x: newX,
      y: newY,
    };
  };

  getXYPosition = (mapHeight, mapWidth, offset, index, camHeight, camWidth) => {
    let x = 0;
    let y = 0;

    const totalPossibleColumns = Math.floor(mapWidth / (camWidth + offset));
    const totalPossibleRows = Math.floor(mapHeight / (camHeight + offset));

    let row = Math.floor(index / totalPossibleColumns);
    let column = Math.floor(index - row * totalPossibleColumns);

    if (row + 1 <= totalPossibleRows) {
      x = (camWidth + offset) * column + offset;
      y = mapHeight - (camHeight + offset) * (row + 1);
    } else {
      row = 0;
      let newOffset = 30;
      x = (camWidth + newOffset) * column + newOffset;
      y = mapHeight - (camHeight + offset) * (row + 1);
    }

    return { x, y };
  };

  _move = (x, y, cam) => {
    const { moveCameraPositionOnMap, cameraPositionOnMap } = this.props;

    let obj = { ...this.state };
    obj[cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_X"] = x;
    obj[cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_Y"] = y;

    this.setState({
      ...obj,
    });

    moveCameraPositionOnMap([
      ...cameraPositionOnMap.map((camOnMap) => {
        if (
          cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
          camOnMap.camId
        ) {
          return {
            camId: cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId,
            coordX: x,
            coordY: y,
          };
        } else {
          return camOnMap;
        }
      }),
    ]);
  };

  removeCameraFromMap = (cam) => {
    const {
      removeCameraOnMap,
      moveCameraPositionOnMap,
      cameraPositionOnMap,
    } = this.props;
    removeCameraOnMap(cam);

    moveCameraPositionOnMap([
      ...cameraPositionOnMap.filter(
        (item) =>
          item.camId !==
          cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
      ),
    ]);
  };

  render() {
    const { camerasOnMap } = this.props;
    const { isLoadingMap } = this.state;

    return (
      <div className="draggable-container" id="draggable-container">
        {camerasOnMap.map((cam, ind) => {
          const coordX =
            cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_X";
          const coordY =
            cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId + "_Y";

          return (
            <Draggable
              x={this.state[coordX]}
              y={this.state[coordY]}
              onMove={this._move}
              cam={cam}
              key={ind}
            >
              {isLoadingMap ? null : (
                <MapCameraPanel
                  cam={cam}
                  ind={ind}
                  removeCameraFromMap={this.removeCameraFromMap}
                />
              )}
            </Draggable>
          );
        })}
      </div>
    );
  }
}

const mapDispatchToProps = {
  removeCameraOnMap,
  moveCameraPositionOnMap,
};

const mapStateToProps = (state) => ({
  camerasOnMap: getCamerasOnMap(state),
  isOpenNavTab: isOpenNavTab(state),
  isBottombarExpanded: isBottombarExpanded(state),
  isBottombarReduced: isBottombarReduced(state),
  cameraPositionOnMap: getCameraPositionOnMap(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DraggableCamerasOnMap)
);
