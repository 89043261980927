import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as moment from "moment";
import { tap, catchError, take } from "rxjs/operators";
import { of } from "rxjs";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
//COMPONENTS
import WebcamDetailHeader from "components/layout/webcams/webcam-detail-header";
//STORE AND ACTIONS
import { getCurrentCamera } from "store";
import { setCurrentCamera } from "reducers/installations/cameras/cameras.actions";

//MODEL
import { AlarmModel } from "reducers/installations/cameras/cameras.model";

const mapStateToProps = (state) => ({
  currentSelectedCam: getCurrentCamera(state),
});

const mapDispatchToProps = {
  setCurrentCamera,
};

class WebcamsAlarmComponent extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {
      openAlarms: [],
    };
  }

  componentDidMount = () => {
    this.apiService = createApiService(this.context);

    this.callCameraAlarms();
  };

  componentDidUpdate = (prevProps) => {
    const { currentSelectedCam } = this.props;

    if (
      currentSelectedCam &&
      currentSelectedCam.properties &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv.id &&
      prevProps.currentSelectedCam &&
      prevProps.currentSelectedCam.properties &&
      prevProps.currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
      prevProps.currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
        .cctv &&
      prevProps.currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
        .cctv.id &&
      prevProps.currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
        .cctv.id !==
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv.id
    ) {
      this.callCameraAlarms();
    }
  };

  callCameraAlarms = () => {
    const { currentSelectedCam } = this.props;

    if (
      currentSelectedCam &&
      currentSelectedCam.properties &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv.id
    ) {
      let id =
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv.id;

      this.subscriptions$.push(
        this.apiService
          .getAlarmsByCameraId(id)
          .pipe(
            take(1),
            tap((data) => {
              let currentAlarms = AlarmModel.fromArray(data);

              this.setState({
                openAlarms: currentAlarms,
              });
            }),

            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  header = () => {
    const {
      currentSelectedCam,
      pinnedCameras,
      toggleDetails,
    } = this.props;

    return (
      <WebcamDetailHeader
        currentSelectedCam={currentSelectedCam}
        pinnedCameras={pinnedCameras}
        toggleDetails={toggleDetails}
      />
    );
  };

  tabs = () => {
    const { handleClickSecondTab } = this.props;
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickSecondTab();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Allarmi</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { openAlarms } = this.state;
    return (
      <div className="uk-flex uk-flex-column contentBodyStationDetail">
        {openAlarms.length > 0 &&
          openAlarms.map((alarm, index) => {
            return (
              <div
                key={index}
                className="uk-width-1-1 uk-flex uk-position-relative uk-margin-bottom"
              >
                <div className="uk-width-2-3 text-alarm-tab ">
                  <div
                    className={
                      alarm.priority === "LOW"
                        ? "low-priority-alarm"
                        : alarm.priority === "MEDIUM"
                        ? "medium-priority-alarm"
                        : "high-priority-alarm"
                    }
                  >
                    {alarm.description ? alarm.description : ""}
                  </div>
                  <div>{alarm.message ? alarm.message : ""}</div>
                  <div>{alarm.status ? alarm.status : "-"}</div>
                </div>
                <div className="uk-width-1-3 uk-margin-left text-alarm-tab">
                  {alarm.openTime
                    ? moment(alarm.openTime).format("DD/MM/YY  HH:mm")
                    : "-"}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  render() {
    const { currentSelectedCam } = this.props;
    return (
      <div>
        {this.header()}
        {currentSelectedCam &&
          currentSelectedCam.properties &&
          currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
          currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
          currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
            .deviceId &&
          this.tabs()}
        {this.body()}
      </div>
    );
  }

  componentWillUnmount = () => {
    this.subscriptions$.forEach((sub$) => sub$.unsubscribe());
  };
}

WebcamsAlarmComponent.contextType = EnvironmentContext;
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WebcamsAlarmComponent)
);
// export default WebcamsAlarmComponent;
