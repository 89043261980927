export const PmvActions = {
  SET_LIST_PMV: "PARKING/SET_LIST_PMV",
  SET_CURRENT_PMV: "PARKING/SET_CURRENT_PMV",
  UPDATE_CURRENT_PMV: "PARKING/UPDATE_CURRENT_PMV",
  RESET_CURRENT_PMV: "PARKING/RESET_CURRENT_PMV",
  SET_CURRENT_GROUP: "PARKING/SET_CURRENT_GROUP",
  // UPDATE_PARKING_STATE_WS: "PARKING/UPDATE_PARKING_STATE_WS",
  // RESET_PARKING_STATE_WS: "PARKING/RESET_PARKING_STATE_WS",
};

export const setPmvList = (list) => (dispatch) => {
  dispatch({
    type: PmvActions.SET_LIST_PMV,
    payload: { list },
  });
};

export const setCurrentPmv = (current) => (dispatch) => {
  dispatch({
    type: PmvActions.SET_CURRENT_PMV,
    payload: current,
  });
};
export const updateCurrentPmv = (current) => (dispatch) => {
  dispatch({
    type: PmvActions.UPDATE_CURRENT_PMV,
    payload: current,
  });
};
export const resetCurrentPmv = () => (dispatch) => {
  dispatch({
    type: PmvActions.RESET_CURRENT_PMV,
  });
};

// export const updatePmvStatesWS = (states) => (dispatch) => {
//   dispatch({
//     type: PmvActions.UPDATE_PARKING_STATE_WS,
//     payload: states,
//   });
// };

// export const resetPmvStatesWS = () => (dispatch) => {
//   dispatch({
//     type: PmvActions.RESET_PARKING_STATE_WS,
//   });
// };

export const setCurrentPmvGroup = (current) => (dispatch) => {
  dispatch({
    type: PmvActions.SET_CURRENT_GROUP,
    payload: current,
  });
};
