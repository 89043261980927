import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";

class InstallationLayer extends CompositeLayer {
  renderLayers() {
    const { id, data, visible, layoutMap, isDrawingMode } = this.props;

    /*  const installationTypes = [
      "cctv",
      "vms",
      "panel",
      "stopsign",
      "tpl",
      "parking",
    ];

    let list = [];
    let selected = [];

    installationTypes.forEach((x) => {
      if (installationsReducer[x].visible) {
        list.push(...installationsReducer[x].filtered);
        selected.push(...installationsReducer[x].selected);
      }
    });

    installationTypes.every((x) => {
      if (installationsReducer[x].visible && installationsReducer[x].current) {
        selected = [installationsReducer[x].current];
        return false; // stop
      }

      return true; // continue
    }); */
    return new IconLayer(
      this.getSubLayerProps({
        id: id,
        data: data,
        pickable: isDrawingMode ? false : true,
        ...DeckLayerUtil.loadOptions(),
        getIcon: (data) => ({
          url: data.selected
            ? DeckLayerUtil.getSelectedIconInstallations(
                data.category.type,
                data.deviceStatus,
                layoutMap
              ) /* 
          selected.findIndex(
            (x) => x.id === data.id && x.category.type === data.category.type
          ) >= 0
            ? DeckLayerUtil.getSelectedIconInstallations(data.category.type)*/
            : DeckLayerUtil.getIconInstallations(
                data.category.type,
                data.deviceStatus,
                layoutMap
              ),
          width: 144,
          height: 144,
        }),
        billboard: false,
        sizeScale: 8,
        showCluster: true,
        visible: visible,
        getPosition: (d) => [d.location.longitude, d.location.latitude],
        getSize: () => 6,
      })
    );
  }
}

InstallationLayer.layerName = "InstallationLayer";

export default InstallationLayer;
