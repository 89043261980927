import React, { Component, forwardRef } from "react";
import "./style.less";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, setHours } from "date-fns";

class CustomisedTrafficDateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      width100,
      value,
      callbackFromParent,
      startDate,
      isEndDate,
      disabled,
      className,
      dateFormat,
      timeFormat,
      isSameDate,
      iconRatio,
      maxDate,
      isTimeEndSameDay,
      maxTime,
    } = this.props;

    const CustomInput = forwardRef((props, ref) => {
      return (
        <div
          className={
            "date-picker-input uk-flex uk-padding uk-input width100" +
            (className ? " " + className : "")
          }
          ref={ref}
          onClick={props.onClick}
        >
          <div className={width100 ? "text-date" : "uk-margin-right inline"}>
            {props.value}{" "}
          </div>
          <span
            className="uk-icon uk-position-right uk-margin-right uk-flex uk-flex-middle"
            uk-icon={
              "icon: acr-interface-calendar; " +
              (iconRatio ? iconRatio : "ratio: 1.5")
            }
          />
        </div>
      );
    });
    return (
      <DatePicker
        selected={value}
        onChange={(date) => callbackFromParent(date)}
        customInput={<CustomInput />}
        dateFormat={dateFormat ? dateFormat : "MMMM d yyyy, h:mm"}
        minDate={isEndDate ? startDate : null}
        maxDate={maxDate ? maxDate : null}
        readOnly={disabled}
        showTimeSelect
        timeFormat={timeFormat ? timeFormat : "p"}
        timeIntervals={15}
        minTime={
          isSameDate
            ? startDate && isEndDate
              ? isTimeEndSameDay
                ? setHours(setMinutes(startDate, 0), 0)
                : setHours(
                    setMinutes(startDate, startDate.getMinutes()),
                    startDate.getHours()
                  )
              : setHours(setMinutes(startDate, 0), 0)
            : setHours(setMinutes(startDate, 0), 0)
        }
        maxTime={maxTime ? maxTime : null}
      />
    );
  }
}
export default CustomisedTrafficDateTimePicker;
