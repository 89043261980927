export const ModalActions = {
  SET_NEW_EVENT: "MODAL/SET_NEW_EVENT",
  RESET_NEW_EVENT: "MODAL/RESET_NEW_EVENT",
  UPDATE_NEW_EVENT: "MODAL/UPDATE_NEW_EVENT",
  HISTORY_PREV_PUNTUALE: "POSITION/HISTORY_PREV_PUNTUALE",
  HISTORY_PREV_ESTESA: "POSITION/HISTORY_PREV_ESTESA",
  SET_SOURCE_SELECT_MODAL: "MODAL/SET_SOURCE_SELECT_MODAL",
  SET_TYPES_SUBTYPES_SELECT_MODAL: "MODAL/SET_TYPES_SUBTYPES_SELECT_MODAL",
  RESET_HOVERED_OBJECTS: "MODAL/RESET_HOVERED_OBJECTS",
  SET_HOVERED_POINT: "MODAL/SET_HOVERED_POINT",
  SET_BOUNDS: "MODAL/SET_BOUNDS",
};

export const setNewEvent = (type, newEvent) => (dispatch) => {
  dispatch({
    type: ModalActions.SET_NEW_EVENT,
    payload: { type, newEvent },
  });
};

export const resetNewEvent = () => (dispatch) => {
  dispatch({
    type: ModalActions.RESET_NEW_EVENT,
  });
};

export const updateNewEvent = (newEvent) => (dispatch) => {
  dispatch({
    type: ModalActions.UPDATE_NEW_EVENT,
    payload: { newEvent },
  });
};

export const historyPrevPuntuale = () => (dispatch) => {
  dispatch({
    type: ModalActions.HISTORY_PREV_PUNTUALE,
  });
};

export const historyPrevEstesa = () => (dispatch) => {
  dispatch({
    type: ModalActions.HISTORY_PREV_ESTESA,
  });
};

export const setSourceSelectModal = (sources) => (dispatch) => {
  dispatch({
    type: ModalActions.SET_SOURCE_SELECT_MODAL,
    payload: sources,
  });
};

export const setTypesSubtypesSelectModal = (typesSub) => (dispatch) => {
  dispatch({
    type: ModalActions.SET_TYPES_SUBTYPES_SELECT_MODAL,
    payload: typesSub,
  });
};

export const resetHoveredObjects = (data) => (dispatch) => {
  dispatch({
    type: ModalActions.RESET_HOVERED_OBJECTS,
    payload: data,
  });
};

export const setHoveredPoint = (data) => (dispatch) => {
  dispatch({
    type: ModalActions.SET_HOVERED_POINT,
    payload: data,
  });
};

export const setBounds = (data) => (dispatch) => {
  dispatch({
    type: ModalActions.SET_BOUNDS,
    payload: data,
  });
};
