import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { take, tap, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import "../style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import moment from "moment";
import { debounceTime, switchMap } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";

//COMPONENTS
import EventHistoryDetail from "./event-detail";
import TableFilters from "components/shared/table-top-filters/table-filters";
import PageNumbers from "components/shared/page-numbers/page-number";
import TableHead from "components/shared/table-components/table-head";
//STORE
import {
  getCurrentEvent,
  getSelectedHistory,
  getActiveEventsFiltered,
  getPlannedEventsFiltered,
  isActiveVisible,
  isPlannedVisible,
  getAllEvents,
  getNewEvent,
  isOpenNavTab,
  getIsHistoryDetailOpen,
  getSavedTableColumns,
  getSavedDateColumns,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedSearchText,
  getSavedFilters,
  getSavedToggledFilters,
  getSelectModal,
  getWsLastEvent,
  getUsername,
  getUserRoles,
} from "store";
import {
  setCurrentEvent,
  setSelectedHistory,
  resetListEventWs,
} from "reducers/events/events.actions";
import { toggleEventHistoryDetail } from "reducers/ui/event-menu/event-menu.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import { setNewEvent } from "reducers/modal/modal.actions";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { addActivity } from "reducers/user/user.actions";
//UTILS
import * as FilterUtils from "utils/filter-utils";
import {
  downloadExcelData,
  isInViewport,
  filterIfSelectedFiltered,
  mapFromApi,
  loadHistoryData,
  initColumns,
  prepareFiltersAPIRecords,
  updateValueByWS,
  attributesApi,
} from "utils/table-utils/utils-report-history";
import { debounce } from "utils/utils";
import { saveBlob } from "utils/hooks";
import {
  handleFilterStringChange,
  resetChildrenForHeaderDropdown,
  onDropdownCheckboxFilterClick,
  resetFiltersState,
  initializeTableState,
  getTableStateSortConfig,
  handleClickDecreasePage,
  handleClickIncreasePage,
  getCurrentPageNumber,
} from "utils/table-utils/util-general-tables";
//MODEL
import { UserActivityModel } from "reducers/user/user.model";

import { renderTableData, toTableColumns } from "../render-table";

const FILTER_TARGET = "historyFilters";

class EventHistoryTable extends Component {
  apiService;
  subscriptions = [];
  selectedRef;
  numberPage$;

  constructor(props) {
    super(props);
    this.state = {
      ...initializeTableState,
      sortConfig: getTableStateSortConfig("event"),
      listEventsFromApi: [],
      filterConfigs: [],
      //FOR API CALLS
      historyList: [],
      currentHistoryPage: 0,
      numberOfHistoryElements: 10,
      listAttributes: [],
      TABLE_COLUMNS: {},
      DATE_COLUMNS: [],
      detailOrderEnum: "DESC",
    };
    this.numberPage$ = new BehaviorSubject();
    this.fetchDataDebounced = debounce(this.fetchData, 600);
  }

  componentDidMount = () => {
    const { setNewEvent, dateFrom, dateTo, searchText } = this.props;

    this.subscriptions.push(
      this.numberPage$
        .pipe(
          debounceTime(500),
          switchMap(
            (x) =>
              new Observable((observer) => {
                const { pageToDisplay } = this.state;
                if (pageToDisplay !== "") {
                  this.refreshData();
                }
              })
          )
        )
        .subscribe()
    );

    setNewEvent("dateStartEvent", dateFrom ? dateFrom : null);
    setNewEvent("dateEndEvent", dateTo ? dateTo : null);
    this.apiService = createApiService(this.context);

    if (searchText) this.setState({ filterStr: searchText });
    attributesApi(this, true);
    this.tableEventConfigs();
    this.refreshData();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { tableFilters } = this.props;

    if (
      (tableFilters.dateColumns &&
        tableFilters.dateColumns !== prevProps.tableFilters.dateColumns) ||
      (tableFilters.dateFrom &&
        tableFilters.dateFrom !== prevProps.tableFilters.dateFrom) ||
      (tableFilters.dateTo &&
        tableFilters.dateTo !== prevProps.tableFilters.dateTo)
    ) {
      this.fetchData(0);
    }
  };

  tableEventConfigs = () => {
    this.apiService
      .getFiltersConfig()
      .pipe(
        take(1),
        tap((_) => {
          const {
            tableColumns,
            saveTableFilters,
            toggledFilters,
            selectModal,
            dateColumns,
            searchText,
            dateFrom,
            dateTo,
          } = this.props;
          const { columns, date } = initColumns(tableColumns, dateColumns);
          let sld = FilterUtils.constructFlatMultiSelect(columns);
          let nestedFilters = FilterUtils.matchNestedFilters(
            FilterUtils.deepCopyObjOfArrays(toggledFilters),
            _,
            selectModal
          );
          if (!tableColumns) {
            let newColumns = FilterUtils.constructTableDropdown(
              columns,
              nestedFilters || [],
              selectModal,
              "history"
            );
            saveTableFilters(
              {
                tableColumns: newColumns,
                dateColumns: date,
                dateFrom: dateFrom,
                dateTo: dateTo,
                searchText: searchText || "",
                toggledFilters: nestedFilters,
              },
              FILTER_TARGET
            );
            this.setState({
              selected: sld,
              TABLE_COLUMNS: newColumns,
              DATE_COLUMNS: date,
              filterStr: searchText || "",
            });
          } else if (tableColumns) {
            saveTableFilters(
              {
                tableColumns: columns,
                dateColumns: date,
                dateFrom: dateFrom,
                dateTo: dateTo,
                searchText: searchText || "",
                toggledFilters: nestedFilters,
              },
              FILTER_TARGET
            );
            this.setState({
              selected: sld,
              TABLE_COLUMNS: columns,
              DATE_COLUMNS: date,
              filterStr: searchText || "",
            });
          }
        })
      )
      .subscribe();
  };

  downloadExcel = () => {
    const { sortConfig, filterStr, selected, count, startIndex } = this.state;
    const { tableFilters } = this.props;

    let newData = downloadExcelData(selected);

    const {
      filters,
      filterDateFrom,
      filterDateTo,
      states,
      orderBy,
      orderType,
      search,
      dateFilterTarget,
    } = prepareFiltersAPIRecords(
      "history",
      tableFilters,
      null,
      null,
      sortConfig,
      filterStr
    );

    this.apiService
      .fetchEventCompleteHistoryExcel(
        newData,
        filterDateFrom,
        filterDateTo,
        states,
        filters,
        dateFilterTarget,
        orderBy,
        orderType,
        search,
        count,
        startIndex
      )
      .pipe(
        tap((blob) => {
          let filename =
            "situation_record_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postEventDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postEventDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "SITUATION",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Storico Eventi"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  handleOnScroll = (e) => {
    const { toggleEventHistoryDetail } = this.props;
    if (!isInViewport(this.selectedRef)) {
      toggleEventHistoryDetail(false);
    }
  };

  onChangeDate = (value, name) => {
    const { setNewEvent, saveTableFilters, tableFilters } = this.props;
    if (value && value !== "") {
      setNewEvent(name, value);
      saveTableFilters(
        {
          ...tableFilters,
          dateFrom: name === "dateStartEvent" ? value : tableFilters.dateFrom,
          dateTo: name === "dateEndEvent" ? value : tableFilters.dateTo,
        },
        FILTER_TARGET
      );
    }
  };

  handleFilterChange = (e) => {
    handleFilterStringChange(this, e, FILTER_TARGET, this.fetchDataDebounced);
  };

  // GESTIONE CHECKBOX: VISUALIZZAZIONE COLONNE
  handleSelectChange = (visibleColumns) => {
    FilterUtils.handleSelectChange(this, visibleColumns, FILTER_TARGET);
  };

  handleSetDateFilterTarget = (date) => {
    const { saveTableFilters, tableFilters, dateColumns } = this.props;
    let selected = [...dateColumns].map((element) => {
      if (element.name === date.name) element.selected = true;
      else element.selected = false;
      return element;
    });
    saveTableFilters(
      {
        ...tableFilters,
        dateColumns: [...selected],
      },
      FILTER_TARGET
    );
  };

  handleResetDateFilterTarget = () => {
    const {
      saveTableFilters,
      tableFilters,
      dateColumns,
      setNewEvent,
    } = this.props;
    let selected = [...dateColumns].map((element) => {
      element.selected = false;
      return element;
    });
    setNewEvent("dateStartEvent", null);
    setNewEvent("dateEndEvent", null);
    saveTableFilters(
      {
        ...tableFilters,
        dateTo: null,
        dateFrom: null,
        dateColumns: [...selected],
      },
      FILTER_TARGET
    );
  };

  handleTableRowClick = (eventSelected) => {
    const {
      setCurrentEvent,
      setSelectedHistory,
      toggleEventHistoryDetail,
      isHistoryEventOpen,
      currentEvent,
    } = this.props;
    const {
      listEventsFromApi,
      numberOfHistoryElements,
      detailOrderEnum,
    } = this.state;
    let currentSelected = listEventsFromApi.find(
      (event) => event.situationRecordId === eventSelected.eventId
    );
    if (currentSelected) {
      if (currentSelected.validity.validityStatus === "closed") {
        setSelectedHistory(currentSelected);
        if (currentEvent) setCurrentEvent(null);
      } else {
        setCurrentEvent(currentSelected);
      }

      loadHistoryData(
        this,
        currentSelected,
        numberOfHistoryElements,
        0,
        detailOrderEnum,
        false
      );
      this.setState({
        currentHistoryPage: 0,
      });
      toggleEventHistoryDetail(!isHistoryEventOpen);
    }
  };

  handleClickIncrease = () => {
    handleClickIncreasePage(this);
  };

  handleClickDecrease = () => {
    handleClickDecreasePage(this);
  };

  editablePageNumber = (inputCurrPage) => {
    getCurrentPageNumber(this, inputCurrPage);
  };

  handleCloseDetail = (e) => {
    const {
      toggleEventHistoryDetail,
      currentEvent,
      historySelected,
      setSelectedHistory,
      setCurrentEvent,
    } = this.props;

    if (currentEvent) {
      setCurrentEvent(null);
    }
    if (historySelected) {
      setSelectedHistory(null);
    }
    this.setState({
      historyList: [],
    });
    toggleEventHistoryDetail(false);
  };

  resetFilters = (e) => {
    const { setNewEvent, resetTableFilters } = this.props;
    const { selected, TABLE_COLUMNS, DATE_COLUMNS } = this.state;
    e.stopPropagation();

    let newStateTableColumns = resetChildrenForHeaderDropdown(TABLE_COLUMNS);

    Object.keys(newStateTableColumns).map((_) => {
      newStateTableColumns[_].checked = true;
      newStateTableColumns[_].show = true;
      return _;
    });

    resetFiltersState(
      this,
      newStateTableColumns,
      selected,
      this.fetchData,
      "event"
    );

    setNewEvent("dateStartEvent", null);
    setNewEvent("dateEndEvent", null);
    resetTableFilters(
      {
        tableColumns: newStateTableColumns,
        dateColumns: [...DATE_COLUMNS],
      },
      FILTER_TARGET
    );
  };

  requestSort = (key) => {
    const { sortConfig } = this.state;

    let direction = "descending";
    if (sortConfig.key === key && sortConfig.direction === "descending") {
      direction = "ascending";
    }
    this.setState(
      { sortConfig: { key: key, direction: direction } },
      this.refreshData
    );
  };

  //CALL API TO OBTAIN NEW EVENT LIST
  refreshData = (clickEvent = null) => {
    const { currentPage } = this.state;
    this.setState({
      ...this.state,
      listEventsFromApi: [],
    });

    //CALL OPEN AND SCHEDULED EVENTS ACCODING TO CHECKED FIELDS IN MENU
    // let refreshPage =
    //   typeof page === "number" && page >= 0 ? page : currentPage;

    //CALL OPEN AND SCHEDULED EVENTS ACCODING TO CHECKED FIELDS IN MENU
    this.fetchData(currentPage);
  };

  handleLoadMoreDetails = (currentSelected) => {
    const {
      numberOfHistoryElements,
      currentHistoryPage,
      detailOrderEnum,
    } = this.state;
    loadHistoryData(
      this,
      currentSelected,
      numberOfHistoryElements,
      currentHistoryPage + 1,
      detailOrderEnum,
      true
    );
  };

  fetchData = (page = null, offset = null) => {
    const { count, currentPage, sortConfig, filterStr } = this.state;
    const { tableFilters } = this.props;

    this.setState({
      ...this.state,
      listEventsFromApi: [],
    });

    const {
      filters,
      currentPageOffset,
      filterDateFrom,
      filterDateTo,
      states,
      orderBy,
      orderType,
      search,
      dateFilterTarget,
    } = prepareFiltersAPIRecords(
      "history",
      tableFilters,
      currentPage,
      page,
      sortConfig,
      filterStr
    );

    //CALL OPEN AND SCHEDULED EVENTS ACCODING TO CHECKED FIELDS IN MENU
    this.subscriptions.push(
      this.apiService
        .getSituationRecords(
          count,
          currentPageOffset,
          filterDateFrom,
          states,
          filterDateTo,
          filters,
          dateFilterTarget,
          null,
          orderBy,
          orderType,
          search
        )
        .pipe(
          tap((events) => {
            if (events && !events.error) {
              const { pageMax, mapped } = mapFromApi(events);
              //MAP LIST OBJECTS TO TABLE COLUMNS
              //SET ORIGINAL AND FILTERED LISTS
              this.setState({
                listEventsFromApi: mapped,
                pageMax: Math.ceil(pageMax),
                currentPage: currentPageOffset,
              });
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  handleDetailsReorder = (target, currentSelected) => {
    const { numberOfHistoryElements } = this.state;
    this.setState({
      detailOrderEnum: target,
    });
    loadHistoryData(
      this,
      currentSelected,
      numberOfHistoryElements,
      0,
      target,
      false
    );
  };

  onCheckboxFilterClick = (newTableColumns) => {
    onDropdownCheckboxFilterClick(
      this,
      this.refreshData,
      FILTER_TARGET,
      newTableColumns
    );
  };

  render = () => {
    const {
      listEventsFromApi,
      filterStr,
      selected,
      historyList,
      count,
      listAttributes,
      detailOrderEnum,
      pageToDisplay,
      pageMax,
      sortConfig,
    } = this.state;
    const {
      toggleGenericTable,
      currentEvent,
      historySelected,
      newEvent,
      isOpenNav,
      dateColumns,
      setSelectedHistory,
      wsLastEvent,
      resetListEventWs,
      tableColumns,
    } = this.props;

    //RIORDINAMENTO
    let sortedlistEvents = [...listEventsFromApi];
    //CHECK PREVENTIVO CON IL VALORE DELL'EVENTO IN SOCKET
    updateValueByWS(
      this,
      sortedlistEvents,
      wsLastEvent,
      true,
      resetListEventWs
    );

    //RICERCA PER FILTRI FIGLI DELLA SELECT
    sortedlistEvents = filterIfSelectedFiltered(this, sortedlistEvents);
    //MAPPA PER VISUALIZZAZIONE SU TABELLA
    sortedlistEvents = toTableColumns(this, sortedlistEvents);

    if (currentEvent) {
      setSelectedHistory(null);
    }

    return (
      <div className="acr-table uk-flex uk-flex-column">
        <div className="navbar-placeholder"></div>
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <div className="grey-space"></div>
          <div className=" uk-width-1-2">
            <ul className=" uk-tab-page-secondary uk-tab uk-margin-remove">
              <li className="uk-active bottombar-title">Storico eventi</li>
              {/* <li className="bottombar-title uk-margin-left">
                <a href="/#">Tab 2</a>
              </li>
              <li className="bottombar-title uk-margin-left">
                <a href="/#">Tab 3</a>
              </li> */}
            </ul>
          </div>
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            {/* <span
              className="uk-margin-small-right pointer"
              uk-icon="icon: acr-interface-new-window"
            ></span> */}
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={(e) => {
                e.preventDefault();
                toggleGenericTable("eventHistoryTable");
              }}
            ></span>
          </div>
        </div>
        <TableFilters
          isOpenNav={isOpenNav}
          dateStart={{ date: newEvent.dateStartEvent, name: "dateStartEvent" }}
          dateEnd={{ date: newEvent.dateEndEvent, name: "dateEndEvent" }}
          changeDate={this.onChangeDate}
          handleFilterChange={this.handleFilterChange}
          handleSelectChange={this.handleSelectChange}
          handleSetDateFilterTarget={this.handleSetDateFilterTarget}
          handleResetDateFilterTarget={this.handleResetDateFilterTarget}
          resetFilters={this.resetFilters}
          filterStr={filterStr}
          filtrableDateColumns={dateColumns}
          selected={selected}
        ></TableFilters>
        {/* <div
          className={"uk-flex uk-flex-row" + (isOpenNav ? " displayNone" : "")}
        >
          
        </div> */}
        <div className="uk-flex uk-flex-right button-bar">
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.refreshData}
            >
              <span
                uk-icon="icon: refresh"
                uk-tooltip="Refresh tabella eventi"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              // disabled={true}
              onClick={this.downloadExcel}
            >
              <span
                uk-icon="icon: download"
                uk-tooltip="Download Excel"
                className="filterBarIcons borderWhite"
              ></span>
            </button>
          </div>
          <button
            className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
            onClick={this.resetFilters}
          >
            <span
              uk-icon="icon: acr-filter-remove"
              uk-tooltip="Reset filtri"
              className="filterBarIcons"
            ></span>
          </button>
          <div className="uk-flex uk-flex-right uk-padding-remove-left">
            <div className="uk-flex button-page">
              <PageNumbers
                handleClickDecrease={this.handleClickDecrease}
                handleClickIncrease={this.handleClickIncrease}
                getPageNumber={this.editablePageNumber}
                currentPage={pageToDisplay}
                minVal={1}
                pageMax={pageMax}
              />
            </div>
          </div>
        </div>
        <div
          className={
            currentEvent || historySelected
              ? "uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top table-external-container uk-margin-left detail-present-margin-bottom"
              : "uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left​​"
          }
          onScroll={(e) => this.handleOnScroll(e)}
        >
          <table
            id="eventTable"
            className="uk-table uk-table-divider uk-table-hover"
          >
            <thead>
              <TableHead
                onCheckboxFilterClick={this.onCheckboxFilterClick}
                requestSort={this.requestSort}
                tableColumns={tableColumns}
                sortConfig={sortConfig}
              />
            </thead>
            <tbody>{renderTableData(this, sortedlistEvents)}</tbody>
          </table>
        </div>
        {(currentEvent || historySelected) && (
          <EventHistoryDetail
            historyList={historyList}
            loadMoreData={this.handleLoadMoreDetails}
            recordPerPage={count}
            listAttributes={listAttributes}
            closeDetail={this.handleCloseDetail}
            handleDetailsReorder={this.handleDetailsReorder}
            orderType={detailOrderEnum}
          />
        )}
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

EventHistoryTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleGenericTable,
  toggleEventHistoryDetail,
  setSelectedHistory,
  setNewEvent,
  setCurrentEvent,
  saveTableFilters,
  resetTableFilters,
  resetListEventWs,
  addActivity,
};

const mapStateToProps = (state) => ({
  allEvents: getAllEvents(state),
  currentEvent: getCurrentEvent(state),
  historySelected: getSelectedHistory(state),
  newEvent: getNewEvent(state),
  activeEvents: getActiveEventsFiltered(state),
  plannedEvents: getPlannedEventsFiltered(state),
  isActiveVisible: isActiveVisible(state),
  isPlannedVisible: isPlannedVisible(state),
  isOpenNav: isOpenNavTab(state),
  isHistoryEventOpen: getIsHistoryDetailOpen(state),
  tableColumns: getSavedTableColumns(state, FILTER_TARGET),
  dateColumns: getSavedDateColumns(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
  searchText: getSavedSearchText(state, FILTER_TARGET),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  toggledFilters: getSavedToggledFilters(state, FILTER_TARGET),
  selectModal: getSelectModal(state),
  wsLastEvent: getWsLastEvent(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventHistoryTable)
);
