export const selectFields = {
  // STATO_EVENTO: {
  //   properties: [
  //     { id: undefined, name: "-", disabled: false },
  //     { id: 1, name: "Attivo", disabled: false },
  //     { id: 2, name: "Pianificato", disabled: false },
  //   ],
  // },
  TIPO_EVENTO: {
    properties: [
      { id: undefined, name: "-", type: null, disabled: false },
      { id: 7, name: "Incidente", type: "Accident", disabled: false },
      {
        id: 8,
        name: "Gestione viabilità",
        type: "AbnormalTraffic",
        disabled: false,
      },
      {
        id: 9,
        name: "Mezzi di soccorso",
        type: "RoadsideAssistance",
        disabled: false,
      },
      {
        id: 10,
        name: "Cantiere stradale",
        type: "ConstructionWorks",
        disabled: false,
      },
      {
        id: 11,
        name: "Alert meteo",
        type: "WeatherRelatedRoadConditions",
        disabled: false,
      },
      {
        id: 12,
        name: "Veicoli in avaria",
        type: "VehicleObstruction",
        disabled: false,
      },
    ],
  },
  GRAVITA: {
    properties: [
      { id: 0, name: "HIGHEST", disabled: false },
      { id: 1, name: "HIGH", disabled: false },
      { id: 2, name: "MEDIUM", disabled: false },
      { id: 3, name: "LOW", disabled: false },
      { id: 4, name: "LOWEST", disabled: false },
      { id: 5, name: "UNKNOWN", disabled: false },
    ],
  },
  VALIDITA: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "VALIDO", disabled: false },
      { id: 2, name: "TERMINATO", disabled: false },
    ],
  },
  DIREZIONE: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "CENTRO", disabled: false },
      { id: 2, name: "FUORI", disabled: false },
      { id: 3, name: "ENTRAMBE", disabled: false },
    ],
  },
  CORSIA: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 0, name: "0 - Emergenza", disabled: false },
      { id: 1, name: "1 - Marcia", disabled: false },
      { id: 2, name: "2 - Sorpasso", disabled: false },
      { id: 3, name: "3 - Sorpasso veloce", disabled: false },
    ],
  },
  OPERAZIONE: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 0, name: "Fine :conclusione dell’evento/pratica", disabled: false },
      { id: 1, name: "Errore Inserimento", disabled: false },
      { id: 2, name: "Falsa Segnalazione", disabled: false },
      { id: 3, name: "Non Trovato", disabled: false },
    ],
  },
  ID_EVENTO: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "1", disabled: false },
      { id: 2, name: "2", disabled: false },
      { id: 3, name: "3", disabled: false },
      { id: 4, name: "4", disabled: false },
      { id: 5, name: "5", disabled: false },
      { id: 6, name: "6", disabled: false },
      { id: 7, name: "7", disabled: false },
      { id: 8, name: "8", disabled: false },
      { id: 9, name: "9", disabled: false },
      { id: 10, name: "10", disabled: false },
      { id: 11, name: "11", disabled: false },
      { id: 12, name: "12", disabled: false },
      { id: 13, name: "13", disabled: false },
      { id: 14, name: "14", disabled: false },
      { id: 15, name: "15", disabled: false },
    ],
  },
  FONTE: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Polizia Stradale", disabled: false },
      { id: 2, name: "Polizia Locale", disabled: false },
      { id: 3, name: "Carabinieri", disabled: false },
      { id: 4, name: "Vigili Del Fuoco", disabled: false },
      { id: 5, name: "Protezione Civile", disabled: false },
      { id: 6, name: "Arpav", disabled: false },
      { id: 7, name: "DSS System", disabled: false },
      { id: 8, name: "Anas", disabled: false },
      { id: 9, name: "Veneto Strade", disabled: false },
      { id: 10, name: "Autostrade", disabled: false },
      { id: 11, name: "Croce Rossa", disabled: false },
      { id: 12, name: "Iso Radio", disabled: false },
      { id: 13, name: "Areonautica Militare", disabled: false },
    ],
  },
  // SOTTOTIPO_EVENTO: {
  //   properties: [
  //     { id: undefined, name: "-", disabled: false },
  //     { id: 1, name: "Coda estesa per due chilometri", disabled: false },
  //     { id: 2, name: "Traffico inteso orario di lavoro", disabled: false },
  //     { id: 3, name: "Nebbia Fitta", disabled: false },
  //     { id: 4, name: "Neve", disabled: false },
  //     { id: 5, name: "Ghiaccio", disabled: false },
  //     { id: 6, name: "Mezzi coinvolti", disabled: false },
  //     { id: 7, name: "Rifacimento pavimentazione", disabled: false },
  //     {
  //       id: 8,
  //       name: "Nebbia fitta per 10km. Limite di velocità inferiore",
  //       disabled: false,
  //     },
  //     { id: 9, name: "Rallentamento per 3km. Code per 2km", disabled: false },
  //     {
  //       id: 10,
  //       name: "Cantiere stradale esteso per due chilometri",
  //       disabled: false,
  //     },
  //     { id: 11, name: "Uomini al lavoro", disabled: false },
  //     { id: 12, name: "Incidente con piu' veicoli coinvolti", disabled: false },
  //     { id: 13, name: "In attesa di ambulanza", disabled: false },
  //     { id: 14, name: "In attesa di vigili del fuoco", disabled: false },
  //   ],
  // },
  SOTTOTIPO_EVENTO: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Traffico stazionario", disabled: false },
      { id: 2, name: "Coda", disabled: false },
      { id: 3, name: "Traffico lento", disabled: false },
      { id: 3, name: "Traffico pesante", disabled: false },
    ],
  },
  SOTTOTIPO_INCIDENTE_STRADALE: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Mezzi coinvolti", disabled: false },
    ],
  },
  SOTTOTIPO_CANTIERE_STRADALE: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      {
        id: 1,
        name: "Cantiere stradale esteso per due chilometri",
        disabled: false,
      },
      { id: 2, name: "Uomini al lavoro", disabled: false },
      { id: 3, name: "Rifacimento pavimentazione", disabled: false },
      {
        id: 4,
        name: "Cantiere stradale esteso per due chilometri",
        disabled: false,
      },
    ],
  },

  SOTTOTIPO_ALLERTA_METEO: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Nebbia Fitta", disabled: false },
      { id: 2, name: "Neve", disabled: false },
      { id: 3, name: "Ghiaccio", disabled: false },
      {
        id: 4,
        name: "Nebbia fitta per 10km. Limite di velocità inferiore",
        disabled: false,
      },
    ],
  },
  SOTTOTIPO_VEICOLO_IN_AVARIA: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Veicolo fermo in corsia", disabled: false },
    ],
  },
  SOTTOTIPO_MEZZI_DI_SOCCORSO: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Ambulanza", disabled: false },
      { id: 2, name: "Vigili del fuoco", disabled: false },
    ],
  },
  SOTTOTIPO_EVENTO_TRAFFICO: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Coda estesa per due chilometri", disabled: false },
      { id: 2, name: "Traffico inteso orario di lavoro", disabled: false },
    ],
  },
  CANCELLAZIONE: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Valido sino a cancellazione manuale", disabled: false },
      { id: 2, name: "Cancellazione automatica", disabled: false },
    ],
  },
  GENERAZIONE: {
    properties: [
      { id: 0, name: "OPERATOR", disabled: false },
      { id: 1, name: "DATEX", disabled: false },
      { id: 2, name: "VERBATEL", disabled: false },
    ],
  },
  PROBABILITA: {
    properties: [
      { id: 0, name: "CERTAIN", disabled: true },
      { id: 1, name: "PROBABLE", disabled: true },
      { id: 2, name: "RISK_OF", disabled: true },
    ],
  },
  FORECAST: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "SI", disabled: false },
      { id: 2, name: "NO", disabled: false },
    ],
  },
  UNIT_OF_MEASURE: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Metri", disabled: false },
      { id: 2, name: "Chilometri", disabled: false },
    ],
  },
  VALUE: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Value 1", disabled: false },
      { id: 2, name: "Value 2", disabled: false },
    ],
  },
  ATTRIBUTES: {
    properties: [
      { id: undefined, name: "-", disabled: false },
      { id: 1, name: "Attributes 1", disabled: false },
      { id: 2, name: "Attributes 2", disabled: false },
    ],
  },
  CAUSA: {
    properties: [
      { id: 0, name: "-", type: null, disabled: false },
      { id: 1, name: "Traffico", type: "abnormalTraffic", disabled: false },
      { id: 2, name: "Incidente", type: "accident", disabled: false },
      {
        id: 3,
        name: "Presenza di animali",
        type: "animalPresence",
        disabled: false,
      },
      {
        id: 4,
        name: "Operazioni delle autorità",
        type: "authorityOperation",
        disabled: false,
      },
      {
        id: 5,
        name: "Lavori in corso",
        type: "constructionWork",
        disabled: false,
      },
      { id: 6, name: "Disturbi", type: "disturbance", disabled: false },
      {
        id: 7,
        name: "Condizioni di guida",
        type: "drivingConditions",
        disabled: false,
      },
      {
        id: 8,
        name: "Ostruzione ambientale",
        type: "environmentalObstruction",
        disabled: false,
      },
      {
        id: 9,
        name: "Apparecchiatura o guasto del sistema",
        type: "equipmentOrSystemFault",
        disabled: false,
      },
      {
        id: 10,
        name: "Danni alle infrastrutture",
        type: "infrastructureDamageObstruction",
        disabled: false,
      },
      {
        id: 11,
        name: "Istruzioni per gli utenti della strada",
        type: "instructionToRoadUsers",
        disabled: false,
      },
      {
        id: 12,
        name: "Manutenzione rete stradale",
        type: "networkManagement",
        disabled: false,
      },
      {
        id: 13,
        name: "Condizioni stradali non correlate al tempo",
        type: "nonWeatherRelatedRoadConditions",
        disabled: false,
      },
      { id: 14, name: "Ostruzione", type: "obstruction", disabled: false },
      { id: 15, name: "Ambiente", type: "poorEnvironment", disabled: false },
      { id: 16, name: "Evento pubblico", type: "publicEvent", disabled: false },
      { id: 17, name: "Reinstradamento", type: "rerouting", disabled: false },
      {
        id: 18,
        name: "Manutenzione stradale",
        type: "roadMaintenance",
        disabled: false,
      },
      {
        id: 19,
        name: "Interruzione del servizio di operatore stradale",
        type: "roadOperatorServiceDisruption",
        disabled: false,
      },
      {
        id: 20,
        name: "Gestione di strade o strade o corsie",
        type: "roadOrCarriagewayOrLaneManagement",
        disabled: false,
      },
      {
        id: 21,
        name: "Assistenza stradale",
        type: "roadsideAssistance",
        disabled: false,
      },
      {
        id: 22,
        name: "Interruzione del servizio su strada",
        type: "roadsideServiceDisruption",
        disabled: false,
      },
      {
        id: 23,
        name: "Gestione velocità",
        type: "speedManagement",
        disabled: false,
      },
      {
        id: 24,
        name: "Interruzione del servizio di transito",
        type: "transitServiceDisruption",
        disabled: false,
      },
      {
        id: 25,
        name: "Ostruzione veicolo",
        type: "vehicleObstruction",
        disabled: false,
      },
      {
        id: 26,
        name: "Condizioni meteo stradali avverse",
        type: "weatherRelatedRoadConditions",
        disabled: false,
      },
      {
        id: 27,
        name: "Equipaggiamento invernale",
        type: "winterEquipmentManagement",
        disabled: false,
      },
      {
        id: 28,
        name: "Evento anticipato",
        type: "earlierEvent",
        disabled: false,
      },
      {
        id: 29,
        name: "Incidente anticipato",
        type: "earlierIncident",
        disabled: false,
      },
      {
        id: 30,
        name: "Traffico causa vacanze",
        type: "holidayTraffic",
        disabled: false,
      },
      {
        id: 31,
        name: "Problemi alla frontiera",
        type: "problemsAtBorderPost",
        disabled: false,
      },
      {
        id: 32,
        name: "Problemi generici ",
        type: "problemsAtCustomPost",
        disabled: false,
      },
      {
        id: 33,
        name: "Problemi sulle strade locali",
        type: "problemsOnLocalRoads",
        disabled: false,
      },
      {
        id: 34,
        name: "Evento stradale",
        type: "roadsideEvent",
        disabled: false,
      },
      {
        id: 35,
        name: "Controllo delle gomme",
        type: "rubberNecking",
        disabled: false,
      },
      {
        id: 36,
        name: "Problemi tecnici",
        type: "technicalProblems",
        disabled: false,
      },
      { id: 37, name: "Atti vandalici", type: "vandalism", disabled: false },
      { id: 38, name: "Altri", type: "other", disabled: false },
    ],
  },
  RETE_RDS_TMC: {
    properties: [{ id: "Italia", name: "Italia", disabled: false }],
  },
  RADIUS_INFLUENCE: {
    properties: [
      { id: 1, name: "1", disabled: false },
      { id: 2, name: "2", disabled: false },
      { id: 3, name: "3", disabled: false },
      { id: 4, name: "4", disabled: false },
      { id: 5, name: "5", disabled: false },
      { id: 6, name: "6", disabled: false },
      { id: 7, name: "7", disabled: false },
      { id: 8, name: "8", disabled: false },
      { id: 9, name: "9", disabled: false },
      { id: 10, name: "10", disabled: false },
      { id: 15, name: "15", disabled: false },
      { id: 20, name: "20", disabled: false },
    ],
  },
  RADIUS_INFLUENCE_METERS: {
    properties: [
      { id: 1000, name: "1000", disabled: false },
      { id: 2000, name: "2000", disabled: false },
      { id: 3000, name: "3000", disabled: false },
      { id: 4000, name: "4000", disabled: false },
      { id: 5000, name: "5000", disabled: false },
      { id: 6000, name: "6000", disabled: false },
      { id: 7000, name: "7000", disabled: false },
      { id: 8000, name: "8000", disabled: false },
      { id: 9000, name: "9000", disabled: false },
      { id: 10000, name: "10000", disabled: false },
      { id: 15000, name: "15000", disabled: false },
      { id: 20000, name: "20000", disabled: false },
    ],
  },
};
