import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  take,
  tap,
  catchError,
  concatMap,
  debounceTime,
  switchMap,
} from "rxjs/operators";
import { of, Observable, BehaviorSubject } from "rxjs";
import moment from "moment";
import "../style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
//COMPONENTS
import EventReportDetail from "./event-detail";
import TableFilters from "components/shared/table-top-filters/table-filters";
import PageNumbers from "components/shared/page-numbers/page-number";
import TableHead from "components/shared/table-components/table-head";
//STORE
import {
  getCurrentEvent,
  getActiveEventsFiltered,
  getPlannedEventsFiltered,
  isActiveVisible,
  isPlannedVisible,
  getAllEvents,
  getNewEvent,
  isOpenNavTab,
  getIsReportDetailOpen,
  getSavedTableColumns,
  getSavedDateColumns,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedSearchText,
  getSavedFilters,
  getSavedToggledFilters,
  getSelectModal,
  getWsLastEvent,
  getUsername,
  getUserRoles,
  getSelectedEvents,
  getInputFields,
  isOpenPopup,
} from "store";
import {
  setCurrentEvent,
  resetListEventWs,
} from "reducers/events/events.actions";
import { toggleEventReportDetail } from "reducers/ui/event-menu/event-menu.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import { setNewEvent } from "reducers/modal/modal.actions";
import { setSelectedEvents } from "reducers/events/events.actions";
import { setInputFields } from "reducers/events/events.actions";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { addActivity } from "reducers/user/user.actions";
//UTILS
import * as FilterUtils from "utils/filter-utils";
import {
  downloadExcelData,
  isInViewport,
  mapFromApi,
  initColumns,
  prepareFiltersAPIRecords,
  updateValueByWS,
  attributesApi,
} from "utils/table-utils/utils-report-history";
import { debounce } from "utils/utils";
import { saveBlob } from "utils/hooks";
import {
  handleFilterStringChange,
  resetChildrenForHeaderDropdown,
  onDropdownCheckboxFilterClick,
  handleClickIncreasePage,
  handleClickDecreasePage,
  resetFiltersState,
  getCurrentPageNumber,
  initializeTableState,
  getTableStateSortConfig,
} from "utils/table-utils/util-general-tables";
//MODEL
import { UserActivityModel } from "reducers/user/user.model";
import { renderTableData, toTableColumns } from "../render-table";
import Popup from "components/shared/popup/popup";
import { togglePopup } from "reducers/ui/modal/modal.actions";

const FILTER_TARGET = "reportFilters";

class EventReportTable extends Component {
  apiService;
  subscriptions = [];
  selectedRef;
  numberPage$;

  constructor(props) {
    super(props);
    this.state = {
      ...initializeTableState,
      sortConfig: getTableStateSortConfig("event"),
      listEventsFromApi: [],
      listEvents: [],
      listAttributes: [],
      TABLE_COLUMNS: {},
      DATE_COLUMNS: [],
      fields: { name: "" },
    };
    this.numberPage$ = new BehaviorSubject();
    this.fetchDataDebounced = debounce(this.fetchData, 600);
  }

  componentDidMount = () => {
    const { setNewEvent, dateFrom, dateTo, searchText } = this.props;

    this.apiService = createApiService(this.context);
    this.subscriptions.push(
      this.numberPage$
        .pipe(
          debounceTime(500),
          switchMap((x) => new Observable((observer) => this.refreshData()))
        )
        .subscribe()
    );

    setNewEvent("dateStartEvent", dateFrom ? dateFrom : null);
    setNewEvent("dateEndEvent", dateTo ? dateTo : null);

    if (searchText) this.setState({ filterStr: searchText });
    attributesApi(this, false);
    this.tableEventConfigs();
    this.refreshData();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { tableFilters } = this.props;

    if (
      (tableFilters.dateColumns &&
        tableFilters.dateColumns !== prevProps.tableFilters.dateColumns) ||
      (tableFilters.dateFrom &&
        tableFilters.dateFrom !== prevProps.tableFilters.dateFrom) ||
      (tableFilters.dateTo &&
        tableFilters.dateTo !== prevProps.tableFilters.dateTo)
    ) {
      this.fetchData(0);
    }
  };

  tableEventConfigs = () => {
    this.apiService
      .getFiltersConfig()
      .pipe(
        take(1),
        tap((_) => {
          const {
            tableColumns,
            toggledFilters,
            saveTableFilters,
            selectModal,
            dateColumns,
            searchText,
            dateFrom,
            dateTo,
          } = this.props;

          const { columns, date } = initColumns(tableColumns, dateColumns);
          let sld = FilterUtils.constructFlatMultiSelect(columns);
          let nestedFilters = FilterUtils.matchNestedFilters(
            FilterUtils.deepCopyObjOfArrays(toggledFilters),
            _,
            selectModal
          );
          if (!tableColumns) {
            let newColumns = FilterUtils.constructTableDropdown(
              columns,
              nestedFilters || [],
              selectModal,
              "report"
            );
            saveTableFilters(
              {
                tableColumns: newColumns,
                dateColumns: date,
                dateFrom: dateFrom,
                dateTo: dateTo,
                searchText: searchText || "",
                toggledFilters: nestedFilters,
              },
              FILTER_TARGET
            );
            this.setState({
              selected: sld,
              TABLE_COLUMNS: newColumns,
              DATE_COLUMNS: date,
              filterStr: searchText || "",
            });
          } else if (tableColumns) {
            saveTableFilters(
              {
                tableColumns: columns,
                dateColumns: date,
                dateFrom: dateFrom,
                dateTo: dateTo,
                searchText: searchText || "",
                toggledFilters: nestedFilters,
              },
              FILTER_TARGET
            );
            this.setState({
              selected: sld,
              TABLE_COLUMNS: columns,
              DATE_COLUMNS: date,
              filterStr: searchText || "",
            });
          }
        })
      )
      .subscribe();
  };

  downloadExcel = () => {
    const { sortConfig, filterStr, selected, count, startIndex } = this.state;
    const { tableFilters } = this.props;

    let newData = downloadExcelData(selected);

    const {
      filters,
      filterDateFrom,
      filterDateTo,
      states,
      orderBy,
      orderType,
      search,
      dateFilterTarget,
    } = prepareFiltersAPIRecords(
      "report",
      tableFilters,
      null,
      null,
      sortConfig,
      filterStr
    );

    this.apiService
      .fetchEventExcel(
        newData,
        filterDateFrom,
        filterDateTo,
        states,
        filters,
        dateFilterTarget,
        orderBy,
        orderType,
        search,
        count,
        startIndex
      )
      .pipe(
        tap((blob) => {
          let filename =
            "situation_record_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postEventDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postEventDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "SITUATION",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Resoconto Eventi"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  handleOnScroll = (e) => {
    const { toggleEventReportDetail } = this.props;
    if (!isInViewport(this.selectedRef)) {
      toggleEventReportDetail(false);
    }
  };

  onChangeDate = (value, name) => {
    const { setNewEvent, saveTableFilters, tableFilters } = this.props;
    if (value && value !== "") {
      setNewEvent(name, value);
      saveTableFilters(
        {
          ...tableFilters,
          dateFrom: name === "dateStartEvent" ? value : tableFilters.dateFrom,
          dateTo: name === "dateEndEvent" ? value : tableFilters.dateTo,
        },
        FILTER_TARGET
      );
    }
  };

  handleFilterChange = (e) => {
    handleFilterStringChange(this, e, FILTER_TARGET, this.fetchDataDebounced);
  };

  // GESTIONE CHECKBOX: VISUALIZZAZIONE COLONNE
  handleSelectChange = (visibleColumns) => {
    FilterUtils.handleSelectChange(this, visibleColumns, FILTER_TARGET);
  };

  handleSetDateFilterTarget = (date) => {
    const { saveTableFilters, tableFilters, dateColumns } = this.props;
    let selected = [...dateColumns].map((element) => {
      if (element.name === date.name) element.selected = true;
      else element.selected = false;
      return element;
    });
    saveTableFilters(
      {
        ...tableFilters,
        dateColumns: [...selected],
      },
      FILTER_TARGET
    );
  };

  handleResetDateFilterTarget = () => {
    const {
      saveTableFilters,
      tableFilters,
      dateColumns,
      setNewEvent,
    } = this.props;
    let selected = [...dateColumns].map((element) => {
      element.selected = false;
      return element;
    });
    setNewEvent("dateStartEvent", null);
    setNewEvent("dateEndEvent", null);
    saveTableFilters(
      {
        ...tableFilters,
        dateTo: null,
        dateFrom: null,
        dateColumns: [...selected],
      },
      FILTER_TARGET
    );
  };

  handleTableRowClickSelected = (eventSelected) => {
    const { allEvents, setSelectedEvents, selectedEvents } = this.props;
    let currentSelected = allEvents.find(
      (event) => event.situationRecordId === eventSelected.eventId
    );

    if (currentSelected) {
      setSelectedEvents([...selectedEvents, currentSelected]);
    }
    if ([...selectedEvents].includes(currentSelected)) {
      setSelectedEvents(
        [...selectedEvents].filter((item) => item !== currentSelected)
      );
    }
  };

  handleTableRowClick = (eventSelected) => {
    const { setCurrentEvent, isReportEventOpen, allEvents } = this.props;

    let currentSelected = allEvents.find(
      (event) => event.situationRecordId === eventSelected.eventId
    );

    if (currentSelected) {
      setCurrentEvent(currentSelected);
      toggleEventReportDetail(!isReportEventOpen);
    }
  };

  onChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  closeEvent = () => {
    const { togglePopup } = this.props;
    togglePopup();
  };

  fetchIdsRecord = (e) => {
    const { selectedEvents } = this.props;
    const selectedEventsIds = selectedEvents.map(
      (element) => element.situationRecordId
    );

    if (
      this.apiService.postIdsRecord(selectedEventsIds, this.state.fields.name)
    )
      // this.closeEvent();
      this.resetFilters();
  };

  handleFetch = () => {
    const { isOpenPopup } = this.props;
    if (isOpenPopup) this.fetchIdsRecord();
    this.closeEvent();
  };

  handleClickIncrease = () => {
    handleClickIncreasePage(this);
  };

  handleClickDecrease = () => {
    handleClickDecreasePage(this);
  };

  editablePageNumber = (inputCurrPage) => {
    getCurrentPageNumber(this, inputCurrPage);
  };

  resetFilters = (e) => {
    const {
      setNewEvent,
      resetTableFilters,
      toggledFilters,
      selectedEvents,
    } = this.props;
    const { selected, TABLE_COLUMNS, DATE_COLUMNS } = this.state;

    if (selectedEvents.length > 0) {
      selectedEvents.pop((selectedEvents.length = 0));
    }

    this.state.fields.name = "";

    // e.stopPropagation();

    let newStateTableColumns = resetChildrenForHeaderDropdown(TABLE_COLUMNS);

    Object.keys(newStateTableColumns).map((_) => {
      newStateTableColumns[_].checked = true;
      newStateTableColumns[_].show = true;
      return _;
    });

    setNewEvent("dateStartEvent", null);
    setNewEvent("dateEndEvent", null);
    resetTableFilters(
      {
        tableColumns: newStateTableColumns,
        dateColumns: [...DATE_COLUMNS],
        toggledFilters: toggledFilters,
      },
      FILTER_TARGET
    );
    resetFiltersState(
      this,
      newStateTableColumns,
      selected,
      this.fetchData,
      "event"
    );
  };

  requestSort = (key) => {
    const { sortConfig } = this.state;

    let direction = "descending";
    if (sortConfig.key === key && sortConfig.direction === "descending") {
      direction = "ascending";
    }
    this.setState(
      { sortConfig: { key: key, direction: direction } },
      this.refreshData
    );
  };

  //CALL API TO OBTAIN NEW EVENT LIST
  refreshData = (clickEvent = null) => {
    const { currentPage } = this.state;
    this.setState({
      ...this.state,
      listEventsFromApi: [],
      listEvents: [],
    });

    //CALL OPEN AND SCHEDULED EVENTS ACCODING TO CHECKED FIELDS IN MENU
    this.fetchData(currentPage);
  };

  fetchData = (page = null, offset = null) => {
    const { count, currentPage, sortConfig, filterStr } = this.state;
    const { tableFilters } = this.props;
    this.setState({
      ...this.state,
      listEventsFromApi: [],
      listEvents: [],
    });

    const {
      filters,
      currentPageOffset,
      filterDateFrom,
      filterDateTo,
      states,
      orderBy,
      orderType,
      search,
      dateFilterTarget,
    } = prepareFiltersAPIRecords(
      "report",
      tableFilters,
      currentPage,
      page,
      sortConfig,
      filterStr
    );

    //CALL OPEN AND SCHEDULED EVENTS ACCODING TO CHECKED FIELDS IN MENU
    this.subscriptions.push(
      this.apiService
        .getSituationRecords(
          count,
          currentPageOffset,
          filterDateFrom,
          states,
          filterDateTo,
          filters,
          dateFilterTarget,
          null,
          orderBy,
          orderType,
          search
        )
        .pipe(
          tap((events) => {
            if (events && !events.error) {
              const { elementAPI, pageMax, mapped } = mapFromApi(events);
              //MAP LIST OBJECTS TO TABLE COLUMNS
              //SET ORIGINAL AND FILTERED LISTS
              this.setState({
                listEventsFromApi: mapped,
                pageMax: Math.ceil(pageMax),
              });
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  onCheckboxFilterClick = (newTableColumns) => {
    onDropdownCheckboxFilterClick(
      this,
      this.refreshData,
      FILTER_TARGET,
      newTableColumns
    );
  };

  render = () => {
    const {
      listEventsFromApi,
      sortConfig,
      filterStr,
      pageToDisplay,
      pageMax,
      selected,
      listAttributes,
      inputFieldDisabled,
    } = this.state;
    const {
      toggleGenericTable,
      currentEvent,
      newEvent,
      isOpenNav,
      selectedEvents,
      inputFields,
      dateColumns,
      wsLastEvent,
      resetListEventWs,
      tableColumns,
      isOpenPopup,
    } = this.props;

    //RIORDINAMENTO
    let sortedlistEvents = [...listEventsFromApi];
    //CHECK PREVENTIVO CON IL VALORE DELL'EVENTO IN SOCKET
    updateValueByWS(
      this,
      sortedlistEvents,
      wsLastEvent,
      false,
      resetListEventWs
    );
    //MAPPA PER VISUALIZZAZIONE SU TABELLA
    sortedlistEvents = toTableColumns(this, sortedlistEvents, inputFields);

    return (
      <>
        {isOpenPopup && (
          <Popup
            iconTypeError={false}
            textBody={
              "Non hai inserito il nome del file, verrà assegnato il nome «" +
              moment().format("YYMMDD_HHmm") +
              "_cantieri» dal sistema."
            }
            titleHeader="Eventi: simulazione impatti"
            toggleClose={this.closeEvent}
            toggleFetch={this.handleFetch}
          />
        )}
        <div className="acr-table uk-flex uk-flex-column">
          <div className="navbar-placeholder"></div>
          <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
            <div className="grey-space"></div>
            <div className=" uk-width-1-2">
              <ul className=" uk-tab-page-secondary uk-tab uk-margin-remove">
                <li className="uk-active bottombar-title">Resoconto eventi</li>
                {/* <li className="bottombar-title uk-margin-left">
                <a href="/#">Tab 2</a>
              </li>
              <li className="bottombar-title uk-margin-left">
                <a href="/#">Tab 3</a>
              </li> */}
              </ul>
            </div>
            <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
              {/* <span
              className="uk-margin-small-right pointer"
              uk-icon="icon: acr-interface-new-window"
            ></span> */}
              <span
                className="pointer"
                uk-icon="icon: acr-interface-close"
                onClick={(e) => {
                  e.preventDefault();
                  toggleGenericTable("eventReportTable");
                }}
              ></span>
            </div>
          </div>
          <TableFilters
            isOpenNav={isOpenNav}
            dateStart={{
              date: newEvent.dateStartEvent,
              name: "dateStartEvent",
            }}
            dateEnd={{ date: newEvent.dateEndEvent, name: "dateEndEvent" }}
            changeDate={this.onChangeDate}
            handleFilterChange={this.handleFilterChange}
            handleSelectChange={this.handleSelectChange}
            handleSetDateFilterTarget={this.handleSetDateFilterTarget}
            handleResetDateFilterTarget={this.handleResetDateFilterTarget}
            resetFilters={this.resetFilters}
            filterStr={filterStr}
            filtrableDateColumns={dateColumns}
            selected={selected}
          ></TableFilters>
          <div className="uk-flex uk-flex-right button-bar">
            <div className="uk-button-group uk-margin-right">
              <input
                className={
                  "custom-button-padding uk-input uk-button-default uk-flex uk-flex-middle uk-margin-right form-control"
                }
                type="text"
                placeholder="Nome file"
                value={this.state.fields.name}
                onChange={this.onChange.bind(this, "name")}
              ></input>
              <button
                disabled={selectedEvents.length <= 1}
                onClick={() => {
                  this.state.fields.name === "" || undefined || null
                    ? this.handleFetch()
                    : this.fetchIdsRecord();
                }}
                className={
                  selectedEvents.length <= 1
                    ? "custom-button-eventSelected-padding uk-button uk-button-default uk-flex uk-flex-middle icon-not-selected cursor-disabled"
                    : "custom-button-eventSelected-padding uk-button uk-button-default uk-flex uk-flex-middle icon-selected cursor-pointer"
                }
              >
                <span
                  uk-icon="icon: Tab-Traffico; ratio: 2;"
                  uk-tooltip="Crea file di simulazione impatti"
                  className={`uk-icon uk-flex uk-flex-middle ${
                    selectedEvents.length <= 1 ? "gray-icon" : "yellow-icon"
                  }`}
                ></span>
              </button>
              {selectedEvents.length > 0 && (
                <span className="uk-badge events-selected-badge">
                  {selectedEvents.length}
                </span>
              )}
            </div>
            <div className="uk-button-group uk-margin-right">
              <button
                className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
                type="button"
                onClick={this.refreshData}
              >
                <span
                  uk-icon="icon: refresh"
                  uk-tooltip="Refresh tabella eventi"
                  className="filterBarIcons"
                ></span>
              </button>
            </div>
            <div className="uk-button-group uk-margin-right">
              <button
                className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
                type="button"
                // disabled={true}
                onClick={this.downloadExcel}
              >
                <span
                  uk-icon="icon: download"
                  uk-tooltip="Download Excel"
                  className="filterBarIcons borderWhite"
                ></span>
              </button>
            </div>
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
              onClick={this.resetFilters}
            >
              <span
                uk-icon="icon: acr-filter-remove"
                uk-tooltip="Reset filtri"
                className="filterBarIcons"
              ></span>
            </button>
            <div className="uk-flex uk-flex-right uk-padding-remove-left">
              <div className="uk-flex button-page">
                <PageNumbers
                  handleClickDecrease={this.handleClickDecrease}
                  handleClickIncrease={this.handleClickIncrease}
                  getPageNumber={this.editablePageNumber}
                  currentPage={pageToDisplay}
                  minVal={1}
                  pageMax={pageMax}
                />
              </div>
            </div>
          </div>
          <div
            className={
              currentEvent
                ? "uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top table-external-container uk-margin-left detail-present-margin-bottom"
                : "uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left​​"
            }
            onScroll={(e) => this.handleOnScroll(e)}
          >
            <table
              id="eventTable"
              className="uk-table uk-table-divider uk-table-hover"
            >
              <thead>
                <TableHead
                  onCheckboxFilterClick={this.onCheckboxFilterClick}
                  requestSort={this.requestSort}
                  tableColumns={tableColumns}
                  sortConfig={sortConfig}
                  selectedEvents={selectedEvents}
                />
              </thead>
              <tbody>
                {renderTableData(this, sortedlistEvents, selectedEvents)}
              </tbody>
            </table>
          </div>
          {currentEvent && (
            <EventReportDetail listAttributes={listAttributes} />
          )}
        </div>
      </>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

EventReportTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleGenericTable,
  toggleEventReportDetail,
  setNewEvent,
  setCurrentEvent,
  saveTableFilters,
  resetTableFilters,
  resetListEventWs,
  addActivity,
  setSelectedEvents,
  setInputFields,
  togglePopup,
};

const mapStateToProps = (state) => ({
  allEvents: getAllEvents(state),
  currentEvent: getCurrentEvent(state),
  newEvent: getNewEvent(state),
  activeEvents: getActiveEventsFiltered(state),
  plannedEvents: getPlannedEventsFiltered(state),
  isActiveVisible: isActiveVisible(state),
  isPlannedVisible: isPlannedVisible(state),
  isOpenNav: isOpenNavTab(state),
  isReportEventOpen: getIsReportDetailOpen(state),
  tableColumns: getSavedTableColumns(state, FILTER_TARGET),
  dateColumns: getSavedDateColumns(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
  searchText: getSavedSearchText(state, FILTER_TARGET),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  toggledFilters: getSavedToggledFilters(state, FILTER_TARGET),
  selectModal: getSelectModal(state),
  wsLastEvent: getWsLastEvent(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
  selectedEvents: getSelectedEvents(state),
  inputFields: getInputFields(state),
  isOpenPopup: isOpenPopup(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventReportTable)
);
