import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Permission from "components/shared/permission";
import {
  areAllVisible,
  isCityBusVisible,
  isRegionBusVisible,
  getPermissions,
  getAllActiveLines,
  isOpenNavTab,
  isOpenNavTPL,
  getCurrentStop,
  getCurrentVehicle,
  getBottombarTabs,
} from "store";

const mapStateToProps = (state) => ({
  allChecked: areAllVisible(state),
  cityBusChecked: isCityBusVisible(state),
  regionBusChecked: isRegionBusVisible(state),
  permissions: getPermissions(state),
  activeLines: getAllActiveLines(state),
  isOpenNavTab: isOpenNavTab(state),
  isOpenNavTPL: isOpenNavTPL(state),
  stopsCurrentReducer: getCurrentStop(state),
  vehicleCurrentReducer: getCurrentVehicle(state),
  bottombarTabs: getBottombarTabs(state),
});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemMobilityTpl extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  tpl = () => {
    const {
      allChecked,
      cityBusChecked,
      regionBusChecked,
      isOpenNavTPL,
      clickOnTrasportoLabel,
      clickOnTrasportoCheckbox,
    } = this.props;

    return (
      <Permission actions={["TPL City", "TPL Region"]}>
        <div
          className={
            isOpenNavTPL
              ? "uk-checkbox-title uk-open acr-transport-open"
              : "uk-checkbox-title"
          }
        >
          <span>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={allChecked || false}
              onChange={() => clickOnTrasportoCheckbox("all")}
            />
          </span>
          <label onClick={() => clickOnTrasportoLabel()}>
            Trasporto Pubblico
          </label>
        </div>

        <div className="uk-checkbox-tree-sub acr-transport-sub">
          <Permission actions="TPL City">
            <label>
              <input
                className="uk-checkbox uk-checkbox-large"
                type="checkbox"
                checked={cityBusChecked || false}
                onChange={() => clickOnTrasportoCheckbox("city")}
              />
              Rete Urbana
            </label>
          </Permission>

          <Permission actions="TPL Region">
            <label>
              <input
                className="uk-checkbox uk-checkbox-large"
                type="checkbox"
                checked={regionBusChecked || false}
                onChange={() => clickOnTrasportoCheckbox("region")}
              />
              Rete Extraurbana
            </label>
          </Permission>
        </div>
      </Permission>
    );
  };

  render() {
    return this.tpl();
  }
}

export default NavItemMobilityTpl;
