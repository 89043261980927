import React, { Component } from "react";

class WorkspaceVisualizationBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { toggleCreation, toggleWorkspaceDimensions } = this.props;

    return (
      <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between">
        <div className="uk-flex uk-flex-row icon-container-width">
          <span
            uk-icon="icon: plus"
            uk-tooltip={"title: Aggiungi Workspace"}
            onClick={(event) => {
              event.preventDefault();
              toggleCreation();
            }}
            className="pointer uk-margin-small-left blue-icon height-width-24"
          />
        </div>
        <div className="uk-margin-left ws-text">{"Work Space"}</div>
        <div className="uk-height-1-1 uk-width-1-1 uk-card uk-card-secondary  uk-flex uk-flex-center uk-flex-middle">
          <span
            uk-icon="icon: thumbnails"
            uk-tooltip={"title: Workspace"}
            onClick={(event) => {
              event.preventDefault();
              toggleWorkspaceDimensions();
            }}
            className="pointer height-width-35"
          />
        </div>
      </div>
    );
  }
}

export default WorkspaceVisualizationBox;
