import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";

import { tap, take, catchError } from "rxjs/operators";
import { of } from "rxjs";
//ACTIONS
import { setStationsList } from "reducers/installations/stations/stations.actions";
import {
  getBoundingBox,
  getCurrentZoom,
  getCurrentStation,
  getStations,
  isStationVisible,
  getCurrentWorkspace,
} from "store";
import { setIsStationLoading } from "reducers/ui/installations-menu/installations-menu.actions";
import { setBounds, filterByMapBounds } from "reducers/map/map.actions";

//MODEL
import StationModel from "reducers/installations/stations/stations.model";
//UTIL
import { filterBounds } from "components/layout/map/load-map/load-map";

class LoadStationData extends Component {
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  componentDidUpdate(prevProps) {
    const {
      currentStation,
      isStationVisible,
      setIsStationLoading,
      setStationsList,
    } = this.props;

    if (prevProps.isStationVisible !== isStationVisible && isStationVisible) {
      if (!currentStation) {
        setIsStationLoading(true);
        this.subscriptions.push(
          this.apiService
            .getStations()
            .pipe(
              take(1),
              tap((x) => {
                setIsStationLoading(false);
                if (x && !x.error) {
                  let stations = StationModel.fromArrayREST(x);
                  setStationsList(stations);
                  filterBounds(this);
                } else {
                  console.log(x);
                }
              }),
              catchError((error) => {
                setIsStationLoading(false);
                console.error(error);
                return of(error);
              })
            )
            .subscribe()
        );
      }
    }
  }

  render() {
    return <div></div>;
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

LoadStationData.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setStationsList,
  setIsStationLoading,
  setBounds,
  filterByMapBounds,
};

const mapStateToProps = (state) => ({
  currentStation: getCurrentStation(state),
  stations: getStations(state),
  isStationVisible: isStationVisible(state),
  currentWorkspace: getCurrentWorkspace(state),
  boundsReducer: getBoundingBox(state),
  currentMapZoom: getCurrentZoom(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadStationData)
);
