import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//COMPONENTS
import Map from "components/layout/map/map";
import StopTooltip from "./tooltip/stop-tooltip";
import BusTooltip from "./tooltip/bus-tooltip";
import EventTooltip from "./tooltip/event-tooltip";
import StationTooltip from "./tooltip/station-tooltip";
import InstallationTooltip from "./tooltip/installation-tooltip";
import Workspace from "./workspace";
import MultipleDistanceTooltip from "./tooltip/multiple-distance-tooltip";
import ShortestDistanceOnGraphTooltip from "./tooltip/shortest-distance-on-graph.js";
import PointTooltip from "./tooltip/point-tooltip";
import ParkingTooltip from "./tooltip/parking-tooltip";
import CamerasTooltip from "./tooltip/cameras-tooltip";
import PMVTooltip from "./tooltip/pmv-tooltip";
// ACTIONS
import {
  getMapGeoJson,
  getHoveredObjects,
  getMultipleDistance,
  getCurrentVehicle,
  getLayoutMap,
} from "store";
import { setCurrentTrip } from "reducers/trips/trips.actions";
import {
  setPolygon,
  setSelectedFeatureIndexes,
  resetSelectedFeatureIndexes,
  setHoveredEvent,
  setHoveredStation,
  setHoveredStop,
  setHoveredInstallation,
  setHoveredMultipleDistance,
  setHoveredShortestDistanceOnGraph,
  setHoveredRdsTmcPoint,
  setHoveredParking,
  setHoveredCamera,
  setHoveredPMV,
} from "reducers/map/map.actions";
import { setNavTabSelectedTab } from "reducers/ui/nav-tab/nav-tab.actions";
import { setCurrentEvent } from "reducers/events/events.actions";
import { setCurrentVehicle } from "reducers/vehicles/vehicles.actions";
import { setCentroidsList } from "reducers/graph/centroids/centroids.actions";
//UTILS
import LoadMapData from "components/pages/load.data/load.map.data";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderWorkspace = () => {
    return <Workspace />;
  };

  // HOVER HANDLERS
  _onHoverInstallation = (object, x, y) => {
    const { setHoveredInstallation, layoutMapReducer } = this.props;
    setHoveredInstallation({
      x: x,
      y: y,
      object: object,
      layoutMap: layoutMapReducer,
    });
  };

  _onHoverEvent = (object, x, y) => {
    const { setHoveredEvent } = this.props;
    setHoveredEvent({
      x: x,
      y: y,
      object: object,
    });
  };

  _onHoverStation = (object, x, y) => {
    const { setHoveredStation } = this.props;
    setHoveredStation({
      x: x,
      y: y,
      object: object,
    });
  };

  _onHoverStop = (object, x, y) => {
    const { setHoveredStop } = this.props;
    setHoveredStop({
      x: x,
      y: y,
      object: object,
    });
  };

  _onHoverMultipleDistance = (object, x, y) => {
    const { setHoveredMultipleDistance } = this.props;
    setHoveredMultipleDistance({
      x: x,
      y: y,
      object: object,
    });
  };

  _onHoverShortestDistanceOnGraph = (object, x, y) => {
    const { setHoveredShortestDistanceOnGraph } = this.props;
    setHoveredShortestDistanceOnGraph({
      x: x,
      y: y,
      object: object,
    });
  };

  _onHoverRdsTmcPoint = (object, x, y) => {
    const { setHoveredRdsTmcPoint } = this.props;
    setHoveredRdsTmcPoint({
      x: x,
      y: y,
      object: object,
    });
  };

  _onHoverParking = (object, x, y) => {
    const { setHoveredParking } = this.props;
    setHoveredParking({
      x: x,
      y: y,
      object: object,
    });
  };

  _onHoverCamera = (object, x, y) => {
    const { setHoveredCamera } = this.props;
    setHoveredCamera({
      x: x,
      y: y,
      object: object,
    });
  };

  _onHoverPMV = (object, x, y) => {
    const { setHoveredPMV } = this.props;
    setHoveredPMV({
      x: x,
      y: y,
      object: object,
    });
  };
  render = () => {
    const { hoveredObjectsReducer, vehiclesCurrentReducer } = this.props;

    return (
      <div className="acr-map-container">
        <Map
          toggleHoverBusOpen={this.toggleHoverBusOpen}
          _onHoverInstallation={this._onHoverInstallation}
          _onHoverEvent={this._onHoverEvent}
          _onHoverStation={this._onHoverStation}
          _onHoverStop={this._onHoverStop}
          _onHoverMultipleDistance={this._onHoverMultipleDistance}
          _onHoverShortestDistanceOnGraph={this._onHoverShortestDistanceOnGraph}
          _onHoverRdsTmcPoint={this._onHoverRdsTmcPoint}
          _onHoverParking={this._onHoverParking}
          _onHoverCamera={this._onHoverCamera}
          _onHoverPMV={this._onHoverPMV}
        />
        {this.renderWorkspace()}
        {hoveredObjectsReducer.hoveredBus.object &&
          !hoveredObjectsReducer.hoveredBus.object.cluster && (
            <BusTooltip
              x={hoveredObjectsReducer.hoveredBus.x}
              y={hoveredObjectsReducer.hoveredBus.y}
              object={hoveredObjectsReducer.hoveredBus.object}
              toBeFollowed={hoveredObjectsReducer.hoveredBus.toBeFollowed}
              currentVehicle={vehiclesCurrentReducer}
            />
          )}
        {hoveredObjectsReducer.hoveredStop.object && (
          <StopTooltip
            x={hoveredObjectsReducer.hoveredStop.x}
            y={hoveredObjectsReducer.hoveredStop.y}
            object={hoveredObjectsReducer.hoveredStop.object}
          />
        )}
        {hoveredObjectsReducer.hoveredEvent.object && (
          <EventTooltip
            x={hoveredObjectsReducer.hoveredEvent.x}
            y={hoveredObjectsReducer.hoveredEvent.y}
            object={hoveredObjectsReducer.hoveredEvent.object}
          />
        )}
        {hoveredObjectsReducer.hoveredStation.object && (
          <StationTooltip
            x={hoveredObjectsReducer.hoveredStation.x}
            y={hoveredObjectsReducer.hoveredStation.y}
            object={hoveredObjectsReducer.hoveredStation.object}
          />
        )}
        {hoveredObjectsReducer.hoveredInstallation.object && (
          <InstallationTooltip
            x={hoveredObjectsReducer.hoveredInstallation.x}
            y={hoveredObjectsReducer.hoveredInstallation.y}
            object={hoveredObjectsReducer.hoveredInstallation.object}
          />
        )}
        {hoveredObjectsReducer.hoveredMultipleDistance.object && (
          <MultipleDistanceTooltip
            x={hoveredObjectsReducer.hoveredMultipleDistance.x}
            y={hoveredObjectsReducer.hoveredMultipleDistance.y}
            object={hoveredObjectsReducer.hoveredMultipleDistance.object}
          />
        )}
        {hoveredObjectsReducer.hoveredShortestDistanceOnGraph.object && (
          <ShortestDistanceOnGraphTooltip
            x={hoveredObjectsReducer.hoveredShortestDistanceOnGraph.x}
            y={hoveredObjectsReducer.hoveredShortestDistanceOnGraph.y}
            object={hoveredObjectsReducer.hoveredShortestDistanceOnGraph.object}
          />
        )}
        {hoveredObjectsReducer.hoveredRdsTmcPoint.object && (
          <PointTooltip
            x={hoveredObjectsReducer.hoveredRdsTmcPoint.x}
            y={hoveredObjectsReducer.hoveredRdsTmcPoint.y}
            object={hoveredObjectsReducer.hoveredRdsTmcPoint.object}
          />
        )}
        {hoveredObjectsReducer.hoveredParking.object && (
          <ParkingTooltip
            x={hoveredObjectsReducer.hoveredParking.x}
            y={hoveredObjectsReducer.hoveredParking.y}
            object={hoveredObjectsReducer.hoveredParking.object}
          />
        )}
        {hoveredObjectsReducer.hoveredCamera.object && (
          <CamerasTooltip
            x={hoveredObjectsReducer.hoveredCamera.x}
            y={hoveredObjectsReducer.hoveredCamera.y}
            object={hoveredObjectsReducer.hoveredCamera.object}
          />
        )}
        {hoveredObjectsReducer.hoveredPMV.object && (
          <PMVTooltip
            x={hoveredObjectsReducer.hoveredPMV.x}
            y={hoveredObjectsReducer.hoveredPMV.y}
            object={hoveredObjectsReducer.hoveredPMV.object}
          />
        )}
        <ErrorBoundary>
          <LoadMapData />
        </ErrorBoundary>
      </div>
    );
  };
}

const mapDispatchToProps = {
  setPolygon,
  setCurrentEvent,
  setCurrentTrip,
  setNavTabSelectedTab,
  setSelectedFeatureIndexes,
  setCentroidsList,
  resetSelectedFeatureIndexes,
  setCurrentVehicle,
  setHoveredEvent,
  setHoveredStation,
  setHoveredStop,
  setHoveredInstallation,
  setHoveredMultipleDistance,
  setHoveredShortestDistanceOnGraph,
  setHoveredRdsTmcPoint,
  setHoveredParking,
  setHoveredCamera,
  setHoveredPMV,
};

const mapStateToProps = (state) => ({
  geoJson: getMapGeoJson(state),
  hoveredObjectsReducer: getHoveredObjects(state),
  multipleDistance: getMultipleDistance(state),
  vehiclesCurrentReducer: getCurrentVehicle(state),
  layoutMapReducer: getLayoutMap(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MapContainer)
);
