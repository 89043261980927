export const INITIAL_STATE_STATIONS = {
  stations: [],
  stationsFiltered: [],
  current: null,
  // currentMap: null,
  searchText: "",
  bounds: [],
};

export default class StationModel {
  archId;
  archName;
  directionName;
  externalId;
  latitude;
  longitude;
  selected;
  sensors;
  stationId;
  supportedMeasure;
  stationVersion;
  stationVersionTime;
  description;
  measures;
  enabled;
  status;

  constructor() {
    this.archId = null;
    this.archName = null;
    this.directionName = null;
    this.externalId = null;
    this.latitude = null;
    this.longitude = null;
    this.selected = null;
    this.sensors = SensorsModel;
    this.stationId = null;
    this.supportedMeasure = SupportedMeasureModel;
    this.stationVersion = null;
    this.stationVersionTime = null;
    this.description = null;
    this.measures = MeasureModel;
    this.enabled = null;
    this.status = null;
  }
  static from(obj, duration) {
    var station = new StationModel();

    if (obj.archId) station.archId = obj.archId;
    if (obj.archName) station.archName = obj.archName;
    if (obj.directionName) station.directionName = obj.directionName;
    if (obj.externalId) station.externalId = obj.externalId;
    if (obj.latitude) station.latitude = obj.latitude;
    if (obj.longitude) station.longitude = obj.longitude;
    if (obj.selected) station.selected = obj.selected;
    if (obj.sensors) station.sensors = SensorsModel.fromArray(obj.sensors);
    if (obj.stationId) station.stationId = obj.stationId;
    if (obj.supportedMeasure)
      station.supportedMeasure = station.supportedMeasure.fromArray(
        obj.supportedMeasure
      );
    if (obj.stationVersion) station.stationVersion = obj.stationVersion;
    if (obj.stationVersionTime)
      station.stationVersionTime = obj.stationVersionTime;
    if (obj.description) station.description = obj.description;
    if (obj.enabled) station.enabled = obj.enabled;
    if (obj.status) station.status = obj.status;

    if (obj.measures && obj.measures.length > 0) {
      station.measures = MeasureModel.fromArray(obj.measures);
    } else {
      let mergedMeasure = [];
      station.measures = mergedMeasure;
    }

    return station;
  }
  static fromREST(obj) {
    var station = new StationModel();
    if (obj.archId) station.archId = obj.archId;
    if (obj.archName) station.archName = obj.archName;
    if (obj.directionName) station.directionName = obj.directionName;
    if (obj.externalId) station.externalId = obj.externalId;
    if (obj.latitude) station.latitude = obj.latitude;
    if (obj.longitude) station.longitude = obj.longitude;
    if (obj.selected) station.selected = obj.selected;
    if (obj.sensors) station.sensors = SensorsModel.fromArray(obj.sensors);
    if (obj.stationId) station.stationId = obj.stationId;
    if (obj.supportedMeasure) {
      station.supportedMeasure = station.supportedMeasure.fromArray(
        obj.supportedMeasure
      );
    }
    if (obj.stationVersion) station.stationVersion = obj.stationVersion;
    if (obj.stationVersionTime)
      station.stationVersionTime = obj.stationVersionTime;
    if (obj.description) station.description = obj.description;
    if (obj.measures) station.measures = MeasureModel.fromArray(obj.measures);
    return station;
  }

  static fromArrayREST(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(StationModel.fromREST).filter((item) => item !== null);
  }
}

export class SensorsModel {
  externalId;
  measureStationId;
  periodOfMeasure;
  productor;
  sensorId;
  sensorName;
  sensorVersion;
  sensorVersionTime;
  sensortype;
  status;
  vehicleClass;
  constructor() {
    this.externalId = null;
    this.measureStationId = null;
    this.periodOfMeasure = null;
    this.productor = null;
    this.sensorId = null;
    this.sensorName = null;
    this.sensorVersion = null;
    this.sensorVersionTime = null;
    this.sensortype = SensorTypeModel;
    this.status = StatusSensorModel;
    this.vehicleClass = VehicleSensorModel;
  }
  static from(obj) {
    var sensor = new SensorsModel();
    if (obj.externalId) sensor.externalId = obj.externalId;
    if (obj.measureStationId) sensor.measureStationId = obj.measureStationId;
    if (obj.periodOfMeasure) sensor.periodOfMeasure = obj.periodOfMeasure;
    if (obj.productor) sensor.productor = obj.productor;
    if (obj.sensorId) sensor.sensorId = obj.sensorId;
    if (obj.sensorName) sensor.sensorName = obj.sensorName;
    if (obj.sensorVersion) sensor.sensorVersion = obj.sensorVersion;
    if (obj.sensorVersionTime) sensor.sensorVersionTime = obj.sensorVersionTime;
    if (obj.sensortype)
      sensor.sensortype = SensorTypeModel.from(obj.sensortype);
    if (obj.status) {
      sensor.status = StatusSensorModel.from(obj.status);
    } else {
      //   let statusMock= ["NO_INFO", "DOWN", "UP"];
      sensor.status = {
        status: "NO_INFO",
        externalId: "-",
        lastTimeUpdateUtc: "-",
      }; //statusMock[Math.floor(Math.random() * 3)];
    }
    if (obj.vehicleClass)
      sensor.vehicleClass = VehicleSensorModel.fromArray(obj.vehicleClass);
    return sensor;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(SensorsModel.from).filter((item) => item !== null);
  }
}
export class SupportedMeasureModel {
  defaultMeasure;
  idSupportedMeasure;
  name;
  nameIt;
  unit;
  measureId;
  measureName;
  measureType;
  measureGroupType;
  measureUnitType;
  standard;
  constructor() {
    this.defaultMeasure = null;
    this.idSupportedMeasure = null;
    this.name = null;
    this.nameIt = null;
    this.unit = null;
    this.measureName = null;
    this.measureType = [];
    this.measureGroupType = [];
    this.measureUnitType = [];
    this.standard = null;
  }
  static from(obj) {
    var supportedMeasure = new SupportedMeasureModel();

    if (obj.defaultMeasure)
      supportedMeasure.defaultMeasure = obj.defaultMeasure;
    if (obj.idSupportedMeasure)
      supportedMeasure.idSupportedMeasure = obj.idSupportedMeasure;
    if (obj.name) supportedMeasure.name = obj.name;
    if (obj.nameIt) supportedMeasure.nameIt = obj.nameIt;
    if (obj.unit) supportedMeasure.unit = obj.unit;
    if (obj.measureName) supportedMeasure.measureName = obj.measureName;
    if (obj.measureType) supportedMeasure.measureType = obj.measureType;
    if (obj.measureGroupType)
      supportedMeasure.measureGroupType = obj.measureGroupType;
    if (obj.measureUnitType)
      supportedMeasure.measureUnitType = obj.measureUnitType;
    if (obj.standard) supportedMeasure.standard = obj.standard;
    return supportedMeasure;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(SupportedMeasureModel.from).filter((item) => item !== null);
  }
}

export class MeasureModel {
  externalIdStation;
  externalIdSensor;
  measureTimeUtc;
  accurancy;
  gap;
  headway;
  length;
  occupancy;
  speed;
  volume;
  density;
  measureType;

  constructor() {
    this.externalIdStation = null;
    this.externalIdSensor = null;
    this.measureTimeUtc = null;
    this.accurancy = null;
    this.gap = null;
    this.headway = null;
    this.length = null;
    this.occupancy = null;
    this.speed = null;
    this.volume = null;
    this.density = null;
    this.measureType = null;
  }
  static from(obj) {
    var measure = new MeasureModel();
    if (obj.externalIdStation)
      measure.externalIdStation = obj.externalIdStation;
    if (obj.externalIdSensor) measure.externalIdSensor = obj.externalIdSensor;
    if (obj.measureTimeUtc) measure.measureTimeUtc = obj.measureTimeUtc;
    measure.accurancy = obj.accurancy;
    measure.gap = obj.gap;
    measure.headway = obj.headway;
    measure.length = obj.length;
    measure.occupancy = obj.occupancy;
    measure.speed = obj.speed;
    measure.volume = obj.volume;
    measure.density = obj.density;
    if (obj.measureType) measure.measureType = obj.measureType;
    return measure;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(MeasureModel.from).filter((item) => item !== null);
  }
}
export class StatusSensorModel {
  externalId;
  lastTimeUpdateUtc;
  status;
  constructor() {
    this.externalId = null;
    this.lastTimeUpdateUtc = null;
    this.status = [];
  }
  static from(obj) {
    var statusSensor = new StatusSensorModel();
    if (obj.externalId) statusSensor.externalId = obj.externalId;
    if (obj.lastTimeUpdateUtc)
      statusSensor.lastTimeUpdateUtc = obj.lastTimeUpdateUtc;
    if (obj.status) statusSensor.status = obj.status;

    return statusSensor;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(StatusSensorModel.from).filter((item) => item !== null);
  }
}
export class SensorTypeModel {
  typeName;
  supportedMeasure;

  constructor() {
    this.typeName = null;
    this.supportedMeasure = SupportedMeasureModel;
  }
  static from(obj) {
    var typeSensor = new SensorTypeModel();
    if (obj.typeName) typeSensor.typeName = obj.typeName;
    if (obj.supportedMeasure)
      typeSensor.supportedMeasure = typeSensor.supportedMeasure.fromArray(
        obj.supportedMeasure
      );

    return typeSensor;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(SensorTypeModel.from).filter((item) => item !== null);
  }
}
export class VehicleSensorModel {
  weight;
  name;
  nameIt;

  constructor() {
    this.weight = null;
    this.name = [];
    this.nameIt = null;
  }
  static from(obj) {
    var vehicleSensor = new VehicleSensorModel();
    if (obj.weight) vehicleSensor.weight = obj.weight;
    if (obj.name) vehicleSensor.name = obj.name;
    if (obj.nameIt) vehicleSensor.nameIt = obj.nameIt;

    return vehicleSensor;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(VehicleSensorModel.from).filter((item) => item !== null);
  }
}
