export const infoRouteList = [
  {
    id: 10,
    toponimo: "Via Dello Sport",
    lunghezza: 1250,
    larghezza: 10,
    nCorsie: "3",
    sensoUnico: "si",
    tipoStrada: "Urbana",
    limite: 50,
  } /* ,
    {
      id: 20,
      toponimo: "Corso Porta Nuova",
      lunghezza: 1850,
      larghezza: 10,
      nCorsie: "3",
      sensoUnico: "no",
      tipoStrada: "Urbana",
      limite: 50
    },
    {
      id: 30,
      toponimo: "Via Franco Faccio",
      lunghezza: 1000,
      larghezza: 10,
      nCorsie: "3",
      sensoUnico: "no",
      tipoStrada: "Urbana",
      limite: 50
    },
    {
      id: 40,
      toponimo: "Lungadige Galtarossa",
      lunghezza: 2000,
      larghezza: 10,
      nCorsie: "3",
      sensoUnico: "si",
      tipoStrada: "Urbana",
      limite: 50
    },
    {
      id: 50,
      toponimo: "Stradone Porta Palio",
      lunghezza: 1920,
      larghezza: 10,
      nCorsie: "3",
      sensoUnico: "no",
      tipoStrada: "Urbana",
      limite: 50
    } */,
];
