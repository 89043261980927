export const TrafficMenuActions = {
  SET_TRAFFIC_VISIBILITY: "UI/TRAFFIC_MENU/SET_TRAFFIC_VISIBILITY",
  SET_TYPICAL_VISIBILITY: "UI/TRAFFIC_MENU/SET_TYPICAL_VISIBILITY",
  SET_NET_STATE_VISIBILITY: "UI/TRAFFIC_MENU/SET_NET_STATE_VISIBILITY",
  RESET_TRAFFIC_VISIBILITY: "UI/TRAFFIC_MENU/RESET_TRAFFIC_VISIBILITY",
  TOGGLE_OPEN_TIME_SLIDER: "UI/TRAFFIC_MENU/TOGGLE_OPEN_TIME_SLIDER",
  TOGGLE_LIST_TRAFFIC_TABLE: "UI/INSTALLATIONS_MENU/TOGGLE_LIST_TRAFFIC_TABLE",
  TOGGLE_TRAFFIC_TABLE_OPEN: "UI/INSTALLATIONS_MENU/TOGGLE_TRAFFIC_TABLE_OPEN",
  SET_IS_TRAFFIC_LOADING: "UI/INSTALLATIONS_MENU/SET_IS_TRAFFIC_LOADING",
};

//TRAFFIC

//toggles only parent "traffic"
export const setTrafficVisibility = (visible) => (dispatch) => {
  dispatch({
    type: TrafficMenuActions.SET_TRAFFIC_VISIBILITY,
    payload: { visible },
  });
};

export const setTypicalVisibility = (visible) => (dispatch) => {
  dispatch({
    type: TrafficMenuActions.SET_TYPICAL_VISIBILITY,
    payload: { visible },
  });
};

export const setNetStateVisibility = (visible) => (dispatch) => {
  dispatch({
    type: TrafficMenuActions.SET_NET_STATE_VISIBILITY,
    payload: { visible },
  });
};

export const resetTrafficVisibility = () => (dispatch) => {
  dispatch({
    type: TrafficMenuActions.RESET_TRAFFIC_VISIBILITY,
    payload: null,
  });
};

export const toggleOpenTrafficTimeSlider = (isOpen) => (dispatch) => {
  dispatch({
    type: TrafficMenuActions.TOGGLE_OPEN_TIME_SLIDER,
    payload: isOpen,
  });
};

export const setIsTrafficLoading = (isTrafficLoading) => (dispatch) => {
  dispatch({
    type: TrafficMenuActions.SET_IS_TRAFFIC_LOADING,
    payload: {
      isTrafficLoading,
    },
  });
};
