import parking_chiuso from "assets/icon/parking/parking_chiuso.svg";
import parking_completo from "assets/icon/parking/parking_completo.svg";
import parking_libero from "assets/icon/parking/parking_libero.svg";
import parking_quasicompleto from "assets/icon/parking/parking_quasicompleto.svg";
import parking_sconosciuto from "assets/icon/parking/parking_sconosciuto.svg";

export const getParkingIcon = (parking) => {
  let operationalStatus = parking.properties.parkingOperationalStatus;
  let openingStatus = parking.properties.parkingOpeningStatus;

  if (openingStatus === "CLOSED") {
    return parking_chiuso;
  } else if (openingStatus !== "CLOSED") {
    switch (operationalStatus) {
      case "SPACES_AVAILABLE":
        return parking_libero;
      case "FULL":
        return parking_completo;
      case "FULL_AT_ENTRANCE":
        return parking_completo;
      case "ALMOST_FULL":
        return parking_quasicompleto;
      case "UNKNOWN":
        return parking_sconosciuto;
      case "OTHER":
        return parking_sconosciuto;
      default:
        return parking_sconosciuto;
    }
  }
};

export const getParkingStatus = (
  parkingOperationalStatus,
  parkingOpeningStatus
) => {
  if (parkingOpeningStatus === "CLOSED") {
    return "Chiuso";
  } else if (parkingOpeningStatus !== "CLOSED") {
    switch (parkingOperationalStatus) {
      case "SPACES_AVAILABLE":
        return "Libero";
      case "FULL":
        return "Completo";
      case "FULL_AT_ENTRANCE":
        return "Completo all'entrata";
      case "ALMOST_FULL":
        return "Quasi completo";
      case "UNKNOWN":
        return "Sconosciuto";
      case "OTHER":
        return "Altro";
      default:
        return "Sconosciuto";
    }
  }
};

export const getParkingStatusColor = (
  parkingOperationalStatus,
  parkingOpeningStatus
) => {
  if (parkingOpeningStatus === "CLOSED") {
    return "";
  } else if (parkingOpeningStatus !== "CLOSED") {
    switch (parkingOperationalStatus) {
      case "SPACES_AVAILABLE":
        return "green-text";
      case "FULL":
        return "red-text";
      case "FULL_AT_ENTRANCE":
        return "orange-text";
      case "ALMOST_FULL":
        return "yellow-text";
      case "UNKNOWN":
        return "";
      case "OTHER":
        return "";
      default:
        return "";
    }
  }
};

export const operationalStatusEnum = [
  { id: "SPACES_AVAILABLE", nameIt: "Libero" },
  { id: "ALMOST_FULL", nameIt: "Quasi Completo" },
  { id: "FULL_AT_ENTRANCE", nameIt: "Completo (all'entrata)" },
  { id: "FULL", nameIt: "Completo" },
  { id: "UNKNOWN", nameIt: "Sconosciuto" },
  { id: "OTHER", nameIt: "Altro" },
];

export const openingStatusEnum = [
  { id: "OPEN", nameIt: "Aperto" },
  { id: "CLOSED", nameIt: "Chiuso" },
  { id: "CLOSED_ABNORMAL", nameIt: "Chiuso" },
  {
    id: "OPENING_TIMES_IN_FORCE",
    nameIt: "Aperto",
  },
  { id: "STATUS_UNKNOWN", nameIt: "Sconosciuto" },
  { id: "OTHER", nameIt: "Altro" },
];

export const parkingSiteType1Enum = [
  { id: "URBAN_PARKING_SITE", nameIt: "Urbano" },
  { id: "INTER_URBAN_PARKING_SITE", nameIt: "Interurbano" },
  { id: "SPECIAL_LOCATION_PARKING_SITE", nameIt: "Ad uso speciale" },
];

export const parkingSiteType2Enum = [
  { id: "ON_STREET_PARKING", nameIt: "Parcheggio Su Strada" },
  { id: "OFF_STREET_PARKING", nameIt: "Parcheggio Fuori Strada" },
  { id: "OTHER", nameIt: "Altro" },
  { id: "MOTORWAY", nameIt: "Autostrada" },
  { id: "NEARBY_MOTORWAY", nameIt: "Vicino all'Autostrada" },
  { id: "LAY_BY", nameIt: "Lay By" },
  { id: "ON_STREET", nameIt: "Su Strada" },
  { id: "AIRPORT_TERMINAL", nameIt: "Terminal Aeroporto" },
  { id: "EXHIBITON_CENTRE", nameIt: "Centro Esibizioni" },
  { id: "SHOPPING_CENTRE", nameIt: "Centro Commerciale" },
  { id: "SPECIFIC_FACILITY", nameIt: "Struttura Specifica" },
  { id: "TRAIN_STATION", nameIt: "Stazione dei Treni" },
  { id: "CAMPGROUND", nameIt: "Campeggio" },
  { id: "THEME_PARK", nameIt: "Parco a Tema" },
  { id: "FERRY_TERMINAL", nameIt: "Terminal Traghetti" },
  { id: "VEHICLE_ON_RAIL_TERMINAL", nameIt: "Terminal Veicolo su Rotaia" },
  { id: "COACH_STATION", nameIt: "Stazione Autobus" },
  { id: "CABLE_CAR_STATION", nameIt: "Stazione Funivia" },
  { id: "PUBLIC_TRANSPORT_STATION", nameIt: "Stazione Trasporto Pubblico" },
  { id: "MARKET", nameIt: "Mercato" },
  { id: "RELIGIOUS_CENTRE", nameIt: "Centro Religioso" },
  { id: "CONVENTION_CENTRE", nameIt: "Centro Convention" },
  { id: "CINEMA", nameIt: "Cinema" },
  { id: "SKILIFT", nameIt: "Skilift" },
  { id: "UNKNOWN", nameIt: "Sconosciuto" },
];

export const parkingSiteType2CamelCaseEnum = [
  { id: "onStreetParking", nameIt: "Parcheggio Su Strada" },
  { id: "offStreetParking", nameIt: "Parcheggio Fuori Strada" },
  { id: "other", nameIt: "Altro" },
  { id: "motorway", nameIt: "Autostrada" },
  { id: "nearbyMotorway", nameIt: "Vicino all'Autostrada" },
  { id: "layBy", nameIt: "Lay By" },
  { id: "onStreet", nameIt: "Su Strada" },
  { id: "airportTerminal", nameIt: "Terminal Aeroporto" },
  { id: "exhibitionCentre", nameIt: "Centro Esibizioni" },
  { id: "shoppingCentre", nameIt: "Centro Commerciale" },
  { id: "specificFacility", nameIt: "Struttura Specifica" },
  { id: "trainStation", nameIt: "Stazione dei Treni" },
  { id: "campground", nameIt: "Campeggio" },
  { id: "themePark", nameIt: "Parco a Tema" },
  { id: "ferryTerminal", nameIt: "Terminal Traghetti" },
  { id: "vehicleOnRailTerminal", nameIt: "Terminal Veicolo su Rotaia" },
  { id: "coachStation", nameIt: "Stazione Autobus" },
  { id: "cableCarStation", nameIt: "Stazione Funivia" },
  { id: "publicTransportStation", nameIt: "Stazione Trasporto Pubblico" },
  { id: "market", nameIt: "Mercato" },
  { id: "religiousCentre", nameIt: "Centro Religioso" },
  { id: "conventionCentre", nameIt: "Centro Convention" },
  { id: "cinema", nameIt: "Cinema" },
  { id: "skilift", nameIt: "Skilift" },
  { id: "unknown", nameIt: "Sconosciuto" },
];

export const parkingLayoutEnum = [
  { id: "MULTI_STOREY", nameIt: "Multipiano" },
  { id: "SINGLE_LEVEL", nameIt: "Parcheggio singolo livello" },
  { id: "UNDERGROUND", nameIt: "Parcheggio sotterraneo" },
  { id: "UNDERGROUND_AND_MULTISTOREY", nameIt: "Multipiano sotterraneo" },
  { id: "AUTOMATED_PARKING_GARAGE", nameIt: "Parcheggio automatizzato" },
  { id: "OPEN_SPACE", nameIt: "Open space" },
  { id: "COVERED", nameIt: "Parcheggio coperto" },
  { id: "NESTED", nameIt: "Parcheggio annidato" },
  { id: "FIELD", nameIt: "Parcheggio sterrato" },
  { id: "UNKNOWN", nameIt: "Sconosciuto" },
  { id: "OTHER", nameIt: "Altro" },
];

export function getOperationalStatusTranslation(parkingOperationalStatus) {
  let translation = "-";
  if (
    parkingOperationalStatus &&
    operationalStatusEnum.find((item) => item.id === parkingOperationalStatus)
  ) {
    translation = operationalStatusEnum.find(
      (item) => item.id === parkingOperationalStatus
    ).nameIt;
  }
  return translation;
}

export function getOpeningStatusTranslation(parkingOpeningStatus) {
  let translation = "-";
  if (
    parkingOpeningStatus &&
    openingStatusEnum.find((item) => item.id === parkingOpeningStatus)
  ) {
    translation = openingStatusEnum.find(
      (item) => item.id === parkingOpeningStatus
    ).nameIt;
  }
  return translation;
}

export function getParkingType1Translation(parking) {
  let translation = "-";
  if (
    parking &&
    parking.properties &&
    parking.properties.parkingSiteType1 &&
    parkingSiteType1Enum.find(
      (item) => item.id === parking.properties.parkingSiteType1
    )
  ) {
    translation = parkingSiteType1Enum.find(
      (item) => item.id === parking.properties.parkingSiteType1
    ).nameIt;
  }
  return translation;
}

export function getParkingType2Translation(parking) {
  let translation = "-";
  if (
    parking &&
    parking.properties &&
    parking.properties.parkingTypeSite2 &&
    parkingSiteType2CamelCaseEnum.find(
      (item) => item.id === parking.properties.parkingTypeSite2
    )
  ) {
    translation = parkingSiteType2CamelCaseEnum.find(
      (item) => item.id === parking.properties.parkingTypeSite2
    ).nameIt;
  }
  return translation;
}

export function getParkingLayoutTranslation(parkingLayout) {
  let translation = "-";
  if (
    parkingLayout &&
    parkingLayoutEnum.find((item) => item.id === parkingLayout)
  ) {
    translation = parkingLayoutEnum.find((item) => item.id === parkingLayout)
      .nameIt;
  }
  return translation;
}

export function getParkingPayment(parkingPayment) {
  if (parkingPayment) {
    return "Libero";
  } else if (parkingPayment === false) {
    return "A pagamento";
  } else {
    return "-";
  }
}

export function getFiltersForParkingTable(stateTableColumns, sortConfig) {
  let filterObject = {
    parkingLayout: [],
    responsibleAuthorityName: [],
    parkingPayment: [],
    parkingOperationalStatus: [],
    parkingOpeningStatus: [],
    orderBy: [],
    orderType: [],
  };

  const filterStrings = [
    "parkingLayout",
    "responsibleAuthorityName",
    "parkingPayment",
    "parkingOperationalStatus",
  ];

  filterStrings.forEach((item) => {
    return (filterObject[item] = [
      ...stateTableColumns[item].childrenForHeaderDropdown.reduce(
        (acc, { checked, id }) => {
          if (checked && !id.startsWith("all")) {
            return acc.concat(id);
          } else {
            return acc;
          }
        },
        []
      ),
    ]);
  });

  stateTableColumns["parkingOpeningStatus"].childrenForHeaderDropdown.forEach(
    (item) => {
      if (item.checked && !item.id.startsWith("all")) {
        if (item.id === "CLOSED") {
          filterObject["parkingOpeningStatus"] = [
            ...filterObject["parkingOpeningStatus"],
            "CLOSED",
            "CLOSED_ABNORMAL",
          ];
        } else if (item.id === "OPEN") {
          filterObject["parkingOpeningStatus"] = [
            ...filterObject["parkingOpeningStatus"],
            "OPEN",
            "OPENING_TIMES_IN_FORCE",
          ];
        } else {
          filterObject["parkingOpeningStatus"] = [
            ...filterObject["parkingOpeningStatus"],
            item.id,
          ];
        }
      }
    }
  );
  filterObject["orderBy"] =
    sortConfig.key && mapSortConfig(sortConfig.key)
      ? [mapSortConfig(sortConfig.key)]
      : [];
  filterObject["orderType"] =
    sortConfig.direction === "ascending"
      ? "ASC"
      : sortConfig.direction === "descending"
      ? "DESC"
      : null;

  return filterObject;
}

export const mapSortConfig = (key) => {
  switch (key) {
    case "responsibleAuthorityName":
      return "RESPONSIBLE_AUTHORITY";
    case "parkingPayment":
      return "PARKING_CHARGE";
    case "parkingLayout":
      return "PARKING_LAYOUT";
    case "parkingOperationalStatus":
      return "PARKING_SITE_STATUS";
    case "parkingOpeningStatus":
      return "PARKING_SITE_OPENING_STATUS";
    default:
      return null;
  }
};

export const PARKING_STATUS = [
  {
    name: "Chiuso",
    value: 1,
    icon: parking_chiuso,
  },
  {
    name: "Completo",
    value: 2,
    icon: parking_completo,
  },
  {
    name: "Libero",
    value: 3,
    icon: parking_libero,
  },
  {
    name: "Quasi completo",
    value: 4,
    icon: parking_quasicompleto,
  },
  {
    name: "Sconosciuto",
    value: 5,
    icon: parking_sconosciuto,
  },
];
