import React, { Component } from "react";
import "./style.less";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";

class StopDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  header = () => {
    const { handleResetSelected, current } = this.props;
    return (
      <div
        id="floatingMenu"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div
          uk-icon={
            current.transportMode && current.transportMode.length === 2
              ? "icon: acr-list-stops-exchange"
              : "icon: acr-list-stops"
          }
          className={
            current.transportMode && current.transportMode.length <= 1
              ? current.transportMode.find(
                  (t) => t === TYPE_TRANSPORT_MODE["extraurb"]
                )
                ? current.isParkingPoint
                  ? "stopSuburbanDetail isParkingPointDetail isParkingPointSubDetail"
                  : "stopSuburbanDetail stopSuburbanDetailBack"
                : current.isParkingPoint
                ? "stopUrbanDetail isParkingPointDetail isParkingPointUrbDetail"
                : "stopUrbanDetail stopUrbanDetailBack"
              : current.isParkingPoint
              ? "stopUrbSubDetail isParkingPointSubUrbDetail"
              : "stopUrbSubDetail"
          }
        ></div>

        <div className=" uk-margin-left uk-margin-right textMedium text-line-width">
          {current && current.nameShort}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            handleResetSelected();
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClickStopTimeTable } = this.props;
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li className="pointer uk-active">
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickStopTimeTable();
            }}
          >
            <a href="/#">ORARI</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { current } = this.props;

    let transport;
    if (current && current.transportMode) {
      if (
        current.transportMode.length === 1 &&
        current.transportMode[0] === TYPE_TRANSPORT_MODE["urb"]
      ) {
        transport = "urbano";
      } else if (
        current.transportMode.length === 1 &&
        current.transportMode[0] === TYPE_TRANSPORT_MODE["extraurb"]
      ) {
        transport = "extraurbano";
      } else if (current.transportMode.length === 2) {
        transport = "entrambi";
      }
    }

    return (
      <div className="uk-flex uk-flex-column contentBodyStopDetail">
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted">Palina</div>
          <div className="text-detail-emphasis uk-margin-left">-</div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted">Nome</div>
          <div className="text-detail-emphasis">
            {current && current.nameShort ? current.nameShort : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted">Toponimo</div>
          <div className="text-detail-emphasis">
            {current && current.nameLong ? current.nameLong : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted">ITD_Latitudine</div>{" "}
          <div className="text-detail-emphasis">
            {current && current.coordinates && current.coordinates.latitude
              ? current.coordinates.latitude
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted">ITD_Longitudine</div>
          <div className="text-detail-emphasis">
            {current && current.coordinates && current.coordinates.longitude
              ? current.coordinates.longitude
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted">Capolinea</div>
          <div className="text-detail-emphasis">
            {current && current.isParkingPoint ? "Sì" : "No"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted">Copertura</div>
          <div className="text-detail-emphasis">
            {current && transport && transport === "urbano"
              ? "Urbano"
              : current && transport && transport === "extraurbano"
              ? "Extraurbano"
              : current && transport && transport === "entrambi"
              ? "Urbano, Extraurbano"
              : "-"}
          </div>
        </li>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}

export default StopDetail;
