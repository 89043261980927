import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";
import IconClusterStationLayer from "./icon-cluster-station-layer";
import iconMapping from "assets/cluster/location-icon-mapping-2-events.json";

class StationsLayer extends CompositeLayer {
  renderLayers() {
    const {
      data,
      layout,
      visible,
      onClick,
      stationCurrentReducer,
      isDrawingMode,
      viewport,
    } = this.props;

    if (viewport.zoom <= 12 && data && data.length >= 1) {
      return new IconClusterStationLayer(
        this.getSubLayerProps({
          id: "",
          data,
          pickable: false,
          getPosition: (d) => [d.longitude, d.latitude],
          iconAtlas: require("assets/cluster/location-icon-atlas-2-white.png"),
          iconMapping,
          sizeScale: 6,
        })
      );
    } else {
      return new IconLayer(
        this.getSubLayerProps({
          id: "icon-stations",
          data: data,
          pickable: isDrawingMode ? false : true,
          ...DeckLayerUtil.loadOptions(),
          getIcon: (obj) => ({
            url: DeckLayerUtil.getIconStation(
              obj,
              layout,
              stationCurrentReducer
            ),
            width: 80,
            height: 80,
            anchorX: 54,
            anchorY: 90,
          }),
          sizeScale: 6,
          showCluster: true,
          visible: visible,
          onClick: onClick,
          billboard: true,
          getPosition: (d) => [d.longitude, d.latitude],
          getSize: (data) => (data && data.selected ? 12 : 8),
        })
      );
    }
  }
}

StationsLayer.layerName = "StationsLayer";

export default StationsLayer;
