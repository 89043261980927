import React, { Component } from "react";
import "../style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import { take, tap, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import * as moment from "moment";
//STORE AND ACTIONS
import { toggleEventHistoryDetail } from "reducers/ui/event-menu/event-menu.actions";
import {
  setCurrentEvent,
  setSelectedHistory,
} from "reducers/events/events.actions";
import {
  getSelectModal,
  getTypeModal,
  getCurrentEvent,
  getSelectedHistory,
  getIsHistoryDetailOpen,
  getUsername,
  getUserRoles,
} from "store";
import { addActivity } from "reducers/user/user.actions";
//MODELS
import { UserActivityModel } from "reducers/user/user.model";
//UTILS
import {
  PROBABILITY_OF_OCCURRENCE,
  SEVERITY_ENUM,
  EVENT_STATE,
  SOURCE_TYPE,
} from "utils/utils";
import { getIconEvent } from "utils/utils_event_icon";
import {
  downloadExcelHistoryData,
  MODIFIED_FIELD_MAPPER,
} from "utils/table-utils/utils-report-history";
import { getTypeTranslation } from "utils/util-events";
import { saveBlob } from "utils/hooks";

class EventHistoryDetail extends Component {
  apiService;

  toggleExpandMessages = () => {
    const {
      isHistoryEventOpen,
      toggleEventHistoryDetail,
      currentEvent,
      historySelected,
      historyList,
      loadMoreData,
    } = this.props;
    if (!isHistoryEventOpen && historyList.length < 1)
      loadMoreData(currentEvent || historySelected);
    toggleEventHistoryDetail(!isHistoryEventOpen);
  };

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  toggleCloseEvent = (e) => {
    const { closeDetail } = this.props;

    e.preventDefault();
    closeDetail(e);
  };

  scrollLoadMoreData = (e) => {
    const { loadMoreData, currentEvent, historySelected } = this.props;
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      e.stopPropagation();
      loadMoreData(currentEvent || historySelected);
    }
  };

  translateFieldName = (field, fieldChange) => {
    const { listAttributes } = this.props;
    switch (field) {
      case "VALIDITY_END_TIME":
        return MODIFIED_FIELD_MAPPER.VALIDITY_END_TIME;
      case "VALIDITY_START_TIME":
        return MODIFIED_FIELD_MAPPER.VALIDITY_START_TIME;
      case "SITUATION_ID":
        return MODIFIED_FIELD_MAPPER.SITUATION_ID;
      case "PROBABILITY_OF_OCCURRENCE":
        return MODIFIED_FIELD_MAPPER.PROBABILITY_OF_OCCURRENCE;
      case "SEVERITY":
        return MODIFIED_FIELD_MAPPER.SEVERITY;
      case "STATE":
        return MODIFIED_FIELD_MAPPER.STATE;
      case "LOCATION":
        return MODIFIED_FIELD_MAPPER.LOCATION;
      case "TYPE":
        return MODIFIED_FIELD_MAPPER.TYPE;
      case "SUBTYPE":
        return MODIFIED_FIELD_MAPPER.SUBTYPE;
      case "GENERAL_PUBLIC_COMMENT":
        return MODIFIED_FIELD_MAPPER.GENERAL_PUBLIC_COMMENT;
      case "ATTRIBUTE":
        let retValue = listAttributes.find((_) => _.name === fieldChange);
        return (
          MODIFIED_FIELD_MAPPER.ATTRIBUTE +
          (retValue ? ": " + retValue.nameIT : "")
        );
      default:
        return field;
    }
  };

  onOrderChange = (event) => {
    const { handleDetailsReorder, currentEvent, historySelected } = this.props;
    event.stopPropagation();
    this.refs.detailsList.scrollTop = 0;
    handleDetailsReorder(event.target.value, currentEvent || historySelected);
  };

  downloadExcel = () => {
    const { historySelected, currentEvent, orderType } = this.props;

    this.apiService
      .fetchEventDetailHistoryExcel(
        historySelected || currentEvent,
        orderType,
        null,
        null
      )
      .pipe(
        tap((blob) => {
          let filename =
            "situation_record_history_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postEventDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postEventDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "SITUATION",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Dettaglio Storico Eventi"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  remapOrTranslate = (fieldType, field, key) => {
    const { listAttributes } = this.props;
    switch (fieldType) {
      case "VALIDITY_END_TIME":
      case "VALIDITY_START_TIME":
        return field ? moment(field).format("DD/MM/YYYY HH:mm") : "-";
      case "SITUATION_ID":
        return MODIFIED_FIELD_MAPPER.SITUATION_ID;
      case "PROBABILITY_OF_OCCURRENCE":
        return PROBABILITY_OF_OCCURRENCE[field];
      case "SEVERITY":
        let severity = SEVERITY_ENUM[field];
        return (
          <div
            className={
              severity === 1
                ? "image-gravity-dark-green"
                : severity === 2
                ? "image-gravity-green"
                : severity === 3
                ? "image-gravity-yellow"
                : severity === 4
                ? "image-gravity-orange"
                : severity === 5
                ? "image-gravity-red"
                : severity === 0
                ? "image-gravity-grey"
                : ""
            }
          ></div>
        );
      case "STATE":
        let state = EVENT_STATE[field.toLowerCase()];
        return (
          <div
            className={
              state === "Attivo"
                ? "green"
                : state === "Pianificato"
                ? "blue"
                : state === "Terminato"
                ? "green"
                : state === "Chiuso"
                ? "orange"
                : ""
            }
          >
            {state}
          </div>
        );
      case "LOCATION":
        return field || "-";
      case "TYPE":
      case "SUBTYPE":
      case "ATTRIBUTE":
        let retValue = listAttributes.find((_) => _.name === field);
        return retValue ? retValue.nameIT : field;
      default:
        // SWITCH di fallback nel caso alcuni dati nello storico fossero serviti con il vecchio metodo e fuori dal controllo degli enum sopra
        switch (key.toLowerCase()) {
          case "overallendtime":
            return field ? moment(field).format("DD/MM/YYYY HH:mm") : "-";
          case "overallstarttime":
            return field ? moment(field).format("DD/MM/YYYY HH:mm") : "-";
          case "probabilityofoccurrence":
            return PROBABILITY_OF_OCCURRENCE[field];
          case "state":
            let state = EVENT_STATE[field.toLowerCase()];
            return (
              <div
                className={
                  state === "Attivo"
                    ? "green"
                    : state === "Pianificato"
                    ? "blue"
                    : state === "Terminato"
                    ? "green"
                    : state === "Chiuso"
                    ? "orange"
                    : ""
                }
              >
                {state}
              </div>
            );
          case "severity":
            let severity = SEVERITY_ENUM[field];
            return (
              <div
                className={
                  severity === 1
                    ? "image-gravity-dark-green"
                    : severity === 2
                    ? "image-gravity-green"
                    : severity === 3
                    ? "image-gravity-yellow"
                    : severity === 4
                    ? "image-gravity-orange"
                    : severity === 5
                    ? "image-gravity-red"
                    : severity === 0
                    ? "image-gravity-grey"
                    : ""
                }
              ></div>
            );
          default:
            return field;
        }
    }
  };

  renderData() {
    const { historyList } = this.props;

    return (
      <div className="uk-flex uk-flex-column uk-flex-1">
        <table className="uk-table uk-table-divider no-bg">
          <thead>
            <tr className="">
              <th className={""}>
                <div className="">Modificato da</div>
              </th>
              <th className={""}>
                <div className="">Data aggiornamento</div>
              </th>
              <th className={""}>
                <div className="">Campo modificato</div>
              </th>
              <th className={""}>
                <div className="">Valore precedente</div>
              </th>
              <th className={""}>
                <div className="">Valore successivo</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {historyList ? (
              historyList.map((record) => (
                <tr className="" key={record.idHistory}>
                  <td className="">
                    {record.sourceReference
                      ? SOURCE_TYPE[record.sourceReference] +
                        " (" +
                        (record.sourceName || "") +
                        ") " +
                        record.sourceIdentification
                      : ""}
                  </td>
                  <td className="">
                    {record.operationDate
                      ? moment(record.operationDate).format("DD/MM/YYYY HH:mm")
                      : "-"}
                  </td>
                  <td className="">
                    {this.translateFieldName(
                      record.fieldChangeType,
                      record.fieldChange
                    ) || "-"}
                  </td>
                  <td className="">
                    {this.remapOrTranslate(
                      record.fieldChangeType || record.fieldChange,
                      record.fieldOldValue,
                      record.fieldChange
                    ) || "-"}
                  </td>
                  <td className="">
                    {this.remapOrTranslate(
                      record.fieldChangeType || record.fieldChange,
                      record.fieldNewValue,
                      record.fieldChange
                    ) || "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  renderHeading = () => {
    const { currentEvent, historySelected, selectModal } = this.props;
    let current = currentEvent || historySelected;
    let iconSrc = current
      ? getIconEvent(current ? current : {}, selectModal)
      : "";
    let iconAlt = current && current.situationRecordType;
    return (
      <div className="uk-flex uk-flex-row uk-flex-between">
        <div className="detail-event-wrapper uk-flex-left">
          Dettaglio Evento:{" "}
          <img className="detail-event-icon" alt={iconAlt} src={iconSrc} />{" "}
          {current && getTypeTranslation(currentEvent, selectModal)} (
          {current && current.situationRecordId})
        </div>
        <div className="uk-button-group uk-margin-right uk-flex-right">
          {/* DROPDOWN SELECT ORDERING */}
          {/* <div className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right">
            <span
              className="uk-margin-small-right uk-icon"
              uk-icon="icon: list; ratio: 1.5"
              uk-tooltip="Ordina dettagli per data di modifica"
            ></span>
            <select
              id="order-details"
              name={"orderType"}
              className="uk-select"
              value={orderType}
              onChange={(e) => this.onOrderChange(e)}
            >
              {ORDER_TYPES.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div> */}
          <button
            className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
            type="button"
            // disabled={true}
            onClick={this.downloadExcel}
          >
            <span
              uk-icon="icon: download"
              uk-tooltip="Download Excel"
              className="filterBarIcons borderWhite"
            ></span>
          </button>
          <button
            className="pointer button-no-focus"
            uk-icon="icon: acr-interface-close"
            onClick={this.toggleCloseEvent}
          ></button>
        </div>
      </div>
    );
  };

  render = () => {
    const { isHistoryEventOpen } = this.props;

    return (
      <div className="pmv-message-container uk-flex-right uk-flex uk-flex-column uk-width-1-1 z-index-1">
        <div className="position-relative uk-flex-right uk-flex uk-flex-column uk-width-1-1">
          <ul className="uk-tab-page-default uk-tab uk-flex-between uk-margin-remove-bottom">
            <li></li>
            <li
              className="pointer uk-active"
              onClick={this.toggleExpandMessages}
            >
              {!isHistoryEventOpen ? (
                <button
                  className="pointer button-no-focus"
                  uk-icon="icon: acr-interface-arrow-up"
                ></button>
              ) : (
                <button
                  className="pointer button-no-focus"
                  uk-icon="icon: acr-interface-arrow-down"
                ></button>
              )}
            </li>
            <li></li>
          </ul>
          <div className="bg-dark uk-flex uk-flex-column uk-width-1-1 uk-height-1-1 uk-padding-small">
            <div className="bg-dark uk-margin-small-left uk-margin-small-right uk-margin-small-bottom uk-margin-small-top uk-padding-remove border-bottom">
              {this.renderHeading()}
            </div>
            <div
              className={
                isHistoryEventOpen
                  ? "event-detail-wrapper max-height-320 bg-dark uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top custom-bottom-padding"
                  : "displayNone"
              }
              ref="detailsList"
              onScroll={this.scrollLoadMoreData}
            >
              {this.renderData()}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

EventHistoryDetail.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleEventHistoryDetail,
  setCurrentEvent,
  setSelectedHistory,
  addActivity,
};

const mapStateToProps = (state) => ({
  isHistoryEventOpen: getIsHistoryDetailOpen(state),
  currentEvent: getCurrentEvent(state),
  historySelected: getSelectedHistory(state),
  typeModal: getTypeModal(state),
  selectModal: getSelectModal(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventHistoryDetail)
);
