import * as moment from "moment";
import { selectFields } from "enums/selectable-params";
import * as xml2js from "xml2js";
import { throwError, timer } from "rxjs";
import { mergeMap, finalize } from "rxjs/operators";

export function toNameShort(nameLong) {
  let name = "";
  if (nameLong && nameLong.length < 4) {
    name = nameLong;
  } else if (nameLong) {
    var splits = nameLong.split(" ");
    var ind = splits.indexOf("-");
    if (ind !== -1) splits.splice(ind, 1);
    name = splits.map((split) => split[0]).join("");
  }
  return name;
}

export function debounce(func, milliseconds) {
  let timeout;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), milliseconds);
  };
}

export function reduceDuplicatesInArrayForId(data) {
  return data.filter(
    (elem, index, self) => index === self.findIndex((t) => t.id === elem.id)
  );
}

export function filterBounds(bounds) {
  return (geometry) =>
    geometry.coordinates &&
    geometry.coordinates.longitude > bounds[0] &&
    geometry.coordinates.latitude > bounds[1] &&
    geometry.coordinates.longitude < bounds[2] &&
    geometry.coordinates.latitude < bounds[3];
}

export function filterBoundsStations(bounds) {
  return (station) =>
    station &&
    station.longitude &&
    station.latitude &&
    station.longitude > bounds[0] &&
    station.latitude > bounds[1] &&
    station.longitude < bounds[2] &&
    station.latitude < bounds[3];
}

export function filterBoundsEvents(bounds) {
  return (geometry) =>
    geometry &&
    geometry.groupOfLocations &&
    geometry.groupOfLocations.length > 0 &&
    getEventPosition(geometry.groupOfLocations) &&
    getEventPosition(geometry.groupOfLocations)[0] > bounds[0] &&
    getEventPosition(geometry.groupOfLocations)[1] > bounds[1] &&
    getEventPosition(geometry.groupOfLocations)[0] < bounds[2] &&
    getEventPosition(geometry.groupOfLocations)[1] < bounds[3];
}

export function selectKpiDescription(kpi) {
  let descr = "";
  switch (kpi.id) {
    case "estimatedVehicleJourneyCount":
      descr = "Nr corse in programma";
      break;
    case "monitoredVehicleJourneyCount":
      descr = "Nr corse in servizio";
      break;
    case "monitoredVehicleJourneyCountLate":
      descr = "Nr corse in ritardo";
      break;
    case "monitoredVehicleJourneyRateLate":
      descr = "% corse in ritardo";
      break;
    case "monitoredVehicleJourneyRate":
      descr = "% corse in servizio";
      break;
    case "monitoredVehicleJourneyOnTimeIndicator":
      descr = "I. Puntualità (ultima ora)";
      break;
    case "monitoredVehicleJourneyCountEarly":
      descr = "Nr corse in anticipo";
      break;
    case "monitoredVehicleJourneyCountOnTime":
      descr = "Nr corse in orario";
      break;
    default:
      descr = "-";
  }

  return descr;
}

export function ISO8601toMin(timePT) {
  if (timePT) {
    var advance = timePT.search("PT-");
    var array = timePT.match(/(\d+)(?=[MHS])/gi) || [];
    if (array.length === 2) array = ["00"].concat(array);
    if (array.length === 1) array = ["00", "00"].concat(array);
    if (array.length === 0) array.push("00", "00", "00");
    var formatted = array
      .map((item) => {
        if (item.length < 2) return "0" + item;
        return item;
      })
      .join(":");
    return {
      delay: Math.round(moment.duration(formatted).asMinutes()),
      advance: advance === 0 || !advance ? true : false,
    };
  } else {
    return {
      delay: 0,
      advance: false,
    };
  }
}
export function lowerCaseAllWordsExceptFirstLetters(string) {
  return string.replace(/\w\S*/g, function (word) {
    return word.charAt(0) + word.slice(1).toLowerCase();
  });
}

export function upperCaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function replaceComma(str) {
  return str ? str.replace(/,/g, ".") : "";
}

// export const TYPE_EVENT = {
//   ACCIDENT: "Incidente",
//   ABNORMAL_TRAFFIC: "Livello di Servizio",
//   ANIMAL_PRESENCE_OBSTRUCTION: "Ostruzione di Animali",
//   POOR_ENVIRONMENT_CONDITIONS: "Precipitazioni",
//   AUTHORITY_OPERATION: "Operazioni delle Autorità	Accertamenti incidente",
//   DISTURBANCE_ACTIVITY: "Allerta",
//   PUBLIC_EVENT: "Evento pubblico",
//   WEATHER_RELATED_ROAD_CONDITIONS: "Condizioni stradali pericolose",
//   EQUIPMENT_OR_SYSTEM_FAULT: "Stato strumentazione",
//   GENERAL_OBSTRUCTION: "Allerta ostruzioni",
//   ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT: "Restrizioni di traffico",
//   CONSTRUCTION_WORKS: "Lavori civili",
//   VEHICLE_OBSTRUCTION: "Allerta Veicoli",
//   MAINTENANCE_WORKS: "Manutenzione stradale",
//   ENVIRONMENTAL_OBSTRUCTION: "Ostruzione ambientale",
//   accidenttype: "Incidente Stradale",
//   roadsideassistance: "Mezzi di Soccorso",
//   maintenanceworks: "Cantiere Stradale",
//   constructionworktype: "Cantiere Stradale",
//   constructionwork: "Cantiere Stradale",
//   abnormaltraffictype: "Traffico",
//   other: "Altro",
//   roadorcarriagewayorlanemanagementtype: "Manutenzione corsia",
//   equipmentorsystemfaulttype: "Sistemi in avaria",
//   vehicleobstructiontype: "Veicolo in Avaria",
//   animalpresenceobstructiontype: "Presenza animali",
//   obstructiontype: "Blocco",
//   genericroadsituationrecord: "Traffico",
//   publiceventtype: "Evento pubblico",
//   poorenvironmenttype: "Allerta Meteo",
//   nonweatherrelatedroadconditions: "Allerta Meteo",
//   weatherrelatedroadconditiontype: "Allerta Meteo",
//   authorityoperationtype: "Gestione stradale",
// };

export const STATUS_INSTALLATIONS = {
  WORKING: "Funzionante",
  NOT_WORKING: "Guasto",
  PARTIALLY_WORKING: "Avaria/Danneggiato",
  DAMAGED: "Danneggiato Non Pilotabile",
  "NO-SIGNAL": "Assenza di segnale",
};
// export const STATUS_SEVERITY = {
//   [selectFields.GRAVITA.properties[4].name]: 1,
//   [selectFields.GRAVITA.properties[3].name]: 2,
//   [selectFields.GRAVITA.properties[2].name]: 3,
//   [selectFields.GRAVITA.properties[1].name]: 4,
//   [selectFields.GRAVITA.properties[0].name]: 5,
// };
// export const SOURCE_EVENT = {
//   trafficmonitoringstation: "Stazione monitoraggio traffico",
//   mobiletelephonecaller: "Chiamata telefonica",
//   cameraobservation: "Videocamera",
//   roadauthorities: "Autorità stradale",
//   policepatrol: "Pattuglia polizia",
// };

// export const VALIDITY_EVENT = {
//   active: "Attivo",
//   suspended: "Sospeso",
// };

export const EVENT_STATE = {
  open: "Attivo",
  active: "Attivo",
  complete: "Terminato",
  planned: "Pianificato",
  close: "Chiuso",
  closed: "Chiuso",
  suspended: "Sospeso",
  scheduled: "Pianificato",
};

export const EVENT_STATE_BE_FE = {
  open: "active",
  active: "active",
  complete: "complete",
  planned: "planned",
  close: "close",
  closed: "closed",
  suspended: "suspended",
  scheduled: "planned",
};

export const EVENT_STATE_FE_RECORD_API = {
  active: "OPEN",
  complete: "CLOSED",
  planned: "SCHEDULED",
  close: "CLOSED",
  closed: "CLOSED",
  suspended: "CLOSED",
  scheduled: "SCHEDULED",
};

export const GRAVITY_TYPE = {
  HIGHEST: "Molto alta",
  HIGH: "Alta",
  MEDIUM: "Media",
  LOW: "Bassa",
  LOWEST: "Molto bassa",
  NONE: "Nessuna",
  UNKNOWN: "Sconosciuta",
};
export const PROBABILITY_OF_OCCURRENCE = {
  CERTAIN: "Certo",
  PROBABLE: "Possibile",
  RISK_OF: "Rischio",
};

export const TYPE_ARC = {
  0: "Autostrada",
  1: "Superstrada",
  2: "Strada extraurbana principale",
  3: "Strada extraurbana secondaria",
  4: "Strada extraurbana secondaria",
  5: "Strada urbana di scorrimento",
  6: "Strada urbana",
  7: "Strada urbana locale",
  8: "Altro tipo",
};
export function getIconEventsBg(event) {
  let iconBg;
  if (event && event.situationId) {
    event.selected
      ? (iconBg = "acr-event-list-base-multiple-selected")
      : (iconBg = "acr-event-list-base-multiple");
  } else {
    event && event.selected
      ? (iconBg = "acr-event-list-base-selected")
      : (iconBg = "acr-event-list-base");
  }
  return iconBg;
}

export function getWorseStationSensorStateDetail(station) {
  if (station.sensors && station.sensors.length === 1) {
    return station.sensors[0].status.status;
  }
  let stateStation = station.sensors.find((item) => {
    return item.status.status === "NO_INFO";
  });
  if (stateStation) {
    return stateStation.status.status;
  } else {
    stateStation = station.sensors.find((item) => {
      return item.status.status === "DOWN";
    });
    if (stateStation) {
      return stateStation.status.status;
    } else {
      return "UP";
    }
  }
}

export function imgStatusInstallations(deviceStatus, type) {
  let code = "";
  if (deviceStatus.status && deviceStatus.status.code)
    code = deviceStatus.status.code;
  if (type && type.toLowerCase() === "cctv") {
    return "acr-menu-installations-management";
  } else {
    switch (code) {
      case "WORKING":
        return "acr-pmv-funzionante";
      case "NO-SIGNAL":
        return "acr-pmv-noSignal";
      case "NOT_WORKING":
      case "DAMAGED":
      case "PARTIALLY_WORKING":
      default:
        return "acr-pmv-alert";
    }
  }
}

export function imgStatusInstallationsColor(deviceStatus) {
  let code = "";
  if (deviceStatus.status && deviceStatus.status.code)
    code = deviceStatus.status.code;
  switch (code) {
    case "WORKING":
      return "functioning-color";
    case "NOT_WORKING":
      return "broken-color";
    case "PARTIALLY_WORKING":
      return "avaria-color";
    case "DAMAGED":
      return "damaged-color";
    case "NO-SIGNAL":
    default:
      return "no-signal-color";
  }
}

export function imgStatusColorDetail(deviceStatus) {
  let code = "";
  if (deviceStatus.status && deviceStatus.status.code)
    code = deviceStatus.status.code;
  switch (code) {
    case "WORKING":
      return "detail-green-icon";
    case "NOT_WORKING":
      return "detail-red-icon";
    case "PARTIALLY_WORKING":
      return "detail-yellow-icon";
    case "DEMAGED":
      return "detail-blue-icon";
    case "NO-SIGNAL":
    default:
      return "detail-white-icon";
  }
}

export const W_CHECKBOX_ENUM = {
  MONDAY: "L",
  TUESDAY: "Ma",
  WEDNESDAY: "Me",
  THURSDAY: "G",
  FRIDAY: "V",
  SATURDAY: "S",
  SUNDAY: "D",
  ALL: "ALL",
};

export function setCheckedWeekly(targetId, configs) {
  let confs = [...configs];
  if (confs.includes(targetId)) {
    return [...confs.filter((item) => item !== targetId)];
  }

  switch (targetId) {
    case W_CHECKBOX_ENUM.MONDAY:
      confs = [...confs, W_CHECKBOX_ENUM.MONDAY];
      break;
    case W_CHECKBOX_ENUM.TUESDAY:
      confs = [...confs, W_CHECKBOX_ENUM.TUESDAY];
      break;
    case W_CHECKBOX_ENUM.WEDNESDAY:
      confs = [...confs, W_CHECKBOX_ENUM.WEDNESDAY];
      break;
    case W_CHECKBOX_ENUM.THURSDAY:
      confs = [...confs, W_CHECKBOX_ENUM.THURSDAY];
      break;
    case W_CHECKBOX_ENUM.FRIDAY:
      confs = [...confs, W_CHECKBOX_ENUM.FRIDAY];
      break;
    case W_CHECKBOX_ENUM.SATURDAY:
      confs = [...confs, W_CHECKBOX_ENUM.SATURDAY];
      break;
    case W_CHECKBOX_ENUM.SUNDAY:
      confs = [...confs, W_CHECKBOX_ENUM.SUNDAY];
      break;
    case W_CHECKBOX_ENUM.ALL:
      confs = [...confs, W_CHECKBOX_ENUM.ALL];
      break;
    default:
      break;
  }
  return Array.from(new Set([...confs]));
}

export function setValidityStatusEvent(
  startDateTime,
  endDateTime,
  status,
  fromBe
) {
  if (status === "ACTIVE") {
    return "active";
  } else if (status === "SUSPENDED") {
    return "suspended";
  } else if (fromBe && status === "DEFINED_BY_VALIDITY_TIME_SPEC") {
    if (
      startDateTime &&
      moment().isAfter(moment(startDateTime)) &&
      (!endDateTime || (endDateTime && moment(endDateTime).isAfter(moment())))
    ) {
      return "active";
    } else if (startDateTime && moment(startDateTime).isAfter(moment())) {
      return "planned";
    } else if (endDateTime && moment().isAfter(moment(endDateTime))) {
      return "closed";
    }
  }
  return "DEFINED_BY_VALIDITY_TIME_SPEC";
}

export function normalizingDateTimeEventModal(date, time) {
  date.setHours(13);
  let localedate = date.toLocaleString("it-IT", { timeZone: "UTC" });
  let newDate;
  let newTime;
  if (localedate) {
    newDate = localedate.split(",")[0];
  }
  if (time) {
    newTime = time.toString().split(" ")[4];
  }
  let locale = newDate + " " + newTime;
  let localemoment = moment(locale, "D/M/YYYY HH:mm:ss").toISOString();
  return localemoment;
}

export const SEVERITY_ENUM = {
  [selectFields.GRAVITA.properties[0].name]: 5,
  [selectFields.GRAVITA.properties[1].name]: 4,
  [selectFields.GRAVITA.properties[2].name]: 3,
  [selectFields.GRAVITA.properties[3].name]: 2,
  [selectFields.GRAVITA.properties[4].name]: 1,
  [selectFields.GRAVITA.properties[5].name]: 0,
};

export const SOURCE_TYPE = {
  OPERATOR: "Operatore",
  VERBATEL: "Verbatel",
  DATEX_NODE: "Nodo Datex",
};

export function eventLocalizationToShowUtil(groupOfLocations) {
  let arrLocations = [];

  let pointRdsTmc = groupOfLocations.find(
    (item) => item.locationType === "ALERT_C_METHOD_4_POINT"
  );
  let linearRdsTmc = groupOfLocations.find(
    (item) => item.locationType === "ALERT_C_METHOD_4_LINEAR"
  );
  let linearList = groupOfLocations.filter(
    (item) => item.locationType === "LINEAR_WITHIN_LINEAR_ELEMENT"
  );
  let point = groupOfLocations.find(
    (item) => item.locationType === "POINT_BY_COORDINATES"
  );

  if (
    pointRdsTmc &&
    pointRdsTmc.defaultLocation &&
    pointRdsTmc.point &&
    pointRdsTmc.point.alertCPoint &&
    pointRdsTmc.point.alertCPoint.primaryLocation &&
    pointRdsTmc.point.alertCPoint.primaryLocation.alertCLocationName &&
    pointRdsTmc.point.alertCPoint.primaryLocation.alertCLocationName.content
  ) {
    arrLocations.push(
      pointRdsTmc.point.alertCPoint.primaryLocation.alertCLocationName.content +
        " - " +
        (pointRdsTmc.point.alertCPoint.primaryLocation.offsetDistance
          ? pointRdsTmc.point.alertCPoint.primaryLocation.offsetDistance
          : "0") +
        " m" +
        (pointRdsTmc.point.alertCPoint.alertCDirection &&
        pointRdsTmc.point.alertCPoint.alertCDirection.alertCDirectionCoded &&
        pointRdsTmc.point.alertCPoint.alertCDirection.alertCDirectionCoded ===
          "BOTH"
          ? " , bidirezionale"
          : "")
    );
  } else if (
    linearRdsTmc &&
    linearRdsTmc.defaultLocation &&
    linearRdsTmc.linear &&
    linearRdsTmc.linear.alertCLinear &&
    linearRdsTmc.linear.alertCLinear.primaryLocation &&
    linearRdsTmc.linear.alertCLinear.primaryLocation.alertCLocationName &&
    linearRdsTmc.linear.alertCLinear.secondaryLocation &&
    linearRdsTmc.linear.alertCLinear.secondaryLocation.alertCLocationName &&
    linearRdsTmc.linear.alertCLinear.primaryLocation.alertCLocationName
      .content &&
    linearRdsTmc.linear.alertCLinear.secondaryLocation.alertCLocationName
      .content
  ) {
    arrLocations.push(
      linearRdsTmc.linear.alertCLinear.secondaryLocation.alertCLocationName
        .content +
        " + " +
        (linearRdsTmc.linear.alertCLinear.secondaryLocation.offsetDistance
          ? linearRdsTmc.linear.alertCLinear.secondaryLocation.offsetDistance
          : "0") +
        ", " +
        linearRdsTmc.linear.alertCLinear.primaryLocation.alertCLocationName
          .content +
        " - " +
        (linearRdsTmc.linear.alertCLinear.primaryLocation.offsetDistance
          ? linearRdsTmc.linear.alertCLinear.primaryLocation.offsetDistance
          : "0") +
        (linearRdsTmc.linear.alertCLinear.alertCDirection &&
        linearRdsTmc.linear.alertCLinear.alertCDirection.alertCDirectionCoded &&
        linearRdsTmc.linear.alertCLinear.alertCDirection
          .alertCDirectionCoded === "BOTH"
          ? " , bidirezionale"
          : "")
    );
  } else if (linearList.length > 0) {
    linearList.forEach((item) => {
      if (
        item.defaultLocation &&
        item.linear &&
        item.linear.linearWithinLinearElement &&
        item.linear.linearWithinLinearElement.linearElement &&
        item.linear.linearWithinLinearElement.linearElement.roadName &&
        item.linear.linearWithinLinearElement.linearElement.roadName.content
      ) {
        let isPresent = arrLocations.find(
          (loc) =>
            loc ===
            item.linear.linearWithinLinearElement.linearElement.roadName.content
        );
        if (!isPresent) {
          return arrLocations.push(
            item.linear.linearWithinLinearElement.linearElement.roadName.content
          );
        }
      }
    });
  } else if (
    point &&
    point.defaultLocation &&
    point.point &&
    point.point.pointByCoordinates &&
    point.point.pointByCoordinates.latitude &&
    point.point.pointByCoordinates.longitude
  ) {
    arrLocations.push(
      Math.round(point.point.pointByCoordinates.latitude * 100000) / 100000 +
        " - " +
        Math.round(point.point.pointByCoordinates.longitude * 100000) / 100000
    );
  } else if (
    point &&
    point.defaultLocation &&
    point.locationForDisplay &&
    point.locationForDisplay.latitude &&
    point.locationForDisplay.longitude
  ) {
    arrLocations.push(
      Math.round(point.locationForDisplay.latitude * 100000) / 100000 +
        " - " +
        Math.round(point.locationForDisplay.longitude * 100000) / 100000
    );
  }

  return arrLocations;
}

export function checkEventEnableUpdatesBySource(event, sourcesConfig) {
  const source = sourcesConfig.find(
    (item) => item.sourceId === event.source.sourceReference
  );

  let enableEventUpdates = false;
  if (source) {
    enableEventUpdates = source.enableUpdates;
  }

  return enableEventUpdates;
}

export function parseXmlToJson(xml) {
  // var ciao = (new window.DOMParser()).parseFromString(xml, "text/xml");
  let parsed;
  xml2js.parseString(
    xml,
    {
      tagNameProcessors: [xml2js.processors.stripPrefix],
      mergeAttrs: true,
      explicitArray: false,
    },
    (err, result) => {
      if (!err) {
        parsed = result;
      }
      return;
    }
  );
  return parsed;
}

export function getEventPosition(groupOfLocations) {
  let longitude = "";
  let latitude = "";

  let hasLocationForDisplay = groupOfLocations.find(
    (item) =>
      item.locationForDisplay &&
      item.locationForDisplay.longitude &&
      item.locationForDisplay.latitude
  );

  let hasPointForDisplay = groupOfLocations.find(
    (item) => item.locationType === "POINT_BY_COORDINATES"
  );

  if (hasLocationForDisplay && hasLocationForDisplay.locationForDisplay) {
    longitude = hasLocationForDisplay.locationForDisplay.longitude;
    latitude = hasLocationForDisplay.locationForDisplay.latitude;
  } else if (
    hasPointForDisplay &&
    hasPointForDisplay.point &&
    hasPointForDisplay.point.pointByCoordinates
  ) {
    longitude = hasPointForDisplay.point.pointByCoordinates.longitude;
    latitude = hasPointForDisplay.point.pointByCoordinates.latitude;
  }

  return [longitude, latitude];
}

export const genericRetryStrategy = (
  maxRetryAttempts = 5,
  scalingDuration = 2000,
  excludedStatusCodes = []
) => (attempts) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find((e) => e === error.status)
      ) {
        return throwError(error);
      }
      // console.log(
      //   `Attempt ${retryAttempt}: retrying in ${retryAttempt *
      //   scalingDuration}ms`
      // );
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    }),
    finalize(() => null /* console.log('We are done!')*/)
  );
};
