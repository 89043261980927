import { WsActions } from "./ws.actions";

const INITIAL_STATE = {
  events: false,
  tpl: false,
  tplLocation: false,
  stationsStatus: false,
  stationsMeasures: false,
  stationNotification: false,
  parkings: false,
  camerasState: false,
  camerasAlarms: false,
};

export default function wsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WsActions.WS_OPEN:
      let type = action.payload && action.payload.type;
      let isOpen = action.payload && action.payload.isOpen;
      return {
        ...state,
        [type]: isOpen,
      };
    default:
      return state;
  }
}

export const isWSTplOpen = (state) => state.tpl;
export const isWSTplLocationOpen = (state) => state.tplLocation;
export const isWSEventsOpen = (state) => state.events;
export const isWSStationStatusOpen = (state) => state.stationsStatus;
export const isWSStationMeasuresOpen = (state) => state.stationsMeasures;
export const isWSStationNotificationOpen = (state) => state.stationNotification;
export const isWSParkingsOpen = (state) => state.parkings;
export const isWSCamerasStateOpen = (state) => state.camerasState;
export const isWSCamerasAlarmsOpen = (state) => state.camerasAlarms;
