import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
// COMPONENTS
import NavTab from "components/layout/nav-tab";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import PointRdsTmcList from "components/layout/point-rds-tmc-list";
import PointsRdsTmcDetail from "components/layout/point-rds-tmc-list/point-rds-tmc-detail";
import SegmentRdsTmcList from "components/layout/segment-rds-tmc-list";
import SegmentRdsTmcDetail from "components/layout/segment-rds-tmc-list/segment-rds-tmc-detail";
import SegmentRdsTmcRoute from "components/layout/segment-rds-tmc-list/segment-rds-tmc-route";
import BottomControlsIcons from "components/shared/bottom-controls/bottom-controls-icons";
//REDUX
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import {
  setCurrentSegmentRdsTmc,
  setSegmentsRdsTmcList,
} from "reducers/graph/segments/segments.actions";
import {
  setCurrentPointRdsTmc,
  setPointsRdsTmcList,
  filterPointsBySegment,
} from "reducers/graph/points/points.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import {
  getNavTabSelectedTab,
  isNetworkRdsTmcVisible,
  getCurrentPointRdsTmc,
  getCurrentSegmentRdsTmc,
  getFilteredSegments,
  getFilteredPoints,
  getBoundingBox,
  getMapPolygon,
  isOpenNavTab,
  getCurrentZoom,
  isPointsLoading,
  isSegmentsLoading,
} from "store";
import { setIsNetworkLoading } from "reducers/ui/graph-menu/graph-menu.actions";
//MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";
//UTILS
import { debounce } from "utils/utils";

class NetworkRdsTmcPage extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {
      //TO SWITCH BETWEEN SEGMENT TABS - DETAIL AND ROUTE (PERCORSO)
      onSegmentRouteTab: false,
    };
    this.setPointsDebounced = debounce(this.setPointList, 500);
  }

  componentDidMount = () => {
    const { setNavTabSelectedTab } = this.props;
    this.apiService = createApiService(this.context);

    setNavTabSelectedTab(0);
  };

  componentDidUpdate = (prevProps) => {
    const { currentPointRdsTmc, currentSegmentRdsTmc } = this.props;

    //FILTER POINTS AND SEGMENTS BY THEIR RELATIONSHIP
    if (
      prevProps.currentPointRdsTmc !== currentPointRdsTmc &&
      currentPointRdsTmc !== null
    ) {
      if (
        currentPointRdsTmc &&
        currentPointRdsTmc.properties &&
        currentPointRdsTmc.properties.road_lcd
      ) {
        let filterSegmentsByPoint =
          "seg_lcd=" +
          currentPointRdsTmc.properties.road_lcd +
          " OR " +
          "road_lcd=" +
          currentPointRdsTmc.properties.road_lcd;
        this.getApiRoadsList(filterSegmentsByPoint);
      }
    }

    if (
      prevProps.currentSegmentRdsTmc !== currentSegmentRdsTmc &&
      currentSegmentRdsTmc !== null
    ) {
      if (
        currentSegmentRdsTmc &&
        currentSegmentRdsTmc.properties &&
        (currentSegmentRdsTmc.properties.seg_lcd ||
          currentSegmentRdsTmc.properties.road_lcd)
      ) {
        let filterPointsBySegmentString = "";
        if (
          currentSegmentRdsTmc.properties.seg_lcd &&
          currentSegmentRdsTmc.properties.road_lcd
        ) {
          filterPointsBySegmentString =
            "road_lcd=" +
            currentSegmentRdsTmc.properties.seg_lcd +
            " OR " +
            "road_lcd=" +
            currentSegmentRdsTmc.properties.road_lcd;
        } else if (currentSegmentRdsTmc.properties.seg_lcd) {
          filterPointsBySegmentString =
            "road_lcd=" + currentSegmentRdsTmc.properties.seg_lcd;
        } else if (currentSegmentRdsTmc.properties.road_lcd) {
          filterPointsBySegmentString =
            "road_lcd=" + currentSegmentRdsTmc.properties.road_lcd;
        }
        this.getApiPointsList(null, filterPointsBySegmentString);
      }
    }
  };

  changeTabs = (index) => {
    const {
      setNavTabSelectedTab,
      setNavTabSearch,
      menuTabReducer,
    } = this.props;
    //EMPTY SEARCH FROM REDUCER
    setNavTabSearch(
      "",
      menuTabReducer === 0 ? "punti-tmc" : "tratte-tmc",
      null,
      null
    );
    //CHANGE TAB
    setNavTabSelectedTab(index);
  };

  togglePointDetail = (point) => {
    const { setCurrentPointRdsTmc } = this.props;
    setCurrentPointRdsTmc({ point: point });
  };

  toggleSegmentDetail = (segment) => {
    const { setCurrentSegmentRdsTmc } = this.props;
    setCurrentSegmentRdsTmc({ segment: segment });
  };

  handleClicksSegmentRoute = () => {
    this.setState({ onSegmentRouteTab: !this.state.onSegmentRouteTab });
  };

  setPointList = () => {
    const {
      boundingBox,
      isNetworkRdsTmcVisible,
      currentMapZoom,
      setIsNetworkLoading,
      isSegmentsLoading,
    } = this.props;

    if (isNetworkRdsTmcVisible) {
      setIsNetworkLoading({
        isPointsLoading: true,
        isSegmentsLoading: isSegmentsLoading,
      });

      let stringFilterByType = null;
      let newBounds =
        boundingBox[0] +
        " " +
        boundingBox[1] +
        "," +
        boundingBox[0] +
        " " +
        boundingBox[3] +
        "," +
        boundingBox[2] +
        " " +
        boundingBox[3] +
        "," +
        boundingBox[2] +
        " " +
        boundingBox[1] +
        "," +
        boundingBox[0] +
        " " +
        boundingBox[1];

      if (currentMapZoom <= 11) {
        stringFilterByType = `subtype_id=1 AND INTERSECTS(geom,POLYGON((${newBounds})))`;
      } else if (currentMapZoom <= 15) {
        stringFilterByType = `(subtype_id=1 OR subtype_id = 2) AND INTERSECTS(geom,POLYGON((${newBounds})))`;
      } else if (currentMapZoom > 15 && currentMapZoom <= 16) {
        stringFilterByType = `(subtype_id=1 OR subtype_id = 2 OR subtype_id=3 OR subtype_id=4 OR subtype_id=5) AND INTERSECTS(geom,POLYGON((${newBounds})))`;
      } else {
        stringFilterByType = `INTERSECTS(geom,POLYGON((${newBounds})))`;
      }
      this.getApiPointsList(null, stringFilterByType);
    }
  };

  getApiPointsList = (bbox, cqlFilter) => {
    const {
      setPointsRdsTmcList,
      currentSegmentRdsTmc,
      filterPointsBySegment,
      setIsNetworkLoading,
    } = this.props;

    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures("points", null, null, null, null, bbox, cqlFilter)
        .pipe(
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "points"
            );
            let points = [];
            if (featureCollection.numberReturned > 0) {
              points = [...featureCollection.features];
            }
            setPointsRdsTmcList(points);
            if (currentSegmentRdsTmc) {
              filterPointsBySegment({ segment: currentSegmentRdsTmc });
            }
            setIsNetworkLoading({
              isPointsLoading: false,
              isSegmentsLoading: false,
            });
          }),
          catchError((error) => {
            console.error(error);
            setIsNetworkLoading({
              isPointsLoading: false,
              isSegmentsLoading: false,
            });

            return of(error);
          })
        )
        .subscribe()
    );
  };

  getApiRoadsList = (cqlFilter) => {
    const { setSegmentsRdsTmcList, setIsNetworkLoading } = this.props;

    let filter = "have_network=true";

    if (cqlFilter) {
      filter = filter + " AND " + cqlFilter;
    }
    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures("roads", null, null, null, null, null, filter)
        .pipe(
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "roads"
            );
            let roads = [];
            if (featureCollection.numberReturned > 0) {
              roads = [...featureCollection.features];
            }
            setSegmentsRdsTmcList(roads);
            setIsNetworkLoading({
              isPointsLoading: false,
              isSegmentsLoading: false,
            });
          }),
          catchError((error) => {
            console.error(error);
            setIsNetworkLoading({
              isPointsLoading: false,
              isSegmentsLoading: false,
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  injectItems = () => {
    const {
      menuTabReducer,
      currentPointRdsTmc,
      currentSegmentRdsTmc,
      filteredSegments,
      filteredPoints,
      isNetworkRdsTmcVisible,
      toggleGenericTable,
      isPointsLoading,
      isSegmentsLoading,
    } = this.props;
    const { onSegmentRouteTab } = this.state;

    let segmentsList = [];
    let pointsList = [];

    if (filteredSegments) {
      segmentsList = [...filteredSegments];
    }
    if (filteredPoints) {
      pointsList = [...filteredPoints];
    }
    const lengthPoints = currentPointRdsTmc ? "1" : pointsList.length;
    const lengthSegment = currentSegmentRdsTmc ? "1" : segmentsList.length;

    const labelPoints = "Punti TMC (" + lengthPoints + ")";
    const labelSegments = "Tratte TMC (" + lengthSegment + ")";

    let controlsList = [];

    if (menuTabReducer === 0) {
      controlsList.push({
        icon: "acr-interface-list",
        tooltip: "Tabella Punti RDS TMC",
        className: "table-icon",
        toggle: () => toggleGenericTable("pointsTable"),
      });
    } else {
      controlsList.push({
        icon: "acr-interface-list",
        tooltip: "Tabella Tratte RDS TMC",
        className: "table-icon",
        toggle: () => toggleGenericTable("tmcTable"),
      });
    }

    return (
      <div className="uk-height-1-1 uk-width-1-1 inherit-transition uk-position-relative">
        <ErrorBoundary>
          <TabPanel className="tabsContainerTpl inherit-transition">
            <ul className="uk-tab">
              <li
                onClick={(e) => {
                  e.preventDefault();
                  this.changeTabs(0);
                }}
                className={
                  menuTabReducer === 0
                    ? "uk-active uk-margin-medium-right"
                    : "uk-margin-medium-right"
                }
              >
                <a href="/#">{labelPoints}</a>
              </li>
              <li
                onClick={(e) => {
                  e.preventDefault();
                  this.changeTabs(1);
                }}
                className={
                  menuTabReducer === 1
                    ? "uk-active uk-margin-medium-right"
                    : "uk-margin-medium-right"
                }
              >
                <a href="/#">{labelSegments}</a>
              </li>
            </ul>
            {isNetworkRdsTmcVisible
              ? [
                  <TabPanel
                    key={"TabPanel-Point"}
                    className="overflow inherit-transition uk-height-1-1"
                    value={menuTabReducer}
                    index={0}
                  >
                    <ErrorBoundary>
                      {currentPointRdsTmc ? (
                        <PointsRdsTmcDetail
                          togglePointDetail={this.togglePointDetail}
                        />
                      ) : (
                        <PointRdsTmcList
                          pointsList={pointsList}
                          togglePointDetail={this.togglePointDetail}
                          isPointsLoading={isPointsLoading}
                        />
                      )}
                    </ErrorBoundary>
                  </TabPanel>,
                  <TabPanel
                    key={"TabPanel-RdsTmc"}
                    className="overflow inherit-transition uk-height-1-1"
                    value={menuTabReducer}
                    index={1}
                  >
                    <ErrorBoundary>
                      {currentSegmentRdsTmc && !onSegmentRouteTab ? (
                        <SegmentRdsTmcDetail
                          toggleSegmentDetail={this.toggleSegmentDetail}
                          handleClicksSegmentRoute={
                            this.handleClicksSegmentRoute
                          }
                        />
                      ) : currentSegmentRdsTmc && onSegmentRouteTab ? (
                        <SegmentRdsTmcRoute
                          toggleSegmentDetail={this.toggleSegmentDetail}
                          handleClicksSegmentRoute={
                            this.handleClicksSegmentRoute
                          }
                        />
                      ) : (
                        <SegmentRdsTmcList
                          segmentsList={segmentsList}
                          toggleSegmentDetail={this.toggleSegmentDetail}
                          isSegmentsLoading={isSegmentsLoading}
                        />
                      )}
                    </ErrorBoundary>
                  </TabPanel>,
                ]
              : null}
          </TabPanel>
        </ErrorBoundary>
        <BottomControlsIcons controls={controlsList} />
      </div>
    );
  };

  handleSearch = (value) => {
    const { setNavTabSearch, menuTabReducer } = this.props;
    setNavTabSearch(
      value,
      menuTabReducer === 0 ? "punti-tmc" : "tratte-tmc",
      null,
      null
    );
  };

  render() {
    const { menuTabReducer } = this.props;

    return (
      <NavTab
        isOpen={true}
        items={this.injectItems()}
        onSearch={this.handleSearch}
        currentTab={menuTabReducer === 0 ? "punti-tmc" : "tratte-tmc"}
      />
    );
  }

  componentWillUnmount() {
    const { setNavTabSearch, menuTabReducer } = this.props;
    this.subscriptions.forEach((x) => x.unsubscribe());
    setNavTabSearch(
      "",
      menuTabReducer === 0 ? "punti-tmc" : "tratte-tmc",
      null,
      null
    );
  }
}

NetworkRdsTmcPage.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  setCurrentPointRdsTmc,
  setCurrentSegmentRdsTmc,
  setSegmentsRdsTmcList,
  setPointsRdsTmcList,
  toggleOpenNavTab,
  toggleGenericTable,
  filterPointsBySegment,
  setIsNetworkLoading,
};

const mapStateToProps = (state) => ({
  menuTabReducer: getNavTabSelectedTab(state),
  isNetworkRdsTmcVisible: isNetworkRdsTmcVisible(state),
  currentPointRdsTmc: getCurrentPointRdsTmc(state),
  currentSegmentRdsTmc: getCurrentSegmentRdsTmc(state),
  filteredSegments: getFilteredSegments(state),
  filteredPoints: getFilteredPoints(state),
  boundingBox: getBoundingBox(state),
  mapPolygonReducer: getMapPolygon(state),
  isOpenNavTab: isOpenNavTab(state),
  currentMapZoom: getCurrentZoom(state),
  isPointsLoading: isPointsLoading(state),
  isSegmentsLoading: isSegmentsLoading(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NetworkRdsTmcPage)
);
