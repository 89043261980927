import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import "../style.less";

import EnvironmentContext from "environment-context";
//COMPONENTS
import TableBody from "components/shared/table-components/table-body";
import TableHead from "components/shared/table-components/table-head";
//STORE
import { getTrafficContexts } from "store";
import { getTrafficDataTableView, getTimeRange } from "utils/utils-traffic";
import { TABLE_COLUMNS } from "utils/table-utils/util-traffic-table";
class ListTrafficTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      openStationTable: false,
      dataTable: [],
      defaultMeasure: null,
      sortConfig: { key: "", direction: "" },
    };
  }

  onCheckboxFilterClick = () => {
    return null;
  };

  requestSort = () => {
    return null;
  };

  toTableColumns = () => {
    const {
      defaultMeasure,
      dayTypes,
      currentElaboratedData,
      contexts,
      measureUnit,
    } = this.props;

    let newList = [];
    let timeRange = getTimeRange(currentElaboratedData);

    timeRange.forEach((item, index) => {
      let local_date_start = moment
        .utc(item)
        .local()
        .format("YYYY-MM-DD HH:mm:ss");
      let time = moment(local_date_start).format("HH:mm:ss");
      let date = moment(local_date_start).format("YYYY-MM-DD");
      let interval15Minutes = moment(time, "HH:mm:ss")
        .add(15, "minutes")
        .format("LTS");
      let tableViewData = getTrafficDataTableView(
        item,
        currentElaboratedData,
        contexts,
        measureUnit
      );

      let newEl = {
        beginning: local_date_start,
        end: date + " " + interval15Minutes,
        measure: defaultMeasure + " [" + measureUnit + "]",
        current:
          (tableViewData && tableViewData[5]) ||
          (tableViewData && tableViewData[5] === 0)
            ? tableViewData[5].toFixed(1)
            : "-",
        forecast15:
          (tableViewData && tableViewData[4]) ||
          (tableViewData && tableViewData[4] === 0)
            ? tableViewData[4].toFixed(1)
            : "-",
        forecast30:
          (tableViewData && tableViewData[3]) ||
          (tableViewData && tableViewData[3] === 0)
            ? tableViewData[3].toFixed(1)
            : "-",
        forecast45:
          (tableViewData && tableViewData[2]) ||
          (tableViewData && tableViewData[2] === 0)
            ? tableViewData[2].toFixed(1)
            : "-",
        forecast60:
          (tableViewData && tableViewData[1]) ||
          (tableViewData && tableViewData[1] === 0)
            ? tableViewData[1].toFixed(1)
            : "-",
        profile:
          (tableViewData && tableViewData[0]) ||
          (tableViewData && tableViewData[0] === 0)
            ? tableViewData[0].toFixed(1)
            : "-",
        dayType: dayTypes.find((time) => {
          return (
            moment(time.date).isSame(moment(item), "year") &&
            moment(time.date).isSame(moment(item), "month") &&
            moment(time.date).isSame(moment(item), "day")
          );
        })
          ? dayTypes.find((time) => {
              return (
                moment(time.date).isSame(moment(item), "year") &&
                moment(time.date).isSame(moment(item), "month") &&
                moment(time.date).isSame(moment(item), "day")
              );
            }).type
          : "-",
      };
      newList = [...newList, newEl];
    });

    return newList;
  };

  render = () => {
    const { currentElaboratedData } = this.props;
    const { sortConfig } = this.state;

    let listOfData = [];
    if (currentElaboratedData) {
      listOfData = this.toTableColumns();
    }

    return (
      <div
        className={
          "uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left"
        }
      >
        <table
          id="eventTable"
          className="uk-table uk-table-divider uk-table-hover"
        >
          <thead>
            <TableHead
              onCheckboxFilterClick={this.onCheckboxFilterClick}
              requestSort={this.requestSort}
              tableColumns={TABLE_COLUMNS}
              sortConfig={sortConfig}
            />
          </thead>
          <tbody>
            <TableBody list={listOfData} tableColumns={TABLE_COLUMNS} />
          </tbody>
        </table>
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

ListTrafficTable.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  contexts: getTrafficContexts(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListTrafficTable)
);
