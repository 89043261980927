import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
import ModalMap from "components/layout/modal/event/new-event/modal-map";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import OrderTableColumn from "components/shared/order-table-column/order-table-column";
import PmvPanelBigIcon from "components/layout/panels/pmv-panel/pmv-panel-big-icon";
import PmvPanelBigText from "components/layout/panels/pmv-panel/pmv-panel-big-text";

class SelectionPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalMapWidth: false,
      busNr: "",
      busName: "",
    };
  }

  renderGroupEventHeader = () => {
    return (
      <tr>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between tableHeadPanel">
            ID PMV
            <OrderTableColumn
              requestSort={this.requestSort}
              type="panelIdPMV"
            />
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Nome PMV
            <OrderTableColumn
              requestSort={this.requestSort}
              type="panelNamePMV"
            />
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Tipo PMV
            <OrderTableColumn
              requestSort={this.requestSort}
              type="panelTypePMV"
            />
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Seleziona
          </div>
        </th>
      </tr>
    );
  };

  renderGroupEventBody() {
    return (
      <tbody>
        <tr>
          <td className="tableCellPanel">1423</td>
          <td>-</td>
          <td>33</td>
          <td>
            <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid checkboxPanelSelection">
              <span>
                <input
                  className="uk-checkbox uk-checkbox-large"
                  type="checkbox"
                />
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td className="tableCellPanel">1423</td>
          <td>-</td>
          <td>33</td>
          <td>
            <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid checkboxPanelSelection">
              <span>
                <input
                  className="uk-checkbox uk-checkbox-large"
                  type="checkbox"
                />
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td className="tableCellPanel">1423</td>
          <td>-</td>
          <td>33</td>
          <td>
            <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid checkboxPanelSelection">
              <span>
                <input
                  className="uk-checkbox uk-checkbox-large"
                  type="checkbox"
                />
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    );
  }

  groupEventButtonsTable = () => {
    return (
      <div className=" uk-flex uk-flex-row uk-flex-right bottomButtonsPanelSelection">
        <div className="">
          <button className="uk-button uk-button-default uk-button-small cancelButton">
            Cancella
          </button>
          <button className="uk-button  button-black uk-button-small uk-margin-left savePanelButton">
            Salva Pannelli PMV
          </button>
        </div>
      </div>
    );
  };

  requestSort = (key) => {
    const { sortConfig } = this.state;

    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    this.setState({ sortConfig: { key: key, direction: direction } });
  };

  applyMessage = () => {
    this.setState({ busNr: this.refs.nrOfBus.value });
    this.setState({ busName: this.refs.nameOfBus.value });
  };

  render() {
    const { modalMapWidth } = this.state;

    return (
      <div className="uk-flex uk-flex-column uk-position-relative uk-form-horizontal">
        <div className="uk-flex uk-flex-row panel-selection-paddng">
          <ErrorBoundary>
            <div className="uk-form-horizontal uk-width-1-2 ">
              <div className="container-InfoManagement ">
                <div className="containerInfoCheckbox">
                  <span>
                    <input
                      className="uk-checkbox uk-checkbox-large"
                      type="checkbox"
                    />
                    <label className="checkboxInfoManagement">
                      Attiva Pannelli PMV
                    </label>
                  </span>
                  <span>
                    <input
                      className="uk-checkbox uk-checkbox-large"
                      type="checkbox"
                    />
                    <label className="checkboxInfoManagement">
                      Associa Pannelli con lo stile
                    </label>
                  </span>
                </div>
                <div className="containerInfoTable">
                  <table
                    id="eventPracticeTable"
                    className="uk-table uk-table-divider uk-table-hover "
                  >
                    <thead>{this.renderGroupEventHeader()}</thead>
                    {this.renderGroupEventBody()}
                  </table>
                </div>
                {this.groupEventButtonsTable()}
              </div>
            </div>
          </ErrorBoundary>
          <div className="localizzazione-form uk-width-1-2 uk-flex padding-form-right mapBoundary uk-position-relative">
            <ErrorBoundary>
              <ModalMap modalMapWidth={modalMapWidth} />
            </ErrorBoundary>
          </div>
        </div>
        <div className="uk-flex uk-flex-column uk-position-relative">
          <div className="uk-flex uk-flex-row ">
            <div className="uk-form-horizontal uk-width-1-2 padding-left-SelectionPan">
              <div className="border-container1">
                <div className="addBusPicture">
                  <button className="uk-button uk-button-default cameraButton">
                    <span
                      uk-icon="icon: acr-interface--image-placeholder"
                      className="cameraIcon iconCameraColor"
                    ></span>
                  </button>
                  <div className="lamp-committ-Icons1">
                    <button className="uk-button-default buttonMeasurements button1">
                      <span
                        uk-icon="icon: acr-interface-lamp"
                        className="iconColor"
                      ></span>
                    </button>
                    <button className="uk-button-default buttonMeasurements button2">
                      <span
                        uk-icon="icon: acr-interface-committ"
                        className="iconColor"
                      ></span>
                    </button>
                  </div>
                </div>
                <div className="busLine">
                  <div>
                    <form className="small-form">
                      <input
                        placeholder="-"
                        ref="nrOfBus"
                        className="uk-input uk-form-width inputBusName"
                      ></input>
                      <input
                        placeholder="-"
                        ref="nameOfBus"
                        className="uk-input uk-form-width inputBusName"
                      ></input>
                      <input
                        placeholder="-"
                        className="uk-input uk-form-width inputBusName"
                      ></input>
                      <input
                        placeholder="-"
                        className="uk-input uk-form-width inputBusName"
                      ></input>
                    </form>
                  </div>
                </div>
                <div className="lamp-committ-Icons3">
                  <button className="uk-button-default buttonMeasurements button1">
                    <span
                      uk-icon="icon: acr-interface-lamp"
                      className="iconColor"
                    ></span>
                  </button>
                  <button className="uk-button-default buttonMeasurements button2">
                    <span
                      uk-icon="icon: acr-interface-committ"
                      className="iconColor"
                    ></span>
                  </button>
                </div>
                <div className="applySaveButton">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      this.applyMessage();
                    }}
                    className="uk-button  button-black uk-button-small uk-margin-left applyButton"
                  >
                    Applica
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="uk-button  button-black uk-button-small uk-margin-left saveButton"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </div>
            <div className="uk-form-horizontal uk-width-1-2 padding-form-right">
              <div className="border-container">
                <div className="containerPreviewMessage">
                  <label className="previewMessage">ANTEPRIMA MESSAGGIO</label>
                </div>
                <div className="bus-panel-container uk-flex uk-flex-row uk-flex-between">
                  <PmvPanelBigIcon />
                  <PmvPanelBigText text={this.state.busName} />
                </div>
                {/* <div className="busMessage"> */}
                {/* <div className="imageBus">Bus Image</div> */}
                {/* <div className="nrBus">{this.state.busNr}</div>
                  <div className="nameBus">{this.state.busName}</div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-flex uk-flex-row uk-flex-right bottomButtons">
            <div className="">
              <button className="uk-button uk-button-default uk-button-small annullaButton">
                Annulla
              </button>
              <button className="uk-button  button-black uk-button-small uk-margin-left sendButton">
                Invia
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectionPanel)
);
