import { EventMenuActions } from "./event-menu.actions";

const INITIAL_STATE = {
  allEventVisible: false,
  activeVisible: false,
  plannedVisible: false,
  eventReportDetailOpen: false,
  eventHistoryDetailOpen: false,
  isEventsLoading: false,
  isEventsAllLoading: false,
};

export default function eventMenuReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EventMenuActions.SET_ALL_EVENT_VISIBILITY:
      return {
        ...state,
        allEventVisible: action.payload.visible,
        activeVisible: action.payload.visible,
        plannedVisible: action.payload.visible,
      };
    case EventMenuActions.SET_EVENT_VISIBILITY:
      return {
        ...state,
        allEventVisible: action.payload.visible,
      };
    case EventMenuActions.SET_ACTIVE_VISIBILITY:
      return {
        ...state,
        allEventVisible: state.plannedVisible && action.payload.visible,
        activeVisible: action.payload.visible,
      };
    case EventMenuActions.SET_PLANNED_VISIBILITY:
      return {
        ...state,
        allEventVisible: state.activeVisible && action.payload.visible,
        plannedVisible: action.payload.visible,
      };
    case EventMenuActions.TOGGLE_EVENT_REPORT_DETAIL:
      return {
        ...state,
        eventReportDetailOpen: action.payload,
        eventHistoryDetailOpen: false,
      };
    case EventMenuActions.TOGGLE_EVENT_HISTORY_DETAIL:
      return {
        ...state,
        eventReportDetailOpen: false,
        eventHistoryDetailOpen: action.payload,
      };
    case EventMenuActions.SET_IS_EVENT_LOADING:
      return {
        ...state,
        isEventsLoading: action.payload.isEventsLoading,
        isEventsAllLoading: action.payload.isEventsAllLoading,
      };
    default:
      return state;
  }
}

export const areAllEventVisible = (state) => state.allEventVisible;
export const isActiveVisible = (state) => state.activeVisible;
export const isPlannedVisible = (state) => state.plannedVisible;
export const isReportDetailOpen = (state) => state.eventReportDetailOpen;
export const isHistoryDetailOpen = (state) => state.eventHistoryDetailOpen;
export const isEventsLoading = (state) => state.isEventsLoading;
export const isEventsAllLoading = (state) => state.isEventsAllLoading;
