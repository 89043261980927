import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import "./style.less";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { setMinutes, setHours } from "date-fns";
import { take, tap, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
//STORE
import {
  isOpenNavTab,
  getTrafficMeasures,
  getUsername,
  getUserRoles,
} from "store";

import { addActivity } from "reducers/user/user.actions";
//COMPONENTS
import CustomisedTrafficDateTimePicker from "components/shared/customised-date-time-picker/customized-traffic-datetime-picker";
import graph_station from "assets/station-table/graph_station.svg";
import table_station from "assets/station-table/table_station.svg";
//UTILS
import { saveBlob } from "utils/hooks";

//MODELS
import { UserActivityModel } from "reducers/user/user.model";

import { getTimeRange } from "utils/util-station-graph";
class TableTrafficTopFilters extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
    };
  }

  downloadStationExcel = () => {
    const { dateStart, dateEnd, currentStation, defaultMeasure } = this.props;
    this.apiService = createApiService(this.context);
    let newDateStart = moment(dateStart);
    let newDateEnd = moment(dateEnd);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();
    let date = newDateStart.toISOString();

    this.apiService
      .fetchStationExcel(
        currentStation.stationId,
        date,
        differentTime,
        defaultMeasure.name
      )
      .pipe(
        tap((blob) => {
          let filename =
            "station_table_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".xlsx";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postStationDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postStationDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "TRAFFIC",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Resoconto Traffico"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  downloadTrafficExcel = () => {
    const {
      dateStart,
      dateEnd,
      currentArc,
      contexts,
      defaultMeasure,
      measures,
    } = this.props;
    this.apiService = createApiService(this.context);

    let newDateStart = moment(dateStart);
    let newDateEnd = moment(dateEnd);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();
    const profile = true;
    const vehicleClassesIds = ["equivalent"];
    let measure =
      measures.find((item) => item.measureName === defaultMeasure) &&
      measures.find((item) => item.measureName === defaultMeasure).measureId;

    let idsContexts = contexts.map((item) => {
      return item.contextId;
    });

    this.apiService
      .fetchTrafficExcel(
        currentArc.properties.archId,
        idsContexts,
        differentTime,
        measure,
        profile,
        dateStart.toISOString(),
        vehicleClassesIds
      )
      .pipe(
        tap((blob) => {
          let filename =
            "traffic_table_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postStationDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  render = () => {
    const {
      isOpenNav,
      dateStart,
      dateEnd,
      changeDateStart,
      changeDateEnd,
      defaultMeasure,
      handleSelectMeasure,
      isStartDate,
      isSameDate,
      isOpenTable,
      toggleTable,
      toggleStatistics,
      measures,
      refreshData,
      pickerMaxDateTime,
    } = this.props;
    let differentTime = getTimeRange(dateStart, new Date());

    return (
      <div className="select-time-style">
        <div className="select-style-top">
          <select
            className="uk-select select-style-sel"
            value={defaultMeasure ? defaultMeasure : ""}
            onChange={(e) => handleSelectMeasure(e.target.value)}
          >
            <option>{defaultMeasure}</option>;
            {measures &&
              measures
                .filter((item) => {
                  return (
                    (item.measureGroupType === "TRAFFIC_DATA" ||
                      item.measureGroupType === "TRAVEL_TIME_DATA") &&
                    item.measureId !== "flow-OUT" &&
                    item.measureId !== "flow-IN"
                  );
                })
                .map((item, index) => {
                  return defaultMeasure &&
                    defaultMeasure !== item.measureName ? (
                    <option key={index} value={item.measureName}>
                      {item.measureName}
                    </option>
                  ) : (
                    ""
                  );
                })}
          </select>
        </div>
        <div className="time-picker-style">
          <span className="center-label ">Dal:</span>
          <CustomisedTrafficDateTimePicker
            className="uk-form-small uk-padding-small select-style"
            value={dateStart}
            dateFormat={"dd/MM/yyyy HH:mm"}
            timeFormat={"HH:mm"}
            callbackFromParent={changeDateStart}
            iconRatio={isOpenNav ? "ratio: 0.8;" : "ratio: 1.5"}
            startDate={dateStart}
            endDate={dateEnd}
            isSameDate={isSameDate}
            startTime={dateStart}
            isTimeEnd={moment(dateStart).isSame(
              moment(new Date()).toDate(),
              "day"
            )}
            maxDate={pickerMaxDateTime}
            maxTime={
              moment(dateEnd).isSame(
                moment(pickerMaxDateTime).toDate(),
                "day"
              ) &&
              moment(dateEnd).isSame(
                moment(pickerMaxDateTime).toDate(),
                "month"
              ) &&
              moment(dateEnd).isSame(moment(pickerMaxDateTime).toDate(), "year")
                ? dateEnd
                : setHours(setMinutes(dateStart, 45), 23)
            }
          />
          <span className="center-label">al:</span>
          <CustomisedTrafficDateTimePicker
            className="uk-form-small uk-padding-small select-style"
            value={dateEnd}
            dateFormat={"dd/MM/yyyy HH:mm"}
            timeFormat={"HH:mm"}
            callbackFromParent={changeDateEnd}
            iconRatio={isOpenNav ? "ratio: 0.8;" : "ratio: 1.5"}
            startDate={dateStart}
            isSameDate={isSameDate}
            maxDate={moment(dateStart)
              .add(
                differentTime > 0 && differentTime <= 10080
                  ? moment(dateStart)
                      .add(differentTime / 60 / 24, "days")
                      .toDate()
                      .getDay() > new Date().getDay()
                    ? differentTime / 60 / 24 - 1
                    : moment(dateStart)
                        .add(differentTime / 60 / 24, "days")
                        .toDate()
                        .getDay() < new Date().getDay()
                    ? differentTime / 60 / 24 + 1
                    : differentTime / 60 / 24
                  : 7,
                "days"
              )
              .toDate()}
            isEndDate={isStartDate}
            startTime={dateStart}
            isTimeEnd={moment(dateEnd).isSame(
              moment(dateStart).add(7, "days").toDate(),
              "day"
            )}
            isTimeEndSameDay={moment(dateEnd).isSame(
              moment(new Date()).toDate(),
              "day"
            )}
            maxTime={
              moment(dateEnd).isSame(
                moment(pickerMaxDateTime).toDate(),
                "day"
              ) &&
              moment(dateEnd).isSame(
                moment(pickerMaxDateTime).toDate(),
                "month"
              ) &&
              moment(dateEnd).isSame(moment(pickerMaxDateTime).toDate(), "year")
                ? pickerMaxDateTime
                : setHours(setMinutes(dateEnd, 45), 23)
            }
          />
        </div>
        <div className="uk-button-group uk-margin-right height-button">
          <button
            className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
            type="button"
            onClick={() => toggleStatistics()}
          >
            <span uk-tooltip="Statistiche" className="filterBarIcons">
              <img
                className="station-icons"
                src={graph_station}
                alt="station-icons"
              ></img>
            </span>
          </button>
        </div>
        <div className="uk-button-group uk-margin-right height-button">
          <button
            className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
            type="button"
            // disabled={true}
            onClick={(e) => toggleTable()}
          >
            <span uk-tooltip="Tabella Stazioni" className="filterBarIcons">
              <img
                className="station-icons"
                src={table_station}
                alt="station-icons"
              ></img>
            </span>
          </button>
        </div>
        <div className="uk-button-group uk-margin-right height-button">
          <button
            className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
            onClick={() => refreshData()}
          >
            <span
              uk-icon="icon: refresh"
              uk-tooltip="Refresh statistiche traffico"
              className="filterBarIcons"
            ></span>
          </button>
        </div>
        <div className="uk-button-group uk-margin-right height-button">
          <button
            className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
            //onClick={this.resetFilters}
          >
            {isOpenTable ? (
              <span
                uk-icon="icon: download "
                uk-tooltip="Download Excel "
                className={"filterBarIcons "}
                onClick={() => this.downloadTrafficExcel()}
              ></span>
            ) : (
              <span
                uk-icon="icon: download "
                uk-tooltip={"Download Excel"}
                className="filterBarIcons cursor-disabled"
                // onClick={() => this.downloadStationExcel()}
              ></span>
            )}
          </button>
        </div>
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

TableTrafficTopFilters.contextType = EnvironmentContext;
const mapDispatchToProps = { addActivity };

const mapStateToProps = (state) => ({
  isOpenNav: isOpenNavTab(state),
  measures: getTrafficMeasures(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableTrafficTopFilters)
);
