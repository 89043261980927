export const TABLE_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: true,
    order: true,
  },
  usernames: {
    name: "Username",
    id: "usernames",
    childrenForHeaderDropdown: [],
    show: true,
    order: true,
  },
  // roles: {
  //   name: "Ruolo",
  //   id: "roles",
  //   show: true,
  //   order: true,
  // },
  modules: {
    name: "Ambito",
    id: "modules",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-modules",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Accesso",
        checked: true,
        id: "NOT_SPECIFIED",
        isChild: true,
        name: "Accesso",
      },
      {
        label: "Grafo Stradale",
        checked: true,
        id: "ROAD_GRAPH",
        isChild: true,
        name: "Grafo Stradale",
      },
      {
        label: "Trasporto Pubblico",
        checked: true,
        id: "PUBLIC_TRANSPORT",
        isChild: true,
        name: "Trasporto Pubblico",
      },
      {
        label: "Traffico",
        checked: true,
        id: "TRAFFIC",
        isChild: true,
        name: "Traffico",
      },
      {
        label: "Eventi",
        checked: true,
        id: "SITUATION",
        isChild: true,
        name: "Eventi",
      },
      {
        label: "Parcheggi",
        checked: true,
        id: "PARKING",
        isChild: true,
        name: "Parcheggi",
      },
      {
        label: "Stazioni di Misura",
        checked: true,
        id: "MEASUREMENT_STATIONS",
        isChild: true,
        name: "Stazioni di Misura",
      },
      {
        label: "Telecamere",
        checked: true,
        id: "CCTV",
        isChild: true,
        name: "Telecamere",
      },
      {
        label: "Pannello a Messaggio Variabile",
        checked: true,
        id: "VMS",
        isChild: true,
        name: "Pannello a Messaggio Variabile",
      },
      {
        label: "Altro",
        checked: true,
        id: "OTHER",
        isChild: true,
        name: "Altro",
      },
    ],
    show: true,
    order: true,
  },
  datetimeAction: {
    id: "datetimeAction",
    name: "Data e ora",
    show: true,
    order: true,
  },
  activityTypes: {
    name: "Tipo Azione",
    id: "activityTypes",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-types",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Login",
        checked: true,
        id: "USER_LOGIN",
        isChild: true,
        name: "Login",
      },
      {
        label: "Logout",
        checked: true,
        id: "USER_LOGOUT",
        isChild: true,
        name: "Logout",
      },
      {
        label: "Creazione",
        checked: true,
        id: "DATA_INSERTED",
        isChild: true,
        name: "Creazione",
      },
      {
        label: "Modifica",
        checked: true,
        id: "DATA_UPDATED",
        isChild: true,
        name: "Modifica",
      },
      {
        label: "Cancellazione",
        checked: true,
        id: "DATA_DELETED",
        isChild: true,
        name: "Cancellazione",
      },
      {
        label: "Export",
        checked: true,
        id: "DATA_EXPORTED",
        isChild: true,
        name: "Export",
      },
      {
        label: "Configurazione",
        checked: true,
        id: "CONFIG_UPDATED",
        isChild: true,
        name: "Configurazione",
      },
    ],
    show: true,
    order: true,
  },
  details: {
    name: "Dettagli",
    id: "details",
    show: true,
    order: false,
  },
};

export const DATE_COLUMNS = [
  {
    id: "datetimeAction",
    name: "Data e ora azione",
    selected: true,
  },
];

export const initColumns = (tableColumns = null, dateColumns = null) => {
  let columns = {};
  let date = [];
  if (
    !tableColumns ||
    (tableColumns &&
      Object.keys(TABLE_COLUMNS)[1] !== Object.keys(tableColumns)[1])
  ) {
    columns = { ...TABLE_COLUMNS };
    date = [...DATE_COLUMNS];
  } else {
    columns = { ...tableColumns };
    date = dateColumns ? [...dateColumns] : [];
  }

  return { columns: columns, date: date };
};
