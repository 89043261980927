import React, { Component } from "react";

class CloseArcsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderTableHead = () => {
    return (
      <thead className="event-arc-header-small">
        <tr>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Direzione
            </div>
          </th>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Nome Strada
            </div>
          </th>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Lunghezza [m]
            </div>
          </th>
        </tr>
      </thead>
    );
  };

  renderTableBody = () => {
    const { selectedArcToAddIndex, listOfCloseArcs, setArcToAdd } = this.props;

    return (
      <tbody>
        {listOfCloseArcs.map((item, index) => {
          return (
            <tr
              key={index}
              className={`event-arc-row-small ${
                index === selectedArcToAddIndex
                  ? "event-table-list-arc-active-row"
                  : null
              }`}
              onClick={(e) => {
                e.preventDefault();
                setArcToAdd(item, index);
              }}
            >
              <td>
                <div
                  className="uk-icon arc-direzione-icon"
                  style={{
                    transform: "rotate(" + item.properties.direction + "deg)",
                  }}
                  uk-icon="arrow-up"
                ></div>
              </td>
              <td>{item.properties.name}</td>
              <td>{item.properties.distance}</td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  render() {
    return (
      <table className="uk-table uk-table-divider uk-table-hover ">
        {this.renderTableHead()}
        {this.renderTableBody()}
      </table>
    );
  }
}

export default CloseArcsTable;
