import React, { Component } from "react";
import "./style.less";
import { of } from "rxjs";
import { tap, take, catchError } from "rxjs/operators";
import createApiService from "services/api.service";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";
//COMPONENTS
import ExpandedWorkspace from "./expanded-workspace";
import MinimisedWorkspace from "./minimised-workspace";
//model
import { PreferenceActivityModel } from "reducers/workspace/workspace.model";
//STORE
import { getUsername } from "store";
import {
  setWorkspaces,
  setCurrentWorkspace,
} from "reducers/workspace/workspace.actions";

class Workspace extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {
      isWsLoading: false,
      isWorkspaceExpanded: false,
    };
  }

  componentDidMount = () => {
    const { setWorkspaces, setCurrentWorkspace, username } = this.props;

    this.setState({
      ...this.state,
      isWsLoading: true,
    });

    this.apiService = createApiService(this.context);
    if (username) {
      this.subscriptions$.push(
        this.apiService
          .getWorkSpaces([username])
          .pipe(
            take(1),
            tap((x) => {
              this.setState({
                ...this.state,
                isWsLoading: false,
              });
              if (x && x.pageHits) {
                setWorkspaces(
                  PreferenceActivityModel.fromArrayREST(x.pageHits)
                );
                const defaultWs = x.pageHits.find((item) => item.isDefault);
                if (defaultWs) {
                  setCurrentWorkspace(defaultWs);
                }
              }
            }),
            catchError((error) => {
              this.setState({
                ...this.state,
                isWsLoading: false,
              });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  componentDidUpdate = (prevProps) => {
    const { setWorkspaces, setCurrentWorkspace, username } = this.props;

    if (prevProps.username !== username) {
      this.setState({
        ...this.state,
        isWsLoading: true,
      });

      this.subscriptions$.push(
        this.apiService
          .getWorkSpaces([username])
          .pipe(
            take(1),
            tap((x) => {
              this.setState({
                ...this.state,
                isWsLoading: false,
              });
              if (x && x.pageHits) {
                setWorkspaces(
                  PreferenceActivityModel.fromArrayREST(x.pageHits)
                );
                const defaultWs = x.pageHits.find((item) => item.isDefault);
                if (defaultWs) {
                  setCurrentWorkspace(defaultWs);
                }
              }
            }),
            catchError((error) => {
              this.setState({
                ...this.state,
                isWsLoading: false,
              });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  toggleWorkspaceDimensions = () => {
    const { isWorkspaceExpanded } = this.state;
    this.setState({
      ...this.state,
      isWorkspaceExpanded: !isWorkspaceExpanded,
    });
  };

  render() {
    const { isWorkspaceExpanded, isWsLoading } = this.state;

    return (
      <div>
        {isWorkspaceExpanded ? (
          <ExpandedWorkspace
            toggleWorkspaceDimensions={this.toggleWorkspaceDimensions}
            isWsLoading={isWsLoading}
          />
        ) : (
          <MinimisedWorkspace
            toggleWorkspaceDimensions={this.toggleWorkspaceDimensions}
          />
        )}
      </div>
    );
  }
}

Workspace.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setWorkspaces,
  setCurrentWorkspace,
};

const mapStateToProps = (state) => ({
  username: getUsername(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Workspace)
);
