import React, { Component } from "react";
import moment from "moment";
import "./style.less";

//ASSETS
import line_start from "assets/images/line/elements_line_start_vertical.svg";
import line_stop from "assets/images/line/elements_line_stop_vertical.svg";
import line_end from "assets/images/line/elements_line_end_vertical.svg";
import line_start_red from "assets/images/line/elements_line_start_vertical_red.svg";
import line_start_red_50 from "assets/images/line/elements_line_start_vertical_red_50.svg";
import line_stop_red from "assets/images/line/elements_line_stop_vertical_red.svg";
import line_stop_red_50 from "assets/images/line/elements_line_stop_vertical_red_50.svg";
import line_end_red from "assets/images/line/elements_line_end_vertical_red.svg";

class VehicleRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  header = () => {
    const { current, handleResetSelected } = this.props;
    return (
      <div
        id="floatingMenuTrip"
        className="uk-flex uk-flex-row uk-flex-between uk-flex-middle header"
      >
        <div className="uk-flex uk-flex-column uk-flex-middle">
          <div
            uk-icon="icon: acr-menu-routes-management"
            className="imgBusTripRoute colorMuted"
          ></div>
          <div className="text-small colorMuted">
            {current &&
            current.monitoredVehicleJourney &&
            current.monitoredVehicleJourney.vehicleJourneyName
              ? current.monitoredVehicleJourney.vehicleJourneyName
              : "-"}
          </div>
        </div>

        <div className="uk-flex uk-flex-column uk-flex-middle uk-width-1-1 uk-margin-left uk-margin-right">
          <div className="uk-flex uk-flex-left widthText textMedium">
            Linea{" "}
            {current &&
            current.monitoredVehicleJourney &&
            current.monitoredVehicleJourney.vehicleJourneyName
              ? current.monitoredVehicleJourney.publishedLineName
              : "-"}
          </div>
          <div className="uk-flex uk-flex-left widthText text-small">
            {current &&
            current.monitoredVehicleJourney &&
            current.monitoredVehicleJourney.destinationName
              ? current.monitoredVehicleJourney.destinationName
              : "-"}
          </div>
        </div>

        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            handleResetSelected();
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClickVehicleDetail } = this.props;
    return (
      <div className="uk-flex tabs ">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer text-title-tab"
            onClick={(event) => {
              event.preventDefault();
              handleClickVehicleDetail();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li className="pointer uk-active text-title-tab">
            <a href="/#">Percorso</a>
          </li>
        </ul>
      </div>
    );
  };

  trip = () => {
    const { current } = this.props;
    let percentageProgress = 0;
    let monitoredCallIndex = 0;
    var stopsList = [];
    if (current && current.monitoredVehicleJourney) {
      stopsList = stopsList
        .concat(
          current.monitoredVehicleJourney.previousCalls,
          current.monitoredVehicleJourney.monitoredCall,
          current.monitoredVehicleJourney.onwardCalls
        )
        .sort((a, b) => {
          return a.order - b.order;
        });
      monitoredCallIndex = current.monitoredVehicleJourney.previousCalls.length;
    }

    // MOCK
    /* let monitoredCallIndex = 4; 
    let percentageProgress = 0; */
    if (
      current &&
      current.progressBetweenStops &&
      current.progressBetweenStops.percentage &&
      (monitoredCallIndex === 0 || monitoredCallIndex + 1 === stopsList.length)
    ) {
      switch (true) {
        case current.progressBetweenStops.percentage <= 7:
          percentageProgress = 0;
          break;
        case 5 < current.progressBetweenStops.percentage &&
          current.progressBetweenStops.percentage <= 37:
          percentageProgress = 25;
          break;
        case 37 < current.progressBetweenStops.percentage &&
          current.progressBetweenStops.percentage <= 62:
          percentageProgress = 50;
          break;
        case 62 < current.progressBetweenStops.percentage &&
          current.progressBetweenStops.percentage <= 85:
          percentageProgress = 75;
          break;
        case 85 < current.progressBetweenStops.percentage:
          percentageProgress = 100;
          break;
        default:
          percentageProgress = 0;
      }
    }

    return (
      <div className="uk-list">
        {stopsList.map((stop, index) => {
          return (
            <div key={index}>
              <li
                className={`uk-flex uk-flex-row uk-flex-middle line-component-height uk-padding-remove ${
                  index === monitoredCallIndex && percentageProgress === 100
                    ? "selected-line-item"
                    : null
                }`}
              >
                <div
                  className={
                    index === monitoredCallIndex && percentageProgress === 100
                      ? "selected-line-circle"
                      : null
                  }
                ></div>
                <div className="line-image-dimension uk-margin-left">
                  <div>
                    {index === 0 ? (
                      <img alt="" src={line_start} key={index}></img>
                    ) : index === stopsList.length - 1 ? (
                      <img alt="" src={line_end} key={index}></img>
                    ) : (
                      <img alt="" src={line_stop} key={index}></img>
                    )}
                  </div>
                  <div id="progress-overlay">
                    {/* first stop */}
                    {index === 0 &&
                    monitoredCallIndex === 0 &&
                    percentageProgress >= 25 ? (
                      <img alt="" src={line_start_red} key={index}></img>
                    ) : null}
                    {index === 0 && monitoredCallIndex > 0 ? (
                      <img alt="" src={line_start_red_50} key={index}></img>
                    ) : null}
                    {/* last stop */}
                    {monitoredCallIndex + 1 === stopsList.length &&
                    index === stopsList.length - 1 &&
                    percentageProgress === 100 ? (
                      <img alt="" src={line_end_red} key={index}></img>
                    ) : null}
                    {/* progressions */}
                    {monitoredCallIndex > index &&
                    index !== 0 &&
                    index !== stopsList.length - 1 ? (
                      <img alt="" src={line_stop_red_50} key={index}></img>
                    ) : null}
                    {monitoredCallIndex === index &&
                    index !== 0 &&
                    index !== stopsList.length - 1 ? (
                      <img alt="" src={line_stop_red} key={index}></img>
                    ) : null}
                    {/* {monitoredCallIndex === index &&
                    percentageProgress >= 50 &&
                    index !== 0 &&
                    index !== stopsList.length - 1 ? (
                      <img alt="" src={line_stop_red} key={index}></img>
                    ) : null}
                    {monitoredCallIndex === index &&
                    percentageProgress === 25 &&
                    index !== 0 &&
                    index !== stopsList.length - 1 ? (
                      <div className="crop-progress-25">
                        <img alt="" src={line_stop_red} key={index}></img>{" "}
                      </div>
                    ) : null}
                    {monitoredCallIndex === index &&
                    percentageProgress === 75 &&
                    index !== 0 &&
                    index !== stopsList.length - 1 ? (
                      <div className="crop-progress-75">
                        <img alt="" src={line_stop_red} key={index}></img>{" "}
                      </div>
                    ) : null}
                    {monitoredCallIndex === index &&
                    percentageProgress === 100 &&
                    index !== 0 &&
                    index !== stopsList.length - 1 ? (
                      <div className="crop-progress-100">
                        <img alt="" src={line_stop_red} key={index}></img>
                      </div>
                    ) : null} */}
                  </div>
                </div>

                <div className="fermata-text uk-flex uk-flex-row uk-flex-left uk-margin-left">
                  {stop.stopPointName
                    ? stop.stopPointName
                    : "Fermata " + (index + 1)}
                </div>

                <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-right ">
                  <div className="text-programmed-hour">
                    {stop.aimedDepartureTime && index !== stopsList.length - 1
                      ? moment(stop.aimedDepartureTime).format("HH:mm")
                      : ""}
                    {stop.aimedArrivalTime && index === stopsList.length - 1
                      ? moment(stop.aimedArrivalTime).format("HH:mm")
                      : ""}
                  </div>
                  <div
                    className={
                      (stop.actualArrivalTime &&
                        index === stopsList.length - 1 &&
                        moment(stop.actualArrivalTime).isAfter(
                          moment(stop.aimedArrivalTime)
                        )) ||
                      (stop.actualDepartureTime &&
                        index !== stopsList.length - 1 &&
                        moment(stop.actualDepartureTime).isAfter(
                          moment(stop.aimedDepartureTime)
                        ))
                        ? "color-red"
                        : "text-arrival-hour"
                    }
                  >
                    {stop.actualArrivalTime && index === stopsList.length - 1
                      ? moment(stop.actualArrivalTime).format("HH:mm")
                      : stop.actualDepartureTime &&
                        index !== stopsList.length - 1
                      ? moment(stop.actualDepartureTime).format("HH:mm")
                      : ""}
                  </div>
                </div>
              </li>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div className="corse-line-container">
        {this.header()}
        {this.tabs()}
        {this.trip()}
      </div>
    );
  }
}
export default VehicleRoute;
