import React, { Component } from "react";

class PointTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ ...this.state, height: height, width: width });
  }

  render() {
    const { x, y, object } = this.props;
    const { height, width } = this.state;

    return (
      <div
        className="show-tooltip"
        ref={(divElement) => {
          this.divElement = divElement;
        }}
        style={{ left: x - width / 2, top: y - (height + 20) }}
      >
        <div className="uk-flex uk-flex-column uk-margin-small-top">
          <div className="uk-flex uk-flex-row uk-flex-middle ">
            <div className="tooltip-text">Nome: </div>
            <div className="tooltip-text-important tooltip-text-distance">
              {object && object.properties && object.properties.name1
                ? object.properties.name1
                : "-"}
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text">Tratta: </div>
            <div className="tooltip-text-important tooltip-text-distance">
              {object &&
              object.properties &&
              object.properties.road_number &&
              object.properties.name_road
                ? object.properties.road_number +
                  " " +
                  object.properties.name_road
                : "-"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PointTooltip;
