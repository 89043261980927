import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";
import {
  isCityBusVisible,
  isRegionBusVisible,
  isAllCamerasVisible,
  isParkingVisible,
  isPmvVisible,
  isActiveVisible,
  isPlannedVisible,
  isArcVisible,
  isNodeVisible,
  isCentroidVisible,
  isNetworkRdsTmcVisible,
  isStationVisible,
} from "store";
import { withRouter } from "react-router-dom";
//ASSETS
// import greenLine from "assets/icon/map/greenLine.svg";
// import yellowLine from "assets/icon/map/yellowLine.svg";
import redLine from "assets/icon/map/redLine.svg";
import blueLine from "assets/icon/map/blueLine.svg";

//EVENT ICON LEGEND
import event_altri_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Altri_Pericoli-BN.svg";
import event_altri_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Altri_Pericoli.svg";
import event_altri_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Altri_Pericoli-BN-CL.svg";
import event_altri_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Altri_Pericoli-CL.svg";

//NETWORK: GRAPH AND RETE RDS-TMC LEGEND
import icn_map_node from "assets/icon/graph/icn-map-point.svg";
import icn_map_centroid from "assets/icon/graph/acr-centroid_mod.svg";
import icn_map_arc from "assets/icon/graph/acr-arc.svg";
import icn_map_point from "assets/icon/graph/icn_map_pin_green.svg";

//  INSTALLATIONS
import { SENSOR_STATUS } from "utils/util-cameras";
import { PARKING_STATUS } from "utils/utils-parking";
import { PMV_STATUS } from "utils/utils-pmv";
import { STATION_STATUS } from "utils/utils-traffic";

const mapStateToProps = (state) => ({
  cityBusChecked: isCityBusVisible(state),
  regionBusChecked: isRegionBusVisible(state),
  //impianti
  cameraChecked: isAllCamerasVisible(state),
  parkingChecked: isParkingVisible(state),
  pmvChecked: isPmvVisible(state),
  stationChecked: isStationVisible(state),
  //eventi
  activeChecked: isActiveVisible(state),
  plannedChecked: isPlannedVisible(state),

  //Network: graph and rete rds tmc
  arcChecked: isArcVisible(state),
  nodechecked: isNodeVisible(state),
  centroidChecked: isCentroidVisible(state),
  networkRdsTmcChecked: isNetworkRdsTmcVisible(state),
});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class HookLegend extends Component {
  renderTplEventsInstallationsLegend = () => {
    const severity = [
      { severity: "highest" },
      { severity: "high" },
      { severity: "medium" },
      { severity: "low" },
      { severity: "lowest" },
      { severity: "unknown" },
    ];

    const {
      layer,
      toggleLegend,
      regionBusChecked,
      cityBusChecked,
      viewport,
      cameraChecked,
      parkingChecked,
      pmvChecked,
      activeChecked,
      plannedChecked,
      arcChecked,
      nodechecked,
      centroidChecked,
      networkRdsTmcChecked,
      stationChecked,
    } = this.props;

    let mapHeight = 800;
    let mapDiv = document.getElementById("view-basemap");
    if (mapDiv) {
      mapHeight = mapDiv.clientHeight;
    }

    return (
      <div
        className="uk-width-1-1 uk-card uk-card-primary uk-card-body uk-padding-small uk-overflow-auto"
        style={{ maxHeight: mapHeight - 200 }}
      >
        <div className="uk-flex uk-flex-row uk-flex-middle uk-width-1-1">
          <h5 className="uk-width-1-2 uk-margin-remove uk-card-title-small">
            LEGENDA
          </h5>
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            {/* <span
              className="uk-margin-small-right cursor-pointer"
              uk-icon="icon: acr-interface-new-window"
            ></span> */}
            <span
              className="cursor-pointer"
              uk-icon="icon: acr-interface-close"
              onClick={(event) => {
                event.preventDefault();
                return toggleLegend();
              }}
            ></span>
          </div>
        </div>

        <div className="uk-flex legend-content uk-margin-left uk-margin-right uk-margin-bottom">
          <div
            className={
              activeChecked &&
              plannedChecked &&
              (cityBusChecked ||
                regionBusChecked ||
                nodechecked ||
                centroidChecked ||
                arcChecked ||
                networkRdsTmcChecked)
                ? "uk-width-1-2"
                : ""
            }
          >
            {(cityBusChecked || regionBusChecked) && (
              <div
                className={
                  !activeChecked &&
                  !plannedChecked &&
                  (cityBusChecked || regionBusChecked)
                    ? "uk-width-1-1"
                    : ""
                }
              >
                {cityBusChecked && viewport && viewport.zoom >= 14 && (
                  <div className="uk-flex uk-flex-row uk-flex-middle">
                    <div className="icon-container uk-flex uk-flex-center">
                      <img
                        alt=""
                        className="legend-img-medium"
                        src={DeckLayerUtil.getIconStop(["07.70"], false, layer)}
                      />
                    </div>
                    <p className="legend-text-small">Fermata Urbana</p>
                  </div>
                )}
                {regionBusChecked && viewport && viewport.zoom >= 14 && (
                  <div className="uk-flex uk-flex-row uk-flex-middle">
                    <div className="icon-container uk-flex uk-flex-center">
                      <img
                        alt=""
                        src={DeckLayerUtil.getIconStop(["07.01"], false, layer)}
                        className="legend-img-medium"
                      />
                    </div>
                    <p className="legend-text-small">Fermata Extraurbana</p>
                  </div>
                )}
                {cityBusChecked && viewport && viewport.zoom >= 14 && (
                  <div className="uk-flex uk-flex-row uk-flex-middle">
                    <div className="icon-container uk-flex uk-flex-center">
                      <img
                        alt=""
                        src={DeckLayerUtil.getIconStop(["07.70"], true, layer)}
                        className="legend-img-medium"
                      />
                    </div>
                    <p className="legend-text-small">
                      Fermata Urbana capolinea
                    </p>
                  </div>
                )}
                {regionBusChecked && viewport && viewport.zoom >= 14 && (
                  <div className="uk-flex uk-flex-row uk-flex-middle">
                    <div className="icon-container uk-flex uk-flex-center">
                      <img
                        alt=""
                        src={DeckLayerUtil.getIconStop(["07.01"], true, layer)}
                        className="legend-img-medium"
                      />
                    </div>
                    <p className="legend-text-small">
                      Fermata Extraurbana capolinea
                    </p>
                  </div>
                )}
                {(regionBusChecked || cityBusChecked) &&
                  viewport &&
                  viewport.zoom >= 14 && (
                    <div className="uk-flex uk-flex-row uk-flex-middle">
                      <div className="icon-container uk-flex uk-flex-center">
                        <img
                          alt=""
                          src={DeckLayerUtil.getIconStop(
                            ["07.70", "07.01"],
                            false,
                            layer
                          )}
                          className="legend-img-medium"
                        />
                      </div>
                      <p className="legend-text-small">
                        Fermata Urbana-Extraurbana
                      </p>
                    </div>
                  )}
                {(regionBusChecked || cityBusChecked) &&
                  viewport &&
                  viewport.zoom >= 14 && (
                    <div className="uk-flex uk-flex-row uk-flex-middle">
                      <div className="icon-container uk-flex uk-flex-center">
                        <img
                          alt=""
                          src={DeckLayerUtil.getIconStop(
                            ["07.70", "07.01"],
                            true,
                            layer
                          )}
                          className="legend-img-medium"
                        />
                      </div>
                      <p className="legend-text-small">
                        Fermata Urbana-Extraurbana capolinea
                      </p>
                    </div>
                  )}
                {cityBusChecked && (
                  <div className="uk-flex uk-flex-row uk-flex-middle">
                    <img alt="" src={redLine} className="legend-line" />
                    <p className="legend-text-small">Linea urbana</p>
                  </div>
                )}
                {regionBusChecked && (
                  <div className="uk-flex uk-flex-row uk-flex-middle">
                    <img alt="" src={blueLine} className="legend-line" />
                    <p className="legend-text-small">Linea extraurbana </p>
                  </div>
                )}
              </div>
            )}

            {(nodechecked ||
              centroidChecked ||
              arcChecked ||
              networkRdsTmcChecked) && (
              <div
                className={
                  !nodechecked &&
                  !centroidChecked &&
                  !arcChecked &&
                  !networkRdsTmcChecked &&
                  (cityBusChecked || regionBusChecked)
                    ? "uk-width-1-1"
                    : ""
                }
              >
                {nodechecked ||
                centroidChecked ||
                arcChecked ||
                networkRdsTmcChecked ? (
                  <div>
                    <div className="uk-flex uk-inline">
                      <img
                        alt=""
                        className="icon-event-altri uk-inline arc-icon-legend network-legend"
                        src={icn_map_arc}
                      ></img>
                      <p className="legend-text-small uk-inline">
                        Arco stradale.
                      </p>
                    </div>
                    <div className="uk-flex uk-inline">
                      <img
                        alt=""
                        className="icon-event-altri uk-inline network-legend"
                        src={icn_map_node}
                      ></img>
                      <p className="legend-text-small uk-inline">
                        Nodo stradale.
                      </p>
                    </div>
                    <div className="uk-flex uk-inline">
                      <img
                        alt=""
                        className="icon-event-altri uk-inline network-legend"
                        src={icn_map_centroid}
                      ></img>
                      <p className="legend-text-small uk-inline">Centroide.</p>
                    </div>
                    <div className="uk-flex uk-inline">
                      <img
                        alt=""
                        className="icon-event-altri uk-inline network-legend"
                        src={icn_map_point}
                      ></img>
                      <p className="legend-text-small uk-inline ">
                        Punto RDS-TMC.
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>

          {(activeChecked || plannedChecked) && (
            <div
              className={
                (activeChecked || plannedChecked) &&
                !cityBusChecked &&
                !regionBusChecked &&
                !nodechecked &&
                !centroidChecked &&
                !networkRdsTmcChecked
                  ? "uk-width-1-1"
                  : "uk-width-1-2"
              }
            >
              {activeChecked || plannedChecked ? (
                <div>
                  <div className="uk-flex uk-inline">
                    <img
                      alt=""
                      className="icon-event-altri uk-inline event-legend"
                      src={event_altri_active}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Evento attivo, probabilità di accadimento
                      &#60;&#60;certa&#62;&#62;.
                    </p>
                  </div>
                  <div className="uk-flex uk-inline">
                    <img
                      alt=""
                      className="icon-event-altri uk-inline event-legend"
                      src={event_altri_active_pr}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Evento attivo, probabilità di accadimento non &#60;&#60;
                      certa&#62;&#62;.
                    </p>
                  </div>
                  <div className="uk-flex uk-inline">
                    <img
                      alt=""
                      className="icon-event-altri uk-inline event-legend"
                      src={event_altri_scheduled}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Evento pianificato, probabilità di accadimento &#60;&#60;
                      certa&#62;&#62;.
                    </p>
                  </div>
                  <div className="uk-flex uk-inline">
                    <img
                      alt=""
                      className="icon-event-altri uk-inline event-legend"
                      src={event_altri_scheduled_pr}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Evento pianificato, probabilità di accadimento non
                      &#60;&#60; certa&#62;&#62;.
                    </p>
                  </div>
                  <div className="uk-flex uk-inline img-margin">
                    <img
                      alt=""
                      className="legend-img-medium legend-img-severity uk-inline event-legend"
                      src={DeckLayerUtil.getIconSeverityEvents(severity[0])}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Severità molto alta.
                    </p>
                  </div>
                  <div className="uk-flex uk-inline">
                    <img
                      alt=""
                      className="legend-img- legend-img-severity uk-inline event-legend"
                      src={DeckLayerUtil.getIconSeverityEvents(severity[1])}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Severità alta.
                    </p>
                  </div>
                  <div className="uk-flex uk-inline">
                    <img
                      alt=""
                      className="legend-img-medium legend-img-severity uk-inline event-legend"
                      src={DeckLayerUtil.getIconSeverityEvents(severity[2])}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Severità media.
                    </p>
                  </div>
                  <div className="uk-flex uk-inline">
                    <img
                      alt=""
                      className="legend-img-medium legend-img-severity uk-inline event-legend"
                      src={DeckLayerUtil.getIconSeverityEvents(severity[3])}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Severità bassa.
                    </p>
                  </div>
                  <div className="uk-flex uk-inline">
                    <img
                      alt=""
                      className="legend-img-medium legend-img-severity uk-inline event-legend"
                      src={DeckLayerUtil.getIconSeverityEvents(severity[4])}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Severità molto bassa.
                    </p>
                  </div>
                  <div className="uk-flex uk-inline">
                    <img
                      alt=""
                      className="legend-img-medium legend-img-severity uk-inline event-legend"
                      src={DeckLayerUtil.getIconSeverityEvents(severity[5])}
                    ></img>
                    <p className="legend-text-small uk-inline">
                      Severità sconosciuta.
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          {(cameraChecked ||
            parkingChecked ||
            pmvChecked ||
            stationChecked) && (
            <div
              className={
                (cameraChecked || parkingChecked) &&
                !cityBusChecked &&
                !regionBusChecked
                  ? "uk-width-1-1"
                  : ""
              }
            >
              <div>
                {stationChecked &&
                  STATION_STATUS.map((status, index) => {
                    return (
                      <div className="uk-flex uk-inline img-margin" key={index}>
                        <img
                          alt=""
                          className="icon-event-altri uk-inline event-legend"
                          src={status && status.icon}
                        ></img>
                        <p className="legend-text-small uk-inline">
                          {status.name}
                        </p>
                      </div>
                    );
                  })}
              </div>
              <div>
                {cameraChecked &&
                  SENSOR_STATUS.map((status, index) => {
                    return (
                      <div className="uk-flex uk-inline img-margin" key={index}>
                        <img
                          alt=""
                          className="icon-event-altri uk-inline event-legend"
                          src={status && status.icon}
                        ></img>
                        <p className="legend-text-small uk-inline">
                          {status.name}
                        </p>
                      </div>
                    );
                  })}
              </div>
              <div>
                {parkingChecked &&
                  PARKING_STATUS.map((status, index) => {
                    return (
                      <div className="uk-flex uk-inline img-margin" key={index}>
                        <img
                          alt=""
                          className="icon-event-altri uk-inline event-legend"
                          src={status && status.icon}
                        ></img>
                        <p className="legend-text-small uk-inline">
                          {status.name}
                        </p>
                      </div>
                    );
                  })}
                {pmvChecked &&
                  PMV_STATUS.map((status, index) => {
                    return (
                      <div className="uk-flex uk-inline img-margin" key={index}>
                        <img
                          alt=""
                          className="icon-event-altri uk-inline event-legend"
                          src={status && status.icon}
                        ></img>
                        <p className="legend-text-small uk-inline">
                          {status.name}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  // renderStandardLegend = () => {
  //   const { toggleLegend } = this.props;

  //   return (
  //     <div className="uk-width-1-1 uk-height-1-1 uk-card uk-card-primary uk-card-body uk-padding-small">
  //       <div className="uk-flex uk-flex-row uk-flex-middle">
  //         <h5 className="uk-width-1-2 uk-margin-remove uk-card-title-small">
  //           LEGENDA
  //         </h5>
  //         <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
  //           {/* <span
  //             className="uk-margin-small-right cursor-pointer"
  //             uk-icon="icon: acr-interface-new-window"
  //           ></span> */}
  //           <span
  //             className="cursor-pointer"
  //             uk-icon="icon: acr-interface-close"
  //             onClick={(event) => {
  //               event.preventDefault();
  //               return toggleLegend();
  //             }}
  //           ></span>
  //         </div>
  //       </div>
  //       <div className="uk-flex uk-flex-column uk-padding uk-padding-remove-top">
  //         <div className="uk-flex uk-flex-row uk-flex-middle">
  //           <img alt="" src={greenLine} className="legend-line" />
  //           <p className="legend-text">
  //             La velocità media è superiore al valore tipico sul km di strada in
  //             oggetto
  //           </p>
  //         </div>
  //         <div className="uk-flex uk-flex-row uk-flex-middle">
  //           <img alt="" src={yellowLine} className="legend-line" />
  //           <p className="legend-text">
  //             La velocità media è leggermente inferiore al valore tipico sul km
  //             di strada in oggetto
  //           </p>
  //         </div>
  //         <div className="uk-flex uk-flex-row uk-flex-middle">
  //           <img alt="" src={redLine} className="legend-line" />
  //           <p className="legend-text">
  //             La velocità media è nettamente inferiore al valore tipico sul km
  //             di strada in oggetto
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  render = () => {
    const { isControlOpen } = this.props;

    return (
      <div
        className={`container-legend ${
          !isControlOpen ? "container-move-to-right" : ""
        }`}
      >
        {this.renderTplEventsInstallationsLegend()}
      </div>
    );
  };
}
export default HookLegend;
