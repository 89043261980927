import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//COMPONENTS
import Permission from "components/shared/permission";
//STORE AND ACTIONS
import { isParkingVisible } from "store";

const mapStateToProps = (state) => ({
  isParkingVisible: isParkingVisible(state),
});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemInstallationParking extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isParkingVisible,
      clickOnParkingCheckbox,
      clickOnParkingLabel,
    } = this.props;

    return (
      <Permission actions="PARKING">
        <div className="uk-checkbox-title uk-open acr-stations-open">
          <span>
            <input
              className="uk-checkbox uk-checkbox-large "
              type="checkbox"
              checked={isParkingVisible || false}
              onChange={() => clickOnParkingCheckbox()}
            />
          </span>
          <label
            className="checkbox-stations"
            onClick={() => clickOnParkingLabel()}
          >
            Parcheggi
          </label>
        </div>
      </Permission>
    );
  }
}

export default NavItemInstallationParking;
