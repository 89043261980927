import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
import createApiService from "services/api.service";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import EnvironmentContext from "environment-context";
import * as turf from "@turf/turf";
//COMPONENT
import ModalMap from "components/layout/modal/event/new-event/modal-map";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import DetailsLocalizationTable from "components/layout/modal/event/new-event/localization-tab/details-localization-table";
import CloseArcsTable from "./close-arcs-table";
//ACTIONS
import {
  getNewEvent,
  getNewEventValid,
  getCurrentEvent,
  getTypeModal,
  getBoundingBoxCartografica,
} from "store";
import { setNewEvent, updateNewEvent } from "reducers/modal/modal.actions";
import {
  enableForwardEventButton,
  disableForwardEventButton,
} from "reducers/ui/modal/modal.actions";
// MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";

class LocalizationCartograficaPuntuale extends Component {
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {
      listOfCloseArcs: [],
      filteredListOfCloseArcs: [],
      selectedArcToAddIndex: null,
      selectedArcPuntuale: null,
      filterText: "",
      modalMapWidth: true,
      isConfirmedArcPuntualeClicked: false,
    };
  }

  componentDidMount() {
    const { newEvent } = this.props;

    // INITIALISE STATE FROM REDUCER
    if (newEvent) {
      this.setState({
        ...this.state,
        filterText: "",
        listOfCloseArcs: newEvent.listOfCloseArcsPuntuale,
        filteredListOfCloseArcs: newEvent.listOfCloseArcsPuntuale,
        selectedArcPuntuale: newEvent.selectedArcPuntuale,
        selectedArcToAddIndex: newEvent.selectedArcToAddIndexPuntuale,
      });
    }

    this.apiService = createApiService(this.context);
  }

  componentDidUpdate = (prevProps) => {
    const { newEvent, typeModal, setNewEvent } = this.props;
    if (
      (newEvent &&
        prevProps.newEvent &&
        prevProps.newEvent.positionPuntuale &&
        newEvent.positionPuntuale &&
        prevProps.newEvent.positionPuntuale !== newEvent.positionPuntuale &&
        newEvent.positionPuntuale.longitude &&
        newEvent.positionPuntuale.latitude) ||
      (!prevProps.newEvent.positionPuntuale &&
        newEvent.positionPuntuale &&
        newEvent.positionPuntuale.longitude &&
        newEvent.positionPuntuale.latitude)
    ) {
      this.setState({
        ...this.state,
        filterText: "",
      });
      let center = [
        newEvent.positionPuntuale.longitude,
        newEvent.positionPuntuale.latitude,
        newEvent.positionPuntuale.longitude,
        newEvent.positionPuntuale.latitude,
      ];
      let radius = 50; // in meters
      var options = {
        steps: 5,
        units: "meters",
        options: {},
      };
      var polygon = turf.circle(center, radius, options);
      const bbox = turf.bbox(polygon);
      this.getApiPuntualeList(bbox);
    }

    if (
      typeModal === "modifyEvent" &&
      prevProps.newEvent.confirmedArcPuntuale !==
        this.props.newEvent.confirmedArcPuntuale
    ) {
      setNewEvent("checkIfEventIsModified", true);
    }
  };

  getApiPuntualeList = (point) => {
    if (point) {
      let bbox = point.map((item) => String(item)).join(",");
      bbox = bbox.concat(",EPSG:4326");

      this.subscriptions.push(
        this.apiService
          .getGeoserverFeatures("road_sections", null, null, null, null, bbox)
          .pipe(
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "road_sections"
              );
              let road_sections = [];
              if (featureCollection.numberReturned > 0) {
                road_sections = [...featureCollection.features];
              }
              //ADD RADIUS OF INFLUENCE TO PROPERTIES
              road_sections = road_sections.map((item) => {
                return {
                  ...item,
                  properties: {
                    ...item.properties,
                    radiusOfInfluence: 1000,
                  },
                };
              });
              this.updateCloseArcList(road_sections);
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  changeRadiusPuntuale = (event) => {
    const { setNewEvent, newEvent } = this.props;
    let newList = newEvent.confirmedArcPuntuale;
    newList.properties.radiusOfInfluence = event.target.value;

    setNewEvent("confirmedArcPuntuale", newList);
    this.setState({
      confirmedArcPuntuale: newList,
    });
  };

  updateCloseArcList = (road_sections) => {
    const { newEvent, setNewEvent } = this.props;
    this.setState({
      ...this.state,
      listOfCloseArcs: road_sections,
      filteredListOfCloseArcs: road_sections,
      selectedArcPuntuale: null,
      selectedArcToAddIndex: null,
    });

    if (newEvent) {
      setNewEvent("listOfCloseArcsPuntuale", road_sections);
      setNewEvent("selectedArcPuntuale", null);
      setNewEvent("selectedArcToAddIndexPuntuale", null);
    }
  };

  disableConfirmButton = () => {
    const { newEvent } = this.props;
    //DISABLE CONFIRM BUTTON IF NO ARC TO ADD
    if (
      (newEvent &&
        newEvent.isConfirmedArcPuntualeClicked &&
        newEvent.confirmedArcPuntuale) ||
      (newEvent && newEvent.selectedArcPuntuale === null)
    ) {
      return true;
    } else {
      return false;
    }
  };

  onClickConfirmArc() {
    const { selectedArcPuntuale } = this.state;
    const { newEvent, setNewEvent } = this.props;

    if (selectedArcPuntuale) {
      //SELECTED ARC BECOMES CONFIRMED
      this.setState({
        ...this.state,
        isConfirmedArcPuntualeClicked: true,
        confirmedArcPuntuale: selectedArcPuntuale,
      });
      if (newEvent) {
        setNewEvent("isConfirmedArcPuntualeClicked", true);
        setNewEvent("confirmedArcPuntuale", selectedArcPuntuale);
      }
    } else {
      this.setState({
        ...this.state,
        isConfirmedArcPuntualeClicked: false,
      });
      setNewEvent("isConfirmedArcPuntualeClicked", false);
    }
  }

  onClickDeleteArcRow = (index) => {
    const { newEvent, setNewEvent } = this.props;

    this.setState({
      ...this.state,
      isConfirmedArcPuntualeClicked: false,
      confirmedArcPuntuale: null,
    });

    if (newEvent) {
      setNewEvent("isConfirmedArcPuntualeClicked", false);
      setNewEvent("confirmedArcPuntuale", null);
    }
  };

  setArcToAdd = (arc, index) => {
    const { setNewEvent } = this.props;
    this.setState({
      ...this.state,
      selectedArcPuntuale: arc,
      selectedArcToAddIndex: index,
    });
    setNewEvent("selectedArcPuntuale", arc);
    setNewEvent("selectedArcToAddIndexPuntuale", index);
  };

  filterArcList = (e) => {
    const { listOfCloseArcs } = this.state;

    let filterString = e.target.value.toLowerCase();
    let filteredList = [...listOfCloseArcs];

    filteredList = filteredList.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.arcid &&
          item.properties.arcid.toString() &&
          item.properties.arcid
            .toString()
            .toLowerCase()
            .includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.name &&
          item.properties.name.toLowerCase().includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.direzione &&
          item.properties.direzione.toLowerCase().includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.distance.toString() &&
          item.properties.distance
            .toString()
            .toLowerCase()
            .includes(filterString))
    );

    this.setState({
      ...this.state,
      filterText: filterString,
      filteredListOfCloseArcs: filteredList,
    });
  };

  disableForwardButton = () => {
    const {
      enableForwardEventButton,
      disableForwardEventButton,
      newEventValid,
    } = this.props;

    if (newEventValid.positionPuntuale || newEventValid.confirmedArcPuntuale) {
      return enableForwardEventButton();
    } else return disableForwardEventButton();
  };

  cancelSearchBar = () => {
    document.getElementById("form-cerca").value = "";
    const { listOfCloseArcs } = this.state;

    let filterString = document
      .getElementById("form-cerca")
      .value.toLowerCase();
    let filteredList = [...listOfCloseArcs];

    filteredList = filteredList.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.arcid &&
          item.properties.arcid.toString() &&
          item.properties.arcid
            .toString()
            .toLowerCase()
            .includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.name &&
          item.properties.name.toLowerCase().includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.direzione &&
          item.properties.direzione.toLowerCase().includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.distance.toString() &&
          item.properties.distance
            .toString()
            .toLowerCase()
            .includes(filterString))
    );

    this.setState({
      ...this.state,
      filterText: filterString,
      filteredListOfCloseArcs: filteredList,
    });
  };

  render() {
    const { newEvent } = this.props;
    const {
      modalMapWidth,
      filteredListOfCloseArcs,
      filterText,
      selectedArcToAddIndex,
    } = this.state;

    this.disableForwardButton();
    return (
      <div className="uk-flex uk-flex-column uk-position-relative height-90">
        <div className="uk-flex uk-flex-row">
          <ErrorBoundary>
            <div className="uk-width-2-3 uk-flex uk-margin-medium-left	mapBoundary">
              <ModalMap
                modeswitch="puntuale"
                modalMapWidth={modalMapWidth}
              ></ModalMap>
            </div>
          </ErrorBoundary>
          <div className="uk-width-1-3 uk-flex uk-margin-large-right uk-flex-column ">
            <div className="uk-width-1-1 uk-flex uk-flex-left uk-inline">
              {filterText !== "" ? (
                <a
                  href="/#"
                  className="uk-form-icon uk-form-icon-flip"
                  uk-icon="icon: close"
                  onClick={(event) => {
                    event.preventDefault();
                    this.cancelSearchBar();
                  }}
                >
                  {""}
                </a>
              ) : (
                ""
              )}
              <input
                id="form-cerca"
                type="text"
                className="uk-input uk-form-small"
                placeholder="Cerca"
                value={filterText}
                onChange={this.filterArcList}
              />
            </div>
            <div className="event-table-list-arc uk-flex uk-width-1-1 uk-margin-small-top">
              <ErrorBoundary>
                <CloseArcsTable
                  listOfCloseArcs={
                    newEvent &&
                    newEvent.listOfCloseArcsPuntuale &&
                    newEvent.listOfCloseArcsPuntuale.length > 0
                      ? filteredListOfCloseArcs
                      : []
                  }
                  setArcToAdd={this.setArcToAdd}
                  selectedArcToAddIndex={selectedArcToAddIndex}
                />
              </ErrorBoundary>
            </div>
            <div className="uk-width-1-1 uk-flex uk-margin-small-top uk-flex-right">
              <button
                className="uk-button uk-button-default uk-button-small uk-margin-left"
                disabled={this.disableConfirmButton()}
                onClick={(e) => {
                  e.preventDefault();
                  this.onClickConfirmArc();
                }}
              >
                Conferma Arco
              </button>
            </div>
          </div>
        </div>
        <ErrorBoundary>
          <DetailsLocalizationTable
            onClickDeleteArcRow={this.onClickDeleteArcRow}
            onClickCompletaPercorso={this.onClickCompletaPercorso}
            changeRadiusPuntuale={this.changeRadiusPuntuale}
          />
        </ErrorBoundary>
      </div>
    );
  }

  componentWillUnmount = () => {
    this.subscriptions.forEach((sub$) => sub$.unsubscribe());
  };
}
LocalizationCartograficaPuntuale.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNewEvent,
  updateNewEvent,
  enableForwardEventButton,
  disableForwardEventButton,
};

const mapStateToProps = (state) => ({
  newEvent: getNewEvent(state),
  currentEvent: getCurrentEvent(state),
  newEventValid: getNewEventValid(state),
  boundingBox: getBoundingBoxCartografica(state),
  typeModal: getTypeModal(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocalizationCartograficaPuntuale)
);
