import { INITIAL_STATE_MODAL } from "./modal.model";
import { ModalActions } from "./modal.actions";

export default function modalReducer(state = INITIAL_STATE_MODAL, action) {
  let newEventType = action.payload ? action.payload.type : null;
  switch (action.type) {
    case ModalActions.SET_NEW_EVENT:
      if (newEventType === "positionPuntuale") {
        return {
          ...state,
          newEvent: {
            ...state.newEvent,
            value: {
              ...state.newEvent.value,
              [newEventType]: action.payload.newEvent,
              historyPuntuale: {
                ...state.newEvent.value.historyPuntuale,
                prev: [
                  ...state.newEvent.value.historyPuntuale.prev,
                  { ...state.newEvent.value.positionPuntuale },
                ],
                next: [],
              },
            },
            valid: {
              ...state.newEvent.valid,
              [newEventType]: action.payload.newEvent ? true : false,
            },
          },
        };
      } else if (newEventType === "positionEstesa") {
        return {
          ...state,
          newEvent: {
            ...state.newEvent,
            value: {
              ...state.newEvent.value,
              [newEventType]: action.payload.newEvent,
              historyEstesa: {
                ...state.newEvent.value.historyEstesa,
                prev: [
                  ...state.newEvent.value.historyEstesa.prev,
                  { ...state.newEvent.value.positionEstesa },
                ],
                next: [],
              },
            },
            valid: {
              ...state.newEvent.valid,
              [newEventType]: action.payload.newEvent ? true : false,
            },
          },
        };
      } else if (newEventType === "eventAttributes") {
        if (action.payload.newEvent) {
          var index = action.payload.newEvent.attributeId;
          return {
            ...state,
            newEvent: {
              ...state.newEvent,
              value: {
                ...state.newEvent.value,
                [newEventType]:
                  state.newEvent.value.eventAttributesValues &&
                  state.newEvent.value.eventAttributesValues.length === 0
                    ? state.newEvent.value.eventAttributes
                      ? Array.isArray(action.payload.newEvent)
                        ? action.payload.newEvent.map((element, index) => ({
                            ...element,
                            newValue: element.attributeValue
                              ? element.attributeValue
                              : state.newEvent.value.eventAttributes &&
                                state.newEvent.value.eventAttributes[index]
                                  .newValue
                              ? state.newEvent.value.eventAttributes[index]
                                  .newValue
                              : null,
                            attributeValue: element.attributeValue
                              ? element.attributeValue
                              : state.newEvent.value.eventAttributes &&
                                state.newEvent.value.eventAttributes[index]
                                  .newValue
                              ? state.newEvent.value.eventAttributes[index]
                                  .newValue
                              : null,
                          }))
                        : state.newEvent.value.eventAttributes.map(
                            (element, currentIndex) =>
                              index === element.attributeId
                                ? (action.payload.newEvent = {
                                    ...action.payload.newEvent,
                                    newValue:
                                      action.payload.newEvent.attributeValue,
                                  })
                                : element
                          )
                      : action.payload.newEvent.map((element, index) => ({
                          ...element,
                          newValue: element.attributeValue
                            ? element.attributeValue
                            : null,
                        }))
                    : action.payload.newEvent.map((element, index) => ({
                        ...element,
                        newValue:
                          state.newEvent &&
                          state.newEvent.value &&
                          state.newEvent.value.eventAttributesValues &&
                          state.newEvent.value.eventAttributesValues.find(
                            (item) =>
                              item.attributeName === element.attributeName
                          ) &&
                          state.newEvent.value.eventAttributesValues.find(
                            (item) =>
                              item.attributeName === element.attributeName
                          ).attributeValue
                            ? state.newEvent.value.eventAttributesValues
                                .find(
                                  (item) =>
                                    item.attributeName === element.attributeName
                                )
                                .attributeValue.toString()
                            : null,
                        attributeValue:
                          state.newEvent.value &&
                          state.newEvent.value.eventAttributesValues &&
                          state.newEvent.value.eventAttributesValues.find(
                            (item) =>
                              item.attributeName === element.attributeName
                          ) &&
                          state.newEvent.value.eventAttributesValues.find(
                            (item) =>
                              item.attributeName === element.attributeName
                          ).attributeValue
                            ? state.newEvent.value.eventAttributesValues
                                .find(
                                  (item) =>
                                    item.attributeName === element.attributeName
                                )
                                .attributeValue.toString()
                            : null,
                      })),
                eventAttributesValues: [],
              },
              valid: {
                ...state.newEvent.valid,
                [newEventType]: action.payload.newEvent ? true : false,
              },
            },
          };
        } else {
          return {
            ...state,
            newEvent: {
              ...state.newEvent,
              value: {
                ...state.newEvent.value,
                [newEventType]: null,
              },
            },
          };
        }
      } else
        return {
          ...state,
          newEvent: {
            ...state.newEvent,
            value: {
              ...state.newEvent.value,
              [newEventType]: action.payload.newEvent,
            },
            valid: {
              ...state.newEvent.valid,
              [newEventType]: action.payload.newEvent ? true : false,
            },
          },
        };
    case ModalActions.RESET_NEW_EVENT:
      return {
        ...state,
        newEvent: {
          valid: INITIAL_STATE_MODAL.newEvent.valid,
          value: INITIAL_STATE_MODAL.newEvent.value,
        },
      };
    case ModalActions.UPDATE_NEW_EVENT:
      action.payload.newEvent.newEvent.value.associatedEvents =
        state.newEvent.value.associatedEvents;
      return {
        ...state,
        newEvent: {
          value: action.payload.newEvent.newEvent.value,
          valid: action.payload.newEvent.newEvent.valid,
        },
      };
    case ModalActions.SET_HOVERED_POINT:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          hovered: {
            ...state.hovered,
            hoveredPoint: action.payload,
          },
        },
      };
    case ModalActions.RESET_HOVERED_OBJECTS:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          hovered: {
            ...state.hovered,
            hoveredPoint: {
              object: "",
              x: null,
              y: null,
            },
          },
        },
      };
    case ModalActions.HISTORY_PREV_PUNTUALE: {
      let isPrev = [...state.newEvent.value.historyPuntuale.prev];
      if (isPrev && isPrev.length > 0) {
        let next = [
          ...state.newEvent.value.historyPuntuale.next,
          { ...state.newEvent.value.positionPuntuale },
        ];
        let statPrev = {
          ...state.newEvent.value.historyPuntuale.prev[
            state.newEvent.value.historyPuntuale.prev.length - 1
          ],
        };
        isPrev.pop();
        return {
          ...state,
          newEvent: {
            ...state.newEvent,
            value: {
              ...state.newEvent.value,
              positionPuntuale: statPrev,
              listOfCloseArcsPuntuale:
                !statPrev || !statPrev.latitude
                  ? []
                  : [...state.newEvent.value.listOfCloseArcsPuntuale],
              historyPuntuale: {
                ...state.newEvent.value.historyPuntuale,
                next,
                prev: isPrev,
              },
            },
            valid: {
              ...state.newEvent.valid,
              positionPuntuale: statPrev && statPrev.latitude ? true : false,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case ModalActions.HISTORY_PREV_ESTESA: {
      let isPrev = [...state.newEvent.value.historyEstesa.prev];
      if (isPrev && isPrev.length > 0) {
        let next = [
          ...state.newEvent.value.historyEstesa.next,
          { ...state.newEvent.value.positionEstesa },
        ];
        let statPrev = {
          ...state.newEvent.value.historyEstesa.prev[
            state.newEvent.value.historyEstesa.prev.length - 1
          ],
        };
        isPrev.pop();
        return {
          ...state,
          newEvent: {
            ...state.newEvent,
            value: {
              ...state.newEvent.value,
              listOfCloseArcsEstesa:
                !statPrev || !statPrev.latitude
                  ? []
                  : [...state.newEvent.value.listOfCloseArcsEstesa],
              positionEstesa: statPrev,
              historyEstesa: {
                ...state.newEvent.value.historyEstesa,
                next,
                prev: isPrev,
              },
            },
            valid: {
              ...state.newEvent.valid,
              positionEstesa: statPrev && statPrev.latitude ? true : false,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case ModalActions.SET_SOURCE_SELECT_MODAL:
      return {
        ...state,
        select: {
          ...state.select,
          sources: action.payload,
        },
      };
    case ModalActions.SET_TYPES_SUBTYPES_SELECT_MODAL:
      return {
        ...state,
        select: {
          ...state.select,
          types: action.payload,
        },
      };
    case ModalActions.SET_ATTRIBUTES_SELECT_MODAL:
      return {
        ...state,
        select: {
          ...state.select,
          attributes: action.payload,
        },
      };
    case ModalActions.SET_BOUNDS: {
      return {
        ...state,
        boundingBox: action.payload,
      };
    }

    default:
      return state;
  }
}

export const getNewEvent = (state) => state.newEvent.value;
export const getNewEventValid = (state) => state.newEvent.valid;
export const getSelectModal = (state) => state.select;
export const getHoveredObjectsModal = (state) => state.newEvent.hovered;
export const getBoundingBoxCartografica = (state) => state.boundingBox;
