export const TopbarActions = {
  TOGGLE_KPI: "UI/TOPBAR/TOGGLE_KPI",
  TOGGLE_DOWN: "UI/TOPBAR/TOGGLE_DOWN",
};

export const toggleKpiContainer = () => (dispatch) => {
  dispatch({
    type: TopbarActions.TOGGLE_KPI,
    payload: null,
  });
};
export const toggleKpiConfirm = () => (dispatch) => {
  dispatch({
    type: TopbarActions.TOGGLE_DOWN,
    payload: null,
  });
};
