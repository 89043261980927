export class TableFilterModel {
  tableColumns;
  dateColumns;
  dateFrom;
  dateTo;
  searchText;
  toggledFilters;

  constructor() {
    this.tableColumns = null;
    this.dateColumns = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.searchText = "";
    this.toggledFilters = [];
  }

  static fromDATA(obj) {
    var filters = new TableFilterModel();
    filters.tableColumns = obj.tableColumns ? obj.tableColumns : null;
    filters.dateColumns = obj.dateColumns ? obj.dateColumns : null;
    filters.dateFrom = obj.dateFrom ? obj.dateFrom : null;
    filters.dateTo = obj.dateTo ? obj.dateTo : null;
    filters.searchText = obj.searchText ? obj.searchText : "";
    filters.toggledFilters = obj.toggledFilters ? obj.toggledFilters : [];
    return filters;
  }
}
