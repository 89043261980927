import React, { Component } from "react";
import "./style.less";

class DrawTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      mapPolygonReducer,
      deleteLayer,
      controlsReducer,
      errorDrawing,
    } = this.props;

    return mapPolygonReducer && mapPolygonReducer.area ? (
      <div
        className="uk-card uk-card-primary 
              uk-flex uk-flex-column showtooltipDraw"
      >
        <span
          className="uk-flex uk-flex-right pointer"
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            deleteLayer();
          }}
        ></span>
        <div className="fontSize16">
          {mapPolygonReducer.area && (
            <div>
              Area:{" "}
              <span className="colorWhite left-tooltip-draw-container">
                {mapPolygonReducer.area} m²
              </span>
            </div>
          )}
          {mapPolygonReducer.radius && (
            <div>
              Raggio:{" "}
              <span className="colorWhite left-tooltip-draw-container">
                {mapPolygonReducer.radius} m
              </span>
            </div>
          )}
          {mapPolygonReducer.radius && (
            <div>
              Centro:{" "}
              <span className="colorWhite left-tooltip-draw-container">
                {Math.round(mapPolygonReducer.center[1] * 1000000) / 1000000 +
                  " - " +
                  Math.round(mapPolygonReducer.center[0] * 1000000) / 1000000}
              </span>
            </div>
          )}
        </div>
      </div>
    ) : controlsReducer.distanceSelected ||
      controlsReducer.circleSelected ||
      controlsReducer.polygonSelected ||
      controlsReducer.rulerSelected ||
      errorDrawing ? (
      <div
        className="uk-card uk-card-primary 
            uk-flex uk-flex-column showtooltipDraw"
      >
        {errorDrawing && "Cerchio non corretto. Riprovare"}
        {controlsReducer.circleSelected &&
          "Impostare il centro con un click sinistro del mouse. Una volta aggiunto il centro del cerchio sulla mappa, far scorrere il mouse verso l'esterno e cliccare sull'estremità del cerchio a seconda del raggio desiderato."}
        {controlsReducer.distanceSelected &&
          "Impostare i diversi punti di misurazione tramite click sinistro del mouse. Per terminare la misurazione, riselezionare l'ultimo punto immesso."}
        {controlsReducer.polygonSelected &&
          "Impostare gli angoli del poligono desiderati con un click sinistro del mouse. Per chiudere la figura, selezionare il primo vertice del poligono."}
        {controlsReducer.rulerSelected &&
          "Impostare i diversi punti di misurazione tramite click sinistro del mouse. Per terminare la misurazione, riselezionare l'ultimo punto immesso."}
      </div>
    ) : null;
  }
}

export default DrawTooltip;
