import { TrafficMenuActions } from "./traffic-menu.actions";

const INITIAL_STATE = {
  trafficVisible: false,
  typicalVisible: false,
  netStateVisible: false,
  timeSliderVisible: false,
  isTrafficLoading: false,
};

export default function trafficMenuReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TrafficMenuActions.SET_TRAFFIC_VISIBILITY:
      return {
        ...state,
        trafficVisible: action.payload.visible,
      };
    case TrafficMenuActions.SET_TYPICAL_VISIBILITY:
      return {
        ...state,
        typicalVisible: action.payload.visible,
        netStateVisible: !action.payload.visible,
      };
    case TrafficMenuActions.SET_NET_STATE_VISIBILITY:
      return {
        ...state,
        typicalVisible: !action.payload.visible,
        netStateVisible: action.payload.visible,
      };
    case TrafficMenuActions.RESET_TRAFFIC_VISIBILITY:
      return {
        ...state,
        trafficVisible: false,
        typicalVisible: false,
        netStateVisible: false,
      };
    case TrafficMenuActions.TOGGLE_OPEN_TIME_SLIDER:
      return {
        ...state,
        timeSliderVisible: action.payload,
      };
    case TrafficMenuActions.SET_IS_TRAFFIC_LOADING:
      return {
        ...state,
        isTrafficLoading: action.payload.isTrafficLoading,
      };
    default:
      return state;
  }
}
export const areAllTrafficVisible = (state) => state.trafficVisible;
export const isTypicalVisible = (state) => state.typicalVisible;
export const isNetStateVisible = (state) => state.netStateVisible;
export const isTimeSliderVisible = (state) => state.timeSliderVisible;
export const isTrafficLoading = (state) => state.isTrafficLoading;
