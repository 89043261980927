export const WsActions = {
  WS_OPEN: "WS_OPEN",
};

export const wsOpen = (type, isOpen) => (dispatch) => {
  dispatch({
    type: WsActions.WS_OPEN,
    payload: { type: type, isOpen: isOpen },
  });
};
