import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";
//UTILS
import { SENSOR_STATUS } from "utils/util-cameras";
import {
  checkAndStartStream,
  stop,
  isElementInViewport,
  CAMERA_WRAPPER_PAGE_ENUM,
} from "utils/utils-streaming";

//SERVICES
import createApiService from "services/api.service";
import { WebCamWrapper } from "components/shared/webcam-wrapper/webcam-wrapper";

class BottombarCameraPanel extends Component {

  apiService;

  constructor(props) {
    super(props);
    this.state = {
      videoObj: null,
      env: null
    };
  }

  componentDidMount = () => {
    this.apiService = createApiService(this.context)
    document
      .getElementById("bottombar-camera-dock")
      .addEventListener("scroll", () => {
        //LOGIC FOR VISIBILITY CHANGE OF VIDEO ELEMENT
        const { env } = this.state;
        if (this.state.videoObj && this.state.videoObj.videoEl) {
          let old_visible =
            this.state.videoObj.videoEl.getAttribute("isVisible") === "true";
          let new_visible = isElementInViewport(this.state.videoObj.videoEl);
          if (new_visible !== old_visible) {
            this.state.videoObj.videoEl.setAttribute(
              "isVisible",
              new_visible ? "true" : "false"
            );
            if (new_visible) {
              this.startStreaming();
            } else {
              const { streamingHlsEnabled, streamingHlsService } = env;
              stop(this, streamingHlsEnabled, this.apiService, streamingHlsService);
            }
          }
        }
      });

    this.startStreaming();
  };

  startStreaming = () => {
    const { cam } = this.props;

    fetch("/config.json")
      .then((res) => res.json())
      .then((environment) => {
        this.setState({
          ...this.state,
          env: environment,
        });
        checkAndStartStream(this, environment, cam, this.apiService, CAMERA_WRAPPER_PAGE_ENUM.BOTTOMBAR);
      });
  };

  componentDidUpdate(prevProps) {
    const { camerasOnMap, cam, pinnedCameras } = this.props;

    if (
      prevProps.camerasOnMap &&
      camerasOnMap &&
      camerasOnMap.length > prevProps.camerasOnMap.length
    ) {
      let isOnMap = camerasOnMap.find(
        (item) =>
          item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
          cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
      );
      let wasOnMap = prevProps.camerasOnMap.find(
        (item) =>
          item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
          cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
      );
    } else if (
      prevProps.camerasOnMap &&
      camerasOnMap &&
      camerasOnMap.length < prevProps.camerasOnMap.length
    ) {
      let isOnMap = camerasOnMap.find(
        (item) =>
          item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
          cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
      );
      let wasOnMap = prevProps.camerasOnMap.find(
        (item) =>
          item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
          cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
      );
      if (!isOnMap && wasOnMap) {
        this.startStreaming();
      }
    }
  }

  refreshStream = () => {
    const { cam } = this.props;
    const { env, videoObj } = this.state;
    if(env.streamingHlsEnabled && videoObj && videoObj.streamId) {
      stop(this, env.streamingHlsEnabled, this.apiService, env.streamingHlsService);
    }
    checkAndStartStream(this, env, cam, this.apiService, CAMERA_WRAPPER_PAGE_ENUM.BOTTOMBAR);
  };

  closeCameraPanel = () => {
    const { cam, removeCamera } = this.props;

    removeCamera(cam);
  };

  render() {
    const { cam, ind, camerasOnMap, openOnMap } = this.props;
    const { env } = this.state;

    let findIcon = SENSOR_STATUS.find(
      (status) =>
        cam &&
        cam.properties &&
        cam.properties.cctvWithLastStateAndOpenAlarms &&
        cam.properties.cctvWithLastStateAndOpenAlarms.last_state &&
        cam.properties.cctvWithLastStateAndOpenAlarms.last_state
          .diagnosticState &&
        typeof cam.properties.cctvWithLastStateAndOpenAlarms.last_state
          .diagnosticState === "string" &&
        status.name ===
        cam.properties.cctvWithLastStateAndOpenAlarms.last_state
          .diagnosticState
    );

    const isOnMap = camerasOnMap.find(
      (item) =>
        item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
        cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
    );

    return (
      <div key={ind} className="card-container">
        <div className="uk-card uk-card-secondary uk-flex uk-flex-column">
          <div className="uk-flex uk-flex-row card-title-container">
            <div className=" uk-width-4-5 uk-flex uk-flex-row uk-flex-middle uk-position-relative">
              <div className="uk-position-relative">
                <img
                  className={"img-status-bottombar"}
                  alt={"webcam_img"}
                  src={findIcon && findIcon.icon}
                />
              </div>
              <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-left camera-card-title">
                {cam &&
                  cam.properties &&
                  cam.properties.cctvWithLastStateAndOpenAlarms &&
                  cam.properties.cctvWithLastStateAndOpenAlarms &&
                  cam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                  cam.properties.cctvWithLastStateAndOpenAlarms.cctv.name
                  ? cam.properties.cctvWithLastStateAndOpenAlarms.cctv.name
                  : "-"}
              </div>
            </div>
            <div className=" uk-width-1-5 uk-flex uk-flex-right">
              <span
                className={
                  !isOnMap
                    ? "pointer uk-margin-small-right"
                    : "uk-disabled uk-margin-small-right"
                }
                uk-icon="icon: refresh"
                uk-tooltip="Ricarica Stream"
                onClick={() => this.refreshStream()}
              ></span>
              <span
                className={
                  !isOnMap
                    ? "pointer uk-margin-small-right"
                    : "uk-disabled uk-margin-small-right"
                }
                uk-icon="icon: acr-map-options-map-type"
                uk-tooltip="Apri su Mappa"
                onClick={() => {
                  const { env } = this.state;
                  stop(this, env.streamingHlsEnabled, this.apiService, env.streamingHlsService);
                  
                  openOnMap(cam)
                }}
                disabled={isOnMap}
              ></span>
              <span
                className="pointer"
                uk-icon="icon: acr-interface-close"
                uk-tooltip="Rimuovi"
                onClick={() => this.closeCameraPanel()}
              ></span>
            </div>
          </div>
          <div className="embed-responsive uk-flex uk-flex-center uk-flex-middle">
            {!isOnMap ? (
              env ? <WebCamWrapper
                videoURI={`${this.state.videoObj && this.state.videoObj.url || ''}`}
                cam={cam}
                origin={CAMERA_WRAPPER_PAGE_ENUM.BOTTOMBAR}
                hlsEnabled={env && env.streamingHlsEnabled}
              /> : <></>
            ) : (
              <div className="no-camera-grey-bg uk-flex uk-flex-middle uk-flex-center">
                <span uk-icon="icon: image; ratio: 2"></span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    const { env } = this.state;

    stop(this, env.streamingHlsEnabled, this.apiService, env.streamingHlsService, true);
  }
}
BottombarCameraPanel.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BottombarCameraPanel)
);
