import { selectFields } from "enums/selectable-params";
import {
  normalizingDateTimeEventModal,
  setValidityStatusEvent,
} from "utils/utils";
import * as turf from "@turf/turf";

export class SituationRecord {
  cause;
  generalPublicComment;
  groupOfLocations;
  impact;
  operatorAction;
  probabilityOfOccurrence;
  severity;
  situationRecordId;
  situationRecordType;
  source;
  traffic;
  validity;
  situationId;
  situationRecordCreationTime;
  situationRecordVersion;
  situationRecordVersionTime;
  situationRecordSubType;

  static from(obj) {
    let record = new SituationRecord();
    if (obj) {
      if (obj.situationId) record.situationId = obj.situationId;
      if (obj.situationRecordSubType)
        record.situationRecordSubType = obj.situationRecordSubType;
      if (obj.cause) record.cause = Cause.from(obj.cause);
      if (obj.generalPublicComment)
        record.generalPublicComment = Comments.fromArray(
          obj.generalPublicComment
        );
      if (obj.groupOfLocations)
        record.groupOfLocations = NetworkLocation.fromArray(
          obj.groupOfLocations
        );
      if (obj.impact) record.impact = Impact.from(obj.impact);
      if (obj.operatorAction)
        record.operatorAction = OperatorActionAttributes.from(
          obj.operatorAction
        );
      if (obj.probabilityOfOccurrence)
        record.probabilityOfOccurrence = obj.probabilityOfOccurrence;
      if (obj.severity) record.severity = obj.severity;
      if (obj.situationRecordCreationTime)
        record.situationRecordCreationTime = obj.situationRecordCreationTime;
      if (obj.situationRecordVersion)
        record.situationRecordVersion = obj.situationRecordVersion;
      if (obj.situationRecordVersionTime)
        record.situationRecordVersionTime = obj.situationRecordVersionTime;
      if (obj.situationRecordId)
        record.situationRecordId = obj.situationRecordId;
      if (obj.situationRecordType)
        record.situationRecordType = obj.situationRecordType;
      if (obj.source) record.source = Source.from(obj.source);
      if (obj.traffic) record.traffic = Traffic.from(obj.traffic);
      if (obj.validity) record.validity = Validity.from(obj.validity, true);
    }
    return record;
  }

  //FROM MODAL TO SITUATION RECORD MODEL
  static fromModal(
    obj,
    select,
    current,
    modal,
    currentLocalizationType,
    localizationRdsTmcCountryCode,
    localizationRdsTmcTableNumber,
    localizationRdsTmcTableVersion,
    username
  ) {
    let road = new SituationRecord();

    if (obj) {
      //ID EVENT
      if (obj.iDEvent) {
        road.situationRecordId = obj.iDEvent;
      }
      //TYPE EVENT
      if (obj.typeEvent) {
        road.situationRecordType = select.types[obj.typeEvent].typeName;
      }
      // CAUSE
      if (obj.typeEvent) {
        road.cause = Cause.from({
          situationRecordId: obj.iDEvent,
          situationRecordType: select.types[obj.typeEvent].typeName,
          situationRecordVersion: obj.situationRecordVersion
            ? obj.situationRecordVersion
            : current && current.situationRecordVersion
            ? current.situationRecordVersion
            : null,
        });
      }

      //TRAFFIC, ACTIVITY, CONDITIONS, EQUIPMENT SYSTEM OR FAULT, OBSTRUCTION, OPERATOR ACTION
      if (obj.typeEvent && obj.underTypeEvent) {
        road.traffic = {};
        switch (
          obj.typeEvent &&
          obj.typeEvent.toString() &&
          select.types[obj.typeEvent.toString()].typeName
        ) {
          case "ABNORMAL_TRAFFIC":
            road.traffic.abnormalTraffic = AbnormalTrafficAttributes.from({
              abnormalTrafficType:
                select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                  .subTypeName,
              relativeTrafficFlow:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "relativeTrafficFlow"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "relativeTrafficFlow"
                    ).newValue
                  : null,
              trafficTrendType:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "trafficTrendType"
                )
                  ? obj.eventAttributes.find(
                      (element) => element.attributeName === "trafficTrendType"
                    ).newValue
                  : null,
            });
            break;
          case "ACCIDENT":
            road.traffic.accident = AccidentAttributes.from({
              accidentType:
                select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                  .subTypeName,
              accidentCause:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "accidentCause"
                )
                  ? obj.eventAttributes.find(
                      (element) => element.attributeName === "accidentCause"
                    ).newValue
                  : null,
              totalNumberOfPeopleInvolved:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) =>
                    element.attributeName === "totalNumberOfPeopleInvolved"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "totalNumberOfPeopleInvolved"
                    ).attributeValue
                  : null,
              totalNumberOfVehiclesInvolved:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) =>
                    element.attributeName === "totalNumberOfVehiclesInvolved"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName ===
                        "totalNumberOfVehiclesInvolved"
                    ).attributeValue
                  : null,
            });
            break;
          case "ANIMAL_PRESENCE_OBSTRUCTION":
            road.traffic.obstruction = ObstructionAttributes.from({
              numberOfObstructions:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "numberOfObstructions"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "numberOfObstructions"
                    ).attributeValue
                  : null,
              animalPresenceObstruction: AnimalPresenceObstructionAttributes.from(
                {
                  animalPresenceObstructionType:
                    select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                      .subTypeName,
                  alive:
                    obj.eventAttributes &&
                    obj.eventAttributes.find(
                      (element) => element.attributeName === "alive"
                    ) &&
                    obj.eventAttributes.find(
                      (element) => element.attributeName === "alive"
                    ).newValue
                      ? obj.eventAttributes.find(
                          (element) => element.attributeName === "alive"
                        ).newValue === "FALSE"
                        ? false
                        : true
                      : null,
                }
              ),
            });
            break;
          case "DISTURBANCE_ACTIVITY":
            road.traffic.activity = ActivityAttributes.from({
              disturbanceActivity: DisturbanceActivityAttributes.from({
                disturbanceActivityType:
                  select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                    .subTypeName,
              }),
            });
            break;
          case "POOR_ENVIRONMENT_CONDITIONS":
            road.traffic.conditions = ConditionsAttributes.from({
              poorEnvironmentConditions: PoorEnvironmentConditionsAttributes.from(
                {
                  pollution: Pollution.from({
                    pollutantConcentration:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "pollutantConcentration"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "pollutantConcentration"
                          ).attributeValue
                        : null,
                    pollutantType:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "pollutantType"
                      ).attributeValue,
                  }),
                  humidity: Humidity.from({
                    relativeHumidity:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "relativeHumidity"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "relativeHumidity"
                          ).attributeValue
                        : null,
                  }),
                  poorEnvironmentType:
                    select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                      .subTypeName,
                  precipitationDetail: PrecipitationDetail.from({
                    precipitationIntensity:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "precipitationIntensity"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "precipitationIntensity"
                          ).attributeValue
                        : null,
                    precipitationType:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "precipitationType"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "precipitationType"
                          ).newValue
                        : null,
                  }),
                  temperature: Temperature.from({
                    airTemperature:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "airTemperature"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "airTemperature"
                          ).attributeValue
                        : null,
                    maximumTemperature:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "maximumTemperature"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "maximumTemperature"
                          ).attributeValue
                        : null,
                    minimumTemperature:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "minimumTemperature"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "minimumTemperature"
                          ).attributeValue
                        : null,
                  }),
                  visibility: Visibility.from({
                    minimunVisibilityDistance:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "minimunVisibilityDistance"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName ===
                              "minimunVisibilityDistance"
                          ).attributeValue
                        : null,
                  }),
                  wind: Wind.from({
                    directionCompass:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "directionCompass"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "directionCompass"
                          ).newValue
                        : null,
                    maximumWindSpeed:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "maximumWindSpeed"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "maximumWindSpeed"
                          ).attributeValue
                        : null,
                    windMeasurementHeight:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "windMeasurementHeight"
                      )
                        ? obj.eventAttributes.find(
                            (element) =>
                              element.attributeName === "windMeasurementHeight"
                          ).attributeValue
                        : null,
                    windSpeed:
                      obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "windSpeed"
                      )
                        ? obj.eventAttributes.find(
                            (element) => element.attributeName === "windSpeed"
                          ).attributeValue
                        : null,
                  }),
                }
              ),
              drivingConditionType:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "drivingConditionType"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "drivingConditionType"
                    ).newValue
                  : null,
            });
            break;
          case "AUTHORITY_OPERATION":
            road.traffic.activity = ActivityAttributes.from({
              authorityOperation: AuthorityOperationAttributes.from({
                authorityOperationType:
                  select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                    .subTypeName,
              }),
            });
            break;

          case "PUBLIC_EVENT":
            road.traffic.activity = ActivityAttributes.from({
              publicEvent: PublicEventAttributes.from({
                publicEventType:
                  select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                    .subTypeName,
              }),
            });
            break;

          case "WEATHER_RELATED_ROAD_CONDITIONS":
            road.traffic.conditions = ConditionsAttributes.from({
              drivingConditionType:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "drivingConditionType"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "drivingConditionType"
                    ).newValue
                  : null,
              weatherRelatedRoadConditions: WeatherRelatedRoadConditionsAttributes.from(
                {
                  weatherRelatedRoadConditionType:
                    select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                      .subTypeName,
                }
              ),
            });
            break;

          case "EQUIPMENT_OR_SYSTEM_FAULT":
            road.traffic.equipmentOrSystemFault = EquipmentOrSystemFaultAttributes.from(
              {
                equipmentOrSystemFaultType:
                  select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                    .subTypeName,
                faultyEquipmentOrSystemType:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) =>
                      element.attributeName === "faultyEquipmentOrSystemType"
                  )
                    ? obj.eventAttributes.find(
                        (element) =>
                          element.attributeName ===
                          "faultyEquipmentOrSystemType"
                      ).newValue
                    : null,
              }
            );
            break;

          case "GENERAL_OBSTRUCTION":
            road.traffic.obstruction = ObstructionAttributes.from({
              generalObstruction: GeneralObstructionAttributes.from({
                obstructionType:
                  select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                    .subTypeName,
              }),
              numberOfObstructions:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "numberOfObstructions"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "numberOfObstructions"
                    ).attributeValue
                  : null,
            });
            break;
          case "ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT":
            road.traffic = null;
            road.operatorAction = OperatorActionAttributes.from({
              actionOrigin:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "actionOrigin"
                )
                  ? obj.eventAttributes.find(
                      (element) => element.attributeName === "actionOrigin"
                    ).newValue
                  : null,
              actionPlanIdentifier:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "actionPlanIdentifier"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "actionPlanIdentifier"
                    ).attributeValue
                  : null,
              operatorActionStatus:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "operatorActionStatus"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "operatorActionStatus"
                    ).newValue
                  : null,
              networkManagement: NetworkManagement.from({
                applicableForTrafficDirection:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) =>
                      element.attributeName === "applicableForTrafficDirection"
                  )
                    ? obj.eventAttributes.find(
                        (element) =>
                          element.attributeName ===
                          "applicableForTrafficDirection"
                      ).newValue
                    : null,
                applicableForTrafficType:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) =>
                      element.attributeName === "applicableForTrafficType"
                  )
                    ? obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "applicableForTrafficType"
                      ).newValue
                    : null,
                complianceOption:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) => element.attributeName === "complianceOption"
                  )
                    ? obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "complianceOption"
                      ).newValue
                    : null,

                roadOrCarriagewayOrLaneManagement: RoadOrCarriagewayOrLaneManagement.from(
                  {
                    roadOrCarriagewayOrLaneManagementType:
                      select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                        .subTypeName,
                  }
                ),
              }),
            });
            break;
          case "MAINTENANCE_WORKS":
            road.traffic = null;
            road.operatorAction = OperatorActionAttributes.from({
              actionPlanIdentifier:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "actionPlanIdentifier"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "actionPlanIdentifier"
                    ).attributeValue
                  : null,
              actionOrigin:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "actionOrigin"
                )
                  ? obj.eventAttributes.find(
                      (element) => element.attributeName === "actionOrigin"
                    ).newValue
                  : null,
              roadworks: Roadworks.from({
                urgentRoadworks:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) => element.attributeName === "urgentRoadworks"
                  )
                    ? obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "urgentRoadworks"
                      ) &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "urgentRoadworks"
                      ).newValue
                      ? obj.eventAttributes.find(
                          (element) =>
                            element.attributeName === "urgentRoadworks"
                        ).newValue === "FALSE"
                        ? false
                        : true
                      : null
                    : null,
                roadworksScale:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) => element.attributeName === "roadworksScale"
                  )
                    ? obj.eventAttributes.find(
                        (element) => element.attributeName === "roadworksScale"
                      ).newValue
                    : null,
                underTraffic:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) => element.attributeName === "underTraffic"
                  )
                    ? obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "underTraffic"
                      ) &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "underTraffic"
                      ).newValue
                      ? obj.eventAttributes.find(
                          (element) => element.attributeName === "underTraffic"
                        ).newValue === "FALSE"
                        ? false
                        : true
                      : null
                    : null,
                roadworksDuration:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) => element.attributeName === "roadworksDuration"
                  )
                    ? obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "roadworksDuration"
                      ).newValue
                    : null,
                maintenanceWorks: MaintenanceWorkType.from({
                  maintenanceWorkType:
                    select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                      .subTypeName,
                }),
              }),
            });
            break;
          case "CONSTRUCTION_WORKS":
            road.traffic = null;
            road.operatorAction = OperatorActionAttributes.from({
              actionOrigin:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "actionOrigin"
                )
                  ? obj.eventAttributes.find(
                      (element) => element.attributeName === "actionOrigin"
                    ).newValue
                  : null,
              actionPlanIdentifier:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "actionPlanIdentifier"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "actionPlanIdentifier"
                    ).attributeValue
                  : null,
              operatorActionStatus:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "operatorActionStatus"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "operatorActionStatus"
                    ).newValue
                  : null,
              roadworks: Roadworks.from({
                roadworksDuration:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) => element.attributeName === "roadworksDuration"
                  )
                    ? obj.eventAttributes.find(
                        (element) =>
                          element.attributeName === "roadworksDuration"
                      ).newValue
                    : null,
                roadworksScale:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) => element.attributeName === "roadworksScale"
                  )
                    ? obj.eventAttributes.find(
                        (element) => element.attributeName === "roadworksScale"
                      ).newValue
                    : null,
                underTraffic:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) => element.attributeName === "underTraffic"
                  )
                    ? obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "underTraffic"
                      ) &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "underTraffic"
                      ).newValue
                      ? obj.eventAttributes.find(
                          (element) => element.attributeName === "underTraffic"
                        ).newValue === "FALSE"
                        ? false
                        : true
                      : null
                    : null,

                urgentRoadworks:
                  obj.eventAttributes &&
                  obj.eventAttributes.find(
                    (element) => element.attributeName === "urgentRoadworks"
                  )
                    ? obj.eventAttributes &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "urgentRoadworks"
                      ) &&
                      obj.eventAttributes.find(
                        (element) => element.attributeName === "urgentRoadworks"
                      ).newValue
                      ? obj.eventAttributes.find(
                          (element) =>
                            element.attributeName === "urgentRoadworks"
                        ).newValue === "FALSE"
                        ? false
                        : true
                      : null
                    : null,
                constructionWorks: ConstructionWorks.from({
                  constructionWorkType:
                    select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                      .subTypeName,
                }),
              }),
            });
            break;

          case "VEHICLE_OBSTRUCTION":
            road.traffic.obstruction = ObstructionAttributes.from({
              numberOfObstructions:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "numberOfObstructions"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "numberOfObstructions"
                    ).attributeValue
                  : null,
              vehicleObstruction: VehicleObstruction.from({
                vehicleObstructionType:
                  select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                    .subTypeName,
              }),
            });
            break;
          case "ENVIRONMENTAL_OBSTRUCTION":
            road.traffic.obstruction = ObstructionAttributes.from({
              numberOfObstructions:
                obj.eventAttributes &&
                obj.eventAttributes.find(
                  (element) => element.attributeName === "numberOfObstructions"
                )
                  ? obj.eventAttributes.find(
                      (element) =>
                        element.attributeName === "numberOfObstructions"
                    ).attributeValue
                  : null,
              environmentalObstruction: EnvironmentalObstructionAttributes.from(
                {
                  environmentalObstructionType:
                    select.types[obj.typeEvent].subTypes[obj.underTypeEvent]
                      .subTypeName,
                }
              ),
            });
            break;
          default:
            break;
        }
      }
    }
    //CAPACITY REMAINING
    road.impact = Impact.from({
      numberOfOperationalLanes: obj.numberOfOperationalLanes
        ? obj.numberOfOperationalLanes
        : null,
      numberOfLanesRestricted: obj.numberOfLanesRestricted
        ? obj.numberOfLanesRestricted
        : null,
      capacityRemaining:
        obj.capacityRemaining || obj.capacityRemaining === 0
          ? obj.capacityRemaining
          : null,
      originalNumberOfLanes: obj.originalNumberOfLanes
        ? obj.originalNumberOfLanes
        : null,
      trafficConstrictionType: obj.trafficConstrictionType
        ? obj.trafficConstrictionType
        : "CARRIAGEWAY_PARTIALLY_OBSTRUCTED",
      lanesRestricted: [],
      speedRemaining:
        obj.residualSpeed || obj.residualSpeed === 0 ? obj.residualSpeed : null,
    });
    //PRACTICE
    if (obj.iDPractice) road.situationId = obj.iDPractice;
    //PROBABILITY
    if (obj.probabilityEvent)
      road.probabilityOfOccurrence = selectFields.PROBABILITA.properties[
        obj.probabilityEvent
      ]
        ? selectFields.PROBABILITA.properties[obj.probabilityEvent].name
        : null;
    //situationRecordCreationTime
    if (obj.situationRecordCreationTime) {
      road.situationRecordCreationTime = obj.situationRecordCreationTime;
    } else {
      road.situationRecordCreationTime =
        current && current.situationRecordCreationTime
          ? current.situationRecordCreationTime
          : null;
    }
    //situationRecordVersion
    if (obj.situationRecordVersion) {
      road.situationRecordVersion = obj.situationRecordVersion.toString();
    } else {
      road.situationRecordVersion =
        current && current.situationRecordVersion
          ? current.situationRecordVersion.toString()
          : null;
    }
    //situationRecordVersionTime
    if (obj.situationRecordVersionTime) {
      road.situationRecordVersionTime = obj.situationRecordVersionTime;
    } else {
      road.situationRecordVersionTime =
        current && current.situationRecordVersionTime
          ? current.situationRecordVersionTime
          : null;
    }
    //GRAVITY
    if (obj.gravityEvent)
      road.severity = selectFields.GRAVITA.properties[obj.gravityEvent].name;
    //NOTE
    if (obj.note)
      road.generalPublicComment = Comments.fromArray([
        {
          comment: Multilingual.from({ language: "IT", content: obj.note }),
          commentDateTime:
            current &&
            current.generalPublicComment &&
            current.generalPublicComment.length > 0 &&
            current.generalPublicComment[0].commentDateTime
              ? current.generalPublicComment[0].commentDateTime
              : new Date().toISOString(),
        },
      ]);
    //SOURCE

    if (username && obj.supplierEvent) {
      road.source = Source.from({
        reliable: true,
        sourceName: { language: "IT", content: username },
        sourceCountry: "IT",
        sourceType: "OTHER_INFORMATION",
        sourceIdentification: "Comune di Verona",
        sourceReference: "OPERATOR",
      });
    }
    // START AND END TIME
    if (obj.stateEvent && obj.timeStartEvent && obj.dateStartEvent)
      road.validity = Validity.from({
        overrunning: true,
        validityStatus: setValidityStatusEvent(
          normalizingDateTimeEventModal(obj.dateStartEvent, obj.timeStartEvent),
          obj.dateEndEvent && obj.dateEndEvent
            ? normalizingDateTimeEventModal(obj.dateEndEvent, obj.timeEndEvent)
            : null,
          "DEFINED_BY_VALIDITY_TIME_SPEC",
          false
        ),
        validityTimeSpecification: {
          overallStartTime: normalizingDateTimeEventModal(
            obj.dateStartEvent,
            obj.timeStartEvent
          ),
          overallEndTime:
            obj.timeEndEvent && obj.dateEndEvent
              ? normalizingDateTimeEventModal(
                  obj.dateEndEvent,
                  obj.timeEndEvent
                )
              : null,
        },
      });

    //GROUP OF LOCATIONS
    //SAVE ONLY ONE LOCALIZATION (LAST SELECTED)
    if (currentLocalizationType === "Cartografica Puntuale") {
      if (
        obj.positionPuntuale &&
        !obj.confirmedArcPuntuale &&
        modal !== "modifyEvent"
      ) {
        road.groupOfLocations = NetworkLocation.fromArray([
          {
            point: Point.from({
              pointByCoordinates: pointByCoordinates.from(obj.positionPuntuale),
            }),
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[0] &&
              current.groupOfLocations[0].networkLocationId
                ? current.groupOfLocations[0].networkLocationId
                : null,
            defaultLocation: true,
            locationType: "POINT_BY_COORDINATES",
            locationForDisplay: PointCoordinates.from(obj.positionPuntuale),
          },
        ]);
      } else if (
        obj.positionPuntuale &&
        !obj.confirmedArcPuntuale &&
        obj.historyPuntuale &&
        obj.historyPuntuale.prev &&
        obj.historyPuntuale.prev.length >= 1 &&
        modal === "modifyEvent"
      ) {
        road.groupOfLocations = NetworkLocation.fromArray([
          {
            point: Point.from({
              pointByCoordinates: pointByCoordinates.from(obj.positionPuntuale),
            }),
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[0] &&
              current.groupOfLocations[0].networkLocationId
                ? current.groupOfLocations[0].networkLocationId
                : null,
            defaultLocation: true,
            locationType: "POINT_BY_COORDINATES",
            locationForDisplay: PointCoordinates.from(obj.positionPuntuale),
          },
        ]);
      } else if (
        obj.positionPuntuale &&
        !obj.confirmedArcPuntuale &&
        obj.historyPuntuale &&
        obj.historyPuntuale.prev &&
        obj.historyPuntuale.prev.length === 0 &&
        modal === "modifyEvent"
      ) {
        road.groupOfLocations = current.groupOfLocations;
      } else if (obj.confirmedArcPuntuale) {
        let coordinates =
          obj &&
          obj.confirmedArcPuntuale &&
          obj.confirmedArcPuntuale.geometry &&
          obj.confirmedArcPuntuale.geometry.coordinates;
        const point1 = turf.point([...coordinates[0]]);
        const point2 = turf.point([...coordinates[coordinates.length - 1]]);
        const midpoint = turf.midpoint(point1, point2);
        const lon = (midpoint.geometry.coordinates[0] * 100000) / 100000;
        const lat = (midpoint.geometry.coordinates[1] * 100000) / 100000;
        let locations = [];
        let indexOfPointByCooordinates =
          current &&
          current.groupOfLocations &&
          current.groupOfLocations[0] &&
          current.groupOfLocations.findIndex(
            (x) => x.locationType === "POINT_BY_COORDINATES"
          );
        let indexOfLinearWithinElement =
          current &&
          current.groupOfLocations &&
          current.groupOfLocations[0] &&
          current.groupOfLocations.findIndex(
            (x) => x.locationType === "LINEAR_WITHIN_LINEAR_ELEMENT"
          );
        locations.push(
          NetworkLocation.from({
            point: Point.from({
              pointByCoordinates: pointByCoordinates.from(obj.positionPuntuale),
            }),
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[indexOfPointByCooordinates] &&
              current.groupOfLocations[indexOfPointByCooordinates]
                .networkLocationId
                ? current.groupOfLocations[indexOfPointByCooordinates]
                    .networkLocationId
                : null,
            defaultLocation: false,
            locationType: "POINT_BY_COORDINATES",
            locationForDisplay: PointCoordinates.from({
              latitude: lat,
              longitude: lon,
            }),
          })
        );
        locations.push(
          NetworkLocation.from({
            linear: {
              linearWithinLinearElement: {
                linearElement: {
                  roadName: {
                    language: "IT",
                    content: obj.confirmedArcPuntuale.properties.name,
                  },
                  roadNumber: obj.confirmedArcPuntuale.properties.arcid.toString(),
                  archId: obj.confirmedArcPuntuale.properties.arcid.toString(),
                },
              },
            },
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[indexOfLinearWithinElement] &&
              current.groupOfLocations[indexOfLinearWithinElement]
                .networkLocationId
                ? current.groupOfLocations[indexOfLinearWithinElement]
                    .networkLocationId
                : null,
            defaultLocation: true,
            locationType: "LINEAR_WITHIN_LINEAR_ELEMENT",
            locationForDisplay: PointCoordinates.from({
              latitude: lat,
              longitude: lon,
            }),
          })
        );
        road.groupOfLocations = locations;
      }
    } else if (currentLocalizationType === "Cartografica Estesa") {
      if (
        obj.positionEstesa &&
        (!obj.listOfConfirmedArcsEstesa ||
          obj.listOfConfirmedArcsEstesa.length === 0)
      ) {
        road.groupOfLocations = NetworkLocation.fromArray([
          {
            point: Point.from({
              pointByCoordinates: pointByCoordinates.from(obj.positionEstesa),
            }),
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[0] &&
              current.groupOfLocations[0].networkLocationId
                ? current.groupOfLocations[0].networkLocationId
                : null,
            defaultLocation: true,
            locationType: "POINT_BY_COORDINATES",
            locationForDisplay: PointCoordinates.from(obj.positionEstesa),
          },
        ]);
      } else if (
        obj.listOfConfirmedArcsEstesa &&
        obj.listOfConfirmedArcsEstesa.length > 0
      ) {
        let locationContained = [];

        let coordinates =
          obj &&
          obj.listOfConfirmedArcsEstesa[0] &&
          obj.listOfConfirmedArcsEstesa[0].geometry &&
          obj.listOfConfirmedArcsEstesa[0].geometry.coordinates;
        const point1 = turf.point([...coordinates[0]]);
        const point2 = turf.point([...coordinates[coordinates.length - 1]]);
        const midpoint = turf.midpoint(point1, point2);
        const lon = (midpoint.geometry.coordinates[0] * 100000) / 100000;
        const lat = (midpoint.geometry.coordinates[1] * 100000) / 100000;

        let indexOfPointByCooordinatesEstesa =
          current &&
          current.groupOfLocations &&
          current.groupOfLocations[0] &&
          current.groupOfLocations.findIndex(
            (x) => x.locationType === "POINT_BY_COORDINATES"
          );
        let currentFiltered =
          current &&
          current.groupOfLocations.filter(
            (item) => item.locationType !== "POINT_BY_COORDINATES"
          );

        locationContained.push(
          NetworkLocation.from({
            point: Point.from({
              pointByCoordinates: pointByCoordinates.from(obj.positionEstesa),
            }),
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[indexOfPointByCooordinatesEstesa] &&
              current.groupOfLocations[indexOfPointByCooordinatesEstesa]
                .networkLocationId
                ? current.groupOfLocations[indexOfPointByCooordinatesEstesa]
                    .networkLocationId
                : null,
            defaultLocation: false,
            locationType: "POINT_BY_COORDINATES",
            locationForDisplay: PointCoordinates.from({
              latitude: lat,
              longitude: lon,
            }),
          })
        );
        obj.listOfConfirmedArcsEstesa.forEach((item, index) => {
          locationContained.push(
            NetworkLocation.from({
              linear: Linear.from({
                linearWithinLinearElement: LinearwithinLinear.from({
                  linearElement: LinearElement.from({
                    roadName: Multilingual.from({
                      language: "IT",
                      content: item.properties.name,
                    }),
                    roadNumber:
                      item.properties &&
                      item.properties.arcid &&
                      item.properties.arcid.toString(),
                    archId:
                      item.properties &&
                      item.properties.arcid &&
                      item.properties.arcid.toString(),
                  }),
                }),
              }),
              networkLocationId:
                currentFiltered &&
                currentFiltered &&
                currentFiltered.length > 0 &&
                currentFiltered[index] &&
                currentFiltered[index].networkLocationId
                  ? currentFiltered[index].networkLocationId
                  : null,
              defaultLocation: true,
              locationType: "LINEAR_WITHIN_LINEAR_ELEMENT",
              locationForDisplay: PointCoordinates.from({
                latitude: lat,
                longitude: lon,
              }),
            })
          );
        });

        road.groupOfLocations = locationContained;
      }
    } else if (currentLocalizationType === "Rete RDS-TMC Puntuale") {
      if (
        obj.initialPointRDSPuntuale &&
        obj.initialPointRDSPuntuale !== "" &&
        obj.initialPointRDSPuntuale !== "-"
      ) {
        let locationContained = [];

        locationContained.push(
          NetworkLocation.from({
            point: Point.from({
              pointByCoordinates: pointByCoordinates.from({
                latitude: obj.pointCoordinatesAfterMoving[1],
                longitude: obj.pointCoordinatesAfterMoving[0],
              }),
            }),
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[0] &&
              current.groupOfLocations[0].networkLocationId
                ? current.groupOfLocations[0].networkLocationId
                : null,
            defaultLocation: false,
            locationType: "POINT_BY_COORDINATES",
            locationForDisplay: PointCoordinates.from({
              latitude: obj.pointCoordinatesAfterMoving[1],
              longitude: obj.pointCoordinatesAfterMoving[0],
            }),
          })
        );

        locationContained.push(
          NetworkLocation.from({
            point: Point.from({
              alertCPoint: AlertCPoint.from({
                alertCDirection: AlertCDirection.from({
                  alertCDirectionNamed: Multilingual.from({
                    content: obj.directionRDSPuntualeName,
                  }),
                  alertCDirectionCoded: obj.directionRDSPuntualeCoded,
                }),
                alertCLocationCountryCode: localizationRdsTmcCountryCode,
                alertCLocationTableNumber: localizationRdsTmcTableNumber,
                alertCLocationTableVersion: localizationRdsTmcTableVersion,
                primaryLocation: PrimaryLocation.from({
                  alertCLocationName: Multilingual.from({
                    content: obj.initialPointRDSPuntualeName,
                  }),
                  specificLocation: Number(obj.initialPointRDSPuntuale),
                  offsetDistance: Number(
                    obj.distanceFromInitialPointRDSPuntuale
                  )
                    ? Number(obj.distanceFromInitialPointRDSPuntuale)
                    : 0,
                }),
              }),
            }),
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[1] &&
              current.groupOfLocations[1].networkLocationId
                ? current.groupOfLocations[1].networkLocationId
                : null,
            locationForDisplay: PointCoordinates.from({
              latitude: obj.pointCoordinatesAfterMoving[1],
              longitude: obj.pointCoordinatesAfterMoving[0],
            }),
            defaultLocation: true,
            locationType: "ALERT_C_METHOD_4_POINT",
          })
        );
        obj.arcListRDSPuntuale.forEach((item, index) => {
          locationContained.push(
            NetworkLocation.from({
              linear: Linear.from({
                linearWithinLinearElement: LinearwithinLinear.from({
                  linearElement: LinearElement.from({
                    roadName: Multilingual.from({
                      language: "IT",
                      content: item.name,
                    }),
                    roadNumber: item.arcid.toString(),
                    archId: item.arcid.toString(),
                  }),
                }),
              }),
              networkLocationId:
                current &&
                current.groupOfLocations &&
                current.groupOfLocations.length > 0 &&
                current.groupOfLocations[index + 2] &&
                current.groupOfLocations[index + 2].networkLocationId
                  ? current.groupOfLocations[index + 2].networkLocationId
                  : null,
              defaultLocation: false,
              locationType: "LINEAR_WITHIN_LINEAR_ELEMENT",
              locationForDisplay: PointCoordinates.from({
                latitude: obj.pointCoordinatesAfterMoving[1],
                longitude: obj.pointCoordinatesAfterMoving[0],
              }),
            })
          );
        });
        road.groupOfLocations = locationContained;
      }
    } else if (currentLocalizationType === "Rete RDS-TMC Estesa") {
      if (
        obj.initialPointRDSEstesa &&
        obj.initialPointRDSEstesa !== "" &&
        obj.initialPointRDSEstesa !== "-" &&
        obj.finalPointRDSEstesa &&
        obj.finalPointRDSEstesa !== "" &&
        obj.finalPointRDSEstesa !== "-"
      ) {
        let locationContained = [];

        locationContained.push(
          NetworkLocation.from({
            point: Point.from({
              pointByCoordinates: pointByCoordinates.from(obj.positionEstesa),
            }),
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[0] &&
              current.groupOfLocations[0].networkLocationId
                ? current.groupOfLocations[0].networkLocationId
                : null,
            defaultLocation: false,
            locationType: "POINT_BY_COORDINATES",
            locationForDisplay: PointCoordinates.from({
              latitude: obj.pointCoordinatesAfterMovingEstesa[1],
              longitude: obj.pointCoordinatesAfterMovingEstesa[0],
            }),
          })
        );

        locationContained.push(
          NetworkLocation.from({
            linear: Linear.from({
              alertCLinear: AlertCLinear.from({
                alertCDirection: AlertCDirection.from({
                  alertCDirectionNamed: Multilingual.from({
                    content: obj.directionRDSEstesaName,
                  }),
                  alertCDirectionCoded: obj.directionRDSEstesaCoded,
                }),
                alertCLocationCountryCode: localizationRdsTmcCountryCode,
                alertCLocationTableNumber: localizationRdsTmcTableNumber,
                alertCLocationTableVersion: localizationRdsTmcTableVersion,
                primaryLocation: PrimaryAndSecondaryLocation.from({
                  alertCLocationName: Multilingual.from({
                    content: obj.initialPointRDSEstesaName,
                  }),
                  specificLocation: Number(obj.initialPointRDSEstesa),
                  offsetDistance: Number(obj.distanceFromInitialPointRDSEstesa)
                    ? Number(obj.distanceFromInitialPointRDSEstesa)
                    : 0,
                }),
                secondaryLocation: PrimaryAndSecondaryLocation.from({
                  alertCLocationName: Multilingual.from({
                    content: obj.finalPointRDSEstesaName,
                  }),
                  specificLocation: Number(obj.finalPointRDSEstesa),
                  offsetDistance: Number(obj.distanceFromFinalPointRDSEstesa)
                    ? Number(obj.distanceFromFinalPointRDSEstesa)
                    : 0,
                }),
              }),
            }),
            networkLocationId:
              current &&
              current.groupOfLocations &&
              current.groupOfLocations.length > 0 &&
              current.groupOfLocations[1] &&
              current.groupOfLocations[1].networkLocationId
                ? current.groupOfLocations[1].networkLocationId
                : null,
            locationForDisplay: PointCoordinates.from({
              latitude: obj.pointCoordinatesAfterMovingEstesa[1],
              longitude: obj.pointCoordinatesAfterMovingEstesa[0],
            }),
            defaultLocation: true,
            locationType: "ALERT_C_METHOD_4_LINEAR",
          })
        );

        obj.arcListRDSEstesa.forEach((item, index) => {
          locationContained.push(
            NetworkLocation.from({
              linear: Linear.from({
                linearWithinLinearElement: LinearwithinLinear.from({
                  linearElement: LinearElement.from({
                    roadName: Multilingual.from({
                      language: "IT",
                      content: item.name,
                    }),
                    roadNumber: item.arcid.toString(),
                    archId: item.arcid.toString(),
                  }),
                }),
              }),
              networkLocationId:
                current &&
                current.groupOfLocations &&
                current.groupOfLocations.length > 0 &&
                current.groupOfLocations[index + 2] &&
                current.groupOfLocations[index + 2].networkLocationId
                  ? current.groupOfLocations[index + 2].networkLocationId
                  : null,
              defaultLocation: false,
              locationType: "LINEAR_WITHIN_LINEAR_ELEMENT",
              locationForDisplay: PointCoordinates.from({
                latitude: obj.pointCoordinatesAfterMovingEstesa[1],
                longitude: obj.pointCoordinatesAfterMovingEstesa[0],
              }),
            })
          );
        });
        road.groupOfLocations = locationContained;
      }
    }

    return road;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(SituationRecord.from).filter((item) => item !== null);
  }
}

export class Traffic {
  abnormalTraffic;
  accident;
  activity;
  conditions;
  equipmentOrSystemFault;
  obstruction;

  static from(obj) {
    let op = new Traffic();
    if (obj) {
      if (obj.abnormalTraffic)
        op.abnormalTraffic = AbnormalTrafficAttributes.from(
          obj.abnormalTraffic
        );
      if (obj.accident) op.accident = AccidentAttributes.from(obj.accident);
      if (obj.activity) op.activity = ActivityAttributes.from(obj.activity);
      if (obj.conditions)
        op.conditions = ConditionsAttributes.from(obj.conditions);
      if (obj.equipmentOrSystemFault)
        op.equipmentOrSystemFault = EquipmentOrSystemFaultAttributes.from(
          obj.equipmentOrSystemFault
        );
      if (obj.obstruction)
        op.obstruction = ObstructionAttributes.from(obj.obstruction);
    }
    return op;
  }
}

export class GroupOfLocations {
  groupOfLocationsType;
  location;
  nonOrderedLocations;

  static from(obj) {
    let op = new GroupOfLocations();
    if (obj) {
      op.groupOfLocationsType = obj.location ? "LOCATION" : "NONORDERLOCATIONS";
      if (obj.location) op.location = NetworkLocation.from(obj.location);
      if (obj.nonOrderedLocations)
        op.nonOrderedLocations = NonOrderedLocationGroupByList.from(
          obj.nonOrderedLocations
        );
    }
    return op;
  }
}

export class Linear {
  alertCLinear;
  linearWithinLinearElement;
  static from(obj) {
    let op = new Linear();
    if (obj) {
      if (obj.alertCLinear)
        op.alertCLinear = AlertCLinear.from(obj.alertCLinear);
      if (obj.linearWithinLinearElement)
        op.linearWithinLinearElement = LinearwithinLinear.from(
          obj.linearWithinLinearElement
        );
    }
    return op;
  }
}
export class LinearwithinLinear {
  administrativeAreaOfLinearSection;
  directionBoundOnLinearSection;
  directionRelativeOnLinearSection;
  fromPoint;
  heightGradeOfLinearSection;
  toPoint;
  linearElement;
  static from(obj) {
    let op = new LinearwithinLinear();
    if (obj) {
      if (obj.administrativeAreaOfLinearSection)
        op.administrativeAreaOfLinearSection = Multilingual.from(
          obj.administrativeAreaOfLinearSection
        );
      if (obj.directionBoundOnLinearSection)
        op.directionBoundOnLinearSection = obj.directionBoundOnLinearSection;
      if (obj.directionRelativeOnLinearSection)
        op.directionRelativeOnLinearSection =
          obj.directionRelativeOnLinearSection;
      if (obj.fromPoint) op.fromPoint = FromToPoint.from(obj.fromPoint);
      if (obj.heightGradeOfLinearSection)
        op.heightGradeOfLinearSection = obj.heightGradeOfLinearSection;
      if (obj.toPoint) op.toPoint = FromToPoint.from(obj.toPoint);
      if (obj.linearElement)
        op.linearElement = LinearElement.from(obj.linearElement);
    }
    return op;
  }
}

export class FromToPoint {
  administrativeAreaOfPoint;
  directionBoundAtPoint;
  heightGradeOfPoint;
  linearElement;
  distanceAlongLinearElement;

  static from(obj) {
    let op = new FromToPoint();
    if (obj) {
      if (obj.administrativeAreaOfPoint)
        op.administrativeAreaOfPoint = Multilingual.from(
          obj.administrativeAreaOfPoint
        );
      if (obj.directionBoundAtPoint)
        op.directionBoundAtPoint = obj.directionBoundAtPoint;
      if (obj.directionRelativeAtPoint)
        op.directionRelativeAtPoint = obj.directionRelativeAtPoint;
      if (obj.heightGradeOfPoint)
        op.heightGradeOfPoint = obj.heightGradeOfPoint;
      if (obj.linearElement)
        op.linearElement = LinearElement.from(obj.linearElement);
      if (obj.distanceAlongLinearElement)
        op.distanceAlongLinearElement = DistanceAlongLinearElement.from(
          obj.distanceAlongLinearElement
        );
    }
    return op;
  }
}

export class LinearElement {
  linearElementNature;
  linearElementReferenceModel;
  roadName;
  roadNumber;
  archId;
  startPointOfLinearElement;
  endPointOfLinearElement;

  static from(obj) {
    let op = new LinearElement();
    if (obj) {
      if (obj.linearElementNature)
        op.linearElementNature = obj.linearElementNature;
      if (obj.linearElementReferenceModel)
        op.linearElementReferenceModel = obj.linearElementReferenceModel;
      if (obj.roadName) op.roadName = Multilingual.from(obj.roadName);
      if (obj.roadNumber) op.roadNumber = obj.roadNumber;
      if (obj.archId) op.archId = obj.archId;
      if (obj.startPointOfLinearElement)
        op.startPointOfLinearElement = StartAndEndPointOfLinearElement.from(
          obj.startPointOfLinearElement
        );
      if (obj.endPointOfLinearElement)
        op.endPointOfLinearElement = StartAndEndPointOfLinearElement.from(
          obj.endPointOfLinearElement
        );
    }
    return op;
  }
}
export class StartAndEndPointOfLinearElement {
  referentIdentifier;
  referentDescription;
  referentName;
  referentType;

  static from(obj) {
    let op = new StartAndEndPointOfLinearElement();
    if (obj) {
      if (obj.referentIdentifier)
        op.referentIdentifier = obj.referentIdentifier;
      if (obj.referentDescription)
        op.referentDescription = Multilingual.from(obj.referentDescription);
      if (obj.referentName) op.referentName = obj.referentName;
      if (obj.referentType) op.referentType = obj.referentType;
    }
    return op;
  }
}

export class DistanceAlongLinearElement {
  distanceAlong;

  static from(obj) {
    let op = new DistanceAlongLinearElement();
    if (obj) {
      if (obj.distanceAlong) op.distanceAlong = obj.distanceAlong;
    }
    return op;
  }
}

export class AlertCPoint {
  alertCDirection;
  alertCLocationCountryCode;
  alertCLocationTableNumber;
  alertCLocationTableVersion;
  primaryLocation;

  static from(obj) {
    let op = new AlertCPoint();
    if (obj) {
      if (obj.alertCDirection)
        op.alertCDirection = AlertCDirection.from(obj.alertCDirection);
      if (obj.alertCLocationCountryCode)
        op.alertCLocationCountryCode = obj.alertCLocationCountryCode;
      if (obj.alertCLocationTableNumber)
        op.alertCLocationTableNumber = obj.alertCLocationTableNumber;
      if (obj.alertCLocationTableVersion)
        op.alertCLocationTableVersion = obj.alertCLocationTableVersion;
      if (obj.primaryLocation)
        op.primaryLocation = PrimaryLocation.from(obj.primaryLocation);
    }
    return op;
  }
}
export class AlertCLinear {
  alertCDirection;
  alertCLocationCountryCode;
  alertCLocationTableNumber;
  alertCLocationTableVersion;
  primaryLocation;
  secondaryLocation;

  static from(obj) {
    let op = new AlertCLinear();
    if (obj) {
      if (obj.alertCDirection)
        op.alertCDirection = AlertCDirection.from(obj.alertCDirection);
      if (obj.alertCLocationCountryCode)
        op.alertCLocationCountryCode = obj.alertCLocationCountryCode;
      if (obj.alertCLocationTableNumber)
        op.alertCLocationTableNumber = obj.alertCLocationTableNumber;
      if (obj.alertCLocationTableVersion)
        op.alertCLocationTableVersion = obj.alertCLocationTableVersion;
      if (obj.primaryLocation)
        op.primaryLocation = PrimaryLocation.from(obj.primaryLocation);
      if (obj.secondaryLocation)
        op.secondaryLocation = PrimaryAndSecondaryLocation.from(
          obj.secondaryLocation
        );
    }
    return op;
  }
}

export class AlertCDirection {
  alertCDirectionCoded;
  alertCDirectionNamed;
  alertCDirectionSense;

  static from(obj) {
    let op = new AlertCDirection();
    if (obj) {
      if (obj.alertCDirectionCoded)
        op.alertCDirectionCoded = obj.alertCDirectionCoded;
      if (obj.alertCDirectionNamed)
        op.alertCDirectionNamed = Multilingual.from(obj.alertCDirectionNamed);
      if (obj.alertCDirectionSense)
        op.alertCDirectionSense = obj.alertCDirectionSense;
    }
    return op;
  }
}

export class PrimaryLocation {
  alertCLocationName;
  offsetDistance;
  specificLocation;

  static from(obj) {
    let op = new PrimaryLocation();
    if (obj) {
      if (obj.alertCLocationName)
        op.alertCLocationName = Multilingual.from(obj.alertCLocationName);
      if (obj.offsetDistance) {
        op.offsetDistance = obj.offsetDistance;
      } else {
        op.offsetDistance = 0;
      }
      if (obj.specificLocation) op.specificLocation = obj.specificLocation;
    }
    return op;
  }
}

export class PrimaryAndSecondaryLocation {
  alertCLocationName;
  offsetDistance;
  specificLocation;

  static from(obj) {
    let op = new PrimaryAndSecondaryLocation();
    if (obj) {
      if (obj.alertCLocationName)
        op.alertCLocationName = Multilingual.from(obj.alertCLocationName);
      if (obj.offsetDistance) {
        op.offsetDistance = obj.offsetDistance;
      } else {
        op.offsetDistance = 0;
      }
      if (obj.specificLocation) op.specificLocation = obj.specificLocation;
    }
    return op;
  }
}

export class Point {
  alertCPoint;
  pointByCoordinates;
  pointAlongLinearElement;

  static from(obj) {
    let op = new Point();
    if (obj) {
      //ALERT POINT AND LINEAR HAVE SAME STRUCTURE
      if (obj.alertCPoint) op.alertCPoint = AlertCPoint.from(obj.alertCPoint);
      if (obj.pointByCoordinates)
        op.pointByCoordinates = pointByCoordinates.from(obj.pointByCoordinates);
      //SAME STRUCTURE OF FROM/TO POINTS OF LINEAR
      if (obj.pointAlongLinearElement)
        op.pointAlongLinearElement = FromToPoint.from(
          obj.pointAlongLinearElement
        );
    }
    return op;
  }
}

export class pointByCoordinates {
  latitude;
  longitude;

  static from(obj) {
    let op = new pointByCoordinates();
    if (obj) {
      if (obj.longitude) op.longitude = obj.longitude;
      if (obj.latitude) op.latitude = obj.latitude;
    }
    return op;
  }
}
export class PointCoordinates {
  latitude;
  longitude;

  static from(obj) {
    let op = new PointCoordinates();
    if (obj) {
      if (obj.longitude) op.longitude = obj.longitude;
      if (obj.latitude) op.latitude = obj.latitude;
    }
    return op;
  }
}

export class NetworkLocation {
  linear;
  point;
  networkLocationId;
  locationForDisplay;
  locationType;
  defaultLocation;
  static from(obj) {
    let op = new NetworkLocation();

    if (obj) {
      if (obj.linear) op.linear = Linear.from(obj.linear);
      op.networkLocationId = obj.networkLocationId
        ? obj.networkLocationId
        : null;
      if (obj.point) op.point = Point.from(obj.point);
      if (obj.locationForDisplay)
        op.locationForDisplay = PointCoordinates.from(obj.locationForDisplay);
      if (obj.locationType) op.locationType = obj.locationType;
      // if (obj.defaultLocation)
      op.defaultLocation = obj.defaultLocation;
    }
    return op;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(NetworkLocation.from).filter((item) => item !== null);
  }
}

export class NonOrderedLocationGroupByList {
  locationContainedInGroup;

  static from(obj) {
    let op = new NonOrderedLocationGroupByList();
    if (obj) {
      if (obj.locationContainedInGroup)
        op.locationContainedInGroup = NetworkLocation.fromArray(
          obj.locationContainedInGroup
        );
    }
    return op;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(NonOrderedLocationGroupByList.from)
      .filter((item) => item !== null);
  }
}

export class Comments {
  comment;
  commentDateTime;

  static from(obj) {
    let com = new Comments();
    if (obj) {
      if (obj.comment) com.comment = Multilingual.from(obj.comment);
      if (obj.commentDateTime) com.commentDateTime = obj.commentDateTime;
    }
    return com;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(Comments.from).filter((item) => item !== null);
  }
}

export class EquipmentOrSystemFaultAttributes {
  equipmentOrSystemFaultType;
  faultyEquipmentOrSystemType;
  obstructionAttributes;

  static from(obj) {
    let op = new EquipmentOrSystemFaultAttributes();
    if (obj) {
      if (obj.equipmentOrSystemFaultType)
        op.equipmentOrSystemFaultType = obj.equipmentOrSystemFaultType;
      if (obj.faultyEquipmentOrSystemType)
        op.faultyEquipmentOrSystemType = obj.faultyEquipmentOrSystemType;
      if (obj.obstructionAttributes)
        op.obstructionAttributes = ObstructionAttributes.from(
          obj.obstructionAttributes
        );
    }
    return op;
  }
}

export class ConditionsAttributes {
  drivingConditionType;
  poorEnvironmentConditions;
  weatherRelatedRoadConditions;

  static from(obj) {
    let op = new ActivityAttributes();
    if (obj) {
      if (obj.drivingConditionType)
        op.drivingConditionType = obj.drivingConditionType;
      if (obj.poorEnvironmentConditions)
        op.poorEnvironmentConditions = PoorEnvironmentConditionsAttributes.from(
          obj.poorEnvironmentConditions
        );
      if (obj.weatherRelatedRoadConditions)
        op.weatherRelatedRoadConditions = WeatherRelatedRoadConditionsAttributes.from(
          obj.weatherRelatedRoadConditions
        );
    }
    return op;
  }
}

export class PoorEnvironmentConditionsAttributes {
  humidity;
  pollution;
  poorEnvironmentType;
  precipitationDetail;
  temperature;
  visibility;
  wind;

  static from(obj) {
    let op = new PoorEnvironmentConditionsAttributes();
    if (obj) {
      if (obj.humidity) op.humidity = Humidity.from(obj.humidity);
      if (obj.pollution) op.pollution = Pollution.from(obj.pollution);
      if (obj.poorEnvironmentType)
        op.poorEnvironmentType = obj.poorEnvironmentType;
      if (obj.precipitationDetail)
        op.precipitationDetail = PrecipitationDetail.from(
          obj.precipitationDetail
        );
      if (obj.temperature) op.temperature = Temperature.from(obj.temperature);
      if (obj.visibility) op.visibility = Visibility.from(obj.visibility);
      if (obj.wind) op.wind = Wind.from(obj.wind);
    }
    return op;
  }
}

export class Humidity {
  relativeHumidity;

  static from(obj) {
    let op = new Humidity();
    if (obj) {
      if (obj.relativeHumidity >= 0) {
        op.relativeHumidity = obj.relativeHumidity;
      } else if (obj.relativeHumidity) {
        op.relativeHumidity = obj.relativeHumidity;
      }
    }
    return op;
  }
}

export class Pollution {
  pollutantConcentration;
  pollutantType;

  static from(obj) {
    let op = new Pollution();
    if (obj) {
      if (obj.pollutantConcentration >= 0) {
        op.pollutantConcentration = obj.pollutantConcentration;
      } else if (obj.pollutantConcentration) {
        op.pollutantConcentration = obj.pollutantConcentration;
      }
      if (obj.pollutantType) op.pollutantType = obj.pollutantType;
    }
    return op;
  }
}

export class PrecipitationDetail {
  precipitationIntensity;
  precipitationType;

  static from(obj) {
    let op = new PrecipitationDetail();
    if (obj) {
      if (obj.precipitationIntensity >= 0) {
        op.precipitationIntensity = obj.precipitationIntensity;
      } else if (obj.precipitationIntensity) {
        op.precipitationIntensity = obj.precipitationIntensity;
      }
      if (obj.precipitationType) op.precipitationType = obj.precipitationType;
    }
    return op;
  }
}

export class Temperature {
  airTemperature;
  maximumTemperature;
  minimumTemperature;

  static from(obj) {
    let op = new Temperature();
    if (obj) {
      if (obj.airTemperature >= 0) {
        op.airTemperature = obj.airTemperature;
      } else if (obj.airTemperature) {
        op.airTemperature = obj.airTemperature;
      }
      if (obj.maximumTemperature >= 0) {
        op.maximumTemperature = obj.maximumTemperature;
      } else if (obj.maximumTemperature) {
        op.maximumTemperature = obj.maximumTemperature;
      }
      if (obj.minimumTemperature >= 0) {
        op.minimumTemperature = obj.minimumTemperature;
      } else if (obj.minimumTemperature) {
        op.minimumTemperature = obj.minimumTemperature;
      }
    }
    return op;
  }
}

export class Visibility {
  minimunVisibilityDistance;

  static from(obj) {
    let op = new Visibility();
    if (obj) {
      if (obj.minimunVisibilityDistance >= 0) {
        op.minimunVisibilityDistance = obj.minimunVisibilityDistance;
      } else if (obj.minimunVisibilityDistance) {
        op.minimunVisibilityDistance = obj.minimunVisibilityDistance;
      }
    }
    return op;
  }
}

export class Wind {
  directionCompass;
  maximumWindSpeed;
  windMeasurementHeight;
  windSpeed;

  static from(obj) {
    let op = new Wind();
    if (obj) {
      if (obj.directionCompass) op.directionCompass = obj.directionCompass;
      if (obj.maximumWindSpeed >= 0) {
        op.maximumWindSpeed = obj.maximumWindSpeed;
      } else if (obj.maximumWindSpeed) {
        op.maximumWindSpeed = obj.maximumWindSpeed;
      }
      if (obj.windMeasurementHeight >= 0) {
        op.windMeasurementHeight = obj.windMeasurementHeight;
      } else if (obj.windMeasurementHeight) {
        op.windMeasurementHeight = obj.windMeasurementHeight;
      }
      if (obj.windSpeed >= 0) {
        op.windSpeed = obj.windSpeed;
      } else if (obj.windSpeed) {
        op.windSpeed = obj.windSpeed;
      }
    }
    return op;
  }
}

export class WeatherRelatedRoadConditionsAttributes {
  weatherRelatedRoadConditionType;

  static from(obj) {
    let op = new WeatherRelatedRoadConditionsAttributes();
    if (obj) {
      if (obj.weatherRelatedRoadConditionType)
        op.weatherRelatedRoadConditionType =
          obj.weatherRelatedRoadConditionType;
    }
    return op;
  }
}

export class ActivityAttributes {
  authorityOperation;
  disturbanceActivity;
  publicEvent;

  static from(obj) {
    let op = new ActivityAttributes();
    if (obj) {
      if (obj.authorityOperation)
        op.authorityOperation = AuthorityOperationAttributes.from(
          obj.authorityOperation
        );
      if (obj.disturbanceActivity)
        op.disturbanceActivity = DisturbanceActivityAttributes.from(
          obj.disturbanceActivity
        );
      if (obj.publicEvent)
        op.publicEvent = PublicEventAttributes.from(obj.publicEvent);
    }
    return op;
  }
}
export class PublicEventAttributes {
  publicEventType;

  static from(obj) {
    let op = new PublicEventAttributes();
    if (obj) {
      if (obj.publicEventType) op.publicEventType = obj.publicEventType;
    }
    return op;
  }
}
export class DisturbanceActivityAttributes {
  disturbanceActivityType;

  static from(obj) {
    let op = new DisturbanceActivityAttributes();
    if (obj) {
      if (obj.disturbanceActivityType)
        op.disturbanceActivityType = obj.disturbanceActivityType;
    }
    return op;
  }
}
export class AuthorityOperationAttributes {
  authorityOperationType;

  static from(obj) {
    let op = new AuthorityOperationAttributes();
    if (obj) {
      if (obj.authorityOperationType)
        op.authorityOperationType = obj.authorityOperationType;
    }
    return op;
  }
}

export class Cause {
  situationRecordId;
  situationRecordType;
  situationRecordVersion;

  static from(obj) {
    let op = new Cause();
    if (obj) {
      if (obj.situationRecordId) op.situationRecordId = obj.situationRecordId;
      if (obj.situationRecordType)
        op.situationRecordType = obj.situationRecordType;
      // if (obj.situationRecordVersion)
      op.situationRecordVersion = obj.situationRecordVersion;
    }
    return op;
  }
}

export class AccidentAttributes {
  accidentCause;
  accidentType;
  totalNumberOfPeopleInvolved;
  totalNumberOfVehiclesInvolved;

  static from(obj) {
    let op = new AccidentAttributes();
    if (obj) {
      if (obj.accidentCause) op.accidentCause = obj.accidentCause;
      if (obj.totalNumberOfVehiclesInvolved >= 0) {
        op.totalNumberOfVehiclesInvolved = obj.totalNumberOfVehiclesInvolved;
      } else if (obj.totalNumberOfPeopleInvolved) {
        op.totalNumberOfPeopleInvolved = obj.totalNumberOfPeopleInvolved;
      }
      if (obj.accidentType) op.accidentType = obj.accidentType;
      if (obj.totalNumberOfPeopleInvolved >= 0) {
        op.totalNumberOfPeopleInvolved = obj.totalNumberOfPeopleInvolved;
      } else if (obj.totalNumberOfPeopleInvolved) {
        op.totalNumberOfPeopleInvolved = obj.totalNumberOfPeopleInvolved;
      }
    }
    return op;
  }
}

export class AbnormalTrafficAttributes {
  abnormalTrafficType;
  relativeTrafficFlow;
  trafficTrendType;

  static from(obj) {
    let op = new AbnormalTrafficAttributes();
    if (obj) {
      if (obj.abnormalTrafficType)
        op.abnormalTrafficType = obj.abnormalTrafficType;
      if (obj.relativeTrafficFlow)
        op.relativeTrafficFlow = obj.relativeTrafficFlow;
      if (obj.trafficTrendType) op.trafficTrendType = obj.trafficTrendType;
    }
    return op;
  }
}

export class ObstructionAttributes {
  animalPresenceObstruction;
  environmentalObstruction;
  generalObstruction;
  numberOfObstructions;
  vehicleObstruction;

  static from(obj) {
    let op = new ObstructionAttributes();
    if (obj) {
      if (obj.animalPresenceObstruction)
        op.animalPresenceObstruction = AnimalPresenceObstructionAttributes.from(
          obj.animalPresenceObstruction
        );
      if (obj.environmentalObstruction)
        op.environmentalObstruction = EnvironmentalObstructionAttributes.from(
          obj.environmentalObstruction
        );
      if (obj.generalObstruction)
        op.generalObstruction = GeneralObstructionAttributes.from(
          obj.generalObstruction
        );
      if (obj.numberOfObstructions >= 0) {
        op.numberOfObstructions = obj.numberOfObstructions;
      } else if (obj.numberOfObstructions) {
        op.numberOfObstructions = obj.numberOfObstructions;
      }
      if (obj.vehicleObstruction)
        op.vehicleObstruction = VehicleObstruction.from(obj.vehicleObstruction);
    }
    return op;
  }
}

export class AnimalPresenceObstructionAttributes {
  animalPresenceObstructionType;
  alive;

  static from(obj) {
    let op = new AnimalPresenceObstructionAttributes();
    if (obj) {
      if (obj.animalPresenceObstructionType)
        op.animalPresenceObstructionType = obj.animalPresenceObstructionType;
      op.alive = obj.alive;
    }
    return op;
  }
}

export class EnvironmentalObstructionAttributes {
  environmentalObstructionType;

  static from(obj) {
    let op = new EnvironmentalObstructionAttributes();
    if (obj) {
      if (obj.environmentalObstructionType)
        op.environmentalObstructionType = obj.environmentalObstructionType;
    }
    return op;
  }
}

export class GeneralObstructionAttributes {
  obstructionType;

  static from(obj) {
    let op = new GeneralObstructionAttributes();
    if (obj) {
      if (obj.obstructionType) op.obstructionType = obj.obstructionType;
    }
    return op;
  }
}

export class VehicleObstruction {
  vehicleObstructionType;

  static from(obj) {
    let op = new VehicleObstruction();
    if (obj) {
      if (obj.vehicleObstructionType)
        op.vehicleObstructionType = obj.vehicleObstructionType;
    }
    return op;
  }
}

export class OperatorActionAttributes {
  actionOrigin;
  actionPlanIdentifier;
  operatorActionStatus;
  networkManagement;
  roadworks;

  static from(obj) {
    let op = new OperatorActionAttributes();
    if (obj) {
      if (obj.actionOrigin) op.actionOrigin = obj.actionOrigin;
      if (obj.actionPlanIdentifier)
        op.actionPlanIdentifier = obj.actionPlanIdentifier;
      if (obj.operatorActionStatus)
        op.operatorActionStatus = obj.operatorActionStatus;
      if (obj.networkManagement)
        op.networkManagement = NetworkManagement.from(obj.networkManagement);
      if (obj.roadworks) op.roadworks = Roadworks.from(obj.roadworks);
    }
    return op;
  }
}

export class Roadworks {
  constructionWorks;
  maintenanceWorks;
  roadworksDuration;
  roadworksScale;
  underTraffic;
  urgentRoadworks;

  static from(obj) {
    let op = new Roadworks();
    if (obj) {
      if (obj.constructionWorks)
        op.constructionWorks = ConstructionWorks.from(obj.constructionWorks);
      if (obj.maintenanceWorks)
        op.maintenanceWorks = MaintenanceWorkType.from(obj.maintenanceWorks);
      if (obj.roadworksDuration) op.roadworksDuration = obj.roadworksDuration;
      if (obj.roadworksScale) op.roadworksScale = obj.roadworksScale;
      op.urgentRoadworks = obj.urgentRoadworks;
      op.underTraffic = obj.underTraffic;
    }
    return op;
  }
}

export class ConstructionWorks {
  constructionWorkType;

  static from(obj) {
    let op = new ConstructionWorks();
    if (obj) {
      if (obj.constructionWorkType)
        op.constructionWorkType = obj.constructionWorkType;
    }
    return op;
  }
}

export class MaintenanceWorkType {
  maintenanceWorkType;

  static from(obj) {
    let op = new MaintenanceWorkType();
    if (obj) {
      if (obj.maintenanceWorkType)
        op.maintenanceWorkType = obj.maintenanceWorkType;
    }
    return op;
  }
}

export class NetworkManagement {
  applicableForTrafficDirection;
  applicableForTrafficType;
  complianceOption;
  roadOrCarriagewayOrLaneManagement;

  static from(obj) {
    let op = new NetworkManagement();
    if (obj) {
      if (obj.applicableForTrafficDirection)
        op.applicableForTrafficDirection = obj.applicableForTrafficDirection;
      if (obj.applicableForTrafficType)
        op.applicableForTrafficType = obj.applicableForTrafficType;
      if (obj.complianceOption) op.complianceOption = obj.complianceOption;
      if (obj.roadOrCarriagewayOrLaneManagement)
        op.roadOrCarriagewayOrLaneManagement = RoadOrCarriagewayOrLaneManagement.from(
          obj.roadOrCarriagewayOrLaneManagement
        );
    }
    return op;
  }
}

export class RoadOrCarriagewayOrLaneManagement {
  roadOrCarriagewayOrLaneManagementType;

  static from(obj) {
    let op = new RoadOrCarriagewayOrLaneManagement();
    if (obj) {
      if (obj.roadOrCarriagewayOrLaneManagementType)
        op.roadOrCarriagewayOrLaneManagementType =
          obj.roadOrCarriagewayOrLaneManagementType;
    }
    return op;
  }
}

export class Impact {
  numberOfOperationalLanes;
  capacityRemaining;
  numberOfLanesRestricted;
  originalNumberOfLanes;
  trafficConstrictionType;
  speedRemaining;
  lanesRestricted;

  static from(obj) {
    let imp = new Impact();
    if (obj) {
      if (obj.numberOfOperationalLanes || obj.numberOfOperationalLanes === 0)
        imp.numberOfOperationalLanes = obj.numberOfOperationalLanes;
      if (obj.capacityRemaining || obj.capacityRemaining === 0)
        imp.capacityRemaining = obj.capacityRemaining;
      if (obj.numberOfLanesRestricted || obj.numberOfLanesRestricted === 0)
        imp.numberOfLanesRestricted = obj.numberOfLanesRestricted;
      if (obj.originalNumberOfLanes || obj.originalNumberOfLanes === 0)
        imp.originalNumberOfLanes = obj.originalNumberOfLanes;
      if (obj.trafficConstrictionType)
        imp.trafficConstrictionType = obj.trafficConstrictionType;
      if (obj.speedRemaining || obj.speedRemaining === 0)
        imp.speedRemaining = obj.speedRemaining;
      if (obj.lanesRestricted || obj.lanesRestricted === 0)
        imp.lanesRestricted = obj.lanesRestricted;
    }
    return imp;
  }
}

export class Validity {
  overrunning;
  validityStatus;
  validityTimeSpecification;

  static from(obj, fromBe) {
    let validity = new Validity();
    if (obj) {
      if (obj.overrunning) validity.overrunning = obj.overrunning;
      if (obj.validityStatus && obj.validityTimeSpecification)
        validity.validityStatus = setValidityStatusEvent(
          obj.validityTimeSpecification.overallStartTime,
          obj.validityTimeSpecification.overallEndTime,
          obj.validityStatus,
          fromBe
        );
      if (obj.validityTimeSpecification)
        validity.validityTimeSpecification = ValidityTimeSpecification.from(
          obj.validityTimeSpecification
        );
    }
    return validity;
  }
}

export class ValidityTimeSpecification {
  overallStartTime;
  overallEndTime;

  static from(obj) {
    let period = new ValidityTimeSpecification();
    if (obj) {
      if (obj.overallStartTime) period.overallStartTime = obj.overallStartTime;
      if (obj.overallEndTime) period.overallEndTime = obj.overallEndTime;
    }
    return period;
  }
}

export class Source {
  reliable;
  sourceCountry;
  sourceIdentification;
  sourceName;
  sourceType;
  sourceReference;

  static from(obj) {
    let source = new Source();
    if (obj) {
      if (obj.reliable) source.reliable = obj.reliable;
      if (obj.sourceCountry) source.sourceCountry = obj.sourceCountry;
      if (obj.sourceIdentification)
        source.sourceIdentification = obj.sourceIdentification;
      if (obj.sourceName) source.sourceName = Multilingual.from(obj.sourceName);
      if (obj.sourceType) source.sourceType = obj.sourceType;
      if (obj.sourceReference) source.sourceReference = obj.sourceReference;
    }
    return source;
  }
}

export class Multilingual {
  content;
  language;

  static from(obj) {
    let mul = new ValidityTimeSpecification();
    if (obj) {
      if (obj.content) mul.content = obj.content;
      if (obj.language) mul.language = obj.language;
    }
    return mul;
  }
}
