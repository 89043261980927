import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as moment from "moment";
import "./style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";

//ACTIONS
import { getCurrentEvent, getSelectModal } from "store";

//UTIL
import {
  PROBABILITY_OF_OCCURRENCE,
  EVENT_STATE,
  SOURCE_TYPE,
  GRAVITY_TYPE,
} from "utils/utils";
import { getIconEvent } from "utils/utils_event_icon";
import { eventLocalizationToShowUtil } from "utils/utils";
import { getTypeTranslation, getSubtypeTranslation } from "utils/util-events";

class EventDetail extends Component {
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  header = () => {
    const { toggleDetails, currentEvent, selectModal } = this.props;
    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div
          className="uk-position-relative height-width-48"
          /* className={
        (currentEvent && currentEvent.situationId
          ? "iconEventAndPractice"
          : "color-grey") + " uk-position-relative height-width-48"
      }
      uk-icon={"icon: " + getIconEventsBg(currentEvent)} */
        >
          <img
            alt={currentEvent.situationRecordType}
            src={getIconEvent(currentEvent, selectModal)}
          />
        </div>
        <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-muted-event">
          {getSubtypeTranslation(currentEvent, selectModal)}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            toggleDetails(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClickEventPractice } = this.props;
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickEventPractice();
            }}
          >
            <a href="/#">Pratica Evento</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { currentEvent, selectModal } = this.props;

    let arrLocations =
      currentEvent &&
      currentEvent.groupOfLocations &&
      currentEvent.groupOfLocations.length > 0
        ? eventLocalizationToShowUtil(currentEvent.groupOfLocations)
        : [];

    return (
      <div
        className={
          currentEvent && currentEvent.situationId
            ? "uk-flex uk-flex-column contentBodyEventDetail"
            : "uk-flex uk-flex-column contentBodyEventDetail paddingTopSmall"
        }
      >
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-event-detail">Tipo Evento</div>
          <div className="text-detail-emphasis-event-detail">
            {getTypeTranslation(currentEvent, selectModal)}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">
            Sottotipo Evento
          </div>
          <div className="text-detail-emphasis-event-detail subType">
            {getSubtypeTranslation(currentEvent, selectModal)}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">ID Evento</div>
          <div className="text-detail-emphasis-event-detail">
            {currentEvent && currentEvent.situationRecordId
              ? currentEvent.situationRecordId
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">ID Pratica</div>
          <div className="text-detail-emphasis-event-detail">
            {currentEvent && currentEvent.situationId
              ? currentEvent.situationId
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">Localizzazione</div>
          <div
            uk-tooltip={"title: " + arrLocations.toString()}
            className="text-detail-emphasis-event-detail p-dot"
          >
            {arrLocations.toString()}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">Inizio</div>{" "}
          <div className="text-detail-emphasis-event-detail">
            {currentEvent &&
            currentEvent.validity &&
            currentEvent.validity.validityTimeSpecification &&
            currentEvent.validity.validityTimeSpecification.overallStartTime
              ? currentEvent.validity.validityTimeSpecification.overallStartTime.includes(
                  "T"
                )
                ? moment(
                    currentEvent.validity.validityTimeSpecification
                      .overallStartTime
                  ).format("DD/MM/YY - HH:mm")
                : currentEvent.validity.validityTimeSpecification
                    .overallStartTime
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">Fine</div>
          <div className="text-detail-emphasis-event-detail">
            {currentEvent &&
            currentEvent.validity &&
            currentEvent.validity.validityTimeSpecification &&
            currentEvent.validity.validityTimeSpecification.overallEndTime
              ? currentEvent.validity.validityTimeSpecification.overallEndTime.includes(
                  "T"
                )
                ? moment(
                    currentEvent.validity.validityTimeSpecification
                      .overallEndTime
                  ).format("DD/MM/YY - HH:mm")
                : currentEvent.validity.validityTimeSpecification.overallEndTime
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">
            Livello di gravità
          </div>
          <div className="text-detail-emphasis-event-detail">
            {currentEvent && currentEvent.severity
              ? GRAVITY_TYPE[currentEvent.severity]
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">Fonte</div>
          <div className="text-detail-emphasis-event-detail">
            {currentEvent &&
            currentEvent.source &&
            currentEvent.source.sourceReference
              ? SOURCE_TYPE[currentEvent.source.sourceReference]
              : "-"}{" "}
            {currentEvent &&
            currentEvent.source &&
            currentEvent.source.sourceName &&
            currentEvent.source.sourceName.content
              ? " (" +
                currentEvent.source.sourceName.content +
                ") " +
                currentEvent.source.sourceIdentification
              : ""}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">
            Probabilità accadimento
          </div>
          <div className="text-detail-emphasis-event-detail">
            {currentEvent && currentEvent.probabilityOfOccurrence
              ? PROBABILITY_OF_OCCURRENCE[currentEvent.probabilityOfOccurrence]
              : "–"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">Stato</div>
          <div
            className={
              currentEvent &&
              currentEvent.validity &&
              currentEvent.validity.validityStatus
                ? EVENT_STATE[
                    currentEvent.validity.validityStatus.toLowerCase()
                  ] === "Attivo"
                  ? "text-detail-emphasis-event-detail green"
                  : EVENT_STATE[
                      currentEvent.validity.validityStatus.toLowerCase()
                    ] === "Pianificato"
                  ? "text-detail-emphasis-event-detail blue"
                  : "text-detail-emphasis-event-detail orange"
                : "–"
            }
          >
            {currentEvent &&
            currentEvent.validity &&
            currentEvent.validity.validityStatus
              ? EVENT_STATE[currentEvent.validity.validityStatus.toLowerCase()]
              : "–"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">Note</div>
          <div className="text-detail-emphasis-event-detail">
            {currentEvent &&
            currentEvent.generalPublicComment &&
            currentEvent.generalPublicComment.length > 0 &&
            currentEvent.generalPublicComment[0].comment
              ? currentEvent.generalPublicComment[0].comment.content
              : "-"}
          </div>
        </li>
      </div>
    );
  };

  render() {
    const { currentEvent } = this.props;

    return (
      <div>
        {this.header()}
        {currentEvent && currentEvent.situationId && this.tabs()}
        {this.body()}
      </div>
    );
  }
}

EventDetail.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  currentEvent: getCurrentEvent(state),
  selectModal: getSelectModal(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventDetail)
);
