export const WorkspaceActions = {
  SET_WORKSPACES: "USER/SET_WORKSPACES",
  SET_CURRENT_WORKSPACE: "USER/SET_CURRENT_WORKSPACE",
  ADD_WORKSPACE: "USER/ADD_WORKSPACE",
  UPDATE_WORKSPACE: "USER/UPDATE_WORKSPACE",
  DELETE_WORKSPACE: "USER/DELETE_WORKSPACE",
};

export const setWorkspaces = (workspaces) => (dispatch) => {
  dispatch({
    type: WorkspaceActions.SET_WORKSPACES,
    payload: { workspaces },
  });
};

export const setCurrentWorkspace = (ws) => (dispatch) => {
  dispatch({
    type: WorkspaceActions.SET_CURRENT_WORKSPACE,
    payload: { ws },
  });
};

export const deleteWorkspace = (ws) => (dispatch) => {
  dispatch({
    type: WorkspaceActions.DELETE_WORKSPACE,
    payload: { ws },
  });
};

export const addWorkspace = (ws) => (dispatch) => {
  dispatch({
    type: WorkspaceActions.ADD_WORKSPACE,
    payload: { ws },
  });
};

export const updateWorkspace = (ws) => (dispatch) => {
  dispatch({
    type: WorkspaceActions.UPDATE_WORKSPACE,
    payload: { ws },
  });
};
