export const TABLE_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: false,
    order: false,
  },
  beginning: {
    name: "Inizio",
    id: "beginning",
    show: true,
    order: false,
  },
  end: {
    name: "Fine",
    id: "end",
    show: true,
    order: false,
  },
  measure: {
    name: "Variabile",
    id: "measure",
    show: true,
    order: false,
  },
  vehicles: {
    name: "Veicoli Equivalenti",
    id: "vehicles",
    show: true,
    order: false,
  },
  reliability: {
    name: "Affidabilità [%]",
    id: "reliability",
    show: true,
    order: false,
  },
};
