export const ParkingActions = {
  SET_LIST_PARKINGS: "PARKING/SET_LIST_PARKINGS",
  SET_CURRENT_PARKING: "PARKING/SET_CURRENT_PARKING",
  UPDATE_CURRENT_PARKING: "PARKING/UPDATE_CURRENT_PARKING",
  RESET_CURRENT_PARKING: "PARKING/RESET_CURRENT_PARKING",
  UPDATE_PARKING_STATE_WS: "PARKING/UPDATE_PARKING_STATE_WS",
  RESET_PARKING_STATE_WS: "PARKING/RESET_PARKING_STATE_WS",
};

export const setParkingsList = (list) => (dispatch) => {
  dispatch({
    type: ParkingActions.SET_LIST_PARKINGS,
    payload: { list },
  });
};

export const setCurrentParking = (current) => (dispatch) => {
  dispatch({
    type: ParkingActions.SET_CURRENT_PARKING,
    payload: current,
  });
};
export const updateCurrentParking = (current) => (dispatch) => {
  dispatch({
    type: ParkingActions.UPDATE_CURRENT_PARKING,
    payload: current,
  });
};
export const resetCurrentParking = () => (dispatch) => {
  dispatch({
    type: ParkingActions.RESET_CURRENT_PARKING,
  });
};

export const updateParkingStatesWS = (states) => (dispatch) => {
  dispatch({
    type: ParkingActions.UPDATE_PARKING_STATE_WS,
    payload: states,
  });
};

export const resetParkingStatesWS = () => (dispatch) => {
  dispatch({
    type: ParkingActions.RESET_PARKING_STATE_WS,
  });
};
