import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SelectionPanel from "./modal-panel-selection";
import MessageDefinition from "components/layout/modal/event/new-event/info-tab/modal-message-definition";
import "components/layout/modal/style.less";

class InfoTab extends Component {
  subscriptions = [];
  constructor(props) {
    super(props);
    this.state = {
      tabInfoManagement: false,
    };
  }

  changeTab1 = () => {
    this.setState({ tabInfoManagement: false });
  };

  changeTab2 = () => {
    this.setState({ tabInfoManagement: true });
  };

  infoTabs = () => {
    const { tabInfoManagement } = this.state;
    return (
      <div className="uk-form-horizontal">
        <div className="padding-tab-InfoManagement">
          <ul className="uk-tab ">
            <li className={tabInfoManagement ? null : "uk-active"}>
              <a
                onClick={(event) => {
                  event.preventDefault();
                  this.changeTab1();
                }}
                href="/#"
                className="panelSelectionTab"
              >
                Scelta Pannelli PMV
              </a>
            </li>
            <li className={tabInfoManagement ? "uk-active" : null}>
              <a
                onClick={(event) => {
                  event.preventDefault();
                  this.changeTab2();
                }}
                href="/#"
                className="messageDefinitionTab"
              >
                Definizione Messaggi
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  render() {
    const { tabInfoManagement } = this.state;
    return (
      <div>
        {this.infoTabs()}
        {tabInfoManagement ? <MessageDefinition /> : <SelectionPanel />}
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfoTab)
);
