import React, { Component } from "react";
import "./style.less";
import * as moment from "moment";
import logo_atv from "assets/icon/road-events/logo_atv.svg";

class BusTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  componentDidMount() {
    const height = this.divElement.clientHeight;
    this.setState({ height });
  }

  render = () => {
    const { x, y, object, toBeFollowed, currentVehicle } = this.props;
    let vehicle =
      object &&
      object.vehicleActivityLocation &&
      object.vehicleActivityLocation.monitoredVehicleJourney;
    let tempArrTrip = vehicle.framedVehicleJourneyRef.datedVehicleJourneyRef.split(
      "_"
    );
    let trip =
      tempArrTrip.length >= 4
        ? tempArrTrip[2] + "_" + tempArrTrip[3]
        : vehicle.framedVehicleJourneyRef.datedVehicleJourneyRef;

    return (
      <div
        className="show-tooltip"
        ref={(divElement) => {
          this.divElement = divElement;
        }}
        style={{ left: x - 145, top: y - (this.state.height + 20) }}
      >
        <div className="uk-flex uk-flex-column">
          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin-right">
            <div className="tooltip-text-title">
              Vettura {trip ? trip : "N/D"}
            </div>
            <img
              alt=""
              style={{ width: "50px", height: "25px" }}
              src={logo_atv}
            />
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text">Linea: </div>
            <div className="tooltip-text-important">
              {" "}
              {vehicle.publishedLineName
                ? vehicle.publishedLineName
                : vehicle.lineRef}
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text">Corsa: </div>
            <div className="tooltip-text-important"> {trip}</div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text">Turno: </div>
            <div className="tooltip-text-important">
              {vehicle.serviceFeatureRef ? vehicle.serviceFeatureRef : ""}
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <div className="tooltip-text">Autista: </div>
              <div className="tooltip-text-important"> 2121 </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text">Ultima: </div>
            <div className="tooltip-text-important uk-flex uk-flex-row">
              {" "}
              {vehicle.monitoredCall && vehicle.monitoredCall.stopPointName
                ? vehicle.monitoredCall.stopPointName
                : ""}
              (
              {vehicle.monitoredCall && vehicle.monitoredCall.actualArrivalTime
                ? moment(vehicle.monitoredCall.actualArrivalTime).format(
                    "HH:mm"
                  )
                : ""}
              )
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text">
              {vehicle && vehicle.delay && vehicle.delay.advance
                ? "Anticipo: "
                : "Ritardo: "}
            </div>
            <div className="tooltip-text-important">
              {" "}
              {vehicle && vehicle.delay && vehicle.delay.delay
                ? vehicle.delay.delay
                : "In orario"}
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            {!toBeFollowed ? (
              <div className="tooltip-text-black">
                Clicca sull'icona per seguire l'autobus
              </div>
            ) : toBeFollowed &&
              vehicle &&
              vehicle.framedVehicleJourneyRef &&
              currentVehicle &&
              currentVehicle.vehicleActivityLocation &&
              currentVehicle.vehicleActivityLocation.monitoredVehicleJourney
                .framedVehicleJourneyRef.datedVehicleJourneyRef ===
                vehicle.framedVehicleJourneyRef.datedVehicleJourneyRef ? (
              <div className="tooltip-text-black">
                Clicca per smettere di seguire
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
}

export default BusTooltip;
