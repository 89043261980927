import React, { Component } from "react";
import "./style.less";

class HookSearchStreet extends Component {
  render() {
    const {
      isControlOpen,
      toggleSearchStreet,
      // inputSearchStreet,
      // handleChangeInputName,
      // handleChangeInputKmStart,
      // handleChangeInputKmEnd,
      // handleSearchStreet,
      // handleResetSearchStreet,
    } = this.props;

    return (
      <div>
        <div
          className={`container-search-street ${
            !isControlOpen ? "container-move-to-right" : ""
          }`}
        >
          <div className="uk-width-1-1 uk-height-1-1 uk-card uk-card-primary uk-card-body uk-padding-small">
            <div className="uk-flex uk-flex-row uk-flex-middle">
              <h5 className="uk-width-1-2 uk-margin-remove uk-card-title-small">
                RICERCA STRADA
              </h5>
              <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
                {/*<span
                  className="uk-margin-small-right cursor-pointer"
                  uk-icon="icon: acr-interface-new-window"
                ></span> */}
                <span
                  className="cursor-pointer"
                  uk-icon="icon: acr-interface-close"
                  onClick={(event) => {
                    event.preventDefault();
                    return toggleSearchStreet();
                  }}
                ></span>
              </div>
            </div>
            <form className="uk-padding uk-padding-remove-bottom">
              <div className="uk-margin">
                <input
                  className="uk-input"
                  type="text"
                  placeholder="Nome strada"
                />
              </div>
              <div className="uk-margin  uk-margin-medium-bottom">
                <input className="uk-input" type="text" placeholder="Civico" />
              </div>
            </form>
            <div className="uk-width-1-1 uk-flex uk-flex-around uk-padding-small  ">
              <button
                className="uk-button uk-button-small uk-button-default "
                onClick={(event) => {
                  event.preventDefault();
                  // handleResetSearchStreet();
                }}
              >
                Annulla
              </button>
              <button
                className="uk-button uk-button-small uk-button-primary"
                onClick={(event) => {
                  event.preventDefault();
                  // handleSearchStreet();
                }}
              >
                Cerca
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HookSearchStreet;
