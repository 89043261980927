import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";
class TmcPointsLayer extends CompositeLayer {
  renderLayers() {
    const {
      data,
      layout,
      visible,
      onClick,
      pointRdsTmcReducer,
      isDrawingMode,
    } = this.props;
    return new IconLayer(
      this.getSubLayerProps({
        id: "icon-points",
        data: data,
        pickable: isDrawingMode ? false : true,
        ...DeckLayerUtil.loadOptions(),
        getIcon: (obj) => ({
          url: DeckLayerUtil.getIconPointTmc(obj, layout, pointRdsTmcReducer),
          width: 40,
          height: 40,
          anchorX: 20,
          anchorY: 20,
        }),
        sizeScale: 6,
        showCluster: false,
        visible: visible,
        onClick: onClick,
        getPosition: (d) => d.geometry.coordinates,
        getSize: (data) =>
          (data && data.selected) ||
          (pointRdsTmcReducer &&
            data.id === pointRdsTmcReducer.id &&
            pointRdsTmcReducer.selected)
            ? 10
            : 8,
      })
    );
  }
}

TmcPointsLayer.layerName = "TmcPointsLayer";

export default TmcPointsLayer;
