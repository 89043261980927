import {
  VehicleLocationModel,
  MonitoredVehicleJourneyModel,
} from "reducers/vehicles/vehicles.model";

export const INITIAL_STATE_TRIPS = {
  trips: [],
  current: null,
  filtered: [],
  cityBus: [],
  regionBus: [],
  cityBusFiltered: [],
  regionBusFiltered: [],
};

export default class VehicleActivitiesModel {
  itemIdentifier;
  recordedAtTime;
  vehicleMonitoringRef;
  vehicleActivityNote;
  monitoredVehicleJourney;
  progressBetweenStops;
  validUntilTime;
  constructor() {
    this.itemIdentifier = null;
    this.recordedAtTime = null;
    this.vehicleActivityNote = null;
    this.vehicleMonitoringRef = null;
    this.monitoredVehicleJourney = MonitoredVehicleJourneyModel;
    this.progressBetweenStops = null;
    this.validUntilTime = null;
  }
  static fromREST(obj) {
    var trip = new VehicleActivitiesModel();
    if (obj.itemIdentifier) trip.itemIdentifier = obj.itemIdentifier;
    if (obj.recordedAtTime) trip.recordedAtTime = obj.recordedAtTime;
    if (obj.validUntilTime) trip.validUntilTime = obj.validUntilTime;
    if (obj.vehicleActivityNote)
      trip.vehicleActivityNote = obj.vehicleActivityNote;
    if (obj.progressBetweenStops)
      trip.progressBetweenStops = ProgressBetweenStopsModel.from(
        obj.progressBetweenStops
      );
    if (obj.vehicleMonitoringRef)
      trip.vehicleMonitoringRef = obj.vehicleMonitoringRef;
    if (obj.monitoredVehicleJourney)
      trip.monitoredVehicleJourney = MonitoredVehicleJourneyModel.from(
        obj.monitoredVehicleJourney
      );
    return trip;
  }
  static fromArrayREST(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map((x) => VehicleActivitiesModel.fromREST(x))
      .filter((item) => item !== null);
  }
}

export class VehicleRecords {
  itemIdentifier;
  recordedAtTime;
  vehicleMonitoringRef;
  vehicleActivityNote;
  monitoredVehicleJourney;
  validUntilTime;
  constructor() {
    this.itemIdentifier = null;
    this.recordedAtTime = null;
    this.vehicleActivityNote = null;
    this.vehicleMonitoringRef = null;
    this.monitoredVehicleJourney = MonitoredVehicleJourneyModel;
    this.validUntilTime = null;
  }
  static fromREST(obj) {
    var trip = new VehicleRecords();
    if (obj.itemIdentifier) trip.itemIdentifier = obj.itemIdentifier;
    if (obj.recordedAtTime) trip.recordedAtTime = obj.recordedAtTime;
    if (obj.validUntilTime) trip.validUntilTime = obj.validUntilTime;
    if (obj.vehicleActivityNote)
      trip.vehicleActivityNote = obj.vehicleActivityNote;
    if (obj.vehicleMonitoringRef)
      trip.vehicleMonitoringRef = obj.vehicleMonitoringRef;
    if (obj.monitoredVehicleJourney)
      trip.monitoredVehicleJourney = MonitoredVehicleJourneyModel.from(
        obj.monitoredVehicleJourney
      );
    return trip;
  }
  static fromArrayREST(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map((x) => VehicleRecords.fromREST(x))
      .filter((item) => item !== null);
  }
}

export class CallModel {
  actualArrivalTime;
  actualDepartureTime;
  aimedArrivalTime;
  aimedDepartureTime;
  aimedHeadwayInterval;
  arrivalBoardingActivity;
  arrivalPlatformName;
  arrivalProximityTextName;
  arrivalProximityTextRef;
  arrivalStatus;
  arrivalStopAssignment;
  callNote;
  departureBoardingActivity;
  departurePlatformName;
  departureProximityTextName;
  departureProximityTextRef;
  departureStatus;
  departureStopAssignment;
  destinationDisplay;
  distanceFromStop;
  earliestExpectedDepartureTime;
  expectedArrivalTime;
  expectedDepartureTime;
  expectedHeadwayInterval;
  extensions;
  numberOfStopsAway;
  order;
  platformTraversal;
  provisionalExpectedDepartureTime;
  reversesAtStop;
  stopPointName;
  stopPointRef;
  timingPoint;
  vehicleAtStop;
  vehicleLocationAtStop;
  visitNumber;
  constructor() {
    this.actualArrivalTime = null;
    this.actualDepartureTime = null;
    this.aimedArrivalTime = null;
    this.aimedDepartureTime = null;
    this.aimedHeadwayInterval = null;
    this.arrivalBoardingActivity = null;
    this.arrivalPlatformName = null;
    this.arrivalProximityTextName = null;
    this.arrivalProximityTextRef = null;
    this.arrivalStatus = null;
    this.arrivalStopAssignment = StopAssignmentModel;
    this.callNote = null;
    this.departureBoardingActivity = null;
    this.departurePlatformName = null;
    this.departureProximityTextName = null;
    this.departureProximityTextRef = null;
    this.departureStatus = null;
    this.departureStopAssignment = StopAssignmentModel;
    this.destinationDisplay = null;
    this.distanceFromStop = null;
    this.earliestExpectedDepartureTime = null;
    this.expectedArrivalTime = null;
    this.expectedDepartureTime = null;
    this.expectedHeadwayInterval = null;
    this.extensions = null;
    this.numberOfStopsAway = null;
    this.order = null;
    this.platformTraversal = false;
    this.provisionalExpectedDepartureTime = null;
    this.reversesAtStop = false;
    this.stopPointName = null;
    this.stopPointRef = null;
    this.timingPoint = false;
    this.vehicleAtStop = false;
    this.vehicleLocationAtStop = VehicleLocationModel;
    this.visitNumber = null;
  }
  static from(obj) {
    var monitor = new CallModel();
    if (obj.actualArrivalTime)
      monitor.actualArrivalTime = obj.actualArrivalTime;
    if (obj.actualDepartureTime)
      monitor.actualDepartureTime = obj.actualDepartureTime;
    if (obj.aimedArrivalTime) monitor.aimedArrivalTime = obj.aimedArrivalTime;
    if (obj.aimedDepartureTime)
      monitor.aimedDepartureTime = obj.aimedDepartureTime;
    if (obj.aimedHeadwayInterval)
      monitor.aimedHeadwayInterval = obj.aimedHeadwayInterval;
    if (obj.arrivalBoardingActivity)
      monitor.arrivalBoardingActivity = obj.arrivalBoardingActivity;
    if (obj.arrivalPlatformName)
      monitor.arrivalPlatformName = obj.arrivalPlatformName;
    if (obj.arrivalProximityTextName)
      monitor.arrivalProximityTextName = obj.arrivalProximityTextName;
    if (obj.arrivalProximityTextRef)
      monitor.arrivalProximityTextRef = obj.arrivalProximityTextRef;
    if (obj.arrivalStatus) monitor.arrivalStatus = obj.arrivalStatus;
    if (obj.arrivalStopAssignment)
      monitor.arrivalStopAssignment = StopAssignmentModel.from(
        obj.arrivalStopAssignment
      );
    if (obj.callNote) monitor.callNote = obj.callNote;
    if (obj.departureBoardingActivity)
      monitor.departureBoardingActivity = obj.departureBoardingActivity;
    if (obj.departurePlatformName)
      monitor.departurePlatformName = obj.departurePlatformName;
    if (obj.departureProximityTextName)
      monitor.departureProximityTextName = obj.departureProximityTextName;
    if (obj.departureProximityTextRef)
      monitor.departureProximityTextRef = obj.departureProximityTextRef;
    if (obj.departureStatus) monitor.departureStatus = obj.departureStatus;
    if (obj.departureStopAssignment)
      monitor.departureStopAssignment = StopAssignmentModel.from(
        obj.departureStopAssignment
      );
    if (obj.destinationDisplay)
      monitor.destinationDisplay = obj.destinationDisplay;
    if (obj.distanceFromStop) monitor.distanceFromStop = obj.distanceFromStop;
    if (obj.earliestExpectedDepartureTime)
      monitor.earliestExpectedDepartureTime = obj.earliestExpectedDepartureTime;
    if (obj.expectedArrivalTime)
      monitor.expectedArrivalTime = obj.expectedArrivalTime;
    if (obj.expectedDepartureTime)
      monitor.expectedDepartureTime = obj.expectedDepartureTime;
    if (obj.expectedHeadwayInterval)
      monitor.expectedHeadwayInterval = obj.expectedHeadwayInterval;
    if (obj.extensions) monitor.extensions = obj.extensions;
    if (obj.numberOfStopsAway)
      monitor.numberOfStopsAway = obj.numberOfStopsAway;
    if (obj.order) monitor.order = obj.order;
    if (obj.platformTraversal)
      monitor.platformTraversal = obj.platformTraversal;
    if (obj.provisionalExpectedDepartureTime)
      monitor.provisionalExpectedDepartureTime =
        obj.provisionalExpectedDepartureTime;
    if (obj.reversesAtStop) monitor.reversesAtStop = obj.reversesAtStop;
    if (obj.stopPointName) monitor.stopPointName = obj.stopPointName;
    if (obj.stopPointRef) monitor.stopPointRef = obj.stopPointRef;
    if (obj.timingPoint) monitor.timingPoint = obj.timingPoint;
    if (obj.vehicleAtStop) monitor.vehicleAtStop = obj.vehicleAtStop;
    if (obj.vehicleLocationAtStop)
      monitor.vehicleLocationAtStop = VehicleLocationModel.from(
        obj.vehicleLocationAtStop
      );
    if (obj.visitNumber) monitor.visitNumber = obj.visitNumber;
    return monitor;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(CallModel.from).filter((item) => item !== null);
  }
}

export class StopAssignmentModel {
  aimedQuayRef;
  aimedQuayName;
  expectedQuayRef;
  expectedQuayName;
  actualQuayName;
  actualQuayRef;
  constructor() {
    this.aimedQuayRef = null;
    this.aimedQuayName = null;
    this.expectedQuayRef = null;
    this.expectedQuayName = null;
    this.actualQuayName = null;
    this.actualQuayRef = null;
  }
  static from(obj) {
    var stop = new StopAssignmentModel();
    if (obj.aimedQuayRef) stop.aimedQuayRef = obj.aimedQuayRef;
    if (obj.aimedQuayName) stop.aimedQuayName = obj.aimedQuayName;
    if (obj.expectedQuayRef) stop.expectedQuayRef = obj.expectedQuayRef;
    if (obj.expectedQuayName) stop.expectedQuayName = obj.expectedQuayName;
    if (obj.actualQuayName) stop.actualQuayName = obj.actualQuayName;
    if (obj.actualQuayRef) stop.actualQuayRef = obj.actualQuayRef;
    return stop;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(StopAssignmentModel.from).filter((item) => item !== null);
  }
}

export class ProgressBetweenStopsModel {
  linkDistance;
  percentage;
  constructor() {
    this.linkDistance = null;
    this.percentage = null;
  }
  static from(object) {
    var prog = new ProgressBetweenStopsModel();
    if (object.linkDistance) prog.linkDistance = object.linkDistance;
    if (object.percentage) prog.percentage = object.percentage;
    return prog;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(ProgressBetweenStopsModel.from)
      .filter((item) => item !== null);
  }
}
