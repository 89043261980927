import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Permission from "components/shared/permission";
import {
  isOpenNavTab,
  isStationVisible,
  isOpenNavStation,
  getPermissions,
} from "store";
import { setStationVisibility } from "reducers/ui/installations-menu/installations-menu.actions";
import {
  toggleOpenNavTab,
  setNavTabMultitab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { resetCurrentStation } from "reducers/installations/stations/stations.actions";

const mapStateToProps = (state) => ({
  isOpenNavTab: isOpenNavTab(state),
  isStationVisible: isStationVisible(state),
  isOpenNavStation: isOpenNavStation(state),
  permissions: getPermissions(state),
});

const mapDispatchToProps = {
  toggleOpenNavTab,
  setStationVisibility,
  setNavTabMultitab,
  resetCurrentStation,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemIntallationStation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isStationVisible,
      isOpenNavStation,
      clickOnStationCheckbox,
      clickOnStationLabel,
    } = this.props;

    return (
      <Permission actions="STATION">
        <div
          className={
            isOpenNavStation
              ? "uk-checkbox-title uk-open acr-stations-open"
              : "uk-checkbox-title "
          }
        >
          <span>
            <input
              className="uk-checkbox uk-checkbox-large "
              type="checkbox"
              checked={isStationVisible || false}
              onChange={() => clickOnStationCheckbox()}
            />
          </span>
          <label
            className="checkbox-stations"
            onClick={() => clickOnStationLabel()}
          >
            Stazioni di Misura
          </label>
        </div>
      </Permission>
    );
  }
}

export default NavItemIntallationStation;
