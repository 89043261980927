import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  take,
  tap,
  catchError,
  switchMap,
  debounceTime,
  concatMap,
} from "rxjs/operators";
import { BehaviorSubject, Observable, of } from "rxjs";
import "../../style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import moment from "moment";

//COMPONENTS
import Loading from "components/shared/loading/loading";
import TableHead from "components/shared/table-components/table-head";
import TableBody from "components/shared/table-components/table-body";
import TableFilters from "components/shared/table-top-filters/table-filters";
import PageNumbers from "components/shared/page-numbers/page-number";
//STORE
import {
  isOpenNavTab,
  getSavedTableColumns,
  getSavedDateColumns,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedSearchText,
  getSavedFilters,
  getSavedToggledFilters,
  getUsername,
  getUserRoles,
} from "store";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { addActivity } from "reducers/user/user.actions";
// MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";
import { UserActivityModel } from "reducers/user/user.model";
//UTILS
import {
  TABLE_COLUMNS,
  DATE_COLUMNS,
  initColumns,
} from "utils/table-utils/util-tmc-table";
import * as FilterUtils from "utils/filter-utils";
import { debounce } from "utils/utils";
import {
  handleFilterStringChange,
  handleClickIncreasePage,
  handleClickDecreasePage,
  requestSortOrder,
  getCurrentPageNumber,
  sortByDirectionTranslation,
  resetFiltersState,
  initializeTableState,
  getTableStateSortConfig,
} from "utils/table-utils/util-general-tables";
import { saveBlob } from "utils/hooks";

const FILTER_TARGET = "tmcSegmentsFilters";

class TmcTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      currentListSegments: [],
      stateTableColumns: TABLE_COLUMNS,
      sortConfig: getTableStateSortConfig("segment"),
      ...initializeTableState,
    };
    this.getApiSegmentsListDebounced = debounce(this.getApiSegmentsList, 800);
    this.numberPage$ = new BehaviorSubject();
  }

  componentDidMount = () => {
    const { searchText } = this.props;

    this.apiService = createApiService(this.context);
    this.subscriptions.push(
      this.numberPage$
        .pipe(
          debounceTime(800),
          switchMap(
            (x) =>
              new Observable((observer) => {
                const { pageToDisplay } = this.state;
                if (pageToDisplay !== "") {
                  this.getApiSegmentsList();
                }
              })
          )
        )
        .subscribe()
    );

    if (searchText) {
      this.setState({ filterStr: searchText, isTableDataLoading: true });
    } else {
      this.setState({
        ...this.state,
        isTableDataLoading: true,
      });
    }
    this.tableConfigs();
  };

  tableConfigs = () => {
    const {
      tableColumns,
      dateColumns,
      saveTableFilters,
      searchText,
    } = this.props;
    const { columns, date } = initColumns(tableColumns, dateColumns);
    let sld = FilterUtils.constructFlatMultiSelect(columns);
    let obj = {};
    sld.forEach((x) => {
      obj[x.name] = { ...x };
    });
    saveTableFilters(
      {
        tableColumns: columns,
        dateColumns: date,
        dateFrom: null,
        dateTo: null,
        searchText: searchText || "",
        toggledFilters: obj || {},
      },
      FILTER_TARGET
    );
    this.setState({
      selected: sld,
      searchText: searchText || "",
    });
  };

  getApiSegmentsList = () => {
    const { count, startIndex, sortConfig } = this.state;

    const sortBy = sortByDirectionTranslation(sortConfig);
    const cql_filter = this.checkSearchString();

    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures(
          "roads",
          null,
          count,
          sortBy,
          null,
          null,
          cql_filter,
          startIndex
        )
        .pipe(
          take(1),
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "roads"
            );
            let roads = [];
            let pageMax = Math.ceil(featureCollection.totalFeatures / count);
            roads = [...featureCollection.features];
            this.setState({
              ...this.state,
              isTableDataLoading: false,
              currentListSegments: roads,
              pageMax: pageMax,
            });
          }),
          catchError((error) => {
            console.error(error);
            this.setState({
              ...this.state,
              isTableDataLoading: false,
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  //CALL API TO OBTAIN NEW ARC LIST
  refreshData = () => {
    this.setState(
      {
        ...this.state,
        currentListSegments: [],
        isTableDataLoading: true,
      },
      this.getApiSegmentsList
    );
  };

  downloadExcel = () => {
    const { count, startIndex, sortConfig } = this.state;

    //ASC/DESC DIRECTION, SEARCH FILTER AND TOGGLED FILTERS
    const sortBy = sortByDirectionTranslation(sortConfig);
    const cql_filter = this.checkSearchString();

    this.apiService
      .fetchTratteCsv(count, sortBy, cql_filter, startIndex)
      .pipe(
        tap((blob) => {
          let filename =
            "grafo_tratte_rds_tmc_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postPointDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postPointDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "ROAD_GRAPH",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Tratte Rds-Tmc"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  checkSearchString = () => {
    const { filterStr } = this.state;

    if (filterStr !== "") {
      let filterString = "";

      filterString =
        filterString +
        "name ilike '%" +
        filterStr +
        "%' OR start_road ilike '%" +
        filterStr +
        "%' OR end_road ilike '%" +
        filterStr +
        "%'";

      return filterString;
    } else {
      return null;
    }
  };

  handleFilterChange = (e) => {
    handleFilterStringChange(
      this,
      e,
      FILTER_TARGET,
      this.getApiSegmentsListDebounced
    );
  };

  // GESTIONE CHECKBOX: VISUALIZZAZIONE COLONNE
  handleSelectChange = (visibleColumns) => {
    FilterUtils.handleSelectChange(this, visibleColumns, FILTER_TARGET);
  };

  getPageNumber = (pageNumber) => {
    getCurrentPageNumber(this, pageNumber);
  };

  handleClickIncrease = () => {
    handleClickIncreasePage(this);
  };

  handleClickDecrease = () => {
    handleClickDecreasePage(this);
  };

  resetFilters = (e) => {
    const { resetTableFilters } = this.props;
    const { selected } = this.state;

    e.stopPropagation();

    Object.keys(TABLE_COLUMNS).map((_) => {
      TABLE_COLUMNS[_].checked = true;
      TABLE_COLUMNS[_].show = true;
      return _;
    });

    resetFiltersState(
      this,
      TABLE_COLUMNS,
      selected,
      this.getApiSegmentsList,
      "segment"
    );

    resetTableFilters(
      {
        tableColumns: { ...TABLE_COLUMNS },
        dateColumns: [...DATE_COLUMNS],
      },
      FILTER_TARGET
    );
  };

  requestSort = (key) => {
    requestSortOrder(this, key, this.getApiSegmentsList);
  };

  toTableColumns = (list) => {
    let newList = [];
    list.forEach((item) => {
      let newEl = {};
      if (item) {
        newEl = {
          name: item.properties.name,
          tot_length:
            item.properties.tot_length && item.properties.tot_length.toFixed(1),
          start_road: item.properties.start_road,
          end_road: item.properties.end_road,
        };
      }
      newList = [...newList, newEl];
    });

    return newList;
  };

  render = () => {
    const {
      filterStr,
      sortConfig,
      selected,
      isTableDataLoading,
      currentListSegments,
      pageMax,
      pageToDisplay,
    } = this.state;
    const {
      toggleGenericTable,
      isOpenNav,
      dateColumns,
      tableColumns,
    } = this.props;

    let listOfSegments = [];
    if (currentListSegments) {
      listOfSegments = this.toTableColumns(currentListSegments);
    }

    return (
      <div className="acr-table uk-flex uk-flex-column">
        <div className="navbar-placeholder"></div>
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <div className="grey-space"></div>
          <div className=" uk-width-1-2">
            <ul className=" uk-tab-page-secondary uk-tab uk-margin-remove">
              <li className="uk-active bottombar-title">TRATTE TMC</li>
            </ul>
          </div>
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={() => toggleGenericTable("tmcTable")}
            ></span>
          </div>
        </div>
        <TableFilters
          isOpenNav={isOpenNav}
          dateStart={{ date: null, name: "dateStartEvent" }}
          dateEnd={{ date: null, name: "dateEndEvent" }}
          handleFilterChange={this.handleFilterChange}
          handleSelectChange={this.handleSelectChange}
          resetFilters={this.resetFilters}
          filterStr={filterStr}
          filtrableDateColumns={dateColumns}
          selected={selected}
          refreshData={this.refreshData}
          disabledDataFilter={true}
        ></TableFilters>
        <div className="uk-flex uk-flex-right button-bar">
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.refreshData}
            >
              <span
                uk-icon="icon: refresh"
                uk-tooltip="Refresh tabella Tratte"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.downloadExcel}
            >
              <span
                uk-icon="icon: download"
                uk-tooltip="Download Excel"
                className="filterBarIcons borderWhite"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
              onClick={this.resetFilters}
            >
              <span
                uk-icon="icon: acr-filter-remove"
                uk-tooltip="Cancella Filtri"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-flex uk-flex-right button-bar uk-padding-remove-left">
            <PageNumbers
              handleClickDecrease={this.handleClickDecrease}
              handleClickIncrease={this.handleClickIncrease}
              getPageNumber={this.getPageNumber}
              currentPage={pageToDisplay}
              minVal={1}
              pageMax={pageMax}
            />{" "}
          </div>
        </div>
        <div className="uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left ">
          <table
            id="eventTable"
            className="uk-table uk-table-divider uk-table-hover"
          >
            <thead>
              <TableHead
                requestSort={this.requestSort}
                tableColumns={tableColumns}
                sortConfig={sortConfig}
              />
            </thead>
            <tbody>
              {isTableDataLoading ? (
                <tr>
                  <td>
                    <Loading />
                  </td>
                </tr>
              ) : (
                <TableBody list={listOfSegments} tableColumns={tableColumns} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

TmcTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleGenericTable,
  saveTableFilters,
  resetTableFilters,
  addActivity,
};

const mapStateToProps = (state) => ({
  isOpenNav: isOpenNavTab(state),
  tableColumns: getSavedTableColumns(state, FILTER_TARGET),
  dateColumns: getSavedDateColumns(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
  searchText: getSavedSearchText(state, FILTER_TARGET),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  toggledFilters: getSavedToggledFilters(state, FILTER_TARGET),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TmcTable)
);
