import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.less";

//MODEL
import { VehicleActivityModel } from "reducers/vehicles/vehicles.model";

//ACTIONS
import { getLines } from "reducers/lines/lines.actions";
import { getStops } from "reducers/stops/stops.actions";
import { setTransportVisibility } from "reducers/ui/tpl-menu/tpl-menu.actions";
import { getTrips } from "reducers/trips/trips.actions";
import { getRoutes } from "reducers/routes/routes.actions";
import { setVehicleEvents } from "reducers/vehicles/vehicles.actions";
import {
  mapResetLayer,
  toggleTabLegend,
  setBounds,
  filterByMapBounds,
} from "reducers/map/map.actions";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  resetNavTabInfoRoute,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { wsOpen } from "reducers/ui/ws/ws.actions";

//COMPONENTS
import NavTab from "components/layout/nav-tab";
import TabPanel from "components/layout/tabpanel";
import LinesList from "components/layout/lines-list";
import StopsList from "components/layout/stops-list";
import TripsList from "components/layout/trips-list";
import InfoRouteSelected from "components/layout/info-route-selected";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import { filterBounds } from "components/layout/map/load-map/load-map";
//UTILS
import EnvironmentContext from "./../../environment-context";
import { addAllLines } from "reducers/map/map.actions";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";
import { reduceDuplicatesInArrayForId } from "utils/utils";
import {
  getFilteredLines,
  getAllVehiclesEvents,
  getNavTabSelectedTab,
  getNavTabInfoRoute,
  getNavTabSearch,
  getAllLines,
  getCurrentNetwork,
  getAllActiveLines,
  getCurrentStop,
  getCurrentTrip,
  isCityBusVisible,
  isRegionBusVisible,
  getRegionBusStopsFiltered,
  getCityBusStopsFiltered,
  getCityBusTripsFiltered,
  getRegionBusTripsFiltered,
  getCityBusRoutesFiltered,
  getRegionBusRoutesFiltered,
  getAllRoutes,
  getCityBusTrips,
  getRegionBusTrips,
  getSearchTextRoutes,
  isOpenNavTab,
  getControls,
  getCurrentIdRouteForStop,
  getCurrentIdTripForStop,
  getCurrentIdRouteForVehicle,
  getCurrentIdStopForVehicle,
  getCurrentIdStopForRoute,
  getCurrentIdTripForRoute,
  getBoundingBox,
  isWSTplOpen,
  isWSTplLocationOpen,
  isLinesLoading,
  isTripsLoading,
  isTripsAllLoading,
  isStopsLoading,
  getCurrentWorkspace,
  getCurrentZoom,
} from "store";

import UIkit from "@almaviva/acr-uikit/dist/js/uikit";

class TplPage extends Component {
  subscriptions = [];
  apiService;
  lines = [];
  vehicles = [];
  timer = false;
  myTimer = null;
  constructor(props) {
    super(props);
    this.state = {
      notifyLines: false,
      notifyStops: false,
      notifyTrips: false,
      resetSearchbar: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { notifyLines, notifyStops, notifyTrips } = this.state;
    const {
      isOpenNavTab,
      controlsReducer,
      isLinesLoading,
      isTripsAllLoading,
      isStopsLoading,
    } = this.props;
    if (
      prevProps.isOpenNavTab !== isOpenNavTab ||
      (controlsReducer &&
        (controlsReducer.distanceSelected !==
          prevProps.controlsReducer.distanceSelected ||
          controlsReducer.circleSelected !==
            prevProps.controlsReducer.circleSelected ||
          controlsReducer.polygonSelected !==
            prevProps.controlsReducer.polygonSelected ||
          controlsReducer.rulerSelected !==
            prevProps.controlsReducer.rulerSelected))
    ) {
      UIkit.notification.closeAll();
      this.setState({
        notifyLines: false,
        notifyStops: false,
        notifyTrips: false,
      });
    }
    let position = "bottom-center";
    if (isOpenNavTab) {
      position = "bottom-center";
      if (
        controlsReducer &&
        (controlsReducer.distanceSelected ||
          controlsReducer.circleSelected ||
          controlsReducer.polygonSelected ||
          controlsReducer.rulerSelected)
      ) {
        position = "top-center";
      }
    } else {
      position = "bottom-left";
      if (
        controlsReducer &&
        (controlsReducer.distanceSelected ||
          controlsReducer.circleSelected ||
          controlsReducer.polygonSelected ||
          controlsReducer.rulerSelected)
      ) {
        position = "top-left";
      }
    }
    //OPEN NOTIFY
    if (isLinesLoading && !notifyLines) {
      this.setState({ notifyLines: true });
      const string =
        "<span uk-spinner='ratio: 0.5'></span>&nbsp;Caricamento linee in corso...";
      UIkit.notification({
        message: string,
        status: "primary",
        pos: position,
        timeout: 360000000,
        group: "lines-group",
      });
    }
    if (isStopsLoading && !notifyStops) {
      this.setState({ notifyStops: true });
      const string3 =
        "<span uk-spinner='ratio: 0.5'></span>&nbsp;Caricamento fermate in corso...";
      UIkit.notification({
        message: string3,
        status: "primary",
        pos: position,
        timeout: 360000000,
        group: "stops-group",
      });
    }
    if (isTripsAllLoading && !notifyTrips) {
      this.setState({ notifyTrips: true });
      const string2 =
        "<span uk-spinner='ratio: 0.5'></span>&nbsp;Caricamento corse in corso...";
      UIkit.notification({
        message: string2,
        status: "primary",
        pos: position,
        timeout: 360000000,
        group: "trips-group",
      });
    }
  }

  changeTabs = (index) => {
    const {
      setNavTabSelectedTab,
      menuTabReducer,
      setNavTabSearch,
    } = this.props;

    //EMPTY SEARCH FROM REDUCER
    setNavTabSearch(
      "",
      menuTabReducer === 0
        ? "linee"
        : menuTabReducer === 1
        ? "fermate"
        : "percorsi",
      null,
      null
    );
    //CHANGE TAB
    setNavTabSelectedTab(index);
  };

  /* inject tpl items to right sidebar */
  injectItems = () => {
    const {
      menuTabReducer,
      menuInfoRouteReducer,
      linesFilteredReducer,
      cityBusVisible,
      regionBusVisible,
      cityBusStopsFiltered,
      regionBusStopsFiltered,
      cityBusRoutesFiltered,
      regionBusRoutesFiltered,
      cityBusTripsFiltered,
      regionBusTripsFiltered,
      activeLines,
      currentStopReducer,
      tripsCurrentReducer,
      cityBusTrips,
      regionBusTrips,
      searchRoutesReducer,
      currentIdRouteForStop,
      currentIdTripForStop,
      currentIdRouteForVehicle,
      currentIdStopForVehicle,
      isTripsLoading,
      isLinesLoading,
      isStopsLoading,
      /* currentIdTripForRoute,
      currentIdStopForRoute, */
    } = this.props;

    let routes = [];
    let stops = [];
    let trips = [];
    if (cityBusVisible) {
      routes.push(...cityBusRoutesFiltered);
      stops.push(...cityBusStopsFiltered);
      if (searchRoutesReducer.length === 0) {
        trips.push(
          ...[...cityBusTripsFiltered].filter((tr) => {
            let stopsListTr = tr.monitoredVehicleJourney.previousCalls.concat(
              tr.monitoredVehicleJourney.monitoredCall,
              tr.monitoredVehicleJourney.onwardCalls
            );
            return cityBusStopsFiltered.find((s) =>
              stopsListTr.find((r) => r.stopPointRef === s.id)
            );
          })
        );
        //non si può filtrare il percorso altrimenti se non ci sono le corse non escono i rimanenti percorsi
        /* routes = routes.filter((r) =>
          trips.find(
            (t) =>
              t &&
              t.monitoredVehicleJourney &&
              t.monitoredVehicleJourney.journeyPatternRef &&
              t.monitoredVehicleJourney.journeyPatternRef === r.id
          )
        ); */
      } else {
        trips.push(...cityBusTripsFiltered);
      }
    }

    if (regionBusVisible) {
      routes.push(...regionBusRoutesFiltered);
      stops.push(...regionBusStopsFiltered);
      if (searchRoutesReducer.length === 0) {
        trips.push(
          ...[...regionBusTripsFiltered].filter((tr) => {
            let stopsListTr = tr.monitoredVehicleJourney.previousCalls.concat(
              tr.monitoredVehicleJourney.monitoredCall,
              tr.monitoredVehicleJourney.onwardCalls
            );
            return regionBusStopsFiltered.find((s) =>
              stopsListTr.find((r) => r.stopPointRef === s.id)
            );
          })
        );
        //non si può filtrare il percorso altrimenti se non ci sono le corse non escono i rimanenti percorsi
        /* routes = routes.filter((r) =>
          trips.find(
            (t) =>
              t &&
              t.monitoredVehicleJourney &&
              t.monitoredVehicleJourney.journeyPatternRef &&
              t.monitoredVehicleJourney.journeyPatternRef === r.id
          )
        ); */
      } else {
        trips.push(...regionBusTripsFiltered);
      }
    }

    if (currentStopReducer || activeLines.length !== 0 || tripsCurrentReducer) {
      if (currentStopReducer) {
        routes = routes.filter((item) =>
          currentIdRouteForStop.find(
            (curr) =>
              item &&
              item.id &&
              item.lineId &&
              curr &&
              curr.routeId &&
              curr.lineId &&
              curr.routeId === item.id &&
              curr.lineId === item.lineId
          )
        );
        trips = trips.filter((t) =>
          currentIdTripForStop.find(
            (trCurr) =>
              trCurr &&
              trCurr.id &&
              t &&
              t.monitoredVehicleJourney &&
              t.monitoredVehicleJourney.framedVehicleJourneyRef &&
              t.monitoredVehicleJourney.framedVehicleJourneyRef
                .datedVehicleJourneyRef &&
              trCurr.id ===
                t.monitoredVehicleJourney.framedVehicleJourneyRef
                  .datedVehicleJourneyRef
          )
        );
      }

      if (activeLines.length > 0) {
        stops = [];
        trips = [];
        let arrTrips = [];
        if (cityBusVisible) arrTrips.push(...cityBusTrips);
        if (regionBusVisible) arrTrips.push(...regionBusTrips);
        activeLines.forEach((route) => {
          if (route && route.pointsNonOrdered)
            stops.push(...route.pointsNonOrdered);
          arrTrips.forEach((t) => {
            if (t.monitoredVehicleJourney.journeyPatternRef === route.id) {
              trips.push(t);
            }
          });
        });
      }

      if (tripsCurrentReducer) {
        routes = routes.filter((item) =>
          currentIdRouteForVehicle.find(
            (curr) =>
              item &&
              item.id &&
              item.lineId &&
              curr &&
              curr.routeId &&
              curr.lineId &&
              curr.routeId === item.id &&
              curr.lineId === item.lineId
          )
        );
        stops = stops.filter((t) =>
          currentIdStopForVehicle.find(
            (stopCurr) =>
              stopCurr && stopCurr.id && t && t.id && stopCurr.id === t.id
          )
        );
      }
    }
    stops = reduceDuplicatesInArrayForId(stops);
    var lengthRoutes =
      activeLines.length > 0
        ? activeLines.length
        : (routes && routes.length) || 0;
    var lengthStops = currentStopReducer ? "1" : stops.length;
    var lengthTrips = tripsCurrentReducer ? "1" : trips.length;
    var labelLinee = "Percorsi (" + lengthRoutes + ")";
    var labelFermate = "Fermate (" + lengthStops + ")";
    var labelMezzi = "Corse (" + lengthTrips + ")";

    return (
      <div className="uk-height-1-1 uk-width-1-1 overflow-hidden">
        <ErrorBoundary>
          <TabPanel className="tabsContainerTpl">
            {menuInfoRouteReducer && menuInfoRouteReducer.open ? (
              <ul className="uk-tab uk-flex-between">
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeTabs(3);
                  }}
                  className={menuTabReducer === 3 ? "uk-active" : ""}
                >
                  <a href="/#">Info (1)</a>
                </li>
              </ul>
            ) : cityBusVisible || regionBusVisible ? (
              <ul className="uk-tab uk-flex-between">
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeTabs(0);
                  }}
                  className={menuTabReducer === 0 ? "uk-active" : ""}
                >
                  <a href="/#">{labelLinee}</a>
                </li>
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeTabs(1);
                  }}
                  className={menuTabReducer === 1 ? "uk-active" : ""}
                >
                  <a href="/#">{labelFermate}</a>
                </li>
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeTabs(2);
                  }}
                  className={menuTabReducer === 2 ? "uk-active" : ""}
                >
                  <a href="/#">{labelMezzi}</a>
                </li>
              </ul>
            ) : null}
            {/* <ul className="uk-tab uk-flex-between">
              {menuInfoRouteReducer && menuInfoRouteReducer.open && (
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeTabs(3);
                  }}
                  className={menuTabReducer === 3 ? "uk-active" : ""}
                >
                  <a href="/#">Info (1)</a>
                </li>
              )}
              {!menuInfoRouteReducer && !menuInfoRouteReducer.open && (
              <li
                onClick={(e) => {
                  e.preventDefault();
                  this.changeTabs(0);
                }}
                className={menuTabReducer === 0 ? "uk-active" : ""}
              >
                <a href="/#">{labelLinee}</a>
              </li>
              <li
                onClick={(e) => {
                  e.preventDefault();
                  this.changeTabs(1);
                }}
                className={menuTabReducer === 1 ? "uk-active" : ""}
              >
                <a href="/#">{labelFermate}</a>
              </li>
              <li
                onClick={(e) => {
                  e.preventDefault();
                  this.changeTabs(2);
                }}
                className={menuTabReducer === 2 ? "uk-active" : ""}
              >
                <a href="/#">{labelMezzi}</a>
              </li>)}
            </ul> */}
            {cityBusVisible || regionBusVisible ? (
              <div className="overflow">
                <TabPanel className="overflow" value={menuTabReducer} index={0}>
                  <ErrorBoundary>
                    <LinesList
                      routesList={routes}
                      linesList={linesFilteredReducer}
                      isLinesLoading={isLinesLoading}
                    />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel className="overflow" value={menuTabReducer} index={1}>
                  <ErrorBoundary>
                    <StopsList
                      isStopsLoading={isStopsLoading}
                      stopsList={stops}
                    />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel className="overflow" value={menuTabReducer} index={2}>
                  <ErrorBoundary>
                    <TripsList
                      isTripsLoading={isTripsLoading}
                      tripsList={trips}
                    />
                  </ErrorBoundary>
                </TabPanel>
              </div>
            ) : null}
            {menuInfoRouteReducer && menuInfoRouteReducer.open && (
              <TabPanel className="overflow" value={menuTabReducer} index={3}>
                <ErrorBoundary>
                  <InfoRouteSelected />
                </ErrorBoundary>
              </TabPanel>
            )}
          </TabPanel>
        </ErrorBoundary>
      </div>
    );
  };

  handleSearch = (value) => {
    const {
      setNavTabSearch,
      cityBusTrips,
      regionBusTrips,
      menuTabReducer,
      currentStopReducer,
      tripsCurrentReducer,
      activeLines,
    } = this.props;

    if (value === "") {
      setNavTabSearch(
        value,
        menuTabReducer === 0
          ? "linee"
          : menuTabReducer === 1
          ? "fermate"
          : "percorsi",
        null,
        null
      );
      if (
        !currentStopReducer &&
        !tripsCurrentReducer &&
        activeLines &&
        activeLines.length === 0
      ) {
        filterBounds(this);
      }
    } else {
      let tmpTrips = cityBusTrips.find(
        (x) =>
          x &&
          x.monitoredVehicleJourney &&
          x.monitoredVehicleJourney.vehicleJourneyName &&
          x.monitoredVehicleJourney.vehicleJourneyName === value
      );
      if (!tmpTrips || tmpTrips === undefined) {
        tmpTrips = regionBusTrips.find(
          (x) =>
            x &&
            x.monitoredVehicleJourney &&
            x.monitoredVehicleJourney.vehicleJourneyName &&
            x.monitoredVehicleJourney.vehicleJourneyName === value
        );
      }
      setNavTabSearch(
        value,
        menuTabReducer === 0
          ? "linee"
          : menuTabReducer === 1
          ? "fermate"
          : "percorsi",
        tmpTrips &&
          tmpTrips.monitoredVehicleJourney &&
          tmpTrips.monitoredVehicleJourney.journeyPatternRef,
        null
      );
    }
  };

  render() {
    const { menuTabReducer } = this.props;

    return (
      <NavTab
        onSearch={this.handleSearch}
        items={this.injectItems()}
        currentTab={
          menuTabReducer === 0
            ? "linee"
            : menuTabReducer === 1
            ? "fermate"
            : "percorsi"
        }
      />
    );
  }

  componentWillUnmount() {
    const {
      //mapResetLayer,
      setNavTabSearch,
      //setTransportVisibility,
      resetNavTabInfoRoute,
      menuTabReducer,
    } = this.props;
    this.subscriptions.forEach((x) => x.unsubscribe());
    UIkit.notification.closeAll();
    //mapResetLayer();
    setNavTabSearch(
      "",
      menuTabReducer === 0
        ? "linee"
        : menuTabReducer === 1
        ? "fermate"
        : "percorsi",
      null,
      null
    );
    //setTransportVisibility(false);
    resetNavTabInfoRoute();
  }
}

TplPage.contextType = EnvironmentContext;

const mapDispatchToProps = {
  getLines,
  getStops,
  getTrips,
  getRoutes,
  setVehicleEvents,
  setNavTabSelectedTab,
  mapResetLayer,
  setNavTabSearch,
  setTransportVisibility,
  toggleTabLegend,
  addAllLines,
  resetNavTabInfoRoute,
  setBounds,
  filterByMapBounds,
  wsOpen,
};

const mapStateToProps = (state) => ({
  linesFilteredReducer: getFilteredLines(state),
  linesReducer: getAllLines(state),
  routesReducer: getAllRoutes(state),
  vehiclesReducer: getAllVehiclesEvents(state),
  menuTabReducer: getNavTabSelectedTab(state),
  menuInfoRouteReducer: getNavTabInfoRoute(state),
  menuNavTabSearch: getNavTabSearch(state),
  networkCurrentReducer: getCurrentNetwork(state),
  cityBusVisible: isCityBusVisible(state),
  regionBusVisible: isRegionBusVisible(state),
  cityBusStopsFiltered: getCityBusStopsFiltered(state),
  regionBusStopsFiltered: getRegionBusStopsFiltered(state),
  cityBusTripsFiltered: getCityBusTripsFiltered(state),
  regionBusTripsFiltered: getRegionBusTripsFiltered(state),
  cityBusTrips: getCityBusTrips(state),
  regionBusTrips: getRegionBusTrips(state),
  cityBusRoutesFiltered: getCityBusRoutesFiltered(state),
  regionBusRoutesFiltered: getRegionBusRoutesFiltered(state),
  activeLines: getAllActiveLines(state),
  currentStopReducer: getCurrentStop(state),
  tripsCurrentReducer: getCurrentTrip(state),
  searchRoutesReducer: getSearchTextRoutes(state),
  isOpenNavTab: isOpenNavTab(state),
  controlsReducer: getControls(state),
  currentIdRouteForStop: getCurrentIdRouteForStop(state),
  currentIdTripForStop: getCurrentIdTripForStop(state),
  currentIdRouteForVehicle: getCurrentIdRouteForVehicle(state),
  currentIdStopForVehicle: getCurrentIdStopForVehicle(state),
  currentIdStopForRoute: getCurrentIdStopForRoute(state),
  currentIdTripForRoute: getCurrentIdTripForRoute(state),
  boundsReducer: getBoundingBox(state),
  isWSTplOpen: isWSTplOpen(state),
  isWSTplLocationOpen: isWSTplLocationOpen(state),
  isLinesLoading: isLinesLoading(state),
  isTripsLoading: isTripsLoading(state),
  isTripsAllLoading: isTripsAllLoading(state),
  isStopsLoading: isStopsLoading(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
  currentMapZoom: getCurrentZoom(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TplPage)
);
