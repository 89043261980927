import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
// COMPONENTS
//ACTIONS
import { getNewEvent, getNewEventValid } from "store";
//PARAMETRI SELEZIONABILI
import { selectFields } from "enums/selectable-params";

class DetailsLocalizationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  resetfield(e) {
    this.setState({ filterConfig: { key: "" } });
  }

  getCoordinates = () => {
    const { newEvent } = this.props;

    if (
      !newEvent.positionPuntuale ||
      !newEvent.positionPuntuale.latitude ||
      !newEvent.positionPuntuale.longitude
    ) {
      return "";
    } else if (
      newEvent &&
      newEvent.positionPuntuale &&
      newEvent.positionPuntuale.latitude &&
      newEvent.positionPuntuale.longitude
    ) {
      return (
        newEvent.positionPuntuale.latitude.toFixed(6) +
        "," +
        newEvent.positionPuntuale.longitude.toFixed(6)
      );
    }
  };

  getMiddlePoint = () => {
    const { newEvent } = this.props;

    let lon = null;
    let lat = null;
    if (newEvent && newEvent.confirmedArcPuntuale) {
      let coordinates = newEvent.confirmedArcPuntuale.geometry.coordinates;
      lon =
        Math.round(
          coordinates[Math.round((coordinates.length - 1) / 2)][0] * 100000
        ) / 100000;
      lat =
        Math.round(
          coordinates[Math.round((coordinates.length - 1) / 2)][1] * 100000
        ) / 100000;
    }
    return lat + "," + lon;
  };

  bodyArcList = () => {
    const { onClickDeleteArcRow, changeRadiusPuntuale, newEvent } = this.props;
    return (
      <tbody>
        <tr className="arc-table-small">
          <td>1</td>
          <td>{newEvent.confirmedArcPuntuale.properties.arcid}</td>
          <td>
            <div
              className="uk-icon arc-direzione-icon"
              style={{
                transform:
                  "rotate(" +
                  newEvent.confirmedArcPuntuale.properties.direction +
                  "deg)",
              }}
              uk-icon="arrow-up"
            ></div>
          </td>
          <td>{newEvent.confirmedArcPuntuale.properties.name}</td>
          <td>{newEvent.confirmedArcPuntuale.properties.distance}</td>
          <td>
            <select
              className="uk-select arc-raggio-select"
              defaultValue={
                newEvent.confirmedArcPuntuale.properties.radiusOfInfluence
              }
              onChange={(event) => changeRadiusPuntuale(event)}
              disabled={true}
            >
              {selectFields.RADIUS_INFLUENCE_METERS.properties.map(
                (item, index) => (
                  <option key={index}>{item.name}</option>
                )
              )}
            </select>
          </td>
          <td>
            <div uk-tooltip="Rimuovi arco">
              <div
                className="uk-icon pointer"
                uk-icon="icon: acr-interface-close-big"
                onClick={(event) => {
                  event.preventDefault();
                  onClickDeleteArcRow();
                }}
              ></div>
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    );
  };

  render() {
    //const { sortConfig, filterConfig } = this.state;
    const { newEvent } = this.props;

    return (
      <div className="uk-width-1-1 uk-margin-top padding-form  ">
        <div className="uk-flex uk-flex-row small-form  position-container">
          <div className="">
            <div className="uk-text uk-disabled">0</div>
          </div>
          <div className="uk-margin-left">
            <div className="uk-text uk-disabled">Posizione</div>
          </div>
          <div className="uk-margin-medium-left">
            <input
              id="coordinate"
              className="uk-input"
              readOnly={true}
              value={
                newEvent.isConfirmedArcPuntualeClicked
                  ? this.getMiddlePoint()
                  : this.getCoordinates()
              }
              disabled={true}
            ></input>
          </div>
        </div>
        <div className="event-table-list-selected-arc uk-margin-small-top">
          <table className="uk-table uk-table-divider uk-table-hover">
            <thead>
              <tr>
                <th>
                  <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                    Indice
                  </div>
                </th>
                <th>
                  <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                    ID Arco
                  </div>
                </th>
                <th>
                  <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                    Direzione
                  </div>
                </th>
                <th>
                  <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                    Nome Strada
                  </div>
                </th>
                <th>
                  <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                    Lunghezza [m]
                  </div>
                </th>
                <th>
                  <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                    Raggio influenza [m]
                  </div>
                </th>
                <th>{/* EMPTY FOR DELETE ACTION */}</th>
              </tr>
            </thead>
            {newEvent.confirmedArcPuntuale &&
            newEvent.confirmedArcPuntuale !== "" &&
            newEvent.confirmedArcPuntuale.properties &&
            newEvent.confirmedArcPuntuale.properties.arcid ? (
              this.bodyArcList()
            ) : (
              <tbody></tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  newEvent: getNewEvent(state),
  newEventValid: getNewEventValid(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsLocalizationTable)
);
