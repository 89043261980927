import React, { Component } from "react";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
import { tap, take, catchError } from "rxjs/operators";
import { of, combineLatest } from "rxjs";
import { connect } from "react-redux";
import "./style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
// STORE AND REDUCER
import {
  getListAllKpi,
  getListSelectedKpi,
  isKpiOpen,
  openKpiDown,
  getCurrentNetwork,
  getCurrentWorkspace,
} from "store";
import {
  VehicleJourneysAllCounters,
  VehicleJourneysIndicator,
} from "reducers/kpi/kpi.model";
// ACTIONS
import {
  toggleKpiConfirm,
  toggleKpiContainer,
} from "reducers/ui/topbar/topbar.actions";
import {
  removeSelectedKpi,
  emptySelectedKpiList,
  updateKpiList,
  addSelectedKpi,
} from "reducers/kpi/kpi.actions";
//COMPONENTS
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import Loading from "components/shared/loading/loading";
//UTILS
import { selectKpiDescription } from "utils/utils";

class Kpi extends Component {
  apiService;
  sortableRef;
  selectedRef;
  selectedList;
  selectableList;
  pippo;

  constructor(props) {
    super(props);
    this.state = {
      selectedKpiToSave: [],
      isSortableListDisabled: false,
      sortableElement: null,
      isKpiLoading: false,
    };
    this.selectedList = [];
    this.selectableList = [
      {
        id: "estimatedVehicleJourneyCount",
        kpi: 113,
        timeFrame: "TimeFrame",
        recordedAt: "2020-09-16T17:00:35.615",
        selected: false,
      },
      {
        id: "monitoredVehicleJourneyCount",
        kpi: 0,
        timeFrame: "TimeFrame",
        recordedAt: "2020-09-16T17:00:35.615",
        selected: false,
      },
      {
        id: "monitoredVehicleJourneyCountLate",
        kpi: 0,
        timeFrame: "TimeFrame",
        recordedAt: "2020-09-16T17:00:35.615",
        selected: false,
      },
      {
        id: "monitoredVehicleJourneyCountOnTime",
        kpi: 0,
        timeFrame: "TimeFrame",
        recordedAt: "2020-09-16T17:00:35.615",
        selected: false,
      },
      {
        id: "monitoredVehicleJourneyCountEarly",
        kpi: 0,
        timeFrame: "TimeFrame",
        recordedAt: "2020-09-16T17:00:35.615",
        selected: false,
      },
      {
        id: "monitoredVehicleJourneyRate",
        kpi: 0,
        timeFrame: "TimeFrame",
        recordedAt: "2020-09-16T17:00:35.615",
        selected: false,
      },
      {
        id: "monitoredVehicleJourneyRateLate",
        kpi: null,
        timeFrame: "TimeFrame",
        recordedAt: "2020-09-16T17:00:35.615",
        selected: false,
      },
      {
        id: "monitoredVehicleJourneyOnTimeIndicator",
        kpi: null,
        timeFrame: "TimeFrame",
        recordedAt: "2020-09-16T17:00:36.143",
        selected: false,
      },
    ];
  }

  componentDidMount = () => {
    const { listSelectedKpi } = this.props;
    this.apiService = createApiService(this.context);
    if (listSelectedKpi.leght > 0) {
      this.selectedList = [...listSelectedKpi];
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      isKpiOpen,
      listAllKpi,
      listSelectedKpi,
      openKpiDown,
      currentWorkspace,
      addSelectedKpi,
      toggleKpiContainer,
    } = this.props;

    if (
      (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) ||
      (prevProps.currentWorkspace &&
        currentWorkspace &&
        prevProps.currentWorkspace.preferenceId !==
          currentWorkspace.preferenceId)
    ) {
      let parsedObject;
      if (
        currentWorkspace &&
        currentWorkspace.payload &&
        currentWorkspace.payload.value
      ) {
        parsedObject = JSON.parse(currentWorkspace.payload.value);
        if (parsedObject.selectedKpi && parsedObject.selectedKpi.length > 0) {
          if (parsedObject.selectedKpi && parsedObject.selectedKpi.length > 3) {
            toggleKpiContainer();
          }
          let kpiListWithObjects = [];
          parsedObject.selectedKpi.forEach((item) => {
            let kpi = this.selectableList.find(
              (selectableItem) => selectableItem.id === item
            );
            if (kpi) {
              kpiListWithObjects.push(kpi);
            }
          });
          addSelectedKpi([...kpiListWithObjects]);
        }
      }
    }

    if (
      prevProps &&
      ((prevProps.isKpiOpen && !isKpiOpen) ||
        (!prevProps.isKpiOpen && isKpiOpen))
    ) {
      let checkdisabled =
        (isKpiOpen && this.selectedList.length >= 5) ||
        (!isKpiOpen && this.selectedList.length >= 3);
      this.setState({
        isSortableListDisabled: checkdisabled,
      });
    }

    if (prevState && !prevState.openKpiDown && openKpiDown) {
      this.addListenersOnOpenContainer();
    }

    if (prevProps && prevProps.listAllKpi !== listAllKpi) {
      this.selectableList = [...listAllKpi];
    }

    if (prevProps && prevProps.listSelectedKpi !== listSelectedKpi) {
      this.selectedList = [...listSelectedKpi];
    }
  }

  refreshKpi = () => {
    const { networkCurrentReducer, updateKpiList } = this.props;
    if (networkCurrentReducer && networkCurrentReducer.length > 0) {
      networkCurrentReducer.forEach((item) => {
        const combined = combineLatest([
          this.apiService.getKpiOverall(item.id),
          this.apiService.getKpiMonitoredIndicator(item.id),
        ]).pipe(
          take(1),
          tap(([all, indicator]) => {
            if (all && !all.error) {
              // TO remove indicator
              delete all["monitoredVehicleJourneyOnTimeIndicator"];
              updateKpiList(VehicleJourneysAllCounters.from(all));
            } else {
              console.log(all);
            }

            if (indicator && !indicator.error) {
              let tempIndicator = VehicleJourneysIndicator.from(indicator);
              tempIndicator["monitoredVehicleJourneyOnTimeIndicator"] =
                tempIndicator["indicator"];
              delete tempIndicator["indicator"];
              updateKpiList(tempIndicator);
            } else {
              console.log(indicator);
            }
            this.setState({
              isKpiLoading: false,
            });
          }),
          catchError((error) => {
            console.error(error);
            // updateKpiList(VehicleJourneysAllCounters.from(this.k));
            this.setState({
              isKpiLoading: false,
            });
            return of(error);
          })
        );
        combined.subscribe();

        this.setState({ isKpiLoading: true });
      });
    } else {
      /* updateKpiList(VehicleJourneysAllCounters.from({
        recordedAt: "2017-11-27T17:32:28Z",
        timeFrame: {
          start: "2017-11-27T10:00:00Z",
          end: "2017-11-27T12:00:00Z"
        },
        estimatedVehicleJourneyCount: 0,
        monitoredVehicleJourneyCount: 0,
        monitoredVehicleJourneyCountLate: 0,
        monitoredVehicleJourneyCountOnTime: 0,
        monitoredVehicleJourneyCountEarly: 0,
        monitoredVehicleJourneyRate: 0,
        monitoredVehicleJourneyRateLate: 0,
        monitoredVehicleJourneyOnTimeIndicator: 0
      })); */
    }
  };

  addListenersOnOpenContainer = () => {
    const { isKpiOpen } = this.props;
    // UPPER DRAGGABLE LIST LISTENERS
    UIkit.util.on("#selectedSelected", "added", (item) => {
      if (
        (isKpiOpen && this.selectedList.length < 5) ||
        (!isKpiOpen && this.selectedList.length < 3)
      ) {
        this.addedSelectedKpi(item);
      }
    });

    UIkit.util.on("#selectedSelected", "removed", (item) => {
      this.removeSelectedKpi(item);
    });

    // LOWER DRAGGABLE LIST LISTENERS
    UIkit.util.on("#selectedSortable", "added", (item) => {
      this.removeSelectableKpi(item);
    });

    UIkit.util.on("#selectedSortable", "removed", (item) => {
      this.removeSelectableKpi(item);
    });

    UIkit.util.on("#selectedSelected", "moved", (item) => {
      this.modifySelectedKpi(item);
    });
  };

  modifySelectedKpi = (item) => {
    const { listAllKpi } = this.props;
    this.selectedList = [];
    for (let i = 0; i < item.target.childElementCount; i++) {
      let newItem = listAllKpi.find(
        (kpi) => kpi.id === item.target.childNodes[i].id
      );
      this.selectedList.push(newItem);
    }
  };

  addedSelectedKpi = (item) => {
    const { listAllKpi } = this.props;
    let newItem = listAllKpi.find((kpi) => kpi.id === item.detail[1].id);

    if (newItem) {
      this.selectedList = Array.from(new Set([...this.selectedList, newItem]));
    }
  };

  removeSelectedKpi = (item) => {
    this.selectedList = Array.from(
      new Set([
        ...this.selectedList.filter((elm) => elm.id !== item.detail[1].id),
      ])
    );
  };

  removeSelectableKpi = (item) => {
    const { listAllKpi } = this.props;
    this.selectableList = [...listAllKpi];
  };

  saveListKpi = () => {
    const { addSelectedKpi, listAllKpi, emptySelectedKpiList } = this.props;

    this.selectableList = [...listAllKpi];

    if (this.selectedList.length > 0) addSelectedKpi([...this.selectedList]);
    else emptySelectedKpiList();

    this.handleOpenCloseKpi();
  };

  annulla = () => {
    const { listSelectedKpi } = this.props;

    this.selectedList = [...listSelectedKpi];

    this.handleOpenCloseKpi();
  };

  handleOpenCloseKpi = () => {
    const { toggleKpiConfirm } = this.props;
    toggleKpiConfirm();
  };

  // RENDERS

  renderClosed = () => {
    const { listSelectedKpi, isKpiOpen } = this.props;
    let toRender = !isKpiOpen ? listSelectedKpi.slice(0, 3) : listSelectedKpi;
    return (
      <div
        className="close-kpi-down-container uk-navbar-tab-options-container-open uk-width-1-1"
        key={"kpi-selected"}
      >
        <div className="uk-width-1-1 uk-flex uk-flex-middle uk-flex-column container-horizontal">
          <div className="uk-flex uk-flex-row uk-height-1-1 uk-width-1-1 uk-flex-wrap uk-flex-middle selected-list-kpi-min-height">
            {[...toRender].map((kpi) => {
              return this.renderSelectedKpiCard(kpi);
            })}
          </div>
        </div>
      </div>
    );
  };

  renderSortable = () => {
    let upperList = [...this.selectedList].map((kpi) => {
      return this.renderUplistSelectableKpiCard(kpi);
    });
    let bottomList = [...this.selectableList].map((kpi) => {
      return this.renderSelectableKpiCard(kpi);
    });

    return (
      <div
        className={
          "open-kpi-down-container uk-flex uk-flex-middle uk-width-1-1 uk-flex-column"
        }
        key={"kpi-selectable"}
      >
        <div className="uk-height-1-1 uk-width-1-1 uk-flex uk-flex-middle uk-flex-column container-horizontal">
          <div
            id="selectedSelected"
            className="uk-flex uk-flex-row uk-height-1-1 uk-width-1-1 selected-list-kpi-min-height uk-flex-wrap uk-flex-center padding-26"
            uk-sortable={"group: sortable-group; animation: 0"}
            ref={(_) => (this.selectedRef = _)}
          >
            {upperList}
          </div>
          <div className={"uk-width-1-1"}>
            <h3 className="generic-text">Trascina per sostituire</h3>
          </div>
          <div
            id="selectedSortable"
            className={
              "uk-flex-center selectable-list uk-sortable uk-flex uk-flex-row uk-selected-kpi-sortable uk-height-1-1 uk-width-1-1 uk-flex-wrap"
            }
            uk-sortable={"group: sortable-group; animation: 0"}
            ref={(_) => (this.sortableRef = _)}
          >
            {bottomList}
          </div>
          <div className={"uk-flex uk-flex-center uk-width-1-1"}>
            <div className="uk-flex acr-kpi-button-container">
              <button
                className="uk-button uk-button-small uk-button-default uk-width-1-2 uk-margin-small-right"
                onClick={this.annulla}
              >
                Annulla
              </button>
              <button
                className="uk-button uk-button-small uk-button-primary uk-width-1-2"
                onClick={this.saveListKpi}
              >
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSelectedKpiCard(kpi) {
    return (
      <div className={"uk-small-squared-card"} key={kpi.id + "_selected"}>
        <div
          className={`uk-card uk-card-small uk-card-hover uk-card-body`}
          key={kpi.id}
        >
          <h3 className="uk-square-card-title">{kpi.kpi || 0}</h3>
          <p className="uk-square-card-body">{selectKpiDescription(kpi)}</p>
        </div>
      </div>
    );
  }

  renderSelectableKpiCard(kpi) {
    const { isKpiOpen, listSelectedKpi } = this.props;

    let isKpiSelected = null;
    isKpiSelected = [...listSelectedKpi].find((item) => item.id === kpi.id);

    let description = selectKpiDescription(kpi);

    let isDraggable =
      (this.selectedList.length >= 3 && !isKpiOpen) ||
      (this.selectedList.length >= 5 && isKpiOpen);

    return (
      <div
        className="uk-small-squared-card"
        id={kpi.id}
        key={kpi.id + "_sortable"}
      >
        <div
          className={
            (isKpiSelected ? " uk-card-primary" : " uk-card-secondary") +
            (isDraggable || isKpiSelected ? " uk-sortable-nodrag " : " ") +
            "uk-card uk-card-small uk-card-hover uk-sortable-handle uk-card-body"
          }
          key={kpi.id}
        >
          <h3 className="uk-square-card-title">{kpi.kpi || 0}</h3>
          <p className="uk-square-card-body">{description}</p>
        </div>
      </div>
    );
  }

  renderUplistSelectableKpiCard(kpi) {
    let description = selectKpiDescription(kpi);

    return (
      <div className="uk-small-squared-card" id={kpi.id} key={kpi.id}>
        <div
          className={
            " uk-card-secondary uk-card uk-card-small uk-card-hover uk-sortable-handle uk-card-body"
          }
          key={kpi.id}
        >
          <h3 className="uk-square-card-title">{kpi.kpi || 0}</h3>
          <p className="uk-square-card-body">{description}</p>
        </div>
      </div>
    );
  }

  render() {
    const { isKpiLoading } = this.state;
    const { openKpiDown, isKpiOpen } = this.props;

    return (
      <div
        className={
          isKpiOpen
            ? "bigger-container-five-cards uk-position-relative uk-flex"
            : "bigger-container-three-cards uk-position-relative uk-flex"
        }
      >
        {!isKpiLoading ? (
          <ErrorBoundary>
            {!openKpiDown ? this.renderClosed() : this.renderSortable()}
          </ErrorBoundary>
        ) : (
          <Loading />
        )}
        <button
          onClick={(e) => {
            e.preventDefault();
            this.refreshKpi();
          }}
          uk-icon="icon: refresh"
          className="btn-refresh-kpi"
        ></button>
        <div
          className={`right-ghost ${
            openKpiDown
              ? "uk-navbar-tab-options-container-close cursor-pointer"
              : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            this.handleOpenCloseKpi();
          }}
        ></div>
      </div>
    );
  }
}

Kpi.contextType = EnvironmentContext;

const mapStateToProps = (state) => ({
  listAllKpi: getListAllKpi(state),
  listSelectedKpi: getListSelectedKpi(state),
  isKpiOpen: isKpiOpen(state),
  openKpiDown: openKpiDown(state),
  networkCurrentReducer: getCurrentNetwork(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
});

const mapDispatchToProps = {
  addSelectedKpi,
  removeSelectedKpi,
  emptySelectedKpiList,
  updateKpiList,
  toggleKpiConfirm,
  toggleKpiContainer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Kpi);
