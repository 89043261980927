import { BottombarActions } from "./bottombar.actions";

const INITIAL_STATE = {
  tabs: [],
  activeTab: null,
  isExpanded: false,
  isReduced: false,
};

export default function bottombarReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BottombarActions.EXPAND_BOTTOMBAR:
      return {
        ...state,
        isExpanded: true,
        isReduced: false,
      };
    case BottombarActions.REDUCE_BOTTOMBAR:
      return {
        ...state,
        isExpanded: false,
        isReduced: true,
      };
    case BottombarActions.CLOSE_BOTTOMBAR:
      return {
        ...state,
        isExpanded: false,
        isReduced: false,
        tabs: [],
        activeTab: null,
      };
    case BottombarActions.ADD_BOTTOMBAR_TAB:
      const isAlreadyPresent = state.tabs.find(
        (item) => item === action.payload
      );
      if (isAlreadyPresent) {
        return { ...state };
      } else {
        return {
          ...state,
          tabs: [...state.tabs, action.payload],
        };
      }
    case BottombarActions.REMOVE_BOTTOMBAR_TAB:
      return {
        ...state,
        tabs: state.tabs.filter((item) => item !== action.payload),
      };
    case BottombarActions.RESET_BOTTOMBAR_TABS:
      return {
        ...state,
        activeTab: null,
        tabs: [],
      };
    case BottombarActions.SET_BOTTOMBAR_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    default:
      return state;
  }
}

export const getBottombarTabs = (state) => state.tabs;
export const getBottombarActiveTab = (state) => state.activeTab;
export const isBottombarExpanded = (state) => state.isExpanded;
export const isBottombarReduced = (state) => state.isReduced;
