import { CompositeLayer, PathLayer } from "deck.gl";

class SelectedRedArcLayer extends CompositeLayer {
  renderLayers() {
    const { data, color } = this.props;

    return new PathLayer(
      this.getSubLayerProps({
        id: "arc-layer-puntuale-modal",
        data: data,
        getPath: (d) =>
          d.geometry && d.geometry.coordinates ? d.geometry.coordinates : [],
        getColor: color,
        pickable: true,
        widthScale: 20,
        widthMinPixels: 4,
        widthMaxPixels: 4,
        visible: true,
        rounded: true,
      })
    );
  }
}

SelectedRedArcLayer.layerName = "SelectedRedArcLayer";

export default SelectedRedArcLayer;
