import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
import { tap, catchError, concatMap, finalize } from "rxjs/operators";
import { of } from "rxjs";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
//COMPONENTS
import CustomisedDatePicker from "components/shared/customised-date-time-picker/customised-datepicker";
import CustomisedTimePicker from "components/shared/customised-date-time-picker/customised-timepicker";
// ACTIONS
import {
  enableForwardEventButton,
  disableForwardEventButton,
} from "reducers/ui/modal/modal.actions";
import { setNewEvent, updateNewEvent } from "reducers/modal/modal.actions";
import {
  getUsername,
  getNewEvent,
  getNewEventValid,
  getCurrentEvent,
  getTypeModal,
  getSelectModal,
  getTabModal,
} from "store";
import { setTypeLocalization } from "reducers/ui/modal/modal.actions";
//PARAMETRI SELEZIONABILI
import { selectFields } from "enums/selectable-params";
import {
  GRAVITY_TYPE,
  PROBABILITY_OF_OCCURRENCE,
  EVENT_STATE,
  SOURCE_TYPE,
} from "utils/utils";
//MODEL
import ModalModel from "reducers/modal/modal.model";
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";

class GeneralTab extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {
      isArcsLoading: false,
      isBackOrForwardButtonPressed: false,
    };
  }

  componentDidMount = () => {
    const {
      updateNewEvent,
      username,
      currentEvent,
      typeModal,
      setNewEvent,
      selectModal,
      setTypeLocalization,
      newEvent,
    } = this.props;

    this.apiService = createApiService(this.context);
    let selectSubTipoEvento = document.getElementById("sottotipo-evento");

    if (
      typeModal === "modifyEvent" &&
      currentEvent &&
      newEvent.typeEvent === ""
    ) {
      let { road, currentLocalizationTab } = ModalModel.toModal(
        { ...currentEvent },
        { ...selectModal }
      );
      let evMapped = road;
      //SET CORRECT LOCALIZATION
      setTypeLocalization(currentLocalizationTab);
      //UPDATE EVENT
      updateNewEvent(evMapped);
      //AFTER SETTING ID, MAKE API CALL TO FETCH SELECTED ARC(S) FROM ID
      this.fetchArcs(evMapped);
      this.fetchSegments(evMapped);

      if (evMapped.newEvent.value.typeEvent !== -1) {
        selectModal.types[evMapped.newEvent.value.typeEvent].subTypes.forEach(
          (item, index) =>
            (selectSubTipoEvento.options[
              selectSubTipoEvento.options.length
            ] = new Option(
              selectModal.types[evMapped.newEvent.value.typeEvent].subTypes[
                index
              ].subTypeNameIT,
              index
            ))
        );
      }
    }
    if (
      typeModal === "addAssociatedEvent" &&
      currentEvent &&
      currentEvent.situationId
    ) {
      setNewEvent(
        "iDPractice",
        currentEvent && currentEvent.situationId
          ? currentEvent.situationId
          : "practice-" +
              new Date().valueOf().toString() +
              "-" +
              (Math.floor(Math.random() * (100000000 - 1 + 1)) + 1).toString()
      );
      if (
        newEvent.dateStartEvent === null ||
        newEvent.dateStartEvent === undefined
      ) {
        setNewEvent("dateStartEvent", new Date());
      }
      if (
        newEvent.timeStartEvent === null ||
        newEvent.timeStartEvent === undefined
      ) {
        setNewEvent("timeStartEvent", new Date());
      }
    }

    if (typeModal !== "modifyEvent") {
      setNewEvent(
        "iDEvent",
        "event-" +
          new Date().valueOf().toString() +
          "-" +
          (Math.floor(Math.random() * (100000000 - 1 + 1)) + 1).toString()
      );
      setNewEvent("sourceOptionEvent", username);

      //initialize selects with first type and first subtype of lists
      if (selectModal.types.length > 0) {
        let firstTypeOfList = selectModal.types[0];
        if (newEvent.typeEvent === "") {
          setNewEvent("typeEvent", "0");
        }
        firstTypeOfList.subTypes.forEach(
          (item, index) =>
            (selectSubTipoEvento.options[
              selectSubTipoEvento.options.length
            ] = new Option(
              firstTypeOfList.subTypes[index].subTypeNameIT,
              index
            ))
        );
        if (newEvent.underTypeEvent === "") {
          setNewEvent("underTypeEvent", "0");
        }
      }

      if (typeModal === "newEvent") {
        setNewEvent(
          "iDPractice",
          "practice-" +
            new Date().valueOf().toString() +
            "-" +
            (Math.floor(Math.random() * (100000000 - 1 + 1)) + 1).toString()
        );

        if (
          newEvent.dateStartEvent === null ||
          newEvent.dateStartEvent === undefined
        ) {
          setNewEvent("dateStartEvent", new Date());
        }
        if (
          newEvent.timeStartEvent === null ||
          newEvent.timeStartEvent === undefined
        ) {
          setNewEvent("timeStartEvent", new Date());
        }
      }
    }
    if (typeModal === "modifyEvent") {
      this.setState({ isBackOrForwardButtonPressed: true });
    }
    const selectTipoEvento = document.getElementById("tipo-evento");
    const selectGravity = document.getElementById("gravita");
    const selectProbability = document.getElementById("probabilita-evento");

    selectModal.types.forEach(
      (item, index) =>
        (selectTipoEvento.options[selectTipoEvento.options.length] = new Option(
          selectModal.types[index].typeNameIT,
          index
        ))
    );

    selectFields.GRAVITA.properties.forEach((item, index) => {
      if (item.name !== "UNKNOWN") {
        selectGravity.options[selectGravity.options.length] = new Option(
          GRAVITY_TYPE[selectFields.GRAVITA.properties[index].name],
          index //item.id
        );
      }
    });
    selectFields.PROBABILITA.properties.forEach(
      (item, index) =>
        (selectProbability.options[
          selectProbability.options.length
        ] = new Option(
          PROBABILITY_OF_OCCURRENCE[
            selectFields.PROBABILITA.properties[index].name
          ],
          index
        ))
    );
  };

  componentDidUpdate(prevProps) {
    const { newEvent, selectModal, tabModal } = this.props;
    if (
      (newEvent.typeEvent !== prevProps.typeEvent &&
        newEvent.typeEvent !== "") ||
      prevProps.tabModal !== tabModal
    ) {
      let selectSubTipoEvento = document.getElementById("sottotipo-evento");
      this.removeOptions(selectSubTipoEvento);
      if (newEvent.typeEvent !== -1) {
        selectModal.types[newEvent.typeEvent].subTypes.forEach(
          (item, index) =>
            (selectSubTipoEvento.options[
              selectSubTipoEvento.options.length
            ] = new Option(
              selectModal.types[newEvent.typeEvent].subTypes[
                index
              ].subTypeNameIT,
              index
            ))
        );
        selectSubTipoEvento.value = newEvent.underTypeEvent;
      }
    }
  }

  fetchArcs = (evMapped) => {
    const { setNewEvent } = this.props;

    if (evMapped.newEvent.value.confirmedArcPuntuale) {
      this.setState({
        ...this.state,
        isArcsLoading: true,
      });
      UIkit.notification.closeAll("loading");
      UIkit.notification({
        message: "Caricamento dati in corso",
        status: "primary",
        pos: "bottom-center",
        group: "loading",
      });
      const filter =
        "arcid='" + evMapped.newEvent.value.confirmedArcPuntuale + "'";
      this.subscriptions.push(
        this.apiService
          .getGeoserverFeatures(
            "road_sections",
            null,
            null,
            null,
            null,
            null,
            filter
          )
          .pipe(
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "road_sections"
              );
              let road_sections = [];
              this.setState({
                ...this.state,
                isArcsLoading: false,
              });
              UIkit.notification.closeAll("loading");
              if (data.numberReturned > 0) {
                road_sections = [...featureCollection.features];
                road_sections = road_sections.map((item) => {
                  return {
                    ...item,
                    properties: {
                      ...item.properties,
                      radiusOfInfluence: 1000,
                    },
                  };
                });
                return setNewEvent("confirmedArcPuntuale", road_sections[0]);
              }
            }),
            catchError((error) => {
              UIkit.notification.closeAll("loading");
              this.setState({
                ...this.state,
                isArcsLoading: false,
              });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
    if (
      evMapped.newEvent.value.listOfConfirmedArcsEstesa &&
      evMapped.newEvent.value.listOfConfirmedArcsEstesa.length > 0
    ) {
      UIkit.notification.closeAll("loading");
      UIkit.notification({
        message: "Caricamento dati in corso",
        status: "primary",
        pos: "bottom-center",
        group: "loading",
      });
      this.setState({
        ...this.state,
        isArcsLoading: true,
      });
      let selectedListWithObject = [];
      this.subscriptions.push(
        this.callApi([...evMapped.newEvent.value.listOfConfirmedArcsEstesa])
          .pipe(
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "road_sections"
              );
              let road_sections = [];
              if (data.numberReturned > 0) {
                road_sections = [...featureCollection.features];
                road_sections = road_sections.map((item) => {
                  return {
                    ...item,
                    properties: {
                      ...item.properties,
                      radiusOfInfluence: 1000,
                    },
                  };
                });
                selectedListWithObject.push(road_sections[0]);
              }
            }),
            finalize(() => {
              UIkit.notification.closeAll("loading");
              this.setState({
                ...this.state,
                isArcsLoading: false,
              });
              return setNewEvent(
                "listOfConfirmedArcsEstesa",
                selectedListWithObject
              );
            }),
            catchError((error) => {
              UIkit.notification.closeAll("loading");
              this.setState({
                ...this.state,
                isArcsLoading: false,
              });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  callApi = (list) => {
    return of(...list).pipe(
      concatMap((arc) =>
        this.apiService.getGeoserverFeatures(
          "road_sections",
          null,
          null,
          null,
          null,
          null,
          "arcid='" + arc + "'"
        )
      ),
      catchError((error) => {
        console.error(error);
        return of(error);
      })
    );
  };

  fetchSegments(evMapped) {
    const { setNewEvent } = this.props;

    if (evMapped.newEvent.value.initialPointRDSPuntuale) {
      UIkit.notification.closeAll("loading");
      UIkit.notification({
        message: "Caricamento dati in corso",
        status: "primary",
        pos: "bottom-center",
        group: "loading",
      });
      let filter = "lcd=" + evMapped.newEvent.value.initialPointRDSPuntuale;
      this.subscriptions.push(
        this.apiService
          .getGeoserverFeatures("points", null, null, null, null, null, filter)
          .pipe(
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "points"
              );
              let points = [];
              if (featureCollection.numberReturned > 0) {
                points = [...featureCollection.features];
                setNewEvent(
                  "streetRDSPuntuale",
                  points[0].properties.road_lcd.toString()
                );
                setNewEvent(
                  "initialPointRDSPuntualeName",
                  points[0].properties.name1
                );
              }
              UIkit.notification.closeAll("loading");
            }),
            catchError((error) => {
              console.error(error);
              UIkit.notification.closeAll("loading");
              this.setState({
                ...this.state,
                isPointsLoading: false,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    }

    if (
      evMapped.newEvent.value.initialPointRDSEstesa ||
      evMapped.newEvent.value.finalPointRDSEstesa
    ) {
      let filter = "";
      if (evMapped.newEvent.value.initialPointRDSEstesa) {
        filter = "lcd=" + evMapped.newEvent.value.initialPointRDSEstesa;
      }
      if (
        evMapped.newEvent.value.initialPointRDSEstesa &&
        evMapped.newEvent.value.finalPointRDSEstesa
      ) {
        filter = filter + "OR";
      }
      if (evMapped.newEvent.value.finalPointRDSEstesa) {
        filter = "lcd=" + evMapped.newEvent.value.finalPointRDSEstesa;
      }
      UIkit.notification.closeAll("loading");
      UIkit.notification({
        message: "Caricamento dati in corso",
        status: "primary",
        pos: "bottom-center",
        group: "loading",
      });
      this.subscriptions.push(
        this.apiService
          .getGeoserverFeatures("points", null, null, null, null, null, filter)
          .pipe(
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "points"
              );
              let points = [];
              if (featureCollection.numberReturned > 0) {
                points = [...featureCollection.features];
                setNewEvent("streetRDSEstesa", points[0].properties.road_lcd);
                if (
                  points[0] &&
                  points[0].properties.lcd ===
                    Number(evMapped.newEvent.value.initialPointRDSEstesa)
                ) {
                  setNewEvent(
                    "initialPointRDSEstesaName",
                    points[0].properties.name1
                  );
                } else if (
                  points[1] &&
                  points[1].properties.lcd ===
                    Number(evMapped.newEvent.value.initialPointRDSEstesa)
                ) {
                  setNewEvent(
                    "initialPointRDSEstesaName",
                    points[1].properties.name1
                  );
                }

                if (
                  points[0] &&
                  points[0].properties.lcd ===
                    Number(evMapped.newEvent.value.finalPointRDSEstesa)
                ) {
                  setNewEvent(
                    "finalPointRDSEstesaName",
                    points[0].properties.name1
                  );
                } else if (
                  points[1] &&
                  points[1].properties.lcd ===
                    Number(evMapped.newEvent.value.finalPointRDSEstesa)
                ) {
                  setNewEvent(
                    "finalPointRDSEstesaName",
                    points[1].properties.name1
                  );
                }
              }
              UIkit.notification.closeAll("loading");
            }),
            catchError((error) => {
              UIkit.notification.closeAll("loading");
              console.error(error);
              this.setState({
                ...this.state,
                isPointsLoading: false,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    }
  }

  removeOptions(selectElement) {
    while (selectElement.options.length > 0) {
      selectElement.remove(0);
    }
  }

  handler = (e, name) => {
    const { setNewEvent } = this.props;

    const handlervalue = e.target.value;

    if (handlervalue && handlervalue !== "") {
      setNewEvent(name, handlervalue);

      //AND RESET EVENT ATTRIBUTES
      if (name === "typeEvent") {
        setNewEvent("underTypeEvent", "0");
      }
      //IN CASE OF CHANGE OF TYPE OR UNDERTYPE, RESET WITH FIRST UNDERTYPE
      if (name === "typeEvent" || name === "underTypeEvent") {
        setNewEvent("eventAttributes", null);
      }
    }
  };

  onClickIcon = (e) => {
    const { newEvent, setNewEvent } = this.props;
    e.preventDefault();
    if (newEvent && newEvent.stateEvent !== "closed") {
      setNewEvent("note", " ");
    }
  };

  disableForwardButton = () => {
    const {
      enableForwardEventButton,
      disableForwardEventButton,
      newEventValid,
    } = this.props;
    const { isArcsLoading } = this.state;
    if (
      newEventValid.iDEvent &&
      newEventValid.iDPractice &&
      newEventValid.stateEvent &&
      newEventValid.typeEvent &&
      newEventValid.underTypeEvent &&
      newEventValid.dateStartEvent &&
      newEventValid.timeStartEvent &&
      newEventValid.gravityEvent &&
      newEventValid.sourceOptionEvent &&
      newEventValid.probabilityEvent &&
      newEventValid.note &&
      newEventValid.supplierEvent &&
      !isArcsLoading
    ) {
      return enableForwardEventButton();
    } else return disableForwardEventButton();
  };

  onChangeDate = (value, name) => {
    const { setNewEvent, newEvent } = this.props;

    if (name === "dateEndEvent") {
      setNewEvent(name, value);
      //check if time remains valid after changing the day
      const isValid = this.checkIfValidTime(
        value,
        newEvent.timeEndEvent,
        "end"
      );
      if (!isValid) {
        setNewEvent("timeEndEvent", newEvent.timeStartEvent);
      }
    } else if (name === "dateStartEvent") {
      setNewEvent(name, value);
      //check if time remains valid after changing the day
      const isValid = this.checkIfValidTime(
        value,
        newEvent.timeStartEvent,
        "start"
      );
      if (!isValid) {
        setNewEvent("timeStartEvent", newEvent.timeEndEvent);
      }
    } else {
      setNewEvent(name, value);
    }
  };

  checkIfValidTime = (date, time, string) => {
    const { newEvent } = this.props;

    let isSameDate = null;
    if (string === "end") {
      isSameDate =
        newEvent &&
        newEvent.dateStartEvent &&
        date &&
        newEvent.dateStartEvent.getDate() === date.getDate() &&
        newEvent.dateStartEvent.getMonth() === date.getMonth()
          ? true
          : null;
    } else if (string === "start") {
      isSameDate =
        newEvent &&
        date &&
        newEvent.dateEndEvent &&
        date.getDate() === newEvent.dateEndEvent.getDate() &&
        date.getMonth() === newEvent.dateEndEvent.getMonth()
          ? true
          : null;
    }
    const startTime = newEvent.timeStartEvent;
    const endTime = newEvent.timeEndEvent;

    let isValid = true;

    if (time && isSameDate && (time < startTime || time > endTime)) {
      isValid = false;
    }

    return isValid;
  };

  componentWillUnmount = () => {
    const { disableForwardEventButton, typeModal } = this.props;

    this.subscriptions.forEach((x) => x.unsubscribe());

    if (typeModal !== "modifyEvent") {
      disableForwardEventButton();
    } else {
      this.setState({ isBackOrForwardButtonPressed: false });
    }
  };

  render() {
    const { newEvent, selectModal } = this.props;

    this.disableForwardButton();

    return (
      <form className="uk-form-horizontal padding-form small-form">
        <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
          <div className="uk-width-1-2">
            <label className="uk-form-label">Id Evento</label>
            <div className="uk-form-controls">
              <input
                id="id-evento"
                name={"idevent"}
                className="uk-input"
                value={newEvent && newEvent.iDEvent}
                disabled={true}
              />
            </div>
          </div>
          <div className="uk-width-1-2">
            <label className="uk-form-label padding-form-label">
              Id Pratica
            </label>
            <div className="uk-form-controls">
              <input
                id="id-pratica"
                className="uk-input"
                value={newEvent && newEvent.iDPractice}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
          <div className="uk-width-1-2">
            <label className="uk-form-label">Stato Evento</label>
            <div className="uk-form-controls">
              <input
                id="stato-evento"
                className="uk-input"
                value={
                  newEvent &&
                  newEvent.stateEvent &&
                  newEvent.stateEvent === "new"
                    ? newEvent.stateEvent.toUpperCase()
                    : EVENT_STATE[newEvent.stateEvent].toUpperCase()
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label">Tipo Evento</label>
          <div className="uk-form-controls">
            <select
              id="tipo-evento"
              name={"typeEvent"}
              className="uk-select uk-width-1-1"
              value={newEvent && newEvent.typeEvent}
              onChange={(e) => this.handler(e, "typeEvent")}
              disabled={newEvent && newEvent.stateEvent === "closed"}
            ></select>
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label">Sottotipo Evento</label>
          <div className="uk-form-controls">
            <select
              id="sottotipo-evento"
              name={"underTypeEvent"}
              className="uk-select uk-width-1-1"
              value={newEvent && newEvent.underTypeEvent}
              onChange={(e) => this.handler(e, "underTypeEvent")}
              disabled={newEvent && newEvent.stateEvent === "closed"}
            ></select>
          </div>
        </div>
        <div className="uk-flex uk-flex-row uk-flex-middle uk-margin">
          <div className="uk-width-1-2">
            <label className="uk-form-label">Data/Ora Inizio</label>
            <div className="uk-form-controls uk-flex uk-flex-row">
              <div className="uk-width-1-2">
                <CustomisedDatePicker
                  value={newEvent && newEvent.dateStartEvent}
                  callbackFromParent={(data) =>
                    this.onChangeDate(data, "dateStartEvent")
                  }
                  startDate={newEvent && newEvent.dateStartEvent}
                  endDate={newEvent && newEvent.dateEndEvent}
                  isStartDate={true}
                  disabled={newEvent && newEvent.stateEvent === "closed"}
                />
              </div>
              <div className="uk-width-1-2">
                <div className="uk-margin-left padding-time">
                  <CustomisedTimePicker
                    value={newEvent && newEvent.timeStartEvent}
                    callbackFromParent={(data) =>
                      this.onChangeDate(data, "timeStartEvent")
                    }
                    isSameDate={
                      newEvent &&
                      newEvent.dateStartEvent &&
                      newEvent.dateEndEvent &&
                      newEvent.dateStartEvent.getDate() ===
                        newEvent.dateEndEvent.getDate() &&
                      newEvent.dateStartEvent.getMonth() ===
                        newEvent.dateEndEvent.getMonth()
                        ? true
                        : null
                    }
                    startTime={newEvent && newEvent.timeStartEvent}
                    endTime={newEvent && newEvent.timeEndEvent}
                    disabled={newEvent && newEvent.stateEvent === "closed"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-2">
            <label className="uk-form-label  padding-form-label">
              Data/Ora Fine
            </label>

            <div className="uk-form-controls uk-flex uk-flex-row ">
              <div className="uk-width-1-2">
                <CustomisedDatePicker
                  value={newEvent && newEvent.dateEndEvent}
                  callbackFromParent={(data) =>
                    this.onChangeDate(data, "dateEndEvent")
                  }
                  startDate={newEvent && newEvent.dateStartEvent}
                  endDate={newEvent && newEvent.dateEndEvent}
                  isEndDate={true}
                  disabled={newEvent && newEvent.stateEvent === "closed"}
                />
              </div>
              <div className="uk-width-1-2">
                <div className="uk-margin-left padding-time">
                  <CustomisedTimePicker
                    className="uk-margin-left"
                    value={newEvent && newEvent.timeEndEvent}
                    callbackFromParent={(data) =>
                      this.onChangeDate(data, "timeEndEvent")
                    }
                    isSameDate={
                      newEvent &&
                      newEvent.dateStartEvent &&
                      newEvent.dateEndEvent &&
                      newEvent.dateStartEvent.getDate() ===
                        newEvent.dateEndEvent.getDate() &&
                      newEvent.dateStartEvent.getMonth() ===
                        newEvent.dateEndEvent.getMonth()
                        ? true
                        : null
                    }
                    isEndTime={true}
                    startTime={newEvent && newEvent.timeStartEvent}
                    endTime={newEvent && newEvent.timeEndEvent}
                    disabled={newEvent && newEvent.stateEvent === "closed"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="uk-margin">
          <label className="uk-form-label">Cancellazione</label>
          <div className="uk-form-controls">
            <select
              id="cancellazione-evento"
              name={"cancellationEvent"}
              className="uk-select uk-width-1-1"
              value={newEvent.valueCancellationEvent}
              onChange={(e) => this.handler(e, "cancellationEvent")}
            ></select>
          </div>
        </div> */}
        <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
          <div className="uk-width-1-2">
            <label className="uk-form-label">Gravità</label>
            <div className="uk-form-controls">
              <select
                id="gravita"
                name={"gravity"}
                className="uk-select uk-width-1-1"
                value={newEvent && newEvent.gravityEvent}
                onChange={(e) => this.handler(e, "gravityEvent")}
                disabled={newEvent && newEvent.stateEvent === "closed"}
              ></select>
            </div>
          </div>
          <div className="uk-width-1-2">
            <div
              className={
                newEvent && newEvent.gravityEvent === "4"
                  ? "image-gravity-dark-green"
                  : newEvent && newEvent.gravityEvent === "3"
                  ? "image-gravity-green"
                  : newEvent && newEvent.gravityEvent === "2"
                  ? "image-gravity-yellow"
                  : newEvent && newEvent.gravityEvent === "1"
                  ? "image-gravity-orange"
                  : newEvent && newEvent.gravityEvent === "0"
                  ? "image-gravity-red"
                  : newEvent && newEvent.gravityEvent === "5"
                  ? "image-gravity-grey"
                  : ""
              }
            ></div>
          </div>
        </div>

        <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
          <div className="uk-width-1-2">
            <label className="uk-form-label">Tipo Fonte</label>
            <div className="uk-form-controls">
              <input
                id="fornitore-evento"
                name={"supplierEvent"}
                className="uk-input"
                disabled={true}
                value={
                  newEvent &&
                  (newEvent.supplierEvent || newEvent.supplierEvent === 0) &&
                  selectModal.sources[newEvent.supplierEvent]
                    ? SOURCE_TYPE[
                        selectModal.sources[newEvent.supplierEvent].sourceId
                      ]
                    : "Operatore"
                }
              ></input>
            </div>
          </div>
          <div className="uk-width-1-2">
            <label className="uk-form-label padding-form-label">
              Nome Fonte
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="source-evento"
                name={"source"}
                disabled={true}
                value={newEvent && newEvent.sourceOptionEvent}
              />
            </div>
          </div>
        </div>

        <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
          <div className="uk-width-1-2">
            <label className="uk-form-label">Probabilità Accadimento</label>
            <div className="uk-form-controls">
              <select
                id="probabilita-evento"
                name={"probability"}
                className="uk-select "
                value={newEvent && newEvent.probabilityEvent}
                onChange={(e) => this.handler(e, "probabilityEvent")}
                disabled={newEvent && newEvent.stateEvent === "closed"}
              ></select>
            </div>
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label">Note</label>
          <div className="uk-form-controls">
            <textarea
              className="uk-textarea"
              value={newEvent && newEvent.note}
              onChange={(e) => this.handler(e, "note")}
              disabled={newEvent && newEvent.stateEvent === "closed"}
            />
            <span
              className="iconRecycleBin"
              uk-icon="icon: acr-interface-cestino"
              onClick={(e) => {
                e.preventDefault();
                this.onClickIcon(e);
              }}
            ></span>
          </div>
        </div>
      </form>
    );
  }
}

GeneralTab.contextType = EnvironmentContext;

const mapDispatchToProps = {
  enableForwardEventButton,
  disableForwardEventButton,
  setNewEvent,
  updateNewEvent,
  setTypeLocalization,
};

const mapStateToProps = (state) => ({
  username: getUsername(state),
  newEvent: getNewEvent(state),
  currentEvent: getCurrentEvent(state),
  newEventValid: getNewEventValid(state),
  typeModal: getTypeModal(state),
  selectModal: getSelectModal(state),
  tabModal: getTabModal(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralTab)
);
