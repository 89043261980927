import React, { Component } from "react";
import "components/layout/modal/style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  tap,
  take,
  catchError,
  finalize,
  concatMap,
  mergeMap,
} from "rxjs/operators";
import { of, merge } from "rxjs";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
//COMPONENTS
import Popup from "components/shared/popup/popup";
import LeftComponentIcons from "./left-component-icons";
import TabHeader from "./tab-header";
import CloseEvent from "./event/close-event/close-event";
import ClosePractice from "./event/close-practice/close-practice";
import NewEventContainer from "./event/new-event/new-event-container";
import PracticeManagement from "./event/practice-management/practice-management";
//ACTIONS
import {
  togglePopup,
  resetModalUi,
  toggleModal,
  setApiOnError,
} from "reducers/ui/modal/modal.actions";
import { resetNewEvent } from "reducers/modal/modal.actions";
import { setEventsList } from "reducers/events/events.actions";
import {
  isOpenPopup,
  getNewEvent,
  getTypeModal,
  getCurrentEvent,
  getTabModal,
  isOpenModal,
  getSelectModal,
  getAllEvents,
  getCurrentEventGroup,
  isApiOnError,
  getUsername,
  getUserRoles,
} from "store";
import { addActivity } from "reducers/user/user.actions";
import { closeEvent, closeEventPractice } from "reducers/events/events.actions";
//MODEL
import { UserActivityModel } from "reducers/user/user.model";
import { SituationRecord } from "reducers/events/models/situation-record";
//UTILS
import { getTypeTranslation } from "utils/util-events";
import { filterBounds } from "components/layout/map/load-map/load-map";

class ModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeEvent: false,
      closePractice: false,
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  postEventCreationActivity = (event) => {
    const { username, addActivity, selectModal, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "SITUATION",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_UPDATED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value:
                'Evento "' +
                getTypeTranslation(event, selectModal) +
                '" con id: ' +
                event.situationRecordId,
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  clickOkToDelete = () => {
    const { currentEvent, closeEvent, setApiOnError } = this.props;
    this.setState({
      ...this.state,
      closeEvent: !this.state.closeEvent,
    });
    if (currentEvent && currentEvent.situationRecordId) {
      let body = {
        situationRecordId: currentEvent.situationRecordId,
        validity: {
          validityTimeSpecification: {
            overallStartTime:
              currentEvent.validity.validityStatus === "planned"
                ? new Date(Date.now() - 1000 * 60).toISOString()
                : currentEvent.validity.validityTimeSpecification
                    .overallStartTime,
            overallEndTime: new Date().toISOString(),
          },
          overrunning: true,
          validityStatus: "DEFINED_BY_VALIDITY_TIME_SPEC",
        },
      };
      this.apiService
        .closeSituationRecord(body)
        .pipe(
          take(1),
          tap((x) => {
            if (
              x &&
              !x.error &&
              x.validity &&
              x.validity.validityTimeSpecification
            ) {
              let curEv = { ...currentEvent };
              curEv.validity.validityTimeSpecification =
                x.validity.validityTimeSpecification;
              closeEvent(curEv);
            }
          }),
          concatMap((data) => {
            return this.postEventCreationActivity(currentEvent);
          }),
          finalize(() => this.checkNumberElements()),
          catchError((error) => {
            setApiOnError(true);
            console.error(error);
            return of(error);
          })
        )
        .subscribe();
    }
    //closeEvent({ ...currentEvent });
  };

  deletePractice = () => {
    const { newEvent, closeEventPractice, setApiOnError } = this.props;
    this.setState({
      closePractice: !this.state.closePractice,
    });

    let body = [];
    newEvent.associatedEvents.forEach((item) => {
      body.push({
        situationRecordId: item.situationRecordId,
        validity: {
          validityTimeSpecification: {
            overallStartTime:
              item.validity.validityStatus === "planned"
                ? new Date(Date.now() - 1000 * 60).toISOString()
                : item.validity.validityTimeSpecification.overallStartTime,
            overallEndTime: new Date().toISOString(),
          },
          overrunning: true,
          validityStatus: "DEFINED_BY_VALIDITY_TIME_SPEC",
        },
      });
    });
    of(...body)
      .pipe(
        concatMap((event) => this.apiService.closeSituationRecord(event)),
        tap((x) => {
          closeEventPractice(x);
        }),
        concatMap((event) => {
          let foundEvent = newEvent.associatedEvents.find(
            (item) => item.situationRecordId === event.situationRecordId
          );
          return this.postEventCreationActivity(foundEvent);
        }),
        finalize(() => this.checkNumberElements()),
        catchError((error) => {
          setApiOnError(true);
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  checkNumberElements = () => {
    let now = null;
    let arrayIntervalsOpen = [];

    return this.apiService
      .getSituationRecords(1, 0, now, ["OPEN", "SCHEDULED"])
      .pipe(
        tap((open) => {
          if (open && !open.error && open.totalElements) {
            let totalInArray = 0;
            for (let i = 0; i <= 1000; i++) {
              if (open.totalElements <= totalInArray) {
                break;
              } else {
                arrayIntervalsOpen.push({
                  limit: 50,
                  offset: i,
                });
                totalInArray += 50;
              }
            }
          }
        }),
        mergeMap(() => merge(this.openEvents(arrayIntervalsOpen, now))),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  openEvents = (arr, now) => {
    const { setEventsList, selectModal } = this.props;

    return of(...arr).pipe(
      concatMap((interval) =>
        this.apiService.getSituationRecords(
          interval.limit,
          interval.offset,
          now,
          ["OPEN", "SCHEDULED"]
        )
      ),
      tap((open) => {
        let openRoadSituations = [];
        if (open && !open.error && open.payload) {
          openRoadSituations = SituationRecord.fromArray(open.payload);
        }
        setEventsList(openRoadSituations, selectModal);
      }),
      finalize(() => {
        filterBounds(this);
      }),
      catchError((error) => {
        console.error(error);
        return of(error);
      })
    );
  };

  onClose = () => {
    const {
      resetModalUi,
      resetNewEvent,
      isOpenModal,
      setApiOnError,
    } = this.props;
    if (isOpenModal) {
      resetNewEvent();
      resetModalUi();
      setApiOnError(false);
    }
  };

  injectModalTypeComponent = () => {
    const { typeModal } = this.props;
    switch (typeModal) {
      case "newEvent":
        return <NewEventContainer />;
      case "modifyEvent":
        return <NewEventContainer />;
      case "addAssociatedEvent":
        return <NewEventContainer />;
      case "closePractice":
        return <ClosePractice />;
      case "closeEvent":
        return <CloseEvent />;
      case "practiceManagement":
        return <PracticeManagement />;
      default:
        return null;
    }
  };

  titleTab = () => {
    const { typeModal, currentEvent } = this.props;
    switch (typeModal) {
      case "newEvent":
        return "Eventi: Inserisci nuovo evento";
      case "addAssociatedEvent":
        return "Eventi: Inserisci evento associato";
      case "closePractice":
        return "Eventi: Chiudi Pratica";
      case "practiceManagement":
        if (currentEvent === null) {
          return "Eventi: Raggruppa in pratica";
        } else {
          return "Eventi: Gestione Pratica";
        }
      case "closeEvent":
        return "Eventi: Chiudi Eventi";
      case "modifyEvent":
        return "Eventi: Modifica Evento";
      default:
        return "";
    }
  };

  popupSwitchTitle = () => {
    const { typeModal, currentEvent } = this.props;
    switch (typeModal) {
      case "newEvent":
        return "Eventi: Nuovo Evento";
      case "addAssociatedEvent":
        return "Eventi: Evento Associato";
      case "modifyEvent":
        return "Eventi: Modifica Evento";
      case "practiceManagement":
        if (currentEvent === null) {
          return "Eventi: Raggruppa in pratica";
        } else {
          return "Eventi: Gestione Pratica";
        }
      default:
        return "";
    }
  };

  popupSwitchBody = () => {
    const {
      currentEvent,
      modalTabReducer,
      typeModal,
      newEvent,
      isApiOnError,
      selectModal,
    } = this.props;
    const { closeEvent, closePractice } = this.state;
    let body = "";
    let type = "";
    let underType = "";
    if (newEvent && (newEvent.typeEvent || newEvent.typeEvent === 0)) {
      type = selectModal.types[newEvent.typeEvent].typeNameIT;
      underType =
        selectModal.types[newEvent.typeEvent].subTypes[newEvent.underTypeEvent]
          .subTypeNameIT;
    }

    switch (typeModal) {
      case "modifyEvent":
        if (!isApiOnError) {
          if (modalTabReducer === "attributes") {
            body =
              "Evento " + type + " (" + underType + ") aggiornato con successo";
          }
          if (modalTabReducer === "general" && !closeEvent) {
            body =
              "Si sta per chiudere l'evento " +
              type +
              " (" +
              underType +
              "). Confermare l'operazione?";
          } else if (modalTabReducer === "general" && closeEvent) {
            body =
              "Evento " + type + " (" + underType + ") chiuso con successo.";
          }
        } else {
          body = "Errore: Evento non aggiornato";
        }
        break;
      case "addAssociatedEvent":
        body = !isApiOnError
          ? "Evento " +
            type +
            " (" +
            underType +
            ") aperto con successo, associato alla pratica " +
            (currentEvent && currentEvent.situationId)
          : "Errore: evento non creato";
        break;
      case "newEvent":
        body = !isApiOnError
          ? "Evento " + type + " (" + underType + ") aperto con successo"
          : "Errore: evento non creato";
        break;
      case "practiceManagement":
        body = !closePractice
          ? "Si stanno per chiudere tutti gli eventi inclusi nella pratica. Confermare l'operazione?"
          : "Pratica chiusa con successo.";
        break;
      default:
        break;
    }

    return body;
  };

  closePopupEvent = () => {
    const { togglePopup } = this.props;
    togglePopup();
    this.onClose();
  };

  render() {
    const { isOpenPopup, isApiOnError } = this.props;
    const { closeEvent, closePractice } = this.state;
    return (
      <div className="modal-container">
        <div className="uk-flex uk-flex-row uk-height-1-1 uk-width-1-1">
          <LeftComponentIcons />
          <div className="uk-flex uk-flex-column uk-height-1-1 uk-width-1-1">
            <TabHeader
              onClose={this.onClose}
              textTitle={this.titleTab()}
            ></TabHeader>
            <div className="modal-body">{this.injectModalTypeComponent()}</div>
          </div>
        </div>
        {isOpenPopup && (
          <Popup
            iconTypeError={isApiOnError}
            clickOkToDelete={this.clickOkToDelete}
            deletePractice={this.deletePractice}
            close
            textBody={this.popupSwitchBody()}
            titleHeader={this.popupSwitchTitle()}
            toggleClose={this.closePopupEvent}
            closeEvent={closeEvent}
            closePractice={closePractice}
          />
        )}
      </div>
    );
  }
}
ModalContainer.contextType = EnvironmentContext;

const mapDispatchToProps = {
  togglePopup,
  resetNewEvent,
  resetModalUi,
  closeEvent,
  toggleModal,
  closeEventPractice,
  setApiOnError,
  addActivity,
  setEventsList,
};

const mapStateToProps = (state) => ({
  isOpenPopup: isOpenPopup(state),
  newEvent: getNewEvent(state),
  listEvent: getAllEvents(state),
  typeModal: getTypeModal(state),
  currentEvent: getCurrentEvent(state),
  modalTabReducer: getTabModal(state),
  isOpenModal: isOpenModal(state),
  selectModal: getSelectModal(state),
  currentEventGroup: getCurrentEventGroup(state),
  isApiOnError: isApiOnError(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
);
