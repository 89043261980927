import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { take, tap, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import "../style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap, debounceTime } from "rxjs/operators";
import * as moment from "moment";

//COMPONENTS
import Loading from "components/shared/loading/loading";
import TableHead from "components/shared/table-components/table-head";
import TableBody from "./table-body";
import TableFilters from "components/shared/table-top-filters/table-filters";
import PageNumbers from "components/shared/page-numbers/page-number";
import AlarmDetail from "./alarm-detail";
//STORE
import {
  isOpenNavTab,
  getSavedTableColumns,
  getSavedDateColumns,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedSearchText,
  getSavedFilters,
  getSavedToggledFilters,
  getSelectModal,
  isAlarmTableOpen,
  getWsLastCameraAlarm,
  getWsLastCameraStatus,
  getUserRoles,
  getUsername,
} from "store";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import {
  resetListAlarmWs,
  resetListStatusWs,
} from "reducers/installations/cameras/cameras.actions";
import { addActivity } from "reducers/user/user.actions";
// MODEL
import { UserActivityModel } from "reducers/user/user.model";
import { AlarmModel } from "reducers/installations/cameras/cameras.model";
//UTILS
import {
  TABLE_COLUMNS,
  DATE_COLUMNS,
  initColumns,
  updateValueByWS,
} from "utils/table-utils/util-camera-table";
import * as FilterUtils from "utils/filter-utils";
import { debounce } from "utils/utils";
import { getFiltersForCameraTable } from "utils/util-cameras";
import { saveBlob } from "utils/hooks";
import {
  handleFilterStringChange,
  handleClickIncreasePage,
  handleClickDecreasePage,
  requestSortOrder,
  resetChildrenForHeaderDropdown,
  onDropdownCheckboxFilterClick,
  getCurrentPageNumber,
  resetFiltersState,
  initializeTableState,
  getTableStateSortConfig,
} from "utils/table-utils/util-general-tables";

const FILTER_TARGET = "cameraFilters";

class CameraTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      ...initializeTableState,
      currentListCamera: [],
      currentCameraWithActiveAlarms: [],
      targetCamera: "",
      stateTableColumns: TABLE_COLUMNS,
      sortConfig: getTableStateSortConfig("cam"),
    };
    this.getCamerasListDebounced = debounce(this.getCameraList, 800);
    this.numberPage$ = new BehaviorSubject();
  }

  componentDidMount = () => {
    const { searchText } = this.props;
    this.apiService = createApiService(this.context);
    this.subscriptions.push(
      this.numberPage$
        .pipe(
          debounceTime(800),
          switchMap(
            (x) =>
              new Observable((observer) => {
                const { pageToDisplay } = this.state;
                if (pageToDisplay !== "") {
                  this.setState({
                    ...this.state,
                    isTableDataLoading: true,
                  });
                  this.getCameraList();
                }
              })
          )
        )
        .subscribe()
    );

    if (searchText) {
      this.setState({
        ...this.state,
        filterStr: searchText,
        isTableDataLoading: true,
      });
    } else {
      this.setState({
        ...this.state,
        isTableDataLoading: false,
      });
    }
    this.tableConfigs();
  };

  getCurrentActiveAlarmsForSelectedCamera = (cameraId, cameraName) => {
    this.subscriptions.push(
      this.apiService
        .getAlarmsByCameraId(cameraId)
        .pipe(
          take(1),
          tap((data) => {
            let currentAlarms = AlarmModel.fromArray(data);
            this.setState({
              currentCameraWithActiveAlarms: currentAlarms,
              targetCamera: cameraId + " - " + cameraName,
            });
          }),

          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  tableConfigs = () => {
    const {
      tableColumns,
      dateColumns,
      saveTableFilters,
      searchText,
    } = this.props;

    const { columns, date } = initColumns(tableColumns, dateColumns);
    let sld = FilterUtils.constructFlatMultiSelect(columns);
    let obj = {};
    sld.forEach((x) => {
      obj[x.name] = { ...x };
    });
    saveTableFilters(
      {
        tableColumns: columns,
        dateColumns: date,
        dateFrom: null,
        dateTo: null,
        searchText: searchText || "",
        toggledFilters: obj || {},
      },
      FILTER_TARGET
    );
    this.setState({
      selected: sld,
      searchText: searchText || "",
    });
  };

  getCameraList = () => {
    const {
      count,
      currentPage,
      filterStr,
      sortConfig,
      stateTableColumns,
    } = this.state;

    const {
      diagnosticState,
      type,
      group,
      orderBy,
      orderType,
    } = getFiltersForCameraTable(stateTableColumns, sortConfig);

    this.subscriptions.push(
      this.apiService
        .getCamerasList(
          count,
          currentPage,
          diagnosticState,
          type,
          group,
          filterStr,
          ["NAME", "DESCRIPTION", "ID"],
          orderBy.length > 0 ? orderBy : null,
          orderType
        )
        .pipe(
          take(1),
          tap((data) => {
            let cameras = [...data.payload];
            let pageMax = Math.ceil(data.totalElements / count);

            this.setState({
              ...this.state,
              currentListCamera: cameras,
              isTableDataLoading: false,
              pageMax: pageMax,
            });
          }),
          catchError((error) => {
            console.error(error);
            this.setState({
              ...this.state,
              isTableDataLoading: false,
              currentListCamera: [],
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  //CALL API TO OBTAIN NEW ARC LIST
  refreshData = () => {
    this.setState({
      ...this.state,
      currentListCamera: [],
      isTableDataLoading: true,
    });
    this.getCameraList();
  };

  downloadExcel = () => {
    const {
      count,
      currentPage,
      sortConfig,
      filterStr,
      stateTableColumns,
    } = this.state;

    const { orderBy, orderType } = getFiltersForCameraTable(
      stateTableColumns,
      sortConfig
    );

    this.apiService
      .fetchCameraListExcel(
        currentPage,
        count,
        filterStr,
        ["NAME", "DESCRIPTION", "ID"],
        orderBy,
        orderType
      )
      .pipe(
        tap((blob) => {
          let filename =
            "cctv_export_" + moment().format("DD-MM-YYYY HH:mm:ss") + ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postCameraDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postCameraDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "CCTV",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Resoconto Telecamere"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  handleFilterChange = (e) => {
    handleFilterStringChange(
      this,
      e,
      FILTER_TARGET,
      this.getCamerasListDebounced
    );
  };

  // GESTIONE CHECKBOX: VISUALIZZAZIONE COLONNE
  handleSelectChange = (visibleColumns) => {
    FilterUtils.handleSelectChange(this, visibleColumns, FILTER_TARGET);
  };

  //GESTIONE CHECKBOX FILTRI SU COLONNE
  onCheckboxFilterClick = (newTableColumns) => {
    this.setState({
      ...this.state,
      isTableDataLoading: true,
    });
    onDropdownCheckboxFilterClick(
      this,
      this.getCameraList,
      FILTER_TARGET,
      newTableColumns
    );
  };

  getPageNumber = (pageNumber) => {
    getCurrentPageNumber(this, pageNumber);
  };

  handleClickIncrease = () => {
    handleClickIncreasePage(this);
  };

  handleClickDecrease = () => {
    handleClickDecreasePage(this);
  };

  requestSort = (key) => {
    requestSortOrder(this, key, this.getCameraList);
  };

  resetFilters = (e) => {
    const { resetTableFilters, toggledFilters } = this.props;
    const { selected, stateTableColumns } = this.state;

    e.stopPropagation();

    let newStateTableColumns = resetChildrenForHeaderDropdown(
      stateTableColumns
    );

    resetTableFilters(
      {
        tableColumns: { ...newStateTableColumns },
        dateColumns: [...DATE_COLUMNS],
        toggledFilters: toggledFilters,
      },
      FILTER_TARGET
    );

    resetFiltersState(
      this,
      newStateTableColumns,
      selected,
      this.getCameraList,
      "cam"
    );
  };

  toTableColumns = (cameras) => {
    let newList = [];
    cameras.forEach((camera) => {
      let newEl = {};
      if (camera) {
        newEl = {
          cameraId: camera.cctv.id ? camera.cctv.id : "-",
          cameraName: camera.cctv.name ? camera.cctv.name : "-",
          cameraCoordinates:
            Math.round(camera.cctv.latitude * 1000000) / 1000000 +
            ", " +
            Math.round(camera.cctv.longitude * 1000000) / 1000000,
          cameraDescription: camera.cctv.description
            ? camera.cctv.description
            : "-",
          diagnosticState:
            camera && camera.last_state && camera.last_state.diagnosticState
              ? camera.last_state.diagnosticState
              : "-",
          activeAlarms:
            camera.open_alarms.length > 0 ? camera.open_alarms.length : "0",
          type: camera.cctv.type || "-",
          group: camera.cctv.group || "-",
          allActiveAlarms: camera.open_alarms,
        };
      }
      newList = [...newList, newEl];
    });

    return newList;
  };

  render = () => {
    const {
      sortConfig,
      filterStr,
      selected,
      isTableDataLoading,
      currentListCamera,
      pageMax,
      pageToDisplay,
      currentCameraWithActiveAlarms,
      targetCamera,
    } = this.state;
    const {
      toggleGenericTable,
      isOpenNav,
      dateColumns,
      tableColumns,
      isAlarmTableOpen,
      wsCameraStatus,
      wsCameraAlarm,
      resetListAlarmWs,
      resetListStatusWs,
    } = this.props;

    let listOfCameras = [];
    updateValueByWS(
      currentListCamera,
      wsCameraStatus,
      wsCameraAlarm,
      resetListAlarmWs,
      resetListStatusWs
    );
    if (currentListCamera) {
      //MAPPA PER VISUALIZZAZIONE SU TABELLA
      listOfCameras = this.toTableColumns(currentListCamera);
    }
    return (
      <div className="acr-table uk-flex uk-flex-column">
        <div className="navbar-placeholder"></div>
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <div className="grey-space"></div>
          <div className=" uk-width-1-2">
            <ul className=" uk-tab-page-secondary uk-tab uk-margin-remove">
              <li className="uk-active bottombar-title">Resoconto CCTV</li>
            </ul>
          </div>
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={() => toggleGenericTable("cameraTable")}
            ></span>
          </div>
        </div>
        <TableFilters
          isOpenNav={isOpenNav}
          dateStart={{ date: null, name: "dateStartEvent" }}
          dateEnd={{ date: null, name: "dateEndEvent" }}
          handleFilterChange={this.handleFilterChange}
          handleSelectChange={this.handleSelectChange}
          resetFilters={this.resetFilters}
          filterStr={filterStr}
          filtrableDateColumns={dateColumns}
          selected={selected}
          refreshData={this.refreshData}
          disabledDataFilter={true}
        ></TableFilters>
        <div className="uk-flex uk-flex-right button-bar">
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.refreshData}
            >
              <span
                uk-icon="icon: refresh"
                uk-tooltip="Refresh tabella Telecamere"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              disabled={false}
              onClick={this.downloadExcel}
            >
              <span
                uk-icon="icon: download"
                uk-tooltip="Download Excel"
                className="filterBarIcons borderWhite"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
              onClick={this.resetFilters}
            >
              <span
                uk-icon="icon: acr-filter-remove"
                uk-tooltip="Cancella Filtri"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-flex uk-flex-right button-bar uk-padding-remove-left">
            <PageNumbers
              handleClickDecrease={this.handleClickDecrease}
              handleClickIncrease={this.handleClickIncrease}
              getPageNumber={this.getPageNumber || 1}
              currentPage={pageToDisplay || 1}
              minVal={1}
              pageMax={pageMax || 1}
            />
          </div>
        </div>
        <div className="uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left ">
          <table
            id="eventTable"
            className="uk-table uk-table-divider uk-table-hover"
          >
            <thead>
              <TableHead
                onCheckboxFilterClick={this.onCheckboxFilterClick}
                requestSort={this.requestSort}
                tableColumns={tableColumns}
                sortConfig={sortConfig}
              />
            </thead>
            <tbody>
              {isTableDataLoading ? (
                <tr>
                  <td>
                    <Loading />
                  </td>
                </tr>
              ) : (
                <TableBody
                  list={listOfCameras}
                  tableColumns={tableColumns}
                  getCurrentActiveAlarmsForSelectedCamera={
                    this.getCurrentActiveAlarmsForSelectedCamera
                  }
                />
              )}
            </tbody>
          </table>
        </div>
        {currentCameraWithActiveAlarms &&
        currentCameraWithActiveAlarms.length !== 0 &&
        targetCamera &&
        isAlarmTableOpen ? (
          <AlarmDetail
            currentCameraWithActiveAlarms={currentCameraWithActiveAlarms}
            targetCamera={targetCamera}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

CameraTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleGenericTable,
  saveTableFilters,
  resetTableFilters,
  resetListAlarmWs,
  resetListStatusWs,
  addActivity,
};

const mapStateToProps = (state) => ({
  isOpenNav: isOpenNavTab(state),
  tableColumns: getSavedTableColumns(state, FILTER_TARGET),
  dateColumns: getSavedDateColumns(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
  searchText: getSavedSearchText(state, FILTER_TARGET),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  toggledFilters: getSavedToggledFilters(state, FILTER_TARGET),
  selectModal: getSelectModal(state),
  isAlarmTableOpen: isAlarmTableOpen(state),
  wsCameraAlarm: getWsLastCameraAlarm(state),
  wsCameraStatus: getWsLastCameraStatus(state),
  userRoles: getUserRoles(state),
  username: getUsername(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CameraTable)
);
