import React, { Component } from "react";
import "./style.less";

class TableBody extends Component {
  render = () => {
    const { tableColumns, list } = this.props;

    return list.map((item, ind) => {
      return (
        <tr key={"column-tr-table-body" + ind}>
          {tableColumns &&
            Object.values(tableColumns).map((t, i) => {
              if (i !== 0) {
                return (
                  <td
                    uk-tooltip={"title: " + Object.values(item)[i - 1]}
                    key={"column-td-table-body" + i}
                    className={
                      tableColumns[t.id] && !tableColumns[t.id].show
                        ? "displayNone"
                        : ""
                    }
                  >
                    <p className={"p-dot"}>{Object.values(item)[i - 1]}</p>
                  </td>
                );
              }
              return null;
            })}
        </tr>
      );
    });
  };
}

export default TableBody;
