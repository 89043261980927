import React, { Component } from "react";
import { connect } from "react-redux";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import "./style.less";
import {
  setNumberActiveEvents,
  setNumberActiveEventsWithHighestSeverity,
} from "reducers/events/events.actions";
import {
  getActiveEvents,
  getNumberActiveEvents,
  getNumberActiveEventsWithHighestSeverity,
} from "store";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";

class Alert extends Component {
  alarmOn = false;
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {
      nrAlarmsToShow: "0",
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
    this.nbrActiveEvents();
    this.nbrActiveEventsWithHighestSeverity();
  }

  componentDidUpdate = (prevProps) => {
    const {
      eventsActiveReducer,
      numberActiveEventsWithHighestSeverity,
    } = this.props;

    if (
      prevProps.eventsActiveReducer !== eventsActiveReducer &&
      prevProps.eventsActiveReducer.length !== 0
    ) {
      //ACTIVE EVENTS REDUCER GETS POPULATED/UPDATED -> UPDATE ALARM VALUES TO SHOW
      this.nbrActiveEvents();
      this.showActiveEventsWithHighestSeverity(
        numberActiveEventsWithHighestSeverity,
        eventsActiveReducer
      );
    } else if (
      prevProps.eventsActiveReducer !== eventsActiveReducer &&
      prevProps.eventsActiveReducer.length === 0
    ) {
      this.nbrActiveEvents();
      this.nbrActiveEventsWithHighestSeverity();
    }
  };

  nbrActiveEvents = () => {
    const { setNumberActiveEvents } = this.props;
    this.subscriptions.push(
      this.apiService
        .getSituationRecords(1, 0, null, ["OPEN"])
        .pipe(
          tap((open) => {
            if (open && !open.error && open.totalElements) {
              setNumberActiveEvents(open.totalElements);
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  //FIRST INITALISATION: IT WORKS UP TO 50 EVENTS WITH HIGHEST SEVERITY
  nbrActiveEventsWithHighestSeverity = () => {
    const {
      setNumberActiveEventsWithHighestSeverity,
      eventsActiveReducer,
    } = this.props;
    let filters = {
      severity: ["HIGHEST"],
    };
    this.subscriptions.push(
      this.apiService
        .getSituationRecords(50, 0, null, ["OPEN"], null, filters)
        .pipe(
          tap((open) => {
            if (open && !open.error && open.payload) {
              setNumberActiveEventsWithHighestSeverity(open.payload.length);
              this.showActiveEventsWithHighestSeverity(
                open.payload.length,
                eventsActiveReducer
              );
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  showActiveEventsWithHighestSeverity = (
    numberActiveEventsWithHighestSeverity,
    eventsActiveReducer
  ) => {
    let nrAlarms = 0;
    if (eventsActiveReducer.length > 0) {
      eventsActiveReducer.forEach((element) => {
        if (element && element.severity && element.severity === "HIGHEST") {
          nrAlarms++;
        }
      });
    } else if (eventsActiveReducer.length === 0) {
      if (numberActiveEventsWithHighestSeverity < 50) {
        nrAlarms = numberActiveEventsWithHighestSeverity;
      } else {
        nrAlarms = numberActiveEventsWithHighestSeverity + "+";
      }
    }

    nrAlarms = nrAlarms.toString();
    this.setState({
      nrAlarmsToShow: nrAlarms,
    });
    return nrAlarms;
  };

  render() {
    const { numberActiveEvents } = this.props;
    const { nrAlarmsToShow } = this.state;

    return (
      <div>
        <div className="uk-flex uk-flex-row uk-flex-middle alert-container">
          {/* ALERTS */}
          <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
            <div className="alert-image">
              <span
                className={nrAlarmsToShow !== "0" ? "alarm-on" : "alarm-off"}
                uk-icon="icon: acr-interface-alerts"
              ></span>
              <span className="uk-badge alert-badge">{nrAlarmsToShow}</span>
            </div>
            <div className="uk-padding-small">
              <h4 className="alert-text">Alert</h4>
            </div>
          </div>
          {/* EVENTS */}
          <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
            <div className="event-image">
              <span
                className={"alarm-off"}
                uk-icon="icon:  acr-interface-events"
              ></span>
              <span className="uk-badge event-badge">{numberActiveEvents}</span>
            </div>
            <div className="uk-padding-small">
              <h4 className="alert-text">Eventi</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Alert.contextType = EnvironmentContext;

const mapStateToProps = (state) => ({
  eventsActiveReducer: getActiveEvents(state),
  numberActiveEvents: getNumberActiveEvents(state),
  numberActiveEventsWithHighestSeverity: getNumberActiveEventsWithHighestSeverity(
    state
  ),
});

const mapDispatchToProps = {
  setNumberActiveEvents,
  setNumberActiveEventsWithHighestSeverity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
