import React, { Component } from "react";
//import "../style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentNode } from "store";
//ASSETS
import icn_map_point from "assets/icon/graph/icn-map-point.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";

class NodeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  header = () => {
    const { toggleNodeDetails, currentNode } = this.props;

    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="centroid-icon uk-flex uk-flex-middle uk-flex-center">
          <div className="parent uk-flex uk-flex-middle uk-flex-center">
            <div className="circle2"></div>
            <img
              alt="icn_diamond"
              src={icn_diamond}
              className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
            ></img>
            {/* <div className="node-icon image2"></div> */}
            <img
              alt="icn_map_point"
              src={icn_map_point}
              className="image2  height-width-48 uk-flex uk-flex-middle uk-flex-center arch-icon"
            ></img>
          </div>
        </div>
        <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-emphasis-arc">
          {currentNode && currentNode.properties.n
            ? currentNode.properties.n
            : "-"}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            toggleNodeDetails(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { currentNode } = this.props;

    return (
      <div className="uk-flex uk-flex-column contentBodyEventDetail">
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">ID Nodo</div>
          <div className="text-detail-emphasis-arc">
            {currentNode && currentNode.properties.n
              ? currentNode.properties.n
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Latitudine</div>
          <div className="text-detail-emphasis-arc">
            {currentNode && currentNode.geometry.coordinates[1]
              ? currentNode.geometry.coordinates[1]
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Longitudine</div>
          <div className="text-detail-emphasis-arc">
            {currentNode && currentNode.geometry.coordinates[0]
              ? currentNode.geometry.coordinates[0]
              : "-"}
          </div>
        </li>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentNode: getCurrentNode(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NodeDetail)
);
