import React, { Component } from "react";
import alarm_icon from "assets/icon/installations/alarm_icon.svg";
//COMPONENTS
import Permission from "components/shared/permission";
//UTIL
import { SENSOR_STATUS } from "utils/util-cameras";
import { WebcamPin } from "./webcam-pin-camera";

class WebcamDetailHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      currentSelectedCam,
      pinnedCameras,
      pinCamera,
      toggleDetails,
      maxCamerasPinnable
    } = this.props;

    let statusIcon = SENSOR_STATUS.find(
      (status) =>
        currentSelectedCam &&
        currentSelectedCam.properties &&
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
          .last_state &&
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.last_state
          .diagnosticState &&
        typeof currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
          .last_state.diagnosticState === "string" &&
        status.name ===
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
          .last_state.diagnosticState
    );

    const isPinned = pinnedCameras.find(
      (item) =>
        item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
          .deviceId
    );

    const showAlarmIcon =
      currentSelectedCam &&
      currentSelectedCam.properties &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
        .open_alarms &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.open_alarms
        .length > 0;
        
    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="uk-position-relative event-height-width-48">
          {showAlarmIcon && (
            <img className="img-alarm" alt={"alarm_img"} src={alarm_icon} />
          )}
          <img
            className={"img-status"}
            alt={"webcam_img"}
            src={statusIcon && statusIcon.icon}
          />
        </div>
        <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-muted-event">
          {currentSelectedCam &&
            currentSelectedCam.properties &&
            currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
            currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
            currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
            currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv.name
            ? currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
              .name
            : "-"}
        </div>
        <Permission actions="CCTV-STREAMING">
          <div className="uk-margin-right">
            <WebcamPin
              camera={currentSelectedCam}
              maxCamerasPinnable={maxCamerasPinnable}
              pinCamera={pinCamera}
              pinnedCameras={pinnedCameras}
              isPinned={isPinned}
            />
          </div>
        </Permission>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            toggleDetails(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  }
}

export default WebcamDetailHeader;
