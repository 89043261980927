import React, { Component } from "react";
import createApiService from "services/api.service";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.less";

//UTILS
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";
import { loadFilterStop } from "utils/utils-filter-tpl";

//ACTIONS
import { setCurrentRoutesMapForStopSelected } from "reducers/map/map.actions";
import {
  setCurrentStop,
  resetCurrentStop,
  setCurrentIdRouteForStop,
  setCurrentIdTripForStop,
} from "reducers/stops/stops.actions";
import { getCurrentStop, getLayoutMap } from "store";

//COMPONENTS
import InfiniteScroll from "react-infinite-scroll-component";
import StopTimeTable from "components/layout/stops-list/stop-timetable";
import StopDetail from "components/layout/stops-list/stop-detail";
import Loading from "components/shared/loading/loading";

// ASSETS
import EnvironmentContext from "environment-context";

const SCROLL_OFFSET = 50;

class StopsList extends Component {
  subscriptions = [];
  ref = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {
      stop: null,
      nextStops: [],
      currentListLen: 0,
      currentOffset: SCROLL_OFFSET,
      isStopsDetailLoading: false,
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  //modifica logica con cui il current viene cambiato
  handleClickStop = (stop) => {
    loadFilterStop(this, stop, false);
  };

  handleClickStopDetail = () => {
    this.setState({
      ...this.state,
      viewStopDetail: true,
      viewStopTimetable: false,
    });
  };

  handleClickStopTimetable = () => {
    this.setState({
      ...this.state,
      viewStopDetail: false,
      viewStopTimetable: true,
    });
  };

  resetSelected = () => {
    const { resetCurrentStop } = this.props;
    resetCurrentStop();

    this.setState({
      ...this.state,
      viewStopDetail: false,
      viewStopTimetable: false,
    });
  };

  handleClickPdf = (link) => {
    //this.apiService.getPdf(link);
    this.apiService.goToSite(link);
  };

  fetchMoreData = () => {
    const { stopsList } = this.props;
    const { currentOffset } = this.state;

    let newOffset = currentOffset + SCROLL_OFFSET;
    let fetchmore = stopsList.slice(0, newOffset);
    this.setState({
      nextStops: fetchmore,
      currentOffset: newOffset,
    });
  };

  constructFetchedDOM = () => {
    const { stopsReducer, layoutMapReducer } = this.props;
    const { viewStopDetail, viewStopTimetable, nextStops } = this.state;

    return (
      <div className="stop-list-container">
        {(!viewStopDetail && !viewStopTimetable) || !stopsReducer ? (
          <div className="uk-list">
            {nextStops.map((stop, index) => {
              return (
                <div
                  className="uk-flex uk-flex-row uk-flex-middle uk-flex-between"
                  key={index}
                >
                  <li
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleClickStop(stop);
                    }}
                    className="uk-flex uk-flex-between uk-flex-middle"
                    id={stop.id}
                  >
                    <div
                      uk-icon={
                        stop.transportMode && stop.transportMode.length === 2
                          ? "icon: acr-list-stops-exchange"
                          : "icon: acr-list-stops"
                      }
                      className={
                        stopsReducer && stopsReducer.id === stop.id
                          ? stop.transportMode && stop.transportMode.length <= 1
                            ? stop.transportMode.find(
                                (t) => t === TYPE_TRANSPORT_MODE["extraurb"]
                              )
                              ? stop.isParkingPoint
                                ? "stopSuburban isParkingPointActive isParkingPointSubActive"
                                : "stopSuburban stopSuburbanBack"
                              : stop.isParkingPoint
                              ? "stopUrban isParkingPointActive isParkingPointUrbActive"
                              : "stopUrban stopUrbanBack"
                            : stop.isParkingPoint
                            ? "stopUrbSub stopUrbSubBack isParkingPointSubUrbActive"
                            : "stopUrbSub stopUrbSubBack"
                          : stop.transportMode && stop.transportMode.length <= 1
                          ? stop.transportMode.find(
                              (t) => t === TYPE_TRANSPORT_MODE["extraurb"]
                            )
                            ? stop.isParkingPoint
                              ? "stopInactiveSub isParkingPointInactive isParkingPointSubInactive"
                              : "stopInactiveSub"
                            : stop.isParkingPoint
                            ? "stopInactiveUrb isParkingPointInactive isParkingPointUrbInactive"
                            : "stopInactiveUrb"
                          : stop.isParkingPoint
                          ? "stopInactiveUrbSub isParkingPointInactive isParkingPointSubUrbInactive"
                          : "stopInactiveUrbSub"
                      }
                    ></div>
                    <div
                      className={
                        stopsReducer && stopsReducer.id === stop.id
                          ? "uk-width-1-1 uk-margin-left uk-margin-right active list-text"
                          : "uk-width-1-1 uk-margin-left uk-margin-right inactive list-text"
                      }
                    >
                      {stop.nameLong}
                    </div>
                  </li>
                  <span
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleClickPdf("https://m.atv.verona.it/#/stops");
                    }}
                    uk-icon="icon: acr-interface-time"
                    className={
                      stopsReducer && stopsReducer.id === stop.id
                        ? "pointer active"
                        : "pointer inactive"
                    }
                  />
                </div>
              );
            })}
          </div>
        ) : stopsReducer && viewStopDetail && !viewStopTimetable ? (
          <StopDetail
            current={stopsReducer}
            handleClickStopTimeTable={this.handleClickStopTimetable}
            handleResetSelected={this.resetSelected}
          />
        ) : stopsReducer && !viewStopDetail && viewStopTimetable ? (
          <StopTimeTable
            current={stopsReducer}
            handleClickStopDetail={this.handleClickStopDetail}
            layer={layoutMapReducer}
            handleResetSelected={this.resetSelected}
          />
        ) : null}
      </div>
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { nextStops, currentOffset, currentListLen } = state;
    const { stopsList } = props;
    let newState = {};
    if (currentListLen !== stopsList.length && stopsList.length === 0) {
      newState = {
        currentListLen: stopsList.length,
        currentOffset: SCROLL_OFFSET,
        nextStops: [],
      };
    }

    if (currentListLen !== stopsList.length && stopsList.length > 0) {
      newState = {
        currentListLen: stopsList.length,
        currentOffset: stopsList.length - 1, //SCROLL_OFFSET
        nextStops: stopsList.slice(0, stopsList.length), //stopsList.slice(0, SCROLL_OFFSET),
      };
    }
    if (nextStops.length === 0 && currentListLen > 0) {
      newState = {
        ...state,
        nextStops: stopsList.slice(
          0,
          currentListLen > SCROLL_OFFSET ? currentOffset : currentListLen
        ),
      };
    }

    return newState;
  }

  renderInfiniteScroll = () => {
    const { stopsList, stopsReducer } = this.props;
    const { nextStops } = this.state;

    return (
      <InfiniteScroll
        dataLength={nextStops.length || 0} //This is important field to render the next data
        next={this.fetchMoreData}
        hasMore={nextStops.length === stopsList.length ? false : true}
        loader={
          <div>
            {stopsList.length && !stopsReducer ? "" /* <Loading /> */ : ""}
          </div>
        }
        scrollableTarget={"tabpanel-1"}
        className="inner-infinite-scroll"
      >
        {this.constructFetchedDOM()}
      </InfiniteScroll>
    );
  };

  render() {
    const { isStopsLoading } = this.props;
    const { isStopsDetailLoading } = this.state;

    return (
      <div>
        {isStopsLoading || isStopsDetailLoading ? (
          <Loading />
        ) : (
          this.renderInfiniteScroll()
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

StopsList.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setCurrentStop,
  resetCurrentStop,
  setCurrentIdRouteForStop,
  setCurrentIdTripForStop,
  setCurrentRoutesMapForStopSelected,
};

const mapStateToProps = (state) => ({
  stopsReducer: getCurrentStop(state),
  layoutMapReducer: getLayoutMap(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StopsList)
);
