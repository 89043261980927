// DECK
import { CompositeLayer } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
import Supercluster from "supercluster";
import { SEVERITY_ENUM } from "utils/utils";
import { selectFields } from "enums/selectable-params";

import * as ICON_MEDIUM_EVENT from "assets/cluster/location-icon-media-severity-2.png";
import * as ICON_VERY_HIGH_EVENTS from "assets/cluster/location-icon-moltoAlta-severity-2.png";
import * as ICON_HIGH_EVENTS from "assets/cluster/location-icon-alta-severity-2.png";
import * as ICON_LOW_EVENTS from "assets/cluster/location-icon-bassa-severity-2.png";
import * as ICON_UNKNOWN_EVENTS from "assets/cluster/location-icon-unknown-severity-2.png";

function getIconName(size) {
  if (size === 0) {
    return "";
  }
  if (size < 10) {
    return `marker-${size}`;
  }
  if (size < 300) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  return "marker-300";
}

function getIconSize(size) {
  return Math.min(100, size) / 100 + 1;
}

class IconClusterEventsLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: 18,
        minPoints: 2,
        radius: props.sizeScale,
        map: (props) => ({
          severity: (props && props.severity) || "Molto bassa",
        }),
        reduce: (acc, prs) => {
          let currentMax =
            Math.max(
              SEVERITY_ENUM[acc.severity],
              SEVERITY_ENUM[prs.severity]
            ) || 0;
          acc.severity = Object.keys(SEVERITY_ENUM).find(
            (key) => SEVERITY_ENUM[key] === currentMax
          );
        },
      });

      index.load(
        props.data.map((d) => {
          return {
            geometry: { coordinates: props.getPosition(d) },
            properties: d,
          };
        })
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      const { index } = this.state;
      let cluster = index.getClusters([-180, -85, 180, 85], z);

      cluster.map((cl) => {
        let maxSeverity = 0;
        if (cl.properties && cl.id) {
          index.getChildren(cl.id, z).forEach((item) => {
            if (item && item.properties && item.properties.severity) {
              if (maxSeverity < SEVERITY_ENUM[item.properties.severity]) {
                maxSeverity = SEVERITY_ENUM[item.properties.severity];
              }
            }
          });
          cl.severity = Object.keys(SEVERITY_ENUM).find(
            (key) => SEVERITY_ENUM[key] === maxSeverity
          );
        }
        return cl;
      });

      this.setState({
        data: cluster,
        z,
      });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== "hover") {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    const { iconMapping, sizeScale } = this.props;

    let veryHighSeverityArr = [];
    let highSeverityArr = [];
    let mediumSeverityArr = [];
    let lowSeverityArr = [];
    let unknownSeverityArr = [];
    veryHighSeverityArr = data.filter(
      (item) =>
        item &&
        item.properties &&
        item.properties.severity &&
        item.properties.severity === selectFields.GRAVITA.properties[0].name
    );
    highSeverityArr = data.filter(
      (item) =>
        item &&
        item.properties &&
        item.properties.severity &&
        item.properties.severity === selectFields.GRAVITA.properties[1].name
    );
    mediumSeverityArr = data.filter(
      (item) =>
        item &&
        item.properties &&
        item.properties.severity &&
        item.properties.severity === selectFields.GRAVITA.properties[2].name
    );
    lowSeverityArr = data.filter(
      (item) =>
        item &&
        item.properties &&
        item.properties.severity &&
        item.properties.severity
          .toUpperCase()
          .includes(selectFields.GRAVITA.properties[3].name)
    );
    unknownSeverityArr = data.filter(
      (item) =>
        item &&
        item.properties &&
        item.properties.severity &&
        item.properties.severity
          .toUpperCase()
          .includes(selectFields.GRAVITA.properties[5].name)
    );

    let layers = [
      new IconLayer(
        this.getSubLayerProps({
          id: "icon-event-vh",
          data: veryHighSeverityArr,
          iconAtlas: ICON_VERY_HIGH_EVENTS,
          iconMapping,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) => {
            return getIconName(
              d.properties.cluster ? d.properties.point_count : 1
            );
          },
          getSize: (d) =>
            getIconSize(d.properties.cluster ? d.properties.point_count : 1),
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "icon-event-h",
          data: highSeverityArr,
          iconAtlas: ICON_HIGH_EVENTS,
          iconMapping,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) =>
            getIconName(d.properties.cluster ? d.properties.point_count : 1),
          getSize: (d) =>
            getIconSize(d.properties.cluster ? d.properties.point_count : 1),
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "icon-event-m",
          data: mediumSeverityArr,
          iconAtlas: ICON_MEDIUM_EVENT,
          iconMapping,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) =>
            getIconName(d.properties.cluster ? d.properties.point_count : 1),
          getSize: (d) =>
            getIconSize(d.properties.cluster ? d.properties.point_count : 1),
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "icon-event-l",
          data: lowSeverityArr,
          iconAtlas: ICON_LOW_EVENTS,
          iconMapping,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) =>
            getIconName(d.properties.cluster ? d.properties.point_count : 1),
          getSize: (d) =>
            getIconSize(d.properties.cluster ? d.properties.point_count : 1),
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "icon-event-u",
          data: unknownSeverityArr,
          iconAtlas: ICON_UNKNOWN_EVENTS,
          iconMapping,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) =>
            getIconName(d.properties.cluster ? d.properties.point_count : 1),
          getSize: (d) =>
            getIconSize(d.properties.cluster ? d.properties.point_count : 1),
        })
      ),
    ];

    return layers;
  }
}

IconClusterEventsLayer.layerName = "IconClusterEventsLayer";

export default IconClusterEventsLayer;
