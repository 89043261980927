import React from "react";
import * as moment from "moment";

//UTILS
import { getIconEvent } from "utils/utils_event_icon";
import {
  PROBABILITY_OF_OCCURRENCE,
  EVENT_STATE,
  SEVERITY_ENUM,
  SOURCE_TYPE,
  eventLocalizationToShowUtil,
} from "utils/utils";
import { getSubtypeTranslation } from "utils/util-events";

export const renderTableData = (component, listEvents, selectedEvents) => {
  const {
    tableColumns,
    currentEvent,
    selectModal,
    allEvents,
  } = component.props;
  const { listEventsFromApi } = component.state;

  const archIds = allEvents.map((item) => {
    let l = item.groupOfLocations
      .map(
        (location) =>
          location.linear?.linearWithinLinearElement?.linearElement?.archId
      )
      .filter(Boolean);
    return { id: item.situationRecordId, archId: l };
  });

  return listEvents.map((event, index) => {
    return tableColumns ? (
      <tr
        key={event.eventId}
        ref={(el) => {
          if (currentEvent && event.eventId === currentEvent.situationRecordId)
            component.selectedRef = el;
        }}
        className={
          currentEvent && event.eventId === currentEvent.situationRecordId
            ? "row-selected"
            : ""
        }
        onClick={(e) => {
          e.preventDefault();
          component.handleTableRowClick(event);
        }}
      >
        {selectedEvents && (
          <td>
            <input
              key={event.eventId}
              className={
                tableColumns &&
                tableColumns["checkbox"] &&
                !tableColumns["checkbox"].show
                  ? "displayNone"
                  : "uk-checkbox uk-checkbox-large cursor-disabled"
              }
              uk-tooltip={
                archIds.filter((e) => e.id === event.eventId).length > 0 &&
                archIds.filter((e) => e.id === event.eventId)[0].archId.length < 1
                  ? "Evento non selezionabile"
                  : null
              }
              type="checkbox"
              onClick={(e) => {
                e.stopPropagation();
                component.handleTableRowClickSelected(event);
              }}
              onChange={() => {}}
              checked={
                [...selectedEvents].find(
                  (item) => item.situationRecordId === event.eventId
                ) || false
              }
              disabled={
                archIds.filter((e) => e.id === event.eventId).length > 0 &&
                archIds.filter((e) => e.id === event.eventId)[0].archId.length < 1    
              }
            />
          </td>
        )}
        <td
          className={
            tableColumns && tableColumns["icon"] && !tableColumns["icon"].show
              ? "displayNone"
              : ""
          }
        >
          <div className="uk-position-relative height-width-48">
            <img
              alt={
                listEventsFromApi.find(
                  (_) => _.situationRecordId === event.eventId
                ).situationRecordType
              }
              src={getIconEvent(
                listEventsFromApi.find(
                  (_) => _.situationRecordId === event.eventId
                ),
                selectModal
              )}
            />
          </div>
        </td>
        <td
          uk-tooltip={"title: " + event.eventId}
          className={
            tableColumns &&
            tableColumns["eventId"] &&
            !tableColumns["eventId"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">{event.eventId}</p>
        </td>
        <td
          uk-tooltip={"title: " + event.eventType}
          className={
            tableColumns && tableColumns["type"] && !tableColumns["type"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">{event.eventType}</p>
        </td>
        <td
          uk-tooltip={"title: " + event.eventSubType}
          className={
            tableColumns &&
            tableColumns["subtype"] &&
            !tableColumns["subtype"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">{event.eventSubType}</p>
        </td>
        <td
          uk-tooltip={"title: " + event.eventState}
          className={
            tableColumns && tableColumns["state"] && !tableColumns["state"].show
              ? "displayNone"
              : ""
          }
        >
          <div
            className={
              event.eventState === "Attivo"
                ? "green"
                : event.eventState === "Pianificato"
                ? "blue"
                : event.eventState === "Terminato"
                ? "green"
                : event.eventState === "Chiuso"
                ? "orange"
                : ""
            }
          >
            <p className="p-dot">{event.eventState}</p>
          </div>
        </td>
        <td
          uk-tooltip={"title: " + event.eventLocation.toString()}
          className={
            tableColumns &&
            tableColumns["location"] &&
            !tableColumns["location"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">{event.eventLocation}</p>
        </td>
        <td
          uk-tooltip={"title: " + event.eventSource}
          className={
            tableColumns &&
            tableColumns["source"] &&
            !tableColumns["source"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">{event.eventSource}</p>
        </td>
        <td
          uk-tooltip={"title: " + event.eventSeverity}
          className={
            tableColumns &&
            tableColumns["severity"] &&
            !tableColumns["severity"].show
              ? "displayNone"
              : ""
          }
        >
          <div
            className={
              event.eventSeverity === 1
                ? "image-gravity-dark-green"
                : event.eventSeverity === 2
                ? "image-gravity-green"
                : event.eventSeverity === 3
                ? "image-gravity-yellow"
                : event.eventSeverity === 4
                ? "image-gravity-orange"
                : event.eventSeverity === 5
                ? "image-gravity-red"
                : event.eventSeverity === 0
                ? "image-gravity-grey"
                : ""
            }
          >
            {/*event.eventSeverity*/}
          </div>
        </td>
        <td
          uk-tooltip={"title: " + event.eventProbability}
          className={
            tableColumns &&
            tableColumns["probabilityOfOccurrence"] &&
            !tableColumns["probabilityOfOccurrence"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">
            {PROBABILITY_OF_OCCURRENCE[event.eventProbability]}
          </p>
          {/* === "CERTAIN" ? (
                <div className="check"> </div>
              ) : (
                ""
              )} */}
        </td>
        <td
          uk-tooltip={"title: " + event.eventIdPractice}
          className={
            tableColumns &&
            tableColumns["practiceId"] &&
            !tableColumns["practiceId"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">{event.eventIdPractice}</p>
        </td>
        <td
          uk-tooltip={"title: " + event.eventStartTime}
          className={
            tableColumns &&
            tableColumns["dateStart"] &&
            !tableColumns["dateStart"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">{event.eventStartTime}</p>
        </td>
        <td
          uk-tooltip={"title: " + event.eventEndTime}
          className={
            tableColumns &&
            tableColumns["dateEnd"] &&
            !tableColumns["dateEnd"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">{event.eventEndTime}</p>
        </td>
        {/* <td>{event.eventVMS === 1 ? <div className="check"> </div> : ""}</td> */}
        <td
          uk-tooltip={"title: " + event.eventInsertTime}
          className={
            tableColumns &&
            tableColumns["insertedOn"] &&
            !tableColumns["insertedOn"].show
              ? "displayNone"
              : ""
          }
        >
          <p className="p-dot">{event.eventInsertTime}</p>
        </td>
      </tr>
    ) : (
      <tr key={"berofe-api" + index}></tr>
    );
  });
};

export const toTableColumns = (component, events) => {
  const { selectModal } = component.props;
  let newList = [];

  events.map((event) => {
    let arrLocations =
      event && event.groupOfLocations && event.groupOfLocations.length > 0
        ? eventLocalizationToShowUtil(event.groupOfLocations)
        : [];

    let newEl = {};
    if (event) {
      newEl = {
        eventId: event.situationRecordId,
        eventIcon: event.situationRecordType
          ? {
              situationRecordId: event.situationRecordId,
              situationRecordType: event.situationRecordType,
              probabilityOfOccurrence: event.probabilityOfOccurrence,
              validity: {
                validityStatus: event.validity && event.validity.validityStatus,
              },
            }
          : "-",
        eventType:
          event.situationRecordType &&
          selectModal.types &&
          selectModal.types.find(
            (z) => z.typeName === event.situationRecordType
          )
            ? selectModal.types.find(
                (z) => z.typeName === event.situationRecordType
              ).typeNameIT
            : "–",
        eventSubType: getSubtypeTranslation(event, selectModal),
        eventInsertTime: event.situationRecordCreationTime
          ? moment(event.situationRecordCreationTime).format("DD/MM/YY - HH:mm")
          : "–",
        eventSource:
          event.source && event.source.sourceReference
            ? SOURCE_TYPE[event.source.sourceReference] +
              " (" +
              ((event.source.sourceName && event.source.sourceName.content) ||
                "") +
              ") " +
              event.source.sourceIdentification
            : "",
        eventSeverity: SEVERITY_ENUM[event.severity],
        eventProbability: event.probabilityOfOccurrence,
        eventStartTime:
          event.validity &&
          event.validity.validityTimeSpecification &&
          event.validity.validityTimeSpecification.overallStartTime
            ? moment(
                event.validity.validityTimeSpecification.overallStartTime
              ).format("DD/MM/YY - HH:mm")
            : "-",
        eventEndTime:
          event.validity &&
          event.validity.validityTimeSpecification &&
          event.validity.validityTimeSpecification.overallEndTime
            ? moment(
                event.validity.validityTimeSpecification.overallEndTime
              ).format("DD/MM/YY - HH:mm")
            : "-",
        eventLocation: arrLocations.toString(),
        eventState:
          event.validity && event.validity.validityStatus
            ? EVENT_STATE[event.validity.validityStatus]
            : "-",
        eventIdPractice: event.situationId ? event.situationId : "",
        eventVMS: Math.floor(Math.random() * 2),
      };
    }
    arrLocations = [];
    return (newList = [...newList, newEl]);
  });

  return newList;
};
