import { CompositeLayer, PathLayer } from "deck.gl";

class BusLineLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      color,
      onClick,
      visible = true,
      selected,
      isDrawingMode,
    } = this.props;

    let layers = [
      new PathLayer(
        this.getSubLayerProps({
          id,
          data,
          getPath: (d) => d && d.geometry && d.geometry.coordinates,
          onClick,
          getColor: color,
          widthMinPixels: selected ? 10 : 4,
          widthMaxPixels: selected ? 10 : 4,
          visible,
          pickable: isDrawingMode ? false : true,
          rounded: true,
          trailLength: 200,
          currentTime: 100,
        })
      ),
    ];
    if (selected) {
      let outlineId = id + "-outiline";
      layers.unshift(
        new PathLayer(
          this.getSubLayerProps({
            id: outlineId,
            data,
            getPath: (d) => d && d.geometry && d.geometry.coordinates,
            onClick,
            getColor: [255, 255, 255],
            widthMinPixels: 12,
            widthMaxPixels: 12,
            visible,
            pickable: isDrawingMode ? false : true,
            rounded: true,
            trailLength: 200,
            currentTime: 100,
          })
        )
      );
    }

    return layers;
  }
}

BusLineLayer.layerName = "BusLineLayer";

export default BusLineLayer;
