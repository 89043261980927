export const KpiActions = {
  REMOVE_SELECTED_KPI: "KPI/REMOVE_SELECTED_KPI",
  ADD_SELECTED_KPI: "KPI/ADD_SELECTED_KPI",
  UPDATE_LIST_KPI: "KPI/UPDATE_LIST_KPI",
  EMPTY_SELECTED_KPI_LIST: "KPI/EMPTY_SELECTED_KPI_LIST",
};

export const updateKpiList = (kpi) => (dispatch) => {
  dispatch({
    type: KpiActions.UPDATE_LIST_KPI,
    payload: kpi,
  });
};

export const addSelectedKpi = (kpi) => (dispatch) => {
  dispatch({
    type: KpiActions.ADD_SELECTED_KPI,
    payload: kpi,
  });
};

export const removeSelectedKpi = (kpi) => (dispatch) => {
  dispatch({
    type: KpiActions.REMOVE_SELECTED_KPI,
    payload: kpi,
  });
};

export const emptySelectedKpiList = () => (dispatch) => {
  dispatch({
    type: KpiActions.EMPTY_SELECTED_KPI_LIST,
    payload: null,
  });
};
