import React, { Component } from "react";
import "./style.less";
import * as moment from "moment";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

import {
  setCurrentContextTimeOffSet,
  setCurrentTrafficTime,
  setCurrentDayType,
  setCurrentTrafficStartTime,
} from "reducers/traffic/traffic.actions";
import {
  getTrafficContexts,
  getTrafficCurrentTime,
  getCurrentTimeOffSet,
  getTrafficCurrentDayType,
  isTypicalVisible,
  getTrafficCurrentStartTime,
  isOpenNavTab,
} from "store";
//UTILS
import { dayTypeList } from "utils/utils-traffic";

class TypicalTimeSlider extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);

    this.state = {
      currentDate: null,
      valueDisplay: 0,
      timePickerIsOpen: false,
      marks: [],
    };
  }

  componentDidMount = () => {
    const { currentOffSet, currentTime } = this.props;

    const startOfDay = moment(currentTime).startOf("day");
    let tempMarks = [];

    tempMarks.push({
      value: 0,
      label: startOfDay.format("HH"),
    });

    let i;
    const nrOfQuartersOfHourInADay = 24 * 4;
    let newTime = startOfDay.clone();
    for (i = 1; i < nrOfQuartersOfHourInADay; i++) {
      newTime.add(15, "minutes");
      let isEveryFourHours =
        newTime.format("mm") === "00" && newTime.format("HH") % 4 === 0
          ? newTime.format("HH")
          : "";
      let isEveryHours = newTime.format("mm") === "00";
      if (isEveryHours) {
        tempMarks.push({
          value: i,
          label: isEveryFourHours,
        });
      }
    }

    let duration = moment.duration(moment(currentTime).diff(startOfDay));
    let minutes = duration.asMinutes();
    let nowValue = minutes / 15;

    this.setState({
      currentDate: currentTime,
      valueDisplay: nowValue,
      marks: tempMarks,
    });

    const slider = document.getElementById("typic-slider");
    slider.value = currentOffSet;
  };

  handleChange = (event, newValue) => {
    const {
      setCurrentContextTimeOffSet,
      setCurrentTrafficTime,
      currentTime,
    } = this.props;

    const startOfDay = moment(currentTime).startOf("day");
    let addMinutesFromStartOfDay = startOfDay.clone();
    addMinutesFromStartOfDay.add(newValue * 15, "minutes");
    setCurrentContextTimeOffSet(0);
    setCurrentTrafficTime(new Date(addMinutesFromStartOfDay));
    // setCurrentTrafficStartTime(new Date(addMinutesFromStartOfDay))

    this.setState({
      valueDisplay: newValue,
      currentDate: new Date(addMinutesFromStartOfDay),
    });
  };

  // handleChange = (event) => {
  //   const { valueDisplay } = this.state;
  //   const {
  //     setCurrentContextTimeOffSet,
  //     currentTime,
  //     setCurrentTrafficTime,
  //   } = this.props;

  //   const newValue = Number(event.target.value);
  //   let newDate = currentTime;

  //   let deltaTime = getDeltaTimeOffSet(newValue);

  //   if (
  //     (newValue < 0 && newValue < valueDisplay && valueDisplay !== newValue) ||
  //     (newValue < 0 && newValue > valueDisplay && valueDisplay !== newValue)
  //   ) {
  //     newDate = new Date(currentTime.getTime() - deltaTime * 60 * 60 * 1000);
  //   } else if (
  //     (newValue > 0 && newValue > valueDisplay && valueDisplay !== newValue) ||
  //     (newValue > 0 && newValue < valueDisplay && valueDisplay !== newValue)
  //   ) {
  //     newDate = new Date(currentTime.getTime() + deltaTime * 60 * 60 * 1000);
  //   } else if (
  //     (newValue === 0 && valueDisplay > 0) ||
  //     (newValue === 0 && valueDisplay < 0)
  //   ) {
  //     newDate = currentTime;
  //   }

  //   this.setState({
  //     valueDisplay: newValue,
  //     currentDate: newDate,
  //   });
  //   setCurrentContextTimeOffSet(newValue);
  //   setCurrentTrafficTime(newDate);
  // };

  changeDayType = (dayType) => {
    const { setCurrentDayType } = this.props;
    setCurrentDayType(dayType);
  };

  render() {
    const { valueDisplay, marks } = this.state;
    const { currentDayType, currentTime } = this.props;

    const SliderCustom = withStyles({
      root: {
        height: 2,
        padding: "15px 0",
      },
      thumb: {
        height: 28,
        width: 28,
        backgroundColor: "blue",
        marginTop: -14,
        marginLeft: -14,
      },
      active: {},
      valueLabel: {
        left: "calc(-170% + 20px)",
        top: -13,
        "& *": {
          background: "transparent",
          color: "white",
          position: "absolute",
          width: "100px",
        },
      },
      track: {
        height: 2,
        backgroundImage: "linear-gradient(.30turn, yellow,yellow)",
      },
      rail: {
        height: 2,
        opacity: 4,
        backgroundImage: "linear-gradient(.30turn, yellow,yellow)",
      },
      mark: {
        height: 8,
        width: 1,
        marginTop: -3,
      },
      markActive: {
        backgroundColor: "currentColor",
      },
      markLabelActive: {
        color: "white",
        fontSize: "0.800rem",
      },
      markLabel: {
        color: "white",
        fontSize: "0.800rem",
      },
    })(Slider);

    return (
      <div
        className="uk-card uk-card-primary uk-animation-slide-left-medium scale-container-traffic-slider uk-flex uk-flex-row widget-border-light"
        // style={{ width: !isOpenNavTab ? 850 : 650 }}
      >
        <div className="day-type-container uk-flex uk-flex-column uk-flex-center">
          <div className="uk-flex uk-flex-row uk-flex-center">Tipo giorno:</div>
          <div className="uk-flex uk-flex-row uk-flex-wrap uk-flex-center">
            {dayTypeList.map((item, index) => {
              return (
                <div key={index} uk-tooltip={item.nameIT}>
                  <button
                    className={
                      item &&
                      currentDayType &&
                      item.nameIT === currentDayType.nameIT
                        ? "uk-button uk-button-default day-type-button day-type-selected"
                        : "uk-button uk-button-default day-type-button"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.changeDayType(item);
                    }}
                  >
                    {item.shortName}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="uk-flex uk-flex-column uk-height-1-1 uk-width-1-1 ">
          <div className="time-width">
            <div className="slider-padding">
              <SliderCustom
                defaultValue={moment(currentTime).format("HH:mm")}
                valueLabelDisplay="on"
                valueLabelFormat={moment(currentTime).format("HH:mm")}
                aria-labelledby="discrete-slider-custom"
                step={1}
                marks={marks}
                min={0}
                max={24 * 4 - 1}
                onChange={this.handleChange}
                value={valueDisplay}
                id="typic-slider"
              />
            </div>
          </div>
          {/* <div className="slider-padding uk-flex uk-flex-column uk-flex-center">
            <input
              id="typic-slider"
              type="range"
              className="uk-range custom-slider"
              list="sliderValues"
              min={-60}
              max={60}
              step={15}
              value={valueDisplay}
              onChange={this.handleChange}
            ></input>
            <datalist id="sliderValues">
              {marks.map((item) => {
                return (
                  <option
                    key={item.value}
                    value={item.value}
                    label={item.label}
                    className="slider-option"
                  ></option>
                );
              })}
            </datalist>
          </div> */}
        </div>
      </div>
    );
  }
}
TypicalTimeSlider.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setCurrentContextTimeOffSet,
  setCurrentTrafficTime,
  setCurrentDayType,
  setCurrentTrafficStartTime,
};

const mapStateToProps = (state) => ({
  contexts: getTrafficContexts(state),
  currentTime: getTrafficCurrentTime(state),
  currentOffSet: getCurrentTimeOffSet(state),
  currentDayType: getTrafficCurrentDayType(state),
  isTypicalVisible: isTypicalVisible(state),
  trafficCurrentStartTime: getTrafficCurrentStartTime(state),
  isOpenNavTab: isOpenNavTab(state),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TypicalTimeSlider)
);
