export const NavTabActions = {
  TOGGLE_NAVTAB: "UI/NAV_TAB/TOGGLE_NAVTAB",
  SET_TAB: "UI/NAV_TAB/SET_TAB",
  ADD_INFO_ROUTE: "UI/NAV_TAB/ADD_INFO_ROUTE",
  RESET_INFO_ROUTE: "UI/NAV_TAB/RESET_INFO_ROUTE",
  TOGGLE_INFO_ROUTE: "UI/NAV_TAB/TOGGLE_INFO_ROUTE",
  TOGGLE_BOTTOMBAR: "UI/NAV_TAB/TOGGLE_BOTTOMBAR",
  SET_SEARCH: "UI/NAV_TAB/SET_SEARCH",
  SET_MULTITAB_TYPE: "UI/NAV_TAB/SET_MULTITAB_TYPE",
};

export const toggleOpenNavTab = (open) => (dispatch) => {
  dispatch({
    type: NavTabActions.TOGGLE_NAVTAB,
    payload: open,
  });
};

export const setNavTabSelectedTab = (tab) => (dispatch) => {
  dispatch({
    type: NavTabActions.SET_TAB,
    payload: tab,
  });
};

export const resetNavTabInfoRoute = () => (dispatch) => {
  dispatch({
    type: NavTabActions.RESET_INFO_ROUTE,
    payload: null,
  });
};

export const addNavTabInfoRoute = (data) => (dispatch) => {
  dispatch({
    type: NavTabActions.ADD_INFO_ROUTE,
    payload: data,
  });
};

export const toggleNavTabInfoRoute = () => (dispatch) => {
  dispatch({
    type: NavTabActions.TOGGLE_INFO_ROUTE,
    payload: null,
  });
};

export const setNavTabSearch = (text, type, journeyPatternRef, selectModal) => (
  dispatch
) => {
  dispatch({
    type: NavTabActions.SET_SEARCH,
    payload: {
      text: text,
      type: type,
      journeyPatternRef: journeyPatternRef,
      selectModal: selectModal,
    },
  });
};

export const setNavTabMultitab = (selectedTab) => (dispatch) => {
  dispatch({
    type: NavTabActions.SET_MULTITAB_TYPE,
    payload: selectedTab,
  });
};
