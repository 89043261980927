import React, { Component, createRef } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//COMPONENTS
import Loading from "components/shared/loading/loading";
import InfiniteScroll from "react-infinite-scroll-component";
//ASSETS
import acr_centroid_mod from "assets/icon/graph/acr-centroid_mod.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";
import { getCurrentCentroidMap } from "store";

const SCROLL_OFFSET = 25;

class CentroidList extends Component {
  constructor(props) {
    super(props);
    this.scrollDiv = createRef();
    this.state = {
      openDetail: false,
      currentOffset: SCROLL_OFFSET,
      nextCentroids: [],
      currentListLen: 0,
    };
  }

  componentDidMount = () => {
    const { currentCentroidMap } = this.props;
    if (currentCentroidMap && currentCentroidMap.selected) {
      this.scrollSmoothHandler();
    }
  };

  componentDidUpdate = () => {
    const { currentCentroidMap } = this.props;
    if (currentCentroidMap && currentCentroidMap.selected) {
      this.scrollSmoothHandler();
    }
  };

  fetchMoreData = () => {
    const { currentOffset } = this.state;
    const { centroidsList } = this.props;
    let newOffset = currentOffset + SCROLL_OFFSET;
    let fetchmore = centroidsList.slice(0, newOffset);
    this.setState({
      nextCentroids: fetchmore,
      currentOffset: newOffset,
    });
  };

  scrollSmoothHandler = () => {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  constructFetchedDOM = () => {
    const { nextCentroids } = this.state;
    const { toggleCentroidDetails, currentCentroidMap } = this.props;

    return (
      <div className="centroid-list-container">
        <div className="uk-list">
          {nextCentroids &&
            nextCentroids.map((centroid, index) => {
              return (
                <div
                  className="uk-flex uk-flex-row uk-flex-middle uk-flex-between"
                  key={index}
                >
                  <li
                    onClick={(event) => {
                      event.preventDefault();
                      toggleCentroidDetails(centroid);
                    }}
                    id={centroid.id}
                    className={
                      currentCentroidMap &&
                      currentCentroidMap.selected &&
                      currentCentroidMap.id === centroid.id
                        ? "uk-flex uk-flex-between uk-active uk-flex-middle"
                        : "uk-flex uk-flex-between uk-flex-middle"
                    }
                  >
                    <div className="centroid-icon uk-flex uk-flex-middle uk-flex-center">
                      <div className="uk-position-relative graph-height-width-30 uk-flex uk-flex-middle uk-flex-center">
                        {currentCentroidMap &&
                        currentCentroidMap.selected &&
                        currentCentroidMap.id === centroid.id ? (
                          <div
                            className="parent uk-flex uk-flex-middle uk-flex-center"
                            ref={this.scrollDiv}
                          >
                            <div className="circle-list-map"></div>
                            <img
                              alt="icn_diamond"
                              src={icn_diamond}
                              className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
                            ></img>
                            <img
                              alt="acr_centroid_mod"
                              src={acr_centroid_mod}
                              className=" image2  height-width-48 uk-flex uk-flex-middle uk-flex-center centroid-icon-dimension"
                            ></img>
                          </div>
                        ) : (
                          <img
                            alt="acr_centroid_mod"
                            src={acr_centroid_mod}
                            className="uk-position-relative height-width-48 uk-flex uk-flex-middle uk-flex-center centroid-icon-dimension"
                          ></img>
                        )}
                      </div>
                    </div>
                    <div className="uk-flex uk-margin-small-left centroid-id-name">
                      <div className="uk-flex">
                        <div
                          className={
                            currentCentroidMap &&
                            currentCentroidMap.selected &&
                            currentCentroidMap.id === centroid.id
                              ? "uk-width-1-1"
                              : "uk-width-1-1 list-text"
                          }
                        >
                          {centroid.id ? centroid.id : "-"}
                        </div>
                      </div>
                    </div>
                    <div className="uk-flex uk-margin-small-left centroid-header-name">
                      <div
                        className={
                          currentCentroidMap &&
                          currentCentroidMap.selected &&
                          currentCentroidMap.id === centroid.id
                            ? "uk-width-1-1"
                            : "uk-width-1-1 list-text"
                        }
                      >
                        {centroid.properties.name
                          ? centroid.properties.name
                          : "-"}
                      </div>
                    </div>
                  </li>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { nextCentroids, currentOffset, currentListLen } = state;
    const { centroidsList } = props;
    let newState = {};

    if (currentListLen !== centroidsList.length && centroidsList.length === 0) {
      newState = {
        currentListLen: centroidsList.length,
        currentOffset: SCROLL_OFFSET,
        nextCentroids: [],
      };
    }
    if (currentListLen !== centroidsList.length && centroidsList.length > 0) {
      newState = {
        currentListLen: centroidsList.length,
        currentOffset: centroidsList.length - 1,
        nextCentroids: centroidsList.slice(0, centroidsList.length),
      };
    }
    if (nextCentroids.length === 0 && currentListLen > 0) {
      newState = {
        ...state,
        nextCentroids: centroidsList.slice(
          0,
          currentListLen > SCROLL_OFFSET ? currentOffset : currentListLen
        ),
      };
    }

    return newState;
  }

  renderInfiniteScroll = () => {
    const { nextCentroids } = this.state;
    const { centroidsList } = this.props;

    return (
      <InfiniteScroll
        dataLength={nextCentroids.length || 0} //This is important field to render the next data
        next={this.fetchMoreData}
        hasMore={nextCentroids.length === centroidsList.length ? false : true}
        loader={
          <div>
            {centroidsList.length ? "" /* <Loading /> */ : "Nessun elemento..."}
          </div>
        }
        scrollableTarget={"tabpanel-0"}
        className="inner-infinite-scroll"
      >
        {this.constructFetchedDOM()}
      </InfiniteScroll>
    );
  };

  render() {
    const { isCentroidsLoading } = this.props;

    return (
      <div>
        {isCentroidsLoading ? <Loading /> : this.renderInfiniteScroll()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCentroidMap: getCurrentCentroidMap(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CentroidList)
);
