export const ArcsActions = {
  SET_LIST_ARCS: "GRAPH/SET_LIST_ARCS",
  SET_LIST_ARCS_EVENT: "GRAPH/SET_LIST_ARCS_EVENT",
  SET_CURRENT_ARC: "GRAPH/SET_CURRENT_ARC",
  SET_CURRENT_ARC_MAP: "GRAPH/SET_CURRENT_ARC_MAP",
  RESET_CURRENT_ARC_MAP: "GRAPH/RESET_CURRENT_ARC_MAP",
};

export const setArcsList = (list) => (dispatch) => {
  dispatch({
    type: ArcsActions.SET_LIST_ARCS,
    payload: { list },
  });
};

export const setArcsListEvent = (list) => (dispatch) => {
  dispatch({
    type: ArcsActions.SET_LIST_ARCS_EVENT,
    payload: { list },
  });
};

export const setCurrentArc = (current) => (dispatch) => {
  dispatch({
    type: ArcsActions.SET_CURRENT_ARC,
    payload: current,
  });
};

export const setCurrentArcMap = (currentMap) => (dispatch) => {
  dispatch({
    type: ArcsActions.SET_CURRENT_ARC_MAP,
    payload: currentMap,
  });
};

export const resetCurrentArcMap = () => (dispatch) => {
  dispatch({
    type: ArcsActions.RESET_CURRENT_ARC_MAP,
  });
};
