import { INITIAL_STATE_MAP, defaultGeoJsonObj } from "./map.model";
import { MapActions } from "./map.actions";
import { LinesActions } from "../lines/lines.actions";
import { StopsActions } from "../stops/stops.actions";
import { BottombarActions } from "./../ui/bottombar/bottombar.actions";
import { NavTabActions } from "../ui/nav-tab/nav-tab.actions";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";

function filterFeatures(searchText) {
  return (feature) =>
    (feature &&
      feature.properties &&
      feature.properties.routeRef &&
      //feature.properties.name.toLowerCase().includes(searchText) ||
      feature.properties.routeRef.toLowerCase().includes(searchText)) ||
    //feature.properties.lineRef.toLowerCase().includes(searchText) ||
    //feature.properties.nameLine.toLowerCase().includes(searchText) ||
    (feature &&
      feature.properties &&
      feature.properties.nameRoute &&
      feature.properties.nameRoute.toLowerCase().includes(searchText)) ||
    (feature &&
      feature.properties &&
      feature.properties.nameShortLine &&
      feature.properties.nameShortLine.toLowerCase().includes(searchText));
}

function filterActiveLines(lines, active, routeRef) {
  let activeLines = [];
  const index = active.findIndex((x) => routeRef === x.properties.routeRef);

  if (index >= 0) {
    activeLines = active.filter((x) => routeRef !== x.properties.routeRef);
  } else {
    lines.forEach((line) => {
      if (routeRef === line.properties.routeRef) {
        activeLines.push(line);
      }
    });

    activeLines = [...active, ...activeLines];
  }

  return activeLines;
}

export default function mapReducer(state = INITIAL_STATE_MAP, action) {
  switch (action.type) {
    case MapActions.RESET_ALL_MAP:
      return INITIAL_STATE_MAP;
    case MapActions.ADD_ALL_LINES:
      const cityBus = [];
      const regionBus = [];
      action.payload &&
        action.payload.lines &&
        action.payload.lines.features &&
        action.payload.lines.features.forEach((line) => {
          if (
            line &&
            line.properties &&
            line.properties.routeRef &&
            line.properties.transportMode &&
            line.properties.transportMode.subMode &&
            line.properties.transportMode.subMode.code
          ) {
            if (
              line.properties.transportMode.subMode.code ===
              TYPE_TRANSPORT_MODE["extraurb"]
            ) {
              let foundRouteRefExtraurb = state.layers.regionBus.find(
                (x) => x.properties.routeRef === line.properties.routeRef
              );
              if (!foundRouteRefExtraurb) regionBus.push(line);
            }

            if (
              line.properties.transportMode.subMode.code ===
              TYPE_TRANSPORT_MODE["urb"]
            ) {
              let foundRouteRefUrb = state.layers.cityBus.find(
                (x) => x.properties.routeRef === line.properties.routeRef
              );
              if (!foundRouteRefUrb) cityBus.push(line);
            }
          }
        });

      return {
        ...state,
        layers: {
          ...state.layers,
          cityBus: [...state.layers.cityBus, ...cityBus],
          regionBus: [...state.layers.regionBus, ...regionBus],
          cityBusFiltered: [...state.layers.cityBusFiltered, ...cityBus],
          regionBusFiltered: [...state.layers.regionBusFiltered, ...regionBus],
        },
      };

    case MapActions.SET_GEOJSON:
      return {
        ...state,
        geojson: action.payload,
        // history: {
        //   ...state.history,
        //   prev: [...state.history.prev, { ...state }],
        //   next: [],
        // },
      };
    case MapActions.SET_LAYOUT:
      return {
        ...state,
        layout: action.payload,
      };

    case MapActions.RESET_GEOJSON:
      return {
        ...state,
        geojson: defaultGeoJsonObj,
        // history: {
        //   ...state.history,
        //   prev: [...state.history.prev, { ...state }],
        //   next: [],
        //  },
      };

    case MapActions.SET_SELECTED_FEATURE_INDEX:
      return {
        ...state,
        selectedFeatureIndexes: action.payload,
        history: {
          ...state.history,
          //prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };

    case MapActions.RESET_SELECTED_FEATURE_INDEX:
      return {
        ...state,
        selectedFeatureIndexes: [],
        // history: {
        //   ...state.history,
        //   prev: [...state.history.prev, { ...state }],
        //    next: [],
        //  },
      };

    case MapActions.RESET_LAYERS:
      return {
        ...state,
        layers: {
          cityBus: [],
          regionBus: [],
          cityBusFiltered: [],
          regionBusFiltered: [],
          cityBusActiveLines: [],
          regionBusActiveLines: [],
        },
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };
    case LinesActions.RESET_ACTIVE_LINES:
    case BottombarActions.CLOSE_BOTTOMBAR:
      return {
        ...state,
        layers: {
          ...state.layers,
          cityBusActiveLines: [],
          regionBusActiveLines: [],
        },
        // history: {
        //   ...state.history,
        //   prev: [...state.history.prev, { ...state }],
        //   next: [],
        // },
      };
    case LinesActions.SET_ACTIVE_LINES:
      if (action.payload && action.payload !== undefined) {
        // This is more like a toggle active lines
        return {
          ...state,
          layers: {
            ...state.layers,
            cityBusActiveLines: filterActiveLines(
              state.layers.cityBus,
              state.layers.cityBusActiveLines,
              action.payload.routeRef
            ),
            regionBusActiveLines: filterActiveLines(
              state.layers.regionBus,
              state.layers.regionBusActiveLines,
              action.payload.routeRef
            ),
          },
          history: {
            ...state.history,
            prev: [...state.history.prev, { ...state }],
            //   next: [],
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case MapActions.SET_POLYGON:
      return {
        ...state,
        polygon: action.payload,
        // history: {
        //   ...state.history,
        //   prev: [...state.history.prev, { ...state }],
        //   next: [],
        // },
      };

    case MapActions.TOGGLE_CHANGE_LAYER: {
      return {
        ...state,
        controls: {
          ...state.controls,
          openTabChangeLayer: !state.controls.openTabChangeLayer,
        },
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };
    }

    case MapActions.TOGGLE_LEGEND: {
      return {
        ...state,
        controls: {
          ...state.controls,
          openTabLegend: !state.controls.openTabLegend,
        },
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };
    }

    case MapActions.TOGGLE_SEARCH_STREET: {
      return {
        ...state,
        controls: {
          ...state.controls,
          openTabSearchStreet: !state.controls.openTabSearchStreet,
        },
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          // next: [],
        },
      };
    }
    case MapActions.TOGGLE_POLYGON_SELECTED: {
      return {
        ...state,
        controls: {
          ...state.controls,
          polygonSelected: !state.controls.polygonSelected,
        },
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };
    }
    case MapActions.TOGGLE_CIRCLE_SELECTED: {
      return {
        ...state,
        controls: {
          ...state.controls,
          circleSelected: !state.controls.circleSelected,
        },
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };
    }
    case MapActions.TOGGLE_RULER_SELECTED: {
      return {
        ...state,
        controls: {
          ...state.controls,
          rulerSelected: !state.controls.rulerSelected,
        },
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };
    }
    case MapActions.TOGGLE_DISTANCE_SELECTED: {
      return {
        ...state,
        controls: {
          ...state.controls,
          distanceSelected: !state.controls.distanceSelected,
        },
        // history: {
        //   ...state.history,
        //   prev: [...state.history.prev, { ...state }],
        //   next: [],
        // },
      };
    }
    case MapActions.RESET_TOOLTIP_SELECTED: {
      return {
        ...state,
        controls: {
          ...state.controls,
          distanceSelected: false,
          rulerSelected: false,
          polygonSelected: false,
          circleSelected: false,
          openTabLegend: false,
          openTabChangeLayer: false,
          openTabSearchStreet: false,
        },
        // history: {
        //   ...state.history,
        //   prev: [...state.history.prev, { ...state }],
        //   next: [],
        // },
      };
    }
    case NavTabActions.SET_SEARCH:
      if (
        action.payload.location &&
        action.payload.location.includes("tpl") &&
        action.payload.menuTab === 0
      ) {
        let s =
          action.payload && action.payload.journeyPatternRef
            ? action.payload.journeyPatternRef.toLowerCase()
            : action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const filterFeaturesFn = filterFeatures(s);
        return {
          ...state,
          layers: {
            ...state.layers,
            cityBusFiltered:
              s !== ""
                ? state.layers.cityBus.filter(filterFeaturesFn)
                : [...state.layers.cityBus],
            regionBusFiltered:
              s !== ""
                ? state.layers.regionBus.filter(filterFeaturesFn)
                : [...state.layers.regionBus],
          },
        };
      } else {
        return { ...state };
      }
    case MapActions.HISTORY_NEXT: {
      let isNext = [...state.history.next];
      if (isNext && isNext.length > 0) {
        let prev = [...state.history.prev, { ...state }];
        let statNext = { ...state.history.next[0] };
        isNext.shift();
        return {
          ...statNext,
          history: {
            ...state.history,
            prev,
            next: isNext,
          },
        };
      } else {
        return { ...state };
      }
    }
    case MapActions.HISTORY_PREV: {
      let isPrev = [...state.history.prev];
      if (isPrev && isPrev.length > 0) {
        let next = [...state.history.next, { ...state }];
        let statPrev = { ...state.history.prev[state.history.prev.length - 1] };
        isPrev.pop();
        return {
          ...statPrev,
          history: {
            ...state.history,
            next,
            prev: isPrev,
          },
        };
      } else {
        return { ...state };
      }
    }
    case MapActions.TOGGLE_MAP_CONTROLS: {
      return {
        ...state,
        controls: {
          ...state.controls,
          openMapControls: !state.controls.openMapControls,
        },
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };
    }
    case MapActions.SET_HOVERED_BUS:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredBus: action.payload,
        },
      };
    case MapActions.SET_HOVERED_STOP:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredStop: action.payload,
        },
      };
    case MapActions.SET_HOVERED_EVENT:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredEvent: action.payload,
        },
      };
    case MapActions.SET_HOVERED_INSTALLATION:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredInstallation: action.payload,
        },
      };
    case MapActions.SET_HOVERED_STATION:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredStation: action.payload,
        },
      };
    case MapActions.SET_HOVERED_MULTIPLE_DISTANCE:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredMultipleDistance: action.payload,
        },
      };
    case MapActions.SET_HOVERED_SHORTEST_DISTANCE_ON_GRAPH:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredShortestDistanceOnGraph: action.payload,
        },
      };
    case MapActions.SET_HOVERED_RDS_TMC_POINT:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredRdsTmcPoint: action.payload,
        },
      };
    case MapActions.SET_HOVERED_PARKING:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredParking: action.payload,
        },
      };
    case MapActions.SET_HOVERED_CAMERA:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredCamera: action.payload,
        },
      };
    case MapActions.SET_HOVERED_PMV:
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredPMV: action.payload,
        },
      };
    case MapActions.RESET_HOVERED_OBJECTS:
      return {
        ...state,
        hovered: {
          hoveredBus: {
            object: "",
            x: null,
            y: null,
            toBeFollowed: false,
          },
          hoveredStop: {
            object: "",
            x: null,
            y: null,
          },
          hoveredEvent: {
            object: "",
            x: null,
            y: null,
          },
          hoveredInstallation: {
            object: "",
            x: null,
            y: null,
          },
          hoveredMultipleDistance: {
            object: "",
            x: null,
            y: null,
          },
          hoveredShortestDistanceOnGraph: {
            object: "",
            x: null,
            y: null,
          },
          hoveredRdsTmcPoint: {
            object: "",
            x: null,
            y: null,
          },
          hoveredParking: {
            object: "",
            x: null,
            y: null,
          },
          hoveredCamera: {
            object: "",
            x: null,
            y: null,
          },
          hoveredPMV: {
            object: "",
            x: null,
            y: null,
          },
        },
      };
    case MapActions.RESET_MULTIPLE_DISTANCE: {
      return {
        ...state,
        multipleDistance: [],
        // history: {
        //   ...state.history,
        //   prev: [...state.history.prev, { ...state }],
        //   next: [],
        // },
      };
    }
    case MapActions.SET_MULTIPLE_DISTANCE: {
      return {
        ...state,
        multipleDistance: action.payload,
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };
    }
    case MapActions.SAVE_FIGURE: {
      return {
        ...state,
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
        },
      };
    }
    case MapActions.SET_MODE: {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case MapActions.SET_BOUNDS: {
      return {
        ...state,
        boundingBox: action.payload.data,
        zoom: action.payload.zoom,
      };
    }
    case MapActions.RESET_SHORTEST_DISTANCE_ON_GRAPH: {
      return {
        ...state,
        shortestDistanceOnGraph: [],
        // history: {
        //   ...state.history,
        //   prev: [...state.history.prev, { ...state }],
        //   next: [],
        // },
      };
    }
    case MapActions.SET_SHORTEST_DISTANCE_ON_GRAPH: {
      return {
        ...state,
        shortestDistanceOnGraph: action.payload,
        history: {
          ...state.history,
          prev: [...state.history.prev, { ...state }],
          //next: [],
        },
      };
    }

    case MapActions.SET_CURRENT_ROUTES_FOR_STOP_SELECTED:
      return {
        ...state,
        layers: {
          ...state.layers,
          regionBusFiltered: state.layers.regionBus.filter((item) =>
            action.payload.find(
              (curr) =>
                curr &&
                curr.routeId &&
                curr.lineId &&
                item.properties &&
                curr.routeId === item.properties.routeRef &&
                curr.lineId === item.properties.lineRef
            )
          ),
          cityBusFiltered: state.layers.cityBus.filter((item) =>
            action.payload.find(
              (curr) =>
                curr &&
                curr.routeId &&
                curr.lineId &&
                item.properties &&
                curr.routeId === item.properties.routeRef &&
                curr.lineId === item.properties.lineRef
            )
          ),
        },
      };

    case StopsActions.RESET_CURRENT_STOP:
      return {
        ...state,
        layers: {
          ...state.layers,
          regionBusFiltered: state.layers.regionBus,
          cityBusFiltered: state.layers.cityBus,
        },
      };

    case MapActions.FILTER_BY_BOUNDS:
      if (
        state.layers.regionBusFiltered.length !== state.layers.regionBus ||
        state.layers.cityBusFiltered.length !== state.layers.cityBus
      ) {
        return {
          ...state,
          layers: {
            ...state.layers,
            regionBusFiltered: state.layers.regionBus,
            cityBusFiltered: state.layers.cityBus,
          },
        };
      } else {
        return { ...state };
      }
    case MapActions.SET_CURRENT_MAP_ZOOM:
      return {
        ...state,
        zoom: action.payload,
      };
    default:
      return state;
  }
}

export const getMapPolygon = (state) => state.polygon;
export const getMapLayers = (state) => state.layers;
export const getControls = (state) => state.controls;
export const getMapGeoJson = (state) => state.geojson;
export const getMapSelectedFeatureIndexes = (state) =>
  state.selectedFeatureIndexes;
export const getLayoutMap = (state) => state.layout;

export const getCityBusLines = (state) => state.layers.cityBus;
export const getRegionBusLines = (state) => state.layers.regionBus;

export const getCityBusActiveLines = (state) => state.layers.cityBusActiveLines;
export const getRegionBusActiveLines = (state) =>
  state.layers.regionBusActiveLines;

export const getCityBusLinesFiltered = (state) => state.layers.cityBusFiltered;
export const getRegionBusLinesFiltered = (state) =>
  state.layers.regionBusFiltered;

export const getHoveredObjects = (state) => state.hovered;
export const getMultipleDistance = (state) => state.multipleDistance;
export const getMode = (state) => state.mode;
export const getBoundingBox = (state) => state.boundingBox;
export const getShortestDistanceOnGraph = (state) =>
  state.shortestDistanceOnGraph;

export const getCurrentZoom = (state) => state.zoom;
