import React, { Component } from "react";
import "./style.less";
class OrderTableColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { requestSort, type, sortConfig } = this.props;

    return (
      <div
        className="uk-icon uk-flex uk-flex-column uk-margin-small-left small-black-icon"
        onClick={() => requestSort(type)}
      >
        <div
          uk-icon="icon:triangle-up"
          className={`uk-icon ${
            sortConfig &&
            sortConfig.key === type &&
            sortConfig.direction === "ascending"
              ? "yellow-icon-arrows"
              : ""
          }`}
        ></div>
        <div
          uk-icon="icon:triangle-down"
          className={`uk-icon ${
            sortConfig &&
            sortConfig.key === type &&
            sortConfig.direction === "descending"
              ? "yellow-icon-arrows"
              : ""
          }`}
        ></div>
      </div>
    );
  }
}

export default OrderTableColumn;
