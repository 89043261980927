import React, { Component } from "react";
import "./style.less";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="uk-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
          <div className="uk-flex uk-flex-column uk-margin-top uk-margin-right uk-margin-left uk-margin-bottom">
            <div className="uk-icon uk-flex  uk-flex-row uk-flex-center">
              <span className="uk-icon" uk-icon="icon: warning; ratio: 2" />
            </div>
            <div className="error-text uk-margin-top">
              Oops! Si è verificato un errore.
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
