import { GraphMenuActions } from "./graph-menu.actions";

const INITIAL_STATE = {
  arcVisible: false,
  nodeVisible: false,
  centroidVisible: false,
  isNetworkRdsTmcVisible: false,
  isArcsLoading: false,
  isNodesLoading: false,
  isCentroidsLoading: false,
};

export default function graphMenuReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GraphMenuActions.SET_GRAPH_VISIBILITY:
      return {
        ...state,
        allVisible: action.payload.visible.all,
        arcVisible: action.payload.visible.arc,
        nodeVisible: action.payload.visible.node,
        centroidVisible: action.payload.visible.centroid,
      };
    case GraphMenuActions.SET_ARC_VISIBILITY:
      return {
        ...state,
        arcVisible: action.payload.visible,
      };
    case GraphMenuActions.SET_NODE_VISIBILITY:
      return {
        ...state,
        nodeVisible: action.payload.visible,
      };
    case GraphMenuActions.SET_CENTROID_VISIBILITY:
      return {
        ...state,
        centroidVisible: action.payload.visible,
      };
    case GraphMenuActions.SET_NETWORK_RDS_TMC_VISIBILITY:
      return {
        ...state,
        isNetworkRdsTmcVisible: action.payload.visible.all,
      };
    case GraphMenuActions.SET_IS_GRAPH_LOADING:
      return {
        ...state,
        isArcsLoading: action.payload.isArcsLoading,
        isNodesLoading: action.payload.isNodesLoading,
        isCentroidsLoading: action.payload.isCentroidsLoading,
      };
    case GraphMenuActions.SET_IS_NETWORK_LOADING:
      return {
        ...state,
        isPointsLoading: action.payload.isPointsLoading,
        isSegmentsLoading: action.payload.isSegmentsLoading,
      };
    default:
      return state;
  }
}

export const areAllGraphVisible = (state) => state.allVisible;
export const isArcVisible = (state) => state.arcVisible;
export const isNodeVisible = (state) => state.nodeVisible;
export const isCentroidVisible = (state) => state.centroidVisible;
export const isNetworkRdsTmcVisible = (state) => state.isNetworkRdsTmcVisible;

export const isArcsLoading = (state) => state.isArcsLoading;
export const isNodesLoading = (state) => state.isNodesLoading;
export const isCentroidsLoading = (state) => state.isCentroidsLoading;
export const isPointsLoading = (state) => state.isPointsLoading;
export const isSegmentsLoading = (state) => state.isSegmentsLoading;
