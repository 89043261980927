import React, { Component } from "react";
import "./style.less";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//COMPONENTS
import Loading from "components/shared/loading/loading";
import InfiniteScroll from "react-infinite-scroll-component";
//ASSETS
import acr_segment from "assets/icon/graph/acr-segment-green.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";

import { getCurrentSegmentRdsTmcMap } from "store";

const SCROLL_OFFSET = 25;

class SegmentRdsTmcList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDetail: false,
      currentOffset: SCROLL_OFFSET,
      nextSegments: [],
      currentListLen: 0,
    };
  }

  fetchMoreData = () => {
    const { currentOffset } = this.state;
    const { segmentsList } = this.props;
    let newOffset = currentOffset + SCROLL_OFFSET;
    let fetchmore = segmentsList.slice(0, newOffset);
    this.setState({
      nextSegments: fetchmore,
      currentOffset: newOffset,
    });
  };

  constructFetchedDOM = () => {
    const { nextSegments } = this.state;
    const { toggleSegmentDetail, currentSegmentMap } = this.props;
    return (
      <div className="segment-list-container">
        <div className="uk-list">
          {nextSegments &&
            nextSegments.map((segment, index) => {
              return (
                <div
                  className="uk-flex uk-flex-row uk-flex-middle uk-flex-between"
                  key={index}
                >
                  <li
                    onClick={(event) => {
                      event.preventDefault();
                      toggleSegmentDetail(segment);
                    }}
                    id={segment.id}
                    className="uk-flex uk-flex-between uk-flex-middle"
                  >
                    <div className="segment-icon uk-flex uk-flex-middle uk-flex-center">
                      {currentSegmentMap &&
                      currentSegmentMap.properties.selected &&
                      currentSegmentMap.properties.id ===
                        segment.properties.id ? (
                        <div className="uk-position-relative network-height-width-48 uk-flex uk-flex-middle uk-flex-center">
                          <div className="parent uk-flex uk-flex-middle uk-flex-center">
                            <div className="circle-list-map"></div>
                            <img
                              alt="icn_diamond"
                              src={icn_diamond}
                              className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
                            ></img>
                            <img
                              alt="acr_segment"
                              src={acr_segment}
                              className="image2 height-width-48 uk-flex uk-flex-middle uk-flex-center segment"
                            ></img>
                          </div>
                        </div>
                      ) : (
                        <div className="uk-position-relative network-height-width-48 uk-flex uk-flex-middle uk-flex-center">
                          <img
                            alt="acr_segment"
                            src={acr_segment}
                            className="uk-position-relative height-width-48 uk-flex uk-flex-middle uk-flex-center segment"
                          ></img>
                        </div>
                      )}
                    </div>
                    <div className="uk-flex uk-margin-small-left">
                      <div className="uk-flex">
                        <div
                          className={
                            currentSegmentMap &&
                            currentSegmentMap.properties.selected &&
                            currentSegmentMap.properties.id ===
                              segment.properties.id
                              ? "uk-width-1-1 segment-header-name"
                              : "uk-width-1-1 list-text segment-header-name"
                          }
                        >
                          {segment &&
                          segment.properties &&
                          segment.properties.name
                            ? segment.properties.name
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <div className="uk-flex uk-flex-column uk-margin-small-left">
                      <div
                        className={
                          currentSegmentMap &&
                          currentSegmentMap.properties.selected &&
                          currentSegmentMap.properties.id ===
                            segment.properties.id
                            ? "uk-width-1-1"
                            : "uk-width-1-1 list-text"
                        }
                      >
                        {segment.properties.tot_length.toFixed(1)
                          ? segment.properties.tot_length.toFixed(1)
                          : "-"}{" "}
                        km
                      </div>
                    </div>
                  </li>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { nextSegments, currentOffset, currentListLen } = state;
    const { segmentsList } = props;
    let newState = {};

    if (currentListLen !== segmentsList.length && segmentsList.length === 0) {
      newState = {
        currentListLen: segmentsList.length,
        currentOffset: SCROLL_OFFSET,
        nextSegments: [],
      };
    }
    if (currentListLen !== segmentsList.length && segmentsList.length > 0) {
      newState = {
        currentListLen: segmentsList.length,
        currentOffset: segmentsList.length - 1,
        nextSegments: segmentsList.slice(0, segmentsList.length),
      };
    }
    if (nextSegments.length === 0 && currentListLen > 0) {
      newState = {
        ...state,
        nextSegments: segmentsList.slice(
          0,
          currentListLen > SCROLL_OFFSET ? currentOffset : currentListLen
        ),
      };
    }

    return newState;
  }

  renderInfiniteScroll = () => {
    const { nextSegments } = this.state;
    const { segmentsList } = this.props;

    return (
      <InfiniteScroll
        dataLength={nextSegments.length || 0} //This is important field to render the next data
        next={this.fetchMoreData}
        hasMore={nextSegments.length === segmentsList.length ? false : true}
        loader={
          <div>
            {segmentsList.length ? "" /* <Loading /> */ : "Nessun elemento..."}
          </div>
        }
        scrollableTarget={"tabpanel-0"}
        className="inner-infinite-scroll"
      >
        {this.constructFetchedDOM()}
      </InfiniteScroll>
    );
  };

  render() {
    const { isSegmentsLoading } = this.props;

    return (
      <div>{isSegmentsLoading ? <Loading /> : this.renderInfiniteScroll()}</div>
    );
  }
}
const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  currentSegmentMap: getCurrentSegmentRdsTmcMap(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SegmentRdsTmcList)
);
