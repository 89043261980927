import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { isKpiOpen, openKpiDown } from "store";
import { toggleKpiContainer } from "reducers/ui/topbar/topbar.actions";
import * as moment from "moment";
import "moment/locale/it";

class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    moment.locale("it");
  }
  //WeatherConditions
  //0 Sunny
  //1 Rain
  //2 Cloudy
  //3 Snow
  //4 Stormy
  //5 sun/cloudy

  weatherList = [
    {
      id: 0,
      date: moment().format("ddd DD/MM"),
      weatherConditions: Math.floor(Math.random() * 6),
      temperatureMax: "14",
      temperatureMin: "2",
    },
    {
      id: 1,
      date: moment().add(1, "days").format("ddd DD/MM"),
      weatherConditions: Math.floor(Math.random() * 6),
      temperatureMax: "8",
      temperatureMin: "0",
    },
    {
      id: 2,
      date: moment().add(2, "days").format("ddd DD/MM"),
      weatherConditions: Math.floor(Math.random() * 6),
      temperatureMax: "10",
      temperatureMin: "2",
    },
  ];

  componentDidMount() {
    /* const { toggleKpiContainer } = this.props;

    let element = document.getElementById("openContainer");
    element.addEventListener("click", function (e) {
      if (e.offsetX > element.offsetWidth - 20) {
        toggleKpiContainer();
      }
    }); */
  }

  renderWeatherCondition = (weatherConditions, index) => {
    if (weatherConditions === 0) {
      return (
        <span
          className={index === 0 ? "weather-icon-primary-sun" : ""}
          uk-icon="icon: acr-weather-sun"
        ></span>
      );
    } else if (weatherConditions === 1) {
      return (
        <span
          className={index === 0 ? "weather-icon-primary-rainy" : ""}
          uk-icon="icon: acr-weather-rain"
        ></span>
      );
    } else if (weatherConditions === 2) {
      return (
        <span
          className={index === 0 ? "weather-icon-primary-cloudy" : ""}
          uk-icon="icon: acr-weather-clouds"
        ></span>
      );
    } else if (weatherConditions === 3) {
      return (
        <span
          className={index === 0 ? "weather-icon-primary-snowy" : ""}
          uk-icon="icon: acr-weather-snow"
        ></span>
      );
    } else if (weatherConditions === 4) {
      return (
        <span
          className={index === 0 ? "weather-icon-primary-stormy" : ""}
          uk-icon="icon: acr-weather-storm"
        ></span>
      );
    } else if (weatherConditions === 5) {
      return (
        <span
          className={index === 0 ? "weather-icon-primary-sun-cloud" : ""}
          uk-icon="icon: acr-weather-sun-cloud"
        ></span>
      );
    }
  };

  openWeather = () => {
    return (
      <div id="openContainer" className="open-weather-container">
        {/* {this.weatherList.map((weather, index) => {
          return (
            <div
              className="uk-flex uk-flex-column uk-flex-center uk-flex-middle day-weather"
              key={index}
            >
              <div className="uk-flex uk-flex-row uk-flex-center uk-flex-middle">
                {this.renderWeatherCondition(weather.weatherConditions, index)}
                <div className="uk-flex uk-flex-column">
                  <div className="uk-padding-small">
                    <h3 className="weather-primary-text">
                      {" "}
                      {weather.temperatureMax}°C{" "}
                    </h3>
                  </div>
                  <div className="uk-padding-small">
                    <h3 className="weather-text">
                      {" "}
                      {weather.temperatureMin}°C{" "}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="uk-padding-small">
                {index === 0 ? (
                  <h3 className="weather-primary-text">{weather.date.charAt(0).toUpperCase() + weather.date.slice(1)}</h3>
                ) : (
                  <h3 className="weather-text">{weather.date.charAt(0).toUpperCase() + weather.date.slice(1)}</h3>
                )}
              </div>
            </div>
          );
        })} */}
      </div>
    );
  };

  closedWeather = () => {
    return (
      <div
        // id="openContainer"
        className="closed-weather-container"
      >
        {/* <div
          className="uk-flex uk-flex-column uk-flex-center uk-flex-middle day-weather"
          key="0"
        >
          <div className="uk-flex uk-flex-row uk-flex-center uk-flex-middle">
            {this.renderWeatherCondition(
              this.weatherList[0].weatherConditions,
              0
            )}
            <div className="uk-flex uk-flex-column">
              <div className="uk-padding-small">
                <h3 className="weather-primary-text">
                  {" "}
                  {this.weatherList[0].temperatureMax}°C{" "}
                </h3>
              </div>
              <div className="uk-padding-small">
                <h3 className="weather-text">
                  {" "}
                  {this.weatherList[0].temperatureMin}°C{" "}
                </h3>
              </div>
            </div>
          </div>
          <div className="uk-padding-small">
            <h3 className="weather-primary-text">
              {this.weatherList[0].date.charAt(0).toUpperCase() + this.weatherList[0].date.slice(1)}
            </h3>
          </div>
        </div> */}
      </div>
    );
  };

  onToggleWeather = () => {
    const { toggleKpiContainer, openKpiDown } = this.props;
    if (!openKpiDown) toggleKpiContainer();
  };

  render() {
    const { isKpiOpen } = this.props;
    return (
      <div id="external-container" className="external-container-weather">
        {isKpiOpen ? this.closedWeather() : this.openWeather()}
        <div
          onClick={(e) => {
            e.preventDefault();
            this.onToggleWeather();
          }}
          className={
            !isKpiOpen
              ? "btn-open-weather uk-navbar-tab-container-open"
              : "btn-open-weather uk-navbar-tab-container-close"
          }
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isKpiOpen: isKpiOpen(state),
    openKpiDown: openKpiDown(state),
  };
};

const mapDispatchToProps = {
  toggleKpiContainer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Weather);
