import { CompositeLayer, PathLayer } from "deck.gl";

class ShortestDistanceArcLayer extends CompositeLayer {
  renderLayers() {
    const { id, data, visible, color, onClick } = this.props;

    return new PathLayer(
      this.getSubLayerProps({
        id,
        data: data,
        getPath: (d) => d.points,
        getColor: color,
        pickable: true,
        widthMinPixels: 4,
        onClick: onClick,
        visible,
        rounded: true,
      })
    );
  }
}

ShortestDistanceArcLayer.layerName = "ShortestDistanceArcLayer";

export default ShortestDistanceArcLayer;
