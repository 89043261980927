import React, { Component } from "react";
import { AuthContext } from "@almaviva/ljsa-react-module";
import { connect } from "react-redux";
import Loading from "components/shared/loading/loading";
import { setPermissions } from "reducers/user/user.actions";

const mapDispatchToProps = {
  setPermissions,
};

@connect(null, mapDispatchToProps)
class PermissionService extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { setPermissions } = this.props;

    this.context.getProfileInfo().then(async (response) => {
      const data = await response.json();

      const actions = [];
      data &&
        data.roles &&
        data.roles.forEach((el) =>
          actions.push(...el.actions.map((x) => x.name))
        );

      setPermissions(actions);
      this.setState({ loading: false });
    });
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <Loading />;
    }

    return this.props.children;
  }
}

export default PermissionService;
