/*
 * Copyright 2018 Kurento (https://www.kurento.org)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as kurentoUtils from "kurento-utils";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
//images
import backgroundImage from "assets/video/transparent-1px.png";
import spinnerGif from "assets/video/loader.gif";
// import rtspImg from "assets/video/webrtc.png";
import logoVerona from "assets/images/logo_comune_verona.png";
import { of } from 'rxjs';
import { take, tap, catchError } from "rxjs/operators";

export const CAMERA_WRAPPER_PAGE_ENUM = {
  DETAIL: 0,
  MAP: 1,
  BOTTOMBAR: 2
}

export function isElementInViewport(el) {
  var rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight,
    efp = function (x, y) {
      return document.elementFromPoint(x, y);
    };

  // Return false if it's not in the viewport
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  )
    return false;

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  );
}

export function startHlsVideo(cameraComponent, url, apiService, streamProxy) {
  apiService.startHlsStream(url, streamProxy).pipe(
    take(1),
    tap(config => {
      let videoObj = cameraComponent.state.videoObj;
      if(videoObj) {
        videoObj.url = streamProxy + config.uri
        videoObj.streamId = config.id
      } else {
        videoObj = {
          url: streamProxy + config.uri,
          streamId: config.id
        }
      }
      cameraComponent.setState({
        ...cameraComponent.state,
        videoObj
      });

    }),
    catchError(error => {
      UIkit.notification.closeAll("streaming-error");

      UIkit.notification({
        message: "<span class='uk-alert-danger'></span>&nbsp;Servizio Hls non raggiungibile",
        status: "danger",
        pos: "bottom-right",
        group: "streaming-error",
      });
      console.error("Streaming error hls: " + error);
      return of(false);
    })
  ).subscribe();
}

export function startKurentoWs(cameraComponent, streamingProxyBe) {
  const videoObj = cameraComponent.state.videoObj;
  videoObj.ws = new WebSocket(streamingProxyBe);

  // Listen for possible errors
  videoObj.ws.addEventListener("error", function (event) {
    UIkit.notification.closeAll("streaming-error");

    UIkit.notification({
      message: "Kurento Websocket error",
      status: "danger",
      pos: "bottom-right",
      group: "streaming-error",
    });
  });
  //due callback per la ws
  //GESTIRE LA SDP OFFER
  videoObj.ws.onmessage = wsMessageHandler(cameraComponent, streamingProxyBe);
  //APRIRE LA WS
  videoObj.ws.onopen = uiStartWrapper(cameraComponent);
  cameraComponent.setState({
    ...cameraComponent.state,
    videoObj,
  });
}

export function HTMLTagIdForComponentInjection(origin) {
  switch(origin) {
      case CAMERA_WRAPPER_PAGE_ENUM.MAP: {
          return "cam-panel-streaming-map";
      }
      case CAMERA_WRAPPER_PAGE_ENUM.BOTTOMBAR: {
          return "cam-panel-streaming";
      }
      case CAMERA_WRAPPER_PAGE_ENUM.DETAIL:
      default: {
          return "cam-detail-streaming";
      }
  }
} 

export function checkAndStartStream(component, environment, cam, apiService, origin) {
  const streamingProxyBe = environment.streamingProxyBe; // per Kurento
  const streamingHlsService = environment.streamingHlsService; // per Servizio Hls
  const milestoneRtspStream = environment.milestoneRtspStream;
  const heartbeatInterval = environment.kurentoBEheartbeatInterval;
  const hlsEnabled = environment.streamingHlsEnabled;
  let videoId = cam &&
    cam.properties &&
    cam.properties.cctvWithLastStateAndOpenAlarms &&
    cam.properties.cctvWithLastStateAndOpenAlarms &&
    cam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
    cam.properties.cctvWithLastStateAndOpenAlarms.cctv.externalCameraId
      ? HTMLTagIdForComponentInjection(origin) + "-" +
        cam.properties.cctvWithLastStateAndOpenAlarms.cctv.externalCameraId.replace(
          /\./g,
          ""
        )
      : HTMLTagIdForComponentInjection(origin);
  const deviceId =
    cam &&
    cam.properties &&
    cam.properties.cctvWithLastStateAndOpenAlarms &&
    cam.properties.cctvWithLastStateAndOpenAlarms &&
    cam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
    cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId;
  if (milestoneRtspStream && videoId && deviceId && streamingProxyBe && streamingHlsService && heartbeatInterval && apiService) {
    startStreamingProcess(
      component,
      milestoneRtspStream,
      videoId,
      deviceId,
      streamingProxyBe,
      streamingHlsService,
      heartbeatInterval,
      apiService,
      hlsEnabled
    );
  }
}

export function startStreamingProcess(
  cameraComponent,
  milestoneRtspStream,
  videoId,
  deviceId,
  streamingProxyBe,
  streamingHlsService,
  heartbeatInterval,
  apiService,
  hlsEnabled
) {
  if(hlsEnabled) {
    startHlsVideo(cameraComponent, milestoneRtspStream + deviceId, apiService, streamingHlsService);
  } else {
    const UI_IDLE = 0;
    // //console.log("Page loaded");
    let video = document.getElementById(videoId);
    if (video && video.classList) {
      video.classList.add("embed-responsive-item");
      // video.setAttribute('poster', logoVerona);
      video.muted = true;
      //un flusso da aprire per ogni telecamera
      let newVideoObj = null;
      newVideoObj = {
        videoEl: video /* !obbligatorio elemento html del video, riferimento diretto */,
        uiState: UI_IDLE /* non necessario*/,
        ws: null /* !obbligatorio */,
        webRtc: null /* !obbligatorio class webRtcPeer vedi connessione */,
        hbIntervalId: 600000 /* !obbligatorio per mandare heartbeat - meno di 700 sec */,
        hbInterval: heartbeatInterval,
        //url da costruire lato FE
        url: milestoneRtspStream + deviceId,
      };
      let visible = isElementInViewport(newVideoObj.videoEl);
      newVideoObj.videoEl.setAttribute("isVisible", visible ? "true" : "false");
  
      cameraComponent.setState(
        {
          ...cameraComponent.state,
          videoObj: newVideoObj,
        },
        () => startKurentoWs(cameraComponent, streamingProxyBe)
      );
    }
  }
}

function explainUserMediaError(err) {
  const n = err.name;
  if (n === "NotFoundError" || n === "DevicesNotFoundError") {
    return "Missing webcam for required tracks";
  } else if (n === "NotReadableError" || n === "TrackStartError") {
    return "Webcam is already in use";
  } else if (
    n === "OverconstrainedError" ||
    n === "ConstraintNotSatisfiedError"
  ) {
    return "Webcam doesn't provide required tracks";
  } else if (n === "NotAllowedError" || n === "PermissionDeniedError") {
    return "Webcam permission has been denied by the user";
  } else if (n === "TypeError") {
    return "No media tracks have been requested";
  } else {
    return "Unknown error: " + err;
  }
}

function sendError(ws, message) {
  console.error(message);

  sendMessage(ws, {
    id: "ERROR",
    message: message,
  });
}

function sendMessage(ws, message) {
  if (ws.readyState !== ws.OPEN) {
    //console.warn("[sendMessage] Skip, WebSocket session isn't open");
    return;
  }

  const jsonMessage = JSON.stringify(message);
  //console.log("[sendMessage] message: " + jsonMessage);
  ws.send(jsonMessage);
}

/* ============================= */
/* ==== WebSocket signaling ==== */
/* ============================= */

var wsMessageHandler = function (cameraComponent, streamingProxyBe) {
  return function (message) {
    const jsonMessage = JSON.parse(message.data);
    //console.log("[onmessage] Received message: " + message.data);

    switch (jsonMessage.id ? jsonMessage.id.toLowerCase() : null) {
      //IMPORTANTE QUESTO JSON -> GESTISCO LA RISPOSTA
      case "startResponse":
        handleProcessSdpAnswer(cameraComponent, jsonMessage, streamingProxyBe);
        break;
      //QUESTO è DA COPIARE PARI, ROBA DI PROTOCOLLO
      case "iceCandidate":
        handleAddIceCandidate(cameraComponent, jsonMessage);
        break;
      //NEL CASO IL BE NON HA INSTANZIATO NIENTE LATO KURENTO (ES FLUSSO SBAGLIATO) -> FAI QUELLO CHE VUOI
      case "error":
        handleError(cameraComponent, jsonMessage);
        break;
      //NEL CASO TERMINI IL FLUSSO -> PUOI DARE UN MESSAGGIO TIPO FLUSSO INTERROTTO, PUOI RIAVVIARE
      case "playEnd":
        handleError(cameraComponent, jsonMessage);
        stop(cameraComponent);
        break;
      default:
        // Ignore the message
        //console.warn("[onmessage] Invalid message, id: " + jsonMessage.id);
        break;
    }
  };
};

// PROCESS_SDP_ANSWER ----------------------------------------------------------

function handleProcessSdpAnswer(
  cameraComponent,
  jsonMessage,
  streamingProxyBe
) {
  const UI_STARTED = 2;
  const videoObj = cameraComponent.state.videoObj;

  //console.log("[handleProcessSdpAnswer] SDP Answer from Kurento, process in WebRTC Peer");

  if (videoObj.webRtc == null) {
    //console.warn("[handleProcessSdpAnswer] Skip, no WebRTC Peer");
    return;
  }

  videoObj.webRtc.processAnswer(jsonMessage.sdpAnswer, (err) => {
    if (err) {
      //SE FALLISCE FA RIPARTIRE TUTTO
      sendError(videoObj.ws, "[handleProcessSdpAnswer] Error: " + err);
      stop(cameraComponent);
      videoObj.ws = new WebSocket(streamingProxyBe);
      videoObj.ws.onmessage = wsMessageHandler(
        cameraComponent,
        streamingProxyBe
      );
      videoObj.ws.onopen = uiStartWrapper(cameraComponent);
      window.setTimeout(uiStartWrapper(cameraComponent), 2000);
      return;
    }

    //HA PROCESSATO LA RIPOSTA, QUINIDI INIZIA IL VIDEO
    //console.log("[handleProcessSdpAnswer] SDP Answer ready; start remote video");
    startVideo(videoObj.videoEl);

    videoObj.uiState = UI_STARTED;

    cameraComponent.setState({
      ...cameraComponent.state,
      videoObj,
    });
  });
}

// ADD_ICE_CANDIDATE -----------------------------------------------------------

function handleAddIceCandidate(cameraComponent, jsonMessage) {
  const videoObj = cameraComponent.state.videoObj;

  if (videoObj.webRtc == null) {
    //console.warn("[handleAddIceCandidate] Skip, no WebRTC Peer");
    return;
  }

  videoObj.webRtc.addIceCandidate(jsonMessage.candidate, (err) => {
    if (err) {
      console.error("[handleAddIceCandidate] " + err);
      return;
    }
  });
}

// STOP ------------------------------------------------------------------------

export function stop(cameraComponent, hlsEnabled, apiService, streamProxy, willUmount) {
  const videoObj = cameraComponent.state.videoObj;
  if(hlsEnabled) {
    if(streamProxy && apiService && videoObj && videoObj.streamId) {
      apiService.stopHlsStream(streamProxy, videoObj.streamId).pipe(
        take(1),
        tap(_ => {
          if(!willUmount) {
              cameraComponent.setState({
                ...cameraComponent.state,
                videoObj: null,
              });
          }
        }),
        catchError(error => {
          console.error("Streaming error hls: " + error);
          return of(false);
        })
      ).subscribe();
    }
  } else {
    if (videoObj) {
      const UI_IDLE = 0;
  
      if (videoObj.uiState === UI_IDLE) {
        //console.log("[stop] Skip, already stopped");
        return;
      }
  
      //console.log("[stop]");
  
      if (videoObj.webRtc) {
        //lo pulisce e setta a null
        videoObj.webRtc.dispose();
        videoObj.webRtc = null;
      }
  
      videoObj.uiState = UI_IDLE;
      hideSpinner(videoObj.videoEl);
      clearInterval(videoObj.hbIntervalId);
      videoObj.hbIntervalId = null;
      sendMessage(videoObj.ws, {
        id: "stop",
      });
  
      cameraComponent.setState({
        ...cameraComponent.state,
        videoObj,
      });
    }
  }
}

// ERROR -----------------------------------------------------------------------

function handleError(cameraComponent, jsonMessage) {
  const errMessage = jsonMessage.message;
  UIkit.notification.closeAll("streaming-error");

  UIkit.notification({
    message: "Kurento error",
    status: "danger",
    pos: "bottom-right",
    group: "streaming-error",
  });
  console.error("Kurento error: " + errMessage);

  // //console.log("Assume that the other side stops after an error...");
  stop(cameraComponent);
}

/* ==================== */
/* ==== UI actions ==== */
/* ==================== */

// Start -----------------------------------------------------------------------

function uiStartWrapper(cameraComponent) {
  return function () {
    uiStart(cameraComponent);
  };
}

function uiStart(cameraComponent) {
  const UI_STARTING = 1;
  const UI_STARTED = 2;
  const videoObj = cameraComponent.state.videoObj;

  // //console.log("[start] Create WebRtcPeerSendrecv");
  videoObj.uiState = UI_STARTING;
  showSpinner(videoObj.videoEl);

  //vengono utilizzate per il primo messaggio
  const options = {
    //elemento html a cui collegare il video sul fe
    remoteVideo: videoObj.videoEl,
    //fisso, potrebbe potenzilmanete cambiare
    mediaConstraints: {
      audio: true,
      video: {
        framerate: 10,
      },
    },
    //fisso, sempre uguale
    onicecandidate: (candidate) =>
      sendMessage(videoObj.ws, {
        id: "onIceCandidate",
        candidate: candidate,
      }),
  };
  //console.log("[start] Created options");
  //instanzia, ci sono tre modi per aprirlo, solo receive
  videoObj.webRtc = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
    options,
    function (err) {
      //callback
      if (err) {
        sendError(
          videoObj.ws,
          "[start/WebRtcPeerSendrecv] Error: " + explainUserMediaError(err)
        );
        stop(cameraComponent);
        return;
      }

      //console.log("[start/WebRtcPeerSendrecv] Generate SDP Offer");
      videoObj.webRtc.generateOffer((err, sdpOffer) => {
        if (err) {
          sendError(
            videoObj.ws,
            "[start/WebRtcPeerSendrecv/generateOffer] Error: " + err
          );
          stop(cameraComponent);
          return;
        }

        //IMPORTANTE
        sendMessage(videoObj.ws, {
          id: "start",
          sdpOffer: sdpOffer,
          //url che vogliamo aprire
          videourl: videoObj.url,
        });

        //console.log("[start/WebRtcPeerSendrecv/generateOffer] Done!");
        videoObj.uiState = UI_STARTED;
        //interval per heartbeat
        videoObj.hbIntervalId = setInterval(function () {
          sendMessage(videoObj.ws, {
            id: "heartbeat",
          });
        }, videoObj.hbInterval);
      });
    }
  );
}

// function onIceCandidate(candidate) {
//   //console.log('Local candidate' + JSON.stringify(candidate));

//   var message = {
//     id: "onIceCandidate",
//     candidate: candidate,
//   };
//   sendMessage(message);
// }

// -----------------------------------------------------------------------------

/* ================== */
/* ==== UI state ==== */
/* ================== */

function showSpinner(videoEl) {
  videoEl.poster = backgroundImage;
  videoEl.style.background = `center transparent url(${spinnerGif}) no-repeat`;
}

function hideSpinner(videoEl) {
  if(!!!videoEl) {
    return;
  }
  videoEl.src = "";
  videoEl.poster = logoVerona;
  videoEl.style.background = "";
}

function startVideo(video) {
  // Manually start the <video> HTML element
  // This is used instead of the 'autoplay' attribute, because iOS Safari
  // requires a direct user interaction in order to play a video with audio.
  // Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
  video.play().catch((err) => {
    if (err.name === "NotAllowedError") {
      console.error("[start] Browser doesn't allow playing video: " + err);
    } else {
      console.error("[start] Error in video.play(): " + err);
    }
  });
}
