import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.less";
import darkBusImageBlue from "assets/icon/bus/dark_icn_map_pin_bus_lex.svg";
import darkBusImageRed from "assets/icon/bus/dark_icn_map_pin_bus_ls.svg";
// STORE
import { getAllActiveLines } from "store";

class VehicleLabelOnLine extends Component {
  render() {
    const { vehicles, stop, transportMode } = this.props;

    return (
      <div className="uk-width-1-1 uk-position-relative">
        {vehicles &&
          vehicles.map((vehicle, index) => {
            return vehicle &&
              vehicle.monitoredVehicleJourney &&
              /* (vehicle.monitoredVehicleJourney.directionRef === "inbound" ||
                !vehicle.monitoredVehicleJourney.directionRef) && */
              vehicle.monitoredVehicleJourney.monitoredCall &&
              vehicle.monitoredVehicleJourney.monitoredCall.stopPointRef ===
                stop.id ? (
              <div className={"uk-position-absolute go"} key={index}>
                <div
                  className="imgBusVehicleLabelOnline"
                  style={{
                    marginLeft:
                      vehicle.monitoredVehicleJourney.onwardCalls &&
                      vehicle.monitoredVehicleJourney.onwardCalls.length > 0 &&
                      vehicle.monitoredVehicleJourney.previousCalls &&
                      vehicle.monitoredVehicleJourney.previousCalls.length === 0
                        ? /* Math.round(
                          (vehicle.monitoredVehicleJourney.progressRate *
                            80) /
                            100
                        ) + */
                          0 + "px"
                        : vehicle.monitoredVehicleJourney.onwardCalls &&
                          vehicle.monitoredVehicleJourney.onwardCalls.length > 0
                        ? /* Math.round(
                            (vehicle.monitoredVehicleJourney.progressRate *
                              80) /
                              100
                          ) + */
                          20 + "px"
                        : "42px",
                  }}
                >
                  <img
                    alt=""
                    src={
                      transportMode === "07.01"
                        ? darkBusImageBlue
                        : darkBusImageRed
                    }
                  />
                </div>
              </div>
            ) : null; /* : vehicle &&
              vehicle.monitoredVehicleJourney &&
              vehicle.monitoredVehicleJourney.directionRef === "outbound" &&
              vehicle.monitoredVehicleJourney.monitoredCall &&
              vehicle.monitoredVehicleJourney.monitoredCall.stopPointRef ===
                stop.id &&
              vehicle.monitoredVehicleJourney.progressRate ? (
              <div className={"uk-position-absolute back"} key={index}>
                <div
                  uk-icon={transportMode === "07.01"
                      ? "icon: dark-acr-map-pin-bus-lex"
                      : "icon: dark-acr-map-pin-bus-ls"}
                  className="imgBusVehicleLabelOnline"
                  
                  style={{
                    marginLeft:
                      -Math.round(
                        (vehicle.monitoredVehicleJourney.progressRate * 80) /
                          100
                      ) +
                      20 +
                      "px",
                  }}
                ></div>
              </div>
            ) */
          })}
      </div>
    );
  }
}
const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  lines: getAllActiveLines(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VehicleLabelOnLine)
);
