import { CamerasActions } from "./cameras.actions";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";
import { INITIAL_STATE_CAMERAS } from "./cameras.model";
import * as FilterUtils from "utils/filter-utils";

function searchCameras(searchText) {
  return (cam) =>
    (cam &&
      cam.properties &&
      cam.properties.cctvWithLastStateAndOpenAlarms &&
      cam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
      cam.properties.cctvWithLastStateAndOpenAlarms.cctv.name.toString() &&
      cam.properties.cctvWithLastStateAndOpenAlarms.cctv.name
        .toString()
        .toLowerCase()
        .includes(searchText)) ||
    (cam &&
      cam.properties &&
      cam.properties.cctvWithLastStateAndOpenAlarms &&
      cam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
      cam.properties.cctvWithLastStateAndOpenAlarms.cctv.externalCameraId.toString() &&
      cam.properties.cctvWithLastStateAndOpenAlarms.cctv.externalCameraId
        .toString()
        .toLowerCase()
        .includes(searchText)) ||
    (cam &&
      cam.properties &&
      cam.properties.cctvWithLastStateAndOpenAlarms &&
      cam.properties.cctvWithLastStateAndOpenAlarms.cctv.description &&
      cam.properties.cctvWithLastStateAndOpenAlarms.cctv.description.toString() &&
      cam.properties.cctvWithLastStateAndOpenAlarms.cctv.description
        .toString()
        .toLowerCase()
        .includes(searchText));
}

export default function camerasReducer(state = INITIAL_STATE_CAMERAS, action) {
  let camerasListCopy = FilterUtils.deepCopyOfArraysOfObj(state.cameras);
  let camerasFilteredListCopy = FilterUtils.deepCopyOfArraysOfObj(
    state.camerasFiltered
  );

  switch (action.type) {
    case CamerasActions.SET_LIST_CAMERAS:
      const selectedItem = state.cameras.find((item) => {
        return item.selected;
      });
      let payloadList = FilterUtils.deepCopyOfArraysOfObj(action.payload.list);
      payloadList.forEach((item, index, arr) => {
        if (
          selectedItem &&
          selectedItem.properties &&
          item.properties &&
          selectedItem.properties.cctvWithLastStateAndOpenAlarms &&
          selectedItem.properties.cctvWithLastStateAndOpenAlarms.cctv &&
          item.properties.cctvWithLastStateAndOpenAlarms &&
          item.properties.cctvWithLastStateAndOpenAlarms.cctv &&
          selectedItem.properties.cctvWithLastStateAndOpenAlarms.cctv.id ===
            item.properties.cctvWithLastStateAndOpenAlarms.cctv.id
        ) {
          arr[index] = {
            ...item,
            selected: selectedItem.selected,
          };
        }
      });
      let camerasFiltered = FilterUtils.deepCopyOfArraysOfObj(payloadList);
      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "cameras"
      ) {
        const searchText = state.searchText.toLowerCase();
        const searchFn = searchCameras(searchText);
        camerasFiltered = payloadList.filter(searchFn);
      }
      return {
        ...state,
        cameras: payloadList,
        camerasFiltered: camerasFiltered,
      };

    case CamerasActions.SET_CURRENT_CAMERA:
      if (state.cameras) {
        camerasListCopy.forEach((item, index, arr) => {
          if (
            item &&
            item.properties &&
            item.properties.cctvWithLastStateAndOpenAlarms &&
            item.properties.cctvWithLastStateAndOpenAlarms.cctv &&
            action.payload &&
            action.payload.properties &&
            action.payload.properties.cctvWithLastStateAndOpenAlarms &&
            action.payload.properties.cctvWithLastStateAndOpenAlarms.cctv &&
            item.properties.cctvWithLastStateAndOpenAlarms.cctv.id ===
              action.payload.properties.cctvWithLastStateAndOpenAlarms.cctv.id
          ) {
            arr[index] = {
              ...item,
              selected: action.payload.selected ? false : true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      if (state.camerasFiltered) {
        camerasFilteredListCopy.forEach((item, index, arr) => {
          if (
            item &&
            item.properties &&
            item.properties.cctvWithLastStateAndOpenAlarms &&
            item.properties.cctvWithLastStateAndOpenAlarms.cctv &&
            action.payload &&
            action.payload.properties &&
            action.payload.properties.cctvWithLastStateAndOpenAlarms &&
            action.payload.properties.cctvWithLastStateAndOpenAlarms.cctv &&
            action.payload.properties.cctvWithLastStateAndOpenAlarms.cctv &&
            item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
              action.payload.properties.cctvWithLastStateAndOpenAlarms.cctv
                .deviceId
          ) {
            arr[index] = {
              ...item,
              selected: action.payload.selected ? false : true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      return {
        ...state,
        current:
          action.payload &&
          state.current &&
          state.current.properties.cctvWithLastStateAndOpenAlarms.cctv
            .deviceId &&
          action.payload.properties.cctvWithLastStateAndOpenAlarms
            .cctvdeviceId &&
          state.current.properties.cctvWithLastStateAndOpenAlarms
            .cctvdeviceId ===
            action.payload.properties.cctvWithLastStateAndOpenAlarms
              .cctvdeviceId
            ? null
            : action.payload,
        cameras: camerasListCopy,
        camerasFiltered: camerasFilteredListCopy,
      };

    case CamerasActions.RESET_CURRENT_CAMERA:
      if (state.cameras) {
        camerasListCopy.forEach((item, index, arr) => {
          if (item && item.selected) {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      if (state.camerasFiltered) {
        camerasFilteredListCopy.forEach((item, index, arr) => {
          if (item && item.selected) {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      return {
        ...state,
        cameras: camerasListCopy,
        camerasFiltered: camerasFilteredListCopy,
        current: null,
      };

    case CamerasActions.RESET_ALARM_CAMERAS_WS:
      return {
        ...state,
        wsLastCameraAlarm: [],
      };

    case CamerasActions.RESET_STATUS_CAMERAS_WS:
      return {
        ...state,
        wsLastCameraStatus: [],
      };

    case NavTabActions.SET_SEARCH:
      if (action.payload && action.payload.type === "cameras") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const searchFn = searchCameras(searchText);
        let camerasFiltered = state.cameras.filter(searchFn);
        return {
          ...state,
          camerasFiltered,
          searchText,
          searchType: action.payload.type,
        };
      } else {
        return { ...state };
      }
    case CamerasActions.UPDATE_CAMERA_STATE_WS: {
      let objState = { ...state };
      let newCurrent = state.current;

      //FOR EACH CAMERA IN THE LIST, SEARCH CORRESPONDANCE AND UPDATE IT
      action.payload.forEach((newDeviceStatusItem) => {
        camerasListCopy.forEach((cam, index, arr) => {
          if (
            cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
            newDeviceStatusItem.deviceId
          ) {
            arr[index] = {
              ...cam,
              properties: {
                ...cam.properties,
                cctvWithLastStateAndOpenAlarms: {
                  ...cam.properties.cctvWithLastStateAndOpenAlarms,
                  cctv: {
                    ...cam.properties.cctvWithLastStateAndOpenAlarms.cctv,
                    id: newDeviceStatusItem.cameraId,
                  },
                  last_state: {
                    ...cam.properties.cctvWithLastStateAndOpenAlarms.last_state,
                    diagnosticState: newDeviceStatusItem.diagnosticState,
                    timestamp: newDeviceStatusItem.timestamp,
                  },
                },
              },
            };
          }
        });

        camerasFilteredListCopy.forEach((cam, index, arr) => {
          if (
            cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
            newDeviceStatusItem.deviceId
          ) {
            arr[index] = {
              ...cam,
              properties: {
                ...cam.properties,
                cctvWithLastStateAndOpenAlarms: {
                  ...cam.properties.cctvWithLastStateAndOpenAlarms,
                  cctv: {
                    ...cam.properties.cctvWithLastStateAndOpenAlarms.cctv,
                    id: newDeviceStatusItem.cameraId,
                  },
                  last_state: {
                    ...cam.properties.cctvWithLastStateAndOpenAlarms.last_state,
                    diagnosticState: newDeviceStatusItem.diagnosticState,
                    timestamp: newDeviceStatusItem.timestamp,
                  },
                },
              },
            };
          }
        });

        if (newCurrent) {
          newCurrent = camerasListCopy.find(
            (cam) =>
              cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
              state.current.properties.cctvWithLastStateAndOpenAlarms.cctv
                .deviceId
          );
        }

        objState = {
          ...objState,
          cameras: camerasListCopy,
          camerasFiltered: camerasFilteredListCopy,
          current: newCurrent ? newCurrent : null,
        };
      });
      return {
        ...objState,
        wsLastCameraStatus: action.payload,
      };
    }
    case CamerasActions.ADD_NEW_PINNED_CAMERA:
      if (action.payload) {
        return {
          ...state,
          camerasPinned: [...state.camerasPinned, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case CamerasActions.REMOVE_PINNED_CAMERA:
      if (action.payload && action.payload.properties) {
        return {
          ...state,
          camerasPinned: state.camerasPinned.filter(
            (item) =>
              item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId !==
              action.payload.properties.cctvWithLastStateAndOpenAlarms.cctv
                .deviceId
          ),
          camerasOnMap: state.camerasOnMap.filter(
            (item) =>
              item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId !==
              action.payload.properties.cctvWithLastStateAndOpenAlarms.cctv
                .deviceId
          ),
        };
      } else {
        return {
          ...state,
        };
      }
    case CamerasActions.RESET_PINNED_CAMERAS:
      return {
        ...state,
        camerasPinned: [],
        camerasOnMap: [],
      };
    case CamerasActions.ADD_NEW_CAMERA_ON_MAP:
      if (action.payload) {
        return {
          ...state,
          camerasOnMap: [...state.camerasOnMap, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case CamerasActions.REMOVE_CAMERA_ON_MAP:
      if (action.payload && action.payload.properties) {
        return {
          ...state,
          camerasOnMap: state.camerasOnMap.filter(
            (item) =>
              item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId !==
              action.payload.properties.cctvWithLastStateAndOpenAlarms.cctv
                .deviceId
          ),
        };
      } else {
        return {
          ...state,
        };
      }
    case CamerasActions.UPDATE_CAMERA_ALARMS_WS: {
      let objState = { ...state };

      let newCurrent = state.current;
      //   //FOR EACH ALARM IN THE LIST, SEARCH CAMERA AND UPDATE IT
      action.payload.forEach((newAlarm) => {
        camerasListCopy.forEach((cam, camIndex, arrCameras) => {
          if (
            cam.properties &&
            cam.properties.cctvWithLastStateAndOpenAlarms &&
            cam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
            cam.properties.cctvWithLastStateAndOpenAlarms.cctv.id ===
              newAlarm.cameraId
          ) {
            let alarmListCopy = cam.properties.cctvWithLastStateAndOpenAlarms.open_alarms.map(
              (x) => x
            );

            //CHECK CORRESPONDANCE
            const isAlarmAlreadyPresent = alarmListCopy.some(
              (item) => item === newAlarm.alarmCode
            );

            if (newAlarm.status === "CLOSED" && isAlarmAlreadyPresent) {
              //IF CLOSED, REMOVE FROM LIST OF ALARMS
              alarmListCopy = alarmListCopy.filter(
                (item) => item !== newAlarm.alarmCode
              );
            } else if (newAlarm.status !== "CLOSED" && !isAlarmAlreadyPresent) {
              //IF NEW/NOT PRESENT, ADD
              alarmListCopy = [...alarmListCopy, newAlarm.alarmCode];
            }
            //IF NOT PRESENT BUT STATUS CLOSED, DO NOTHING
            arrCameras[camIndex] = {
              ...cam,
              properties: {
                cctvWithLastStateAndOpenAlarms: {
                  ...cam.properties.cctvWithLastStateAndOpenAlarms,
                  open_alarms: [...alarmListCopy],
                },
              },
            };
          }
        });

        camerasFilteredListCopy.forEach((cam, camIndex, arrCameras) => {
          if (
            cam.properties &&
            cam.properties.cctvWithLastStateAndOpenAlarms &&
            cam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
            cam.properties.cctvWithLastStateAndOpenAlarms.cctv.id ===
              newAlarm.cameraId
          ) {
            let alarmListCopy = cam.properties.cctvWithLastStateAndOpenAlarms.open_alarms.map(
              (x) => x
            );
            //CHECK CORRESPONDANCE
            const isAlarmAlreadyPresent = alarmListCopy.some(
              (item) => item === newAlarm.alarmCode
            );

            if (newAlarm.status === "CLOSED" && isAlarmAlreadyPresent) {
              //IF CLOSED, REMOVE FROM LIST OF ALARMS
              alarmListCopy = alarmListCopy.filter(
                (item) => item !== newAlarm.alarmCode
              );
            } else if (newAlarm.status !== "CLOSED" && !isAlarmAlreadyPresent) {
              //IF NEW/NOT PRESENT, ADD
              alarmListCopy = [...alarmListCopy, newAlarm.alarmCode];
            }
            //IF NOT PRESENT BUT STATUS CLOSED, DO NOTHING
            arrCameras[camIndex] = {
              ...cam,
              properties: {
                cctvWithLastStateAndOpenAlarms: {
                  ...cam.properties.cctvWithLastStateAndOpenAlarms,
                  open_alarms: [...alarmListCopy],
                },
              },
            };
          }
        });

        if (newCurrent) {
          newCurrent = camerasListCopy.find(
            (cam) =>
              cam.properties.cctvWithLastStateAndOpenAlarms.cctv.id ===
              state.current.properties.cctvWithLastStateAndOpenAlarms.cctv.id
          );
        }

        objState = {
          ...objState,
          cameras: camerasListCopy,
          camerasFiltered: camerasFilteredListCopy,
          current: newCurrent ? newCurrent : null,
        };
      });

      return {
        ...objState,
        wsLastCameraAlarm: action.payload,
      };
    }
    case CamerasActions.MOVE_CAMERA_POSITION_ON_MAP:
      if (action.payload) {
        return {
          ...state,
          cameraPositionOnMap: [...action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    default:
      return state;
  }
}

export const getCameras = (state) => state.cameras;
export const getFilteredCameras = (state) => state.camerasFiltered;
export const getCurrentCamera = (state) => state.current;
export const getPinnedCameras = (state) => state.camerasPinned;
export const getCamerasOnMap = (state) => state.camerasOnMap;
export const getWsLastCameraAlarm = (state) => state.wsLastCameraAlarm;
export const getWsLastCameraStatus = (state) => state.wsLastCameraStatus;
export const getCameraPositionOnMap = (state) => state.cameraPositionOnMap;
