import { CompositeLayer, IconLayer } from "deck.gl";
import pointDraw from "assets/icon/pointDraw.svg";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";

class MarkerPointsShortestDistanceLayer extends CompositeLayer {
  renderLayers() {
    const { id, data, geoJson } = this.props;

    if (data && data.length > 0 && geoJson && geoJson.features.length > 0) {
      return new IconLayer(
        this.getSubLayerProps({
          id: id,
          data: data,
          pickable: true,
          ...DeckLayerUtil.loadOptions(),
          getIcon: () => ({
            url: pointDraw,
            width: 50,
            height: 40,
            anchorX: 25,
            anchorY: 20,
          }),
          sizeScale: 4,
          visible: true,
          getPosition: (d) => d.coordinates,
          getSize: () => 4,
        })
      );
    } else {
      return [];
    }
  }
}

MarkerPointsShortestDistanceLayer.layerName =
  "MarkerPointsShortestDistanceLayer";

export default MarkerPointsShortestDistanceLayer;
