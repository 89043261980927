import React, { Component } from "react";
import "../style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toggleAlarmTable } from "reducers/ui/installations-menu/installations-menu.actions";
import { isAlarmTableOpen } from "store";
import EnvironmentContext from "environment-context";
import * as moment from "moment";

//UTILS
import { ALARM_PRIORITY } from "utils/util-cameras";
import alarm_icon from "assets/icon/installations/alarm_icon.svg";

class AlarmDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  renderTableHead = () => {
    return (
      <tr>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between"></div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Tipo Allarme
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Nome Allarme
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Descrizione
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Stato
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Priorità
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Fonte
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Ora inizio
          </div>
        </th>
      </tr>
    );
  };

  renderTableBody = () => {
    const { currentCameraWithActiveAlarms } = this.props;
    return (
      <tbody>
        {currentCameraWithActiveAlarms.map((item, index) => {
          let priority =
            ALARM_PRIORITY.find(
              (priority) => item.priority === priority.name
            ) &&
            ALARM_PRIORITY.find((priority) => item.priority === priority.name)
              .color;

          return (
            <tr key={index}>
              <td>
                <div className="uk-position-relative height-width-48">
                  <img alt="alarm-icon" src={alarm_icon} />
                </div>
              </td>
              <td>{item.type ? item.type : "-"}</td>
              <td>{item.message ? item.message : "-"}</td>
              <td>{item.description ? item.description : "-"}</td>
              <td>{item.status ? item.status : "-"}</td>
              <td className={priority ? priority : ""}>
                {item.priority ? item.priority : "-"}
              </td>
              <td>{item.source ? item.source : "-"}</td>
              <td>
                {item.openTime
                  ? moment(item.openTime).format("DD/MM/YY  HH:mm")
                  : "-"}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  renderData() {
    return (
      <table id="alarmTable" className="uk-table uk-table-divider no-bg">
        <thead>{this.renderTableHead()}</thead>
        {this.renderTableBody()}
      </table>
    );
  }

  renderHeading = () => {
    const { toggleAlarmTable, targetCamera } = this.props;
    return (
      <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container detail-event-wrapper">
        <div className=" uk-width-1-2">Dettaglio Allarmi: {targetCamera}</div>
        <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
          <span
            className="pointer"
            uk-icon="icon: acr-interface-close"
            onClick={toggleAlarmTable}
          ></span>
        </div>
      </div>
    );
  };

  render = () => {
    const { isAlarmTableOpen } = this.props;
    return (
      <div
        className={
          "pmv-message-container uk-flex-right uk-flex uk-flex-column uk-width-1-1 z-index-1" +
          isAlarmTableOpen
            ? "uk-position-relative"
            : ""
        }
      >
        <div className="position-relative uk-flex-right uk-flex uk-flex-column uk-width-1-1">
          <div className="bg-dark uk-flex uk-flex-column uk-width-1-1 uk-height-1-1 uk-padding-small">
            <div className="bg-dark uk-margin-small-left uk-margin-small-right uk-margin-small-bottom uk-margin-small-top uk-padding-remove border-bottom">
              {this.renderHeading()}
            </div>
            <div className="event-detail-wrapper max-height-320 bg-dark uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top custom-bottom-padding">
              {this.renderData()}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

AlarmDetail.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleAlarmTable,
};

const mapStateToProps = (state) => ({
  isAlarmTableOpen: isAlarmTableOpen(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlarmDetail)
);
