import React, { Component } from "react";
import * as moment from "moment";

import { getIconEvent } from "utils/utils_event_icon";
import { selectFields } from "enums/selectable-params";
import { eventLocalizationToShowUtil } from "utils/utils";
import { getSubtypeTranslation } from "utils/util-events";

class EventListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      event,
      index,
      selectedEvent,
      toggleDetailsGroupEvent,
      toggleDetails,
      selectModal,
    } = this.props;

    let arrLocations =
      event && event.groupOfLocations && event.groupOfLocations.length > 0
        ? eventLocalizationToShowUtil(event.groupOfLocations)
        : [];

    return (
      <div
        key={index}
        className={
          event.isSelected
            ? "uk-flex uk-flex-row uk-flex-middle uk-flex-around uk-width-1-1 height-84"
            : "uk-flex uk-flex-row uk-flex-middle uk-flex-around uk-width-1-1 height-84 associated-events-padding "
        }
      >
        {event.isSelected ? (
          <div className="associated-events">
            <span
              className={`${
                event.eventNumber === 1
                  ? "vertical-line-trasparent"
                  : "vertical-line"
              }`}
            ></span>

            <span className="horizontal-line"></span>
          </div>
        ) : (
          ""
        )}

        <li
          className={
            selectedEvent === event.situationRecordId
              ? "uk-flex uk-flex-around uk-flex-middle uk-width-1-1 uk-active"
              : "uk-flex uk-flex-around uk-flex-middle uk-width-1-1"
          }
          onClick={(e) => {
            e.preventDefault();
            if (e.ctrlKey) {
              toggleDetailsGroupEvent(event);
            } else {
              toggleDetails(event);
            }
          }}
        >
          {event && event.severity && (
            <span
              className={
                event.severity === selectFields.GRAVITA.properties[4].name
                  ? "item-severity-dark-green"
                  : event.severity === selectFields.GRAVITA.properties[3].name
                  ? "item-severity-green"
                  : event.severity === selectFields.GRAVITA.properties[2].name
                  ? "item-severity-yellow"
                  : event.severity === selectFields.GRAVITA.properties[1].name
                  ? "item-severity-orange"
                  : event.severity === selectFields.GRAVITA.properties[0].name
                  ? "item-severity-red"
                  : event.severity === selectFields.GRAVITA.properties[5].name
                  ? "item-severity-gray"
                  : "item-severity-gray"
              }
            ></span>
          )}
          <div className="uk-position-relative event-height-width-48">
            <img
              alt={event.situationRecordType}
              src={getIconEvent(event, selectModal)}
            />
          </div>
          <div className="uk-flex uk-margin-small-left width-260">
            <div className="uk-flex uk-flex-row">
              <div className="uk-flex uk-flex-column">
                <div
                  className={
                    selectedEvent === event.situationRecordId
                      ? "uk-width-1-1 list-text headerName highlight "
                      : "uk-width-1-1 list-text headerName "
                  }
                  ref={
                    selectedEvent === event.situationRecordId
                      ? this.scrollDiv
                      : ""
                  }
                  tabIndex={
                    selectedEvent === event.situationRecordId ? "-1" : ""
                  }
                >
                  {getSubtypeTranslation(event, selectModal)}
                </div>
                <div
                  uk-tooltip={"title: " + arrLocations.toString()}
                  className={
                    selectedEvent === event.situationRecordId
                      ? "uk-width-1-1 uk-margin-right list-text location_info p-dot-2"
                      : "uk-width-1-1 uk-margin-right inactive list-text location_info p-dot-2"
                  }
                >
                  {arrLocations.toString()}
                </div>
              </div>
              <div>
                <div
                  className={
                    selectedEvent === event.situationRecordId
                      ? "record_creation_date "
                      : "record_creation_date inactive"
                  }
                >
                  {event.validity &&
                  event.validity.validityTimeSpecification &&
                  event.validity.validityTimeSpecification.overallStartTime
                    ? moment(
                        event.validity.validityTimeSpecification
                          .overallStartTime
                      ).format("DD/MM/YYYY HH:mm")
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </li>
      </div>
    );
  }
}

export default EventListItem;
