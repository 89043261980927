import { geometryGeoJson } from "reducers/graph/graph-features.models";
export const INITIAL_STATE = {
  arcs: [],
  arcsFiltered: [],
  current: null,
  currentMap: [],
  searchText: "",
  searchType: "",
  bounds: [],
  contexts: [], //list of contexts relative to 0, 15, 30, 45, 60 mins in the future
  currentContext: null, //contest to show in widget: actual, forecast, history, typic
  measures: [],
  currentMeasure: null,
  dayTypes: [],
  currentDayType: null, // Uno degli enum di default "Lavorativo"
  currentDay: "", // stringa rappresentate la data di intervallo (es. "oggi", "10/04/2021"): "oggi" di default
  currentTime: "", // ora attuale al momento di apertura della pagina del traffico, arrotondata al quarto d'ora - cambia quando viene modificato lo slider
  currentStartTime: "", // ISO String per comodità sulla logica di filtraggio sulle api
  currentEndTime: "", // ISO String per comodità sulla logica di filtraggio sulle api
  vehicleClasses: [],
  currentVehicleClass: null,
  currentTimeOffSet: 0,
  currentDateSlider: null, //da rimuovere
  wsLastNotification: [],
  currentElaboratedData: [],
};

/* TRAFFIC ENUMS */
export const TRAFFIC_CONTEXT_ENUM = {
  ACTUAL: "ACTUAL",
  FORECAST: "FORECAST",
  TYPICAL: "TYPICAL",
};

export const MEASURE_TYPE = {
  AVERAGE_DISTANCE_GAP: "AVERAGE_DISTANCE_GAP",
  AVERAGE_TIME_GAP: "AVERAGE_TIME_GAP",
  AVERGAE_DISTANCE_HEADWAY: "AVERGAE_DISTANCE_HEADWAY",
  AVERAGE_TIME_HEADWAY: "AVERAGE_TIME_HEADWAY",
  DENSITY: "DENSITY",
  OCCUPANCY: "OCCUPANCY",
  AVERAGE_VEHICLE_SPEED: "AVERAGE_VEHICLE_SPEED",
  NORMALLY_EXPECTED_SPEED: "NORMALLY_EXPECTED_SPEED",
  MINIMUM_SPEED: "MINIMUM_SPEED",
  MAXIMUM_SPEED: "MAXIMUM_SPEED",
  SPEED_PERCENTILE: "SPEED_PERCENTILE",
  VEHICLE_PERCENTAGE: "VEHICLE_PERCENTAGE",
  SPEED_ON_MAXIMUM_SPEED: "SPEED_ON_MAXIMUM_SPEED",
  AXLE_FLOW: "AXLE_FLOW",
  PCU_FLOW: "PCU_FLOW",
  LONG_VEHICLES_PERCENTAGE: "LONG_VEHICLES_PERCENTAGE",
  NORMALLY_EXPECTED_FLOW: "NORMALLY_EXPECTED_FLOW",
  VEHICLE_FLOW: "VEHICLE_FLOW",
  MONTHLY_AVERAGE_DAILY_TRAFFIC: "MONTHLY_AVERAGE_DAILY_TRAFFIC",
  ANNUAL_AVERAGE_DAILY_TRAFFIC: "ANNUAL_AVERAGE_DAILY_TRAFFIC",
  FLOW_ON_CAPACITY: "FLOW_ON_CAPACITY",
  TRAVEL_TIME: "TRAVEL_TIME",
  FREE_FLOW_TRAVEL_TIME: "FREE_FLOW_TRAVEL_TIME",
  TRAVEL_TIME_DELAY: "TRAVEL_TIME_DELAY",
  NORMALLY_EXPECTED_TRAVEL_TIME: "NORMALLY_EXPECTED_TRAVEL_TIME",
  FREE_FLOW_SPEED: "FREE_FLOW_SPEED",
  TRAFFIC_STATUS: "TRAFFIC_STATUS",
  RELATIVE_HUMIDITY: "RELATIVE_HUMIDITY",
  MINIMUM_VISIBILITY_DISTANCE: "MINIMUM_VISIBILITY_DISTANCE",
  PRESSURE: "PRESSURE",
  WIND_SPEED: "WIND_SPEED",
  MAXIMUM_WIND_SPEED: "MAXIMUM_WIND_SPEED",
  WIND_DIRECTION_BEARING: "WIND_DIRECTION_BEARING",
  MAXIMUM_WIND_DIRECTION_BEARING: "MAXIMUM_WIND_DIRECTION_BEARING",
  WIND_DIRECTION_COMPASS: "WIND_DIRECTION_COMPASS",
  MAXIMUM_WIND_DIRECTION_COMPASS: "MAXIMUM_WIND_DIRECTION_COMPASS",
  AIR_TEMPERATURE: "AIR_TEMPERATURE",
  DEW_POINT_TEMPERATURE: "DEW_POINT_TEMPERATURE",
  MAXIMUM_TEMPERATURE: "MAXIMUM_TEMPERATURE",
  MINIMUM_TEMPERATURE: "MINIMUM_TEMPERATURE",
  TEMPERATURE_BELOW_OR_ABOVE_ROAD_SURFACE:
    "TEMPERATURE_BELOW_OR_ABOVE_ROAD_SURFACE",
  ROAD_SURFACE_TEMPERATURE: "ROAD_SURFACE_TEMPERATURE",
  PROTECTION_TEMPERATURE: "PROTECTION_TEMPERATURE",
  DELCING_APPLICATION_RATE: "DELCING_APPLICATION_RATE",
  DELCING_CONCENTRATION: "DELCING_CONCENTRATION",
  FRICTION: "FRICTION",
  DEPTH_OF_SNOW: "DEPTH_OF_SNOW",
  WATER_FILM_THICKNESS: "WATER_FILM_THICKNESS",
  ICE_LAYER_THICKNESS: "ICE_LAYER_THICKNESS",
  ICE_PERCENTAGE: "ICE_PERCENTAGE",
  PRECIPITATION_INTENSITY: "PRECIPITATION_INTENSITY",
  DEPOSITION_DEPTH: "DEPOSITION_DEPTH",
  POLLUTANT_CONCENTRATION: "POLLUTANT_CONCENTRATION",
  ACCURACY: "ACCURACY",
  OTHER: "OTHER",
};

export const MEASURE_GROUP_TYPE = {
  TRAFFIC_DATA: "TRAFFIC_DATA",
  TRAFFIC_STATUS: "TRAFFIC_STATUS",
  TRAVEL_TIME_DATA: "TRAVEL_TIME_DATA",
  WEATHER_DATA: "WEATHER_DATA",
};

export const MEASURE_UNIT_TYPE = {
  SECONDS: "s",
  MINUTES: "min",
  HOURS: "h",
  DAYS: "d",
  METRES: "m",
  KILOMETRES: "km",
  KILOMETRES_PER_HOUR: "km/h",
  VEHICLES_PER_HOUR: "v/h",
  DENSITY_VEHICLES_PER_KILOMETRE: "v/km",
  AXLE_PER_HOUR: "A/h",
  PASSENGER_CAR_UNITS_PER_HOUR: "PCU/h",
  PERCENTAGE: "%",
  TEMPERATURE_CELSIUS: "°C",
  INTENSITY_KILOGRAMS_PER_SQUARE_METRE: "l/m²",
  CONCENTRATION_KILOGRAMS_PER_SQUARE_METRE: "kg/m²",
  HECTOPASCAL: "hPa",
  ANGLE_IN_DEGREES: "gradi",
  INTENSITY_MILLIMETRES_PER_HOUR: "mm/h",
  CONCENTRATION_MICROGRAMS_PER_CUBIC_METRE: "microg/m³",
};

export const DAY_TYPE = {
  WORKING_SCHOOL: "Feriale Scolastico",
  WORKING_NO_SCHOOL: "Feriale non Scolastico",
  HOLIDAY: "Festivo",
};

export const DAY_TYPE_EXTENDED = {
  WORKING_SCHOOL: {
    enum: "WORKING_SCHOOL",
    name: "Working School",
    nameIT: "Feriale Scolastico",
  },
  WORKING_NO_SCHOOL: {
    enum: "WORKING_NO_SCHOOL",
    name: "Working No School",
    nameIT: "Feriale non Scolastico",
  },
  HOLIDAY: { enum: "HOLIDAY", name: "Holiday", nameIT: "Festivo" },
};

export const VEHICLE_TYPE = {
  ANY_VEHICLE: "ANY_VEHICLE",
  BUS: "BUS",
  CAR: "CAR",
  CAR_OR_LIGHT_VEHICLE: "CAR_OR_LIGHT_VEHICLE",
  HEAVY_VEHICLE: "HEAVY_VEHICLE",
  LORRY: "LORRY",
  MOTORCYCLE: "MOTORCYCLE",
  VAN: "VAN",
  TRAM: "TRAM",
  OTHER: "OTHER",
  UNKNOWN: "UNKNOWN",
};
export const MEASURE_NAME = {
  Flow: "Flusso",
  Speed: "Velocità",
  Density: "Densità",
  Travel_Time: "Tempo Di Viaggio",
  Flow_On_Capacity: "Flusso/Capacità",
  Speed_On_Speed_Max: "Velocità/Velocità Massima",
  Los: "Los",
};

export class TrafficContext {
  contextId;
  contextType;
  contextName;
  timeOffset;

  constructor() {
    this.contextType = null;
    this.contextId = null;
    this.contextName = null;
    this.timeOffset = null;
  }
  static fromREST(obj) {
    var context = new TrafficContext();
    if (obj.contextId) context.contextId = obj.contextId;
    if (obj.contextName) context.contextName = obj.contextName;
    if (obj.timeOffset) context.timeOffset = obj.timeOffset;
    if (obj.contextType)
      context.contextType = TRAFFIC_CONTEXT_ENUM[obj.contextType];
    return context;
  }

  static fromArrayREST(data) {
    return data.map(TrafficContext.fromREST).filter((item) => item !== null);
  }
}

export class Measures {
  measureId;
  measureName;
  measureType;
  measureGroupType;
  measureUnitType;
  standard;

  constructor() {
    this.measureId = null;
    this.measureName = null;
    this.measureType = null;
    this.measureUnitType = null;
    this.standard = null;
  }

  static fromREST(obj) {
    let trafficMesures = new Measures();
    trafficMesures.measureId = obj.measureId ? obj.measureId : null;
    trafficMesures.measureName = obj.measureName ? obj.measureName : null;
    trafficMesures.measureType = obj.measureType
      ? MEASURE_TYPE[obj.measureType]
      : null;
    trafficMesures.measureGroupType = obj.measureGroupType
      ? MEASURE_GROUP_TYPE[obj.measureGroupType]
      : null;
    trafficMesures.measureUnitType = obj.measureUnitType
      ? MEASURE_UNIT_TYPE[obj.measureUnitType]
      : null;
    trafficMesures.standard = obj.standard ? obj.standard : null;
    return trafficMesures;
  }

  static fromArrayREST(data) {
    return data.map(Measures.fromREST).filter((item) => item !== null);
  }
}

export class TrafficArcsMeasure {
  measureId = "";
  measureValue = 0;

  constructor() {
    this.measureId = null;
    this.measureValue = null;
  }

  static fromREST(obj) {
    var measure = new TrafficArcsMeasure();
    measure.measureId = obj.measureId ? obj.measureId : "";
    measure.measureValue = obj.measureValue ? obj.measureValue : 0;

    return measure;
  }

  static fromArrayREST(data) {
    return data
      .map(TrafficArcsMeasure.fromREST)
      .filter((item) => item !== null);
  }
}

/* ElaboratedDataFeatureProperties da swagger */
export class TrafficArcsPropertiesModel {
  archId;
  archName;
  direction;
  measures;
  profile;

  constructor() {
    //ID
    this.archId = null;
    //NAME
    this.archName = null;
    //DIRECTION
    this.direction = null;
    //MEASURES
    this.measures = null;
    //PROFILE
    this.profile = null;
  }

  static fromREST(obj) {
    var trafficArcs = new TrafficArcsPropertiesModel();
    trafficArcs.archId = obj.archId ? obj.archId : null;
    trafficArcs.archName = obj.archName ? obj.archName : null;
    trafficArcs.direction = obj.direction ? obj.direction : null;
    trafficArcs.measures =
      obj.measures && obj.measures.length > 0
        ? TrafficArcsMeasure.fromArrayREST(obj.measures)
        : [];
    trafficArcs.profile =
      obj.profile && obj.profile.length > 0
        ? TrafficArcsMeasure.fromArrayREST(obj.profile)
        : [];
    return trafficArcs;
  }

  static fromArrayREST(data) {
    return data
      .map(TrafficArcsPropertiesModel.fromREST)
      .filter((item) => item !== null);
  }
}

/* ElaboratedDataFeatures da swagger */
export class TrafficArcsFeatures {
  features;

  constructor() {
    this.features = null;
  }

  static fromREST(obj) {
    let arcsFeatures = new TrafficArcsFeatures();
    arcsFeatures.features = obj.features = obj.features
      ? TrafficArcsFeature.fromArrayREST(obj.features)
      : [];
    return arcsFeatures;
  }

  static fromArrayREST(data) {
    return data
      .map(TrafficArcsFeatures.fromREST)
      .filter((item) => item !== null);
  }
}

/* ElaboratedDataFeature da swagger */

export class TrafficArcsFeature {
  id;
  type;
  geometry;
  properties;

  constructor() {
    this.id = null;
    this.type = null;
    this.geometry = null;
    this.properties = null;
  }

  static fromREST(obj) {
    let arcsFeature = new TrafficArcsFeature();
    arcsFeature.id = obj.id ? obj.id : null;
    arcsFeature.type = obj.type ? obj.type : null;
    arcsFeature.geometry = obj.geometry
      ? geometryGeoJson.fromREST(obj.geometry)
      : null;
    arcsFeature.properties = obj.properties
      ? TrafficArcsPropertiesModel.fromREST(obj.properties)
      : null;
    return arcsFeature;
  }

  static fromArrayREST(data) {
    return data
      .map(TrafficArcsFeature.fromREST)
      .filter((item) => item !== null);
  }
}

export class TrafficElaboratedData {
  measurementTimeValue;
  measurementStartOfPeriod;
  measurementPeriod;
  lastUpdateTimeValue;
  archs;

  constructor() {
    this.measurementTimeValue = null;
    this.measurementStartOfPeriod = null;
    this.measurementPeriod = null;
    this.lastUpdateTimeValue = null;
    this.archs = null;
  }

  static fromREST(obj) {
    let trafficElaboratedData = new TrafficElaboratedData();
    trafficElaboratedData.measurementTimeValue = obj.measurementTimeValue
      ? obj.measurementTimeValue
      : null;
    trafficElaboratedData.measurementStartOfPeriod = obj.measurementStartOfPeriod
      ? obj.measurementStartOfPeriod
      : null;
    trafficElaboratedData.measurementPeriod = obj.measurementPeriod
      ? obj.measurementPeriod
      : null;
    trafficElaboratedData.lastUpdateTimeValue = obj.lastUpdateTimeValue
      ? obj.lastUpdateTimeValue
      : null;
    trafficElaboratedData.archs = obj.archs
      ? TrafficArc.fromArrayREST(obj.archs)
      : [];
    return trafficElaboratedData;
  }

  static fromArrayREST(data) {
    return data
      .map(TrafficElaboratedData.fromREST)
      .filter((item) => item !== null);
  }
}

export class TrafficArc {
  archId;
  contexts;
  profile;

  constructor() {
    this.archId = null;
    this.contexts = [];
    this.profile = [];
  }

  static fromREST(obj) {
    let arc = new TrafficArc();
    arc.archId = obj.archId ? obj.archId : null;
    arc.contexts = obj.contexts
      ? TrafficArcsContext.fromArrayREST(obj.contexts)
      : [];
    arc.profile = obj.profile
      ? TrafficVehicleClassLight.fromArrayREST(obj.profile)
      : [];
    return arc;
  }

  static fromArrayREST(data) {
    return data.map(TrafficArc.fromREST).filter((item) => item !== null);
  }
}

export class TrafficVehicleClassLight {
  vehicleClassId;
  measures;

  constructor() {
    this.vehicleClassId = null;
    this.measures = [];
  }

  static fromREST(obj) {
    let vehicleClass = new TrafficVehicleClassLight();
    vehicleClass.vehicleClassId = obj.vehicleClassId
      ? obj.vehicleClassId
      : null;
    vehicleClass.measures = obj.measures
      ? TrafficArcsMeasure.fromArrayREST(obj.measures)
      : [];

    return vehicleClass;
  }
  static fromArrayREST(data) {
    return data
      .map(TrafficVehicleClassLight.fromREST)
      .filter((item) => item !== null);
  }
}

export class TrafficVehicleClass {
  vehicleClassId;
  vehicleClassName;
  vehicleType;
  weight;

  constructor() {
    this.vehicleClassId = null;
    this.vehicleClassName = null;
    this.vehicleType = null;
    this.weight = null;
  }

  static fromREST(obj) {
    let vehicleClass = new TrafficVehicleClass();
    vehicleClass.vehicleClassId = obj.vehicleClassId
      ? obj.vehicleClassId
      : null;
    vehicleClass.vehicleClassName = obj.vehicleClassName
      ? obj.vehicleClassName
      : null;
    vehicleClass.vehicleType = obj.vehicleType
      ? VEHICLE_TYPE[obj.vehicleType]
      : null;
    vehicleClass.weight = obj.weight ? obj.weight : null;
    return vehicleClass;
  }

  static fromArrayREST(data) {
    return data
      .map(TrafficVehicleClass.fromREST)
      .filter((item) => item !== null);
  }
}

export class TrafficArcsContext {
  contextId;
  vehicleClasses;

  constructor() {
    this.contextId = null;
    this.vehicleClasses = null;
  }

  static fromREST(obj) {
    let trafficArcsContext = new TrafficArcsContext();
    trafficArcsContext.contextId = obj.contextId ? obj.contextId : null;
    trafficArcsContext.vehicleClasses = obj.vehicleClasses
      ? TrafficVehicleClassLight.fromArrayREST(obj.vehicleClasses)
      : [];
    return trafficArcsContext;
  }

  static fromArrayREST(data) {
    return data
      .map(TrafficArcsContext.fromREST)
      .filter((item) => item !== null);
  }
}

export class Day {
  date;
  type;

  constructor() {
    this.date = null;
    this.type = null;
  }

  static fromREST(obj) {
    let day = new Day();
    day.date = obj.date ? obj.date : null;
    day.type = obj.type ? DAY_TYPE[obj.type] : null;
    return day;
  }

  static fromArrayREST(data) {
    return data.map(Day.fromREST).filter((item) => item !== null);
  }
}

// CLASS TEMPLATE
// export class NOME {

//   constructor() {
//   }

//   static fromREST(obj) {
//     return;
//   }

//   static fromArrayREST(data) {
//     return data
//       .map()
//       .filter((item) => item !== null)
//   }
// }
