import React, { Component } from "react";
import "./style.less";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import createApiService from "services/api.service";
import { tap, catchError, take } from "rxjs/operators";
import { of } from "rxjs";
//MODEL
import { PmvAnagraficaAndStatusModel } from "reducers/installations/pmv/pmv.model";

class PMVTooltip extends Component {
  apiService;
  subscriptions = [];
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      pmvObject: "",
    };
  }

  componentDidMount() {
    this.callApi();

    this.getWidthHeight();
  }

  callApi = () => {
    const { object } = this.props;
    if (
      object &&
      object.properties &&
      object.properties.anagrafica &&
      object.properties.anagrafica.id
    ) {
      let id = object.properties.anagrafica.id;
      const jwt = sessionStorage.getItem("id_token");

      fetch("/config.json")
        .then((res) => res.json())
        .then((environment) => {
          this.apiService = createApiService(environment);
          this.apiService
            .getPmvStatusById(id, jwt)
            .pipe(
              take(1),
              tap((data) => {
                let currentPmv = PmvAnagraficaAndStatusModel.from(data);
                object.nomeMessaggio =
                  currentPmv &&
                  currentPmv.stati &&
                  currentPmv.stati.length > 0 &&
                  currentPmv.stati[0].statoOperativo &&
                  currentPmv.stati[0].statoOperativo.nomeMessaggio
                    ? currentPmv.stati[0].statoOperativo.nomeMessaggio
                    : null;
                object.descrizioneEsposizione =
                  currentPmv &&
                  currentPmv.stati &&
                  currentPmv.stati.length > 0 &&
                  currentPmv.stati[0].statoOperativo &&
                  currentPmv.stati[0].statoOperativo.descrizioneEsposizione
                    ? currentPmv.stati[0].statoOperativo.descrizioneEsposizione
                    : null;

                this.setState({
                  ...this.state,
                  pmvObject: object,
                });
              }),

              catchError((error) => {
                console.error(error);
                return of(error);
              })
            )
            .subscribe();
        });
    }
  };

  getWidthHeight = () => {
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ ...this.state, height: height, width: width });
  };

  render() {
    const { x, y } = this.props;
    const { height, width, pmvObject } = this.state;

    return (
      <div
        className="show-tooltip"
        style={{ left: x - width / 2, top: y - (height + 20) }}
        ref={(divElement) => {
          this.divElement = divElement;
        }}
      >
        <div className="uk-flex uk-flex-column">
          <div className="uk-flex uk-flex-center uk-width-1-1 uk-position-relative uk-margin-top ">
            <p className="legend-text-small uk-text-bold">
              {pmvObject &&
              pmvObject.properties &&
              pmvObject.properties.anagrafica &&
              pmvObject.properties.anagrafica.nome
                ? pmvObject.properties.anagrafica.nome
                : " -"}
            </p>
          </div>
          <div className="uk-flex uk-flex-column uk-margin-small-top">
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Tipologia: &nbsp;</div>
              <div className="tooltip-text-important">
                {pmvObject &&
                pmvObject.properties &&
                pmvObject.properties.anagrafica &&
                pmvObject.properties.anagrafica.tipologia
                  ? pmvObject.properties.anagrafica.tipologia
                  : " -"}
              </div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Nome Messaggio: &nbsp;</div>
              <div className="tooltip-text-important subType">
                {pmvObject && pmvObject.nomeMessaggio
                  ? pmvObject.nomeMessaggio
                  : "-"}
              </div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">
                Descrizione Esposizione: &nbsp;
              </div>
              <div className="tooltip-text-important">
                {pmvObject && pmvObject.descrizioneEsposizione
                  ? pmvObject.descrizioneEsposizione
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {};
const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PMVTooltip)
);
