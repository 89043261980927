import React, { Component } from "react";
import "./style.less";
import * as moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";
import { of } from "rxjs";
import { tap, take, catchError } from "rxjs/operators";
//STORE
import {
  isOpenNavTab,
  getTrafficCurrentContext,
  getTrafficCurrentTime,
  getTrafficCurrentMeasure,
  getTrafficMeasures,
  getTrafficCurrentDayType,
  getCurrentTimeOffSet,
  getTrafficContexts,
  areAllTrafficVisible,
  isNetStateVisible,
  isTypicalVisible,
  getTrafficCurrentStartTime,
  //workspace
  getCurrentWorkspace,
} from "store";
import createApiService from "services/api.service";
//COMPONENTS
import ArcTrafficDetailHeader from "components/layout/arc-traffic/arc-traffic-detail-header/index";
import LineChartTrafficMeasures from "components/layout/line-chart/line-chart-traffic-measures";
//ASSETS
import {
  getMeasurePropertiesById,
  getLineChartDataFromMeasuredState,
  getContextAndDateFromApiCall,
  getLineChartDataFromMeasuredTypic,
  measureToShow,
} from "utils/utils-traffic";

class ArcTrafficMeasures extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {
      arcMeasures: null,
      arcGraphMeasures: [],
    };
  }

  componentDidMount = () => {
    this.apiService = createApiService(this.context);

    this.getArcMeasuresList();
  };

  componentDidUpdate = (prevProps) => {
    const {
      isNetStateVisible,
      isTypicalVisible,
      currentOffSet,
      currentTime,
      currentDayType,
      currentWorkspace,
    } = this.props;
    if (
      prevProps.isNetStateVisible !== isNetStateVisible ||
      prevProps.isTypicalVisible !== isTypicalVisible ||
      prevProps.currentOffSet !== currentOffSet ||
      prevProps.currentTime !== currentTime ||
      prevProps.currentDayType !== currentDayType ||
      (prevProps.currentWorkspace !== currentWorkspace &&
        prevProps.currentWorkspace &&
        currentWorkspace &&
        prevProps.currentWorkspace.preferenceId !==
          currentWorkspace.preferenceId)
    ) {
      this.getArcMeasuresList();
    }
  };

  getArcMeasuresList = () => {
    const {
      currentArc,
      currentOffSet,
      currentTime,
      contexts,
      isNetStateVisible,
      isTypicalVisible,
      currentDayType,
      trafficCurrentStartTime,
    } = this.props;

    //GRAPH - GET DATA FOR THE WHOLE DAY
    const graphStartDate = moment(currentTime).startOf("day").toISOString();
    const graphInterval = 1440;
    const graphMeasuresId = ["flow_cap"];

    const vehicleClassesIds = ["equivalent"];

    if (isNetStateVisible) {
      let { outputDate, outputContext } = getContextAndDateFromApiCall(
        trafficCurrentStartTime,
        currentOffSet,
        contexts,
        currentTime
      );

      const graphContextIds = contexts.find((item) => {
        return item.timeOffset === 0 || item.timeOffset === null;
      })
        ? [
            contexts.find((item) => {
              return item.timeOffset === 0 || item.timeOffset === null;
            }).contextId,
          ]
        : [];

      if (
        currentArc &&
        currentArc.properties &&
        currentArc.properties.archId &&
        outputContext
      ) {
        this.subscriptions$.push(
          this.apiService
            .postTrafficContextOrientedElaboratedByArch(
              currentArc.properties.archId,
              outputDate,
              [outputContext.contextId],
              vehicleClassesIds,
              null,
              true
            )
            .pipe(
              take(1),
              tap((data) => {
                this.setState({
                  ...this.state,
                  arcMeasures: data,
                });
              }),
              catchError((error) => {
                this.setState({
                  ...this.state,
                  arcMeasures: [],
                });
                console.error(error);
                return of(error);
              })
            )
            .subscribe(),
          this.apiService
            .getTrafficContextOrientedElaboratedByArch(
              currentArc.properties.archId,
              graphStartDate,
              graphInterval,
              [graphContextIds],
              vehicleClassesIds,
              graphMeasuresId,
              false
            )
            .pipe(
              take(1),
              tap((data) => {
                this.setState({
                  ...this.state,
                  arcGraphMeasures: data,
                });
              }),
              catchError((error) => {
                this.setState({
                  ...this.state,
                  arcGraphMeasures: [],
                });
                console.error(error);
                return of(error);
              })
            )
            .subscribe()
        );
      }
    } else if (isTypicalVisible) {
      this.subscriptions$.push(
        this.apiService
          .postTrafficProfileOrientedElaboratedByArch(
            currentArc.properties.archId,
            moment.utc(currentTime).format("HH:mm"),
            currentDayType.enum,
            ["equivalent"],
            null
          )
          .pipe(
            take(1),
            tap((data) => {
              this.setState({
                ...this.state,
                arcMeasures: data,
              });
            }),
            catchError((error) => {
              this.setState({
                ...this.state,
                arcMeasures: [],
              });
              console.error(error);
              return of(error);
            })
          )
          .subscribe(),
        this.apiService
          .getTrafficProfileOrientedElaboratedByArch(
            currentArc.properties.archId,
            "00:00",
            "23:59",
            currentDayType.enum,
            ["equivalent"],
            graphMeasuresId
          )
          .pipe(
            take(1),
            tap((data) => {
              this.setState({
                ...this.state,
                arcGraphMeasures: data,
              });
            }),
            catchError((error) => {
              this.setState({
                ...this.state,
                arcGraphMeasures: [],
              });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  header = () => {
    const {
      toggleArcDetails,
      currentArc,
      isOpenNavTab,
      isNetStateVisible,
    } = this.props;

    const name = currentArc.properties.archName;
    const direction = currentArc.properties.direction;

    const trafficMeasure =
      isNetStateVisible &&
      currentArc.properties.measures.find((item) => item.measureId === "los")
        ? currentArc.properties.measures.find(
            (item) => item.measureId === "los"
          ).measureValue
        : currentArc.properties.profile.find((item) => item.measureId === "los")
        ? currentArc.properties.profile.find((item) => item.measureId === "los")
            .measureValue
        : null;

    return (
      <ArcTrafficDetailHeader
        isOpenNavTab={isOpenNavTab}
        toggleArcDetails={toggleArcDetails}
        name={name}
        direction={direction}
        trafficMeasure={trafficMeasure}
      />
    );
  };

  tabs = () => {
    const { handleClickArcMeasures } = this.props;
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer "
            onClick={(event) => {
              event.preventDefault();
              handleClickArcMeasures();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Misure</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { isNetStateVisible } = this.props;
    return (
      <div className="uk-flex uk-flex-column contentBodyEventDetail uk-height-1-1">
        {isNetStateVisible
          ? this.renderStateArcMeasures()
          : this.renderTypicArcMeasures()}
        {this.renderArcGraph()}
      </div>
    );
  };

  renderTypicArcMeasures = () => {
    const { listTrafficMeasures, currentDayType } = this.props;
    const { arcMeasures } = this.state;

    let profileMeasuresArray = [];

    if (
      arcMeasures &&
      arcMeasures.archs &&
      arcMeasures.archs[0] &&
      arcMeasures.archs[0].profile[0] &&
      arcMeasures.archs[0].profile[0].measures
    ) {
      profileMeasuresArray = arcMeasures.archs[0].profile[0].measures;
    }

    return (
      <div className="uk-flex uk-flex-column">
        <li className="uk-flex uk-flex-row" key={0}>
          <div className="text-emphasis-color  uk-flex uk-flex-row uk-flex-middle">
            {currentDayType.nameIT}
          </div>
        </li>
        <li className="uk-flex uk-flex-row" key={1}>
          <div className="text-measure-type text-muted-color "></div>
          <div className="text-measure-context text-muted-color uk-flex uk-flex-row uk-flex-middle"></div>
          <div className="text-measure-profile  text-muted-color uk-flex uk-flex-row uk-flex-middle ">
            Profilo
          </div>
        </li>
        {profileMeasuresArray.map((profileMeasure, index) => {
          const { name, unitType } = getMeasurePropertiesById(
            listTrafficMeasures,
            profileMeasure.measureId
          );

          const meas = measureToShow(profileMeasure, unitType);

          return (
            <li className="uk-flex uk-flex-row" key={index + 2}>
              <div className="text-measure-type text-muted-color uk-flex uk-flex-row uk-flex-middle">
                {name +
                  (unitType && unitType !== "null"
                    ? " [" + unitType + "]"
                    : "")}
              </div>
              <div className="text-measure-context text-emphasis-color  uk-flex uk-flex-row uk-flex-middle"></div>
              <div className="text-measure-profile text-emphasis-color  uk-flex uk-flex-row uk-flex-middle">
                {meas}
              </div>
            </li>
          );
        })}
      </div>
    );
  };

  renderStateArcMeasures = () => {
    const { listTrafficMeasures, currentDayType } = this.props;
    const { arcMeasures } = this.state;

    let contextMeasuresArray = [];
    let profileMeasuresArray = [];
    if (
      arcMeasures &&
      arcMeasures.archs &&
      arcMeasures.archs[0] &&
      arcMeasures.archs[0].contexts[0] &&
      arcMeasures.archs[0].contexts[0].vehicleClasses[0] &&
      arcMeasures.archs[0].contexts[0].vehicleClasses[0].measures
    ) {
      contextMeasuresArray =
        arcMeasures.archs[0].contexts[0].vehicleClasses[0].measures;

      contextMeasuresArray =
        contextMeasuresArray &&
        contextMeasuresArray[0] &&
        contextMeasuresArray.map((obj) => JSON.stringify(obj));
      contextMeasuresArray = [...new Set(contextMeasuresArray)].map((u) =>
        JSON.parse(u)
      );
    }

    if (
      arcMeasures &&
      arcMeasures.archs &&
      arcMeasures.archs[0] &&
      arcMeasures.archs[0].profile[0] &&
      arcMeasures.archs[0].profile[0].measures
    ) {
      profileMeasuresArray = arcMeasures.archs[0].profile[0].measures;
      profileMeasuresArray =
        profileMeasuresArray &&
        profileMeasuresArray[0] &&
        profileMeasuresArray.map((obj) => JSON.stringify(obj));
      profileMeasuresArray = [...new Set(profileMeasuresArray)].map((u) =>
        JSON.parse(u)
      );
    }

    return (
      <div className="uk-flex uk-flex-column">
        <li className="uk-flex uk-flex-row" key={0}>
          <div className="text-emphasis-color  uk-flex uk-flex-row uk-flex-middle">
            {currentDayType.nameIT}
          </div>
        </li>
        <li className="uk-flex uk-flex-row" key={1}>
          <div className="text-measure-type text-muted-color uk-flex uk-flex-row uk-flex-middle"></div>
          <div className="text-measure-context text-muted-color uk-flex uk-flex-row uk-flex-middle">
            Stato Rete
          </div>
          <div className="text-measure-profile  text-muted-color uk-flex uk-flex-row uk-flex-middle ">
            Profilo
          </div>
        </li>
        {contextMeasuresArray.map((contextMeasure, index) => {
          const { name, unitType } = getMeasurePropertiesById(
            listTrafficMeasures,
            contextMeasure.measureId
          );

          const measContext = measureToShow(contextMeasure, unitType);
          let profileMeasure = profileMeasuresArray.find(
            (profileMeasure) =>
              profileMeasure.measureId === contextMeasure.measureId
          );
          const measProfile = measureToShow(profileMeasure, unitType);

          return (
            <li className="uk-flex uk-flex-row" key={index + 2}>
              <div className="text-measure-type text-muted-color uk-flex uk-flex-row uk-flex-middle">
                {name +
                  (unitType && unitType !== "null"
                    ? " [" + unitType + "]"
                    : "")}
              </div>
              <div className="text-measure-context text-emphasis-color  uk-flex uk-flex-row uk-flex-middle">
                {measContext}
              </div>
              <div className="text-measure-profile text-emphasis-color  uk-flex uk-flex-row uk-flex-middle">
                {measProfile}
              </div>
            </li>
          );
        })}
      </div>
    );
  };

  renderArcGraph = () => {
    const { currentMeasure, currentTime, isNetStateVisible } = this.props;
    const { arcGraphMeasures } = this.state;

    let data = [];
    if (isNetStateVisible) {
      data = getLineChartDataFromMeasuredState(arcGraphMeasures);
    } else {
      data = getLineChartDataFromMeasuredTypic(arcGraphMeasures);
    }
    return (
      <div
        id="trafficDetailGraph"
        className="uk-flex uk-flex-column uk-flex-middle height-chart width-chart"
      >
        <div className="graph-title uk-width-1-1 uk-flex uk-flex-row uk-flex-right">
          {moment(currentTime).format("DD/MM/YYYY HH:mm")}
        </div>
        <LineChartTrafficMeasures data={data} defaultMeasure={currentMeasure} />
      </div>
    );
  };

  render() {
    return (
      <div className="inherit-transition">
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }

  componentWillUnmount = () => {
    this.subscriptions$.forEach((sub$) => sub$.unsubscribe());
  };
}

ArcTrafficMeasures.contextType = EnvironmentContext;

const mapStateToProps = (state) => ({
  isOpenNavTab: isOpenNavTab(state),
  currentContext: getTrafficCurrentContext(state),
  currentTime: getTrafficCurrentTime(state),
  currentMeasure: getTrafficCurrentMeasure(state),
  listTrafficMeasures: getTrafficMeasures(state),
  currentDayType: getTrafficCurrentDayType(state),
  currentOffSet: getCurrentTimeOffSet(state),
  contexts: getTrafficContexts(state),
  trafficCurrentStartTime: getTrafficCurrentStartTime(state),
  //CHECKBOX
  allTrafficVisible: areAllTrafficVisible(state),
  isNetStateVisible: isNetStateVisible(state),
  isTypicalVisible: isTypicalVisible(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArcTrafficMeasures)
);
