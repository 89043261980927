import React, { Component } from "react";
import "./style.less";
import * as moment from "moment";
import { SOURCE_TYPE } from "utils/utils";
import { getIconEvent } from "utils/utils_event_icon";
import { getSelectModal } from "store";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getTypeTranslation, getSubtypeTranslation } from "utils/util-events";

class EventTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    this.getWidthHeight();
  }

  getWidthHeight = () => {
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ ...this.state, height: height, width: width });
  };

  render() {
    const { x, y, object, selectModal } = this.props;
    const { height, width } = this.state;

    let imageEvent = getIconEvent(object, selectModal);
    return (
      <div
        className="show-tooltip"
        style={{ left: x - width / 2, top: y - (height + 20) }}
        ref={(divElement) => {
          this.divElement = divElement;
        }}
      >
        <div className="uk-flex uk-flex-column uk-margin-small-top">
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              alt=""
              style={{ width: "40px", height: "40px" }}
              src={imageEvent}
            />
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
            <div className="tooltip-text-large">Tipo: </div>
            <div className="tooltip-text-important">
              {getTypeTranslation(object, selectModal)}
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
            <div className="tooltip-text-large">Sottotipo: </div>
            <div className="tooltip-text-important subType">
              {getSubtypeTranslation(object, selectModal)}
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
            <div className="tooltip-text-large">Inizio: </div>
            <div className="tooltip-text-important">
              {object.validity &&
              object.validity.validityTimeSpecification &&
              object.validity.validityTimeSpecification.overallStartTime
                ? moment(
                    object.validity.validityTimeSpecification.overallStartTime,
                    "DD/MM/YYYY"
                  ).isValid()
                  ? moment(
                      object.validity.validityTimeSpecification
                        .overallStartTime,
                      "DD/MM/YYYY"
                    ).format(
                      "DD/MM/YY"
                      // "DD/MM/YY - HH:mm"
                    )
                  : object.validity.validityTimeSpecification.overallStartTime.includes(
                      "T"
                    )
                  ? moment(
                      object.validity.validityTimeSpecification.overallStartTime
                    ).format("DD/MM/YY - HH:mm")
                  : moment(
                      object.validity.validityTimeSpecification
                        .overallStartTime,
                      "MM/DD/YYYY"
                    ).format(
                      "DD/MM/YY"
                      // "DD/MM/YY - HH:mm"
                    )
                : ""}
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
            <div className="tooltip-text-large">Fine: </div>
            <div className="tooltip-text-important">
              {object.validity &&
              object.validity.validityTimeSpecification &&
              object.validity.validityTimeSpecification.overallEndTime
                ? moment(
                    object.validity.validityTimeSpecification.overallEndTime,
                    "DD/MM/YYYY"
                  ).isValid()
                  ? moment(
                      object.validity.validityTimeSpecification.overallEndTime,
                      "DD/MM/YYYY"
                    ).format(
                      "DD/MM/YY"
                      // "DD/MM/YY - HH:mm"
                    )
                  : object.validity.validityTimeSpecification.overallEndTime.includes(
                      "T"
                    )
                  ? moment(
                      object.validity.validityTimeSpecification.overallEndTime
                    ).format("DD/MM/YY - HH:mm")
                  : moment(
                      object.validity.validityTimeSpecification.overallEndTime,
                      "MM/DD/YYYY"
                    ).format(
                      "DD/MM/YY"
                      // "DD/MM/YY - HH:mm"
                    )
                : ""}
            </div>
          </div>
          {/* <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
            <div className="tooltip-text-large">Gravità:</div>
            <div className="tooltip-text-important"> {object.severity}</div>
          </div> */}
          <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
            <div className="tooltip-text-large">Fonte: </div>
            <div className="tooltip-text-important">
              {object && object.source && object.source.sourceReference
                ? SOURCE_TYPE[object.source.sourceReference]
                : "-"}{" "}
              {object &&
              object.source &&
              object.source.sourceName &&
              object.source.sourceName.content
                ? " (" +
                  object.source.sourceName.content +
                  ") " +
                  object.source.sourceIdentification
                : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  selectModal: getSelectModal(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventTooltip)
);
