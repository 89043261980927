import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { take, tap, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import * as moment from "moment";
import "../style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap, debounceTime } from "rxjs/operators";

//COMPONENTS
import Loading from "components/shared/loading/loading";
import TableHead from "components/shared/table-components/table-head";
import TableBody from "components/shared/table-components/table-body";
import TableFilters from "components/shared/table-top-filters/table-filters";
import PageNumbers from "components/shared/page-numbers/page-number";
//STORE
import {
  isOpenNavTab,
  getSavedTableColumns,
  getSavedDateColumns,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedSearchText,
  getSavedFilters,
  getSavedToggledFilters,
  getUserActivity,
  getUsername,
  getUserRoles,
} from "store";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import { setActivityList, addActivity } from "reducers/user/user.actions";
// MODEL
import { UserActivityModel } from "reducers/user/user.model";
//UTILS
import {
  TABLE_COLUMNS,
  DATE_COLUMNS,
  initColumns,
} from "utils/table-utils/util-user-activity-table";
import * as FilterUtils from "utils/filter-utils";
import { debounce } from "utils/utils";
import {
  getFilterForUserActivitytable,
  getActivityTypeTranslation,
  getModuleTranslation,
  showActivityDetails,
  showRoles,
} from "utils/util-user-activity";
import { saveBlob } from "utils/hooks";
import {
  handleFilterStringChange,
  handleClickIncreasePage,
  handleClickDecreasePage,
  requestSortOrder,
  resetChildrenForHeaderDropdown,
  onDropdownCheckboxFilterClick,
  getCurrentPageNumber,
  resetFiltersState,
  initializeTableState,
  getTableStateSortConfig,
  changeDateFilter,
  setDateFilter,
} from "utils/table-utils/util-general-tables";

const FILTER_TARGET = "userActivityFilters";

class UserActivityTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      currentListActivities: [],
      stateTableColumns: TABLE_COLUMNS,
      sortConfig: getTableStateSortConfig("user"),
      ...initializeTableState,
    };
    this.getActivityListDebounced = debounce(this.getActivityList, 800);
    this.numberPage$ = new BehaviorSubject();
  }

  componentDidMount = () => {
    const { searchText } = this.props;

    this.apiService = createApiService(this.context);
    this.subscriptions.push(
      this.numberPage$
        .pipe(
          debounceTime(800),
          switchMap(
            (x) =>
              new Observable((observer) => {
                const { pageToDisplay } = this.state;
                if (pageToDisplay !== "") {
                  this.getActivityList();
                }
              })
          )
        )
        .subscribe()
    );

    if (searchText) {
      this.setState({
        ...this.state,
        filterStr: searchText,
        isActivityTableLoading: true,
      });
    } else {
      this.setState({
        ...this.state,
        isActivityTableLoading: true,
      });
    }

    this.tableConfigs();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { tableFilters } = this.props;
    if (
      (tableFilters.dateFrom &&
        tableFilters.dateFrom !== prevProps.tableFilters.dateFrom) ||
      (tableFilters.dateTo &&
        tableFilters.dateTo !== prevProps.tableFilters.dateTo)
    ) {
      this.getActivityList();
    }
  };

  tableConfigs = () => {
    const {
      tableColumns,
      dateColumns,
      saveTableFilters,
      searchText,
      dateFrom,
      dateTo,
    } = this.props;

    const { columns, date } = initColumns(tableColumns, dateColumns);

    let sld = FilterUtils.constructFlatMultiSelect(columns);
    let obj = {};
    sld.forEach((x) => {
      obj[x.name] = { ...x };
    });

    if (!tableColumns) {
      this.getUserList();
    }
    saveTableFilters(
      {
        tableColumns: columns,
        dateColumns: date,
        dateFrom: dateFrom ? dateFrom : null,
        dateTo: dateTo ? dateTo : null,
        searchText: searchText || "",
        toggledFilters: obj || {},
      },
      FILTER_TARGET
    );
    this.setState({
      selected: sld,
      searchText: searchText || "",
    });
  };

  getUserList = () => {
    const { stateTableColumns } = this.state;
    const { saveTableFilters, tableFilters } = this.props;
    this.setState({
      ...this.state,
      isActivityTableLoading: true,
    });
    this.subscriptions.push(
      this.apiService
        .getUsersList()
        .pipe(
          take(1),
          tap((data) => {
            let listOfUsers = [...data];
            let updatedTableColumns = {
              ...stateTableColumns,
              usernames: {
                ...stateTableColumns.usernames,
                childrenForHeaderDropdown: [
                  {
                    label: "Seleziona tutto",
                    checked: true,
                    id: "all-usernames",
                    isChild: true,
                    name: "Seleziona tutto",
                  },
                  ...listOfUsers.map((item) => {
                    return {
                      label: item.username,
                      checked: true,
                      id: item.username,
                      isChild: true,
                      name: item.username,
                    };
                  }),
                ],
              },
            };
            saveTableFilters(
              {
                ...tableFilters,
                tableColumns: updatedTableColumns,
                dateColumns: [...DATE_COLUMNS],
              },
              FILTER_TARGET
            );

            this.setState({
              ...this.state,
              stateTableColumns: updatedTableColumns,
            });
          }),
          concatMap(() => {
            return this.getActivityListObservable();
          }),
          catchError((error) => {
            this.setState({
              ...this.state,
              isActivityTableLoading: false,
            });
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  getActivityList = () => {
    const { tableFilters, setActivityList } = this.props;
    const { count, sortConfig, currentPage, filterStr } = this.state;

    const {
      modules,
      usernames,
      // roles,
      activityTypes,
      orderBy,
      orderType,
    } = getFilterForUserActivitytable(tableFilters.tableColumns, sortConfig);

    let dropdownUsername = [];

    tableFilters.tableColumns.usernames.childrenForHeaderDropdown.map(
      (item) => {
        if (item.label !== "Seleziona tutto" && item.checked) {
          dropdownUsername.push(item.label);
        }
      }
    );

    this.subscriptions.push(
      this.apiService
        .getUserActivity(
          count,
          currentPage,
          tableFilters.dateFrom ? tableFilters.dateFrom.toISOString() : null,
          tableFilters.dateTo ? tableFilters.dateTo.toISOString() : null,
          ["CREATION_TIME"],
          modules,
          dropdownUsername,
          null,
          null,
          null,
          activityTypes,
          null,
          null,
          filterStr,
          null,
          orderBy,
          orderType
        )
        .pipe(
          take(1),
          tap((data) => {
            let activities = UserActivityModel.fromArrayREST(
              data && data.pageHits ? data.pageHits : []
            );

            setActivityList(activities);
            let pageMax = Math.ceil(data.totalHits / count);

            this.setState({
              ...this.state,
              isActivityTableLoading: false,
              currentListActivities: activities,
              pageMax: pageMax,
            });
          }),
          catchError((error) => {
            setActivityList([]);
            console.error(error);
            this.setState({
              ...this.state,
              isActivityTableLoading: false,
              currentListActivities: [],
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  getActivityListObservable = () => {
    const { tableFilters, setActivityList } = this.props;
    const { count, sortConfig, currentPage, filterStr } = this.state;

    const {
      modules,
      usernames,
      // roles,
      activityTypes,
      orderBy,
      orderType,
    } = getFilterForUserActivitytable(tableFilters.tableColumns, sortConfig);

    let dropdownUsername = [];

    tableFilters.tableColumns.usernames.childrenForHeaderDropdown.map(
      (item) => {
        if (item.label !== "Seleziona tutto" && item.checked) {
          dropdownUsername.push(item.label);
        }
      }
    );

    return this.apiService
      .getUserActivity(
        count,
        currentPage,
        tableFilters.dateFrom ? tableFilters.dateFrom.toISOString() : null,
        tableFilters.dateTo ? tableFilters.dateTo.toISOString() : null,
        ["CREATION_TIME"],
        modules,
        dropdownUsername,
        null,
        null,
        null,
        activityTypes,
        null,
        null,
        filterStr,
        null,
        orderBy,
        orderType
      )
      .pipe(
        take(1),
        tap((data) => {
          let activities = UserActivityModel.fromArrayREST(
            data && data.pageHits ? data.pageHits : []
          );

          setActivityList(activities);
          let pageMax = Math.ceil(data.totalHits / count);

          this.setState({
            ...this.state,
            isActivityTableLoading: false,
            currentListActivities: activities,
            pageMax: pageMax,
          });
        }),
        catchError((error) => {
          setActivityList([]);
          console.error(error);
          this.setState({
            ...this.state,
            isActivityTableLoading: false,
            currentListActivities: [],
          });
          return of(error);
        })
      );
  };

  //CALL API TO OBTAIN NEW ARC LIST
  refreshData = () => {
    this.setState({
      ...this.state,
      currentListActivities: [],
      isActivityTableLoading: true,
    });
    this.getActivityList();
  };

  handleFilterChange = (e) => {
    handleFilterStringChange(
      this,
      e,
      FILTER_TARGET,
      this.getActivityListDebounced
    );
  };

  // GESTIONE CHECKBOX: VISUALIZZAZIONE COLONNE
  handleSelectChange = (visibleColumns) => {
    FilterUtils.handleSelectChange(this, visibleColumns, FILTER_TARGET);
  };

  //GESTIONE CHECKBOX FILTRI SU COLONNE
  onCheckboxFilterClick = (newTableColumns) => {
    this.setState({
      ...this.state,
      isArcsTableLoading: true,
    });
    onDropdownCheckboxFilterClick(
      this,
      this.getActivityListDebounced,
      FILTER_TARGET,
      newTableColumns
    );
  };

  getPageNumber = (pageNumber) => {
    getCurrentPageNumber(this, pageNumber);
  };

  handleClickIncrease = () => {
    handleClickIncreasePage(this);
  };

  handleClickDecrease = () => {
    handleClickDecreasePage(this);
  };

  requestSort = (key) => {
    requestSortOrder(this, key, this.getActivityList);
  };

  onChangeDate = (value, name) => {
    changeDateFilter(this, value, name, FILTER_TARGET);
  };

  resetFilters = (e) => {
    const { resetTableFilters, toggledFilters, tableFilters } = this.props;
    const { selected } = this.state;

    e.stopPropagation();

    let newStateTableColumns = resetChildrenForHeaderDropdown(
      tableFilters.tableColumns
    );

    resetTableFilters(
      {
        tableColumns: { ...newStateTableColumns },
        dateColumns: [...DATE_COLUMNS],
        toggledFilters: toggledFilters,
      },
      FILTER_TARGET
    );

    resetFiltersState(
      this,
      newStateTableColumns,
      selected,
      this.getActivityList,
      "user"
    );
  };

  handleResetDateFilterTarget = () => {
    const { saveTableFilters, tableFilters, dateColumns } = this.props;
    let selected = [...dateColumns].map((element) => {
      //keep only selectable date param selected
      if (element.id !== "datetimeAction") {
        element.selected = false;
      }
      return element;
    });

    saveTableFilters(
      {
        ...tableFilters,
        dateTo: null,
        dateFrom: null,
        dateColumns: [...selected],
      },
      FILTER_TARGET
    );

    this.getActivityList();
  };

  handleSetDateFilterTarget = (date) => {
    setDateFilter(this, date, FILTER_TARGET);
  };

  downloadExcel = () => {
    const { tableFilters } = this.props;
    const {
      count,
      sortConfig,
      filterStr,
      stateTableColumns,
      currentPage,
    } = this.state;

    const {
      modules,
      usernames,
      // roles,
      activityTypes,
      orderBy,
      orderType,
    } = getFilterForUserActivitytable(stateTableColumns, sortConfig);

    this.apiService
      .fetchUserActivityExcel(
        count,
        currentPage,
        ["CREATION_TIME"],
        tableFilters.dateFrom
          ? moment(tableFilters.dateFrom).toISOString()
          : null,
        tableFilters.dateTo ? moment(tableFilters.dateTo).toISOString() : null,
        modules,
        usernames,
        null,
        null,
        null,
        activityTypes,
        null,
        null,
        filterStr,
        null,
        orderBy,
        orderType
      )
      .pipe(
        tap((blob) => {
          let filename =
            "user_activity_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postActivityDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postActivityDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "OTHER",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Resoconto Attività Utente"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  toTableColumns = (activities) => {
    let newList = [];
    activities.forEach((act) => {
      let newEl = {};
      if (act) {
        newEl = {
          usernames: act.user ? act.user.username : "",
          modules: getModuleTranslation(act.module),
          datetimeAction: act.activityCreationTime
            ? moment
                .utc(act.activityCreationTime)
                .local()
                .format("DD/MM/YY - HH:mm")
            : "-",
          activityTypes: getActivityTypeTranslation(act.activityType),
          details:
            act.details && act.details.values
              ? showActivityDetails(act.details.values)
              : "Nessun dettaglio disponibile",
        };
      }

      newList = [...newList, newEl];
    });

    return newList;
  };

  render = () => {
    const {
      sortConfig,
      filterStr,
      selected,
      isActivityTableLoading,
      pageMax,
      pageToDisplay,
    } = this.state;
    const {
      toggleGenericTable,
      isOpenNav,
      dateColumns,
      tableColumns,
      tableFilters,
      userActivities,
    } = this.props;

    let listOfActivites = [];
    if (userActivities) {
      //MAPPA PER VISUALIZZAZIONE SU TABELLA
      listOfActivites = this.toTableColumns(userActivities);
    }

    return (
      <div className="acr-table uk-flex uk-flex-column">
        <div className="navbar-placeholder"></div>
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <div className="grey-space"></div>
          <div className=" uk-width-1-2">
            <ul className=" uk-tab-page-secondary uk-tab uk-margin-remove">
              <li className="uk-active bottombar-title">REPORT ATTIVITÀ</li>
            </ul>
          </div>
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={() => toggleGenericTable("userActivityTable")}
            ></span>
          </div>
        </div>
        <TableFilters
          isOpenNav={isOpenNav}
          dateStart={{ date: tableFilters.dateFrom, name: "dateStartEvent" }}
          dateEnd={{ date: tableFilters.dateTo, name: "dateEndEvent" }}
          changeDate={this.onChangeDate}
          handleFilterChange={this.handleFilterChange}
          handleSelectChange={this.handleSelectChange}
          handleResetDateFilterTarget={this.handleResetDateFilterTarget}
          handleSetDateFilterTarget={this.handleSetDateFilterTarget}
          resetFilters={this.resetFilters}
          filterStr={filterStr}
          filtrableDateColumns={dateColumns}
          selected={selected}
          refreshData={this.refreshData}
          disabledDataFilter={false}
        ></TableFilters>
        <div className="uk-flex uk-flex-right button-bar">
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.refreshData}
            >
              <span
                uk-icon="icon: refresh"
                uk-tooltip="Refresh tabella Attività Utente"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              disabled={false}
              onClick={this.downloadExcel}
            >
              <span
                uk-icon="icon: download"
                uk-tooltip="Download Excel"
                className="filterBarIcons borderWhite"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
              onClick={this.resetFilters}
            >
              <span
                uk-icon="icon: acr-filter-remove"
                uk-tooltip="Cancella Filtri"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-flex uk-flex-right button-bar uk-padding-remove-left">
            <PageNumbers
              handleClickDecrease={this.handleClickDecrease}
              handleClickIncrease={this.handleClickIncrease}
              getPageNumber={this.getPageNumber}
              currentPage={pageToDisplay}
              minVal={1}
              pageMax={pageMax}
            />
          </div>
        </div>
        <div className="uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left ">
          <table
            id="eventTable"
            className="uk-table uk-table-divider uk-table-hover"
          >
            <thead>
              <TableHead
                onCheckboxFilterClick={this.onCheckboxFilterClick}
                requestSort={this.requestSort}
                tableColumns={tableColumns}
                sortConfig={sortConfig}
              />
            </thead>
            <tbody>
              {isActivityTableLoading ? (
                <tr>
                  <td>
                    <Loading />
                  </td>
                </tr>
              ) : (
                <TableBody list={listOfActivites} tableColumns={tableColumns} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

UserActivityTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleGenericTable,
  saveTableFilters,
  resetTableFilters,
  setActivityList,
  addActivity,
};

const mapStateToProps = (state) => ({
  isOpenNav: isOpenNavTab(state),
  tableColumns: getSavedTableColumns(state, FILTER_TARGET),
  dateColumns: getSavedDateColumns(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
  searchText: getSavedSearchText(state, FILTER_TARGET),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  toggledFilters: getSavedToggledFilters(state, FILTER_TARGET),
  userActivities: getUserActivity(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserActivityTable)
);
