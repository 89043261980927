export const TABLE_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: true,
    order: true,
  },
  id: {
    name: "ID Arco",
    id: "id",
    show: true,
    order: true,
  },
  name: {
    name: "Nome",
    id: "name",
    show: true,
    order: true,
  },
  a: {
    name: "Id Nodo Origine",
    id: "a",
    show: true,
    order: true,
  },
  b: {
    name: "Id Nodo Destinazione",
    id: "b",
    show: true,
    order: true,
  },
  lanes: {
    name: "Corsie",
    id: "lanes",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-corsie",
        isChild: true,
        name: "Seleziona tutto",
      },
      { label: "0", checked: true, id: "0", isChild: true, name: 0 },
      { label: "1", checked: true, id: "1", isChild: true, name: 1 },
      { label: "2", checked: true, id: "2", isChild: true, name: 2 },
      { label: "3", checked: true, id: "3", isChild: true, name: 3 },
      { label: "4", checked: true, id: "4", isChild: true, name: 4 },
      { label: "5", checked: true, id: "5", isChild: true, name: 5 },
      { label: "6", checked: true, id: "6", isChild: true, name: 6 },
    ],
    show: true,
    order: true,
  },
  capacity: {
    name: "Capacità",
    id: "capacity",
    show: true,
    order: true,
  },
  frc: {
    name: "Classe",
    id: "frc",
    show: true,
    order: true,
  },
  distance: {
    name: "Lunghezza",
    id: "distance",
    show: true,
    order: true,
  },
  area_name: {
    name: "Area Amministrativa",
    id: "area_name",
    show: true,
    order: true,
  },
  speed: {
    name: "Velocità massima",
    id: "speed",
    show: true,
    order: true,
  },
  fft: {
    name: "Tempo di Viaggio",
    id: "fft",
    show: true,
    order: true,
  },
  bridge: {
    name: "Ponte",
    id: "bridge",
    show: true,
    order: false,
  },
  tunnel: {
    name: "Tunnel",
    id: "tunnel",
    show: true,
    order: false,
  },
  tolling: {
    name: "Pedaggio",
    id: "tolling",
    show: true,
    order: false,
  },
  ztl: {
    name: "ZTL",
    id: "ztl",
    show: true,
    order: false,
  },
  coeff1: {
    name: "Coeff1",
    id: "coeff1",
    show: true,
    order: true,
  },
  coeff2: {
    name: "Coeff2",
    id: "coeff2",
    show: true,
    order: true,
  },
};

export const DATE_COLUMNS = [];

export const initColumns = (tableColumns = null, dateColumns = null) => {
  let columns = {};
  let date = [];
  if (
    !tableColumns ||
    (tableColumns &&
      Object.keys(TABLE_COLUMNS)[1] !== Object.keys(tableColumns)[1])
  ) {
    columns = { ...TABLE_COLUMNS };
    date = [...DATE_COLUMNS];
  } else {
    columns = { ...tableColumns };
    date = dateColumns ? [...dateColumns] : [];
  }

  return { columns: columns, date: date };
};
