import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import { of } from "rxjs";
import { tap, take, catchError, concatMap } from "rxjs/operators";
import * as moment from "moment";

//ACTIONS, STORE
import {
  getBoundingBox,
  getCurrentZoom,
  isNetStateVisible,
  areAllTrafficVisible,
  getTrafficCurrentContext,
  getCurrentTimeOffSet,
  getTrafficContexts,
  isWSStationNotificationOpen,
  getWsLastNotification,
  getTrafficCurrentTime,
  isTypicalVisible,
  getTrafficCurrentDayType,
  getTrafficCurrentStartTime,
} from "store";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import createApiService from "services/api.service";
import {
  setTrafficArcsList,
  setTrafficCurrentArc,
  setTrafficCurrentArcMap,
  setTrafficContexts,
  setDayTypes,
  setMeasures,
  setVehicleClasses,
  setCurrentTrafficTime,
  setTrafficCurrentContext,
  setCurrentDayType,
  setCurrentDay,
  setCurrentVehicleClass,
  setCurrentMeasure,
  setCurrentTrafficStartTime,
  setCurrentTrafficEndTime,
  setCurrentContextTimeOffSet,
} from "reducers/traffic/traffic.actions";
import {
  setIsTrafficLoading,
  toggleOpenTrafficTimeSlider,
} from "reducers/ui/traffic-menu/traffic-menu.actions";
import { wsOpen } from "reducers/ui/ws/ws.actions";
import {
  updateTrafficNotificationsWS,
  resetTrafficNotificationsWS,
} from "reducers/traffic/traffic.actions";
//models
import { TrafficContext, Measures } from "reducers/traffic/traffic.model";
import {
  DAY_TYPE_EXTENDED,
  TrafficArcsFeature,
} from "reducers/traffic/traffic.model";
//UTIL
import {
  getBoundingBoxStringForCqlFilter,
  getArcFilterByBboxAndFrcParam,
} from "components/pages/load.data/map.utils";
import {
  widgetContestsToShow,
  getContextAndDateFromApiCall,
} from "utils/utils-traffic";

class LoadTrafficData extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.apiService = createApiService(this.context);

    const nowDate = this.initialReducerConfig();
    //CONFIG WS
    this.configWS();

    //API CALLS TO CONFIG TRAFFIC
    this.configTraffic(nowDate);
  };

  initialReducerConfig = () => {
    const {
      setCurrentTrafficTime,
      setCurrentDay,
      setCurrentContextTimeOffSet,
      setCurrentTrafficStartTime,
    } = this.props;

    const nowDate = new Date();
    const nowMoment = moment(nowDate);

    //FROM CONFIG FILE
    const minutesOffset = this.context.minutesOffsetForCurrentTraffic;

    //ROUND TO QUARTER OF HOUR
    const roundedDownMinute = Math.floor(nowMoment.minute() / 15) * 15;
    const roundedDownMoment = nowMoment.clone();
    roundedDownMoment.minute(roundedDownMinute).second(0).millisecond(0);

    //IF THE DIFFERNECE BETWEEN NOW AND START OF QUARTER IS TOO SMALL, TAKE PREVIOUS QUARTER
    const duration = moment.duration(nowMoment.diff(roundedDownMoment));
    const minutes = duration.asMinutes();

    if (minutes >= minutesOffset) {
      setCurrentTrafficTime(roundedDownMoment.toDate());
      setCurrentTrafficStartTime(roundedDownMoment.toDate());
    } else {
      const newSubtractedMoment = nowMoment.clone();
      newSubtractedMoment.subtract(minutesOffset, "minutes");
      const newRoundedDownMinute =
        Math.floor(newSubtractedMoment.minute() / 15) * 15;
      const newRoundedDownMoment = nowMoment.clone();
      newRoundedDownMoment
        .hour(newSubtractedMoment.hour())
        .minute(newRoundedDownMinute)
        .second(0)
        .millisecond(0);
      setCurrentTrafficTime(newRoundedDownMoment.toDate());
      setCurrentTrafficStartTime(newRoundedDownMoment.toDate());
    }

    const today = nowMoment.format("DD/MM/YYYY");
    setCurrentDay(today);

    setCurrentContextTimeOffSet(0);

    return nowDate;
  };

  configTraffic = (nowDate) => {
    const {
      setTrafficContexts,
      // setDayTypes,
      setMeasures,
      setVehicleClasses,
      setTrafficArcsList,
      setTrafficCurrentContext,
      setCurrentDayType,
      setCurrentMeasure,
      boundingBox,
      currentMapZoom,
      isNetStateVisible,
      isTypicalVisible,
      setIsTrafficLoading,
    } = this.props;

    let newBounds = getBoundingBoxStringForCqlFilter(boundingBox);
    let stringFilterByFrc = getArcFilterByBboxAndFrcParam(
      currentMapZoom,
      newBounds
    );
    let defaultContext = null;
    let defaultContextForApiCall = null;

    setIsTrafficLoading(true);

    this.subscriptions$.push(
      this.apiService
        .getTrafficVechicleClasses()
        .pipe(
          take(1),
          tap((data) => {
            setVehicleClasses(data);
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe(),
      this.apiService
        .getTrafficMeasures()
        .pipe(
          take(1),
          tap((data) => {
            setMeasures(Measures.fromArrayREST(data));
            let current = data.find((item) => item.measureId === "los");
            setCurrentMeasure(current);
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe(),
      this.apiService
        .getTrafficSpecificDayType(moment(new Date()).format("YYYY-MM-DD"))
        .pipe(
          take(1),
          tap((data) => {
            setCurrentDayType(DAY_TYPE_EXTENDED[data.type]);
          }),
          concatMap((data) => {
            if (isTypicalVisible) {
              return this.apiService
                .getTrafficArchesOrientedElaboratedDataProfile(
                  moment.utc(nowDate).format("HH:mm"),
                  stringFilterByFrc,
                  DAY_TYPE_EXTENDED[data.type].enum,
                  ["equivalent"],
                  ["los"]
                )
                .pipe(
                  take(1),
                  tap((arc) => {
                    let trafficArcsFeatures = TrafficArcsFeature.fromArrayREST(
                      arc.features
                    );
                    setTrafficArcsList(trafficArcsFeatures);
                    setIsTrafficLoading(false);
                  }),
                  catchError((error) => {
                    setTrafficArcsList([]);
                    setIsTrafficLoading(false);
                    console.error(error);
                    return of(error);
                  })
                );
            } else {
              return of([]);
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe(),
      this.apiService
        .getTrafficContexts()
        .pipe(
          take(1),
          tap((data) => {
            setTrafficContexts(TrafficContext.fromArrayREST(data));
            if (isNetStateVisible) {
              defaultContext = widgetContestsToShow.find(
                (item) => item.key === "ACTUAL"
              );
            } else if (isTypicalVisible) {
              defaultContext = widgetContestsToShow.find(
                (item) => item.key === "TYPICAL"
              );
            }
            setTrafficCurrentContext(defaultContext);

            defaultContextForApiCall = data.find((item) => {
              return item.timeOffset === 0 || item.timeOffset === null;
            });
          }),
          concatMap((data) => {
            if (isNetStateVisible && defaultContextForApiCall) {
              return this.apiService
                .getTrafficArchesOrientedElaboratedDataByContext(
                  defaultContextForApiCall.contextId,
                  nowDate.toISOString(),
                  stringFilterByFrc,
                  ["equivalent"],
                  ["los"],
                  true
                )
                .pipe(
                  take(1),
                  tap((arcs) => {
                    let trafficArcsFeatures = TrafficArcsFeature.fromArrayREST(
                      arcs.features
                    );
                    setTrafficArcsList(trafficArcsFeatures);
                    setIsTrafficLoading(false);
                  }),
                  catchError((error) => {
                    setTrafficArcsList([]);
                    setIsTrafficLoading(false);
                    console.error(error);
                    return of(error);
                  })
                );
            } else {
              return of([]);
            }
          }),
          catchError((error) => {
            setTrafficContexts(TrafficContext.fromArrayREST([]));
            console.error(error);
            setIsTrafficLoading(false);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  configWS = () => {
    const { isWSStationNotificationOpen } = this.props;
    if (!isWSStationNotificationOpen) {
      this.wsNotificationOpen();
    }
  };

  wsNotificationOpen = () => {
    this.apiService.getTrafficNotificationRuntime(
      this.trafficNotificationMessage,
      this.trafficNotificationErr,
      this.trafficNotificationOpen
    );
  };

  trafficNotificationOpen = (e) => {
    const { wsOpen, isWSStationNotificationOpen } = this.props;
    if (!isWSStationNotificationOpen) wsOpen("stationNotification", true);
  };

  trafficNotificationErr = (e) => {
    const { wsOpen, isWSStationNotificationOpen } = this.props;
    this.apiService.closeTrafficNotificationRuntime();
    if (isWSStationNotificationOpen) wsOpen("stationNotification", false);
  };

  trafficNotificationMessage = (e) => {
    const { updateTrafficNotificationsWS } = this.props;
    if (e) {
      let trafficNotification = JSON.parse(e.data);
      if (
        trafficNotification &&
        (!trafficNotification.status || trafficNotification.status !== "alive")
      ) {
        if (trafficNotification) {
          updateTrafficNotificationsWS([trafficNotification]);
        }
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      //UI
      isNetStateVisible,
      isTypicalVisible,
      allTrafficVisible,
      //REDUCER
      boundingBox,
      currentMapZoom,
      currentOffSet,
      contexts,
      currentTime,
      currentDayType,
      setTrafficCurrentContext,
      setCurrentDayType,
      trafficCurrentStartTime,
      setTrafficArcsList,
      setCurrentTrafficTime,
      setIsTrafficLoading,
      //WS
      resetTrafficNotificationsWS,
      wsLastNotification,
      setCurrentTrafficStartTime,
    } = this.props;

    if (prevProps.allTrafficVisible !== allTrafficVisible) {
      this.initialReducerConfig();
    }

    if (prevProps.isTypicalVisible !== isTypicalVisible && isTypicalVisible) {
      setIsTrafficLoading(true);

      const typicalContext = widgetContestsToShow.find(
        (item) => item.key === "TYPICAL"
      );
      setTrafficCurrentContext(typicalContext);

      let newBounds = getBoundingBoxStringForCqlFilter(boundingBox);
      let stringFilterByFrc = getArcFilterByBboxAndFrcParam(
        currentMapZoom,
        newBounds
      );

      this.subscriptions$.push(
        this.apiService
          .getTrafficSpecificDayType(moment(currentTime).format("YYYY-MM-DD"))
          .pipe(
            take(1),
            tap((data) => {
              setCurrentDayType(DAY_TYPE_EXTENDED[data.type]);
            }),
            concatMap((data) => {
              return this.apiService
                .getTrafficArchesOrientedElaboratedDataProfile(
                  moment.utc(currentTime).format("HH:mm"),
                  stringFilterByFrc,
                  DAY_TYPE_EXTENDED[data.type].enum,
                  ["equivalent"],
                  ["los"]
                )
                .pipe(
                  take(1),
                  tap((data) => {
                    let trafficArcsFeatures = TrafficArcsFeature.fromArrayREST(
                      data.features
                    );
                    setTrafficArcsList(trafficArcsFeatures);
                    setIsTrafficLoading(false);
                  }),
                  catchError((error) => {
                    setTrafficArcsList([]);
                    setIsTrafficLoading(false);
                    console.error(error);
                    return of(error);
                  })
                );
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    } else if (
      (prevProps.boundingBox !== boundingBox ||
        prevProps.isTypicalVisible !== isTypicalVisible ||
        prevProps.currentOffSet !== currentOffSet ||
        prevProps.currentTime !== currentTime ||
        prevProps.trafficCurrentStartTime !== trafficCurrentStartTime ||
        (prevProps.currentDayType &&
          currentDayType &&
          prevProps.currentDayType.enum !== currentDayType.enum)) &&
      isTypicalVisible &&
      currentTime &&
      currentDayType
    ) {
      if (prevProps.boundingBox === boundingBox) {
        setIsTrafficLoading(true);
      }
      let newBounds = getBoundingBoxStringForCqlFilter(boundingBox);
      let stringFilterByFrc = getArcFilterByBboxAndFrcParam(
        currentMapZoom,
        newBounds
      );

      this.subscriptions$.push(
        this.apiService
          .getTrafficArchesOrientedElaboratedDataProfile(
            moment.utc(currentTime).format("HH:mm"),
            stringFilterByFrc,
            currentDayType.enum,
            ["equivalent"],
            ["los"]
          )
          .pipe(
            take(1),
            tap((data) => {
              let trafficArcsFeatures = TrafficArcsFeature.fromArrayREST(
                data.features
              );
              setTrafficArcsList(trafficArcsFeatures);
              setIsTrafficLoading(false);
            }),
            catchError((error) => {
              setTrafficArcsList([]);
              setIsTrafficLoading(false);
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }

    if (
      prevProps.isNetStateVisible !== isNetStateVisible &&
      isNetStateVisible
    ) {
      setIsTrafficLoading(true);

      const netStateContest = widgetContestsToShow.find(
        (item) => item.key === "ACTUAL"
      );
      setTrafficCurrentContext(netStateContest);

      let newBounds = getBoundingBoxStringForCqlFilter(boundingBox);
      let stringFilterByFrc = getArcFilterByBboxAndFrcParam(
        currentMapZoom,
        newBounds
      );

      let { outputDate, outputContext } = getContextAndDateFromApiCall(
        trafficCurrentStartTime,
        currentOffSet,
        contexts,
        currentTime
      );

      this.subscriptions$.push(
        this.apiService
          .getTrafficSpecificDayType(moment(currentTime).format("YYYY-MM-DD"))
          .pipe(
            take(1),
            tap((data) => {
              setCurrentDayType(DAY_TYPE_EXTENDED[data.type]);
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );

      if (outputContext) {
        this.subscriptions$.push(
          this.apiService
            .getTrafficArchesOrientedElaboratedDataByContext(
              outputContext.contextId,
              outputDate,
              stringFilterByFrc,
              ["equivalent"],
              ["los"],
              true
            )
            .pipe(
              take(1),
              tap((data) => {
                let trafficArcsFeatures = TrafficArcsFeature.fromArrayREST(
                  data.features
                );
                setTrafficArcsList(trafficArcsFeatures);
                setIsTrafficLoading(false);
              }),
              catchError((error) => {
                setTrafficArcsList([]);
                setIsTrafficLoading(false);
                console.error(error);
                return of(error);
              })
            )
            .subscribe()
        );
      }
    } else if (
      //LOAD ARC DATA ON BOUNDING BOX CHANGE
      (prevProps.boundingBox !== boundingBox ||
        prevProps.currentOffSet !== currentOffSet ||
        prevProps.currentTime !== currentTime ||
        prevProps.trafficCurrentStartTime !== trafficCurrentStartTime) &&
      isNetStateVisible
    ) {
      if (prevProps.boundingBox === boundingBox) {
        setIsTrafficLoading(true);
      }

      let newBounds = getBoundingBoxStringForCqlFilter(boundingBox);
      let stringFilterByFrc = getArcFilterByBboxAndFrcParam(
        currentMapZoom,
        newBounds
      );

      let { outputDate, outputContext } = getContextAndDateFromApiCall(
        trafficCurrentStartTime,
        currentOffSet,
        contexts,
        currentTime
      );

      if (outputContext) {
        this.subscriptions$.push(
          this.apiService
            .getTrafficArchesOrientedElaboratedDataByContext(
              outputContext.contextId,
              outputDate,
              stringFilterByFrc,
              ["equivalent"],
              ["los"],
              true
            )
            .pipe(
              take(1),
              tap((data) => {
                let trafficArcsFeatures = TrafficArcsFeature.fromArrayREST(
                  data.features
                );
                setTrafficArcsList(trafficArcsFeatures);
                setIsTrafficLoading(false);
              }),
              catchError((error) => {
                setTrafficArcsList([]);
                setIsTrafficLoading(false);
                console.error(error);
                return of(error);
              })
            )
            .subscribe()
        );
      }
    }

    if (
      isNetStateVisible &&
      currentTime &&
      (prevProps.currentOffSet !== currentOffSet ||
        prevProps.currentTime !== currentTime)
    ) {
      this.subscriptions$.push(
        this.apiService
          .getTrafficSpecificDayType(moment(currentTime).format("YYYY-MM-DD"))
          .pipe(
            take(1),
            tap((data) => {
              setCurrentDayType(DAY_TYPE_EXTENDED[data.type]);
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }

    if (
      JSON.stringify(prevProps.wsLastNotification) !==
        JSON.stringify(wsLastNotification) &&
      wsLastNotification.length > 0 &&
      isNetStateVisible
    ) {
      wsLastNotification.forEach((item) => {
        if (
          item.payload.contextType === "ACTUAL" &&
          moment(currentTime).isSame(trafficCurrentStartTime) &&
          currentOffSet === 0
        ) {
          setCurrentTrafficStartTime(
            moment(item.payload.measurementDatetime).toDate()
          );
          setCurrentTrafficTime(
            moment(item.payload.simulationDatetime).toDate()
          );
        } else if (
          item.payload.contextType === "FORECAST" &&
          item.payload.contextId === "context_15" &&
          moment(trafficCurrentStartTime)
            .add(15, "minutes")
            .isSame(moment(currentTime)) &&
          currentOffSet === 15
        ) {
          setCurrentTrafficStartTime(
            moment(item.payload.measurementDatetime).toDate()
          );
          setCurrentTrafficTime(
            moment(item.payload.simulationDatetime).toDate()
          );
        } else if (
          item.payload.contextType === "FORECAST" &&
          item.payload.contextId === "context_30" &&
          moment(trafficCurrentStartTime)
            .add(30, "minutes")
            .isSame(moment(currentTime)) &&
          currentOffSet === 30
        ) {
          setCurrentTrafficStartTime(
            moment(item.payload.measurementDatetime).toDate()
          );
          setCurrentTrafficTime(
            moment(item.payload.simulationDatetime).toDate()
          );
        } else if (
          item.payload.contextType === "FORECAST" &&
          item.payload.contextId === "context_45" &&
          moment(trafficCurrentStartTime)
            .add(45, "minutes")
            .isSame(moment(currentTime)) &&
          currentOffSet === 45
        ) {
          setCurrentTrafficStartTime(
            moment(item.payload.measurementDatetime).toDate()
          );
          setCurrentTrafficTime(
            moment(item.payload.simulationDatetime).toDate()
          );
        } else if (
          item.payload.contextType === "FORECAST" &&
          item.payload.contextId === "context_60" &&
          moment(trafficCurrentStartTime)
            .add(60, "minutes")
            .isSame(moment(currentTime)) &&
          currentOffSet === 60
        ) {
          setCurrentTrafficStartTime(
            moment(item.payload.measurementDatetime).toDate()
          );
          setCurrentTrafficTime(
            moment(item.payload.simulationDatetime).toDate()
          );
        }
      });

      resetTrafficNotificationsWS();
    }
  };

  render() {
    return <div></div>;
  }

  componentWillUnmount = () => {
    this.subscriptions$.forEach((x) => x.unsubscribe());
  };
}

LoadTrafficData.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
  setTrafficArcsList,
  setTrafficCurrentArc,
  setTrafficCurrentArcMap,
  setTrafficContexts,
  setDayTypes,
  setMeasures,
  setVehicleClasses,
  setCurrentTrafficTime,
  setCurrentTrafficStartTime,
  setCurrentTrafficEndTime,
  setTrafficCurrentContext,
  setCurrentDayType,
  setCurrentDay,
  setCurrentVehicleClass,
  setCurrentMeasure,
  wsOpen,
  updateTrafficNotificationsWS,
  resetTrafficNotificationsWS,
  setCurrentContextTimeOffSet,
  toggleOpenTrafficTimeSlider,
  setIsTrafficLoading,
};

const mapStateToProps = (state) => ({
  //CHECKBOX
  allTrafficVisible: areAllTrafficVisible(state),
  isNetStateVisible: isNetStateVisible(state),
  isTypicalVisible: isTypicalVisible(state),
  //TRAFFIC REDUCER
  boundingBox: getBoundingBox(state),
  currentMapZoom: getCurrentZoom(state),
  currentContext: getTrafficCurrentContext(state),
  currentOffSet: getCurrentTimeOffSet(state),
  contexts: getTrafficContexts(state),
  currentTime: getTrafficCurrentTime(state),
  currentDayType: getTrafficCurrentDayType(state),
  trafficCurrentStartTime: getTrafficCurrentStartTime(state),
  //WS
  isWSStationNotificationOpen: isWSStationNotificationOpen(state),
  wsLastNotification: getWsLastNotification(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadTrafficData)
);
