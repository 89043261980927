import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isOpenNavTab, getTrafficContexts } from "store";
import EnvironmentContext from "environment-context";

//UTILS
import { TYPE_ARC } from "utils/utils";
//COMPONENTS
import ArcDetailHeader from "../arc-detail-header";
import ArcTrafficDetailHeader from "../../arc-traffic/arc-traffic-detail-header";

class ArcDetail extends Component {
  apiService;
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  header = () => {
    const {
      toggleArcDetails,
      currentArc,
      arcDetailType,
      trafficMeasure,
    } = this.props;

    if (arcDetailType === "traffic") {
      const name = currentArc.properties.name;
      const direction = currentArc.properties.direction;

      return (
        <ArcTrafficDetailHeader
          isOpenNavTab={isOpenNavTab}
          toggleArcDetails={toggleArcDetails}
          name={name}
          direction={direction}
          trafficMeasure={trafficMeasure}
        />
      );
    } else {
      return (
        <ArcDetailHeader
          isOpenNavTab={isOpenNavTab}
          toggleArcDetails={toggleArcDetails}
          currentArc={currentArc}
        />
      );
    }
  };

  tabs = () => {
    const { handleClickSecondTab, secondTab } = this.props;
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickSecondTab();
            }}
          >
            <a href="/#">{secondTab}</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { currentArc } = this.props;

    return (
      <div className="uk-flex uk-flex-column contentBodyEventDetail">
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">ID ARCO</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.arcid
              ? currentArc.properties.arcid
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Nome</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.name
              ? currentArc.properties.name
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Lunghezza</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.distance
              ? currentArc.properties.distance
              : "-"}{" "}
            m
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Senso Unico</div>
          <div className="text-detail-emphasis-arc">
            {currentArc &&
            (currentArc.properties.oneway === 0 ||
              currentArc.properties.oneway === 1)
              ? currentArc.properties.oneway === 0
                ? "NO"
                : "SI"
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Area Amministrativa</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.area_name
              ? currentArc.properties.area_name
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Tipo</div>
          <div className="text-detail-emphasis-arc">
            {currentArc &&
            (currentArc.properties.frc || currentArc.properties.frc === 0)
              ? TYPE_ARC[currentArc.properties.frc]
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Numero Corsie</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.lanes
              ? currentArc.properties.lanes
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Capacità</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.capacity
              ? currentArc.properties.capacity
              : "-"}{" "}
            vei/h
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Velocità massima</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.speed
              ? currentArc.properties.speed
              : "-"}{" "}
            km/h
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Ponte</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.bridge
              ? currentArc.properties.bridge
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Tunnel</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.tunnel
              ? currentArc.properties.tunnel
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Pedaggio</div>
          <div className="text-detail-emphasis-arc">
            {currentArc && currentArc.properties.tolling
              ? currentArc.properties.tolling
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">ZTL</div>
          <div className="text-detail-emphasis-arc">
            {currentArc &&
            (currentArc.properties.ztl || currentArc.properties.ztl === 0)
              ? currentArc.properties.ztl === 0
                ? "NO"
                : "SI"
              : "-"}
          </div>
        </li>
      </div>
    );
  };

  render() {
    return (
      <div className="inherit-transition">
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}
ArcDetail.contextType = EnvironmentContext;

const mapStateToProps = (state) => ({
  isOpenNavTab: isOpenNavTab(state),
  contexts: getTrafficContexts(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArcDetail)
);
