import { SegmentsActions } from "reducers/graph/segments/segments.actions.js";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";

const INITIAL_STATE = {
  segments: [],
  segmentsFiltered: [],
  current: null,
  currentMap: null,
  searchText: "",
  searchType: "",
  bounds: [],
};

function searchSegments(searchText) {
  return (segment) =>
    segment &&
    segment.properties.name &&
    segment.properties.name.toLowerCase().includes(searchText.toLowerCase());
}

export default function segmentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SegmentsActions.SET_LIST_SEGMENTS:
      let segmentsFiltered = action.payload.list;
      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "tratte-tmc"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchSegments(searchText);
        segmentsFiltered = action.payload.list.filter(searchFn);
      }
      return {
        ...state,
        segments: action.payload.list,
        segmentsFiltered: segmentsFiltered,
      };
    case SegmentsActions.SET_CURRENT_SEGMENT:
      let newarrSegment = [...state.segments].map((item) => ({
        ...item,
        properties: {
          ...item.properties,
          selected:
            item &&
            item.properties &&
            action.payload &&
            action.payload.segment &&
            action.payload.segment.properties &&
            action.payload.segment.properties.id &&
            item.properties.id === action.payload.segment.properties.id
              ? state.currentMap &&
                state.currentMap.properties &&
                state.currentMap.properties.id ===
                  action.payload.segment.properties.id
                ? item.properties.selected
                : !item.properties.selected
              : false,
        },
      }));
      let newCurr;
      if (action.payload && action.payload.segment) {
        newCurr = {
          ...action.payload.segment,
          properties: {
            ...action.payload.segment.properties,
            selected: !action.payload.segment.properties.selected,
          },
        };
      } else {
        newCurr = action.payload.segment;
      }
      return {
        ...state,
        current:
          state.current &&
          state.current.properties &&
          state.current.properties.id &&
          action.payload &&
          action.payload.segment &&
          action.payload.segment.properties &&
          action.payload.segment.properties.id &&
          state.current.properties.id === action.payload.segment.properties.id
            ? null
            : newCurr,
        segments: newarrSegment,
        currentMap: null,
      };
    case SegmentsActions.RESET_CURRENT_SEGMENT_MAP:
      return {
        ...state,
        segments: [],
        segmentsFiltered: [],
        current: null,
        currentMap: null,
      };
    case SegmentsActions.SET_CURRENT_SEGMENT_MAP:
      let newarrSegmentsMap = [...state.segments].map((item) => ({
        ...item,
        properties: {
          ...item.properties,
          selected:
            item &&
            item.properties &&
            action.payload &&
            action.payload.segment &&
            action.payload.segment.properties &&
            action.payload.segment.properties.id &&
            item.properties.id === action.payload.segment.properties.id
              ? !item.properties.selected
              : false,
        },
      }));
      let newCurrMap;

      if (action.payload && action.payload.segment) {
        newCurrMap = {
          ...action.payload.segment,
          properties: {
            ...action.payload.segment.properties,
            selected: !action.payload.segment.properties.selected,
          },
        };
      } else {
        newCurrMap = action.payload.segment;
      }
      return {
        ...state,
        currentMap:
          state.currentMap &&
          state.currentMap.properties &&
          state.currentMap.properties.id &&
          action.payload &&
          action.payload.segment &&
          action.payload.segment.properties &&
          action.payload.segment.properties.id &&
          state.currentMap.properties.id ===
            action.payload.segment.properties.id
            ? null
            : state.current &&
              state.current.properties &&
              state.current.properties.id
            ? null
            : newCurrMap,
        segments: newarrSegmentsMap,
        current:
          state.current &&
          state.current.properties &&
          state.current.properties.id &&
          action.payload &&
          action.payload.segment &&
          action.payload.segment.properties &&
          action.payload.segment.properties.id
            ? state.current.properties.id ===
              action.payload.segment.properties.id
              ? null
              : action.payload.segment
            : action.payload.segment,
      };

    case SegmentsActions.FILTER_SEGMENTS_BY_POINT: {
      let searchSegment =
        action.payload &&
        action.payload.point &&
        action.payload.point.properties &&
        action.payload.point.properties.road_lcd
          ? action.payload.point.properties.road_lcd
          : null;
      let segmentsFilteredByPoints = state.segments;
      if (searchSegment) {
        segmentsFilteredByPoints = state.segments.filter(
          (item) =>
            item.properties.seg_lcd === searchSegment ||
            item.properties.road_lcd === searchSegment
        );
      }
      return {
        ...state,
        segmentsFiltered: segmentsFilteredByPoints,
      };
    }
    case NavTabActions.SET_SEARCH:
      if (action.payload && action.payload.type === "tratte-tmc") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const searchFn = searchSegments(searchText);
        let segmentsFiltered = state.segments.filter(searchFn);
        const searchType = action.payload.type;

        return {
          ...state,
          segmentsFiltered,
          searchText,
          searchType,
        };
      } else {
        return { ...state };
      }
    default:
      return state;
  }
}

export const getSegmentsRdsTmc = (state) => state.segments;
export const getCurrentSegmentRdsTmc = (state) => state.current;
export const getCurrentSegmentRdsTmcMap = (state) => state.currentMap;
export const getFilteredSegments = (state) => state.segmentsFiltered;
