import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  areAllGraphVisible,
  isArcVisible,
  isNodeVisible,
  isCentroidVisible,
  isOpenNavTab,
  isOpenNavGraph,
} from "store";

const mapStateToProps = (state) => ({
  allGraphChecked: areAllGraphVisible(state),
  arcChecked: isArcVisible(state),
  nodeChecked: isNodeVisible(state),
  centroidChecked: isCentroidVisible(state),
  isOpenNavTab: isOpenNavTab(state),
  isOpenNavGraph: isOpenNavGraph(state),
});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemNetworkGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isOpenNavGraph,
      allGraphChecked,
      arcChecked,
      nodeChecked,
      centroidChecked,
      clickOnGrafoLabel,
      clickOnGrafoCheckbox,
    } = this.props;

    return (
      <div>
        <div
          className={
            isOpenNavGraph
              ? "uk-checkbox-title uk-open acr-graph-open"
              : "uk-checkbox-title"
          }
        >
          <span>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={allGraphChecked || false}
              onChange={() => clickOnGrafoCheckbox("all")}
            />
          </span>
          <label onClick={() => clickOnGrafoLabel()}>Grafo Stradale</label>
        </div>

        <div className="uk-checkbox-tree-sub acr-graph-sub">
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={arcChecked || false}
              onChange={() => clickOnGrafoCheckbox("arc")}
            />
            Archi
          </label>
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={nodeChecked || false}
              onChange={() => clickOnGrafoCheckbox("node")}
            />
            Nodi
          </label>
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={centroidChecked || false}
              onChange={() => clickOnGrafoCheckbox("centroid")}
            />
            Centroidi
          </label>
        </div>
      </div>
    );
  }
}

export default NavItemNetworkGraph;
