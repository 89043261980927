import React, { Component } from "react";
import ReactPlayer from 'react-player/lazy'
import { HTMLTagIdForComponentInjection } from "utils/utils-streaming";

export class WebCamWrapper extends Component {

    constructor(props) {
        super(props);

    }

    render = () => {
        const { videoURI, cam, origin, hlsEnabled } = this.props;
        const id = HTMLTagIdForComponentInjection(origin);

        return hlsEnabled && videoURI ? <ReactPlayer width={"100%"} height={"100%"} url={`${videoURI || ''}`} stopOnUnmount={true} loop={true} volume={0} controls={false} playing={true} />
            : !hlsEnabled ? (<video
                className="uk-width-1-1"
                id={
                    cam &&
                        cam.properties &&
                        cam.properties.cctvWithLastStateAndOpenAlarms &&
                        cam.properties.cctvWithLastStateAndOpenAlarms &&
                        cam.properties.cctvWithLastStateAndOpenAlarms
                            .cctv &&
                        cam.properties.cctvWithLastStateAndOpenAlarms
                            .cctv.externalCameraId
                        ? id + "-" +
                        cam.properties.cctvWithLastStateAndOpenAlarms.cctv.externalCameraId.replace(
                            /\./g,
                            ""
                        )
                        : id
                }
            ></video>)
            : <></>
    }
}