//IMG
import pmv_img_red from "assets/icon/installations/pmv-states/pmv_red_circle.svg";
import pmv_img_green from "assets/icon/installations/pmv-states/pmv_green_circle.svg";
import pmv_img_gray from "assets/icon/installations/pmv-states/pmv_gray_circle.svg";
import pmv_img_yellow from "assets/icon/installations/pmv-states/pmv_yellow_circle.svg";
import pmv_img_blue from "assets/icon/installations/pmv-states/pmv_blue_circle.svg";
import pmv_img_red_filled from "assets/icon/installations/pmv-states/pmv_red_filled.svg";
import pmv_img_green_filled from "assets/icon/installations/pmv-states/pmv_green_filled.svg";
import pmv_img_gray_filled from "assets/icon/installations/pmv-states/pmv_gray_filled.svg";
import pmv_img_yellow_filled from "assets/icon/installations/pmv-states/pmv_yellow_filled.svg";
import pmv_img_blue_filled from "assets/icon/installations/pmv-states/pmv_blue_filled.svg";

import * as moment from "moment";

export const PMV_STATUS = [
  {
    name: "Normale",
    value: 1,
    icon: pmv_img_green,
    mapIcon: pmv_img_green_filled,
  },
  {
    name: "Degradato",
    value: 2,
    icon: pmv_img_yellow,
    mapIcon: pmv_img_yellow_filled,
  },
  {
    name: "Guasto",
    value: 3,
    icon: pmv_img_red,
    mapIcon: pmv_img_red_filled,
  },
  {
    name: "Sconosciuto",
    value: 4,
    icon: pmv_img_gray,
    mapIcon: pmv_img_gray_filled,
  },
  {
    name: "Offline",
    value: 5,
    icon: pmv_img_blue,
    mapIcon: pmv_img_blue_filled,
  },
];

export function createMsgUrl(endpoint, currentPmvGroup) {
  let endpointString = endpoint + "/pmv/esposizionemanualediretta/";
  currentPmvGroup.forEach((pmv, index) => {
    endpointString = endpointString + pmv.properties.anagrafica.id;
    if (index !== currentPmvGroup.length - 1) {
      endpointString = endpointString + ",";
    }
  });

  return endpointString;
}

export function getFiltersForPmvTable(
  pageOffset,
  pageLimit,
  stateTableColumns,
  sortConfig,
  filterStr
) {
  let nomePannello = filterStr;

  let tipologia = [
    ...stateTableColumns["tipologia"].childrenForHeaderDropdown.reduce(
      (acc, { checked, id }) => {
        if (checked && !id.startsWith("all")) {
          return acc.concat(id);
        } else {
          return acc;
        }
      },
      []
    ),
  ];
  let statoFunzionamento = [
    ...stateTableColumns["statoFunzionamento"].childrenForHeaderDropdown.reduce(
      (acc, { checked, id }) => {
        if (checked && !id.startsWith("all")) {
          return acc.concat(id);
        } else {
          return acc;
        }
      },
      []
    ),
  ];

  let ordinamento = sortConfig.key;
  let tipoOrdinamento =
    sortConfig.direction === "ascending"
      ? "Ascendente"
      : sortConfig.direction === "descending"
      ? "Discendente"
      : "Ascendente";

  const body = {
    pageLimit: pageLimit,
    pageOffset: pageOffset,
    filtriAnagrafica: {
      nomePannello: nomePannello,
      strada: "",
      tipologie: tipologia,
    },
    statiFunzionamento: statoFunzionamento,
    ordinamento: ordinamento,
    tipoOrdinamento: tipoOrdinamento,
  };

  return body;
}

export function getFiltersForPmvHistoryTable(
  pageOffset,
  pageLimit,
  stateTableColumns,
  dateFrom,
  dateTo
) {
  let statoFunzionamento = [
    ...stateTableColumns["statoFunzionamento"].childrenForHeaderDropdown.reduce(
      (acc, { checked, id }) => {
        if (checked && !id.startsWith("all")) {
          return acc.concat(id);
        } else {
          return acc;
        }
      },
      []
    ),
  ];

  const body = {
    pageLimit: pageLimit,
    pageOffset: pageOffset,
    statiFunzionamento: statoFunzionamento,
    validita: {
      da: dateFrom
        ? dateFrom.toISOString()
        : moment().subtract(3, "days").toISOString(),
      a: dateTo ? dateTo.toISOString() : moment().toISOString(),
    },
  };

  return body;
}

export function getCheckedTypesArray(
  isAesysPanelVisible,
  isSolariPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
) {
  let array = [];
  if (isAesysPanelVisible) {
    array.push("Aesys");
  }
  if (isSolariPanelVisible) {
    array.push("Solari");
  }
  if (isFuturitPanelVisible) {
    array.push("Futurit");
  }
  if (isVisuallabPanelVisible) {
    array.push("Visual Lab");
  }
  if(isSfheraPanelVisible){
    array.push("Sfhera")
  }

  return array;
}
