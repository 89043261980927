import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentSegmentRdsTmc } from "store";
//ASSETS
import acr_segment from "assets/icon/graph/acr-segment-green.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";

class SegmentRdsTmcDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  header = () => {
    const { toggleSegmentDetail, currentSegment } = this.props;

    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="uk-flex uk-flex-middle uk-flex-center">
          <div className="parent uk-flex uk-flex-middle uk-flex-center">
            <div className="circle2"></div>

            <img
              alt="icn_diamond"
              src={icn_diamond}
              className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
            ></img>
            <img
              alt="acr_segment"
              src={acr_segment}
              className="image2 height-width-48 uk-flex uk-flex-middle uk-flex-center segment"
            ></img>
          </div>
        </div>
        <div className=" uk-margin-small-left uk-margin-small-right text-line-width text-detail-emphasis-arc tratte-title-height">
          {currentSegment &&
          currentSegment.properties &&
          currentSegment.properties.name
            ? currentSegment.properties.name
            : "-"}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            toggleSegmentDetail(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClicksSegmentRoute } = this.props;
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClicksSegmentRoute();
            }}
          >
            <a href="/#">Percorso</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { currentSegment } = this.props;

    return (
      <div className="uk-flex uk-flex-column contentBodyEventDetail">
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Nome Tratta</div>
          <div className="text-detail-emphasis-arc">
            {currentSegment &&
            currentSegment.properties &&
            currentSegment.properties.name
              ? currentSegment.properties.name
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">ID Tratta</div>
          <div className="text-detail-emphasis-arc">
            {currentSegment && currentSegment.properties.road_lcd
              ? currentSegment.properties.road_lcd
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Lunghezza</div>
          <div className="text-detail-emphasis-arc">
            {currentSegment && currentSegment.properties.tot_length.toFixed(1)
              ? currentSegment.properties.tot_length.toFixed(1)
              : "-"}{" "}
            Km
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Direzione negativa</div>
          <div className="text-detail-emphasis-arc">
            {currentSegment && currentSegment.properties.start_road
              ? currentSegment.properties.start_road
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Direzione positiva</div>
          <div className="text-detail-emphasis-arc">
            {currentSegment && currentSegment.properties.end_road
              ? currentSegment.properties.end_road
              : "-"}
          </div>
        </li>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentSegment: getCurrentSegmentRdsTmc(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SegmentRdsTmcDetail)
);
