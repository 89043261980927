import React, { Component } from "react";
import "./style.less";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";

class InstallationTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { x, y, object, layoutMap } = this.props;

    let image = DeckLayerUtil.getIconInstallations(
      object.category.type,
      object.deviceStatus,
      layoutMap
    );
    return (
      <div
        className={"show-tooltip"}
        style={{ left: x - 125, top: y - (220 + 20) }}
      >
        <div className="uk-flex uk-flex-column uk-margin-top">
          <div style={{ width: "100%", textAlign: "center" }}>
            <img alt="" style={{ width: "40px", height: "40px" }} src={image} />
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-top">
            <div className="tooltip-text-large">ID: </div>
            <div className="tooltip-text-important"> {object.id}</div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text-large">Nome: </div>
            <div className="tooltip-text-important"> {object.name}</div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text-large">FEP: </div>
            <div className="tooltip-text-important"> {object.ip}</div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text-large">Latitudine: </div>
            <div className="tooltip-text-important">
              {" "}
              {object.location.latitude}{" "}
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle">
            <div className="tooltip-text-large">Longitudine:</div>
            <div className="tooltip-text-important">
              {" "}
              {object.location.longitude}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstallationTooltip;
