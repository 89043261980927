import React, { Component } from "react";

class TabPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, children, value, index, ...other } = this.props;

    return (
      <div
        className="uk-height-1-1 inherit-transition"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className="uk-flex uk-flex-column uk-width-1-1 uk-height-1-1 uk-padding-remove inherit-transition">
            {children}
          </div>
        )}
      </div>
    );
  }
}
export default TabPanel;
