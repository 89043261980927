import * as FilterUtils from "utils/filter-utils";

export function handleFilterStringChange(
  tableComponent,
  e,
  FILTER_TARGET,
  apiCall
) {
  const { saveTableFilters, tableFilters } = tableComponent.props;

  saveTableFilters(
    {
      ...tableFilters,
      searchText: e.target.value,
    },
    FILTER_TARGET
  );

  tableComponent.setState(
    {
      ...tableComponent.state,
      filterStr: e.target.value,
      currentPage: 0,
      pageToDisplay: 1,
      startIndex: 0,
    },
    apiCall
  );
}

export function handleClickIncreasePage(tableComponent) {
  const {
    currentPage,
    pageMax,
    startIndex,
    count,
    pageToDisplay,
  } = tableComponent.state;

  if (currentPage < pageMax - 1) {
    tableComponent.setState(
      {
        ...tableComponent.state,
        currentPage: parseInt(currentPage) + 1,
        startIndex: startIndex + count,
        pageToDisplay: parseInt(pageToDisplay) + 1,
      },
      () => {
        tableComponent.numberPage$.next();
      }
    );
  }
}

export function handleClickDecreasePage(tableComponent) {
  const {
    currentPage,
    startIndex,
    count,
    pageToDisplay,
  } = tableComponent.state;
  if (pageToDisplay > 1) {
    tableComponent.setState(
      {
        ...tableComponent.state,
        currentPage: parseInt(currentPage) - 1,
        startIndex: startIndex - count,
        pageToDisplay: parseInt(pageToDisplay) - 1,
      },
      () => {
        tableComponent.numberPage$.next();
      }
    );
  }
}

export function requestSortOrder(tableComponent, key, apiCall) {
  const { sortConfig } = tableComponent.state;

  let direction = "descending";
  if (sortConfig.key === key && sortConfig.direction === "descending") {
    direction = "ascending";
  }

  tableComponent.setState(
    { ...tableComponent.state, sortConfig: { key: key, direction: direction } },
    apiCall
  );
}

export function resetChildrenForHeaderDropdown(stateTableColumns) {
  Object.keys(stateTableColumns).map((_) => {
    stateTableColumns[_].checked = true;
    stateTableColumns[_].show = true;
    if (
      stateTableColumns[_].childrenForHeaderDropdown &&
      stateTableColumns[_].childrenForHeaderDropdown.length > 0
    ) {
      stateTableColumns[_].childrenForHeaderDropdown = stateTableColumns[
        _
      ].childrenForHeaderDropdown.map((item) => {
        return {
          ...item,
          checked: true,
        };
      });
    }
    return _;
  });
  return stateTableColumns;
}

export function onDropdownCheckboxFilterClick(
  tableComponent,
  apiCall,
  FILTER_TARGET,
  newTableColumns
) {
  const { saveTableFilters, tableFilters } = tableComponent.props;

  saveTableFilters(
    {
      ...tableFilters,
      tableColumns: newTableColumns,
    },
    FILTER_TARGET
  );

  tableComponent.setState(
    {
      ...tableComponent.state,
      stateTableColumns: newTableColumns,
      startIndex: 0,
      currentPage: 0,
      pageToDisplay: 1,
      pageMax: 1,
    },
    apiCall
  );
}

export function getCurrentPageNumber(tableComponent, pageNumber) {
  const { currentPage, pageMax, count } = tableComponent.state;

  let currPage = pageNumber.target.value;
  let parsedPage = parseInt(currPage);

  if (currentPage <= pageMax && parsedPage && parsedPage <= pageMax) {
    tableComponent.setState(
      {
        ...tableComponent.state,
        currentPage: parsedPage - 1,
        pageToDisplay: parsedPage,
        startIndex: parsedPage * count - count,
      },
      () => {
        tableComponent.numberPage$.next();
      }
    );
  }

  if (parsedPage && parsedPage <= 0) {
    tableComponent.setState({
      ...tableComponent.state,
      currentPage: 0,
      pageToDisplay: 1,
    });
  }

  if (currPage === "") {
    tableComponent.setState({
      pageToDisplay: "",
    });
  }
}

export function sortByDirectionTranslation(sortConfig) {
  let sortBy = "";

  if (sortConfig && sortConfig.key && sortConfig.direction) {
    let dir = sortConfig.direction === "ascending" ? "A" : "D";
    sortBy = sortConfig.key + " " + dir;
  }

  return sortBy;
}

export function resetFiltersState(
  tableComponent,
  newStateTableColumns,
  selected,
  apiCall,
  type
) {
  let sort = { key: "", direction: "" };
  if (type === "event") {
    sort = { key: "insertedOn", direction: "descending" };
  } else if (type === "arch") {
    sort = { key: "frc", direction: "descending" };
  } else if (type === "point") {
    sort = { key: "name1", direction: "descending" };
  } else if (type === "segment") {
    sort = { key: "name", direction: "descending" };
  } else if (type === "pmv") {
    sort = { key: "Nessuno", direction: "descending" };
  } else if (type === "user") {
    sort = { key: "datetimeAction", direction: "descending" };
  }

  tableComponent.setState(
    {
      ...tableComponent.state,
      filterStr: "",
      startIndex: 0,
      currentPage: 0,
      pageToDisplay: 1,
      TABLE_COLUMNS: newStateTableColumns,
      stateTableColumns: newStateTableColumns,
      selected: FilterUtils.resetFilters(selected),
      sortConfig: sort,
    },
    apiCall
  );
}

export function changeDateFilter(tableComponent, value, name, FILTER_TARGET) {
  const { saveTableFilters, tableFilters } = tableComponent.props;
  if (value && value !== "") {
    saveTableFilters(
      {
        ...tableFilters,
        dateFrom: name === "dateStartEvent" ? value : tableFilters.dateFrom,
        dateTo: name === "dateEndEvent" ? value : tableFilters.dateTo,
      },
      FILTER_TARGET
    );
  }
}

export function setDateFilter(tableComponent, date, FILTER_TARGET) {
  const { saveTableFilters, tableFilters, dateColumns } = tableComponent.props;
  let selected = [...dateColumns].map((element) => {
    if (element.name === date.name) element.selected = true;
    else element.selected = false;
    return element;
  });
  saveTableFilters(
    {
      ...tableFilters,
      dateColumns: [...selected],
    },
    FILTER_TARGET
  );
}

export const initializeTableState = {
  count: 20,
  startIndex: 0,
  currentPage: 0,
  pageMax: 1,
  pageToDisplay: 1,
  filterStr: "",
  selected: {},
  isTableDataLoading: false,
  selectedEvents: []
};

export function getTableStateSortConfig(type) {
  let sort = { key: "", direction: "" };
  if (type === "event") {
    sort = { key: "insertedOn", direction: "descending" };
  } else if (type === "arch") {
    sort = { key: "frc", direction: "descending" };
  } else if (type === "point") {
    sort = { key: "name1", direction: "descending" };
  } else if (type === "segment") {
    sort = { key: "name", direction: "descending" };
  } else if (type === "pmv") {
    sort = { key: "Nessuno", direction: "descending" };
  } else if (type === "user") {
    sort = { key: "datetimeAction", direction: "descending" };
  } else if (type === "cam") {
    sort = { key: "Nessuno", direction: "descending" };
  }

  return sort;
}
