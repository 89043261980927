import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import {
  tap,
  take,
  catchError,
  concatMap,
  finalize,
  mergeMap,
} from "rxjs/operators";
import { of, merge } from "rxjs";
//ACTIONS
import { setIsEventLoading } from "reducers/ui/event-menu/event-menu.actions";
import {
  setSourceSelectModal,
  setTypesSubtypesSelectModal,
} from "reducers/modal/modal.actions";
import {
  getCurrentNetwork,
  getBoundingBox,
  getSelectModal,
  isEventsLoading,
  isEventsAllLoading,
  isActiveVisible,
  isPlannedVisible,
  getCurrentWorkspace,
  getCurrentZoom,
} from "store";
import { setEventsList } from "reducers/events/events.actions";
import { setBounds, filterByMapBounds } from "reducers/map/map.actions";
//UTIL
import { filterBounds } from "components/layout/map/load-map/load-map";
//MODEL
import { SituationRecord } from "reducers/events/models/situation-record";

class LoadEventData extends Component {
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      networkCurrentReducer,
      isActiveVisible,
      isPlannedVisible,
    } = this.props;

    this.apiService = createApiService(this.context);
    this.configModal();

    if (isActiveVisible || isPlannedVisible) {
      !networkCurrentReducer
        ? this.getApiNetworkDetails()
        : this.eventCallApi();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      networkCurrentReducer,
      isActiveVisible,
      isPlannedVisible,
    } = this.props;
    if (
      (prevProps.isActiveVisible !== isActiveVisible && isActiveVisible) ||
      (prevProps.isPlannedVisible !== isPlannedVisible && isPlannedVisible)
    ) {
      !networkCurrentReducer
        ? this.getApiNetworkDetails()
        : this.eventCallApi();
    }
  }

  configModal = () => {
    this.configSourceModal();
    this.configTypesSubtypesModal();
  };

  configSourceModal = () => {
    const { setSourceSelectModal } = this.props;
    this.subscriptions.push(
      this.apiService
        .getConfigSources()
        .pipe(
          take(1),
          tap((x) => {
            if (x && !x.error) {
              x.map((item, index) => {
                return {
                  sourceId: item.sourceId,
                  enableUpdates: item.enableUpdates,
                  sourceIndex: index,
                };
              });
              setSourceSelectModal(x);
            } else {
              console.log(x);
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  configTypesSubtypesModal = () => {
    const { setTypesSubtypesSelectModal } = this.props;
    this.subscriptions.push(
      this.apiService
        .getConfigTypesSubtypes()
        .pipe(
          take(1),
          tap((x) => {
            if (x && !x.error) {
              let types = [].concat(
                x.filter((obj) => {
                  return obj.subTypes.length > 0;
                })
              );
              setTypesSubtypesSelectModal(types);
            } else {
              console.log(x);
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  getApiNetworkDetails = () => {
    fetch("/config.json")
      .then((res) => res.json())
      .then((environment) => {
        this.eventCallApi(environment.network);
      });
  };

  eventCallApi = (idNetwork) => {
    const { networkCurrentReducer, setIsEventLoading } = this.props;

    let idNet = [];
    networkCurrentReducer && networkCurrentReducer.length > 0
      ? networkCurrentReducer.forEach((x) => idNet.push(x.id))
      : idNet.push(idNetwork);
    if (idNet && idNet.length > 0) {
      this.checkNumberElements();
    }
    setIsEventLoading({
      isEventsLoading: true,
      isEventsAllLoading: true,
    });
  };

  checkNumberElements = () => {
    let now = null;
    let arrayIntervalsOpen = [];

    this.subscriptions.push(
      this.apiService
        .getSituationRecords(1, 0, now, ["OPEN", "SCHEDULED"])
        .pipe(
          tap((open) => {
            if (open && !open.error && open.totalElements) {
              let totalInArray = 0;
              for (let i = 0; i <= 1000; i++) {
                if (open.totalElements <= totalInArray) {
                  break;
                } else {
                  arrayIntervalsOpen.push({
                    limit: 50,
                    offset: i,
                  });
                  totalInArray += 50;
                }
              }
            }
          }),
          mergeMap(() => merge(this.openEvents(arrayIntervalsOpen, now))),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  openEvents = (arr, now) => {
    const {
      setEventsList,
      selectModal,
      setIsEventLoading,
      isEventsAllLoading,
    } = this.props;

    return of(...arr).pipe(
      concatMap((interval) =>
        this.apiService.getSituationRecords(
          interval.limit,
          interval.offset,
          now,
          ["OPEN", "SCHEDULED"]
        )
      ),
      tap((open) => {
        let openRoadSituations = [];
        if (open && !open.error && open.payload) {
          openRoadSituations = SituationRecord.fromArray(open.payload);
        }
        setEventsList(openRoadSituations, selectModal);
        setIsEventLoading({
          isEventsLoading: false,
          isEventsAllLoading: isEventsAllLoading,
        });
      }),
      finalize(() => {
        setIsEventLoading({
          isEventsLoading: false,
          isEventsAllLoading: false,
        });
        this.setState({ isEventsAllLoading: false });
        filterBounds(this);
      }),
      catchError((error) => {
        setIsEventLoading({
          isEventsLoading: false,
          isEventsAllLoading: false,
        });
        console.error(error);
        return of(error);
      })
    );
  };

  render() {
    return <div></div>;
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

LoadEventData.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setSourceSelectModal,
  setTypesSubtypesSelectModal,
  setBounds,
  filterByMapBounds,
  setEventsList,
  setIsEventLoading,
};

const mapStateToProps = (state) => ({
  networkCurrentReducer: getCurrentNetwork(state),
  selectModal: getSelectModal(state),
  boundsReducer: getBoundingBox(state),
  isEventsLoading: isEventsLoading(state),
  isEventsAllLoading: isEventsAllLoading(state),
  isActiveVisible: isActiveVisible(state),
  isPlannedVisible: isPlannedVisible(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
  currentMapZoom: getCurrentZoom(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadEventData)
);
