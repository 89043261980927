import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";

//COMPONENTS
import NavTab from "components/layout/nav-tab";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import PmvsContainer from "components/layout/pmvs";
import Loading from "components/shared/loading/loading";

//STORE AND ACTIONS
import {
  getBoundingBox,
  isOpenNavTab,
  getNavTabSearch,
  getNavTabSelectedTab,
  getFilteredPmvs,
  isPmvVisible,
  getCurrentPmv,
  isAesysPanelVisible,
  isSolariPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
  isPmvLoading,
} from "store";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";

class PmvPage extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { setNavTabSelectedTab } = this.props;
    setNavTabSelectedTab(0);
  };

  componentDidUpdate = (prevProps) => {};

  handleSearch = (value) => {
    const { setNavTabSearch } = this.props;
    setNavTabSearch(value, "pmv", null, null);
  };

  injectItems = () => {
    const {
      filteredPmvs,
      isPmvVisible,
      isAesysPanelVisible,
      isSolariPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      isPmvLoading,
    } = this.props;

    return (isPmvVisible ||
      isAesysPanelVisible ||
      isSolariPanelVisible ||
      isFuturitPanelVisible ||
      isVisuallabPanelVisible || 
      isSfheraPanelVisible) &&
      isPmvLoading ? (
      <ErrorBoundary>
        <TabPanel className="tabsContainerTpl inherit-transition">
          <Loading />
        </TabPanel>
      </ErrorBoundary>
    ) : (isPmvVisible ||
        isAesysPanelVisible ||
        isSolariPanelVisible ||
        isFuturitPanelVisible ||
        isVisuallabPanelVisible || 
        isSfheraPanelVisible) &&
      !isPmvLoading ? (
      <ErrorBoundary>
        <TabPanel className="tabsContainerTpl inherit-transition">
          <ul className="uk-tab uk-flex-between">
            <li className={"uk-active"}>
              <a href="/#">
                {"Pannelli (" + (filteredPmvs && filteredPmvs.length) + ")"}
              </a>
            </li>
          </ul>
          <PmvsContainer></PmvsContainer>
        </TabPanel>
      </ErrorBoundary>
    ) : null;
  };

  render() {
    return (
      <NavTab
        isOpen={true}
        items={this.injectItems()}
        onSearch={this.handleSearch}
      />
    );
  }

  componentWillUnmount() {
    const { setNavTabSearch } = this.props;
    this.subscriptions$.forEach((x) => x.unsubscribe());
    setNavTabSearch("", "pmv", null, null);
  }
}

PmvPage.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
};

const mapStateToProps = (state) => ({
  //UI
  menuNavTabSearch: getNavTabSearch(state),
  menuTabReducer: getNavTabSelectedTab(state),
  isOpenNavTab: isOpenNavTab(state),
  isPmvVisible: isPmvVisible(state),
  isSolariPanelVisible: isSolariPanelVisible(state),
  isAesysPanelVisible: isAesysPanelVisible(state),
  isFuturitPanelVisible: isFuturitPanelVisible(state),
  isVisuallabPanelVisible: isVisuallabPanelVisible(state),
  isSfheraPanelVisible: isSfheraPanelVisible(state),
  //BBOX
  boundingBox: getBoundingBox(state),
  //PMV
  filteredPmvs: getFilteredPmvs(state),
  currentPmv: getCurrentPmv(state),
  //LOADING
  isPmvLoading: isPmvLoading(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PmvPage)
);
