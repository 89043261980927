import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";

class MapModalControls extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { zoomIn, zoomOut, goBackward, modeswitch } = this.props;
    return (
      <div>
        <div className="navigation uk-flex uk-flex-column">
          <div className="map-control-button uk-flex uk-flex-middle uk-flex-center">
            <span
              uk-tooltip="title: Zoom avanti"
              className="uk-icon cursor-pointer"
              uk-icon="icon: acr-map-options-zoom-in"
              onClick={zoomIn}
            ></span>
          </div>
          <div className="map-control-button uk-flex uk-flex-middle uk-flex-center">
            <span
              uk-tooltip="title: Zoom indietro"
              className="uk-icon cursor-pointer"
              uk-icon="icon: acr-map-options-zoom-out"
              onClick={zoomOut}
            ></span>
          </div>
          {modeswitch !== "rds-tmc-puntuale" &&
            modeswitch !== "rds-tmc-estesa" && (
              <div className="map-control-button uk-flex uk-flex-middle uk-flex-center control-border-radius-bottom">
                <span
                  uk-tooltip="title: Indietro"
                  className="uk-icon cursor-pointer"
                  uk-icon="icon: acr-map-options-back"
                  onClick={goBackward}
                ></span>
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MapModalControls)
);
