export const BottombarActions = {
  CLOSE_BOTTOMBAR: "UI/BOTTOMBAR/CLOSE",
  ADD_BOTTOMBAR_TAB: "UI/ADD_BOTTOMBAR_TAB",
  REMOVE_BOTTOMBAR_TAB: "UI/REMOVE_BOTTOMBAR_TAB",
  SET_BOTTOMBAR_ACTIVE_TAB: "UI/SET_BOTTOMBAR_ACTIVE_TAB",
  EXPAND_BOTTOMBAR: "UI/EXPAND_BOTTOMBAR",
  REDUCE_BOTTOMBAR: "UI/REDUCE_BOTTOMBAR",
  RESET_BOTTOMBAR_TABS: "UI/RESET_BOTTOMBAR_TABS",
};

export const expandBottombar = () => (dispatch) => {
  dispatch({
    type: BottombarActions.EXPAND_BOTTOMBAR,
  });
};

export const reduceBottombar = () => (dispatch) => {
  dispatch({
    type: BottombarActions.REDUCE_BOTTOMBAR,
  });
};

export const closeBottombar = () => (dispatch) => {
  dispatch({
    type: BottombarActions.CLOSE_BOTTOMBAR,
  });
};

export const addBottombarTab = (tab) => (dispatch) => {
  dispatch({
    type: BottombarActions.ADD_BOTTOMBAR_TAB,
    payload: tab,
  });
};

export const removeBottombarTab = (tab) => (dispatch) => {
  dispatch({
    type: BottombarActions.REMOVE_BOTTOMBAR_TAB,
    payload: tab,
  });
};

export const resetBottombarTabs = () => (dispatch) => {
  dispatch({
    type: BottombarActions.RESET_BOTTOMBAR_TABS,
  });
};

export const setBottombarActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: BottombarActions.SET_BOTTOMBAR_ACTIVE_TAB,
    payload: tab,
  });
};
