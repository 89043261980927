import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import configureStore from "./store";

import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";

import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
import UIkitIcons from "@almaviva/acr-uikit/dist/js/uikit-icons-acr.js";
import "@almaviva/acr-uikit/dist/css/uikit.min.css";
import "@almaviva/acr-uikit/src/less/uikit.theme.less";

UIkitIcons(UIkit);

const rootElement = document.getElementById("root");
const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
