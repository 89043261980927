import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  take,
  tap,
  catchError,
  switchMap,
  debounceTime,
  concatMap,
} from "rxjs/operators";
import { of } from "rxjs";
import * as moment from "moment";
import "../style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import { BehaviorSubject, Observable } from "rxjs";

//COMPONENTS
import Loading from "components/shared/loading/loading";
import TableHead from "components/shared/table-components/table-head";
import TableBody from "./table-body";
import TableFilters from "components/shared/table-top-filters/table-filters";
import PageNumbers from "components/shared/page-numbers/page-number";
import TableUpperTabs from "components/layout/tables/pmv-table/table-upper-tabs";
//STORE
import {
  isOpenNavTab,
  getSavedTableColumns,
  getSavedDateColumns,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedSearchText,
  getSavedFilters,
  getSavedToggledFilters,
  getUsername,
  getUserRoles,
} from "store";
import { togglePmvHistoryTable } from "reducers/ui/installations-menu/installations-menu.actions";
import {
  saveTableFilters,
  resetTableFilters,
  resetAllTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { addActivity } from "reducers/user/user.actions";
//UTILS
import {
  TABLE_HISTORY_COLUMNS,
  DATE_COLUMNS,
  initColumnsHistory,
} from "utils/table-utils/util-pmv-table";
import * as FilterUtils from "utils/filter-utils";
import { debounce } from "utils/utils";
import { getFiltersForPmvHistoryTable } from "utils/utils-pmv";
import {
  handleFilterStringChange,
  handleClickIncreasePage,
  handleClickDecreasePage,
  requestSortOrder,
  resetChildrenForHeaderDropdown,
  onDropdownCheckboxFilterClick,
  getCurrentPageNumber,
  resetFiltersState,
  initializeTableState,
  getTableStateSortConfig,
  changeDateFilter,
  setDateFilter,
} from "utils/table-utils/util-general-tables";
import { saveBlob } from "utils/hooks";
//MODELS
import { PmvAnagraficaAndStatusHistoryModel } from "reducers/installations/pmv/pmv.model";
import { UserActivityModel } from "reducers/user/user.model";

const FILTER_TARGET = "pmvHistoryFilters";

class PmvHistoryTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      currentListPmvs: [],
      ...initializeTableState,
      sortConfig: getTableStateSortConfig("pmv"),
      stateTableColumns: TABLE_HISTORY_COLUMNS,
    };
    this.getPmvListDebounced = debounce(this.getPmvList, 800);
    this.numberPage$ = new BehaviorSubject();
  }

  componentDidMount = () => {
    const { searchText } = this.props;
    this.apiService = createApiService(this.context);

    this.subscriptions.push(
      this.numberPage$
        .pipe(
          debounceTime(800),
          switchMap(
            (x) =>
              new Observable((observer) => {
                const { pageToDisplay } = this.state;
                if (pageToDisplay !== "") {
                  this.getPmvList();
                }
              })
          )
        )
        .subscribe()
    );

    if (searchText) {
      this.setState({
        ...this.state,
        filterStr: searchText,
        isTableDataLoading: true,
      });
    } else {
      this.setState({
        ...this.state,
        isTableDataLoading: true,
      });
    }
    this.tableConfigs();
  };

  tableConfigs = () => {
    const {
      tableColumns,
      dateColumns,
      saveTableFilters,
      searchText,
      dateFrom,
      dateTo,
    } = this.props;

    const { columns, date } = initColumnsHistory(tableColumns, dateColumns);
    let sld = FilterUtils.constructFlatMultiSelect(columns);
    let obj = {};
    sld.forEach((x) => {
      obj[x.name] = { ...x };
    });

    let newDateFrom = dateFrom;
    let newDateTo = dateTo;

    if (!dateFrom && !dateTo) {
      newDateTo = moment().toDate();
      newDateFrom = moment().subtract(3, "days").toDate();
    }
    saveTableFilters(
      {
        tableColumns: columns,
        dateColumns: date,
        dateFrom: newDateFrom,
        dateTo: newDateTo,
        searchText: searchText || "",
        toggledFilters: obj || {},
      },
      FILTER_TARGET
    );
    this.setState({
      selected: sld,
      searchText: searchText || "",
    });
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { tableFilters } = this.props;

    if (
      (tableFilters.dateColumns &&
        prevProps.tableFilters &&
        prevProps.tableFilters.dateColumns &&
        tableFilters.dateColumns !== prevProps.tableFilters.dateColumns) ||
      (tableFilters.dateFrom &&
        prevProps.tableFilters &&
        prevProps.tableFilters.dateFrom &&
        tableFilters.dateFrom !== prevProps.tableFilters.dateFrom) ||
      (tableFilters.dateTo &&
        prevProps.tableFilters &&
        prevProps.tableFilters.dateTo &&
        tableFilters.dateTo !== prevProps.tableFilters.dateTo)
    ) {
      this.getPmvList();
    }
  };

  getPmvList = () => {
    const { count, currentPage, stateTableColumns } = this.state;
    const { selectedPmv, dateFrom, dateTo } = this.props;

    const body = getFiltersForPmvHistoryTable(
      currentPage,
      count,
      stateTableColumns,
      dateFrom,
      dateTo
    );

    if (selectedPmv && selectedPmv.anagrafica && selectedPmv.anagrafica.id) {
      const jwt = sessionStorage.getItem("id_token");

      this.subscriptions.push(
        this.apiService
          .getPmvListStatusHistory(selectedPmv.anagrafica.id, body, jwt)
          .pipe(
            take(1),
            tap((data) => {
              let pmvs = PmvAnagraficaAndStatusHistoryModel.from(data);
              let pageMax = Math.ceil(data.totalHits / count);

              this.setState({
                ...this.state,
                isTableDataLoading: false,
                currentListPmvs: pmvs,
                pageMax: pageMax,
              });
            }),
            catchError((error) => {
              console.error(error);
              this.setState({
                ...this.state,
                isTableDataLoading: false,
                currentListPmvs: [],
                pageMax: 1,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  //CALL API TO OBTAIN NEW ARC LIST
  refreshData = () => {
    this.setState({
      ...this.state,
      currentListPmvs: [],
      isTableDataLoading: true,
    });
    this.getPmvList();
  };

  downloadExcel = () => {
    const { count, currentPage, stateTableColumns } = this.state;
    const { selectedPmv, dateFrom, dateTo } = this.props;

    const body = getFiltersForPmvHistoryTable(
      currentPage,
      count,
      stateTableColumns,
      dateFrom,
      dateTo
    );

    this.subscriptions.push(
      this.apiService
        .fetchPmvHistoryExcel(selectedPmv.anagrafica.id, body)
        .pipe(
          take(1),
          tap((blob) => {
            let filename =
              "pmv_storico_" +
              selectedPmv.anagrafica.nome +
              "_export_" +
              moment().format("DD-MM-YYYY HH:mm:ss") +
              ".csv";
            saveBlob(blob, filename);
          }),
          concatMap((data) => {
            return this.postPmvListDownloadActivity(data);
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  postPmvListDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;
    const { selectedPmv } = this.props;

    return this.apiService
      .postUserActivity({
        module: "VMS",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "' + selectedPmv.anagrafica.nome + ' Storico"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  handleFilterChange = (e) => {
    handleFilterStringChange(this, e, FILTER_TARGET, this.getPmvListDebounced);
  };

  // GESTIONE CHECKBOX: VISUALIZZAZIONE COLONNE
  handleSelectChange = (visibleColumns) => {
    FilterUtils.handleSelectChange(this, visibleColumns, FILTER_TARGET);
  };

  //GESTIONE CHECKBOX FILTRI SU COLONNE
  onCheckboxFilterClick = (newTableColumns) => {
    this.setState({
      ...this.state,
      isTableDataLoading: true,
    });
    onDropdownCheckboxFilterClick(
      this,
      this.getPmvList,
      FILTER_TARGET,
      newTableColumns
    );
  };

  getPageNumber = (pageNumber) => {
    getCurrentPageNumber(this, pageNumber);
  };

  handleClickIncrease = () => {
    handleClickIncreasePage(this);
  };

  handleClickDecrease = () => {
    handleClickDecreasePage(this);
  };

  requestSort = (key) => {
    requestSortOrder(this, key, this.getPmvList);
  };

  onChangeDate = (value, name) => {
    const { dateFrom, dateTo } = this.props;

    if (name === "dateStartEvent") {
      let duration = moment.duration(moment(dateTo).diff(value));
      let minutes = duration.asMinutes();
      //NO MORE THAN 3 DAYS
      if (minutes <= 4320) {
        changeDateFilter(this, value, name, FILTER_TARGET);
      } else {
        changeDateFilter(this, value, name, FILTER_TARGET);
        let newMaxValue = moment(value).add(3, "days").toDate();
        changeDateFilter(this, newMaxValue, "dateEndEvent", FILTER_TARGET);
      }
    } else if (name === "dateEndEvent") {
      let duration = moment.duration(moment(value).diff(dateFrom));
      let minutes = duration.asMinutes();
      //NO MORE THAN 3 DAYS
      if (minutes <= 4320) {
        changeDateFilter(this, value, name, FILTER_TARGET);
      } else {
        changeDateFilter(this, value, name, FILTER_TARGET);
        let newMaxValue = moment(value).subtract(3, "days").toDate();
        changeDateFilter(this, newMaxValue, "dateStartEvent", FILTER_TARGET);
      }
    }
  };

  handleResetDateFilterTarget = () => {
    const { saveTableFilters, tableFilters, dateColumns } = this.props;
    let selected = [...dateColumns].map((element) => {
      if (element.id !== "validita") {
        element.selected = false;
      }
      return element;
    });

    saveTableFilters(
      {
        ...tableFilters,
        dateTo: moment().toDate(),
        dateFrom: moment().subtract(3, "days").toDate(),
        dateColumns: [...selected],
      },
      FILTER_TARGET
    );

    this.getPmvList();
  };

  handleSetDateFilterTarget = (date) => {
    setDateFilter(this, date, FILTER_TARGET);
  };

  resetFilters = (e) => {
    const { toggledFilters, resetAllTableFilters } = this.props;
    const { selected, stateTableColumns } = this.state;

    e.stopPropagation();

    let newStateTableColumns = resetChildrenForHeaderDropdown(
      stateTableColumns
    );

    resetAllTableFilters(
      {
        tableColumns: { ...newStateTableColumns },
        dateColumns: [...DATE_COLUMNS],
        toggledFilters: toggledFilters,
        dateTo: moment().toDate(),
        dateFrom: moment().subtract(3, "days").toDate(),
      },
      FILTER_TARGET
    );

    resetFiltersState(
      this,
      newStateTableColumns,
      selected,
      this.getPmvList,
      "pmv"
    );
  };

  toTableColumns = (pmv) => {
    let newList = [];

    let newEl = {};
    pmv &&
      pmv.statiPerPannello &&
      pmv.statiPerPannello.length > 0 &&
      pmv.statiPerPannello.forEach((panel) => {
        const pmvGif =
          panel && panel.statoOperativo && panel.statoOperativo.gifBase64
            ? "data:image/gif;base64," + panel.statoOperativo.gifBase64
            : "";
        newEl = {
          Nome:
            pmv && pmv.anagrafica && pmv.anagrafica.nome
              ? pmv.anagrafica.nome
              : "-",
          strada:
            pmv && pmv.anagrafica && pmv.anagrafica.strada
              ? pmv.anagrafica.strada
              : "-",

          statoFunzionamento:
            panel && panel.statoFunzionamento ? panel.statoFunzionamento : "-",
          pmvGif:
            pmvGif !== "" ? (
              <div className="uk-flex uk-flex-row uk-flex-center uk-flex-middle">
                <div
                  className={
                    panel &&
                    panel.statiPerPannello &&
                    panel.statiPerPannello.length > 0 &&
                    panel.statoOperativo &&
                    panel.statoOperativo.lampeggiante &&
                    panel.statoOperativo.lampeggiante === "Attivo"
                      ? "lampeggiante-giallo-table"
                      : panel &&
                        panel.statiPerPannello &&
                        panel.statiPerPannello.length > 0 &&
                        panel.statoOperativo &&
                        panel.statoOperativo.lampeggiante &&
                        panel.statoOperativo.lampeggiante === "Disattivo"
                      ? "lampeggiante-grigio-table"
                      : ""
                  }
                ></div>
                <img className="pmv-panel-table" alt="" src={pmvGif} />{" "}
                <div
                  className={
                    panel &&
                    panel.statiPerPannello &&
                    panel.statiPerPannello.length > 0 &&
                    panel.statoOperativo &&
                    panel.statoOperativo.lampeggiante &&
                    panel.statoOperativo.lampeggiante === "Attivo"
                      ? "lampeggiante-giallo-table"
                      : panel &&
                        panel.statiPerPannello &&
                        panel.statiPerPannello.length > 0 &&
                        panel.statoOperativo &&
                        panel.statoOperativo.lampeggiante &&
                        panel.statoOperativo.lampeggiante === "Disattivo"
                      ? "lampeggiante-grigio-table"
                      : ""
                  }
                ></div>
              </div>
            ) : (
              "-"
            ),
          pmvMsgName:
            panel && panel.statoOperativo && panel.statoOperativo.nomeMessaggio
              ? panel.statoOperativo.nomeMessaggio
              : "-",
          pmvExpositionLevel:
            panel &&
            panel.statoOperativo &&
            panel.statoOperativo.livelloEsposizione
              ? panel.statoOperativo.livelloEsposizione
              : "-",
          pmvDescriptionExposition:
            panel &&
            panel.statoOperativo &&
            panel.statoOperativo.descrizioneEsposizione
              ? panel.statoOperativo.descrizioneEsposizione
              : "-",
          pmvValidityFrom:
            panel && panel.periodo && panel.periodo.da
              ? moment(panel.periodo.da).format("DD/MM/YYYY HH:mm")
              : "-",

          pmvValidityTo:
            panel && panel.periodo && panel.periodo.a
              ? moment(panel.periodo.a).format("DD/MM/YYYY HH:mm")
              : "-",
          tipologia:
            pmv && pmv.anagrafica && pmv.anagrafica.tipologia
              ? pmv.anagrafica.tipologia
              : "-",
        };

        newList = [...newList, newEl];
      });

    return newList;
  };

  render = () => {
    const {
      sortConfig,
      filterStr,
      selected,
      isTableDataLoading,
      currentListPmvs,
      pageMax,
      pageToDisplay,
    } = this.state;
    const {
      isOpenNav,
      dateColumns,
      tableColumns,
      changeSelectedTab,
      changeSelectedPmvAndTab,
      selectedTab,
      selectedPmv,
      dateFrom,
      dateTo,
    } = this.props;

    let listOfPmv = [];

    if (currentListPmvs) {
      //MAPPA PER VISUALIZZAZIONE SU TABELLA
      listOfPmv = this.toTableColumns(currentListPmvs);
    }

    return (
      <div className="uk-flex uk-flex-column uk-width-1-1 uk-height-1-1">
        <div className="navbar-placeholder"></div>
        <TableUpperTabs
          changeSelectedTab={changeSelectedTab}
          changeSelectedPmvAndTab={changeSelectedPmvAndTab}
          selectedTab={selectedTab}
          selectedPmv={selectedPmv}
        />
        <TableFilters
          isOpenNav={isOpenNav}
          dateStart={{
            date: dateFrom ? dateFrom : null,
            name: "dateStartEvent",
          }}
          dateEnd={{ date: dateTo ? dateTo : null, name: "dateEndEvent" }}
          changeDate={this.onChangeDate}
          handleFilterChange={this.handleFilterChange}
          handleSelectChange={this.handleSelectChange}
          handleResetDateFilterTarget={this.handleResetDateFilterTarget}
          handleSetDateFilterTarget={this.handleSetDateFilterTarget}
          resetFilters={this.resetFilters}
          filterStr={filterStr}
          filtrableDateColumns={dateColumns}
          selected={selected}
          refreshData={this.refreshData}
          disabledDataFilter={false}
          inputFieldDisabled={true}
        ></TableFilters>
        <div className="uk-flex uk-flex-right button-bar">
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.refreshData}
            >
              <span
                uk-icon="icon: refresh"
                uk-tooltip="Refresh tabella Pannelli"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              disabled={false}
              onClick={this.downloadExcel}
            >
              <span
                uk-icon="icon: download"
                uk-tooltip="Download Excel"
                className="filterBarIcons borderWhite"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
              onClick={this.resetFilters}
            >
              <span
                uk-icon="icon: acr-filter-remove"
                uk-tooltip="Cancella Filtri"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-flex uk-flex-right button-bar uk-padding-remove-left">
            <PageNumbers
              handleClickDecrease={this.handleClickDecrease}
              handleClickIncrease={this.handleClickIncrease}
              getPageNumber={this.getPageNumber}
              currentPage={pageToDisplay}
              minVal={1}
              pageMax={pageMax}
            />
          </div>
        </div>
        <div className="uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left ">
          <table
            id="eventTable"
            className="uk-table uk-table-divider uk-table-hover"
          >
            <thead>
              <TableHead
                onCheckboxFilterClick={this.onCheckboxFilterClick}
                requestSort={this.requestSort}
                tableColumns={tableColumns}
                sortConfig={sortConfig}
              />
            </thead>
            <tbody>
              {isTableDataLoading ? (
                <tr>
                  <td>
                    <Loading />
                  </td>
                </tr>
              ) : (
                <TableBody list={listOfPmv} tableColumns={tableColumns} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

PmvHistoryTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  saveTableFilters,
  resetTableFilters,
  togglePmvHistoryTable,
  addActivity,
  resetAllTableFilters,
};

const mapStateToProps = (state) => ({
  isOpenNav: isOpenNavTab(state),
  tableColumns: getSavedTableColumns(state, FILTER_TARGET),
  dateColumns: getSavedDateColumns(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
  searchText: getSavedSearchText(state, FILTER_TARGET),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  toggledFilters: getSavedToggledFilters(state, FILTER_TARGET),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PmvHistoryTable)
);
