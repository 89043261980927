export const VehiclesActions = {
  GET_VEHICLES: "APP/GET_VEHICLES",
  SET_CURRENT_VEHICLE: "APP/SET_CURRENT_VEHICLE",
  RESET_CURRENT_VEHICLE: "APP/RESET_CURRENT_VEHICLE",
  SET_VEHICLES_EVENTS: "APP/SET_VEHICLES_EVENTS",
  RESET_VEHICLES: "APP/RESET_VEHICLES",
  UPDATE_EVENTS_VEHICLES: "APP/UPDATE_EVENTS_VEHICLES",
  SET_CURRENT_ID_ROUTE_VEHICLE: "APP/SET_CURRENT_ID_ROUTE_VEHICLE",
  SET_CURRENT_ID_STOP_VEHICLE: "APP/SET_CURRENT_ID_STOP_VEHICLE",
};

export const getVehicles = (data) => (dispatch) => {
  dispatch({
    type: VehiclesActions.GET_VEHICLES,
    payload: data,
  });
};

export const setCurrentVehicle = (data) => (dispatch) => {
  dispatch({
    type: VehiclesActions.SET_CURRENT_VEHICLE,
    payload: data,
  });
};

export const resetCurrentVehicle = () => (dispatch) => {
  dispatch({
    type: VehiclesActions.RESET_CURRENT_VEHICLE,
    payload: null,
  });
};

export const setVehicleEvents = (data) => (dispatch) => {
  dispatch({
    type: VehiclesActions.SET_VEHICLES_EVENTS,
    payload: data,
  });
};

export const resetVehicles = () => (dispatch) => {
  dispatch({
    type: VehiclesActions.RESET_VEHICLES,
  });
};

export const updateVehicleEvents = (data) => (dispatch) => {
  dispatch({
    type: VehiclesActions.UPDATE_EVENTS_VEHICLES,
    payload: data,
  });
};

export const setCurrentIdRouteForVehicle = (routeIdForTrip) => (dispatch) => {
  dispatch({
    type: VehiclesActions.SET_CURRENT_ID_ROUTE_VEHICLE,
    payload: routeIdForTrip,
  });
};

export const setCurrentIdStopForVehicle = (stopIdForTrip) => (dispatch) => {
  dispatch({
    type: VehiclesActions.SET_CURRENT_ID_STOP_VEHICLE,
    payload: stopIdForTrip,
  });
};
