import IconClusterLayer from "./icon-cluster-layer";
import iconMapping from "assets/cluster/location-icon-mapping-2.json";
import iconAtlas from "assets/cluster/location-icon-atlas-2.png";
import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";

class RealTimeLayer extends CompositeLayer {
  renderLayers() {
    const {
      vehiclesEventsReducer,
      viewport,
      layoutMap,
      isDrawingMode,
    } = this.props;
    let data = vehiclesEventsReducer;

    if (viewport.zoom < 15 && data && data.length > 1) {
      return new IconClusterLayer(
        this.getSubLayerProps({
          id: "icon-cluster-bus",
          data,
          pickable: false,
          getPosition: (d) =>
            d.vehicleActivityLocation &&
            d.vehicleActivityLocation.monitoredVehicleJourney &&
            d.vehicleActivityLocation.monitoredVehicleJourney.vehicleLocation
              ? [
                  d.vehicleActivityLocation.monitoredVehicleJourney
                    .vehicleLocation.longitude,
                  d.vehicleActivityLocation.monitoredVehicleJourney
                    .vehicleLocation.latitude,
                ]
              : [0, 0],
          iconAtlas,
          iconMapping,
          sizeScale: 60,
        })
      );
    } else {
      return new IconLayer(
        this.getSubLayerProps({
          id: "icon-bus",
          data: data,
          pickable: isDrawingMode ? false : true,
          ...DeckLayerUtil.loadOptions(),
          getIcon: (obj) => ({
            url: DeckLayerUtil.iconBus(obj, layoutMap),
            width: 110,
            height: 140,
            anchorX: 55,
            anchorY: 100,
          }),
          sizeScale: 8,
          showCluster: true,
          visible: true,
          currentTime: new Date(),
          getPosition: (d) =>
            d.vehicleActivityLocation &&
            d.vehicleActivityLocation.monitoredVehicleJourney &&
            d.vehicleActivityLocation.monitoredVehicleJourney.vehicleLocation
              ? [
                  d.vehicleActivityLocation.monitoredVehicleJourney
                    .vehicleLocation.longitude,
                  d.vehicleActivityLocation.monitoredVehicleJourney
                    .vehicleLocation.latitude,
                ]
              : [0, 0],
          getSize: () => 8,
        })
      );
    }
  }
}

RealTimeLayer.layerName = "RealTimeLayer";

export default RealTimeLayer;
