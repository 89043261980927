import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentPointRdsTmc, getSegmentsRdsTmc } from "store";
//ASSETS
import icn_map_pin_green from "assets/icon/graph/icn_map_pin_green.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";

class PointRdsTmcDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  header = () => {
    const { togglePointDetail, currentPoint } = this.props;

    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="uk-flex uk-flex-middle uk-flex-center">
          <div className="parent uk-flex uk-flex-middle uk-flex-center">
            <div className="circle2"></div>
            <img
              alt="icn_diamond"
              src={icn_diamond}
              className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
            ></img>
            <img
              alt=" icn_map_pin_green"
              src={icn_map_pin_green}
              className="image2 uk-flex uk-flex-middle uk-flex-center"
            ></img>
          </div>
        </div>
        <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-emphasis-arc tratte-title-height">
          {currentPoint && currentPoint.properties.name1
            ? currentPoint.properties.name1
            : "-"}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            togglePointDetail(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { currentPoint } = this.props;

    return (
      <div className="uk-flex uk-flex-column contentBodyEventDetail">
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Nome LCD</div>
          <div className="text-detail-emphasis-arc">
            {currentPoint && currentPoint.properties.name1
              ? currentPoint.properties.name1
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">ID LCD</div>
          <div className="text-detail-emphasis-arc">
            {currentPoint && currentPoint.properties.lcd
              ? currentPoint.properties.lcd
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Latitudine</div>
          <div className="text-detail-emphasis-arc">
            {currentPoint && currentPoint.geometry.coordinates[1]
              ? currentPoint.geometry.coordinates[1]
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Longitudine</div>
          <div className="text-detail-emphasis-arc">
            {currentPoint && currentPoint.geometry.coordinates[0]
              ? currentPoint.geometry.coordinates[0]
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Nome Tratta</div>
          <div className="text-detail-emphasis-arc">
            {currentPoint && currentPoint.properties.road_number
              ? currentPoint.properties.road_number
              : "-"}{" "}
            {currentPoint && currentPoint.properties.name_road
              ? currentPoint.properties.name_road
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Urbano</div>
          <div className="text-detail-emphasis-arc">
            {currentPoint && currentPoint.urban ? "Sì" : "No"}
          </div>
        </li>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPoint: getCurrentPointRdsTmc(state),
  segmentsList: getSegmentsRdsTmc(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PointRdsTmcDetail)
);
