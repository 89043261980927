export const TABLE_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: true,
    order: true,
  },
  parkingTableName: {
    name: "Nome",
    id: "parkingTableName",
    show: true,
    order: false,
  },
  parkingDescription: {
    name: "Descrizione",
    id: "parkingDescription",
    show: true,
    order: false,
  },
  parkingAddress: {
    name: "Indirizzo",
    id: "parkingAddress",
    show: true,
    order: false,
  },
  parkingCoordinates: {
    name: "Coordinate",
    id: "parkingCoordinates",
    show: true,
    order: false,
  },
  responsibleAuthorityName: {
    name: "Gestore",
    id: "responsibleAuthorityName",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-gestore",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Amt",
        checked: true,
        id: "contact-amt",
        isChild: true,
        name: "Amt",
      },
      {
        label: "Apcoa Italia",
        checked: true,
        id: "contact-apcoa",
        isChild: true,
        name: "Apcoa Italia",
      },
      {
        label: "Grandi Stazioni",
        checked: true,
        id: "contact-grandi-stazioni",
        isChild: true,
        name: "Grandi Stazioni",
      },
      {
        label: "Saba Italia",
        checked: true,
        id: "contact-saba",
        isChild: true,
        name: "Saba Italia",
      },
    ],
    show: true,
    order: true,
  },
  parkingPayment: {
    name: "Tipologia",
    id: "parkingPayment",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-payment",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Libero",
        checked: true,
        id: "FREE",
        isChild: true,
        name: "Libero",
      },
      {
        label: "A Pagamento",
        checked: true,
        id: "PAID",
        isChild: true,
        name: "A Pagamento",
      },
    ],
    show: true,
    order: true,
  },
  parkingLayout: {
    name: "Tipo Struttura",
    id: "parkingLayout",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-layout",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Multipiano",
        checked: true,
        id: "MULTI_STOREY",
        isChild: true,
        name: "Multipiano",
      },
      {
        label: "Parcheggio singolo livello",
        checked: true,
        id: "SINGLE_LEVEL",
        isChild: true,
        name: "Parcheggio singolo livello",
      },
      {
        label: "Parcheggio sotterraneo",
        checked: true,
        id: "UNDERGROUND",
        isChild: true,
        name: "Parcheggio sotterraneo",
      },
      {
        label: "Multipiano sotterraneo",
        checked: true,
        id: "UNDERGROUND_AND_MULTISTOREY",
        isChild: true,
        name: "Multipiano sotterraneo",
      },
      {
        label: "Parcheggio automatizzato",
        checked: true,
        id: "AUTOMATED_PARKING_GARAGE",
        isChild: true,
        name: "Parcheggio automatizzato",
      },
      {
        label: "Open space",
        checked: true,
        id: "OPEN_SPACE",
        isChild: true,
        name: "Open space",
      },
      {
        label: "Parcheggio coperto",
        checked: true,
        id: "COVERED",
        isChild: true,
        name: "Parcheggio coperto",
      },
      {
        label: "Parcheggio annidato",
        checked: true,
        id: "NESTED",
        isChild: true,
        name: "Parcheggio annidato",
      },
      {
        label: "Parcheggio sterrato",
        checked: true,
        id: "FIELD",
        isChild: true,
        name: "Parcheggio sterrato",
      },
      {
        label: "Sconosciuto",
        checked: true,
        id: "UNKNOWN",
        isChild: true,
        name: "Sconosciuto",
      },
      {
        label: "Altro",
        checked: true,
        id: "OTHER",
        isChild: true,
        name: "Altro",
      },
    ],
    show: true,
    order: true,
  },
  parkingOpeningStatus: {
    name: "Stato Apertura",
    id: "parkingOpeningStatus",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-status",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Chiuso",
        checked: true,
        id: "CLOSED",
        isChild: true,
        name: "Chiuso",
      },
      // {
      //   label: "Chiusura anomala",
      //   checked: true,
      //   id: "CLOSED_ABNORMAL",
      //   isChild: true,
      //   name: "Chiusura anomala",
      // },
      {
        label: "Aperto",
        checked: true,
        id: "OPEN",
        isChild: true,
        name: "Aperto",
      },
      // {
      //   label: "Aperto da orari (apertura non garantita)",
      //   checked: true,
      //   id: "OPENING_TIMES_IN_FORCE",
      //   isChild: true,
      //   name: "Aperto da orari (apertura non garantita)",
      // },
      {
        label: "Sconosciuto",
        checked: true,
        id: "STATUS_UNKNOWN",
        isChild: true,
        name: "Sconosciuto",
      },
      {
        label: "Altro",
        checked: true,
        id: "OTHER",
        isChild: true,
        name: "Altro",
      },
    ],
    show: true,
    order: false,
  },

  parkingOperationalStatus: {
    name: "Stato Operativo",
    id: "parkingOperationalStatus",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-status",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Libero",
        checked: true,
        id: "SPACES_AVAILABLE",
        isChild: true,
        name: "Libero",
      },
      {
        label: "Completo",
        checked: true,
        id: "FULL",
        isChild: true,
        name: "Completo",
      },
      {
        label: "Completo all'entrata",
        checked: true,
        id: "FULL_AT_ENTRANCE",
        isChild: true,
        name: "Completo all'entrata",
      },
      {
        label: "Quasi completo",
        checked: true,
        id: "ALMOST_FULL",
        isChild: true,
        name: "Quasi completo",
      },
      {
        label: "Sconosciuto",
        checked: true,
        id: "UNKNOWN",
        isChild: true,
        name: "Sconosciuto",
      },
      {
        label: "Altro",
        checked: true,
        id: "OTHER",
        isChild: true,
        name: "Altro",
      },
    ],
    show: true,
    order: false,
  },
  parkingNumberOfSpaces: {
    name: "Capacità Massima",
    id: "parkingNumberOfSpaces",
    show: true,
    order: false,
  },
  parkingNumberOfSpacesOverride: {
    name: "Capacità Attuale",
    id: "parkingNumberOfSpacesOverride",
    show: true,
    order: false,
  },
  parkingNumberOfOccupiedSpaces: {
    name: "N° Posti Occupati",
    id: "parkingNumberOfOccupiedSpaces",
    show: true,
    order: false,
  },
  parkingNumberOfVacantSpaces: {
    name: "N° Posti Liberi",
    id: "parkingNumberOfVacantSpaces",
    show: true,
    order: false,
  },
  parkingOccupancy: {
    name: "% Occupazione",
    id: "parkingOccupancy",
    show: true,
    order: false,
  },
  latestParkingUpdate: {
    name: "Ultimo Aggiornamento",
    id: "latestParkingUpdate",
    show: true,
    order: false,
  },
};

export const DATE_COLUMNS = [];

export const initColumns = (tableColumns = null, dateColumns = null) => {
  let columns = {};
  let date = [];
  if (
    !tableColumns ||
    (tableColumns &&
      Object.keys(TABLE_COLUMNS)[1] !== Object.keys(tableColumns)[1])
  ) {
    columns = { ...TABLE_COLUMNS };
    date = [...DATE_COLUMNS];
  } else {
    columns = { ...tableColumns };
    date = dateColumns ? [...dateColumns] : [];
  }

  return { columns: columns, date: date };
};
