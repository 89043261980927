import { Component } from "react";
import { connect } from "react-redux";
import { getPermissions } from "store";
import { PropTypes } from "prop-types";

const mapStateToProps = (state) => ({
  permissions: getPermissions(state),
});

@connect(mapStateToProps)
class Permission extends Component {
  render() {
    const { permissions, actions, children, every } = this.props;

    if (Array.isArray(actions)) {
      if (
        (every && actions.every((x) => permissions.indexOf(x) >= 0)) ||
        (!every && actions.some((x) => permissions.indexOf(x) >= 0))
      ) {
        return children;
      }
    } else if (permissions.indexOf(actions) >= 0) {
      return children;
    }

    return null;
  }
}

Permissions.defaultProps = {
  every: false,
};

Permissions.propTypes = {
  every: PropTypes.boolean,
  actions: PropTypes.oneOf([PropTypes.array, PropTypes.string]),
};

export default Permission;
