import { NavTabActions } from "./nav-tab.actions";

export const INITIAL_STATE = {
  isOpenNavTab: false,
  tabTpl: 0,
  infoRoute: {
    open: false,
    list: [],
  },
  search: "",
  selectedTab: "",
};

export default function navTabReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NavTabActions.TOGGLE_NAVTAB:
      return {
        ...state,
        isOpenNavTab: action.payload,
      };
    case NavTabActions.SET_TAB:
      return {
        ...state,
        tabTpl: action.payload,
      };
    case NavTabActions.ADD_INFO_ROUTE:
      return {
        ...state,
        infoRoute: {
          ...state.infoRoute,
          list:
            action.payload instanceof Array
              ? action.payload
              : [...state.infoRoute.list, action.payload],
        },
      };
    case NavTabActions.RESET_INFO_ROUTE:
      return {
        ...state,
        infoRoute: {
          ...state.infoRoute,
          list: [],
          open: false,
        },
      };
    case NavTabActions.TOGGLE_INFO_ROUTE:
      return {
        ...state,
        infoRoute: {
          ...state.infoRoute,
          open: !state.infoRoute.open,
        },
      };
    case NavTabActions.SET_SEARCH:
      return {
        ...state,
        search:
          action.payload && action.payload.text ? action.payload.text : "",
      };
    case NavTabActions.SET_MULTITAB_TYPE:
      return {
        ...state,
        selectedTab: action.payload,
      };
    default:
      return state;
  }
}

export const isOpenNavTab = (state) => state.isOpenNavTab;
export const getNavTabSelectedTab = (state) => state.tabTpl;
export const getNavTabInfoRoute = (state) => state.infoRoute;
export const getNavTabSearch = (state) => state.search;
export const getSelectedMultitab = (state) => state.selectedTab;
