export const RoutesActions = {
  GET_ROUTES: "APP/GET_ROUTES",
  RESET_ROUTES: "APP/RESET_ROUTES",
  SET_CURRENT_ID_STOP_ROUTE: "APP/SET_CURRENT_ID_STOP_ROUTE",
  SET_CURRENT_ID_TRIP_ROUTE: "APP/SET_CURRENT_ID_TRIP_ROUTE",
};

export const getRoutes = (data) => (dispatch) => {
  dispatch({
    type: RoutesActions.GET_ROUTES,
    payload: data,
  });
};

export const resetRoutes = () => (dispatch) => {
  dispatch({
    type: RoutesActions.RESET_ROUTES,
  });
};

export const setCurrentIdStopForRoute = (stopIdForRoute) => (dispatch) => {
  dispatch({
    type: RoutesActions.SET_CURRENT_ID_STOP_ROUTE,
    payload: stopIdForRoute,
  });
};

export const setCurrentIdTripForRoute = (tripIdForRoute) => (dispatch) => {
  dispatch({
    type: RoutesActions.SET_CURRENT_ID_TRIP_ROUTE,
    payload: tripIdForRoute,
  });
};
