export const TrafficActions = {
  SET_TRAFFIC_LIST_ARCS: "TRAFFIC/SET_TRAFFIC_LIST_ARCS",
  SET_TRAFFIC_CURRENT_ARC: "TRAFFIC/SET_TRAFFIC_CURRENT_ARC",
  SET_TRAFFIC_CURRENT_ARC_MAP: "TRAFFIC/SET_TRAFFIC_CURRENT_ARC_MAP",
  SET_CONTEXTS: "TRAFFIC/SET_CONTEXTS",
  SET_CURRENT_CONTEXT: "TRAFFIC/SET_CURRENT_CONTEXT",
  SET_MEASURES: "TRAFFIC/SET_MEASURES",
  SET_CURRENT_MEASURE: "TRAFFIC/SET_CURRENT_MEASURE",
  SET_DAY_TYPES: "TRAFFIC/SET_DAY_TYPES",
  SET_CURRENT_DAY_TYPE: "TRAFFIC/SET_CURRENT_DAY_TYPE",
  SET_CURRENT_DAY: "TRAFFIC/SET_CURRENT_DAY",
  SET_VEHICLE_CLASSES: "TRAFFIC/SET_VEHICLE_CLASSES",
  SET_CURRENT_VEHICLE_CLASS: "TRAFFIC/SET_CURRENT_VEHICLE_CLASS",
  SET_CURRENT_TIME: "TRAFFIC/SET_CURRENT_TIME",
  SET_CURRENT_START_TIME: "TRAFFIC/SET_CURRENT_START_TIME",
  SET_CURRENT_END_TIME: "TRAFFIC/SET_CURRENT_END_TIME",
  SET_CURRENT_TIME_OFFSET: "TRAFFIC/SET_CURRENT_TIME_OFFSET",
  RESET_TRAFFIC_CURRENT_ARC_MAP: "TRAFFIC/RESET_TRAFFIC_CURRENT_ARC_MAP",
  SET_CURRENT_DATE_SLIDER: "TRAFFIC/SET_CURRENT_DATE_SLIDER",
  UPDATE_TRAFFIC_NOTIFICATION_WS: "TRAFFIC/UPDATE_TRAFFIC_NOTIFICATION_WS",
  RESET_TRAFFIC_NOTIFICATION_WS: "TRAFFIC/RESET_TRAFFIC_NOTIFICATION_WS",
};

export const setTrafficArcsList = (list) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_TRAFFIC_LIST_ARCS,
    payload: { list },
  });
};

export const setTrafficCurrentArc = (current) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_TRAFFIC_CURRENT_ARC,
    payload: current,
  });
};

export const setTrafficCurrentArcMap = (currentMap) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_TRAFFIC_CURRENT_ARC_MAP,
    payload: currentMap,
  });
};

export const setTrafficContexts = (contexts) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CONTEXTS,
    payload: contexts,
  });
};

export const setTrafficCurrentContext = (context) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_CONTEXT,
    payload: context,
  });
};

export const setMeasures = (measures) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_MEASURES,
    payload: measures,
  });
};

export const setCurrentMeasure = (measure) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_MEASURE,
    payload: measure,
  });
};

export const setDayTypes = (dayTypes) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_DAY_TYPES,
    payload: dayTypes,
  });
};

export const setCurrentDayType = (dayType) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_DAY_TYPE,
    payload: dayType,
  });
};

export const setCurrentDay = (day) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_DAY,
    payload: day,
  });
};

export const setVehicleClasses = (vehicleClasses) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_VEHICLE_CLASSES,
    payload: vehicleClasses,
  });
};

export const setCurrentVehicleClass = (vehicleClass) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_VEHICLE_CLASS,
    payload: vehicleClass,
  });
};

export const setCurrentTrafficTime = (time) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_TIME,
    payload: time,
  });
};

export const setCurrentTrafficStartTime = (time) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_START_TIME,
    payload: time,
  });
};

export const setCurrentTrafficEndTime = (time) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_END_TIME,
    payload: time,
  });
};

export const resetTrafficCurrentArcMap = () => (dispatch) => {
  dispatch({
    type: TrafficActions.RESET_TRAFFIC_CURRENT_ARC_MAP,
  });
};

export const setCurrentContextTimeOffSet = (time) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_TIME_OFFSET,
    payload: time,
  });
};

export const setCurrentDateSlider = (time) => (dispatch) => {
  dispatch({
    type: TrafficActions.SET_CURRENT_DATE_SLIDER,
    payload: time,
  });
};

export const updateTrafficNotificationsWS = (notifications) => (dispatch) => {
  dispatch({
    type: TrafficActions.UPDATE_TRAFFIC_NOTIFICATION_WS,
    payload: notifications,
  });
};

export const resetTrafficNotificationsWS = () => (dispatch) => {
  dispatch({
    type: TrafficActions.RESET_TRAFFIC_NOTIFICATION_WS,
    payload: null,
  });
};
