import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";
import map_pin from "assets/icon/modal/icn_map_pin.svg";

class PinLayer extends CompositeLayer {
  renderLayers() {
    const { list } = this.props;
    return new IconLayer(
      this.getSubLayerProps({
        id: "icon-pins-modal",
        data: list,
        pickable: true,
        ...DeckLayerUtil.loadOptions(),
        getIcon: (obj) => ({
          url: map_pin,
          width: 35,
          height: 40,
          anchorX: 10,
          anchorY: 35,
        }),
        sizeScale: 8,
        showCluster: false,
        visible: true,
        getPosition: (d) => [parseFloat(d.lon), parseFloat(d.lat), d.index],

        getSize: 8,
        parameters: {
          depthTest: false,
        },
      })
    );
  }
}

PinLayer.layerName = "PinLayerModal";

export default PinLayer;
