import React, { Component } from "react";
import "components/layout/modal/style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DeckGL from "@deck.gl/react";
import { MapView, MapController } from "@deck.gl/core";
import { ViewMode } from "nebula.gl";
import { _MapContext as MapContext, StaticMap } from "react-map-gl";
import * as turf from "@turf/turf";
//UTILS
import * as CustomLayers from "utils/map-utils/layer-difinitions";
//ACTIONS
import {
  setNewEvent,
  updateNewEvent,
  historyPrevPuntuale,
  historyPrevEstesa,
  setBounds,
} from "reducers/modal/modal.actions";
import { setZoomLocalization } from "reducers/ui/modal/modal.actions";
import {
  getLayoutMap,
  getNewEvent,
  getCurrentEvent,
  getTypeModal,
  getNewEventValid,
  getHoveredObjectsModal,
  getBoundingBoxCartografica,
  getZoomLocalization,
} from "store";
import { setHoveredPoint } from "reducers/modal/modal.actions";
import {
  enableForwardEventButton,
  disableForwardEventButton,
} from "reducers/ui/modal/modal.actions";
//COMPONENTS
import MapModalControls from "./map-modal-controls";
import EnvironmentContext from "../../../../../environment-context";
import * as MapListeners from "components/layout/map/listeners/listeners";
import ConfirmedArcLayer from "components/layout/modal/event/new-event/layers/confirm-arc-layer";
import TmcPointsLayer from "components/layout/modal/event/new-event/layers/tmc-points-layer";
import TmcSegmentsLayer from "components/layout/modal/event/new-event/layers/tmc-segments-layer";
import PointTooltip from "components/layout/modal/event/new-event/tooltip/point-tooltip";
import RadiusInfluenceLayer from "components/layout/modal/event/new-event/layers/radius-influence-layer";
import PinTextLayer from "components/layout/modal/event/new-event/layers/pin-text-layer";
import SelectedRedArcLayer from "components/layout/modal/event/new-event/layers/arc-puntuale-red-layer";
import RoutingDistanceLayer from "components/layout/modal/event/new-event/layers/routing-distance-layer";
import PinLayer from "components/layout/modal/event/new-event/layers/pin-layer";
import PinLayerInMiddleOfConfirmedArc from "components/layout/modal/event/new-event/layers/pin-layer-confirmed-arc";
class ModalMap extends Component {
  map;
  deck;
  staticmap;
  mapLayers = [];
  INITIAL_VIEW_STATE;

  constructor(props) {
    super(props);
    this.state = {
      viewport: null,
      style: null,
      mode: CustomLayers.shortestDistanceOnGraphLayer,
    };
    this.setMapRef = this.setMapRef.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
  }

  componentDidMount() {
    const { map } = this.context;
    const {
      mapLayout,
      newEvent,
      modeswitch,
      setBounds,
      filteredPointsList,
      zoomLocalization,
    } = this.props;

    map.mapOptions.zoom = zoomLocalization;

    this.INITIAL_VIEW_STATE = { ...map.mapOptions };

    this.setState({
      viewport: { ...this.INITIAL_VIEW_STATE },
      style: this.getMapLayout(mapLayout, map),
    });

    //SET MAP BOUNDS
    let bounds = [...map.mapBounds];
    setBounds(bounds);

    //ZOOM ON ALREADY SELECTED ELEMENT
    if (
      modeswitch === "puntuale" &&
      newEvent &&
      newEvent.confirmedArcPuntuale !== null &&
      newEvent.confirmedArcPuntuale.properties &&
      newEvent.confirmedArcPuntuale.geometry
    ) {
      let indArcPuntuale = 0;
      indArcPuntuale = Math.round(
        newEvent.confirmedArcPuntuale.geometry.coordinates.length / 2
      );
      let confirmedArcPuntualeMiddlePoint = {
        ...newEvent.confirmedArcPuntuale.geometry.coordinates[indArcPuntuale],
      };
      let latPuntuale = confirmedArcPuntualeMiddlePoint[1];
      let longPuntuale = confirmedArcPuntualeMiddlePoint[0];
      //LEAVE ANIMATION TO 0
      this.mapFlyTo(latPuntuale, longPuntuale, zoomLocalization, 0);
    }

    if (
      modeswitch === "estesa" &&
      newEvent &&
      newEvent.selectedArcEstesa !== null &&
      newEvent.selectedArcEstesa.properties &&
      newEvent.selectedArcEstesa.geometry
    ) {
      let indArcEstesa = 0;
      indArcEstesa = Math.round(
        newEvent.selectedArcEstesa.geometry.coordinates.length / 2
      );
      let selectedArcEstesaMiddlePoint = {
        ...newEvent.selectedArcEstesa.geometry.coordinates[indArcEstesa],
      };
      let latEstesa = selectedArcEstesaMiddlePoint[1];
      let longEstesa = selectedArcEstesaMiddlePoint[0];
      //LEAVE ANIMATION TO 0
      this.mapFlyTo(latEstesa, longEstesa, zoomLocalization, 0);
    }

    if (
      modeswitch === "rds-tmc-puntuale" &&
      newEvent &&
      newEvent.initialPointRDSPuntuale !== null &&
      newEvent.initialPointRDSPuntuale !== ""
    ) {
      const pointToZoom = filteredPointsList.find(
        (item) =>
          item.properties.lcd ===
          Number(this.props.newEvent.initialPointRDSPuntuale)
      );
      if (pointToZoom) {
        const lat = pointToZoom.latitudine;
        const long = pointToZoom.longitudine;
        this.mapFlyTo(lat, long, zoomLocalization, 0);
      }
    }

    if (
      modeswitch === "rds-tmc-estesa" &&
      newEvent &&
      newEvent.initialPointRDSEstesa !== null &&
      newEvent.initialPointRDSEstesa !== ""
    ) {
      const pointToZoom = filteredPointsList.find(
        (item) =>
          item.properties.lcd ===
          Number(this.props.newEvent.initialPointRDSEstesa)
      );
      if (pointToZoom) {
        const lat = pointToZoom.latitudine;
        const long = pointToZoom.longitudine;
        this.mapFlyTo(lat, long, zoomLocalization, 0);
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const {
      newEvent,
      filteredPointsList,
      modeswitch,
      segmentsList,
      zoomLocalization,
      mapLayout,
    } = this.props;
    const { viewport } = this.state;
    const { map } = this.context;

    if (prevProps.zoomLocalization !== zoomLocalization) {
      map.mapOptions.zoom = zoomLocalization;
      this.INITIAL_VIEW_STATE = { ...map.mapOptions };
      this.setState({
        viewport: { ...this.INITIAL_VIEW_STATE },
        style: this.getMapLayout(mapLayout, map),
      });
    }
    if (
      prevProps.newEvent.pointCoordinatesAfterMoving !==
        newEvent.pointCoordinatesAfterMoving &&
      newEvent.pointCoordinatesAfterMoving.length > 0
    ) {
      const lat =
        newEvent.pointCoordinatesAfterMoving &&
        newEvent.pointCoordinatesAfterMoving[1];
      const long =
        newEvent.pointCoordinatesAfterMoving &&
        newEvent.pointCoordinatesAfterMoving[0];
      this.mapFlyTo(lat, long, viewport.zoom, 1000);
    }

    if (
      prevProps.newEvent.pointCoordinatesAfterMovingEstesa !==
        newEvent.pointCoordinatesAfterMovingEstesa &&
      newEvent.pointCoordinatesAfterMovingEstesa.length > 0
    ) {
      const lat =
        newEvent.pointCoordinatesAfterMovingEstesa &&
        newEvent.pointCoordinatesAfterMovingEstesa[1];
      const long =
        newEvent.pointCoordinatesAfterMovingEstesa &&
        newEvent.pointCoordinatesAfterMovingEstesa[0];
      this.mapFlyTo(lat, long, viewport.zoom, 1000);
    }

    if (
      prevProps.newEvent.pointCoordinatesAfterMoving !==
        newEvent.pointCoordinatesAfterMoving &&
      newEvent.pointCoordinatesAfterMoving.length > 0
    ) {
      const lat =
        newEvent.pointCoordinatesAfterMoving &&
        newEvent.pointCoordinatesAfterMoving[1];
      const long =
        newEvent.pointCoordinatesAfterMoving &&
        newEvent.pointCoordinatesAfterMoving[0];
      this.mapFlyTo(lat, long, viewport.zoom, 1000);
    }

    if (
      prevProps.filteredPointsList !== filteredPointsList &&
      filteredPointsList.length > 0 &&
      prevProps.filteredPointsList.length !== filteredPointsList.length &&
      modeswitch === "rds-tmc-puntuale" &&
      newEvent &&
      newEvent.initialPointRDSPuntuale !== null &&
      newEvent.initialPointRDSPuntuale !== ""
    ) {
      const pointToZoom = filteredPointsList.find(
        (item) =>
          item.properties.lcd ===
          Number(this.props.newEvent.initialPointRDSPuntuale)
      );
      if (pointToZoom) {
        const lat = pointToZoom.geometry.coordinates[1];
        const long = pointToZoom.geometry.coordinates[0];
        this.mapFlyTo(lat, long, viewport.zoom, 1000);
      }
    }

    if (
      prevProps.filteredPointsList !== filteredPointsList &&
      filteredPointsList.length > 0 &&
      prevProps.filteredPointsList.length !== filteredPointsList.length &&
      modeswitch === "rds-tmc-estesa" &&
      newEvent &&
      newEvent.initialPointRDSEstesa !== null &&
      newEvent.initialPointRDSEstesa !== ""
    ) {
      const pointToZoom = filteredPointsList.find(
        (item) =>
          item.properties.lcd ===
          Number(this.props.newEvent.initialPointRDSEstesa)
      );
      if (pointToZoom) {
        const lat = pointToZoom.geometry.coordinates[1];
        const long = pointToZoom.geometry.coordinates[0];
        this.mapFlyTo(lat, long, viewport.zoom, 1000);
      }
    }

    //ZOOM ON NEWLY SELECTED ELEMENT
    /* if (
      newEvent.selectedArcPuntuale &&
      prevProps.newEvent.selectedArcPuntuale !== newEvent.selectedArcPuntuale
    ) {
      console.log("entro");
      let indArcPuntuale = 0;
      indArcPuntuale = Math.round(
        newEvent.selectedArcPuntuale.geometry.coordinates.length / 2
      );
      let selectedArcPuntualeMiddlePoint = {
        ...newEvent.selectedArcPuntuale.geometry.coordinates[indArcPuntuale],
      };
      let latPuntuale = selectedArcPuntualeMiddlePoint[1];
      let longPuntuale = selectedArcPuntualeMiddlePoint[0];
      this.mapFlyTo(latPuntuale, longPuntuale, viewport.zoom, 1000);
    }
    if (
      newEvent.selectedArcEstesa &&
      prevProps.newEvent.selectedArcEstesa !== newEvent.selectedArcEstesa
    ) {
      let indArcEstesa = 0;
      indArcEstesa = Math.round(
        newEvent.selectedArcEstesa.geometry.coordinates.length / 2
      );
      let selectedArcEstesaMiddlePoint = {
        ...newEvent.selectedArcEstesa.geometry.coordinates[indArcEstesa],
      };
      let latEstesa = selectedArcEstesaMiddlePoint[1];
      let longEstesa = selectedArcEstesaMiddlePoint[0];
      this.mapFlyTo(latEstesa, longEstesa, viewport.zoom, 1000);
    } */
    if (
      newEvent.initialPointRDSPuntuale &&
      prevProps.newEvent.initialPointRDSPuntuale !==
        newEvent.initialPointRDSPuntuale
    ) {
      const pointToZoom = filteredPointsList.find(
        (item) =>
          item.properties.lcd === Number(newEvent.initialPointRDSPuntuale)
      );
      if (pointToZoom) {
        const lat = pointToZoom.geometry.coordinates[1];
        const long = pointToZoom.geometry.coordinates[0];
        this.mapFlyTo(lat, long, viewport.zoom, 1000);
      }
    }
    if (
      newEvent.initialPointRDSEstesa &&
      prevProps.newEvent.initialPointRDSEstesa !==
        newEvent.initialPointRDSEstesa
    ) {
      const pointToZoom = filteredPointsList.find(
        (item) => item.properties.lcd === Number(newEvent.initialPointRDSEstesa)
      );
      if (pointToZoom) {
        const lat = pointToZoom.geometry.coordinates[1];
        const long = pointToZoom.geometry.coordinates[0];
        this.mapFlyTo(lat, long, viewport.zoom, 1000);
      }
    }
    if (
      newEvent.finalPointRDSEstesa &&
      prevProps.newEvent.finalPointRDSEstesa !== newEvent.finalPointRDSEstesa
    ) {
      const pointToZoom = filteredPointsList.find(
        (item) => item.properties.lcd === Number(newEvent.finalPointRDSEstesa)
      );
      if (pointToZoom) {
        const lat = pointToZoom.geometry.coordinates[1];
        const long = pointToZoom.geometry.coordinates[0];
        this.mapFlyTo(lat, long, viewport.zoom, 1000);
      }
    }

    if (
      newEvent.streetRDSPuntuale &&
      prevProps.newEvent.streetRDSPuntuale !== newEvent.streetRDSPuntuale
    ) {
      let segm = segmentsList.find(
        (item) =>
          item.properties.road_lcd === Number(newEvent.streetRDSPuntuale)
      );
      if (segm) {
        let index = 0;
        index = Math.round(segm.geometry.coordinates.length / 2);
        let segmentMiddlePoint = {
          ...segm.geometry.coordinates[index],
        };
        let latPuntuale = segmentMiddlePoint[1];
        let longPuntuale = segmentMiddlePoint[0];
        this.mapFlyTo(latPuntuale, longPuntuale, viewport.zoom, 1000);
      }
    }

    if (
      newEvent.streetRDSEstesa &&
      prevProps.newEvent.streetRDSEstesa !== newEvent.streetRDSEstesa
    ) {
      let segm = segmentsList.find(
        (item) => item.properties.road_lcd === Number(newEvent.streetRDSEstesa)
      );
      if (segm) {
        let index = 0;
        index = Math.round(segm.geometry.coordinates.length / 2);
        let segmentMiddlePoint = {
          ...segm.geometry.coordinates[index],
        };
        let latPuntuale = segmentMiddlePoint[1];
        let longPuntuale = segmentMiddlePoint[0];
        this.mapFlyTo(latPuntuale, longPuntuale, viewport.zoom, 1000);
      }
    }
  };

  componentWillUnmount = () => {
    const { setZoomLocalization } = this.props;
    const { viewport } = this.state;
    setZoomLocalization(viewport.zoom);
  };
  getMapLayout = (mapLayout, map) => {
    switch (mapLayout) {
      case "light":
        return map.mapStyle.light;
      case "normal":
        return map.mapStyle.normal;
      case "streets":
        return map.mapStyle.streets;
      case "dark":
      default:
        return map.mapStyle.dark;
    }
  };

  // SETTERS

  setViewport = (viewport) => {
    this.setState({ ...this.state, viewport: viewport });
  };

  setMapRef = (element) => {
    this.staticmap = element;
    if (this.staticmap) {
      this.map = this.staticmap.getMap();
    }
  };

  mapFlyTo = (lat, lon, zoom, dur) => {
    MapListeners.mapFlyTo(this, lat, lon, zoom, dur);
  };

  // GENERIC

  zoomIn = () => {
    const { viewport } = this.state;
    const { map } = this.context;

    if (viewport.zoom < map.mapOptions.maxZoom) {
      let newZoom = this.map.getZoom() + 1;
      this.map.flyTo({ zoom: newZoom });
      this.setState({
        ...this.state,
        viewport: { ...viewport, zoom: newZoom },
      });
    }
  };

  zoomOut = () => {
    const { viewport } = this.state;
    const { map } = this.context;

    if (viewport.zoom > map.mapOptions.minZoom) {
      let newZoom = this.map.getZoom() - 1;
      this.map.flyTo({ zoom: newZoom });
      this.setState({
        ...this.state,
        viewport: { ...viewport, zoom: newZoom },
      });
    }
  };

  goBackward = () => {
    const { historyPrevPuntuale, historyPrevEstesa, modeswitch } = this.props;
    if (modeswitch === "estesa") {
      historyPrevEstesa();
    } else {
      historyPrevPuntuale();
    }
  };

  onLayerClick = (event, info) => {
    const { modeswitch, mapModeReducer, setNewEvent } = this.props;

    if (modeswitch === "estesa" && event) {
      const position = {
        latitude: event.coordinate[1],
        longitude: event.coordinate[0],
      };
      setNewEvent("positionEstesa", position);
    } else if (modeswitch === "puntuale" && event) {
      const position = {
        latitude: event.coordinate[1],
        longitude: event.coordinate[0],
      };
      setNewEvent("positionPuntuale", position);
    }

    if (mapModeReducer !== ViewMode) {
      // don't change selection while editing
      return;
    }
  };

  // RENDER METHODS

  renderMapControls = () => {
    const { modeswitch } = this.props;
    return (
      <MapModalControls
        zoomOut={this.zoomOut}
        zoomIn={this.zoomIn}
        toDefaultPosition={this.toDefaultPosition}
        goBackward={this.goBackward}
        modeswitch={modeswitch}
      />
    );
  };

  renderStaticMap = () => {
    const { viewport, style } = this.state;

    return (
      <StaticMap
        id="map"
        {...viewport}
        ref={this.setMapRef}
        mapStyle={style}
        mapboxApiAccessToken={this.context.map.mapboxApiAccessToken}
      />
    );
  };

  //PUNTUALE AND ESTESA

  //SELECTED POSITION ON MAP
  renderSelectedPositionPinLayer = (lat, long) => {
    const list = [
      {
        lon: long,
        lat: lat,
        index: 0,
      },
    ];
    let layer = [new PinLayer({ list })];
    return layer;
  };

  //SELECTED ARC
  renderSelectedArcLayer = (arc) => {
    let data = [arc];
    let color = [255, 0, 0];
    let layer = [new SelectedRedArcLayer({ data, color })];
    return layer;
  };

  //CONFIRMED ARC
  renderConfirmedArcLayer = (arcs) => {
    let data = [];
    let color = [];
    let layer = [];
    color = [3, 140, 252];
    data = arcs;
    layer = [new ConfirmedArcLayer({ data, color })];
    return layer;
  };

  //PIN IN THE MIDDLE OF CONFIRMED ARC
  renderPinInTheMiddleOfConfirmedArcs = (arcs) => {
    let list = [];
    if (arcs.length > 0) {
      list = arcs.map((item, index) => {
        let coordinates = item.geometry.coordinates;
        var point1 = turf.point([...coordinates[0]]);
        var point2 = turf.point([...coordinates[coordinates.length - 1]]);
        var midpoint = turf.midpoint(point1, point2);
        return {
          lon: (midpoint.geometry.coordinates[0] * 100000) / 100000,
          lat: (midpoint.geometry.coordinates[1] * 100000) / 100000,
          index: index + 1,
        };
      });
    }
    let layer = [new PinLayerInMiddleOfConfirmedArc({ list })];
    return layer;
  };

  //TEXT OF PIN IN THE MIDDLE OF CONFIRMED ARC
  renderTextInPinInTheMiddleOfConfirmedArc = (arcs) => {
    let list = [];

    list = arcs.map((item, index) => {
      let coordinates = item.geometry.coordinates;
      var point1 = turf.point([...coordinates[0]]);
      var point2 = turf.point([...coordinates[coordinates.length - 1]]);
      var midpoint = turf.midpoint(point1, point2);
      return {
        lon: (midpoint.geometry.coordinates[0] * 100000) / 100000,
        lat: (midpoint.geometry.coordinates[1] * 100000) / 100000,
        index: index + 1,
      };
    });

    let layer = [new PinTextLayer({ list })];
    return layer;
  };

  //RADIUS OF INFLUENCE
  renderRadiusOfInfluenceLayer = (arcs) => {
    let listPolygons = arcs.map((item, index) => {
      let radius = item.properties.radiusOfInfluence;
      let options = { units: "meters" };
      let coordinates = item.geometry.coordinates;
      let centerLat = coordinates[0][0];
      let centerLong = coordinates[0][1];
      let center = [centerLat, centerLong];
      let circle = turf.circle(center, radius, options);
      return circle;
    });

    let layer = [new RadiusInfluenceLayer({ list: listPolygons })];
    return layer;
  };

  //PUNTUALE
  //TEXT OF PIN IN THE MIDDLE OF SELECTED POINT
  renderTextInInitialPin = (lat, long) => {
    const { newEvent } = this.props;
    let list = [];

    if (newEvent && newEvent.confirmedArcPuntuale === null) {
      list = [
        {
          lon: long,
          lat: lat,
          index: 0,
        },
      ];
    }
    let layer = [new PinTextLayer({ list })];
    return layer;
  };

  //RDS-TMC

  //POINT LAYER
  renderTmcPointsLayer = () => {
    const { filteredPointsList } = this.props;

    let data = [];
    if (filteredPointsList.length > 0) {
      data = filteredPointsList.map((item) => {
        return {
          lon: item.geometry.coordinates[0],
          lat: item.geometry.coordinates[1],
          nomeTratta:
            item.properties.road_number + " " + item.properties.name_road,
          nomeLCD: item.properties.name1,
        };
      });
    }
    let layer = [
      new TmcPointsLayer({
        data: data,
        onHover: ({ object, x, y }) => this.onHoverPoint(object, x, y),
      }),
    ];
    return layer;
  };

  //SEGMENT LAYER
  renderTmcSegmentsLayer = () => {
    const { segmentsList } = this.props;
    let data = segmentsList;
    let layer = [new TmcSegmentsLayer({ data })];
    return layer;
  };

  //PUNTUALE

  //RADIUS CIRCLE
  renderTmcPointsRadiusOfInfluenceLayer = (pointCenter, pointRadius) => {
    let list = [];

    if (pointCenter.length > 0) {
      const long = pointCenter[0];
      const lat = pointCenter[1];
      const center = [long, lat];
      const options = { units: "meters" };
      const radius = pointRadius;
      const circle = turf.circle(center, radius, options);
      list = [circle];
    }
    let layer = [new RadiusInfluenceLayer({ list })];
    return layer;
  };

  renderRoutingDistanceLayer = (arrRouting) => {
    let layer = [];
    layer = [
      new RoutingDistanceLayer({
        id: "routing-distance-layer",
        data: arrRouting,
        visible: true,
        color: [168, 50, 76],
      }),
    ];
    return layer;
  };

  renderTmcInitialAndFinalPointsLayer = (initial, final) => {
    const { filteredPointsList } = this.props;
    let list = [];
    let initialPoint;
    let finalPoint;

    if (initial) {
      initialPoint = filteredPointsList.find(
        (item) => item.properties.lcd === Number(initial)
      );
    }
    if (final) {
      finalPoint = filteredPointsList.find(
        (item) => item.properties.lcd === Number(final)
      );
    }

    if (initial && initialPoint) {
      list.push({
        lon: initialPoint.geometry.coordinates[0],
        lat: initialPoint.geometry.coordinates[1],
        index: 1,
      });
    }
    if (final && finalPoint) {
      list.push({
        lon: finalPoint.geometry.coordinates[0],
        lat: finalPoint.geometry.coordinates[1],
        index: 2,
      });
    }
    let layer = [new PinTextLayer({ list })];
    return layer;
  };

  onHoverPoint = (object, x, y) => {
    const { setHoveredPoint } = this.props;
    setHoveredPoint({
      x: x,
      y: y,
      object: object,
    });
  };

  render() {
    const { viewport } = this.state;
    const {
      modeswitch,
      modalMapWidth,
      modalMapHalfWidth,
      mapModeReducer,
      newEvent,
      hoveredObjectsReducer,
      shortestDistancePoints,
      arcsRdsTmcToDraw,
    } = this.props;

    //SAME LAYERS FOR ESTESA AND PUNTUALE
    let selectedPointOnMapLayer = [];
    let selectedArcLayer = [];
    let confirmedArcs = [];
    let radiusOfInfluence = [];
    let pinInTheMiddleOfConfirmedArcs = [];
    let textInPinInTheMiddleOfConfirmedArcs = [];
    let shortestDistanceRouting = [];
    //LAYER PUNTUALE
    let textInitialPinPuntuale = [];
    //LAYERS FOR RDS-TMC
    let modalPointsLayer = [];
    //let modalSegmentLayer = [];
    let modalPointsRadiusLayer = [];
    let modalPointText = [];
    let arcListEstesa = [];
    let coordinatesRdsTmcAfterMoving = [];
    //ALL LAYERS
    let layerList = [];

    if (modeswitch === "puntuale") {
      if (
        newEvent &&
        newEvent.positionPuntuale &&
        newEvent.positionPuntuale.longitude &&
        newEvent.positionPuntuale.latitude
      ) {
        selectedPointOnMapLayer = this.renderSelectedPositionPinLayer(
          newEvent.positionPuntuale.latitude,
          newEvent.positionPuntuale.longitude
        );
        textInitialPinPuntuale = this.renderTextInInitialPin(
          newEvent.positionPuntuale.latitude,
          newEvent.positionPuntuale.longitude
        );
      }
      if (
        newEvent &&
        newEvent.selectedArcPuntuale !== null &&
        newEvent.selectedArcPuntuale.geometry &&
        newEvent.selectedArcPuntuale.geometry.coordinates
      ) {
        selectedArcLayer = this.renderSelectedArcLayer(
          newEvent.selectedArcPuntuale
        );
      }
      if (
        newEvent &&
        newEvent.confirmedArcPuntuale !== null &&
        newEvent.confirmedArcPuntuale.geometry &&
        newEvent.confirmedArcPuntuale.geometry.coordinates
      ) {
        radiusOfInfluence = this.renderRadiusOfInfluenceLayer([
          newEvent.confirmedArcPuntuale,
        ]);
        confirmedArcs = this.renderConfirmedArcLayer([
          newEvent.confirmedArcPuntuale,
        ]);
        pinInTheMiddleOfConfirmedArcs = this.renderPinInTheMiddleOfConfirmedArcs(
          [newEvent.confirmedArcPuntuale]
        );
        textInPinInTheMiddleOfConfirmedArcs = this.renderTextInPinInTheMiddleOfConfirmedArc(
          [newEvent.confirmedArcPuntuale]
        );
      }
    } else if (modeswitch === "estesa") {
      if (
        newEvent &&
        newEvent.positionEstesa &&
        newEvent.positionEstesa.longitude &&
        newEvent.positionEstesa.latitude
      ) {
        selectedPointOnMapLayer = this.renderSelectedPositionPinLayer(
          newEvent.positionEstesa.latitude,
          newEvent.positionEstesa.longitude
        );
      }
      if (
        newEvent &&
        newEvent.selectedArcEstesa !== null &&
        newEvent.selectedArcEstesa.geometry.coordinates
      ) {
        selectedArcLayer = this.renderSelectedArcLayer(
          newEvent.selectedArcEstesa
        );
      }
      if (newEvent && newEvent.listOfConfirmedArcsEstesa.length > 0) {
        radiusOfInfluence = this.renderRadiusOfInfluenceLayer(
          newEvent.listOfConfirmedArcsEstesa
        );
        confirmedArcs = this.renderConfirmedArcLayer(
          newEvent.listOfConfirmedArcsEstesa
        );
        shortestDistanceRouting = this.renderRoutingDistanceLayer(
          shortestDistancePoints
        );
        pinInTheMiddleOfConfirmedArcs = this.renderPinInTheMiddleOfConfirmedArcs(
          newEvent.listOfConfirmedArcsEstesa
        );
        textInPinInTheMiddleOfConfirmedArcs = this.renderTextInPinInTheMiddleOfConfirmedArc(
          newEvent.listOfConfirmedArcsEstesa
        );
      }
    }
    if (modeswitch === "puntuale" || modeswitch === "estesa") {
      layerList = [
        ...radiusOfInfluence,
        ...selectedArcLayer,
        ...confirmedArcs,
        ...shortestDistanceRouting,
        ...selectedPointOnMapLayer,
        ...textInitialPinPuntuale,
        ...pinInTheMiddleOfConfirmedArcs,
        ...textInPinInTheMiddleOfConfirmedArcs,
      ];
    }

    if (modeswitch === "rds-tmc-puntuale" || modeswitch === "rds-tmc-estesa") {
      modalPointsLayer = this.renderTmcPointsLayer();

      if (
        modeswitch === "rds-tmc-puntuale" &&
        newEvent &&
        newEvent.initialPointRDSPuntuale !== "" &&
        newEvent.initialPointRDSPuntuale !== "-"
      ) {
        if (newEvent && newEvent.pointCoordinatesAfterMoving.length > 0)
          modalPointsRadiusLayer = this.renderTmcPointsRadiusOfInfluenceLayer(
            newEvent.pointCoordinatesAfterMoving,
            newEvent.radiusInfluenceRDSPuntuale
          );
        modalPointText = this.renderTmcInitialAndFinalPointsLayer(
          newEvent.initialPointRDSPuntuale,
          null
        );
        coordinatesRdsTmcAfterMoving = this.renderSelectedPositionPinLayer(
          newEvent.pointCoordinatesAfterMoving[1],
          newEvent.pointCoordinatesAfterMoving[0]
        );

        layerList = [
          ...modalPointsRadiusLayer,
          // ...modalSegmentLayer,
          ...modalPointsLayer,
          ...coordinatesRdsTmcAfterMoving,
          ...modalPointText,
        ];
      } else if (
        modeswitch === "rds-tmc-estesa" &&
        newEvent &&
        newEvent.initialPointRDSEstesa !== "" &&
        newEvent.initialPointRDSEstesa !== "-"
      ) {
        modalPointsRadiusLayer = this.renderTmcPointsRadiusOfInfluenceLayer(
          newEvent.pointCoordinatesAfterMovingEstesa,
          newEvent.radiusInfluenceRDSEstesa
        );
        modalPointText = this.renderTmcInitialAndFinalPointsLayer(
          newEvent.initialPointRDSEstesa,
          newEvent.finalPointRDSEstesa
        );
        coordinatesRdsTmcAfterMoving = this.renderSelectedPositionPinLayer(
          newEvent.pointCoordinatesAfterMovingEstesa[1],
          newEvent.pointCoordinatesAfterMovingEstesa[0]
        );
        if (arcsRdsTmcToDraw) {
          arcListEstesa = this.renderSelectedArcLayer(arcsRdsTmcToDraw);
        }
        layerList = [
          ...modalPointsRadiusLayer,
          // ...modalSegmentLayer,
          ...arcListEstesa,
          ...modalPointsLayer,
          ...coordinatesRdsTmcAfterMoving,
          ...modalPointText,
        ];
      } else {
        layerList = [...modalPointsLayer];
      }
    }

    return (
      <div
        className={
          modalMapWidth
            ? "modal-map-Localization-two-thirds"
            : modalMapHalfWidth
            ? "modal-map-Localization-half"
            : "modal-map-InfoManagement "
        }
      >
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v0.44.0/mapbox-gl.css"
          rel="stylesheet"
        />
        <DeckGL
          id="deck-canvas"
          ref={(_) => (this.deck = _)}
          viewState={viewport}
          layers={layerList}
          ContextProvider={MapContext.Provider}
          initialViewState={{ ...this.INITIAL_VIEW_STATE }}
          onViewStateChange={({ viewState }) => this.setViewport(viewState)}
          onClick={this.onLayerClick}
          views={
            new MapView({
              id: "basemap",
              controller: {
                type: MapController,
                doubleClickZoom: mapModeReducer === ViewMode,
              },
            })
          }
        >
          {this.renderStaticMap()}
        </DeckGL>
        {this.renderMapControls()}
        {hoveredObjectsReducer &&
          hoveredObjectsReducer.hoveredPoint &&
          hoveredObjectsReducer.hoveredPoint.object && (
            <PointTooltip
              x={hoveredObjectsReducer.hoveredPoint.x}
              y={hoveredObjectsReducer.hoveredPoint.y}
              object={hoveredObjectsReducer.hoveredPoint.object}
            />
          )}
      </div>
    );
  }
}

ModalMap.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setHoveredPoint,
  historyPrevPuntuale,
  historyPrevEstesa,
  setNewEvent,
  updateNewEvent,
  enableForwardEventButton,
  disableForwardEventButton,
  setBounds,
  setZoomLocalization,
};

const mapStateToProps = (state) => ({
  mapLayout: getLayoutMap(state),
  //MODAL REDUCER
  newEvent: getNewEvent(state),
  currentEvent: getCurrentEvent(state),
  typeModal: getTypeModal(state),
  newEventValid: getNewEventValid(state),
  hoveredObjectsReducer: getHoveredObjectsModal(state),
  modalMapBoundingBoxReducer: getBoundingBoxCartografica(state),
  zoomLocalization: getZoomLocalization(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalMap)
);
