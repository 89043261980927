import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "components/layout/modal/style.less";
import ModalMap from "components/layout/modal/event/new-event/modal-map";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import OrderTableColumn from "components/shared/order-table-column/order-table-column";
import PmvPanelBigIcon from "components/layout/panels/pmv-panel/pmv-panel-big-icon";
import PmvPanelBigText from "components/layout/panels/pmv-panel/pmv-panel-big-text";

class MessageDefinition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      busNr: "",
      busName: "",
    };
  }

  applyMessage = () => {
    this.setState({ busNr: this.refs.nrOfBus.value });
    this.setState({ busName: this.refs.nameOfBus.value });
  };

  renderGroupEventHeader = () => {
    return (
      <tr>
        <th className="tableCellPanel">
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            ID PMV
            <OrderTableColumn
              requestSort={this.requestSort}
              type="panelIdPMV"
            />
          </div>
        </th>
        <th>
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Nome PMV
            <OrderTableColumn
              requestSort={this.requestSort}
              type="panelIdPMV"
            />
          </div>
        </th>
        <th colSpan="3">
          <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
            Tipo PMV
            <OrderTableColumn
              requestSort={this.requestSort}
              type="panelIdPMV"
            />
          </div>
        </th>
        <th className="middleSelection">
          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between">
            Seleziona
          </div>
        </th>
      </tr>
    );
  };

  renderGroupEventBody() {
    return (
      <tbody>
        <tr>
          <td className="column-1-messdef">
            <p className="first-line-mess-definition">1423</p>
            <table>
              <tbody>
                <tr>
                  <td>241</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="column-2-messdef">
            <p>-</p>
            <table>
              <tbody>
                <tr>
                  <td className="noPaddingtd">241</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="verticalLine column-3-messdef" colSpan="3">
            <p>Solari_3L+P+2P</p>
            <table>
              <tbody>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="config-button column-4-messdef" colSpan="1">
            <button
              className="uk-button-default buttonMeasurements button1 iconColorConfiguration"
              uk-icon="icon: acr-interface-configura"
            ></button>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td className="column-1-messdef">
            <p className="first-line-mess-definition">1423</p>
            <table>
              <tbody>
                <tr>
                  <td>241</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="column-2-messdef noPaddingtd">
            <p>-</p>
            <table>
              <tbody>
                <tr>
                  <td className="noPaddingtd">241</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="verticalLine column-3-messdef" colSpan="3">
            <p>Solari_3L+P+2P</p>
            <table>
              <tbody>
                <tr>
                  <td className="noPaddingtd"></td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="config-button column-4-messdef" colSpan="1">
            <button
              className="uk-button-default buttonMeasurements button1 iconColorConfiguration"
              uk-icon="icon: acr-interface-configura"
            ></button>
            <table>
              <tbody>
                <tr>
                  <td className="noPaddingtd"></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td className="column-1-messdef">
            <p className="first-line-mess-definition">1423</p>
            <table>
              <tbody>
                <tr>
                  <td>241</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="column-2-messdef">
            <p>-</p>
            <table>
              <tbody>
                <tr>
                  <td className="noPaddingtd">241</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="verticalLine column-3-messdef" colSpan="3">
            <p>Solari_3L+P+2P</p>
            <table>
              <tbody>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="config-button column-4-messdef" colSpan="1">
            <button
              className="uk-button-default buttonMeasurements button1 iconColorConfiguration"
              uk-icon="icon: acr-interface-configura"
            ></button>
            <table>
              <tbody>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    );
  }

  groupEventButtons = () => {
    return (
      <div className="uk-width-1-1 uk-flex uk-flex-row uk-flex-right">
        <div className="">
          <button className="uk-button uk-button-default uk-button-small">
            Annulla
          </button>
          <button className="uk-button  button-black uk-button-small uk-margin-left">
            Invia
          </button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="uk-flex uk-flex-column uk-position-relative">
        <div className="uk-flex uk-flex-row panel-selection-paddng">
          <ErrorBoundary>
            <div className="uk-form-horizontal uk-width-1-2 ">
              <div className="container-InfoManagement ">
                <div className="containerInfoCheckbox">
                  <label className="previewMessage">
                    ASSOCIAZIONE DEI MESSAGGI AI PANNELLI PMV
                  </label>
                </div>
                <div className="containerMessDefTable">
                  <table
                    id="eventPracticeTable"
                    className="uk-table uk-table-divider uk-table-hover "
                  >
                    <thead>{this.renderGroupEventHeader()}</thead>
                    {this.renderGroupEventBody()}
                  </table>
                </div>
              </div>
            </div>
          </ErrorBoundary>
          <div className="localizzazione-form uk-width-1-2 uk-flex padding-form-right mapBoundary uk-position-relative">
            <ErrorBoundary>
              <ModalMap />
            </ErrorBoundary>
          </div>
        </div>
        <div className="uk-flex uk-flex-column uk-position-relative">
          <div className="uk-flex uk-flex-row ">
            <div className="uk-form-horizontal uk-width-1-2 padding-left-SelectionPan">
              <div className="border-container1">
                <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
                  <div className="uk-width-1-1">
                    <label className="uk-form-label priorityLabel">
                      Priorità
                    </label>
                    <div>
                      <form className="small-form">
                        <select className="uk-select inputPriority"></select>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="addBusPictureMessageDefinition">
                  <button className="uk-button uk-button-default cameraButton">
                    <span
                      uk-icon="icon: acr-interface--image-placeholder"
                      className="cameraIcon iconCameraColor"
                    ></span>
                  </button>
                  <div className="lamp-committ-Icons1">
                    <button className="uk-button-default buttonMeasurements button1">
                      <span
                        uk-icon="icon: acr-interface-lamp"
                        className="iconColor"
                      ></span>
                    </button>
                    <button className="uk-button-default buttonMeasurements button2">
                      <span
                        uk-icon="icon: acr-interface-committ"
                        className="iconColor"
                      ></span>
                    </button>
                  </div>
                </div>
                <div className="busLineMessageDefinition">
                  <div>
                    <form className="small-form">
                      <input
                        placeholder="-"
                        ref="nrOfBus"
                        className="uk-input uk-form-width inputBusName"
                      ></input>
                      <input
                        placeholder="-"
                        ref="nameOfBus"
                        className="uk-input uk-form-width inputBusName"
                      ></input>
                      <input
                        placeholder="-"
                        className="uk-input uk-form-width inputBusName"
                      ></input>
                      <input
                        placeholder="-"
                        className="uk-input uk-form-width inputBusName"
                      ></input>
                    </form>
                  </div>
                </div>
                <div className="lamp-committ-Icons2">
                  <button className="uk-button-default buttonMeasurements button1">
                    <span
                      uk-icon="icon: acr-interface-lamp"
                      className="iconColor"
                    ></span>
                  </button>
                  <button className="uk-button-default buttonMeasurements button2">
                    <span
                      uk-icon="icon: acr-interface-committ"
                      className="iconColor"
                    ></span>
                  </button>
                </div>
                <div className="applySaveButtonMessDefinition">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      this.applyMessage();
                    }}
                    className="uk-button  button-black uk-button-small uk-margin-left applyButton"
                  >
                    Applica
                  </button>
                  <button className="uk-button  button-black uk-button-small uk-margin-left saveButton">
                    Salva
                  </button>
                </div>
              </div>
            </div>
            <div className="uk-form-horizontal uk-width-1-2 padding-form-right">
              <div className="border-container">
                <div className="containerPreviewMessage">
                  <label className="previewMessage">ANTEPRIMA MESSAGGIO</label>
                </div>
                <div className="bus-panel-container uk-flex uk-flex-row uk-flex-between">
                  <PmvPanelBigIcon />
                  <PmvPanelBigText text={this.state.busName} />
                </div>
              </div>
              <div className="uk-width-1-1 uk-flex uk-flex-row uk-flex-right bottomButtonsMesDef">
                <div className="">
                  <button className="uk-button uk-button-default uk-button-small annullaButton">
                    Annulla
                  </button>
                  <button className="uk-button  button-black uk-button-small uk-margin-left sendButton">
                    Invia
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessageDefinition)
);
