import * as React from "react";
import { AuthConsumer } from "@almaviva/ljsa-react-module";

export const Callback = () => (
  <AuthConsumer>
    {({ signinRedirect, signinRedirectCallback }) => {
      if (Boolean(window.location.hash)) {
        // depends on IDENTITY_CONFIG.response_type
        signinRedirectCallback();
      } else {
        signinRedirect();
      }
    }}
  </AuthConsumer>
);
