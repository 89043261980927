import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";

//COMPONENTS
import NavTab from "components/layout/nav-tab";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import ParkingsContainer from "components/layout/parkings";
import Loading from "components/shared/loading/loading";

//STORE AND ACTIONS
import {
  getBoundingBox,
  isOpenNavTab,
  getNavTabSearch,
  getNavTabSelectedTab,
  getFilteredParkings,
  isParkingVisible,
  getCurrentParking,
  isParkingLoading,
} from "store";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { setParkingsList } from "reducers/installations/parking/parking.actions";
class ParkingPage extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { setNavTabSelectedTab } = this.props;
    setNavTabSelectedTab(0);
  };

  componentDidUpdate = (prevProps) => {};

  handleSearch = (value) => {
    const { setNavTabSearch } = this.props;
    setNavTabSearch(value, "parking", null, null);
  };

  injectItems = () => {
    const { filteredParkings, isParkingVisible, isParkingLoading } = this.props;

    return isParkingVisible && isParkingLoading ? (
      <ErrorBoundary>
        <TabPanel className="tabsContainerTpl inherit-transition">
          <Loading />
        </TabPanel>
      </ErrorBoundary>
    ) : isParkingVisible && !isParkingLoading ? (
      <ErrorBoundary>
        <TabPanel className="tabsContainerTpl inherit-transition">
          <ul className="uk-tab uk-flex-between">
            <li className={"uk-active"}>
              <a href="/#">
                {"Parcheggi (" +
                  (filteredParkings && filteredParkings.length) +
                  ")"}
              </a>
            </li>
          </ul>
          <ParkingsContainer></ParkingsContainer>
        </TabPanel>
      </ErrorBoundary>
    ) : null;
  };

  render() {
    return (
      <NavTab
        isOpen={true}
        items={this.injectItems()}
        onSearch={this.handleSearch}
      />
    );
  }

  componentWillUnmount() {
    const { setNavTabSearch } = this.props;
    this.subscriptions$.forEach((x) => x.unsubscribe());
    setNavTabSearch("", "parking", null, null);
  }
}

ParkingPage.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
  setParkingsList,
};

const mapStateToProps = (state) => ({
  //UI
  menuNavTabSearch: getNavTabSearch(state),
  menuTabReducer: getNavTabSelectedTab(state),
  isOpenNavTab: isOpenNavTab(state),
  isParkingVisible: isParkingVisible(state),
  //BBOX
  boundingBox: getBoundingBox(state),
  //PARKING
  filteredParkings: getFilteredParkings(state),
  currentParking: getCurrentParking(state),
  //LOADING
  isParkingLoading: isParkingLoading(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParkingPage)
);
