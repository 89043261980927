import React, { Component, createRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
//UTILS
import "./style.less";
import { getWorseStationSensorStateDetail } from "utils/utils";
//ASSETS
import stations_img_red from "assets/icon/multitab-icns/Tab_StazioniMisuraRed.svg";
import stations_img_green from "assets/icon/multitab-icns/Tab_StazioniMisuraGreen.svg";
import stations_img_gray from "assets/icon/multitab-icns/Tab_StazioniMisuraGrey.svg";

class StationsList extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.scrollDiv = createRef();
    this.state = {
      iconVisibile: false,
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  render() {
    const { toggleDetails, listEvent } = this.props;

    return (
      <div className="uk-list margin-bottom50">
        {listEvent.map((event, index) => {
          let stateSensor = getWorseStationSensorStateDetail(event);

          return (
            <div
              key={index}
              className={
                "uk-flex uk-flex-row uk-flex-middle uk-flex-around uk-width-1-1 height-84"
              }
            >
              <li
                className={"uk-flex uk-flex-around uk-flex-middle uk-width-1-1"}
                onClick={(e) => {
                  e.preventDefault();
                  toggleDetails(event);
                }}
              >
                <div className="uk-position-relative event-height-width-48">
                  <img
                    className={"img-status"}
                    alt={"stations_img"}
                    src={
                      stateSensor === "NO_INFO"
                        ? stations_img_gray
                        : stateSensor === "DOWN"
                        ? stations_img_red
                        : stations_img_green
                    }
                  />
                </div>
                <div className="uk-flex uk-margin-small-left width-260">
                  <div className="uk-flex uk-flex-row">
                    <div className="uk-flex uk-flex-column headerName">
                      {event && event.archName && event.directionName
                        ? event.archName + ", direzione " + event.directionName
                        : event.archName}
                    </div>
                  </div>
                </div>
              </li>
            </div>
          );
        })}
      </div>
    );
  }
}

StationsList.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StationsList)
);
