import { CompositeLayer, PathLayer } from "deck.gl";

class RoutingDistanceLayer extends CompositeLayer {
  renderLayers() {
    const { id, data, visible, color } = this.props;

    //CREATE AN ARRAY WITH ALL ARRAYS OF POINTS TO DRAW ON MAP
    let list = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        item.geometry.coordinates.forEach((item2) => {
          return list.push(item2);
        });
      });
    }

    return new PathLayer(
      this.getSubLayerProps({
        id,
        data: list,
        getPath: (d) => d,
        getColor: color,
        pickable: false,
        widthMinPixels: 4,
        //onClick: onClick,
        visible,
        rounded: true,
      })
    );
  }
}

RoutingDistanceLayer.layerName = "RoutingDistanceLayer";

export default RoutingDistanceLayer;
