export const TABLE_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: true,
    order: false,
  },
  Nome: {
    name: "Nome",
    id: "Nome",
    show: true,
    order: true,
  },
  strada: {
    name: "Localizzazione",
    id: "strada",
    show: true,
    order: true,
  },
  statoFunzionamento: {
    name: "Stato Diagnostico",
    id: "statoFunzionamento",
    show: true,
    order: true,
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-state",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Normale",
        checked: true,
        id: "Normale",
        isChild: true,
        name: "Normale",
      },
      {
        label: "Degradato",
        checked: true,
        id: "Degradato",
        isChild: true,
        name: "Degradato",
      },
      {
        label: "Guasto",
        checked: true,
        id: "Guasto",
        isChild: true,
        name: "Guasto",
      },
      {
        label: "Sconosciuto",
        checked: true,
        id: "Sconosciuto",
        isChild: true,
        name: "Sconosciuto",
      },
      {
        label: "Offline",
        checked: true,
        id: "Offline",
        isChild: true,
        name: "Offline",
      },
    ],
  },
  pmvGif: {
    name: "Stato Operativo",
    id: "pmvGif",
    show: true,
    order: false,
  },

  pmvMsgName: {
    name: "Nome Messaggio",
    id: "pmvMsgName",
    show: true,
    order: false,
  },

  pmvExpositionLevel: {
    name: "Livello Esposizione",
    id: "pmvExpositionLevel",
    show: true,
    order: false,
  },

  pmvDescriptionExposition: {
    name: "Descrizione Esposizione",
    id: "pmvDescriptionExposition",
    show: true,
    order: false,
  },

  validitaDa: {
    name: "Valido Da",
    id: "validitaDa",
    show: true,
    order: false,
  },
  validitaA: {
    name: "Valido A",
    id: "validitaA",
    show: true,
    order: false,
  },
  tipologia: {
    name: "Tipologia",
    id: "tipologia",
    show: true,
    order: false,
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-type",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Aesys",
        checked: true,
        id: "Aesys",
        isChild: true,
        name: "Aesys",
      },
      {
        label: "Solari",
        checked: true,
        id: "Solari",
        isChild: true,
        name: "Solari",
      },
      {
        label: "Futurit",
        checked: true,
        id: "Futurit",
        isChild: true,
        name: "Futurit",
      },
      {
        label: "Visual Lab",
        checked: true,
        id: "Visual Lab",
        isChild: true,
        name: "Visual Lab",
      },
      {
        label: "Sfhera",
        checked: true,
        id: "Sfhera",
        isChild: true,
        name: "Sfhera",
      },
    ],
  },
  pmvHistoryActions: {
    name: "Azioni",
    id: "pmvHistoryActions",
    show: true,
    order: false,
  },
};

export const TABLE_HISTORY_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: true,
    order: false,
  },
  Nome: {
    name: "Nome",
    id: "Nome",
    show: true,
    order: false,
  },
  strada: {
    name: "Localizzazione",
    id: "strada",
    show: true,
    order: false,
  },
  statoFunzionamento: {
    name: "Stato Diagnostico",
    id: "statoFunzionamento",
    show: true,
    order: false,
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all-state",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Normale",
        checked: true,
        id: "Normale",
        isChild: true,
        name: "Normale",
      },
      {
        label: "Degradato",
        checked: true,
        id: "Degradato",
        isChild: true,
        name: "Degradato",
      },
      {
        label: "Guasto",
        checked: true,
        id: "Guasto",
        isChild: true,
        name: "Guasto",
      },
      {
        label: "Sconosciuto",
        checked: true,
        id: "Sconosciuto",
        isChild: true,
        name: "Sconosciuto",
      },
      {
        label: "Offline",
        checked: true,
        id: "Offline",
        isChild: true,
        name: "Offline",
      },
    ],
  },
  pmvGif: {
    name: "Stato Operativo",
    id: "pmvGif",
    show: true,
    order: false,
  },
  pmvMsgName: {
    name: "Nome Messaggio",
    id: "pmvMsgName",
    show: true,
    order: false,
  },
  pmvExpositionLevel: {
    name: "Livello Esposizione",
    id: "pmvExpositionLevel",
    show: true,
    order: false,
  },
  pmvDescriptionExposition: {
    name: "Descrizione Esposizione",
    id: "pmvDescriptionExposition",
    show: true,
    order: false,
  },
  validitaDa: {
    name: "Valido Da",
    id: "validitaDa",
    show: true,
    order: false,
  },
  validitaA: {
    name: "Valido A",
    id: "validitaA",
    show: true,
    order: false,
  },
  tipologia: {
    name: "Tipologia",
    id: "tipologia",
    show: true,
    order: false,
  },
};

export const DATE_COLUMNS = [
  {
    id: "validita",
    name: "Validità",
    selected: true,
  },
];

export const initColumns = (tableColumns = null, dateColumns = null) => {
  let columns = {};
  let date = [];
  if (
    !tableColumns ||
    (tableColumns &&
      Object.keys(TABLE_COLUMNS)[1] !== Object.keys(tableColumns)[1])
  ) {
    columns = { ...TABLE_COLUMNS };
    date = [...DATE_COLUMNS];
  } else {
    columns = { ...tableColumns };
    date = dateColumns ? [...dateColumns] : [];
  }

  return { columns: columns, date: date };
};

export const initColumnsHistory = (tableColumns = null, dateColumns = null) => {
  let columns = {};
  let date = [];
  if (
    !tableColumns ||
    (tableColumns &&
      Object.keys(TABLE_HISTORY_COLUMNS)[1] !== Object.keys(tableColumns)[1])
  ) {
    columns = { ...TABLE_HISTORY_COLUMNS };
    date = [...DATE_COLUMNS];
  } else {
    columns = { ...tableColumns };
    date = dateColumns ? [...dateColumns] : [];
  }

  return { columns: columns, date: date };
};
