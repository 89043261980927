export const FilterTableActions = {
  SAVE_FILTERS: "TABLE/FILTER/SAVE_FILTERS",
  RESET_FILTERS: "TABLE/FILTER/RESET_FILTERS",
  RESET_ALL_FILTERS: "TABLE/FILTER/RESET_ALL_FILTERS",
};

export const saveTableFilters = (filters, target) => (dispatch) => {
  dispatch({
    type: FilterTableActions.SAVE_FILTERS,
    payload: { filters, target },
  });
};

export const resetTableFilters = (filters, target) => (dispatch) => {
  dispatch({
    type: FilterTableActions.RESET_FILTERS,
    payload: { filters, target },
  });
};

export const resetAllTableFilters = (filters, target) => (dispatch) => {
  dispatch({
    type: FilterTableActions.RESET_ALL_FILTERS,
    payload: { filters, target },
  });
};
