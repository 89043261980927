import { INITIAL_STATE_VEHICLES } from "./vehicles.model";
import { VehiclesActions } from "./vehicles.actions";
import { TripsActions } from "../trips/trips.actions";
import { NavTabActions } from "../ui/nav-tab/nav-tab.actions";
import * as R from "ramda";

export default function vehiclesReducer(
  state = INITIAL_STATE_VEHICLES,
  action
) {
  switch (action.type) {
    case VehiclesActions.GET_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      };
    case VehiclesActions.UPDATE_EVENTS_VEHICLES:
      return {
        ...state,
        current:
          state.current &&
          state.current.vehicleActivityLocation &&
          state.current.vehicleActivityLocation.monitoredVehicleJourney &&
          state.current.vehicleActivityLocation.monitoredVehicleJourney
            .framedVehicleJourneyRef &&
          state.current.vehicleActivityLocation.monitoredVehicleJourney
            .framedVehicleJourneyRef.datedVehicleJourneyRef &&
          [...action.payload].find(
            (x) =>
              x &&
              x.vehicleActivityLocation &&
              x.vehicleActivityLocation.monitoredVehicleJourney &&
              x.vehicleActivityLocation.monitoredVehicleJourney
                .framedVehicleJourneyRef &&
              x.vehicleActivityLocation.monitoredVehicleJourney
                .framedVehicleJourneyRef.datedVehicleJourneyRef &&
              x.vehicleActivityLocation.monitoredVehicleJourney
                .framedVehicleJourneyRef.datedVehicleJourneyRef ===
                state.current.vehicleActivityLocation.monitoredVehicleJourney
                  .framedVehicleJourneyRef.datedVehicleJourneyRef
          )
            ? state.current
            : null,
        vehiclesEvents: action.payload,
      };
    case VehiclesActions.SET_CURRENT_VEHICLE: {
      let current = null;
      if (
        (state.current &&
          state.current.vehicleActivityLocation.monitoredVehicleJourney
            .framedVehicleJourneyRef.datedVehicleJourneyRef ===
            action.payload.monitoredVehicleJourney.framedVehicleJourneyRef
              .datedVehicleJourneyRef) ||
        (state.current &&
          state.current.vehicleActivityLocation &&
          action.payload.vehicleActivityLocation &&
          state.current.vehicleActivityLocation.monitoredVehicleJourney
            .framedVehicleJourneyRef.datedVehicleJourneyRef ===
            action.payload.vehicleActivityLocation.monitoredVehicleJourney
              .framedVehicleJourneyRef.datedVehicleJourneyRef)
      ) {
        let v = [...state.vehiclesEvents].map((x) => {
          x.selected = false;
          return x;
        });
        return {
          ...state,
          current: null,
          vehiclesEvents: v,
        };
      } else {
        let vehiclesEvents = [...state.vehiclesEvents].map((vehicle) => {
          if (
            vehicle.vehicleActivityLocation.monitoredVehicleJourney
              .framedVehicleJourneyRef.datedVehicleJourneyRef ===
              action.payload.monitoredVehicleJourney.framedVehicleJourneyRef
                .datedVehicleJourneyRef ||
            (state.current &&
              action.payload.vehicleActivityLocation &&
              state.current.vehicleActivityLocation.monitoredVehicleJourney
                .framedVehicleJourneyRef.datedVehicleJourneyRef ===
                action.payload.vehicleActivityLocation.monitoredVehicleJourney
                  .framedVehicleJourneyRef.datedVehicleJourneyRef)
          ) {
            vehicle.selected = true;
            current = vehicle;
          } else {
            vehicle.selected = false;
          }
          return vehicle;
        });
        return {
          ...state,
          current,
          vehiclesEvents,
        };
      }
    }
    case VehiclesActions.RESET_CURRENT_VEHICLE: {
      let listVehiclesEvents = [...state.vehiclesEvents].map((vehicle) => {
        vehicle.selected = false;
        return vehicle;
      });
      return {
        ...state,
        current: null,
        vehiclesEvents: listVehiclesEvents,
      };
    }
    case VehiclesActions.SET_VEHICLES_EVENTS:
      let currentEventVehicle = state.current ? { ...state.current } : null;
      let vehicleEvent = [...state.vehiclesEvents];
      if (
        action.payload &&
        Array.isArray(action.payload) &&
        action.payload.find(
          (x) =>
            x &&
            x.vehicleActivityLocation &&
            x.vehicleActivityLocation.monitoredVehicleJourney &&
            x.vehicleActivityLocation.monitoredVehicleJourney
              .framedVehicleJourneyRef.datedVehicleJourneyRef
        )
      ) {
        action.payload.forEach((element) => {
          const loc = element.vehicleActivityLocation;
          const journey = loc.monitoredVehicleJourney;

          let index = vehicleEvent.findIndex(
            (item) =>
              item &&
              item.vehicleActivityLocation &&
              journey.framedVehicleJourneyRef.datedVehicleJourneyRef ===
                item.vehicleActivityLocation.monitoredVehicleJourney
                  .framedVehicleJourneyRef.datedVehicleJourneyRef
          );
          if (index >= 0) {
            let concatValues = (k, l, r) => (r ? r : l);
            const stateLocation = vehicleEvent[index].vehicleActivityLocation;
            let merge = R.mergeDeepWithKey(concatValues, stateLocation, loc);
            merge = {
              ...merge,
              monitoredVehicleJourney: {
                ...merge.monitoredVehicleJourney,
                onwardCalls: journey.monitoredCall
                  ? journey.onwardCalls
                  : stateLocation.monitoredVehicleJourney.onwardCalls,
                previousCalls: journey.monitoredCall
                  ? journey.previousCalls
                  : stateLocation.monitoredVehicleJourney.previousCalls,
              },
            };
            vehicleEvent[index] = {
              ...vehicleEvent[index],
              e2eId: element.e2eId,
              transportMode: element.transportMode,
              vehicleActivityLocation: { ...merge },
            };
            if (
              currentEventVehicle &&
              currentEventVehicle.vehicleActivityLocation &&
              currentEventVehicle.vehicleActivityLocation
                .monitoredVehicleJourney.framedVehicleJourneyRef
                .datedVehicleJourneyRef ===
                journey.framedVehicleJourneyRef.datedVehicleJourneyRef
            )
              currentEventVehicle = vehicleEvent[index];
          } else {
            vehicleEvent.push(element);
          }
        });
      }
      return {
        ...state,
        current: currentEventVehicle,
        vehiclesEvents: vehicleEvent,
      };

    case TripsActions.SET_CURRENT_TRIP:
      let newCurrent = state.current ? { ...state.current } : null;
      let vehicleEventNew = [...state.vehiclesEvents].map((vehicle) => {
        if (
          (action.payload &&
            action.payload.monitoredVehicleJourney &&
            action.payload.monitoredVehicleJourney.framedVehicleJourneyRef &&
            vehicle.vehicleActivityLocation.monitoredVehicleJourney
              .framedVehicleJourneyRef.datedVehicleJourneyRef ===
              action.payload.monitoredVehicleJourney.framedVehicleJourneyRef
                .datedVehicleJourneyRef) ||
          (action.payload &&
            action.payload.monitoredVehicleJourney &&
            vehicle.vehicleActivityLocation.monitoredVehicleJourney
              .framedVehicleJourneyRef.datedVehicleJourneyRef ===
              action.payload.monitoredVehicleJourney.vehicleJourneyName)
        ) {
          vehicle.selected = true;
          newCurrent = vehicle;
        } else {
          vehicle.selected = false;
        }
        return vehicle;
      });
      return {
        ...state,
        current: newCurrent,
        vehiclesEvents: vehicleEventNew,
      };

    case TripsActions.RESET_CURRENT_TRIP:
      let vehicles = [...state.vehiclesEvents].map((vehicle) => {
        vehicle.selected = false;
        return vehicle;
      });
      return {
        ...state,
        current: null,
        vehiclesEvents: vehicles,
      };
    case NavTabActions.SET_SEARCH:
      if (
        action.payload.location &&
        action.payload.location.includes("tpl") &&
        action.payload.menuTab === 2
      ) {
        return {
          ...state,
          current: null,
        };
      } else {
        return { ...state };
      }
    case VehiclesActions.RESET_VEHICLES:
      return INITIAL_STATE_VEHICLES;
    case VehiclesActions.SET_CURRENT_ID_ROUTE_VEHICLE:
      return {
        ...state,
        currentIdRouteForVehicle: action.payload,
      };
    case VehiclesActions.SET_CURRENT_ID_STOP_VEHICLE:
      return {
        ...state,
        currentIdStopForVehicle: action.payload,
      };

    default:
      return state;
  }
}

export const getVehicles = (state) => state.vehicles;
export const getCurrentVehicle = (state) => state.current;
export const resetCurrentVehicle = (state) => state.current;
export const getVehiclesEvents = (state) => state.vehiclesEvents;
export const getCurrentIdRouteForVehicle = (state) =>
  state.currentIdRouteForVehicle;
export const getCurrentIdStopForVehicle = (state) =>
  state.currentIdStopForVehicle;
