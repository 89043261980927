import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";

//STORE AND ACTIONS
import {
  getBoundingBox,
  isOpenNavTab,
  getNavTabSearch,
  getNavTabSelectedTab,
  getFilteredParkings,
  isParkingVisible,
  getCurrentParking,
  isWSParkingsOpen,
} from "store";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import {
  ParkingFeatureCollectionModel,
  ParkingRecordStatusModel,
} from "reducers/installations/parking/parking.model";
import {
  setParkingsList,
  updateParkingStatesWS,
} from "reducers/installations/parking/parking.actions";
import { wsOpen } from "reducers/ui/ws/ws.actions";
import { setIsParkingLoading } from "reducers/ui/installations-menu/installations-menu.actions";
//UTILS
import { getBoundingBoxStringForBboxParamNoEpsg } from "components/pages/load.data/map.utils";

class LoadParkingData extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      boundingBox,
      setParkingsList,
      isWSParkingsOpen,
      isParkingVisible,
      setIsParkingLoading,
    } = this.props;

    this.apiService = createApiService(this.context);

    if (isParkingVisible) {
      setIsParkingLoading(true);
      const bbox = getBoundingBoxStringForBboxParamNoEpsg(boundingBox);

      this.subscriptions$.push(
        this.apiService
          .getAllParkingRecordsWithLatestStatusByBbox(bbox)
          .pipe(
            tap((data) => {
              setIsParkingLoading(false);
              let featureCollection = ParkingFeatureCollectionModel.fromREST(
                data
              );
              let parkings = [];
              if (featureCollection.features) {
                parkings = [...featureCollection.features];
                setParkingsList(parkings);
              }
            }),
            catchError((error) => {
              setIsParkingLoading(false);
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }

    if (!isWSParkingsOpen) {
      this.wsParkings();
    }
  };

  wsParkings = () => {
    this.apiService.getParkingRuntime(
      this.eventSourceMessage,
      this.eventSourceErr,
      this.eventSourceOpen
    );
  };

  eventSourceOpen = (e) => {
    const { wsOpen, isWSParkingsOpen } = this.props;
    if (!isWSParkingsOpen) wsOpen("parkings", true);
  };

  eventSourceErr = (e) => {
    const { wsOpen, isWSParkingsOpen } = this.props;
    // this.apiService.closeParkingRuntime();
    if (isWSParkingsOpen) {
      wsOpen("parkings", false);
    }
  };

  eventSourceMessage = (e) => {
    const { updateParkingStatesWS } = this.props;

    if (e && e.data) {
      let parkingNotification = JSON.parse(e.data);
      if (
        parkingNotification &&
        (!parkingNotification.status || parkingNotification.status !== "alive")
      ) {
        let arrParkings = [];
        let parkingWs = [];
        !(parkingNotification instanceof Array)
          ? parkingWs.push(parkingNotification)
          : (parkingWs = parkingNotification);
        parkingWs.forEach((item) => {
          if (item.header && item.payload && item.payload.length > 0) {
            arrParkings.push(ParkingRecordStatusModel.from(item.payload[0]));
          }
        });
        if (arrParkings && arrParkings.length > 0) {
          updateParkingStatesWS(arrParkings);
        }
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      boundingBox,
      setParkingsList,
      isParkingVisible,
      setIsParkingLoading,
    } = this.props;

    if (
      (prevProps.isParkingVisible !== isParkingVisible ||
        prevProps.boundingBox !== boundingBox) &&
      isParkingVisible &&
      boundingBox
    ) {
      if (prevProps.boundingBox === boundingBox) {
        setIsParkingLoading(true);
      }
      const bbox = getBoundingBoxStringForBboxParamNoEpsg(boundingBox);

      this.subscriptions$.push(
        this.apiService
          .getAllParkingRecordsWithLatestStatusByBbox(bbox)
          .pipe(
            tap((data) => {
              setIsParkingLoading(false);
              let featureCollection = ParkingFeatureCollectionModel.fromREST(
                data
              );
              let parkings = [];
              if (featureCollection.features) {
                parkings = [...featureCollection.features];
                setParkingsList(parkings);
              }
            }),
            catchError((error) => {
              setIsParkingLoading(false);
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  render() {
    return <div></div>;
  }

  componentWillUnmount() {
    this.subscriptions$.forEach((x) => x.unsubscribe());
  }
}

LoadParkingData.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
  setParkingsList,
  wsOpen,
  updateParkingStatesWS,
  setIsParkingLoading,
};

const mapStateToProps = (state) => ({
  //UI
  menuNavTabSearch: getNavTabSearch(state),
  menuTabReducer: getNavTabSelectedTab(state),
  isOpenNavTab: isOpenNavTab(state),
  isParkingVisible: isParkingVisible(state),
  //BBOX
  boundingBox: getBoundingBox(state),
  //PARKING
  filteredParkings: getFilteredParkings(state),
  currentParking: getCurrentParking(state),
  //WS
  isWSParkingsOpen: isWSParkingsOpen(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadParkingData)
);
