import React, { Component } from "react";
import "./style.less";

class PmvPanelBottombar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { text } = this.props;

    return (
      <div className="uk-flex uk-flex-row pmv-panel">
        <div className="uk-position-relative uk-flex uk-flex-center uk-flex-middle  pmv-icon">
          <div
            className="uk-icon"
            uk-icon="icon: acr-menu-routes-management"
          ></div>
        </div>
        <div className="uk-position-relative uk-flex uk-flex-center uk-flex-middle">
          <div className="pmv-text">{text}</div>
        </div>
      </div>
    );
  }
}

export default PmvPanelBottombar;
