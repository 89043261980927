export const ConfigurationsActions = {
  SET_CURRENT_NETWORK: "SET_CURRENT_NETWORK",
  SET_NETWORKS: "SET_NETWORKS",
  CHECK_NETWORK: "CHECK_NETWORK",
};

export const setCurrentNetwork = (data) => (dispatch) => {
  dispatch({
    type: ConfigurationsActions.SET_CURRENT_NETWORK,
    payload: data,
  });
};

export const setNetworks = (data) => (dispatch) => {
  dispatch({
    type: ConfigurationsActions.SET_NETWORKS,
    payload: data,
  });
};

export const checkNetwork = (data) => (dispatch) => {
  dispatch({
    type: ConfigurationsActions.CHECK_NETWORK,
    payload: data,
  });
};
