import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//COMPONENTS
import BottombarCameraPanel from "components/layout/panels/camera-panel/bottombar-camera-panel";
//STORE
import { getPinnedCameras, getCamerasOnMap, isOpenNavTab } from "store";

class CamerasDock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      pinnedCameras,
      camerasOnMap,
      removeCamera,
      openOnMap,
      isOpenNavTab,
    } = this.props;

    return (
      <div
        id="bottombar-camera-dock"
        className={
          isOpenNavTab
            ? "uk-grid uk-grid-medium uk-child-width-1-3@m uk-overflow-auto installation-container "
            : "uk-grid uk-grid-medium uk-child-width-1-4@m uk-overflow-auto installation-container "
        }
      >
        {pinnedCameras &&
          pinnedCameras.length > 0 &&
          pinnedCameras.map((cam, ind) => {
            return (
              <BottombarCameraPanel
                key={cam?.properties?.cctvWithLastStateAndOpenAlarms?.cctv?.deviceId || ind}
                cam={cam}
                ind={ind}
                camerasOnMap={camerasOnMap}
                removeCamera={removeCamera}
                openOnMap={openOnMap}
                pinnedCameras={pinnedCameras}
              />
            );
          })}
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  pinnedCameras: getPinnedCameras(state),
  camerasOnMap: getCamerasOnMap(state),
  isOpenNavTab: isOpenNavTab(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CamerasDock)
);
