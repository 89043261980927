import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer from "./reducers/root.reducer"; // the value from combineReducers
import * as linesReducer from "./reducers/lines/lines.reducer";
import * as stopsReducer from "./reducers/stops/stops.reducer";
import * as tripsReducer from "./reducers/trips/trips.reducer";
import * as routesReducer from "./reducers/routes/routes.reducer";
import * as eventsReducer from "./reducers/events/events.reducer";
import * as vehiclesReducer from "./reducers/vehicles/vehicles.reducer";
import * as configurationsReducer from "./reducers/configurations/configurations.reducer";
import * as mapReducer from "./reducers/map/map.reducer";
import * as installationsReducer from "./reducers/installations/installations.combine";
import * as uiReducer from "./reducers/ui/ui.reducer";
import * as kpiReducer from "./reducers/kpi/kpi.reducer";
import * as userReducer from "./reducers/user/user.reducer";
import * as modalReducer from "./reducers/modal/modal.reducer";
import * as graphReducer from "./reducers/graph/graph.reducer";
import * as filtersTableReducer from "./reducers/filter-table-selected/filter-table-selected.reducer";
import * as trafficReducer from "./reducers/traffic/traffic.reducer";
import * as workspaceReducer from "./reducers/workspace/workspace.reducer";

import logger from "redux-logger";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["configurations"],
  blacklist: [],
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {

  const middleware = process.env.NODE_ENV === 'production' ? applyMiddleware(thunk) : applyMiddleware(thunk, logger)
  const store = createStore(
    persistedReducer,
    composeEnhancers(middleware)
  );

  let persistor = persistStore(store);
  return { store, persistor };
}

// Disattivare devtools in produzione
export const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//TPL
export const getAllLines = (state) => linesReducer.getLines(state.lines);
export const getFilteredLines = (state) =>
  linesReducer.getFilteredLines(state.lines);
export const getAllStops = (state) => stopsReducer.getStops(state.stops);

export const getAllTrips = (state) => tripsReducer.getTrips(state.trips);
export const getFilteredTrips = (state) =>
  tripsReducer.getFilteredTrips(state.trips);
export const getAllRoutes = (state) => routesReducer.getRoutes(state.routes);
export const getAllVehicles = (state) =>
  vehiclesReducer.getVehicles(state.vehicles);
export const getAllVehiclesEvents = (state) =>
  vehiclesReducer.getVehiclesEvents(state.vehicles);
export const getCurrentStop = (state) =>
  stopsReducer.getCurrentStop(state.stops);
export const getCurrentTrip = (state) =>
  tripsReducer.getCurrentTrip(state.trips);
export const getCurrentVehicle = (state) =>
  vehiclesReducer.getCurrentVehicle(state.vehicles);

export const getAllActiveLines = (state) =>
  linesReducer.getActiveLines(state.lines);
export const getMode = (state) => mapReducer.getMode(state.map);
export const getBoundingBox = (state) => mapReducer.getBoundingBox(state.map);
export const getCurrentZoom = (state) => mapReducer.getCurrentZoom(state.map);

export const getCurrentIdRouteForVehicle = (state) =>
  vehiclesReducer.getCurrentIdRouteForVehicle(state.vehicles);
export const getCurrentIdStopForVehicle = (state) =>
  vehiclesReducer.getCurrentIdStopForVehicle(state.vehicles);
export const getCurrentIdStopForRoute = (state) =>
  routesReducer.getCurrentIdStopForRoute(state.routes);
export const getCurrentIdTripForRoute = (state) =>
  routesReducer.getCurrentIdTripForRoute(state.routes);

export const getCityBusRoutes = (state) =>
  routesReducer.getCityBusRoutes(state.routes);
export const getRegionBusRoutes = (state) =>
  routesReducer.getRegionBusRoutes(state.routes);

export const getCityBusTrips = (state) =>
  tripsReducer.getCityBusTrips(state.trips);
export const getRegionBusTrips = (state) =>
  tripsReducer.getRegionBusTrips(state.trips);
export const getCityBusTripsFiltered = (state) =>
  tripsReducer.getCityBusTripsFiltered(state.trips);
export const getRegionBusTripsFiltered = (state) =>
  tripsReducer.getRegionBusTripsFiltered(state.trips);

export const getCityBusActiveLines = (state) =>
  mapReducer.getCityBusActiveLines(state.map);
export const getRegionBusActiveLines = (state) =>
  mapReducer.getRegionBusActiveLines(state.map);

export const getCityBusLinesFiltered = (state) =>
  mapReducer.getCityBusLinesFiltered(state.map);
export const getRegionBusLinesFiltered = (state) =>
  mapReducer.getRegionBusLinesFiltered(state.map);

export const getCityBusRoutesFiltered = (state) =>
  routesReducer.getCityBusRoutesFiltered(state.routes);
export const getRegionBusRoutesFiltered = (state) =>
  routesReducer.getRegionBusRoutesFiltered(state.routes);
export const getSearchTextRoutes = (state) =>
  routesReducer.getSearchTextRoutes(state.routes);

export const getCityBusStopsActive = (state) =>
  stopsReducer.getCityBusStopsActive(state.stops);
export const getRegionBusStopsActive = (state) =>
  stopsReducer.getRegionBusStopsActive(state.stops);

export const getCityBusStops = (state) =>
  stopsReducer.getCityBusStops(state.stops);
export const getRegionBusStops = (state) =>
  stopsReducer.getRegionBusStops(state.stops);
export const getCityBusStopsFiltered = (state) =>
  stopsReducer.getCityBusStopsFiltered(state.stops);
export const getRegionBusStopsFiltered = (state) =>
  stopsReducer.getRegionBusStopsFiltered(state.stops);
export const getCityBusStopsFilteredMap = (state) =>
  stopsReducer.getCityBusStopsFilteredMap(state.stops);
export const getRegionBusStopsFilteredMap = (state) =>
  stopsReducer.getRegionBusStopsFilteredMap(state.stops);
export const getCurrentIdRouteForStop = (state) =>
  stopsReducer.getCurrentIdRouteForStop(state.stops);
export const getCurrentIdTripForStop = (state) =>
  stopsReducer.getCurrentIdTripForStop(state.stops);

//UI
export const isOpenNavTab = (state) => uiReducer.isOpenNavTab(state.ui);
export const getNavTabSelectedTab = (state) =>
  uiReducer.getNavTabSelectedTab(state.ui);
export const getNavTabInfoRoute = (state) =>
  uiReducer.getNavTabInfoRoute(state.ui);

export const getBottombarTabs = (state) => uiReducer.getBottombarTabs(state.ui);
export const getBottombarActiveTab = (state) =>
  uiReducer.getBottombarActiveTab(state.ui);
export const isBottombarExpanded = (state) =>
  uiReducer.isBottombarExpanded(state.ui);
export const isBottombarReduced = (state) =>
  uiReducer.isBottombarReduced(state.ui);

export const getNavTabSearch = (state) => uiReducer.getNavTabSearch(state.ui);
export const getSelectedMultitab = (state) =>
  uiReducer.getSelectedMultitab(state.ui);

export const isKpiOpen = (state) => uiReducer.isKpiOpen(state.ui);
export const openKpiDown = (state) => uiReducer.openKpiDown(state.ui);
export const getTabModal = (state) => uiReducer.getTabModal(state.ui);
export const getTypeModal = (state) => uiReducer.getTypeModal(state.ui);
export const getTypeLocalization = (state) =>
  uiReducer.getTypeLocalization(state.ui);
export const isOpenPopup = (state) => uiReducer.isOpenPopup(state.ui);
export const isOpenModal = (state) => uiReducer.isOpenModal(state.ui);
export const getZoomLocalization = (state) =>
  uiReducer.getZoomLocalization(state.ui);
export const getInsertEventButtonDisabled = (state) =>
  uiReducer.getInsertEventButtonDisabled(state.ui);
export const getForwardEventButtonDisabled = (state) =>
  uiReducer.getForwardEventButtonDisabled(state.ui);

export const areAllTrafficVisible = (state) =>
  uiReducer.areAllTrafficVisible(state.ui);
export const isTypicalVisible = (state) => uiReducer.isTypicalVisible(state.ui);
export const isNetStateVisible = (state) =>
  uiReducer.isNetStateVisible(state.ui);
export const isTimeSliderVisible = (state) =>
  uiReducer.isTimeSliderVisible(state.ui);

export const areAllVisible = (state) => uiReducer.areAllVisible(state.ui);
export const isCityBusVisible = (state) => uiReducer.isCityBusVisible(state.ui);
export const isRegionBusVisible = (state) =>
  uiReducer.isRegionBusVisible(state.ui);
export const areAllEventVisible = (state) =>
  uiReducer.areAllEventVisible(state.ui);
export const isAllCamerasVisible = (state) =>
  uiReducer.isAllCamerasVisible(state.ui);
export const isMobilitaVisible = (state) =>
  uiReducer.isMobilitaVisible(state.ui);
export const isParkingVisible = (state) => uiReducer.isParkingVisible(state.ui);
export const isPmvVisible = (state) => uiReducer.isPmvVisible(state.ui);
export const isAesysPanelVisible = (state) =>
  uiReducer.isAesysPanelVisible(state.ui);
export const isSolariPanelVisible = (state) =>
  uiReducer.isSolariPanelVisible(state.ui);
export const isFuturitPanelVisible = (state) =>
  uiReducer.isFuturitPanelVisible(state.ui);
export const isVisuallabPanelVisible = (state) =>
  uiReducer.isVisuallabPanelVisible(state.ui);
export const isSfheraPanelVisible = (state) =>
  uiReducer.isSfheraPanelVisible(state.ui);
export const isStationVisible = (state) => uiReducer.isStationVisible(state.ui);
export const isAlarmTableOpen = (state) => uiReducer.isAlarmTableOpen(state.ui);
//LOADING DATA
export const isLinesLoading = (state) => uiReducer.isLinesLoading(state.ui);
export const isTripsLoading = (state) => uiReducer.isTripsLoading(state.ui);
export const isTripsAllLoading = (state) =>
  uiReducer.isTripsAllLoading(state.ui);
export const isStopsLoading = (state) => uiReducer.isStopsLoading(state.ui);
export const isEventsLoading = (state) => uiReducer.isEventsLoading(state.ui);
export const isEventsAllLoading = (state) =>
  uiReducer.isEventsAllLoading(state.ui);
export const isTrafficLoading = (state) => uiReducer.isTrafficLoading(state.ui);
export const isStationLoading = (state) => uiReducer.isStationLoading(state.ui);
export const isPmvLoading = (state) => uiReducer.isPmvLoading(state.ui);
export const isCctvLoading = (state) => uiReducer.isCctvLoading(state.ui);
export const isParkingLoading = (state) => uiReducer.isParkingLoading(state.ui);
export const isArcsLoading = (state) => uiReducer.isArcsLoading(state.ui);
export const isNodesLoading = (state) => uiReducer.isNodesLoading(state.ui);
export const isCentroidsLoading = (state) =>
  uiReducer.isCentroidsLoading(state.ui);
export const isPointsLoading = (state) => uiReducer.isPointsLoading(state.ui);
export const isSegmentsLoading = (state) =>
  uiReducer.isSegmentsLoading(state.ui);

export const isOpenDropdownNetwork = (state) =>
  uiReducer.isOpenDropdownNetwork(state.ui);
export const isOpenNavNetworkRdsTmc = (state) =>
  uiReducer.isOpenNavNetworkRdsTmc(state.ui);
export const isOpenNavGraph = (state) => uiReducer.isOpenNavGraph(state.ui);
export const isOpenNavEvents = (state) => uiReducer.isOpenNavEvents(state.ui);
export const isOpenNavTPL = (state) => uiReducer.isOpenNavTPL(state.ui);
export const isOpenNavTraffic = (state) => uiReducer.isOpenNavTraffic(state.ui);
export const isOpenNavStation = (state) => uiReducer.isOpenNavStation(state.ui);
export const isOpenNavWebcams = (state) => uiReducer.isOpenNavWebcams(state.ui);
export const isOpenNavPmv = (state) => uiReducer.isOpenNavPmv(state.ui);

export const isActiveVisible = (state) => uiReducer.isActiveVisible(state.ui);
export const isPlannedVisible = (state) => uiReducer.isPlannedVisible(state.ui);
export const areAllGraphVisible = (state) =>
  uiReducer.areAllGraphVisible(state.ui);
export const isArcVisible = (state) => uiReducer.isArcVisible(state.ui);
export const isNodeVisible = (state) => uiReducer.isNodeVisible(state.ui);
export const isCentroidVisible = (state) =>
  uiReducer.isCentroidVisible(state.ui);
export const isNetworkRdsTmcVisible = (state) =>
  uiReducer.isNetworkRdsTmcVisible(state.ui);
export const isApiOnError = (state) => uiReducer.isApiOnError(state.ui);

//GENERIC TABLE
export const isGenericTableToggled = (state) =>
  uiReducer.isGenericTableToggled(state.ui);
export const getTypeOfToggledTable = (state) =>
  uiReducer.typeOfToggledTable(state.ui);
export const isToggleSavingWorkspace = (state) =>
  uiReducer.isToggleSavingWorkspace(state.ui);

export const getListSelectedKpi = (state) =>
  kpiReducer.getListSelectedKpi(state.kpi);
export const getListAllKpi = (state) => kpiReducer.getListAllKpi(state.kpi);

//MAP
export const getMapLayers = (state) => mapReducer.getMapLayers(state.map);
export const getMapPolygon = (state) => mapReducer.getMapPolygon(state.map);
export const getControls = (state) => mapReducer.getControls(state.map);
export const getMapSelectedFeatureIndexes = (state) =>
  mapReducer.getMapSelectedFeatureIndexes(state.map);
export const getMapGeoJson = (state) => mapReducer.getMapGeoJson(state.map);
export const getLayoutMap = (state) => mapReducer.getLayoutMap(state.map);
export const getHoveredObjects = (state) =>
  mapReducer.getHoveredObjects(state.map);
export const getMultipleDistance = (state) =>
  mapReducer.getMultipleDistance(state.map);
export const getShortestDistanceOnGraph = (state) =>
  mapReducer.getShortestDistanceOnGraph(state.map);

//USER
export const getUsername = (state) => userReducer.getUsername(state.user);
export const getPermissions = (state) => userReducer.getPermissions(state.user);
export const getUserActivity = (state) =>
  userReducer.getUserActivity(state.user);
export const getUserRoles = (state) => userReducer.getUserRoles(state.user);

//  EVENTS
export const getAllEvents = (state) => eventsReducer.getEvents(state.events);
export const getCurrentEvent = (state) =>
  eventsReducer.getCurrentEvent(state.events);
export const getSelectedEvents = (state) =>
  eventsReducer.getSelectedEvents(state.events);
export const getInputFields = (state) =>
  eventsReducer.getInputFields(state.events);
export const getCurrentEventGroup = (state) =>
  eventsReducer.getCurrentEventGroup(state.events);

export const getActiveEventsFiltered = (state) =>
  eventsReducer.getActiveEventsFiltered(state.events);
export const getPlannedEventsFiltered = (state) =>
  eventsReducer.getPlannedEventsFiltered(state.events);
export const getActiveEvents = (state) =>
  eventsReducer.getActiveEvents(state.events);
export const getPlannedEvents = (state) =>
  eventsReducer.getPlannedEvents(state.events);
export const getSelectedHistory = (state) =>
  eventsReducer.getSelectedHistory(state.events);
export const getWsLastEvent = (state) =>
  eventsReducer.getWsLastEvent(state.events);
export const getNumberActiveEvents = (state) =>
  eventsReducer.getNumberActiveEvents(state.events);
export const getNumberActiveEventsWithHighestSeverity = (state) =>
  eventsReducer.getNumberActiveEventsWithHighestSeverity(state.events);

export const getCurrentNetwork = (state) =>
  configurationsReducer.getCurrentNetwork(state.configurations);
export const getNetworks = (state) =>
  configurationsReducer.getNetworks(state.configurations);

export const getCityBusLines = (state) => mapReducer.getCityBusLines(state.map);
export const getRegionBusLines = (state) =>
  mapReducer.getRegionBusLines(state.map);

//EVENT MODAL
export const getNewEvent = (state) => modalReducer.getNewEvent(state.modal);
export const getNewEventValid = (state) =>
  modalReducer.getNewEventValid(state.modal);
export const getSelectModal = (state) =>
  modalReducer.getSelectModal(state.modal);
export const getHoveredObjectsModal = (state) =>
  modalReducer.getHoveredObjectsModal(state.modal);
export const getBoundingBoxCartografica = (state) =>
  modalReducer.getBoundingBoxCartografica(state.modal);

export const getPmvHistoryTableOpen = (state) =>
  uiReducer.getPmvHistoryTableOpen(state.ui);
export const getIsReportDetailOpen = (state) =>
  uiReducer.getIsReportDetailOpen(state.ui);
export const getIsHistoryDetailOpen = (state) =>
  uiReducer.getIsHistoryDetailOpen(state.ui);
//WS
export const isWSTplOpen = (state) => uiReducer.isWSTplOpen(state.ui);
export const isWSTplLocationOpen = (state) =>
  uiReducer.isWSTplLocationOpen(state.ui);
export const isWSEventsOpen = (state) => uiReducer.isWSEventsOpen(state.ui);
export const isWSStationStatusOpen = (state) =>
  uiReducer.isWSStationStatusOpen(state.ui);
export const isWSStationMeasuresOpen = (state) =>
  uiReducer.isWSStationMeasuresOpen(state.ui);
export const isWSStationNotificationOpen = (state) =>
  uiReducer.isWSStationNotificationOpen(state.ui);
export const isWSParkingsOpen = (state) => uiReducer.isWSParkingsOpen(state.ui);
export const isWSCamerasStateOpen = (state) =>
  uiReducer.isWSCamerasStateOpen(state.ui);
export const isWSCamerasAlarmsOpen = (state) =>
  uiReducer.isWSCamerasAlarmsOpen(state.ui);

//GRAPH
export const getArcs = (state) => graphReducer.getArcs(state.graph);
export const getArcsEvent = (state) => graphReducer.getArcsEvent(state.graph);
export const getCurrentArc = (state) => graphReducer.getCurrentArc(state.graph);
export const getCurrentArcMap = (state) =>
  graphReducer.getCurrentArcMap(state.graph);
export const getNodes = (state) => graphReducer.getNodes(state.graph);
export const getCurrentNode = (state) =>
  graphReducer.getCurrentNode(state.graph);
export const getCurrentNodeMap = (state) =>
  graphReducer.getCurrentNodeMap(state.graph);
export const getCentroids = (state) => graphReducer.getCentroids(state.graph);
export const getCurrentCentroid = (state) =>
  graphReducer.getCurrentCentroid(state.graph);
export const getCurrentCentroidMap = (state) =>
  graphReducer.getCurrentCentroidMap(state.graph);
export const getFilteredCentroids = (state) =>
  graphReducer.getFilteredCentroids(state.graph);
export const getFilteredArc = (state) =>
  graphReducer.getFilteredArc(state.graph);
export const getFilteredNodes = (state) =>
  graphReducer.getFilteredNodes(state.graph);

//RETE RDS-TMC
export const getPointsRdsTmc = (state) =>
  graphReducer.getPointsRdsTmc(state.graph);
export const getCurrentPointRdsTmc = (state) =>
  graphReducer.getCurrentPointRdsTmc(state.graph);
export const getCurrentPointRdsTmcMap = (state) =>
  graphReducer.getCurrentPointRdsTmcMap(state.graph);
export const getSegmentsRdsTmc = (state) =>
  graphReducer.getSegmentsRdsTmc(state.graph);
export const getCurrentSegmentRdsTmc = (state) =>
  graphReducer.getCurrentSegmentRdsTmc(state.graph);
export const getCurrentSegmentRdsTmcMap = (state) =>
  graphReducer.getCurrentSegmentRdsTmcMap(state.graph);
export const getFilteredSegments = (state) =>
  graphReducer.getFilteredSegments(state.graph);
export const getFilteredPoints = (state) =>
  graphReducer.getFilteredPoints(state.graph);

// TABLE FILTERS
export const getSavedTableColumns = (state, stateKey) =>
  filtersTableReducer.getSavedTableColumns(state.tableFilters, stateKey);
export const getSavedDateColumns = (state, stateKey) =>
  filtersTableReducer.getSavedDateColumns(state.tableFilters, stateKey);
export const getSavedDateFrom = (state, stateKey) =>
  filtersTableReducer.getSavedDateFrom(state.tableFilters, stateKey);
export const getSavedDateTo = (state, stateKey) =>
  filtersTableReducer.getSavedDateTo(state.tableFilters, stateKey);
export const getSavedSearchText = (state, stateKey) =>
  filtersTableReducer.getSavedSearchText(state.tableFilters, stateKey);
export const getSavedFilters = (state, stateKey) =>
  filtersTableReducer.getSavedFilters(state.tableFilters, stateKey);
export const getSavedToggledFilters = (state, stateKey) =>
  filtersTableReducer.getSavedToggledFilters(state.tableFilters, stateKey);

//TRAFFIC
export const getTrafficArcs = (state) =>
  trafficReducer.getTrafficArcs(state.traffic);
export const getTrafficCurrentArc = (state) =>
  trafficReducer.getTrafficCurrentArc(state.traffic);
export const getTrafficCurrentArcMap = (state) =>
  trafficReducer.getTrafficCurrentArcMap(state.traffic);
export const getTrafficContexts = (state) =>
  trafficReducer.getTrafficContexts(state.traffic);
export const getTrafficCurrentContext = (state) =>
  trafficReducer.getTrafficCurrentContext(state.traffic);
export const getTrafficMeasures = (state) =>
  trafficReducer.getTrafficMeasures(state.traffic);
export const getTrafficCurrentMeasure = (state) =>
  trafficReducer.getTrafficCurrentMeasure(state.traffic);
export const getTrafficDayTypes = (state) =>
  trafficReducer.getTrafficDayTypes(state.traffic);
export const getTrafficCurrentDayType = (state) =>
  trafficReducer.getTrafficCurrentDayType(state.traffic);
export const getTrafficCurrentDay = (state) =>
  trafficReducer.getTrafficCurrentDay(state.traffic);
export const getTrafficVehicleClasses = (state) =>
  trafficReducer.getTrafficVehicleClasses(state.traffic);
export const getTrafficCurrentVehicleClass = (state) =>
  trafficReducer.getTrafficCurrentVehicleClass(state.traffic);
export const getTrafficCurrentTime = (state) =>
  trafficReducer.getTrafficCurrentTime(state.traffic);
export const getTrafficCurrentStartTime = (state) =>
  trafficReducer.getTrafficCurrentStartTime(state.traffic);
export const getTrafficCurrentEndTime = (state) =>
  trafficReducer.getTrafficCurrentEndTime(state.traffic);

//  TRAFFIC SLIDER
export const getCurrentTimeOffSet = (state) =>
  trafficReducer.getCurrentTimeOffSet(state.traffic);
export const getCurrentDateSlider = (state) =>
  trafficReducer.getCurrentDateSlider(state.traffic);
export const getWsLastNotification = (state) =>
  trafficReducer.getWsLastNotification(state.traffic);

//STATIONS
export const getStations = (state) =>
  installationsReducer.getStations(state.installations);
export const getCurrentStation = (state) =>
  installationsReducer.getCurrentStation(state.installations);
export const getCurrentStationMap = (state) =>
  installationsReducer.getCurrentStationMap(state.installations);
export const getFilteredStations = (state) =>
  installationsReducer.getFilteredStations(state.installations);

//PARKINGS
export const getParkings = (state) =>
  installationsReducer.getParkings(state.installations);
export const getCurrentParking = (state) =>
  installationsReducer.getCurrentParking(state.installations);
export const getCurrentParkingMap = (state) =>
  installationsReducer.getCurrentParkingMap(state.installations);
export const getFilteredParkings = (state) =>
  installationsReducer.getFilteredParkings(state.installations);
export const getWsLastParking = (state) =>
  installationsReducer.getWsLastParking(state.installations);

//CAMERAS
export const getCameras = (state) =>
  installationsReducer.getCameras(state.installations);
export const getCurrentCamera = (state) =>
  installationsReducer.getCurrentCamera(state.installations);
export const getFilteredCameras = (state) =>
  installationsReducer.getFilteredCameras(state.installations);
export const getPinnedCameras = (state) =>
  installationsReducer.getPinnedCameras(state.installations);
export const getCamerasOnMap = (state) =>
  installationsReducer.getCamerasOnMap(state.installations);
export const getCameraPositionOnMap = (state) =>
  installationsReducer.getCameraPositionOnMap(state.installations);
export const getWsLastCameraAlarm = (state) =>
  installationsReducer.getWsLastCameraAlarm(state.installations);
export const getWsLastCameraStatus = (state) =>
  installationsReducer.getWsLastCameraStatus(state.installations);

//PARKINGS
export const getPmvs = (state) =>
  installationsReducer.getPmvs(state.installations);
export const getCurrentPmv = (state) =>
  installationsReducer.getCurrentPmv(state.installations);
export const getFilteredPmvs = (state) =>
  installationsReducer.getFilteredPmvs(state.installations);
export const getCurrentPmvGroup = (state) =>
  installationsReducer.getCurrentPmvGroup(state.installations);

//WORKSPACE
export const getWorkspaces = (state) =>
  workspaceReducer.getWorkspaces(state.workspace);
export const getCurrentWorkspace = (state) =>
  workspaceReducer.getCurrentWorkspace(state.workspace);
