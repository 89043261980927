import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import { of } from "rxjs";
import { tap, take, catchError } from "rxjs/operators";
//MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";

//ACTIONS, STORE
import {
  getTrafficCurrentArc,
  getTrafficArcs,
  getTrafficCurrentArcMap,
  getBoundingBox,
  isOpenNavTab,
  getNavTabSearch,
  getCurrentZoom,
  isNetStateVisible,
  getNavTabSelectedTab,
  areAllTrafficVisible,
  getTrafficCurrentContext,
  getCurrentTimeOffSet,
  getTrafficContexts,
  isWSStationNotificationOpen,
  getWsLastNotification,
  getTrafficCurrentTime,
  isTypicalVisible,
  getTrafficCurrentDayType,
  getTrafficCurrentStartTime,
  getTrafficMeasures,
  isTrafficLoading,
} from "store";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import createApiService from "services/api.service";
import {
  setTrafficArcsList,
  setTrafficCurrentArc,
  setTrafficCurrentArcMap,
  setTrafficContexts,
  setDayTypes,
  setMeasures,
  setVehicleClasses,
  setCurrentTrafficTime,
  setTrafficCurrentContext,
  setCurrentDayType,
  setCurrentDay,
  setCurrentVehicleClass,
  setCurrentMeasure,
  setCurrentTrafficStartTime,
  setCurrentTrafficEndTime,
  setCurrentContextTimeOffSet,
} from "reducers/traffic/traffic.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import { toggleOpenTrafficTimeSlider } from "reducers/ui/traffic-menu/traffic-menu.actions";
import { wsOpen } from "reducers/ui/ws/ws.actions";
import {
  updateTrafficNotificationsWS,
  resetTrafficNotificationsWS,
} from "reducers/traffic/traffic.actions";

//COMPONENTS
import NavTab from "components/layout/nav-tab";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import ArcList from "components/layout/arc-list";
import ArcTrafficMeasures from "components/layout/arc-traffic/arc-traffic-measures";
import ArcDetail from "components/layout/arc-list/arc-detail";
import BottomControlsIcons from "components/shared/bottom-controls/bottom-controls-icons";

class TrafficPage extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {
      onArcMeasureTab: true,
      isCurrentArcLoading: false,
      currentArcGeoModel: null,
    };
  }

  componentDidMount = () => {
    const { setNavTabSelectedTab } = this.props;

    this.apiService = createApiService(this.context);

    setNavTabSelectedTab(0);
  };

  componentDidUpdate = (prevProps) => {
    const { currentArc } = this.props;

    // ARC SELECTION FROM LIST OR MAP
    if (prevProps.currentArc !== currentArc && currentArc) {
      this.setState({
        ...this.state,
        isCurrentArcLoading: true,
      });

      let stringFilter = "arcid='" + currentArc.properties.archId + "'";
      this.subscriptions$.push(
        this.apiService
          .getGeoserverFeatures(
            "road_sections",
            null,
            null,
            null,
            null,
            null,
            stringFilter
          )
          .pipe(
            take(1),
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "road_sections"
              );
              let currentArc = null;
              if (data.numberReturned > 0) {
                currentArc = featureCollection.features[0];
              }
              this.setState({
                ...this.state,
                currentArcGeoModel: currentArc,
              });
              return this.setState({
                ...this.state,
                isCurrentArcLoading: false,
              });
            }),
            catchError((error) => {
              console.error(error);
              this.setState({
                ...this.state,
                isCurrentArcLoading: false,
                currentArcGeoModel: null,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    } else if (prevProps.currentArc !== currentArc && !currentArc) {
      this.setState({
        ...this.state,
        currentArcGeoModel: null,
      });
    }
  };

  handleSearch = (value) => {
    const { setNavTabSearch } = this.props;
    setNavTabSearch(value, "traffic", null, null);
  };

  toggleArcDetails = (arc) => {
    const { setTrafficCurrentArc } = this.props;
    setTrafficCurrentArc({ arc: arc });
  };

  handleClickArcMeasures = () => {
    this.setState({ onArcMeasureTab: !this.state.onArcMeasureTab });
  };

  injectItems = () => {
    const {
      allTrafficVisible,
      filteredArcs,
      currentArc,
      menuTabReducer,
      currentArcMap,
      toggleGenericTable,
      isTrafficLoading,
    } = this.props;
    const { onArcMeasureTab, currentArcGeoModel } = this.state;

    const lengthArcs = currentArc ? "1" : filteredArcs.length;
    const labelArchi = "Archi (" + lengthArcs + ")";

    let controlsDetail = [
      {
        icon: "settings",
        tooltip: "Statistiche",
        toggle: (e) => toggleGenericTable("trafficTable"),
      },
    ];

    const trafficMeasure =
      isNetStateVisible &&
      currentArc &&
      currentArc.properties &&
      currentArc.properties.measures &&
      currentArc.properties.measures.find((item) => item.measureId === "los")
        ? currentArc.properties.measures.find(
            (item) => item.measureId === "los"
          ).measureValue
        : currentArc &&
          currentArc.properties &&
          currentArc.properties.profile &&
          currentArc.properties.profile.find((item) => item.measureId === "los")
        ? currentArc.properties.profile.find((item) => item.measureId === "los")
            .measureValue
        : null;

    return (
      <div className="uk-height-1-1 uk-width-1-1 inherit-transition uk-position-relative">
        <ErrorBoundary>
          <TabPanel className="tabsContainerTpl inherit-transition">
            <ul className="uk-tab uk-flex-between">
              {allTrafficVisible ? (
                <li
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className={menuTabReducer === 0 ? "uk-active" : ""}
                >
                  <a href="/#">{labelArchi}</a>
                </li>
              ) : null}
            </ul>
            {allTrafficVisible ? (
              <TabPanel
                className="overflow inherit-transition uk-height-1-1"
                value={menuTabReducer}
                index={0}
              >
                <ErrorBoundary>
                  {currentArc && currentArcGeoModel && !onArcMeasureTab ? (
                    <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
                      <div className="uk-height-1-1 overflow event-list-container">
                        <ArcDetail
                          toggleArcDetails={this.toggleArcDetails}
                          handleClickSecondTab={this.handleClickArcMeasures}
                          currentArc={currentArcGeoModel}
                          secondTab={"Misure"}
                          arcDetailType={"traffic"}
                          trafficMeasure={trafficMeasure}
                        />
                        <BottomControlsIcons controls={controlsDetail} />
                      </div>
                    </div>
                  ) : currentArc && currentArcGeoModel && onArcMeasureTab ? (
                    <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
                      <div className="uk-height-1-1 overflow event-list-container">
                        <ArcTrafficMeasures
                          toggleArcDetails={this.toggleArcDetails}
                          handleClickArcMeasures={this.handleClickArcMeasures}
                          currentArc={currentArc}
                          currentArcMap={currentArcMap}
                        />
                        <BottomControlsIcons controls={controlsDetail} />
                      </div>
                    </div>
                  ) : (
                    <ArcList
                      arcsList={filteredArcs}
                      toggleArcDetails={this.toggleArcDetails}
                      isArcsLoading={isTrafficLoading}
                      currentArcMap={currentArcMap}
                      arcListType={"traffic"}
                    />
                  )}
                </ErrorBoundary>
              </TabPanel>
            ) : null}
          </TabPanel>
        </ErrorBoundary>
      </div>
    );
  };

  render() {
    return (
      <NavTab
        isOpen={true}
        items={this.injectItems()}
        onSearch={this.handleSearch}
      />
    );
  }

  componentWillUnmount = () => {
    const { setNavTabSearch, toggleOpenTrafficTimeSlider } = this.props;
    this.subscriptions$.forEach((x) => x.unsubscribe());
    setNavTabSearch("", "traffic", null, null);

    toggleOpenTrafficTimeSlider(false);
  };
}

TrafficPage.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
  setTrafficArcsList,
  setTrafficCurrentArc,
  setTrafficCurrentArcMap,
  setTrafficContexts,
  setDayTypes,
  setMeasures,
  setVehicleClasses,
  setCurrentTrafficTime,
  setCurrentTrafficStartTime,
  setCurrentTrafficEndTime,
  setTrafficCurrentContext,
  setCurrentDayType,
  setCurrentDay,
  setCurrentVehicleClass,
  setCurrentMeasure,
  wsOpen,
  updateTrafficNotificationsWS,
  resetTrafficNotificationsWS,
  toggleGenericTable,
  setCurrentContextTimeOffSet,
  toggleOpenTrafficTimeSlider,
};

const mapStateToProps = (state) => ({
  //UI
  menuNavTabSearch: getNavTabSearch(state),
  menuTabReducer: getNavTabSelectedTab(state),
  isOpenNavTab: isOpenNavTab(state),
  //CHECKBOX
  allTrafficVisible: areAllTrafficVisible(state),
  isNetStateVisible: isNetStateVisible(state),
  isTypicalVisible: isTypicalVisible(state),
  //TRAFFIC REDUCER
  currentArc: getTrafficCurrentArc(state),
  currentArcMap: getTrafficCurrentArcMap(state),
  filteredArcs: getTrafficArcs(state),
  boundingBox: getBoundingBox(state),
  currentMapZoom: getCurrentZoom(state),
  currentContext: getTrafficCurrentContext(state),
  currentOffSet: getCurrentTimeOffSet(state),
  contexts: getTrafficContexts(state),
  currentTime: getTrafficCurrentTime(state),
  currentDayType: getTrafficCurrentDayType(state),
  trafficCurrentStartTime: getTrafficCurrentStartTime(state),
  measures: getTrafficMeasures(state),
  //WS
  isWSStationNotificationOpen: isWSStationNotificationOpen(state),
  wsLastNotification: getWsLastNotification(state),
  //LOADING
  isTrafficLoading: isTrafficLoading(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrafficPage)
);
