import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";
//
import { getPmvHistoryTableOpen } from "store";
import { togglePmvHistoryTable } from "reducers/ui/installations-menu/installations-menu.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";

class TableUpperTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleListTab = () => {
    const { changeSelectedTab } = this.props;
    changeSelectedTab("list");
  };

  toggleHistoryTab = () => {
    const { changeSelectedTab } = this.props;
    changeSelectedTab("history");
  };

  closeHistoryTab = () => {
    const { togglePmvHistoryTable, changeSelectedPmvAndTab } = this.props;
    togglePmvHistoryTable();
    changeSelectedPmvAndTab(null, "list");
  };

  render() {
    const {
      toggleGenericTable,
      isPmvHistoryTableOpen,
      selectedTab,
      selectedPmv,
    } = this.props;

    return (
      <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
        {selectedTab === "history" ? (
          <div className="dark-grey-space"></div>
        ) : (
          <div className="grey-space"></div>
        )}
        <div className=" uk-width-1-2">
          <ul className=" uk-tab-page-secondary-large uk-tab uk-margin-remove">
            <li
              className={
                selectedTab === "history"
                  ? "bottombar-title"
                  : "uk-active bottombar-title"
              }
              onClick={this.toggleListTab}
            >
              PMV RESOCONTO
            </li>
            {isPmvHistoryTableOpen && selectedPmv ? (
              <li
                className={
                  selectedTab === "history"
                    ? "uk-active bottombar-title uk-flex uk-flex-row uk-flex-middle"
                    : "bottombar-title uk-flex uk-flex-row uk-flex-middle"
                }
              >
                <span
                  className="uk-margin-right"
                  onClick={this.toggleHistoryTab}
                >
                  PMV STORICO -{" "}
                  {selectedPmv &&
                    selectedPmv.anagrafica &&
                    selectedPmv.anagrafica.nome}
                </span>
                <span
                  uk-tooltip="title: Chiudi Storico"
                  className="pointer"
                  uk-icon="icon: acr-interface-close"
                  onClick={this.closeHistoryTab}
                ></span>
              </li>
            ) : null}
          </ul>
        </div>
        <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
          <span
            className="pointer"
            uk-icon="icon: acr-interface-close"
            onClick={() => toggleGenericTable("pmvTable")}
          ></span>
        </div>
      </div>
    );
  }
}

TableUpperTabs.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleGenericTable,
  togglePmvHistoryTable,
};

const mapStateToProps = (state) => ({
  isPmvHistoryTableOpen: getPmvHistoryTableOpen(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableUpperTabs)
);
