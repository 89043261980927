import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";
//COMPONENTS
import Line from "./line";
import DockInstallation from "./dock-installation";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import BottombarTabs from "./bottombar-tabs";
import CamerasDock from "./cameras-dock";
import Permission from "components/shared/permission";
//ACTIONS
import {
  closeBottombar,
  setBottombarActiveTab,
  resetBottombarTabs,
  removeBottombarTab,
  reduceBottombar,
  expandBottombar,
} from "reducers/ui/bottombar/bottombar.actions";
import { resetActiveLines } from "reducers/lines/lines.actions";
import {
  resetPinnedCameras,
  removePinnedCamera,
  addNewCameraOnMap,
} from "reducers/installations/cameras/cameras.actions";
// STORE
import {
  getAllActiveLines,
  getPinnedCameras,
  getBottombarActiveTab,
  getBottombarTabs,
  isBottombarExpanded,
  isBottombarReduced,
} from "store";

class Bottombar extends Component {
  changeTab = (selectedTab) => {
    const { setBottombarActiveTab } = this.props;
    setBottombarActiveTab(selectedTab);
  };

  toggleMinimizeBottombar = () => {
    const {
      reduceBottombar,
      expandBottombar,
      isBottombarExpanded,
    } = this.props;
    if (isBottombarExpanded) {
      reduceBottombar();
    } else {
      expandBottombar();
    }
  };

  closeAndResetBottombar = () => {
    const {
      closeBottombar,
      resetPinnedCameras,
      resetActiveLines,
      resetBottombarTabs,
    } = this.props;
    closeBottombar();
    resetBottombarTabs();
    resetPinnedCameras();
    resetActiveLines();
  };

  removeCamera = (camera) => {
    const {
      removePinnedCamera,
      pinnedCameras,
      bottombarTabs,
      removeBottombarTab,
      closeBottombar,
      setBottombarActiveTab,
    } = this.props;
    removePinnedCamera(camera);

    if (pinnedCameras.length === 1) {
      let newTab =
        bottombarTabs.filter((item) => item !== "Telecamere").length > 0
          ? bottombarTabs[0]
          : null;
      setBottombarActiveTab(newTab);
      if (!newTab) {
        closeBottombar();
      }
      removeBottombarTab("Telecamere");
    }
  };

  openOnMap = (camera) => {
    const { addNewCameraOnMap } = this.props;
    addNewCameraOnMap(camera);
  };

  renderBottombarMinimised = () => {
    const { activeTab, bottombarTabs, pinnedCameras } = this.props;

    const maxNrOfStreamingCameras = this.context.maxNrOfStreamingCameras;

    return (
      <div className="bottombar-container-min">
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <BottombarTabs
            changeTab={this.changeTab}
            activeTab={activeTab}
            tabs={bottombarTabs}
            nrOfCameras={pinnedCameras.length}
            maxNrOfCameras={maxNrOfStreamingCameras}
          />
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            {/* <span
              className="uk-margin-small-right pointer"
              uk-icon="icon: acr-interface-new-window"
            ></span> */}
            <span
              className="uk-margin-small-right pointer"
              uk-icon="icon: acr-interface-enlarge"
              onClick={() => this.toggleMinimizeBottombar()}
            ></span>
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={() => this.closeAndResetBottombar()}
            ></span>
          </div>
        </div>
      </div>
    );
  };

  renderBottombar = () => {
    const { activeTab, bottombarTabs, pinnedCameras } = this.props;

    const maxNrOfStreamingCameras = this.context.maxNrOfStreamingCameras;

    let content = null;
    switch (activeTab) {
      case "Linearizzata":
        content = <Line />;
        break;
      case "Telecamere":
        content = (
          <Permission actions="CCTV-STREAMING">
            <CamerasDock
              removeCamera={this.removeCamera}
              openOnMap={this.openOnMap}
            />
          </Permission>
        );
        break;
      case "Pmv":
        content = <DockInstallation />;
        break;
      default:
        break;
    }

    return (
      <div className="bottombar-container">
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <BottombarTabs
            changeTab={this.changeTab}
            activeTab={activeTab}
            tabs={bottombarTabs}
            nrOfCameras={pinnedCameras.length}
            maxNrOfCameras={maxNrOfStreamingCameras}
          />
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            {/* <span
              className="uk-margin-small-right pointer"
              uk-icon="icon: acr-interface-new-window"
            ></span> */}
            <span
              className="uk-margin-small-right pointer"
              uk-icon="icon: acr-interface-minimize"
              onClick={() => this.toggleMinimizeBottombar()}
            ></span>
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={() => this.closeAndResetBottombar()}
            ></span>
          </div>
        </div>
        <ErrorBoundary>{content}</ErrorBoundary>
      </div>
    );
  };

  render = () => {
    const { isBottombarExpanded, isBottombarReduced } = this.props;

    return (
      <div
        className={
          isBottombarReduced
            ? "bottombar-external-minimised"
            : isBottombarExpanded
            ? "bottombar-external-open"
            : "bottombar-external-closed"
        }
        id="bottombar-container"
      >
        {isBottombarReduced
          ? this.renderBottombarMinimised()
          : isBottombarExpanded
          ? this.renderBottombar()
          : null}
      </div>
    );
  };
}
Bottombar.contextType = EnvironmentContext;

const mapDispatchToProps = {
  resetActiveLines,
  closeBottombar,
  setBottombarActiveTab,
  resetPinnedCameras,
  resetBottombarTabs,
  removePinnedCamera,
  addNewCameraOnMap,
  removeBottombarTab,
  reduceBottombar,
  expandBottombar,
};

const mapStateToProps = (state) => ({
  activeLines: getAllActiveLines(state),
  pinnedCameras: getPinnedCameras(state),
  activeTab: getBottombarActiveTab(state),
  bottombarTabs: getBottombarTabs(state),
  isBottombarExpanded: isBottombarExpanded(state),
  isBottombarReduced: isBottombarReduced(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Bottombar)
);
