import { TopbarActions } from "./topbar.actions";

export const INITIAL_STATE = {
  openKpiContainer: false,
};

export default function topbarReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TopbarActions.TOGGLE_KPI:
      return {
        ...state,
        openKpiContainer: !state.openKpiContainer,
      };
    case TopbarActions.TOGGLE_DOWN:
      return {
        ...state,
        openKpiDown: !state.openKpiDown,
      };
    default:
      return state;
  }
}

export const isKpiOpen = (state) => state.openKpiContainer;
export const openKpiDown = (state) => state.openKpiDown;
export const getListSelectedKpi = (state) => state.listSelectedKpi;
export const getListAllKpi = (state) => state.listAllKpi;
