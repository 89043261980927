import { CompositeLayer, IconLayer } from "deck.gl";
import { getIconEvent } from "utils/utils_event_icon";
import { selectFields } from "enums/selectable-params";
import IconClusterEventsLayer from "./icon-cluster-events-layer";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";
//ASSETS
import iconMapping from "assets/cluster/location-icon-mapping-2-events.json";
//UTIL
import { getEventPosition } from "utils/utils";

class EventsLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      visible,
      isDrawingMode,
      viewport,
      selectModal,
    } = this.props;
    if (viewport.zoom <= 12 && data && data.length >= 1) {
      return new IconClusterEventsLayer(
        this.getSubLayerProps({
          id: "",
          data,
          pickable: false,
          getPosition: (d) => getEventPosition(d.groupOfLocations),
          iconAtlas: data.forEach((element) =>
            element.severity === selectFields.GRAVITA.properties[0].name
              ? require("assets/cluster/location-icon-moltoAlta-severity-2.png")
              : element.severity === selectFields.GRAVITA.properties[1].name
              ? require("assets/cluster/location-icon-alta-severity-2.png")
              : element.severity === selectFields.GRAVITA.properties[2].name
              ? require("assets/cluster/location-icon-media-severity-2.png")
              : element.severity === selectFields.GRAVITA.properties[3].name ||
                element.severity === selectFields.GRAVITA.properties[4].name
              ? require("assets/cluster/location-icon-bassa-severity-2.png")
              : ""
          ),
          iconMapping,
          sizeScale: 60,
        })
      );
    } else {
      return [
        //EVENTS
        new IconLayer(
          this.getSubLayerProps({
            id: id + "icons",
            data: data,
            pickable: isDrawingMode ? false : true,
            ...DeckLayerUtil.loadOptions(),
            getIcon: (data) => ({
              url: getIconEvent(data, selectModal),
              width: 80,
              height: 80,
              anchorX: 54,
              anchorY: 90,
              mask: false,
            }),
            billboard: true,
            sizeScale: 8,
            showCluster: true,
            visible: visible,
            getPosition: (d) => getEventPosition(d.groupOfLocations),
            getSize: (data) => (data && data.selected ? 10 : 8),
          })
        ),
        //EVENT SEVERITY
        new IconLayer(
          this.getSubLayerProps({
            id: id + "severity",
            data: data,
            pickable: isDrawingMode ? false : true,
            ...DeckLayerUtil.loadOptions(),
            getIcon: (data) => ({
              url: DeckLayerUtil.getIconSeverityEvents(data),
              width: 40,
              height: 20,
              anchorX: 30,
            }),
            billboard: true,
            sizeScale: 6,
            showCluster: true,
            visible: visible,
            getPosition: (d) => getEventPosition(d.groupOfLocations),
            getSize: () => 4,
          })
        ),
      ];
    }
  }
}

EventsLayer.layerName = "EventsLayer";

export default EventsLayer;
