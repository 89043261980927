export const EventMenuActions = {
  SET_EVENT_VISIBILITY: "UI/EVENT_MENU/SET_EVENT_VISIBILITY",
  TOGGLE_EVENT_REPORT_OPEN: "EVENTS/TOGGLE_EVENT_REPORT_OPEN",
  TOGGLE_EVENT_HISTORY_OPEN: "EVENTS/TOGGLE_EVENT_HISTORY_OPEN",
  SET_ALL_EVENT_VISIBILITY: "EVENTS/SET_ALL_EVENT_VISIBILITY",
  SET_ACTIVE_VISIBILITY: "EVENTS/SET_ACTIVE_VISIBILITY",
  SET_PLANNED_VISIBILITY: "EVENTS/SET_PLANNED_VISIBILITY",
  TOGGLE_EVENT_REPORT_DETAIL: "EVENTS/TOGGLE_EVENT_REPORT_DETAIL",
  TOGGLE_EVENT_HISTORY_DETAIL: "EVENTS/TOGGLE_EVENT_HISTORY_DETAIL",
  SET_IS_EVENT_LOADING: "EVENTS/SET_IS_EVENT_LOADING",
};

//EVENT
export const setEventVisibility = (visible) => (dispatch) => {
  dispatch({
    type: EventMenuActions.SET_EVENT_VISIBILITY,
    payload: { visible },
  });
};

export const setAllEventVisibility = (visible) => (dispatch) => {
  dispatch({
    type: EventMenuActions.SET_ALL_EVENT_VISIBILITY,
    payload: { visible },
  });
};

export const setActiveVisibility = (visible) => (dispatch) => {
  dispatch({
    type: EventMenuActions.SET_ACTIVE_VISIBILITY,
    payload: { visible },
  });
};
export const setPlannedVisibility = (visible) => (dispatch) => {
  dispatch({
    type: EventMenuActions.SET_PLANNED_VISIBILITY,
    payload: { visible },
  });
};

export const toggleEventReport = () => (dispatch) => {
  dispatch({
    type: EventMenuActions.TOGGLE_EVENT_REPORT_OPEN,
    payload: null,
  });
};

export const toggleEventReportDetail = (open) => (dispatch) => {
  dispatch({
    type: EventMenuActions.TOGGLE_EVENT_REPORT_DETAIL,
    payload: open,
  });
};

export const toggleEventHistory = () => (dispatch) => {
  dispatch({
    type: EventMenuActions.TOGGLE_EVENT_HISTORY_OPEN,
    payload: null,
  });
};

export const toggleEventHistoryDetail = (open) => (dispatch) => {
  dispatch({
    type: EventMenuActions.TOGGLE_EVENT_HISTORY_DETAIL,
    payload: open,
  });
};

export const setIsEventLoading = ({ isEventsLoading, isEventsAllLoading }) => (
  dispatch
) => {
  dispatch({
    type: EventMenuActions.SET_IS_EVENT_LOADING,
    payload: {
      isEventsLoading,
      isEventsAllLoading,
    },
  });
};
