import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { tap, catchError } from "rxjs/operators";
import { of, forkJoin } from "rxjs";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
//COMPONENTS
//ACTIONS
import {
  getNewEvent,
  getNewEventValid,
  getCurrentEvent,
  getTypeModal,
  getBoundingBoxCartografica,
} from "store";
import { setNewEvent, updateNewEvent } from "reducers/modal/modal.actions";
import {
  enableForwardEventButton,
  disableForwardEventButton,
} from "reducers/ui/modal/modal.actions";
//COMPONENT
import ModalMap from "components/layout/modal/event/new-event/modal-map";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
//PARAMETRI SELEZIONABILI
import { selectFields } from "enums/selectable-params";
// MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";
import { debounce } from "utils/utils";

class LocalizationRDSEstesa extends Component {
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {
      modalMapWidth: false,
      modalMapHalfWidth: true,
      statePointsListMap: [],
      pointsListPercorso: [],
      finalPointsList: [],
      segmentsList: [],
      isSegmentsLoading: false,
      isPointsLoading: false,
      currentFinalPoint: null,
      currentInitialPoint: null,
      directionsArray: [],
      maxOffset1: 0,
      maxOffset2: 0,
      arcsToDraw: [],
    };
    this.getArcsOnChangeDistanceDebounced = debounce(
      this.getArcsOnChangeDistance,
      1200
    );
  }

  componentDidMount = () => {
    const { newEvent } = this.props;
    this.apiService = createApiService(this.context);
    const selectReteRdsTmc = document.getElementById("rete-rds-estesa");

    selectFields.RETE_RDS_TMC.properties.forEach(
      (item, index) =>
        (selectReteRdsTmc.options[selectReteRdsTmc.options.length] = new Option(
          item.name,
          index
        ))
    );

    selectReteRdsTmc.value = String(newEvent.networkRDSEstesa);

    this.getApiRoadsList();
  };

  removeOptions(selectElement) {
    while (selectElement.options.length > 0) {
      selectElement.remove(0);
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { statePointsListMap } = this.state;
    const { newEvent, typeModal, setNewEvent, pointsListMap } = this.props;

    if (
      prevProps.pointsListMap &&
      pointsListMap &&
      prevProps.pointsListMap.length !== pointsListMap.length
    ) {
      this.setState({
        ...this.state,
        statePointsListMap: pointsListMap,
      });
    }

    if (
      prevProps.newEvent.streetRDSEstesa !== newEvent.streetRDSEstesa &&
      newEvent.streetRDSEstesa !== ""
    ) {
      this.resetEvent("street");
      this.resetState("street");
      this.setState(
        {
          ...this.state,
          directionsArray: [],
        },
        () => {
          this.updateDirezioneList();
          this.getApiPointsListMap();
        }
      );
    }

    if (
      prevProps.newEvent.streetRDSEstesa !== newEvent.streetRDSEstesa &&
      (newEvent.streetRDSEstesa === "" || newEvent.streetRDSEstesa === "-")
    ) {
      this.resetEvent("street");
      this.resetState("street");
      this.setState(
        {
          ...this.state,
          statePointsListMap: [],
          pointsListPercorso: [],
          finalPointsList: [],
          currentInitialPoint: null,
          maxOffset1: 0,
          maxOffset2: 0,
          arcsToDraw: [],
          directionsArray: [],
        },
        () => {
          this.updateDirezioneList();
          this.getApiPointsListMap();
        }
      );
    }

    if (
      prevProps.newEvent.directionRDSEstesaCoded !==
        newEvent.directionRDSEstesaCoded &&
      newEvent.directionRDSEstesaCoded !== ""
    ) {
      this.resetEvent("direction");
      this.resetState("direction");
      this.getApiPointsListPercorso();
    }

    if (
      prevProps.newEvent.directionRDSEstesaCoded !==
        newEvent.directionRDSEstesaCoded &&
      (newEvent.directionRDSEstesaCoded === "" ||
        newEvent.directionRDSEstesaCoded === "-")
    ) {
      this.resetEvent("direction");
      this.resetState("direction");
    }

    if (
      prevProps.newEvent.initialPointRDSEstesa !==
        newEvent.initialPointRDSEstesa &&
      (newEvent.initialPointRDSEstesa === "" ||
        newEvent.initialPointRDSEstesa === "-")
    ) {
      this.resetState("initial-point");
      this.resetEvent("initial-point");
      this.setState({
        ...this.state,
        currentInitialPoint: null,
        maxOffset1: 0,
        maxOffset2: 0,
        arcsToDraw: [],
      });
    }

    if (
      prevProps.newEvent.initialPointRDSEstesa !==
        newEvent.initialPointRDSEstesa &&
      newEvent.initialPointRDSEstesa !== ""
    ) {
      this.resetState("initial-point");
      this.resetEvent("initial-point");
      let point = statePointsListMap.find(
        (item) => item.properties.lcd === Number(newEvent.initialPointRDSEstesa)
      );
      let newEventAfterMovingEstesa = [];
      let initialPRDSEstesaName = "";
      let cInitPoint = null;
      if (point) {
        cInitPoint = point;
        newEventAfterMovingEstesa = [...point.geometry.coordinates];
        initialPRDSEstesaName = point.properties.name1;
      } else {
        newEventAfterMovingEstesa = [];
        initialPRDSEstesaName = "";
        cInitPoint = null;
      }
      setNewEvent(
        "pointCoordinatesAfterMovingEstesa",
        newEventAfterMovingEstesa
      );
      setNewEvent("initialPointRDSEstesaName", initialPRDSEstesaName);
      this.setState({
        ...this.state,
        currentInitialPoint: cInitPoint,
      });
      this.filterFinalPointList();
      this.updateDistances();
    }

    if (
      prevProps.newEvent.finalPointRDSEstesa !== newEvent.finalPointRDSEstesa &&
      newEvent.finalPointRDSEstesa !== ""
    ) {
      this.resetState("final-point");
      this.resetEvent("final-point");

      let point = statePointsListMap.find(
        (item) => item.properties.lcd === Number(newEvent.finalPointRDSEstesa)
      );
      let finalPEstesaName = "";
      let cFinalPoint = null;
      if (point) {
        cFinalPoint = point;
        finalPEstesaName = point.properties.name1;
      } else {
        finalPEstesaName = "";
        cFinalPoint = null;
      }
      this.setState({
        ...this.state,
        currentFinalPoint: cFinalPoint,
      });
      setNewEvent("finalPointRDSEstesaName", finalPEstesaName);
      this.updateRadiusOfInfluence();
      this.updateDistances();
    }

    if (
      prevProps.newEvent.finalPointRDSEstesa !== newEvent.finalPointRDSEstesa &&
      (newEvent.finalPointRDSEstesa === "" ||
        newEvent.finalPointRDSEstesa === "-")
    ) {
      this.resetState("final-point");
      this.resetEvent("final-point");
      this.setState({
        ...this.state,
        currentFinalPoint: null,
        arcsToDraw: [],
      });
    }

    if (
      prevProps.newEvent.distanceFromInitialPointRDSEstesa !==
        newEvent.distanceFromInitialPointRDSEstesa &&
      newEvent.directionRDSEstesaCoded !== "" &&
      newEvent.directionRDSEstesaCoded !== "-"
    ) {
      this.getArcsOnChangeDistanceDebounced();
    }

    if (
      prevProps.newEvent.distanceFromFinalPointRDSEstesa !==
        newEvent.distanceFromFinalPointRDSEstesa &&
      newEvent.directionRDSEstesaCoded !== "" &&
      newEvent.directionRDSEstesaCoded !== "-"
    ) {
      this.getArcsOnChangeDistanceDebounced();
    }

    if (
      newEvent.initialPointRDSEstesa &&
      newEvent.finalPointRDSEstesa &&
      prevProps.newEvent.finalPointRDSEstesa !== newEvent.finalPointRDSEstesa
    ) {
      this.getArcsOnChangeDistanceDebounced();
    }

    if (
      (typeModal === "modifyEvent" &&
        prevProps.newEvent.arcListRDSEstesa.length !==
          newEvent.arcListRDSEstesa.length) ||
      (typeModal === "modifyEvent" &&
        prevProps.newEvent.finalPointRDSEstesa !==
          newEvent.finalPointRDSEstesa) ||
      (typeModal === "modifyEvent" &&
        prevProps.newEvent.directionRDSEstesaCoded !==
          newEvent.directionRDSEstesaCoded) ||
      (typeModal === "modifyEvent" &&
        prevProps.newEvent.initialPointRDSEstesa !==
          newEvent.initialPointRDSEstesa)
    ) {
      setNewEvent("checkIfEventIsModified", true);
    }
  };

  getApiRoadsList = () => {
    this.setState({
      ...this.state,
      isSegmentsLoading: true,
    });

    let filter = "have_network=true";
    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures("roads", null, null, null, null, null, filter)
        .pipe(
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "roads"
            );
            let segmentsList = [];
            if (featureCollection.numberReturned > 0) {
              segmentsList = [...featureCollection.features];
            }
            return this.setState(
              {
                ...this.state,
                isSegmentsLoading: false,
                segmentsList: segmentsList,
              },
              () => {
                this.updateStreetList();
                this.getApiPointsListMap();
              }
            );
          }),
          catchError((error) => {
            console.error(error);
            this.setState({
              ...this.state,
              isSegmentsLoading: false,
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  //API CALL TO POINTS LIST
  getApiPointsListMap = () => {
    const { newEvent, pointsListMap } = this.props;
    const { segmentsList } = this.state;

    let filter;
    if (
      newEvent &&
      newEvent.streetRDSEstesa &&
      newEvent.streetRDSEstesa !== "" &&
      newEvent.streetRDSEstesa !== "-"
    ) {
      let street = segmentsList.find(
        (item) => item.properties.road_lcd === Number(newEvent.streetRDSEstesa)
      );
      if (street) {
        filter = "road_lcd=" + street.properties.road_lcd;
        this.subscriptions.push(
          this.apiService
            .getGeoserverFeatures(
              "points",
              null,
              null,
              null,
              null,
              null,
              filter
            )
            .pipe(
              tap((data) => {
                let featureCollection = FeatureCollectionModel.fromREST(
                  data,
                  "points"
                );
                let points = [];
                if (featureCollection.numberReturned > 0) {
                  points = [...featureCollection.features];
                }
                return this.setState(
                  {
                    ...this.state,
                    statePointsListMap: points,
                  },
                  this.filterFinalPointList
                );
              }),
              catchError((error) => {
                console.error(error);
                return of(error);
              })
            )
            .subscribe()
        );
      }
    } else {
      this.setState({
        ...this.state,
        statePointsListMap: pointsListMap,
      });
    }
  };

  getApiPointsListPercorso = () => {
    const { newEvent } = this.props;
    const { directionsArray } = this.state;

    this.setState({
      ...this.state,
      isPointsLoading: false,
    });

    let direction = directionsArray.find(
      (item) => item.value === newEvent.directionRDSEstesaCoded
    );

    if (newEvent.streetRDSEstesa && direction && direction.value !== "-") {
      let direzione = direction.value === "BOTH" ? "POSITIVE" : direction.value;

      this.subscriptions.push(
        this.apiService
          .getRouteDetailsOnSectionV2(direzione, newEvent.streetRDSEstesa)
          .pipe(
            tap((data) => {
              let points = [...data];
              return this.setState(
                {
                  ...this.state,
                  isPointsLoading: false,
                  pointsListPercorso: points,
                },
                () => {
                  this.updatePointsList();
                  this.filterFinalPointList();
                }
              );
            })
          )
          .subscribe()
      );
    } else if (direction && direction.value === "-") {
      this.setState(
        {
          ...this.state,
          isPointsLoading: false,
          pointsListPercorso: [],
        },
        this.updatePointsList
      );
    }
  };

  updatePointsList = () => {
    const { pointsListPercorso } = this.state;
    const { newEvent } = this.props;

    const selectInitialPointRdsTMc = document.getElementById(
      "initial-point-rds-estesa"
    );

    const selectFinalPointRdsTMc = document.getElementById(
      "final-point-rds-estesa"
    );

    if (pointsListPercorso.length > 0) {
      selectInitialPointRdsTMc.options[0] = new Option("-", undefined);
      selectFinalPointRdsTMc.options[0] = new Option("-", undefined);

      pointsListPercorso.forEach(
        (item) =>
          (selectInitialPointRdsTMc.options[
            selectInitialPointRdsTMc.options.length
          ] = new Option(item.name1, item.lcd))
      );

      pointsListPercorso.forEach(
        (item) =>
          (selectFinalPointRdsTMc.options[
            selectFinalPointRdsTMc.options.length
          ] = new Option(item.name1, item.lcd))
      );
    }

    if (
      newEvent.initialPointRDSEstesa !== "" &&
      newEvent.initialPointRDSEstesa !== "-" &&
      newEvent.finalPointRDSEstesa !== "" &&
      newEvent.finalPointRDSEstesa !== "-"
    ) {
      selectInitialPointRdsTMc.value = String(newEvent.initialPointRDSEstesa);
      selectFinalPointRdsTMc.value = String(newEvent.finalPointRDSEstesa);

      //MAKE API CALL FOR INITIAL AND FINAL POINT
      this.getInitialAndFinalPoint(
        newEvent.initialPointRDSEstesa,
        newEvent.finalPointRDSEstesa
      );
      this.updateRadiusOfInfluence();
    }
  };

  getInitialAndFinalPoint = (initial, final) => {
    const { setNewEvent } = this.props;

    const initialPointCall = this.apiService.getGeoserverFeatures(
      "points",
      null,
      null,
      null,
      null,
      null,
      "lcd = " + initial
    );
    const finalPointCall = this.apiService.getGeoserverFeatures(
      "points",
      null,
      null,
      null,
      null,
      null,
      "lcd = " + final
    );

    this.subscriptions.push(
      forkJoin([initialPointCall, finalPointCall]).subscribe((res) => {
        const initialPoint =
          res && res.length > 0 && res[0].features && res[0].features.length > 0
            ? res[0].features[0]
            : null;
        const finalPoint =
          res && res.length > 0 && res[1].features && res[1].features.length > 0
            ? res[1].features[0]
            : null;

        if (initialPoint && finalPoint) {
          this.setState(
            {
              ...this.state,
              currentInitialPoint: initialPoint,
              currentFinalPoint: finalPoint,
            },
            () => {
              this.updateDistances();
              this.getArcsOnChangeDistanceDebounced();
            }
          );
          setNewEvent("finalPointRDSEstesaName", finalPoint.properties.name1);
          setNewEvent(
            "initialPointRDSEstesaName",
            initialPoint.properties.name1
          );
        } else {
          this.setState({
            ...this.state,
            currentInitialPoint: null,
            currentFinalPoint: null,
          });
          setNewEvent("initialPointRDSEstesaName", "");
          setNewEvent("finalPointRDSEstesaName", "");
        }
      })
    );
  };

  updateStreetList = () => {
    const { newEvent } = this.props;
    const { segmentsList } = this.state;
    const selectStradaRdsTmc = document.getElementById("strada-rds-estesa");

    this.resetState("street");

    if (segmentsList.length > 0) {
      selectStradaRdsTmc.options[0] = new Option("-", undefined);

      segmentsList.forEach(
        (item) =>
          (selectStradaRdsTmc.options[
            selectStradaRdsTmc.options.length
          ] = new Option(
            item && item.properties && item.properties.name
              ? item.properties.name
              : "-",
            item.properties.road_lcd
          ))
      );
    }
    if (
      newEvent.streetRDSEstesa !== 0 &&
      newEvent.streetRDSEstesa !== "" &&
      newEvent.streetRDSEstesa !== "-"
    ) {
      selectStradaRdsTmc.value = newEvent.streetRDSEstesa;
      this.updateDirezioneList();
      this.updateRadiusOfInfluence();
    }
  };

  updateDirezioneList = () => {
    const { newEvent } = this.props;
    const { segmentsList } = this.state;

    const selectDirezioneRdsTmc = document.getElementById(
      "direzione-rds-estesa"
    );
    let street = segmentsList.find(
      (item) => item.properties.road_lcd === Number(newEvent.streetRDSEstesa)
    );
    let dirArray = [];

    if (street) {
      //CREATE AN ARRAY WITH TWO VALUES: START AND END ROAD OF TRATTA
      dirArray = [
        { text: "-", value: undefined },
        { text: "Entrambe", value: "BOTH" },
        {
          text: street.properties.start_road,
          value: "NEGATIVE",
        },
        {
          text: street.properties.end_road,
          value: "POSITIVE",
        },
      ];

      this.setState({
        ...this.state,
        directionsArray: dirArray,
      });
    }

    this.removeOptions(selectDirezioneRdsTmc);

    dirArray.forEach(
      (item) =>
        (selectDirezioneRdsTmc.options[
          selectDirezioneRdsTmc.options.length
        ] = new Option(item.text, item.value))
    );

    if (
      newEvent.directionRDSEstesaCoded !== undefined &&
      newEvent.directionRDSEstesaCoded !== "" &&
      newEvent.directionRDSEstesaCoded !== "-"
    ) {
      selectDirezioneRdsTmc.value = newEvent.directionRDSEstesaCoded;
      //api call must be done AFTER directions have been loaded in state!
      this.setState(
        {
          ...this.state,
          directionsArray: dirArray,
        },
        this.getApiPointsListPercorso
      );
    }
  };

  filterFinalPointList = () => {
    const { newEvent } = this.props;
    const { pointsListPercorso } = this.state;
    const selectFinalPoint = document.getElementById("final-point-rds-estesa");

    this.removeOptions(selectFinalPoint);

    let filteredList = [...pointsListPercorso];
    let indexOfFirstLCD = filteredList.findIndex(
      (item) => item.lcd === Number(newEvent.initialPointRDSEstesa)
    );

    filteredList = filteredList.splice(0, indexOfFirstLCD);
    filteredList = filteredList.reverse();

    selectFinalPoint.options[0] = new Option("-", undefined);

    filteredList.forEach(
      (item) =>
        (selectFinalPoint.options[selectFinalPoint.options.length] = new Option(
          item.name1,
          item.lcd
        ))
    );

    if (
      newEvent.finalPointRDSEstesa !== "" &&
      newEvent.finalPointRDSEstesa !== "-"
    ) {
      selectFinalPoint.value = String(newEvent.finalPointRDSEstesa);
      this.updateDistances();
      this.updateRadiusOfInfluence();
    }
  };

  updateDistances = () => {
    const { newEvent } = this.props;
    const { pointsListPercorso } = this.state;
    const selectDistanceFromInitialPoint = document.getElementById(
      "distance-initial"
    );
    const selectDistanceFromFinalPoint = document.getElementById(
      "distance-final"
    );

    let selected1 = pointsListPercorso.find(
      (item) => item.lcd === Number(newEvent.initialPointRDSEstesa)
    );

    let selected2 = pointsListPercorso.find(
      (item) => item.lcd === Number(newEvent.finalPointRDSEstesa)
    );

    let maxDistance1 = null;
    let maxDistance2 = null;

    if (
      selected1 &&
      typeof selected1.distance_fe === "number" &&
      selected1.distance_fe >= 0
    ) {
      let dir1 =
        selected1 && selected1.direction
          ? selected1.direction.toLowerCase() === "neg"
            ? "pos"
            : "neg"
          : "";
      let previousPoint1 = dir1
        ? pointsListPercorso.find((item) => {
            if (selected1[dir1 + "_off_lcd"]) {
              return item.lcd === selected1[dir1 + "_off_lcd"];
            } else {
              if (dir1 === "pos") {
                return item.lcd === selected1["pos_off_lcd"];
              } else {
                return item.lcd === selected1["neg_off_lcd"];
              }
            }
          })
        : null;

      let diffDistance;
      if (previousPoint1 && previousPoint1.distance_fe > 0) {
        diffDistance =
          selected1.distance_fe > previousPoint1.distance_fe
            ? selected1.distance_fe - previousPoint1.distance_fe
            : previousPoint1.distance_fe - selected1.distance_fe;
      } else {
        diffDistance = selected1.distance_fe;
      }
      maxDistance1 = Math.trunc(Math.abs(diffDistance) * 1000);

      selectDistanceFromInitialPoint.setAttribute("max", maxDistance1);
    }

    if (
      selected2 &&
      typeof selected2.distance_fe === "number" &&
      selected2.distance_fe >= 0
    ) {
      let dir2 =
        selected2 && selected2.direction && selected2.direction.toLowerCase();
      let previousPoint2 = pointsListPercorso.find((item) => {
        if (selected2[dir2 + "_off_lcd"]) {
          return item.lcd === selected2[dir2 + "_off_lcd"];
        } else {
          if (dir2 === "pos") {
            return item.lcd === selected2["pos_off_lcd"];
          } else {
            return item.lcd === selected2["neg_off_lcd"];
          }
        }
      });

      let diffDistance;
      if (previousPoint2 && previousPoint2.distance_fe > 0) {
        diffDistance =
          selected2.distance_fe > previousPoint2.distance_fe
            ? selected2.distance_fe - previousPoint2.distance_fe
            : previousPoint2.distance_fe - selected2.distance_fe;
      } else {
        diffDistance = selected2.distance_fe;
      }
      maxDistance2 = Math.trunc(Math.abs(diffDistance) * 1000);

      selectDistanceFromFinalPoint.setAttribute("max", maxDistance2);
    }

    if (
      newEvent &&
      newEvent.distanceFromInitialPointRDSEstesa &&
      newEvent.distanceFromInitialPointRDSEstesa !== ""
    ) {
      selectDistanceFromInitialPoint.value = String(
        newEvent.distanceFromInitialPointRDSEstesa
      );
    } else {
      selectDistanceFromInitialPoint.value = 0;
    }

    if (
      newEvent &&
      newEvent.distanceFromFinalPointRDSEstesa &&
      newEvent.distanceFromFinalPointRDSEstesa !== ""
    ) {
      selectDistanceFromFinalPoint.value = String(
        newEvent.distanceFromFinalPointRDSEstesa
      );
    } else {
      selectDistanceFromFinalPoint.value = 0;
    }

    this.setState({
      ...this.state,
      maxOffset1: maxDistance1,
      maxOffset2: maxDistance2,
    });
  };

  updateRadiusOfInfluence = () => {
    const { newEvent } = this.props;

    const inputRadiusOfInfluenceRdsTmc = document.getElementById(
      "radius-influence-rds-tmc"
    );

    if (newEvent && newEvent.radiusInfluenceRDSEstesa) {
      inputRadiusOfInfluenceRdsTmc.value = String(
        newEvent.radiusInfluenceRDSEstesa
      );
    }
  };

  getArcsOnChangeDistance = () => {
    const { newEvent, setNewEvent } = this.props;
    const { currentInitialPoint, currentFinalPoint } = this.state;
    UIkit.notification.closeAll();

    if (currentInitialPoint && currentFinalPoint) {
      this.setState({
        ...this.state,
        isDistanceLoading: true,
      });

      let direzione = newEvent.directionRDSEstesaCoded;
      let distanzaSource = newEvent.distanceFromInitialPointRDSEstesa
        ? newEvent.distanceFromInitialPointRDSEstesa
        : 0;
      let distanzaTarget = newEvent.distanceFromFinalPointRDSEstesa
        ? newEvent.distanceFromFinalPointRDSEstesa
        : 0;
      let idPointSource = currentInitialPoint.properties.lcd;
      let idPointTarget = currentFinalPoint.properties.lcd;

      this.subscriptions.push(
        this.apiService
          .getSegmentEventSectionV2(
            direzione,
            distanzaSource,
            distanzaTarget,
            idPointSource,
            idPointTarget
          )
          .pipe(
            tap((data) => {
              this.setState({ ...this.state, arcsToDraw: data });
              setNewEvent(
                "pointCoordinatesAfterMovingEstesa",
                data.geometry &&
                  data.geometry.coordinates.length > 0 &&
                  data.geometry.coordinates[0]
              );
              let newArcList = [...data.properties.arc_list];
              if (direzione === "BOTH") {
                newArcList = [
                  ...data.properties.arc_list,
                  ...data.properties.arc_list_brother,
                ];
              }
              newArcList = newArcList.filter((item) => item !== null);
              setNewEvent("arcListRDSEstesa", newArcList);
            }),
            catchError((error) => {
              setNewEvent(
                "pointCoordinatesAfterMovingEstesa",
                currentInitialPoint.geometry.coordinates
              );
              UIkit.notification({
                message:
                  "Ci sono stati problemi nel caricamento del nodo corrispondente",
                status: "primary",
                pos: "bottom-center",
                timeout: 5000,
              });
              console.error(error);
              this.setState({
                ...this.state,
                isDistanceLoading: false,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  resetState = (string) => {
    const selectInitialPointRdsTMc = document.getElementById(
      "initial-point-rds-estesa"
    );
    const selectFinalPointRdsTmc = document.getElementById(
      "final-point-rds-estesa"
    );

    const inputRadiusOfInfluenceRdsTmc = document.getElementById(
      "radius-influence-rds-tmc"
    );
    const selectDirezioneRdsTmc = document.getElementById(
      "direzione-rds-estesa"
    );
    const selectStradaRdsTmc = document.getElementById("strada-rds-estesa");

    const inputDistanceFromInitialPoint = document.getElementById(
      "distance-initial"
    );
    const inputDistanceFromFinalPoint = document.getElementById(
      "distance-final"
    );

    if (string === "network" || string === "street" || string === "direction") {
      this.removeOptions(selectInitialPointRdsTMc);
      this.removeOptions(selectFinalPointRdsTmc);

      if (inputRadiusOfInfluenceRdsTmc) {
        inputRadiusOfInfluenceRdsTmc.value = 1000;
      }
      if (inputDistanceFromInitialPoint) {
        inputDistanceFromInitialPoint.value = 0;
      }
      if (inputDistanceFromFinalPoint) {
        inputDistanceFromFinalPoint.value = 0;
      }
    }
    if (string === "network" || string === "street") {
      this.removeOptions(selectDirezioneRdsTmc);
    }
    if (string === "network") {
      this.removeOptions(selectStradaRdsTmc);
    }
    if (string === "initial-point" || string === "final-point") {
      if (inputDistanceFromFinalPoint) {
        inputDistanceFromFinalPoint.value = 0;
      }
    }
    if (string === "initial-point") {
      if (inputRadiusOfInfluenceRdsTmc) {
        inputRadiusOfInfluenceRdsTmc.value = 1000;
      }
      if (inputDistanceFromInitialPoint) {
        inputDistanceFromInitialPoint.value = 0;
      }
    }
  };

  resetEvent = (string) => {
    const { setNewEvent } = this.props;

    if (string === "network") {
      setNewEvent("streetRDSEstesa", "");
      setNewEvent("arcListRDSEstesa", []);
    }
    if (string === "street" || string === "network") {
      setNewEvent("directionRDSEstesaCodedCoded", "");
      setNewEvent("directionRDSEstesaCodedName", "");
      setNewEvent("initialPointRDSEstesa", "");
      setNewEvent("finalPointRDSEstesa", "");
      setNewEvent("initialPointRDSEstesaName", "");
      setNewEvent("finalPointRDSEstesaName", "");
      setNewEvent("distanceFromInitialPointRDSEstesa", 0);
      setNewEvent("distanceFromFinalPointRDSEstesa", 0);
      setNewEvent("radiusInfluenceRDSEstesa", 1000);
      setNewEvent("arcListRDSEstesa", []);
    }
    if (string === "direction") {
      setNewEvent("pointCoordinatesAfterMovingEstesa", []);
      setNewEvent("initialPointRDSEstesa", "");
      setNewEvent("finalPointRDSEstesa", "");
      setNewEvent("initialPointRDSEstesaName", "");
      setNewEvent("finalPointRDSEstesaName", "");
      setNewEvent("distanceFromInitialPointRDSEstesa", 0);
      setNewEvent("distanceFromFinalPointRDSEstesa", 0);
      setNewEvent("radiusInfluenceRDSEstesa", 1000);
      setNewEvent("arcListRDSEstesa", []);
    }

    if (string === "initial-point" || string === "final-point") {
      setNewEvent("distanceFromFinalPointRDSEstesa", 0);
      setNewEvent("radiusInfluenceRDSEstesa", 1000);
    }

    if (string === "initial-point") {
      setNewEvent("finalPointRDSEstesa", "");
      setNewEvent("finalPointRDSEstesaName", "");
      setNewEvent("distanceFromInitialPointRDSEstesa", 0);
      setNewEvent("radiusInfluenceRDSEstesa", 1000);
      setNewEvent("arcListRDSEstesa", []);
    }
  };

  handler = (e, name) => {
    const { setNewEvent } = this.props;
    const { statePointsListMap, directionsArray } = this.state;
    var handlervalue = e.target.value;

    if (handlervalue && handlervalue !== "") {
      setNewEvent(name, handlervalue);
    }
    if (handlervalue && name === "initialPointRDSEstesa") {
      let point = statePointsListMap.find(
        (item) => item.properties.lcd === Number(handlervalue)
      );
      let initialCoordsAfterMovingEstesa = [];
      let initialPEstesa = "";
      let cInitPoint = null;
      if (point) {
        cInitPoint = point;
        initialCoordsAfterMovingEstesa = [...point.geometry.coordinates];
        initialPEstesa = point.properties.name1;
      } else {
        initialCoordsAfterMovingEstesa = [];
        initialPEstesa = "";
        cInitPoint = null;
      }
      this.setState({
        ...this.state,
        currentInitialPoint: cInitPoint,
      });
      setNewEvent(
        "pointCoordinatesAfterMovingEstesa",
        initialCoordsAfterMovingEstesa
      );
      setNewEvent("initialPointRDSEstesaName", initialPEstesa);
    }
    if (handlervalue && name === "finalPointRDSEstesa") {
      let point = statePointsListMap.find(
        (item) => item.properties.lcd === Number(handlervalue)
      );
      if (point) {
        this.setState({
          ...this.state,
          currentFinalPoint: point,
        });
        setNewEvent("finalPointRDSEstesaName", point.properties.name1);
      } else {
        this.setState({
          ...this.state,
          currentFinalPoint: null,
        });
        setNewEvent("pointCoordinatesAfterMovingEstesa", []);
        setNewEvent("finalPointRDSEstesaName", "");
      }
    }

    if (handlervalue && name === "directionRDSEstesaCoded") {
      setNewEvent("directionRDSEstesaCoded", handlervalue);

      let directionName = directionsArray.find(
        (item) => item.value === handlervalue
      );

      if (directionName && directionName.text) {
        setNewEvent("directionRDSEstesaName", directionName.text);
      }
    }
  };

  disableForwardButton = () => {
    const {
      enableForwardEventButton,
      disableForwardEventButton,
      newEventValid,
      newEvent,
    } = this.props;
    const { maxOffset1, maxOffset2 } = this.state;

    if (
      newEventValid.streetRDSEstesa &&
      newEventValid.directionRDSEstesaCoded &&
      newEventValid.initialPointRDSEstesa &&
      newEventValid.finalPointRDSEstesa &&
      // newEventValid.distanceFromInitialPointRDSEstesa &&
      // newEventValid.distanceFromFinalPointRDSEstesa &&
      newEventValid.radiusInfluenceRDSEstesa &&
      Number(newEvent.distanceFromInitialPointRDSEstesa) <= maxOffset1 &&
      Number(newEvent.distanceFromFinalPointRDSEstesa) <= maxOffset2
    ) {
      return enableForwardEventButton();
    } else return disableForwardEventButton();
  };

  componentWillUnmount = () => {
    this.subscriptions.forEach((x) => x.unsubscribe());
  };

  render() {
    const {
      modalMapWidth,
      modalMapHalfWidth,
      isSegmentsLoading,
      statePointsListMap,
      segmentsList,
      maxOffset1,
      maxOffset2,
      arcsToDraw,
    } = this.state;
    const { newEvent } = this.props;

    this.disableForwardButton();

    return (
      <div className="uk-flex uk-flex-row">
        <div className="uk-width-1-2">
          <form className="uk-form-horizontal padding-form-left small-form ">
            <div className="uk-margin">
              <label className="uk-form-label">Rete</label>
              <div className="uk-form-controls">
                <select
                  id="rete-rds-estesa"
                  className="uk-select uk-width-1-1"
                  value={newEvent && newEvent.networkRDSEstesa}
                  onChange={(e) => this.handler(e, "networkRDSEstesa")}
                ></select>
              </div>
            </div>
            <div
              className={isSegmentsLoading ? "uk-margin uk-flex" : "uk-margin"}
            >
              <label className="uk-form-label">Strada</label>
              <div>
                {isSegmentsLoading ? <div uk-spinner="ratio: 0.5"></div> : ""}
              </div>
              <div
                className={
                  isSegmentsLoading ? "uk-form-spinner" : "uk-form-controls"
                }
              >
                <select
                  id="strada-rds-estesa"
                  className="uk-select uk-width-1-1"
                  value={newEvent && newEvent.streetRDSEstesa}
                  onChange={(e) => this.handler(e, "streetRDSEstesa")}
                ></select>
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">Direzione</label>
              <div className="uk-form-controls">
                <select
                  id="direzione-rds-estesa"
                  className="uk-select uk-width-1-1"
                  value={newEvent && newEvent.directionRDSEstesaCoded}
                  onChange={(e) => this.handler(e, "directionRDSEstesaCoded")}
                ></select>
              </div>
            </div>
            <div className="container-localization-distance">
              <div className="uk-flex uk-margin">
                <div className="localizationLabel">
                  <label>Punto Primario (LCD 1)</label>
                </div>
                <div className="localizationSelection">
                  <select
                    id="initial-point-rds-estesa"
                    className="uk-select dropdownSelection"
                    value={newEvent && newEvent.initialPointRDSEstesa}
                    onChange={(e) => this.handler(e, "initialPointRDSEstesa")}
                  ></select>
                </div>
              </div>
              <div className="uk-flex uk-margin">
                <div className="localizationLabel">
                  <label className="">Distanza dal Punto Iniziale [m]</label>
                </div>
                <div className="localizationSelection">
                  <div className="uk-flex uk-flex-row uk-flex-right">
                    <input
                      id="distance-initial"
                      type="number"
                      className="uk-input dropdownSelection"
                      onChange={(e) =>
                        this.handler(e, "distanceFromInitialPointRDSEstesa")
                      }
                      min={0}
                      max={maxOffset1}
                      disabled={
                        newEvent &&
                        (!newEvent.initialPointRDSEstesa ||
                          newEvent.initialPointRDSEstesa === "" ||
                          newEvent.initialPointRDSEstesa === "-")
                      }
                    ></input>
                  </div>
                  <div className="uk-flex uk-flex-row uk-flex-right">
                    <div>
                      {newEvent &&
                      newEvent.initialPointRDSEstesa &&
                      newEvent.initialPointRDSEstesa !== "" &&
                      newEvent.initialPointRDSEstesa !== "-" &&
                      maxOffset1
                        ? "Distanza massima: " + maxOffset1 + " m"
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-flex uk-margin">
                <div className="localizationLabel">
                  <label className="">Punto Secondario (LCD 2)</label>
                </div>
                <div className="localizationSelection">
                  <select
                    id="final-point-rds-estesa"
                    className="uk-select dropdownSelection"
                    value={newEvent && newEvent.finalPointRDSEstesa}
                    onChange={(e) => this.handler(e, "finalPointRDSEstesa")}
                    disabled={
                      newEvent &&
                      newEvent.initialPointRDSEstesa &&
                      newEvent.initialPointRDSEstesa !== "" &&
                      newEvent.initialPointRDSEstesa !== "-"
                        ? false
                        : true
                    }
                  ></select>
                </div>
              </div>
              <div className="uk-flex uk-margin">
                <div className="localizationLabel">
                  <label className="">Distanza dal Punto Finale [m]</label>
                </div>
                <div className="localizationSelection">
                  <div className="uk-flex uk-flex-row uk-flex-right">
                    <input
                      id="distance-final"
                      type="number"
                      className="uk-input dropdownSelection"
                      onChange={(e) =>
                        this.handler(e, "distanceFromFinalPointRDSEstesa")
                      }
                      min={0}
                      max={maxOffset2}
                      disabled={
                        newEvent &&
                        (!newEvent.finalPointRDSEstesa ||
                          newEvent.finalPointRDSEstesa === "" ||
                          newEvent.finalPointRDSEstesa === "-")
                      }
                    ></input>
                  </div>
                  <div className="uk-flex uk-flex-row uk-flex-right">
                    <div>
                      {newEvent &&
                      newEvent.finalPointRDSEstesa &&
                      newEvent.finalPointRDSEstesa !== "" &&
                      newEvent.finalPointRDSEstesa !== "-" &&
                      maxOffset2
                        ? "Distanza massima: " + maxOffset2 + " m"
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-flex uk-margin">
                <div className="localizationLabel">
                  <label className="">Raggio d'influenza [m]</label>
                </div>
                <div className="localizationSelection">
                  <input
                    id="radius-influence-rds-tmc"
                    type="number"
                    className="uk-input dropdownSelection"
                    onChange={(e) =>
                      this.handler(e, "radiusInfluenceRDSEstesa")
                    }
                    min={0}
                    disabled={true}
                  ></input>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="uk-flex uk-width-1-2">
          <ErrorBoundary>
            <div className="uk-flex mapBoundary padding-form-right">
              <ModalMap
                modeswitch="rds-tmc-estesa"
                modalMapWidth={modalMapWidth}
                modalMapHalfWidth={modalMapHalfWidth}
                filteredPointsList={statePointsListMap}
                segmentsList={segmentsList}
                arcsRdsTmcToDraw={arcsToDraw}
              ></ModalMap>
            </div>
          </ErrorBoundary>
        </div>
      </div>
    );
  }

  componentWillUnmount = () => {
    this.subscriptions.forEach((sub$) => sub$.unsubscribe());
  };
}

LocalizationRDSEstesa.contextType = EnvironmentContext;

const mapDispatchToProps = {
  enableForwardEventButton,
  disableForwardEventButton,
  setNewEvent,
  updateNewEvent,
};

const mapStateToProps = (state) => ({
  newEvent: getNewEvent(state),
  currentEvent: getCurrentEvent(state),
  newEventValid: getNewEventValid(state),
  typeModal: getTypeModal(state),
  boundingBox: getBoundingBoxCartografica(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocalizationRDSEstesa)
);
