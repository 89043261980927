export function onHoverInstallation(mapComponent, object, x, y) {
  const { _onHoverInstallation } = mapComponent.props;
  _onHoverInstallation(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}

export function onHoverEvent(mapComponent, object, x, y) {
  const { _onHoverEvent } = mapComponent.props;
  _onHoverEvent(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}
export function onHoverStation(mapComponent, object, x, y) {
  const { _onHoverStation } = mapComponent.props;
  _onHoverStation(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}
export function onHoverLine(mapComponent, object, x, y) {
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}

export function onHoverStop(mapComponent, object, x, y) {
  const { _onHoverStop } = mapComponent.props;
  _onHoverStop(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}

export function _onHoverBus(mapComponent, object, x, y) {
  const { hoveredObjectsReducer, setHoveredBus } = mapComponent.props;
  setHoveredBus({
    x: x,
    y: y,
    object: object,
    toBeFollowed: hoveredObjectsReducer.hoveredBus.toBeFollowed,
  });
  mapComponent.onHoverLine(object, x, y);
}

export function onHoverRdsTmcPoint(mapComponent, object, x, y) {
  const { _onHoverRdsTmcPoint } = mapComponent.props;
  _onHoverRdsTmcPoint(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}

export function onHoverArc(mapComponent, object, x, y) {
  //const { _onHoverArc } = mapComponent.props;
  //_onHoverArc(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}
export function onHoverNode(mapComponent, object, x, y) {
  //const { _onHoverNode } = mapComponent.props;
  //_onHoverNode(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}
export function onHoverCentroid(mapComponent, object, x, y) {
  //const { _onHoverCentroid } = mapComponent.props;
  //_onHoverCentroid(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}

export function onHoverTrafficArc(mapComponent, object, x, y) {
  //const { _onHoverArc } = mapComponent.props;
  //_onHoverArc(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}

export function onHoverParking(mapComponent, object, x, y) {
  const { _onHoverParking } = mapComponent.props;
  _onHoverParking(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}

export function onHoverCamera(mapComponent, object, x, y) {
  const { _onHoverCamera } = mapComponent.props;
  _onHoverCamera(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}

export function onHoverPMV(mapComponent, object, x, y) {
  const { _onHoverPMV } = mapComponent.props;
  _onHoverPMV(object, x, y);
  if (object) {
    mapComponent.setState({
      hoverLine: true,
    });
  } else
    mapComponent.setState({
      hoverLine: false,
    });
}
