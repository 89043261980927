import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//ACTIONS
import {
  toggleNavTabInfoRoute,
  addNavTabInfoRoute,
  setNavTabSelectedTab,
  resetNavTabInfoRoute,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { toggleMapControls } from "reducers/map/map.actions";
import {
  toggleTabChangeLayer,
  toggleDistanceSelected,
  toggleRulerSelected,
  toggleCircleSelected,
  togglePolygonSelected,
  toggleTabLegend,
  toggleTabSearchStreet,
  resetTooltipSelected,
} from "reducers/map/map.actions";
import {
  getNavTabInfoRoute,
  getLayoutMap,
  getControls,
  isArcVisible,
} from "store";
import "./style.less";
//HOOKS
import HookSearchStreet from "./hook-search-street";
import HookLegend from "./hook-legend";
import HookLayer from "./hook-layer";
//UTILS
import { infoRouteList } from "utils/mock/info-list";

class MapControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // control open
      openControl: false,
      // input street
      inputSearchStreet: {
        nameStreet: "",
        kmStart: "",
        kmEnd: "",
      },
    };
  }

  // toggle legends and search
  toggleSearchStreet = () => {
    const { toggleTabSearchStreet } = this.props;
    toggleTabSearchStreet();
  };

  toggleLegend = () => {
    const { toggleTabLegend } = this.props;
    toggleTabLegend();
  };

  toggleTabChangeLayer = () => {
    const { toggleTabChangeLayer } = this.props;
    toggleTabChangeLayer();
  };

  toggleDrawCircle = () => {
    const { toggleCircleSelected } = this.props;
    toggleCircleSelected();
  };

  toggleDrawPolygon = () => {
    const { togglePolygonSelected } = this.props;
    togglePolygonSelected();
  };

  toggleDrawRuler = () => {
    const { toggleRulerSelected } = this.props;
    toggleRulerSelected();
  };

  toggleDelete = () => {
    const { resetTooltipSelected } = this.props;
    resetTooltipSelected();
  };

  toggleDrawDistance = () => {
    const { toggleDistanceSelected } = this.props;
    toggleDistanceSelected();
  };

  // input search street
  handleChangeInputName = (event) => {
    this.setState({
      ...this.state,
      inputSearchStreet: {
        ...this.state.inputSearchStreet,
        name: event.target.value,
      },
    });
  };

  handleChangeInputKmStart = (event) => {
    this.setState({
      ...this.state,
      inputSearchStreet: {
        ...this.state.inputSearchStreet,
        kmStart: event.target.value,
      },
    });
  };

  handleChangeInputKmEnd = (event) => {
    this.setState({
      ...this.state,
      inputSearchStreet: {
        ...this.state.inputSearchStreet,
        kmEnd: event.target.value,
      },
    });
  };

  handleSearchStreet = () => {
    const {
      toggleNavTabInfoRoute,
      addNavTabInfoRoute,
      setNavTabSelectedTab,
    } = this.props;
    setNavTabSelectedTab(3);
    addNavTabInfoRoute(infoRouteList);
    toggleNavTabInfoRoute();
  };

  closeSearchStreet = () => {
    const { resetNavTabInfoRoute, setNavTabSelectedTab } = this.props;
    resetNavTabInfoRoute();
    setNavTabSelectedTab(0);
  };

  handleResetSearchStreet = () => {
    this.setState({
      ...this.state,
      inputSearchStreet: {
        name: "",
        kmStart: "",
        kmEnd: "",
      },
    });
  };

  renderOpenCloseButton() {
    const { openControl } = this.state;

    return (
      <div className="container-button">
        <div
          className={`uk-width-1-1 uk-height-1-1 uk-card cursor-pointer  ${
            openControl ? "uk-card-secondary" : "uk-card-primary"
          } uk-flex uk-flex-center uk-flex-middle`}
          onClick={this.toggleBranches}
        >
          <span className="" uk-icon="icon: acr-map-options"></span>
        </div>
      </div>
    );
  }

  toggleBranches = () => {
    const { toggleMapControls } = this.props;
    toggleMapControls();
    this.setState({
      ...this.state,
      openControl: !this.state.openControl,
    });
  };

  renderOpenBranches() {
    const {
      toDefaultPosition,
      toNord,
      zoomIn,
      zoomOut,
      drawPolygon,
      drawCircle,
      drawRuler,
      drawDistance,
      deleteLayer,
      goForward,
      goBackward,
      // setPan,
      // menuTabReducer,
      controlsReducer,
      isArcVisible,
    } = this.props;

    return (
      <div>
        <div
          className={
            isArcVisible
              ? "container-tools-absolute-higher"
              : "container-tools-absolute"
          }
        >
          <div className="uk-card uk-card-primary uk-flex uk-flex-column uk-flex-middle">
            {/* <div className="margin-distance-bottom cursor-pointer uk-margin-top">
              <span
                uk-tooltip="title: Informazioni"
                className={
                  menuTabReducer.open
                    ? "uk-icon-link uk-icon icon-selected"
                    : "uk-icon-link uk-icon"
                }
                uk-icon="icon: acr-map-options-information"
                onClick={() => {
                  menuTabReducer.open
                    ? this.closeSearchStreet()
                    : this.handleSearchStreet()
                  }}
              ></span>
            </div> */}
            {/* <div className="margin-distance-bottom cursor-pointer">
              <span
                uk-tooltip="title: Cerca strada"
                id="search-street"
                className={
                  controlsReducer.openTabSearchStreet
                    ? "uk-icon-link uk-icon icon-selected"
                    : "uk-icon-link uk-icon"
                }
                uk-icon="icon: acr-map-options-find-road"
                onClick={this.toggleSearchStreet}
              ></span>
            </div> */}
            <div className="margin-distance-bottom cursor-pointer uk-margin-top">
              <span
                uk-tooltip="title: Disegna cerchio"
                className={
                  controlsReducer.circleSelected
                    ? "uk-icon-link uk-icon icon-selected"
                    : "uk-icon-link uk-icon"
                }
                uk-icon="icon: acr-map-options-oval"
                onClick={() => {
                  drawCircle();
                  this.toggleDrawCircle();
                }}
              ></span>
            </div>
            <div className="margin-distance-bottom cursor-pointer">
              <span
                uk-tooltip="title: Disegna poligono"
                className={
                  controlsReducer.polygonSelected
                    ? "uk-icon-link uk-icon icon-selected"
                    : "uk-icon-link uk-icon"
                }
                uk-icon="icon: acr-map-options-polygon"
                onClick={() => {
                  drawPolygon();
                  this.toggleDrawPolygon();
                }}
              ></span>
            </div>
            {isArcVisible ? (
              <div className="margin-distance-bottom cursor-pointer">
                <span
                  uk-tooltip="title: Misura distanza su percorso"
                  className={
                    controlsReducer.rulerSelected
                      ? "uk-icon-link uk-icon icon-selected"
                      : "uk-icon-link uk-icon"
                  }
                  uk-icon="icon: acr-map-options-ruler"
                  onClick={() => {
                    drawRuler();
                    this.toggleDrawRuler();
                  }}
                ></span>
              </div>
            ) : null}
            <div className="margin-distance-bottom cursor-pointer">
              <span
                uk-tooltip="title: Misura distanza"
                className={
                  controlsReducer.distanceSelected
                    ? "uk-icon-link uk-icon icon-selected"
                    : "uk-icon-link uk-icon"
                }
                uk-icon="icon: acr-map-options-aerial"
                onClick={() => {
                  drawDistance();
                  this.toggleDrawDistance();
                }}
              ></span>
            </div>
            <div className="margin-distance-bottom cursor-pointer">
              <span
                uk-tooltip="title: Pulisci mappa"
                className="uk-icon-link uk-icon"
                uk-icon="icon: acr-map-options-delete"
                onClick={() => {
                  deleteLayer();
                  this.toggleDelete();
                }}
              ></span>
            </div>
          </div>
        </div>

        <div className="container-navigation-absolute">
          <div className="uk-card uk-card-primary uk-flex uk-flex-row uk-flex-middle">
            <div className="margin-distance-left cursor-pointer uk-margin-left ">
              <span
                uk-tooltip="title: Zoom avanti"
                className="uk-icon-link uk-icon"
                uk-icon="icon: acr-map-options-zoom-in"
                onClick={zoomIn}
              ></span>
            </div>
            <div className="margin-distance-left cursor-pointer ">
              <span
                uk-tooltip="title: Zoom indietro"
                className="uk-icon-link uk-icon"
                uk-icon="icon: acr-map-options-zoom-out"
                onClick={zoomOut}
              ></span>
            </div>
            <div className="margin-distance-left cursor-pointer">
              <span
                uk-tooltip="title: Indietro"
                className="uk-icon-link uk-icon"
                uk-icon="icon: acr-map-options-back"
                onClick={goBackward}
              ></span>
            </div>
            <div className="margin-distance-left cursor-pointer">
              <span
                uk-tooltip="title: Avanti"
                className="uk-icon-link uk-icon"
                uk-icon="icon: acr-map-options-next"
                onClick={goForward}
              ></span>
            </div>
            <div className="margin-distance-left cursor-pointer">
              <span
                uk-tooltip="title: Torna a posizione default"
                className="uk-icon-link uk-icon"
                uk-icon="icon: acr-map-options-world"
                onClick={toDefaultPosition}
              ></span>
            </div>
            <div className="margin-distance-left cursor-pointer">
              <span
                uk-tooltip="title: Cambia mappa"
                className={
                  controlsReducer.openTabChangeLayer
                    ? "uk-icon-link uk-icon icon-selected"
                    : "uk-icon-link uk-icon"
                }
                uk-icon="icon: acr-map-options-map-type"
                onClick={this.toggleTabChangeLayer}
              ></span>
            </div>
            {/* <div className="margin-distance-left cursor-pointer">
              <span
                id="set-pan"
                className="uk-icon-link uk-icon"
                uk-icon="icon: acr-map-options-hand"
                onClick={() => {
                  setPan();
                  this.changeIconColor("set-pan");
                }}
              ></span>
            </div> */}
            <div className="margin-distance-left cursor-pointer">
              <span
                uk-tooltip="title: Nord"
                className="uk-icon-link uk-icon"
                uk-icon="icon: acr-map-options-localizzazione"
                onClick={toNord}
              ></span>
            </div>
            <div className="margin-distance-left cursor-pointer">
              <span
                uk-tooltip="title: Legenda"
                className={
                  controlsReducer.openTabLegend
                    ? "uk-icon-link uk-icon icon-selected"
                    : "uk-icon-link uk-icon"
                }
                uk-icon="icon: acr-map-options-legenda"
                onClick={this.toggleLegend}
              ></span>
            </div>
          </div>
        </div>
        {this.renderOpenCloseButton()}
      </div>
    );
  }

  render() {
    const {
      handleSwitchLayer,
      layoutMapReducer,
      controlsReducer,
      viewport,
    } = this.props;
    const { openControl, inputSearchStreet } = this.state;

    return (
      <div>
        <div>
          {openControl
            ? this.renderOpenBranches()
            : this.renderOpenCloseButton()}
        </div>
        {/* TOOLS */}
        {controlsReducer.openTabChangeLayer && (
          <HookLayer
            isControlOpen={openControl}
            toggleTabChangeLayer={this.toggleTabChangeLayer}
            handleSwitchLayer={handleSwitchLayer}
            layer={layoutMapReducer}
          />
        )}
        {controlsReducer.openTabLegend && (
          <HookLegend
            isControlOpen={openControl}
            toggleLegend={this.toggleLegend}
            layer={layoutMapReducer}
            viewport={viewport}
          />
        )}
        {controlsReducer.openTabSearchStreet && (
          <HookSearchStreet
            isControlOpen={openControl}
            handleSearchStreet={this.handleSearchStreet}
            handleResetSearchStreet={this.handleResetSearchStreet}
            toggleSearchStreet={this.toggleSearchStreet}
            inputSearchStreet={inputSearchStreet}
            handleChangeInputName={this.handleChangeInputName}
            handleChangeInputKmStart={this.handleChangeInputKmStart}
            handleChangeInputKmEnd={this.handleChangeInputKmEnd}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleNavTabInfoRoute,
  addNavTabInfoRoute,
  setNavTabSelectedTab,
  resetNavTabInfoRoute,
  toggleMapControls,
  toggleTabChangeLayer,
  toggleDistanceSelected,
  toggleRulerSelected,
  toggleCircleSelected,
  togglePolygonSelected,
  toggleTabLegend,
  toggleTabSearchStreet,
  resetTooltipSelected,
};

const mapStateToProps = (state) => ({
  menuTabReducer: getNavTabInfoRoute(state),
  layoutMapReducer: getLayoutMap(state),
  controlsReducer: getControls(state),
  isArcVisible: isArcVisible(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MapControls)
);
