import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./style.less";
import createApiService from "services/api.service";
import * as moment from "moment";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import EnvironmentContext from "environment-context";

//ACTIONS
import {
  getCurrentEvent,
  getSelectModal,
  getNewEvent,
  getAllEvents,
} from "store";
//UTILS
import {
  EVENT_STATE,
  PROBABILITY_OF_OCCURRENCE,
  SOURCE_TYPE,
  GRAVITY_TYPE,
} from "utils/utils";
import { getIconEvent } from "utils/utils_event_icon";
import { eventLocalizationToShowUtil } from "utils/utils";
import { getSubtypeTranslation } from "utils/util-events";

class EventPractice extends Component {
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {
      practices: [],
      clickEvent: true,
      clickEventChiusuraStrada: false,
    };
  }

  componentDidMount() {
    const { listEvent, currentEvent } = this.props;

    this.apiService = createApiService(this.context);
    this.subscriptions.push(
      this.apiService
        .getEventsAssociatedToSituationId(currentEvent.situationId)
        .pipe(
          tap((data) => {
            let evDetails = [];
            let findEventDetails;
            data.situationRecordsIds.forEach((assEvent) => {
              findEventDetails = listEvent.find(
                (event) => assEvent === event.situationRecordId
              );
              if (findEventDetails) {
                evDetails.push(findEventDetails);
              }
            });

            this.setState({
              practices: evDetails,
            });
          }),
          catchError((error) => {
            console.error(error);

            return of(error);
          })
        )
        .subscribe()
    );
  }

  componentDidUpdate = (prevProps) => {
    const { listEvent, currentEvent, newEvent } = this.props;
    let update = listEvent !== prevProps.listEvent;
    let currentEventIsRemovedFromPractice = newEvent.associatedEvents.find(
      (item) => item.situationRecordId === currentEvent.situationRecordId
    );
    if (
      currentEventIsRemovedFromPractice &&
      newEvent.associatedEvents.length > 0 &&
      update
    ) {
      this.subscriptions.push(
        this.apiService
          .getEventsAssociatedToSituationId(currentEvent.situationId)
          .pipe(
            tap((data) => {
              let evDetails = [];
              let findEventDetails;
              data.situationRecordsIds.forEach((assEvent) => {
                findEventDetails = listEvent.find(
                  (event) => assEvent === event.situationRecordId
                );
                if (findEventDetails) {
                  evDetails.push(findEventDetails);
                }
              });

              this.setState({
                practices: evDetails,
              });
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    } else if (newEvent.associatedEvents.length > 0 && update) {
      this.subscriptions.push(
        this.apiService
          .getEventsAssociatedToSituationId(currentEvent.situationId)
          .pipe(
            tap((data) => {
              let evDetails = [];
              let findEventDetails;
              data.situationRecordsIds.forEach((assEvent) => {
                findEventDetails = listEvent.find(
                  (event) => assEvent === event.situationRecordId
                );
                if (findEventDetails) {
                  evDetails.push(findEventDetails);
                }
              });
              this.setState({
                practices: evDetails,
              });
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    } else if (
      currentEvent.situationRecordId !==
      prevProps.currentEvent.situationRecordId
    ) {
      this.subscriptions.push(
        this.apiService
          .getEventsAssociatedToSituationId(currentEvent.situationId)
          .pipe(
            tap((data) => {
              let evDetails = [];
              let findEventDetails;
              data.situationRecordsIds.forEach((assEvent) => {
                findEventDetails = listEvent.find(
                  (event) => assEvent === event.situationRecordId
                );
                if (findEventDetails) {
                  evDetails.push(findEventDetails);
                }
              });

              this.setState({
                practices: evDetails,
              });
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    } else if (update) {
      this.subscriptions.push(
        this.apiService
          .getEventsAssociatedToSituationId(currentEvent.situationId)
          .pipe(
            tap((data) => {
              let evDetails = [];
              let findEventDetails;
              data.situationRecordsIds.forEach((assEvent) => {
                findEventDetails = listEvent.find(
                  (event) => assEvent === event.situationRecordId
                );
                if (findEventDetails) {
                  evDetails.push(findEventDetails);
                }
              });

              this.setState({
                practices: evDetails,
              });
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };
  header = () => {
    const { toggleDetails, currentEvent, selectModal } = this.props;

    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div
          className="uk-position-relative height-width-48"
          /* className={
          (currentEvent && currentEvent.situationId
            ? "iconEventAndPractice"
            : "color-grey") + " uk-position-relative height-width-48"
        }
        uk-icon={"icon: " + getIconEventsBg(currentEvent)} */
        >
          <img
            alt={currentEvent.situationRecordType}
            src={getIconEvent(currentEvent, selectModal)}
          />
        </div>
        <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-muted-event">
          {getSubtypeTranslation(currentEvent, selectModal)}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            toggleDetails(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClickEventPractice } = this.props;
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickEventPractice();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Pratica Evento</a>
          </li>
        </ul>
      </div>
    );
  };

  /* checkEventChiusuraButton = () => {
    const { clickEventChiusuraStrada  } = this.state;
    this.setState({ clickEventChiusuraStrada: !clickEventChiusuraStrada });
    this.setState({ clickEventCoda: false });
  }; */

  bodyEvent = (event) => {
    const { selectModal } = this.props;

    let arrLocations =
      event && event.groupOfLocations && event.groupOfLocations.length > 0
        ? eventLocalizationToShowUtil(event.groupOfLocations)
        : [];

    return (
      <div>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-practice">Tipo Evento</div>
          <div className="text-detail-emphasis-practice">
            {event &&
            event.situationRecordType &&
            selectModal &&
            selectModal.types &&
            selectModal.types.find(
              (x) => x.typeName === event.situationRecordType
            ).typeNameIT
              ? selectModal.types.find(
                  (x) => x.typeName === event.situationRecordType
                ).typeNameIT
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">Sottotipo Evento</div>
          <div className="text-detail-emphasis-practice">
            {getSubtypeTranslation(event, selectModal)}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">ID Evento</div>
          <div className="text-detail-emphasis-practice">
            {event && event.situationRecordId ? event.situationRecordId : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">ID Pratica</div>
          <div className="text-detail-emphasis-practice">
            {event && event.situationId ? event.situationId : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">Localizzazione</div>
          <div
            uk-tooltip={"title: " + arrLocations.toString()}
            className="text-detail-emphasis-practice p-dot"
          >
            {arrLocations.toString()}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">Inizio</div>
          <div className="text-detail-emphasis-practice">
            {event &&
            event.validity &&
            event.validity.validityTimeSpecification &&
            event.validity.validityTimeSpecification.overallStartTime
              ? event.validity.validityTimeSpecification.overallStartTime.includes(
                  "T"
                )
                ? moment(
                    event.validity.validityTimeSpecification.overallStartTime
                  ).format("DD/MM/YY - HH:mm")
                : event.validity.validityTimeSpecification.overallStartTime
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">Fine</div>
          <div className="text-detail-emphasis-practice">
            {event &&
            event.validity &&
            event.validity.validityTimeSpecification &&
            event.validity.validityTimeSpecification.overallEndTime
              ? event.validity.validityTimeSpecification.overallEndTime.includes(
                  "T"
                )
                ? moment(
                    event.validity.validityTimeSpecification.overallEndTime
                  ).format("DD/MM/YY - HH:mm")
                : event.validity.validityTimeSpecification.overallEndTime
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">Livello di gravità</div>
          <div className="text-detail-emphasis-practice">
            {event && event.severity ? GRAVITY_TYPE[event.severity] : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">Fonte</div>
          <div className="text-detail-emphasis-practice">
            {event && event.source && event.source.sourceReference
              ? SOURCE_TYPE[event.source.sourceReference]
              : "-"}{" "}
            {event &&
            event.source &&
            event.source.sourceName &&
            event.source.sourceName.content
              ? " (" +
                event.source.sourceName.content +
                ") " +
                event.source.sourceIdentification
              : ""}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-event-detail">
            Probabilità accadimento
          </div>
          <div className="text-detail-emphasis-event-detail">
            {event && event.probabilityOfOccurrence
              ? PROBABILITY_OF_OCCURRENCE[event.probabilityOfOccurrence]
              : "–"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">Stato</div>
          <div
            className={
              event && event.validity && event.validity.validityStatus
                ? EVENT_STATE[event.validity.validityStatus.toLowerCase()] ===
                  "Attivo"
                  ? "text-detail-emphasis-event-detail green"
                  : EVENT_STATE[event.validity.validityStatus.toLowerCase()] ===
                    "Pianificato"
                  ? "text-detail-emphasis-event-detail blue"
                  : "text-detail-emphasis-event-detail orange"
                : "–"
            }
          >
            {event && event.validity && event.validity.validityStatus
              ? EVENT_STATE[event.validity.validityStatus.toLowerCase()]
              : "–"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className=" text-detail-muted-practice">Note</div>
          <div className="text-detail-emphasis-practice">
            {event &&
            event.comments &&
            event.comments.length > 0 &&
            event.comments[0].comment
              ? event.comments[0].comment
              : "-"}
          </div>
        </li>
      </div>
    );
  };

  checkEventButton = (ev) => {
    const { practices } = this.state;
    let newPractices = practices.map((p) => {
      if (ev.situationRecordId === p.situationRecordId) {
        p.expanded = !p.expanded;
      }
      return p;
    });
    this.setState({ practices: newPractices });
  };

  body = () => {
    //const { currentEvent, clickEventCoda, checkEventCodaButton } = this.props;
    const { practices } = this.state;
    const { selectModal } = this.props;

    return (
      <div className="uk-flex uk-flex-column contentBodyEventDetailPractice">
        {practices.map((event, index) => {
          return (
            <div key={index} className="uk-inline">
              <div className="uk-checkbox-title">
                <button
                  id={"event-practice-" + event.situationId}
                  className={
                    event.expanded
                      ? "uk-button uk-button-default eventDropButton uk-padding-remove uk-flex uk-flex-row uk-flex-middle"
                      : "uk-button uk-button-default eventDropButton changeEventColor uk-padding-remove  uk-flex uk-flex-row uk-flex-middle"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.checkEventButton(event);
                  }}
                >
                  <div className="dropdownIcon">
                    <img
                      src={getIconEvent(event, selectModal)}
                      alt={event.situationRecordType}
                    />
                  </div>
                  <div className="practice-title">
                    {getSubtypeTranslation(event, selectModal)}
                  </div>
                  <span
                    uk-icon={
                      event.expanded ? "icon:triangle-up" : "icon:triangle-down"
                    }
                  ></span>
                </button>
                {event.expanded ? this.bodyEvent(event) : ""}
                <hr className="divider"></hr>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

EventPractice.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  currentEvent: getCurrentEvent(state),
  selectModal: getSelectModal(state),
  newEvent: getNewEvent(state),
  listEvent: getAllEvents(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventPractice)
);
