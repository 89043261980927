import { CompositeLayer, PathLayer } from "deck.gl";
import { getArcColorRGBByLos, getArcWidth } from "utils/utils-traffic";
import { PathStyleExtension } from "@deck.gl/extensions";

class TrafficArcLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      visible,
      onClick,
      trafficArcCurrentReducer,
      trafficArcMapCurrentReducer,
      isDrawingMode,
      isNetStateVisible,
      isTypicalVisible,
    } = this.props;
    return new PathLayer(
      this.getSubLayerProps({
        id,
        data,
        getPath: (d) => d.geometry.coordinates,
        getColor: (data) =>
          getArcColorRGBByLos(
            isNetStateVisible &&
              data.properties.measures.find((item) => item.measureId === "los")
              ? data.properties.measures.find(
                  (item) => item.measureId === "los"
                ).measureValue
              : isTypicalVisible &&
                data.properties.profile.find((item) => item.measureId === "los")
              ? data.properties.profile.find((item) => item.measureId === "los")
                  .measureValue
              : null
          ),
        pickable: isDrawingMode ? false : true,
        widthMinPixels: 2,
        widthMaxPixels: 20,
        getWidth: (d) =>
          getArcWidth(d, trafficArcCurrentReducer, trafficArcMapCurrentReducer),
        onClick: onClick,
        visible,
        rounded: true,
        parameters: {
          depthTest: false,
        },
        extensions: [new PathStyleExtension({ offset: true })],
        getOffset: (data) => 1,
      })
    );
  }
}

TrafficArcLayer.layerName = "TrafficArcLayer";

export default TrafficArcLayer;
