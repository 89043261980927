import React, { Component, createRef } from "react";
import "./style.less";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//COMPONENTS
import Loading from "components/shared/loading/loading";
import InfiniteScroll from "react-infinite-scroll-component";
import ArcListItem from "./arc-list-item/arc-list-item";
import ArcTrafficListItem from "../arc-traffic/arc-traffic-list-item/arc-traffic-list-item";

const SCROLL_OFFSET = 25;

class ArcList extends Component {
  constructor(props) {
    super(props);
    this.scrollDiv = createRef();
    this.state = {
      openDetail: false,
      currentOffset: SCROLL_OFFSET,
      nextArcs: [],
      currentListLen: 0,
    };
  }

  componentDidMount = () => {
    const { currentArcMap } = this.props;
    if (currentArcMap.length >= 1) {
      currentArcMap.forEach((item) => {
        if (item.properties.selected) {
          this.scrollSmoothHandler();
        }
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { currentArcMap } = this.props;
    if (currentArcMap.length >= 2) {
      this.scrollSmoothHandler();
    }
  };

  fetchMoreData = () => {
    const { currentOffset } = this.state;
    const { arcsList } = this.props;
    let newOffset = currentOffset + SCROLL_OFFSET;
    let fetchmore = [...arcsList].slice(0, newOffset);
    this.setState({
      nextArcs: fetchmore,
      currentOffset: newOffset,
    });
  };

  scrollSmoothHandler = () => {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  constructFetchedDOM = () => {
    const { nextArcs } = this.state;
    const { toggleArcDetails, currentArcMap, arcListType } = this.props;
    let selectedArc = 0;

    if (arcListType === "graph") {
      return (
        <div className="arc-list-container inherit-transition">
          <div className="uk-list">
            {nextArcs &&
              nextArcs.map((arc, index) => {
                if (currentArcMap.length > 0) {
                  currentArcMap.forEach((item) => {
                    if (
                      item.properties.selected &&
                      item.properties.arcid === arc.properties.arcid
                    ) {
                      selectedArc = arc.properties.arcid;
                    }
                  });
                }
                return (
                  <ArcListItem
                    arc={arc}
                    key={index}
                    index={index}
                    toggleArcDetails={toggleArcDetails}
                    currentArcMap={currentArcMap}
                    selectedArc={selectedArc}
                    scrollDiv={this.scrollDiv}
                  />
                );
              })}
          </div>
        </div>
      );
    } else if (arcListType === "traffic") {
      return (
        <div className="arc-list-container inherit-transition">
          <div className="uk-list">
            {nextArcs &&
              nextArcs.map((arc, index) => {
                if (currentArcMap.length > 0) {
                  currentArcMap.forEach((item) => {
                    if (
                      item.properties.selected &&
                      item.properties.archId === arc.properties.archId
                    ) {
                      selectedArc = arc.properties.archId;
                    }
                  });
                }

                return (
                  <ArcTrafficListItem
                    key={index}
                    arc={arc}
                    index={index}
                    toggleArcDetails={toggleArcDetails}
                    currentArcMap={currentArcMap}
                    selectedArc={selectedArc}
                    scrollDiv={this.scrollDiv}
                  />
                );
              })}
          </div>
        </div>
      );
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { nextArcs, currentOffset, currentListLen } = state;
    const { arcsList } = props;
    let newState = {};

    if (currentListLen !== arcsList.length && arcsList.length === 0) {
      newState = {
        currentListLen: arcsList.length,
        currentOffset: SCROLL_OFFSET,
        nextArcs: [],
      };
    }
    if (
      (currentListLen !== arcsList.length ||
        JSON.stringify(arcsList) !== JSON.stringify(nextArcs)) &&
      arcsList.length > 0
    ) {
      newState = {
        currentListLen: arcsList.length,
        currentOffset: arcsList.length - 1,
        nextArcs: arcsList.slice(0, arcsList.length),
      };
    }
    if (nextArcs.length === 0 && currentListLen > 0) {
      newState = {
        ...state,
        nextArcs: arcsList.slice(
          0,
          currentListLen > SCROLL_OFFSET ? currentOffset : currentListLen
        ),
      };
    }

    return newState;
  }

  renderInfiniteScroll = () => {
    const { nextArcs } = this.state;
    const { arcsList } = this.props;

    return (
      <InfiniteScroll
        dataLength={nextArcs.length || 0} //This is important field to render the next data
        next={this.fetchMoreData}
        hasMore={nextArcs.length === arcsList.length ? false : true}
        loader={
          <div>
            {arcsList.length ? "" /* <Loading /> */ : "Nessun elemento..."}
          </div>
        }
        scrollableTarget={"tabpanel-0"}
        className="inner-infinite-scroll"
      >
        {this.constructFetchedDOM()}
      </InfiniteScroll>
    );
  };

  render() {
    const { isArcsLoading, currentArcMap } = this.props;

    if (currentArcMap) {
      currentArcMap.forEach((item) => {
        if (item.properties.selected === "true") {
          this.scrollSmoothHandler();
        }
      });
    }
    return (
      <div>{isArcsLoading ? <Loading /> : this.renderInfiniteScroll()}</div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArcList)
);
