import { INITIAL_STATE_ROUTES } from "./routes.model";
import { RoutesActions } from "./routes.actions";
import { NavTabActions } from "../ui/nav-tab/nav-tab.actions";
//import { MapActions } from "../map/map.actions";
//import { filterBounds } from "utils/utils";

function routeFilter(searchText) {
  return (route) =>
    route.id.toLowerCase().includes(searchText) ||
    route.nameLong.toLowerCase().includes(searchText) ||
    route.nameShort
      .toLowerCase()
      .includes(
        searchText
      ) /* ||
    route.line.nameShort.toLowerCase().includes(searchText) */;
}

export default function routesReducer(state = INITIAL_STATE_ROUTES, action) {
  switch (action.type) {
    case RoutesActions.GET_ROUTES:
      let list = action.payload.sort((a, b) =>
        a.nameShort > b.nameShort ? 1 : b.nameShort > a.nameShort ? -1 : 0
      );
      action.payload = action.payload.filter(
        (x) => !state.routes.find((l) => x.id === l.id && l.lineId === x.lineId)
      );
      const cityBus = action.payload.filter(
        (x) =>
          x.transportMode &&
          x.transportMode.subMode &&
          x.transportMode.subMode.code === "07.70"
      );
      const regionBus = action.payload.filter(
        (x) =>
          x.transportMode &&
          x.transportMode.subMode &&
          x.transportMode.subMode.code === "07.01"
      );
      return {
        ...state,
        routes: [...state.routes, ...list],
        cityBus: [...state.cityBus, ...cityBus],
        regionBus: [...state.regionBus, ...regionBus],
        cityBusFiltered: [...state.cityBusFiltered, ...cityBus],
        regionBusFiltered: [...state.regionBusFiltered, ...regionBus],
      };
    case RoutesActions.RESET_ROUTES:
      return INITIAL_STATE_ROUTES;
    case NavTabActions.SET_SEARCH:
      if (
        action.payload &&
        (action.payload.type === "percorsi" || action.payload.type === "linee")
      ) {
        let s =
          action.payload && action.payload.journeyPatternRef
            ? action.payload.journeyPatternRef.toLowerCase()
            : action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const searchFn = routeFilter(s);
        return {
          ...state,
          searchText: s,
          cityBusFiltered:
            s !== "" ? state.cityBus.filter(searchFn) : [...state.cityBus],
          regionBusFiltered:
            s !== "" ? state.regionBus.filter(searchFn) : [...state.regionBus],
          searchType: action.payload.type,
        };
      } else {
        return { ...state };
      }
    /*case MapActions.FILTER_BY_BOUNDS: {
      const { searchText } = state;
      const searchFn = routeFilter(searchText);
      const { bounds } = action.payload;
      //const filterFn = filterBounds(bounds);

      return {
        ...state,
        bounds: [...bounds],
        regionBusFiltered: state.regionBus.filter(searchFn),
        //comemntati perchè non possiamo ricavarci le fermate date le rotte
        //.filter((x) => x.points && x.points.some(filterFn)),
        cityBusFiltered: state.cityBus.filter(searchFn),
        //.filter((x) => x.points && x.points.some(filterFn)),
      };
    }*/
    case RoutesActions.SET_CURRENT_ID_STOP_ROUTE:
      return {
        ...state,
        currentIdStopForRoute: action.payload,
      };
    case RoutesActions.SET_CURRENT_ID_TRIP_ROUTE:
      return {
        ...state,
        currentIdTripForRoute: action.payload,
      };
    default:
      return state;
  }
}

export const getRoutes = (state) => state.routes;

export const getCityBusRoutes = (state) => state.cityBus;
export const getRegionBusRoutes = (state) => state.regionBus;

export const getCityBusRoutesFiltered = (state) => state.cityBusFiltered;
export const getRegionBusRoutesFiltered = (state) => state.regionBusFiltered;

export const getSearchTextRoutes = (state) => state.searchText;
export const getCurrentIdStopForRoute = (state) => state.currentIdStopForRoute;
export const getCurrentIdTripForRoute = (state) => state.currentIdTripForRoute;
