import React, { Component } from "react";

class PracticeManagementBody extends Component {
  render() {
    const {
      listOfEvents,
      associateEventsDetails,
      handleEventCauseChange,
      handleCheckboxChange,
    } = this.props;
    const indexCauseEvent = listOfEvents.findIndex(
      (event) => event.causeEvent === event.eventId
    );
    return (
      <tbody>
        {listOfEvents.map((event, index) => {
          return (
            <tr key={event.eventId} id={event.eventId}>
              <td>{event.eventId}</td>
              <td>
                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                  <span>
                    <input
                      className="uk-radio uk-radio-large"
                      type="radio"
                      name="check"
                      value={event.eventId}
                      id={event.eventId}
                      defaultChecked={
                        indexCauseEvent !== -1
                          ? index === indexCauseEvent
                          : index === 0
                      }
                      disabled={associateEventsDetails.length <= 1}
                      onChange={(e) => {
                        handleEventCauseChange(e, index);
                      }}
                    />
                  </span>
                </div>
              </td>
              <td>{event && event.eventType}</td>
              <td>{event && event.eventUndertype}</td>
              <td>{event && event.eventOpenTime}</td>
              <td>{event && event.eventClosingTime}</td>
              <td>
                <div
                  className={
                    event.eventSeverity === 1
                      ? "image-gravity-dark-green"
                      : event.eventSeverity === 2
                      ? "image-gravity-green"
                      : event.eventSeverity === 3
                      ? "image-gravity-yellow"
                      : event.eventSeverity === 4
                      ? "image-gravity-orange"
                      : event.eventSeverity === 5
                      ? "image-gravity-red"
                      : event.eventSeverity === 0
                      ? "image-gravity-grey"
                      : ""
                  }
                ></div>
              </td>
              <td>
                <div
                  className={
                    event && event.eventValidity
                      ? event.eventValidity === "active"
                        ? "text-detail-emphasis-event-detail green"
                        : event.eventValidity === "planned"
                        ? "text-detail-emphasis-event-detail blue"
                        : "text-detail-emphasis-event-detail orange"
                      : ""
                  }
                >
                  {event && event.eventValidity}
                </div>
              </td>
              <td>
                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid checkBox">
                  <span>
                    <input
                      className="uk-checkbox uk-checkbox-large "
                      type="checkbox"
                      value={event.eventId || ""}
                      id={event.eventId}
                      onChange={(e) => {
                        handleCheckboxChange(e, event);
                      }}
                      checked={
                        event.isChecked === true ||
                        event.isChecked === undefined
                          ? true
                          : false
                      }
                      disabled={
                        associateEventsDetails &&
                        associateEventsDetails !== null &&
                        associateEventsDetails !== undefined &&
                        associateEventsDetails.length === 1
                      }
                    />
                  </span>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default PracticeManagementBody;
