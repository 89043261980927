export function createStringifiedPreferences(
  name,
  layoutMap,
  currentZoom,
  bbox,
  selectedKpi,
  isNetworkRdsTmcVisible,
  isArcVisible,
  isNodeVisible,
  isCentroidVisible,
  areAllGraphVisible,
  allTrasportoChecked,
  cityBusChecked,
  regionBusChecked,
  allEventChecked,
  activeChecked,
  plannedChecked,
  allTrafficChecked,
  tipicChecked,
  netStateChecked,
  isParkingVisible,
  isStationVisible,
  allCamerasVisible,
  isMobilitaVisible,
  isPmvVisible,
  isSolariPanelVisible,
  isAesysPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
  pinnedCameras,
  activeLines
) {
  let stringified = "";

  let body = {
    workspaceName: name,
    layoutMap: layoutMap,
    zoom: currentZoom,
    mapCenter: [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2],
    selectedKpi:
      selectedKpi.length > 0 ? [...selectedKpi.map((item) => item.id)] : [],
    menu: {
      graph: {
        networkRdsTmc: isNetworkRdsTmcVisible,
        graph: areAllGraphVisible,
        arc: isArcVisible,
        node: isNodeVisible,
        centroid: isCentroidVisible,
      },
      tpl: {
        transportation: allTrasportoChecked,
        city: cityBusChecked,
        regional: regionBusChecked,
        events: allEventChecked,
        activeEvents: activeChecked,
        plannedEvents: plannedChecked,
        traffic: allTrafficChecked,
        typicalTraffic: tipicChecked,
        stateTraffic: netStateChecked,
      },
      installations: {
        station: isStationVisible,
        cameras: allCamerasVisible,
        mobilitaCameras: isMobilitaVisible,
        pmv: isPmvVisible,
        solariPmv: isSolariPanelVisible,
        aesysPmv: isAesysPanelVisible,
        futuritPmv: isFuturitPanelVisible,
        spheraPmv: isSfheraPanelVisible,
        visualLabPmv: isVisuallabPanelVisible,
        parking: isParkingVisible,
      },
    },
    bottombar: {
      cctv:
        pinnedCameras.length > 0
          ? [
              ...pinnedCameras.map(
                (item) => item.properties.cctvWithLastStateAndOpenAlarms.cctv.id
              ),
            ]
          : [],
      tpl:
        activeLines.length > 0
          ? [
              ...activeLines.map(
                (item) =>
                  item.id +
                  "-" +
                  item.lineId +
                  "-" +
                  item.nameShort +
                  "-" +
                  item.transportMode.subMode.code
              ),
            ]
          : [],
    },
  };

  stringified = JSON.stringify(body);

  return stringified;
}
