import React, { Component } from "react";
import "./style.less";

class BottombarTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      changeTab,
      activeTab,
      tabs,
      nrOfCameras,
      maxNrOfCameras,
    } = this.props;

    return (
      <div className=" uk-width-1-2 uk-flex uk-row">
        <ul className="uk-tab-page-primary uk-tab uk-margin-remove">
          {tabs.map((item, index) => {
            let text = item;
            if (item === "Telecamere" && maxNrOfCameras) {
              text = text + " (" + nrOfCameras + "/" + maxNrOfCameras + ")";
            }
            return (
              <li
                className={
                  item === activeTab
                    ? "uk-active bottombar-title"
                    : "bottombar-title"
                }
                onClick={() => changeTab(item)}
                key={index}
              >
                {text}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default BottombarTabs;
