import { FilterTableActions } from "reducers/filter-table-selected/filter-table-selected.actions";
import { TableFilterModel } from "reducers/filter-table-selected/filter-table-selected.models";

const ITableFilter_init = {
  tableColumns: null,
  dateColumns: null,
  dateFrom: null,
  dateTo: null,
  searchText: "",
  toggledFilters: [],
};

const INITIAL_STATE = {
  arcsFilters: ITableFilter_init,
  tmcPointsFilters: ITableFilter_init,
  tmcSegmentsFilters: ITableFilter_init,
  reportFilters: ITableFilter_init,
  historyFilters: ITableFilter_init,
  trafficFilters: ITableFilter_init,
  stationFilters: ITableFilter_init,
  pmvHistoryFilters: ITableFilter_init,
  pmvFilters: ITableFilter_init,
  cameraFilters: ITableFilter_init,
  parkingFilters: ITableFilter_init,
  userActivityFilters: ITableFilter_init,
};

export default function filterTableReducer(state = INITIAL_STATE, action) {
  let newState = { ...state };
  switch (action.type) {
    case FilterTableActions.SAVE_FILTERS:
      newState[action.payload.target] = {
        ...TableFilterModel.fromDATA(action.payload.filters),
      };
      return newState;
    case FilterTableActions.RESET_FILTERS:
      newState[action.payload.target] = {
        ...ITableFilter_init,
        tableColumns: { ...action.payload.filters.tableColumns },
        dateColumns: [...action.payload.filters.dateColumns],
        toggledFilters: { ...action.payload.filters.toggledFilters },
      };
      return newState;
    case FilterTableActions.RESET_ALL_FILTERS:
      newState[action.payload.target] = {
        ...ITableFilter_init,
        tableColumns: { ...action.payload.filters.tableColumns },
        dateColumns: [...action.payload.filters.dateColumns],
        toggledFilters: { ...action.payload.filters.toggledFilters },
        dateFrom: action.payload.filters.dateFrom,
        dateTo: action.payload.filters.dateTo,
      };
      return newState;
    default:
      return state;
  }
}

export const getSavedTableColumns = (state, stateKey) =>
  state[stateKey].tableColumns;
export const getSavedDateColumns = (state, stateKey) =>
  state[stateKey].dateColumns;
export const getSavedDateFrom = (state, stateKey) => state[stateKey].dateFrom;
export const getSavedDateTo = (state, stateKey) => state[stateKey].dateTo;
export const getSavedSearchText = (state, stateKey) =>
  state[stateKey].searchText;
export const getSavedToggledFilters = (state, stateKey) =>
  state[stateKey].toggledFilters;
export const getSavedFilters = (state, stateKey) => state[stateKey];
