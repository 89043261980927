import React, { Component } from "react";

class DropdownColumnSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCheckboxFilterClick(e, item) {
    const { onChange } = this.props;

    onChange(item);
  }

  render() {
    const { data } = this.props;

    return (
      <div
        uk-dropdown="mode: hover"
        className="uk-overflow-auto custom-dropdown-class-width"
      >
        {data &&
          data.map((item, index) => {
            return (
              <div
                key={index}
                className=" uk-flex uk-flex-row uk-flex-middle uk-space-between"
              >
                <input
                  type="checkbox"
                  id={item.id}
                  checked={item.checked}
                  hidden={item.id === "checkbox" ? true : false}
                  onChange={(e) => this.onCheckboxFilterClick(e, item)}
                  className="uk-checkbox uk-checkbox-small "
                />
                <label
                  className="uk-margin-left"
                  hidden={item.name === "Selezione Eventi" ? true : false}
                >
                  {item.label}
                </label>
              </div>
            );
          })}
      </div>
    );
  }
}

export default DropdownColumnSelect;
