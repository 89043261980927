import React, { Component } from "react";
import NavTab from "components/layout/nav-tab";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toggleOpenNavTab } from "reducers/ui/nav-tab/nav-tab.actions";

class BasicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    const { toggleOpenNavTab } = this.props;
    toggleOpenNavTab(false);
  };

  render() {
    return <NavTab isOpen={true} items={null} onSearch={null} />;
  }
}

const mapDispatchToProps = {
  toggleOpenNavTab,
};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicPage)
);
