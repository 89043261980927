import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as moment from "moment";
import "./style.less";
//STORE
import { getCurrentParking } from "store";
import { setCurrentParking } from "reducers/installations/parking/parking.actions";
//COMPONENTS
import LineChartParkingMeasures from "components/layout/line-chart/line-chart-parking-measures";
//UTILS
import {
  getParkingIcon,
  openingStatusEnum,
  getOpeningStatusTranslation,
  getOperationalStatusTranslation,
} from "utils/utils-parking";

const mapStateToProps = (state) => ({
  currentParking: getCurrentParking(state),
});

const mapDispatchToProps = {
  setCurrentParking,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ParkingsDetailDynamicData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeDetails = () => {
    const { setCurrentParking } = this.props;
    setCurrentParking(null);
  };

  header = () => {
    const { currentParking } = this.props;

    const parkingIcon = getParkingIcon(currentParking);

    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="uk-position-relative event-height-width-48">
          <img className={"img-status"} alt={"webcam_img"} src={parkingIcon} />
        </div>
        <div className="uk-flex uk-flex-column ">
          <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-muted-event">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.parkingName
              ? currentParking.properties.parkingName
              : "-"}
          </div>
          <div className=" uk-margin-left uk-margin-right text-line-width text-detail-muted-event location_info p-dot-2">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.parkingAddress
              ? currentParking.properties.parkingAddress
              : "-"}
          </div>
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            this.closeDetails();
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClickSecondTab } = this.props;

    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickSecondTab();
            }}
          >
            <a href="/#">Anagrafica</a>
          </li>
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dati Dinamici</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { currentParking, dataToPlot } = this.props;
    let operationalStatusTranslation = getOperationalStatusTranslation(
      currentParking &&
        currentParking.properties &&
        currentParking.properties.parkingOperationalStatus
        ? currentParking.properties.parkingOperationalStatus
        : null
    );

    return (
      <div className={"uk-flex uk-flex-column contentBodyStationDetail"}>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Stato Apertura</div>
          <div
            className={`text-detail-emphasis-station-detail ${
              currentParking &&
              currentParking.properties &&
              currentParking.properties.parkingOpeningStatus &&
              openingStatusEnum.find((item) => {
                return (
                  item.id === currentParking.properties.parkingOpeningStatus
                );
              }).nameIt === "Aperto"
                ? "green-text"
                : ""
            }`}
          >
            {getOpeningStatusTranslation(
              currentParking &&
                currentParking.properties &&
                currentParking.properties.parkingOpeningStatus
                ? currentParking.properties.parkingOpeningStatus
                : null
            )}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Stato Operativo
          </div>
          <div
            className={`text-detail-emphasis-station-detail ${
              operationalStatusTranslation === "Libero"
                ? "green-text"
                : operationalStatusTranslation === "Quasi Completo"
                ? "yellow-text"
                : operationalStatusTranslation === "Completo (all'entrata)"
                ? "orange-text"
                : operationalStatusTranslation === "Completo"
                ? "red-text"
                : ""
            }`}
          >
            {getOperationalStatusTranslation(
              currentParking &&
                currentParking.properties &&
                currentParking.properties.parkingOperationalStatus
                ? currentParking.properties.parkingOperationalStatus
                : null
            )}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Capacità Corrente
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            (currentParking.properties.parkingNumberOfSpacesOverride ||
              currentParking.properties.parkingNumberOfSpacesOverride === 0)
              ? currentParking.properties.parkingNumberOfSpacesOverride
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            N° Posti Liberi
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            (currentParking.properties.parkingNumberOfVacantSpaces ||
              currentParking.properties.parkingNumberOfVacantSpaces === 0)
              ? currentParking.properties.parkingNumberOfVacantSpaces
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            N° Posti Occupati
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            (currentParking.properties.parkingNumberOfOccupiedSpaces ||
              currentParking.properties.parkingNumberOfOccupiedSpaces === 0)
              ? currentParking.properties.parkingNumberOfOccupiedSpaces
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Occupazione [%]
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            (currentParking.properties.parkingOccupancy ||
              currentParking.properties.parkingOccupancy === 0)
              ? currentParking.properties.parkingOccupancy
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Ultimo aggiornamento
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.latestParkingUpdate
              ? moment(currentParking.properties.latestParkingUpdate).format(
                  "DD/MM/YYYY HH:mm"
                )
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="uk-flex uk-flex-column">
            <div className="graph-title uk-width-1-1 uk-flex uk-flex-row uk-flex-right">
              {moment().format("DD/MM/YYYY")}
            </div>
            <div className="text-detail-emphasis-station-detail height-chart-parking">
              <LineChartParkingMeasures data={dataToPlot} />
            </div>
          </div>
        </li>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}

export default ParkingsDetailDynamicData;
