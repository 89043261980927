import React, { Component } from "react";
import "../../../style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//PARAMETRI SELEZIONABILI
import { selectFields } from "enums/selectable-params";
//ACTIONS
import { getNewEvent } from "store";
class SelectedArcsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderCompletaPercorso = () => {
    const {
      onClickCompletaPercorso,
      btnRouting,
      isRoutingLoading,
      listOfArcs,
    } = this.props;
    if (isRoutingLoading) {
      return (
        <div
          uk-spinner="ratio: 0.5"
          className="uk-spinner-center small-icon-width"
        ></div>
      );
    } else if (btnRouting) {
      return (
        <div uk-tooltip="Completa percorso">
          <div
            className="uk-icon pointer small-icon-width"
            uk-icon="icon: forward"
            onClick={() => onClickCompletaPercorso()}
          ></div>
        </div>
      );
    } else {
      return (
        <div
          className="cursor-disabled "
          uk-tooltip={
            listOfArcs.length > 2
              ? "Questa funzione può essere utilizzata soltanto se sono stati confermati due archi"
              : "Rimuovi e conferma un nuovo arco per calcolare un altro percorso"
          }
        >
          <div
            className="uk-icon small-icon-width"
            uk-icon="icon: forward"
          ></div>
        </div>
      );
    }
  };

  render() {
    const { onClickDeleteArcRow, changeRadiusEstesa, listOfArcs } = this.props;

    let listOfConfirmedArcs = listOfArcs;

    return (
      <div className="event-table-list-selected-arc uk-margin-small-bottom uk-margin-small-top uk-margin-large-right uk-margin-large-left">
        <table className="uk-table uk-table-divider uk-table-hover">
          <thead>
            <tr>
              <th>
                <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                  Indice
                </div>
              </th>
              <th>
                <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                  ID Arco
                </div>
              </th>
              <th>
                <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                  Direzione
                </div>
              </th>
              <th>
                <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                  Nome Strada
                </div>
              </th>
              <th>
                <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                  Lunghezza [m]
                </div>
              </th>
              <th>
                <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
                  Raggio influenza [m]
                </div>
              </th>
              <th>{/* EMPTY FOR DELETE ACTION */}</th>
              <th>{/* EMPTY FOR COMPLETA PERCORSO */}</th>
            </tr>
          </thead>
          <tbody>
            {listOfConfirmedArcs.map((item, index) => {
              return (
                <tr key={index + 1} className="arc-table-small">
                  <td>{index + 1}</td>
                  <td>{item.properties.arcid}</td>
                  {item.properties.direction ? (
                    <td>
                      <div
                        className="uk-icon arc-direzione-icon"
                        style={{
                          transform:
                            "rotate(" + item.properties.direction + "deg)",
                        }}
                        uk-icon="arrow-up"
                      ></div>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>{item.properties.name}</td>
                  <td>
                    {item.properties.distance
                      ? item.properties.distance
                      : item.properties.cost
                      ? item.properties.cost.toFixed(2)
                      : ""}
                  </td>
                  <td>
                    <select
                      className="uk-select arc-raggio-select"
                      defaultValue={
                        item.properties.radiusOfInfluence ||
                        "Seleziona raggio [m]"
                      }
                      onChange={(e) =>
                        changeRadiusEstesa(e, item.properties.arcid)
                      }
                      disabled={true}
                    >
                      {selectFields.RADIUS_INFLUENCE_METERS.properties.map(
                        (item, index) => (
                          <option key={index}>{item.name}</option>
                        )
                      )}
                    </select>
                  </td>
                  <td>
                    <div uk-tooltip="Rimuovi arco">
                      <div
                        className="uk-icon pointer"
                        uk-icon="icon: acr-interface-close-big"
                        onClick={(event) => {
                          event.preventDefault();
                          onClickDeleteArcRow(index);
                        }}
                      ></div>
                    </div>
                  </td>
                  <td>
                    {index === listOfConfirmedArcs.length - 1 && index !== 0
                      ? this.renderCompletaPercorso()
                      : null}
                  </td>
                </tr>
              );
            })}
            {/* FINAL EMPTY ROW */}
            {/* <tr key={listOfConfirmedArcs.length} className="arc-table-small">
              <td colSpan="8" className="uk-flex uk-flex-row uk-flex-center uk-width-1-1">
                Aggiungi arco
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  newEvent: getNewEvent(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectedArcsTable)
);
