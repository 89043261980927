import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//NAV ITEMS
import NavItemIntallationStation from "./nav-item-installations-stations";
import NavItemWebCams from "./nav-item-installations-webcams";
import NavItemInstallationParking from "./nav-item-installations-parking";
import NavItemInstallationPmv from "./nav-item-installations-pmv";
//STORE AND ACTIONS
import {
  isAllCamerasVisible,
  isParkingVisible,
  isPmvVisible,
  getPermissions,
  isOpenNavTab,
  isOpenNavTraffic,
  isStationVisible,
  isOpenNavStation,
  isOpenNavWebcams,
  isMobilitaVisible,
  getBottombarTabs,
  isOpenNavPmv,
  isAesysPanelVisible,
  isSolariPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
} from "store";
import {
  setAllCamerasVisibility,
  setParkingVisibility,
  setPmvVisibility,
  setStationVisibility,
  setAesysPanelVisibility,
  setSolariPanelVisibility,
  setFuturitPanelVisibility,
  setVisuallabPanelVisibility,
  setSpheraPanelVisibility,
} from "reducers/ui/installations-menu/installations-menu.actions";
import {
  toggleOpenNavTab,
  setNavTabMultitab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import {
  toggleNavStation,
  toggleNavWebcams,
  toggleNavPmv,
  toggleNavParking,
} from "reducers/ui/nav/nav.actions";
import { resetCurrentStation } from "reducers/installations/stations/stations.actions";
import {
  closeBottombar,
  setBottombarActiveTab,
  removeBottombarTab,
} from "reducers/ui/bottombar/bottombar.actions";
import {
  setCurrentCamera,
  resetPinnedCameras,
} from "reducers/installations/cameras/cameras.actions";
import { resetCurrentParking } from "reducers/installations/parking/parking.actions";
import {
  resetCurrentPmv,
  setCurrentPmvGroup,
} from "reducers/installations/pmv/pmv.actions";

const mapStateToProps = (state) => ({
  permissions: getPermissions(state),
  isOpenNavPmv: isOpenNavPmv(state),
  bottombarTabs: getBottombarTabs(state),
  isParkingVisible: isParkingVisible(state),
  isOpenNavTab: isOpenNavTab(state),
  isOpenNavTraffic: isOpenNavTraffic(state),
  isStationVisible: isStationVisible(state),
  isOpenNavStation: isOpenNavStation(state),
  isOpenNavWebcams: isOpenNavWebcams(state),
  allCamerasVisible: isAllCamerasVisible(state),
  isMobilitaVisible: isMobilitaVisible(state),
  isPmvVisible: isPmvVisible(state),
  isSolariPanelVisible: isSolariPanelVisible(state),
  isAesysPanelVisible: isAesysPanelVisible(state),
  isFuturitPanelVisible: isFuturitPanelVisible(state),
  isVisuallabPanelVisible: isVisuallabPanelVisible(state),
  isSfheraPanelVisible: isSfheraPanelVisible(state),
});

const mapDispatchToProps = {
  setAllCamerasVisibility,
  setParkingVisibility,
  setPmvVisibility,
  toggleOpenNavTab,
  setStationVisibility,
  toggleNavStation,
  toggleNavWebcams,
  setNavTabMultitab,
  resetCurrentStation,
  setCurrentCamera,
  setBottombarActiveTab,
  removeBottombarTab,
  closeBottombar,
  resetPinnedCameras,
  resetCurrentParking,
  toggleNavPmv,
  setCurrentPmvGroup,
  resetCurrentPmv,
  setAesysPanelVisibility,
  setSolariPanelVisibility,
  setFuturitPanelVisibility,
  setVisuallabPanelVisibility,
  setSpheraPanelVisibility,
  toggleNavParking,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemInstallations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  clickOnStationLabel = () => {
    const {
      goToInstallations,
      isOpenNavStation,
      toggleNavStation,
      setNavTabMultitab,
      permissions,
      isStationVisible,
      toggleOpenNavTab,
    } = this.props;

    const stationPermission = permissions.indexOf("STATION") >= 0;
    if (stationPermission) {
      toggleNavStation(!isOpenNavStation);
      setNavTabMultitab("Stazioni di Misura");
      goToInstallations("stations");

      if (!isStationVisible) {
        toggleOpenNavTab(false);
      }
    }
  };

  clickOnStationCheckbox = (value) => {
    const {
      toggleOpenNavTab,
      isStationVisible,
      setStationVisibility,
      resetCurrentStation,
      permissions,
    } = this.props;

    const stationPermission = permissions.indexOf("STATION") >= 0;
    if (stationPermission) {
      this.clickOnStationLabel();
      setStationVisibility(!isStationVisible);
      toggleOpenNavTab(!isStationVisible);

      if (isStationVisible) {
        resetCurrentStation();
      }
    }
  };

  clickOnCameraLabel = () => {
    const {
      goToInstallations,
      isOpenNavWebcams,
      toggleNavWebcams,
      setNavTabMultitab,
      permissions,
      allCamerasVisible,
      toggleOpenNavTab,
    } = this.props;

    const monitoringPermission = permissions.indexOf("CCTV-MONITORING") >= 0;
    if (monitoringPermission) {
      toggleNavWebcams(!isOpenNavWebcams);
      setNavTabMultitab("Telecamere");
      goToInstallations("cctv");

      if (!allCamerasVisible) {
        toggleOpenNavTab(false);
      }
    }
  };

  clickOnCameraCheckbox = (name) => {
    const {
      toggleOpenNavTab,
      setCurrentCamera,
      isMobilitaVisible,
      allCamerasVisible,
      setAllCamerasVisibility,
      setBottombarActiveTab,
      removeBottombarTab,
      closeBottombar,
      bottombarTabs,
      resetPinnedCameras,
      permissions,
      setNavTabMultitab,
      goToInstallations,
      toggleNavWebcams,
      isOpenNavWebcams,
    } = this.props;

    const monitoringPermission = permissions.indexOf("CCTV-MONITORING") >= 0;

    if (monitoringPermission) {
      setNavTabMultitab("Telecamere");
      goToInstallations("cctv");

      if (name === "all") {
        setAllCamerasVisibility(!allCamerasVisible);

        if (!isOpenNavWebcams && !allCamerasVisible) {
          toggleNavWebcams(true);
        } else if (isOpenNavWebcams && allCamerasVisible) {
          toggleNavWebcams(false);
        }

        const navOpen = allCamerasVisible || isMobilitaVisible;
        !navOpen ? toggleOpenNavTab(true) : toggleOpenNavTab(false);
      } else if (name === "mobilita") {
        setAllCamerasVisibility(!isMobilitaVisible);
        toggleOpenNavTab(!isMobilitaVisible);
        toggleNavWebcams(!isMobilitaVisible);
      }

      if (allCamerasVisible || isMobilitaVisible) {
        setCurrentCamera(null);
        resetPinnedCameras();
        let newTab =
          bottombarTabs.filter((item) => item !== "Telecamere").length > 0
            ? bottombarTabs[0]
            : null;
        setBottombarActiveTab(newTab);
        if (!newTab) {
          closeBottombar();
        }
        removeBottombarTab("Telecamere");
      }
    }
  };

  clickOnParkingLabel = () => {
    const {
      goToInstallations,
      setNavTabMultitab,
      permissions,
      isParkingVisible,
      toggleOpenNavTab,
      toggleNavParking,
    } = this.props;

    const parkingPermission = permissions.indexOf("PARKING") >= 0;
    if (parkingPermission) {
      toggleNavParking(true);
      setNavTabMultitab("Parcheggi");
      goToInstallations("parking");

      if (!isParkingVisible) {
        toggleOpenNavTab(false);
      }
    }
  };

  clickOnParkingCheckbox = (value) => {
    const {
      toggleOpenNavTab,
      isParkingVisible,
      setParkingVisibility,
      resetCurrentParking,
      permissions,
    } = this.props;

    const parkingPermission = permissions.indexOf("PARKING") >= 0;
    if (parkingPermission) {
      this.clickOnParkingLabel();
      setParkingVisibility(!isParkingVisible);
      toggleOpenNavTab(!isParkingVisible);

      if (isParkingVisible) {
        resetCurrentParking();
      }
    }
  };

  clickOnPmvLabel = () => {
    const {
      goToInstallations,
      isOpenNavPmv,
      toggleNavPmv,
      setNavTabMultitab,
      permissions,
    } = this.props;

    const pmvPermission = permissions.indexOf("PMV_MONITORING") >= 0;
    if (pmvPermission) {
      toggleNavPmv(!isOpenNavPmv);
      setNavTabMultitab("Pannelli");
      goToInstallations("pmv");
    }
  };

  clickOnPmvCheckbox = (name) => {
    const {
      toggleOpenNavTab,
      isPmvVisible,
      setPmvVisibility,
      permissions,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      resetCurrentPmv,
      setCurrentPmvGroup,
      setAesysPanelVisibility,
      setSolariPanelVisibility,
      setFuturitPanelVisibility,
      setVisuallabPanelVisibility,
      setSpheraPanelVisibility,
      toggleNavPmv,
      setNavTabMultitab,
      goToInstallations,
      isOpenNavPmv,
    } = this.props;

    const pmvPermission = permissions.indexOf("PMV_MONITORING") >= 0;

    if (pmvPermission) {
      setNavTabMultitab("Pannelli");
      goToInstallations("pmv");
      resetCurrentPmv();

      if (name === "all") {
        if (!isOpenNavPmv && !isPmvVisible) {
          toggleNavPmv(true);
        } else if (isOpenNavPmv && isPmvVisible) {
          toggleNavPmv(false);
        }
        setCurrentPmvGroup([]);
        toggleOpenNavTab(
          !isSolariPanelVisible ||
            !isAesysPanelVisible ||
            !isFuturitPanelVisible ||
            !isVisuallabPanelVisible ||
            !isSfheraPanelVisible
        );
        setPmvVisibility(!isPmvVisible);
      } else if (name === "solari") {
        toggleOpenNavTab(
          !(
            isSolariPanelVisible &&
            !isAesysPanelVisible &&
            !isFuturitPanelVisible &&
            !isVisuallabPanelVisible && 
            !isSfheraPanelVisible
          )
        );
        toggleNavPmv(
          !(
            isSolariPanelVisible &&
            !isAesysPanelVisible &&
            !isFuturitPanelVisible &&
            !isVisuallabPanelVisible &&
            !isSfheraPanelVisible
          )
        );
        setSolariPanelVisibility(!isSolariPanelVisible);
      } else if (name === "aesys") {
        toggleOpenNavTab(
          !(
            !isSolariPanelVisible &&
            isAesysPanelVisible &&
            !isFuturitPanelVisible &&
            !isVisuallabPanelVisible &&
            !isSfheraPanelVisible
          )
        );
        toggleNavPmv(
          !(
            !isSolariPanelVisible &&
            isAesysPanelVisible &&
            !isFuturitPanelVisible &&
            !isVisuallabPanelVisible &&
            !isSfheraPanelVisible
          )
        );
        setAesysPanelVisibility(!isAesysPanelVisible);
      } else if (name === "futurit") {
        toggleOpenNavTab(
          !(
            !isSolariPanelVisible &&
            !isAesysPanelVisible &&
            isFuturitPanelVisible &&
            !isVisuallabPanelVisible &&
            !isSfheraPanelVisible
          )
        );
        toggleNavPmv(
          !(
            !isSolariPanelVisible &&
            !isAesysPanelVisible &&
            isFuturitPanelVisible &&
            !isVisuallabPanelVisible &&
            !isSfheraPanelVisible
          )
        );
        setFuturitPanelVisibility(!isFuturitPanelVisible);
      } else if (name === "visuallab") {
        toggleOpenNavTab(
          !(
            !isSolariPanelVisible &&
            !isAesysPanelVisible &&
            !isFuturitPanelVisible &&
            isVisuallabPanelVisible &&
            !isSfheraPanelVisible
          )
        );
        toggleNavPmv(
          !(
            !isSolariPanelVisible &&
            !isAesysPanelVisible &&
            !isFuturitPanelVisible &&
            isVisuallabPanelVisible &&
            !isSfheraPanelVisible
          )
        );
        setVisuallabPanelVisibility(!isVisuallabPanelVisible);
      } else if (name === "sfhera") {
        toggleOpenNavTab(
          !(
            !isSolariPanelVisible &&
            !isAesysPanelVisible &&
            !isFuturitPanelVisible &&
            !isVisuallabPanelVisible &&
            isSfheraPanelVisible
          )
        );
        toggleNavPmv(
          !(
            !isSolariPanelVisible &&
            !isAesysPanelVisible &&
            !isFuturitPanelVisible &&
            !isVisuallabPanelVisible &&
            isSfheraPanelVisible
          )
        );
        setSpheraPanelVisibility(!isSfheraPanelVisible);
      }
    }  
  }
  

  render() {
    const {
      onClickInstallations,
      location,
      isStationVisible,
      allCamerasVisible,
      checked,
      isParkingVisible,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
    } = this.props;
    const isActive = location.pathname.includes("installations");

    return (
      <li className={isActive ? "uk-parent uk-active" : "uk-parent"}>
        <a onClick={onClickInstallations} href="/#">
          <span
            uk-icon="icon: acr-menu-installations-management; ratio: 1.4;"
            className={`uk-icon ${
              isStationVisible ||
              allCamerasVisible ||
              isParkingVisible ||
              isSolariPanelVisible ||
              isAesysPanelVisible ||
              isFuturitPanelVisible ||
              isVisuallabPanelVisible ||
              isSfheraPanelVisible
                ? "yellow-icon"
                : ""
            }`}
          ></span>
          Gestione impianti
        </a>
        {checked ? (
          <ul className="uk-nav-sub uk-margin-small-top acr-transport-nav-sub">
            {/* This is an example, we could use directly permissions from props (free choice here) */}
            <div className="uk-checkbox-tree-large uk-margin-right acr-transport-checkbox-tree">
              {/* STATIONS */}
              {isActive && (
                <div className="uk-parent">
                  <NavItemIntallationStation
                    clickOnStationLabel={this.clickOnStationLabel}
                    clickOnStationCheckbox={this.clickOnStationCheckbox}
                  />
                </div>
              )}
              {/* PANNELLI */}
              {isActive && (
                <div className="uk-parent">
                  <NavItemInstallationPmv
                    clickOnPmvCheckbox={this.clickOnPmvCheckbox}
                    clickOnPmvLabel={this.clickOnPmvLabel}
                  />
                </div>
              )}
              {/* WEBCAMS */}
              {isActive && (
                <div className="uk-parent">
                  <NavItemWebCams
                    clickOnCameraCheckbox={this.clickOnCameraCheckbox}
                    clickOnCameraLabel={this.clickOnCameraLabel}
                  />
                </div>
              )}
              {/* PARCHEGGI */}
              {isActive && (
                <div className="uk-parent">
                  <NavItemInstallationParking
                    clickOnParkingCheckbox={this.clickOnParkingCheckbox}
                    clickOnParkingLabel={this.clickOnParkingLabel}
                  />
                </div>
              )}
            </div>
          </ul>
        ) : null}
      </li>
    );
  }
}

export default NavItemInstallations;
