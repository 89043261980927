export const NavActions = {
  TOGGLE_DROPDOWN_NETWORK: "UI/NAV/TOGGLE_DROPDOWN_NETWORK",
  TOGGLE_NAV_GRAPH: "UI/NAV/TOGGLE_NAV_GRAPH",
  TOGGLE_NAV_NETWORKRDSTMC: "UI/NAV/TOGGLE_NAV_NETWORKRDSTMC",
  TOGGLE_NAV_TPL: "UI/NAV/TOGGLE_NAV_TPL",
  TOGGLE_NAV_EVENTS: "UI/NAV/TOGGLE_NAV_EVENTS",
  TOGGLE_NAV_TRAFFIC: "UI/NAV/TOGGLE_NAV_TRAFFIC",
  TOGGLE_NAV_STATION: "UI/NAV/TOGGLE_NAV_STATION",
  TOGGLE_NAV_WEBCAMS: "UI/NAV/TOGGLE_NAV_WEBCAMS",
  TOGGLE_NAV_PMV: "UI/NAV/TOGGLE_NAV_PMV",
  TOGGLE_NAV_PARKING: "UI/NAV/TOGGLE_NAV_PARKING",
};

export const toggleDropdownNetwork = () => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_DROPDOWN_NETWORK,
    payload: null,
  });
};

export const toggleNavGraph = (isOpen) => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_NAV_GRAPH,
    payload: isOpen,
  });
};

export const toggleNavNetworkRDSTMC = (isOpen) => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_NAV_NETWORKRDSTMC,
    payload: isOpen,
  });
};

export const toggleNavTPL = (isOpen) => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_NAV_TPL,
    payload: isOpen,
  });
};

export const toggleNavEvents = (isOpen) => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_NAV_EVENTS,
    payload: isOpen,
  });
};

export const toggleNavTraffic = (isOpen) => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_NAV_TRAFFIC,
    payload: isOpen,
  });
};
export const toggleNavStation = (isOpen) => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_NAV_STATION,
    payload: isOpen,
  });
};
export const toggleNavWebcams = (isOpen) => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_NAV_WEBCAMS,
    payload: isOpen,
  });
};
export const toggleNavPmv = (isOpen) => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_NAV_PMV,
    payload: isOpen,
  });
};
export const toggleNavParking = (isOpen) => (dispatch) => {
  dispatch({
    type: NavActions.TOGGLE_NAV_PARKING,
    payload: isOpen,
  });
};
