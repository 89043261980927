import React, { Component } from "react";
import { ResponsiveBar } from "@nivo/bar";
import * as moment from "moment";

class LineChartParkingMeasures extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    // let newDateStart = moment();
    // let newDateEnd = moment();
    // if (data && data.length > 0) {
    //   newDateStart = moment(data[0].timeStamp);
    //   newDateEnd = moment(data[data.length - 1].timeStamp);
    // }
    // let diff = newDateEnd.diff(newDateStart);
    // let diffDuration = moment.duration(diff);
    // let differentTime =
    //   diffDuration.days() * 60 * 24 +
    //   diffDuration.hours() * 60 +
    //   diffDuration.minutes();

    return (
      <ResponsiveBar
        data={data}
        keys={["occupazione"]}
        indexBy="timeStamp"
        margin={{ top: 50, right: 40, bottom: 65, left: 60 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "category10" }}
        minValue={0}
        maxValue={100}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 1,
          tickPadding: 5,
          tickRotation: 90,
          legend: "",
          legendPosition: "middle",
          legendOffset: 32,
          format: function (value) {
            return moment(value).format("HH:mm:ss");
          },
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Occupazione [%]",
          legendPosition: "middle",
          legendOffset: -50,
        }}
        enableLabel={false}
        legends={[
          {
            dataFrom: "keys",
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: -50,
            translateY: -34,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 0,
            itemOpacity: 0.75,
            symbolSize: 18,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            itemTextColor: "#ffffff",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                  fontSize: "35px",
                },
              },
            ],
          },
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={{
          textColor: "#ffffff",
          fontSize: "20px",
          tooltip: {
            container: {
              background: "#333",
            },
          },
          axis: {
            tickColor: "#eee",
            ticks: {
              line: {
                stroke: "#555555",
              },
              text: {
                fill: "#ffffff",
                fontSize: "15px",
              },
            },
            legend: {
              text: {
                fill: "#ffffff",
                fontSize: "15px",
              },
            },
          },
          grid: {
            line: {
              stroke: "#5c5c5c",
              strokeWidth: 1,
              strokeDasharray: "4 4",
            },
          },
        }}
        tooltip={(slice) => {
          let half = data.length / 2;
          return (
            <div
              style={{
                background: "#0f0f0f",
                padding: "9px 12px",
                border: "1px solid #ccc",
                position: "absolute",
                right: slice.index > half ? 10 : -135,
              }}
            >
              x=
              {[slice.data].map((i) => {
                return moment(i.timeStamp).format("HH:mm:ss");
              })}
              <div>
                y=
                {[slice.data].map((i) => {
                  return i.occupazione;
                })}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default LineChartParkingMeasures;
