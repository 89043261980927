import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";

class BusStopLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      active,
      visible,
      viewport,
      layoutMap,
      isDrawingMode,
    } = this.props;

    let zoom = viewport.zoom;
    let maxZoom = viewport.maxZoom;
    let minZoom = viewport.minZoom;
    let minIconSize = 0.5;
    const sizeTrigger = Math.trunc(zoom * 10) / 10;
    const maxSizeAt = maxZoom * 0.7;
    return new IconLayer(
      this.getSubLayerProps({
        id: id,
        data: data,
        ...DeckLayerUtil.loadOptions(),
        getIcon: (obj) => {
          return {
            url: active
              ? DeckLayerUtil.getSelectedIconStop(
                  obj.transportMode,
                  obj.isParkingPoint,
                  layoutMap
                )
              : DeckLayerUtil.getIconStop(
                  obj.transportMode,
                  obj.isParkingPoint,
                  layoutMap
                ),
            width: active ? 200 : 170,
            height: active ? 300 : 170,
            anchorX: 75,
            anchorY: 47,
          };
        },
        sizeScale: 10,
        billboard: false,
        getSize: () => {
          const delta = maxSizeAt - minZoom;
          return (
            Math.min(1, Math.max(minIconSize, (zoom - minZoom) / delta)) *
            (active ? 4.3 : 2.3)
          );
        },
        updateTriggers: {
          getSize: sizeTrigger,
        },
        sizeMinPixels: 0,
        pickable: isDrawingMode ? false : true,
        getPosition: (d) => [d.coordinates.longitude, d.coordinates.latitude],
        visible,
      })
    );
  }
}

BusStopLayer.layerName = "BusStopLayer";

export default BusStopLayer;
