import { CompositeLayer, PolygonLayer } from "deck.gl";

class RadiusInfluenceLayer extends CompositeLayer {
  renderLayers() {
    const { list } = this.props;
    //EMPIRICAL RULE TO LOWER OPACITY AS NUMBER OF CIRCLES INCREASES
    let opacity = 0.1 / (list.length * 5);
    //REMOVE FILLING AFTER 5+ CIRCLES
    let fillColor = list.length <= 5 ? [255, 255, 0] : null;

    return new PolygonLayer(
      this.getSubLayerProps({
        id: "polygon-layer-modal",
        data: list,
        pickable: true,
        stroked: true,
        filled: true,
        wireframe: true,
        lineWidthMinPixels: 3,
        getPolygon: (d) => d.geometry.coordinates,
        getLineWidth: 6,
        getLineColor: [255, 255, 0], //disable the polygon strokes by setting this prop on TileLayer
        getTentativeLineColor: [255, 255, 0],
        getFillColor: fillColor,
        opacity: opacity,
        parameters: {
          depthTest: false,
        },
      })
    );
  }
}

RadiusInfluenceLayer.layerName = "RadiusInfluenceLayer";

export default RadiusInfluenceLayer;
