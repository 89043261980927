import React, { Component } from "react";
import "./style.less";

class PmvPanelBigIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="uk-position-relative uk-flex uk-flex-center uk-flex-middle pmv-icon-big">
        <div
          className="uk-icon"
          uk-icon="icon: acr-menu-routes-management"
        ></div>
      </div>
    );
  }
}

export default PmvPanelBigIcon;
