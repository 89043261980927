import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./style.less";

//ACTIONS
import { resetNavTabInfoRoute } from "reducers/ui/nav-tab/nav-tab.actions";
import { getNavTabInfoRoute } from "store";

class InfoRouteSelected extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  infoBody = () => {
    const { menuInfoRouteReducer } = this.props;
    return (
      <div className="uk-height-1-1">
        {menuInfoRouteReducer &&
          menuInfoRouteReducer.list &&
          menuInfoRouteReducer.list.length > 0 &&
          menuInfoRouteReducer.list.map((route, index) => {
            return (
              <div
                key={index}
                className="uk-flex uk-flex-column uk-padding uk-flex-left fontSize16"
              >
                {route.id && (
                  <div className="uk-flex uk-flex-middle itemInfo">
                    <span className="width250 colorMuted">ID Arco:</span>{" "}
                    {route.id}
                  </div>
                )}
                {route.toponimo && (
                  <div className="uk-flex uk-flex-middle itemInfo">
                    <span className="width250 colorMuted">Toponimo:</span>{" "}
                    {route.toponimo}
                  </div>
                )}
                {route.lunghezza && (
                  <div className="uk-flex uk-flex-middle itemInfo">
                    <span className="width250 colorMuted">Lunghezza: </span>
                    {route.lunghezza}
                  </div>
                )}
                {route.larghezza && (
                  <div className="uk-flex uk-flex-middle itemInfo">
                    <span className="width250 colorMuted">Larghezza:</span>{" "}
                    {route.larghezza}
                  </div>
                )}
                {route.nCorsie && (
                  <div className="uk-flex uk-flex-middle itemInfo">
                    <span className="width250 colorMuted">N° Corsie:</span>{" "}
                    {route.nCorsie}
                  </div>
                )}
                {route.sensoUnico && (
                  <div className="uk-flex uk-flex-middle itemInfo">
                    <span className="width250 colorMuted colorMuted">
                      Senso Unico:{" "}
                    </span>
                    {route.sensoUnico}
                  </div>
                )}
                {route.tipoStrada && (
                  <div className="uk-flex uk-flex-middle itemInfo">
                    <span className="width250 colorMuted">Tipo Strada: </span>
                    {route.tipoStrada}
                  </div>
                )}
                {route.limite && (
                  <div className="uk-flex uk-flex-middle itemInfo">
                    <span className="width250 colorMuted">
                      Limite di Velocità:
                    </span>{" "}
                    {route.limite}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  render() {
    return <div className="flex1 uk-height-1-1">{this.infoBody()}</div>;
  }
}

const mapDispatchToProps = {
  resetNavTabInfoRoute,
};

const mapStateToProps = (state) => ({
  menuInfoRouteReducer: getNavTabInfoRoute(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfoRouteSelected)
);
