import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//ACTIONS, STORE
import { isNetStateVisible, isTypicalVisible } from "store";
//ASSETS
import acr_arc from "assets/icon/graph/acr-arc.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";
//UTILS
import {
  getArcTrafficDescriptionByLos,
  getArcColorByLos,
} from "utils/utils-traffic";

class ArcTrafficListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  netStateTrafficState = () => {
    const { arc, currentArcMap, selectedArc } = this.props;

    return (
      <div className="uk-flex uk-flex-column uk-margin-small-left velocitaMax-container">
        <div
          className={
            getArcColorByLos(
              arc.properties &&
                arc.properties.measures &&
                arc.properties.measures.find(
                  (item) => item.measureId === "los"
                ) &&
                arc.properties.measures.find((item) => item.measureId === "los")
                  .measureValue
            ) + "-traffic-text uk-width-1-1"
          }
        >
          {arc.properties &&
          arc.properties.measures &&
          arc.properties.measures.find((item) => item.measureId === "los") &&
          arc.properties.measures.find((item) => item.measureId === "los")
            .measureValue
            ? getArcTrafficDescriptionByLos(
                arc.properties.measures.find((item) => item.measureId === "los")
                  .measureValue
              )
            : getArcTrafficDescriptionByLos(null)}
        </div>
        <div
          className={
            currentArcMap && selectedArc === arc.properties.archId
              ? "uk-width-1-1"
              : "uk-width-1-1 list-text"
          }
        >
          {"In genere: "}
          {arc.properties &&
          arc.properties.profile &&
          arc.properties.profile.find((item) => item.measureId === "los") &&
          arc.properties.profile.find((item) => item.measureId === "los")
            .measureValue
            ? getArcTrafficDescriptionByLos(
                arc.properties.profile.find((item) => item.measureId === "los")
                  .measureValue
              )
            : getArcTrafficDescriptionByLos(null)}
        </div>
      </div>
    );
  };

  typicTrafficState = () => {
    const { arc, currentArcMap, selectedArc } = this.props;

    return (
      <div className="uk-flex uk-flex-column uk-margin-small-left velocitaMax-container">
        <div
          className={
            currentArcMap && selectedArc === arc.properties.archId
              ? "uk-width-1-1"
              : "uk-width-1-1 list-text"
          }
        >
          {"In genere: "}
          <div
            className={
              getArcColorByLos(
                arc.properties &&
                  arc.properties.profile &&
                  arc.properties.profile.find(
                    (item) => item.measureId === "los"
                  ) &&
                  arc.properties.profile.find(
                    (item) => item.measureId === "los"
                  ).measureValue
              ) + "-traffic-text uk-width-1-1"
            }
          >
            {arc.properties &&
            arc.properties.profile &&
            arc.properties.profile.find((item) => item.measureId === "los") &&
            arc.properties.profile.find((item) => item.measureId === "los")
              .measureValue
              ? getArcTrafficDescriptionByLos(
                  arc.properties.profile.find(
                    (item) => item.measureId === "los"
                  ).measureValue
                )
              : getArcTrafficDescriptionByLos(null)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      arc,
      index,
      toggleArcDetails,
      currentArcMap,
      selectedArc,
      netStateChecked,
      scrollDiv,
    } = this.props;

    return (
      <div
        className="uk-flex uk-flex-row uk-flex-middle uk-flex-between"
        key={index}
      >
        <li
          onClick={(event) => {
            event.preventDefault();
            toggleArcDetails(arc);
          }}
          className={
            currentArcMap &&
            currentArcMap.length === 1 &&
            currentArcMap[0].properties.archId === arc.properties.archId
              ? "uk-flex uk-flex-between uk-active uk-flex-middle"
              : "uk-flex uk-flex-between uk-flex-middle"
          }
        >
          <div className="arc-icon uk-flex uk-flex-middle uk-flex-center">
            <div className="uk-position-relative graph-height-width-48 uk-flex uk-flex-middle uk-flex-center">
              {currentArcMap &&
              currentArcMap.length > 1 &&
              selectedArc === arc.properties.archId ? (
                <div
                  className="parent uk-flex uk-flex-middle uk-flex-center"
                  ref={scrollDiv}
                  tabIndex="-1"
                >
                  <div className="circle-list-map"></div>
                  <img
                    alt="icn_diamond"
                    src={icn_diamond}
                    className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
                  ></img>
                  <img
                    alt="acr_arc"
                    src={acr_arc}
                    className="image2  height-width-48 uk-flex uk-flex-middle uk-flex-center arch-icon"
                    style={{
                      transform:
                        "rotate(" + (arc.properties.direction - 45) + "deg)",
                    }}
                  ></img>
                </div>
              ) : (
                <img
                  alt="acr_arc"
                  src={acr_arc}
                  className="uk-position-relative height-width-48 uk-flex uk-flex-middle uk-flex-center arch-icon"
                  style={{
                    transform:
                      "rotate(" + (arc.properties.direction - 45) + "deg)",
                  }}
                ></img>
              )}
            </div>
          </div>
          <div className="uk-flex uk-margin-small-left arc-name-container">
            <div className="uk-flex uk-flex-column">
              <div
                className={
                  currentArcMap && selectedArc === arc.properties.archId
                    ? "uk-width-1-1 arc-header-name"
                    : "uk-width-1-1 list-text arc-header-name"
                }
              >
                {arc.properties.archName}
              </div>
            </div>
          </div>
          {netStateChecked
            ? this.netStateTrafficState()
            : this.typicTrafficState()}
        </li>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  tipicChecked: isTypicalVisible(state),
  netStateChecked: isNetStateVisible(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArcTrafficListItem)
);
