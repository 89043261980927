import * as stationsReducer from "./stations/stations.reducer";
import * as parkingsReducer from "./parking/parking.reducer";
import * as camerasReducer from "./cameras/cameras.reducer";
import * as pmvsReducer from "./pmv/pmv.reducer";
import { combineReducers } from "redux";

export default combineReducers({
  stations: stationsReducer.default,
  parkings: parkingsReducer.default,
  cameras: camerasReducer.default,
  pmvs: pmvsReducer.default,
});

//STATIONS
export const getStations = (state) =>
  stationsReducer.getStations(state.stations);
export const getCurrentStation = (state) =>
  stationsReducer.getCurrentStation(state.stations);
export const getCurrentStationMap = (state) =>
  stationsReducer.getCurrentStationMap(state.stations);
export const getFilteredStations = (state) =>
  stationsReducer.getFilteredStations(state.stations);

//PARKINGS
export const getParkings = (state) =>
  parkingsReducer.getParkings(state.parkings);
export const getCurrentParking = (state) =>
  parkingsReducer.getCurrentParking(state.parkings);
export const getCurrentParkingMap = (state) =>
  parkingsReducer.getCurrentParkingMap(state.parkings);
export const getFilteredParkings = (state) =>
  parkingsReducer.getFilteredParkings(state.parkings);
export const getWsLastParking = (state) =>
  parkingsReducer.getWsLastParking(state.parkings);

//CAMERAS
export const getCameras = (state) => camerasReducer.getCameras(state.cameras);
export const getCurrentCamera = (state) =>
  camerasReducer.getCurrentCamera(state.cameras);
export const getFilteredCameras = (state) =>
  camerasReducer.getFilteredCameras(state.cameras);
export const getPinnedCameras = (state) =>
  camerasReducer.getPinnedCameras(state.cameras);
export const getCamerasOnMap = (state) =>
  camerasReducer.getCamerasOnMap(state.cameras);
export const getWsLastCameraAlarm = (state) =>
  camerasReducer.getWsLastCameraAlarm(state.cameras);
export const getWsLastCameraStatus = (state) =>
  camerasReducer.getWsLastCameraStatus(state.cameras);
export const getCameraPositionOnMap = (state) =>
  camerasReducer.getCameraPositionOnMap(state.cameras);

//PMV
export const getPmvs = (state) => pmvsReducer.getPmvs(state.pmvs);
export const getCurrentPmv = (state) => pmvsReducer.getCurrentPmv(state.pmvs);
export const getFilteredPmvs = (state) =>
  pmvsReducer.getFilteredPmvs(state.pmvs);
export const getCurrentPmvGroup = (state) =>
  pmvsReducer.getCurrentPmvGroup(state.pmvs);
