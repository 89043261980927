import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
import "react-datepicker/dist/react-datepicker.css";
//COMPONENTS
import CustomisedDatePicker from "components/shared/customised-date-time-picker/customised-datepicker";
// ACTIONS
import { togglePopup } from "reducers/ui/modal/modal.actions";
import { isOpenPopup, getCurrentEvent } from "store";
//PARAMETRI SELEZIONABILI
import { selectFields } from "enums/selectable-params";
import Popup from "components/shared/popup/popup";

class CloseEvent extends Component {
  subscriptions = [];
  constructor(props) {
    super(props);
    this.state = {
      validIDEvent: false,
      valueIDEvent: "",
      validSourceEvent: false,
      valueSourceEvent: "",
      validNote: false,
      valueNote: "",
      valueDateEndEvent: new Date(),
    };
  }

  componentDidMount = () => {
    var selectSource = document.getElementById("source-event");

    selectFields.FONTE.properties.forEach(
      (item, index) =>
        (selectSource.options[selectSource.options.length] = new Option(
          selectFields.FONTE.properties[index].name,
          index
        ))
    );
  };

  handler = (e, name) => {
    var handlervalue = e.target.value;

    if (
      name === "sourceEvent" &&
      handlervalue !== "-" &&
      handlervalue !== "undefined"
    ) {
      this.setState({
        validSourceEvent: true,
        valueSourceEvent: handlervalue,
      });
    } else if (name === "sourceEvent" && handlervalue === "-") {
      this.setState({
        validSourceEvent: false,
        valueSourceEvent: handlervalue,
      });
    }
  };

  onChange = (e, name) => {
    var onchangevalue = e.target.value;
    if (
      name === "noteEvent" &&
      onchangevalue !== "" &&
      onchangevalue !== "undefined"
    ) {
      this.setState({ validNote: true, valueNote: onchangevalue });
    }
    if (name === "noteEvent" && onchangevalue === "") {
      this.setState({
        validNote: false,
        valueNote: onchangevalue,
      });
    }
  };

  onClickDeleteNote = () => {
    this.setState({
      valueNote: "",
    });
  };

  closeEvent = () => {
    const { togglePopup } = this.props;
    togglePopup();
  };

  closePopupEvent = () => {
    const { togglePopup, onClose } = this.props;
    togglePopup();
    onClose();
  };

  changeDate = (value, name) => {
    this.setState({ valueDateEndEvent: value });
  };

  buttonEvent = () => {
    const { currentEvent, onClose } = this.props;
    return (
      <div className="close-button-container uk-flex uk-flex-row uk-flex-between">
        <button
          className="uk-button uk-button-default uk-button-small"
          onClick={onClose}
        >
          Annulla
        </button>
        <button
          className="uk-button uk-button-primary uk-button-small btn-close"
          disabled={!currentEvent}
          onClick={(e) => {
            e.preventDefault();
            this.closeEvent();
          }}
        >
          Chiudi Evento
        </button>
      </div>
    );
  };

  render() {
    const { valueSourceEvent, valueNote, valueDateEndEvent } = this.state;
    const { currentEvent, isOpenPopup } = this.props;

    return (
      <div className="padding-top-form small-form">
        <form className="uk-form-horizontal padding-form ">
          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-1">
              <label className="uk-form-label">ID Evento</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  value={
                    currentEvent && currentEvent.situationRecordId
                      ? currentEvent.situationRecordId
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-1">
              <label className="uk-form-label">ID Pratica</label>
              <div className="uk-form-controls">
                <input
                  className={
                    currentEvent && currentEvent.situationId
                      ? "uk-input"
                      : "uk-input bg-grey"
                  }
                  value={
                    currentEvent && currentEvent.situationId
                      ? currentEvent.situationId
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-1">
              <label className="uk-form-label">Stato Pratica</label>
              <div className="uk-form-controls">
                <input
                  className={
                    currentEvent && currentEvent.situationId
                      ? "uk-input"
                      : "uk-input bg-grey"
                  }
                  value={currentEvent && currentEvent.situationId}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-1">
              <label className="uk-form-label">Fonte</label>
              <div className="uk-form-controls">
                <select
                  id="source-event"
                  name={"sourceEvent"}
                  className="uk-select"
                  value={valueSourceEvent}
                  onChange={(e) => this.handler(e, "sourceEvent")}
                ></select>
              </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-1">
              <label className="uk-form-label">Comunicazione</label>
              <div className="uk-form-controls">
                <CustomisedDatePicker
                  value={valueDateEndEvent}
                  callbackFromParent={(data) => this.changeDate(data)}
                  endDate={valueDateEndEvent}
                  width100={true}
                />
              </div>
            </div>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">Note</label>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea no-resize"
                value={valueNote}
                onChange={(e) => this.onChange(e, "noteEvent")}
              />
              <span
                className="iconRecycleBin"
                uk-icon="icon: acr-interface-cestino"
                onClick={(e) => {
                  e.preventDefault();
                  this.onClickDeleteNote();
                }}
              ></span>
            </div>
          </div>
        </form>
        {this.buttonEvent()}
        {isOpenPopup && (
          <Popup
            iconTypeError={false}
            textBody={
              currentEvent && currentEvent.situationId
                ? "Evento e Pratica chiusi con successo"
                : "Evento chiuso con successo"
            }
            titleHeader="Eventi: Chiudi Evento"
            toggleClose={this.closePopupEvent}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  togglePopup,
  /* disableCloseEventButton, enableCloseEventButton */
};

const mapStateToProps = (state) => ({
  isOpenPopup: isOpenPopup(state),
  currentEvent: getCurrentEvent(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CloseEvent)
);
