import { INITIAL_STATE_KPI } from "./kpi.model";
import { KpiActions } from "./kpi.actions";

export default function kpiReducer(state = INITIAL_STATE_KPI, action) {
  switch (action.type) {
    case KpiActions.ADD_SELECTED_KPI:
      return {
        ...state,
        listSelectedKpi: Array.from(new Set([...action.payload])),
      };
    case KpiActions.REMOVE_SELECTED_KPI:
      return {
        ...state,
        listSelectedKpi: state.listSelectedKpi.filter(
          (kpi) => kpi !== action.payload
        ),
      };
    case KpiActions.EMPTY_SELECTED_KPI_LIST:
      return {
        ...state,
        listSelectedKpi: [],
      };
    case KpiActions.UPDATE_LIST_KPI:
      let listAllKpi = [];
      let listSelectedKpi = [];
      let list = [];
      Object.keys(action.payload).forEach((key) => {
        if (key !== "recordedAt" && key !== "timeFrame") {
          list.push({
            id: key,
            kpi: action.payload[key],
            timeFrame: action.payload["timeFrame"],
            recordedAt: action.payload["recordedAt"],
            selected: false,
          });
        }
      });
      if (state.listAllKpi.length > 0 && list.length > 0) {
        listAllKpi = [...state.listAllKpi].map((k) => {
          list.forEach((elem) => {
            if (k.id === elem.id) {
              k.kpi = elem.kpi;
              k.recordedAt = elem.recordedAt;
              k.timeFrame = elem.timeFrame;
            }
          });
          return k;
        });
      } else {
        listAllKpi = list;
      }
      if (state.listSelectedKpi.length > 0) {
        listSelectedKpi = [...state.listSelectedKpi].map((k) => {
          list.forEach((elem) => {
            if (k.id === elem.id) {
              k.kpi = elem.kpi;
              k.recordedAt = elem.recordedAt;
              k.timeFrame = elem.timeFrame;
              k.selected = true;
            }
          });
          return k;
        });
      }
      return {
        ...state,
        listSelectedKpi,
        listAllKpi,
      };
    default:
      return state;
  }
}

export const getListSelectedKpi = (state) => state.listSelectedKpi;
export const getListAllKpi = (state) => state.listAllKpi;
