import { ModalActions } from "./modal.actions";

const INITIAL_STATE = {
  isOpenPopup: false,
  tab: "general",
  isInsertEventButtonDisabled: true,
  isApiOnError: false,
  typeModal: "newEvent",
  isOpenModal: false,
  localizationType: "Cartografica Puntuale",
  zoomMap: 12,
};

export default function modalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ModalActions.RESET_MODAL_UI:
      return INITIAL_STATE;
    case ModalActions.SET_TAB_MODAL:
      return {
        ...state,
        tab: action.payload,
      };
    case ModalActions.DISABLE_FORWARD_EVENT_BUTTON:
      return {
        ...state,
        isForwardEventButtonDisabled: true,
      };
    case ModalActions.ENABLE_FORWARD_EVENT_BUTTON:
      return {
        ...state,
        isForwardEventButtonDisabled: false,
      };
    case ModalActions.DISABLE_INSERT_EVENT_BUTTON:
      return {
        ...state,
        isInsertEventButtonDisabled: true,
      };
    case ModalActions.ENABLE_INSERT_EVENT_BUTTON:
      return {
        ...state,
        isInsertEventButtonDisabled: false,
      };
    case ModalActions.SET_TYPE_MODAL:
      return {
        ...state,
        typeModal: action.payload,
      };
    case ModalActions.SET_TYPE_LOCALIZATION:
      return {
        ...state,
        localizationType: action.payload,
      };
    case ModalActions.TOGGLE_POPUP:
      return {
        ...state,
        isOpenPopup: !state.isOpenPopup,
      };
    case ModalActions.TOGGLE_MODAL:
      return {
        ...state,
        isOpenModal: !state.isOpenModal,
      };
    case ModalActions.API_ON_ERROR:
      return {
        ...state,
        isApiOnError: action.payload,
      };
    case ModalActions.SET_ZOOM_LOCALIZATION:
      return {
        ...state,
        zoomMap: action.payload,
      };
    default:
      return state;
  }
}

export const getTabModal = (state) => state.tab;
export const getInsertEventButtonDisabled = (state) =>
  state.isInsertEventButtonDisabled;
export const isApiOnError = (state) => state.isApiOnError;
export const getForwardEventButtonDisabled = (state) =>
  state.isForwardEventButtonDisabled;
export const getTypeModal = (state) => state.typeModal;
export const getTypeLocalization = (state) => state.localizationType;
export const isOpenPopup = (state) => state.isOpenPopup;
export const isOpenModal = (state) => state.isOpenModal;
export const getZoomLocalization = (state) => state.zoomMap;
