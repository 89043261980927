import { CompositeLayer, TextLayer } from "deck.gl";

class PinTextLayer extends CompositeLayer {
  renderLayers() {
    const { list } = this.props;

    return new TextLayer(
      this.getSubLayerProps({
        data: list,
        getSize: 38,
        getText: (d) => (d.index ? d.index.toString() : "0"),
        getColor: [0, 0, 0],
        getPixelOffset: [12, -30],
        getPosition: (d) => [parseFloat(d.lon), parseFloat(d.lat), d.index],
        fontWeight: 600,
        parameters: {
          depthTest: false,
        },
      })
    );
  }
}

PinTextLayer.layerName = "PinTextLayer";

export default PinTextLayer;
