import { PmvActions } from "./pmv.actions";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";
import { INITIAL_STATE_PMV } from "./pmv.model";
import * as FilterUtils from "utils/filter-utils";

function searchPmv(searchText) {
  return (panel) =>
    (panel &&
      panel.properties &&
      panel.properties.anagrafica &&
      panel.properties.anagrafica.id &&
      panel.properties.anagrafica.id.toString() &&
      panel.properties.anagrafica.id
        .toString()
        .toLowerCase()
        .includes(searchText)) ||
    (panel &&
      panel.properties &&
      panel.properties.anagrafica &&
      panel.properties.anagrafica.nome &&
      panel.properties.anagrafica.nome.toString() &&
      panel.properties.anagrafica.nome
        .toString()
        .toLowerCase()
        .includes(searchText)) ||
    (panel &&
      panel.properties &&
      panel.properties.anagrafica &&
      panel.properties.anagrafica.strada &&
      panel.properties.anagrafica.strada.toString() &&
      panel.properties.anagrafica.strada
        .toString()
        .toLowerCase()
        .includes(searchText));
}

export default function pmvsReducer(state = INITIAL_STATE_PMV, action) {
  let pmvsListCopy = FilterUtils.deepCopyOfArraysOfObj(state.pmvs);
  let pmvsFilteredListCopy = FilterUtils.deepCopyOfArraysOfObj(
    state.pmvsFiltered
  );

  switch (action.type) {
    case PmvActions.SET_LIST_PMV:
      let selectedItem = state.pmvs.find((item) => {
        return item.selected;
      });

      let selectedPmvGroup = state.pmvsFiltered.filter((item) => item.selected);

      let payloadList = FilterUtils.deepCopyOfArraysOfObj(action.payload.list);

      payloadList.forEach((item, index, arr) => {
        if (
          selectedItem &&
          selectedItem.properties &&
          item.properties &&
          selectedItem.properties.anagrafica &&
          item.properties.anagrafica &&
          selectedItem.properties.anagrafica.id ===
            item.properties.anagrafica.id
        ) {
          arr[index] = {
            ...item,
            selected: selectedItem.selected,
          };
        } else if (selectedPmvGroup && selectedPmvGroup.length > 0) {
          selectedPmvGroup.forEach((pmv, pmvIndex) => {
            if (
              pmv.properties.anagrafica.id === item.properties.anagrafica.id
            ) {
              arr[index] = {
                ...item,
                selected: pmv.selected,
              };
            }
          });
        }
      });
      let pmvsFiltered = FilterUtils.deepCopyOfArraysOfObj(payloadList);
      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "pmv"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchPmv(searchText);
        pmvsFiltered = payloadList.filter(searchFn);
      }

      return {
        ...state,
        pmvs: payloadList,
        pmvsFiltered: pmvsFiltered,
      };
    case PmvActions.UPDATE_CURRENT_PMV:
      return {
        ...state,
        current: action.payload,
      };

    case PmvActions.SET_CURRENT_GROUP:
      let index = state.currentGroup.findIndex(
        (x) =>
          action.payload.properties &&
          action.payload.properties.anagrafica &&
          x.properties &&
          x.properties.anagrafica &&
          x.properties.anagrafica.id === action.payload.properties.anagrafica.id
      );
      let selectedPmv = [];
      if (
        action.paylaod !== null &&
        action.payload !== undefined &&
        action.payload.length !== 0
      ) {
        if (index === -1) {
          selectedPmv = [...state.currentGroup, action.payload];
        } else {
          selectedPmv = [...state.currentGroup];
          selectedPmv.splice(index, 1);
        }
      } else {
        selectedPmv = [];
      }

      let updatepmvsFiltered = state.pmvsFiltered.map((item) => ({
        ...item,
        selected:
          action.payload.properties &&
          action.payload.properties.anagrafica &&
          item.properties.anagrafica &&
          item.properties.anagrafica.id ===
            action.payload.properties.anagrafica.id
            ? !item.selected
            : item.selected,
      }));

      return {
        ...state,
        currentGroup: selectedPmv,
        pmvsFiltered: updatepmvsFiltered,
      };

    case PmvActions.SET_CURRENT_PMV:
      if (state.pmvs) {
        pmvsListCopy.forEach((item, index, arr) => {
          if (
            item &&
            item.properties &&
            item.properties.anagrafica &&
            item.properties.anagrafica.id &&
            action.payload &&
            action.payload.properties &&
            action.payload.properties.anagrafica &&
            action.payload.properties.anagrafica.id &&
            item.properties.anagrafica.id ===
              action.payload.properties.anagrafica.id
          ) {
            arr[index] = {
              ...item,
              selected: action.payload.selected ? false : true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      if (state.pmvsFiltered) {
        pmvsFilteredListCopy.forEach((item, index, arr) => {
          if (
            item &&
            item.properties &&
            item.properties.anagrafica &&
            item.properties.anagrafica.id &&
            action.payload &&
            action.payload.properties &&
            action.payload.properties.anagrafica &&
            action.payload.properties.anagrafica.id &&
            item.properties.anagrafica.id ===
              action.payload.properties.anagrafica.id
          ) {
            arr[index] = {
              ...item,
              selected: action.payload.selected ? false : true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }

      return {
        ...state,
        current:
          action.payload &&
          state.current &&
          state.current.properties &&
          state.current.properties.anagrafica &&
          state.current.properties.anagrafica.id &&
          action.payload.properties &&
          action.payload.properties.anagrafica &&
          action.payload.properties.anagrafica.id &&
          state.current.properties.anagrafica.id ===
            action.payload.properties.anagrafica.id
            ? null
            : action.payload,
        pmvs: pmvsListCopy,
        pmvsFiltered: pmvsFilteredListCopy,
        currentGroup: [],
      };

    case PmvActions.RESET_CURRENT_PMV:
      if (state.pmvs) {
        pmvsListCopy.forEach((item, index, arr) => {
          if (item && item.selected) {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      if (state.pmvsFiltered) {
        pmvsFilteredListCopy.forEach((item, index, arr) => {
          if (item && item.selected) {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      return {
        ...state,
        pmvs: pmvsListCopy,
        pmvsFiltered: pmvsFilteredListCopy,
        current: null,
      };

    case NavTabActions.SET_SEARCH:
      if (action.payload && action.payload.type === "pmv") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        let pmvsFiltered = state.pmvs.filter(searchPmv(searchText));
        return {
          ...state,
          pmvsFiltered,
          searchText,
          searchType: action.payload.type,
        };
      } else {
        return { ...state };
      }
    // case PmvActions.UPDATE_PARKING_STATE_WS: {
    //   let objState = { ...state };
    //   let newCurrent = state.current;
    //   //FOR EACH PARKING IN THE LIST, SEARCH CORRESPONDANCE AND UPDATE IT
    //   action.payload.forEach((newParkingStatusItem) => {
    //     pmvsListCopy.forEach((cam, index, arr) => {
    //       if (
    //         cam.properties.anagrafica.id ===
    //         newParkingStatusItem.id
    //       ) {
    //         arr[index] = {
    //           ...cam,
    //           properties: {
    //             ...newParkingStatusItem,
    //           },
    //         };
    //       }
    //     });
    //     pmvsFilteredListCopy.forEach((cam, index, arr) => {
    //       if (
    //         cam.properties.anagrafica.id ===
    //         newParkingStatusItem.id
    //       ) {
    //         arr[index] = {
    //           ...cam,
    //           properties: {
    //             ...newParkingStatusItem,
    //           },
    //         };
    //       }
    //     });
    //     if (newCurrent) {
    //       newCurrent = pmvsListCopy.find(
    //         (cam) =>
    //           cam.properties.anagrafica.id ===
    //           state.current.properties.anagrafica.id
    //       );
    //     }
    //     objState = {
    //       ...objState,
    //       pmvs: pmvsListCopy,
    //       pmvsFiltered: pmvsFilteredListCopy,
    //       current: newCurrent ? newCurrent : null,
    //     };
    //   });
    //   return {
    //     ...objState,
    //     wsLastParking: action.payload,
    //   };
    // }
    // case PmvActions.RESET_PARKING_STATE_WS:
    //   return {
    //     ...state,
    //     wsLastParking: [],
    //   };
    default:
      return state;
  }
}

export const getPmvs = (state) => state.pmvs;
export const getFilteredPmvs = (state) => state.pmvsFiltered;
export const getCurrentPmv = (state) => state.current;
export const getCurrentPmvGroup = (state) => state.currentGroup;
