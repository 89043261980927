export const StopsActions = {
  GET_STOPS: "APP/GET_STOPS",
  RESET_STOPS: "APP/RESET_STOPS",
  SET_CURRENT_STOP: "APP/SET_CURRENT_STOP",
  RESET_CURRENT_STOP: "APP/RESET_CURRENT_STOP",
  RESET_VISIBILITY_STOP: "APP/RESET_VISIBILITY_STOP",
  FILTER_STOP_MAP: "APP/FILTER_STOP_MAP",
  SET_CURRENT_ID_ROUTE_STOP: "APP/SET_CURRENT_ID_ROUTE_STOP",
  SET_CURRENT_ID_TRIP_STOP: "APP/SET_CURRENT_ID_TRIP_STOP",
};

export const getStops = (data) => (dispatch) => {
  dispatch({
    type: StopsActions.GET_STOPS,
    payload: data,
  });
};

export const resetStops = () => (dispatch) => {
  dispatch({
    type: StopsActions.RESET_STOPS,
  });
};

export const setCurrentStop = (current, fromMap) => (dispatch) => {
  dispatch({
    type: StopsActions.SET_CURRENT_STOP,
    payload: { current, fromMap },
  });
};

export const resetCurrentStop = () => (dispatch) => {
  dispatch({
    type: StopsActions.RESET_CURRENT_STOP,
    payload: null,
  });
};

export const filterStopMap = (points) => (dispatch) => {
  dispatch({
    type: StopsActions.FILTER_STOP_MAP,
    payload: points,
  });
};

export const setCurrentIdRouteForStop = (routeIdForStop) => (dispatch) => {
  dispatch({
    type: StopsActions.SET_CURRENT_ID_ROUTE_STOP,
    payload: routeIdForStop,
  });
};

export const setCurrentIdTripForStop = (tripIdForStop) => (dispatch) => {
  dispatch({
    type: StopsActions.SET_CURRENT_ID_TRIP_STOP,
    payload: tripIdForStop,
  });
};
