import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../style.less";
import EnvironmentContext from "environment-context";

//COMPONENTS
import PmvListTable from "components/layout/tables/pmv-table/pmv-list-table";
import PmvHistoryTable from "components/layout/tables/pmv-table/pmv-history-table";

class PmvTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "list",
      selectedPmv: null,
    };
  }

  changeSelectedTab = (tab) => {
    this.setState({
      ...this.state,
      selectedTab: tab,
    });
  };

  changeSelectedPmvAndTab = (pmv, tab) => {
    this.setState({
      ...this.state,
      selectedTab: tab,
      selectedPmv: pmv,
    });
  };

  render = () => {
    const { selectedTab, selectedPmv } = this.state;
    return (
      <div className="acr-table">
        {selectedTab === "history" && selectedPmv ? (
          <PmvHistoryTable
            changeSelectedTab={this.changeSelectedTab}
            changeSelectedPmvAndTab={this.changeSelectedPmvAndTab}
            selectedTab={selectedTab}
            selectedPmv={selectedPmv}
          />
        ) : (
          <PmvListTable
            changeSelectedTab={this.changeSelectedTab}
            changeSelectedPmvAndTab={this.changeSelectedPmvAndTab}
            selectedTab={selectedTab}
            selectedPmv={selectedPmv}
            resetSelectedPmvAndTab={this.resetSelectedPmvAndTab}
          />
        )}
      </div>
    );
  };
}

PmvTable.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PmvTable)
);
