import { CompositeLayer, IconLayer } from "deck.gl";
import IconClusterCameraLayer from "./icon-cluster-camera-layer";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";

//ASSETS
import iconMapping from "assets/cluster/location-icon-mapping-2-events.json";
import alarm_icon from "assets/icon/installations/alarm_icon.svg";

class WebcamsLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      layout,
      visible,
      onClick,
      camCurrentReducer,
      isDrawingMode,
      viewport,
    } = this.props;

    const ICON_ALARM_MAPPING = {
      marker: {
        x: 0,
        y: 0,
        width: 49,
        height: 49,
        anchorY: 80,
        anchorX: 13,
      },
    };

    let activeAlarm = [];

    data.forEach((item) => {
      const alarmData =
        item &&
        item.properties &&
        item.properties.cctvWithLastStateAndOpenAlarms &&
        item.properties.cctvWithLastStateAndOpenAlarms.open_alarms &&
        item.properties.cctvWithLastStateAndOpenAlarms.open_alarms.length > 0;

      if (alarmData) {
        activeAlarm.push(item);
      }
    });

    if (viewport.zoom <= 12 && data && data.length >= 1) {
      return new IconClusterCameraLayer(
        this.getSubLayerProps({
          id: "",
          data,
          pickable: false,
          getPosition: (d) => [
            d &&
              d.properties &&
              d.properties.cctvWithLastStateAndOpenAlarms &&
              d.properties.cctvWithLastStateAndOpenAlarms.cctv &&
              d.properties.cctvWithLastStateAndOpenAlarms.cctv.longitude,
            d &&
              d.properties &&
              d.properties.cctvWithLastStateAndOpenAlarms &&
              d.properties.cctvWithLastStateAndOpenAlarms.cctv &&
              d.properties.cctvWithLastStateAndOpenAlarms.cctv.latitude,
          ],
          iconAtlas: require("assets/cluster/location-icon-atlas-2-white.png"),
          iconMapping,
          sizeScale: 6,
        })
      );
    } else {
      return [
        new IconLayer(
          this.getSubLayerProps({
            id: "icon-webcams",
            data: data,
            pickable: isDrawingMode ? false : true,
            ...DeckLayerUtil.loadOptions(),
            getIcon: (obj) => ({
              url: DeckLayerUtil.getIconWebcam(obj, layout, camCurrentReducer),
              width: 80,
              height: 80,
              anchorX: 54,
              anchorY: 90,
            }),
            sizeScale: 6,
            showCluster: false,
            visible: visible,
            onClick: onClick,
            getPosition: (d) => [
              d &&
                d.properties &&
                d.properties.cctvWithLastStateAndOpenAlarms &&
                d.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                d.properties.cctvWithLastStateAndOpenAlarms.cctv.longitude,
              d &&
                d.properties &&
                d.properties.cctvWithLastStateAndOpenAlarms &&
                d.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                d.properties.cctvWithLastStateAndOpenAlarms.cctv.latitude,
            ],
            getSize: (data) => (data && data.selected ? 12 : 8),
          })
        ),

        new IconLayer(
          this.getSubLayerProps({
            id: id + "alarm-icon",
            data: activeAlarm,
            pickable: isDrawingMode ? false : true,
            ...DeckLayerUtil.loadOptions(),
            getIcon: (data) => "marker",
            sizeScale: 6,
            showCluster: false,
            visible: visible,
            getPosition: (d) => [
              d &&
                d.properties &&
                d.properties.cctvWithLastStateAndOpenAlarms &&
                d.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                d.properties.cctvWithLastStateAndOpenAlarms.cctv.longitude,
              d &&
                d.properties &&
                d.properties.cctvWithLastStateAndOpenAlarms &&
                d.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                d.properties.cctvWithLastStateAndOpenAlarms.cctv.latitude,
            ],
            iconAtlas: alarm_icon,
            iconMapping: ICON_ALARM_MAPPING,
            getSize: (data) => (data && data.selected ? 12 : 8),
          })
        ),
      ];
    }
  }
}

WebcamsLayer.layerName = "WebcamsLayer";

export default WebcamsLayer;
