export const INITIAL_STATE_USER = {
  permissions: [],
  username: "",
  roles: [],
  activity: [],
};

export class UserModel {
  role;
  username;

  constructor() {
    this.roles = null;
    this.username = null;
  }

  static fromREST(obj) {
    let user = new UserModel();
    user.role = obj.role ? obj.role : [];
    user.username = obj.username ? obj.username : null;
    return user;
  }
}

export class CategoryModel {
  categoryName;
  subCategoryName;

  constructor() {
    this.categoryName = null;
    this.subCategoryName = null;
  }

  static fromREST(obj) {
    let cat = new CategoryModel();
    cat.categoryName = obj.categoryName ? obj.categoryName : null;
    cat.subCategoryName = obj.subCategoryName ? obj.subCategoryName : null;
    return cat;
  }
}

export class ValueModel {
  value;
  lang;

  constructor() {
    this.value = null;
    this.lang = null;
  }

  static fromREST(obj) {
    let val = new ValueModel();
    val.value = obj.value ? obj.value : null;
    val.lang = obj.lang ? obj.lang : null;
    return val;
  }

  static fromArrayREST(data) {
    return data.map(ValueModel.fromREST).filter((item) => item !== null);
  }
}

export class ListOfValuesModel {
  values;

  constructor() {
    this.values = null;
  }

  static fromREST(obj) {
    let val = new ListOfValuesModel();
    val.values = obj.values ? ValueModel.fromArrayREST(obj.values) : [];
    return val;
  }
}

export class UserActivityModel {
  activityId;
  activityCreationTime;
  module;
  user;
  category;
  tags;
  activityType;
  actionType;
  sourceName;
  details;
  comment;

  constructor() {
    this.activityId = null;
    this.activityCreationTime = null;
    this.module = null;
    this.user = UserModel;
    this.category = CategoryModel;
    this.tags = [];
    this.activityType = null;
    this.actionType = null;
    this.sourceName = null;
    this.details = ListOfValuesModel;
    this.comment = ListOfValuesModel;
  }

  static fromREST(obj) {
    let activity = new UserActivityModel();
    activity.activityId = obj.activityId ? obj.activityId : null;
    activity.activityCreationTime = obj.activityCreationTime
      ? obj.activityCreationTime
      : null;
    activity.module = obj.module ? obj.module : null;
    activity.user = obj.user ? UserModel.fromREST(obj.user) : null;
    activity.category = obj.category
      ? CategoryModel.fromREST(obj.category)
      : null;
    activity.tags = obj.tags ? obj.tags : [];
    activity.activityType = obj.activityType ? obj.activityType : null;
    activity.actionType = obj.actionType ? obj.actionType : null;
    activity.sourceName = obj.sourceName ? obj.sourceName : null;
    activity.details = obj.details
      ? ListOfValuesModel.fromREST(obj.details)
      : null;
    activity.comment = obj.comment
      ? ListOfValuesModel.fromREST(obj.comment)
      : null;
    return activity;
  }

  static fromArrayREST(data) {
    return data.map(UserActivityModel.fromREST).filter((item) => item !== null);
  }
}
