import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";
import NavItemNetworkGraph from "./nav-item-network-graph";
import NavItemNetworkReteRdsTmc from "./nav-item-network-rete-rds-tmc";

//ACTIONS
import {
  toggleNavGraph,
  toggleNavNetworkRDSTMC,
} from "reducers/ui/nav/nav.actions";
import {
  setNavTabMultitab,
  toggleOpenNavTab,
  setNavTabSelectedTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { setNetworkRdsTmcVisibility } from "reducers/ui/graph-menu/graph-menu.actions";
import { resetCurrentPointMap } from "reducers/graph/points/points.actions";
import { resetCurrentSegmentMap } from "reducers/graph/segments/segments.actions";
import { setGraphVisibility } from "reducers/ui/graph-menu/graph-menu.actions";
import { resetCurrentArcMap } from "reducers/graph/arcs/arcs.actions";
import { resetCurrentCentroidMap } from "reducers/graph/centroids/centroids.actions";
import { resetCurrentNodeMap } from "reducers/graph/nodes/nodes.actions";
//STORE
import {
  isOpenNavGraph,
  isOpenNavNetworkRdsTmc,
  isNetworkRdsTmcVisible,
  isArcVisible,
  isNodeVisible,
  isCentroidVisible,
  areAllGraphVisible,
} from "store";

class NavItemNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  clickOnReteRdsTmcLabel = () => {
    const {
      goToNetwork,
      setNavTabMultitab,
      isNetworkRdsTmcVisible,
      toggleOpenNavTab,
    } = this.props;

    setNavTabMultitab("Rete RDS-TMC");
    goToNetwork("rete-rds-tmc");

    if (!isNetworkRdsTmcVisible) {
      toggleOpenNavTab(false);
    }
  };

  clickOnReteRdsTmcCheckbox = () => {
    const {
      toggleOpenNavTab,
      isNetworkRdsTmcVisible,
      setNetworkRdsTmcVisibility,
      resetCurrentPointMap,
      resetCurrentSegmentMap,
    } = this.props;

    this.clickOnReteRdsTmcLabel();
    setNetworkRdsTmcVisibility({ all: !isNetworkRdsTmcVisible });
    toggleOpenNavTab(!isNetworkRdsTmcVisible);

    if (isNetworkRdsTmcVisible) {
      resetCurrentPointMap();
      resetCurrentSegmentMap();
    }
  };

  clickOnGrafoLabel = () => {
    const {
      goToNetwork,
      isOpenNavGraph,
      toggleNavGraph,
      setNavTabMultitab,
    } = this.props;

    toggleNavGraph(!isOpenNavGraph);
    setNavTabMultitab("Grafo Stradale");
    goToNetwork("graph");
  };

  clickOnGrafoCheckbox = (name) => {
    const {
      toggleOpenNavTab,
      allGraphChecked,
      setGraphVisibility,
      arcChecked,
      nodeChecked,
      centroidChecked,
      toggleNavGraph,
      setNavTabMultitab,
      goToNetwork,
      isOpenNavGraph,
      setNavTabSelectedTab,
      resetCurrentArcMap,
      resetCurrentCentroidMap,
      resetCurrentNodeMap,
    } = this.props;

    setNavTabMultitab("Grafo Stradale");
    goToNetwork("graph");

    if (name === "all") {
      if (!isOpenNavGraph && !allGraphChecked) {
        toggleNavGraph(true);
      } else if (isOpenNavGraph && allGraphChecked) {
        toggleNavGraph(false);
      }
      toggleOpenNavTab(!arcChecked || !nodeChecked || !centroidChecked);
      setGraphVisibility({
        all: !allGraphChecked,
        arc: !allGraphChecked,
        node: !allGraphChecked,
        centroid: !allGraphChecked,
      });
      setNavTabSelectedTab(0);
      resetCurrentArcMap();
      resetCurrentNodeMap();
      resetCurrentCentroidMap();
    } else if (name === "arc") {
      toggleOpenNavTab(!(arcChecked && !nodeChecked && !centroidChecked));
      toggleNavGraph(!(arcChecked && !nodeChecked && !centroidChecked));
      setNavTabSelectedTab(0);
      setGraphVisibility({
        all: !arcChecked && nodeChecked && centroidChecked,
        arc: !arcChecked,
        node: nodeChecked,
        centroid: centroidChecked,
      });
      // IF ARC DE-CHECKED AND OTHER VALUES ARE CHECKED, SELECT ONE OF THEIR TABS
      if (arcChecked && (nodeChecked || centroidChecked)) {
        if (nodeChecked) {
          setNavTabSelectedTab(1);
        } else {
          setNavTabSelectedTab(2);
        }
      }
      if (arcChecked) {
        resetCurrentArcMap();
      }
    } else if (name === "node") {
      toggleOpenNavTab(!(!arcChecked && nodeChecked && !centroidChecked));
      toggleNavGraph(!(!arcChecked && nodeChecked && !centroidChecked));
      setNavTabSelectedTab(1);
      setGraphVisibility({
        all: !nodeChecked && arcChecked && centroidChecked,
        arc: arcChecked,
        node: !nodeChecked,
        centroid: centroidChecked,
      });
      // IF CENTROID DE-CHECKED AND OTHER VALUES ARE CHECKED, SELECT ONE OF THEIR TABS
      if (nodeChecked && (arcChecked || centroidChecked)) {
        if (arcChecked) {
          setNavTabSelectedTab(0);
        } else {
          setNavTabSelectedTab(2);
        }
      }
      if (nodeChecked) {
        resetCurrentNodeMap();
      }
    } else if (name === "centroid") {
      toggleOpenNavTab(!(!arcChecked && !nodeChecked && centroidChecked));
      toggleNavGraph(!(!arcChecked && !nodeChecked && centroidChecked));
      setNavTabSelectedTab(2);
      setGraphVisibility({
        all: !centroidChecked && arcChecked && nodeChecked,
        arc: arcChecked,
        node: nodeChecked,
        centroid: !centroidChecked,
      });
      // IF NODE DE-CHECKED AND OTHER VALUES ARE CHECKED, SELECT ONE OF THEIR TABS
      if (centroidChecked && (arcChecked || nodeChecked)) {
        if (arcChecked) {
          setNavTabSelectedTab(0);
        } else {
          setNavTabSelectedTab(1);
        }
      }
      if (centroidChecked) {
        resetCurrentCentroidMap();
      }
    }
  };

  render() {
    const {
      checked,
      location,
      onClickNetwork,
      arcChecked,
      nodeChecked,
      centroidChecked,
      isNetworkRdsTmcVisible,
    } = this.props;
    const isActive = location.pathname.includes("network");

    return (
      <li className={isActive ? "uk-parent uk-active" : "uk-parent"}>
        <a onClick={onClickNetwork} href="/#">
          <span
            uk-icon="icon: acr-menu-road-network; ratio: 1.4;"
            className={`uk-icon ${
              arcChecked ||
              nodeChecked ||
              centroidChecked ||
              isNetworkRdsTmcVisible
                ? "yellow-icon"
                : ""
            }`}
          ></span>
          Rete Stradale
        </a>
        {checked ? (
          <ul className="uk-nav-sub uk-margin-small-top acr-transport-nav-sub">
            <div className="uk-checkbox-tree-large uk-margin-right acr-transport-checkbox-tree">
              {isActive && (
                <div className="uk-parent">
                  {/* GRAPH */}
                  <NavItemNetworkGraph
                    clickOnGrafoLabel={this.clickOnGrafoLabel}
                    clickOnGrafoCheckbox={this.clickOnGrafoCheckbox}
                  />
                  {/* RETE */}
                  <NavItemNetworkReteRdsTmc
                    clickOnReteRdsTmcLabel={this.clickOnReteRdsTmcLabel}
                    clickOnReteRdsTmcCheckbox={this.clickOnReteRdsTmcCheckbox}
                  />
                </div>
              )}
            </div>
          </ul>
        ) : null}
      </li>
    );
  }
}

NavItemNetwork.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleNavGraph,
  toggleNavNetworkRDSTMC,
  setNavTabMultitab,
  toggleOpenNavTab,
  setNetworkRdsTmcVisibility,
  resetCurrentPointMap,
  resetCurrentSegmentMap,
  setGraphVisibility,
  setNavTabSelectedTab,
  resetCurrentCentroidMap,
  resetCurrentArcMap,
  resetCurrentNodeMap,
};

const mapStateToProps = (state) => ({
  isOpenNavGraph: isOpenNavGraph(state),
  isOpenNavNetworkRdsTmc: isOpenNavNetworkRdsTmc(state),
  isNetworkRdsTmcVisible: isNetworkRdsTmcVisible(state),
  arcChecked: isArcVisible(state),
  nodeChecked: isNodeVisible(state),
  centroidChecked: isCentroidVisible(state),
  allGraphChecked: areAllGraphVisible(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavItemNetwork)
);
