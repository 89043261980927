export const MapActions = {
  RESET_LAYERS: "MAP/RESET_LAYERS",
  SET_POLYGON: "MAP/SET_POLYGON",
  TOGGLE_CHANGE_LAYER: "MAP/TOGGLE_CHANGE_LAYER",
  TOGGLE_LEGEND: "MAP/TOGGLE_LEGEND",
  TOGGLE_SEARCH_STREET: "MAP/TOGGLE_SEARCH_STREET",
  TOGGLE_DISTANCE_SELECTED: "MAP/TOGGLE_DISTANCE_SELECTED",
  TOGGLE_RULER_SELECTED: "MAP/TOGGLE_RULER_SELECTED",
  TOGGLE_CIRCLE_SELECTED: "MAP/TOGGLE_CIRCLE_SELECTED",
  TOGGLE_POLYGON_SELECTED: "MAP/TOGGLE_POLYGON_SELECTED",
  RESET_TOOLTIP_SELECTED: "map/RESET_TOOLTIP_SELECTED",
  HISTORY_PREV: "MAP/HISTORY_PREV",
  HISTORY_NEXT: "MAP/HISTORY_NEXT",
  SET_GEOJSON: "MAP/SET_GEOJSON",
  RESET_GEOJSON: "MAP/RESET_GEOJSON",
  SET_SELECTED_FEATURE_INDEX: "MAP/SET_SELECTED_FEATURE_INDEX",
  RESET_SELECTED_FEATURE_INDEX: "MAP/RESET_SELECTED_FEATURE_INDEX",
  SET_LAYOUT: "MAP/SET_LAYOUT",
  ADD_ALL_LINES: "MAP/ADD_ALL_LINES",
  FILTER_BY_BOUNDS: "MAP/FILTER_BY_BOUNDS",
  TOGGLE_MAP_CONTROLS: "MAP/TOGGLE_MAP_CONTROLS",
  SET_HOVERED_BUS: "MAP/SET_HOVERED_BUS",
  SET_HOVERED_STOP: "MAP/SET_HOVERED_STOP",
  SET_HOVERED_EVENT: "MAP/SET_HOVERED_EVENT",
  SET_HOVERED_STATION: "MAP/SET_HOVERED_STATION",
  SET_HOVERED_INSTALLATION: "MAP/SET_HOVERED_INSTALLATION",
  SET_HOVERED_PMV: "MAP/SET_HOVERED_PMV",
  SET_HOVERED_MULTIPLE_DISTANCE: "MAP/SET_HOVERED_MULTIPLE_DISTANCE",
  SET_HOVERED_SHORTEST_DISTANCE_ON_GRAPH:
    "MAP/SET_HOVERED_SHORTEST_DISTANCE_ON_GRAPH",
  RESET_HOVERED_OBJECTS: "MAP/RESET_HOVERED_OBJECTS",
  SET_MULTIPLE_DISTANCE: "MAP/SET_MULTIPLE_DISTANCE",
  RESET_MULTIPLE_DISTANCE: "MAP/RESET_MULTIPLE_DISTANCE",
  RESET_ALL_MAP: "MAP/RESET_ALL_MAP",
  SAVE_FIGURE: "MAP/SAVE_FIGURE",
  SET_MODE: "MAP/SET_MODE",
  SET_BOUNDS: "MAP/SET_BOUNDS",
  SET_SHORTEST_DISTANCE_ON_GRAPH: "MAP/SET_SHORTEST_DISTANCE_ON_GRAPH",
  RESET_SHORTEST_DISTANCE_ON_GRAPH: "MAP/RESET_SHORTEST_DISTANCE_ON_GRAPH",
  SET_HOVERED_RDS_TMC_POINT: "MAP/SET_HOVERED_RDS_TMC_POINT",
  SET_CURRENT_ROUTES_FOR_STOP_SELECTED:
    "map/SET_CURRENT_ROUTES_FOR_STOP_SELECTED",
  SET_CURRENT_MAP_ZOOM: "MAP/SET_CURRENT_MAP_ZOOM",
  SET_HOVERED_PARKING: "MAP/SET_HOVERED_PARKING",
  SET_HOVERED_CAMERA: "MAP/SET_HOVERED_CAMERA",
};

export const addAllLines = (lines) => (dispatch) => {
  dispatch({
    type: MapActions.ADD_ALL_LINES,
    payload: { lines },
  });
};

export const mapResetLayer = () => (dispatch) => {
  dispatch({
    type: MapActions.RESET_LAYERS,
    payload: null,
  });
};

export const setPolygon = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_POLYGON,
    payload: data,
  });
};

export const toggleTabChangeLayer = () => (dispatch) => {
  dispatch({
    type: MapActions.TOGGLE_CHANGE_LAYER,
  });
};
export const toggleDistanceSelected = () => (dispatch) => {
  dispatch({
    type: MapActions.TOGGLE_DISTANCE_SELECTED,
  });
};
export const toggleRulerSelected = () => (dispatch) => {
  dispatch({
    type: MapActions.TOGGLE_RULER_SELECTED,
  });
};
export const toggleCircleSelected = () => (dispatch) => {
  dispatch({
    type: MapActions.TOGGLE_CIRCLE_SELECTED,
  });
};
export const togglePolygonSelected = () => (dispatch) => {
  dispatch({
    type: MapActions.TOGGLE_POLYGON_SELECTED,
  });
};
export const toggleTabLegend = () => (dispatch) => {
  dispatch({
    type: MapActions.TOGGLE_LEGEND,
  });
};
export const toggleTabSearchStreet = () => (dispatch) => {
  dispatch({
    type: MapActions.TOGGLE_SEARCH_STREET,
  });
};
export const resetTooltipSelected = () => (dispatch) => {
  dispatch({
    type: MapActions.RESET_TOOLTIP_SELECTED,
  });
};

export const historyPrev = () => (dispatch) => {
  dispatch({
    type: MapActions.HISTORY_PREV,
  });
};

export const historyNext = () => (dispatch) => {
  dispatch({
    type: MapActions.HISTORY_NEXT,
  });
};

export const setGeoJsonObj = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_GEOJSON,
    payload: data,
  });
};

export const resetGeoJsonObj = () => (dispatch) => {
  dispatch({
    type: MapActions.RESET_GEOJSON,
  });
};
export const setSelectedFeatureIndexes = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_SELECTED_FEATURE_INDEX,
    payload: data,
  });
};

export const resetSelectedFeatureIndexes = () => (dispatch) => {
  dispatch({
    type: MapActions.RESET_SELECTED_FEATURE_INDEX,
  });
};

export const setLayout = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_LAYOUT,
    payload: data,
  });
};

export const filterByMapBounds = (bounds) => (dispatch) => {
  dispatch({
    type: MapActions.FILTER_BY_BOUNDS,
    payload: { bounds },
  });
};

export const toggleMapControls = () => (dispatch) => {
  dispatch({
    type: MapActions.TOGGLE_MAP_CONTROLS,
  });
};
export const setHoveredBus = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_BUS,
    payload: data,
  });
};

export const setHoveredEvent = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_EVENT,
    payload: data,
  });
};
export const setHoveredStation = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_STATION,
    payload: data,
  });
};

export const setHoveredStop = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_STOP,
    payload: data,
  });
};

export const setHoveredInstallation = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_INSTALLATION,
    payload: data,
  });
};

export const setHoveredMultipleDistance = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_MULTIPLE_DISTANCE,
    payload: data,
  });
};

export const setHoveredShortestDistanceOnGraph = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_SHORTEST_DISTANCE_ON_GRAPH,
    payload: data,
  });
};

export const setHoveredRdsTmcPoint = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_RDS_TMC_POINT,
    payload: data,
  });
};

export const resetHoveredObjects = (data) => (dispatch) => {
  dispatch({
    type: MapActions.RESET_HOVERED_OBJECTS,
    payload: data,
  });
};
export const setMultipleDistance = (obj) => (dispatch) => {
  dispatch({
    type: MapActions.SET_MULTIPLE_DISTANCE,
    payload: obj,
  });
};
export const resetMultipleDistance = () => (dispatch) => {
  dispatch({
    type: MapActions.RESET_MULTIPLE_DISTANCE,
  });
};

export const resetAllMap = () => (dispatch) => {
  dispatch({
    type: MapActions.RESET_ALL_MAP,
  });
};
export const saveFigure = () => (dispatch) => {
  dispatch({
    type: MapActions.SAVE_FIGURE,
  });
};
export const setMode = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_MODE,
    payload: data,
  });
};

export const setBounds = (data, zoom) => (dispatch) => {
  dispatch({
    type: MapActions.SET_BOUNDS,
    payload: { data: data, zoom: zoom },
  });
};

export const setShortestDistanceOnGraph = (obj) => (dispatch) => {
  dispatch({
    type: MapActions.SET_SHORTEST_DISTANCE_ON_GRAPH,
    payload: obj,
  });
};
export const resetShortestDistanceOnGraph = () => (dispatch) => {
  dispatch({
    type: MapActions.RESET_SHORTEST_DISTANCE_ON_GRAPH,
  });
};

export const setCurrentRoutesMapForStopSelected = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_CURRENT_ROUTES_FOR_STOP_SELECTED,
    payload: data,
  });
};

export const setHoveredParking = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_PARKING,
    payload: data,
  });
};

export const setHoveredCamera = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_CAMERA,
    payload: data,
  });
};

export const setHoveredPMV = (data) => (dispatch) => {
  dispatch({
    type: MapActions.SET_HOVERED_PMV,
    payload: data,
  });
};
