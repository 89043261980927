import React, { Component } from "react";
import "../style.less";

//UTILS
import { PMV_STATUS } from "utils/utils-pmv";
class TableBody extends Component {
  render = () => {
    const { tableColumns, list } = this.props;

    return list.map((item, ind) => {
      const statusIcon = PMV_STATUS.find(
        (status) =>
          item &&
          item.statoFunzionamento &&
          typeof item.statoFunzionamento === "string" &&
          status.name.toLowerCase() === item.statoFunzionamento.toLowerCase()
      );

      return (
        <tr key={"column-tr-table-body" + ind}>
          {tableColumns &&
            Object.values(tableColumns).map((t, i) => {
              if (i !== 0) {
                return (
                  <td
                    uk-tooltip={
                      t.id !== "pmvGif" && t.id !== "pmvHistoryActions"
                        ? "title: " + Object.values(item)[i - 1]
                        : t.id === "pmvHistoryActions"
                        ? "Apertura Storico"
                        : null
                    }
                    key={"column-td-table-body" + i}
                    className={
                      tableColumns[t.id] && !tableColumns[t.id].show
                        ? "displayNone"
                        : ""
                    }
                  >
                    <div
                      className={
                        t.id === "statoFunzionamento" &&
                        statusIcon &&
                        statusIcon.value === 3
                          ? "sensore-guasto"
                          : t.id === "statoFunzionamento" &&
                            statusIcon &&
                            statusIcon.value === 4
                          ? "sensore-no-info"
                          : t.id === "statoFunzionamento" &&
                            statusIcon &&
                            statusIcon.value === 2
                          ? "sensore-disconnesso"
                          : t.id === "statoFunzionamento" &&
                            statusIcon &&
                            statusIcon.value === 1
                          ? "sensore-funzionante"
                          : t.id === "pmvGif"
                          ? "gif-table-padding"
                          : ""
                      }
                    >
                      {Object.values(item)[i - 1]}
                    </div>
                  </td>
                );
              }
              return null;
            })}
        </tr>
      );
    });
  };
}

export default TableBody;
