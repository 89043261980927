import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
//ACTIONS
import {
  getNewEvent,
  getNewEventValid,
  getCurrentEvent,
  getTypeModal,
  getBoundingBoxCartografica,
} from "store";
import { setNewEvent, updateNewEvent } from "reducers/modal/modal.actions";
import {
  enableForwardEventButton,
  disableForwardEventButton,
} from "reducers/ui/modal/modal.actions";
//COMPONENT
import ModalMap from "components/layout/modal/event/new-event/modal-map";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
//PARAMETRI SELEZIONABILI
import { selectFields } from "enums/selectable-params";
// MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";
import { debounce } from "utils/utils";

class LocalizationRDSPuntuale extends Component {
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {
      modalMapWidth: false,
      modalMapHalfWidth: true,
      statePointsListMap: [],
      pointsListPercorso: [],
      segmentsList: [],
      isSegmentsLoading: false,
      isPointsLoading: false,
      currentInitialPoint: null,
      directionsArray: [],
      maxOffset: 0,
    };
    this.changeDistanceDebounced = debounce(this.changeDistance, 1200);
  }

  componentDidMount = () => {
    const selectReteRdsTmc = document.getElementById("rete-rds-puntuale");
    this.apiService = createApiService(this.context);

    selectFields.RETE_RDS_TMC.properties.forEach(
      (item, index) =>
        (selectReteRdsTmc.options[selectReteRdsTmc.options.length] = new Option(
          selectFields.RETE_RDS_TMC.properties[index].name,
          index
        ))
    );

    this.getApiRoadsList();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { statePointsListMap } = this.state;
    const { typeModal, setNewEvent, newEvent, pointsListMap } = this.props;

    if (
      prevProps.pointsListMap &&
      pointsListMap &&
      prevProps.pointsListMap.length !== pointsListMap.length
    ) {
      this.setState({
        ...this.state,
        statePointsListMap: pointsListMap,
      });
    }

    if (
      prevProps.newEvent.streetRDSPuntuale !== newEvent.streetRDSPuntuale &&
      newEvent.streetRDSPuntuale !== ""
    ) {
      this.resetEvent("street");
      this.resetState("street");
      this.setState(
        {
          ...this.state,
          directionsArray: [],
        },
        () => {
          this.updateDirezioneList();
          this.getApiPointsListMap();
        }
      );
    }

    if (
      prevProps.newEvent.streetRDSPuntuale !== newEvent.streetRDSPuntuale &&
      (newEvent.streetRDSPuntuale === "" || newEvent.streetRDSPuntuale === "-")
    ) {
      this.resetEvent("street");
      this.resetState("street");
      this.setState(
        {
          ...this.state,
          statePointsListMap: [],
          pointsListPercorso: [],
          currentInitialPoint: null,
          directionsArray: [],
          maxOffset: 0,
        },
        () => {
          this.updateDirezioneList();
          this.getApiPointsListMap();
        }
      );
    }

    if (
      prevProps.newEvent.directionRDSPuntualeCoded !==
        newEvent.directionRDSPuntualeCoded &&
      newEvent.directionRDSPuntualeCoded !== ""
    ) {
      this.resetEvent("direction");
      this.resetState("direction");
      this.getApiPointsListPercorso();
    }

    if (
      prevProps.newEvent.directionRDSPuntualeCoded !==
        newEvent.directionRDSPuntualeCoded &&
      (newEvent.directionRDSPuntualeCoded === "" ||
        newEvent.directionRDSPuntualeCoded === "-")
    ) {
      this.resetEvent("direction");
      this.resetState("direction");
    }

    if (
      prevProps.newEvent.initialPointRDSPuntuale !==
        newEvent.initialPointRDSPuntuale &&
      newEvent.initialPointRDSPuntuale !== ""
    ) {
      this.resetEvent("point");
      this.resetState("point");

      let point = statePointsListMap.find(
        (item) =>
          item.properties.lcd === Number(newEvent.initialPointRDSPuntuale)
      );

      if (point) {
        this.setState({
          ...this.state,
          currentInitialPoint: point,
        });
        setNewEvent("pointCoordinatesAfterMoving", [
          ...point.geometry.coordinates,
        ]);
        setNewEvent("initialPointRDSPuntualeName", point.properties.name1);
      } else {
        this.setState({
          ...this.state,
          currentInitialPoint: null,
        });
        setNewEvent("pointCoordinatesAfterMoving", []);
        setNewEvent("initialPointRDSPuntualeName", "");
      }
      this.updateRadiusOfInfluence();
      this.updateDistanceFromCenter();
    }

    if (
      prevProps.newEvent.distanceFromInitialPointRDSPuntuale !==
        newEvent.distanceFromInitialPointRDSPuntuale &&
      newEvent.directionRDSPuntualeCoded !== ""
    ) {
      this.changeDistanceDebounced();
    }

    if (
      newEvent.initialPointRDSPuntuale &&
      prevProps.newEvent.initialPointRDSPuntuale !==
        newEvent.initialPointRDSPuntuale
    ) {
      this.changeDistanceDebounced();
    }

    if (
      typeModal === "modifyEvent" &&
      (prevProps.newEvent.arcListRDSPuntuale.length !==
        this.props.newEvent.arcListRDSPuntuale.length ||
        (newEvent.initialPointRDSPuntuale &&
          prevProps.newEvent.initialPointRDSPuntuale !==
            newEvent.initialPointRDSPuntuale))
    ) {
      setNewEvent("checkIfEventIsModified", true);
    }
  };

  getApiRoadsList = () => {
    this.setState({
      ...this.state,
      isSegmentsLoading: true,
    });

    let filter = "have_network=true";
    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures("roads", null, null, null, null, null, filter)
        .pipe(
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "roads"
            );
            let segmentsList = [];
            if (featureCollection.numberReturned > 0) {
              segmentsList = [...featureCollection.features];
            }
            return this.setState(
              {
                ...this.state,
                isSegmentsLoading: false,
                segmentsList: segmentsList,
              },
              () => {
                this.updateStreetList();
                this.getApiPointsListMap();
              }
            );
          }),
          catchError((error) => {
            console.error(error);
            this.setState({
              ...this.state,
              isSegmentsLoading: false,
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  //API CALL TO POINTS LIST
  getApiPointsListMap = () => {
    const { newEvent, pointsListMap } = this.props;
    const { segmentsList } = this.state;

    let filter;
    if (
      newEvent &&
      newEvent.streetRDSPuntuale &&
      newEvent.streetRDSPuntuale !== "" &&
      newEvent.streetRDSPuntuale !== "-"
    ) {
      let street = segmentsList.find(
        (item) =>
          item.properties.road_lcd === Number(newEvent.streetRDSPuntuale)
      );
      if (street) {
        filter = "road_lcd=" + street.properties.road_lcd;

        this.subscriptions.push(
          this.apiService
            .getGeoserverFeatures(
              "points",
              null,
              null,
              null,
              null,
              null,
              filter
            )
            .pipe(
              tap((data) => {
                let featureCollection = FeatureCollectionModel.fromREST(
                  data,
                  "points"
                );
                let points = [];
                if (featureCollection.numberReturned > 0) {
                  points = [...featureCollection.features];
                }

                return this.setState(
                  {
                    ...this.state,
                    statePointsListMap: points,
                  },
                  this.updateDistanceFromCenter
                );
              }),
              catchError((error) => {
                console.error(error);
                return of(error);
              })
            )
            .subscribe()
        );
      }
    } else {
      this.setState({
        ...this.state,
        statePointsListMap: pointsListMap,
      });
    }
  };

  getApiPointsListPercorso = () => {
    const { newEvent } = this.props;
    const { directionsArray } = this.state;

    let direction = directionsArray.find(
      (item) => item.value === newEvent.directionRDSPuntualeCoded
    );

    if (
      newEvent &&
      newEvent.streetRDSPuntuale &&
      direction &&
      direction.value &&
      direction.value !== "-"
    ) {
      this.setState({
        ...this.state,
        isPointsLoading: true,
      });
      let direzione = direction.value === "BOTH" ? "POSITIVE" : direction.value;

      this.subscriptions.push(
        this.apiService
          .getRouteDetailsOnSectionV2(direzione, newEvent.streetRDSPuntuale)
          .pipe(
            tap((data) => {
              let points = [...data];
              return this.setState(
                {
                  ...this.state,
                  isPointsLoading: false,
                  pointsListPercorso: points,
                },
                this.updatePointsList
              );
            }),
            catchError((error) => {
              console.error(error);
              this.setState({
                ...this.state,
                isPointsLoading: false,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    } else if (direction && direction.value === "-") {
      this.setState(
        {
          ...this.state,
          isPointsLoading: false,
          pointsListPercorso: [],
        },
        this.updatePointsList
      );
    }
  };

  removeOptions(selectElement) {
    while (selectElement.options.length > 0) {
      selectElement.remove(0);
    }
  }

  // ON SELECT NETWORK
  updateStreetList = () => {
    const { segmentsList } = this.state;
    const { newEvent } = this.props;
    const selectStradaRdsTmc = document.getElementById("strada-rds-puntuale");

    this.resetState("street");

    if (segmentsList.length > 0) {
      selectStradaRdsTmc.options[0] = new Option("-", undefined);

      segmentsList.forEach(
        (item) =>
          (selectStradaRdsTmc.options[
            selectStradaRdsTmc.options.length
          ] = new Option(
            item && item.properties && item.properties.name
              ? item.properties.name
              : "-",
            item.properties.road_lcd
          ))
      );
    }

    if (
      (newEvent.streetRDSPuntuale &&
        newEvent.streetRDSPuntuale !== "" &&
        newEvent.streetRDSPuntuale !== "-") ||
      (newEvent.directionRDSPuntualeCoded && newEvent.streetRDSPuntuale !== "")
    ) {
      selectStradaRdsTmc.value = newEvent.streetRDSPuntuale;
      this.updateDirezioneList();
      this.updateRadiusOfInfluence();
    }
  };

  updateDirezioneList = () => {
    const { newEvent } = this.props;
    const { segmentsList } = this.state;
    const selectDirezioneRdsTmc = document.getElementById(
      "direzione-rds-puntuale"
    );

    let street = segmentsList.find(
      (item) => item.properties.road_lcd === Number(newEvent.streetRDSPuntuale)
    );

    let directionsArray = [];
    if (street) {
      //CREATE AN ARRAY WITH TWO VALUES: START AND END ROAD OF TRATTA
      directionsArray = [
        { text: "-", value: undefined },
        { text: "Entrambe", value: "BOTH" },
        {
          text: street.properties.start_road,
          value: "NEGATIVE",
        },
        {
          text: street.properties.end_road,
          value: "POSITIVE",
        },
      ];
    }

    this.setState({
      ...this.state,
      directionsArray: [...directionsArray],
    });

    this.removeOptions(selectDirezioneRdsTmc);

    directionsArray.forEach(
      (item, index) =>
        (selectDirezioneRdsTmc.options[
          selectDirezioneRdsTmc.options.length
        ] = new Option(item.text, item.value))
    );

    if (
      newEvent.directionRDSPuntualeCoded !== undefined &&
      newEvent.directionRDSPuntualeCoded !== "" &&
      newEvent.directionRDSPuntualeCoded !== "-"
    ) {
      selectDirezioneRdsTmc.value = newEvent.directionRDSPuntualeCoded;
      //api call must be done AFTER directions have been loaded in state!
      this.setState(
        {
          ...this.state,
          directionsArray: [...directionsArray],
        },
        this.getApiPointsListPercorso
      );
    }
  };

  updatePointsList = () => {
    const { pointsListPercorso } = this.state;
    const { newEvent } = this.props;

    const selectInitialPointRdsTMc = document.getElementById(
      "initial-point-rds-puntuale"
    );

    if (pointsListPercorso.length > 0) {
      selectInitialPointRdsTMc.options[0] = new Option("-", undefined);

      pointsListPercorso.forEach(
        (item) =>
          (selectInitialPointRdsTMc.options[
            selectInitialPointRdsTMc.options.length
          ] = new Option(item.name1, item.lcd))
      );
    }

    if (
      newEvent.initialPointRDSPuntuale !== "" &&
      newEvent.initialPointRDSPuntuale !== "-"
    ) {
      selectInitialPointRdsTMc.value = newEvent.initialPointRDSPuntuale;
      this.updateRadiusOfInfluence();
      this.getInitialPoint(newEvent.initialPointRDSPuntuale);
    }
  };

  getInitialPoint = (initial) => {
    const { setNewEvent } = this.props;

    const initialPointCall = this.apiService.getGeoserverFeatures(
      "points",
      null,
      null,
      null,
      null,
      null,
      "lcd = " + initial
    );

    this.subscriptions.push(
      initialPointCall.subscribe((res) => {
        const initialPoint =
          res && res.features && res.features.length > 0
            ? res.features[0]
            : null;

        if (initialPoint) {
          this.setState(
            {
              ...this.state,
              currentInitialPoint: initialPoint,
            },
            () => {
              this.updateDistanceFromCenter();
              this.changeDistanceDebounced();
            }
          );
          setNewEvent(
            "initialPointRDSPuntualeName",
            initialPoint.properties.name1
          );
        } else {
          this.setState({
            ...this.state,
            currentInitialPoint: null,
          });
          setNewEvent("initialPointRDSPuntualeName", "");
        }
      })
    );
  };

  updateDistanceFromCenter = () => {
    const { newEvent } = this.props;
    const { pointsListPercorso } = this.state;

    let selected = pointsListPercorso.find(
      (item) => item.lcd === Number(newEvent.initialPointRDSPuntuale)
    );

    let previousPoint;
    let dir =
      selected && selected.direction
        ? selected.direction.toLowerCase() === "neg"
          ? "pos"
          : "neg"
        : "";
    if (
      selected &&
      typeof selected.distance_fe === "number" &&
      selected.distance_fe >= 0
    ) {
      previousPoint = dir
        ? pointsListPercorso.find((item) => {
            if (selected[dir + "_off_lcd"]) {
              return item.lcd === selected[dir + "_off_lcd"];
            } else {
              if (dir === "pos") {
                return item.lcd === selected["pos_off_lcd"];
              } else {
                return item.lcd === selected["neg_off_lcd"];
              }
            }
          })
        : null;

      const selectDistanceFromInitialPoint = document.getElementById(
        "distance-initial-point-rds-tmc"
      );

      let diffDistance;
      if (
        previousPoint &&
        typeof previousPoint.distance_fe === "number" &&
        previousPoint.distance_fe >= 0
      ) {
        diffDistance =
          selected.distance_fe > previousPoint.distance_fe
            ? selected.distance_fe - previousPoint.distance_fe
            : previousPoint.distance_fe - selected.distance_fe;
      } else {
        diffDistance = selected.distance_fe;
      }

      let maxDistance = Math.trunc(Math.abs(diffDistance) * 1000);
      this.setState(
        {
          ...this.state,
          maxOffset: maxDistance,
        },
        () => {
          if (
            newEvent &&
            newEvent.distanceFromInitialPointRDSPuntuale &&
            newEvent.distanceFromInitialPointRDSPuntuale !== ""
          ) {
            selectDistanceFromInitialPoint.value = Number(
              newEvent.distanceFromInitialPointRDSPuntuale
            );
          } else {
            selectDistanceFromInitialPoint.value = 0;
          }
        }
      );
      selectDistanceFromInitialPoint.setAttribute("max", maxDistance);
    }
  };

  updateRadiusOfInfluence = () => {
    const { newEvent } = this.props;

    const inputRadiusOfInfluenceRdsTmc = document.getElementById(
      "radius-of-influence-puntuale"
    );

    if (newEvent && newEvent.radiusInfluenceRDSPuntuale) {
      inputRadiusOfInfluenceRdsTmc.value = String(
        newEvent.radiusInfluenceRDSPuntuale
      );
    }
  };

  changeDistance = () => {
    const { newEvent, setNewEvent } = this.props;
    const { currentInitialPoint } = this.state;

    UIkit.notification.closeAll();
    if (currentInitialPoint) {
      this.setState({
        ...this.state,
        isDistanceLoading: true,
      });

      let direzione = newEvent.directionRDSPuntualeCoded;
      let distanza = newEvent.distanceFromInitialPointRDSPuntuale;
      let idPoint = currentInitialPoint.properties.lcd;
      let idRoad = currentInitialPoint.properties.road_lcd;

      this.subscriptions.push(
        this.apiService
          .getPointEventSectionV2(direzione, distanza, idPoint, idRoad)
          .pipe(
            tap((data) => {
              let coords;
              if (data.geometry && data.geometry.type === "Point") {
                coords = data.geometry.coordinates;
                setNewEvent("pointCoordinatesAfterMoving", coords);
                let newArcList = [];
                if (
                  data.properties.arc_list &&
                  data.properties.arc_list.length > 0 &&
                  data.properties.arc_list_brother &&
                  data.properties.arc_list_brother.length > 0
                ) {
                  newArcList = [
                    data.properties.arc_list[0],
                    data.properties.arc_list_brother[0],
                  ];
                } else if (
                  data.properties.arc_list &&
                  data.properties.arc_list.length > 0
                ) {
                  newArcList = [data.properties.arc_list[0]];
                }
                newArcList = newArcList.filter((item) => item !== null);
                setNewEvent("arcListRDSPuntuale", newArcList);
              }
            }),
            catchError((error) => {
              setNewEvent(
                "pointCoordinatesAfterMoving",
                currentInitialPoint.geometry.coordinates
              );
              UIkit.notification({
                message:
                  "Ci sono stati problemi nel caricamento del nodo corrispondente",
                status: "primary",
                pos: "bottom-center",
                timeout: 5000,
              });
              console.error(error);
              this.setState({
                ...this.state,
                isDistanceLoading: false,
              });
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  resetState = (string) => {
    const inputDistanceFromInitialPoint = document.getElementById(
      "distance-initial-point-rds-tmc"
    );
    const inputRadiusOfInfluence = document.getElementById(
      "radius-of-influence-puntuale"
    );
    const selectInitialPointRdsTMc = document.getElementById(
      "initial-point-rds-puntuale"
    );
    const selectDirezioneRdsTmc = document.getElementById(
      "direzione-rds-puntuale"
    );
    const selectStradaRdsTmc = document.getElementById("strada-rds-puntuale");

    if (string === "network" || string === "street" || string === "direction") {
      this.removeOptions(selectInitialPointRdsTMc);
      inputRadiusOfInfluence.value = 1000;
      inputDistanceFromInitialPoint.value = 0;
    }
    if (string === "network" || string === "street") {
      this.removeOptions(selectDirezioneRdsTmc);
    }
    if (string === "network") {
      this.removeOptions(selectStradaRdsTmc);
    }

    if (string === "point") {
      inputDistanceFromInitialPoint.value = 0;
    }
  };

  resetEvent = (string) => {
    const { setNewEvent } = this.props;

    if (string === "network") {
      setNewEvent("streetRDSPuntuale", "");
      setNewEvent("arcListRDSPuntuale", []);
    }
    if (string === "street" || string === "network") {
      setNewEvent("directionRDSPuntualeCoded", "");
      setNewEvent("directionRDSPuntualeName", "");
      setNewEvent("initialPointRDSPuntuale", "");
      setNewEvent("initialPointRDSPuntualeName", "");
      setNewEvent("distanceFromInitialPointRDSPuntuale", 0);
      setNewEvent("radiusInfluenceRDSPuntuale", 1000);
      setNewEvent("pointCoordinatesAfterMoving", []);
      setNewEvent("arcListRDSPuntuale", []);
    }
    if (string === "direction") {
      setNewEvent("pointCoordinatesAfterMoving", []);
      setNewEvent("initialPointRDSPuntualeName", "");
      setNewEvent("initialPointRDSPuntuale", "");
      setNewEvent("distanceFromInitialPointRDSPuntuale", 0);
      setNewEvent("radiusInfluenceRDSPuntuale", 1000);
      setNewEvent("arcListRDSPuntuale", []);
    }

    if (string === "point") {
      setNewEvent("distanceFromInitialPointRDSPuntuale", 0);
    }
  };

  handler = (e, name) => {
    const { setNewEvent } = this.props;
    const { statePointsListMap, directionsArray } = this.state;

    var handlervalue = e.target.value;
    if (handlervalue && handlervalue !== "") {
      setNewEvent(name, handlervalue);
    }

    if (handlervalue && name === "initialPointRDSPuntuale") {
      let point = statePointsListMap.find(
        (item) => item.properties.lcd === Number(handlervalue)
      );
      let cInitPoint = null;
      let pointCoordsAfterMoving = [];
      let initPointRDSPuntualeName = "";
      if (point) {
        cInitPoint = point;
        pointCoordsAfterMoving = [...point.geometry.coordinates];
        initPointRDSPuntualeName = point.properties.name1;
      } else {
        cInitPoint = null;
        pointCoordsAfterMoving = [];
        initPointRDSPuntualeName = "";
      }
      this.setState({
        ...this.state,
        currentInitialPoint: cInitPoint,
      });
      setNewEvent("pointCoordinatesAfterMoving", pointCoordsAfterMoving);
      setNewEvent("initialPointRDSPuntualeName", initPointRDSPuntualeName);
    }

    if (handlervalue && name === "directionRDSPuntualeCoded") {
      setNewEvent("directionRDSPuntualeCoded", handlervalue);

      let directionName = directionsArray.find(
        (item) => item.value === handlervalue
      );

      if (directionName && directionName.text) {
        setNewEvent("directionRDSPuntualeName", directionName.text);
      }
    }
  };

  disableForwardButton = () => {
    const {
      enableForwardEventButton,
      disableForwardEventButton,
      newEvent,
      newEventValid,
    } = this.props;
    const { maxOffset } = this.state;

    if (
      newEventValid.streetRDSPuntuale &&
      newEventValid.directionRDSPuntualeCoded &&
      newEventValid.initialPointRDSPuntuale &&
      Number(newEvent.distanceFromInitialPointRDSPuntuale) <= Number(maxOffset)
    ) {
      return enableForwardEventButton();
    } else return disableForwardEventButton();
  };

  render() {
    const {
      modalMapWidth,
      modalMapHalfWidth,
      statePointsListMap,
      segmentsList,
      isSegmentsLoading,
      isPointsLoading,
      maxOffset,
    } = this.state;
    const { newEvent } = this.props;

    this.disableForwardButton();

    return (
      <div className="uk-flex uk-flex-row">
        <div className="uk-width-1-2">
          <form className="uk-form-horizontal padding-form-left small-form ">
            <div className="uk-margin">
              <label className="uk-form-label">Rete</label>
              <div className="uk-form-controls">
                <select
                  id="rete-rds-puntuale"
                  className="uk-select uk-width-1-1"
                  value={newEvent && newEvent.networkRDSPuntuale}
                  onChange={(e) => this.handler(e, "networkRDSPuntuale")}
                ></select>
              </div>
            </div>
            <div
              className={isSegmentsLoading ? "uk-margin uk-flex" : "uk-margin"}
            >
              <label className="uk-form-label">Strada</label>
              <div>
                {isSegmentsLoading ? <div uk-spinner="ratio: 0.5"></div> : ""}
              </div>
              <div
                className={
                  isSegmentsLoading ? "uk-form-spinner" : "uk-form-controls"
                }
              >
                <select
                  id="strada-rds-puntuale"
                  className="uk-select uk-width-1-1"
                  value={newEvent && newEvent.streetRDSPuntuale}
                  onChange={(e) => this.handler(e, "streetRDSPuntuale")}
                ></select>
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">Direzione</label>
              <div className="uk-form-controls">
                <select
                  id="direzione-rds-puntuale"
                  className="uk-select uk-width-1-1"
                  value={newEvent && newEvent.directionRDSPuntualeCoded}
                  onChange={(e) => this.handler(e, "directionRDSPuntualeCoded")}
                ></select>
              </div>
            </div>
            <div className="container-localization-distance">
              <div className="uk-flex uk-margin">
                <div
                  className={
                    isPointsLoading
                      ? "localizationLabel uk-flex "
                      : "localizationLabel"
                  }
                >
                  <label>Punto Primario (LCD 1)</label>
                </div>
                <div>
                  {isPointsLoading ? <div uk-spinner="ratio: 0.5"></div> : ""}
                </div>
                <div className="localizationSelection">
                  <select
                    id="initial-point-rds-puntuale"
                    className="uk-select dropdownSelection"
                    value={newEvent && newEvent.initialPointRDSPuntuale}
                    onChange={(e) => this.handler(e, "initialPointRDSPuntuale")}
                  ></select>
                </div>
              </div>
              <div className="uk-flex uk-margin">
                <div className="localizationLabel">
                  <label className="">Distanza dal Punto Iniziale [m]</label>
                </div>
                <div className="localizationSelection uk-flex uk-flex-column">
                  <div className="uk-flex uk-flex-row uk-flex-right">
                    <input
                      id="distance-initial-point-rds-tmc"
                      type="number"
                      className="uk-input dropdownSelection"
                      onChange={(e) =>
                        this.handler(e, "distanceFromInitialPointRDSPuntuale")
                      }
                      min={0}
                      max={Number(maxOffset)}
                      disabled={
                        newEvent &&
                        (!newEvent.initialPointRDSPuntuale ||
                          newEvent.initialPointRDSPuntuale === "" ||
                          newEvent.initialPointRDSPuntuale === "-")
                      }
                    ></input>
                  </div>
                  <div className="uk-flex uk-flex-row uk-flex-right">
                    <div>
                      {newEvent &&
                      newEvent.initialPointRDSPuntuale &&
                      newEvent.initialPointRDSPuntuale !== "" &&
                      newEvent.initialPointRDSPuntuale !== "-"
                        ? "Distanza massima: " + maxOffset + " m"
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-flex uk-margin">
                <div className="localizationLabel">
                  <label className="">Raggio d'influenza [m] </label>
                </div>
                <div className="localizationSelection">
                  <input
                    id="radius-of-influence-puntuale"
                    type="number"
                    className="uk-input dropdownSelection"
                    onChange={(e) =>
                      this.handler(e, "radiusInfluenceRDSPuntuale")
                    }
                    min={0}
                    disabled={true}
                  ></input>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="uk-flex uk-width-1-2">
          <ErrorBoundary>
            <div className="uk-flex mapBoundary padding-form-right">
              <ModalMap
                modeswitch="rds-tmc-puntuale"
                modalMapWidth={modalMapWidth}
                modalMapHalfWidth={modalMapHalfWidth}
                filteredPointsList={statePointsListMap}
                segmentsList={segmentsList}
              ></ModalMap>
            </div>
          </ErrorBoundary>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

LocalizationRDSPuntuale.contextType = EnvironmentContext;

const mapDispatchToProps = {
  enableForwardEventButton,
  disableForwardEventButton,
  setNewEvent,
  updateNewEvent,
};

const mapStateToProps = (state) => ({
  //MODAL REDUCER
  newEvent: getNewEvent(state),
  currentEvent: getCurrentEvent(state),
  newEventValid: getNewEventValid(state),
  boundingBox: getBoundingBoxCartografica(state),
  typeModal: getTypeModal(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocalizationRDSPuntuale)
);
