//UTILS
import { selectFields } from "enums/selectable-params";

export const INITIAL_STATE_MODAL = {
  select: {
    types: [],
    sources: [],
  },
  newEvent: {
    value: {
      //generals
      iDEvent: "",
      iDPractice: "",
      stateEvent: "new",
      typeEvent: "",
      underTypeEvent: "",
      dateStartEvent: null,
      dateEndEvent: null,
      timeStartEvent: null,
      timeEndEvent: null,
      gravityEvent: "2",
      sourceOptionEvent: "",
      probabilityEvent: "0",
      note: "",
      supplierEvent: "0",
      situationRecordCreationTime: "",
      cause: null,
      //localization
      groupOfLocations: null,
      checkIfEventIsModified: false,
      //attributes
      impactCalculations: false,
      eventAttributes: null,
      eventAttributesValues: [],
      nPeopleInvolved: null,
      nVehiclesInvolved: null,
      lanes: [],
      impactLanes: "Parziale",
      numberOfOperationalLanes: 0,
      capacityRemaining: 0,
      numberOfLanesRestricted: 0,
      originalNumberOfLanes: 0,
      residualSpeed: 1,
      abnormalTraffic: null,
      accident: null,
      activity: null,
      conditions: null,
      equipmentOrSystemFault: null,
      obstruction: null,
      operatorAction: null,
      //localization - puntuale
      positionPuntuale: null,
      listOfCloseArcsPuntuale: [],
      confirmedArcPuntuale: null,
      selectedArcPuntuale: null,
      selectedArcToAddIndexPuntuale: null,
      isConfirmedArcPuntualeClicked: false,
      historyPuntuale: {
        prev: [],
        next: [],
      },
      //localization - estesa
      positionEstesa: null,
      listOfCloseArcsEstesa: [],
      listOfConfirmedArcsEstesa: [],
      selectedArcEstesa: null,
      selectedArcToAddIndexEstesa: null,
      historyEstesa: {
        prev: [],
        next: [],
      },
      // localization rds-tmc - puntuale
      networkRDSPuntuale: "Italia",
      streetRDSPuntuale: undefined,
      directionRDSPuntualeCoded: "",
      directionRDSPuntualeName: "",
      initialPointRDSPuntuale: undefined,
      initialPointRDSPuntualeName: "",
      distanceFromInitialPointRDSPuntuale: 0,
      radiusInfluenceRDSPuntuale: 1000,
      pointCoordinatesAfterMoving: [],
      arcListRDSPuntuale: [],
      // localization rds-tmc - estesa
      networkRDSEstesa: "Italia",
      streetRDSEstesa: "",
      directionRDSEstesaName: "",
      directionRDSEstesaCoded: "",
      initialPointRDSEstesa: "",
      initialPointRDSEstesaName: "",
      finalPointRDSEstesa: "",
      finalPointRDSEstesaName: "",
      distanceFromInitialPointRDSEstesa: 0,
      distanceFromFinalPointRDSEstesa: 0,
      radiusInfluenceRDSEstesa: 1000,
      pointCoordinatesAfterMovingEstesa: [],
      trafficConstrictionType: "CARRIAGEWAY_PARTIALLY_OBSTRUCTED",
      arcListRDSEstesa: [],
      //practice management
      associatedEvents: [],
    },
    valid: {
      //generals
      iDEvent: true,
      iDPractice: true,
      stateEvent: true,
      typeEvent: false,
      underTypeEvent: false,
      dateStartEvent: true,
      dateEndEvent: true,
      timeStartEvent: true,
      timeEndEvent: true,
      gravityEvent: true,
      sourceOptionEvent: true,
      probabilityEvent: true,
      note: true,
      supplierEvent: true,
      //localization
      groupOfLocations: false,
      checkIfEventIsModified: false,
      //attributes
      impactCalculations: false,
      eventAttributes: false,
      eventAttributesValues: false,
      nPeopleInvolved: true,
      nVehiclesInvolved: true,
      lanes: true,
      impactLanes: true,
      numberOfOperationalLanes: true,
      capacityRemaining: true,
      numberOfLanesRestricted: true,
      originalNumberOfLanes: true,
      residualSpeed: true,
      abnormalTraffic: true,
      accident: true,
      activity: true,
      conditions: true,
      equipmentOrSystemFault: true,
      obstruction: true,
      operatorAction: true,
      //localization - puntuale
      positionPuntuale: false,
      selectedArcPuntuale: false,
      isConfirmedArcPuntualeClicked: false,
      //localization - estesa
      positionEstesa: false,
      listOfConfirmedArcsEstesa: false,
      selectedArcEstesa: false,
      // localization rds-tmc - puntuale
      networkRDSPuntuale: true,
      streetRDSPuntuale: false,
      directionRDSPuntualeCoded: false,
      directionRDSPuntualeName: false,
      initialPointRDSPuntuale: false,
      distanceFromInitialPointRDSPuntuale: true,
      radiusInfluenceRDSPuntuale: true,
      arcListRDSPuntuale: false,
      // localization rds-tmc - estesa
      networkRDSEstesa: true,
      streetRDSEstesa: false,
      directionRDSEstesaName: false,
      directionRDSEstesaCoded: false,
      initialPointRDSEstesa: false,
      finalPointRDSEstesa: false,
      distanceFromInitialPointRDSEstesa: true,
      distanceFromFinalPointRDSEstesa: true,
      radiusInfluenceRDSEstesa: true,
      trafficConstrictionType: true,
      arcListRDSEstesa: false,
      //practice management
      associatedEvents: false,
    },
    hovered: {
      hoveredPoint: {
        object: "",
        x: null,
        y: null,
      },
    },
  },
};

export default class ModalModel {
  newEvent = {
    select: {
      types: [],
      sources: [],
      attributes: [],
    },
    value: {
      //generals
      iDEvent: "",
      iDPractice: "",
      stateEvent: "new",
      typeEvent: "",
      underTypeEvent: "",
      dateStartEvent: null,
      dateEndEvent: null,
      timeStartEvent: null,
      timeEndEvent: null,
      gravityEvent: "2",
      sourceOptionEvent: "",
      probabilityEvent: "0",
      note: "",
      supplierEvent: "0",
      situationRecordCreationTime: "",
      cause: null,
      //localization
      groupOfLocations: null,
      checkIfEventIsModified: false,
      //atributes
      impactCalculations: false,
      eventAttributes: null,
      eventAttributesValues: [],
      nPeopleInvolved: null,
      nVehiclesInvolved: null,
      lanes: [],
      impactLanes: "Parziale",
      numberOfOperationalLanes: 0,
      capacityRemaining: 0,
      numberOfLanesRestricted: 0,
      originalNumberOfLanes: 0,
      residualSpeed: 1,
      abnormalTraffic: null,
      accident: null,
      activity: null,
      conditions: null,
      equipmentOrSystemFault: null,
      obstruction: null,
      operatorAction: null,
      //localization - puntuale
      positionPuntuale: null,
      listOfCloseArcsPuntuale: [],
      selectedArcPuntuale: null,
      selectedArcToAddIndexPuntuale: null,
      confirmedArcPuntuale: null,
      isConfirmedArcPuntualeClicked: false,
      historyPuntuale: {
        prev: [],
        next: [],
      },
      //localization - estesa
      positionEstesa: null,
      listOfCloseArcsEstesa: [],
      listOfConfirmedArcsEstesa: [],
      selectedArcEstesa: null,
      selectedArcToAddIndexEstesa: null,
      historyEstesa: {
        prev: [],
        next: [],
      },
      // localization rds-tmc - puntuale
      networkRDSPuntuale: "Italia",
      streetRDSPuntuale: undefined,
      directionRDSPuntualeCoded: "",
      directionRDSPuntualeName: "",
      initialPointRDSPuntuale: undefined,
      initialPointRDSPuntualeName: "",
      distanceFromInitialPointRDSPuntuale: 0,
      radiusInfluenceRDSPuntuale: 1000,
      pointCoordinatesAfterMoving: [],
      arcListRDSPuntuale: [],
      // localization rds-tmc - estesa
      networkRDSEstesa: "Italia",
      streetRDSEstesa: "",
      directionRDSEstesaName: "",
      directionRDSEstesaCoded: "",
      initialPointRDSEstesa: "",
      initialPointRDSEstesaName: "",
      finalPointRDSEstesa: "",
      finalPointRDSEstesaName: "",
      distanceFromInitialPointRDSEstesa: 0,
      distanceFromFinalPointRDSEstesa: 0,
      radiusInfluenceRDSEstesa: 1000,
      pointCoordinatesAfterMovingEstesa: [],
      trafficConstrictionType: "CARRIAGEWAY_PARTIALLY_OBSTRUCTED",
      arcListRDSEstesa: [],
      //practice management
      associatedEvents: [],
    },
    valid: {
      //generals
      iDEvent: true,
      iDPractice: true,
      stateEvent: true,
      typeEvent: false,
      underTypeEvent: false,
      dateStartEvent: true,
      dateEndEvent: true,
      timeStartEvent: true,
      timeEndEvent: true,
      gravityEvent: true,
      sourceOptionEvent: true,
      probabilityEvent: true,
      note: true,
      supplierEvent: true,
      //localization
      groupOfLocations: false,
      checkIfEventIsModified: false,
      //attributes
      impactCalculations: false,
      eventAttributes: false,
      eventAttributesValues: false,
      nPeopleInvolved: true,
      nVehiclesInvolved: true,
      lanes: true,
      impactLanes: true,
      numberOfOperationalLanes: true,
      capacityRemaining: true,
      numberOfLanesRestricted: true,
      originalNumberOfLanes: true,
      residualSpeed: true,
      abnormalTraffic: true,
      accident: true,
      activity: true,
      conditions: true,
      equipmentOrSystemFault: true,
      obstruction: true,
      operatorAction: true,
      //localization - puntuale
      positionPuntuale: false,
      selectedArcPuntuale: false,
      isConfirmedArcPuntualeClicked: false,
      //localization - estesa
      positionEstesa: false,
      listOfConfirmedArcsEstesa: false,
      selectedArcEstesa: false,
      // localization rds-tmc - puntuale
      networkRDSPuntuale: true,
      streetRDSPuntuale: false,
      directionRDSPuntualeCoded: false,
      directionRDSPuntualeName: false,
      initialPointRDSPuntuale: false,
      distanceFromInitialPointRDSPuntuale: true,
      radiusInfluenceRDSPuntuale: true,
      arcListRDSPuntuale: false,
      // localization rds-tmc - estesa
      networkRDSEstesa: true,
      streetRDSEstesa: false,
      directionRDSEstesaName: false,
      directionRDSEstesaCoded: false,
      initialPointRDSEstesa: false,
      finalPointRDSEstesa: false,
      distanceFromInitialPointRDSEstesa: true,
      distanceFromFinalPointRDSEstesa: true,
      radiusInfluenceRDSEstesa: true,
      trafficConstrictionType: true,
      arcListRDSEstesa: false,
      //practice management
      associatedEvents: false,
    },
    hovered: {
      hoveredPoint: {
        object: "",
        x: null,
        y: null,
      },
    },
  };
  constructor() {
    this.newEvent = {
      select: {
        types: [],
        sources: [],
        attributes: [],
      },
      value: {
        //generals
        iDEvent: "",
        iDPractice: "",
        stateEvent: "new",
        typeEvent: "",
        underTypeEvent: "",
        dateStartEvent: null,
        dateEndEvent: null,
        timeStartEvent: null,
        timeEndEvent: null,
        gravityEvent: "2",
        sourceOptionEvent: "",
        probabilityEvent: "0",
        note: "",
        supplierEvent: "0",
        situationRecordCreationTime: "",
        cause: null,
        //localization
        groupOfLocations: null,
        checkIfEventIsModified: false,
        //atributes
        impactCalculations: false,
        eventAttributes: null,
        eventAttributesValues: [],
        nPeopleInvolved: null,
        nVehiclesInvolved: null,
        lanes: [],
        impactLanes: "Parziale",
        residualSpeed: 1,
        numberOfOperationalLanes: 0,
        capacityRemaining: 0,
        numberOfLanesRestricted: 0,
        originalNumberOfLanes: 0,
        abnormalTraffic: null,
        accident: null,
        activity: null,
        conditions: null,
        equipmentOrSystemFault: null,
        obstruction: null,
        operatorAction: null,
        //localization - puntuale
        positionPuntuale: null,
        listOfCloseArcsPuntuale: [],
        selectedArcPuntuale: null,
        selectedArcToAddIndexPuntuale: null,
        confirmedArcPuntuale: null,
        isConfirmedArcPuntualeClicked: false,
        historyPuntuale: {
          prev: [],
          next: [],
        },
        //localization - estesa
        positionEstesa: null,
        listOfCloseArcsEstesa: [],
        listOfConfirmedArcsEstesa: [],
        selectedArcEstesa: null,
        selectedArcToAddIndexEstesa: null,
        historyEstesa: {
          prev: [],
          next: [],
        },
        // localization rds-tmc - puntuale
        networkRDSPuntuale: "Italia",
        streetRDSPuntuale: undefined,
        directionRDSPuntualeCoded: "",
        directionRDSPuntualeName: "",
        initialPointRDSPuntuale: undefined,
        initialPointRDSPuntualeName: "",
        distanceFromInitialPointRDSPuntuale: 0,
        radiusInfluenceRDSPuntuale: 1000,
        pointCoordinatesAfterMoving: [],
        arcListRDSPuntuale: [],
        // localization rds-tmc - estesa
        networkRDSEstesa: "Italia",
        streetRDSEstesa: "",
        directionRDSEstesaName: "",
        directionRDSEstesaCoded: "",
        initialPointRDSEstesa: "",
        initialPointRDSEstesaName: "",
        finalPointRDSEstesa: "",
        finalPointRDSEstesaName: "",
        distanceFromInitialPointRDSEstesa: 0,
        distanceFromFinalPointRDSEstesa: 0,
        radiusInfluenceRDSEstesa: 1000,
        pointCoordinatesAfterMovingEstesa: [],

        trafficConstrictionType: "CARRIAGEWAY_PARTIALLY_OBSTRUCTED",
        arcListRDSEstesa: [],

        //practice management
        associatedEvents: [],
      },
      valid: {
        //generals
        iDEvent: true,
        iDPractice: true,
        stateEvent: true,
        typeEvent: false,
        underTypeEvent: false,
        dateStartEvent: true,
        dateEndEvent: true,
        timeStartEvent: true,
        timeEndEvent: true,
        gravityEvent: true,
        sourceOptionEvent: true,
        probabilityEvent: true,
        note: true,
        supplierEvent: true,
        //localization
        groupOfLocations: false,
        checkIfEventIsModified: false,
        //attributes
        impactCalculations: false,
        eventAttributes: false,
        eventAttributesValues: false,
        nPeopleInvolved: true,
        nVehiclesInvolved: true,
        lanes: true,
        impactLanes: true,
        numberOfOperationalLanes: true,
        capacityRemaining: true,
        numberOfLanesRestricted: true,
        originalNumberOfLanes: true,
        residualSpeed: true,
        abnormalTraffic: true,
        accident: true,
        activity: true,
        conditions: true,
        equipmentOrSystemFault: true,
        obstruction: true,
        operatorAction: true,
        //localization - puntuale
        positionPuntuale: false,
        selectedArcPuntuale: false,
        isConfirmedArcPuntualeClicked: false,
        //localization - estesa
        positionEstesa: false,
        listOfConfirmedArcsEstesa: false,
        // localization rds-tmc - puntuale
        networkRDSPuntuale: true,
        streetRDSPuntuale: false,
        directionRDSPuntualeCoded: false,
        directionRDSPuntualeName: false,
        initialPointRDSPuntuale: false,
        distanceFromInitialPointRDSPuntuale: true,
        radiusInfluenceRDSPuntuale: true,
        selectedArcEstesa: false,
        arcListRDSPuntuale: false,
        // localization rds-tmc - estesa
        networkRDSEstesa: true,
        streetRDSEstesa: false,
        directionRDSEstesaName: false,
        directionRDSEstesaCoded: false,
        initialPointRDSEstesa: false,
        finalPointRDSEstesa: false,
        distanceFromInitialPointRDSEstesa: true,
        distanceFromFinalPointRDSEstesa: true,
        radiusInfluenceRDSEstesa: true,
        trafficConstrictionType: true,
        arcListRDSEstesa: false,
        //practice management
        associatedEvents: false,
      },
      hovered: {
        hoveredPoint: {
          object: "",
          x: null,
          y: null,
        },
      },
    };
  }

  static toModal(obj, select) {
    let road = new ModalModel();

    let indexType = select.types.findIndex(
      (x) => x.typeName === obj.situationRecordType
    );

    let currentLocalizationTab = "Cartografica Puntuale";

    if (obj && indexType !== -1) {
      //SITUATION RECORD ID
      if (obj.situationRecordId) {
        road.newEvent.value.iDEvent = obj.situationRecordId;
      }
      //SITUATION ID
      if (obj.situationId) {
        road.newEvent.value.iDPractice = obj.situationId;
      }
      //SITUATION RECORD TYPE
      if (obj.situationRecordType) {
        road.newEvent.value.typeEvent = indexType.toString();
        road.newEvent.valid.typeEvent = true;
      }

      //UNDERTYPE EVENT
      if (obj.situationRecordType) {
        let subTypeIndex, eventAttributesValues;
        switch (obj.situationRecordType) {
          case "ABNORMAL_TRAFFIC":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.abnormalTraffic.abnormalTrafficType
            );
            eventAttributesValues = [
              {
                attributeName: "relativeTrafficFlow",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.abnormalTraffic &&
                  obj.traffic.abnormalTraffic.relativeTrafficFlow
                    ? obj.traffic.abnormalTraffic.relativeTrafficFlow
                    : null,
              },
              {
                attributeName: "trafficTrendType",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.abnormalTraffic &&
                  obj.traffic.abnormalTraffic.trafficTrendType
                    ? obj.traffic.abnormalTraffic.trafficTrendType
                    : null,
              },
            ];
            break;
          case "ACCIDENT":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) => x.subTypeName === obj.traffic.accident.accidentType
            );
            eventAttributesValues = [
              {
                attributeName: "accidentCause",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.accident &&
                  obj.traffic.accident.accidentCause
                    ? obj.traffic.accident.accidentCause
                    : null,
              },
              {
                attributeName: "totalNumberOfPeopleInvolved",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.accident &&
                  obj.traffic.accident.totalNumberOfPeopleInvolved
                    ? obj.traffic.accident.totalNumberOfPeopleInvolved
                    : obj.traffic &&
                      obj.traffic.accident &&
                      obj.traffic.accident.totalNumberOfPeopleInvolved === 0
                    ? obj.traffic.accident.totalNumberOfPeopleInvolved.toString()
                    : null,
              },
              {
                attributeName: "totalNumberOfVehiclesInvolved",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.accident &&
                  obj.traffic.accident.totalNumberOfVehiclesInvolved
                    ? obj.traffic.accident.totalNumberOfVehiclesInvolved
                    : obj.traffic &&
                      obj.traffic.accident &&
                      obj.traffic.accident.totalNumberOfVehiclesInvolved === 0
                    ? obj.traffic.accident.totalNumberOfVehiclesInvolved.toString()
                    : null,
              },
            ];

            break;
          case "ANIMAL_PRESENCE_OBSTRUCTION":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.obstruction.animalPresenceObstruction
                  .animalPresenceObstructionType
            );
            eventAttributesValues = [
              {
                attributeName: "numberOfObstructions",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.obstruction &&
                  obj.traffic.obstruction.numberOfObstructions
                    ? obj.traffic.obstruction.numberOfObstructions
                    : obj.traffic &&
                      obj.traffic.obstruction &&
                      obj.traffic.obstruction.numberOfObstructions === 0
                    ? obj.traffic.obstruction.numberOfObstructions.toString()
                    : null,
              },
              {
                attributeName: "alive",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.obstruction &&
                  obj.traffic.obstruction.animalPresenceObstruction &&
                  (obj.traffic.obstruction.animalPresenceObstruction.alive ||
                    obj.traffic.obstruction.animalPresenceObstruction.alive ===
                      false)
                    ? obj.traffic.obstruction.animalPresenceObstruction.alive
                        .toString()
                        .toUpperCase()
                    : null,
              },
            ];

            break;
          case "POOR_ENVIRONMENT_CONDITIONS":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.conditions.poorEnvironmentConditions
                  .poorEnvironmentType
            );
            eventAttributesValues = [
              {
                attributeName: "pollutantConcentration",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions.pollution &&
                  obj.traffic.conditions.poorEnvironmentConditions.pollution
                    .pollutantConcentration
                    ? obj.traffic.conditions.poorEnvironmentConditions.pollution
                        .pollutantConcentration
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .pollution &&
                      obj.traffic.conditions.poorEnvironmentConditions.pollution
                        .pollutantConcentration === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.pollution.pollutantConcentration.toString()
                    : null,
              },
              {
                attributeName: "pollutantType",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions.pollution &&
                  obj.traffic.conditions.poorEnvironmentConditions.pollution
                    .pollutantType
                    ? obj.traffic.conditions.poorEnvironmentConditions.pollution
                        .pollutantType
                    : null,
              },
              {
                attributeName: "relativeHumidity",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions.humidity &&
                  obj.traffic.conditions.poorEnvironmentConditions.humidity
                    .relativeHumidity
                    ? obj.traffic.conditions.poorEnvironmentConditions.humidity
                        .relativeHumidity
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .humidity &&
                      obj.traffic.conditions.poorEnvironmentConditions.humidity
                        .relativeHumidity === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.humidity.relativeHumidity.toString()
                    : null,
              },
              {
                attributeName: "precipitationIntensity",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions
                    .precipitationDetail &&
                  obj.traffic.conditions.poorEnvironmentConditions
                    .precipitationDetail.precipitationIntensity
                    ? obj.traffic.conditions.poorEnvironmentConditions
                        .precipitationDetail.precipitationIntensity
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .precipitationDetail &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .precipitationDetail.precipitationIntensity === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.precipitationDetail.precipitationIntensity.toString()
                    : null,
              },
              {
                attributeName: "precipitationType",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions
                    .precipitationDetail &&
                  obj.traffic.conditions.poorEnvironmentConditions
                    .precipitationDetail.precipitationType
                    ? obj.traffic.conditions.poorEnvironmentConditions
                        .precipitationDetail.precipitationType
                    : null,
              },
              {
                attributeName: "airTemperature",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions
                    .temperature &&
                  obj.traffic.conditions.poorEnvironmentConditions.temperature
                    .airTemperature
                    ? obj.traffic.conditions.poorEnvironmentConditions
                        .temperature.airTemperature
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .temperature &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .temperature.airTemperature === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.temperature.airTemperature.toString()
                    : null,
              },
              {
                attributeName: "maximumTemperature",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions
                    .temperature &&
                  obj.traffic.conditions.poorEnvironmentConditions.temperature
                    .maximumTemperature
                    ? obj.traffic.conditions.poorEnvironmentConditions
                        .temperature.maximumTemperature
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .temperature &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .temperature.maximumTemperature === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.temperature.maximumTemperature.toString()
                    : null,
              },
              {
                attributeName: "minimumTemperature",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions
                    .temperature &&
                  obj.traffic.conditions.poorEnvironmentConditions.temperature
                    .minimumTemperature
                    ? obj.traffic.conditions.poorEnvironmentConditions
                        .temperature.minimumTemperature
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .temperature &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .temperature.minimumTemperature === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.temperature.minimumTemperature.toString()
                    : null,
              },
              {
                attributeName: "minimunVisibilityDistance",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions.visibility &&
                  obj.traffic.conditions.poorEnvironmentConditions.visibility
                    .minimunVisibilityDistance
                    ? obj.traffic.conditions.poorEnvironmentConditions
                        .visibility.minimunVisibilityDistance
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .visibility &&
                      obj.traffic.conditions.poorEnvironmentConditions
                        .visibility.minimunVisibilityDistance === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.visibility.minimunVisibilityDistance.toString()
                    : null,
              },
              {
                attributeName: "directionCompass",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions.wind &&
                  obj.traffic.conditions.poorEnvironmentConditions.wind
                    .directionCompass
                    ? obj.traffic.conditions.poorEnvironmentConditions.wind
                        .directionCompass
                    : null,
              },
              {
                attributeName: "maximumWindSpeed",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions.wind &&
                  obj.traffic.conditions.poorEnvironmentConditions.wind
                    .maximumWindSpeed
                    ? obj.traffic.conditions.poorEnvironmentConditions.wind
                        .maximumWindSpeed
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions.wind &&
                      obj.traffic.conditions.poorEnvironmentConditions.wind
                        .maximumWindSpeed === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.wind.maximumWindSpeed.toString()
                    : null,
              },
              {
                attributeName: "windMeasurementHeight",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions.wind &&
                  obj.traffic.conditions.poorEnvironmentConditions.wind
                    .windMeasurementHeight
                    ? obj.traffic.conditions.poorEnvironmentConditions.wind
                        .windMeasurementHeight
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions.wind &&
                      obj.traffic.conditions.poorEnvironmentConditions.wind
                        .windMeasurementHeight === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.wind.windMeasurementHeight.toString()
                    : null,
              },
              {
                attributeName: "windSpeed",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.poorEnvironmentConditions &&
                  obj.traffic.conditions.poorEnvironmentConditions.wind &&
                  obj.traffic.conditions.poorEnvironmentConditions.wind
                    .windSpeed
                    ? obj.traffic.conditions.poorEnvironmentConditions.wind
                        .windSpeed
                    : obj.traffic &&
                      obj.traffic.conditions &&
                      obj.traffic.conditions.poorEnvironmentConditions &&
                      obj.traffic.conditions.poorEnvironmentConditions.wind &&
                      obj.traffic.conditions.poorEnvironmentConditions.wind
                        .windSpeed === 0
                    ? obj.traffic.conditions.poorEnvironmentConditions.wind.windSpeed.toString()
                    : null,
              },
              {
                attributeName: "drivingConditionType",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.drivingConditionType
                    ? obj.traffic.conditions.drivingConditionType
                    : null,
              },
            ];
            break;
          case "AUTHORITY_OPERATION":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.activity.authorityOperation.authorityOperationType
            );
            break;
          case "DISTURBANCE_ACTIVITY":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.activity.disturbanceActivity.disturbanceActivityType
            );
            break;
          case "PUBLIC_EVENT":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.activity.publicEvent.publicEventType
            );
            break;
          case "WEATHER_RELATED_ROAD_CONDITIONS":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.conditions.weatherRelatedRoadConditions
                  .weatherRelatedRoadConditionType
            );
            eventAttributesValues = [
              {
                attributeName: "drivingConditionType",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.conditions &&
                  obj.traffic.conditions.drivingConditionType
                    ? obj.traffic.conditions.drivingConditionType
                    : null,
              },
            ];
            break;
          case "EQUIPMENT_OR_SYSTEM_FAULT":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.equipmentOrSystemFault.equipmentOrSystemFaultType
            );
            eventAttributesValues = [
              {
                attributeName: "faultyEquipmentOrSystemType",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.equipmentOrSystemFault &&
                  obj.traffic.equipmentOrSystemFault.faultyEquipmentOrSystemType
                    ? obj.traffic.equipmentOrSystemFault
                        .faultyEquipmentOrSystemType
                    : null,
              },
            ];
            break;
          case "GENERAL_OBSTRUCTION":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.obstruction.generalObstruction.obstructionType
            );
            eventAttributesValues = [
              {
                attributeName: "numberOfObstructions",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.obstruction &&
                  obj.traffic.obstruction.numberOfObstructions
                    ? obj.traffic.obstruction.numberOfObstructions
                    : obj.traffic &&
                      obj.traffic.obstruction &&
                      obj.traffic.obstruction.numberOfObstructions === 0
                    ? obj.traffic.obstruction.numberOfObstructions.toString()
                    : null,
              },
            ];

            break;
          case "ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.operatorAction.networkManagement
                  .roadOrCarriagewayOrLaneManagement
                  .roadOrCarriagewayOrLaneManagementType
            );
            eventAttributesValues = [
              {
                attributeName: "actionOrigin",
                attributeValue:
                  obj.operatorAction && obj.operatorAction.actionOrigin
                    ? obj.operatorAction.actionOrigin
                    : null,
              },
              {
                attributeName: "actionPlanIdentifier",
                attributeValue:
                  obj.operatorAction && obj.operatorAction.actionPlanIdentifier
                    ? obj.operatorAction.actionPlanIdentifier
                    : null,
              },
              {
                attributeName: "operatorActionStatus",
                attributeValue:
                  obj.operatorAction && obj.operatorAction.operatorActionStatus
                    ? obj.operatorAction.operatorActionStatus
                    : null,
              },
              {
                attributeName: "applicableForTrafficDirection",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.networkManagement &&
                  obj.operatorAction.networkManagement
                    .applicableForTrafficDirection
                    ? obj.operatorAction.networkManagement
                        .applicableForTrafficDirection
                    : null,
              },
              {
                attributeName: "applicableForTrafficType",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.networkManagement &&
                  obj.operatorAction.networkManagement.applicableForTrafficType
                    ? obj.operatorAction.networkManagement
                        .applicableForTrafficType
                    : null,
              },
              {
                attributeName: "complianceOption",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.networkManagement &&
                  obj.operatorAction.networkManagement.complianceOption
                    ? obj.operatorAction.networkManagement.complianceOption
                    : null,
              },
            ];
            break;
          case "MAINTENANCE_WORKS":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.operatorAction.roadworks.maintenanceWorks
                  .maintenanceWorkType
            );
            eventAttributesValues = [
              {
                attributeName: "actionPlanIdentifier",
                attributeValue:
                  obj.operatorAction && obj.operatorAction.actionPlanIdentifier
                    ? obj.operatorAction.actionPlanIdentifier
                    : null,
              },
              {
                attributeName: "actionOrigin",
                attributeValue:
                  obj.operatorAction && obj.operatorAction.actionOrigin
                    ? obj.operatorAction.actionOrigin
                    : null,
              },
              {
                attributeName: "urgentRoadworks",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.roadworks &&
                  obj.operatorAction.roadworks.urgentRoadworks
                    ? obj.operatorAction.roadworks.urgentRoadworks
                        .toString()
                        .toUpperCase()
                    : obj.operatorAction &&
                      obj.operatorAction.roadworks &&
                      obj.operatorAction.roadworks.urgentRoadworks === false
                    ? obj.operatorAction.roadworks.urgentRoadworks
                        .toString()
                        .toUpperCase()
                    : null,
              },
              {
                attributeName: "roadworksScale",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.roadworks &&
                  obj.operatorAction.roadworks.roadworksScale
                    ? obj.operatorAction.roadworks.roadworksScale
                    : null,
              },
              {
                attributeName: "underTraffic",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.roadworks &&
                  obj.operatorAction.roadworks.underTraffic
                    ? obj.operatorAction.roadworks.underTraffic
                        .toString()
                        .toUpperCase()
                    : obj.operatorAction &&
                      obj.operatorAction.roadworks &&
                      obj.operatorAction.roadworks.underTraffic === false
                    ? obj.operatorAction.roadworks.underTraffic
                        .toString()
                        .toUpperCase()
                    : null,
              },
              {
                attributeName: "roadworksDuration",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.roadworks &&
                  obj.operatorAction.roadworks.roadworksDuration
                    ? obj.operatorAction.roadworks.roadworksDuration
                    : null,
              },
            ];
            break;
          case "CONSTRUCTION_WORKS":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.operatorAction.roadworks.constructionWorks
                  .constructionWorkType
            );
            eventAttributesValues = [
              {
                attributeName: "actionOrigin",
                attributeValue:
                  obj.operatorAction && obj.operatorAction.actionOrigin
                    ? obj.operatorAction.actionOrigin
                    : null,
              },
              {
                attributeName: "actionPlanIdentifier",
                attributeValue:
                  obj.operatorAction && obj.operatorAction.actionPlanIdentifier
                    ? obj.operatorAction.actionPlanIdentifier
                    : null,
              },
              {
                attributeName: "operatorActionStatus",
                attributeValue:
                  obj.operatorAction && obj.operatorAction.operatorActionStatus
                    ? obj.operatorAction.operatorActionStatus
                    : null,
              },
              {
                attributeName: "roadworksDuration",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.roadworks &&
                  obj.operatorAction.roadworks.roadworksDuration
                    ? obj.operatorAction.roadworks.roadworksDuration
                    : null,
              },
              {
                attributeName: "roadworksScale",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.roadworks &&
                  obj.operatorAction.roadworks.roadworksScale
                    ? obj.operatorAction.roadworks.roadworksScale
                    : null,
              },
              {
                attributeName: "underTraffic",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.roadworks &&
                  obj.operatorAction.roadworks.underTraffic
                    ? obj.operatorAction.roadworks.underTraffic
                        .toString()
                        .toUpperCase()
                    : obj.operatorAction &&
                      obj.operatorAction.roadworks &&
                      obj.operatorAction.roadworks.underTraffic === false
                    ? obj.operatorAction.roadworks.underTraffic
                        .toString()
                        .toUpperCase()
                    : null,
              },
              {
                attributeName: "urgentRoadworks",
                attributeValue:
                  obj.operatorAction &&
                  obj.operatorAction.roadworks &&
                  obj.operatorAction.roadworks.urgentRoadworks
                    ? obj.operatorAction.roadworks.urgentRoadworks
                        .toString()
                        .toUpperCase()
                    : obj.operatorAction &&
                      obj.operatorAction.roadworks &&
                      obj.operatorAction.roadworks.urgentRoadworks === false
                    ? obj.operatorAction.roadworks.urgentRoadworks
                        .toString()
                        .toUpperCase()
                    : null,
              },
            ];
            break;
          case "VEHICLE_OBSTRUCTION":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.obstruction.vehicleObstruction
                  .vehicleObstructionType
            );
            eventAttributesValues = [
              {
                attributeName: "numberOfObstructions",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.obstruction &&
                  obj.traffic.obstruction.numberOfObstructions
                    ? obj.traffic.obstruction.numberOfObstructions
                    : obj.traffic &&
                      obj.traffic.obstruction &&
                      obj.traffic.obstruction.numberOfObstructions === 0
                    ? obj.traffic.obstruction.numberOfObstructions.toString()
                    : null,
              },
            ];

            break;
          case "ENVIRONMENTAL_OBSTRUCTION":
            subTypeIndex = select.types[indexType].subTypes.findIndex(
              (x) =>
                x.subTypeName ===
                obj.traffic.obstruction.environmentalObstruction
                  .environmentalObstructionType
            );
            eventAttributesValues = [
              {
                attributeName: "numberOfObstructions",
                attributeValue:
                  obj.traffic &&
                  obj.traffic.obstruction &&
                  obj.traffic.obstruction.numberOfObstructions
                    ? obj.traffic.obstruction.numberOfObstructions
                    : obj.traffic &&
                      obj.traffic.obstruction &&
                      obj.traffic.obstruction.numberOfObstructions === 0
                    ? obj.traffic.obstruction.numberOfObstructions.toString()
                    : null,
              },
            ];
            break;
          default:
            break;
        }
        road.newEvent.value.underTypeEvent = subTypeIndex.toString();
        road.newEvent.valid.underTypeEvent = true;
        road.newEvent.value.eventAttributesValues = eventAttributesValues;
      }

      //SITUATION CREATION TIME
      if (obj.situationRecordCreationTime) {
        road.newEvent.value.situationRecordCreationTime =
          obj.situationRecordCreationTime &&
          obj.situationRecordCreationTime !== null
            ? obj.situationRecordCreationTime
            : new Date();
      }
      //CAUSE
      if (obj.cause) {
        road.newEvent.value.cause = obj.cause;
      }
      //SEVERITY
      if (obj.severity) {
        road.newEvent.value.gravityEvent = selectFields.GRAVITA.properties
          .findIndex((x) => x.name === obj.severity)
          .toString();
      }
      // TRAFFIC (not used in modal fields)
      if (obj.traffic && obj.traffic.abnormalTraffic) {
        road.newEvent.value.abnormalTraffic = obj.traffic.abnormalTraffic;
      }
      if (obj.traffic && obj.traffic.accident) {
        road.accident = obj.traffic.accident;
      }
      if (obj.traffic && obj.traffic.activity) {
        road.newEvent.value.activity = obj.traffic.activity;
      }
      if (obj.traffic && obj.traffic.conditions) {
        road.newEvent.value.conditions = obj.traffic.conditions;
      }
      if (obj.traffic && obj.traffic.equipmentOrSystemFault) {
        road.newEvent.value.equipmentOrSystemFault =
          obj.traffic.equipmentOrSystemFault;
      }
      if (obj.traffic && obj.traffic.obstruction) {
        road.newEvent.value.obstruction = obj.traffic.obstruction;
      }
      //PROBABILITY OF OCCURRENCE
      if (obj.probabilityOfOccurrence) {
        let indexProb = selectFields.PROBABILITA.properties.findIndex(
          (x) => x.name === obj.probabilityOfOccurrence
        );
        road.newEvent.value.probabilityEvent = indexProb.toString();
      }
      //SOURCE
      if (obj.source && obj.source.sourceReference) {
        let indexSource = select.sources.findIndex(
          (x) => x.sourceId === obj.source.sourceReference
        );
        road.newEvent.value.supplierEvent =
          indexSource.toString() && indexSource !== -1
            ? indexSource.toString()
            : "";
      }
      if (obj.source && obj.source.sourceName) {
        road.newEvent.value.sourceOptionEvent =
          obj.source.sourceName.content || "";
      }
      //IMPACT
      if (obj.impact) {
        let arrLanes = ["1", "2", "3", "4", "5", "6"];
        road.newEvent.value.numberOfOperationalLanes =
          obj.impact.numberOfOperationalLanes || 0;
        road.newEvent.value.numberOfLanesRestricted =
          obj.impact.numberOfLanesRestricted || 0;
        road.newEvent.value.capacityRemaining =
          obj.impact.capacityRemaining || 0;
        road.newEvent.value.originalNumberOfLanes =
          obj.impact.originalNumberOfLanes || 0;
        road.newEvent.value.impactLanes =
          obj.impact.trafficConstrictionType === "CARRIAGEWAY_BLOCKED"
            ? "Totale"
            : "Parziale";
        road.newEvent.value.residualSpeed =
          obj.impact.speedRemaining || obj.impact.speedRemaining === 0
            ? obj.impact.speedRemaining
            : 1;
        road.newEvent.value.lanes =
          obj.impact.originalNumberOfLanes ===
            obj.impact.numberOfLanesRestricted &&
          obj.impact.numberOfLanesRestricted === 1
            ? ["All", "1"]
            : obj.impact.originalNumberOfLanes ===
                obj.impact.numberOfLanesRestricted &&
              obj.impact.numberOfLanesRestricted === 2
            ? ["All", "1", "2"]
            : obj.impact.originalNumberOfLanes ===
                obj.impact.numberOfLanesRestricted &&
              obj.impact.numberOfLanesRestricted === 3
            ? ["All", "1", "2", "3"]
            : obj.impact.originalNumberOfLanes ===
                obj.impact.numberOfLanesRestricted &&
              obj.impact.numberOfLanesRestricted === 4
            ? ["All", "1", "2", "3", "4"]
            : obj.impact.originalNumberOfLanes ===
                obj.impact.numberOfLanesRestricted &&
              obj.impact.numberOfLanesRestricted === 5
            ? ["All", "1", "2", "3", "4", "5"]
            : obj.impact.originalNumberOfLanes ===
                obj.impact.numberOfLanesRestricted &&
              obj.impact.numberOfLanesRestricted === 6
            ? ["All", "1", "2", "3", "4", "5", "6"]
            : arrLanes.filter(
                (x) => parseInt(x) <= obj.impact.numberOfLanesRestricted
              );
      }
      //NOTE
      if (
        obj.generalPublicComment &&
        obj.generalPublicComment.length > 0 &&
        obj.generalPublicComment[0].comment
      ) {
        road.newEvent.value.note = obj.generalPublicComment[0].comment.content
          ? obj.generalPublicComment[0].comment.content
          : "";
      }
      //VALIDITY (START AND END TIME)
      if (obj.validity) {
        if (
          obj.validity.validityTimeSpecification &&
          obj.validity.validityTimeSpecification.overallStartTime
        ) {
          road.newEvent.value.dateStartEvent = new Date(
            obj.validity.validityTimeSpecification.overallStartTime
          );
          road.newEvent.value.timeStartEvent = new Date(
            obj.validity.validityTimeSpecification.overallStartTime
          );
        }
        if (
          obj.validity.validityTimeSpecification &&
          obj.validity.validityTimeSpecification.overallEndTime
        ) {
          road.newEvent.value.dateEndEvent = new Date(
            obj.validity.validityTimeSpecification.overallEndTime
          );

          road.newEvent.value.timeEndEvent = new Date(
            obj.validity.validityTimeSpecification.overallEndTime
          );
        }
        if (obj.validity.validityStatus) {
          road.newEvent.value.stateEvent = obj.validity.validityStatus;
        }
      }

      //LOCALIZATION VALUES FROM SITUATION RECORD
      let indexOfPointByCooordinates =
        obj &&
        obj.groupOfLocations &&
        obj.groupOfLocations[0] &&
        obj.groupOfLocations.findIndex(
          (x) => x.locationType === "POINT_BY_COORDINATES"
        );
      let indexOfLinearWithinElement;
      let indexOfAlertCMethodPoint;
      let indexOfAlertCMethodLinear;
      let linearWithinElementOnly;

      if (obj && obj.groupOfLocations && obj.groupOfLocations.length >= 2) {
        indexOfAlertCMethodPoint =
          obj &&
          obj.groupOfLocations &&
          obj.groupOfLocations[0] &&
          obj.groupOfLocations.findIndex(
            (x) => x.locationType === "ALERT_C_METHOD_4_POINT"
          );
        indexOfAlertCMethodLinear =
          obj &&
          obj.groupOfLocations &&
          obj.groupOfLocations[0] &&
          obj.groupOfLocations.findIndex(
            (x) => x.locationType === "ALERT_C_METHOD_4_LINEAR"
          );
        indexOfLinearWithinElement =
          obj &&
          obj.groupOfLocations &&
          obj.groupOfLocations[0] &&
          obj.groupOfLocations.findIndex(
            (x) => x.locationType === "LINEAR_WITHIN_LINEAR_ELEMENT"
          );
        linearWithinElementOnly =
          obj &&
          obj.groupOfLocations &&
          obj.groupOfLocations[0] &&
          obj.groupOfLocations.filter(
            (x) => x.locationType === "LINEAR_WITHIN_LINEAR_ELEMENT"
          );
      }

      //CASE: RDS-TMC
      if (
        obj &&
        obj.groupOfLocations &&
        obj.groupOfLocations[indexOfAlertCMethodPoint] &&
        obj.groupOfLocations[indexOfAlertCMethodPoint].point &&
        !obj.groupOfLocations[indexOfAlertCMethodPoint].linear &&
        obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint &&
        obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
          .primaryLocation
      ) {
        // CASE: ONLY RDS-TMC POINT
        //POINT LCD
        currentLocalizationTab = "Rete RDS-TMC Puntuale";

        //SET INITIAL TRATTA
        road.newEvent.value.networkRDSPuntuale = "Italia";
        road.newEvent.valid.networkRDSPuntuale = true;
        //INITIAL POINT
        if (
          obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
            .primaryLocation.specificLocation
        ) {
          road.newEvent.value.initialPointRDSPuntuale =
            obj.groupOfLocations &&
            obj.groupOfLocations[0] &&
            obj.groupOfLocations[0].point &&
            obj.groupOfLocations[0].point.alertCPoint &&
            obj.groupOfLocations[0].point.alertCPoint.primaryLocation &&
            obj.groupOfLocations[0].point.alertCPoint.primaryLocation
              .specificLocation;
          road.newEvent.valid.initialPointRDSPuntuale = true;
        }

        //COORDINATES AFTER MOVING
        road.newEvent.value.pointCoordinatesAfterMoving = obj.groupOfLocations[
          indexOfAlertCMethodPoint
        ].locationForDisplay
          ? [
              obj.groupOfLocations[indexOfAlertCMethodPoint].locationForDisplay
                .longitude,
              obj.groupOfLocations[indexOfAlertCMethodPoint].locationForDisplay
                .latitude,
            ]
          : [];
        road.newEvent.valid.pointCoordinatesAfterMoving = true;
        // OFFSET
        road.newEvent.value.distanceFromInitialPointRDSPuntuale = obj
          .groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
          .primaryLocation.offsetDistance
          ? obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
              .primaryLocation.offsetDistance
          : 0;
        road.newEvent.valid.distanceFromInitialPointRDSPuntuale = true;
        //DIRECTION
        if (
          obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
            .alertCDirection &&
          obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
            .alertCDirection.alertCDirectionNamed
        ) {
          road.newEvent.value.directionRDSPuntualeName =
            obj.groupOfLocations[
              indexOfAlertCMethodPoint
            ].point.alertCPoint.alertCDirection.alertCDirectionNamed.content;
          road.newEvent.valid.directionRDSPuntualeName = true;
        }

        if (
          obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
            .alertCDirection &&
          obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
            .alertCDirection.alertCDirectionCoded
        ) {
          road.newEvent.value.directionRDSPuntualeCoded =
            obj.groupOfLocations[
              indexOfAlertCMethodPoint
            ].point.alertCPoint.alertCDirection.alertCDirectionCoded;
          road.newEvent.valid.directionRDSPuntualeCoded = true;
        }
        if (
          obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
            .primaryLocation.alertCLocationName &&
          obj.groupOfLocations[indexOfAlertCMethodPoint].point.alertCPoint
            .primaryLocation.specificLocation
        ) {
          road.newEvent.value.initialPointRDSPuntualeName =
            obj.groupOfLocations[
              indexOfAlertCMethodPoint
            ].point.alertCPoint.primaryLocation.alertCLocationName.content;
          road.newEvent.value.initialPointRDSPuntuale = obj.groupOfLocations[
            indexOfAlertCMethodPoint
          ].point.alertCPoint.primaryLocation.specificLocation.toString();
        }
        //ARC LIST IS SET IN MODAL, AFTER API CALL
        // if (
        //   obj.groupOfLocations[indexOfLinearWithinElement].linear
        //     .linearWithinLinearElement.linearElement &&
        //   obj.groupOfLocations[indexOfLinearWithinElement].linear
        //     .linearWithinLinearElement.linearElement.archId
        // ) {
        //   road.newEvent.value.arcListRDSPuntuale = [
        //     obj.groupOfLocations[indexOfLinearWithinElement].linear
        //       .linearWithinLinearElement.linearElement.archId,
        //   ];
        //   road.newEvent.valid.arcListRDSPuntuale = true;
        // }
        // if (
        //   obj.groupOfLocations[indexOfAlertCMethodPoint].locationForDisplay
        // ) {
        //   road.newEvent.value.pointCoordinatesAfterMovingEstesa =
        //     obj.groupOfLocations[indexOfAlertCMethodPoint].locationForDisplay;
        // }
      } else if (
        obj &&
        obj.groupOfLocations &&
        obj.groupOfLocations[indexOfAlertCMethodLinear] &&
        obj.groupOfLocations[indexOfAlertCMethodLinear].linear &&
        obj.groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
      ) {
        //CASE: RDS-TMC LINEAR
        currentLocalizationTab = "Rete RDS-TMC Estesa";
        //SET INITIAL TRATTA
        road.newEvent.value.networkRDSEstesa = "Italia";
        road.newEvent.valid.networkRDSEstesa = true;
        if (
          obj.groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
            .primaryLocation &&
          obj.groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
            .secondaryLocation
        ) {
          //COORDINATES AFTER MOVING
          road.newEvent.value.pointCoordinatesAfterMovingEstesa = obj
            .groupOfLocations[indexOfAlertCMethodLinear].locationForDisplay
            ? [
                obj.groupOfLocations[indexOfAlertCMethodLinear]
                  .locationForDisplay.longitude,
                obj.groupOfLocations[indexOfAlertCMethodLinear]
                  .locationForDisplay.latitude,
              ]
            : [];
          //POINT LCD - INITIAL AND FINAL
          if (
            obj.groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
              .primaryLocation.specificLocation
          ) {
            road.newEvent.value.initialPointRDSEstesa =
              obj.groupOfLocations[
                indexOfAlertCMethodLinear
              ].linear.alertCLinear.primaryLocation.specificLocation;
            road.newEvent.valid.initialPointRDSEstesa = true;
          }
          if (
            obj.groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
              .secondaryLocation.specificLocation
          ) {
            road.newEvent.value.finalPointRDSEstesa =
              obj.groupOfLocations[
                indexOfAlertCMethodLinear
              ].linear.alertCLinear.secondaryLocation.specificLocation;
            road.newEvent.valid.finalPointRDSEstesa = true;
          }
          // OFFSET
          road.newEvent.value.distanceFromInitialPointRDSEstesa = obj
            .groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
            .primaryLocation.offsetDistance
            ? obj.groupOfLocations[indexOfAlertCMethodLinear].linear
                .alertCLinear.primaryLocation.offsetDistance
            : 0;
          road.newEvent.valid.distanceFromInitialPointRDSEstesa = true;
          road.newEvent.value.distanceFromFinalPointRDSEstesa = obj
            .groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
            .secondaryLocation.offsetDistance
            ? obj.groupOfLocations[indexOfAlertCMethodLinear].linear
                .alertCLinear.secondaryLocation.offsetDistance
            : 0;
          road.newEvent.valid.distanceFromFinalPointRDSEstesa = true;
          //DIRECTION
          if (
            obj.groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
              .alertCDirection &&
            obj.groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
              .alertCDirection.alertCDirectionCoded
          ) {
            road.newEvent.value.directionRDSEstesaCoded =
              obj.groupOfLocations[
                indexOfAlertCMethodLinear
              ].linear.alertCLinear.alertCDirection.alertCDirectionCoded;
            road.newEvent.valid.directionRDSEstesaCoded = true;
          }
          if (
            obj.groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
              .alertCDirection &&
            obj.groupOfLocations[indexOfAlertCMethodLinear].linear.alertCLinear
              .alertCDirection.alertCDirectionNamed
          ) {
            road.newEvent.value.directionRDSEstesaName =
              obj.groupOfLocations[
                indexOfAlertCMethodLinear
              ].linear.alertCLinear.alertCDirection.alertCDirectionNamed.content;
            road.newEvent.valid.directionRDSEstesaName = true;
          }
        }
        if (
          obj.groupOfLocations[indexOfAlertCMethodLinear].point &&
          obj.groupOfLocations[indexOfAlertCMethodLinear].point.alertCPoint &&
          obj.groupOfLocations[indexOfAlertCMethodLinear].point.alertCPoint
            .primaryLocation &&
          obj.groupOfLocations[indexOfAlertCMethodLinear].point.alertCPoint
            .primaryLocation.alertCLocationName &&
          obj.groupOfLocations[indexOfAlertCMethodLinear].point.alertCPoint
            .primaryLocation.specificLocation
        ) {
          road.newEvent.value.initialPointRDSEstesaName =
            obj.groupOfLocations[
              indexOfAlertCMethodLinear
            ].point.alertCPoint.primaryLocation.alertCLocationName.content;
          // road.newEvent.value.initialPointRDSEstesa = obj.groupOfLocations[
          //   indexOfAlertCMethodLinear
          // ].point.alertCPoint.primaryLocation.specificLocation.toString();
        }
      } else if (
        obj &&
        obj.groupOfLocations &&
        obj.groupOfLocations.length === 2 &&
        obj.groupOfLocations[indexOfLinearWithinElement] &&
        // obj.groupOfLocations[indexOfPointByCooordinates].point &&
        obj.groupOfLocations[indexOfLinearWithinElement].linear &&
        obj.groupOfLocations[indexOfLinearWithinElement].linear
          .linearWithinLinearElement
      ) {
        //CASE: ONE POINT AND ONE LINEAR
        currentLocalizationTab = "Cartografica Puntuale";

        //POINT
        if (
          obj.groupOfLocations[indexOfPointByCooordinates] &&
          obj.groupOfLocations[indexOfPointByCooordinates].locationForDisplay
        ) {
          road.newEvent.value.positionPuntuale =
            obj.groupOfLocations[indexOfPointByCooordinates].locationForDisplay;
          road.newEvent.valid.positionPuntuale = true;
        }
        //LINEAR
        if (
          obj.groupOfLocations[indexOfLinearWithinElement].linear
            .linearWithinLinearElement.linearElement &&
          obj.groupOfLocations[indexOfLinearWithinElement].linear
            .linearWithinLinearElement.linearElement.archId
        ) {
          road.newEvent.value.confirmedArcPuntuale =
            obj.groupOfLocations[
              indexOfLinearWithinElement
            ].linear.linearWithinLinearElement.linearElement.archId;
          road.newEvent.valid.confirmedArcPuntuale = true;
        }
      } else if (
        obj &&
        obj.groupOfLocations &&
        obj.groupOfLocations[indexOfLinearWithinElement] &&
        // obj.groupOfLocations[indexOfPointByCooordinates].point &&
        obj.groupOfLocations[indexOfLinearWithinElement].linear &&
        indexOfAlertCMethodPoint === -1
      ) {
        //CASE: MORE LINEARS
        currentLocalizationTab = "Cartografica Estesa";
        let selectedArcs = [];
        linearWithinElementOnly.forEach((item) => {
          if (item.linear) {
            return selectedArcs.push(
              item.linear.linearWithinLinearElement.linearElement.archId
            );
          }
          if (item.point) {
            //POINT
            if (item.locationForDisplay) {
              road.newEvent.value.positionEstesa = item.locationForDisplay;
              road.newEvent.valid.positionPuntuale = true;
            }
          }
        });
        if (selectedArcs.length > 0) {
          road.newEvent.value.listOfConfirmedArcsEstesa = [...selectedArcs];
          road.newEvent.valid.listOfConfirmedArcsEstesa = true;
        }
      } else if (
        obj &&
        obj.groupOfLocations &&
        obj.groupOfLocations[indexOfPointByCooordinates] &&
        obj.groupOfLocations[indexOfPointByCooordinates].locationForDisplay
      ) {
        // CASE: ONLY POINT
        currentLocalizationTab = "Cartografica Puntuale";
        road.newEvent.value.positionPuntuale =
          obj.groupOfLocations[indexOfPointByCooordinates].locationForDisplay;
        road.newEvent.valid.positionPuntuale = true;
      }
    }

    //ADD EMPTY HOVERED OBJECT
    road.newEvent.hovered = {
      hoveredPoint: {
        object: "",
        x: null,
        y: null,
      },
    };

    return { road, currentLocalizationTab };
  }
}
