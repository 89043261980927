import * as moment from "moment";
import {
  GRAVITY_TYPE,
  EVENT_STATE,
  EVENT_STATE_BE_FE,
  PROBABILITY_OF_OCCURRENCE,
} from "utils/utils";
import * as FilterUtils from "utils/filter-utils";

export const getSelectionLevelText = (values) => {
  if (
    values &&
    Object.keys(values).length > 0 &&
    values.some((o) => o && o.id === "all" && !o.checked)
  ) {
    return `Vista Parziale`;
  } else {
    return `Vista Totale`;
  }
};

export const constructFlatMultiSelect = (tableColumns) => {
  tableColumns = setSelections(tableColumns);
  return tableColumns
    ? Object.values(tableColumns).map((item) => {
        let remapped = {
          label: item.name,
          checked: item.checked !== undefined ? item.checked : true,
          isChild: false,
          show: item.show,
          className: item.id + "_clazz",
          ...item,
        };
        return remapped;
      })
    : [];
};

export const constructMultiSelect = (
  tableColumns,
  nestedFilters,
  selectModal,
  typeOfModal = null
) => {
  tableColumns = setSelections(tableColumns);
  return tableColumns && nestedFilters
    ? Object.values(tableColumns).map((item) => {
        let remapped = {
          label: item.name,
          checked: item.checked !== undefined ? item.checked : true,
          isChild: false,
          show: item.show,
          className: item.id + "_clazz",
          ...item,
        };
        if (nestedFilters[item.id]) {
          let remapFilter = nestedFilters[item.id];
          if (remapFilter) {
            remapped["childrenForHeaderDropdown"] = [...remapFilter];
            if (
              typeOfModal &&
              typeOfModal === "report" &&
              remapped.id === "state"
            ) {
              remapped.childrenForHeaderDropdown.splice(
                remapped.childrenForHeaderDropdown.findIndex(
                  (o) => o.id === "closed"
                )
              );
            }
            if (typeOfModal && remapped.id === "type") {
              remapped.childrenForHeaderDropdown = remapped.childrenForHeaderDropdown.filter(
                (o) => {
                  let found = selectModal.types.find(
                    (x) => x.typeName === o.name
                  );
                  return found ? true : false;
                }
              );
            }
          }
        }
        return remapped;
      })
    : [];
};

export const constructTableDropdown = (
  tableColumns,
  nestedFilters,
  selectModal,
  typeOfModal = null
) => {
  let columnsCopy = deepCopy(tableColumns);

  if (tableColumns && nestedFilters) {
    Object.values(tableColumns).forEach((item) => {
      let remapped = {
        label: item.name,
        children: item.children || [],
        checked: item.checked !== undefined ? item.checked : true,
        isChild: false,
        show: item.show,
        className: item.id + "_clazz",
        ...item,
      };
      if (nestedFilters[item.id]) {
        let remapFilter = nestedFilters[item.id];
        if (remapFilter) {
          remapped["childrenForHeaderDropdown"] = [...remapFilter];
          if (
            typeOfModal &&
            typeOfModal === "report" &&
            remapped.id === "state"
          ) {
            remapped.childrenForHeaderDropdown.splice(
              remapped.childrenForHeaderDropdown.findIndex(
                (o) => o.id === "closed"
              )
            );
          }
          if (typeOfModal && remapped.id === "type") {
            remapped.childrenForHeaderDropdown = remapped.childrenForHeaderDropdown.filter(
              (o) => {
                let found = selectModal.types.find(
                  (x) => x.typeName === o.name
                );
                return found ? true : false;
              }
            );
          }
        }
        columnsCopy[item.id] = remapped;
      }
    });
  }
  return columnsCopy;
};

export const getChildrenAsArrayOfArrays = (selected, currentNode = null) => {
  let hasChildren = selected.filter(
    (item) =>
      item.childrenForHeaderDropdown &&
      item.childrenForHeaderDropdown.length > 0
  );
  return hasChildren.reduce((acc, hasChilds) => {
    hasChilds.childrenForHeaderDropdown.map((_) => {
      if (currentNode && _.id === currentNode.id) {
        _.checked = currentNode.checked;
      }
      return _;
    });
    let key = hasChilds.id;
    acc[key] = hasChilds.childrenForHeaderDropdown;
    return acc;
  }, {});
};

export const setSelections = (tableColumns) => {
  return Object.values(tableColumns).map((_) => {
    _["show"] = _["checked"] !== undefined ? _["checked"] : true;
    return _;
  });
};

export const toggleAll = (selected, all) => {
  let allNodesUpdated = [...selected].map((item) => {
    item.checked = all;
    item.show = all;
    return item;
  });
  return FilterUtils.setSelections(allNodesUpdated);
};

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const remap = (parent, selectModal) => {
  switch (parent.filterName.toLowerCase()) {
    case "probabilityofoccurrence": {
      parent.values = [
        ...parent.values.map((label) => ({
          id: label,
          translate: PROBABILITY_OF_OCCURRENCE[label],
        })),
      ];
      break;
    }
    case "type": {
      parent.values = [
        ...parent.values.map((label) => {
          let translate =
            selectModal &&
            selectModal.types &&
            selectModal.types.find((z) => z.typeName === label);
          return {
            id: label,
            translate: (translate && translate.typeNameIT) || capitalize(label),
          };
        }),
      ];
      break;
    }
    case "severity": {
      parent.values = [
        ...parent.values.map((label) => ({
          id: label,
          translate: GRAVITY_TYPE[label],
        })),
      ];
      break;
    }
    case "state": {
      parent.values = [
        ...parent.values.map((label) => ({
          id: EVENT_STATE_BE_FE[label.toLowerCase()],
          translate: EVENT_STATE[label.toLowerCase()],
        })),
      ];
      break;
    }
    default:
      parent.values = [
        ...parent.values.map((val) => {
          return (
            !val.id && {
              id: val,
              translate: capitalize(val.toLowerCase().split("_").join(" ")),
            }
          );
        }),
      ];
      break;
  }
  return parent;
};

export const toggleAllCheckWhenOtherSelected = (checked, selected) => {
  if (!checked) {
    return false;
  }
  let checkedBoxes = [...selected].slice(1).filter((box) => box.checked);
  if (checkedBoxes.length === selected.length - 1) {
    return true;
  } else {
    return false;
  }
};

export const matchNestedFilters = (saved, fromRest, selectModal) => {
  let remapFilter = [];
  let savedKeys = Object.keys(saved);
  remapFilter = fromRest.map((record) => {
    return remap(record, selectModal);
  });
  remapFilter.forEach((remapped) => {
    let found = savedKeys.find((key) => key === remapped.filterName);
    if (found) {
      saved[found].map((x) => {
        return {
          label: remapped.translate,
          checked: x.checked !== undefined ? x.checked : true,
          name: remapped.id,
          id: remapped.id && remapped.id.toLowerCase(),
          isChild: true,
        };
      });
    } else {
      if (remapped.values) {
        saved[remapped.filterName] = [
          ...remapped.values.map((filterValue) => {
            return {
              label: filterValue.translate,
              checked: true,
              name: filterValue.id,
              id: filterValue.id && filterValue.id.toLowerCase(),
              isChild: true,
            };
          }),
        ];
      }
    }
  });
  return saved;
};

export const resetFilters = (selected) => {
  return selected.map((x) => {
    x.checked = true;
    x.show = true;
    if (x.children && x.children.length > 0) {
      x.children = x.children.map((z) => {
        z.checked = true;
        return z;
      });
    }
    if (x.childrenForHeaderDropdown && x.childrenForHeaderDropdown.length > 0) {
      x.childrenForHeaderDropdown = x.childrenForHeaderDropdown.map((z) => {
        z.checked = true;
        return z;
      });
    }
    return x;
  });
};

export const renderAriaLabelCheckboxes = (multiselect) => {
  multiselect.forEach((item) => {
    let DOMElemRow = document.getElementsByClassName("toggle collapsed");
    if (DOMElemRow) {
      for (var x of DOMElemRow) {
        x.title = "Filtro dati";
      }
    }
    if (item && item.children && item.children.length > 0) {
      let DOMElem = document.getElementById(item.id);
      if (DOMElem) {
        let unCheckedChildren = item.children.find((_) => !_.checked);
        if (unCheckedChildren) {
          DOMElem.indeterminate = true;
        } else {
          DOMElem.indeterminate = false;
          if (!item.show) {
            DOMElem.checked = false;
          }
        }
      }
    }
  });
};

export const sortUtil = (sortedlist, sortConfig) => {
  return sortedlist.sort((a, b) => {
    if (sortConfig.key === "archTime") {
      let aTime = moment(a[sortConfig.key], "HH:mm:ss");
      let bTime = moment(b[sortConfig.key], "HH:mm:ss");
      return sortConfig.direction === "ascending"
        ? aTime.diff(bTime)
        : bTime.diff(aTime);
    } else if (sortConfig.key.toLowerCase().indexOf("time") > -1) {
      let aDate = moment(a[sortConfig.key], "DD/MM/YYYY HH:mm");
      let bDate = moment(b[sortConfig.key], "DD/MM/YYYY HH:mm");
      return sortConfig.direction === "ascending"
        ? aDate.diff(bDate)
        : bDate.diff(aDate);
    } else if (isNaN(a[sortConfig.key]) && isNaN(b[sortConfig.key])) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
    } else {
      return sortConfig.direction === "ascending"
        ? b[sortConfig.key] - a[sortConfig.key]
        : a[sortConfig.key] - b[sortConfig.key];
    }
    return 0;
  });
};

export const handleSelectChange = (
  component,
  visibleColumns,
  FILTER_TARGET
) => {
  const { tableColumns, saveTableFilters, tableFilters } = component.props;

  let setToVisible = { ...tableColumns };

  //LISTA CHECKBOX SPUNTATE E NON E LE USI PER SALVARE STATO COLONNE VISIBILI E NON VISIBILI
  Object.keys(setToVisible).forEach((_) => {
    let found =
      visibleColumns && visibleColumns.find((o) => o.id === setToVisible[_].id);
    setToVisible[_].show = found ? found.show : setToVisible[_].show;
    setToVisible[_].checked = found ? found.checked : setToVisible[_].checked;
  });

  let hasChildren = getChildrenAsArrayOfArrays(visibleColumns, null);

  saveTableFilters(
    {
      ...tableFilters,
      tableColumns: setToVisible,
      toggledFilters: deepCopyObjOfArrays(hasChildren),
    },
    FILTER_TARGET
  );
};

export const deepCopy = (obj) => {
  let copy = {};
  // Object.keys(obj).forEach((x) => copy[x] = {...obj[x] });
  copy = JSON.parse(JSON.stringify(obj));
  return copy;
};

export const deepCopyObjOfArrays = (obj) => {
  let copy = {};
  Object.keys(obj).forEach(
    (x) => (copy[x] = [...obj[x].map((_) => deepCopy(_))])
  );
  return copy;
};

export const deepCopyOfArraysOfObj = (arr) => {
  let copy = [];
  arr.forEach((x, i) => copy.push({ ...deepCopy(arr[i]) }));
  return copy;
};
