import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFF500",
    },
    secondary: {
      main: "#00b0f0",
    },
    background: {
      default: "#000000",
      primary: "#002060",
      secondary: "#262626",
      popup: "#bfbfbf",
      smooth: "#181717",
      hover: "#1e3447",
      error: "#c00000",
      form: "#a3a3a3",
    },
    divider: "#4473c4",
    border: {
      default: "#4473c4",
      disabled: "#d0d8dd",
      bold: "#121212",
      primary: "#a3a3a3",
    },
    text: {
      primary: "#FFFFFF",
    },
    active: "#FFFFFF",
    inactive: "#616161",
  },
  topbar: {
    height: "5rem",
  },
  drawer: {
    width: "16rem",
  },
  rightbar: {
    width: "444px",
  },
});

export default theme;
