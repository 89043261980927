import * as moment from "moment";
import { EVENT_STATE_FE_RECORD_API } from "utils/utils";
import { SituationRecord } from "reducers/events/models/situation-record";
import { take, tap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import * as FilterUtils from "utils/filter-utils";

export const TABLE_COLUMNS = {
  all: {
    name: "(Seleziona tutto)",
    id: "all",
    show: true,
    checked: true,
    order: true,
  },
  checkbox: {
    name: "Selezione Eventi",
    id: "checkbox",
    show: true,
    checked: true,
    order: false,
  },
  icon: {
    name: "Icona",
    id: "icon",
    show: true,
    checked: true,
    order: false,
  },
  eventId: {
    name: "ID Evento",
    id: "eventId",
    show: true,
    checked: true,
    order: true,
  },
  type: {
    name: "Tipo",
    id: "type",
    show: true,
    checked: true,
    order: true,
  },
  subtype: {
    name: "Sottotipo",
    id: "subtype",
    show: true,
    checked: true,
    order: true,
  },
  state: {
    name: "Stato",
    id: "state",
    show: true,
    checked: true,
    order: true,
  },
  location: {
    name: "Localizzazione",
    id: "location",
    show: true,
    checked: true,
    order: false,
  },
  source: {
    name: "Fonte",
    id: "source",
    show: true,
    checked: true,
    order: true,
  },
  severity: {
    name: "Severità",
    id: "severity",
    show: true,
    checked: true,
    order: true,
  },
  probabilityOfOccurrence: {
    name: "Probabilità Accadimento",
    id: "probabilityOfOccurrence",
    show: true,
    checked: true,
    order: true,
  },
  practiceId: {
    name: "ID Pratica",
    id: "practiceId",
    show: true,
    checked: true,
    order: true,
  },
  dateStart: {
    name: "Ora inizio",
    id: "dateStart",
    show: true,
    checked: true,
    order: true,
  },
  dateEnd: {
    name: "Ora fine",
    id: "dateEnd",
    show: true,
    checked: true,
    order: true,
  },
  insertedOn: {
    name: "Ora inserimento",
    id: "insertedOn",
    show: true,
    checked: true,
    order: true,
  },
};

export const DATE_COLUMNS = [
  {
    id: "start",
    name: "Ora inizio",
    selected: true,
  },
  {
    id: "end",
    name: "Ora fine",
    selected: false,
  },
  {
    id: "insert",
    name: "Ora inserimento",
    selected: false,
  },
];

export const MODIFIED_FIELD_MAPPER = {
  TYPE: "Tipo",
  SUBTYPE: "Sottotipo",
  STATE: "Stato",
  LOCATION: "Localizzazione",
  SEVERITY: "Severità",
  PROBABILITY_OF_OCCURRENCE: "Probabilità Accadimento",
  SITUATION_ID: "ID Pratica",
  VALIDITY_START_TIME: "Ora inizio",
  VALIDITY_END_TIME: "Ora fine",
  ATTRIBUTE: "Attributo",
  GENERAL_PUBLIC_COMMENT: "Note",
};

export const ORDER_TYPES = [
  {
    label: "Crescente",
    value: "ASC",
  },
  {
    label: "Decrescente",
    value: "DESC",
  },
];

export const downloadExcelData = (selected) => {
  let newObj = {
    situationRecordId: selected[2].checked,
    type: selected[3].checked,
    subtype: selected[4].checked,
    status: selected[5].checked,
    localization: selected[6].checked,
    source: selected[7].checked,
    severity: selected[8].checked,
    probabilityOfOccurrence: selected[9].checked,
    situationId: selected[10].checked,
    attribute: true,
    note: true,
    start: selected[11].checked,
    end: selected[12].checked,
    created: selected[13].checked,
    vms: false,
  };
  return newObj;
};

export const downloadExcelHistoryData = (selected) => {
  let newObj = {
    situationRecordId: true,
    sourceName: true,
    sourceIdentification: true,
    sourceReference: true,
    operationAction: true,
    operationDate: true,
    fieldChang: true,
    fieldOldValue: true,
    fieldNewValue: true,
  };
  // let newObj = {
  //   situationRecordId: selected[2].checked,
  //   type: selected[3].checked,
  //   subtype: selected[4].checked,
  //   status: selected[5].checked,
  //   localization: selected[6].checked,
  //   source: selected[7].checked,
  //   severity: selected[8].checked,
  //   probabilityOfOccurrence: selected[9].checked,
  //   situationId: selected[10].checked,
  //   attribute: false,
  //   note: false,
  //   start: selected[11].checked,
  //   end: selected[12].checked,
  //   created: selected[13].checked,
  //   vms: false,
  // };
  return newObj;
};

export const isInViewport = (offset = 0, selectedRef) => {
  if (!selectedRef) return false;
  const top = selectedRef.getBoundingClientRect().top;
  return top + offset >= 0 && top - offset <= window.innerHeight;
};

export const filterIfDates = (componentRef, list) => {
  const { dateFrom, dateTo, dateColumns } = componentRef.props;

  if (dateFrom && dateTo && dateColumns) {
    let useFilter = dateColumns.find((item) => item.selected);
    if (useFilter) {
      switch (useFilter.id) {
        case "start":
          list = list.filter(
            (item) =>
              item &&
              item.validity.validityTimeSpecification.overallStartTime &&
              moment(dateFrom.toISOString()).isBefore(
                item.validity.validityTimeSpecification.overallStartTime
              ) &&
              moment(dateTo.toISOString()).isAfter(
                item.validity.validityTimeSpecification.overallStartTime
              )
          );
          break;
        case "end":
          list = list.filter(
            (item) =>
              item &&
              item.validity.validityTimeSpecification.overallEndTime &&
              moment(dateFrom.toISOString()).isBefore(
                item.validity.validityTimeSpecification.overallEndTime
              ) &&
              dateTo &&
              moment(dateTo.toISOString()).isAfter(
                item.validity.validityTimeSpecification.overallEndTime
              )
          );
          break;
        case "insert":
          list = list.filter(
            (item) =>
              item &&
              item.validity.validityTimeSpecification.overallStartTime &&
              moment(dateFrom.toISOString()).isBefore(
                item.validity.validityTimeSpecification.overallStartTime
              ) &&
              moment(dateTo.toISOString()).isAfter(
                item.validity.validityTimeSpecification.overallStartTime
              )
          );
          break;
        default:
          break;
      }
    }
  } else if (dateFrom && !dateTo && dateColumns) {
    let useFilter = dateColumns.find((item) => item.selected);
    if (useFilter) {
      switch (useFilter.id) {
        case "start":
          list = list.filter(
            (item) =>
              item &&
              item.validity.validityTimeSpecification.overallStartTime &&
              moment(dateFrom.toISOString()).isBefore(
                item.validity.validityTimeSpecification.overallStartTime
              )
          );
          break;
        case "end":
          list = list.filter(
            (item) =>
              item &&
              item.validity.validityTimeSpecification.overallEndTime &&
              moment(dateFrom.toISOString()).isBefore(
                item.validity.validityTimeSpecification.overallEndTime
              )
          );
          break;
        case "insert":
          list = list.filter(
            (item) =>
              item &&
              item.validity.validityTimeSpecification.overallStartTime &&
              moment(dateFrom.toISOString()).isBefore(
                item.validity.validityTimeSpecification.overallStartTime
              )
          );
          break;
        default:
          break;
      }
    }
  } else if (!dateFrom && dateTo && dateColumns) {
    let useFilter = dateColumns.find((item) => item.selected);
    if (useFilter) {
      switch (useFilter.id) {
        case "start":
          list = list.filter(
            (item) =>
              item &&
              item.validity.validityTimeSpecification.overallStartTime &&
              moment(dateTo.toISOString()).isAfter(
                item.validity.validityTimeSpecification.overallStartTime
              )
          );
          break;
        case "end":
          list = list.filter(
            (item) =>
              item &&
              item.validity.validityTimeSpecification.overallEndTime &&
              moment(dateTo.toISOString()).isAfter(
                item.validity.validityTimeSpecification.overallEndTime
              )
          );
          break;
        case "insert":
          list = list.filter(
            (item) =>
              item &&
              item.validity.validityTimeSpecification.overallStartTime &&
              moment(dateTo.toISOString()).isAfter(
                item.validity.validityTimeSpecification.overallStartTime
              )
          );
          break;
        default:
          break;
      }
    }
  }
  return list;
};

export const filterIfSelectedFiltered = (componentRef, list) => {
  const { tableColumns } = componentRef.props;

  let filteredList = list;

  if (
    tableColumns &&
    tableColumns["state"] &&
    tableColumns["state"].childrenForHeaderDropdown
  ) {
    filteredList = list.filter((item) => {
      let state = item.validity.validityStatus;
      let filterStateSelected = tableColumns[
        "state"
      ].childrenForHeaderDropdown.filter((sev) => sev.checked);
      if (filterStateSelected.find((item) => item.name === state)) {
        return true;
      } else return false;
    });
  }
  return filteredList;
};

export const mapFromApi = (events) => {
  let eventsPayload = [];
  let elementAPI = 0;
  let pageMax = 0;

  eventsPayload = [...eventsPayload, ...events.payload];
  elementAPI += events.payload.length;
  pageMax += events.totalPages;

  let mapped = [...SituationRecord.fromArray(eventsPayload)];

  return {
    elementAPI: elementAPI,
    pageMax: pageMax,
    mapped: mapped,
  };
};

export const attributesApi = (component, fullMap) => {
  component.subscriptions.push(
    component.apiService
      .getConfigTypesSubtypes(true)
      .pipe(
        take(1),
        tap((x) => {
          let arr = [];
          if (x && !x.error) {
            x.forEach((element) => {
              if (!fullMap) {
                arr.push(...element.completeAttributes);
              } else {
                let attributes = [
                  ...element.completeAttributes.map((x) => x.attributeValues),
                ];
                arr.push(
                  ...element.completeAttributes.map((x) => {
                    return {
                      name: x.attributeName,
                      nameIT: x.attributeNameIT,
                    };
                  })
                );
                arr.push({
                  name: element.typeName,
                  nameIT: element.typeNameIT,
                });
                arr.push(
                  ...element.subTypes.map((x) => {
                    return {
                      name: x.subTypeName,
                      nameIT: x.subTypeNameIT,
                    };
                  })
                );
                arr = [...arr, ...attributes.flat()];
              }
            });
            component.setState({ listAttributes: arr });
          }
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe()
  );
};

export const loadHistoryData = (
  component,
  currentSelected,
  limit = 10,
  offset = 0,
  orderType = "DESC",
  nowOpen
) => {
  const { historyList } = component.state;
  component.apiService
    .getHistorySituationRecords(
      currentSelected.situationRecordId,
      limit,
      offset,
      orderType
    )
    .pipe(
      take(1),
      map((z) => {
        return {
          ...z,
          payload: [
            ...z.payload
              .filter((_) => _.operationAction !== "SAVE")
              .filter((_) => _.fieldChange !== "network"),
          ],
        };
      }),

      tap((_) => {
        component.setState({
          historyList: [...(nowOpen ? historyList : []), ..._.payload],
          currentHistoryPage: offset,
        });
      }),
      catchError((error) => {
        console.error(error);
        return of(error);
      })
    )
    .subscribe();
};

export const initColumns = (
  tableColumns = null,
  dateColumns = null,
  history = null
) => {
  let columns = {};
  let date = [];

  if (!tableColumns) {
    columns = FilterUtils.deepCopy(TABLE_COLUMNS);
    date = [...DATE_COLUMNS];
  } else {
    columns = { ...tableColumns };
    date = dateColumns ? [...dateColumns] : [];
  }

  return { columns: columns, date: date };
};

export const updateValueByWS = (
  componentRef,
  list,
  wsElement,
  isHistory,
  resetListEventWs
) => {
  if (wsElement && wsElement.length > 0) {
    wsElement.forEach((item) => {
      const typeEventWs =
        item.situationRecord &&
        item.situationRecord.validity &&
        item.situationRecord.validity.validityStatus;

      if (item.actionType === "NEW" && item.situationRecord) {
        list.push(item.situationRecord);

        if (typeEventWs === "closed" && !isHistory) {
          list = list.filter(
            (element) => item.situationRecordId !== element.situationRecordId
          );
        }
      } else if (item.actionType === "UPDATE" && item.situationRecord) {
        list = list.map((element) => {
          element = FilterUtils.deepCopy(item.situationRecord);
          return element;
        });

        if (typeEventWs === "closed" && !isHistory) {
          list = list.filter(
            (element) => item.situationRecordId !== element.situationRecordId
          );
        }
      } else if (item.actionType === "EXPIRED") {
        if (isHistory) {
          let found = list.find(
            (x) => item.situationRecordId === x.situationRecordId
          );
          if (found) {
            list = list.map((element) => {
              element.validity.validityStatus = "closed";
              return element;
            });
          }
        } else {
          list = list.filter(
            (element) => item.situationRecordId !== element.situationRecordId
          );
        }
      }
    });
    componentRef.setState({ listEventsFromApi: list });
    resetListEventWs();
  }
};

export const prepareFiltersAPIRecords = (
  applicationPage,
  tableFilters,
  currentPage,
  page,
  sortConfig,
  filterStr
) => {
  let filters = {};

  if (tableFilters && tableFilters.tableColumns) {
    Object.keys(tableFilters.tableColumns).forEach((item) => {
      let keyword = item;
      if (
        tableFilters.tableColumns[keyword] &&
        tableFilters.tableColumns[keyword].childrenForHeaderDropdown
      ) {
        filters[keyword] = tableFilters.tableColumns[
          keyword
        ].childrenForHeaderDropdown
          .map((f) => {
            let copy = { ...f };
            if (item === "state") {
              copy.name = EVENT_STATE_FE_RECORD_API[copy.id];
            }
            return copy;
          })
          .filter((f) => f.checked)
          .map((f) => f.name);
      }
    });
  }

  let states = [];
  if (applicationPage === "report") {
    states = filters.state
      ? filters.state.filter((s) => s !== "CLOSED")
      : ["OPEN", "SCHEDULED"];
  } else if (applicationPage === "history") {
    states = filters.state || ["OPEN", "SCHEDULED", "CLOSED"];
  }
  delete filters.state;

  let currentPageOffset =
    typeof page === "number" && page >= 0 ? page : currentPage;
  let filterDateFrom =
    tableFilters.dateFrom && tableFilters.dateFrom.toISOString();
  let filterDateTo = tableFilters.dateTo && tableFilters.dateTo.toISOString();

  // possibili valori su cui fare filtro temporale filterDateFrom e filterDateTo ['START','END','INSERT']
  let targetDateColumn =
    tableFilters.dateColumns &&
    tableFilters.dateColumns.filter((f) => f.selected)[0];
  let dateFilterTarget = "";
  if (targetDateColumn) {
    switch (targetDateColumn.id.toUpperCase()) {
      case "START":
        dateFilterTarget = "START_DATETIME";
        break;
      case "END":
        dateFilterTarget = "END_DATETIME";
        break;
      default:
        dateFilterTarget = "CREATION_DATETIME";
        break;
    }
  }

  let orderBy;
  switch (sortConfig.key) {
    case "eventId":
      orderBy = "SITUATION_RECORD_ID";
      break;
    case "practiceId":
      orderBy = "SITUATION_ID";
      break;
    case "type":
      orderBy = "SITUATION_RECORD_TYPE";
      break;
    case "subtype":
      orderBy = "SITUATION_RECORD_SUBTYPE";
      break;
    case "insertedOn":
      orderBy = "SITUATION_RECORD_CREATION_TIME";
      break;
    case "state":
      orderBy = "STATE";
      break;
    case "severity":
      orderBy = "SEVERITY";
      break;
    case "probabilityOfOccurrence":
      orderBy = "PROBABILITY_OF_OCCURRENCE";
      break;
    // case "eventId":
    //   orderBy = "SOURCE_IDENTIFICATION";
    //   break;
    case "source":
      orderBy = "SOURCE_REFERENCE";
      break;
    case "dateStart":
      orderBy = "VALIDITY_START_TIME";
      break;
    case "dateEnd":
      orderBy = "VALIDITY_END_TIME";
      break;
    default:
      orderBy = "VALIDITY_START_TIME";
  }
  const orderType = sortConfig.direction === "ascending" ? "ASC" : "DESC";

  return {
    filters: filters,
    currentPageOffset: currentPageOffset,
    filterDateFrom: filterDateFrom,
    filterDateTo: filterDateTo,
    states: states,
    dateFilterTarget: dateFilterTarget,
    orderBy: orderBy,
    orderType: orderType,
    search: filterStr,
  };
};
