import { PathStyleExtension } from "@deck.gl/extensions";
import * as HooksMapDraw from "utils/map-utils/hook-map-drawing";

import {
  ViewMode,
  DrawCircleFromCenterMode,
  DrawLineStringMode,
  DrawPolygonMode,
  MeasureDistanceMode,
  DrawPointMode,
  MeasureAreaMode,
} from "nebula.gl";

export const viewModeLayer = {
  id: "draw-layer",
  mode: ViewMode,
};

export const iconLayer = {
  id: "icon-layer",
  sizeScale: 15,
  pickable: true,
  getPosition: (d) => {
    return d.geometry.coordinates;
  },
  visible: true,
};

export const pathLayer = {
  id: "path-layer",
  getColor: [253, 128, 93],
  /* opacity: 0.8, */
  pickable: true,
  widthMinPixels: 4,
  rounded: true,
  trailLength: 200,
  currentTime: 100,
  visible: true,
};

export const geoJsonLayer = {
  id: "geojson",
  pickable: true,
  getFillColor: [253, 128, 93],
  getLineColor: [253, 128, 93],
  getLineWidth: 1,
  stroked: false,
  extruded: true,
  lineWidthScale: 20,
  lineWidthMinPixels: 2,
  pointRadiusMinPixels: 11,
  getRadius: 10,
  visible: true,
};

export const circleSelectionLayer = {
  id: "circle-layer",
  mode: DrawCircleFromCenterMode,
  stroked: true,
  filled: true,
  getTentativeFillColor: HooksMapDraw._getDeckColorForFeature(0, 0.5, 0.5),
  getTentativeLineColor: [128, 128, 128],
};

export const measureAreaMode = {
  id: "polygon-layer",
  mode: MeasureAreaMode,
  stroked: false,
  filled: true,
  getTentativeFillColor: HooksMapDraw._getDeckColorForFeature(0, 0.5, 0.5),
  getTentativeLineColor: [128, 128, 128],
  getEditHandlePointOutlineColor: [0, 0, 0],
  getEditHandlePointColor: [255, 56, 56],
  getEditHandleIconSize: 15,
  getLineWidth: 3,
};

export const lineDistanceLayer = {
  id: "distance-p2p",
  mode: MeasureDistanceMode,
  stroked: true,
  pickable: true,
  filled: true,
  extruded: true,
  lineWidthMinPixels: 2,
  getTentativeFillColor: [255, 56, 56],
  getTentativeLineColor: [255, 56, 56],
  getEditHandlePointOutlineColor: [0, 0, 0],
  getEditHandlePointColor: [255, 56, 56],
  getEditHandleIconSize: 15,
  getLineWidth: 3,
  getElevation: 30,
  getDashArray: [5, 3],
  dashJustified: true,
  modeConfig: {
    formatTooltip: (distance) =>
      parseFloat(distance).toFixed(2) + " chilometri",
  },
  extensions: [new PathStyleExtension({ dash: true })],
};

export const polygonLayer = {
  id: "polygon-layer",
  mode: DrawPolygonMode,
  stroked: true,
  filled: true,
  getTentativeFillColor: [0, 0, 0, 0],
  //getTentativeFillColor: HooksMapDraw._getDeckColorForFeature(0, 0.5, 0.5),
  getTentativeLineColor: [128, 128, 128],
  getEditHandlePointOutlineColor: [0, 0, 0],
  getEditHandlePointColor: [255, 56, 56],
  getEditHandleIconSize: 15,
  getLineWidth: 3,
  getLineColor: (f) =>
    f.geometry.type === "LineString" ? [128, 128, 128] : [0, 0, 0, 0], //disable the polygon strokes by setting this prop on TileLayer
};

//SHORTEST DISTANCE ON GRAPH BETWEEN TWO POINTS
export const shortestDistanceOnGraphLayer = {
  id: "route-layer",
  mode: DrawLineStringMode,
  stroked: true,
  filled: true,
  lineWidthMinPixels: 2,
  getLineWidth: 3,
  getElevation: 30,
  getTentativeFillColor: [255, 56, 56],
  getTentativeLineColor: [255, 56, 56],
};

export const multipleDistanceLayer = {
  id: "distance-layer",
  mode: DrawLineStringMode,
  stroked: false,
  pickable: true,
  filled: false,
  extruded: true,
  lineWidthMinPixels: 2,
  getTentativeFillColor: [0, 0, 0, 0],
  //getTentativeFillColor: [255, 56, 56],
  getTentativeLineColor: [255, 56, 56],
  getEditHandlePointOutlineColor: [0, 0, 0],
  getEditHandlePointColor: [255, 56, 56],
  getEditHandleIconSize: 15,
  //getFillColor: [255, 56, 56],
  getLineColor: (f) =>
    f.geometry.type === "LineString" ? [255, 56, 56] : [0, 0, 0, 0],
  getLineWidth: 3,
  getElevation: 30,
  getDashArray: [5, 3],
  dashJustified: true,
  extensions: [new PathStyleExtension({ dash: true })],
};

export const pointLayer = {
  id: "point-layer",
  mode: DrawPointMode,
  stroked: true,
};
