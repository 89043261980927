import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
import EnvironmentContext from "environment-context";
//COMPONENTS
import LocalizationCartograficaPuntuale from "components/layout/modal/event/new-event/localization-tab/localization-cartografica-puntuale";
import LocalizationCartograficaPuntualeEstesa from "components/layout/modal/event/new-event/localization-tab/localization-cartografica-estesa";
import LocalizationRDSEstesa from "components/layout/modal/event/new-event/localization-tab/localization-RDS-estesa";
import LocalizationRDSPuntuale from "components/layout/modal/event/new-event/localization-tab/localization-RDS-puntuale";
// STORE AND ACTIONS
import { getTypeLocalization } from "store";
import { setTypeLocalization } from "reducers/ui/modal/modal.actions";

class LocalizationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onTypeChange = (e) => {
    const { setTypeLocalization } = this.props;

    const onchangevalue = e.target.value;
    setTypeLocalization(onchangevalue);
  };

  render() {
    const { currentLocalizationType, pointsListMap } = this.props;

    return (
      <div className="localizzazione-form">
        <form className="uk-form-horizontal padding-form ">
          <div className="uk-margin">
            <label className="uk-form-label">Tipo</label>
            <div className="uk-form-controls">
              <select
                className="uk-select uk-width-1-1"
                value={currentLocalizationType}
                onChange={(e) => this.onTypeChange(e)}
              >
                <option>Cartografica Puntuale</option>
                <option>Cartografica Estesa</option>
                <option>Rete RDS-TMC Puntuale</option>
                <option>Rete RDS-TMC Estesa</option>
              </select>
            </div>
          </div>
        </form>
        {currentLocalizationType === "Cartografica Puntuale" ? (
          <LocalizationCartograficaPuntuale />
        ) : null}
        {currentLocalizationType === "Cartografica Estesa" ? (
          <LocalizationCartograficaPuntualeEstesa />
        ) : null}
        {currentLocalizationType === "Rete RDS-TMC Puntuale" ? (
          <LocalizationRDSPuntuale pointsListMap={pointsListMap} />
        ) : null}
        {currentLocalizationType === "Rete RDS-TMC Estesa" ? (
          <LocalizationRDSEstesa pointsListMap={pointsListMap} />
        ) : null}
      </div>
    );
  }
}

LocalizationTab.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setTypeLocalization,
};

const mapStateToProps = (state) => ({
  currentLocalizationType: getTypeLocalization(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocalizationTab)
);
