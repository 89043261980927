import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// COMPONENTS
import LineStop from "./line-stop";
// STORE
import { getAllActiveLines } from "store";

class Line extends Component {
  render() {
    const { lines } = this.props;
    return (
      <div className="uk-flex uk-flex-column uk-overflow-auto line-container">
        {lines &&
          lines.map((route, ind) => {
            return (
              <div
                key={ind}
                className=" uk-flex uk-flex-row uk-padding uk-padding-remove-bottom uk-line-dimensions"
              >
                <div className="uk-margin-right">
                  <span
                    className={`${
                      route.transportMode &&
                      route.transportMode.subMode &&
                      route.transportMode.subMode.code === "07.01"
                        ? "line-circle-blue"
                        : "line-circle-red"
                    } uk-flex uk-flex-column uk-flex-middle `}
                  >
                    <h6
                      className={`${
                        route.nameShort && route.nameShort.length > 4
                          ? "line-circle-text"
                          : "line-circle-title"
                      }`}
                    >
                      {route.nameShort}
                    </h6>
                    <h6 className="line-circle-text">Linea</h6>
                  </span>
                </div>
                <div className="uk-flex uk-flex-column uk-margin-top">
                  <div className="uk-flex uk-flex-row">
                    {route.points
                      ? route.points.map((stop, index) => [
                          <LineStop
                            key={index}
                            vehicles={route.vehicles}
                            transportMode={
                              route.transportMode &&
                              route.transportMode.subMode &&
                              route.transportMode.subMode.code
                            }
                            stop={stop}
                            index={index}
                            length={route.points.length}
                          />,
                        ])
                      : route.pointsNonOrdered
                      ? route.pointsNonOrdered.map((stop, index) => [
                          <LineStop
                            key={index}
                            vehicles={route.vehicles}
                            transportMode={route.transportMode.subMode.code}
                            stop={stop}
                            index={index}
                            length={route.pointsNonOrdered.length}
                          />,
                        ])
                      : ""}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  lines: getAllActiveLines(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Line));
