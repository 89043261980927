import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentPmvGroup } from "reducers/installations/pmv/pmv.actions";
import { PMV_STATUS } from "utils/utils-pmv";
import { getCurrentPmvGroup } from "store";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";

const mapStateToProps = (state) => ({
  currentPmvGroup: getCurrentPmvGroup(state),
});

const mapDispatchToProps = {
  setCurrentPmvGroup,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class PmvListComponent extends Component {
  togglePmvDetails = (device) => {
    const { toggleDetails } = this.props;
    toggleDetails(device);
  };

  toggleDetailsGroupPmv = (pmv) => {
    const { setCurrentPmvGroup, currentPmvGroup } = this.props;

    let showPopup = currentPmvGroup.findIndex(
      (x) =>
        x.properties &&
        pmv.properties &&
        x.properties.anagrafica &&
        pmv.properties.anagrafica &&
        x.properties.anagrafica.id === pmv.properties.anagrafica.id
    );
    if (pmv) {
      setCurrentPmvGroup(pmv);
    }

    if (
      currentPmvGroup.length > 0 &&
      pmv &&
      pmv.properties &&
      pmv.properties.anagrafica &&
      pmv.properties.anagrafica.gruppo &&
      currentPmvGroup[0] &&
      currentPmvGroup[0].properties &&
      currentPmvGroup[0].properties.anagrafica &&
      pmv.properties.anagrafica.gruppo !==
        currentPmvGroup[0].properties.anagrafica.gruppo &&
      showPopup === -1
    ) {
      UIkit.notification.closeAll("loading");
      UIkit.notification({
        message: "I pannelli selezionati appartengono a gruppi diversi.",
        status: "primary",
        pos: "bottom-center",
        group: "loading",
      });
    }
  };

  render() {
    const { filteredPmvs, currentPmvGroup } = this.props;

    let currentPmvSelection;

    return (
      <div className="uk-list margin-bottom50">
        {filteredPmvs.map((pmv, index) => {
          if (currentPmvGroup && currentPmvGroup.length > 0) {
            currentPmvGroup.forEach((selectedPmv, selectedIndex) => {
              if (
                pmv.properties &&
                pmv.properties.anagrafica &&
                selectedPmv.properties &&
                selectedPmv.properties.anagrafica &&
                pmv.properties.anagrafica.id ===
                  selectedPmv.properties.anagrafica.id
              ) {
                currentPmvSelection = pmv.properties.anagrafica.id;
              }
            });
          }
          const findIcon = PMV_STATUS.find(
            (status) =>
              pmv &&
              pmv.properties &&
              pmv.properties.anagrafica &&
              typeof pmv.properties.anagrafica.stato === "string" &&
              status.name.toLowerCase() ===
                pmv.properties.anagrafica.stato.toLowerCase()
          );

          return (
            <div
              key={index}
              className={
                "uk-flex uk-flex-row uk-flex-middle uk-flex-around uk-width-1-1 height-84"
              }
            >
              <li
                className={
                  currentPmvSelection === pmv.properties.anagrafica.id
                    ? "uk-flex uk-flex-around uk-flex-middle uk-width-1-1  uk-active"
                    : "uk-flex uk-flex-around uk-flex-middle uk-width-1-1"
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (e.ctrlKey) {
                    this.toggleDetailsGroupPmv(pmv);
                  } else {
                    this.togglePmvDetails(pmv);
                  }
                }}
              >
                <div className="uk-position-relative event-height-width-48">
                  <img
                    className={"img-status"}
                    alt={"pmv_img"}
                    src={findIcon && findIcon.icon}
                  />
                </div>
                <div className="uk-flex uk-margin-small-left width-260">
                  <div className="uk-flex uk-flex-row">
                    <div className="uk-flex uk-flex-column headerName">
                      <div className="headerName">
                        {pmv &&
                        pmv.properties &&
                        pmv.properties.anagrafica &&
                        pmv.properties.anagrafica.nome
                          ? pmv.properties.anagrafica.nome
                          : "-"}
                      </div>
                      <div className="uk-width-1-1 uk-margin-right inactive list-text location_info p-dot-2">
                        {pmv &&
                        pmv.properties &&
                        pmv.properties.anagrafica &&
                        pmv.properties.anagrafica.strada
                          ? pmv.properties.anagrafica.strada
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          );
        })}
      </div>
    );
  }
}

export default PmvListComponent;
