import {
  toNameShort,
  lowerCaseAllWordsExceptFirstLetters,
  upperCaseFirstLetter,
  replaceComma,
} from "utils/utils";
import StopModel from "../stops/stops.model";

export const INITIAL_STATE_ROUTES = {
  routes: [],
  current: null,
  cityBus: [],
  regionBus: [],
  cityBusFiltered: [],
  regionBusFiltered: [],
  searchText: "",
  searchType: "",
  bounds: [],
  currentIdStopForRoute: [],
  currentIdTripForRoute: [],
};

export default class RouteModel {
  id;
  nameLong;
  nameShort;
  direction;
  line;
  type;
  vehicles;
  startPoint;
  endPoint;
  links;
  points;
  transportMode;
  routeRef;
  vehicles;

  constructor() {
    this.id = null;
    this.nameLong = null;
    this.nameShort = null;
    this.direction = IDirection;
    this.line = LineShortModel;
    this.type = null;
    this.vehicles = VehiclesModel;
    this.startPoint = IPoint;
    this.endPoint = IPoint;
    this.links = null;
    this.points = null;
    this.transportMode = null;
    this.routeRef = null;
    this.vehicles = [];
  }

  static fromREST(obj) {
    var route = new RouteModel();
    if (obj.route) {
      route.id = obj.route.id;
      route.nameLong = upperCaseFirstLetter(
        lowerCaseAllWordsExceptFirstLetters(replaceComma(obj.route.nameLong))
      );
      route.nameShort = upperCaseFirstLetter(
        lowerCaseAllWordsExceptFirstLetters(replaceComma(obj.route.nameShort))
      );
      route.type = obj.route.type;
      if (obj.route.vehicles)
        route.vehicles = VehiclesModel.fromArray(obj.route.vehicles);
      route.direction = obj.route.direction;
      route.line = LineShortModel.from(obj.route.line);
      route.type = obj.route.type;
      route.transportMode = obj.transportMode;
      route.startPoint = obj.route.startPoint;
      route.endPoint = obj.route.endPoint;
      route.links = obj.orderedLinks;
      route.points = StopModel.fromArrayREST(obj.orderedPoints);
      route.routeRef = obj.route.id;
      if (obj.route && obj.route.vehicles) route.vehicles = obj.route.vehicles;
    }
    return route;
  }

  static fromArrayREST(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(RouteModel.fromREST).filter((item) => item !== null);
  }
}

export class LineShortModel {
  id;
  nameShort;
  network;
  versionId;

  constructor() {
    this.id = null;
    this.nameShort = null;
    this.network = INetwork;
    this.versionId = null;
  }

  static from(obj) {
    var line = new LineShortModel();
    if (obj) {
      line.id = obj.id;
      line.nameShort = toNameShort(obj.nameShort);
      line.network = obj.network;
      line.versionId = obj.versionId;
    }
    return line;
  }
}

const IDirection = {
  code: null,
  description: null,
};

const INetwork = {
  id: null,
  nameShort: null,
};

const IPoint = {
  id: null,
  nameShort: null,
};

export class VehiclesModel {
  vehicleMonitoringRef;
  progressBetweenStops;
  direction;
  order;
  lastStopPointRef;
  nextStopPointRef;

  constructor() {
    this.vehicleMonitoringRef = null;
    this.progressBetweenStops = null;
    this.direction = null;
    this.order = 0;
    this.lastStopPointRef = null;
    this.nextStopPointRef = null;
  }

  static from(obj) {
    var vehicle = new VehiclesModel();
    vehicle.vehicleMonitoringRef = obj.vehicleMonitoringRef;
    vehicle.progressBetweenStops = obj.progressBetweenStops;
    vehicle.direction = obj.direction;
    vehicle.order = obj.order;
    vehicle.lastStopPointRef = obj.lastStopPointRef;
    vehicle.nextStopPointRef = obj.nextStopPointRef;
    return vehicle;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(VehiclesModel.from).filter((item) => item !== null);
  }
}

export class VersionedLinkInLinkSequence {
  changed;
  created;
  id;
  networkId;
  order;
  version;

  constructor() {
    this.changed = null;
    this.created = null;
    this.id = null;
    this.networkId = null;
    this.order = null;
    this.version = null;
  }

  static from(obj) {
    var link = new VersionedLinkInLinkSequence();
    if (obj) {
      link.changed = obj.changed;
      link.created = obj.created;
      link.id = obj.id;
      link.networkId = obj.networkId;
      link.order = obj.order;
      link.version = VersionModel.from(obj.version);
    }
    return link;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(VersionedLinkInLinkSequence.from)
      .filter((item) => item != null);
  }
}

export class VersionedPointInLinkSequence {
  changed;
  created;
  id;
  networkId;
  order;
  version;

  constructor() {
    this.changed = null;
    this.created = null;
    this.id = null;
    this.networkId = null;
    this.order = null;
    this.version = null;
  }

  static from(obj) {
    var link = new VersionedPointInLinkSequence();
    link.changed = obj.changed;
    link.created = obj.created;
    link.id = obj.id;
    link.networkId = obj.networkId;
    link.order = obj.order;
    link.version = VersionModel.from(obj.version);
    return link;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(VersionedPointInLinkSequence.from)
      .filter((item) => item != null);
  }
}

export class VersionModel {
  changed;
  created;
  data;
  endDate;
  startDate;
  versionId;
  id;
  status;
  type;

  constructor() {
    this.changed = null;
    this.created = null;
    this.id = null;
    this.status = null;
    this.data = null;
    this.type = null;
    this.endDate = null;
    this.startDate = null;
    this.versionId = null;
  }

  static from(obj) {
    var v = new VersionModel();
    if (obj) {
      v.changed = obj.changed;
      v.created = obj.created;
      v.id = obj.id;
      v.data = obj.data;
      v.type = obj.type;
      v.status = obj.status;
      v.versionId = obj.versionId;
      v.endDate = obj.endDate;
      v.startDate = obj.startDate;
    }
    return v;
  }
}
