import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
//ACTIONS
import { getTabModal } from "store";
//MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";
//MODAL COMPONENT
import GeneralTab from "components/layout/modal/event/new-event/general-tab/general-tab";
import LocalizationTab from "components/layout/modal/event/new-event/localization-tab/localization-tab";
import AttributesTab from "components/layout/modal/event/new-event/attributes-tab/attributes-tab";
import InfoTab from "components/layout/modal/event/new-event/info-tab/info-tab";
import InnerHeader from "components/layout/modal/event/new-event/inner-header";

class NewEventContainer extends Component {
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {
      pointsListMap: [],
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
    this.getApiPointsListMap();
  }

  //API CALL TO POINTS LIST
  getApiPointsListMap = () => {
    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures("points", null, null, null, null, null, null)
        .pipe(
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "points"
            );
            let points = [];
            if (featureCollection.numberReturned > 0) {
              points = [...featureCollection.features];
            }

            return this.setState({
              ...this.state,
              pointsListMap: points,
            });
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  render() {
    const { modalTabReducer } = this.props;
    const { pointsListMap } = this.state;

    return (
      <div className="uk-height-1-1">
        <InnerHeader />
        {modalTabReducer === "general" ? <GeneralTab></GeneralTab> : null}
        {modalTabReducer === "localization" ? (
          <LocalizationTab pointsListMap={pointsListMap}></LocalizationTab>
        ) : null}
        {modalTabReducer === "attributes" ? (
          <AttributesTab></AttributesTab>
        ) : null}
        {modalTabReducer === "info" ? <InfoTab></InfoTab> : null}
      </div>
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

NewEventContainer.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  modalTabReducer: getTabModal(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewEventContainer)
);
