import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//COMPONENTS
import Permission from "components/shared/permission";
//STORE AND ACTIONS
import {
  isOpenNavTab,
  isPmvVisible,
  getPermissions,
  isAesysPanelVisible,
  isSolariPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
  isOpenNavPmv,
} from "store";

const mapStateToProps = (state) => ({
  isOpenNavTab: isOpenNavTab(state),
  isPmvVisible: isPmvVisible(state),
  permissions: getPermissions(state),
  isSolariPanelVisible: isSolariPanelVisible(state),
  isAesysPanelVisible: isAesysPanelVisible(state),
  isFuturitPanelVisible: isFuturitPanelVisible(state),
  isVisuallabPanelVisible: isVisuallabPanelVisible(state),
  isSfheraPanelVisible: isSfheraPanelVisible(state),
  isOpenNavPmv: isOpenNavPmv(state),
});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemInstallationPmv extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isPmvVisible,
      isOpenNavPmv,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      clickOnPmvCheckbox,
      clickOnPmvLabel,
    } = this.props;

    return (
      <Permission actions="PMV_MONITORING">
        <div
          className={
            isOpenNavPmv
              ? "uk-checkbox-title uk-open acr-pmv-open"
              : "uk-checkbox-title"
          }
        >
          <span>
            <input
              className="uk-checkbox uk-checkbox-large "
              type="checkbox"
              checked={isPmvVisible || false}
              onChange={() => clickOnPmvCheckbox("all")}
            />
          </span>
          <label onClick={() => clickOnPmvLabel()}>Pannelli</label>
        </div>

        <div className="uk-checkbox-tree-sub acr-pmv-sub">
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={isSolariPanelVisible || false}
              onChange={() => clickOnPmvCheckbox("solari")}
            />
            Solari
          </label>
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={isAesysPanelVisible || false}
              onChange={() => clickOnPmvCheckbox("aesys")}
            />
            Aesys
          </label>
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={isFuturitPanelVisible || false}
              onChange={() => clickOnPmvCheckbox("futurit")}
            />
            Futurit
          </label>
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={isVisuallabPanelVisible || false}
              onChange={() => clickOnPmvCheckbox("visuallab")}
            />
            Visual Lab
          </label>
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={isSfheraPanelVisible || false}
              onChange={() =>clickOnPmvCheckbox("sfhera")}
            />
            Sfhera
          </label>
        </div>
      </Permission>
    );
  }
}

export default NavItemInstallationPmv;
