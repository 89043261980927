import * as HooksMapDraw from "utils/map-utils/hook-map-drawing";

function hex2rgb(hex) {
  const value = parseInt(hex, 16);
  return [16, 8, 0].map((shift) => ((value >> shift) & 0xff) / 255);
}

const FEATURE_COLORS = [
  "F9E900",
  "C00000",
  "FFFFFF",
  "00AEE4",
  "DAF0E3",
  "9BCC32",
  "07A35A",
  "F7DF90",
  "EA376C",
  "6A126A",
  "FCB09B",
  "B0592D",
  "C1B5E3",
  "9C805B",
  "CCDFE5",
].map(hex2rgb);

export function _getDeckColorForFeature(index, bright, alpha) {
  // const length = FEATURE_COLORS.length;
  const color = FEATURE_COLORS[index].map((c) => c * bright * 255);

  return [...color, alpha * 255];
}

export const metersPerPx = (map) => {
  if (map) {
    return (
      (((156543.03392 / 2) * Math.cos((map.getCenter().lat * Math.PI) / 180)) /
        Math.pow(2, map.getZoom())) *
      50
    );
  } else {
    return 0;
  }
};

export const featuresToInfoString = (featureCollection) => {
  const info = featureCollection.features.map(
    (feature) =>
      `${feature.geometry.type}(${getPositionCount(feature.geometry)})`
  );

  return JSON.stringify(info);
};

function getPositionCount(geometry) {
  const flatMap = (f, arr) => arr.reduce((x, y) => [...x, ...f(y)], []);

  const { type, coordinates } = geometry;
  switch (type) {
    case "Point":
      return 1;
    case "LineString":
    case "MultiPoint":
      return coordinates.length;
    case "Polygon":
    case "MultiLineString":
      return flatMap((x) => x, coordinates).length;
    case "MultiPolygon":
      return flatMap((x) => flatMap((y) => y, x), coordinates).length;
    default:
      throw Error(`Unknown geometry type: ${type}`);
  }
}

export function getFillColor(feature, isSelected) {
  const index = feature.geometry.type !== "LineString" ? 0 : 1;
  const bright = feature.geometry.type !== "LineString" ? 0.5 : 1;
  const alpha = feature.geometry.type !== "LineString" ? 0.5 : 1;
  return HooksMapDraw._getDeckColorForFeature(index, bright, alpha);
}

export function getLineColor(feature, isSelected) {
  const index = feature.geometry.type !== "LineString" ? 2 : 1;
  const bright = feature.geometry.type !== "LineString" ? 0.5 : 1;
  const alpha = feature.geometry.type !== "LineString" ? 0.8 : 1;
  return HooksMapDraw._getDeckColorForFeature(index, bright, alpha);
}
