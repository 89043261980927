import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import Permission from "components/shared/permission";
import "./style.less";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
//ACTIONS
import {
  setSourceSelectModal,
  setTypesSubtypesSelectModal,
} from "reducers/modal/modal.actions";
import { setBounds, filterByMapBounds } from "reducers/map/map.actions";
import { setNavTabSearch } from "reducers/ui/nav-tab/nav-tab.actions";
import {
  getCurrentEvent,
  getCurrentEventGroup,
  getCurrentNetwork,
  getNewEvent,
  getActiveEventsFiltered,
  getPlannedEventsFiltered,
  getActiveEvents,
  getPlannedEvents,
  isActiveVisible,
  isPlannedVisible,
  getBoundingBox,
  getNavTabSelectedTab,
  getSelectModal,
  isWSEventsOpen,
  getAllEvents,
  getPermissions,
  isEventsLoading,
  isEventsAllLoading,
  getCurrentWorkspace,
  getCurrentZoom,
} from "store";
import {
  setEventsList,
  setCurrentEvent,
  updateEventsWS,
  setCurrentEventGroup,
} from "reducers/events/events.actions";
import { wsOpen } from "reducers/ui/ws/ws.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import { setTypeModal, toggleModal } from "reducers/ui/modal/modal.actions";
//MODEL
import { SituationRecord } from "reducers/events/models/situation-record";
// PROJECT IMPORTS
import EventList from "components/layout/event-list";
import NavTab from "components/layout/nav-tab";
import EventDetail from "components/layout/event-list/event-detail/index";
import EventPractice from "components/layout/event-list/event-practice/index";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import BottomControlsIcons from "components/shared/bottom-controls/bottom-controls-icons";
//UTIL
import { filterBounds } from "components/layout/map/load-map/load-map";

//UTIL
import { checkEventEnableUpdatesBySource } from "utils/utils";
import { getSelectedEvents } from "store";

class EventPage extends Component {
  subscriptions = [];
  apiService;
  eventWs = [];

  constructor(props) {
    super(props);
    this.state = {
      eventPractice: false,
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  configModal = () => {
    const { isWSEventsOpen } = this.props;
    if (!isWSEventsOpen) this.wsEvents();
  };

  wsEvents = () => {
    this.apiService.getEventsRuntime(
      this.eventSourceMessage,
      this.eventSourceErr,
      this.eventSourceOpen
    );
  };

  eventSourceOpen = (e) => {
    const { wsOpen, isWSEventsOpen } = this.props;
    if (!isWSEventsOpen) wsOpen("events", true);
  };

  eventSourceErr = (e) => {
    const { wsOpen, isWSEventsOpen } = this.props;
    if (isWSEventsOpen) wsOpen("events", false);
  };

  eventSourceMessage = (e) => {
    const { updateEventsWS } = this.props;

    if (e && e.data) {
      let data = JSON.parse(e.data);
      if (data && (!data.status || data.status !== "alive")) {
        let arrEv = [];
        let eventWs = [];
        !(data instanceof Array) ? eventWs.push(data) : (eventWs = data);
        eventWs.forEach((item) => {
          if (item.header) {
            arrEv.push({
              situationRecord: item.payload
                ? SituationRecord.from(item.payload.situationRecord)
                : null,
              situationRecordId: item.header.situationRecordId,
              actionType: item.header.sourceType,
            });
          }
        });
        if (arrEv && arrEv.length > 0) {
          updateEventsWS(arrEv);
        }
      }
    }
  };

  toggleDetails = (event) => {
    const { setCurrentEvent } = this.props;
    setCurrentEvent(event);
  };

  toggleDetailsGroupEvent = (event) => {
    const { setCurrentEventGroup, selectModal } = this.props;

    if (
      event &&
      selectModal &&
      selectModal.sources &&
      checkEventEnableUpdatesBySource(event, selectModal.sources)
    ) {
      setCurrentEventGroup(event);
    } else {
      UIkit.notification.closeAll("loading");
      UIkit.notification({
        message: "Non si può modificare questo evento",
        status: "primary",
        pos: "bottom-center",
        group: "loading",
      });
    }
  };

  setTypeModal = (type) => {
    const { setTypeModal, toggleModal } = this.props;
    setTypeModal(type);
    toggleModal();
  };

  injectItems = () => {
    const {
      currentEvent,
      activeEventsFiltered,
      plannedEventsFiltered,
      isActiveVisible,
      isPlannedVisible,
      toggleGenericTable,
      currentEventGroup,
      permissions,
      selectModal,
      isEventsLoading,
    } = this.props;
    const { eventPractice } = this.state;

    const eventRedirectPermission = permissions.indexOf("EVENT_REDIRECT") >= 0;

    let listEvent = [];
    if (isActiveVisible) listEvent.push(...activeEventsFiltered);
    if (isPlannedVisible) listEvent.push(...plannedEventsFiltered);
    listEvent.sort((a, b) => {
      if (a.situationId < b.situationId) {
        return -1;
      }
      if (a.situationId > b.situationId) {
        return 1;
      }
      return 0;
    });

    let controlsList = [
      {
        icon: "acr-event-nuovo-evento",
        tooltip: "Inserisci Nuovo Evento",
        className: "eventi-icon",
        toggle: (e) => {
          e.preventDefault();
          this.setTypeModal("newEvent");
        },
      },
      {
        icon: "acr-interface-list",
        tooltip: "Resoconto Eventi",
        className: "table-icon",
        toggle: () => toggleGenericTable("eventReportTable"),
      },
      {
        icon: "acr-interface-chron-list",
        tooltip: "Storico Eventi",
        className: "storicoEventi-icon",
        toggle: () => toggleGenericTable("eventHistoryTable"),
      },
    ];

    if (currentEventGroup.length >= 2) {
      controlsList.push({
        icon: "acr-event-raggruppa-eventi",
        tooltip: "Raggruppa Eventi",
        toggle: (e) => {
          e.preventDefault();
          this.setTypeModal("practiceManagement");
        },
      });
    }

    if (eventRedirectPermission) {
      controlsList.push({
        icon: "forward",
        tooltip: "Nodo Datex",
        toggle: (e) => {
          e.preventDefault();
          window.open(this.context.endpointMovaliaDatex, "_tab");
        },
      });
    }

    let controlsDetail = [
      {
        icon: "acr-event-nuova-pratica",
        tooltip: "Aggiungi evento associato",
        className: "eventi-icon",
        toggle: (e) => {
          e.preventDefault();
          if (currentEvent) {
            this.setTypeModal("addAssociatedEvent");
          }
        },
      },
      {
        icon: "acr-interface-list",
        tooltip: "Resoconto Eventi",
        className: "table-icon",
        toggle: () => toggleGenericTable("eventReportTable"),
      },
      {
        icon: "acr-interface-chron-list",
        tooltip: "Storico Eventi",
        className: "storicoEventi-icon",
        toggle: () => toggleGenericTable("eventHistoryTable"),
      },
    ];

    if (
      currentEvent &&
      selectModal &&
      selectModal.sources &&
      checkEventEnableUpdatesBySource(currentEvent, selectModal.sources)
    ) {
      controlsDetail.splice(1, 0, {
        icon: "acr-event-modifica-evento",
        tooltip: "Modifica Evento",
        className: "eventi-icon",
        toggle: (e) => {
          e.preventDefault();
          if (currentEvent) {
            this.setTypeModal("modifyEvent");
          }
        },
      });
      controlsDetail.splice(2, 0, {
        icon: "acr-event-chiudi-pratica",
        tooltip: "Gestione Pratica",
        className: "gestionePratica-icon ",
        toggle: (e) => {
          e.preventDefault();
          if (currentEvent && currentEvent.situationId) {
            this.setTypeModal("practiceManagement");
          }
        },
      });
    }

    return !currentEvent ? (
      <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
        <div className="uk-height-1-1 overflow event-list-container">
          <ErrorBoundary>
            <EventList
              toggleDetails={this.toggleDetails}
              toggleDetailsGroupEvent={this.toggleDetailsGroupEvent}
              listEvent={listEvent}
              isEventsLoading={isEventsLoading}
            ></EventList>
          </ErrorBoundary>
        </div>
        <Permission actions="EVENT_MODIFY">
          <BottomControlsIcons controls={controlsList} />
        </Permission>
      </div>
    ) : (
      <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
        <div className="uk-height-1-1 overflow">
          <ErrorBoundary>
            {!eventPractice && (
              <EventDetail
                handleClickEventPractice={this.handleClickEventPractice}
                toggleDetails={this.toggleDetails}
                listEvent={listEvent}
              />
            )}
            {eventPractice && (
              <EventPractice
                handleClickEventPractice={this.handleClickEventPractice}
                toggleDetails={this.toggleDetails}
              />
            )}
          </ErrorBoundary>
        </div>
        <Permission actions="EVENT_MODIFY">
          <BottomControlsIcons controls={controlsDetail} />
        </Permission>
      </div>
    );
  };

  handleClickEventPractice = () => {
    this.setState({ eventPractice: !this.state.eventPractice });
  };

  handleSearch = (value) => {
    const { setNavTabSearch, selectModal } = this.props;

    setNavTabSearch(value, "eventi", null, selectModal);

    if (value === "") {
      filterBounds(this);
    }
  };

  render() {
    return (
      <NavTab
        isOpen={true}
        items={this.injectItems()}
        onSearch={this.handleSearch}
      />
    );
  }

  componentWillUnmount() {
    const { setNavTabSearch } = this.props;
    this.subscriptions.forEach((x) => x.unsubscribe());
    setNavTabSearch("", "eventi", null, null);
  }
}

EventPage.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setEventsList,
  setCurrentEvent,
  setCurrentEventGroup,
  setNavTabSearch,
  toggleGenericTable,
  setTypeModal,
  toggleModal,
  setSourceSelectModal,
  setTypesSubtypesSelectModal,
  updateEventsWS,
  setBounds,
  filterByMapBounds,
  wsOpen,
};

const mapStateToProps = (state) => ({
  currentEvent: getCurrentEvent(state),
  activeEvents: getActiveEvents(state),
  plannedEvents: getPlannedEvents(state),
  activeEventsFiltered: getActiveEventsFiltered(state),
  plannedEventsFiltered: getPlannedEventsFiltered(state),
  isActiveVisible: isActiveVisible(state),
  isPlannedVisible: isPlannedVisible(state),
  networkCurrentReducer: getCurrentNetwork(state),
  newEvent: getNewEvent(state),
  currentEventGroup: getCurrentEventGroup(state),
  boundsReducer: getBoundingBox(state),
  menuTabReducer: getNavTabSelectedTab(state),
  selectModal: getSelectModal(state),
  isWSEventsOpen: isWSEventsOpen(state),
  eventsReducer: getAllEvents(state),
  selectedEvents: getSelectedEvents(state),
  permissions: getPermissions(state),
  isEventsLoading: isEventsLoading(state),
  isEventsAllLoading: isEventsAllLoading(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
  currentMapZoom: getCurrentZoom(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventPage)
);
