import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getCurrentParking } from "store";
import { setCurrentParking } from "reducers/installations/parking/parking.actions";

//UTILS
import {
  getParkingIcon,
  getParkingLayoutTranslation,
  getParkingType1Translation,
  getParkingPayment,
  getParkingType2Translation,
} from "utils/utils-parking";

const mapStateToProps = (state) => ({
  currentParking: getCurrentParking(state),
});

const mapDispatchToProps = {
  setCurrentParking,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ParkingsDetailComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeDetails = () => {
    const { setCurrentParking } = this.props;
    setCurrentParking(null);
  };

  header = () => {
    const { currentParking } = this.props;

    const parkingIcon = getParkingIcon(currentParking);

    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="uk-position-relative event-height-width-48">
          <img className={"img-status"} alt={"webcam_img"} src={parkingIcon} />
        </div>
        <div className="uk-flex uk-flex-column ">
          <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-muted-event">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.parkingName
              ? currentParking.properties.parkingName
              : "-"}
          </div>
          <div className=" uk-margin-left uk-margin-right text-line-width text-detail-muted-event location_info p-dot-2">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.parkingAddress
              ? currentParking.properties.parkingAddress
              : "-"}
          </div>
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            this.closeDetails();
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClickSecondTab } = this.props;

    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Anagrafica</a>
          </li>
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickSecondTab();
            }}
          >
            <a href="/#">Dati Dinamici</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { currentParking } = this.props;

    return (
      <div className={"uk-flex uk-flex-column contentBodyStationDetail"}>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Nome</div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.parkingName
              ? currentParking.properties.parkingName
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Descrizione</div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.parkingDescription
              ? currentParking.properties.parkingDescription
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Gestore</div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.responsibleAuthorityName
              ? currentParking.properties.responsibleAuthorityName
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Indirizzo</div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.parkingAddress
              ? currentParking.properties.parkingAddress
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Posizione</div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            currentParking.properties.parkingCoordinates &&
            currentParking.properties.parkingCoordinates.latitude &&
            currentParking.properties.parkingCoordinates.longitude
              ? currentParking.properties.parkingCoordinates.latitude +
                "," +
                currentParking.properties.parkingCoordinates.longitude
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Tipologia</div>
          <div className="text-detail-emphasis-station-detail">
            {getParkingPayment(currentParking.properties.parkingPayment)}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Tipo Struttura</div>
          <div className="text-detail-emphasis-station-detail">
            {getParkingLayoutTranslation(
              currentParking &&
                currentParking.properties &&
                currentParking.properties.parkingLayout
                ? currentParking.properties.parkingLayout
                : null
            )}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Classificazione
          </div>
          <div className="text-detail-emphasis-station-detail">
            {getParkingType1Translation(currentParking)}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Classificazione Secondaria
          </div>
          <div className="text-detail-emphasis-station-detail">
            {getParkingType2Translation(currentParking)}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Capacità massima
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentParking &&
            currentParking.properties &&
            (currentParking.properties.parkingNumberOfSpaces ||
              currentParking.properties.parkingNumberOfSpaces === 0)
              ? currentParking.properties.parkingNumberOfSpaces
              : "-"}
          </div>
        </li>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}

export default ParkingsDetailComponent;
