import { CompositeLayer } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
import Supercluster from "supercluster";
import pmv_img_red_filled from "assets/icon/installations/pmv-states/pmv_red_filled.svg";
import pmv_img_green_filled from "assets/icon/installations/pmv-states/pmv_green_filled.svg";
import pmv_img_gray_filled from "assets/icon/installations/pmv-states/pmv_gray_filled.svg";
import pmv_img_yellow_filled from "assets/icon/installations/pmv-states/pmv_yellow_filled.svg";
import pmv_img_blue_filled from "assets/icon/installations/pmv-states/pmv_blue_filled.svg";

function getIconName(size) {
  if (size === 0) {
    return "";
  }
  if (size < 10) {
    return `marker-${size}`;
  }
  if (size < 300) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  return "marker-300";
}

// function getIconSize(size) {
//   return Math.min(100, size) / 100 + 1;
// }

class IconClusterPmvLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: 18,
        radius: 18,
        map: (props) => {
          return {
            anagrafica: {
              stato: props.anagrafica && props.anagrafica.stato,
            },
          };
        },
        reduce: (acc, prs) => {
          if (
            acc.anagrafica &&
            prs.anagrafica &&
            (acc.anagrafica.stato === "Offline" ||
              prs.anagrafica.stato === "Offline")
          ) {
            acc.anagrafica.stato = "Offline";
          } else if (
            acc.anagrafica &&
            prs.anagrafica &&
            (acc.anagrafica.stato === "Sconosciuto" ||
              prs.anagrafica.stato === "Sconosciuto")
          ) {
            acc.anagrafica.stato = "Sconosciuto";
          } else if (
            acc.anagrafica &&
            prs.anagrafica &&
            (acc.anagrafica.stato === "Guasto" ||
              prs.anagrafica.stato === "Guasto")
          ) {
            acc.anagrafica.stato = "Guasto";
          } else if (
            acc.anagrafica &&
            prs.anagrafica &&
            (acc.anagrafica.stato === "Degradato" ||
              prs.anagrafica.stato === "Degradato")
          ) {
            acc.anagrafica.stato = "Degradato";
          } else {
            acc.anagrafica.stato = "Normale";
          }
        },
      });
      index.load(
        props.data.map((d) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d.properties,
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== "hover") {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    const { iconAtlas, iconMapping, sizeScale } = this.props;

    const ICON_PARKING_MAPPING = {
      marker: {
        x: 0,
        y: 0,
        width: 49,
        height: 49,
        mask: false,
        anchorY: 20,
        anchorX: 10,
      },
    };
    let normaleStateArray = [];
    let degradatoStateArray = [];
    let guastoStateArray = [];
    let sconosciutoStateArray = [];
    let offlineStateArray = [];

    normaleStateArray = data.filter(
      (item) =>
        item.properties &&
        item.properties.anagrafica &&
        item.properties.anagrafica.stato &&
        item.properties.anagrafica.stato === "Normale"
    );
    degradatoStateArray = data.filter(
      (item) =>
        item.properties &&
        item.properties.anagrafica &&
        item.properties.anagrafica.stato &&
        item.properties.anagrafica.stato === "Degradato"
    );
    guastoStateArray = data.filter(
      (item) =>
        item.properties &&
        item.properties.anagrafica &&
        item.properties.anagrafica.stato &&
        item.properties.anagrafica.stato === "Guasto"
    );
    sconosciutoStateArray = data.filter(
      (item) =>
        item.properties &&
        item.properties.anagrafica &&
        item.properties.anagrafica.stato &&
        item.properties.anagrafica.stato === "Sconosciuto"
    );
    offlineStateArray = data.filter(
      (item) =>
        item.properties &&
        item.properties.anagrafica &&
        item.properties.anagrafica.stato &&
        item.properties.anagrafica.stato === "Offline"
    );
    return [
      new IconLayer(
        this.getSubLayerProps({
          id: "pmv-icon-normale",
          data: normaleStateArray,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: pmv_img_green_filled,
          iconMapping: ICON_PARKING_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "pmv-icon-degradato",
          data: degradatoStateArray,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: pmv_img_yellow_filled,
          iconMapping: ICON_PARKING_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "pmv-icon-guasto",
          data: guastoStateArray,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: pmv_img_red_filled,
          iconMapping: ICON_PARKING_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "pmv-icon-sconosciuto",
          data: sconosciutoStateArray,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: pmv_img_gray_filled,
          iconMapping: ICON_PARKING_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "pmv-icon-offline",
          data: offlineStateArray,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: pmv_img_blue_filled,
          iconMapping: ICON_PARKING_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "pmv-number-icon",
          data,
          iconAtlas,
          iconMapping,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) =>
            getIconName(d.properties.cluster ? d.properties.point_count : 1),
          getSize: (d) => 8,
        })
      ),
    ];
  }
}

IconClusterPmvLayer.layerName = "IconClusterPmvLayer";

export default IconClusterPmvLayer;
