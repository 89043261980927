import * as turf from "@turf/turf";
import { WebMercatorViewport } from "@deck.gl/core";

export function getBoundingBoxStringForBboxParam(boundingBox) {
  let bbox = boundingBox.map((item) => String(item)).join(",");
  bbox = bbox.concat(",EPSG:4326");
  return bbox;
}

export function getBoundingBoxFilter(boundingBox) {
  let bbox = {
    bassoSinistra: {
      latitudine: boundingBox[1],
      longitudine: boundingBox[0],
    },
    altoDestra: {
      latitudine: boundingBox[3],
      longitudine: boundingBox[2],
    },
  };
  return bbox;
}

export function getBoundingBoxStringForBboxParamNoEpsg(boundingBox) {
  if (boundingBox && boundingBox.length > 0) {
    const bbox = boundingBox.map((item) => String(item)).join(",");
    return bbox;
  } else {
    return [];
  }
}

export function getBoundingBoxStringForCqlFilter(boundingBox) {
  let newBounds =
    boundingBox[0] +
    " " +
    boundingBox[1] +
    "," +
    boundingBox[0] +
    " " +
    boundingBox[3] +
    "," +
    boundingBox[2] +
    " " +
    boundingBox[3] +
    "," +
    boundingBox[2] +
    " " +
    boundingBox[1] +
    "," +
    boundingBox[0] +
    " " +
    boundingBox[1];

  return newBounds;
}

export function getCqlCircleString(mapPolygonReducer) {
  let options = { units: "meters" };
  let circle = turf.circle(
    mapPolygonReducer.center,
    mapPolygonReducer.radius,
    options
  );
  let circleString = circle.geometry.coordinates[0]
    .map((item) => {
      return item[0] + " " + item[1];
    })
    .join(",");

  return `within(geom,POLYGON((${circleString})))`;
}

export function getCqLPolygonString(mapPolygonReducer) {
  let polygonString = mapPolygonReducer.polygoncoordinates
    .map((item) => {
      return item[0] + " " + item[1];
    })
    .join(",");

  return `within(geom,POLYGON((${polygonString})))`;
}

export function getArcFilterByBboxAndFrcParam(zoom, bounds) {
  let stringFilterByFrc = null;
  if (zoom <= 11) {
    stringFilterByFrc = `frc=0 AND INTERSECTS(geom,POLYGON((${bounds})))`;
  } else if (zoom <= 15) {
    stringFilterByFrc = `(frc=0 OR frc=1 OR frc=2) AND INTERSECTS(geom,POLYGON((${bounds})))`;
  } else if (zoom > 15 && zoom <= 16) {
    stringFilterByFrc = `(frc=0 OR frc=1 OR frc=2 OR frc=3 OR frc=4) AND INTERSECTS(geom,POLYGON((${bounds})))`;
  } else {
    stringFilterByFrc = `INTERSECTS(geom,POLYGON((${bounds})))`;
  }

  return stringFilterByFrc;
}

export function getBoundsFromWebmercatorView(
  long,
  lat,
  zoom,
  deckHeight,
  deckWidth
) {
  const viewport = new WebMercatorViewport({
    pitch: 0,
    bearing: 0,
    longitude: lat,
    latitude: long,
    zoom: zoom,
    height: deckWidth,
    width: deckWidth,
  });

  const nw = viewport.unproject([0, 0]);
  const se = viewport.unproject([viewport.height, viewport.width]);

  const newBounds = [nw[0], se[1], se[0], nw[1]];

  return newBounds;
}
