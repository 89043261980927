import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./style.less";
import { getWorseStationSensorStateDetail } from "utils/utils";
import { ResponsiveLine } from "@nivo/line";
import * as moment from "moment";

//ACTIONS
import { getCurrentStation, isGenericTableToggled } from "store";
import EnvironmentContext from "environment-context";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
//IMG
import stations_img_red from "assets/icon/multitab-icns/Tab_StazioniMisuraRed.svg";
import stations_img_green from "assets/icon/multitab-icns/Tab_StazioniMisuraGreen.svg";
import stations_img_gray from "assets/icon/multitab-icns/Tab_StazioniMisuraGrey.svg";
//UTILS
import { getTrafficMaxIntervalTime } from "utils/utils-traffic";

class StationDetail extends Component {
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {
      dataUpdate: null,
    };
  }

  componentDidMount = () => {
    const { data } = this.props;
    if (data && data[0] && data[0].data.length > 288) {
      let newdataUpdate = data.map((element) => {
        return {
          ...element,
          data: element.data.filter((item, index) => {
            return index % 8 === 0;
          }),
        };
      });
      this.setState({
        dataUpdate: newdataUpdate,
      });
    } else {
      this.setState({
        dataUpdate: data,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    const { dataUpdate } = this.state;

    if (
      (data &&
        data[0] &&
        data[0].data[0] &&
        data[0].data[0].x &&
        prevProps.data &&
        prevProps.data[0] &&
        prevProps.data[0].data[0] &&
        prevProps.data[0].data[0].x &&
        data[0].data[0].x !== prevProps.data[0].data[0].x) ||
      (data &&
        data[0] &&
        data[0].data[0] &&
        data[0].data[0].y &&
        prevProps.data &&
        prevProps.data[0] &&
        prevProps.data[0].data[0] &&
        prevProps.data[0].data[0].y &&
        data[0].data[0].y !== prevProps.data[0].data[0].y) ||
      (!prevProps.data[0] && !dataUpdate) ||
      (dataUpdate &&
        dataUpdate[0] &&
        dataUpdate[0].data &&
        dataUpdate[0].data.length === 0 &&
        data &&
        data[0] &&
        data[0].data &&
        data[0].data.length > 0)
    ) {
      this.setState({
        dataUpdate: data,
      });
    }
  };
  header = () => {
    const {
      toggleDetails,
      currentStation,
      toggleGenericTable,
      isGenericTableToggled,
    } = this.props;

    let stateSensor = getWorseStationSensorStateDetail(currentStation);
    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="uk-position-relative height-width-48">
          <img
            alt={currentStation.sensorId}
            className={"img-status"}
            src={
              stateSensor && stateSensor === "NO_INFO"
                ? stations_img_gray
                : stateSensor === "DOWN"
                ? stations_img_red
                : stations_img_green
            }
          />
        </div>
        <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-muted-event">
          {currentStation &&
          currentStation.archName &&
          currentStation.directionName
            ? currentStation.archName +
              ", direzione " +
              currentStation.directionName
            : currentStation.archName}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            if (isGenericTableToggled) {
              toggleGenericTable("stationsTable");
            }
            toggleDetails(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const {
      currentStation,
      data,
      defaultMeasure,
      handleSelectMeasure,
      currentArc,
    } = this.props;
    const { dataUpdate } = this.state;

    const isThereAnyNonNullValue =
      data[0] &&
      data[0].data.length > 0 &&
      data[0].data.find((item) => item.y !== null);
    const differentTime = getTrafficMaxIntervalTime(data);

    return (
      <div className={"uk-flex uk-flex-column contentBodyStationDetail"}>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Descrizione</div>
          <div className="text-detail-emphasis-station-detail">
            {currentStation &&
            currentStation.archName &&
            currentStation.directionName
              ? currentStation.archName +
                " direzione " +
                currentStation.directionName
              : currentStation && currentStation.archName
              ? currentStation.archName
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Associazione Arco
          </div>
          {currentArc &&
          currentArc.features &&
          currentArc.features[0] &&
          currentArc.features[0].properties &&
          currentArc.features[0].properties.name ? (
            <div className="text-detail-emphasis-station-detail">
              <div
                className="check-color"
                uk-icon="ratio: 2; icon: acr-interface-check"
              ></div>
              <div> {currentArc.features[0].properties.name}</div>
            </div>
          ) : (
            <div className="text-detail-emphasis-station-detail">
              <div
                className="check-color-red"
                uk-icon="ratio: 2; icon: close"
              ></div>
              <div className="arc-padding">-</div>
            </div>
          )}
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Tipologia Sensori
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentStation.sensortype ? currentStation.sensortype : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Stato Sensori</div>
          <div className="text-detail-emphasis-station-detail">
            <ul>
              {currentStation &&
                currentStation.sensors &&
                currentStation.sensors[0] &&
                currentStation.sensors.map((item) => {
                  return (
                    <li className="display-sensor" key={item.sensorId}>
                      <div className="display-padding">
                        {item.sensorId ? item.sensorId : "-"}
                      </div>
                      <div
                        className={
                          item.status.status === "DOWN"
                            ? "sensore-guasto"
                            : item.status.status === "NO_INFO"
                            ? "sensore-no-info"
                            : "sensore-funzionante"
                        }
                      >
                        {item &&
                          item.status &&
                          item.status.status &&
                          item.status.status.toUpperCase()}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Tipologia Misura
          </div>
          <div className="text-detail-emphasis-station-detail">
            <select
              className="uk-select select-style-sel"
              value={
                defaultMeasure && defaultMeasure.name ? defaultMeasure.name : ""
              }
              onChange={(e) => handleSelectMeasure(e.target.value)}
            >
              <option>{defaultMeasure && defaultMeasure.nameIt}</option>;
              {currentStation &&
                currentStation.supportedMeasure.map((item, index) => {
                  return defaultMeasure && defaultMeasure.name !== item.name ? (
                    <option key={index} value={item.name}>
                      {item.nameIt}
                    </option>
                  ) : (
                    ""
                  );
                })}
            </select>
          </div>
        </li>
        <li className="uk-flex uk-flex-row height-chart">
          <div className="text-detail-emphasis-station-detail height-chart">
            {isThereAnyNonNullValue ? (
              <ResponsiveLine
                data={dataUpdate && dataUpdate.length > 0 ? dataUpdate : data}
                margin={{ top: 50, right: 40, bottom: 50, left: 60 }}
                xScale={{
                  format: "%Y-%m-%dT%H:%M:%S.%L%Z",
                  type: "time",
                }}
                xFormat="time:%Y-%m-%dT%H:%M:%S.%L%Z"
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 90,
                  legend: "",
                  legendOffset: 45,
                  legendPosition: "middle",
                  format: "%H:%M",
                  tickValues:
                    differentTime <= 40
                      ? "every 5 minutes"
                      : differentTime <= 120
                      ? "every 15 minutes"
                      : differentTime <= 360
                      ? "every 30 minutes"
                      : differentTime <= 720
                      ? "every 1 hour"
                      : differentTime <= 2160
                      ? "every 2 hours"
                      : differentTime <= 6480
                      ? "every 4 hours"
                      : "every 8 hours",
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend:
                    defaultMeasure &&
                    defaultMeasure.nameIt + " [" + defaultMeasure.unit + "]",
                  legendOffset: -55,
                  legendPosition: "middle",
                  fill: "rgba(0, 0, 0, 0)",
                }}
                colors={{ scheme: "spectral" }}
                pointSize={10}
                pointColor="black"
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                areaOpacity={0}
                crosshairType="right"
                useMesh={true}
                theme={{
                  textColor: "#ffffff",
                  fontSize: "15px",
                  tooltip: {
                    container: {
                      background: "#333",
                    },
                  },
                  axis: {
                    tickColor: "#eee",
                    ticks: {
                      line: {
                        stroke: "#555555",
                      },
                      text: {
                        fill: "#ffffff",
                        fontSize: "15px",
                      },
                    },
                    legend: {
                      text: {
                        fill: "#ffffff",
                        fontSize: "15px",
                      },
                    },
                  },
                  grid: {
                    line: {
                      stroke: "#5c5c5c",
                      strokeWidth: 1,
                      strokeDasharray: "4 4",
                    },
                  },
                }}
                legends={[
                  {
                    anchor: "top-left",
                    direction: "row",
                    justify: false,
                    translateX: -50,
                    translateY: -34,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 0,
                    itemOpacity: 0.75,
                    symbolSize: 18,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    itemTextColor: "#ffffff",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                          fontSize: "35px",
                        },
                      },
                    ],
                  },
                ]}
                tooltip={(slice) => {
                  let half = data[0].data.length / 2;
                  return (
                    <div
                      style={{
                        background: "#0f0f0f",
                        padding: "9px 12px",
                        border: "1px solid #ccc",
                        position: "absolute",
                        right: slice.point.index < half ? 10 : -125,
                      }}
                    >
                      x=
                      {[slice.point.data].map((i) => {
                        return moment(new Date(i.xFormatted)).format(
                          "DD/MM/YYYY-HH:mm"
                        );
                      })}
                      <div>
                        y=
                        {[slice.point.data].map((i) => {
                          return i.y;
                        })}
                      </div>
                    </div>
                  );
                }}
              />
            ) : (
              "NO DATA"
            )}
          </div>
        </li>
      </div>
    );
  };

  render() {
    const { currentStation } = this.props;

    return (
      <div>
        {this.header()}
        {currentStation && currentStation.stationId && this.tabs()}
        {this.body()}
      </div>
    );
  }
}

StationDetail.contextType = EnvironmentContext;

const mapDispatchToProps = { toggleGenericTable };

const mapStateToProps = (state) => ({
  currentStation: getCurrentStation(state),
  currentStationDetail: getCurrentStation(state),
  isGenericTableToggled: isGenericTableToggled(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StationDetail)
);
