import React, { Component } from "react";
import "./style.less";
class PageNumbers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      handleClickDecrease,
      handleClickIncrease,
      getPageNumber,
      currentPage,
      minVal,
      pageMax,
    } = this.props;

    return (
      <div className="uk-flex button-page">
        <button
          className="uk-icon uk-card-secondary uk-width-1-5 uk-margin-small-right uk-border-circle"
          uk-icon="icon: arrow-left"
          onClick={(e) => {
            e.preventDefault();
            handleClickDecrease();
          }}
        ></button>

        <div className="uk-width-1-2">
          <input
            type="number"
            className="uk-input editable-number-page uk-margin-small-right page-number-input"
            value={currentPage}
            min={minVal}
            max={pageMax}
            onChange={(e) => getPageNumber(e)}
          />
        </div>
        <div className="pageMax-number uk-width-1-2">{" / " + pageMax}</div>

        <button
          className="uk-icon uk-card-secondary uk-width-1-5 uk-margin-small-left uk-border-circle"
          uk-icon="icon: arrow-right"
          onClick={(e) => {
            e.preventDefault();
            handleClickIncrease();
          }}
        ></button>
      </div>
    );
  }
}

export default PageNumbers;
