import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";

class TmcPointsLayer extends CompositeLayer {
  renderLayers() {
    const { data } = this.props;

    return new IconLayer(
      this.getSubLayerProps({
        id: "rds-tmc-icon-points-modal",
        data: data,
        pickable: true,
        ...DeckLayerUtil.loadOptions(),
        getIcon: (obj) => ({
          url: DeckLayerUtil.getIconPointTmc(obj),
          width: 35,
          height: 40,
          anchorX: 10,
          anchorY: 35,
        }),
        sizeScale: 8,
        showCluster: false,
        visible: true,
        getPosition: (d) => [parseFloat(d.lon), parseFloat(d.lat)],
        getSize: 8,
      })
    );
  }
}

TmcPointsLayer.layerName = "TmcPointsLayerModal";

export default TmcPointsLayer;
