import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./style.less";
//STORE
import {
  isArcVisible,
  isNodeVisible,
  isCentroidVisible,
  isCityBusVisible,
  isRegionBusVisible,
  isActiveVisible,
  isPlannedVisible,
  isParkingVisible,
  isOpenNavTab,
  isAllCamerasVisible,
  areAllTrafficVisible,
  isAesysPanelVisible,
  isSolariPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
  getCurrentWorkspace,
} from "store";
//ACTIONS
import {
  closeBottombar,
  resetBottombarTabs,
} from "reducers/ui/bottombar/bottombar.actions";
import { setEventsList } from "reducers/events/events.actions";
import {
  toggleOpenNavTab,
  setNavTabMultitab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import {
  toggleNavGraph,
  toggleNavTPL,
  toggleNavEvents,
  toggleNavTraffic,
  toggleNavWebcams,
  toggleNavPmv,
  toggleNavParking,
} from "reducers/ui/nav/nav.actions";
import {
  setGraphVisibility,
  setNetworkRdsTmcVisibility,
} from "reducers/ui/graph-menu/graph-menu.actions";
import { setTransportVisibility } from "reducers/ui/tpl-menu/tpl-menu.actions";
import {
  setAllEventVisibility,
  setActiveVisibility,
  setPlannedVisibility,
} from "reducers/ui/event-menu/event-menu.actions";
import {
  setTrafficVisibility,
  setTypicalVisibility,
  setNetStateVisibility,
} from "reducers/ui/traffic-menu/traffic-menu.actions";
import {
  setAllCamerasVisibility,
  setParkingVisibility,
  setPmvVisibility,
  setStationVisibility,
  setAesysPanelVisibility,
  setSolariPanelVisibility,
  setFuturitPanelVisibility,
  setVisuallabPanelVisibility,
  setSpheraPanelVisibility
} from "reducers/ui/installations-menu/installations-menu.actions";
//COMPONENTS
import ErrorBoundary from "../../shared/error-boundary/error-boundary";
import NavItemMobility from "./mobility/nav-item-mobility";
import NavItemInstallations from "./installation/nav-item-installations";
import NavItemUser from "./user/nav-item-user";
import NavItemNetwork from "./network/nav-item-network";
import SelectNetwork from "../network/select-network";

class Nav extends Component {
  subscriptions = [];
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
    };
  }

  goToHome = () => {
    const { history, closeBottombar, resetBottombarTabs } = this.props;
    closeBottombar();
    resetBottombarTabs();

    history.push("/home");
    setNavTabMultitab("");
  };

  goToRoadConditions = (route) => {
    const { history, setNavTabMultitab } = this.props;
    if (route) {
      history.push("/viability" + route);
    } else {
      history.push("/viability");
      setNavTabMultitab("");
    }
  };

  goToInstallations = (route) => {
    const { history, setNavTabMultitab } = this.props;

    if (route) {
      history.push("/installations/" + route);
    } else {
      history.push("/installations");
      setNavTabMultitab("");
    }
  };

  goToNetwork = (route) => {
    const { history, setNavTabMultitab } = this.props;

    if (route) {
      history.push("/network/" + route);
    } else {
      history.push("/network");
      setNavTabMultitab("");
    }
  };

  handleNetworkClick = () => {
    const {
      toggleNavGraph,
      arcChecked,
      nodeChecked,
      centroidChecked,
    } = this.props;

    this.goToNetwork();
    this.setState({ checked: true });

    //IF SOME SUB-CHECK IS CHECKED, OPEN DOWN
    if (arcChecked || nodeChecked || centroidChecked) {
      toggleNavGraph(true);
    }
  };

  handleMultiNetworkClick = () => {
    //const { isOpenNavTab, toggleOpenNavTab } = this.props;
    /* if (isOpenNavTab) {
    toggleOpenNavTab(false);
  } */
    this.goToHome();
  };

  handleViabilityClick = () => {
    const {
      cityBusChecked,
      regionBusChecked,
      toggleNavTPL,
      toggleNavEvents,
      activeChecked,
      plannedChecked,
      allTrafficVisible,
      toggleNavTraffic,
    } = this.props;

    this.goToRoadConditions(null);
    this.setState({ checked: true });

    if (cityBusChecked || regionBusChecked) {
      toggleNavTPL(true);
    }

    if (allTrafficVisible) {
      toggleNavTraffic(true);
    }

    if (activeChecked || plannedChecked) {
      toggleNavEvents(true);
    }
  };

  handleInstallationsClick = () => {
    const {
      isAllCamerasVisible,
      toggleNavWebcams,
      isAesysPanelVisible,
      isSolariPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      toggleNavPmv,
      toggleNavParking,
    } = this.props;

    this.goToInstallations();
    this.setState({ checked: true });

    if (isAllCamerasVisible) {
      toggleNavWebcams(true);
    }

    if (isParkingVisible) {
      toggleNavParking(true);
    }

    if (
      isAesysPanelVisible ||
      isSolariPanelVisible ||
      isFuturitPanelVisible ||
      isVisuallabPanelVisible ||
      isSfheraPanelVisible
    ) {
      toggleNavPmv(true);
    }
  };

  handleChangeChk = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  };

  render() {
    const { checked } = this.state;

    return (
      <div className="acr-nav uk-nav-toggle-container uk-padding">
        <div className="acr-logo">
          <div className="acr-name">ACR</div>
          <div className="acr-name-long">
            <div>Authority</div>
            <div>Control Room</div>
          </div>
        </div>

        <input
          type="checkbox"
          checked={checked}
          onChange={this.handleChangeChk}
          className="uk-nav-toggle no-border-clickClick cursor-pointer"
        />

        <ul
          className="uk-nav-primary uk-nav-parent-icon uk-nav"
          uk-nav="multiple: false"
        >
          <ErrorBoundary>
            <NavItemNetwork
              checked={checked}
              onClickNetwork={this.handleNetworkClick}
              goToNetwork={this.goToNetwork}
            ></NavItemNetwork>
          </ErrorBoundary>

          <ErrorBoundary>
            <NavItemMobility
              checked={checked}
              onClickMobility={this.handleViabilityClick}
              goToRoadConditions={this.goToRoadConditions}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <NavItemInstallations
              checked={checked}
              onClickInstallations={this.handleInstallationsClick}
              goToInstallations={this.goToInstallations}
            />
          </ErrorBoundary>

          <NavItemUser />

          <li className="acr-version">{process.env.REACT_APP_VERSION}</li>
        </ul>
        <ErrorBoundary>
          <SelectNetwork handleNetworkClick={this.handleMultiNetworkClick} />
        </ErrorBoundary>
      </div>
    );
  }
}

const mapDispatchToProps = {
  closeBottombar,
  resetBottombarTabs,
  setEventsList,
  toggleOpenNavTab,
  toggleNavGraph,
  toggleNavTPL,
  toggleNavEvents,
  toggleNavTraffic,
  setNavTabMultitab,
  toggleNavWebcams,
  toggleNavPmv,
  toggleNavParking,
  setGraphVisibility,
  setNetworkRdsTmcVisibility,
  setTransportVisibility,
  setAllEventVisibility,
  setActiveVisibility,
  setPlannedVisibility,
  setTrafficVisibility,
  setTypicalVisibility,
  setNetStateVisibility,
  setAllCamerasVisibility,
  setParkingVisibility,
  setPmvVisibility,
  setStationVisibility,
  setAesysPanelVisibility,
  setSolariPanelVisibility,
  setFuturitPanelVisibility,
  setVisuallabPanelVisibility,
  setSpheraPanelVisibility
};

const mapStateToProps = (state) => ({
  isOpenNavTab: isOpenNavTab(state),
  arcChecked: isArcVisible(state),
  nodeChecked: isNodeVisible(state),
  centroidChecked: isCentroidVisible(state),
  cityBusChecked: isCityBusVisible(state),
  regionBusChecked: isRegionBusVisible(state),
  activeChecked: isActiveVisible(state),
  plannedChecked: isPlannedVisible(state),
  isAllCamerasVisible: isAllCamerasVisible(state),
  isParkingVisible: isParkingVisible(state),
  allTrafficVisible: areAllTrafficVisible(state),
  isSolariPanelVisible: isSolariPanelVisible(state),
  isAesysPanelVisible: isAesysPanelVisible(state),
  isFuturitPanelVisible: isFuturitPanelVisible(state),
  isVisuallabPanelVisible: isVisuallabPanelVisible(state),
  isSfheraPanelVisible: isSfheraPanelVisible(state),
  currentWorkspace: getCurrentWorkspace(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));
