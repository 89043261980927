import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { of, merge } from "rxjs";
import {
  tap,
  take,
  catchError,
  concatMap,
  finalize,
  mergeMap,
} from "rxjs/operators";
import "./style.less";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
//COMPONENTS
import EventList from "components/layout/event-list/index.js";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import BottomControlsIcons from "components/shared/bottom-controls/bottom-controls-icons";
import ArcDetailHeader from "../arc-detail-header";
//ACTIONS AND STORE
import {
  getCurrentArc,
  getAllEvents,
  getActiveEventsFiltered,
  isActiveVisible,
  isPlannedVisible,
  getCurrentEvent,
} from "store";
import { setCurrentEvent } from "reducers/events/events.actions";
import { SituationRecord } from "reducers/events/models/situation-record";
import { setTypeModal, toggleModal } from "reducers/ui/modal/modal.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import { setNewEvent } from "reducers/modal/modal.actions";
import {
  setSourceSelectModal,
  setTypesSubtypesSelectModal,
} from "reducers/modal/modal.actions";
import { toggleNavEvents } from "reducers/ui/nav/nav.actions";
import { toggleOpenNavTab } from "reducers/ui/nav-tab/nav-tab.actions";

class ArcEvent extends Component {
  subscriptions = [];
  arrEvtMock = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {
      listEvents: [],
      isEventsLoading: false,
      isEventsAllLoading: false,
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
    this.configModal();
    this.eventCallApi();
  }

  configModal = () => {
    this.configSourceModal();
    this.configTypesSubtypesModal();
  };

  configSourceModal = () => {
    const { setSourceSelectModal } = this.props;
    this.subscriptions.push(
      this.apiService
        .getConfigSources()
        .pipe(
          take(1),
          tap((x) => {
            if (x && !x.error) {
              x.map((item, index) => {
                return {
                  sourceId: item.sourceId,
                  enableUpdates: item.enableUpdates,
                  sourceIndex: index,
                };
              });
              setSourceSelectModal(x);
            } else {
              console.log(x);
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  configTypesSubtypesModal = () => {
    const { setTypesSubtypesSelectModal } = this.props;
    this.subscriptions.push(
      this.apiService
        .getConfigTypesSubtypes()
        .pipe(
          take(1),
          tap((x) => {
            if (x && !x.error) {
              let types = [].concat(
                x.filter((obj) => {
                  return obj.subTypes.length > 0;
                })
              );

              setTypesSubtypesSelectModal(types);
            } else {
              console.log(x);
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  eventCallApi = (idNetwork) => {
    const { networkCurrentReducer } = this.props;

    let idNet = [];
    networkCurrentReducer && networkCurrentReducer.length > 0
      ? networkCurrentReducer.forEach((x) => idNet.push(x.id))
      : idNet.push(idNetwork);
    if (idNet && idNet.length > 0) {
      this.getEventsOnArch();
    }
  };

  getEventsOnArch = () => {
    const { currentArc } = this.props;

    let now = null;
    let arrayIntervalsScheduled = [];
    let arrayIntervalsOpen = [];
    this.setState({
      listEvents: [],
    });
    currentArc.properties = {
      ...currentArc.properties,
      radiusOfInfluence: 1000,
    };
    this.subscriptions.push(
      this.apiService
        .getSituationRecords(1, 0, now, "SCHEDULED", null, null, null, [
          currentArc.properties.arcid,
        ])
        .pipe(
          tap((scheduled) => {
            if (scheduled && !scheduled.error && scheduled.totalElements) {
              let totalInArray = 0;
              for (let i = 0; i <= 1000; i++) {
                if (scheduled.totalElements <= totalInArray) {
                  break;
                } else {
                  arrayIntervalsScheduled.push({
                    limit: 50,
                    offset: i,
                  });
                  totalInArray += 50;
                }
              }
            }
          }),
          mergeMap(() =>
            merge(this.scheduledEvents(arrayIntervalsScheduled, now))
          ),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.apiService
        .getSituationRecords(1, 0, now, "OPEN", null, null, null, [
          currentArc.properties.arcid,
        ])
        .pipe(
          tap((open) => {
            if (open && !open.error && open.totalElements) {
              let totalInArray = 0;
              for (let i = 0; i <= 1000; i++) {
                if (open.totalElements <= totalInArray) {
                  break;
                } else {
                  arrayIntervalsOpen.push({
                    limit: 50,
                    offset: i,
                  });
                  totalInArray += 50;
                }
              }
            }
          }),
          mergeMap(() => merge(this.openEvents(arrayIntervalsOpen, now))),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  scheduledEvents = (arr, now) => {
    const { listEvents } = this.state;
    const { currentArc } = this.props;

    return of(...arr).pipe(
      concatMap((interval) =>
        this.apiService.getSituationRecords(
          interval.limit,
          interval.offset,
          now,
          "SCHEDULED",
          null,
          null,
          null,
          [currentArc.properties.arcid]
        )
      ),
      tap((scheduled) => {
        let scheduledRoadSituations = [];
        if (scheduled && !scheduled.error && scheduled.payload) {
          scheduledRoadSituations = SituationRecord.fromArray(
            scheduled.payload
          );
        }
        this.setState({
          isEventsLoading: false,
          listEvents: [...listEvents, ...scheduledRoadSituations],
        });
      }),
      finalize(() => {
        this.setState({ isEventsAllLoading: false });
      }),
      catchError((error) => {
        this.setState({ isEventsLoading: false, isEventsAllLoading: false });
        console.error(error);
        return of(error);
      })
    );
  };

  openEvents = (arr, now) => {
    const { listEvents } = this.state;
    const { currentArc } = this.props;

    return of(...arr).pipe(
      concatMap((interval) =>
        this.apiService.getSituationRecords(
          interval.limit,
          interval.offset,
          now,
          "OPEN",
          null,
          null,
          null,
          [currentArc.properties.arcid]
        )
      ),
      tap((open) => {
        let openRoadSituations = [];
        if (open && !open.error && open.payload) {
          openRoadSituations = SituationRecord.fromArray(open.payload);
        }
        this.setState({
          isEventsLoading: false,
          listEvents: [...listEvents, ...openRoadSituations],
        });
      }),
      finalize(() => {
        this.setState({ isEventsAllLoading: false });
      }),
      catchError((error) => {
        this.setState({
          isEventsLoading: false,
          isEventsAllLoading: false,
        });
        console.error(error);
        return of(error);
      })
    );
  };

  header = () => {
    const { toggleArcDetails, currentArc, isOpenNavTab } = this.props;

    return (
      <ArcDetailHeader
        isOpenNavTab={isOpenNavTab}
        toggleArcDetails={toggleArcDetails}
        currentArc={currentArc}
      />
    );
  };

  tabs = () => {
    const { handleClickSecondTab } = this.props;
    return (
      <div className="uk-flex stop-tabs underScrolling">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickSecondTab();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Eventi</a>
          </li>
        </ul>
      </div>
    );
  };

  setTypeModal = (type) => {
    const { setTypeModal, toggleModal, setNewEvent, currentArc } = this.props;
    setTypeModal(type);
    toggleModal();
    setNewEvent("selectedArcPuntuale", currentArc);
    setNewEvent("selectedArcToAddIndexPuntuale", 0);
    setNewEvent("listOfCloseArcsPuntuale", [currentArc]);
    setNewEvent("selectedArcEstesa", currentArc);
    setNewEvent("selectedArcToAddIndexEstesa", 0);
    setNewEvent("listOfCloseArcsEstesa", [currentArc]);
  };

  toggleDetails = (event) => {
    const {
      toggleNavEvents,
      toggleOpenNavTab,
      history,
      location,
      setCurrentEvent,
      currentEvent,
    } = this.props;

    if (location.pathname.includes("graph")) {
      if (
        currentEvent &&
        currentEvent.situationRecordId !== event.situationRecordId
      ) {
        toggleNavEvents(true);
        history.push("/viability/events");
        setCurrentEvent(event);
      } else if (currentEvent === null) {
        toggleNavEvents(true);
        history.push("/viability/events");
        setCurrentEvent(event);
      } else {
        toggleNavEvents(true);
        history.push("/viability/events");
      }
    }

    toggleOpenNavTab(true);
  };

  body = () => {
    const { toggleGenericTable } = this.props;
    const { listEvents } = this.state;

    const controlsList = [
      {
        icon: "acr-event-nuovo-evento",
        tooltip: "Inserisci Nuovo Evento",
        className: "eventi-icon",
        toggle: (e) => {
          e.preventDefault();
          this.setTypeModal("newEvent");
        },
      },
      {
        icon: "acr-interface-list",
        tooltip: "Resoconto Eventi",
        className: "table-icon",
        toggle: () => {
          toggleGenericTable("eventReportTable");
        },
      },
    ];

    return (
      <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
        <div className="uk-height-1-1 overflow event-list-container">
          <ErrorBoundary>
            <EventList
              toggleDetails={this.toggleDetails}
              listEvent={listEvents}
            ></EventList>
          </ErrorBoundary>
        </div>
        <BottomControlsIcons controls={controlsList} />
      </div>
    );
  };

  render() {
    return (
      <div className="inherit-transition uk-height-1-1">
        {this.header()}
        {this.tabs()}
        <div className="contentEvent uk-height-1-1">{this.body()}</div>
      </div>
    );
  }

  componentWillUnmount = () => {
    this.subscriptions.forEach((sub$) => sub$.unsubscribe());
  };
}

const mapStateToProps = (state) => ({
  currentArc: getCurrentArc(state),
  listEvent: getAllEvents(state),
  isActiveVisible: isActiveVisible(state),
  isPlannedVisible: isPlannedVisible(state),
  activeEvents: getActiveEventsFiltered(state),
  currentEvent: getCurrentEvent(state),
});

ArcEvent.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setTypeModal,
  toggleModal,
  toggleGenericTable,
  setNewEvent,
  setSourceSelectModal,
  setTypesSubtypesSelectModal,
  setCurrentEvent,
  toggleNavEvents,
  toggleOpenNavTab,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArcEvent)
);
