import * as CustomLayers from "utils/map-utils/layer-difinitions";

export function drawCircle(mapComponent) {
  const {
    mapPolygonReducer,
    setPolygon,
    resetGeoJsonObj,
    controlsReducer,
    setMode,
  } = mapComponent.props;
  if (
    (mapPolygonReducer && mapPolygonReducer.radius) ||
    controlsReducer.circleSelected
  ) {
    resetGeoJsonObj();
    setPolygon(null);
    setMode(null);
  } else {
    mapComponent.deleteLayer();
    setMode(CustomLayers.circleSelectionLayer);
  }
}

export function goBackward(mapComponent) {
  const { historyPrev, setMode } = mapComponent.props;
  setMode(CustomLayers.circleSelectionLayer);
  historyPrev();
}

export function goForward(mapComponent) {
  const { historyNext } = mapComponent.props;
  historyNext();
}

export function drawPolygon(mapComponent) {
  const {
    mapPolygonReducer,
    setPolygon,
    resetGeoJsonObj,
    controlsReducer,
    setMode,
  } = mapComponent.props;
  if (
    (mapPolygonReducer && !mapPolygonReducer.radius) ||
    controlsReducer.polygonSelected
  ) {
    resetGeoJsonObj();
    setPolygon(null);
    setMode(null);
  } else {
    mapComponent.deleteLayer();
    setMode(CustomLayers.polygonLayer);
  }
}

export function drawRuler(mapComponent) {
  const {
    setMode,
    shortestDistanceOnGraphReducer,
    resetGeoJsonObj,
    resetShortestDistanceOnGraph,
  } = mapComponent.props;

  if (
    shortestDistanceOnGraphReducer &&
    shortestDistanceOnGraphReducer.length > 0
  ) {
    resetGeoJsonObj();
    resetShortestDistanceOnGraph();
    setMode(null);
  } else {
    mapComponent.deleteLayer();
    setMode(CustomLayers.shortestDistanceOnGraphLayer);
  }
}

export function drawDistance(mapComponent) {
  const {
    multipleDistance,
    resetGeoJsonObj,
    resetMultipleDistance,
    controlsReducer,
    setMode,
  } = mapComponent.props;
  if (
    (multipleDistance && multipleDistance.length > 0) ||
    controlsReducer.distanceSelected
  ) {
    resetGeoJsonObj();
    resetMultipleDistance();
    setMode(null);
  } else {
    mapComponent.deleteLayer();
    setMode(CustomLayers.multipleDistanceLayer);
  }
}

export function setPan(mapComponent) {
  const { setMode } = mapComponent.props;
  setMode(CustomLayers.viewModeLayer);
}

// GENERIC
export function deleteLayer(mapComponent) {
  mapComponent.drawingLayers = [];
  const {
    setPolygon,
    resetSelectedFeatureIndexes,
    resetGeoJsonObj,
    resetTooltipSelected,
    toggleDistanceSelected,
    toggleRulerSelected,
    toggleCircleSelected,
    togglePolygonSelected,
    controlsReducer,
    resetMultipleDistance,
    saveFigure,
    resetShortestDistanceOnGraph,
    setMode,
  } = mapComponent.props;

  if (controlsReducer.circleSelected) {
    toggleCircleSelected();
  }
  if (controlsReducer.polygonSelected) {
    togglePolygonSelected();
  }
  if (controlsReducer.distanceSelected) {
    toggleDistanceSelected();
  }
  if (controlsReducer.rulerSelected) {
    toggleRulerSelected();
  }
  saveFigure();
  setPolygon(null);
  resetSelectedFeatureIndexes();
  resetGeoJsonObj();
  resetMultipleDistance();
  resetTooltipSelected();
  resetShortestDistanceOnGraph();
  // closeBottombar();
  // resetBottombarTabs();
  setMode(null);

  mapComponent.setState({
    mode: null,
    polygon: {
      center: null,
      positionTooltip: { x: 0, y: 0 },
      count: 0,
    },
  });
}

export function toDefaultPosition(mapComponent) {
  mapComponent.setState({ viewport: { ...mapComponent.INITIAL_VIEW_STATE } });
}

export function toNord(mapComponent) {
  mapComponent.setState({
    viewport: { ...mapComponent.state.viewport, bearing: 0 },
  });
}

export function zoomIn(mapComponent) {
  const { viewport } = mapComponent.state;
  const { map } = mapComponent.context;

  if (viewport.zoom < map.mapOptions.maxZoom) {
    let newZoom = mapComponent.map.getZoom() + 1;
    mapComponent.map.flyTo({ zoom: newZoom });
    mapComponent.setState({
      viewport: { ...viewport, zoom: newZoom },
    });
  }
}

export function zoomOut(mapComponent) {
  const { viewport } = mapComponent.state;
  const { map } = mapComponent.context;

  if (viewport.zoom > map.mapOptions.minZoom) {
    let newZoom = mapComponent.map.getZoom() - 1;
    mapComponent.map.flyTo({ zoom: newZoom });
    mapComponent.setState({
      viewport: { ...viewport, zoom: newZoom },
    });
  }
}
