import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Permission from "components/shared/permission";
import {
  areAllTrafficVisible,
  getPermissions,
  isOpenNavTraffic,
  isTypicalVisible,
  isNetStateVisible,
} from "store";

const mapStateToProps = (state) => ({
  allTrafficChecked: areAllTrafficVisible(state),
  tipicChecked: isTypicalVisible(state),
  netStateChecked: isNetStateVisible(state),
  permissions: getPermissions(state),
  isOpenNavTraffic: isOpenNavTraffic(state),
});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemMobilityTraffic extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  traffic = () => {
    const {
      allTrafficChecked,
      tipicChecked,
      netStateChecked,
      isOpenNavTraffic,
      clickOnTrafficoLabel,
      clickOnTrafficoCheckbox,
    } = this.props;

    return (
      <Permission actions="TRAFFIC">
        <div
          className={
            isOpenNavTraffic
              ? "uk-checkbox-title uk-open acr-traffic-open"
              : "uk-checkbox-title"
          }
        >
          <span>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={allTrafficChecked || false}
              onChange={() => clickOnTrafficoCheckbox("all")}
            />
          </span>
          <label onClick={() => clickOnTrafficoLabel()}>Traffico</label>
        </div>

        <div className="uk-checkbox-tree-sub">
          <label className="uk-flex uk-flex-middle">
            <input
              className="uk-radio"
              type="radio"
              checked={netStateChecked || false}
              onChange={() => clickOnTrafficoCheckbox("net-state")}
            />
            <div className="uk-margin-left">Stato della rete</div>
          </label>
          <label className="uk-flex uk-flex-middle">
            <input
              className="uk-radio"
              type="radio"
              checked={tipicChecked || false}
              onChange={() => clickOnTrafficoCheckbox("typical")}
            />
            <div className="uk-margin-left">Tipico</div>
          </label>
        </div>
      </Permission>
    );
  };

  render() {
    return this.traffic();
  }
}

export default NavItemMobilityTraffic;
