import React, { Component } from "react";
import "./style.less";
import moment from "moment";

class VehicleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  header = () => {
    const { current, handleResetSelected } = this.props;
    return (
      <div
        id="floatingMenuTrip"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="uk-flex uk-flex-column uk-flex-middle">
          <div
            uk-icon="icon: acr-menu-routes-management"
            className="imgBusTripDetail colorMuted"
          ></div>
          <div className="textSmall colorMuted">
            {current &&
            current.monitoredVehicleJourney &&
            current.monitoredVehicleJourney.vehicleJourneyName
              ? current.monitoredVehicleJourney.vehicleJourneyName
              : "-"}
          </div>
        </div>

        <div className="uk-flex uk-flex-column uk-flex-middle widthText marginLeft marginRight">
          <div className="widthText textMedium">
            Linea{" "}
            {current &&
            current.monitoredVehicleJourney &&
            current.monitoredVehicleJourney.vehicleJourneyName
              ? current.monitoredVehicleJourney.publishedLineName
              : "-"}
          </div>
          <div className="widthText textSmall">
            {current &&
            current.monitoredVehicleJourney &&
            current.monitoredVehicleJourney.destinationName
              ? current.monitoredVehicleJourney.destinationName
              : "-"}
          </div>
        </div>

        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            handleResetSelected();
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    const { handleClickVehicleRoute } = this.props;
    return (
      <div className="uk-flex tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li className="pointer uk-active">
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickVehicleRoute();
            }}
          >
            <a href="/#">Percorso</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { current } = this.props;
    var monitoredVehicleJourney;
    let idTrip = "";
    if (current && current.monitoredVehicleJourney) {
      monitoredVehicleJourney = current.monitoredVehicleJourney;
      let tempArrTrip = monitoredVehicleJourney.framedVehicleJourneyRef.datedVehicleJourneyRef.split(
        "_"
      );
      idTrip =
        tempArrTrip.length >= 3
          ? tempArrTrip[2] + "_" + tempArrTrip[3]
          : current.monitoredVehicleJourney.framedVehicleJourneyRef
              .datedVehicleJourneyRef;
    } else {
      monitoredVehicleJourney = null;
    }

    return (
      <div className="uk-flex uk-flex-middle uk-flex-row bodyContainer">
        <div>
          <li className="uk-flex">
            <div className="text16 containerTextDetail colorMuted">
              ID Corsa
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail colorMuted">
              Posizione
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail colorMuted">Linea</div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail colorMuted">
              {/* Percorso Linea */}Orario di Partenza
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail colorMuted">
              ID Vettura
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail colorMuted">
              Ultima Fermata
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail colorMuted">
              Prossima Fermata
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail colorMuted">
              {monitoredVehicleJourney &&
              monitoredVehicleJourney.delay &&
              monitoredVehicleJourney.delay.advance
                ? "Anticipo: "
                : "Ritardo: "}
            </div>
          </li>
        </div>
        <div className="paddingSecondaryList">
          <li className="uk-flex">
            <div className="text16 containerTextDetail">{idTrip}</div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail">
              {monitoredVehicleJourney &&
              monitoredVehicleJourney.vehicleLocation &&
              monitoredVehicleJourney.vehicleLocation.latitude &&
              monitoredVehicleJourney.vehicleLocation.longitude
                ? monitoredVehicleJourney.vehicleLocation.latitude +
                  " - " +
                  monitoredVehicleJourney.vehicleLocation.longitude
                : "-"}
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail">
              {monitoredVehicleJourney &&
              monitoredVehicleJourney.publishedLineName
                ? monitoredVehicleJourney.publishedLineName
                : "-"}
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail">
              {/* {monitoredVehicleJourney &&
              monitoredVehicleJourney.originName &&
              monitoredVehicleJourney.destinationName
                ? monitoredVehicleJourney.originName +
                  " - " +
                  monitoredVehicleJourney.destinationName
                : "-"} */}
              {monitoredVehicleJourney &&
              monitoredVehicleJourney.previousCalls &&
              monitoredVehicleJourney.previousCalls.length > 0 &&
              monitoredVehicleJourney.previousCalls[0].aimedDepartureTime
                ? moment(
                    monitoredVehicleJourney.previousCalls[0].aimedDepartureTime
                  ).format("HH:mm")
                : monitoredVehicleJourney &&
                  monitoredVehicleJourney.monitoredCall &&
                  monitoredVehicleJourney.monitoredCall.aimedDepartureTime
                ? moment(
                    monitoredVehicleJourney.monitoredCall.aimedDepartureTime
                  ).format("HH:mm")
                : monitoredVehicleJourney &&
                  monitoredVehicleJourney.onwardCalls &&
                  monitoredVehicleJourney.onwardCalls.length > 0 &&
                  monitoredVehicleJourney.onwardCalls[0].aimedDepartureTime
                ? moment(
                    monitoredVehicleJourney.onwardCalls[0].aimedDepartureTime
                  ).format("HH:mm")
                : "-"}
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail">
              {monitoredVehicleJourney &&
              monitoredVehicleJourney.vehicleJourneyName
                ? monitoredVehicleJourney.vehicleJourneyName
                : "-"}
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail">
              {monitoredVehicleJourney &&
              monitoredVehicleJourney.monitoredCall &&
              monitoredVehicleJourney.monitoredCall.stopPointName
                ? monitoredVehicleJourney.monitoredCall.stopPointName
                : "-"}
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail">
              {monitoredVehicleJourney &&
              monitoredVehicleJourney.onwardCalls.length > 0 &&
              monitoredVehicleJourney.onwardCalls[0].stopPointName
                ? monitoredVehicleJourney.onwardCalls[0].stopPointName
                : "-"}
            </div>
          </li>
          <li className="uk-flex">
            <div className="text16 containerTextDetail">
              {monitoredVehicleJourney &&
              monitoredVehicleJourney.delay &&
              monitoredVehicleJourney.delay.delay
                ? monitoredVehicleJourney.delay.delay + " Min"
                : "-"}
            </div>
          </li>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}
export default VehicleDetail;
