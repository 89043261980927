import { tableMenuActions } from "./table-menu.actions";

const INITIAL_STATE = {
  tableToggled: false,
  typeTable: null,
  isToggleSavingWorkspace: false,
};

export default function tableMenuReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case tableMenuActions.TOGGLE_GENERIC_TABLE:
      return {
        ...state,
        tableToggled:
          action.payload.type &&
          state.typeTable &&
          action.payload.type !== state.typeTable
            ? true
            : !state.tableToggled,
        typeTable:
          !state.tableToggled || action.payload.type !== state.typeTable
            ? action.payload.type
            : null,
        isToggleSavingWorkspace: true,
      };
    case tableMenuActions.TOGGLE_SAVE_WORKSPACE:
      return {
        ...state,
        isToggleSavingWorkspace: action.payload,
      };
    default:
      return state;
  }
}

export const isGenericTableToggled = (state) => state.tableToggled;
export const typeOfToggledTable = (state) => state.typeTable;
export const isToggleSavingWorkspace = (state) => state.isToggleSavingWorkspace;
