import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { take, tap, switchMap, catchError } from "rxjs/operators";
// import { forkJoin, of } from "rxjs";
import Permission from "components/shared/permission";

//BASE WINDOW SERVICE FOR QT ATCION PROPAGATION
// import createStreamWindowService from "services/stream-window-service";
// import { ELIGIALBE_CAMS } from "utils/soap-webcams-utils";

import {
  isAllCamerasVisible,
  getPermissions,
  isOpenNavTab,
  isOpenNavWebcams,
  isMobilitaVisible,
  getBottombarTabs,
} from "store";

const mapStateToProps = (state) => ({
  permissions: getPermissions(state),
  isOpenNavTab: isOpenNavTab(state),
  allCamerasVisible: isAllCamerasVisible(state),
  isOpenNavWebcams: isOpenNavWebcams(state),
  isMobilitaVisible: isMobilitaVisible(state),
  bottombarTabs: getBottombarTabs(state),
});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemWebCams extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  webcam = () => {
    const {
      allCamerasVisible,
      isOpenNavWebcams,
      isMobilitaVisible,
      clickOnCameraCheckbox,
      clickOnCameraLabel,
    } = this.props;

    return (
      <Permission actions="CCTV-MONITORING">
        <div
          className={
            isOpenNavWebcams
              ? "uk-checkbox-title uk-open acr-webcams-open"
              : "uk-checkbox-title"
          }
        >
          <span>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={allCamerasVisible || false}
              onChange={() => clickOnCameraCheckbox("all")}
            />
          </span>
          <label onClick={() => clickOnCameraLabel()}>Telecamere</label>
        </div>
        <div className="uk-checkbox-tree-sub">
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={isMobilitaVisible || false}
              onChange={() => clickOnCameraCheckbox("mobilita")}
            />
            Mobilità
          </label>
        </div>
      </Permission>
    );
  };

  render() {
    return this.webcam();
  }
}

export default NavItemWebCams;
