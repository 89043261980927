import React, { Component } from "react";
//ASSETS
import acr_arc from "assets/icon/graph/acr-arc.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";

class ArcListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      arc,
      index,
      toggleArcDetails,
      currentArcMap,
      selectedArc,
      scrollDiv,
    } = this.props;

    return (
      <div
        className="uk-flex uk-flex-row uk-flex-middle uk-flex-between"
        key={index}
      >
        <li
          onClick={(event) => {
            event.preventDefault();
            toggleArcDetails(arc);
          }}
          id={arc.properties.arcid}
          className={
            currentArcMap &&
            currentArcMap.length === 1 &&
            currentArcMap[0].properties.arcid === arc.properties.arcid
              ? "uk-flex uk-flex-between uk-active uk-flex-middle"
              : "uk-flex uk-flex-between uk-flex-middle"
          }
        >
          <div className="arc-icon uk-flex uk-flex-middle uk-flex-center">
            <div className="uk-position-relative graph-height-width-48 uk-flex uk-flex-middle uk-flex-center">
              {currentArcMap &&
              currentArcMap.length > 1 &&
              selectedArc === arc.properties.arcid ? (
                <div
                  className="parent uk-flex uk-flex-middle uk-flex-center"
                  ref={scrollDiv}
                  tabIndex="-1"
                >
                  <div className="circle-list-map"></div>
                  <img
                    alt="icn_diamond"
                    src={icn_diamond}
                    className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
                  ></img>
                  <img
                    alt="acr_arc"
                    src={acr_arc}
                    className="image2  height-width-48 uk-flex uk-flex-middle uk-flex-center arch-icon"
                    style={{
                      transform:
                        "rotate(" + (arc.properties.direction - 45) + "deg)",
                    }}
                  ></img>
                </div>
              ) : (
                <img
                  alt="acr_arc"
                  src={acr_arc}
                  className="uk-position-relative height-width-48 uk-flex uk-flex-middle uk-flex-center arch-icon"
                  style={{
                    transform:
                      "rotate(" + (arc.properties.direction - 45) + "deg)",
                  }}
                ></img>
              )}
            </div>
          </div>
          <div className="uk-flex uk-margin-small-left arc-name-container">
            <div className="uk-flex uk-flex-column">
              <div
                className={
                  currentArcMap && selectedArc === arc.properties.arcid
                    ? "uk-width-1-1 arc-header-name"
                    : "uk-width-1-1 list-text arc-header-name"
                }
              >
                {arc.properties.name}
              </div>
              <div
                className={
                  currentArcMap && selectedArc === arc.properties.arcid
                    ? "uk-width-1-1 arc-header-name italic-text"
                    : "uk-width-1-1 list-text arc-header-name italic-text"
                }
              >
                {arc.properties.area_name}
              </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-column uk-margin-small-left velocitaMax-container">
            <div
              className={
                currentArcMap && selectedArc === arc.properties.arcid
                  ? "uk-width-1-1"
                  : "uk-width-1-1 list-text"
              }
            >
              {arc.properties.distance ? arc.properties.distance : "-"} m
            </div>
            <div
              className={
                currentArcMap && selectedArc === arc.properties.arcid
                  ? "uk-width-1-1"
                  : "uk-width-1-1 list-text"
              }
            >
              {arc.properties.speed ? arc.properties.speed : "-"} km/h
            </div>
          </div>
        </li>
      </div>
    );
  }
}

export default ArcListItem;
