import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as moment from "moment";

//UTILS
import { PMV_STATUS } from "utils/utils-pmv";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class PmvDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeDetails = () => {
    const { toggleDetails } = this.props;
    toggleDetails(null);
  };

  header = () => {
    const { currentPmvStatus } = this.props;

    const statusIcon = PMV_STATUS.find(
      (status) =>
        currentPmvStatus &&
        currentPmvStatus.anagrafica &&
        typeof currentPmvStatus.anagrafica.stato === "string" &&
        status.name.toLowerCase() ===
          currentPmvStatus.anagrafica.stato.toLowerCase()
    );

    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="uk-position-relative event-height-width-48">
          <img
            className={"img-status"}
            alt={"pmv_img"}
            src={statusIcon && statusIcon.icon}
          />
        </div>
        <div className="uk-flex uk-flex-column ">
          <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-muted-event">
            {currentPmvStatus &&
            currentPmvStatus.anagrafica &&
            currentPmvStatus.anagrafica.nome
              ? currentPmvStatus.anagrafica.nome
              : "-"}
          </div>
          <div className=" uk-margin-left uk-margin-right text-line-width text-detail-muted-event location_info p-dot-2">
            {currentPmvStatus &&
            currentPmvStatus.anagrafica &&
            currentPmvStatus.anagrafica.strada
              ? currentPmvStatus.anagrafica.strada
              : "-"}
          </div>
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            this.closeDetails();
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { currentPmvStatus, refreshPmvOnAir, isPmvLoading } = this.props;

    const statusIcon = PMV_STATUS.find(
      (status) =>
        currentPmvStatus &&
        currentPmvStatus.anagrafica &&
        typeof currentPmvStatus.anagrafica.stato === "string" &&
        status.name.toLowerCase() ===
          currentPmvStatus.anagrafica.stato.toLowerCase()
    );
    const pmvGif =
      currentPmvStatus &&
      currentPmvStatus.stati[0].statoOperativo &&
      currentPmvStatus.stati[0].statoOperativo.gifBase64
        ? "data:image/gif;base64," +
          currentPmvStatus.stati[0].statoOperativo.gifBase64
        : "";

    return (
      <div className={"uk-flex uk-flex-column contentBodyStationDetail"}>
        <div className="uk-flex uk-flex-row uk-flex-right uk-padding-small">
          {isPmvLoading ? (
            <span uk-spinner="ratio: 0.5" />
          ) : (
            <span
              className="pointer"
              uk-icon="icon: refresh"
              uk-tooltip="Ricarica Pmv"
              onClick={() => refreshPmvOnAir(currentPmvStatus)}
            ></span>
          )}
        </div>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Nome</div>
          <div className="text-detail-emphasis-station-detail">
            {currentPmvStatus &&
            currentPmvStatus.anagrafica &&
            currentPmvStatus.anagrafica.nome
              ? currentPmvStatus.anagrafica.nome
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Tipologia</div>
          <div className="text-detail-emphasis-station-detail">
            {currentPmvStatus &&
            currentPmvStatus.anagrafica &&
            currentPmvStatus.anagrafica.tipologia
              ? currentPmvStatus.anagrafica.tipologia
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Strada</div>
          <div className="text-detail-emphasis-station-detail">
            {currentPmvStatus &&
            currentPmvStatus.anagrafica &&
            currentPmvStatus.anagrafica.strada
              ? currentPmvStatus.anagrafica.strada
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Stato Diagnostico
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentPmvStatus && currentPmvStatus.anagrafica && (
              <div
                className={
                  statusIcon
                    ? statusIcon.value === 1
                      ? "pmv-normale"
                      : statusIcon.value === 2
                      ? "pmv-degradato"
                      : statusIcon.value === 3
                      ? "pmv-guasto"
                      : statusIcon.value === 4
                      ? "pmv-sconosciuto"
                      : statusIcon.value === 5
                      ? "pmv-offline"
                      : "pmv-sconosciuto"
                    : "pmv-sconosciuto"
                }
              >
                {statusIcon ? statusIcon.name : "-"}
              </div>
            )}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">On Air</div>
        </li>
        <li className="uk-flex uk-flex-row uk-flex-center uk-flex-middle">
          <div
            className={
              currentPmvStatus &&
              currentPmvStatus.stati &&
              currentPmvStatus.stati.length > 0 &&
              currentPmvStatus.stati[0].statoOperativo &&
              currentPmvStatus.stati[0].statoOperativo.lampeggiante &&
              currentPmvStatus.stati[0].statoOperativo.lampeggiante === "Attivo"
                ? "lampeggiante-giallo"
                : currentPmvStatus &&
                  currentPmvStatus.stati &&
                  currentPmvStatus.stati.length > 0 &&
                  currentPmvStatus.stati[0].statoOperativo &&
                  currentPmvStatus.stati[0].statoOperativo.lampeggiante &&
                  currentPmvStatus.stati[0].statoOperativo.lampeggiante ===
                    "Disattivo"
                ? "lampeggiante-grigio"
                : ""
            }
          ></div>
          {pmvGif !== "" ? (
            <img className="pmv-panel-detail" alt="" src={pmvGif} />
          ) : null}
          <div
            className={
              currentPmvStatus &&
              currentPmvStatus.stati &&
              currentPmvStatus.stati.length > 0 &&
              currentPmvStatus.stati[0].statoOperativo &&
              currentPmvStatus.stati[0].statoOperativo.lampeggiante &&
              currentPmvStatus.stati[0].statoOperativo.lampeggiante === "Attivo"
                ? "lampeggiante-giallo"
                : currentPmvStatus &&
                  currentPmvStatus.stati &&
                  currentPmvStatus.stati.length > 0 &&
                  currentPmvStatus.stati[0].statoOperativo &&
                  currentPmvStatus.stati[0].statoOperativo.lampeggiante &&
                  currentPmvStatus.stati[0].statoOperativo.lampeggiante ===
                    "Disattivo"
                ? "lampeggiante-grigio"
                : ""
            }
          ></div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Nome Messaggio</div>
          <div className="text-detail-emphasis-station-detail">
            {currentPmvStatus &&
            currentPmvStatus.stati &&
            currentPmvStatus.stati.length > 0 &&
            currentPmvStatus.stati[0].statoOperativo &&
            currentPmvStatus.stati[0].statoOperativo.nomeMessaggio
              ? currentPmvStatus.stati[0].statoOperativo.nomeMessaggio
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Livello Esposizione
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentPmvStatus &&
            currentPmvStatus.stati &&
            currentPmvStatus.stati.length > 0 &&
            currentPmvStatus.stati[0].statoOperativo &&
            currentPmvStatus.stati[0].statoOperativo.livelloEsposizione
              ? currentPmvStatus.stati[0].statoOperativo.livelloEsposizione
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">
            Descrizione Esposizione
          </div>
          <div className="text-detail-emphasis-station-detail">
            {currentPmvStatus &&
            currentPmvStatus.stati &&
            currentPmvStatus.stati.length > 0 &&
            currentPmvStatus.stati[0].statoOperativo &&
            currentPmvStatus.stati[0].statoOperativo.descrizioneEsposizione
              ? currentPmvStatus.stati[0].statoOperativo.descrizioneEsposizione
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Validità da</div>
          <div className="text-detail-emphasis-station-detail">
            {currentPmvStatus &&
            currentPmvStatus.stati &&
            currentPmvStatus.stati.length > 0 &&
            currentPmvStatus.stati[0].statoOperativo &&
            currentPmvStatus.stati[0].statoOperativo.validita &&
            currentPmvStatus.stati[0].statoOperativo.validita.da
              ? moment(
                  currentPmvStatus.stati[0].statoOperativo.validita.da
                ).format("DD/MM/YYYY HH:mm")
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Validità a</div>
          <div className="text-detail-emphasis-station-detail">
            {currentPmvStatus &&
            currentPmvStatus.stati &&
            currentPmvStatus.stati.length > 0 &&
            currentPmvStatus.stati[0].statoOperativo &&
            currentPmvStatus.stati[0].statoOperativo.validita &&
            currentPmvStatus.stati[0].statoOperativo.validita.a
              ? moment(
                  currentPmvStatus.stati[0].statoOperativo.validita.a
                ).format("DD/MM/YYYY HH:mm")
              : "-"}
          </div>
        </li>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}

export default PmvDetail;
