import { TrafficActions } from "./traffic.actions";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";
import { INITIAL_STATE } from "./traffic.model";
import * as FilterUtils from "utils/filter-utils";

export function searchArcs(searchText) {
  return (arc) =>
    (arc &&
      arc.properties.archId &&
      arc.properties.archId.toString().toLowerCase().includes(searchText)) ||
    (arc &&
      arc.properties.archName &&
      arc.properties.archName.toLowerCase().includes(searchText));
}

export default function trafficReducer(state = INITIAL_STATE, action) {
  let arcsListCopy = FilterUtils.deepCopyOfArraysOfObj(state.arcs);

  switch (action.type) {
    case TrafficActions.SET_TRAFFIC_LIST_ARCS:
      let arcsFiltered = action.payload.list;
      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "traffic"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchArcs(searchText);
        arcsFiltered = action.payload.list.filter(searchFn);
      }
      return {
        ...state,
        arcs: action.payload.list,
        arcsFiltered: arcsFiltered,
      };
    case TrafficActions.SET_TRAFFIC_CURRENT_ARC:
      if (state.arcs) {
        arcsListCopy.forEach((item, index, arr) => {
          if (
            item &&
            item.properties &&
            action.payload &&
            action.payload.arc &&
            action.payload.arc.properties &&
            action.payload.arc.properties.archId &&
            item.properties.archId === action.payload.arc.properties.archId
          ) {
            arr[index] = {
              ...item,
              selected:
                state.currentMap &&
                state.currentMap.properties &&
                state.currentMap.properties.archId ===
                  action.payload.arc.properties.archId
                  ? false
                  : true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }

      let newarrArcFiltered = FilterUtils.deepCopyOfArraysOfObj(arcsListCopy);
      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "traffic"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchArcs(searchText);
        newarrArcFiltered = arcsListCopy.filter(searchFn);
      }

      let newCurr;
      if (action.payload && action.payload.arc) {
        newCurr = {
          ...action.payload.arc,
          properties: {
            ...action.payload.arc.properties,
            selected: !action.payload.arc.properties.selected,
          },
        };
      } else {
        newCurr = action.payload.arc;
      }

      return {
        ...state,
        current:
          state.current &&
          state.current.properties &&
          state.current.properties.archId &&
          action.payload &&
          action.payload.arc &&
          action.payload.arc.properties &&
          action.payload.arc.properties.archId &&
          state.current.properties.archId ===
            action.payload.arc.properties.archId
            ? null
            : newCurr,
        arcs: arcsListCopy,
        arcsFiltered: newarrArcFiltered,
        currentMap:
          action.payload.arc === null ? [] : [...state.currentMap, newCurr],
      };
    case TrafficActions.SET_TRAFFIC_CURRENT_ARC_MAP:
      let currentArcMap = [];

      let newCurrMap;
      if (action.payload && action.payload.arc) {
        newCurrMap = {
          ...action.payload.arc,
          properties: {
            ...action.payload.arc.properties,
            selected: !action.payload.arc.properties.selected,
          },
        };
      } else {
        newCurrMap = action.payload.arc;
      }

      if (state && state.currentMap && state.currentMap.length > 0) {
        let alreadyExists = [...state.currentMap].find(
          (arc) =>
            arc.properties.archId === action.payload.arc.properties.archId
        );
        if (alreadyExists) {
          currentArcMap = [...state.currentMap].filter(function (item1) {
            return item1.properties.archId !== alreadyExists.properties.archId;
          });
        } else {
          currentArcMap = [...state.currentMap, newCurrMap];
        }
      } else {
        currentArcMap = [newCurrMap];
      }

      if (state.arcs) {
        arcsListCopy.forEach((item, index, arr) => {
          let exist = currentArcMap.find(
            (arc) => item.properties.archId === arc.properties.archId
          );
          if (exist) {
            arr[index] = {
              ...item,
              selected: true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }

      let newarrArcMapFiltered = FilterUtils.deepCopyOfArraysOfObj(
        arcsListCopy
      );

      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "traffic"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchArcs(searchText);
        newarrArcMapFiltered = newarrArcMapFiltered.filter(searchFn);
      }

      return {
        ...state,
        currentMap:
          state.currentMap &&
          state.currentMap.properties &&
          state.currentMap.properties.archId &&
          action.payload &&
          action.payload.arc &&
          action.payload.arc.properties &&
          action.payload.arc.properties.archId &&
          state.currentMap.properties.archId ===
            action.payload.arc.properties.archId
            ? []
            : currentArcMap,
        arcsFiltered: newarrArcMapFiltered,
        arcs: arcsListCopy,
        current:
          state.currentMap && state.currentMap.length !== 0
            ? null
            : action.payload.arc,
      };
    case TrafficActions.RESET_TRAFFIC_CURRENT_ARC_MAP:
      return {
        ...state,
        currentMap: [],
        current: null,
        arcs: [],
        arcsFiltered: [],
      };
    case NavTabActions.SET_SEARCH: {
      if (action.payload && action.payload.type === "traffic") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const searchFn = searchArcs(searchText);
        let arcsFiltered = state.arcs.filter(searchFn);
        const searchType = action.payload.type;

        return {
          ...state,
          arcsFiltered,
          searchText,
          searchType,
        };
      } else {
        return { ...state };
      }
    }
    case TrafficActions.SET_CONTEXTS:
      return {
        ...state,
        contexts: action.payload,
      };
    case TrafficActions.SET_CURRENT_CONTEXT:
      return {
        ...state,
        currentContext: action.payload,
      };
    case TrafficActions.SET_MEASURES:
      return {
        ...state,
        measures: action.payload,
      };
    case TrafficActions.SET_CURRENT_MEASURE:
      return {
        ...state,
        currentMeasure: action.payload,
      };
    case TrafficActions.SET_DAY_TYPES:
      return {
        ...state,
        dayTypes: action.payload,
      };
    case TrafficActions.SET_CURRENT_DAY_TYPE:
      return {
        ...state,
        currentDayType: action.payload,
      };
    case TrafficActions.SET_CURRENT_DAY:
      return {
        ...state,
        currentDay: action.payload,
      };
    case TrafficActions.SET_VEHICLE_CLASSES:
      return {
        ...state,
        vehicleClasses: action.payload,
      };
    case TrafficActions.SET_CURRENT_VEHICLE_CLASS:
      return {
        ...state,
        currentVehicleClass: action.payload,
      };
    case TrafficActions.SET_CURRENT_TIME:
      return {
        ...state,
        currentTime: action.payload,
      };
    case TrafficActions.SET_CURRENT_START_TIME:
      return {
        ...state,
        currentStartTime: action.payload,
      };
    case TrafficActions.SET_CURRENT_END_TIME:
      return {
        ...state,
        currentEndTime: action.payload,
      };
    case TrafficActions.SET_CURRENT_TIME_OFFSET:
      return {
        ...state,
        currentTimeOffSet: action.payload,
      };
    case TrafficActions.SET_CURRENT_DATE_SLIDER:
      return {
        ...state,
        currentDateSlider: action.payload,
      };
    case TrafficActions.UPDATE_TRAFFIC_NOTIFICATION_WS: {
      return {
        ...state,
        wsLastNotification: [...state.wsLastNotification, ...action.payload],
      };
    }
    case TrafficActions.RESET_TRAFFIC_NOTIFICATION_WS: {
      return {
        ...state,
        wsLastNotification: [],
      };
    }
    default:
      return state;
  }
}

export const getTrafficArcs = (state) => state.arcsFiltered;
export const getTrafficCurrentArc = (state) => state.current;
export const getTrafficCurrentArcMap = (state) => state.currentMap;
export const getTrafficContexts = (state) => state.contexts;
export const getTrafficCurrentContext = (state) => state.currentContext;
export const getTrafficMeasures = (state) => state.measures;
export const getTrafficCurrentMeasure = (state) => state.currentMeasure;
export const getTrafficDayTypes = (state) => state.days;
export const getTrafficCurrentDayType = (state) => state.currentDayType;
export const getTrafficCurrentDay = (state) => state.currentDay;
export const getTrafficVehicleClasses = (state) => state.vehicleClasses;
export const getTrafficCurrentVehicleClass = (state) =>
  state.currentVehicleClass;
export const getTrafficCurrentTime = (state) => state.currentTime;
export const getTrafficCurrentStartTime = (state) => state.currentStartTime;
export const getTrafficCurrentEndTime = (state) => state.currentEndTime;
export const getCurrentTimeOffSet = (state) => state.currentTimeOffSet;
export const getCurrentDateSlider = (state) => state.currentDateSlider;
export const getWsLastNotification = (state) => state.wsLastNotification;
