export const MULTITAB_SELECTED_TAB = [
  {
    name: "Grafo Stradale",
    icon: "Tab-Grafo-Stradale",
    primary: false,
    selected: false,
  },
  {
    name: "Rete RDS-TMC",
    icon: "Tab-ReteRDS-TMC",
    primary: false,
    selected: false,
  },
  {
    name: "Trasporto Pubblico",
    icon: "Tab-Trasporto-Pubblico",
    primary: false,
    selected: false,
  },
  {
    name: "Traffico",
    icon: "Tab-Traffico",
    primary: false,
    selected: false,
  },
  {
    name: "Eventi",
    icon: "Tab-Eventi",
    primary: false,
    selected: false,
  },
  {
    name: "Stazioni di Misura",
    icon: "Tab-StazioniMisura",
    primary: false,
    selected: false,
  },
  {
    name: "Pannelli",
    icon: "Tab-Pannelli",
    primary: false,
    selected: false,
  },
  {
    name: "Telecamere",
    icon: "Tab-Videocamere",
    primary: false,
    selected: false,
  },
  {
    name: "Parcheggi",
    icon: "Tab-Parcheggi",
    primary: false,
    selected: false,
  },
];
