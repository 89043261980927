import { InstallationsMenuActions } from "./installations-menu.actions";

const INITIAL_STATE = {
  //pmv
  pmvVisible: false,
  aesysPanelVisible: false,
  solariPanelVisible: false,
  futuritPanelVisible: false,
  visuallabPanelVisible: false,
  sfheraPanelVisible: false,
  mgsPmvHistoryOpen: false,
  //parking
  parkingVisible: false,
  //camera
  camerasMobilitaVisible: false,
  allCamerasVisible: false,
  isAlarmTableOpen: false,
  //station
  stationVisible: false,
  //loading
  isStationLoading: false,
  isPmvLoading: false,
  isCctvLoading: false,
  isParkingLoading: false,
};

export default function installationsMenuReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    //PARKING
    case InstallationsMenuActions.SET_PARKING_VISIBILITY:
      return {
        ...state,
        parkingVisible: action.payload.visible,
      };
    //PMV
    case InstallationsMenuActions.SET_PMV_VISIBILITY:
      return {
        ...state,
        pmvVisible: action.payload.visible,
        aesysPanelVisible: action.payload.visible,
        solariPanelVisible: action.payload.visible,
        futuritPanelVisible: action.payload.visible,
        visuallabPanelVisible: action.payload.visible,
        sfheraPanelVisible: action.payload.visible,
      };
    case InstallationsMenuActions.SET_AESYS_PANEL_VISIBILITY:
      return {
        ...state,
        pmvVisible:
          state.solariPanelVisible &&
          state.futuritPanelVisible &&
          state.visuallabPanelVisible &&
          state.sfheraPanelVisible && 
          action.payload.visible,
        aesysPanelVisible: action.payload.visible,
      };
    case InstallationsMenuActions.SET_SOLARI_PANEL_VISIBILITY:
      return {
        ...state,
        pmvVisible:
          state.aesysPanelVisible &&
          state.futuritPanelVisible &&
          state.visuallabPanelVisible &&
          state.sfheraPanelVisible && 
          action.payload.visible,
        solariPanelVisible: action.payload.visible,
      };
    case InstallationsMenuActions.SET_FUTURIT_PANEL_VISIBILITY:
      return {
        ...state,
        pmvVisible:
          state.aesysPanelVisible &&
          state.solariPanelVisible &&
          state.visuallabPanelVisible &&
          state.sfheraPanelVisible && 
          action.payload.visible,
        futuritPanelVisible: action.payload.visible,
      };
      case InstallationsMenuActions.SET_SFHERA_PANEL_VISIBILITY:
        return {
          ...state,
          pmvVisible:
            state.aesysPanelVisible &&
            state.solariPanelVisible &&
            state.visuallabPanelVisible &&
            state.futuritPanelVisible && 
            action.payload.visible,
            sfheraPanelVisible: action.payload.visible,
        };
    case InstallationsMenuActions.SET_VISUALLAB_PANEL_VISIBILITY:
      return {
        ...state,
        pmvVisible:
          state.aesysPanelVisible &&
          state.solariPanelVisible &&
          state.futuritPanelVisible &&
          action.payload.visible,
        visuallabPanelVisible: action.payload.visible,
      };
    case InstallationsMenuActions.TOGGLE_MGS_HISTORY_TABLE_OPEN:
      return {
        ...state,
        mgsPmvHistoryOpen: !state.mgsPmvHistoryOpen,
      };
    //STATION
    case InstallationsMenuActions.SET_STATION_VISIBILITY:
      return {
        ...state,
        stationVisible: action.payload.visible,
      };
    //CAMERAS
    case InstallationsMenuActions.SET_CAMERAS_MOBILITY_VISIBILITY:
      return {
        ...state,
        camerasMobilitaVisible: action.payload.visible,
      };
    case InstallationsMenuActions.SET_ALL_CAMERAS_VISIBILITY:
      return {
        ...state,
        allCamerasVisible: action.payload.visible,
        camerasMobilitaVisible: action.payload.visible,
      };
    case InstallationsMenuActions.TOGGLE_ALARM_TABLE:
      return {
        ...state,
        isAlarmTableOpen: !state.isAlarmTableOpen,
      };
    case InstallationsMenuActions.SET_IS_STATION_LOADING:
      return {
        ...state,
        isStationLoading: action.payload.isLoading,
      };
    case InstallationsMenuActions.SET_IS_PMV_LOADING:
      return {
        ...state,
        isPmvLoading: action.payload.isLoading,
      };
    case InstallationsMenuActions.SET_IS_CCTV_LOADING:
      return {
        ...state,
        isCctvLoading: action.payload.isLoading,
      };
    case InstallationsMenuActions.SET_IS_PARKING_LOADING:
      return {
        ...state,
        isParkingLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
}
//STATION
export const isStationVisible = (state) => state.stationVisible;
//PARKING
export const isParkingVisible = (state) => state.parkingVisible;
//CAMERA
export const isAlarmTableOpen = (state) => state.isAlarmTableOpen;
export const getIsAllCamerasVisible = (state) => state.allCamerasVisible;
export const getIsCamerasMobilitaVisible = (state) =>
  state.camerasMobilitaVisible;
//PMV
export const isAesysPanelVisible = (state) => state.aesysPanelVisible;
export const isSolariPanelVisible = (state) => state.solariPanelVisible;
export const isFuturitPanelVisible = (state) => state.futuritPanelVisible;
export const isVisuallabPanelVisible = (state) => state.visuallabPanelVisible;
export const isSfheraPanelVisible = (state) => state.sfheraPanelVisible
export const isPmvVisible = (state) => state.pmvVisible;
export const getPmvHistoryTableOpen = (state) => state.mgsPmvHistoryOpen;
//loading
export const isStationLoading = (state) => state.isStationLoading;
export const isPmvLoading = (state) => state.isPmvLoading;
export const isCctvLoading = (state) => state.isCctvLoading;
export const isParkingLoading = (state) => state.isParkingLoading;
