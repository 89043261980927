import { WebMercatorViewport } from "@deck.gl/core";

const arraymove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

export function _onMapLoad(style, mapInstance) {
  if (style === "streets") {
    setTimeout(() => {
      let findInd = mapInstance.map
        .getStyle()
        .layers.findIndex((item) => item.id === "3d-buildings");
      if (findInd >= 0) {
        mapInstance.map.getStyle().layers = arraymove(
          mapInstance.map.getStyle().layers,
          findInd,
          0
        );
      } else {
        const firstLabelLayerId = mapInstance.map
          .getStyle()
          .layers.find((layer) => layer.type === "symbol").id;
        mapInstance.map.addLayer(
          {
            id: "3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["==", "extrude", "true"],
            type: "fill-extrusion",
            minzoom: 15,
            paint: {
              "fill-extrusion-color": "#aaa",

              // use an 'interpolate' expression to add a smooth transition effect to the
              // buildings as the user zooms in
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.6,
            },
          },
          firstLabelLayerId
        );
      }
    }, 5000);
  }
}

export function filterBounds(myComponent) {
  const {
    setBounds,
    filterByMapBounds,
    boundsReducer,
    currentMapZoom,
  } = myComponent.props;

  fetch("/config.json")
    .then((res) => res.json())
    .then((environment) => {
      let bbox;
      let zoom;

      if (boundsReducer && boundsReducer.length > 0) {
        bbox = [
          boundsReducer[0],
          boundsReducer[1],
          boundsReducer[2],
          boundsReducer[3],
        ];
      } else {
        bbox = [
          environment.map.mapBounds[0][0],
          environment.map.mapBounds[0][1],
          environment.map.mapBounds[1][0],
          environment.map.mapBounds[1][1],
        ];
      }
      if (currentMapZoom) {
        zoom = currentMapZoom;
      } else {
        zoom = environment.map.mapOptions.zoom;
      }
      setBounds(bbox, zoom);
      filterByMapBounds(bbox);
    });
}
