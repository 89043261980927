import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.less";
import EnvironmentContext from "environment-context";
import {
  tap,
  take,
  catchError,
  concatMap,
  finalize,
  mergeMap,
} from "rxjs/operators";
import { of, merge } from "rxjs";
import createApiService from "services/api.service";
import { setArcsListEvent } from "reducers/graph/arcs/arcs.actions";
import { toggleSaveWorkspace } from "reducers/ui/table-menu/table-menu.actions";

// DECK
import DeckGL from "@deck.gl/react";
import { MapView, MapController } from "@deck.gl/core";
import { ViewMode } from "nebula.gl";
import { _MapContext as MapContext } from "react-map-gl";

//ACTIONS
import { setCurrentTrip } from "reducers/trips/trips.actions";
import {
  toggleNavGraph,
  toggleNavNetworkRDSTMC,
  toggleNavTPL,
  toggleNavEvents,
  toggleNavTraffic,
  toggleNavStation,
  toggleNavWebcams,
} from "reducers/ui/nav/nav.actions";
import {
  setPolygon,
  historyPrev,
  historyNext,
  setSelectedFeatureIndexes,
  resetSelectedFeatureIndexes,
  setGeoJsonObj,
  resetGeoJsonObj,
  resetTooltipSelected,
  setLayout,
  toggleDistanceSelected,
  toggleRulerSelected,
  toggleCircleSelected,
  togglePolygonSelected,
  filterByMapBounds,
  setMultipleDistance,
  resetMultipleDistance,
  setHoveredBus,
  saveFigure,
  setMode,
  setBounds,
  setShortestDistanceOnGraph,
  resetShortestDistanceOnGraph,
  setCurrentRoutesMapForStopSelected,
  resetHoveredObjects,
} from "reducers/map/map.actions";
import {
  setCurrentStop,
  filterStopMap,
  setCurrentIdRouteForStop,
  setCurrentIdTripForStop,
} from "reducers/stops/stops.actions";
import {
  setNavTabSelectedTab,
  toggleOpenNavTab,
  setNavTabMultitab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { setActiveLines, resetActiveLines } from "reducers/lines/lines.actions";
import {
  setCurrentIdTripForRoute,
  setCurrentIdStopForRoute,
} from "reducers/routes/routes.actions";
import {
  setCurrentEvent,
  setCurrentEventGroup,
} from "reducers/events/events.actions";
import {
  closeBottombar,
  resetBottombarTabs,
} from "reducers/ui/bottombar/bottombar.actions";
import {
  setCurrentArcMap,
  setCurrentArc,
} from "reducers/graph/arcs/arcs.actions";
import { setCurrentStation } from "reducers/installations/stations/stations.actions";
import { setCurrentNodeMap } from "reducers/graph/nodes/nodes.actions";
import { setCurrentPointMap } from "reducers/graph/points/points.actions";
import { setCurrentSegmentRdsTmcMap } from "reducers/graph/segments/segments.actions";
import { setCurrentCentroidMap } from "reducers/graph/centroids/centroids.actions";
import { setCurrentCamera } from "reducers/installations/cameras/cameras.actions";
import {
  setCurrentPmv,
  setCurrentPmvGroup,
} from "reducers/installations/pmv/pmv.actions";
import { setCurrentParking } from "reducers/installations/parking/parking.actions";
import { setTransportVisibility } from "reducers/ui/tpl-menu/tpl-menu.actions";
import {
  setAllEventVisibility,
  setActiveVisibility,
  setPlannedVisibility,
} from "reducers/ui/event-menu/event-menu.actions";
import {
  setTrafficVisibility,
  setTypicalVisibility,
  setNetStateVisibility,
  resetTrafficVisibility,
} from "reducers/ui/traffic-menu/traffic-menu.actions";
import {
  setAllCamerasVisibility,
  setParkingVisibility,
  setPmvVisibility,
  setStationVisibility,
  setAesysPanelVisibility,
  setSolariPanelVisibility,
  setFuturitPanelVisibility,
  setVisuallabPanelVisibility,
  setSpheraPanelVisibility,
} from "reducers/ui/installations-menu/installations-menu.actions";
import {
  getAllVehiclesEvents,
  getAllTrips,
  getCurrentTrip,
  getCurrentStop,
  getCurrentStation,
  getMapPolygon,
  getMapGeoJson,
  getMapSelectedFeatureIndexes,
  getLayoutMap,
  isCityBusVisible,
  isRegionBusVisible,
  getCityBusRoutesFiltered,
  getRegionBusRoutesFiltered,
  getCityBusLinesFiltered,
  getRegionBusLinesFiltered,
  getCityBusActiveLines,
  getRegionBusActiveLines,
  getCityBusStopsActive,
  getRegionBusStopsActive,
  getRegionBusStopsFilteredMap,
  getCityBusStopsFilteredMap,
  getAllActiveLines,
  getControls,
  getMultipleDistance,
  getShortestDistanceOnGraph,
  getHoveredObjects,
  getCurrentVehicle,
  getMode,
  getBoundingBox,
  getAllStops,
  //event ui
  isActiveVisible,
  isPlannedVisible,
  //event
  getActiveEventsFiltered,
  getPlannedEventsFiltered,
  getCurrentEvent,
  getSelectModal,
  //installations ui
  isAllCamerasVisible,
  isParkingVisible,
  isPmvVisible,
  isStationVisible,
  isAesysPanelVisible,
  isSolariPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
  //road graph
  getArcs,
  getArcsEvent,
  getCentroids,
  getNodes,
  //graph ui
  isCentroidVisible,
  isNodeVisible,
  isArcVisible,
  //network tmc-rds
  getSegmentsRdsTmc,
  getPointsRdsTmc,
  //network ui
  isNetworkRdsTmcVisible,
  getCurrentCentroid,
  getCurrentNode,
  getCurrentArc,
  getCurrentArcMap,
  getCurrentPointRdsTmc,
  getCurrentSegmentRdsTmc,
  getCurrentZoom,
  //traffic
  isOpenNavTraffic,
  isTimeSliderVisible,
  getTrafficCurrentContext,
  getTrafficCurrentMeasure,
  getTrafficCurrentDayType,
  getTrafficCurrentTime,
  getTrafficCurrentArc,
  getTrafficArcs,
  getTrafficCurrentArcMap,
  getCurrentTimeOffSet,
  getTrafficCurrentStartTime,
  //traffic ui
  isNetStateVisible,
  isTypicalVisible,
  areAllTrafficVisible,
  //installations
  getFilteredStations,
  //cameras
  getFilteredCameras,
  getCurrentCamera,
  getCamerasOnMap,
  //parking
  getFilteredParkings,
  getCurrentParking,
  //pmv
  getFilteredPmvs,
  getCurrentPmv,
  //workspace
  getCurrentWorkspace,
  isToggleSavingWorkspace,
  //kpi
  getListAllKpi,
} from "store";
import {
  setCurrentVehicle,
  setCurrentIdRouteForVehicle,
  setCurrentIdStopForVehicle,
} from "reducers/vehicles/vehicles.actions";
import { toggleOpenTrafficTimeSlider } from "reducers/ui/traffic-menu/traffic-menu.actions";
import {
  setTrafficCurrentArc,
  setTrafficCurrentArcMap,
} from "reducers/traffic/traffic.actions";
import {
  setCamerasList,
  addNewPinnedCamera,
  resetPinnedCameras,
} from "reducers/installations/cameras/cameras.actions";
import {
  expandBottombar,
  setBottombarActiveTab,
  addBottombarTab,
} from "reducers/ui/bottombar/bottombar.actions";
import {
  setGraphVisibility,
  setNetworkRdsTmcVisibility,
} from "reducers/ui/graph-menu/graph-menu.actions";
//COMPONENTS
import DrawTooltip from "components/layout/map/tooltip/draw-tooltip";
import DraggableCamerasOnMap from "components/layout/map/draggable-cameras-on-map/draggable-cameras-on-map";
// UTILS
/* import FPS from "./fps"; */
import {
  debounce,
  reduceDuplicatesInArrayForId,
  getEventPosition,
} from "utils/utils";
import { makeLayersList } from "utils/map-utils/map-layers-list";
import { getDetailRoute } from "utils/utils-filter-tpl";
//import { graf } from "utils/el_str";
import {
  getBoundingBoxStringForBboxParamNoEpsg,
  getBoundsFromWebmercatorView,
} from "components/pages/load.data/map.utils";
//MODELS
import { CameraFeatureCollectionModel } from "reducers/installations/cameras/cameras.model";

import * as MapListeners from "./listeners/listeners";
import * as MapHoverListeners from "./hover-listeners/hover-listeners";
import * as MapRenderers from "./render/render-utils";
import * as MapDrawingTools from "./drawers/drawing-tools";

class Map extends Component {
  map;
  deckRef;
  staticmap;
  editableLayer;
  drawingLayers;
  INITIAL_VIEW_STATE;
  prevPropsRoute = null;
  prevPropsTrip = null;
  prevPropsVehicle = null;
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      layoutMap: "dark",
      mode: null,
      polygon: {
        center: [],
        positionTooltip: { x: 0, y: 0 },
        count: 0,
      },
      hoverLine: false,
      errorDrawing: false,
      ctrlDown: false,
    };
    this.ctrlPressed = this.ctrlPressed.bind(this);
    this.ctrlPressedUp = this.ctrlPressedUp.bind(this);
    this.editableLayer = null;
    this.setMapRef = this.setMapRef.bind(this);
    this.filterBoundsOnMapDebounced = debounce(this.filterBoundsOnMap, 250);
  }

  ctrlPressed(event) {
    if (event.ctrlKey) {
      this.setState({
        ctrlDown: true,
      });
    }
  }

  ctrlPressedUp(event) {
    if (!event.ctrlKey) {
      this.setState({
        ctrlDown: false,
      });
    }
  }

  bottombarTplWorkspace = (line) => {
    return this.apiService.getFilterRoute(line.lineId, line.id).pipe(
      take(1),
      tap((details) => {
        if (details && !details.error) {
          setCurrentIdTripForRoute(details.mvjs ? details.mvjs : []);
          setCurrentIdStopForRoute(details.points ? details.points : []);
        } else {
          console.log(details);
        }
      }),

      mergeMap((x) => merge(getDetailRoute(this, line, false, x.points))),

      catchError((error) => {
        console.error(error);
        return of(error);
      })
    );
  };

  componentDidMount() {
    const { map } = this.context;
    const {
      layoutMapReducer,
      setBounds,
      mapBoundingBoxReducer,
      currentMapZoom,
    } = this.props;

    this.INITIAL_VIEW_STATE = { ...map.mapOptions };
    if (mapBoundingBoxReducer.length > 0 && currentMapZoom) {
      const mapCenter = [
        (mapBoundingBoxReducer[0] + mapBoundingBoxReducer[2]) / 2,
        (mapBoundingBoxReducer[1] + mapBoundingBoxReducer[3]) / 2,
      ];
      this.setState({
        viewport: {
          ...this.INITIAL_VIEW_STATE,
          latitude: mapCenter[1],
          longitude: mapCenter[0],
          zoom: currentMapZoom,
        },
        style: this.getMapLayout(layoutMapReducer, map),
      });
      let bounds = [
        map.mapBounds[0][0],
        map.mapBounds[0][1],
        map.mapBounds[1][0],
        map.mapBounds[1][1],
      ];
      setBounds(bounds, this.INITIAL_VIEW_STATE.zoom);
    } else {
      this.setState({
        viewport: { ...this.INITIAL_VIEW_STATE },
        style: this.getMapLayout(layoutMapReducer, map),
      });
      let bounds = [
        map.mapBounds[0][0],
        map.mapBounds[0][1],
        map.mapBounds[1][0],
        map.mapBounds[1][1],
      ];
      setBounds(bounds, this.INITIAL_VIEW_STATE.zoom);
    }

    document.addEventListener("keydown", this.ctrlPressed, false);
    document.addEventListener("keyup", this.ctrlPressedUp, false);
    document
      .getElementById("acr-map")
      .addEventListener("contextmenu", (evt) => evt.preventDefault());
    this.apiService = createApiService(this.context);
  }

  getMapLayout = (mapLayout, map) => {
    switch (mapLayout) {
      case "light":
        return map.mapStyle.light;
      case "normal":
        return map.mapStyle.normal;
      case "streets":
        return map.mapStyle.streets;
      case "dark":
      default:
        return map.mapStyle.dark;
    }
  };

  componentDidUpdate(prevProps) {
    const {
      stopsCurrentReducer,
      activeLines,
      tripsCurrentReducer,
      vehicleCurrentReducer,
      hoveredObjectsReducer,
      pointRdsTmcReducer,
      currentEvent,
      centroidCurrentReducer,
      nodeCurrentReducer,
      arcCurrentReducer,
      stationCurrentReducer,
      setNavTabMultitab,
      trafficArcCurrentReducer,
      currentSelectedCam,
      currentParking,
      camerasOnMap,
      currentPmv,
      currentWorkspace,
      setCamerasList,
      addNewPinnedCamera,
      resetPinnedCameras,
      setAllCamerasVisibility,
      expandBottombar,
      setBottombarActiveTab,
      addBottombarTab,
      setGraphVisibility,
      setNetworkRdsTmcVisibility,
      setBounds,
      setTransportVisibility,
      setAllEventVisibility,
      setActiveVisibility,
      setPlannedVisibility,
      setTrafficVisibility,
      setTypicalVisibility,
      setNetStateVisibility,
      setStationVisibility,
      setParkingVisibility,
      setPmvVisibility,
      setAesysPanelVisibility,
      setSolariPanelVisibility,
      setFuturitPanelVisibility,
      setVisuallabPanelVisibility,
      setSpheraPanelVisibility,
      setLayout,
      closeBottombar,
      toggleOpenNavTab,
      isToggleSavingWorkspace,
      resetActiveLines,
      resetTrafficVisibility,
      toggleSaveWorkspace,
      filterByMapBounds,
    } = this.props;

    const { viewport } = this.state;

    const { map } = this.context;

    if (
      ((currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) ||
        (prevProps.currentWorkspace &&
          currentWorkspace &&
          prevProps.currentWorkspace.preferenceId !==
            currentWorkspace.preferenceId)) &&
      isToggleSavingWorkspace === true
    ) {
      toggleSaveWorkspace(false);
    } else if (
      ((currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) ||
        (prevProps.currentWorkspace &&
          currentWorkspace &&
          prevProps.currentWorkspace.preferenceId !==
            currentWorkspace.preferenceId)) &&
      isToggleSavingWorkspace === false
    ) {
      let parsedObject;

      if (
        currentWorkspace &&
        currentWorkspace.payload &&
        currentWorkspace.payload.value
      ) {
        parsedObject = JSON.parse(currentWorkspace.payload.value);
        toggleOpenNavTab(false);
        //SET MAP LAYOUT, BOUND AND ZOOM
        if (parsedObject.layoutMap && parsedObject.layoutMap !== "") {
          setLayout(parsedObject.layoutMap);

          this.setState({
            style: this.getMapLayout(parsedObject.layoutMap, map),
          });
        }

        //SET GRAPH MENU
        if (parsedObject.menu && parsedObject.menu.graph) {
          setGraphVisibility({
            all: parsedObject.menu.graph.graph
              ? parsedObject.menu.graph.graph
              : false,
            arc: parsedObject.menu.graph.arc
              ? parsedObject.menu.graph.arc
              : false,
            node: parsedObject.menu.graph.node
              ? parsedObject.menu.graph.node
              : false,
            centroid: parsedObject.menu.graph.centroid
              ? parsedObject.menu.graph.centroid
              : false,
          });
          setNetworkRdsTmcVisibility({
            all: parsedObject.menu.graph.networkRdsTmc
              ? parsedObject.menu.graph.networkRdsTmc
              : false,
          });
        }
        //SET TPL MENU
        if (parsedObject.menu && parsedObject.menu.tpl) {
          setTransportVisibility({
            all: parsedObject.menu.tpl.transportation
              ? parsedObject.menu.tpl.transportation
              : false,
            city: parsedObject.menu.tpl.city
              ? parsedObject.menu.tpl.city
              : false,
            region: parsedObject.menu.tpl.regional
              ? parsedObject.menu.tpl.regional
              : false,
          });
          setAllEventVisibility(
            parsedObject.menu.tpl.events ? parsedObject.menu.tpl.events : false
          );
          setActiveVisibility(
            parsedObject.menu.tpl.activeEvents
              ? parsedObject.menu.tpl.activeEvents
              : false
          );
          setPlannedVisibility(
            parsedObject.menu.tpl.plannedEvents
              ? parsedObject.menu.tpl.plannedEvents
              : false
          );
          setTrafficVisibility(
            parsedObject.menu.tpl.traffic
              ? parsedObject.menu.tpl.traffic
              : false
          );

          if (parsedObject.menu.tpl.typicalTraffic) {
            setTypicalVisibility(parsedObject.menu.tpl.typicalTraffic);
          }

          if (parsedObject.menu.tpl.stateTraffic) {
            setNetStateVisibility(parsedObject.menu.tpl.stateTraffic);
          }

          if (
            parsedObject.menu.tpl.typicalTraffic === false &&
            parsedObject.menu.tpl.stateTraffic === false
          ) {
            resetTrafficVisibility();
          }
        }
        //INSTALLATIONS
        if (parsedObject.menu && parsedObject.menu.installations) {
          setStationVisibility(
            parsedObject.menu.installations.station
              ? parsedObject.menu.installations.station
              : false
          );
          setParkingVisibility(
            parsedObject.menu.installations.parking
              ? parsedObject.menu.installations.parking
              : false
          );
          setPmvVisibility(
            parsedObject.menu.installations.pmv
              ? parsedObject.menu.installations.pmv
              : false
          );
          setAesysPanelVisibility(
            parsedObject.menu.installations.aesysPmv
              ? parsedObject.menu.installations.aesysPmv
              : false
          );
          setSolariPanelVisibility(
            parsedObject.menu.installations.solariPmv
              ? parsedObject.menu.installations.solariPmv
              : false
          );
          setFuturitPanelVisibility(
            parsedObject.menu.installations.futuritPmv
              ? parsedObject.menu.installations.futuritPmv
              : false
          );
          setVisuallabPanelVisibility(
            parsedObject.menu.installations.visualLabPmv
              ? parsedObject.menu.installations.visualLabPmv
              : false
          );
          setSpheraPanelVisibility(
            parsedObject.menu.installations.spheraPmv
              ? parsedObject.menu.installations.spheraPmv
              : false
          )
        }

        if (
          parsedObject.mapCenter &&
          parsedObject.mapCenter.length > 0 &&
          parsedObject.zoom
        ) {
          this.mapFlyTo(
            parsedObject.mapCenter[1],
            parsedObject.mapCenter[0],
            parsedObject.zoom,
            0
          );

          const deckElm = document.getElementById("acr-map");
          if (deckElm) {
            const deckHeight = deckElm.clientHeight;
            const deckWidth = deckElm.clientWidth;

            const newBounds = getBoundsFromWebmercatorView(
              parsedObject.mapCenter[1],
              parsedObject.mapCenter[0],
              parsedObject.zoom,
              deckHeight,
              deckWidth
            );

            setBounds(newBounds, parsedObject.zoom);
            filterByMapBounds(newBounds);

            //set cctv on menu and pinned cameras
            const isCameravisible =
              parsedObject.menu &&
              parsedObject.menu.installations &&
              parsedObject.menu.installations.cameras;

            resetPinnedCameras();
            setAllCamerasVisibility(isCameravisible);

            if (isCameravisible) {
              const bbox = getBoundingBoxStringForBboxParamNoEpsg(newBounds);

              this.subscriptions.push(
                this.apiService
                  .getCamerasListByBbox(bbox)
                  .pipe(
                    tap((data) => {
                      if (data.features) {
                        let featureCollection = CameraFeatureCollectionModel.fromREST(
                          data
                        );
                        let cameras = [];
                        cameras = [...featureCollection.features];
                        setCamerasList(cameras);
                        if (
                          parsedObject.bottombar &&
                          parsedObject.bottombar.cctv &&
                          parsedObject.bottombar.cctv.length > 0
                        ) {
                          let pinnedCamerasPreference =
                            parsedObject.bottombar.cctv;
                          cameras.forEach((item) => {
                            let pinnedCameraToAdd = pinnedCamerasPreference.find(
                              (pinned) =>
                                pinned ===
                                item.properties.cctvWithLastStateAndOpenAlarms
                                  .cctv.id
                            );
                            if (pinnedCameraToAdd) {
                              addNewPinnedCamera(item);
                              addBottombarTab("Telecamere");
                              setBottombarActiveTab("Telecamere");
                              expandBottombar();
                            }
                          });
                        }
                      }
                    }),
                    concatMap((data) =>
                      this.retrieveCamerasOutOfBound(data, parsedObject)
                    ),
                    catchError((error) => {
                      console.error(error);
                      return of(error);
                    })
                  )
                  .subscribe()
              );
            } else {
              closeBottombar();
            }

            const isTplVisible =
              (parsedObject.menu &&
                parsedObject.menu.tpl &&
                parsedObject.menu.tpl.city) ||
              (parsedObject.menu &&
                parsedObject.menu.tpl &&
                parsedObject.menu.tpl.regional);

            if (isTplVisible) {
              let tpl = parsedObject.bottombar && parsedObject.bottombar.tpl;
              resetActiveLines();
              if (tpl.length > 0) {
                let routeArr = [];
                tpl.map((item) => {
                  let substrings = item.split("-");
                  let routeId = substrings[0];
                  let lineId = substrings[1];
                  let nameShort = substrings[2];
                  let code = substrings[3];
                  let route = {
                    lineId: lineId,
                    id: routeId,
                    routeRef: routeId,
                    nameShort: nameShort,
                    transportMode: {
                      subMode: {
                        code: code,
                      },
                    },
                  };
                  routeArr.push(route);
                });

                addBottombarTab("Linearizzata");
                setBottombarActiveTab("Linearizzata");
                expandBottombar();

                of(...routeArr)
                  .pipe(
                    concatMap((line) => {
                      return this.bottombarTplWorkspace(line);
                    }),
                    finalize(() => {
                      if (
                        parsedObject.mapCenter &&
                        parsedObject.mapCenter.length > 0 &&
                        parsedObject.zoom &&
                        newBounds
                      ) {
                        this.mapFlyTo(
                          parsedObject.mapCenter[1],
                          parsedObject.mapCenter[0],
                          parsedObject.zoom,
                          0
                        );
                        setBounds(newBounds, parsedObject.zoom);
                      }
                    }),
                    catchError((error) => {
                      console.error(error);
                      return of(error);
                    })
                  )
                  .subscribe();
              }
            } else {
              closeBottombar();
            }
          }
        }
      }
    }

    if (
      (stopsCurrentReducer && !prevProps.stopsCurrentReducer) ||
      (stopsCurrentReducer &&
        prevProps.stopsCurrentReducer &&
        prevProps.stopsCurrentReducer.id !== stopsCurrentReducer.id &&
        !currentWorkspace)
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      if (currentWorkspace === null) {
        const deckElm = document.getElementById("acr-map");
        if (deckElm) {
          const deckHeight = deckElm.clientHeight;
          const deckWidth = deckElm.clientWidth;
          const newBounds = getBoundsFromWebmercatorView(
            stopsCurrentReducer.coordinates.latitude,
            stopsCurrentReducer.coordinates.longitude,
            newZoom,
            deckHeight,
            deckWidth
          );
          setBounds(newBounds, newZoom);
          filterByMapBounds(newBounds);
        }
        this.mapFlyTo(
          stopsCurrentReducer.coordinates.latitude,
          stopsCurrentReducer.coordinates.longitude,
          newZoom,
          0
        );
      }
      this.onHoverStop(null, 0, 0);

      setNavTabMultitab("Trasporto Pubblico");
    }

    if (
      prevProps.stopsCurrentReducer !== stopsCurrentReducer &&
      stopsCurrentReducer === null
    ) {
      this.filterBoundsOnMap(this.state.viewport.zoom);
    }

    if (!activeLines && activeLines.length === 0) {
      this.prevPropsRoute = null;
    } else if (
      (activeLines && activeLines.length > 0 && !this.prevPropsRoute) ||
      (activeLines &&
        activeLines.length > 0 &&
        this.prevPropsRoute &&
        this.prevPropsRoute.id !== activeLines[activeLines.length - 1].id)
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      this.prevPropsRoute = {
        ...activeLines[activeLines.length - 1],
      };
      let ind = 0;
      if (this.prevPropsRoute.points && this.prevPropsRoute.points.length > 0) {
        ind = Math.round(this.prevPropsRoute.points.length / 2);

        if (currentWorkspace === null) {
          const deckElm = document.getElementById("acr-map");
          if (deckElm) {
            const deckHeight = deckElm.clientHeight;
            const deckWidth = deckElm.clientWidth;
            const newBounds = getBoundsFromWebmercatorView(
              this.prevPropsRoute.points[ind].coordinates.latitude,
              this.prevPropsRoute.points[ind].coordinates.longitude,
              newZoom,
              deckHeight,
              deckWidth
            );
            setBounds(newBounds, newZoom);
          }

          this.mapFlyTo(
            this.prevPropsRoute.points[ind].coordinates.latitude,
            this.prevPropsRoute.points[ind].coordinates.longitude,
            newZoom,
            0
          );

          this.filterBoundsOnMap(this.state.viewport.newZoom);
        }
      }

      setNavTabMultitab("Trasporto Pubblico");
    }

    if (
      (tripsCurrentReducer &&
        tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation &&
        !prevProps.tripsCurrentReducer) ||
      (tripsCurrentReducer &&
        prevProps.tripsCurrentReducer &&
        tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation &&
        prevProps.tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation &&
        prevProps.tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation
          .longitude !==
          tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation
            .longitude &&
        prevProps.tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation
          .latitude !==
          tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation.latitude)
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      if (currentWorkspace === null) {
        const deckElm = document.getElementById("acr-map");
        if (deckElm) {
          const deckHeight = deckElm.clientHeight;
          const deckWidth = deckElm.clientWidth;
          const newBounds = getBoundsFromWebmercatorView(
            tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation
              .latitude,
            tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation
              .longitude,
            newZoom,
            deckHeight,
            deckWidth
          );
          setBounds(newBounds, newZoom);
          filterByMapBounds(newBounds);
        }

        this.mapFlyTo(
          tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation.latitude,
          tripsCurrentReducer.monitoredVehicleJourney.vehicleLocation.longitude,
          newZoom,
          0
        );
      }

      setNavTabMultitab("Trasporto Pubblico");
    }

    if (!vehicleCurrentReducer) {
      this.prevPropsVehicle = null;
    } else if (
      (vehicleCurrentReducer &&
        hoveredObjectsReducer.hoveredBus.toBeFollowed &&
        vehicleCurrentReducer &&
        vehicleCurrentReducer.vehicleActivityLocation &&
        vehicleCurrentReducer.vehicleActivityLocation.monitoredVehicleJourney
          .vehicleLocation &&
        !this.prevPropsVehicle) ||
      (vehicleCurrentReducer.vehicleActivityLocation &&
        this.prevPropsVehicle &&
        vehicleCurrentReducer.vehicleActivityLocation.monitoredVehicleJourney
          .vehicleLocation &&
        this.prevPropsVehicle.vehicleActivityLocation.monitoredVehicleJourney
          .vehicleLocation &&
        this.prevPropsVehicle.vehicleActivityLocation.monitoredVehicleJourney
          .vehicleLocation.longitude !==
          vehicleCurrentReducer.vehicleActivityLocation.monitoredVehicleJourney
            .vehicleLocation.longitude &&
        this.prevPropsVehicle.vehicleActivityLocation.monitoredVehicleJourney
          .vehicleLocation.latitude !==
          vehicleCurrentReducer.vehicleActivityLocation.monitoredVehicleJourney
            .vehicleLocation.latitude)
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      this.prevPropsVehicle = { ...vehicleCurrentReducer };

      if (currentWorkspace === null) {
        const deckElm = document.getElementById("acr-map");
        if (deckElm) {
          const deckHeight = deckElm.clientHeight;
          const deckWidth = deckElm.clientWidth;
          const newBounds = getBoundsFromWebmercatorView(
            this.prevPropsVehicle.vehicleActivityLocation
              .monitoredVehicleJourney.vehicleLocation.latitude,
            this.prevPropsVehicle.vehicleActivityLocation
              .monitoredVehicleJourney.vehicleLocation.longitude,
            newZoom,
            deckHeight,
            deckWidth
          );
          setBounds(newBounds, newZoom);
          filterByMapBounds(newBounds);
        }
        this.mapFlyTo(
          this.prevPropsVehicle.vehicleActivityLocation.monitoredVehicleJourney
            .vehicleLocation.latitude,
          this.prevPropsVehicle.vehicleActivityLocation.monitoredVehicleJourney
            .vehicleLocation.longitude,
          newZoom,
          1000
        );
      }
      this.onHoverLine(null, 0, 0);

      setNavTabMultitab("Trasporto Pubblico");
    }

    if (
      centroidCurrentReducer &&
      centroidCurrentReducer.geometry.coordinates[0] &&
      centroidCurrentReducer.geometry.coordinates[1] &&
      prevProps.centroidCurrentReducer !== centroidCurrentReducer
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          centroidCurrentReducer.geometry.coordinates[1],
          centroidCurrentReducer.geometry.coordinates[0],
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }
      this.mapFlyTo(
        centroidCurrentReducer.geometry.coordinates[1],
        centroidCurrentReducer.geometry.coordinates[0],
        newZoom,
        0
      );
      setNavTabMultitab("Grafo Stradale");
    }

    if (
      nodeCurrentReducer &&
      nodeCurrentReducer.geometry.coordinates[0] &&
      nodeCurrentReducer.geometry.coordinates[1] &&
      prevProps.nodeCurrentReducer !== nodeCurrentReducer
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          nodeCurrentReducer.geometry.coordinates[1],
          nodeCurrentReducer.geometry.coordinates[0],
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }
      this.mapFlyTo(
        nodeCurrentReducer.geometry.coordinates[1],
        nodeCurrentReducer.geometry.coordinates[0],
        newZoom,
        0
      );
      setNavTabMultitab("Grafo Stradale");
    }

    if (
      pointRdsTmcReducer &&
      pointRdsTmcReducer.geometry.coordinates[0] &&
      pointRdsTmcReducer.geometry.coordinates[1] &&
      prevProps.pointRdsTmcReducer !== pointRdsTmcReducer
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }

      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          pointRdsTmcReducer.geometry.coordinates[1],
          pointRdsTmcReducer.geometry.coordinates[0],
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }

      this.mapFlyTo(
        pointRdsTmcReducer.geometry.coordinates[1],
        pointRdsTmcReducer.geometry.coordinates[0],
        newZoom,
        0
      );
      this.onHoverRdsTmcPoint(null, 0, 0);
      setNavTabMultitab("Rete RDS-TMC");
    }

    if (
      arcCurrentReducer &&
      arcCurrentReducer.geometry.coordinates &&
      prevProps.arcCurrentReducer !== arcCurrentReducer
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          Math.round(
            arcCurrentReducer.geometry.coordinates[
              Math.round(
                (arcCurrentReducer.geometry.coordinates.length - 1) / 2
              )
            ][1] * 100000
          ) / 100000,
          Math.round(
            arcCurrentReducer.geometry.coordinates[
              Math.round(
                (arcCurrentReducer.geometry.coordinates.length - 1) / 2
              )
            ][0] * 100000
          ) / 100000,
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }

      this.mapFlyTo(
        Math.round(
          arcCurrentReducer.geometry.coordinates[
            Math.round((arcCurrentReducer.geometry.coordinates.length - 1) / 2)
          ][1] * 100000
        ) / 100000,
        Math.round(
          arcCurrentReducer.geometry.coordinates[
            Math.round((arcCurrentReducer.geometry.coordinates.length - 1) / 2)
          ][0] * 100000
        ) / 100000,
        newZoom,
        0
      );
      setNavTabMultitab("Grafo Stradale");
    }

    if (
      trafficArcCurrentReducer &&
      trafficArcCurrentReducer.geometry.coordinates &&
      prevProps.trafficArcCurrentReducer !== trafficArcCurrentReducer
    ) {
      let newZoom = 16;

      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }

      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          Math.round(
            trafficArcCurrentReducer.geometry.coordinates[
              Math.round(
                (trafficArcCurrentReducer.geometry.coordinates.length - 1) / 2
              )
            ][1] * 100000
          ) / 100000,
          Math.round(
            trafficArcCurrentReducer.geometry.coordinates[
              Math.round(
                (trafficArcCurrentReducer.geometry.coordinates.length - 1) / 2
              )
            ][0] * 100000
          ) / 100000,
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }

      this.mapFlyTo(
        Math.round(
          trafficArcCurrentReducer.geometry.coordinates[
            Math.round(
              (trafficArcCurrentReducer.geometry.coordinates.length - 1) / 2
            )
          ][1] * 100000
        ) / 100000,
        Math.round(
          trafficArcCurrentReducer.geometry.coordinates[
            Math.round(
              (trafficArcCurrentReducer.geometry.coordinates.length - 1) / 2
            )
          ][0] * 100000
        ) / 100000,
        newZoom,
        0
      );

      setNavTabMultitab("Traffico");
    }

    if (
      currentEvent &&
      currentEvent.groupOfLocations &&
      currentEvent.groupOfLocations.length > 0 &&
      prevProps.currentEvent !== currentEvent
    ) {
      this.getEventListOfArcs(currentEvent);

      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      let coords = getEventPosition(currentEvent.groupOfLocations);
      if (coords[0] !== "" && coords[1] !== "") {
        const deckElm = document.getElementById("acr-map");
        if (deckElm) {
          const deckHeight = deckElm.clientHeight;
          const deckWidth = deckElm.clientWidth;
          const newBounds = getBoundsFromWebmercatorView(
            coords[1],
            coords[0],
            newZoom,
            deckHeight,
            deckWidth
          );
          setBounds(newBounds, newZoom);
          filterByMapBounds(newBounds);
        }

        this.mapFlyTo(coords[1], coords[0], newZoom, 0);
      }
      this.onHoverEvent(null, 0, 0);
      this.filterBoundsOnMap(newZoom);
      setNavTabMultitab("Eventi");
    }

    if (prevProps.currentEvent !== currentEvent && currentEvent === null) {
      this.filterBoundsOnMap(this.state.viewport.zoom);
    }

    if (
      stationCurrentReducer &&
      stationCurrentReducer.longitude &&
      stationCurrentReducer.latitude &&
      prevProps.stationCurrentReducer !== stationCurrentReducer
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }

      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          stationCurrentReducer.latitude,
          stationCurrentReducer.longitude,
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }
      this.mapFlyTo(
        stationCurrentReducer.latitude,
        stationCurrentReducer.longitude,
        newZoom,
        0
      );
      this.onHoverStation(null, 0, 0);
      setNavTabMultitab("Stazioni di Misura");
    }

    if (
      prevProps.stationCurrentReducer !== stationCurrentReducer &&
      stationCurrentReducer === null
    ) {
      this.filterBoundsOnMap(this.state.viewport.zoom);
    }

    if (
      currentSelectedCam &&
      currentSelectedCam.properties &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
        .latitude &&
      currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
        .longitude &&
      prevProps.currentSelectedCam !== currentSelectedCam
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }

      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
            .latitude,
          currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
            .longitude,
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }

      this.mapFlyTo(
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
          .latitude,
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
          .longitude,
        newZoom,
        0
      );
      this.onHoverCamera(null, 0, 0);
      setNavTabMultitab("Telecamere");
    }

    if (
      currentParking &&
      currentParking.geometry &&
      currentParking.geometry.coordinates[0] &&
      currentParking.geometry.coordinates[1] &&
      ((!prevProps.currentParking &&
        prevProps.currentParking !== currentParking) ||
        (prevProps.currentParking &&
          prevProps.currentParking.id &&
          currentParking.id &&
          prevProps.currentParking.id !== currentParking.id))
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          currentParking.geometry.coordinates[0],
          currentParking.geometry.coordinates[1],
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }
      this.mapFlyTo(
        currentParking.geometry.coordinates[0],
        currentParking.geometry.coordinates[1],
        newZoom,
        0
      );
      setNavTabMultitab("Parcheggi");
    }

    if (
      prevProps.camerasOnMap &&
      camerasOnMap &&
      prevProps.camerasOnMap.length !== camerasOnMap.length &&
      camerasOnMap.length > prevProps.camerasOnMap.length
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }
      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          camerasOnMap[camerasOnMap.length - 1].properties
            .cctvWithLastStateAndOpenAlarms.cctv.latitude,
          camerasOnMap[camerasOnMap.length - 1].properties
            .cctvWithLastStateAndOpenAlarms.cctv.longitude,
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }

      this.mapFlyTo(
        camerasOnMap[camerasOnMap.length - 1].properties
          .cctvWithLastStateAndOpenAlarms.cctv.latitude,
        camerasOnMap[camerasOnMap.length - 1].properties
          .cctvWithLastStateAndOpenAlarms.cctv.longitude,
        newZoom,
        0
      );
      setNavTabMultitab("Telecamere");
    }

    if (
      currentPmv &&
      currentPmv.geometry &&
      currentPmv.geometry.coordinates[0] &&
      currentPmv.geometry.coordinates[1] &&
      prevProps.currentPmv !== currentPmv
    ) {
      let newZoom = 16;
      if (viewport.zoom + 0.5 < viewport.maxZoom && viewport.zoom + 0.5 > 15) {
        newZoom = viewport.zoom + 0.5;
      } else if (viewport.zoom + 0.5 > viewport.maxZoom) {
        newZoom = viewport.maxZoom;
      }

      const deckElm = document.getElementById("acr-map");
      if (deckElm) {
        const deckHeight = deckElm.clientHeight;
        const deckWidth = deckElm.clientWidth;
        const newBounds = getBoundsFromWebmercatorView(
          currentPmv.geometry.coordinates[0],
          currentPmv.geometry.coordinates[1],
          newZoom,
          deckHeight,
          deckWidth
        );
        setBounds(newBounds, newZoom);
        filterByMapBounds(newBounds);
      }

      this.mapFlyTo(
        currentPmv.geometry.coordinates[0],
        currentPmv.geometry.coordinates[1],
        newZoom,
        0
      );

      this.onHoverPMV(null, 0, 0);
      setNavTabMultitab("Pannelli");
    }
  }

  retrieveCamerasOutOfBound = (cameras, parsedObject) => {
    const {
      addNewPinnedCamera,
      addBottombarTab,
      setBottombarActiveTab,
      expandBottombar,
    } = this.props;

    if (
      parsedObject.bottombar &&
      parsedObject.bottombar.cctv &&
      parsedObject.bottombar.cctv.length > 0 &&
      cameras
    ) {
      const pinnedCamerasPreference = parsedObject.bottombar.cctv;
      let camerasNotInBbox = [];

      if (cameras.features && cameras.features.length > 0) {
        pinnedCamerasPreference.forEach((pinned) => {
          let pinnedCameraToAdd = cameras.features.find(
            (item) =>
              pinned === item.properties.cctvWithLastStateAndOpenAlarms.cctv.id
          );
          if (!pinnedCameraToAdd) {
            camerasNotInBbox.push(pinned);
          }
        });
      } else {
        camerasNotInBbox = [...pinnedCamerasPreference];
      }

      return of(...camerasNotInBbox).pipe(
        concatMap((cam) =>
          this.apiService.getCameraWithLastStateById(cam).pipe(
            tap((camera) => {
              let newCamObj = {
                id: camera.id,
                properties: {
                  cctvWithLastStateAndOpenAlarms: {
                    ...camera,
                  },
                },
              };
              addNewPinnedCamera(newCamObj);
              addBottombarTab("Telecamere");
              setBottombarActiveTab("Telecamere");
              expandBottombar();
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
        )
      );
    } else return of([]);
  };

  getEventListOfArcs = (current) => {
    const { setArcsListEvent } = this.props;

    let indexOfLinearWithinElement =
      current &&
      current.groupOfLocations &&
      current.groupOfLocations[0] &&
      current.groupOfLocations.findIndex(
        (x) => x.locationType === "LINEAR_WITHIN_LINEAR_ELEMENT"
      );
    if (
      current &&
      current.groupOfLocations &&
      current.groupOfLocations.length > 0 &&
      current.groupOfLocations[indexOfLinearWithinElement] &&
      current.groupOfLocations[indexOfLinearWithinElement].linear
    ) {
      let arrArcs = [];
      let routing = [];
      current.groupOfLocations.forEach((arc) => {
        arrArcs.push({
          archId:
            arc.linear && arc.linear.linearWithinLinearElement
              ? arc.linear.linearWithinLinearElement.linearElement.archId
              : null,
        });
      });
      arrArcs = arrArcs.filter((item) => {
        return item.archId != null;
      });

      of(...arrArcs)
        .pipe(
          concatMap((node) =>
            this.apiService.getGeoserverFeatures(
              "road_sections",
              null,
              null,
              null,
              null,
              null,
              "arcid='" + node.archId + "'"
            )
          ),
          tap((x) => {
            if (x && !x.error) {
              routing.push(x.features[0]);
            } else {
              console.log("error", x);
            }
          }),
          finalize(() => {
            setArcsListEvent(routing);
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe();
    } else {
      setArcsListEvent([]);
    }
  };

  // SETTERS

  setMapRef = (element) => {
    if (element) {
      this.staticmap = element;
      this.map = this.staticmap.getMap();
    }
  };

  setPan = () => {
    MapDrawingTools.setPan(this);
  };

  // DRAWING TOOLS
  drawCircle = () => {
    MapDrawingTools.drawCircle(this);
  };

  ifFunct = () => {
    const { layoutMapReducer } = this.props;
    const { layoutMap } = this.state;

    if (this.map && layoutMapReducer && layoutMapReducer !== layoutMap) {
      MapListeners.handleSwitchLayer(this, layoutMapReducer);
    }
  };

  goBackward = () => {
    MapDrawingTools.goBackward(this);
    setTimeout(() => {
      this.ifFunct();
    }, 1000);
  };

  goForward = () => {
    MapDrawingTools.goForward(this);
    setTimeout(() => {
      this.ifFunct();
    }, 1000);
  };

  drawPolygon = () => {
    MapDrawingTools.drawPolygon(this);
  };

  drawRuler = () => {
    MapDrawingTools.drawRuler(this);
  };

  drawDistance = () => {
    MapDrawingTools.drawDistance(this);
  };

  // GENERIC
  deleteLayer = () => {
    MapDrawingTools.deleteLayer(this);
  };

  toDefaultPosition = () => {
    MapDrawingTools.toDefaultPosition(this);
  };

  toNord = () => {
    MapDrawingTools.toNord(this);
  };

  zoomIn = () => {
    MapDrawingTools.zoomIn(this);
  };

  zoomOut = () => {
    MapDrawingTools.zoomOut(this);
  };

  // HANDLERS
  handleSwitchLayer = (event) => {
    MapListeners.handleSwitchLayer(this, event);
  };

  // EVENT LISTENERS
  onClickStop = (object) => {
    MapListeners.onClickStop(this, object);
  };

  onClickCentroid = (object) => {
    MapListeners.onClickCentroid(this, object);
  };

  onClickArc = (object) => {
    MapListeners.onClickArc(this, object);
  };

  onClickTrafficArc = (object) => {
    MapListeners.onClickTrafficArc(this, object);
  };

  onClickPoint = (object) => {
    MapListeners.onClickPoint(this, object);
  };

  onClickNode = (object) => {
    MapListeners.onClickNode(this, object);
  };
  onClickStation = (object) => {
    MapListeners.onClickStation(this, object);
  };
  onClickWebcam = (object) => {
    MapListeners.onClickWebcam(this, object);
  };

  onClickPMV = (object) => {
    MapListeners.onClickPMV(this, object);
  };

  onClickSegments = (object) => {
    MapListeners.onClickSegments(this, object);
  };
  onClickEvent = (object) => {
    MapListeners.onClickEvent(this, object);
  };

  onClickParking = (object) => {
    MapListeners.onClickParking(this, object);
  };

  onClickLine = (type) => (obj) => {
    MapListeners.onClickLine(type, obj, this, reduceDuplicatesInArrayForId);
  };

  onLayerClick = (info) => {
    MapListeners.onLayerClick(this, info);
  };

  onClickBusTpl = (object, x, y) => {
    MapListeners.onClickBusTpl(object, x, y, this);
  };

  onCircleClick = () => {
    MapListeners.onCircleClick(this);
  };

  onPolygonClick = () => {
    MapListeners.onPolygonClick(this);
  };

  onShortestDistanceOnGraphClick = () => {
    MapListeners.onShortestDistanceOnGraphClick(this);
  };

  onMultipleDistanceClick = () => {
    MapListeners.onMultipleDistanceClick(this);
  };

  infoSelectRoute = (route) => {
    console.log("infoSelectRoute", route);
  };

  mapFlyTo = (lat, lon, zoom, dur) => {
    MapListeners.mapFlyTo(this, lat, lon, zoom, dur);
  };

  mapFitBounds = (points) => {
    MapListeners.mapFitBounds(this, points);
  };

  onEdit = ({ updatedData, editType, editContext }) => {
    MapListeners.onEdit({ updatedData, editType, editContext }, this);
  };

  // RENDER METHODS
  initDrawingLayer = (layerProps) => {
    return MapRenderers.initDrawingLayer(this, layerProps);
  };

  renderMapControls = () => {
    return MapRenderers.renderMapControls(this);
  };

  renderScale = () => {
    return MapRenderers.renderScale(this);
  };

  renderMultipleDistanceLayer = () => {
    return MapRenderers.renderMultipleDistanceLayer(this);
  };

  renderShortestDistanceOnGraph = () => {
    return MapRenderers.renderShortestDistanceOnGraph(this);
  };

  renderRealTimeLayer = () => {
    return MapRenderers.renderRealTimeLayer(this);
  };

  renderEventsLayer = (id, data, visible) => {
    return MapRenderers.renderEventsLayer(this, id, data, visible);
  };

  renderArcLayer = (id, data, color, lighterColor, visible) => {
    return MapRenderers.renderArcLayer(
      this,
      id,
      data,
      color,
      lighterColor,
      visible
    );
  };

  renderTrafficArcLayer = (id, data, visible) => {
    return MapRenderers.renderTrafficArcLayer(this, id, data, visible);
  };

  renderArcLayerEvent = (id, data, color, lighterColor, visible) => {
    return MapRenderers.renderArcLayerEvent(
      this,
      id,
      data,
      color,
      lighterColor,
      visible
    );
  };

  renderShortestDistanceArcLayer = (id, data, color, visible) => {
    return MapRenderers.renderShortestDistanceArcLayer(
      this,
      id,
      data,
      color,
      visible
    );
  };
  renderStationsLayer = () => {
    return MapRenderers.renderStationsLayer(this);
  };

  renderWebcamsLayer = () => {
    return MapRenderers.renderWebcamsLayer(this);
  };

  renderNodeLayer = () => {
    return MapRenderers.renderNodeLayer(this);
  };

  renderCentroidsLayer = () => {
    const { viewport } = this.state;
    if (viewport.zoom >= 15) {
      return MapRenderers.renderCentroidsLayer(this);
    }
  };

  renderPmvsLayer = () => {
    return MapRenderers.renderPmvsLayer(this);
  };

  renderSegmentsTmcLayer = (id, data, color, visible) => {
    return MapRenderers.renderSegmentsTmcLayer(this, id, data, color, visible);
  };

  renderPointsTmcLayer = () => {
    return MapRenderers.renderPointsTmcLayer(this);
  };

  renderInstallationsLayer = (id, data, visible) => {
    return MapRenderers.renderInstallationsLayer(this, id, data, visible);
  };

  renderDrawPointsLayer = () => {
    return MapRenderers.renderDrawPointsLayer(this);
  };

  renderParkingLayer = (id, data, visible) => {
    return MapRenderers.renderParkingLayer(this, id, data, visible);
  };

  renderStaticMap = () => {
    return MapRenderers.renderStaticMap(this);
  };

  renderTrafficWidget = () => {
    return MapRenderers.renderTrafficWidget(this);
  };

  onOpenTimeSlider = () => {
    const { toggleOpenTrafficTimeSlider, isTimeSliderVisible } = this.props;
    toggleOpenTrafficTimeSlider(!isTimeSliderVisible);
  };

  createBusStopLayer(id, data, active, visible) {
    const { viewport } = this.state;
    if (viewport.zoom >= 14) {
      return MapRenderers.createBusStopLayer(
        this,
        id,
        data,
        active,
        visible,
        viewport
      );
    }
  }

  createBusLineLayer(id, data, color, onClick, visible = true, selected) {
    return MapRenderers.createBusLineLayer(
      this,
      id,
      data,
      color,
      onClick,
      visible,
      selected
    );
  }

  // HOVER LISTENERS
  onHoverInstallation(object, x, y) {
    MapHoverListeners.onHoverInstallation(this, object, x, y);
  }

  onHoverEvent(object, x, y) {
    MapHoverListeners.onHoverEvent(this, object, x, y);
  }

  onHoverStation(object, x, y) {
    MapHoverListeners.onHoverStation(this, object, x, y);
  }

  onHoverLine(object, x, y) {
    MapHoverListeners.onHoverLine(this, object, x, y);
  }

  onHoverStop(object, x, y) {
    MapHoverListeners.onHoverStop(this, object, x, y);
  }

  _onHoverBus = (object, x, y) => {
    MapHoverListeners._onHoverBus(this, object, x, y);
  };

  onHoverRdsTmcPoint(object, x, y) {
    MapHoverListeners.onHoverRdsTmcPoint(this, object, x, y);
  }

  onHoverArc(object, x, y) {
    MapHoverListeners.onHoverArc(this, object, x, y);
  }

  onHoverTrafficArc(object, x, y) {
    MapHoverListeners.onHoverTrafficArc(this, object, x, y);
  }

  onHoverNode(object, x, y) {
    MapHoverListeners.onHoverNode(this, object, x, y);
  }
  onHoverCentroid(object, x, y) {
    MapHoverListeners.onHoverCentroid(this, object, x, y);
  }

  onHoverParking(object, x, y) {
    MapHoverListeners.onHoverParking(this, object, x, y);
  }

  onHoverCamera(object, x, y) {
    MapHoverListeners.onHoverCamera(this, object, x, y);
  }

  onHoverPMV(object, x, y) {
    MapHoverListeners.onHoverPMV(this, object, x, y);
  }

  filterBoundsOnMap = (zoom) => {
    const {
      setBounds,
      filterByMapBounds,
      stopsCurrentReducer,
      tripsCurrentReducer,
      activeLines,
      currentEvent,
      stationCurrentReducer,
    } = this.props;
    if (
      (!stopsCurrentReducer &&
        !tripsCurrentReducer &&
        activeLines &&
        activeLines.length === 0) ||
      !currentEvent ||
      !stationCurrentReducer
    ) {
      if (this.map) {
        const bounds = this.map.getBounds();
        const bbox = [
          bounds._sw.lng,
          bounds._sw.lat,
          bounds._ne.lng,
          bounds._ne.lat,
        ];
        setBounds(bbox, zoom);
        filterByMapBounds(bbox);
      }
    }
  };

  onViewState = ({ viewState }) => {
    this.filterBoundsOnMapDebounced(viewState.zoom);

    if (viewState && viewState.zoom < 14) {
      this.onHoverStop(null, 0, 0);
      this._onHoverBus(null, 0, 0);
      this.onHoverEvent(null, 0, 0);
      this.onHoverStation(null, 0, 0);
      this.onHoverInstallation(null, 0, 0);
      this.onHoverLine(null, 0, 0);
      this.onHoverRdsTmcPoint(null, 0, 0);
      this.onHoverParking(null, 0, 0);
      this.onHoverCamera(null, 0, 0);
      this.onHoverPMV(null, 0, 0);
    }
    this.setState({ viewport: viewState });
  };

  render() {
    const { controlsReducer, mapPolygonReducer, mapModeReducer } = this.props;
    const { viewport, hoverLine, errorDrawing } = this.state;

    this.drawingLayers = this.initDrawingLayer(mapModeReducer);
    let mapLayers = [];

    if (viewport) {
      mapLayers = makeLayersList(this);
    }
    return (
      <div id="acr-map" className="acr-map">
        {/* <FPS deck={this.deckRef ? this.deckRef.deck : null} /> */}
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v0.44.0/mapbox-gl.css"
          rel="stylesheet"
        />
        <DeckGL
          id="deck-canvas"
          ref={(_) => (this.deckRef = _)}
          viewState={viewport}
          layers={mapLayers}
          ContextProvider={MapContext.Provider}
          initialViewState={this.INITIAL_VIEW_STATE}
          onViewStateChange={this.onViewState}
          onClick={this.onLayerClick}
          views={
            new MapView({
              id: "basemap",
              controller: {
                type: MapController,
                doubleClickZoom: mapModeReducer === ViewMode,
              },
            })
          }
          getCursor={() => {
            return controlsReducer.distanceSelected ||
              controlsReducer.circleSelected ||
              controlsReducer.polygonSelected ||
              controlsReducer.rulerSelected
              ? "crosshair"
              : hoverLine
              ? "pointer"
              : "grab";
          }}
        >
          {this.renderStaticMap()}
        </DeckGL>
        {this.renderMapControls()}
        {this.map ? this.renderTrafficWidget() : null}
        {this.map ? this.renderScale() : null}
        <DrawTooltip
          deleteLayer={this.deleteLayer}
          mapPolygonReducer={mapPolygonReducer}
          controlsReducer={controlsReducer}
          errorDrawing={errorDrawing}
        />
        <DraggableCamerasOnMap />
      </div>
    );
  }

  componentWillUnmount = () => {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };
}

Map.contextType = EnvironmentContext;

const mapDispatchToProps = {
  historyPrev,
  historyNext,
  setPolygon,
  setCurrentEvent,
  setCurrentEventGroup,
  setCurrentPmvGroup,
  setCurrentTrip,
  setCurrentStop,
  setActiveLines,
  setNavTabSelectedTab,
  setSelectedFeatureIndexes,
  resetSelectedFeatureIndexes,
  setGeoJsonObj,
  resetGeoJsonObj,
  closeBottombar,
  resetBottombarTabs,
  setLayout,
  setCurrentStation,
  setCurrentVehicle,
  filterByMapBounds,
  resetTooltipSelected,
  toggleDistanceSelected,
  toggleRulerSelected,
  toggleCircleSelected,
  togglePolygonSelected,
  setMultipleDistance,
  resetMultipleDistance,
  setHoveredBus,
  filterStopMap,
  saveFigure,
  setMode,
  setCurrentArcMap,
  setCurrentCentroidMap,
  setCurrentNodeMap,
  setCurrentPointMap,
  setCurrentSegmentRdsTmcMap,
  setBounds,
  setShortestDistanceOnGraph,
  resetShortestDistanceOnGraph,
  setArcsListEvent,
  toggleOpenNavTab,
  setNavTabMultitab,
  toggleNavGraph,
  toggleNavStation,
  toggleNavNetworkRDSTMC,
  toggleNavTPL,
  toggleNavEvents,
  toggleNavTraffic,
  setCurrentArc,
  setCurrentIdRouteForStop,
  setCurrentIdTripForStop,
  setCurrentIdRouteForVehicle,
  setCurrentIdStopForVehicle,
  setCurrentIdTripForRoute,
  setCurrentIdStopForRoute,
  setCurrentRoutesMapForStopSelected,
  toggleOpenTrafficTimeSlider,
  setTrafficCurrentArc,
  setTrafficCurrentArcMap,
  resetActiveLines,
  //cam
  setCurrentCamera,
  toggleNavWebcams,
  setCamerasList,
  addNewPinnedCamera,
  resetPinnedCameras,
  setAllCamerasVisibility,
  //parking
  setCurrentParking,
  //map
  resetHoveredObjects,
  //pmv
  setCurrentPmv,
  //bottombar
  expandBottombar,
  setBottombarActiveTab,
  addBottombarTab,
  // visibility menu
  setGraphVisibility,
  setNetworkRdsTmcVisibility,
  setTransportVisibility,
  setAllEventVisibility,
  setActiveVisibility,
  setPlannedVisibility,
  setTrafficVisibility,
  setTypicalVisibility,
  setNetStateVisibility,
  setStationVisibility,
  setParkingVisibility,
  setPmvVisibility,
  setAesysPanelVisibility,
  setSolariPanelVisibility,
  setFuturitPanelVisibility,
  setVisuallabPanelVisibility,
  setSpheraPanelVisibility,
  resetTrafficVisibility,
  //workspace
  toggleSaveWorkspace,
};

const mapStateToProps = (state) => ({
  vehiclesEventsReducer: getAllVehiclesEvents(state),
  tripsReducer: getAllTrips(state),
  centroidCurrentReducer: getCurrentCentroid(state),
  nodeCurrentReducer: getCurrentNode(state),

  pointRdsTmcReducer: getCurrentPointRdsTmc(state),
  arcCurrentReducer: getCurrentArc(state),
  arcMapCurrentReducer: getCurrentArcMap(state),
  segmentRdsTmcReducer: getCurrentSegmentRdsTmc(state),
  tripsCurrentReducer: getCurrentTrip(state),
  stopsCurrentReducer: getCurrentStop(state),
  vehicleCurrentReducer: getCurrentVehicle(state),
  mapPolygonReducer: getMapPolygon(state),
  stationCurrentReducer: getCurrentStation(state),
  selectedFeatureIndexes: getMapSelectedFeatureIndexes(state),
  geoJson: getMapGeoJson(state),
  layoutMapReducer: getLayoutMap(state),
  cityBusVisible: isCityBusVisible(state),
  regionBusVisible: isRegionBusVisible(state),
  cityBusRoutes: getCityBusRoutesFiltered(state),
  regionBusRoutes: getRegionBusRoutesFiltered(state),
  cityBusStops: getCityBusStopsFilteredMap(state),
  regionBusStops: getRegionBusStopsFilteredMap(state),
  cityBusLines: getCityBusLinesFiltered(state),
  regionBusLines: getRegionBusLinesFiltered(state),
  cityBusActiveLines: getCityBusActiveLines(state),
  regionBusActiveLines: getRegionBusActiveLines(state),
  cityBusStopsActive: getCityBusStopsActive(state),
  regionBusStopsActive: getRegionBusStopsActive(state),
  activeLines: getAllActiveLines(state),
  allStopsReducer: getAllStops(state),
  //map
  controlsReducer: getControls(state),
  multipleDistance: getMultipleDistance(state),
  shortestDistanceOnGraphReducer: getShortestDistanceOnGraph(state),
  hoveredObjectsReducer: getHoveredObjects(state),
  mapModeReducer: getMode(state),
  mapBoundingBoxReducer: getBoundingBox(state),
  currentMapZoom: getCurrentZoom(state),
  //event ui
  isActiveVisible: isActiveVisible(state),
  isPlannedVisible: isPlannedVisible(state),
  //event
  activeEvents: getActiveEventsFiltered(state),
  plannedEvents: getPlannedEventsFiltered(state),
  currentEvent: getCurrentEvent(state),
  selectModal: getSelectModal(state),
  //installations ui
  isAllCamerasVisible: isAllCamerasVisible(state),
  isParkingVisible: isParkingVisible(state),
  isPmvVisible: isPmvVisible(state),
  isStationVisible: isStationVisible(state),
  isSolariPanelVisible: isSolariPanelVisible(state),
  isAesysPanelVisible: isAesysPanelVisible(state),
  isFuturitPanelVisible: isFuturitPanelVisible(state),
  isVisuallabPanelVisible: isVisuallabPanelVisible(state),
  isSfheraPanelVisible: isSfheraPanelVisible(state),
  //stations
  stations: getFilteredStations(state),
  //road graph
  arcEvents: getArcs(state),
  arcSelectedEvent: getArcsEvent(state),
  centroidEvents: getCentroids(state),
  nodeEvents: getNodes(state),
  // graph ui
  isCentroidVisible: isCentroidVisible(state),
  isNodeVisible: isNodeVisible(state),
  isArcVisible: isArcVisible(state),
  //network tmc-rds
  segmentEvents: getSegmentsRdsTmc(state),
  pointsEvents: getPointsRdsTmc(state),
  //newtwork ui
  isNetworkRdsTmcVisible: isNetworkRdsTmcVisible(state),
  // traffic ui
  isNavTrafficOpen: isOpenNavTraffic(state),
  isTimeSliderVisible: isTimeSliderVisible(state),
  allTrafficChecked: areAllTrafficVisible(state),
  isNetStateVisible: isNetStateVisible(state),
  isTypicalVisible: isTypicalVisible(state),
  // traffic data
  currentContext: getTrafficCurrentContext(state),
  currentDayType: getTrafficCurrentDayType(state),
  currentTime: getTrafficCurrentTime(state),
  currentMeasure: getTrafficCurrentMeasure(state),
  trafficArcCurrentReducer: getTrafficCurrentArc(state),
  trafficArcMapCurrentReducer: getTrafficCurrentArcMap(state),
  trafficFilteredArcs: getTrafficArcs(state),
  currentOffSet: getCurrentTimeOffSet(state),
  trafficCurrentStartTime: getTrafficCurrentStartTime(state),
  //camera
  webcams: getFilteredCameras(state),
  currentSelectedCam: getCurrentCamera(state),
  camerasOnMap: getCamerasOnMap(state),
  //parking
  filteredParkings: getFilteredParkings(state),
  currentParking: getCurrentParking(state),
  //PMV
  filteredPmvs: getFilteredPmvs(state),
  currentPmv: getCurrentPmv(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
  isToggleSavingWorkspace: isToggleSavingWorkspace(state),
  //kpi
  listAllKpi: getListAllKpi(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Map));
