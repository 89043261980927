import React, { Component } from "react";
import "./style.less";
//REDUCER
import {
  isCityBusVisible,
  isRegionBusVisible,
  isOpenNavTab,
  isTypicalVisible,
  isNetStateVisible,
  isAllCamerasVisible,
  isParkingVisible,
  isPmvVisible,
  isArcVisible,
  isNodeVisible,
  isCentroidVisible,
  isNetworkRdsTmcVisible,
  isActiveVisible,
  isPlannedVisible,
  areAllGraphVisible,
  getSelectedMultitab,
  isStationVisible,
  getCurrentEventGroup,
  getCurrentNode,
  getCurrentCentroid,
  getCurrentArc,
  getCurrentPointRdsTmc,
  getCurrentSegmentRdsTmc,
  getCurrentEvent,
  getCurrentStation,
  getTypeModal,
  isAesysPanelVisible,
  isSolariPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
  getCurrentWorkspace,
} from "store";
import {
  toggleOpenNavTab,
  setNavTabMultitab,
  setNavTabSearch,
  setNavTabSelectedTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setTypeModal, resetModalUi } from "reducers/ui/modal/modal.actions";
import { setAllEventVisibility } from "reducers/ui/event-menu/event-menu.actions";
import { setCurrentArc } from "reducers/graph/arcs/arcs.actions";
import { setCurrentNode } from "reducers/graph/nodes/nodes.actions";
import { setCurrentCentroid } from "reducers/graph/centroids/centroids.actions";
import { setCurrentPointRdsTmc } from "reducers/graph/points/points.actions";
import { setCurrentSegmentRdsTmc } from "reducers/graph/segments/segments.actions";
import { resetCurrentStop } from "reducers/stops/stops.actions";
import { resetCurrentTrip } from "reducers/trips/trips.actions";
import { setCurrentStation } from "reducers/installations/stations/stations.actions";
import {
  setCurrentEvent,
  setCurrentEventGroup,
} from "reducers/events/events.actions";
import {
  setCurrentIdStopForRoute,
  setCurrentIdTripForRoute,
} from "reducers/routes/routes.actions";
import { resetActiveLines } from "reducers/lines/lines.actions";
import {
  toggleNavGraph,
  toggleNavNetworkRDSTMC,
  toggleNavTPL,
  toggleNavEvents,
  toggleNavTraffic,
  toggleNavStation,
  toggleNavWebcams,
  toggleNavPmv,
  toggleNavParking,
} from "reducers/ui/nav/nav.actions";
import { resetNewEvent } from "reducers/modal/modal.actions";
import { setGraphVisibility } from "reducers/ui/graph-menu/graph-menu.actions";
import { setTrafficCurrentArc } from "reducers/traffic/traffic.actions";
import {
  setCurrentCamera,
  resetPinnedCameras,
} from "reducers/installations/cameras/cameras.actions";
import { resetCurrentParking } from "reducers/installations/parking/parking.actions";
import {
  resetCurrentPmv,
  setCurrentPmvGroup,
} from "reducers/installations/pmv/pmv.actions";

//UTILS
import { debounce } from "utils/utils";
import { MULTITAB_SELECTED_TAB } from "utils/util-multitab-icons";
import * as FilterUtils from "utils/filter-utils";

const mapStateToProps = (state) => ({
  //traffic
  tipicChecked: isTypicalVisible(state),
  netStateChecked: isNetStateVisible(state),
  //tpl
  cityBusChecked: isCityBusVisible(state),
  regionBusChecked: isRegionBusVisible(state),
  isOpenNavTab: isOpenNavTab(state),
  //eventi
  activeChecked: isActiveVisible(state),
  plannedChecked: isPlannedVisible(state),
  currentEventGroup: getCurrentEventGroup(state),
  //Installation
  cameraChecked: isAllCamerasVisible(state),
  parkingChecked: isParkingVisible(state),
  pmvChecked: isPmvVisible(state),
  stazioniDiMisuraChecked: isStationVisible(state),
  //pmv
  isSolariPanelVisible: isSolariPanelVisible(state),
  isAesysPanelVisible: isAesysPanelVisible(state),
  isFuturitPanelVisible: isFuturitPanelVisible(state),
  isVisuallabPanelVisible: isVisuallabPanelVisible(state),
  isSfheraPanelVisible: isSfheraPanelVisible(state),

  //Network: graph and rete rds tmc
  arcChecked: isArcVisible(state),
  nodechecked: isNodeVisible(state),
  centroidChecked: isCentroidVisible(state),
  networkRdsTmcChecked: isNetworkRdsTmcVisible(state),
  typeModal: getTypeModal(state),
  currentEvent: getCurrentEvent(state),
  //Graph
  allGraphChecked: areAllGraphVisible(state),
  currentNode: getCurrentNode(state),
  currentCentroid: getCurrentCentroid(state),
  currentArc: getCurrentArc(state),
  currentPointRdsTmc: getCurrentPointRdsTmc(state),
  currentSegmentRdsTmc: getCurrentSegmentRdsTmc(state),
  currentStation: getCurrentStation(state),
  selectedMultitab: getSelectedMultitab(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
});

const mapDispatchToProps = {
  toggleOpenNavTab,
  setTypeModal,
  resetNewEvent,
  resetModalUi,
  setAllEventVisibility,
  toggleNavEvents,
  toggleNavTraffic,
  setGraphVisibility,
  toggleNavTPL,
  toggleNavGraph,
  toggleNavNetworkRDSTMC,
  setNavTabMultitab,
  toggleNavStation,
  toggleNavWebcams,
  setNavTabSearch,
  setCurrentNode,
  setCurrentCentroid,
  setCurrentArc,
  setCurrentPointRdsTmc,
  setCurrentSegmentRdsTmc,
  setCurrentEvent,
  setCurrentEventGroup,
  setCurrentIdStopForRoute,
  setCurrentIdTripForRoute,
  resetActiveLines,
  resetCurrentStop,
  resetCurrentTrip,
  setCurrentStation,
  setTrafficCurrentArc,
  setCurrentCamera,
  resetCurrentParking,
  resetPinnedCameras,
  setCurrentPmvGroup,
  resetCurrentPmv,
  toggleNavPmv,
  toggleNavParking,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      body: "",
      selectedTabs: MULTITAB_SELECTED_TAB,
      //TODO
      buttonFilterActivation: false,
    };

    // if this prop changes in time move to componentDidUpdate
    this.onSearchDebounced = debounce(this.props.onSearch, 250);
  }

  componentDidMount() {
    const {
      cityBusChecked,
      regionBusChecked,
      activeChecked,
      plannedChecked,
      arcChecked,
      nodechecked,
      centroidChecked,
      networkRdsTmcChecked,
      stazioniDiMisuraChecked,
      netStateChecked,
      tipicChecked,
      cameraChecked,
      parkingChecked,
      selectedMultitab,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible
    } = this.props;
    const { selectedTabs } = this.state;

    //NB COMPONENTS IS INSTANTIATED EVERY TIME PAGE IS CHANGED, SO WE JUST NEED TO CHECK ON MOUNT

    let newSelectedTabs = FilterUtils.deepCopyOfArraysOfObj(selectedTabs);
    newSelectedTabs[0].selected = centroidChecked || arcChecked || nodechecked;
    newSelectedTabs[1].selected = networkRdsTmcChecked;
    newSelectedTabs[2].selected = regionBusChecked || cityBusChecked;
    newSelectedTabs[3].selected = netStateChecked || tipicChecked;
    newSelectedTabs[4].selected = activeChecked || plannedChecked;
    newSelectedTabs[5].selected = stazioniDiMisuraChecked;
    newSelectedTabs[6].selected =
      isSolariPanelVisible ||
      isAesysPanelVisible ||
      isFuturitPanelVisible ||
      isVisuallabPanelVisible || 
      isSfheraPanelVisible;
    newSelectedTabs[7].selected = cameraChecked;
    newSelectedTabs[8].selected = parkingChecked;

    newSelectedTabs.forEach((item, index, arr) => {
      if (item.name === selectedMultitab) {
        arr[index] = {
          ...item,
          primary: true,
        };
      } else {
        arr[index] = {
          ...item,
          primary: false,
        };
      }
    });
    this.setState({
      ...this.state,
      selectedTabs: [...newSelectedTabs],
    });
  }

  componentDidUpdate = (prevProps) => {
    const { currentTab, selectedMultitab } = this.props;
    const { selectedTabs } = this.state;

    //EMPTY SEARCH WHEN CHANGE TAB
    if (currentTab && prevProps.currentTab !== currentTab) {
      this.setState({
        searchValue: "",
      });
    }

    if (prevProps.selectedMultitab !== selectedMultitab) {
      let newSelectedTabs = FilterUtils.deepCopyOfArraysOfObj(selectedTabs);
      newSelectedTabs.forEach((item, index, arr) => {
        if (item.name === selectedMultitab) {
          arr[index] = {
            ...item,
            primary: true,
          };
        } else {
          arr[index] = {
            ...item,
            primary: false,
          };
        }
      });
      this.setState({
        ...this.state,
        selectedTabs: [...newSelectedTabs],
      });
    }
  };

  handleChange = (event) => {
    if (event.target) {
      const { value } = event.target;

      this.setState({ searchValue: value });
      this.onSearchDebounced(value);
    }
  };

  handleFilterRemove = () => {
    const {
      selectedMultitab,
      nodechecked,
      arcChecked,
      centroidChecked,
      cityBusChecked,
      regionBusChecked,
      activeChecked,
      plannedChecked,
      networkRdsTmcChecked,
      currentEventGroup,
      setCurrentArc,
      setCurrentNode,
      setCurrentEvent,
      setCurrentCentroid,
      setCurrentEventGroup,
      setCurrentPointRdsTmc,
      setCurrentSegmentRdsTmc,
      resetActiveLines,
      resetCurrentStop,
      resetCurrentTrip,
      stazioniDiMisuraChecked,
      setCurrentStation,
      tipicChecked,
      netStateChecked,
      setTrafficCurrentArc,
      setCurrentCamera,
      cameraChecked,
      parkingChecked,
      resetCurrentParking,
      resetCurrentPmv,
      setCurrentPmvGroup,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
    } = this.props;

    let search = { target: { value: "" } };
    this.handleChange(search);

    if (
      (nodechecked || arcChecked || centroidChecked) &&
      selectedMultitab === "Grafo Stradale"
    ) {
      setCurrentNode({ node: null });
      setCurrentCentroid({ centroid: null });
      setCurrentArc({ arc: null });
    } else if (networkRdsTmcChecked && selectedMultitab === "Rete RDS-TMC") {
      setCurrentPointRdsTmc({ point: null });
      setCurrentSegmentRdsTmc({ segment: null });
    } else if (
      (cityBusChecked || regionBusChecked) &&
      selectedMultitab === "Trasporto Pubblico"
    ) {
      resetActiveLines();
      resetCurrentStop();
      resetCurrentTrip();
    } else if (
      (activeChecked || plannedChecked) &&
      selectedMultitab === "Eventi"
    ) {
      setCurrentEvent(null);
      if (currentEventGroup.length > 0) {
        setCurrentEventGroup([]);
      }
    } else if (
      stazioniDiMisuraChecked &&
      selectedMultitab === "Stazioni di Misura"
    ) {
      setCurrentStation(null);
    } else if (
      (tipicChecked || netStateChecked) &&
      selectedMultitab === "Traffico"
    ) {
      setTrafficCurrentArc({ arc: null });
    } else if (cameraChecked && selectedMultitab === "Telecamere") {
      setCurrentCamera(null);
    } else if (parkingChecked && selectedMultitab === "Parcheggi") {
      resetCurrentParking();
    } else if (
      (isSolariPanelVisible ||
        isAesysPanelVisible ||
        isFuturitPanelVisible ||
        isVisuallabPanelVisible ||
        isSfheraPanelVisible) &&
      selectedMultitab === "Pannelli"
    ) {
      resetCurrentPmv();
      setCurrentPmvGroup([]);
    }
  };

  handleChange = (event) => {
    if (event.target) {
      const { value } = event.target;
      this.setState({ searchValue: value });
      this.onSearchDebounced(value);
    }
  };

  handleOpenNavTab = (tabName) => {
    const {
      history,
      location,
      toggleNavEvents,
      toggleOpenNavTab,
      setNavTabMultitab,
      selectedMultitab,
      toggleNavStation,
      toggleNavWebcams,
      toggleNavGraph,
      toggleNavTPL,
      toggleNavNetworkRDSTMC,
      toggleNavTraffic,
      toggleNavPmv,
      arcChecked,
      nodeChecked,
      centroidChecked,
      isOpenNavTab,
    } = this.props;

    setNavTabMultitab(tabName);
    if (tabName !== selectedMultitab || !isOpenNavTab) {
      toggleOpenNavTab(true);
    } else {
      toggleOpenNavTab(false);
    }

    if (tabName === "Grafo Stradale") {
      if (!location.pathname.includes("graph")) {
        history.push("/network/graph");
        toggleNavGraph(true);
      }
      if (arcChecked && !nodeChecked && !centroidChecked) {
        setNavTabSelectedTab(0);
      } else if (!arcChecked || nodeChecked || !centroidChecked) {
        setNavTabSelectedTab(1);
      } else if (!arcChecked || !nodeChecked || centroidChecked) {
        setNavTabSelectedTab(2);
      }
    }
    if (tabName === "Rete RDS-TMC") {
      if (!location.pathname.includes("rete-rds-tmc")) {
        history.push("/network/rete-rds-tmc");
        toggleNavNetworkRDSTMC(true);
      }
    }
    if (tabName === "Trasporto Pubblico") {
      if (!location.pathname.includes("tpl")) {
        history.push("/viability/tpl");
        toggleNavTPL(true);
      }
    }

    if (tabName === "Traffico") {
      if (!location.pathname.includes("traffic")) {
        toggleNavTraffic(true);
        history.push("/viability/traffic");
      }
    }

    if (tabName === "Eventi") {
      if (!location.pathname.includes("events")) {
        toggleNavEvents(true);
        history.push("/viability/events");
      }
    }

    if (tabName === "Stazioni di Misura") {
      if (!location.pathname.includes("stations")) {
        toggleNavStation(true);
        history.push("/installations/stations");
      }
    }
    if (tabName === "Pannelli") {
      if (!location.pathname.includes("pmv")) {
        toggleNavPmv(true);
        history.push("/installations/pmv");
      }
    }
    if (tabName === "Telecamere") {
      if (!location.pathname.includes("cctv")) {
        toggleNavWebcams(true);
        history.push("/installations/cctv");
      }
    }
    if (tabName === "Parcheggi") {
      if (!location.pathname.includes("parking")) {
        toggleNavParking(true);
        history.push("/installations/parking");
      }
    }
  };

  handleChangeChkNavTab = () => {
    const { isOpenNavTab, toggleOpenNavTab } = this.props;
    toggleOpenNavTab(!isOpenNavTab);
  };

  searchBar = () => {
    const { searchValue } = this.state;
    const { selectedMultitab, isOpenNavTab } = this.props;

    return (
      <div
        className={`uk-flex uk-flex-middle uk-flex-center uk-form-controls search ${
          isOpenNavTab && selectedMultitab !== "" ? "search-visible" : ""
        } `}
      >
        <div
          uk-icon="icon: acr-interface-search"
          className="uk-flex uk-flex-middle uk-flex-center search-icon"
        ></div>
        <div
          uk-icon="icon: acr-filter-remove"
          className={` "uk-flex uk-flex-middle uk-flex-center filter-icon uk-icon cursor-pointer uk-link" ${
            this.state.buttonFilterActivation ? "button-filter-activation" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            this.handleFilterRemove(e);
          }}
          uk-tooltip="Reset filtri e selezione"
        ></div>
        <div className="search-bar">
          <input
            className="uk-input uk-form-large padding-search"
            type="text"
            placeholder="Cerca..."
            onChange={this.handleChange}
            value={searchValue}
          ></input>
          {searchValue.length > 0 && (
            <div
              uk-icon="icon: acr-interface-close-big"
              className="uk-flex uk-flex-middle uk-flex-center search-close-icon"
              onClick={(e) => {
                e.preventDefault();
                this.handleChange({ target: { value: "" } });
              }}
            >
              {" "}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      cityBusChecked,
      regionBusChecked,
      activeChecked,
      plannedChecked,
      cameraChecked,
      pmvChecked,
      arcChecked,
      nodechecked,
      centroidChecked,
      networkRdsTmcChecked,
      items,
      isOpenNavTab,
      selectedMultitab,
      stazioniDiMisuraChecked,
      netStateChecked,
      tipicChecked,
      parkingChecked,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      currentWorkspace,
    } = this.props;
    const { selectedTabs } = this.state;

    let tabsToShow = selectedTabs.filter((item) => item.selected === true);
    let parsedObject, graphMenu, tplMenu, installationsMenu, wsTabsToShow;
    if (
      currentWorkspace &&
      currentWorkspace.payload &&
      currentWorkspace.payload.value
    ) {
      parsedObject = JSON.parse(currentWorkspace.payload.value);

      graphMenu = Object.keys(parsedObject.menu.graph)
        .filter((k) => parsedObject.menu.graph[k])
        .filter((item) => {
          return item !== "arc" && item !== "node" && item !== "centroid";
        }).length;

      tplMenu = Object.keys(parsedObject.menu.tpl)
        .filter((k) => parsedObject.menu.tpl[k])
        .filter((item) => {
          return (
            item !== "activeEvents" &&
            item !== "plannedEvents" &&
            item !== "city" &&
            item !== "regional" &&
            item !== "stateTraffic"
          );
        }).length;

      installationsMenu = Object.keys(parsedObject.menu.installations)
        .filter((k) => parsedObject.menu.installations[k])
        .filter((item) => {
          return (
            item !== "mobilitaCameras" &&
            item !== "solariPmv" &&
            item !== "aesysPmv" &&
            item !== "futuritPmv" &&
            item !== "visualLabPmv" &&
            item !== "spheraPmv"
          );
        }).length;

      wsTabsToShow = graphMenu + tplMenu + installationsMenu;
    }

    return (
      <div className="uk-nav-tab-container sideBar margin-from-topbar ">
        <div
          className={`navtab-container ${
            tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
              ? "navtab-container-padding"
              : "navtab-container-padding-2"
          }`}
        >
          {!cityBusChecked &&
          !regionBusChecked &&
          !activeChecked &&
          !plannedChecked &&
          !cameraChecked &&
          !parkingChecked &&
          !pmvChecked &&
          !arcChecked &&
          !nodechecked &&
          !centroidChecked &&
          !networkRdsTmcChecked &&
          !stazioniDiMisuraChecked &&
          !netStateChecked &&
          !tipicChecked &&
          !isSolariPanelVisible &&
          !isAesysPanelVisible &&
          !isFuturitPanelVisible &&
          !isVisuallabPanelVisible && 
          !isSfheraPanelVisible ? (
            <div className="overlay-icon uk-nav-tab-toggle-multitab  no-border-click uk-disabled "></div>
          ) : (
            ""
          )}
          {centroidChecked || arcChecked || nodechecked ? (
            <div
              onClick={(e) => {
                this.handleOpenNavTab(selectedTabs[0].name);
              }}
              className="cursor-pointer events-div-multitab"
            >
              <div
                className={` uk-nav-tab-toggle-multitab no-border-click  resize-icon overlay-icon ${
                  selectedTabs[0].primary === true
                    ? "selected-bookmark"
                    : "primary-svg-color"
                } ${
                  tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
                    ? "bookmark-height"
                    : ""
                }`}
              ></div>
              <div
                className="multitab-icon image-to-change"
                uk-icon={"icon: " + selectedTabs[0].icon}
              ></div>
            </div>
          ) : (
            ""
          )}
          {networkRdsTmcChecked ? (
            <div
              onClick={(e) => {
                this.handleOpenNavTab(selectedTabs[1].name);
              }}
              className="cursor-pointer events-div-multitab"
            >
              <div
                className={` uk-nav-tab-toggle-multitab no-border-click  resize-icon overlay-icon ${
                  selectedTabs[1].primary === true
                    ? "selected-bookmark"
                    : "primary-svg-color"
                } ${
                  tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
                    ? "bookmark-height"
                    : ""
                }`}
              ></div>
              <div
                className="multitab-icon image-to-change"
                uk-icon={"icon: " + selectedTabs[1].icon}
              ></div>
            </div>
          ) : (
            ""
          )}
          {cityBusChecked || regionBusChecked ? (
            <div
              onClick={(e) => {
                this.handleOpenNavTab(selectedTabs[2].name);
              }}
              className="cursor-pointer events-div-multitab"
            >
              <div
                className={` uk-nav-tab-toggle-multitab no-border-click  resize-icon overlay-icon ${
                  selectedTabs[2].primary === true
                    ? "selected-bookmark"
                    : "primary-svg-color"
                } ${
                  tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
                    ? "bookmark-height"
                    : ""
                }`}
              ></div>
              <div
                className="multitab-icon image-to-change"
                uk-icon={"icon: " + selectedTabs[2].icon}
              ></div>
            </div>
          ) : (
            ""
          )}

          {netStateChecked || tipicChecked ? (
            <div
              onClick={(e) => {
                this.handleOpenNavTab(selectedTabs[3].name);
              }}
              className="cursor-pointer events-div-multitab"
            >
              <div
                className={` uk-nav-tab-toggle-multitab no-border-click  resize-icon overlay-icon ${
                  selectedTabs[3].primary === true
                    ? "selected-bookmark"
                    : "primary-svg-color"
                } ${
                  tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
                    ? "bookmark-height"
                    : ""
                }`}
              ></div>
              <div
                className="multitab-icon image-to-change"
                uk-icon={"icon: " + selectedTabs[3].icon}
              ></div>
            </div>
          ) : (
            ""
          )}
          {activeChecked || plannedChecked ? (
            <div
              onClick={(e) => {
                this.handleOpenNavTab(selectedTabs[4].name);
              }}
              className="cursor-pointer events-div-multitab"
            >
              <div
                className={` uk-nav-tab-toggle-multitab no-border-click  resize-icon overlay-icon ${
                  selectedTabs[4].primary === true
                    ? "selected-bookmark"
                    : "primary-svg-color"
                } ${
                  tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
                    ? "bookmark-height"
                    : ""
                }`}
              ></div>
              <div
                className="multitab-icon image-to-change"
                uk-icon={"icon: " + selectedTabs[4].icon}
              ></div>
            </div>
          ) : (
            ""
          )}

          {stazioniDiMisuraChecked ? (
            <div
              onClick={(e) => {
                this.handleOpenNavTab(selectedTabs[5].name);
              }}
              className="cursor-pointer events-div-multitab"
            >
              <div
                className={` uk-nav-tab-toggle-multitab no-border-click  resize-icon overlay-icon ${
                  selectedTabs[5].primary === true
                    ? "selected-bookmark"
                    : "primary-svg-color"
                } ${
                  tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
                    ? "bookmark-height"
                    : ""
                }`}
              ></div>
              <div
                className="multitab-icon image-to-change"
                uk-icon={"icon: " + selectedTabs[5].icon}
              ></div>
            </div>
          ) : (
            ""
          )}
          {isSolariPanelVisible ||
          isAesysPanelVisible ||
          isFuturitPanelVisible ||
          isVisuallabPanelVisible ||
          isSfheraPanelVisible ? (
            <div
              onClick={(e) => {
                this.handleOpenNavTab(selectedTabs[6].name);
              }}
              className="cursor-pointer events-div-multitab"
            >
              <div
                className={` uk-nav-tab-toggle-multitab no-border-click  resize-icon overlay-icon ${
                  selectedTabs[6].primary === true
                    ? "selected-bookmark"
                    : "primary-svg-color"
                } ${
                  tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
                    ? "bookmark-height"
                    : ""
                }`}
              ></div>
              <div
                className="multitab-icon image-to-change"
                uk-icon={"icon: " + selectedTabs[6].icon}
              ></div>
            </div>
          ) : (
            ""
          )}

          {cameraChecked ? (
            <div
              onClick={(e) => {
                this.handleOpenNavTab(selectedTabs[7].name);
              }}
              className="cursor-pointer events-div-multitab"
            >
              <div
                className={` uk-nav-tab-toggle-multitab no-border-click  resize-icon overlay-icon ${
                  selectedTabs[7].primary === true
                    ? "selected-bookmark"
                    : "primary-svg-color"
                } ${
                  tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
                    ? "bookmark-height"
                    : ""
                }`}
              ></div>
              <div
                className="multitab-icon image-to-change"
                uk-icon={"icon: " + selectedTabs[7].icon}
              ></div>
            </div>
          ) : (
            ""
          )}

          {parkingChecked ? (
            <div
              onClick={(e) => {
                this.handleOpenNavTab(selectedTabs[8].name);
              }}
              className="cursor-pointer events-div-multitab"
            >
              <div
                className={` uk-nav-tab-toggle-multitab no-border-click  resize-icon overlay-icon ${
                  selectedTabs[8].primary === true
                    ? "selected-bookmark"
                    : "primary-svg-color"
                } ${
                  tabsToShow.length >= 7 || (wsTabsToShow && wsTabsToShow >= 7)
                    ? "bookmark-height"
                    : ""
                }`}
              ></div>
              <div
                className="multitab-icon image-to-change"
                uk-icon={"icon: " + selectedTabs[8].icon}
              ></div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div
          className={`uk-nav-tab container-nav-tab ${
            isOpenNavTab ? "open-nav-tab-details" : "cursor-pointer"
          }`}
          uk-nav=""
        >
          {(cityBusChecked ||
            regionBusChecked ||
            tipicChecked ||
            netStateChecked ||
            activeChecked ||
            plannedChecked ||
            cameraChecked ||
            parkingChecked ||
            pmvChecked ||
            arcChecked ||
            nodechecked ||
            centroidChecked ||
            networkRdsTmcChecked ||
            stazioniDiMisuraChecked ||
            isSolariPanelVisible ||
            isAesysPanelVisible ||
            isFuturitPanelVisible ||
            isVisuallabPanelVisible ||
            isSfheraPanelVisible) &&
            this.searchBar()}
          {selectedMultitab && selectedMultitab !== "" && isOpenNavTab ? (
            <div className="drawer-header">{items}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(NavTab);
