import React, { Component } from "react";
import "../style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toggleEventReportDetail } from "reducers/ui/event-menu/event-menu.actions";
import {
  getSelectModal,
  getTypeModal,
  getCurrentEvent,
  getIsReportDetailOpen,
} from "store";
import EnvironmentContext from "environment-context";
import { getAttributes } from "utils/util-attributes-mapping";
import { getIconEvent } from "utils/utils_event_icon";
import createApiService from "services/api.service";
import { getTypeTranslation } from "utils/util-events";

class EventReportDetail extends Component {
  apiService;
  subscriptions = [];
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.apiService = createApiService(this.context);
  };

  toggleExpandMessages = () => {
    const { isReportEventOpen, toggleEventReportDetail } = this.props;
    toggleEventReportDetail(!isReportEventOpen);
  };

  renderData() {
    const { currentEvent, listAttributes } = this.props;
    let eventAttributes = getAttributes(currentEvent);

    return (
      <div className="uk-flex uk-flex-column uk-flex-1">
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-event-detail-table">NOTE</div>
          <div className="text-detail-emphasis-event-detail">
            {currentEvent &&
            currentEvent.generalPublicComment &&
            currentEvent.generalPublicComment.length > 0 &&
            currentEvent.generalPublicComment[0].comment
              ? currentEvent.generalPublicComment[0].comment.content
              : "-"}
          </div>
        </li>
        {eventAttributes
          ? Object.keys(eventAttributes).map((attribute) => (
              <li className="uk-flex uk-flex-row" key={attribute}>
                <div className=" text-detail-muted-event-detail-table">
                  {listAttributes.find((o) => o.attributeName === attribute) &&
                    listAttributes.find((o) => o.attributeName === attribute)
                      .attributeNameIT}
                  :
                </div>
                <div className="text-detail-emphasis-event-detail">
                  {typeof eventAttributes[attribute] === "boolean"
                    ? eventAttributes[attribute] === true
                      ? "SI"
                      : eventAttributes[attribute] === false
                      ? "NO"
                      : "-"
                    : typeof eventAttributes[attribute] === "number"
                    ? eventAttributes[attribute]
                    : listAttributes.find((o) => o.attributeName === attribute)
                    ? listAttributes.find((o) => o.attributeName === attribute)
                        .attributeValues &&
                      listAttributes
                        .find((o) => o.attributeName === attribute)
                        .attributeValues.find(
                          (x) => x.name === eventAttributes[attribute]
                        )
                      ? listAttributes
                          .find((o) => o.attributeName === attribute)
                          .attributeValues.find(
                            (x) => x.name === eventAttributes[attribute]
                          ).nameIT
                      : !!eventAttributes[attribute] || "-"
                    : "-"}
                  {/* {eventAttributes[attribute]
                    ? eventAttributes[attribute]
                    : "-"} */}
                </div>
              </li>
            ))
          : "-"}
      </div>
    );
  }

  renderHeading = () => {
    const { currentEvent, selectModal } = this.props;
    let current = currentEvent ? currentEvent : {};
    let iconSrc = current ? getIconEvent(current, selectModal) : "";
    let iconAlt = currentEvent && currentEvent.situationRecordType;
    return (
      <div className="detail-event-wrapper">
        Dettaglio Evento:{" "}
        <img className="detail-event-icon" alt={iconAlt} src={iconSrc} />{" "}
        {current && getTypeTranslation(currentEvent, selectModal)} (
        {currentEvent.situationRecordId})
      </div>
    );
  };

  render = () => {
    const { isReportEventOpen } = this.props;

    return (
      <div className="pmv-message-container uk-flex-right uk-flex uk-flex-column uk-width-1-1 z-index-1">
        <div className="position-relative uk-flex-right uk-flex uk-flex-column uk-width-1-1">
          <ul className="uk-tab-page-default uk-tab uk-flex-between uk-margin-remove-bottom">
            <li></li>
            <li
              className="pointer uk-active"
              onClick={this.toggleExpandMessages}
            >
              {!isReportEventOpen ? (
                <button
                  className="pointer button-no-focus"
                  uk-icon="icon: acr-interface-arrow-up"
                ></button>
              ) : (
                <button
                  className="pointer button-no-focus"
                  uk-icon="icon: acr-interface-arrow-down"
                ></button>
              )}
            </li>
            <li></li>
          </ul>
          <div className="bg-dark uk-flex uk-flex-column uk-width-1-1 uk-height-1-1 uk-padding-small">
            <div className="bg-dark uk-margin-small-left uk-margin-small-right uk-margin-small-bottom uk-margin-small-top uk-padding-remove border-bottom">
              {this.renderHeading()}
            </div>
            <div
              className={
                isReportEventOpen
                  ? "event-detail-wrapper max-height-320 bg-dark uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top custom-bottom-padding"
                  : "displayNone"
              }
            >
              {this.renderData()}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

EventReportDetail.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleEventReportDetail,
};

const mapStateToProps = (state) => ({
  isReportEventOpen: getIsReportDetailOpen(state),
  currentEvent: getCurrentEvent(state),
  typeModal: getTypeModal(state),
  selectModal: getSelectModal(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventReportDetail)
);
