import React, { Component } from "react";
import "./style.less";

class Loading extends Component {
  render() {
    return (
      <div className="spinner-container uk-margin-top uk-margin-bottom">
        <div uk-spinner="ratio: 2" className="uk-spinner-center"></div>
      </div>
    );
  }
}

export default Loading;
