import {
  toNameShort,
  lowerCaseAllWordsExceptFirstLetters,
  upperCaseFirstLetter,
  replaceComma,
} from "utils/utils";

export const INITIAL_STATE_LINES = {
  lines: [],
  current: null,
  activeLines: [],
  filtered: [],
};

export default class LineModel {
  id;
  nameLong;
  nameShort;
  transportMode;
  transportOperatorRef;
  color;
  type;
  routes;
  networkId;
  hasIconPNG;
  hasIconSVG;
  versionRef;
  constructor() {
    this.id = null;
    this.nameLong = null;
    this.nameShort = null;
    this.transportMode = ITransportMode;
    this.transportOperatorRef = ITransportOperatorRef;
    this.color = null;
    this.type = null;
    this.routes = IRoute;
    this.networkId = null;
    this.hasIconPNG = null;
    this.hasIconSVG = null;
    this.versionRef = IVersionRef;
  }
  static fromREST(obj) {
    var trip = new LineModel();
    trip.id = obj.id;
    trip.nameLong = upperCaseFirstLetter(
      lowerCaseAllWordsExceptFirstLetters(replaceComma(obj.nameLong))
    );
    trip.nameShort = upperCaseFirstLetter(
      lowerCaseAllWordsExceptFirstLetters(
        toNameShort(replaceComma(obj.nameShort))
      )
    );
    trip.transportMode = obj.transportMode;
    trip.transportOperatorRef = obj.transportOperatorRef;
    trip.color = obj.color;
    trip.type = obj.type;
    trip.routes = obj.routes;
    trip.networkId = obj.networkId;
    trip.hasIconPNG = obj.hasIconPNG;
    trip.hasIconSVG = obj.hasIconSVG;
    trip.versionRef = obj.versionRef;
    return trip;
  }
  static fromArrayREST(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(LineModel.fromREST).filter((item) => item !== null);
  }
}

const ITransportMode = {
  code: null,
  label: null,
  description: null,
  isManaged: null,
  subMode: null,
};

const ITransportOperatorRef = {
  id: null,
  nameShort: null,
};

const IRoute = {
  id: null,
  nameShort: null,
};

const IVersionRef = {
  fromDate: null,
  toDate: null,
  versionId: null,
};
