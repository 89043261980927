import { combineReducers } from "redux";
import * as arcsReducer from "./arcs/arcs.reducer";
import * as centroidsReducer from "./centroids/centroids.reducer";
import * as nodesReducer from "./nodes/nodes.reducer";
import * as pointsReducer from "./points/points.reducer";
import * as segmentsReducer from "./segments/segments.reducer";

export default combineReducers({
  arcs: arcsReducer.default,
  centroids: centroidsReducer.default,
  nodes: nodesReducer.default,
  segments: segmentsReducer.default,
  points: pointsReducer.default,
});

export const getArcs = (state) => arcsReducer.getArcs(state.arcs);
export const getArcsEvent = (state) => arcsReducer.getArcsEvent(state.arcs);

export const getCurrentArc = (state) => arcsReducer.getCurrentArc(state.arcs);
export const getCurrentArcMap = (state) =>
  arcsReducer.getCurrentArcMap(state.arcs);
export const getFilteredArc = (state) => arcsReducer.getFilteredArc(state.arcs);

export const getNodes = (state) => nodesReducer.getNodes(state.nodes);
export const getCurrentNode = (state) =>
  nodesReducer.getCurrentNode(state.nodes);
export const getCurrentNodeMap = (state) =>
  nodesReducer.getCurrentNodeMap(state.nodes);
export const getFilteredNodes = (state) =>
  nodesReducer.getFilteredNodes(state.nodes);

export const getCentroids = (state) =>
  centroidsReducer.getCentroids(state.centroids);
export const getCurrentCentroid = (state) =>
  centroidsReducer.getCurrentCentroid(state.centroids);
export const getCurrentCentroidMap = (state) =>
  centroidsReducer.getCurrentCentroidMap(state.centroids);
export const getFilteredCentroids = (state) =>
  centroidsReducer.getFilteredCentroids(state.centroids);

export const getPointsRdsTmc = (state) =>
  pointsReducer.getPointsRdsTmc(state.points);
export const getCurrentPointRdsTmc = (state) =>
  pointsReducer.getCurrentPointRdsTmc(state.points);
export const getCurrentPointRdsTmcMap = (state) =>
  pointsReducer.getCurrentPointRdsTmcMap(state.points);
export const getFilteredPoints = (state) =>
  pointsReducer.getFilteredPoints(state.points);

export const getSegmentsRdsTmc = (state) =>
  segmentsReducer.getSegmentsRdsTmc(state.segments);
export const getCurrentSegmentRdsTmc = (state) =>
  segmentsReducer.getCurrentSegmentRdsTmc(state.segments);
export const getCurrentSegmentRdsTmcMap = (state) =>
  segmentsReducer.getCurrentSegmentRdsTmcMap(state.segments);
export const getFilteredSegments = (state) =>
  segmentsReducer.getFilteredSegments(state.segments);
