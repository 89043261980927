import { CompositeLayer, PathLayer } from "deck.gl";

class ArcLayerEvent extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      visible,
      color,
      lighterColor,
      onClick,
      isDrawingMode,
    } = this.props;

    return new PathLayer(
      this.getSubLayerProps({
        id,
        data,
        getPath: (d) =>
          d && d.geometry && d.geometry.coordinates
            ? d.geometry.coordinates
            : [],
        getColor: (data) =>
          data && data.properties.selected ? lighterColor : color,
        pickable: isDrawingMode ? false : true,
        widthMinPixels: 2,
        getWidth: (data) => (data && data.properties.selected ? 10 : 4),
        onClick: onClick,
        visible,
        rounded: true,
        parameters: {
          depthTest: false,
        },
      })
    );
  }
}

ArcLayerEvent.layerName = "ArcLayerEvent";

export default ArcLayerEvent;
