import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//COMPONENTS
import EnvironmentContext from "environment-context";
import Permission from "components/shared/permission";
import WebcamDetailHeader from "components/layout/webcams/webcam-detail-header";

//SERVICES
import createApiService from "services/api.service";

// STYLE
import "./style.less";
//STORE AND ACTIONS
import { getCurrentCamera } from "store";
import { setCurrentCamera } from "reducers/installations/cameras/cameras.actions";

//UTIL
import { SENSOR_STATUS } from "utils/util-cameras";
import { checkAndStartStream, stop, CAMERA_WRAPPER_PAGE_ENUM } from "utils/utils-streaming";
import { WebCamWrapper } from "components/shared/webcam-wrapper/webcam-wrapper";

class WebcamsDetailComponent extends Component {

  apiService;

  constructor(props) {
    super(props);
    this.state = {
      videoObj: null,
      env: null
    };
  }

  componentDidMount = () => {
    this.apiService = createApiService(this.context);
    const { currentSelectedCam } = this.props;

    fetch("/config.json")
      .then((res) => res.json())
      .then((environment) => {
        this.setState({
          ...this.state,
          env: environment,
        });
        checkAndStartStream(this, environment, currentSelectedCam, this.apiService, CAMERA_WRAPPER_PAGE_ENUM.DETAIL);
      });
  };

  refreshStream = () => {
    const { currentSelectedCam } = this.props;
    const { env, videoObj } = this.state;

    if(env.streamingHlsEnabled && videoObj && videoObj.streamId) {
      stop(this, env.streamingHlsEnabled, this.apiService, env.streamingHlsService);
    }
    checkAndStartStream(this, env, currentSelectedCam, this.apiService, CAMERA_WRAPPER_PAGE_ENUM.DETAIL);
  };

  componentDidUpdate = (prevProps) => {
    const { currentSelectedCam } = this.props;

    if (
      prevProps.currentSelectedCam &&
      currentSelectedCam &&
      prevProps.currentSelectedCam.id !== currentSelectedCam.id
    ) {
      this.refreshStream();
    }
  };

  header = () => {
    const {
      currentSelectedCam,
      pinnedCameras,
      toggleDetails,
      pinCamera
    } = this.props;

    const { env } = this.state;
    
    return (
      <WebcamDetailHeader
        currentSelectedCam={currentSelectedCam}
        pinnedCameras={pinnedCameras}
        toggleDetails={toggleDetails}
        pinCamera={pinCamera}
        maxCamerasPinnable={env && env.maxNrOfStreamingCameras}
      />
    );
  };

  tabs = () => {
    const { handleClickSecondTab } = this.props;
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
          <li
            className="pointer"
            onClick={(event) => {
              event.preventDefault();
              handleClickSecondTab();
            }}
          >
            <a href="/#">Allarmi</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const { currentSelectedCam } = this.props;
    const { env } = this.state;
    let statusIcon = SENSOR_STATUS.find(
      (status) =>
        currentSelectedCam &&
        currentSelectedCam.properties &&
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
          .last_state &&
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.last_state
          .diagnosticState &&
        typeof currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
          .last_state.diagnosticState === "string" &&
        status.name ===
        currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
          .last_state.diagnosticState
    );

    return (
      <div className="uk-flex uk-flex-column contentBodyStationDetail">
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">ID</div>
          <div className="text-detail-emphasis-station-detail">
            {currentSelectedCam &&
              currentSelectedCam.properties &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv.id
              ? currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
                .cctv.id
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Nome</div>
          <div className="text-detail-emphasis-station-detail">
            {currentSelectedCam &&
              currentSelectedCam.properties &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
                .name
              ? currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
                .cctv.name
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Posizione</div>
          <div className="text-detail-emphasis-station-detail">
            {currentSelectedCam &&
              currentSelectedCam.properties &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
                .latitude &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
                .longitude
              ? Math.round(
                currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
                  .cctv.longitude * 1000000
              ) /
              1000000 +
              ", " +
              Math.round(
                currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
                  .cctv.latitude * 1000000
              ) /
              1000000
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Descrizione</div>
          <div className="text-detail-emphasis-station-detail">
            {currentSelectedCam &&
              currentSelectedCam.properties &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
                .description
              ? currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
                .cctv.description
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Stato</div>
          <div className="text-detail-emphasis-station-detail">
            {currentSelectedCam && (
              <div
                className={
                  statusIcon
                    ? statusIcon.value === 3
                      ? "sensore-guasto"
                      : statusIcon.value === 4
                        ? "sensore-no-info"
                        : statusIcon.value === 2
                          ? "sensore-disconnesso"
                          : "sensore-funzionante"
                    : "sensore-funzionante"
                }
              >
                {statusIcon ? statusIcon.name : "-"}
              </div>
            )}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-station-detail">Tipo</div>
          <div className="text-detail-emphasis-station-detail">
            {currentSelectedCam &&
              currentSelectedCam.properties &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
              currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
                .type
              ? currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms
                .cctv.type
              : "-"}
          </div>
        </li>
        <Permission actions="CCTV-STREAMING">
          <li
            id="video-container"
            className="uk-flex uk-flex-column uk-flex-center uk-margin-top"
          >
            <div className="uk-flex uk-flex-row uk-flex-right uk-padding-small">
              <span
                className="pointer uk-margin-right"
                uk-icon="icon: refresh"
                uk-tooltip="Ricarica Stream"
                onClick={() => this.refreshStream()}
              ></span>
            </div>
            {
              env ? <WebCamWrapper
              videoURI={`${this.state.videoObj && this.state.videoObj.url || ''}`}
              cam={ currentSelectedCam }
              origin={CAMERA_WRAPPER_PAGE_ENUM.DETAIL}
              hlsEnabled={env && env.streamingHlsEnabled}
              /> : <></>
            }
          </li>
        </Permission>
      </div>
    );
  };

  render() {
    const { currentSelectedCam } = this.props;
    return (
      <div>
        {this.header()}
        {currentSelectedCam &&
          currentSelectedCam.properties &&
          currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
          currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms &&
          currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv &&
          currentSelectedCam.properties.cctvWithLastStateAndOpenAlarms.cctv
            .deviceId &&
          this.tabs()}
        {this.body()}
      </div>
    );
  }

  componentWillUnmount() {
    const { env } = this.state;

    stop(this, env.streamingHlsEnabled, this.apiService, env.streamingHlsService, true);
  }
}

WebcamsDetailComponent.contextType = EnvironmentContext;

const mapStateToProps = (state) => ({
  currentSelectedCam: getCurrentCamera(state),
});

const mapDispatchToProps = {
  setCurrentCamera
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WebcamsDetailComponent)
);
