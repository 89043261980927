import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { tap, take, catchError } from "rxjs/operators";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { of } from "rxjs";
import * as moment from "moment";

//COMPONENT
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import BottomControlsIcons from "components/shared/bottom-controls/bottom-controls-icons";
import ParkingsDetailComponent from "components/layout/parkings/parkings-detail/parkings-detail";
import ParkingsDetailDynamicData from "components/layout/parkings/parkings-dynamic-data/parkings-dynamic-data";
import ParkingsListComponent from "components/layout/parkings/parkings-list/parkings-list";
import { ParkingRecordStatusModel } from "reducers/installations/parking/parking.model";
//
import {
  setCurrentParking,
  updateCurrentParking,
  resetParkingStatesWS,
} from "reducers/installations/parking/parking.actions";
import {
  getFilteredParkings,
  getCurrentParking,
  getWsLastParking,
} from "store";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";

const mapStateToProps = (state) => ({
  //PARKING
  filteredParkings: getFilteredParkings(state),
  currentParking: getCurrentParking(state),
  wsLastParking: getWsLastParking(state),
});

const mapDispatchToProps = {
  setCurrentParking,
  toggleGenericTable,
  updateCurrentParking,
  resetParkingStatesWS,
};

class ParkingsContainer extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {
      onParkingDetailTab: false,
      dataToPlot: [],
    };
  }

  componentDidMount = () => {
    const { currentParking } = this.props;

    this.apiService = createApiService(this.context);
    if (currentParking) {
      this.subscriptions$.push(
        this.apiService
          .getParkingRecordStatusById(
            currentParking && currentParking.id,
            moment().startOf("day").toISOString(),
            moment().endOf("day").toISOString()
          )
          .pipe(
            take(1),
            tap((data) => {
              let newData = ParkingRecordStatusModel.fromArray(data);
              let newDataSorted = newData.sort(function (a, b) {
                return a.latestParkingUpdate < b.latestParkingUpdate
                  ? -1
                  : a.latestParkingUpdate > b.latestParkingUpdate
                  ? 1
                  : 0;
              });
              let plottedData = newDataSorted.map((item) => {
                return {
                  timeStamp: item.latestParkingUpdate
                    ? moment(item.latestParkingUpdate).format("HH:mm")
                    : null,
                  occupazione: item.parkingOccupancy,
                  valueColor: "hsl(134, 70%, 50%)",
                };
              });
              this.setState({ dataToPlot: plottedData });
            }),
            catchError((error) => {
              this.setState({ dataToPlot: [] });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      currentParking,
      updateCurrentParking,
      wsLastParking,
      resetParkingStatesWS,
    } = this.props;

    if (
      (!prevProps.currentParking && currentParking) ||
      (prevProps.currentParking &&
        currentParking &&
        prevProps.currentParking.id !== currentParking.id) ||
      (wsLastParking &&
        wsLastParking.length > 0 &&
        wsLastParking[0].parkingRecordId &&
        currentParking &&
        Number(wsLastParking[0].parkingRecordId) === Number(currentParking.id))
    ) {
      this.setState({ dataToPlot: [] });
      this.subscriptions$.push(
        this.apiService
          .getParkingRecordStatusById(
            currentParking && currentParking.id,
            moment().startOf("day").toISOString(),
            moment().endOf("day").toISOString()
          )
          .pipe(
            take(1),
            tap((data) => {
              let newData = ParkingRecordStatusModel.fromArray(data);
              let newDataSorted = newData.sort(function (a, b) {
                return a.latestParkingUpdate < b.latestParkingUpdate
                  ? -1
                  : a.latestParkingUpdate > b.latestParkingUpdate
                  ? 1
                  : 0;
              });
              newDataSorted = newDataSorted.filter(function (
                value,
                index,
                Arr
              ) {
                if (newDataSorted.length > 2000) {
                  return index % 300 === 0 || index === Arr.length - 1;
                } else if (newDataSorted.length > 1500) {
                  return index % 150 === 0 || index === Arr.length - 1;
                } else if (newDataSorted.length > 900) {
                  return index % 100 === 0 || index === Arr.length - 1;
                } else if (newDataSorted.length > 500) {
                  return index % 50 === 0 || index === Arr.length - 1;
                } else if (newDataSorted.length > 200) {
                  return index % 25 === 0 || index === Arr.length - 1;
                } else if (newDataSorted.length > 100) {
                  return index % 15 === 0 || index === Arr.length - 1;
                } else if (newDataSorted.length > 50) {
                  return index % 9 === 0 || index === Arr.length - 1;
                } else if (newDataSorted.length > 40) {
                  return index % 5 === 0 || index === Arr.length - 1;
                } else if (newDataSorted.length > 30) {
                  return index % 3 === 0 || index === Arr.length - 1;
                } else if (newDataSorted.length > 15) {
                  return index % 2 === 0 || index === Arr.length - 1;
                } else return true;
              });
              let plottedData = newDataSorted.map((item) => {
                return {
                  timeStamp: item.latestParkingUpdate
                    ? item.latestParkingUpdate
                    : null,
                  occupazione: item.parkingOccupancy,
                  valueColor: "hsl(134, 70%, 50%)",
                };
              });
              this.setState({ dataToPlot: plottedData });
              if (newDataSorted && newDataSorted[newDataSorted.length - 1]) {
                let newCurrent = {
                  ...currentParking,
                  properties: {
                    ...currentParking.properties,
                    ...newDataSorted[newDataSorted.length - 1],
                  },
                };
                updateCurrentParking(newCurrent);
              }
            }),
            catchError((error) => {
              this.setState({ dataToPlot: [] });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
      resetParkingStatesWS();
    }
  };

  toggleDetails = (selectedDevice) => {
    const { setCurrentParking } = this.props;

    if (selectedDevice) {
      setCurrentParking(selectedDevice);
      this.setState({ dataToPlot: [] });
    } else {
      setCurrentParking(null);
      this.setState({ dataToPlot: [] });
    }
  };

  handleClickSecondTab = () => {
    this.setState({ onParkingDetailTab: !this.state.onParkingDetailTab });
  };

  render() {
    const { currentParking, filteredParkings, toggleGenericTable } = this.props;
    const { onParkingDetailTab, dataToPlot } = this.state;
    const controlsList = [
      {
        icon: "acr-interface-list",
        tooltip: "Resoconto Parcheggi",
        className: "table-icon",
        toggle: (e) => {
          toggleGenericTable("parkingTable");
        },
      },
    ];
    return (
      <TabPanel className="overflow inherit-transition uk-height-1-1">
        <ErrorBoundary>
          {currentParking && !onParkingDetailTab ? (
            <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
              <div className="uk-height-1-1 overflow event-list-container">
                <ParkingsDetailComponent
                  handleClickSecondTab={this.handleClickSecondTab}
                ></ParkingsDetailComponent>
              </div>
            </div>
          ) : currentParking && onParkingDetailTab ? (
            <ParkingsDetailDynamicData
              handleClickSecondTab={this.handleClickSecondTab}
              dataToPlot={dataToPlot}
            ></ParkingsDetailDynamicData>
          ) : (
            <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
              <div className="uk-height-1-1 overflow event-list-container">
                <ParkingsListComponent
                  toggleDetails={this.toggleDetails}
                  filteredParkings={filteredParkings}
                ></ParkingsListComponent>
              </div>
              <BottomControlsIcons controls={controlsList} />
            </div>
          )}
        </ErrorBoundary>
      </TabPanel>
    );
  }
}
ParkingsContainer.contextType = EnvironmentContext;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParkingsContainer)
);
