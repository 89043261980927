import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";

//ACTIONS
import { setNavTabSearch } from "reducers/ui/nav-tab/nav-tab.actions";
import {
  getNavTabSelectedTab,
  getBoundingBox,
  isOpenNavWebcams,
  getFilteredCameras,
  isAllCamerasVisible,
  getPinnedCameras,
  getBottombarTabs,
  isCctvLoading,
  getCurrentWorkspace,
  getCurrentZoom,
} from "store";
import {
  closeBottombar,
  expandBottombar,
  setBottombarActiveTab,
  addBottombarTab,
  removeBottombarTab,
} from "reducers/ui/bottombar/bottombar.actions";
import {
  setCurrentCamera,
  addNewPinnedCamera,
  removePinnedCamera,
} from "reducers/installations/cameras/cameras.actions";
import { setBounds, filterByMapBounds } from "reducers/map/map.actions";
import { wsOpen } from "reducers/ui/ws/ws.actions";
//COMPONENT
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import WebCamsContainer from "components/layout/webcams";
import NavTab from "components/layout/nav-tab";
import Loading from "components/shared/loading/loading";

//UTIL
import { filterBounds } from "components/layout/map/load-map/load-map";

class CamerasPage extends Component {
  subscriptions = [];
  bufferTabs = [];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      defaultMeasure: null,
      openDetail: false,
      currentArc: null,
    };
  }

  componentDidMount = () => {};

  toggleDetails = (selectedDevice) => {
    const { setCurrentCamera } = this.props;
    setCurrentCamera(selectedDevice);
  };

  pinCamera = (pinnedCamera) => {
    const {
      pinnedCameras,
      addNewPinnedCamera,
      removePinnedCamera,
      addBottombarTab,
      setBottombarActiveTab,
      removeBottombarTab,
      closeBottombar,
      expandBottombar,
      bottombarTabs,
    } = this.props;

    if (
      pinnedCameras.find(
        (item) =>
          item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
          pinnedCamera.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
      )
    ) {
      removePinnedCamera(pinnedCamera);
      if (pinnedCameras.length === 1) {
        let newTab =
          bottombarTabs.filter((item) => item !== "Telecamere").length > 0
            ? bottombarTabs[0]
            : null;
        setBottombarActiveTab(newTab);
        if (!newTab) {
          closeBottombar();
        }
        removeBottombarTab("Telecamere");
      }
    } else {
      addNewPinnedCamera(pinnedCamera);
      if (pinnedCameras.length === 0) {
        addBottombarTab("Telecamere");
        setBottombarActiveTab("Telecamere");
        expandBottombar();
      }
    }
  };

  handleSearch = (value) => {
    const { setNavTabSearch } = this.props;

    setNavTabSearch(value, "cameras", null, null);
    if (value === "") {
      filterBounds(this);
    }
  };

  /* inject installation items to right sidebar */
  injectItems = () => {
    const { webcams, cameraChecked, isCctvLoading } = this.props;

    return cameraChecked && isCctvLoading ? (
      <ErrorBoundary>
        <TabPanel className="tabsContainerTpl inherit-transition">
          <Loading />
        </TabPanel>
      </ErrorBoundary>
    ) : cameraChecked && !isCctvLoading ? (
      <ErrorBoundary>
        <TabPanel className="tabsContainerTpl inherit-transition">
          <ul className="uk-tab uk-flex-between">
            <li className={"uk-active"}>
              <a href="/#">
                {"Telecamere (" + (webcams && webcams.length) + ")"}
              </a>
            </li>
          </ul>
          <WebCamsContainer
            pinCamera={this.pinCamera}
            toggleDetails={this.toggleDetails}
          ></WebCamsContainer>
        </TabPanel>
      </ErrorBoundary>
    ) : null;
  };

  render() {
    return (
      <NavTab
        isOpen={true}
        items={this.injectItems()}
        onSearch={this.handleSearch}
      />
    );
  }

  componentWillUnmount = () => {
    const { setNavTabSearch, location, menuTabReducer } = this.props;

    this.bufferTabs = [];
    setNavTabSearch("", null, location && location.pathname, menuTabReducer);
  };
}

CamerasPage.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSearch,
  setBounds,
  filterByMapBounds,
  wsOpen,
  removeBottombarTab,
  setBottombarActiveTab,
  expandBottombar,
  closeBottombar,
  addBottombarTab,
  setCurrentCamera,
  addNewPinnedCamera,
  removePinnedCamera,
};

const mapStateToProps = (state) => ({
  webcams: getFilteredCameras(state),
  menuTabReducer: getNavTabSelectedTab(state),
  boundsReducer: getBoundingBox(state),
  isOpenNavWebcams: isOpenNavWebcams(state),
  cameraChecked: isAllCamerasVisible(state),
  pinnedCameras: getPinnedCameras(state),
  bottombarTabs: getBottombarTabs(state),
  isCctvLoading: isCctvLoading(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
  currentMapZoom: getCurrentZoom(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CamerasPage)
);
