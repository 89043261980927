import { NodesActions } from "./nodes.actions";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";

const INITIAL_STATE = {
  nodes: [],
  nodesFiltered: [],
  current: null,
  currentMap: null,
  searchText: "",
  searchType: "",
  bounds: [],
};

function searchNodes(searchText) {
  return (node) =>
    node && node.id && node.id.toString().toLowerCase().includes(searchText);
}

export default function nodesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NodesActions.SET_LIST_NODES:
      let nodesFiltered = action.payload.list;
      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "nodi"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchNodes(searchText);
        nodesFiltered = action.payload.list.filter(searchFn);
      }
      return {
        ...state,
        nodes: action.payload.list,
        nodesFiltered: nodesFiltered,
      };
    case NodesActions.SET_CURRENT_NODE:
      let newarrNode = [];
      if (state.nodes) {
        newarrNode = [...state.nodes].map((item) => ({
          ...item,
          selected:
            item &&
            action.payload &&
            action.payload.node &&
            action.payload.node.id &&
            item.id === action.payload.node.id
              ? state.currentMap &&
                state.currentMap.id === action.payload.node.id
                ? item.selected
                : !item.selected
              : false,
        }));
      }
      let newCurr;
      if (action.payload && action.payload.node) {
        newCurr = {
          ...action.payload.node,
          selected: !action.payload.node.selected,
        };
      } else {
        newCurr = action.payload.node;
      }

      return {
        ...state,
        current:
          state.current &&
          state.current.id &&
          action.payload &&
          action.payload.node &&
          action.payload.node.id &&
          state.current.id &&
          state.current.id === action.payload.node.id
            ? null
            : newCurr,
        nodes: newarrNode,
        currentMap: null,
      };
    case NodesActions.RESET_CURRENT_NODE_MAP:
      return {
        ...state,
        nodes: [],
        nodesFiltered: [],
        current: null,
        currentMap: null,
      };
    case NodesActions.SET_CURRENT_NODE_MAP:
      let newarrNodeMap = [...state.nodes].map((item) => ({
        ...item,
        selected:
          item &&
          action.payload &&
          action.payload.node &&
          action.payload.node.id &&
          item.id === action.payload.node.id
            ? !item.selected
            : false,
      }));
      let newCurrMap = {
        ...action.payload.node,
        selected: !action.payload.node.selected,
      };

      return {
        ...state,
        currentMap:
          state.currentMap &&
          state.currentMap.id &&
          action.payload &&
          action.payload.node &&
          action.payload.node.id &&
          state.currentMap.id === action.payload.node.id
            ? null
            : state.current && state.current.id
            ? null
            : newCurrMap,
        nodes: newarrNodeMap,
        current:
          state.current &&
          state.current.id &&
          action.payload &&
          action.payload.node &&
          action.payload.node.id
            ? state.current.id === action.payload.node.id
              ? null
              : action.payload.node
            : action.payload.node,
      };

    case NavTabActions.SET_SEARCH: {
      if (action.payload && action.payload.type === "nodi") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const searchFn = searchNodes(searchText);
        let nodesFiltered = state.nodes.filter(searchFn);
        const searchType = action.payload.type;
        return {
          ...state,
          nodesFiltered,
          searchText,
          searchType,
        };
      } else {
        return { ...state };
      }
    }
    default:
      return state;
  }
}

export const getNodes = (state) => state.nodes;
export const getCurrentNode = (state) => state.current;
export const getCurrentNodeMap = (state) => state.currentMap;
export const getFilteredNodes = (state) => state.nodesFiltered;
