//IMG
import webcam_img_red from "assets/icon/installations/webcams-states/camera_red_circle.svg";
import webcam_img_green from "assets/icon/installations/webcams-states/camera_green_circle.svg";
import webcam_img_white from "assets/icon/installations/webcams-states/camera_white_circle.svg";
import webcam_img_yellow from "assets/icon/installations/webcams-states/camera_yellow_circle.svg";
import webcam_img_red_filled from "assets/icon/installations/webcams-states/camera_red_filled.svg";
import webcam_img_green_filled from "assets/icon/installations/webcams-states/camera_green_filled.svg";
import webcam_img_white_filled from "assets/icon/installations/webcams-states/camera_white_filled.svg";
import webcam_img_yellow_filled from "assets/icon/installations/webcams-states/camera_yellow_filled.svg";

export const SENSOR_STATUS = [
  {
    name: "FUNZIONANTE",
    value: 1,
    icon: webcam_img_green,
    mapIcon: webcam_img_green_filled,
  },
  {
    name: "AVARIA",
    value: 2,
    icon: webcam_img_yellow,
    mapIcon: webcam_img_yellow_filled,
  },
  {
    name: "DISCONNESSO",
    value: 3,
    icon: webcam_img_red,
    mapIcon: webcam_img_red_filled,
  },
  {
    name: "NO_INFO",
    value: 4,
    icon: webcam_img_white,
    mapIcon: webcam_img_white_filled,
  },
];

export const ALARM_PRIORITY = [
  {
    name: "HIGH",
    value: 1,
    color: "priority-red",
  },
  {
    name: "MEDIUM",
    value: 2,
    color: "priority-orange",
  },
  {
    name: "LOW",
    value: 3,
    color: "priority-yellow",
  },
];

export const ALARM_STATUS = [
  {
    name: "NEW",
    value: 1,
    nameIT: "Nuovo",
  },
  {
    name: "IN_PROGRESS",
    value: 2,
    nameIT: "In corso",
  },
  {
    name: "ON_HOLD",
    value: 3,
    nameIT: "In attesa",
  },
  {
    name: "CLOSED",
    value: 4,
    nameIT: "Chiuso",
  },
];

export function getFiltersForCameraTable(stateTableColumns, sortConfig) {
  let filterObject = {
    diagnosticState: [],
    type: [],
    group: [],
    orderBy: [],
    orderType: [],
  };

  const filterStrings = ["diagnosticState", "type", "group"];

  filterStrings.forEach((item) => {
    return (filterObject[item] = [
      ...stateTableColumns[item].childrenForHeaderDropdown.reduce(
        (acc, { checked, id }) => {
          if (checked && !id.startsWith("all")) {
            return acc.concat(id);
          } else {
            return acc;
          }
        },
        []
      ),
    ]);
  });

  filterObject["orderBy"] =
    sortConfig.key && mapSortConfig(sortConfig.key)
      ? [mapSortConfig(sortConfig.key)]
      : [];
  filterObject["orderType"] =
    sortConfig.direction === "ascending"
      ? "ASC"
      : sortConfig.direction === "descending"
      ? "DESC"
      : null;

  return filterObject;
}

export const mapSortConfig = (key) => {
  switch (key) {
    case "cameraId":
      return "ID";
    case "cameraName":
      return "NAME";
    // case 'cameraDescription':
    //   return 'CAMERA_DESCRIPTION';
    // case 'activeAlarms':
    //   return 'ACTIVE_ALARMS';
    case "diagnosticState":
      return "DIAGNOSTIC_STATE";
    case "group":
      return "GROUP";
    case "type":
      return "TYPE";
    default:
      return null;
  }
};
