import React from "react";

// DECK
import { EditableGeoJsonLayer } from "nebula.gl";
import { StaticMap } from "react-map-gl";

// CONTROLS AND CUSTOM LAYERS
import MapControls from "components/layout/map/map-controls";
import Scale from "components/layout/map/scale";
import RealTimeLayer from "components/layout/map/layers/real-time-layer";
import BusLineLayer from "components/layout/map/layers/bus-line-layer";
import BusStopLayer from "components/layout/map/layers/bus-stop-layer";
import EventsLayer from "components/layout/map/layers/events-layer";
import InstallationLayer from "components/layout/map/layers/installation-layer";
import DrawPointsLayer from "components/layout/map/layers/draw-points-layer";
import ArcLayer from "components/layout/map/layers/arc-layer";
import TrafficArcLayer from "components/layout/map/layers/traffic-arc-layer";
import ArcLayerEvent from "components/layout/map/layers/arc-layer-event";
import StationsLayer from "components/layout/map/layers/stations-layer";
import WebcamsLayer from "components/layout/map/layers/webcams-layer";
import ShortestDistanceArcLayer from "components/layout/map/layers/shortest-distance-arc-layer";
import CentroidsLayer from "components/layout/map/layers/centroids-layer";
import NodesLayer from "components/layout/map/layers/nodes-layer";
import TmcSectionLayer from "components/layout/map/layers/tmc-segment-layer";
import TmcPointsLayer from "components/layout/map/layers/tmc-points-layer";
import ParkingLayer from "components/layout/map/layers/parking-layer";
import PmvLayer from "components/layout/map/layers/pmv-layer";
//import { graf } from "utils/el_str";
import MarkerPointsDistanceLayer from "../layers/marker-points-distance-layer";
import MarkerPointsShortestDistanceLayer from "../layers/marker-points-shortest-distance-layer";
import { _onMapLoad } from "../load-map/load-map";

import TrafficWidget from "components/shared/traffic-widget/traffic-widget";

// UTILS
import * as HooksMapDraw from "utils/map-utils/hook-map-drawing";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";
import { getFillColor, getLineColor } from "utils/map-utils/hook-map-drawing";

export function initDrawingLayer(mapComponent, layerProps) {
  if (layerProps) {
    const { geoJson, selectedFeatureIndexes } = mapComponent.props;

    return new EditableGeoJsonLayer({
      ...layerProps,
      data: geoJson,
      selectedFeatureIndexes,
      onEdit: mapComponent.onEdit,
      lineWidthMinPixels: 2,
      pointRadiusMinPixels: 5,
      getLineDashArray: () => [0, 0],
      // Accessors receive an isSelected argument
      getFillColor: getFillColor,
      getLineColor: getLineColor,
    });
  } else {
    return null;
  }
}

export function renderMapControls(mapComponent) {
  const { layoutMapReducer } = mapComponent.props;
  const { viewport } = mapComponent.state;
  return (
    <MapControls
      deleteLayer={mapComponent.deleteLayer}
      drawPolygon={mapComponent.drawPolygon}
      drawCircle={mapComponent.drawCircle}
      drawRuler={mapComponent.drawRuler}
      drawDistance={mapComponent.drawDistance}
      zoomOut={mapComponent.zoomOut}
      zoomIn={mapComponent.zoomIn}
      toNord={mapComponent.toNord}
      toDefaultPosition={mapComponent.toDefaultPosition}
      setPan={mapComponent.setPan}
      goForward={mapComponent.goForward}
      goBackward={mapComponent.goBackward}
      layout={layoutMapReducer}
      handleSwitchLayer={mapComponent.handleSwitchLayer}
      infoSelectRoute={mapComponent.infoSelectRoute}
      viewport={viewport}
    />
  );
}

export function renderScale(mapComponent) {
  var meters = HooksMapDraw.metersPerPx(mapComponent.map);
  return (
    <Scale
      meters={meters}
      center={mapComponent.map.getCenter()}
      scale={mapComponent.map.transform.scale}
    />
  );
}

export function renderMultipleDistanceLayer(mapComponent) {
  const {
    geoJson,
    multipleDistance,
    _onHoverMultipleDistance,
  } = mapComponent.props;

  return new MarkerPointsDistanceLayer({
    id: "points-multiple-distance",
    multipleDistance: multipleDistance,
    geoJson: geoJson,
    onHover: ({ object, x, y }) => _onHoverMultipleDistance(object, x, y),
  });
}

export function renderShortestDistanceOnGraph(mapComponent) {
  const {
    geoJson,
    shortestDistanceOnGraphReducer,
    _onHoverShortestDistanceOnGraph,
  } = mapComponent.props;

  return new MarkerPointsShortestDistanceLayer({
    id: "points-shortest-distance",
    data: shortestDistanceOnGraphReducer,
    geoJson: geoJson,
    onHover: ({ object, x, y }) =>
      _onHoverShortestDistanceOnGraph(object, x, y),
  });
}

export function renderRealTimeLayer(mapComponent) {
  const {
    vehiclesEventsReducer,
    layoutMapReducer,
    cityBusVisible,
    regionBusVisible,
  } = mapComponent.props;
  const { viewport } = mapComponent.state;
  const { mapModeReducer } = mapComponent.props;
  let arrayVehicles = [];
  if (cityBusVisible && !regionBusVisible) {
    arrayVehicles = [...vehiclesEventsReducer].filter(
      (item) =>
        item &&
        item.transportMode &&
        item.transportMode.subMode &&
        item.transportMode.subMode.code &&
        item.transportMode.subMode.code === TYPE_TRANSPORT_MODE["urb"]
    );
  }
  if (regionBusVisible && !cityBusVisible) {
    arrayVehicles = [...vehiclesEventsReducer].filter(
      (item) =>
        item &&
        item.transportMode &&
        item.transportMode.subMode &&
        item.transportMode.subMode.code &&
        item.transportMode.subMode.code === TYPE_TRANSPORT_MODE["extraurb"]
    );
  }
  if (regionBusVisible && cityBusVisible) {
    arrayVehicles = [...vehiclesEventsReducer].filter(
      (item) =>
        item &&
        item.transportMode &&
        item.transportMode.subMode &&
        item.transportMode.subMode.code &&
        (item.transportMode.subMode.code === TYPE_TRANSPORT_MODE["extraurb"] ||
          item.transportMode.subMode.code === TYPE_TRANSPORT_MODE["urb"])
    );
  }
  return new RealTimeLayer({
    id: "real-time-layer",
    vehiclesEventsReducer: arrayVehicles,
    viewport: viewport,
    layoutMap: layoutMapReducer,
    onHover: ({ object, x, y }) => mapComponent._onHoverBus(object, x, y),
    onClick: ({ object, x, y }) => mapComponent.onClickBusTpl(object, x, y),
    isDrawingMode: mapModeReducer,
  });
}

export function renderArcLayer(
  mapComponent,
  id,
  data,
  color,
  lighterColor,
  visible
) {
  const {
    mapModeReducer,
    arcCurrentReducer,
    arcMapCurrentReducer,
  } = mapComponent.props;
  return new ArcLayer({
    id,
    data,
    color,
    lighterColor,
    visible,
    onClick: (obj) => mapComponent.onClickArc(obj),
    onHover: ({ object, x, y }) => mapComponent.onHoverArc(object, x, y),
    isDrawingMode: mapModeReducer,
    arcCurrentReducer: arcCurrentReducer,
    arcMapCurrentReducer: arcMapCurrentReducer,
  });
}

export function renderTrafficArcLayer(mapComponent, id, data, visible) {
  const {
    mapModeReducer,
    trafficArcCurrentReducer,
    trafficArcMapCurrentReducer,
    isNetStateVisible,
    isTypicalVisible,
  } = mapComponent.props;

  return new TrafficArcLayer({
    id,
    data,
    visible,
    onClick: (obj) => mapComponent.onClickTrafficArc(obj),
    onHover: ({ object, x, y }) => mapComponent.onHoverTrafficArc(object, x, y),
    isDrawingMode: mapModeReducer,
    trafficArcCurrentReducer: trafficArcCurrentReducer,
    trafficArcMapCurrentReducer: trafficArcMapCurrentReducer,
    isNetStateVisible,
    isTypicalVisible,
  });
}

export function renderArcLayerEvent(
  mapComponent,
  id,
  data,
  color,
  lighterColor,
  visible
) {
  const { mapModeReducer } = mapComponent.props;
  return new ArcLayerEvent({
    id,
    data,
    color,
    lighterColor,
    visible,
    onClick: (obj) => mapComponent.onClickArc(obj),
    isDrawingMode: mapModeReducer,
  });
}

export function renderShortestDistanceArcLayer(
  mapComponent,
  id,
  data,
  color,
  visible
) {
  const { mapModeReducer } = mapComponent.props;

  return new ShortestDistanceArcLayer({
    id,
    data,
    color,
    visible,
    onClick: () => null,
    isDrawingMode: mapModeReducer,
  });
}

export function renderCentroidsLayer(mapComponent) {
  const {
    layoutMapReducer,
    mapModeReducer,
    centroidEvents,
    isCentroidVisible,
    centroidCurrentReducer,
  } = mapComponent.props;

  return new CentroidsLayer({
    id: "centroids-layer",
    data: centroidEvents,
    layout: layoutMapReducer,
    visible: isCentroidVisible,
    onClick: (obj) => mapComponent.onClickCentroid(obj),
    onHover: ({ object, x, y }) => mapComponent.onHoverCentroid(object, x, y),
    isDrawingMode: mapModeReducer,
    centroidCurrentReducer: centroidCurrentReducer,
  });
}
export function renderNodeLayer(mapComponent) {
  const {
    layoutMapReducer,
    mapModeReducer,
    nodeEvents,
    isNodeVisible,
    nodeCurrentReducer,
  } = mapComponent.props;

  return new NodesLayer({
    id: "nodes-layer",
    data: nodeEvents,
    layout: layoutMapReducer,
    visible: isNodeVisible,
    onClick: (obj) => mapComponent.onClickNode(obj),
    onHover: ({ object, x, y }) => mapComponent.onHoverNode(object, x, y),
    isDrawingMode: mapModeReducer,
    nodeCurrentReducer: nodeCurrentReducer,
  });
}
export function renderStationsLayer(mapComponent) {
  const {
    layoutMapReducer,
    mapModeReducer,
    stations,
    isStationVisible,
    stationCurrentReducer,
  } = mapComponent.props;
  const { viewport } = mapComponent.state;

  return new StationsLayer({
    id: "stations-layer",
    data: stations,
    layout: layoutMapReducer,
    visible: isStationVisible,
    onClick: (obj) => mapComponent.onClickStation(obj),
    onHover: ({ object, x, y }) => mapComponent.onHoverStation(object, x, y),
    isDrawingMode: mapModeReducer,
    stationCurrentReducer: stationCurrentReducer,
    viewport: viewport,
  });
}

export function renderWebcamsLayer(mapComponent) {
  const {
    layoutMapReducer,
    mapModeReducer,
    webcams,
    isAllCamerasVisible,
    camCurrentReducer,
  } = mapComponent.props;
  const { viewport } = mapComponent.state;

  return new WebcamsLayer({
    id: "webcams-layer",
    data: webcams,
    layout: layoutMapReducer,
    visible: isAllCamerasVisible,
    onClick: (obj) => mapComponent.onClickWebcam(obj),
    onHover: ({ object, x, y }) => mapComponent.onHoverCamera(object, x, y),
    isDrawingMode: mapModeReducer,
    camCurrentReducer: camCurrentReducer,
    viewport: viewport,
  });
}

export function renderPmvsLayer(mapComponent) {
  const {
    layoutMapReducer,
    mapModeReducer,
    filteredPmvs,
    isPmvVisible,
    pmvCurrentReducer,
    isAesysPanelVisible,
    isSolariPanelVisible,
    isFuturitPanelVisible,
    isVisuallabPanelVisible,
    isSfheraPanelVisible,
  } = mapComponent.props;
  const { viewport } = mapComponent.state;

  return new PmvLayer({
    id: "pmv-layer",
    data: filteredPmvs,
    layout: layoutMapReducer,
    visible:
      isPmvVisible ||
      isAesysPanelVisible ||
      isSolariPanelVisible ||
      isFuturitPanelVisible ||
      isVisuallabPanelVisible ||
      isSfheraPanelVisible,
    onClick: (obj) => mapComponent.onClickPMV(obj),
    onHover: ({ object, x, y }) => mapComponent.onHoverPMV(object, x, y),
    isDrawingMode: mapModeReducer,
    pmvCurrentReducer: pmvCurrentReducer,
    viewport: viewport,
  });
}

export function renderSegmentsTmcLayer(mapComponent, id, data, color, visible) {
  const { mapModeReducer } = mapComponent.props;

  return new TmcSectionLayer({
    id,
    data,
    color,
    visible,
    onClick: (obj) => mapComponent.onClickSegments(obj),
    isDrawingMode: mapModeReducer,
  });
}

export function renderPointsTmcLayer(mapComponent) {
  const {
    layoutMapReducer,
    mapModeReducer,
    pointsEvents,
    isNetworkRdsTmcVisible,
    pointRdsTmcReducer,
  } = mapComponent.props;

  return new TmcPointsLayer({
    id: "points-tmc-layer",
    data: pointsEvents,
    layout: layoutMapReducer,
    visible: isNetworkRdsTmcVisible,
    onClick: (obj) => mapComponent.onClickPoint(obj),
    onHover: ({ object, x, y }) =>
      mapComponent.onHoverRdsTmcPoint(object, x, y),
    isDrawingMode: mapModeReducer,
    pointRdsTmcReducer: pointRdsTmcReducer,
  });
}

export function renderEventsLayer(mapComponent, id, data, visible) {
  const { layoutMapReducer, mapModeReducer, selectModal } = mapComponent.props;
  const { viewport } = mapComponent.state;
  return new EventsLayer({
    id,
    data,
    visible,
    layoutMap: layoutMapReducer,
    onHover: ({ object, x, y }) => mapComponent.onHoverEvent(object, x, y),
    onClick: (obj) => mapComponent.onClickEvent(obj),
    isDrawingMode: mapModeReducer,
    viewport: viewport,
    selectModal: selectModal,
  });
}

export function renderInstallationsLayer(mapComponent, id, data, visible) {
  const {
    onClickInstallation,
    layoutMapReducer,
    mapModeReducer,
  } = mapComponent.props;
  return new InstallationLayer({
    id,
    data,
    visible,
    layoutMap: layoutMapReducer,
    onHover: ({ object, x, y }) => {
      mapComponent.onHoverInstallation(object, x, y);
    },
    onClick: (obj) => onClickInstallation(obj),
    isDrawingMode: mapModeReducer,
  });
}

export function renderParkingLayer(mapComponent, id, data, visible) {
  const { layoutMapReducer, mapModeReducer } = mapComponent.props;
  const { viewport } = mapComponent.state;

  return new ParkingLayer({
    id,
    data,
    visible,
    layoutMap: layoutMapReducer,
    onHover: ({ object, x, y }) => mapComponent.onHoverParking(object, x, y),
    onClick: (obj) => mapComponent.onClickParking(obj),
    isDrawingMode: mapModeReducer,
    viewport: viewport,
  });
}

export function renderDrawPointsLayer(mapComponent) {
  const { mapPolygonReducer, geoJson } = mapComponent.props;
  return new DrawPointsLayer({
    id:
      mapPolygonReducer &&
      mapPolygonReducer.center &&
      mapPolygonReducer.area === undefined
        ? "point-circle-draw"
        : "point-polygon-draw",
    mapPolygonReducer: mapPolygonReducer,
    geoJson: geoJson,
  });
}

export function renderStaticMap(mapComponent) {
  const { viewport, style } = mapComponent.state;

  return (
    <StaticMap
      id="map"
      {...viewport}
      ref={mapComponent.setMapRef}
      mapStyle={style}
      onLoad={() => _onMapLoad(style, mapComponent)}
      mapboxApiAccessToken={mapComponent.context.map.mapboxApiAccessToken}
    />
  );
}

export function createBusStopLayer(
  mapComponent,
  id,
  data,
  active,
  visible,
  viewport
) {
  const { layoutMapReducer, mapModeReducer } = mapComponent.props;
  return new BusStopLayer({
    id: id,
    data: data,
    active: active,
    visible: visible,
    viewport: viewport,
    layoutMap: layoutMapReducer,
    onHover: ({ object, x, y }) => mapComponent.onHoverStop(object, x, y),
    onClick: (object) => mapComponent.onClickStop(object),
    isDrawingMode: mapModeReducer,
  });
}

export function createBusLineLayer(
  mapComponent,
  id,
  data,
  color,
  onClick,
  visible = true,
  selected
) {
  const { mapModeReducer } = mapComponent.props;
  return new BusLineLayer({
    id,
    data,
    color,
    onClick,
    visible,
    selected,
    onHover: ({ object, x, y }) => mapComponent.onHoverLine(object, x, y),
    isDrawingMode: mapModeReducer,
  });
}

export function renderTrafficWidget(mapComponent) {
  const {
    currentContext,
    currentMeasure,
    currentDayType,
    currentTime,
    allTrafficChecked,
    isTimeSliderVisible,
    currentOffSet,
    isNetStateVisible,
    isTypicalVisible,
    trafficCurrentStartTime,
  } = mapComponent.props;

  return allTrafficChecked ? (
    <TrafficWidget
      context={currentContext}
      dayType={currentDayType}
      measure={currentMeasure}
      time={currentTime}
      isSliderOpen={isTimeSliderVisible}
      openTimeSlider={mapComponent.onOpenTimeSlider}
      currentOffSet={currentOffSet}
      isTypicalVisible={isTypicalVisible}
      isNetStateVisible={isNetStateVisible}
      trafficCurrentStartTime={trafficCurrentStartTime}
    ></TrafficWidget>
  ) : null;
}
