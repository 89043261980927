import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Draggable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relX: 0,
      relY: 0,
      x: props.x,
      y: props.y,
    };
    this.gridX = props.gridX || 1;
    this.gridY = props.gridY || 1;
  }

  componentDidUpdate = (prevProps) => {
    if (
      (prevProps.x !== this.props.x && this.props.x !== this.state.x) ||
      (prevProps.y !== this.props.y && this.props.y !== this.state.y)
    ) {
      this.setState({
        x: this.props.x,
        y: this.props.y,
      });
    }
  };

  _onStart = (e) => {
    this.setState({
      relX: e.pageX - this.props.x,
      relY: e.pageY - this.props.y,
    });
  };

  _onMouseDown = (event) => {
    if (event.button !== 0) {
      return;
    }
    this._onStart(event);

    document.addEventListener("mousemove", this._onMouseMove);
    document.addEventListener("mouseup", this._onMouseUp);
    event.preventDefault();
  };

  _onMouseUp = (event) => {
    document.removeEventListener("mousemove", this._onMouseMove);
    document.removeEventListener("mouseup", this._onMouseUp);
    this.props.onStop && this.props.onStop(this.state.x, this.state.y);
    event.preventDefault();
  };

  _onMouseMove = (event) => {
    const x =
      Math.trunc((event.pageX - this.state.relX) / this.gridX) * this.gridX;
    const y =
      Math.trunc((event.pageY - this.state.relY) / this.gridY) * this.gridY;

    const draggableHeight = this.handle.clientHeight;
    const draggableWidth = this.handle.clientWidth;
    const mapHeight = document.getElementById("draggable-container")
      .clientHeight;
    const mapWidth = document.getElementById("draggable-container").clientWidth;

    if (
      (x !== this.state.x || y !== this.state.y) &&
      x >= 0 &&
      y >= 0 &&
      y + draggableHeight <= mapHeight &&
      x + draggableWidth <= mapWidth
    ) {
      this.setState({
        x,
        y,
      });
      this.props.onMove &&
        this.props.onMove(this.state.x, this.state.y, this.props.cam);
    }
  };

  render = () => {
    return (
      <div
        onMouseDown={this._onMouseDown}
        className="draggable"
        ref={(div) => {
          this.handle = div;
        }}
        style={{
          position: "absolute",
          left: this.state.x,
          top: this.state.y,
        }}
        id={
          this.props.cam.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
        }
      >
        {this.props.children}
      </div>
    );
  };
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Draggable)
);
