//events scheduled probability certain
import event_altri_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Altri_Pericoli-CL.svg";
import event_animali_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Animali_Selvatici_Vaganti-CL.svg";
import event_coda_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Coda-CL.svg";
import event_divieto_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Divieto_di_Transito-CL.svg";
import event_doppio_senso_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Doppio_Senso-CL.svg";
import event_fiera_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Esposizione-Fiera-CL.svg";
import event_vento_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Forte_Vento_Laterale-CL.svg";
import event_incidente_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Incidente-CL.svg";
import event_lavori_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Lavori-CL.svg";
// import event_lavori_temp_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Lavori_Temporanei-CL.svg";
import event_mezzi_azione_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Mezzi_di_Lavoro_in_Azione-CL.svg";
// import event_incendio_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Pericolo_di_Incendio-CL.svg";
import event_polizia_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Polizia-CL.svg";
import event_posto_blocco_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Posto_di_Blocco-CL.svg";
import event_sgombraneve_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Sgombraneve_in_Azione-CL.svg";
import event_sdrucciolevole_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Strada_Sdrucciolevole-CL.svg";
import event_sdrucciolevole_ghiaccio_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Strada_Sdrucciolevole_per_Ghiaccio-CL.svg";
import event_sdrucciolevole_pioggia_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Strada_Sdrucciolevole_per_Pioggia-CL.svg";
import event_strettoia_scheduled from "assets/icon/events_new/ColoriClessidra/Italian_traffic_signs_Strettoia_Simmetrica-CL.svg";
//events active probability certain
import event_altri_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Altri_Pericoli.svg";
import event_animali_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Animali_Selvatici_Vaganti.svg";
import event_coda_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Coda.svg";
import event_divieto_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Divieto_di_Transito.svg";
import event_doppio_senso_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Doppio_Senso.svg";
import event_fiera_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Esposizione-Fiera.svg";
import event_vento_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Forte_Vento_Laterale.svg";
import event_incidente_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Incidente.svg";
import event_lavori_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Lavori.svg";
// import event_lavori_temp_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Lavori_Temporanei.svg";
import event_mezzi_azione_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Mezzi_di_Lavoro_in_Azione.svg";
// import event_incendio_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Pericolo_di_Incendio.svg";
import event_polizia_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Polizia.svg";
import event_posto_blocco_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Posto_di_Blocco.svg";
import event_sgombraneve_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Sgombraneve_in_Azione.svg";
import event_sdrucciolevole_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Strada_Sdrucciolevole.svg";
import event_sdrucciolevole_ghiaccio_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Strada_Sdrucciolevole_per_Ghiaccio.svg";
import event_sdrucciolevole_pioggia_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Strada_Sdrucciolevole_per_Pioggia.svg";
import event_strettoia_active from "assets/icon/events_new/Colori/Italian_traffic_signs_Strettoia_Simmetrica.svg";
//events scheduled probability probable
import event_altri_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Altri_Pericoli-BN-CL.svg";
import event_animali_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Animali_Selvatici_Vaganti-BN-CL.svg";
import event_coda_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Coda-BN-CL.svg";
import event_divieto_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Divieto_di_Transito-BN-CL.svg";
import event_doppio_senso_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Doppio_Senso-BN-CL.svg";
import event_fiera_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Esposizione-Fiera-BN-CL.svg";
import event_vento_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Forte_Vento_Laterale-BN-CL.svg";
import event_incidente_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Incidente-BN-CL.svg";
import event_lavori_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Lavori-BN-CL.svg";
// import event_lavori_temp_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Lavori_Temporanei-BN-CL.svg";
import event_mezzi_azione_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Mezzi_di_Lavoro_in_Azione-BN-CL.svg";
// import event_incendio_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Pericolo_di_Incendio-BN-CL.svg";
import event_polizia_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Polizia-BN-CL.svg";
import event_posto_blocco_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Posto_di_Blocco-BN-CL.svg";
import event_sgombraneve_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Sgombraneve_in_Azione-BN-CL.svg";
import event_sdrucciolevole_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Strada_Sdrucciolevole-BN-CL.svg";
import event_sdrucciolevole_ghiaccio_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Strada_Sdrucciolevole_per_Ghiaccio-BN-CL.svg";
import event_sdrucciolevole_pioggia_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Strada_Sdrucciolevole_per_Pioggia-BN-CL.svg";
import event_strettoia_scheduled_pr from "assets/icon/events_new/BN_Clessidra/Italian_traffic_signs_Strettoia_Simmetrica-BN-CL.svg";
//events active probability probable
import event_altri_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Altri_Pericoli-BN.svg";
import event_animali_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Animali_Selvatici_Vaganti-BN.svg";
import event_coda_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Coda-BN.svg";
import event_divieto_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Divieto_di_Transito-BN.svg";
import event_doppio_senso_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Doppio_Senso-BN.svg";
import event_fiera_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Esposizione_Fiera-BN.svg";
import event_vento_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Forte_Vento_Laterale-BN.svg";
import event_incidente_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Incidente-BN.svg";
import event_lavori_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Lavori-BN.svg";
// import event_lavori_temp_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Lavori_Temporanei-BN.svg";
import event_mezzi_azione_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Mezzi_di_Lavoro_in_Azione-BN.svg";
// import event_incendio_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Pericolo_di_Incendio-BN.svg";
import event_polizia_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Polizia-BN.svg";
import event_posto_blocco_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Posto_di_Blocco-BN.svg";
import event_sgombraneve_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Sgombraneve_in_Azione-BN.svg";
import event_sdrucciolevole_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Strada_Sdrucciolevole-BN.svg";
import event_sdrucciolevole_ghiaccio_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Strada_Sdrucciolevole_per_Ghiaccio-BN.svg";
import event_sdrucciolevole_pioggia_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Strada_Sdrucciolevole_per_Pioggia-BN.svg";
import event_strettoia_active_pr from "assets/icon/events_new/BiancoNero/Italian_traffic_signs_Strettoia_Simmetrica-BN.svg";

//ACTIONS
import { getTypeName, getSubtypeName } from "utils/util-events";

export function getIconEvent(event, selectModal) {
  let icon = null;

  const type = getTypeName(event, selectModal);
  const subtype = getSubtypeName(event, selectModal);

  if (event && event.situationRecordId) {
    switch (type) {
      case "ABNORMAL_TRAFFIC":
        switch (event.validity && event.validity.validityStatus) {
          case "planned":
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_coda_scheduled_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_coda_scheduled;
                break;
            }
            break;
          case "active":
          default:
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_coda_active_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_coda_active;
                break;
            }
            break;
        }
        break;
      case "ACCIDENT":
        switch (event.validity && event.validity.validityStatus) {
          case "planned":
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_incidente_scheduled_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_incidente_scheduled;
                break;
            }
            break;
          case "active":
          default:
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_incidente_active_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_incidente_active;
                break;
            }
        }
        break;
      case "PUBLIC_EVENT":
        switch (event.validity && event.validity.validityStatus) {
          case "planned":
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_fiera_scheduled_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_fiera_scheduled;
                break;
            }
            break;
          case "active":
          default:
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_fiera_active_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_fiera_active;
                break;
            }
        }
        break;
      case "EQUIPMENT_OR_SYSTEM_FAULT":
      case "GENERAL_OBSTRUCTION":
      case "ENVIRONMENTAL_OBSTRUCTION":
      case "DISTURBANCE_ACTIVITY":
        switch (event.validity && event.validity.validityStatus) {
          case "planned":
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_altri_scheduled_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_altri_scheduled;
                break;
            }
            break;
          case "active":
          default:
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_altri_active_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_altri_active;
                break;
            }
        }
        break;
      case "ANIMAL_PRESENCE_OBSTRUCTION":
        switch (event.validity && event.validity.validityStatus) {
          case "planned":
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_animali_scheduled_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_animali_scheduled;
                break;
            }
            break;
          case "active":
          default:
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_animali_active_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_animali_active;
                break;
            }
            break;
        }
        break;

      case "AUTHORITY_OPERATION":
        switch (subtype) {
          case "POLICE_CHECK_POINT":
          case "ROAD_OPERATOR_CHECK_POINT":
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_posto_blocco_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_posto_blocco_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_posto_blocco_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_posto_blocco_active;
                    break;
                }
            }
            break;
          default:
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_polizia_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_polizia_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_polizia_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_polizia_active;
                    break;
                }
                break;
            }
        }
        break;
      case "VEHICLE_OBSTRUCTION":
        switch (subtype) {
          case "SLOW_MOVING_MAINTENANCE_VEHICLE":
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_mezzi_azione_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_mezzi_azione_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_mezzi_azione_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_mezzi_azione_active;
                    break;
                }
            }
            break;
          case "SNOWPLOUGH":
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_sgombraneve_scheduled_pr;

                    break;
                  case "CERTAIN":
                  default:
                    icon = event_sgombraneve_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_sgombraneve_active_pr;

                    break;
                  case "CERTAIN":
                  default:
                    icon = event_sgombraneve_active;

                    break;
                }
            }
            break;
          default:
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_altri_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_altri_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_altri_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_altri_active;
                    break;
                }
                break;
            }
        }
        break;

      case "CONSTRUCTION_WORKS":
      case "MAINTENANCE_WORKS":
        switch (event.validity && event.validity.validityStatus) {
          case "planned":
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_lavori_scheduled_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_lavori_scheduled;
                break;
            }
            break;
          case "active":
          default:
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_lavori_active_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_lavori_active;
                break;
            }
        }
        break;
      case "ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT":
        switch (subtype) {
          case "CARRIAGEWAY_CLOSURES":
          case "CLOSED_PERMANENTLY_FOR_THE_WINTER":
          case "INTERMITTENT_SHORT_TERM_CLOSURES":
          case "OVERNIGHT_CLOSURES":
          case "ROAD_CLOSED":
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_divieto_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_divieto_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_divieto_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_divieto_active;
                    break;
                }
                break;
            }

            break;
          case "CONTRAFLOW":
          case "SINGLE_ALTERNATE_LINE_TRAFFIC":
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_doppio_senso_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_doppio_senso_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_doppio_senso_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_doppio_senso_active;
                    break;
                }
                break;
            }

            break;
          case "DO_NOT_USE_SPECIFIED_LANES_OR_CARRIAGEWAYS":
          case "LANE_CLOSURES":
          case "NARROW_LANES":
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_strettoia_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_strettoia_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_strettoia_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_strettoia_active;
                    break;
                }
                break;
            }

            break;
          default:
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_altri_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_altri_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_altri_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_altri_active;
                    break;
                }
                break;
            }
        }
        break;

      case "WEATHER_RELATED_ROAD_CONDITIONS":
        switch (event.validity && event.validity.validityStatus) {
          case "planned":
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_sdrucciolevole_scheduled_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_sdrucciolevole_scheduled;
                break;
            }
            break;
          case "active":
          default:
            switch (event.probabilityOfOccurrence) {
              case "PROBABLE":
              case "RISK_OF":
                icon = event_sdrucciolevole_active_pr;
                break;
              case "CERTAIN":
              default:
                icon = event_sdrucciolevole_active;
                break;
            }
        }

        break;
      case "POOR_ENVIRONMENT_CONDITIONS":
        switch (subtype) {
          case "BAD_WEATHER":
          case "BLOWING_DUST":
          case "DAMAGING_HAIL":
          case "ECLIPSE":
          case "GALES":
          case "HAIL":
          case "HEAVY_RAIN":
          case "HURRICANE_FORCE_WINDS":
          case "PRECIPITATION_IN_THE_AREA":
          case "RAIN":
          case "RAIN_CHANGING_TO_SNOW":
          case "SAND_STORMS":
          case "SHOWERS":
          case "THUNDERSTORMS":
          case "WINTER STORM":
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_sdrucciolevole_pioggia_scheduled_pr;

                    break;
                  case "CERTAIN":
                  default:
                    icon = event_sdrucciolevole_pioggia_scheduled;
                    break;
                }

                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_sdrucciolevole_pioggia_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_sdrucciolevole_pioggia_active;
                    break;
                }
            }
            break;
          case "BLIZZARD":
          case "BLOWING_SNOW":
          case "FROST":
          case "HEAVY_FROST":
          case "HEAVY_SNOWFALL":
          case "SLEET":
          case "SNOW_CHANGING_TO_RAIN":
          case "SNOWFALL":
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                  case "RISK_OF":
                    icon = event_sdrucciolevole_ghiaccio_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_sdrucciolevole_ghiaccio_scheduled;
                    break;
                }

                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                    icon = event_sdrucciolevole_ghiaccio_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_sdrucciolevole_ghiaccio_active;
                    break;
                }
            }
            break;

          case "CROSSWINDS":
          case "GUSTY_WINDS":
          case "STORM_FORCE_WINDS":
          case "STRONG_GUSTS_OF_WIND":
          case "STRONG_WINDS":
          case "TORNADOES":
          case "VERY_STRONG_GUSTS_OF_WIND":
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                    icon = event_vento_scheduled_pr;

                    break;
                  case "CERTAIN":
                  default:
                    icon = event_vento_scheduled;
                    break;
                }

                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                    icon = event_vento_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_vento_active;
                    break;
                }
            }
            break;
          default:
            switch (event.validity && event.validity.validityStatus) {
              case "planned":
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                    icon = event_altri_scheduled_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_altri_scheduled;
                    break;
                }
                break;
              case "active":
              default:
                switch (event.probabilityOfOccurrence) {
                  case "PROBABLE":
                    icon = event_altri_active_pr;
                    break;
                  case "CERTAIN":
                  default:
                    icon = event_altri_active;
                    break;
                }
                break;
            }
        }
        break;
      default:
        icon = event_altri_active;
        break;
    }
  }
  return icon;
}
