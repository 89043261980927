import { EventsActions } from "./events.actions";
import { NavTabActions } from "../ui/nav-tab/nav-tab.actions";
import { MapActions } from "../map/map.actions";
import { filterBoundsEvents } from "utils/utils";
import { getTypeTranslation, getSubtypeTranslation } from "utils/util-events";

export const INITIAL_STATE_EVENT_LIST = {
  current: null,
  list: [],
  searchText: "",
  searchType: "",
  bounds: [],
  active: [],
  planned: [],
  activeFiltered: [],
  plannedFiltered: [],
  currentGroup: [],
  historySelected: null,
  wsLastEvent: [],
  numberActiveEvents: 0,
  numberActiveEventsWithHighestSeverity: 0,
  selectModal: [],
  selected: [],
  field: [],
};

function searchEvents(event, searchText, selectModal) {
  if (searchText !== "") {
    const typeString = getTypeTranslation(event, selectModal);
    const subtypeString = getSubtypeTranslation(event, selectModal);

    return (
      (event &&
        event.source &&
        event.source.sourceReference &&
        searchText &&
        event.source.sourceReference
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (event &&
        event.situationRecordType &&
        searchText &&
        event.situationRecordType
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (event &&
        event.situationRecordType &&
        selectModal &&
        typeString &&
        typeString.toLowerCase() &&
        typeString.toLowerCase().includes(searchText.toLowerCase())) ||
      (event &&
        event.situationRecordType &&
        selectModal &&
        subtypeString &&
        subtypeString.toLowerCase() &&
        subtypeString.toLowerCase().includes(searchText.toLowerCase())) ||
      (event &&
        event.situationRecordId &&
        event.situationRecordId &&
        searchText &&
        event.situationRecordId
          .toLowerCase()
          .includes(searchText.toLowerCase()))
    );
  } else if (searchText === "") {
    return true;
  }
}

function filterEventsState(type) {
  return (event) =>
    event &&
    event.validity &&
    event.validity.validityStatus &&
    event.validity.validityStatus === type;
}

export default function eventsReducer(
  state = INITIAL_STATE_EVENT_LIST || [],
  action
) {
  switch (action.type) {
    case EventsActions.SET_LIST:
      let listEv = [...action.payload.list];
      let tmpListEv = [...action.payload.list];

      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "eventi"
      ) {
        let searchString =
          state && state.searchText ? state.searchText.toLowerCase() : "";
        tmpListEv = tmpListEv.filter((event) => {
          if (
            searchEvents(
              event,
              searchString,
              action.payload && action.payload.selectModal
            )
          ) {
            return true;
          } else return false;
        });
      }

      return {
        ...state,
        list: state.list
          .concat(listEv)
          .filter(
            (v, i, a) =>
              a.findIndex(
                (t) => t.situationRecordId === v.situationRecordId
              ) === i
          ),
        active: state.active
          .concat(listEv.filter(filterEventsState("active")))
          .filter(
            (v, i, a) =>
              a.findIndex(
                (t) => t.situationRecordId === v.situationRecordId
              ) === i
          ),
        planned: state.planned
          .concat(listEv.filter(filterEventsState("planned")))
          .filter(
            (v, i, a) =>
              a.findIndex(
                (t) => t.situationRecordId === v.situationRecordId
              ) === i
          ),
        activeFiltered: state.activeFiltered
          .concat(tmpListEv.filter(filterEventsState("active")))
          .filter(
            (v, i, a) =>
              a.findIndex(
                (t) => t.situationRecordId === v.situationRecordId
              ) === i
          ),
        plannedFiltered: state.plannedFiltered
          .concat(tmpListEv.filter(filterEventsState("planned")))
          .filter(
            (v, i, a) =>
              a.findIndex(
                (t) => t.situationRecordId === v.situationRecordId
              ) === i
          ),
        selectModal: action.payload.selectModal,
      };
    case EventsActions.UPDATE_EVENT:
      if (action.payload) {
        let selList =
          action.payload.validity && action.payload.validity.validityStatus;
        let selListFiltered = selList + "Filtered";
        // let stateCurrentSelected = {
        //   ...state.current,
        //   selected:
        //     state.current.situationRecordId === action.payload.situationRecordId
        //       ? true
        //       : false,
        // };
        // let actionCurrentSelected = {
        //   ...action.payload,
        //   selected:
        //     state.current.situationRecordId === action.payload.situationRecordId
        //       ? true
        //       : false,
        // };
        return {
          ...state,
          list: state.list.map((item) => {
            if (
              action.payload.situationRecordId &&
              item.situationRecordId === action.payload.situationRecordId
            ) {
              item = action.payload;
            }
            return item;
          }),
          [selList]: state[selList].map((item) => {
            if (
              action.payload.situationRecordId &&
              item.situationRecordId === action.payload.situationRecordId
            ) {
              item = action.payload;
            }
            return item;
          }),
          [selListFiltered]: state[selListFiltered].map((item) => {
            if (
              action.payload.situationRecordId &&
              item.situationRecordId === action.payload.situationRecordId
            ) {
              item = action.payload;
            }
            return item;
          }),

          current:
            state.current &&
            state.current.situationRecordId === action.payload.situationRecordId
              ? action.payload
              : state.current,
          // currentGroup: [],
        };
      } else {
        return { ...state };
      }
    case EventsActions.CLOSE_EVENT_PRACTICE:
      let event = action.payload.event;

      return {
        ...state,
        list: state.list.filter((item) => {
          return item.situationRecordId !== event.situationRecordId;
        }),
        active: state.active.filter((item) => {
          return item.situationRecordId !== event.situationRecordId;
        }),
        planned: state.planned.filter((item) => {
          return item.situationRecordId !== event.situationRecordId;
        }),
        activeFiltered: state.activeFiltered.filter((item) => {
          return item.situationRecordId !== event.situationRecordId;
        }),
        plannedFiltered: state.plannedFiltered.filter((item) => {
          return item.situationRecordId !== event.situationRecordId;
        }),
        currentGroup: [],
        current: null,
      };

    case EventsActions.SET_CURRENT_GROUP:
      if (action.payload) {
        let situationRecordType =
          action.payload.validity && action.payload.validity.validityStatus;

        if (situationRecordType && situationRecordType !== "closed") {
          let situationRecordTypeFiltered = situationRecordType + "Filtered";

          let pos = state.currentGroup
            .map((e) => {
              return e.situationRecordId;
            })
            .indexOf(action.payload.situationRecordId);

          let newarrEvent =
            situationRecordType &&
            [...state[situationRecordType]].map((item) => ({
              ...item,
              selected:
                item.situationRecordId === action.payload.situationRecordId
                  ? !item.selected
                  : item.selected,
            }));
          let newarrEventFiltered =
            situationRecordType &&
            [...state[situationRecordTypeFiltered]].map((item) => ({
              ...item,
              selected:
                item.situationRecordId === action.payload.situationRecordId
                  ? !item.selected
                  : item.selected,
            }));
          let newCurr = action.payload;

          newCurr = { ...newCurr, selected: true };

          let newCurrArr;

          if (state.currentGroup.length === 0) {
            newCurrArr = [...state.currentGroup, newCurr];
          } else {
            if (pos === -1) {
              newCurrArr = [...state.currentGroup, newCurr];
            } else {
              newCurrArr = [...state.currentGroup];
              newCurrArr.splice(pos, 1);
            }
          }

          return {
            ...state,
            active: state.active.map((x) => {
              x.selected = false;
              return x;
            }),
            planned: state.planned.map((x) => {
              x.selected = false;
              return x;
            }),
            activeFiltered: state.activeFiltered.map((x) => {
              x.selected = false;
              return x;
            }),
            plannedFiltered: state.plannedFiltered.map((x) => {
              x.selected = false;
              return x;
            }),
            currentGroup: situationRecordType ? newCurrArr : [],
            [situationRecordType]: newarrEvent,
            [situationRecordTypeFiltered]: newarrEventFiltered,
          };
        } else {
          return {
            ...state,
            currentGroup: [],
          };
        }
      } else {
        let current = { ...state.current };
        let situationRecordTypeCurr =
          current.validity && current.validity.validityStatus;
        if (situationRecordTypeCurr !== "closed") {
          let situationRecordTypeCurrFiltered =
            situationRecordTypeCurr + "Filtered";
          let arrEvent = [...state[situationRecordTypeCurr]].map((item) => ({
            ...item,
            selected: false,
          }));
          let arrEventFiltered = [
            ...state[situationRecordTypeCurrFiltered],
          ].map((item) => ({
            ...item,
            selected: false,
          }));
          return {
            ...state,
            current: null,
            [situationRecordTypeCurr]: arrEvent,
            [situationRecordTypeCurrFiltered]: arrEventFiltered,
          };
        } else {
          return { ...state };
        }
      }

    case EventsActions.SET_CURRENT:
      if (action.payload) {
        let situationRecordType = "";

        if (
          action.payload.validity &&
          action.payload.validity.validityStatus ===
            "DEFINED_BY_VALIDITY_TIME_SPEC"
        ) {
          const inActive = state.active.find(
            (item) =>
              item.situationRecordId === action.payload.situationRecordId
          );
          if (inActive) {
            situationRecordType = "active";
          } else {
            situationRecordType = "planned";
          }
        } else {
          situationRecordType =
            action.payload.validity && action.payload.validity.validityStatus;
        }

        if (situationRecordType && situationRecordType !== "closed") {
          let situationRecordTypeFiltered = situationRecordType + "Filtered";

          let newarrEvent = [...state[situationRecordType]].map((item) => ({
            ...item,
            selected:
              item.situationRecordId === action.payload.situationRecordId
                ? !item.selected
                : false,
          }));
          let newarrEventFiltered = [...state[situationRecordTypeFiltered]].map(
            (item) => ({
              ...item,
              selected:
                item.situationRecordId === action.payload.situationRecordId
                  ? !item.selected
                  : false,
            })
          );
          let newCurr =
            state.current &&
            state.current.situationRecordId === action.payload.situationRecordId
              ? null
              : action.payload;

          if (newCurr) newCurr = { ...newCurr, selected: true };
          return {
            ...state,
            active: state.active.map((x) => {
              x.selected = false;
              return x;
            }),
            planned: state.planned.map((x) => {
              x.selected = false;
              return x;
            }),
            activeFiltered: state.active.map((x) => {
              x.selected = false;
              return x;
            }),
            plannedFiltered: state.planned.map((x) => {
              x.selected = false;
              return x;
            }),
            current: newCurr,
            currentGroup: [],
            [situationRecordType]: newarrEvent,
            [situationRecordTypeFiltered]: newarrEventFiltered,
          };
        } else {
          return {
            ...state,
          };
        }
      } else {
        let current = { ...state.current };
        let situationRecordTypeCurr =
          current.validity && current.validity.validityStatus;
        if (situationRecordTypeCurr !== "closed") {
          let situationRecordTypeCurrFiltered =
            situationRecordTypeCurr + "Filtered";
          if (
            situationRecordTypeCurrFiltered &&
            state[situationRecordTypeCurr]
          ) {
            let arrEvent = [...state[situationRecordTypeCurr]].map((item) => ({
              ...item,
              selected: false,
            }));
            let arrEventFiltered = [
              ...state[situationRecordTypeCurrFiltered],
            ].map((item) => ({
              ...item,
              selected: false,
            }));
            return {
              ...state,
              current: null,
              [situationRecordTypeCurr]: arrEvent,
              [situationRecordTypeCurrFiltered]: arrEventFiltered,
            };
          }
        } else {
          return { ...state };
        }
      }
      return { ...state };

    case EventsActions.SET_SELECTED_EVENTS:
      return { ...state, selected: action.payload };

    case EventsActions.SET_INPUT_FIELDS:
      return { ...state, field: action.payload }; // TODO: Priva veniva valorizzato current

    case EventsActions.SET_SELECTED_HISTORY: {
      return {
        ...state,
        historySelected: action.payload,
      };
    }
    case NavTabActions.SET_SEARCH:
      if (action.payload && action.payload.type === "eventi") {
        let tmpListEv = [...state.list];

        let searchString =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";

        tmpListEv = tmpListEv.filter((event) => {
          if (
            searchEvents(
              event,
              searchString,
              action.payload && action.payload.selectModal
            )
          ) {
            return true;
          } else return false;
        });

        let activeFiltered = tmpListEv.filter(filterEventsState("active"));
        let plannedFiltered = tmpListEv.filter(filterEventsState("planned"));

        return {
          ...state,
          activeFiltered,
          plannedFiltered,
          searchText: action.payload.text.toLowerCase(),
          searchType: action.payload.type,
        };
      } else {
        return { ...state };
      }
    case MapActions.SET_LAYOUT:
      let curr = state.current ? { ...state.current } : null;
      if (curr) curr.layoutMap = action.payload;
      return {
        ...state,
        current: curr,
        list: state.list.map((x) => {
          x.layoutMap = action.payload;
          return x;
        }),
        activeFiltered: state.active.map((x) => {
          x.layoutMap = action.payload;
          return x;
        }),
        plannedFiltered: state.planned.map((x) => {
          x.layoutMap = action.payload;
          return x;
        }),
      };
    case MapActions.FILTER_BY_BOUNDS: {
      const { searchText, selectModal } = state;
      const { bounds } = action.payload;

      const filterFn = filterBoundsEvents(bounds);

      if (searchText === "") {
        return {
          ...state,
          bounds: [...bounds],
          activeFiltered: state.active.filter(filterFn),
          plannedFiltered: state.planned.filter(filterFn),
        };
      } else {
        return {
          ...state,
          bounds: [...bounds],
          activeFiltered: state.active
            .filter((x) => searchEvents(x, searchText, selectModal))
            .filter(filterFn),
          plannedFiltered: state.planned
            .filter((x) => searchEvents(x, searchText, selectModal))
            .filter(filterFn),
        };
      }
    }
    case EventsActions.ADD_EVENT_LIST:
      let typeNewEv =
        action.payload.event.validity &&
        action.payload.event.validity.validityStatus;
      if (typeNewEv !== "closed") {
        let typeNewEvFiltered = typeNewEv + "Filtered";
        return {
          ...state,
          list: [...state.list, action.payload.event],
          [typeNewEv]: [...state[typeNewEv], action.payload.event],
          [typeNewEvFiltered]: [
            ...state[typeNewEvFiltered],
            action.payload.event,
          ],
        };
      } else {
        return {
          ...state,
        };
      }
    case EventsActions.MODIFY_EVENT:
      let typeModEv =
        action.payload.event.validity &&
        action.payload.event.validity.validityStatus;
      let typeModEvFiltered = typeModEv + "Filtered";
      let oldsituationRecordType = null;
      oldsituationRecordType = action.payload.oldRoadSituationType
        ? action.payload.oldRoadSituationType.toLowerCase()
        : null;
      action.payload.event = { ...action.payload.event, selected: false };
      if (oldsituationRecordType && oldsituationRecordType === typeModEv) {
        return {
          ...state,
          current: null,
          list: state.list.map((item) => {
            if (
              item.situationRecordId === action.payload.event.situationRecordId
            ) {
              item = action.payload.event;
              item.selected = false;
            }
            return item;
          }),
          [typeModEv]: state[typeModEv].map((item) => {
            if (
              item.situationRecordId === action.payload.event.situationRecordId
            ) {
              item = action.payload.event;
              item.selected = false;
            }
            return item;
          }),
          [typeModEvFiltered]: state[typeModEvFiltered].map((item) => {
            if (
              item.situationRecordId === action.payload.event.situationRecordId
            ) {
              item = action.payload.event;
              item.selected = false;
            }
            return item;
          }),
        };
      } else if (typeModEv !== "closed") {
        let oldsituationRecordTypeFiltered = oldsituationRecordType
          ? oldsituationRecordType + "Filtered"
          : "";

        return {
          ...state,
          current: null,
          list: state.list.map((item) => {
            if (
              item.situationRecordId === action.payload.event.situationRecordId
            ) {
              item = action.payload.event;
              item.selected = false;
            }
            return item;
          }),
          [typeModEv]: [[...state[typeModEv]], action.payload.event],
          [typeModEvFiltered]: [
            [...state[typeModEvFiltered]],
            action.payload.event,
          ],
          [oldsituationRecordType]:
            oldsituationRecordType &&
            state[oldsituationRecordType].filter(
              (item) =>
                item.situationRecordId !==
                action.payload.event.situationRecordId
            ),
          [oldsituationRecordTypeFiltered]:
            oldsituationRecordTypeFiltered &&
            state[oldsituationRecordTypeFiltered].filter(
              (item) =>
                item.situationRecordId !==
                action.payload.event.situationRecordId
            ),
        };
      } else {
        let oldTypeFiltered = oldsituationRecordType
          ? oldsituationRecordType + "Filtered"
          : "";
        return {
          ...state,
          current: null,
          list: state.list.filter(
            (item) =>
              item.situationRecordId !== action.payload.event.situationRecordId
          ),
          [oldsituationRecordType]: state[oldsituationRecordType].filter(
            (item) =>
              item.situationRecordId !== action.payload.event.situationRecordId
          ),
          [oldTypeFiltered]: state[oldTypeFiltered].filter(
            (item) =>
              item.situationRecordId !== action.payload.event.situationRecordId
          ),
        };
      }
    case EventsActions.CLOSE_EVENT:
      let typeCloseEv =
        action.payload.event &&
        action.payload.event.validity &&
        action.payload.event.validity.validityStatus;

      let typeCloseEvFiltered = typeCloseEv + "Filtered";

      return {
        ...state,
        list: state.list.filter(
          (item) =>
            item.situationRecordId !== action.payload.event.situationRecordId
        ),
        [typeCloseEv]: state[typeCloseEv].filter(
          (item) =>
            item.situationRecordId !== action.payload.event.situationRecordId
        ),
        [typeCloseEvFiltered]: state[typeCloseEvFiltered].filter(
          (item) =>
            item.situationRecordId !== action.payload.event.situationRecordId
        ),
        current: null,
      };
    case EventsActions.UPDATE_EVENT_WS:
      let found = null;
      let typeEventWs = null;
      let typeEventWsFiltered = null;
      let objState = { ...state };
      let newCurrent = null;

      action.payload.forEach((item) => {
        if (
          item &&
          item.actionType !== "EXPIRED" &&
          item.situationRecord &&
          item.situationRecordId
        ) {
          //ACTIVE OR PLANNED
          typeEventWs =
            item.situationRecord &&
            item.situationRecord.validity &&
            item.situationRecord.validity.validityStatus;
          typeEventWsFiltered = typeEventWs + "Filtered";
          //SET FILTERED LISTS IN CASE THEY ARE IN CURRENT BBOX AND SEARCH TEXT
          const filterFn = filterBoundsEvents(state.bounds);
          let isEventInBboxAndSearched = [item.situationRecord]
            .filter(filterFn)
            .filter((x) =>
              searchEvents(x, state.searchText, state.selectModal)
            );

          if (
            objState &&
            objState.current &&
            objState.current.situationRecordId === item.situationRecordId
          ) {
            newCurrent = item.situationRecord;
          }
          found = state.list.find(
            (el) => el.situationRecordId === item.situationRecordId
          );

          //ADD TO ACTIVE OR PLANNED EVENTS
          if (typeEventWs && typeEventWs !== "closed") {
            objState = {
              ...objState,
              list: found
                ? objState.list.map((x) => {
                    if (x.situationRecordId === item.situationRecordId) {
                      x = item.situationRecord;
                    }
                    return x;
                  })
                : [...objState.list, item.situationRecord],
              [typeEventWs]: found
                ? objState[typeEventWs].map((x) => {
                    if (x.situationRecordId === item.situationRecordId) {
                      x = item.situationRecord;
                    }
                    return x;
                  })
                : [...objState[typeEventWs], item.situationRecord],
              current: newCurrent,
            };
          }
          //ADD TO FILTERED ACTIVE OR PLANNED IN CASE THEY ARE IN BBOX
          if (
            typeEventWsFiltered &&
            typeEventWs !== "closed" &&
            isEventInBboxAndSearched.length > 0
          ) {
            objState = {
              ...objState,
              [typeEventWsFiltered]: found
                ? objState[typeEventWsFiltered].map((x) => {
                    if (x.situationRecordId === item.situationRecordId) {
                      x = item.situationRecord;
                    }
                    return x;
                  })
                : [...objState[typeEventWsFiltered], item.situationRecord],
            };
          }
          //CASE: TYPE IS NEW OR UPDATE BUT EVENT IS CLOSED
          if (typeEventWs === "closed") {
            if (
              item.situationRecordId &&
              objState &&
              objState.current &&
              objState.current.situationRecordId === item.situationRecordId
            ) {
              newCurrent = null;
            }
            const oldFound = state.list.find(
              (el) => el.situationRecordId === item.situationRecordId
            );
            const oldTypeEventWs =
              oldFound && oldFound.validity && oldFound.validity.validityStatus;
            const oldTypeEventWsFiltered = oldTypeEventWs + "Filtered";

            //SET FILTERED LISTS IN CASE THEY ARE IN CURRENT BBOX AND SEARCH TEXT
            const filterFn = filterBoundsEvents(state.bounds);
            let isEventInBboxAndSearched = [item.situationRecord]
              .filter(filterFn)
              .filter((x) =>
                searchEvents(x, state.searchText, state.selectModal)
              );

            if (oldTypeEventWs && oldTypeEventWs !== "closed") {
              objState = {
                ...objState,
                list: objState.list.filter(
                  (x) => x.situationRecordId !== item.situationRecordId
                ),
                [oldTypeEventWs]: objState[oldTypeEventWs].filter(
                  (x) => x.situationRecordId !== item.situationRecordId
                ),
                current: newCurrent,
              };
            }

            if (
              oldTypeEventWsFiltered &&
              oldTypeEventWs !== "closed" &&
              isEventInBboxAndSearched.length > 0
            ) {
              objState = {
                ...objState,
                [oldTypeEventWsFiltered]: objState[
                  oldTypeEventWsFiltered
                ].filter((x) => x.situationRecordId !== item.situationRecordId),
              };
            }
          }
        } else if (item && item.situationRecord && item.situationRecordId) {
          if (
            item.situationRecordId &&
            objState &&
            objState.current &&
            objState.current.situationRecordId === item.situationRecordId
          ) {
            newCurrent = null;
          }
          found = state.list.find(
            (el) => el.situationRecordId === item.situationRecordId
          );
          typeEventWs =
            found && found.validity && found.validity.validityStatus;
          typeEventWsFiltered = typeEventWs + "Filtered";

          //SET FILTERED LISTS IN CASE THEY ARE IN CURRENT BBOX AND SEARCH TEXT
          const filterFn = filterBoundsEvents(state.bounds);
          let isEventInBboxAndSearched = [item.situationRecord]
            .filter(filterFn)
            .filter((x) =>
              searchEvents(x, state.searchText, state.selectModal)
            );

          if (typeEventWs && typeEventWs !== "closed") {
            objState = {
              ...objState,
              list: objState.list.filter(
                (x) => x.situationRecordId !== item.situationRecordId
              ),
              [typeEventWs]: objState[typeEventWs].filter(
                (x) => x.situationRecordId !== item.situationRecordId
              ),
              current: newCurrent,
            };
          }

          if (
            typeEventWsFiltered &&
            typeEventWs !== "closed" &&
            isEventInBboxAndSearched.length > 0
          ) {
            objState = {
              ...objState,
              [typeEventWsFiltered]: objState[typeEventWsFiltered].filter(
                (x) => x.situationRecordId !== item.situationRecordId
              ),
            };
          }
        }
      });
      return {
        ...objState,
        wsLastEvent: action.payload,
      };
    case EventsActions.RESET_EVENT_LIST_WS:
      return {
        ...state,
        wsLastEvent: [],
      };

    case EventsActions.SET_NUMBER_ACTIVE_EVENTS:
      return {
        ...state,
        numberActiveEvents: action.payload,
      };
    case EventsActions.SET_NUMBER_ACTIVE_EVENTS_WITH_HIGHEST_SEVERITY:
      return {
        ...state,
        numberActiveEventsWithHighestSeverity: action.payload,
      };
    default:
      return state;
  }
}

export const getInputFields = (state) => state.field;
export const getEvents = (state) => state.list;
export const getCurrentEvent = (state) => state.current;
export const getSelectedEvents = (state) => state.selected;
export const getCurrentEventGroup = (state) => state.currentGroup;
export const getActiveEventsFiltered = (state) => state.activeFiltered;
export const getPlannedEventsFiltered = (state) => state.plannedFiltered;
export const getActiveEvents = (state) => state.active;
export const getPlannedEvents = (state) => state.planned;
export const getSelectedHistory = (state) => state.historySelected;
export const getWsLastEvent = (state) => state.wsLastEvent;
export const getNumberActiveEvents = (state) => state.numberActiveEvents;
export const getNumberActiveEventsWithHighestSeverity = (state) =>
  state.numberActiveEventsWithHighestSeverity;
