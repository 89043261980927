export const EventsActions = {
  SET_LIST: "EVENTS/SET_LIST",
  SET_CURRENT: "EVENTS/SET_CURRENT",
  SET_SELECTED_EVENTS: "EVENTS/SET_SELECTED_EVENTS",
  SET_INPUT_FIELDS: "EVENTS/SET_INPUT_FIELDS",
  SET_SELECTED_HISTORY: "EVENTS/SET_SELECTED_HISTORY",
  ADD_EVENT_LIST: "EVENTS/ADD_EVENT_LIST",
  MODIFY_EVENT: "EVENTS/MODIFY_EVENT",
  CLOSE_EVENT: "EVENTS/CLOSE_EVENT",
  SET_CURRENT_GROUP: "EVENTS/SET_CURRENT_GROUP",
  UPDATE_EVENT: "EVENTS/UPDATE_EVENT",
  CLOSE_EVENT_PRACTICE: "EVENTS/CLOSE_EVENT_PRACTICE",
  UPDATE_EVENT_WS: "EVENTS/UPDATE_EVENT_WS",
  RESET_EVENT_LIST_WS: "EVENTS/RESET_EVENT_LIST_WS",
  SET_NUMBER_ACTIVE_EVENTS: "EVENTS/SET_NUMBER_ACTIVE_EVENTS",
  SET_NUMBER_ACTIVE_EVENTS_WITH_HIGHEST_SEVERITY:
    "EVENTS/SET_NUMBER_ACTIVE_EVENTS_WITH_HIGHEST_SEVERITY",
};

export const setInputFields = (field) => (dispatch) => {
  dispatch({
    type: EventsActions.SET_INPUT_FIELDS,
    payload: field,
  });
};


export const setSelectedEvents = (selected) => (dispatch) => {
  dispatch({
    type: EventsActions.SET_SELECTED_EVENTS,
    payload: selected,
  });
};

export const setEventsList = (list, selectModal) => (dispatch) => {
  dispatch({
    type: EventsActions.SET_LIST,
    payload: { list: list, selectModal: selectModal },
  });
};

export const setCurrentEvent = (current) => (dispatch) => {
  dispatch({
    type: EventsActions.SET_CURRENT,
    payload: current,
  });
};

export const setCurrentEventGroup = (current) => (dispatch) => {
  dispatch({
    type: EventsActions.SET_CURRENT_GROUP,
    payload: current,
  });
};
export const setSelectedHistory = (historySelected) => (dispatch) => {
  dispatch({
    type: EventsActions.SET_SELECTED_HISTORY,
    payload: historySelected,
  });
};

export const addEventToList = (event) => (dispatch) => {
  dispatch({
    type: EventsActions.ADD_EVENT_LIST,
    payload: { event },
  });
};

export const modifyEvent = (event, oldRoadSituationType) => (dispatch) => {
  dispatch({
    type: EventsActions.MODIFY_EVENT,
    payload: { event, oldRoadSituationType },
  });
};

export const closeEvent = (event) => (dispatch) => {
  dispatch({
    type: EventsActions.CLOSE_EVENT,
    payload: { event },
  });
};
export const updateEvents = (event) => (dispatch) => {
  dispatch({
    type: EventsActions.UPDATE_EVENT,
    payload: event,
  });
};
export const closeEventPractice = (event) => (dispatch) => {
  dispatch({
    type: EventsActions.CLOSE_EVENT_PRACTICE,
    payload: { event },
  });
};
export const updateEventsWS = (events) => (dispatch) => {
  dispatch({
    type: EventsActions.UPDATE_EVENT_WS,
    payload: events,
  });
};
export const resetListEventWs = () => (dispatch) => {
  dispatch({
    type: EventsActions.RESET_EVENT_LIST_WS,
    payload: null,
  });
};

export const setNumberActiveEvents = (data) => (dispatch) => {
  dispatch({
    type: EventsActions.SET_NUMBER_ACTIVE_EVENTS,
    payload: data,
  });
};

export const setNumberActiveEventsWithHighestSeverity = (data) => (
  dispatch
) => {
  dispatch({
    type: EventsActions.SET_NUMBER_ACTIVE_EVENTS_WITH_HIGHEST_SEVERITY,
    payload: data,
  });
};
