import { NavActions } from "./nav.actions";

export const INITIAL_STATE = {
  isOpenDropdownNetwork: false,
  isOpenNavGraph: false,
  isOpenNavNetworkRdsTmc: false,
  isOpenNavTPL: false,
  isOpenNavEvents: false,
  isOpenNavTraffic: false,
  isOpenNavStation: false,
  isOpenNavWebcams: false,
  isOpenNavPmv: false,
  isOpenNavParking: false,
};

export default function navReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NavActions.TOGGLE_DROPDOWN_NETWORK:
      return {
        ...state,
        isOpenDropdownNetwork: !state.isOpenDropdownNetwork,
      };
    case NavActions.TOGGLE_NAV_GRAPH:
      return {
        ...state,
        isOpenNavNetworkRdsTmc: false,
        isOpenNavEvents: false,
        isOpenNavTPL: false,
        isOpenNavGraph: action.payload,
        isOpenNavTraffic: false,
        isOpenNavStation: false,
        isOpenNavWebcams: false,
        isOpenNavPmv: false,
        isOpenNavParking: false,
      };
    case NavActions.TOGGLE_NAV_NETWORKRDSTMC:
      return {
        ...state,
        isOpenNavGraph: false,
        isOpenNavEvents: false,
        isOpenNavTPL: false,
        isOpenNavNetworkRdsTmc: action.payload,
        isOpenNavTraffic: false,
        isOpenNavStation: false,
        isOpenNavWebcams: false,
        isOpenNavPmv: false,
        isOpenNavParking: false,
      };
    case NavActions.TOGGLE_NAV_TPL:
      return {
        ...state,
        isOpenNavGraph: false,
        isOpenNavNetworkRdsTmc: false,
        isOpenNavEvents: false,
        isOpenNavTPL: action.payload,
        isOpenNavTraffic: false,
        isOpenNavStation: false,
        isOpenNavWebcams: false,
        isOpenNavPmv: false,
        isOpenNavParking: false,
      };
    case NavActions.TOGGLE_NAV_EVENTS:
      return {
        ...state,
        isOpenNavGraph: false,
        isOpenNavNetworkRdsTmc: false,
        isOpenNavTPL: false,
        isOpenNavEvents: action.payload,
        isOpenNavTraffic: false,
        isOpenNavStation: false,
        isOpenNavWebcams: false,
        isOpenNavPmv: false,
        isOpenNavParking: false,
      };
    case NavActions.TOGGLE_NAV_TRAFFIC:
      return {
        ...state,
        isOpenNavGraph: false,
        isOpenNavNetworkRdsTmc: false,
        isOpenNavTPL: false,
        isOpenNavEvents: false,
        isOpenNavTraffic: action.payload,
        isOpenNavStation: false,
        isOpenNavWebcams: false,
        isOpenNavPmv: false,
        isOpenNavParking: false,
      };
    case NavActions.TOGGLE_NAV_STATION:
      return {
        ...state,
        isOpenNavGraph: false,
        isOpenNavNetworkRdsTmc: false,
        isOpenNavTPL: false,
        isOpenNavEvents: false,
        isOpenNavTraffic: false,
        isOpenNavStation: action.payload,
        isOpenNavPmv: false,
        isOpenNavParking: false,
      };
    case NavActions.TOGGLE_NAV_WEBCAMS:
      return {
        ...state,
        isOpenNavGraph: false,
        isOpenNavNetworkRdsTmc: false,
        isOpenNavTPL: false,
        isOpenNavEvents: false,
        isOpenNavTraffic: false,
        isOpenNavStation: false,
        isOpenNavWebcams: action.payload,
        isOpenNavPmv: false,
        isOpenNavParking: false,
      };
    case NavActions.TOGGLE_NAV_PMV:
      return {
        ...state,
        isOpenNavGraph: false,
        isOpenNavNetworkRdsTmc: false,
        isOpenNavTPL: false,
        isOpenNavEvents: false,
        isOpenNavTraffic: false,
        isOpenNavStation: false,
        isOpenNavWebcams: false,
        isOpenNavPmv: action.payload,
        isOpenNavParking: false,
      };
    case NavActions.TOGGLE_NAV_PARKING:
      return {
        ...state,
        isOpenNavGraph: false,
        isOpenNavNetworkRdsTmc: false,
        isOpenNavTPL: false,
        isOpenNavEvents: false,
        isOpenNavTraffic: false,
        isOpenNavStation: false,
        isOpenNavWebcams: false,
        isOpenNavPmv: false,
        isOpenNavParking: action.payload,
      };
    default:
      return state;
  }
}

export const isOpenDropdownNetwork = (state) => state.isOpenDropdownNetwork;
export const isOpenNavGraph = (state) => state.isOpenNavGraph;
export const isOpenNavNetworkRdsTmc = (state) => state.isOpenNavNetworkRdsTmc;
export const isOpenNavTPL = (state) => state.isOpenNavTPL;
export const isOpenNavEvents = (state) => state.isOpenNavEvents;
export const isOpenNavTraffic = (state) => state.isOpenNavTraffic;
export const isOpenNavStation = (state) => state.isOpenNavStation;
export const isOpenNavWebcams = (state) => state.isOpenNavWebcams;
export const isOpenNavPmv = (state) => state.isOpenNavPmv;
