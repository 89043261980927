import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";
class CentroidsLayer extends CompositeLayer {
  renderLayers() {
    const {
      data,
      layout,
      visible,
      onClick,
      centroidCurrentReducer,
      isDrawingMode,
    } = this.props;

    return new IconLayer(
      this.getSubLayerProps({
        id: "icon-centroids",
        data: data,
        pickable: isDrawingMode ? false : true,
        ...DeckLayerUtil.loadOptions(),
        getIcon: (obj) => ({
          url: DeckLayerUtil.getIconCentroids(
            obj,
            layout,
            centroidCurrentReducer
          ),
          width: 40,
          height: 40,
          anchorX: 20,
          anchorY: 20,
        }),
        sizeScale: 5,
        showCluster: false,
        visible: visible,
        onClick: onClick,
        getPosition: (d) => d.geometry.coordinates,
        getSize: (data) =>
          (data && data.selected) ||
          (centroidCurrentReducer &&
            data.id === centroidCurrentReducer.id &&
            centroidCurrentReducer.selected)
            ? 10
            : 6,
      })
    );
  }
}

CentroidsLayer.layerName = "CentroidsLayer";

export default CentroidsLayer;
