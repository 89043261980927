import React, { Component, createRef } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//COMPONENTS
import Loading from "components/shared/loading/loading";
import InfiniteScroll from "react-infinite-scroll-component";
//ASSETS
import icn_map_point from "assets/icon/graph/icn-map-point.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";
import { getCurrentNodeMap } from "store";

const SCROLL_OFFSET = 25;

class NodeList extends Component {
  constructor(props) {
    super(props);
    this.scrollDiv = createRef();
    this.state = {
      openDetail: false,
      currentOffset: SCROLL_OFFSET,
      nextNodes: [],
      currentListLen: 0,
    };
  }

  componentDidMount = () => {
    const { currentNodeMap } = this.props;
    if (currentNodeMap && currentNodeMap.selected) {
      this.scrollSmoothHandler();
    }
  };

  componentDidUpdate = () => {
    const { currentNodeMap } = this.props;
    if (currentNodeMap && currentNodeMap.selected) {
      this.scrollSmoothHandler();
    }
  };

  fetchMoreData = () => {
    const { currentOffset } = this.state;
    const { nodesList } = this.props;
    let newOffset = currentOffset + SCROLL_OFFSET;
    let fetchmore = nodesList.slice(0, newOffset);
    this.setState({
      nextNodes: fetchmore,
      currentOffset: newOffset,
    });
  };

  scrollSmoothHandler = () => {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  constructFetchedDOM = () => {
    const { nextNodes } = this.state;
    const { toggleNodeDetails, currentNodeMap } = this.props;

    return (
      <div className="node-list-container">
        <div className="uk-list">
          {nextNodes &&
            nextNodes.map((node, index) => {
              return (
                <div
                  className="uk-flex uk-flex-row uk-flex-middle uk-flex-between"
                  key={index}
                >
                  <li
                    id={node.id}
                    className={
                      currentNodeMap &&
                      currentNodeMap.selected &&
                      currentNodeMap.id === node.id
                        ? "uk-flex uk-flex-between uk-active uk-flex-middle"
                        : "uk-flex uk-flex-between uk-flex-middle"
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      toggleNodeDetails(node);
                    }}
                  >
                    <div className="node-icon-bg uk-flex uk-flex-middle uk-flex-center">
                      {/* <div className="uk-position-relative node-icon"></div> */}
                      {currentNodeMap &&
                      currentNodeMap.selected &&
                      currentNodeMap.id === node.id ? (
                        <div
                          className="parent uk-flex uk-flex-middle uk-flex-center"
                          ref={this.scrollDiv}
                        >
                          <div className="circle-list-map"></div>
                          <img
                            alt="icn_diamond"
                            src={icn_diamond}
                            className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
                          ></img>
                          <img
                            alt="icn_map_point"
                            src={icn_map_point}
                            className="image2  height-width-48 uk-flex uk-flex-middle uk-flex-center arch-icon"
                          ></img>
                        </div>
                      ) : (
                        <img
                          alt="icn_map_point"
                          src={icn_map_point}
                          className="uk-position-relative height-width-48 uk-flex uk-flex-middle uk-flex-center arch-icon"
                        ></img>
                      )}
                    </div>

                    <div className="uk-flex uk-flex-column uk-margin-small-left">
                      <div
                        className={
                          currentNodeMap &&
                          currentNodeMap.selected &&
                          currentNodeMap.id === node.id
                            ? "uk-width-1-1 node-header-name"
                            : "uk-width-1-1 list-text node-header-name"
                        }
                      >
                        {node.properties.n ? node.properties.n : "-"}
                      </div>
                      <div
                        className={
                          currentNodeMap &&
                          currentNodeMap.selected &&
                          currentNodeMap.id === node.id
                            ? "uk-width-1-1 node-header-name"
                            : "uk-width-1-1 list-text node-header-name"
                        }
                      >
                        (
                        {node.geometry.coordinates[0]
                          ? Math.round(node.geometry.coordinates[1] * 1000000) /
                            1000000
                          : "-"}{" "}
                        °N -
                        {node.geometry.coordinates[1]
                          ? Math.round(node.geometry.coordinates[0] * 1000000) /
                            1000000
                          : "-"}{" "}
                        °E)
                      </div>
                    </div>
                  </li>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { nextNodes, currentOffset, currentListLen } = state;
    const { nodesList } = props;
    let newState = {};

    if (currentListLen !== nodesList.length && nodesList.length === 0) {
      newState = {
        currentListLen: nodesList.length,
        currentOffset: SCROLL_OFFSET,
        nextNodes: [],
      };
    }
    if (currentListLen !== nodesList.length && nodesList.length > 0) {
      newState = {
        currentListLen: nodesList.length,
        currentOffset: nodesList.length - 1,
        nextNodes: nodesList.slice(0, nodesList.length),
      };
    }
    if (nextNodes.length === 0 && currentListLen > 0) {
      newState = {
        ...state,
        nextNodes: nodesList.slice(
          0,
          currentListLen > SCROLL_OFFSET ? currentOffset : currentListLen
        ),
      };
    }

    return newState;
  }

  renderInfiniteScroll = () => {
    const { nextNodes } = this.state;
    const { nodesList } = this.props;

    return (
      <InfiniteScroll
        dataLength={nextNodes.length || 0} //This is important field to render the next data
        next={this.fetchMoreData}
        hasMore={nextNodes.length === nodesList.length ? false : true}
        loader={
          <div>
            {nodesList.length ? "" /* <Loading /> */ : "Nessun elemento..."}
          </div>
        }
        scrollableTarget={"tabpanel-0"}
        className="inner-infinite-scroll"
      >
        {this.constructFetchedDOM()}
      </InfiniteScroll>
    );
  };

  render() {
    const { isNodesLoading } = this.props;

    return (
      <div>{isNodesLoading ? <Loading /> : this.renderInfiniteScroll()}</div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentNodeMap: getCurrentNodeMap(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NodeList)
);
