import { geometryGeoJson } from "../../graph/graph-features.models";

export const INITIAL_STATE_PMV = {
  pmvs: [],
  pmvsFiltered: [],
  current: null,
  searchText: "",
  searchType: "",
  wsLastPmv: [],
  currentGroup: [],
};

export class PmvFeatureCollectionModel {
  type;
  features;
  totalFeatures;
  numberMatched;
  numberReturned;
  timestamp;

  constructor() {
    this.type = null;
    this.features = null;
    this.totalFeatures = null;
    this.numberMatched = null;
    this.numberReturned = null;
    this.timestamp = null;
  }

  static fromREST(obj) {
    var collection = new PmvFeatureCollectionModel();
    collection.type = obj.type ? obj.type : null;
    collection.features = obj.features
      ? PmvFeaturesModel.fromArrayREST(obj.features)
      : [];
    collection.totalFeatures = obj.totalFeatures ? obj.totalFeatures : null;
    collection.numberMatched = obj.numberMatched ? obj.numberMatched : null;
    collection.numberReturned = obj.numberReturned ? obj.numberReturned : null;
    collection.timestamp = obj.timestamp ? obj.timestamp : null;
    return collection;
  }

  static fromArrayREST(data) {
    return data
      .map(PmvFeatureCollectionModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class PmvFeaturesModel {
  type;
  id;
  geometry;
  properties;

  constructor() {
    this.type = null;
    this.id = null;
    this.geometry = null;
    this.properties = null;
  }

  static fromREST(obj) {
    let features = new PmvFeaturesModel();
    features.type = obj.type ? obj.type : null;
    features.id = obj.id ? obj.id : null;
    features.geometry = obj.geometry
      ? geometryGeoJson.fromREST(obj.geometry)
      : null;
    features.selected = obj.selected ? obj.selected : false;
    features.properties = PmvPropertiesAnagrafica.from(obj.properties)
      ? obj.properties
      : null;
    return features;
  }
  static fromArrayREST(data) {
    return data.map(PmvFeaturesModel.fromREST).filter((item) => item !== null);
  }
}

export class PmvPropertiesAnagrafica {
  anagrafica;

  constructor() {
    this.anagrafica = null;
  }

  static from(obj) {
    let panel = new PmvPropertiesAnagrafica();
    if (obj.anagrafica)
      panel.anagrafica = PmvAnagraficaModel.from(obj.anagrafica);
    return panel;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(PmvPropertiesAnagrafica.from)
      .filter((item) => item !== null);
  }
}

export class PmvAnagraficaAndStatusModel {
  anagrafica;
  stati;

  constructor() {
    this.anagrafica = null;
    this.stati = null;
  }

  static from(obj) {
    let panel = new PmvAnagraficaAndStatusModel();
    if (obj.anagrafica) {
      panel.anagrafica = PmvAnagraficaModel.from(obj.anagrafica);
    }
    if (obj.stati) {
      panel.stati = PmvOnlyStatusModel.fromArray(obj.stati);
    }
    return panel;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(PmvAnagraficaAndStatusModel.from)
      .filter((item) => item !== null);
  }
}

export class PmvAnagraficaAndStatusHistoryModel {
  anagrafica;
  statiPerPannello;
  pageHits;
  totalHits;

  constructor() {
    this.anagrafica = null;
    this.statiPerPannello = null;
    this.pageHits = null;
    this.totalHits = null;
  }

  static from(obj) {
    let panel = new PmvAnagraficaAndStatusHistoryModel();
    if (obj.anagrafica) {
      panel.anagrafica = PmvAnagraficaModel.from(obj.anagrafica);
    }
    if (obj.statiPerPannello) {
      panel.statiPerPannello = PmvOnlyStatusModel.fromArray(
        obj.statiPerPannello
      );
    }
    panel.totalHits = obj.totalHits ? obj.totalHits : null;
    panel.pageHits = obj.pageHits ? obj.pageHits : null;
    return panel;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(PmvAnagraficaAndStatusHistoryModel.from)
      .filter((item) => item !== null);
  }
}

export class PmvOnlyStatusModel {
  statoOperativo;
  statoFunzionamento;
  periodo;

  constructor() {
    this.statoOperativo = null;
    this.statoFunzionamento = null;
    this.periodo = null;
  }

  static from(obj) {
    let panel = new PmvOnlyStatusModel();
    if (obj.statoOperativo)
      panel.statoOperativo = PmvOperationalStatusModel.from(obj.statoOperativo);
    if (obj.statoFunzionamento)
      panel.statoFunzionamento = obj.statoFunzionamento;
    if (obj.periodo) panel.periodo = ValiditaModel.from(obj.periodo);
    return panel;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(PmvOnlyStatusModel.from).filter((item) => item !== null);
  }
}

export class PmvOperationalStatusModel {
  nomeMessaggio;
  livelloEsposizione;
  descrizioneEsposizione;
  gifBase64;
  validita;
  fonte;
  lampeggiante;

  constructor() {
    this.nomeMessaggio = null;
    this.livelloEsposizione = null;
    this.descrizioneEsposizione = null;
    this.gifBase64 = null;
    this.validita = null;
    this.fonte = null;
    this.lampeggiante = null;
  }

  static from(obj) {
    let panel = new PmvOperationalStatusModel();
    //
    if (obj.nomeMessaggio) panel.nomeMessaggio = obj.nomeMessaggio;
    if (obj.livelloEsposizione)
      panel.livelloEsposizione = obj.livelloEsposizione;
    if (obj.descrizioneEsposizione)
      panel.descrizioneEsposizione = obj.descrizioneEsposizione;
    if (obj.gifBase64) panel.gifBase64 = obj.gifBase64;
    if (obj.fonte) panel.fonte = obj.fonte;
    if (obj.validita) panel.validita = ValiditaModel.from(obj.validita);
    if (obj.lampeggiante) panel.lampeggiante = obj.lampeggiante;

    return panel;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(PmvOperationalStatusModel.from)
      .filter((item) => item !== null);
  }
}

export class PmvAnagraficaModel {
  id;
  nome;
  posizione;
  strada;
  tipologia;
  gruppo;
  idGruppo;
  stato;
  paginaDiDettaglioUrl;
  paginaInvioMessggioUrl;

  constructor() {
    this.id = null;
    this.nome = null;
    this.posizione = null;
    this.strada = null;
    this.tipologia = null;
    this.gruppo = null;
    this.idGruppo = null;
    this.stato = null;
    this.paginaDiDettaglioUrl = null;
    this.paginaInvioMessggioUrl = null;
  }

  static from(obj) {
    let panel = new PmvAnagraficaModel();
    if (obj.id) panel.id = obj.id;
    if (obj.nome) panel.nome = obj.nome;
    if (obj.posizione) panel.posizione = obj.posizione;
    if (obj.strada) panel.strada = obj.strada;
    if (obj.tipologia) panel.tipologia = obj.tipologia;
    if (obj.gruppo) panel.gruppo = obj.gruppo;
    if (obj.idGruppo) panel.idGruppo = obj.idGruppo;
    if (obj.stato) panel.stato = obj.stato;
    if (obj.paginaDiDettaglioUrl)
      panel.paginaDiDettaglioUrl = obj.paginaDiDettaglioUrl;
    if (obj.paginaInvioMessggioUrl) {
      panel.paginaInvioMessggioUrl = obj.paginaInvioMessggioUrl;
    }
    return panel;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(PmvAnagraficaModel.from).filter((item) => item !== null);
  }
}

export class ValiditaModel {
  da;
  a;

  constructor() {
    this.da = null;
    this.a = null;
  }

  static from(obj) {
    let panel = new ValiditaModel();
    if (obj.da) panel.da = obj.da;
    if (obj.a) panel.a = obj.a;

    return panel;
  }
}
