import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { take, tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import * as moment from "moment";
import "../style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap, debounceTime, concatMap } from "rxjs/operators";

//COMPONENTS
import Loading from "components/shared/loading/loading";
import TableHead from "components/shared/table-components/table-head";
import TableBody from "./table-body";
import TableFilters from "components/shared/table-top-filters/table-filters";
import PageNumbers from "components/shared/page-numbers/page-number";
//MODEL
import { UserActivityModel } from "reducers/user/user.model";
//STORE
import {
  isOpenNavTab,
  getSavedTableColumns,
  getSavedDateColumns,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedSearchText,
  getSavedFilters,
  getSavedToggledFilters,
  getUsername,
  getUserRoles,
} from "store";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { addActivity } from "reducers/user/user.actions";
//UTILS
import {
  TABLE_COLUMNS,
  DATE_COLUMNS,
  initColumns,
} from "utils/table-utils/util-parking-table";
import * as FilterUtils from "utils/filter-utils";
import { debounce } from "utils/utils";
import {
  getParkingLayoutTranslation,
  getOperationalStatusTranslation,
  getOpeningStatusTranslation,
  getParkingPayment,
  getFiltersForParkingTable,
} from "utils/utils-parking";
import {
  handleFilterStringChange,
  handleClickIncreasePage,
  handleClickDecreasePage,
  requestSortOrder,
  resetChildrenForHeaderDropdown,
  onDropdownCheckboxFilterClick,
  getCurrentPageNumber,
  resetFiltersState,
  initializeTableState,
  getTableStateSortConfig,
} from "utils/table-utils/util-general-tables";
import { saveBlob } from "utils/hooks";

const FILTER_TARGET = "parkingFilters";

class ParkingTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      currentListParkings: [],
      stateTableColumns: TABLE_COLUMNS,
      sortConfig: getTableStateSortConfig("arch"),
      ...initializeTableState,
    };
    this.getParkingListDebounced = debounce(this.getParkingList, 800);
    this.numberPage$ = new BehaviorSubject();
  }

  componentDidMount = () => {
    const { searchText } = this.props;
    this.apiService = createApiService(this.context);

    this.subscriptions.push(
      this.numberPage$
        .pipe(
          debounceTime(800),
          switchMap(
            (x) =>
              new Observable((observer) => {
                const { pageToDisplay } = this.state;
                if (pageToDisplay !== "") {
                  this.getParkingList();
                }
              })
          )
        )
        .subscribe()
    );

    if (searchText) {
      this.setState({
        ...this.state,
        filterStr: searchText,
        isTableDataLoading: true,
      });
    } else {
      this.setState({
        ...this.state,
        isTableDataLoading: true,
      });
    }
    this.tableConfigs();
  };

  tableConfigs = () => {
    const {
      tableColumns,
      dateColumns,
      saveTableFilters,
      searchText,
    } = this.props;

    const { columns, date } = initColumns(tableColumns, dateColumns);
    let sld = FilterUtils.constructFlatMultiSelect(columns);
    let obj = {};
    sld.forEach((x) => {
      obj[x.name] = { ...x };
    });
    saveTableFilters(
      {
        tableColumns: columns,
        dateColumns: date,
        dateFrom: null,
        dateTo: null,
        searchText: searchText || "",
        toggledFilters: obj || {},
      },
      FILTER_TARGET
    );
    this.setState({
      selected: sld,
      searchText: searchText || "",
    });

    //TODO DE-COMMENT WHEN CONTACT API IS AVAILABLE
    // this.getContactList()
  };

  getContactList = () => {
    const { stateTableColumns } = this.state;
    const { saveTableFilters, tableFilters } = this.props;

    this.subscriptions.push(
      this.apiService
        .getParkingContacts()
        .pipe(
          take(1),
          tap((data) => {
            let listOfAuthorities = [...data];
            let updatedTableColumns = {
              ...stateTableColumns,
              responsibleAuthorityName: {
                ...stateTableColumns.responsibleAuthorityName,
                childrenForHeaderDropdown: [
                  {
                    label: "Seleziona tutto",
                    checked: true,
                    id: "all-gestore",
                    isChild: true,
                    name: "Seleziona tutto",
                  },
                  ...listOfAuthorities.map((item) => {
                    return {
                      label: item,
                      checked: true,
                      id: item,
                      isChild: true,
                      name: item,
                    };
                  }),
                ],
              },
            };

            saveTableFilters(
              {
                ...tableFilters,
                tableColumns: updatedTableColumns,
              },
              FILTER_TARGET
            );

            this.setState({
              ...this.state,
              stateTableColumns: updatedTableColumns,
            });
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  getParkingList = () => {
    const {
      count,
      sortConfig,
      currentPage,
      filterStr,
      stateTableColumns,
    } = this.state;

    const {
      parkingLayout,
      responsibleAuthorityName,
      parkingPayment,
      parkingOperationalStatus,
      parkingOpeningStatus,
      orderBy,
      orderType,
    } = getFiltersForParkingTable(stateTableColumns, sortConfig);

    this.subscriptions.push(
      this.apiService
        .getAllParkingRecordsWithLatestStatus(
          currentPage,
          count,
          true,
          null,
          null,
          //FROM HERE
          parkingLayout,
          responsibleAuthorityName,
          parkingPayment,
          parkingOperationalStatus,
          parkingOpeningStatus,
          filterStr,
          ["PARKING_NAME", "PARKING_DESCRIPTION"],
          orderBy,
          orderType
        )
        .pipe(
          take(1),
          tap((data) => {
            let parkings = [...data.payload];
            let pageMax = Math.ceil(data.totalElements / count);

            this.setState({
              ...this.state,
              isTableDataLoading: false,
              currentListParkings: parkings,
              pageMax: pageMax,
            });
          }),
          catchError((error) => {
            console.error(error);
            this.setState({
              ...this.state,
              isTableDataLoading: false,
              currentListParkings: [],
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  //CALL API TO OBTAIN NEW ARC LIST
  refreshData = () => {
    this.setState({
      ...this.state,
      currentListParkings: [],
      isTableDataLoading: true,
    });
    this.getParkingList();
  };

  downloadExcel = () => {
    const {
      count,
      sortConfig,
      currentPage,
      filterStr,
      stateTableColumns,
    } = this.state;

    const {
      parkingLayout,
      responsibleAuthorityName,
      parkingPayment,
      parkingOperationalStatus,
      parkingOpeningStatus,
      orderBy,
      orderType,
    } = getFiltersForParkingTable(stateTableColumns, sortConfig);

    this.apiService
      .fetchParkingCsv(
        currentPage,
        count,
        true,
        null,
        null,
        //FROM HERE
        parkingLayout,
        responsibleAuthorityName,
        parkingPayment,
        parkingOperationalStatus,
        parkingOpeningStatus,
        filterStr,
        ["PARKING_NAME", "PARKING_DESCRIPTION"],
        orderBy,
        orderType
      )
      .pipe(
        tap((blob) => {
          let filename =
            "parcheggi_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postParkingDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postParkingDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "PARKING",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Parcheggi"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  handleFilterChange = (e) => {
    handleFilterStringChange(
      this,
      e,
      FILTER_TARGET,
      this.getParkingListDebounced
    );
  };

  // GESTIONE CHECKBOX: VISUALIZZAZIONE COLONNE
  handleSelectChange = (visibleColumns) => {
    FilterUtils.handleSelectChange(this, visibleColumns, FILTER_TARGET);
  };

  //GESTIONE CHECKBOX FILTRI SU COLONNE
  onCheckboxFilterClick = (newTableColumns) => {
    this.setState({
      ...this.state,
      isTableDataLoading: true,
    });
    onDropdownCheckboxFilterClick(
      this,
      this.getParkingList,
      FILTER_TARGET,
      newTableColumns
    );
  };

  getPageNumber = (pageNumber) => {
    getCurrentPageNumber(this, pageNumber);
  };

  handleClickIncrease = () => {
    handleClickIncreasePage(this);
  };

  handleClickDecrease = () => {
    handleClickDecreasePage(this);
  };

  requestSort = (key) => {
    requestSortOrder(this, key, this.getParkingList);
  };

  resetFilters = (e) => {
    const { resetTableFilters, toggledFilters } = this.props;
    const { selected, stateTableColumns } = this.state;

    e.stopPropagation();

    let newStateTableColumns = resetChildrenForHeaderDropdown(
      stateTableColumns
    );

    resetTableFilters(
      {
        tableColumns: { ...newStateTableColumns },
        dateColumns: [...DATE_COLUMNS],
        toggledFilters: toggledFilters,
      },
      FILTER_TARGET
    );

    resetFiltersState(
      this,
      newStateTableColumns,
      selected,
      this.getParkingList,
      "parking"
    );
  };

  toTableColumns = (parkings) => {
    let newList = [];

    parkings.forEach((parking) => {
      let newEl = {};
      if (parking) {
        newEl = {
          parkingTableName: parking.parkingTableName
            ? parking.parkingTableName
            : "-",
          parkingDescription: parking.parkingDescription
            ? parking.parkingDescription
            : "-",
          parkingAddress: parking.parkingAddress ? parking.parkingAddress : "-",
          parkingCoordinates:
            parking &&
            parking.parkingCoordinates &&
            parking.parkingCoordinates.latitude &&
            parking.parkingCoordinates.longitude
              ? parking.parkingCoordinates.latitude +
                ", " +
                parking.parkingCoordinates.longitude
              : "-",
          responsibleAuthorityName: parking.responsibleAuthorityName
            ? parking.responsibleAuthorityName
            : "-",
          parkingPayment: getParkingPayment(parking.parkingPayment),
          parkingLayout: getParkingLayoutTranslation(parking.parkingLayout),
          parkingOpeningStatus: getOpeningStatusTranslation(
            parking.parkingOpeningStatus
          ),
          parkingOperationalStatus: getOperationalStatusTranslation(
            parking.parkingOperationalStatus
          ),
          parkingNumberOfSpaces:
            parking.parkingNumberOfSpaces ||
            typeof parking.parkingNumberOfSpaces === "number"
              ? parking.parkingNumberOfSpaces
              : "-",
          parkingNumberOfSpacesOverride:
            parking.parkingNumberOfSpacesOverride ||
            typeof parking.parkingNumberOfSpacesOverride === "number"
              ? parking.parkingNumberOfSpacesOverride
              : "-",
          parkingNumberOfOccupiedSpaces:
            parking.parkingNumberOfOccupiedSpaces ||
            typeof parking.parkingNumberOfOccupiedSpaces === "number"
              ? parking.parkingNumberOfOccupiedSpaces
              : "-",
          parkingNumberOfVacantSpaces:
            parking.parkingNumberOfVacantSpaces ||
            typeof parking.parkingNumberOfVacantSpaces === "number"
              ? parking.parkingNumberOfVacantSpaces
              : "-",
          parkingOccupancy:
            parking.parkingOccupancy ||
            typeof parking.parkingOccupancy === "number"
              ? parking.parkingOccupancy
              : "-",
          latestParkingUpdate: parking.latestParkingUpdate
            ? moment(parking.latestParkingUpdate).format("DD/MM/YY - HH:mm")
            : "-",
        };
      }
      newList = [...newList, newEl];
    });

    return newList;
  };

  render = () => {
    const {
      sortConfig,
      filterStr,
      selected,
      isTableDataLoading,
      currentListParkings,
      pageMax,
      pageToDisplay,
    } = this.state;
    const {
      toggleGenericTable,
      isOpenNav,
      dateColumns,
      tableColumns,
    } = this.props;

    let listOfParkings = [];
    if (currentListParkings) {
      //MAPPA PER VISUALIZZAZIONE SU TABELLA
      listOfParkings = this.toTableColumns(currentListParkings);
    }

    return (
      <div className="acr-table uk-flex uk-flex-column">
        <div className="navbar-placeholder"></div>
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <div className="grey-space"></div>
          <div className=" uk-width-1-2">
            <ul className=" uk-tab-page-secondary uk-tab uk-margin-remove">
              <li className="uk-active bottombar-title">PARCHEGGI</li>
            </ul>
          </div>
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={() => toggleGenericTable("parkingTable")}
            ></span>
          </div>
        </div>
        <TableFilters
          isOpenNav={isOpenNav}
          dateStart={{ date: null, name: "dateStartEvent" }}
          dateEnd={{ date: null, name: "dateEndEvent" }}
          handleFilterChange={this.handleFilterChange}
          handleSelectChange={this.handleSelectChange}
          resetFilters={this.resetFilters}
          filterStr={filterStr}
          filtrableDateColumns={dateColumns}
          selected={selected}
          refreshData={this.refreshData}
          disabledDataFilter={true}
        ></TableFilters>
        <div className="uk-flex uk-flex-right button-bar">
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.refreshData}
            >
              <span
                uk-icon="icon: refresh"
                uk-tooltip="Refresh tabella Parcheggi"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.downloadExcel}
            >
              <span
                uk-icon="icon: download"
                uk-tooltip="Download Excel"
                className="filterBarIcons borderWhite"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
              onClick={this.resetFilters}
            >
              <span
                uk-icon="icon: acr-filter-remove"
                uk-tooltip="Cancella Filtri"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-flex uk-flex-right button-bar uk-padding-remove-left">
            <PageNumbers
              handleClickDecrease={this.handleClickDecrease}
              handleClickIncrease={this.handleClickIncrease}
              getPageNumber={this.getPageNumber}
              currentPage={pageToDisplay}
              minVal={1}
              pageMax={pageMax}
            />
          </div>
        </div>
        <div className="uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left ">
          <table
            id="eventTable"
            className="uk-table uk-table-divider uk-table-hover"
          >
            <thead>
              <TableHead
                onCheckboxFilterClick={this.onCheckboxFilterClick}
                requestSort={this.requestSort}
                tableColumns={tableColumns}
                sortConfig={sortConfig}
              />
            </thead>
            <tbody>
              {isTableDataLoading ? (
                <tr>
                  <td>
                    <Loading />
                  </td>
                </tr>
              ) : (
                <TableBody list={listOfParkings} tableColumns={tableColumns} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

ParkingTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleGenericTable,
  saveTableFilters,
  resetTableFilters,
  addActivity,
};

const mapStateToProps = (state) => ({
  isOpenNav: isOpenNavTab(state),
  tableColumns: getSavedTableColumns(state, FILTER_TARGET),
  dateColumns: getSavedDateColumns(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
  searchText: getSavedSearchText(state, FILTER_TARGET),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  toggledFilters: getSavedToggledFilters(state, FILTER_TARGET),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParkingTable)
);
