export const INITIAL_STATE_CAMERAS = {
  cameras: [],
  camerasFiltered: [],
  current: null,
  // currentMap: null,
  searchText: "",
  searchType: "",
  bounds: [],
  wsLastCameraAlarm: [],
  wsLastCameraStatus: [],
  camerasPinned: [],
  camerasOnMap: [],
  cameraPositionOnMap: [],
};

export class CameraFeatureCollectionModel {
  totalPages;
  totalElements;
  features;

  constructor() {
    this.totalPages = null;
    this.totalElements = null;
    this.features = null;
  }

  static fromREST(obj) {
    var collection = new CameraFeatureCollectionModel();
    collection.totalPages = obj.totalPages ? obj.totalPages : null;
    collection.totalElements = obj.totalElements ? obj.totalElements : null;
    collection.features = obj.features
      ? CameraFeaturesModel.fromArrayREST(obj.features)
      : []
      ? obj.features
      : null;
    return collection;
  }

  static fromArrayREST(data) {
    return data
      .map(CameraFeatureCollectionModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class CameraFeaturesModel {
  type;
  id;
  geometry;
  properties;

  constructor() {
    this.type = null;
    this.id = null;
    this.geometry = null;
    this.properties = null;
  }

  static fromREST(obj) {
    let features = new CameraFeaturesModel();

    features.type = obj.type ? obj.type : null;
    features.id = obj.id ? obj.id : null;
    features.geometry = obj.geometry
      ? geometryGeoJson.fromREST(obj.geometry)
      : null;
    features.selected = obj.selected ? obj.selected : false;
    features.properties = CCTVWithLastStateAndOpenAlarms.fromREST(
      obj.properties
    )
      ? obj.properties
      : null;

    return features;
  }
  static fromArrayREST(data) {
    return data
      .map(CameraFeaturesModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class CCTVWithLastStateAndOpenAlarms {
  cctvWithLastStateAndOpenAlarms;

  constructor() {
    this.cctvWithLastStateAndOpenAlarms = null;
  }

  static fromREST(obj) {
    var cctvWithLastStateAndOpenAlarms = new CCTVWithLastStateAndOpenAlarms();

    if (obj.cctvWithLastStateAndOpenAlarms.cctv) {
      cctvWithLastStateAndOpenAlarms.cctv = CCTVModel.from(
        obj.cctvWithLastStateAndOpenAlarms.cctv
      );
    }
    if (obj.cctvWithLastStateAndOpenAlarms.last_state) {
      cctvWithLastStateAndOpenAlarms.last_state = CameraLastStateModel.from(
        obj.cctvWithLastStateAndOpenAlarms.last_state
      );
    }
    if (obj.cctvWithLastStateAndOpenAlarms.open_alarms) {
      cctvWithLastStateAndOpenAlarms.open_alarms = obj
        .cctvWithLastStateAndOpenAlarms.open_alarms
        ? obj.cctvWithLastStateAndOpenAlarms.open_alarms
        : [];
    }

    return cctvWithLastStateAndOpenAlarms;
  }

  static fromArrayREST(data) {
    return data
      .map(CCTVWithLastStateAndOpenAlarms.fromREST)
      .filter((item) => item !== null);
  }
}

export class geometryGeoJson {
  type;
  dimensions;

  constructor() {
    this.type = null;
    this.dimensions = null;
  }

  static fromREST(obj) {
    var geometry = new geometryGeoJson();
    geometry.type = obj.type ? obj.type : null;
    geometry.dimensions = obj.dimensions ? obj.dimensions : null;
    return geometry;
  }
}

export class CameraModel {
  cctv;
  last_state;
  open_alarms;

  constructor() {
    this.cctv = null;
    this.last_state = null;
    this.open_alarms = null;
  }

  static from(obj) {
    let camera = new CameraModel();
    camera.cctv = CCTVModel.from(obj.cctv) ? obj.cctv : null;
    camera.last_state = obj.last_state
      ? CameraLastStateModel.from(obj.last_state)
      : null;
    camera.open_alarms = obj.open_alarms ? obj.open_alarms : [];
    return camera;
  }
  static fromArray(data) {
    return data.map(CameraModel.from).filter((item) => item !== null);
  }
}

export class CCTVModel {
  id;
  externalCameraId;
  name;
  description;
  latitude;
  longitude;
  group;
  type;
  deviceId;
  hardwareId;
  recorderId;
  uri;

  constructor() {
    this.id = null;
    this.externalCameraId = null;
    this.name = null;
    this.description = null;
    this.latitude = null;
    this.longitude = null;
    this.group = null;
    this.type = null;
    this.deviceId = null;
    this.hardwareId = null;
    this.recorderId = null;
    this.uri = null;
  }

  static from(obj) {
    let cctv = new CCTVModel();
    if (obj && obj.id) cctv.id = obj.id;
    if (obj && obj.externalCameraId)
      cctv.externalCameraId = obj.externalCameraId;
    if (obj && obj.name) cctv.name = obj.name;
    if (obj && obj.description) cctv.description = obj.description;
    if (obj && obj.latitude) cctv.latitude = obj.latitude;
    if (obj && obj.longitude) cctv.longitude = obj.longitude;
    if (obj && obj.group) cctv.group = obj.group;
    if (obj && obj.type) cctv.type = obj.type;
    if (obj && obj.deviceId) cctv.deviceId = obj.deviceId;
    if (obj && obj.hardwareId) cctv.hardwareId = obj.hardwareId;
    if (obj && obj.recorderId) cctv.recorderId = obj.recorderId;
    if (obj && obj.uri) cctv.uri = obj.uri;
    return cctv;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(CCTVModel.from).filter((item) => item !== null);
  }
}

export class CameraLastStateModel {
  cameraId;
  deviceId;
  diagnosticState;
  timestamp;

  constructor() {
    this.cameraId = null;
    this.deviceId = null;
    this.diagnosticState = null;
    this.timestamp = null;
  }

  static from(obj) {
    let cameraLastState = new CameraLastStateModel();
    if (obj && obj.cameraId) cameraLastState.cameraId = obj.cameraId;
    if (obj && obj.deviceId) cameraLastState.deviceId = obj.deviceId;
    if (obj && obj.diagnosticState)
      cameraLastState.diagnosticState = obj.diagnosticState;
    if (obj && obj.timestamp) cameraLastState.timestamp = obj.timestamp;

    return cameraLastState;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(CameraLastStateModel.from).filter((item) => item !== null);
  }
}

export class AlarmModel {
  cameraId;
  alarmCode;
  description;
  message;
  status;
  priority;
  lastUpdateTime;
  openTime;
  deviceId;
  source;
  type;

  constructor() {
    this.cameraId = null;
    this.alarmCode = null;
    this.description = null;
    this.message = null;
    this.status = null;
    this.priority = null;
    this.lastUpdateTime = null;
    this.openTime = null;
    this.deviceId = null;
    this.source = null;
    this.type = null;
  }

  static from(obj) {
    let alarm = new AlarmModel();
    if (obj && obj.cameraId) alarm.cameraId = obj.cameraId;
    if (obj && obj.alarmCode) alarm.alarmCode = obj.alarmCode;
    if (obj && obj.description) alarm.description = obj.description;
    if (obj && obj.message) alarm.message = obj.message;
    if (obj && obj.status) alarm.status = obj.status;
    if (obj && obj.priority) alarm.priority = obj.priority;
    if (obj && obj.lastUpdateTime) alarm.lastUpdateTime = obj.lastUpdateTime;
    if (obj && obj.openTime) alarm.openTime = obj.openTime;
    if (obj && obj.deviceId) alarm.deviceId = obj.deviceId;
    if (obj && obj.source) alarm.source = obj.source;
    if (obj && obj.type) alarm.type = obj.type;
    return alarm;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(AlarmModel.from).filter((item) => item !== null);
  }
}
