import { CompositeLayer } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
import Supercluster from "supercluster";
import alarm_icon from "assets/icon/installations/alarm_icon.svg";

function getIconName(size) {
  if (size === 0) {
    return "";
  }
  if (size < 10) {
    return `marker-${size}`;
  }
  if (size < 300) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  return "marker-300";
}

class IconClusterCameraLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: 18,
        radius: 18,
        map: (props) => {
          return {
            severity:
              props &&
              props.cctvWithLastStateAndOpenAlarms &&
              props.cctvWithLastStateAndOpenAlarms.last_state &&
              props.cctvWithLastStateAndOpenAlarms.last_state.diagnosticState,
            open_alarms:
              props &&
              props.cctvWithLastStateAndOpenAlarms &&
              props.cctvWithLastStateAndOpenAlarms.open_alarms,
          };
        },
        reduce: (acc, prs) => {
          if (acc.severity === "NO_INFO" || prs.severity === "NO_INFO") {
            acc.severity = "NO_INFO";
          } else if (
            acc.severity === "DISCONNESSO" ||
            prs.severity === "DISCONNESSO"
          ) {
            acc.severity = "DISCONNESSO";
          } else if (acc.severity === "AVARIA" || prs.severity === "AVARIA") {
            acc.severity = "AVARIA";
          } else {
            acc.severity = "FUNZIONANTE";
          }

          let alarmBelongingToCamera = acc.open_alarms;
          if (prs && prs.open_alarms && prs.open_alarms.length > 0) {
            prs.open_alarms.forEach((alarm) => {
              let newIndex = alarmBelongingToCamera.findIndex(
                (x) => x === alarm
              );
              if (newIndex === -1) {
                alarmBelongingToCamera = alarmBelongingToCamera.concat(
                  prs.open_alarms
                );
              }
            });
            acc.open_alarms = alarmBelongingToCamera;
          }
        },
      });
      index.load(
        props.data.map((d) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d.properties,
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      let cluster = this.state.index.getClusters([-180, -85, 180, 85], z);
      this.setState({
        data: cluster,
        z,
      });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== "hover") {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    const { iconAtlas, iconMapping, sizeScale } = this.props;
    const ICON_CAMERA_MAPPING = {
      marker: {
        x: 0,
        y: 0,
        width: 49,
        height: 49,
        anchorY: 30,
        anchorX: 0,
      },
    };
    const ICON_ALARM_MAPPING = {
      marker: {
        x: 0,
        y: 0,
        width: 49,
        height: 49,
        anchorY: 50,
        anchorX: -10,
      },
    };
    let activeCamera = [];
    let disabledCamera = [];
    let failureCamera = [];
    let noInfoCamera = [];

    let activeAlarm = [];

    activeCamera = data.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.cctvWithLastStateAndOpenAlarms &&
          item.properties.cctvWithLastStateAndOpenAlarms.last_state &&
          item.properties.cctvWithLastStateAndOpenAlarms.last_state
            .diagnosticState === "FUNZIONANTE") ||
        (item && item.properties && item.properties.severity === "FUNZIONANTE")
    );

    disabledCamera = data.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.cctvWithLastStateAndOpenAlarms &&
          item.properties.cctvWithLastStateAndOpenAlarms.last_state &&
          item.properties.cctvWithLastStateAndOpenAlarms.last_state
            .diagnosticState === "DISCONNESSO") ||
        (item && item.properties && item.properties.severity === "DISCONNESSO")
    );

    failureCamera = data.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.cctvWithLastStateAndOpenAlarms &&
          item.properties.cctvWithLastStateAndOpenAlarms.last_state &&
          item.properties.cctvWithLastStateAndOpenAlarms.last_state
            .diagnosticState === "AVARIA") ||
        (item && item.properties && item.properties.severity === "AVARIA")
    );

    noInfoCamera = data.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.cctvWithLastStateAndOpenAlarms &&
          item.properties.cctvWithLastStateAndOpenAlarms.last_state &&
          item.properties.cctvWithLastStateAndOpenAlarms.last_state
            .diagnosticState === "NO_INFO") ||
        (item && item.properties && item.properties.severity === "NO_INFO")
    );

    data.forEach((item) => {
      const alarmData =
        (item &&
          item.properties &&
          item.properties.cctvWithLastStateAndOpenAlarms &&
          item.properties.cctvWithLastStateAndOpenAlarms.open_alarms &&
          item.properties.cctvWithLastStateAndOpenAlarms.open_alarms.length >
            0) ||
        (item &&
          item.properties &&
          item.properties.open_alarms &&
          item.properties.open_alarms.length > 0);

      if (alarmData) {
        activeAlarm.push(item);
      }
    });

    return [
      new IconLayer(
        this.getSubLayerProps({
          id: "camera-icon-active",
          data: activeCamera,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: require("assets/icon/installations/webcams-states/camera_green_filled.svg"),
          iconMapping: ICON_CAMERA_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "camera-icon-disabled",
          data: disabledCamera,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: require("assets/icon/installations/webcams-states/camera_red_filled.svg"),
          iconMapping: ICON_CAMERA_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),

      new IconLayer(
        this.getSubLayerProps({
          id: "camera-icon-noSignal",
          data: failureCamera,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: require("assets/icon/installations/webcams-states/camera_yellow_filled.svg"),
          iconMapping: ICON_CAMERA_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "camera-icon-noInfo",
          data: noInfoCamera,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: require("assets/icon/installations/webcams-states/camera_white_filled.svg"),
          iconMapping: ICON_CAMERA_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "number-icon-cam",
          data,
          iconAtlas,
          iconMapping,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) =>
            getIconName(d.properties.cluster ? d.properties.point_count : 1),
          getSize: (d) => 8,
        })
      ),

      new IconLayer(
        this.getSubLayerProps({
          id: "alarm-icon",
          data: activeAlarm,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: alarm_icon,
          iconMapping: ICON_ALARM_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 10,
        })
      ),
    ];
  }
}

IconClusterCameraLayer.layerName = "IconClusterCameraLayer";

export default IconClusterCameraLayer;
