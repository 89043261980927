export const InstallationsMenuActions = {
  //STATION
  SET_STATION_VISIBILITY: "UI/INSTALLATIONS_MENU/SET_STATION_VISIBILITY",
  //PARKING
  SET_PARKING_VISIBILITY: "UI/INSTALLATIONS_MENU/SET_PARKING_VISIBILITY",
  //CAMERA
  SET_CAMERAS_MOBILITY_VISIBILITY:
    "UI/INSTALLATION_MENU/SET_CAMERAS_MOBILITY_VISIBILITY",
  SET_ALL_CAMERAS_VISIBILITY: "UI/INSTALLATION_MENU/SET_ALL_CAMERAS_VISIBILITY",
  TOGGLE_ALARM_TABLE: "UI/INSTALLATIONS_MENU/TOGGLE_ALARM_TABLE",
  //PMV
  SET_PMV_VISIBILITY: "UI/INSTALLATIONS_MENU/SET_PMV_VISIBILITY",
  SET_AESYS_PANEL_VISIBILITY:
    "UI/INSTALLATIONS_MENU/SET_AESYS_PANEL_VISIBILITY",
  SET_SOLARI_PANEL_VISIBILITY:
    "UI/INSTALLATIONS_MENU/SET_SOLARI_PANEL_VISIBILITY",
  SET_FUTURIT_PANEL_VISIBILITY:
    "UI/INSTALLATIONS_MENU/SET_FUTURIT_PANEL_VISIBILITY",
  SET_VISUALLAB_PANEL_VISIBILITY:
    "UI/INSTALLATIONS_MENU/SET_VISUALLAB_PANEL_VISIBILITY",
  TOGGLE_MGS_HISTORY_TABLE_OPEN:
    "UI/INSTALLATIONS_MENU/TOGGLE_MGS_HISTORY_TABLE_OPEN",
  //LOADING
  SET_IS_STATION_LOADING: "UI/INSTALLATIONS_MENU/SET_IS_STATION_LOADING",
  SET_IS_PMV_LOADING: "UI/INSTALLATIONS_MENU/SET_IS_PMV_LOADING",
  SET_IS_CCTV_LOADING: "UI/INSTALLATIONS_MENU/SET_IS_CCTV_LOADING",
  SET_IS_PARKING_LOADING: "UI/INSTALLATIONS_MENU/SET_IS_PARKING_LOADING",
  SET_SFHERA_PANEL_VISIBILITY: 'UI/INSTALLATIONS_MENU/SET_SFHERA_PANEL_VISIBILITY'
};

//PARKING
export const setParkingVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_PARKING_VISIBILITY,
    payload: { visible },
  });
};

//STATION
export const setStationVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_STATION_VISIBILITY,
    payload: { visible },
  });
};

//PMV
export const setPmvVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_PMV_VISIBILITY,
    payload: { visible },
  });
};

export const setAesysPanelVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_AESYS_PANEL_VISIBILITY,
    payload: { visible },
  });
};

export const setSolariPanelVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_SOLARI_PANEL_VISIBILITY,
    payload: { visible },
  });
};

export const setFuturitPanelVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_FUTURIT_PANEL_VISIBILITY,
    payload: { visible },
  });
};

export const setVisuallabPanelVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_VISUALLAB_PANEL_VISIBILITY,
    payload: { visible },
  });
};

export const setSpheraPanelVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_SFHERA_PANEL_VISIBILITY,
    payload: { visible },
  });
};


export const togglePmvHistoryTable = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.TOGGLE_MGS_HISTORY_TABLE_OPEN,
    payload: visible,
  });
};

//CAMERA

export const setAllCamerasVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_ALL_CAMERAS_VISIBILITY,
    payload: { visible },
  });
};

export const setCamerasMobilityVisibility = (visible) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_CAMERAS_MOBILITY_VISIBILITY,
    payload: { visible },
  });
};

export const toggleAlarmTable = () => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.TOGGLE_ALARM_TABLE,
    payload: null,
  });
};

//LOADING
export const setIsStationLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_IS_STATION_LOADING,
    payload: {
      isLoading,
    },
  });
};
export const setIsPmvLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_IS_PMV_LOADING,
    payload: {
      isLoading,
    },
  });
};
export const setIsCctvLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_IS_CCTV_LOADING,
    payload: {
      isLoading,
    },
  });
};
export const setIsParkingLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: InstallationsMenuActions.SET_IS_PARKING_LOADING,
    payload: {
      isLoading,
    },
  });
};
