import { CompositeLayer } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
import Supercluster from "supercluster";
import station_cluster_grey from "assets/icon/multitab-icns/Tab_StazioniMisuraGreyCluster.svg";
import station_cluster_red from "assets/icon/multitab-icns/Tab_StazioniMisuraRedCluster.svg";
import station_cluster_green from "assets/icon/multitab-icns/Tab_StazioniMisuraGreenCluster.svg";
import { getWorseStationSensorStateDetail } from "utils/utils";

function getIconName(size) {
  if (size === 0) {
    return "";
  }
  if (size < 10) {
    return `marker-${size}`;
  }
  if (size < 300) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  return "marker-300";
}

// function getIconSize(size) {
//   return Math.min(100, size) / 100 + 1;
// }

class IconClusterStationLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: 18,
        minPoints: 2,
        radius: 21,
        map: (props) => ({
          severity: getWorseStationSensorStateDetail(props),
        }),
        reduce: (acc, prs) => {
          if (acc.severity === "DOWN" || prs.severity === "DOWN") {
            acc.severity = "DOWN";
          } else if (acc.severity === "NO_INFO" || prs.severity === "NO_INFO") {
            acc.severity = "NO_INFO";
          } else {
            acc.severity = "UP";
          }
        },
      });
      index.load(
        props.data.map((d) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d,
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      const { index } = this.state;
      let cluster = index.getClusters([-180, -85, 180, 85], z);
      this.setState({
        data: cluster,
        z,
      });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== "hover") {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    const { iconAtlas, iconMapping, sizeScale } = this.props;

    const ICON_PARKING_MAPPING = {
      marker: {
        x: 0,
        y: 0,
        width: 800,
        height: 800,
        mask: false,
        anchorY: 500,
        anchorX: 100,
      },
    };
    let upStationsArr = [];
    let downStationsArr = [];
    let noInfoStationsArr = [];

    upStationsArr = data.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.severity &&
          item.properties.severity === "UP") ||
        (item &&
          item.properties &&
          item.properties.sensors &&
          getWorseStationSensorStateDetail(item.properties) === "UP")
    );

    downStationsArr = data.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.severity &&
          item.properties.severity === "DOWN") ||
        (item &&
          item.properties &&
          item.properties.sensors &&
          getWorseStationSensorStateDetail(item.properties) === "DOWN")
    );
    noInfoStationsArr = data.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.severity &&
          item.properties.severity === "NO_INFO") ||
        (item &&
          item.properties &&
          item.properties.sensors &&
          getWorseStationSensorStateDetail(item.properties) === "NO_INFO")
    );

    let layers = [
      new IconLayer(
        this.getSubLayerProps({
          id: "station-icon-a",
          data: upStationsArr,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: station_cluster_green,
          iconMapping: ICON_PARKING_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "station-icon-b",
          data: downStationsArr,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: station_cluster_red,
          iconMapping: ICON_PARKING_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "station-icon-c",
          data: noInfoStationsArr,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          iconAtlas: station_cluster_grey,
          iconMapping: ICON_PARKING_MAPPING,
          getIcon: (data) => "marker",
          getSize: (d) => 8,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: "number-icon",
          data,
          iconAtlas,
          iconMapping,
          sizeScale,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) =>
            getIconName(d.properties.cluster ? d.properties.point_count : 1),
          getSize: (d) => 8,
        })
      ),
    ];
    return layers;
  }
}

IconClusterStationLayer.layerName = "IconClusterStationLayer";

export default IconClusterStationLayer;
