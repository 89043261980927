export const getAttributes = (obj) => {
  let ret = null;
  //TRAFFIC, ACTIVITY, CONDITIONS, EQUIPMENT SYSTEM OR FAULT, OBSTRUCTION, OPERATOR ACTION
  if (obj.situationRecordType) {
    switch (obj.situationRecordType && obj.situationRecordType.toString()) {
      case "ABNORMAL_TRAFFIC":
        ret = {
          relativeTrafficFlow: obj.traffic.abnormalTraffic.relativeTrafficFlow,
          trafficTrendType: obj.traffic.abnormalTraffic.trafficTrendType,
        };
        break;
      case "ACCIDENT":
        ret = {
          accidentCause: obj.traffic.accident.accidentCause,
          totalNumberOfPeopleInvolved:
            obj.traffic.accident.totalNumberOfPeopleInvolved,
          totalNumberOfVehiclesInvolved:
            obj.traffic.accident.totalNumberOfVehiclesInvolved,
        };
        break;
      case "ANIMAL_PRESENCE_OBSTRUCTION":
        ret = {
          numberOfObstructions: obj.traffic.obstruction.numberOfObstructions,
          alive: obj.traffic.obstruction.animalPresenceObstruction.alive,
        };
        break;
      case "POOR_ENVIRONMENT_CONDITION":
        ret = {
          pollutantConcentration:
            obj.traffic.conditions.poorEnvironmentConditions.pollution
              .pollutantConcentration,
          pollutantType:
            obj.traffic.conditions.poorEnvironmentConditions.pollution
              .pollutantType,
          relativeHumidity:
            obj.traffic.conditions.poorEnvironmentConditions.humidity
              .relativeHumidity,
          precipitationIntensity:
            obj.traffic.conditions.poorEnvironmentConditions.precipitationDetail
              .precipitationIntensity,
          precipitationType:
            obj.traffic.conditions.poorEnvironmentConditions.precipitationDetail
              .precipitationType,
          airTemperature:
            obj.traffic.conditions.poorEnvironmentConditions.temperature
              .airTemperature,
          maximumTemperature:
            obj.traffic.conditions.poorEnvironmentConditions.temperature
              .maximumTemperature,
          minimumTemperature:
            obj.traffic.conditions.poorEnvironmentConditions.temperature
              .minimumTemperature,
          minimunVisibilityDistance:
            obj.traffic.conditions.poorEnvironmentConditions.visibility
              .minimunVisibilityDistance,
          directionCompass:
            obj.traffic.conditions.poorEnvironmentConditions.wind
              .directionCompass,
          maximumWindSpeed:
            obj.traffic.conditions.poorEnvironmentConditions.wind
              .maximumWindSpeed,
          windMeasurementHeight:
            obj.traffic.conditions.poorEnvironmentConditions.wind
              .windMeasurementHeight,
          windSpeed:
            obj.traffic.conditions.poorEnvironmentConditions.wind.windSpeed,
          drivingConditionType:
            obj.traffic.abnormalTraffic.drivingConditionType,
        };
        break;
      case "AUTHORITY_OPERATION":
        ret = {};
        break;
      case "PUBLIC_EVENT":
        ret = {};
        break;

      case "WEATHER_RELATED_ROAD_CONDITIONS":
        ret = {
          drivingConditionType: obj.traffic.conditions.drivingConditionType,
        };
        break;
      case "EQUIPMENT_OR_SYSTEM_FAULT":
        ret = {
          faultyEquipmentOrSystemType:
            obj.traffic.equipmentOrSystemFault.faultyEquipmentOrSystemType,
        };
        break;
      case "GENERAL_OBSTRUCTION":
        ret = {
          numberOfObstructions: obj.traffic.obstruction.numberOfObstructions,
        };
        break;
      case "ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT":
        ret = {
          actionOrigin: obj.operatorAction.actionOrigin,
          actionPlanIdentifier: obj.operatorAction.actionPlanIdentifier,
          applicableForTrafficDirection:
            obj.operatorAction.networkManagement.applicableForTrafficDirection,
          applicableForTrafficType:
            obj.operatorAction.networkManagement.applicableForTrafficType,
          complianceOption:
            obj.operatorAction.networkManagement.complianceOption,
        };
        break;
      case "CONSTRUCTION_WORKS":
        ret = {
          underTraffic: obj.operatorAction.roadworks.underTraffic,
          actionPlanIdentifier: obj.operatorAction.actionPlanIdentifier,
          actionOrigin: obj.operatorAction.actionOrigin,
          roadworksScale: obj.operatorAction.roadworks.roadworksScale,
          roadworksDuration: obj.operatorAction.roadworks.roadworksDuration,
          urgentRoadworks: obj.operatorAction.roadworks.urgentRoadworks,
        };
        break;
      case "VEHICLE_OBSTRUCTION":
        ret = {
          numberOfObstructions: obj.traffic.obstruction.numberOfObstructions,
        };
        break;
      case "POOR_ENVIRONMENT_CONDITIONS":
        ret = {
          minimunVisibilityDistance:
            obj.traffic.conditions.poorEnvironmentConditions.visibility
              .minimunVisibilityDistance,
          precipitationIntensity:
            obj.traffic.conditions.poorEnvironmentConditions.precipitationDetail
              .precipitationIntensity,
          maximumWindSpeed:
            obj.traffic.conditions.poorEnvironmentConditions.wind
              .maximumWindSpeed,
          drivingConditionType:
            obj.traffic.conditions.poorEnvironmentConditions
              .drivingConditionType,
          pollutantConcentration:
            obj.traffic.conditions.poorEnvironmentConditions.pollution
              .pollutantConcentration,
          pollutantType:
            obj.traffic.conditions.poorEnvironmentConditions.pollution
              .pollutantType,
          directionCompass:
            obj.traffic.conditions.poorEnvironmentConditions.wind
              .directionCompass,
          windSpeed:
            obj.traffic.conditions.poorEnvironmentConditions.wind.windSpeed,
          windMeasurementHeight:
            obj.traffic.conditions.poorEnvironmentConditions.wind
              .windMeasurementHeight,
          airTemperature:
            obj.traffic.conditions.poorEnvironmentConditions.temperature
              .airTemperature,
          minimumTemperature:
            obj.traffic.conditions.poorEnvironmentConditions.temperature
              .minimumTemperature,
          precipitationType:
            obj.traffic.conditions.poorEnvironmentConditions.precipitationDetail
              .precipitationType,
          relativeHumidity:
            obj.traffic.conditions.poorEnvironmentConditions.humidity
              .relativeHumidity,
          maximumTemperature:
            obj.traffic.conditions.poorEnvironmentConditions.temperature
              .maximumTemperature,
        };
        break;
      default:
        break;
    }
  }
  return ret;
};
