import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as moment from "moment";
import {
  tap,
  catchError,
  take,
  finalize,
  concatMap,
  mergeMap,
} from "rxjs/operators";
import { of, merge } from "rxjs";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import "./style.less";
//ACTIONS
import { SituationRecord } from "reducers/events/models/situation-record";
import {
  getCurrentEvent,
  getCurrentEventGroup,
  getNewEvent,
  getAllEvents,
  getSelectModal,
  getUsername,
  getUserRoles,
} from "store";
import { setNewEvent } from "reducers/modal/modal.actions";
import { togglePopup } from "reducers/ui/modal/modal.actions";
import {
  updateEvents,
  setCurrentEventGroup,
} from "reducers/events/events.actions";
import { addActivity } from "reducers/user/user.actions";
import { setEventsList } from "reducers/events/events.actions";
import { UserActivityModel } from "reducers/user/user.model";
//UTILS
import { getTypeTranslation } from "utils/util-events";
import { SEVERITY_ENUM } from "utils/utils";
import * as FilterUtils from "utils/filter-utils";
import { filterBounds } from "components/layout/map/load-map/load-map";
//COMPONENTS
import PracticeManagementHeader from "components/layout/modal/event/practice-management/practice-management-header";
import PracticeManagementBody from "components/layout/modal/event/practice-management//practice-management-body";

class PracticeManagement extends Component {
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {
      associateEventsDetails: [],
      selectedOption: "",
      maxPracticeEvent: "",
      isUpdated: false,
      changeCheckbox: true,
      selectedOptionChecked: true,
      causeEvent: "",
      sortConfig: { key: "", direction: "" },
    };
  }

  componentDidMount = () => {
    const { currentEvent, currentEventGroup } = this.props;
    this.apiService = createApiService(this.context);

    if (currentEvent && currentEvent.situationId) {
      this.getAssociatedEvents();
    } else if (currentEventGroup) {
      this.getAssociatedEventsGroup();
    }
  };

  getAssociatedEventsGroup = () => {
    const { currentEventGroup, listEvent } = this.props;
    let arrEv = [];
    let routingEv = [];
    let maxEvent = 0;
    let indexLoop = 0;
    let practiceIndex = 0;

    currentEventGroup.forEach((ev) => {
      arrEv.push({
        practiceId: ev.situationId,
      });
    });
    of(...arrEv)
      .pipe(
        concatMap((event) =>
          this.apiService.getEventsAssociatedToSituationId(event.practiceId)
        ),
        tap((x) => {
          if (x && !x.error) {
            x.situationRecordsIds.forEach((item) => {
              routingEv.push(item);
            });
            if (maxEvent < x.situationRecordsIds.length) {
              maxEvent = x.situationRecordsIds.length;
              practiceIndex = indexLoop;
            }
            indexLoop = indexLoop + 1;
          } else {
            console.log("error", x);
          }
        }),
        finalize(() => {
          let uniqueNames = Array.from(new Set(routingEv));
          let evDetails = [];
          let findEventDetails;
          uniqueNames.forEach((assEvent) => {
            findEventDetails = listEvent.find(
              (event) => assEvent === event.situationRecordId
            );
            if (findEventDetails) {
              evDetails.push(findEventDetails);
            }
          });
          let assEv = evDetails.map((event) => ({
            ...event,
            isChecked: true,
            causeEvent: false,
          }));
          this.setState({
            associateEventsDetails: assEv,
            maxPracticeEvent: arrEv[practiceIndex],
            selectedOption: assEv[0].situationRecordId,
          });
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  getAssociatedEvents = () => {
    const { currentEvent, listEvent, setNewEvent } = this.props;

    this.subscriptions.push(
      this.apiService
        .getEventsAssociatedToSituationId(currentEvent.situationId)
        .pipe(
          tap((data) => {
            let evDetails = [];
            let findEventDetails;
            data.situationRecordsIds.forEach((assEvent) => {
              findEventDetails = listEvent.find(
                (event) => assEvent === event.situationRecordId
              );
              if (findEventDetails) {
                evDetails.push(findEventDetails);
              }
            });

            let assEv = evDetails.map((event) => ({
              ...event,
              isChecked: true,
              causeEvent: false,
            }));
            let causeOption = assEv.find(
              (item) => item.situationRecordId === item.cause.situationRecordId
            );
            setNewEvent("associatedEvents", assEv);
            this.setState({
              associateEventsDetails: assEv,
              selectedOption: causeOption.situationRecordId,
            });
          }),
          catchError((error) => {
            console.error(error);

            return of(error);
          })
        )
        .subscribe()
    );
  };

  handleCheckboxChange = (event, row) => {
    let checkedEvents = this.state.associateEventsDetails;
    checkedEvents.forEach((ev) => {
      let checkedBoxes = document.querySelectorAll(
        '.checkBox input[type="checkbox"]:checked'
      );

      if (ev.situationRecordId === row.eventId && checkedBoxes.length >= 1) {
        ev.isChecked = event.target.checked;
      }
    });

    UIkit.notification.closeAll();
    const string =
      "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Scegliere l'evento causa e confermare la pratica configurata premendo su << Aggiorna Pratica >>.</label>";
    this.notif(string, "bottom-center");
    this.setState({
      associateEventsDetails: checkedEvents,
      changeCheckbox: event.target.checked,
    });
  };

  handleEventCauseChange = (event) => {
    let events = this.state.associateEventsDetails;
    events = events.map((causeEvent) => {
      if (causeEvent.situationRecordId === event.target.value) {
        causeEvent.causeEvent = true;
      } else {
        causeEvent.causeEvent = false;
      }
      return causeEvent;
    });
    let selectedCauseEvent = events.filter(
      (item) => item.cause.situationRecordId === item.situationRecordId
    );
    this.setState({
      selectedOption: selectedCauseEvent,
    });

    let checkIfCauseAlreadypresent = events.filter(
      (item) =>
        event.target.value === item.situationRecordId &&
        item.cause.situationRecordId !== item.situationRecordId
    );

    if (
      (checkIfCauseAlreadypresent.length === 0 &&
        selectedCauseEvent.length === 1) ||
      (checkIfCauseAlreadypresent.length === 0 &&
        selectedCauseEvent.length > 1 &&
        event.target.value === selectedCauseEvent[0].situationRecordId)
    ) {
      this.setState({
        selectedOption: event.target.value,
        selectedOptionChecked: true,
      });
    } else {
      this.setState({
        selectedOption: event.target.value,
        selectedOptionChecked: false,
      });
    }

    UIkit.notification.closeAll();
    const string2 =
      "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Scegliere l'evento causa e confermare la pratica configurata premendo su << Aggiorna Pratica >>.</label>";
    this.notif(string2, "bottom-center");
  };

  notif = (msg, pos) => {
    UIkit.notification({
      message: msg,
      status: "primary",
      pos: pos,
      timeout: 5000,
    });
  };

  updatePractice = () => {
    const { associateEventsDetails, maxPracticeEvent } = this.state;
    const {
      setNewEvent,
      updateEvents,
      currentEventGroup,
      currentEvent,
      setCurrentEventGroup,
    } = this.props;

    let arrPr = [];
    let causeSave;
    let practice = maxPracticeEvent
      ? maxPracticeEvent.practiceId
      : currentEvent
      ? currentEvent.situationId
      : null;

    if (practice) {
      associateEventsDetails.forEach((item) => {
        if (item.isChecked) {
          item.situationId = practice;
          arrPr.push(item);
        }
      });
      setNewEvent("associatedEvents", arrPr);

      let selectedCause = document.querySelector('input[name="check"]:checked')
        .value;
      let cause = associateEventsDetails.find(
        (assEvent) =>
          selectedCause === assEvent.situationRecordId &&
          assEvent.situationRecordId === assEvent.cause.situationRecordId
      );
      let checkEventCause = [];
      if (cause) {
        cause = checkEventCause;
      } else {
        checkEventCause = associateEventsDetails.find(
          (item) => item.situationRecordId === selectedCause
        );
      }
      causeSave = associateEventsDetails.find((item) => {
        return item.situationRecordId === selectedCause;
      });
      this.setState({ causeEvent: causeSave });
      let uncheckedEvents = document.querySelectorAll(
        '.checkBox input[type="checkbox"]:not(:checked)'
      );
      let checkIfNotCauseEvent = false;

      let deleteArrEv = [];
      if (uncheckedEvents.length !== 0) {
        uncheckedEvents.forEach((item) => {
          deleteArrEv.push(item.value);
        });
        checkIfNotCauseEvent = deleteArrEv.find(
          (item) =>
            item === causeSave.situationRecordId &&
            item === causeSave.cause.situationRecordId
        );
      }
      if (causeSave && !checkIfNotCauseEvent) {
        arrPr.forEach((item) => {
          item.cause.situationRecordType = causeSave.situationRecordType;
          item.cause.situationRecordId = causeSave.situationRecordId;
          item.cause.situationRecordVersion = causeSave.situationRecordVersion;
        });
        if (currentEventGroup.length > 0) {
          if (checkEventCause) {
            this.setState({
              associateEventsDetails: arrPr,
            });
            let newArrPr = arrPr.map((event) => {
              return {
                ...event,
                validity: {
                  ...event.validity,
                  validityStatus: "DEFINED_BY_VALIDITY_TIME_SPEC",
                },
              };
            });

            let errorOccurs = false;
            this.subscriptions.push(
              of(...newArrPr)
                .pipe(
                  concatMap((SaveSituationRecord) =>
                    this.apiService.updateSingleEvent(SaveSituationRecord)
                  ),
                  tap((x) => {
                    let situation = SituationRecord.from(x);
                    updateEvents(situation);
                  }),
                  concatMap((data) => {
                    return this.postEventModifyActivity(data);
                  }),
                  finalize(() => {
                    this.setState({ isUpdated: true });
                    UIkit.notification.closeAll();
                    if (!errorOccurs) {
                      const string =
                        "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Pratica aggiornata.</label>";
                      this.notif(string, "bottom-center");
                    } else {
                      const string =
                        "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Errore aggiornamento pratica.</label>";
                      this.notif(string, "bottom-center");
                    }
                  }),
                  finalize(() => this.checkNumberElements()),
                  catchError((error) => {
                    console.error(error);
                    errorOccurs = true;
                    return of(error);
                  })
                )
                .subscribe()
            );
          } else {
            UIkit.notification.closeAll();
            const string =
              "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Aggiungere l'evento causa ad un evento selezionato e premere su << Aggiorna Pratica >></label>";
            this.notif(string, "bottom-center");
          }
        } else {
          let associatedEvents = this.state.associateEventsDetails;
          let checkEventCause = associatedEvents.find(
            (assEvent) => selectedCause === assEvent.situationRecordId
          );
          let eventTodelete = [];
          if (checkEventCause) {
            eventTodelete = associatedEvents
              .filter((event) => deleteArrEv.includes(event.situationRecordId))
              .map((event) => {
                return {
                  ...event,
                  validity: {
                    ...event.validity,
                    validityStatus: "DEFINED_BY_VALIDITY_TIME_SPEC",
                  },
                };
              });
            this.associateEventsToNewPractice(eventTodelete);
          } else {
            UIkit.notification.closeAll();
            const string =
              "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Scegliere l'evento causa e confermare la pratica configurata premendo su << Aggiorna Pratica >>.</label>";
            this.notif(string, "bottom-center");
          }
        }
        setCurrentEventGroup([]);
      } else {
        UIkit.notification.closeAll();
        const string2 =
          "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Aggiungere l'evento causa ad un evento selezionato e premere su << Aggiorna Pratica >></label>";
        this.notif(string2, "bottom-center");
      }
    }
  };

  associateEventsToNewPractice = (eventsToDeleteFromPractice) => {
    const { setNewEvent, updateEvents, currentEvent } = this.props;
    const { associateEventsDetails } = this.state;

    let situationId =
      "practice-" +
      new Date().valueOf().toString() +
      "-" +
      (Math.floor(Math.random() * (100000000 - 1 + 1)) + 1).toString();
    let assEvToNewPr = [];
    let objEvent = {
      situationId: situationId,
      headerInformation: {
        areaOfInterest: "CONTINENT_WIDE",
        confidentiality: "RESTRICTED_TO_AUTHORITIES",
        informationStatus: "REAL",
        urgency: "NORMAL_URGENCY",
      },
      situationVersion: "",
      situationVersionTime: new Date().toISOString(),
      situationRecordsIds: eventsToDeleteFromPractice.situationRecordId,
      situationRecords: [],
    };
    let causeSave;
    let arrPr = [];
    let uncheckedEvents = document.querySelectorAll(
      '.checkBox input[type="checkbox"]:not(:checked)'
    );
    let deleteArrEv = [];
    if (uncheckedEvents.length !== 0) {
      uncheckedEvents.forEach((item) => {
        deleteArrEv.push(item.value);
      });
    }
    arrPr = associateEventsDetails.filter(
      (event) => !deleteArrEv.includes(event.situationRecordId)
    );
    let selectedCause = document.querySelector('input[name="check"]:checked')
      .value;

    causeSave = associateEventsDetails.find((item) => {
      return item.situationRecordId === selectedCause;
    });

    let causeEventsArray = [];
    causeEventsArray = arrPr.map((event) => {
      return {
        ...event,
        situationId:
          currentEvent && currentEvent.situationId
            ? currentEvent.situationId
            : causeSave.situationId,
        cause: {
          situationRecordType: causeSave.situationRecordType,
          situationRecordId: causeSave.situationRecordId,
          situationRecordVersion: causeSave.situationRecordVersion,
        },
        validity: {
          ...event.validity,
          validityStatus: "DEFINED_BY_VALIDITY_TIME_SPEC",
        },
      };
    });
    setNewEvent("associatedEvents", causeEventsArray);

    if (eventsToDeleteFromPractice) {
      this.subscriptions.push(
        this.apiService
          .addSituation(objEvent)
          .pipe(
            take(1),
            tap((x) => {
              let situationsToDelete = SituationRecord.from(x);
              setNewEvent("iDPractice", situationsToDelete.situationId);
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
    let arrEvents = [];
    eventsToDeleteFromPractice = eventsToDeleteFromPractice.map((ev) => {
      ev.situationId = situationId;
      arrEvents.push(ev);
      return ev;
    });

    let arrConcat = [...arrEvents, ...causeEventsArray];
    let errorOccurs = false;

    this.subscriptions.push(
      of(...arrConcat)
        .pipe(
          concatMap((event) => this.apiService.updateSingleEvent(event)),
          tap((x) => {
            if (x && !x.error) {
              let situation = SituationRecord.from(x);
              updateEvents(situation);
              if (
                (currentEvent &&
                  currentEvent.situationId === situation.situationId) ||
                causeSave.situationId === situation.situationId
              ) {
                assEvToNewPr.push(situation);
              }
            } else {
              console.log("error", x);
            }
          }),
          concatMap((data) => {
            return this.postEventModifyActivity(data);
          }),
          finalize(() => {
            this.setState({
              associateEventsDetails: assEvToNewPr,
            });
            UIkit.notification.closeAll();
            if (!errorOccurs) {
              const string =
                "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Pratica aggiornata.</label>";
              this.notif(string, "bottom-center");
            } else {
              const string =
                "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Errore aggiornamento pratica.</label>";
              this.notif(string, "bottom-center");
            }
          }),
          finalize(() => this.checkNumberElements()),
          catchError((error) => {
            console.error(error);
            errorOccurs = true;
            return of(error);
          })
        )
        .subscribe()
    );
  };

  postEventModifyActivity = (event) => {
    const { username, addActivity, selectModal, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "SITUATION",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_UPDATED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value:
                'Evento "' +
                getTypeTranslation(event, selectModal) +
                '" con id: ' +
                event.situationRecordId,
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  checkNumberElements = () => {
    let now = null;
    let arrayIntervalsOpen = [];

    return this.apiService
      .getSituationRecords(1, 0, now, ["OPEN", "SCHEDULED"])
      .pipe(
        tap((open) => {
          if (open && !open.error && open.totalElements) {
            let totalInArray = 0;
            for (let i = 0; i <= 1000; i++) {
              if (open.totalElements <= totalInArray) {
                break;
              } else {
                arrayIntervalsOpen.push({
                  limit: 50,
                  offset: i,
                });
                totalInArray += 50;
              }
            }
          }
        }),
        mergeMap(() => merge(this.openEvents(arrayIntervalsOpen, now))),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  openEvents = (arr, now) => {
    const { setEventsList, selectModal } = this.props;

    return of(...arr).pipe(
      concatMap((interval) =>
        this.apiService.getSituationRecords(
          interval.limit,
          interval.offset,
          now,
          ["OPEN", "SCHEDULED"]
        )
      ),
      tap((open) => {
        let openRoadSituations = [];
        if (open && !open.error && open.payload) {
          openRoadSituations = SituationRecord.fromArray(open.payload);
        }
        setEventsList(openRoadSituations, selectModal);
      }),
      finalize(() => {
        this.setState({ isEventsAllLoading: false });
        filterBounds(this);
      }),
      catchError((error) => {
        console.error(error);
        return of(error);
      })
    );
  };

  closePractice = () => {
    const { togglePopup } = this.props;
    togglePopup();
  };

  requestToSort = (key) => {
    const { sortConfig } = this.state;

    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    this.setState({ sortConfig: { key: key, direction: direction } });
  };

  toTableColumns = (events) => {
    const { selectModal } = this.props;
    let newList = [];
    events.map((event) => {
      let newEl = {
        eventId: event.situationRecordId,
        eventType:
          event &&
          event.situationRecordType &&
          selectModal &&
          selectModal.types &&
          selectModal.types.find(
            (x) => x.typeName === event.situationRecordType
          ).typeNameIT
            ? selectModal.types.find(
                (x) => x.typeName === event.situationRecordType
              ).typeNameIT
            : "-",
        eventUndertype:
          event &&
          event.situationRecordSubType &&
          selectModal &&
          selectModal.types &&
          selectModal.types
            .find((x) => x.typeName === event.situationRecordType)
            .subTypes.find(
              (y) => y.subTypeName === event.situationRecordSubType
            )
            ? selectModal.types
                .find((x) => x.typeName === event.situationRecordType)
                .subTypes.find(
                  (y) => y.subTypeName === event.situationRecordSubType
                ).subTypeNameIT
            : "-",
        eventOpenTime:
          event &&
          event.validity &&
          event.validity.validityTimeSpecification &&
          event.validity.validityTimeSpecification.overallStartTime
            ? event.validity.validityTimeSpecification.overallStartTime.includes(
                "T"
              )
              ? moment(
                  event.validity.validityTimeSpecification.overallStartTime
                ).format("DD/MM/YY - HH:mm")
              : event.validity.validityTimeSpecification.overallStartTime
            : "-",
        eventClosingTime:
          event &&
          event.validity &&
          event.validity.validityTimeSpecification &&
          event.validity.validityTimeSpecification.overallEndTime
            ? event.validity.validityTimeSpecification.overallEndTime.includes(
                "T"
              )
              ? moment(
                  event.validity.validityTimeSpecification.overallEndTime
                ).format("DD/MM/YY - HH:mm")
              : event.validity.validityTimeSpecification.overallEndTime
            : "-",
        eventSeverity: SEVERITY_ENUM[event.severity],
        eventValidity:
          event && event.validity && event.validity.validityStatus
            ? event.validity.validityStatus
            : "",
        isChecked: event.isChecked,
        causeEvent:
          event && event.cause && event.cause.situationRecordId
            ? event.cause.situationRecordId
            : "-",
      };
      return (newList = [...newList, newEl]);
    });

    return newList;
  };

  render() {
    const { currentEvent, currentEventGroup } = this.props;
    const {
      associateEventsDetails,
      maxPracticeEvent,
      isUpdated,
      sortConfig,
    } = this.state;
    let sortedListOfEvents = [...associateEventsDetails];

    sortedListOfEvents = this.toTableColumns(sortedListOfEvents);

    if (sortConfig.key !== null && sortedListOfEvents.length > 0) {
      sortedListOfEvents = FilterUtils.sortUtil(sortedListOfEvents, sortConfig);
    }

    return (
      <div className="uk-auto uk-overflow-container uk-padding  uk-margin-left container-table-group-event-practice ">
        <form className="uk-form-horizontal small-form uk-flex uk-flex-row uk-flex-middle">
          <div className="uk-width-1-2 uk-flex-right">
            <label className="uk-form-label padding-form-practice">
              ID Pratica
            </label>
            <div className="uk-form-controls">
              <input
                id="id-pratica"
                className="uk-input"
                defaultValue={
                  currentEvent && currentEvent.situationId
                    ? currentEvent.situationId
                    : maxPracticeEvent.practiceId
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="uk-width-1-2 practice-button display-buttons">
            <button
              className="uk-button uk-button-default uk-button-small close-practice margin-buttons"
              onClick={(e) => {
                e.preventDefault();
                this.closePractice();
              }}
              disabled={currentEventGroup.length > 0 ? !isUpdated : ""}
            >
              Chiudi Pratica
            </button>

            <button
              className="uk-button uk-button-primary uk-button-small"
              onClick={(e) => {
                e.preventDefault();
                this.updatePractice(e);
              }}
              disabled={
                currentEventGroup.length > 0
                  ? isUpdated
                  : associateEventsDetails.length !== 1
                  ? this.state.changeCheckbox &&
                    this.state.selectedOptionChecked
                  : true
              }
            >
              {currentEventGroup.length < 1 || associateEventsDetails.length > 1
                ? "Aggiorna Pratica"
                : "Crea Pratica"}
            </button>
          </div>
        </form>
        <table
          id="eventPracticeTable"
          className="uk-table uk-table-divider uk-table-hover margin-table-practice"
        >
          <PracticeManagementHeader requestToSort={this.requestToSort} />
          {sortedListOfEvents ? (
            <PracticeManagementBody
              listOfEvents={sortedListOfEvents}
              associateEventsDetails={associateEventsDetails}
              handleEventCauseChange={this.handleEventCauseChange}
              handleCheckboxChange={this.handleCheckboxChange}
            />
          ) : (
            ""
          )}
        </table>
      </div>
    );
  }

  componentWillUnmount = () => {
    this.subscriptions.forEach((sub$) => sub$.unsubscribe());
  };
}

PracticeManagement.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNewEvent,
  togglePopup,
  updateEvents,
  setCurrentEventGroup,
  addActivity,
  setEventsList,
};

const mapStateToProps = (state) => ({
  currentEvent: getCurrentEvent(state),
  listEvent: getAllEvents(state),
  newEvent: getNewEvent(state),
  selectModal: getSelectModal(state),
  currentEventGroup: getCurrentEventGroup(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PracticeManagement)
);
