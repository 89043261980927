export const NodesActions = {
  SET_LIST_NODES: "GRAPH/SET_LIST_NODES",
  SET_CURRENT_NODE: "GRAPH/SET_CURRENT_NODE",
  SET_CURRENT_NODE_MAP: "GRAPH/SET_CURRENT_NODE_MAP",
  RESET_CURRENT_NODE_MAP: "GRAPH/RESET_CURRENT_NODE_MAP",
};

export const setNodesList = (list) => (dispatch) => {
  dispatch({
    type: NodesActions.SET_LIST_NODES,
    payload: { list },
  });
};

export const setCurrentNode = (current) => (dispatch) => {
  dispatch({
    type: NodesActions.SET_CURRENT_NODE,
    payload: current,
  });
};
export const setCurrentNodeMap = (currentMap) => (dispatch) => {
  dispatch({
    type: NodesActions.SET_CURRENT_NODE_MAP,
    payload: currentMap,
  });
};

export const resetCurrentNodeMap = () => (dispatch) => {
  dispatch({
    type: NodesActions.RESET_CURRENT_NODE_MAP,
  });
};
