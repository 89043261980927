import React from "react";
import { withRouter } from "react-router-dom";
import "./style.less";
//COMPONENTS
import Weather from "./weather";
import Kpi from "./kpi";
import Alert from "./alert";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
//ASSETS
import logo_verona from "assets/images/logo_verona.png";
import por from "assets/images/logo_por.png";

const Topbar = () => (
  <nav className="uk-navbar-container uk-navbar acr-navbar">
    <div className="uk-navbar-left uk-width-1-1">
      <ul className="uk-navbar-nav uk-width-1-1">
        {/* MENU */}
        <li className="acr-navbar-filler"></li>
        {/* WEATHER */}
        <li>
          <ErrorBoundary>
            <Weather />
          </ErrorBoundary>
        </li>
        <li>
          <ErrorBoundary>
            <Kpi />
          </ErrorBoundary>
        </li>
        {/* ALERTS AND EVENTS */}
        <li>
          <ErrorBoundary>
            <Alert />
          </ErrorBoundary>
        </li>
        {/* LOGOS */}
        <li className="uk-width-1-1">
          <div className="uk-flex uk-flex-row uk-flex-right uk-flex-middle logos-container">
            <img
              alt=""
              className="verona-logo margin-right45"
              src={logo_verona}
            />
            <img alt="" className="por-image margin-right45" src={por} />
          </div>
        </li>
      </ul>
    </div>
  </nav>
);

export default withRouter(Topbar);
