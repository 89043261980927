import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class TabHeader extends Component {
  onClick = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { textTitle } = this.props;

    return (
      <div className="uk-flex uk-flex-row uk-flex-middle uk-width-1-1 container-tab-modal-header">
        <div className=" uk-width-1-2">
          <ul className=" uk-tab-page-secondary-large uk-tab uk-margin-remove">
            <li className="uk-active">
              <div className="text-header-modal">{textTitle}</div>
            </li>
          </ul>
        </div>
        <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
          {/* <span
            className="uk-margin-small-right pointer"
            uk-icon="icon: acr-interface-new-window"
          ></span> */}
          <span
            className="pointer"
            uk-icon="icon: acr-interface-close"
            onClick={(e) => {
              e.preventDefault();
              this.onClick();
            }}
          ></span>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TabHeader)
);
