export const INITIAL_STATE_KPI = {
  listAllKpi: [],
  listSelectedKpi: [],
};

export class KpiModel {
  id;
  selected;
  kpi;
  constructor() {
    this.id = null;
    this.selected = false;
    this.kpi = null;
  }
  static from(obj) {
    var kpi = new KpiModel();
    if (obj.id || obj.id === 0) kpi.id = obj.id;
    if (obj.selected) kpi.selected = obj.selected;
    if (obj.kpi) kpi.kpi = obj.kpi;
    return kpi;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(KpiModel.from).filter((item) => item !== null);
  }
}

export class TimeFrame {
  start;
  end;
  constructor() {
    this.start = null;
    this.end = null;
  }
  static from(obj) {
    var tm = new TimeFrame();
    tm.start = obj.start;
    tm.end = obj.end;
    return tm;
  }
}

export class VehicleJourneysCount {
  recordedAt;
  total;
  timeFrame;
  constructor() {
    this.recordedAt = null;
    this.total = null;
    this.timeFrame = null;
  }
  static from(obj) {
    var vj = new VehicleJourneysCount();
    vj.recordedAt = obj.recordedAt;
    vj.total = obj.total;
    vj.timeFrame = TimeFrame.from(obj.timeFrame);
    return vj;
  }
}

export class VehicleJourneysRate {
  recordedAt;
  rate;
  timeFrame;
  constructor() {
    this.recordedAt = null;
    this.rate = null;
    this.timeFrame = null;
  }
  static from(obj) {
    var vj = new VehicleJourneysRate();
    vj.recordedAt = obj.recordedAt;
    vj.rate = obj.rate;
    vj.timeFrame = TimeFrame.from(obj.timeFrame);
    return vj;
  }
}

export class VehicleJourneysIndicator {
  recordedAt;
  indicator;
  timeFrame;
  constructor() {
    this.recordedAt = null;
    this.indicator = null;
    this.timeFrame = null;
  }
  static from(obj) {
    var vj = new VehicleJourneysIndicator();
    vj.recordedAt = obj.recordedAt;
    vj.indicator = obj.indicator;
    vj.timeFrame = TimeFrame.from(obj.timeFrame);
    return vj;
  }
}

export class VehicleJourneysAllCounters {
  recordedAt;
  timeFrame;
  estimatedVehicleJourneyCount;
  monitoredVehicleJourneyCount;
  monitoredVehicleJourneyCountLate;
  monitoredVehicleJourneyCountOnTime;
  monitoredVehicleJourneyCountEarly;
  monitoredVehicleJourneyRate;
  monitoredVehicleJourneyRateLate;
  monitoredVehicleJourneyOnTimeIndicator;
  constructor() {
    this.recordedAt = null;
    this.timeFrame = null;
    this.estimatedVehicleJourneyCount = null;
    this.monitoredVehicleJourneyCount = null;
    this.monitoredVehicleJourneyCountLate = null;
    this.monitoredVehicleJourneyCountOnTime = null;
    this.monitoredVehicleJourneyCountEarly = null;
    this.monitoredVehicleJourneyRate = null;
    this.monitoredVehicleJourneyRateLate = null;
    this.monitoredVehicleJourneyOnTimeIndicator = null;
  }
  static from(obj) {
    var vj = new VehicleJourneysAllCounters();
    vj.recordedAt = obj.recordedAt;
    vj.timeFrame = TimeFrame.from(obj.timeFrame);
    vj.estimatedVehicleJourneyCount = obj.estimatedVehicleJourneyCount;
    vj.monitoredVehicleJourneyCount = obj.monitoredVehicleJourneyCount;
    vj.monitoredVehicleJourneyCountLate = obj.monitoredVehicleJourneyCountLate;
    vj.monitoredVehicleJourneyCountOnTime =
      obj.monitoredVehicleJourneyCountOnTime;
    vj.monitoredVehicleJourneyCountEarly =
      obj.monitoredVehicleJourneyCountEarly;
    vj.monitoredVehicleJourneyRate = obj.monitoredVehicleJourneyRate;
    vj.monitoredVehicleJourneyRateLate = obj.monitoredVehicleJourneyRateLate;
    vj.monitoredVehicleJourneyOnTimeIndicator =
      obj.monitoredVehicleJourneyOnTimeIndicator;
    return vj;
  }
}
