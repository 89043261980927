export const CamerasActions = {
  SET_LIST_CAMERAS: "INSTALLATIONS/SET_LIST_CAMERAS",
  SET_CURRENT_CAMERA: "INSTALLATIONS/SET_CURRENT_CAMERA",
  SET_CURRENT_CAMERA_MAP: "INSTALLATIONS/SET_CURRENT_CAMERA_MAP",
  RESET_CURRENT_CAMERA: "INSTALLATIONS/RESET_CURRENT_CAMERA",
  UPDATE_CAMERA_STATE_WS: "INSTALLATIONS/UPDATE_CAMERA_STATE_WS",
  ADD_NEW_PINNED_CAMERA: "INSTALLATIONS/ADD_NEW_PINNED_CAMERA",
  REMOVE_PINNED_CAMERA: "INSTALLATIONS/REMOVE_PINNED_CAMERA",
  RESET_PINNED_CAMERAS: "INSTALLATIONS/RESET_PINNED_CAMERAS",
  ADD_NEW_CAMERA_ON_MAP: "INSTALLATIONS/ADD_NEW_CAMERA_ON_MAP",
  REMOVE_CAMERA_ON_MAP: "INSTALLATIONS/REMOVE_CAMERA_ON_MAP",
  MOVE_CAMERA_POSITION_ON_MAP: "INSTALLATIONS/MOVE_CAMERA_POSITION_ON_MAP",
  UPDATE_CAMERA_ALARMS_WS: "INSTALLATIONS/UPDATE_CAMERA_ALARMS_WS",
  RESET_ALARM_CAMERAS_WS: "INSTALLATIONS/RESET_ALARM_CAMERAS_WS",
  RESET_STATUS_CAMERAS_WS: "INSTALLATIONS/RESET_STATUS_CAMERAS_WS",
};

export const setCamerasList = (list) => (dispatch) => {
  dispatch({
    type: CamerasActions.SET_LIST_CAMERAS,
    payload: { list },
  });
};

export const setCurrentCamera = (current) => (dispatch) => {
  dispatch({
    type: CamerasActions.SET_CURRENT_CAMERA,
    payload: current,
  });
};

export const resetCurrentCamera = () => (dispatch) => {
  dispatch({
    type: CamerasActions.RESET_CURRENT_CAMERA,
  });
};

export const updateCameraStatesWS = (states) => (dispatch) => {
  dispatch({
    type: CamerasActions.UPDATE_CAMERA_STATE_WS,
    payload: states,
  });
};

export const addNewPinnedCamera = (camera) => (dispatch) => {
  dispatch({
    type: CamerasActions.ADD_NEW_PINNED_CAMERA,
    payload: camera,
  });
};

export const removePinnedCamera = (camera) => (dispatch) => {
  dispatch({
    type: CamerasActions.REMOVE_PINNED_CAMERA,
    payload: camera,
  });
};

export const resetPinnedCameras = () => (dispatch) => {
  dispatch({
    type: CamerasActions.RESET_PINNED_CAMERAS,
  });
};

export const addNewCameraOnMap = (camera) => (dispatch) => {
  dispatch({
    type: CamerasActions.ADD_NEW_CAMERA_ON_MAP,
    payload: camera,
  });
};

export const removeCameraOnMap = (camera) => (dispatch) => {
  dispatch({
    type: CamerasActions.REMOVE_CAMERA_ON_MAP,
    payload: camera,
  });
};

export const updateCameraAlarmsWS = (states) => (dispatch) => {
  dispatch({
    type: CamerasActions.UPDATE_CAMERA_ALARMS_WS,
    payload: states,
  });
};

export const resetListAlarmWs = () => (dispatch) => {
  dispatch({
    type: CamerasActions.RESET_ALARM_CAMERAS_WS,
  });
};

export const resetListStatusWs = () => (dispatch) => {
  dispatch({
    type: CamerasActions.RESET_STATUS_CAMERAS_WS,
  });
};

export const moveCameraPositionOnMap = (position) => (dispatch) => {
  dispatch({
    type: CamerasActions.MOVE_CAMERA_POSITION_ON_MAP,
    payload: position,
  });
};
