import React, { Component } from "react";
import * as moment from "moment";
import "./style.less";
//COMPONENTS
import CustomisedDateTimePicker from "components/shared/customised-date-time-picker/customised-datetimepicker";
import DropdownColumnSelect from "components/shared/table-top-filters/dropdown-column-select";
//UTILS
import * as FilterUtils from "../../../utils/filter-utils";

class TableFilters extends Component {
  selectAll;

  constructor(props) {
    super(props);
    this.state = {
      multiselect: [],
    };
  }

  componentDidUpdate = () => {
    const { multiselect } = this.state;
    const { selected } = this.props;
    if (
      Object.keys(multiselect).length === 0 &&
      Object.keys(selected).length > 0
    ) {
      this.setState({
        multiselect: [...selected],
      });
    }

    let el = document.getElementById("filter-text-input");
    el.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        return false;
      }
    });

    FilterUtils.renderAriaLabelCheckboxes(multiselect);
  };

  handleFilterChange = (e) => {
    const { handleFilterChange } = this.props;
    handleFilterChange(e);
  };

  handleDateSelected = (e, date) => {
    const { handleSetDateFilterTarget } = this.props;
    e.stopPropagation();
    handleSetDateFilterTarget(date);
  };

  handleResetDateSelected = (e) => {
    const { handleResetDateFilterTarget } = this.props;
    e.stopPropagation();
    handleResetDateFilterTarget();
  };

  onChangeDate = (value, name) => {
    const { changeDate } = this.props;
    changeDate(value, name);
  };

  resetFilters = (e) => {
    const { resetFilters } = this.props;
    this.toggleAll(true);
    resetFilters(e);
  };

  handleOnFocus = (e) => {
    const { multiselect } = this.state;

    setTimeout(() => {
      FilterUtils.renderAriaLabelCheckboxes(multiselect);
    }, 500);
  };

  handleSelectChange = ({ checked, id, isChild }) => {
    const { selected, handleSelectChange } = this.props;
    const { multiselect } = this.state;

    if (id === "all") {
      this.toggleAll(!checked);
    } else {
      let data = multiselect.map((_) => {
        if (_.id === id || _.id === 'checkbox') {
          _.checked = !checked !== 'checkbox';
          _.show = !checked !== 'checkbox';
        }
        return _;
      });
      let allNodesUpdated = [...selected].map((item) => {
        item.checked = item.id === id ? !checked : item.checked;
        item.show = item.id === id ? !checked : item.show;
        return item;
      });

      let allChecked = FilterUtils.toggleAllCheckWhenOtherSelected(
        !checked,
        allNodesUpdated
      );
      allNodesUpdated[0].checked = allChecked;
      allNodesUpdated[0].show = allChecked;

      this.setState({ multiselect: data });
      handleSelectChange(allNodesUpdated);
    }
  };

  toggleAll = (all) => {
    const { selected, handleSelectChange } = this.props;
    handleSelectChange(FilterUtils.toggleAll(selected, all));
  };

  render() {
    const {
      dateStart,
      dateEnd,
      isOpenNav,
      filterStr,
      selected,
      filtrableDateColumns,
      disabledDataFilter,
      inputFieldDisabled,
    } = this.props;

    const { multiselect } = this.state;
    let start = moment(dateStart.date);
    let end = dateEnd.date ? moment(dateEnd.date) : moment(dateStart.date);
    let timeDiff = start.diff(end, "days");
    let isSame = timeDiff >= 0;

    let selectedText = selected
      ? FilterUtils.getSelectionLevelText(selected)
      : "";

    return (
      <div className="uk-flex uk-flex-row filter-bar uk-width-1-1 uk-margin-top uk-margin-bottom">
        <form className="uk-custom-select-and-search uk-flex width-auto">
          <div className="uk-select uk-flex uk-flex-1 uk-padding-remove select-container">
            <div className="select-text-wrapper">{selectedText}</div>
            <DropdownColumnSelect
              className="dropdown-tree"
              data={multiselect}
              onChange={this.handleSelectChange}
              onFocus={this.handleOnFocus}
            />
          </div>
          <div className="position-relative search-container">
            <input
              id="filter-text-input"
              className={
                !inputFieldDisabled
                  ? "uk-input padding-search-custom uk-form-small"
                  : "uk-input padding-search-custom uk-form-small cursor-disabled"
              }
              type="text"
              placeholder="Cerca..."
              onChange={this.handleFilterChange}
              value={filterStr}
              disabled={inputFieldDisabled}
            ></input>
            <div
              uk-icon="icon: acr-interface-search"
              className="uk-flex uk-flex-middle uk-flex-center search-icon-override"
            ></div>
          </div>
        </form>

        <div className=" uk-text-center uk-flex uk-flex-row uk-card uk-card-secondary card uk-margin-remove uk-flex-1">
          <div className="uk-button-group">
            <button
              disabled={disabledDataFilter}
              className={
                !disabledDataFilter
                  ? "custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
                  : "custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle cursor-disabled"
              }
              onClick={this.handleResetDateSelected}
            >
              <span
                uk-icon="icon: acr-filter"
                className="filterBarIcons"
              ></span>
            </button>
            <div className="uk-button-group">
              <button
                disabled={disabledDataFilter}
                className={
                  !disabledDataFilter
                    ? "uk-button uk-button-default uk-flex uk-flex-middle"
                    : "uk-button uk-button-default uk-flex uk-flex-middle cursor-disabled"
                }
                type="button"
                style={{ padding: 0 }}
              >
                <span
                  uk-icon="icon:  triangle-down"
                  className="filterBarIcons"
                ></span>
              </button>
              <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                <div className="uk-nav uk-dropdown-nav uk-flex">
                  <div className="uk-form-controls margin-information-add uk-flex uk-flex-column">
                    {filtrableDateColumns &&
                      filtrableDateColumns.map((item, index) => (
                        <label
                          className="uk-align-left uk-margin-top uk-margin-bottom"
                          key={item.name + "_" + index}
                        >
                          <input
                            type="radio"
                            className={
                              !disabledDataFilter
                                ? "uk-radio-acr uk-radio-acr-small uk-align-left uk-margin-remove-bottom uk-radio uk-margin-right"
                                : "uk-radio-acr uk-radio-acr-small uk-align-left uk-margin-remove-bottom uk-radio uk-margin-right curdor-disabled"
                            }
                            checked={item.selected}
                            name={item.name}
                            onChange={(e) => this.handleDateSelected(e, item)}
                            disabled={disabledDataFilter}
                          />
                          {item.name}
                        </label>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-1">
            <span className="center ">Dal:</span>
            <CustomisedDateTimePicker
              className="uk-form-small uk-padding-small"
              value={dateStart.date}
              dateFormat={"dd/MM/yyyy HH:mm"}
              timeFormat={"HH:mm"}
              callbackFromParent={(data) =>
                this.onChangeDate(data, dateStart.name)
              }
              iconRatio={isOpenNav ? "ratio: 0.8;" : "ratio: 1.5"}
              startDate={dateStart.date}
              endDate={dateEnd.date}
              isSameDate={isSame}
              isStartDate={true}
              disabled={disabledDataFilter}
            />
          </div>
          <div className="uk-flex uk-flex-row uk-flex-1">
            <span className="center">al:</span>
            <CustomisedDateTimePicker
              className="uk-form-small uk-padding-small"
              value={dateEnd.date}
              dateFormat={"dd/MM/yyyy HH:mm"}
              timeFormat={"HH:mm"}
              callbackFromParent={(data) =>
                this.onChangeDate(data, dateEnd.name)
              }
              iconRatio={isOpenNav ? "ratio: 0.8;" : "ratio: 1.5"}
              startDate={dateStart.date}
              endDate={dateEnd.date}
              isSameDate={isSame}
              isEndDate={true}
              disabled={disabledDataFilter}
            />
          </div>
          {/* <div className="uk-button-group">
            <button
              className={
                !disabledDataFilter
                  ? "custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
                  : "custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle cursor-disabled"
              }
              type="button"
              disabled={disabledDataFilter}
            >
              <span
                uk-icon="icon: acr-interface-search"
                className="filterBarIcons"
              ></span>
            </button>
          </div> */}
        </div>
      </div>
    );
  }
}

export default TableFilters;
