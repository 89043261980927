import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import createApiService from "services/api.service";
import InfiniteScroll from "react-infinite-scroll-component";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";
import { loadFilterTrip } from "utils/utils-filter-tpl";
import "./style.less";

//ACTIONS
import { setCurrentTrip, resetCurrentTrip } from "reducers/trips/trips.actions";
import {
  setCurrentVehicle,
  resetCurrentVehicle,
  setCurrentIdRouteForVehicle,
  setCurrentIdStopForVehicle,
} from "reducers/vehicles/vehicles.actions";
import { getAllVehiclesEvents, getCurrentTrip } from "store";

//COMPONENTS
import VehicleDetail from "./vehicle-detail";
import VehicleRoute from "./vehicle-route";
import Loading from "components/shared/loading/loading";

//ASSETS
import EnvironmentContext from "environment-context";
import { getAllRoutes } from "store";

const SCROLL_OFFSET = 50;

class TripsList extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {
      trip: null,
      nextTrips: [],
      currentListLen: 0,
      currentOffset: SCROLL_OFFSET,
    };
    this.handleClickVehicleRoute = this.handleClickVehicleRoute.bind(this);
    this.handleClickVehicleDetail = this.handleClickVehicleDetail.bind(this);
    this.handleClickTrip = this.handleClickTrip.bind(this);
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  handleClickTrip = (trip) => {
    loadFilterTrip(this, trip, false);
  };

  handleClickVehicleDetail = () => {
    this.setState({
      ...this.state,
      viewDetailVehicle: true,
      viewDetailRoute: false,
    });
  };

  handleClickVehicleRoute = () => {
    this.setState({
      ...this.state,
      viewDetailRoute: true,
      viewDetailVehicle: false,
    });
  };

  resetSelected = () => {
    const { resetCurrentTrip } = this.props;
    resetCurrentTrip();
    this.setState({
      ...this.state,
      viewDetailRoute: false,
      viewDetailVehicle: false,
    });
  };

  fetchMoreData = () => {
    const { tripsList } = this.props;
    const { currentOffset } = this.state;

    let newOffset = currentOffset + SCROLL_OFFSET;
    let fetchmore = tripsList.slice(0, newOffset);
    this.setState({
      nextTrips: fetchmore,
      currentOffset: newOffset,
    });
  };

  constructFetchedDOM = () => {
    const { tripsReducer } = this.props;
    const { viewDetailVehicle, viewDetailRoute, nextTrips } = this.state;
    let active;
    return (
      <div className="corse-list-container">
        {tripsReducer === null ? (
          <div className="uk-list">
            {nextTrips.map((trip, index) => {
              return (
                <li
                  key={index}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleClickTrip(trip);
                  }}
                  className="uk-flex uk-flex-left uk-flex-middle"
                  id={
                    trip.monitoredVehicleJourney.framedVehicleJourneyRef &&
                    trip.monitoredVehicleJourney.framedVehicleJourneyRef
                      .datedVehicleJourneyRef
                      ? trip.monitoredVehicleJourney.framedVehicleJourneyRef
                          .datedVehicleJourneyRef
                      : trip.monitoredVehicleJourney.vehicleJourneyName
                  }
                >
                  {(tripsReducer &&
                    tripsReducer.monitoredVehicleJourney.framedVehicleJourneyRef
                      .datedVehicleJourneyRef ===
                      trip.monitoredVehicleJourney.framedVehicleJourneyRef
                        .datedVehicleJourneyRef) ||
                  (tripsReducer &&
                    tripsReducer.monitoredVehicleJourney.framedVehicleJourneyRef
                      .datedVehicleJourneyRef ===
                      trip.monitoredVehicleJourney.vehicleJourneyName)
                    ? (active = true)
                    : (active = false)}
                  <div
                    uk-icon="icon: acr-menu-routes-management"
                    className={
                      trip.transportMode &&
                      trip.transportMode.subMode &&
                      trip.transportMode.subMode.code &&
                      trip.transportMode.subMode.code ===
                        TYPE_TRANSPORT_MODE["extraurb"]
                        ? active
                          ? "vehicleSub activeSub"
                          : "vehicleSub inactiveSub"
                        : active
                        ? "vehicleUrb activeUrb"
                        : "vehicleUrb inactiveUrb"
                    }
                  ></div>
                  <div
                    className={
                      active
                        ? "uk-margin-left active list-text"
                        : "uk-margin-left  inactive list-text"
                    }
                  >
                    {"Corsa " +
                      trip.monitoredVehicleJourney.vehicleJourneyName +
                      " - " +
                      trip.monitoredVehicleJourney.publishedLineName}
                  </div>
                  {/*  {tripsReducer &&
                    tripsReducer.monitoredVehicleJourney.framedVehicleJourneyRef
                      .datedVehicleJourneyRef ===
                      trip.monitoredVehicleJourney.framedVehicleJourneyRef
                        .datedVehicleJourneyRef && (
                          <div
                          uk-icon="icon: acr-interface-close-big"
                          onClick={(event) => {
                            event.preventDefault();
                            this.resetSelected();
                          }}
                          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
                        ></div>
                    )} */}
                </li>
              );
            })}
          </div>
        ) : viewDetailVehicle && !viewDetailRoute && tripsReducer !== null ? (
          <VehicleDetail
            current={tripsReducer}
            handleClickVehicleRoute={this.handleClickVehicleRoute}
            handleResetSelected={this.resetSelected}
          />
        ) : tripsReducer !== null ? (
          <VehicleRoute
            current={tripsReducer}
            handleClickVehicleDetail={this.handleClickVehicleDetail}
            handleResetSelected={this.resetSelected}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { nextTrips, currentOffset, currentListLen } = state;
    const { tripsList } = props;
    let newState = {};

    if (currentListLen !== tripsList.length && tripsList.length === 0) {
      newState = {
        currentListLen: tripsList.length,
        currentOffset: SCROLL_OFFSET,
        nextTrips: [],
      };
    }
    if (currentListLen !== tripsList.length && tripsList.length > 0) {
      newState = {
        currentListLen: tripsList.length,
        currentOffset: tripsList.length - 1, //SCROLL_OFFSET,
        nextTrips: tripsList.slice(0, tripsList.length), //tripsList.slice(0, SCROLL_OFFSET),
      };
    }
    if (nextTrips.length === 0 && currentListLen > 0) {
      newState = {
        ...state,
        nextTrips: tripsList.slice(
          0,
          currentListLen > SCROLL_OFFSET ? currentOffset : currentListLen
        ),
      };
    }

    return newState;
  }

  renderInfiniteScroll = () => {
    const { tripsList, tripsReducer } = this.props;
    const { nextTrips } = this.state;

    return (
      <InfiniteScroll
        dataLength={nextTrips.length || 0} //This is important field to render the next data
        next={this.fetchMoreData}
        hasMore={nextTrips.length === tripsList.length ? false : true}
        loader={
          <div>
            {tripsList.length && !tripsReducer ? "" /* <Loading /> */ : ""}
          </div>
        }
        scrollableTarget={"tabpanel-2"}
        className="inner-infinite-scroll"
      >
        {this.constructFetchedDOM()}
      </InfiniteScroll>
    );
  };
  render() {
    const { isTripsLoading } = this.props;

    return (
      <div>{isTripsLoading ? <Loading /> : this.renderInfiniteScroll()}</div>
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

TripsList.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setCurrentTrip,
  resetCurrentTrip,
  setCurrentVehicle,
  resetCurrentVehicle,
  setCurrentIdRouteForVehicle,
  setCurrentIdStopForVehicle,
};

const mapStateToProps = (state) => ({
  tripsReducer: getCurrentTrip(state),
  vehiclesEventsReducer: getAllVehiclesEvents(state),
  routesReducer: getAllRoutes(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TripsList)
);
