export function getFilterForUserActivitytable(stateTableColumns, sortConfig) {
  let filterObject = {
    modules: [],
    usernames: [],
    // roles: [],
    activityTypes: [],
    orderBy: [],
    orderType: [],
  };

  const filterStrings = [
    "modules",
    "usernames",
    /* "roles," */ "activityTypes",
  ];

  filterStrings.forEach((item) => {
    return (filterObject[item] = [
      ...stateTableColumns[item].childrenForHeaderDropdown.reduce(
        (acc, { checked, id }) => {
          if (checked && !id.startsWith("all")) {
            return acc.concat(id);
          } else {
            return acc;
          }
        },
        []
      ),
    ]);
  });

  filterObject["orderBy"] =
    sortConfig.key && mapSortConfig(sortConfig.key)
      ? [mapSortConfig(sortConfig.key)]
      : [];
  filterObject["orderType"] =
    sortConfig.direction === "ascending"
      ? "ASC"
      : sortConfig.direction === "descending"
      ? "DESC"
      : null;

  return filterObject;
}

export const mapSortConfig = (key) => {
  switch (key) {
    case "activityId":
      return "ACTIVITY_ID";
    case "datetimeAction":
      return "ACTIVITY_CREATION_TIME";
    case "usernames":
      return "USER_USERNAME";
    case "roles":
      return "USER_ROLE";
    case "modules":
      return "MODULE";
    case "activityTypes":
      return "ACTIVITY_TYPE";
    default:
      return null;
  }
};

export const moduleEnum = [
  { id: "ROAD_GRAPH", nameIt: "Grafo Stradale" },
  { id: "PUBLIC_TRANSPORT", nameIt: "Trasporto Pubblico" },
  { id: "TRAFFIC", nameIt: "Traffico" },
  { id: "SITUATION", nameIt: "Eventi" },
  { id: "PARKING", nameIt: "Parcheggi" },
  { id: "MEASUREMENT_STATIONS", nameIt: "Stazioni di Misura" },
  { id: "CCTV", nameIt: "Telecamere" },
  { id: "VMS", nameIt: "Pannello a Messaggio Variabile" },
  { id: "OTHER", nameIt: "Altro" },
  { id: "NOT_SPECIFIED", nameIt: "Accesso" },
];

export function getModuleTranslation(module) {
  let translation = "-";
  if (module && moduleEnum.find((item) => item.id === module)) {
    translation = moduleEnum.find((item) => item.id === module).nameIt;
  }
  return translation;
}

export const activityTypeEnum = [
  { id: "USER_LOGIN", nameIt: "Login" },
  { id: "USER_LOGOUT", nameIt: "Logout" },
  { id: "DATA_INSERTED", nameIt: "Creazione" },
  { id: "DATA_UPDATED", nameIt: "Modifica" },
  { id: "DATA_DELETED", nameIt: "Cancellazione" },
  { id: "DATA_EXPORTED", nameIt: "Export" },
  { id: "CONFIG_UPDATED", nameIt: "Configurazione" },
];

export function getActivityTypeTranslation(activityType) {
  let translation = "-";
  if (
    activityType &&
    activityTypeEnum.find((item) => item.id === activityType)
  ) {
    translation = activityTypeEnum.find((item) => item.id === activityType)
      .nameIt;
  }
  return translation;
}

export function showActivityDetails(detailValues) {
  let detailString = "";
  detailValues.forEach((val, index) => {
    detailString = detailString + val.value;
    if (index + 1 < detailValues.length) {
      detailString = detailString + ", ";
    }
  });
  return detailString;
}

export function showRoles(roles) {
  let roleString = roles;
  // roles.forEach((val, index) => {
  //   roleString = roleString + val;
  //   if (index + 1 < roles.length) {
  //     roleString = roleString + ", ";
  //   }
  // });
  return roleString;
}
