import { CallModel, ProgressBetweenStopsModel } from "../trips/trips.model";
import { ISO8601toMin } from "utils/utils";

export const INITIAL_STATE_VEHICLES = {
  vehicles: [],
  current: null,
  vehiclesEvents: [],
  currentIdRouteForVehicle: [],
  currentIdStopForVehicle: [],
};

export default class VehicleGeneralModel {
  itemId;
  recordedAtTime;
  vehicleMonitoringRef;
  monitoredVehicleJourney;
  progressBetweenStops;

  constructor() {
    this.itemId = null;
    this.recordedAtTime = null;
    this.vehicleMonitoringRef = null;
    this.monitoredVehicleJourney = MonitoredVehicleJourneyModel;
    this.progressBetweenStops = null;
  }
  static fromREST(obj) {
    var vehicle = new VehicleGeneralModel();
    if (obj.itemId) vehicle.itemId = obj.itemId;
    if (obj.recordedAtTime) vehicle.recordedAtTime = obj.recordedAtTime;
    if (obj.vehicleMonitoringRef)
      vehicle.vehicleMonitoringRef = obj.vehicleMonitoringRef;
    if (obj.monitoredVehicleJourney)
      vehicle.monitoredVehicleJourney = MonitoredVehicleJourneyModel.from(
        obj.monitoredVehicleJourney
      );
    if (obj.progressBetweenStops)
      vehicle.progressBetweenStops = ProgressBetweenStopsModel.from(
        obj.progressBetweenStops
      );
    return vehicle;
  }
  static fromArrayREST(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(VehicleGeneralModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class MonitoredVehicleJourneyModel {
  bearing;
  dataSource;
  delay;
  destinationName;
  destinationRef;
  directionName;
  directionRef;
  engineOn;
  extensions;
  framedVehicleJourneyRef;
  inCongestion;
  inPanic;
  isCompleteStopSequence;
  journeyNote;
  journeyParts;
  journeyPatternRef;
  lineRef;
  locationRecordedAtTime;
  monitored;
  monitoredCall;
  occupancy;
  onwardCalls;
  operatorRef;
  originName;
  originRef;
  predictionInaccurate;
  previousCalls;
  productCategoryRef;
  progressRate;
  publishedLineName;
  serviceFeatureRef;
  trainNumbers;
  vehicleFeatureRef;
  vehicleJourneyName;
  vehicleLocation;
  vehicleModeName;
  vehicleModeRef;
  vehicleStatus;
  velocity;
  via;
  constructor() {
    this.bearing = null;
    this.dataSource = null;
    this.delay = null;
    this.destinationName = null;
    this.destinationRef = null;
    this.directionName = null;
    this.directionRef = null;
    this.engineOn = null;
    this.extensions = null;
    this.framedVehicleJourneyRef = null;
    this.inCongestion = null;
    this.inPanic = null;
    this.isCompleteStopSequence = null;
    this.journeyNote = null;
    this.journeyParts = null;
    this.journeyPatternRef = null;
    this.lineRef = null;
    this.locationRecordedAtTime = null;
    this.monitored = null;
    this.monitoredCall = null;
    this.occupancy = null;
    this.onwardCalls = [];
    this.operatorRef = null;
    this.originName = null;
    this.originRef = null;
    this.predictionInaccurate = null;
    this.previousCalls = [];
    this.productCategoryRef = null;
    this.progressRate = null;
    this.publishedLineName = null;
    this.serviceFeatureRef = null;
    this.trainNumbers = null;
    this.vehicleFeatureRef = null;
    this.vehicleJourneyName = null;
    this.vehicleLocation = null;
    this.vehicleModeName = null;
    this.vehicleModeRef = null;
    this.vehicleStatus = null;
    this.velocity = null;
    this.via = null;
  }
  static from(obj) {
    var trip = new MonitoredVehicleJourneyModel();
    if (obj.bearing) trip.bearing = obj.bearing;
    if (obj.dataSource) trip.dataSource = obj.dataSource;
    if (obj.delay) trip.delay = ISO8601toMin(obj.delay);
    if (obj.destinationName) trip.destinationName = obj.destinationName;
    if (obj.destinationRef) trip.destinationRef = obj.destinationRef;
    if (obj.directionName) trip.directionName = obj.directionName;
    if (obj.directionRef) trip.directionRef = obj.directionRef;
    if (obj.engineOn) trip.engineOn = obj.engineOn;
    if (obj.extensions) trip.extensions = obj.extensions;
    if (obj.framedVehicleJourneyRef)
      trip.framedVehicleJourneyRef = FramedVehicleJourneyRefModel.from(
        obj.framedVehicleJourneyRef
      );
    if (obj.inCongestion) trip.inCongestion = obj.inCongestion;
    if (obj.inPanic) trip.inPanic = obj.inPanic;
    if (obj.isCompleteStopSequence)
      trip.isCompleteStopSequence = obj.isCompleteStopSequence;
    if (obj.journeyNote) trip.journeyNote = obj.journeyNote;
    if (obj.journeyParts) trip.journeyParts = obj.journeyParts;
    if (obj.journeyPatternRef) trip.journeyPatternRef = obj.journeyPatternRef;
    if (obj.lineRef) trip.lineRef = obj.lineRef;
    if (obj.locationRecordedAtTime)
      trip.locationRecordedAtTime = obj.locationRecordedAtTime;
    if (obj.monitored) trip.monitored = obj.monitored;
    if (obj.monitoredCall)
      trip.monitoredCall = CallModel.from(obj.monitoredCall);
    if (obj.occupancy) trip.occupancy = obj.occupancy;
    if (obj.onwardCalls)
      trip.onwardCalls = CallModel.fromArray(obj.onwardCalls);
    if (obj.operatorRef) trip.operatorRef = obj.operatorRef;
    if (obj.originName) trip.originName = obj.originName;
    if (obj.originRef) trip.originRef = obj.originRef;
    if (obj.predictionInaccurate)
      trip.predictionInaccurate = obj.predictionInaccurate;
    if (obj.previousCalls)
      trip.previousCalls = CallModel.fromArray(obj.previousCalls);
    if (obj.productCategoryRef)
      trip.productCategoryRef = obj.productCategoryRef;
    if (obj.progressRate) trip.progressRate = obj.progressRate;
    if (obj.publishedLineName) trip.publishedLineName = obj.publishedLineName;
    if (obj.serviceFeatureRef) trip.serviceFeatureRef = obj.serviceFeatureRef;
    if (obj.trainNumbers) trip.trainNumbers = obj.trainNumbers;
    if (obj.vehicleFeatureRef) trip.vehicleFeatureRef = obj.vehicleFeatureRef;
    if (obj.vehicleJourneyName)
      trip.vehicleJourneyName = obj.vehicleJourneyName;
    if (obj.vehicleLocation)
      trip.vehicleLocation = VehicleLocationModel.from(obj.vehicleLocation);
    if (obj.vehicleModeName) trip.vehicleModeName = obj.vehicleModeName;
    if (obj.vehicleModeRef) trip.vehicleModeRef = obj.vehicleModeRef;
    if (obj.vehicleStatus) trip.vehicleStatus = obj.vehicleStatus;
    if (obj.velocity) trip.velocity = obj.velocity;
    if (obj.via) trip.via = obj.via;
    return trip;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(MonitoredVehicleJourneyModel.from)
      .filter((item) => item !== null);
  }
}

export class FramedVehicleJourneyRefModel {
  dataFrameRef;
  datedVehicleJourneyRef;
  constructor() {
    this.dataFrameRef = null;
    this.datedVehicleJourneyRef = null;
  }
  static from(obj) {
    var vehicle = new FramedVehicleJourneyRefModel();
    if (obj.dataFrameRef) vehicle.dataFrameRef = obj.dataFrameRef;
    if (obj.datedVehicleJourneyRef)
      vehicle.datedVehicleJourneyRef = obj.datedVehicleJourneyRef;
    return vehicle;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(FramedVehicleJourneyRefModel.from)
      .filter((item) => item !== null);
  }
}

export class VehicleLocationModel {
  latitude;
  longitude;
  altitude;
  precision;
  constructor() {
    this.latitude = null;
    this.longitude = null;
    this.altitude = null;
    this.precision = null;
  }
  static from(obj) {
    var vehicle = new VehicleLocationModel();
    if (obj.latitude) vehicle.latitude = obj.latitude;
    if (obj.longitude) vehicle.longitude = obj.longitude;
    if (obj.altitude) vehicle.altitude = obj.altitude;
    if (obj.precision) vehicle.precision = obj.precision;
    return vehicle;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(VehicleLocationModel.from).filter((item) => item !== null);
  }
}

export class TempVehicleActivityModel {
  messageReceivedAtTime;
  e2eId;
  vehicleActivityLocation;

  constructor() {
    this.messageReceivedAtTime = null;
    this.e2eId = null;
    this.vehicleActivityLocation = {
      recordedAtTime: null,
      progressBetweenStops: {
        linkDistance: null,
        percentage: null,
      },
      monitoredVehicleJourney: {
        framedVehicleJourneyRef: {
          dataFrameRef: null,
          datedVehicleJourneyRef: null,
        },
        vehicleLocation: {
          latitude: null,
          longitude: null,
          altitude: null,
          precision: null,
        },
        locationRecordedAtTime: null,
        lineRef: null,
      },
    };
  }
  static from(obj) {
    var vehicle = new TempVehicleActivityModel();
    vehicle.messageReceivedAtTime = obj.timestamp;
    vehicle.e2eId = obj.id_vehicle;
    vehicle.vehicleActivityLocation.recordedAtTime = obj.timestamp;
    vehicle.vehicleActivityLocation.monitoredVehicleJourney.vehicleLocation.latitude =
      obj.latitude;
    vehicle.vehicleActivityLocation.monitoredVehicleJourney.vehicleLocation.longitude =
      obj.longitude;
    vehicle.vehicleActivityLocation.monitoredVehicleJourney.locationRecordedAtTime =
      obj.timestamp;
    return vehicle;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(TempVehicleActivityModel.from)
      .filter((item) => item !== null);
  }
}

export class VehicleActivityModel {
  messageReceivedAtTime;
  e2eId;
  vehicleActivityLocation;
  selected;
  transportMode;
  constructor() {
    this.messageReceivedAtTime = null;
    this.e2eId = null;
    this.selected = false;
    this.vehicleActivityLocation = VehicleActivityLocationModel;
    this.transportMode = null;
  }
  static fromWS(obj) {
    let activity;
    if (obj.vehicleActivityLocation) {
      activity = obj.vehicleActivityLocation;
    } else if (obj.vehicleActivity) {
      activity = obj.vehicleActivity;
    }

    var vehicle = new VehicleActivityModel();
    if (obj.messageReceivedAtTime)
      vehicle.messageReceivedAtTime = obj.messageReceivedAtTime;
    if (obj.e2eId) vehicle.e2eId = obj.e2eId;
    if (obj.transportMode) vehicle.transportMode = obj.transportMode;
    if (activity)
      vehicle.vehicleActivityLocation = VehicleActivityLocationModel.from(
        activity
      );
    return vehicle;
  }
  static fromArrayWS(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(VehicleActivityModel.fromWS)
      .filter((item) => item !== null);
  }
}

export class VehicleActivityLocationModel {
  recordedAtTime;
  progressBetweenStops;
  monitoredVehicleJourney;
  constructor() {
    this.recordedAtTime = null;
    this.progressBetweenStops = null;
    this.monitoredVehicleJourney = MonitoredVehicleJourneyModel;
  }
  static from(obj) {
    var vehicle = new VehicleActivityLocationModel();
    if (obj.recordedAtTime) vehicle.recordedAtTime = obj.recordedAtTime;
    if (obj.progressBetweenStops)
      vehicle.progressBetweenStops = ProgressBetweenStopsModel.from(
        obj.progressBetweenStops
      );
    if (obj.monitoredVehicleJourney)
      vehicle.monitoredVehicleJourney = MonitoredVehicleJourneyModel.from(
        obj.monitoredVehicleJourney
      );
    return vehicle;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(VehicleActivityLocationModel.from)
      .filter((item) => item !== null);
  }
}
