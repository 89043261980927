//TODO DELETE
export const InstallationsActions = {
  SET_VISIBLE_INSTALLATIONS: "INSTALLATIONS/SET_VISIBLE",
  SET_SELECTED_INSTALLATIONS: "INSTALLATIONS/SET_SELECTED",
  SET_LIST_INSTALLATIONS: "INSTALLATIONS/SET_LIST",
  SET_CURRENT_INSTALLATIONS: "INSTALLATIONS/SET_CURRENT",
  RESET_CURRENT_INSTALLATIONS: "INSTALLATIONS/RESET_CURRENT",
  RESET_SELECTED_INSTALLATIONS: "INSTALLATIONS/RESET_SELECTED",
  RESET_ALL_SELECTED_INSTALLATIONS: "INSTALLATIONS/RESET_ALL_SELECTED",
  RESET_ALL_VISIBLE_INSTALLATIONS: "INSTALLATIONS/RESET_ALL_VISIBLE",
  SET_HISTORY_DEVICE: "INSTALLATIONS/SET_HISTORY_DEVICE",
  SET_PMV_MGS_HISTORY: "INSTALLATIONS/SET_PMV_MGS_HISTORY",
};

export const resetAllVisibleInstallations = () => (dispatch) => {
  dispatch({
    type: InstallationsActions.RESET_ALL_VISIBLE_INSTALLATIONS,
  });
};
export const resetAllSelectedInstallations = () => (dispatch) => {
  dispatch({
    type: InstallationsActions.RESET_ALL_SELECTED_INSTALLATIONS,
    payload: null,
  });
};

export const setListInstallations = (list) => (dispatch) => {
  dispatch({
    type: InstallationsActions.SET_LIST_INSTALLATIONS,
    payload: { list },
  });
};

export const setCurrentInstallations = (type, current) => (dispatch) => {
  dispatch({
    type: InstallationsActions.SET_CURRENT_INSTALLATIONS,
    payload: { type, current },
  });
};
export const resetCurrentInstallations = (type) => (dispatch) => {
  dispatch({
    type: InstallationsActions.RESET_CURRENT_INSTALLATIONS,
    payload: { type },
  });
};
export const setSelectedInstallations = (type, installation) => (dispatch) => {
  dispatch({
    type: InstallationsActions.SET_SELECTED_INSTALLATIONS,
    payload: { type, installation },
  });
};
export const setVisibleInstallations = (type) => (dispatch) => {
  dispatch({
    type: InstallationsActions.SET_VISIBLE_INSTALLATIONS,
    payload: { type },
  });
};

export const resetSelectedInstallations = (type) => (dispatch) => {
  dispatch({
    type: InstallationsActions.RESET_SELECTED_INSTALLATIONS,
    payload: { type },
  });
};

export const setHistoryDevice = (type, history) => (dispatch) => {
  dispatch({
    type: InstallationsActions.SET_HISTORY_DEVICE,
    payload: { type, history },
  });
};
