import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import { isOpenDropdownNetwork, getNetworks } from "store";
import { toggleDropdownNetwork } from "reducers/ui/nav/nav.actions";
import { resetLines, resetActiveLines } from "reducers/lines/lines.actions";
import { resetStops } from "reducers/stops/stops.actions";
import { resetRoutes } from "reducers/routes/routes.actions";
import { resetTrips } from "reducers/trips/trips.actions";
import { resetVehicles } from "reducers/vehicles/vehicles.actions";
import { resetAllMap } from "reducers/map/map.actions";
import {
  setCurrentNetwork,
  checkNetwork,
} from "reducers/configurations/configurations.actions";
import Loading from "components/shared/loading/loading";

class SelectNetwork extends Component {
  list = [];
  apiService;
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  apply = () => {
    this.apiService = createApiService(this.context);
    const {
      toggleDropdownNetwork,
      setCurrentNetwork,
      networkReducer,
      resetLines,
      resetStops,
      resetRoutes,
      resetTrips,
      resetVehicles,
      resetAllMap,
    } = this.props;
    let arrCheckedNetwork = [];
    arrCheckedNetwork = [...networkReducer].filter(
      (network) => network.checked
    );
    this.apiService.closeVehicleEventsRuntime();
    this.apiService.closeVehicleEventsLocationRuntime();
    resetVehicles();
    resetLines();
    resetStops();
    resetRoutes();
    resetTrips();
    resetAllMap();
    this.setState({ isLoading: true });
    setTimeout(() => {
      setCurrentNetwork(arrCheckedNetwork);
      toggleDropdownNetwork();
      this.setState({ isLoading: false });
      window.location.reload();
    }, 2000);
  };

  closeTab = () => {
    const { toggleDropdownNetwork } = this.props;
    toggleDropdownNetwork();
  };

  buttonNetwork = () => {
    return (
      <div className="apply-button-container uk-flex uk-flex-row uk-flex-bottom">
        <button
          className="uk-button uk-button-default uk-button-small"
          onClick={(e) => {
            e.preventDefault();
            this.closeTab();
          }}
        >
          Annulla
        </button>
        <button
          className="uk-button uk-button-primary uk-button-small"
          onClick={(e) => {
            e.preventDefault();
            this.apply();
          }}
        >
          Applica
        </button>
      </div>
    );
  };

  listNetwork = () => {
    const { networkReducer, checkNetwork } = this.props;

    return (
      <div className="uk-flex overflow-y-auto uk-flex-row uk-height-1-1 uk-width-1-1 uk-flex-around uk-padding uk-flex-wrap">
        {networkReducer.map((net, index) => {
          return (
            <div
              key={index + net.id}
              className="form-network uk-flex uk-flex-middle"
            >
              <input
                className="uk-radio"
                type="radio"
                defaultChecked={net.checked || false}
                name="radioNetwork"
                onClick={() => {
                  checkNetwork(net);
                }}
              />{" "}
              <span className="uk-flex uk-text-center">
                {net.nameLong + " - " + net.nameShort}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { isOpenDropdownNetwork } = this.props;
    const { isLoading } = this.state;
    return (
      isOpenDropdownNetwork && (
        <div className="network-container">
          {!isLoading && this.listNetwork()}
          {!isLoading && this.buttonNetwork()}
          {isLoading && <Loading />}
        </div>
      )
    );
  }
}

SelectNetwork.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleDropdownNetwork,
  setCurrentNetwork,
  checkNetwork,
  resetLines,
  resetStops,
  resetRoutes,
  resetTrips,
  resetActiveLines,
  resetVehicles,
  resetAllMap,
};

const mapStateToProps = (state) => ({
  isOpenDropdownNetwork: isOpenDropdownNetwork(state),
  networkReducer: getNetworks(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectNetwork)
);
