import React, { Component } from "react";

class WorkspaceCreationBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      createWorkspace,
      toggleCreation,
      handleWorkspaceNameChange,
      newWorkspaceString,
      toggleWorkspaceDimensions,
      workspaceToEdit,
      updateWorkspace,
      toggleSaveWorkspace,
    } = this.props;

    return (
      <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between">
        <div className="uk-flex uk-flex-row icon-container-width">
          <span
            uk-icon="icon: check;"
            uk-tooltip={"title: Salva Workspace"}
            onClick={(event) => {
              event.preventDefault();
              if (workspaceToEdit) {
                updateWorkspace();
              } else {
                createWorkspace();
                toggleSaveWorkspace(true);
              }
            }}
            className="pointer uk-margin-small-left green-text height-width-35"
          />
          <span
            uk-icon="icon: close;"
            uk-tooltip={
              workspaceToEdit
                ? "title: Interrompi Modifica Workspace"
                : "title: Interrompi Creazione Workspace"
            }
            onClick={(event) => {
              event.preventDefault();
              toggleCreation();
            }}
            className="pointer red-text height-width-35"
          />
        </div>
        <input
          className="uk-input padding-search-custom uk-form-small ws-form-width"
          type="text"
          placeholder="Nuovo workspace"
          onChange={handleWorkspaceNameChange}
          value={newWorkspaceString}
        ></input>
        <div className="uk-height-1-1 uk-width-1-1 uk-card uk-card-secondary  uk-flex uk-flex-center uk-flex-middle">
          <span
            uk-icon="icon: thumbnails"
            uk-tooltip={"title: Workspace"}
            onClick={(event) => {
              event.preventDefault();
              toggleWorkspaceDimensions();
            }}
            className="pointer height-width-35"
          />
        </div>
      </div>
    );
  }
}

export default WorkspaceCreationBox;
