import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
//REDUX
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import {
  setCurrentSegmentRdsTmc,
  setSegmentsRdsTmcList,
} from "reducers/graph/segments/segments.actions";
import {
  setCurrentPointRdsTmc,
  setPointsRdsTmcList,
  filterPointsBySegment,
} from "reducers/graph/points/points.actions";
import {
  getNavTabSelectedTab,
  isNetworkRdsTmcVisible,
  getCurrentPointRdsTmc,
  getCurrentSegmentRdsTmc,
  getFilteredSegments,
  getFilteredPoints,
  getBoundingBox,
  getMapPolygon,
  isOpenNavTab,
  getCurrentZoom,
  isPointsLoading,
  isSegmentsLoading,
} from "store";
import { setIsNetworkLoading } from "reducers/ui/graph-menu/graph-menu.actions";
//MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";
//UTILS
import {
  getBoundingBoxStringForBboxParam,
  getBoundingBoxStringForCqlFilter,
  getCqlCircleString,
  getCqLPolygonString,
} from "./map.utils";
import { debounce } from "utils/utils";

class LoadNetworkData extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {};
    this.setPointsDebounced = debounce(this.setPointList, 300);
  }

  componentDidMount = () => {
    const {
      mapPolygonReducer,
      isNetworkRdsTmcVisible,
      toggleOpenNavTab,
      isOpenNavTab,
    } = this.props;

    this.apiService = createApiService(this.context);

    //if when you open the page, there is already a drawn polygon, get the elements belonging to the polygon
    if (mapPolygonReducer && mapPolygonReducer.polygoncoordinates) {
      let polygonString = getCqLPolygonString(mapPolygonReducer);
      if (isNetworkRdsTmcVisible) {
        this.getApiPointsList(null, polygonString);
      }
      //OPEN NAVTAB IF IT WAS CLOSED
      if (isNetworkRdsTmcVisible && !isOpenNavTab) {
        toggleOpenNavTab(true);
      }
    } else if (
      mapPolygonReducer &&
      mapPolygonReducer.center &&
      mapPolygonReducer.radius
    ) {
      let circleString = getCqlCircleString(mapPolygonReducer);
      if (isNetworkRdsTmcVisible) {
        this.getApiPointsList(null, circleString);
      }
      //OPEN NAVTAB IF IT WAS CLOSED
      if (isNetworkRdsTmcVisible && !isOpenNavTab) {
        toggleOpenNavTab(true);
      }
    } else {
      //otherwise load all points
      if (isNetworkRdsTmcVisible) {
        this.setPointList();
      }
    }
    if (isNetworkRdsTmcVisible) {
      this.setRoadslist();
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      mapPolygonReducer,
      isNetworkRdsTmcVisible,
      toggleOpenNavTab,
      isOpenNavTab,
      boundingBox,
      currentPointRdsTmc,
      currentSegmentRdsTmc,
      currentMapZoom,
    } = this.props;

    //CHECK IF BOUNDING BOX HAS CHANGED (ZOOM) BUT NO POLYGON IS DRAWN ON THE MAP
    // OR IF POLYGON OR CIRCLE HAS BEEN REMOVED
    // OR IF A LAYER HAS BEEN SELECTED AND DATA SHOULD BE LOADED AGAIN
    if (
      (prevProps.boundingBox !== this.props.boundingBox &&
        !mapPolygonReducer) ||
      (prevProps.mapPolygonReducer !== mapPolygonReducer &&
        !mapPolygonReducer) ||
      (!mapPolygonReducer &&
        prevProps.isNetworkRdsTmcVisible !== isNetworkRdsTmcVisible &&
        isNetworkRdsTmcVisible)
    ) {
      if (!currentPointRdsTmc && !currentSegmentRdsTmc) {
        this.setPointsDebounced();
      }
    }
    if (
      prevProps.isNetworkRdsTmcVisible !== isNetworkRdsTmcVisible &&
      isNetworkRdsTmcVisible
    ) {
      this.setRoadslist();
    }
    //CHECK IF A NEW CIRCLE OR POLYGON HAS BEEN DRAWN AND FILTER SELECTED LAYERS BY POLYGON
    // DO SAME IF THE NETWORK HAS BEEN JUST SELECTED AND POLYGON WAS ALREADY DRAWN
    if (
      (prevProps.mapPolygonReducer !== mapPolygonReducer &&
        mapPolygonReducer) ||
      (prevProps.isNetworkRdsTmcVisible !== isNetworkRdsTmcVisible &&
        isNetworkRdsTmcVisible) ||
      (prevProps.boundingBox !== this.props.boundingBox && mapPolygonReducer)
    ) {
      if (mapPolygonReducer && mapPolygonReducer.polygoncoordinates) {
        let polygonString = getCqLPolygonString(mapPolygonReducer);
        if (isNetworkRdsTmcVisible) {
          this.getApiPointsList(null, polygonString);
        }
        //OPEN NAVTAB IF IT WAS CLOSED
        if (isNetworkRdsTmcVisible && !isOpenNavTab) {
          toggleOpenNavTab(true);
        }
      } else if (
        mapPolygonReducer &&
        mapPolygonReducer.center &&
        mapPolygonReducer.radius
      ) {
        let circleString = getCqlCircleString(mapPolygonReducer);
        if (isNetworkRdsTmcVisible) {
          this.getApiPointsList(null, circleString);
        }
        //OPEN NAVTAB IF IT WAS CLOSED
        if (isNetworkRdsTmcVisible && !isOpenNavTab) {
          toggleOpenNavTab(true);
        }
      }
    }

    //FILTER POINTS AND SEGMENTS BY THEIR RELATIONSHIP
    if (
      prevProps.currentPointRdsTmc !== currentPointRdsTmc &&
      currentPointRdsTmc !== null
    ) {
      if (
        currentPointRdsTmc &&
        currentPointRdsTmc.properties &&
        currentPointRdsTmc.properties.road_lcd
      ) {
        let filterSegmentsByPoint =
          "seg_lcd=" +
          currentPointRdsTmc.properties.road_lcd +
          " OR " +
          "road_lcd=" +
          currentPointRdsTmc.properties.road_lcd;
        this.getApiRoadsList(filterSegmentsByPoint);
      }
    }

    if (
      prevProps.currentSegmentRdsTmc !== currentSegmentRdsTmc &&
      currentSegmentRdsTmc !== null
    ) {
      if (
        currentSegmentRdsTmc &&
        currentSegmentRdsTmc.properties &&
        (currentSegmentRdsTmc.properties.seg_lcd ||
          currentSegmentRdsTmc.properties.road_lcd)
      ) {
        let filterPointsBySegmentString = "";
        if (
          currentSegmentRdsTmc.properties.seg_lcd &&
          currentSegmentRdsTmc.properties.road_lcd
        ) {
          filterPointsBySegmentString =
            "road_lcd=" +
            currentSegmentRdsTmc.properties.seg_lcd +
            " OR " +
            "road_lcd=" +
            currentSegmentRdsTmc.properties.road_lcd;
        } else if (currentSegmentRdsTmc.properties.seg_lcd) {
          filterPointsBySegmentString =
            "road_lcd=" + currentSegmentRdsTmc.properties.seg_lcd;
        } else if (currentSegmentRdsTmc.properties.road_lcd) {
          filterPointsBySegmentString =
            "road_lcd=" + currentSegmentRdsTmc.properties.road_lcd;
        }
        this.getApiPointsList(null, filterPointsBySegmentString);
      }
    }
    //GET COMPLETE LIST WHEN DESELECT CURRENT ARC OR NODE
    if (
      prevProps.currentSegmentRdsTmc !== currentSegmentRdsTmc &&
      currentSegmentRdsTmc === null
    ) {
      let stringFilterByType = null;
      let newBounds = getBoundingBoxStringForBboxParam(boundingBox);

      if (currentMapZoom <= 11) {
        stringFilterByType = `subtype_id=1 AND INTERSECTS(geom,POLYGON((${newBounds})))`;
      } else if (currentMapZoom <= 15) {
        stringFilterByType = `(subtype_id=1 OR subtype_id = 2) AND INTERSECTS(geom,POLYGON((${newBounds})))`;
      } else if (currentMapZoom > 15 && currentMapZoom <= 16) {
        stringFilterByType = `(subtype_id=1 OR subtype_id = 2 OR subtype_id=3 OR subtype_id=4 OR subtype_id=5) AND INTERSECTS(geom,POLYGON((${newBounds})))`;
      } else {
        stringFilterByType = `INTERSECTS(geom,POLYGON((${newBounds})))`;
      }
      this.getApiPointsList(null, stringFilterByType);
    }
    if (
      prevProps.currentPointRdsTmc !== currentPointRdsTmc &&
      currentPointRdsTmc === null
    ) {
      this.getApiRoadsList(null);
    }
  };

  setPointList = () => {
    const {
      boundingBox,
      isNetworkRdsTmcVisible,
      currentMapZoom,
      setIsNetworkLoading,
      isSegmentsLoading,
    } = this.props;

    if (isNetworkRdsTmcVisible) {
      setIsNetworkLoading({
        isPointsLoading: true,
        isSegmentsLoading: isSegmentsLoading,
      });
      let stringFilterByType = null;
      let newBounds = getBoundingBoxStringForCqlFilter(boundingBox);

      if (currentMapZoom <= 11) {
        stringFilterByType = `subtype_id=1 AND INTERSECTS(geom,POLYGON((${newBounds})))`;
      } else if (currentMapZoom <= 15) {
        stringFilterByType = `(subtype_id=1 OR subtype_id = 2) AND INTERSECTS(geom,POLYGON((${newBounds})))`;
      } else if (currentMapZoom > 15 && currentMapZoom <= 16) {
        stringFilterByType = `(subtype_id=1 OR subtype_id = 2 OR subtype_id=3 OR subtype_id=4 OR subtype_id=5) AND INTERSECTS(geom,POLYGON((${newBounds})))`;
      } else {
        stringFilterByType = `INTERSECTS(geom,POLYGON((${newBounds})))`;
      }
      this.getApiPointsList(null, stringFilterByType);
    }
  };

  setRoadslist = () => {
    const { setIsNetworkLoading, isPointsLoading } = this.props;

    setIsNetworkLoading({
      isPointsLoading: isPointsLoading,
      isSegmentsLoading: true,
    });

    this.getApiRoadsList(null);
  };

  getApiPointsList = (bbox, cqlFilter) => {
    const {
      setPointsRdsTmcList,
      currentSegmentRdsTmc,
      filterPointsBySegment,
      setIsNetworkLoading,
    } = this.props;

    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures("points", null, null, null, null, bbox, cqlFilter)
        .pipe(
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "points"
            );
            let points = [];
            if (featureCollection.numberReturned > 0) {
              points = [...featureCollection.features];
            }
            setPointsRdsTmcList(points);
            if (currentSegmentRdsTmc) {
              filterPointsBySegment({ segment: currentSegmentRdsTmc });
            }
            setIsNetworkLoading({
              isPointsLoading: false,
              isSegmentsLoading: false,
            });
          }),
          catchError((error) => {
            console.error(error);
            setIsNetworkLoading({
              isPointsLoading: false,
              isSegmentsLoading: false,
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  getApiRoadsList = (cqlFilter) => {
    const { setSegmentsRdsTmcList, setIsNetworkLoading } = this.props;

    let filter = "have_network=true";

    if (cqlFilter) {
      filter = filter + " AND " + cqlFilter;
    }
    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures("roads", null, null, null, null, null, filter)
        .pipe(
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "roads"
            );
            let roads = [];
            if (featureCollection.numberReturned > 0) {
              roads = [...featureCollection.features];
            }
            setSegmentsRdsTmcList(roads);
            setIsNetworkLoading({
              isPointsLoading: false,
              isSegmentsLoading: false,
            });
          }),
          catchError((error) => {
            console.error(error);
            setIsNetworkLoading({
              isPointsLoading: false,
              isSegmentsLoading: false,
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  render() {
    return <div></div>;
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

LoadNetworkData.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  setCurrentPointRdsTmc,
  setCurrentSegmentRdsTmc,
  setSegmentsRdsTmcList,
  setPointsRdsTmcList,
  toggleOpenNavTab,
  filterPointsBySegment,
  setIsNetworkLoading,
};

const mapStateToProps = (state) => ({
  menuTabReducer: getNavTabSelectedTab(state),
  isNetworkRdsTmcVisible: isNetworkRdsTmcVisible(state),
  currentPointRdsTmc: getCurrentPointRdsTmc(state),
  currentSegmentRdsTmc: getCurrentSegmentRdsTmc(state),
  filteredSegments: getFilteredSegments(state),
  filteredPoints: getFilteredPoints(state),
  boundingBox: getBoundingBox(state),
  mapPolygonReducer: getMapPolygon(state),
  isOpenNavTab: isOpenNavTab(state),
  currentMapZoom: getCurrentZoom(state),
  isPointsLoading: isPointsLoading(state),
  isSegmentsLoading: isSegmentsLoading(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadNetworkData)
);
