import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { take, tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import * as moment from "moment";
import "./style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
//COMPONENTS
import TableTrafficTopFilters from "components/layout/tables/traffic-table/traffic-top-filter";
import TrafficGraph from "components/layout/tables/traffic-table/traffic-graph/index";
import ListTrafficTable from "components/layout/tables/traffic-table/traffic-list/index.js";
import { TrafficElaboratedData, Day } from "reducers/traffic/traffic.model";
//STORE
import {
  isOpenNavTab,
  getCurrentStation,
  getTrafficContexts,
  getTrafficCurrentArc,
  getTrafficMeasures,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedFilters,
} from "store";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";

//UTILS
import { getTrafficDataToBePlotted } from "utils/utils-traffic";

const FILTER_TARGET = "trafficFilters";
class TrafficTable extends Component {
  apiService;
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      prevData: null,
      measureType: "",
      defaultMeasure: null,
      currentMeasure: "",
      isOpenTable: false,
      currentElaboratedData: [],
      dayTypes: [],
      measureUnit: "",
      isGraphLoading: false,
      pickerMaxDateTime: null,
      dateStart: null,
      dateEnd: null,
    };
  }

  componentDidMount = () => {
    const { currentArc, contexts, measures, dateFrom, dateTo } = this.props;

    this.apiService = createApiService(this.context);

    const idsContexts = contexts.map((item) => {
      return item.contextId;
    });
    const vehicleClassesIds = ["equivalent"];
    const profile = true;

    const currentMeasure = measures.filter((item) => {
      return (
        item.measureGroupType === "TRAFFIC_DATA" ||
        item.measureGroupType === "TRAVEL_TIME_DATA"
      );
    })[0];
    if (dateFrom && dateTo) {
      this.setState({
        ...this.state,
        dateStart: dateFrom,
        dateEnd: dateTo,
      });
      let newDateStart = moment(dateFrom);
      let newDateEnd = moment(dateTo);
      let diff = newDateEnd.diff(newDateStart);
      let diffDuration = moment.duration(diff);
      let differentTime =
        diffDuration.days() * 60 * 24 +
        diffDuration.hours() * 60 +
        diffDuration.minutes();
      this.subscriptions$.push(
        this.apiService
          .getTrafficContextOrientedElaboratedByArch(
            currentArc.properties.archId,
            dateFrom.toISOString(),
            differentTime,
            idsContexts,
            vehicleClassesIds,
            currentMeasure.measureId,
            profile
          )
          .pipe(
            take(1),
            tap((data) => {
              let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
                data
              );
              let arrayPlotted = getTrafficDataToBePlotted(
                newCurrentElaboratedData,
                contexts,
                currentMeasure.measureUnitType
              );

              this.setState({
                ...this.state,
                pickerMaxDateTime: dateTo,
                data: arrayPlotted,
                defaultMeasure: measures[0].measureName,
                currentElaboratedData: newCurrentElaboratedData,
                measureUnit: currentMeasure.measureUnitType,
                isGraphLoading: true,
              });
            }),
            catchError((error) => {
              this.setState({
                ...this.state,
                pickerMaxDateTime: dateTo,
                defaultMeasure: measures[0].measureName,
                measureUnit: currentMeasure.measureUnitType,
                isGraphLoading: true,
                data: [],
              });
              console.error(error);

              return of(error);
            })
          )
          .subscribe(),
        this.apiService
          .getTrafficDays(
            moment(dateFrom).format("YYYY-MM-DD"),
            moment(dateTo).format("YYYY-MM-DD")
          )
          .pipe(
            take(1),
            tap((data) => {
              let newDay = Day.fromArrayREST(data);
              this.setState({
                ...this.state,
                dayTypes: newDay,
              });
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    } else {
      const graphOffset = 1440;
      const currentDate = moment().add(1, "hour").toDate();
      const newDateDayBack = new Date(
        currentDate.getTime() - 24 * 60 * 60 * 1000
      );

      if (currentArc && currentArc.properties && currentArc.properties.archId) {
        this.subscriptions$.push(
          this.apiService
            .getTrafficContextOrientedElaboratedByArch(
              currentArc.properties.archId,
              newDateDayBack.toISOString(),
              graphOffset,
              idsContexts,
              vehicleClassesIds,
              currentMeasure.measureId,
              profile
            )
            .pipe(
              take(1),
              tap((data) => {
                let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
                  data
                );
                let arrayPlotted = getTrafficDataToBePlotted(
                  newCurrentElaboratedData,
                  contexts,
                  currentMeasure.measureUnitType
                );

                this.setState({
                  ...this.state,
                  dateStart: newDateDayBack,
                  pickerMaxDateTime: currentDate,
                  dateEnd: currentDate,
                  data: arrayPlotted,
                  defaultMeasure: measures[0].measureName,
                  currentElaboratedData: newCurrentElaboratedData,
                  measureUnit: currentMeasure.measureUnitType,
                  isGraphLoading: true,
                });
              }),
              catchError((error) => {
                this.setState({
                  ...this.state,
                  dateStart: newDateDayBack,
                  pickerMaxDateTime: currentDate,
                  dateEnd: currentDate,
                  defaultMeasure: measures[0].measureName,
                  measureUnit: currentMeasure.measureUnitType,
                  isGraphLoading: true,
                  data: [],
                });
                console.error(error);

                return of(error);
              })
            )
            .subscribe(),
          this.apiService
            .getTrafficDays(
              moment(newDateDayBack).format("YYYY-MM-DD"),
              moment(currentDate).format("YYYY-MM-DD")
            )
            .pipe(
              take(1),
              tap((data) => {
                let newDay = Day.fromArrayREST(data);
                this.setState({
                  ...this.state,
                  dayTypes: newDay,
                });
              }),
              catchError((error) => {
                console.error(error);
                return of(error);
              })
            )
            .subscribe()
        );
      }
    }
  };

  onChangeDateStart = (value) => {
    const { defaultMeasure } = this.state;
    const {
      currentArc,
      contexts,
      measures,
      tableFilters,
      saveTableFilters,
    } = this.props;

    let newDateDayForward;

    if (moment(value).isSame(moment().add(1, "hour"), "day")) {
      newDateDayForward = moment().add(1, "hour").toDate();
    } else {
      newDateDayForward = new Date(value.getTime() + 24 * 60 * 60 * 1000);
    }
    let idsContexts = contexts.map((item) => {
      return item.contextId;
    });
    const vehicleClassesIds = ["equivalent"];
    const currentMeasure = measures
      .filter((item) => {
        return (
          item.measureGroupType === "TRAFFIC_DATA" ||
          item.measureGroupType === "TRAVEL_TIME_DATA"
        );
      })
      .find((item) => {
        return item.measureName === defaultMeasure;
      });
    const profile = true;
    const graphOffset = 1440;
    this.setState({
      ...this.state,
      isGraphLoading: false,
    });

    saveTableFilters(
      {
        ...tableFilters,
        dateFrom: value,
        dateTo: newDateDayForward,
      },
      FILTER_TARGET
    );

    this.subscriptions$.push(
      this.apiService
        .getTrafficContextOrientedElaboratedByArch(
          currentArc.properties.archId,
          value.toISOString(),
          graphOffset,
          idsContexts,
          vehicleClassesIds,
          currentMeasure.measureId,
          profile
        )
        .pipe(
          take(1),
          tap((data) => {
            let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
              data
            );
            let arrayPlotted = getTrafficDataToBePlotted(
              newCurrentElaboratedData,
              contexts,
              currentMeasure.measureUnitType
            );

            this.setState({
              ...this.state,
              dateStart: value,
              dateEnd: newDateDayForward,
              data: arrayPlotted,
              currentElaboratedData: newCurrentElaboratedData,
              measureUnit: currentMeasure.measureUnitType,
              isGraphLoading: true,
            });
          }),
          catchError((error) => {
            let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
              []
            );
            let arrayPlotted = getTrafficDataToBePlotted(
              newCurrentElaboratedData,
              contexts,
              currentMeasure.measureUnitType
            );
            this.setState({
              ...this.state,
              dateStart: value,
              dateEnd: newDateDayForward,
              data: arrayPlotted,
              measureUnit: currentMeasure.measureUnitType,
              isGraphLoading: true,
            });
            console.error(error);
            return of(error);
          })
        )
        .subscribe(),
      this.apiService
        .getTrafficDays(
          moment(new Date(value.getTime() - 24 * 60 * 60 * 1000)).format(
            "YYYY-MM-DD"
          ),
          moment(newDateDayForward).format("YYYY-MM-DD")
        )
        .pipe(
          take(1),
          tap((data) => {
            let newDay = Day.fromArrayREST(data);
            this.setState({
              dayTypes: newDay,
            });
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  onChangeDateEnd = (value) => {
    const { dateStart, defaultMeasure } = this.state;
    const {
      currentArc,
      contexts,
      measures,
      tableFilters,
      saveTableFilters,
    } = this.props;

    let newDateStart;
    if (moment(value).isBefore(moment(dateStart))) {
      newDateStart = moment(value);
    } else {
      newDateStart = moment(dateStart);
    }

    let newDateEnd = moment(value);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();

    let idsContexts = contexts.map((item) => {
      return item.contextId;
    });
    const vehicleClassesIds = ["equivalent"];
    const currentMeasure = measures
      .filter((item) => {
        return (
          item.measureGroupType === "TRAFFIC_DATA" ||
          item.measureGroupType === "TRAVEL_TIME_DATA"
        );
      })
      .find((item) => {
        return item.measureName === defaultMeasure;
      });
    const profile = true;
    const oneDayof15MinuteInterval = 97;
    this.setState({
      ...this.state,
      isGraphLoading: false,
    });

    saveTableFilters(
      {
        ...tableFilters,
        dateFrom: newDateStart.toDate(),
        dateTo: value,
      },
      FILTER_TARGET
    );
    this.subscriptions$.push(
      this.apiService
        .getTrafficContextOrientedElaboratedByArch(
          currentArc.properties.archId,
          dateStart.toISOString(),
          differentTime,
          idsContexts,
          vehicleClassesIds,
          currentMeasure.measureId,
          profile
        )
        .pipe(
          take(1),
          tap((data) => {
            let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
              data,
              differentTime
            );
            let arrayPlotted = getTrafficDataToBePlotted(
              newCurrentElaboratedData,
              contexts,
              currentMeasure.measureUnitType
            );
            if (arrayPlotted[0].data.length > oneDayof15MinuteInterval) {
              let newArrayPlotted = arrayPlotted.map((element) => {
                return {
                  ...element,
                  data: element.data.filter((item, index) => {
                    return index % 8 === 0;
                  }),
                };
              });
              this.setState({
                ...this.state,
                dateStart: newDateStart.toDate(),
                dateEnd: value,
                data: newArrayPlotted,
                currentElaboratedData: newCurrentElaboratedData,
                measureUnit: currentMeasure.measureUnitType,
                isGraphLoading: true,
              });
            } else {
              this.setState({
                ...this.state,
                dateStart: newDateStart.toDate(),
                dateEnd: value,
                data: arrayPlotted,
                currentElaboratedData: newCurrentElaboratedData,
                measureUnit: currentMeasure.measureUnitType,
                isGraphLoading: true,
              });
            }
          }),
          catchError((error) => {
            let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
              [],
              differentTime
            );
            let arrayPlotted = getTrafficDataToBePlotted(
              newCurrentElaboratedData,
              contexts,
              currentMeasure.measureUnitType
            );
            if (arrayPlotted[0].data.length > oneDayof15MinuteInterval) {
              let newArrayPlotted = arrayPlotted.map((element) => {
                return {
                  ...element,
                  data: element.data.filter((item, index) => {
                    return index % 8 === 0;
                  }),
                };
              });
              this.setState({
                ...this.state,
                dateEnd: value,
                data: newArrayPlotted,
                measureUnit: currentMeasure.measureUnitType,
              });
            } else {
              this.setState({
                ...this.state,
                dateEnd: value,
                data: arrayPlotted,
                measureUnit: currentMeasure.measureUnitType,
              });
            }
            console.error(error);
            return of(error);
          })
        )
        .subscribe(),
      this.apiService
        .getTrafficDays(
          moment(new Date(dateStart.getTime() - 24 * 60 * 60 * 1000)).format(
            "YYYY-MM-DD"
          ),
          moment(value).format("YYYY-MM-DD")
        )
        .pipe(
          take(1),
          tap((data) => {
            let newDay = Day.fromArrayREST(data);
            this.setState({
              dayTypes: newDay,
            });
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  handleSelectMeasure = (value) => {
    const { dateStart, dateEnd } = this.state;
    const { currentArc, contexts, measures } = this.props;

    let newDateStart = moment(dateStart);
    let newDateEnd = moment(dateEnd);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();

    const vehicleClassesIds = ["equivalent"];
    const profile = true;

    const currentMeasure = measures
      .filter((item) => {
        return (
          item.measureGroupType === "TRAFFIC_DATA" ||
          item.measureGroupType === "TRAVEL_TIME_DATA"
        );
      })
      .find((item) => {
        return item.measureName === value;
      });

    const idsContexts = contexts.map((item) => {
      return item.contextId;
    });

    this.subscriptions$.push(
      this.apiService
        .getTrafficContextOrientedElaboratedByArch(
          currentArc.properties.archId,
          dateStart.toISOString(),
          differentTime,
          idsContexts,
          vehicleClassesIds,
          currentMeasure.measureId,
          profile
        )
        .pipe(
          take(1),
          tap((data) => {
            let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
              data
            );
            let arrayPlotted = getTrafficDataToBePlotted(
              newCurrentElaboratedData,
              contexts,
              currentMeasure.measureUnitType
            );
            this.setState({
              ...this.state,
              data: arrayPlotted,
              defaultMeasure: value,
              currentElaboratedData: newCurrentElaboratedData,
              measureUnit: currentMeasure.measureUnitType,
            });
          }),
          catchError((error) => {
            let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
              []
            );
            let arrayPlotted = getTrafficDataToBePlotted(
              newCurrentElaboratedData,
              contexts,
              currentMeasure.measureUnitType
            );
            this.setState({
              ...this.state,
              data: arrayPlotted,
              defaultMeasure: value,
              measureUnit: currentMeasure.measureUnitType,
            });

            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  toggleTable = () => {
    this.setState({
      ...this.state,
      isOpenTable: true,
    });
  };

  toggleStatistics = () => {
    this.setState({
      ...this.state,
      isOpenTable: false,
    });
  };
  componentWillUnmount = () => {
    this.subscriptions$.forEach((x) => x.unsubscribe());
  };

  refreshData = () => {
    const { dateStart, defaultMeasure, dateEnd } = this.state;
    const { currentArc, contexts, measures } = this.props;

    let newDateStart = moment(dateStart);
    let newDateEnd = moment(dateEnd);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();

    let idsContexts = contexts.map((item) => {
      return item.contextId;
    });
    const vehicleClassesIds = ["equivalent"];
    const currentMeasure = measures
      .filter((item) => {
        return (
          item.measureGroupType === "TRAFFIC_DATA" ||
          item.measureGroupType === "TRAVEL_TIME_DATA"
        );
      })
      .find((item) => {
        return item.measureName === defaultMeasure;
      });
    const profile = true;
    const oneDayof15MinuteInterval = 97;

    this.subscriptions$.push(
      this.apiService
        .getTrafficContextOrientedElaboratedByArch(
          currentArc.properties.archId,
          dateStart.toISOString(),
          differentTime,
          idsContexts,
          vehicleClassesIds,
          currentMeasure.measureId,
          profile
        )
        .pipe(
          take(1),
          tap((data) => {
            let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
              data,
              differentTime
            );
            let arrayPlotted = getTrafficDataToBePlotted(
              newCurrentElaboratedData,
              contexts,
              currentMeasure.measureUnitType
            );
            if (arrayPlotted[0].data.length > oneDayof15MinuteInterval) {
              let newArrayPlotted = arrayPlotted.map((element) => {
                return {
                  ...element,
                  data: element.data.filter((item, index) => {
                    return index % 8 === 0;
                  }),
                };
              });
              this.setState({
                ...this.state,
                data: newArrayPlotted,
                currentElaboratedData: newCurrentElaboratedData,
                measureUnit: currentMeasure.measureUnitType,
              });
            } else {
              this.setState({
                ...this.state,
                data: arrayPlotted,
                currentElaboratedData: newCurrentElaboratedData,
                measureUnit: currentMeasure.measureUnitType,
              });
            }
          }),
          catchError((error) => {
            let newCurrentElaboratedData = TrafficElaboratedData.fromArrayREST(
              [],
              differentTime
            );
            let arrayPlotted = getTrafficDataToBePlotted(
              newCurrentElaboratedData,
              contexts,
              currentMeasure.measureUnitType
            );
            if (arrayPlotted[0].data.length > oneDayof15MinuteInterval) {
              let newArrayPlotted = arrayPlotted.map((element) => {
                return {
                  ...element,
                  data: element.data.filter((item, index) => {
                    return index % 8 === 0;
                  }),
                };
              });
              this.setState({
                ...this.state,
                data: newArrayPlotted,
                measureUnit: currentMeasure.measureUnitType,
              });
            } else {
              this.setState({
                ...this.state,
                data: arrayPlotted,
                measureUnit: currentMeasure.measureUnitType,
              });
            }
            console.error(error);
            return of(error);
          })
        )
        .subscribe(),
      this.apiService
        .getTrafficDays(
          moment(new Date(dateStart.getTime() - 24 * 60 * 60 * 1000)).format(
            "YYYY-MM-DD"
          ),
          moment(dateEnd).format("YYYY-MM-DD")
        )
        .pipe(
          take(1),
          tap((data) => {
            let newDay = Day.fromArrayREST(data);
            this.setState({
              ...this.state,
              dayTypes: newDay,
            });
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  render = () => {
    const {
      currentStation,
      toggleGenericTable,
      isOpenNav,
      currentArc,
      contexts,
    } = this.props;
    const {
      data,
      dateStart,
      dateEnd,
      defaultMeasure,
      isOpenTable,
      dayTypes,
      measureUnit,
      isGraphLoading,
      pickerMaxDateTime,
      currentElaboratedData,
    } = this.state;
    return (
      <div className="acr-table uk-flex uk-flex-column">
        <div className="navbar-placeholder"></div>
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <div className="grey-space"></div>
          <div className=" uk-width-1-2">
            <ul className=" uk-tab-page-secondary-large uk-tab uk-margin-remove">
              <li className="uk-active bottombar-title">
                Archi -
                {currentArc &&
                  currentArc.properties &&
                  currentArc.properties.archName}
              </li>
            </ul>
          </div>
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={() => toggleGenericTable("trafficTable")}
            ></span>
          </div>
        </div>
        <TableTrafficTopFilters
          className="uk-form-small uk-padding-small select-style"
          dateStart={dateStart}
          dateFormat={"dd/MM/yyyy HH:mm"}
          timeFormat={"HH:mm"}
          iconRatio={isOpenNav ? "ratio: 0.8;" : "ratio: 1.5"}
          dateEnd={dateEnd}
          changeDateStart={(data) => this.onChangeDateStart(data, data)}
          changeDateEnd={(data) => this.onChangeDateEnd(data, data)}
          isSameDate={
            dateStart &&
            dateEnd &&
            dateStart.getDate() === dateEnd.getDate() &&
            dateStart.getMonth() === dateEnd.getMonth() &&
            dateStart.getYear() === dateEnd.getYear()
              ? true
              : null
          }
          isStartDate={true}
          defaultMeasure={defaultMeasure}
          handleSelectMeasure={this.handleSelectMeasure}
          currentStation={currentStation}
          currentArc={currentArc}
          contexts={contexts}
          toggleTable={this.toggleTable}
          toggleStatistics={this.toggleStatistics}
          isOpenTable={isOpenTable}
          refreshData={this.refreshData}
          pickerMaxDateTime={pickerMaxDateTime}
        />
        {isOpenTable ? (
          <ListTrafficTable
            dateStart={dateStart}
            dateEnd={dateEnd}
            defaultMeasure={defaultMeasure}
            dayTypes={dayTypes}
            currentElaboratedData={currentElaboratedData}
            measureUnit={measureUnit}
          />
        ) : (
          <TrafficGraph
            dateStart={dateStart}
            dateEnd={dateEnd}
            defaultMeasure={defaultMeasure}
            data={data}
            measureUnit={measureUnit}
            isGraphLoading={isGraphLoading}
          />
        )}
      </div>
    );
  };
}

TrafficTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  saveTableFilters,
  resetTableFilters,
  toggleGenericTable,
};

const mapStateToProps = (state) => ({
  currentStation: getCurrentStation(state),
  isOpenNav: isOpenNavTab(state),
  contexts: getTrafficContexts(state),
  currentArc: getTrafficCurrentArc(state),
  measures: getTrafficMeasures(state),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrafficTable)
);
