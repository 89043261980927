import { CompositeLayer, IconLayer } from "deck.gl";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";
class NodesLayer extends CompositeLayer {
  renderLayers() {
    const {
      data,
      layout,
      visible,
      onClick,
      nodeCurrentReducer,
      isDrawingMode,
    } = this.props;

    return new IconLayer(
      this.getSubLayerProps({
        id: "icon-nodes",
        data: data,
        pickable: isDrawingMode ? false : true,
        ...DeckLayerUtil.loadOptions(),
        getIcon: (obj) => ({
          url: DeckLayerUtil.getIconNodes(obj, layout, nodeCurrentReducer),
          width: 40,
          height: 40,
          anchorX: 20,
          anchorY: 20,
        }),
        sizeScale: 4,
        showCluster: false,
        visible: visible,
        onClick: onClick,
        getPosition: (d) => d.geometry.coordinates,
        getSize: (data) =>
          (data && data.selected) ||
          (nodeCurrentReducer &&
            data.id === nodeCurrentReducer.id &&
            nodeCurrentReducer.selected)
            ? 12
            : 5,
      })
    );
  }
}

NodesLayer.layerName = "NodesLayer";

export default NodesLayer;
