import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getParkingIcon } from "utils/utils-parking";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ParkingsListComponent extends Component {
  toggleParkingDetails = (device) => {
    const { toggleDetails } = this.props;
    toggleDetails(device);
  };

  render() {
    const { filteredParkings } = this.props;

    return (
      <div className="uk-list margin-bottom50">
        {filteredParkings.map((parking, index) => {
          const parkingIcon = getParkingIcon(parking);
          return (
            <div
              key={index}
              className={
                "uk-flex uk-flex-row uk-flex-middle uk-flex-around uk-width-1-1 height-84"
              }
            >
              <li
                className={"uk-flex uk-flex-around uk-flex-middle uk-width-1-1"}
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleParkingDetails(parking);
                }}
              >
                <div className="uk-position-relative event-height-width-48">
                  <img
                    className={"img-status"}
                    alt={"parking_img"}
                    src={parkingIcon}
                  />
                </div>
                <div className="uk-flex uk-margin-small-left width-260">
                  <div className="uk-flex uk-flex-row">
                    <div className="uk-flex uk-flex-column headerName">
                      <div className="headerName">
                        {parking &&
                        parking.properties &&
                        parking.properties.parkingName
                          ? parking.properties.parkingName
                          : "-"}
                      </div>
                      <div className="uk-width-1-1 uk-margin-right inactive list-text location_info p-dot-2">
                        {parking &&
                        parking.properties &&
                        parking.properties.parkingAddress
                          ? parking.properties.parkingAddress
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ParkingsListComponent;
