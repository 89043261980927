import { PointsActions } from "reducers/graph/points/points.actions.js";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";
import { MapActions } from "reducers/map/map.actions";

const INITIAL_STATE = {
  points: [],
  pointsFiltered: [],
  current: null,
  currentMap: null,
  searchText: "",
  searchType: "",
  bounds: [],
};

function searchPoints(searchText) {
  return (point) =>
    (point &&
      String(point.properties.lcd) &&
      String(point.properties.lcd)
        .toLowerCase()
        .includes(searchText.toLowerCase())) ||
    (point &&
      point.properties &&
      point.properties.name1 &&
      point.properties.name1
        .toLowerCase()
        .includes(searchText.toLowerCase())) ||
    (point &&
      point.properties &&
      point.properties.road_number &&
      point.properties.road_number
        .toLowerCase()
        .includes(searchText.toLowerCase()));
}

export default function pointsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PointsActions.SET_LIST_POINTS:
      let pointsFiltered = action.payload.list;
      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "punti-tmc"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchPoints(searchText);
        pointsFiltered = action.payload.list.filter(searchFn);
      }
      return {
        ...state,
        points: action.payload.list,
        pointsFiltered: pointsFiltered,
      };
    case PointsActions.SET_CURRENT_POINT:
      let newarrPoint = [...state.points].map((item) => ({
        ...item,
        selected:
          item &&
          action.payload &&
          action.payload.point &&
          action.payload.point.id &&
          item.id === action.payload.point.id
            ? state.currentMap &&
              state.currentMap.id === action.payload.point.id
              ? item.selected
              : !item.selected
            : false,
      }));

      let newCurr;
      if (action.payload && action.payload.point) {
        newCurr = {
          ...action.payload.point,
          selected: !action.payload.point.selected,
        };
      } else {
        newCurr = action.payload.point;
      }

      return {
        ...state,
        current:
          state.current &&
          state.current.id &&
          action.payload &&
          action.payload.point &&
          action.payload.point.id &&
          state.current.id === action.payload.point.id
            ? null
            : newCurr,
        points: newarrPoint,
        currentMap: null,
      };
    case PointsActions.RESET_CURRENT_POINT_MAP:
      return {
        ...state,
        points: [],
        pointsFiltered: [],
        current: null,
        currentMap: null,
      };
    case PointsActions.SET_CURRENT_POINT_MAP:
      let newarrPointMap = [...state.points].map((item) => ({
        ...item,
        selected:
          item &&
          action.payload &&
          action.payload.point &&
          action.payload.point.id &&
          item.id === action.payload.point.id
            ? !item.selected
            : false,
      }));

      let newCurrMap = {
        ...action.payload.point,
        selected: !action.payload.point.selected,
      };

      return {
        ...state,
        currentMap:
          state.current &&
          state.current.id &&
          action.payload &&
          action.payload.point &&
          action.payload.point.id &&
          state.current.id === action.payload.point.id
            ? null
            : state.current && state.current.id
            ? null
            : newCurrMap,
        points: newarrPointMap,
        current:
          state.current &&
          state.current.id &&
          action.payload &&
          action.payload.point &&
          action.payload.point.id
            ? state.current.id === action.payload.point.id
              ? null
              : action.payload.point
            : action.payload.point,
      };
    case PointsActions.FILTER_POINTS_BY_SEGMENT:
      let searchSegment =
        action.payload &&
        action.payload.segment &&
        action.payload.segment.properties &&
        action.payload.segment.properties.road_lcd
          ? action.payload.segment.properties.road_lcd
          : null;
      let pointsFilteredBySegment = state.points;
      if (searchSegment) {
        pointsFilteredBySegment = state.points.filter(
          (item) =>
            item.properties.seg_lcd === searchSegment ||
            item.properties.road_lcd === searchSegment
        );
      }
      return {
        ...state,
        pointsFiltered: pointsFilteredBySegment,
      };
    case NavTabActions.SET_SEARCH:
      if (action.payload && action.payload.type === "punti-tmc") {
        let searchText =
          action.payload && action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const searchFn = searchPoints(searchText);
        let pointsFiltered = state.points.filter(searchFn);
        const searchType = action.payload.type;

        return {
          ...state,
          pointsFiltered,
          searchText,
          searchType,
        };
      } else {
        return { ...state };
      }
    default:
      return state;
  }
}

export const getPointsRdsTmc = (state) => state.points;
export const getCurrentPointRdsTmc = (state) => state.current;
export const getCurrentPointRdsTmcMap = (state) => state.currentMap;
export const getFilteredPoints = (state) => state.pointsFiltered;
