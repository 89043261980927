import React, { Component, createRef } from "react";
import "./style.less";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//COMPONENTS
import Loading from "components/shared/loading/loading";
import InfiniteScroll from "react-infinite-scroll-component";
//ASSETS
import map_point from "assets/icon/graph/icn_map_pin_green.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";

import { getCurrentPointRdsTmcMap } from "store";
const SCROLL_OFFSET = 25;

class PointRdsTmcList extends Component {
  constructor(props) {
    super(props);
    this.scrollDiv = createRef();
    this.state = {
      openDetail: false,
      currentOffset: SCROLL_OFFSET,
      nextPoints: [],
      currentListLen: 0,
    };
  }

  componentDidMount = () => {
    const { currentPointMap } = this.props;
    if (currentPointMap && currentPointMap.selected) {
      this.scrollSmoothHandler();
    }
  };

  componentDidUpdate = () => {
    const { currentPointMap } = this.props;
    if (currentPointMap && currentPointMap.selected) {
      this.scrollSmoothHandler();
    }
  };

  fetchMoreData = () => {
    const { currentOffset } = this.state;
    const { pointsList } = this.props;
    let newOffset = currentOffset + SCROLL_OFFSET;
    let fetchmore = pointsList.slice(0, newOffset);
    this.setState({
      nextPoints: fetchmore,
      currentOffset: newOffset,
    });
  };

  scrollSmoothHandler = () => {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  constructFetchedDOM = () => {
    const { nextPoints } = this.state;
    const { togglePointDetail, currentPointMap } = this.props;
    return (
      <div className="point-list-container">
        <div className="uk-list">
          {nextPoints &&
            nextPoints.map((point, index) => {
              return (
                <div
                  className="uk-flex uk-flex-row uk-flex-middle uk-flex-between"
                  key={index}
                >
                  <li
                    onClick={(event) => {
                      event.preventDefault();
                      togglePointDetail(point);
                    }}
                    id={point.id}
                    className={
                      currentPointMap &&
                      currentPointMap.selected &&
                      currentPointMap.id === point.id
                        ? "uk-flex uk-flex-between uk-active uk-flex-middle"
                        : "uk-flex uk-flex-between uk-flex-middle"
                    }
                  >
                    <div className="point-icon uk-flex uk-flex-middle uk-flex-center">
                      {currentPointMap &&
                      currentPointMap.selected &&
                      currentPointMap.id === point.id ? (
                        <div
                          className="parent uk-flex uk-flex-middle uk-flex-center"
                          ref={this.scrollDiv}
                        >
                          <div
                            className="uk-position-relative network-height-width-30 uk-flex uk-flex-middle uk-flex-center"
                            // uk-icon="icon: location"
                          >
                            <div className="circle-list-map"></div>
                            <img
                              alt="icn_diamond"
                              src={icn_diamond}
                              className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
                            ></img>
                            <img
                              alt="map_point"
                              src={map_point}
                              className="image2 uk-flex uk-flex-middle uk-flex-center"
                            ></img>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="uk-position-relative network-height-width-30 uk-flex uk-flex-middle uk-flex-center"
                          // uk-icon="icon: location"
                        >
                          <img
                            alt="map_point"
                            src={map_point}
                            className=" uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
                          ></img>
                        </div>
                      )}
                    </div>
                    <div className="uk-flex uk-margin-small-left">
                      <div className="uk-flex">
                        <div
                          className={
                            currentPointMap &&
                            currentPointMap.selected &&
                            currentPointMap.id === point.id
                              ? "uk-width-1-1 point-header-name"
                              : "uk-width-1-1 list-text point-header-name"
                          }
                        >
                          {point.properties.name1}
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { nextPoints, currentOffset, currentListLen } = state;
    const { pointsList } = props;
    let newState = {};

    if (currentListLen !== pointsList.length && pointsList.length === 0) {
      newState = {
        currentListLen: pointsList.length,
        currentOffset: SCROLL_OFFSET,
        nextPoints: [],
      };
    }
    if (currentListLen !== pointsList.length && pointsList.length > 0) {
      newState = {
        currentListLen: pointsList.length,
        currentOffset: pointsList.length - 1,
        nextPoints: pointsList.slice(0, pointsList.length),
      };
    }
    if (nextPoints.length === 0 && currentListLen > 0) {
      newState = {
        ...state,
        nextPoints: pointsList.slice(
          0,
          currentListLen > SCROLL_OFFSET ? currentOffset : currentListLen
        ),
      };
    }

    return newState;
  }

  renderInfiniteScroll = () => {
    const { nextPoints } = this.state;
    const { pointsList } = this.props;

    return (
      <InfiniteScroll
        dataLength={nextPoints.length || 0} //This is important field to render the next data
        next={this.fetchMoreData}
        hasMore={nextPoints.length === pointsList.length ? false : true}
        loader={
          <div>
            {pointsList.length ? "" /* <Loading /> */ : "Nessun elemento..."}
          </div>
        }
        scrollableTarget={"tabpanel-0"}
        className="inner-infinite-scroll"
      >
        {this.constructFetchedDOM()}
      </InfiniteScroll>
    );
  };

  render() {
    const { isPointsLoading } = this.props;

    return (
      <div>{isPointsLoading ? <Loading /> : this.renderInfiniteScroll()}</div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  currentPointMap: getCurrentPointRdsTmcMap(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PointRdsTmcList)
);
