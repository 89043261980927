import { ArcsActions } from "./arcs.actions";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";

const INITIAL_STATE = {
  arcs: [],
  arcsFiltered: [],
  arcsEvent: [],
  current: null,
  currentMap: [],
  searchText: "",
  searchType: "",
  bounds: [],
};

export function searchArcs(searchText) {
  return (arc) =>
    (arc &&
      arc.properties.arcid &&
      arc.properties.arcid.toString().toLowerCase().includes(searchText)) ||
    (arc &&
      arc.properties.name &&
      arc.properties.name.toLowerCase().includes(searchText));
}

export default function arcsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ArcsActions.SET_LIST_ARCS:
      let arcsFiltered = action.payload.list;
      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "archi"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchArcs(searchText);
        arcsFiltered = action.payload.list.filter(searchFn);
      }
      return {
        ...state,
        arcs: action.payload.list,
        arcsFiltered: arcsFiltered,
      };
    case ArcsActions.SET_LIST_ARCS_EVENT:
      return {
        ...state,
        arcsEvent: action.payload.list,
      };
    case ArcsActions.SET_CURRENT_ARC:
      let newarrArc = [...state.arcs].map((item) => ({
        ...item,
        properties: {
          ...item.properties,
          selected:
            item &&
            item.properties &&
            action.payload &&
            action.payload.arc &&
            action.payload.arc.properties &&
            action.payload.arc.properties.arcid &&
            item.properties.arcid === action.payload.arc.properties.arcid
              ? state.currentMap &&
                state.currentMap.properties &&
                state.currentMap.properties.arcid ===
                  action.payload.arc.properties.arcid
                ? false
                : true
              : false,
        },
      }));

      let newCurr;
      if (action.payload && action.payload.arc) {
        newCurr = {
          ...action.payload.arc,
          properties: {
            ...action.payload.arc.properties,
            selected: !action.payload.arc.properties.selected,
          },
        };
      } else {
        newCurr = action.payload.arc;
      }

      return {
        ...state,
        current:
          state.current &&
          state.current.properties &&
          state.current.properties.arcid &&
          action.payload &&
          action.payload.arc &&
          action.payload.arc.properties &&
          action.payload.arc.properties.arcid &&
          state.current.properties.arcid === action.payload.arc.properties.arcid
            ? null
            : newCurr,
        arcs: newarrArc,
        currentMap:
          action.payload.arc === null ? [] : [...state.currentMap, newCurr],
      };
    case ArcsActions.RESET_CURRENT_ARC_MAP:
      return {
        ...state,
        currentMap: [],
        current: null,
        arcs: [],
        arcsFiltered: [],
        arcsEvent: [],
      };

    case ArcsActions.SET_CURRENT_ARC_MAP:
      let currentArcMap = [];

      let newCurrMap;
      if (action.payload && action.payload.arc) {
        newCurrMap = {
          ...action.payload.arc,
          properties: {
            ...action.payload.arc.properties,
            selected: !action.payload.arc.properties.selected,
          },
        };
      } else {
        newCurrMap = action.payload.arc;
      }

      if (state && state.currentMap && state.currentMap.length > 0) {
        let alreadyExists = [...state.currentMap].find(
          (arc) => arc.properties.arcid === action.payload.arc.properties.arcid
        );
        if (alreadyExists) {
          currentArcMap = [...state.currentMap].filter(function (item1) {
            return item1.properties.arcid !== alreadyExists.properties.arcid;
          });
        } else {
          currentArcMap = [...state.currentMap, newCurrMap];
        }
      } else {
        currentArcMap = [newCurrMap];
      }

      let newarrArcMap = [...state.arcs].map((item) => {
        let exist = currentArcMap.find(
          (arc) => item.properties.arcid === arc.properties.arcid
        );

        if (exist) {
          return {
            ...item,
            properties: {
              ...item.properties,
              selected: true,
            },
          };
        } else {
          return {
            ...item,
            properties: {
              ...item.properties,
              selected: false,
            },
          };
        }
      });

      return {
        ...state,
        currentMap:
          state.currentMap &&
          state.currentMap.properties &&
          state.currentMap.properties.arcid &&
          action.payload &&
          action.payload.arc &&
          action.payload.arc.properties &&
          action.payload.arc.properties.arcid &&
          state.currentMap.properties.arcid ===
            action.payload.arc.properties.arcid
            ? []
            : currentArcMap,

        arcs: newarrArcMap,
        current:
          state.currentMap && state.currentMap.length !== 0
            ? null
            : action.payload.arc,
      };
    case NavTabActions.SET_SEARCH: {
      if (action.payload && action.payload.type === "archi") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const searchFn = searchArcs(searchText);
        let arcsFiltered = state.arcs.filter(searchFn);
        const searchType = action.payload.type;

        return {
          ...state,
          arcsFiltered,
          searchText,
          searchType,
        };
      } else {
        return { ...state };
      }
    }
    default:
      return state;
  }
}

export const getArcs = (state) => state.arcs;
export const getArcsEvent = (state) => state.arcsEvent;
export const getCurrentArc = (state) => state.current;
export const getCurrentArcMap = (state) => state.currentMap;
export const getFilteredArc = (state) => state.arcsFiltered;
