export const TripsActions = {
  GET_TRIPS: "APP/GET_TRIPS",
  SET_CURRENT_TRIP: "APP/SET_CURRENT_TRIP",
  RESET_CURRENT_TRIP: "APP/RESET_CURRENT_TRIP",
  RESET_TRIPS: "APP/RESET_TRIPS",
};

export const getTrips = (data) => (dispatch) => {
  dispatch({
    type: TripsActions.GET_TRIPS,
    payload: data,
  });
};

export const setCurrentTrip = (data) => (dispatch) => {
  dispatch({
    type: TripsActions.SET_CURRENT_TRIP,
    payload: data,
  });
};

export const resetCurrentTrip = () => (dispatch) => {
  dispatch({
    type: TripsActions.RESET_CURRENT_TRIP,
    payload: null,
  });
};

export const resetTrips = () => (dispatch) => {
  dispatch({
    type: TripsActions.RESET_TRIPS,
  });
};
