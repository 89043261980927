import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../../style.less";
import { tap, take, catchError } from "rxjs/operators";
import { of } from "rxjs";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
//import OrderTableColumn from "components/shared/order-table-column/order-table-column";
// ACTIONS
import {
  disableInsertEventButton,
  enableInsertEventButton,
} from "reducers/ui/modal/modal.actions";
import { setNewEvent, updateNewEvent } from "reducers/modal/modal.actions";
import {
  getNewEvent,
  getNewEventValid,
  getSelectModal,
  getTypeModal,
  getCurrentEvent,
  getTypeLocalization,
} from "store";
import { setCurrentEvent } from "reducers/events/events.actions";

class AttributesTab extends Component {
  listEv = [];
  subscriptions = [];
  constructor(props) {
    super(props);
    this.state = {
      sortConfig: { key: "", direction: "" },
      listAttributes: [],
      impactCalculations: false,
    };
  }

  notif = (msg, pos) => {
    const defMsg =
      "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp;Inserire un valore compreso tra 0 e 100</label>";
    UIkit.notification({
      message: msg ? msg : defMsg,
      status: "primary",
      pos: pos,
      timeout: 5000,
    });
  };

  handler = (e, selected) => {
    const { setNewEvent, newEvent } = this.props;
    const { listAttributes } = this.state;
    let eventAttribute = { ...selected };
    if (selected === "capacityRemaining") {
      if (e.target.value >= 0 && e.target.value <= 100) {
        setNewEvent("capacityRemaining", e.target.value / 100);
      } else {
        UIkit.notification.closeAll();
        this.notif(null, "bottom-center");
        setNewEvent("capacityRemaining", newEvent.capacityRemaining);
      }
    } else if (selected === "residualSpeed") {
      if (e.target.value >= 0 && e.target.value <= 100) {
        setNewEvent("residualSpeed", e.target.value / 100);
      } else {
        UIkit.notification.closeAll();
        this.notif(null, "bottom-center");
        setNewEvent("residualSpeed", newEvent.residualSpeed);
      }
    } else {
      if (
        eventAttribute.attributeType !== "integer" &&
        eventAttribute.attributeType !== "float" &&
        eventAttribute.attributeType !== "string"
      ) {
        eventAttribute.attributeValues.map((item) => {
          if (item.nameIT === e.target.value) {
            eventAttribute.attributeValue = item.name;
          } else if (e.target.value === "") {
            eventAttribute.attributeValue = null;
          }
          return item;
        });
      } else {
        eventAttribute.attributeValue = e.target.value;
      }
      setNewEvent("eventAttributes", eventAttribute);
      if (
        eventAttribute.attributeType !== "integer" &&
        eventAttribute.attributeType !== "float" &&
        eventAttribute.attributeType !== "string"
      ) {
        let italianName =
          eventAttribute &&
          eventAttribute.attributeValues &&
          eventAttribute.attributeValues.find(
            (item) => item.name === eventAttribute.attributeValue
          );

        let newListAttributes = [...listAttributes].map((item) => {
          if (item.attributeId === eventAttribute.attributeId) {
            if (e.target.value === "") {
              item.attributeValue = null;
            } else if (italianName) {
              item.attributeValue = italianName.nameIT;
            }
          }
          return item;
        });
        this.setState({
          listAttributes: newListAttributes,
        });
      } else {
        let newListAttributes = [...listAttributes].map((item) => {
          if (item.attributeId === eventAttribute.attributeId) {
            item.attributeValue = eventAttribute.attributeValue;
          }
          return item;
        });
        this.setState({
          listAttributes: newListAttributes,
        });
      }
    }
  };

  lanesImpact = (handlervalue, name) => {
    const { setNewEvent } = this.props;
    if (handlervalue && handlervalue !== "") {
      setNewEvent(name, handlervalue);
      this.checkImpact(null, handlervalue);
    }
  };

  checkImpact = (arrLanes, impact) => {
    const { setNewEvent, newEvent, selectModal } = this.props;

    let typeEv = "";
    let underTypeEv = "";
    let trafficConstrictionType = "";

    //provvisorio prima del grafo

    let originalNumberOfLanes;
    if (newEvent.originalNumberOfLanes === 0 && arrLanes) {
      originalNumberOfLanes = arrLanes.length - 1;
    } else {
      originalNumberOfLanes = newEvent.originalNumberOfLanes;
    }

    typeEv = selectModal.types[newEvent.typeEvent].typeId;
    underTypeEv =
      selectModal.types[newEvent.typeEvent].subTypes[newEvent.underTypeEvent]
        .subTypeId;
    if (impact) {
      impact === "Totale"
        ? (trafficConstrictionType = "CARRIAGEWAY_BLOCKED")
        : (trafficConstrictionType = "CARRIAGEWAY_PARTIALLY_OBSTRUCTED");
      setNewEvent("trafficConstrictionType", trafficConstrictionType);
    }
    setNewEvent("lanesImpact", impact);
    let lanesRestricted = arrLanes
      ? arrLanes.length === originalNumberOfLanes + 1
        ? newEvent.originalNumberOfLanes === 0
          ? arrLanes.length - 1
          : newEvent.originalNumberOfLanes
        : arrLanes.length
      : newEvent.lanes.length === originalNumberOfLanes + 1
      ? newEvent.originalNumberOfLanes
      : newEvent.lanes.length;
    let lanesOp = originalNumberOfLanes - lanesRestricted;

    setNewEvent("numberOfLanesRestricted", lanesRestricted);
    setNewEvent("numberOfOperationalLanes", lanesOp);

    this.apiImpact(
      typeEv,
      underTypeEv,
      trafficConstrictionType,
      lanesRestricted,
      originalNumberOfLanes
    );
  };

  apiImpact = (
    typeEv,
    underTypeEv,
    trafficConstrictionType,
    lanesRestricted,
    originalNumberOfLanes
  ) => {
    const { setNewEvent, newEvent } = this.props;
    this.subscriptions.push(
      this.apiService
        .getConfigImpact(typeEv, underTypeEv, {
          trafficConstrictionType:
            trafficConstrictionType === ""
              ? newEvent.trafficConstrictionType
              : trafficConstrictionType,
          numberOfLanesRestricted: lanesRestricted,
          originalNumberOfLanes: originalNumberOfLanes,
        })
        .pipe(
          take(1),
          tap((x) => {
            if (x && !x.error) {
              setNewEvent("capacityRemaining", x.capacityRemaining);
              setNewEvent("residualSpeed", x.speedRemaining);
            } else {
              console.log(x);
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  lanesFn = (handlervalue, name) => {
    const { setNewEvent, newEvent } = this.props;

    if (handlervalue && handlervalue !== "") {
      let arrLanes = [];
      if (newEvent.lanes && newEvent.lanes.length > 0) {
        const index = newEvent.lanes.findIndex((x) => x === handlervalue);

        if (index >= 0) {
          if (
            newEvent.lanes.find((x) => x === "All") &&
            handlervalue !== "All"
          ) {
            arrLanes = [...newEvent.lanes];
            arrLanes.splice(index, 1);
            const indexAll = arrLanes.findIndex((x) => x === "All");
            arrLanes.splice(indexAll, 1);
          } else {
            arrLanes = [...newEvent.lanes];
            arrLanes.splice(index, 1);
          }
        } else {
          if (
            !newEvent.lanes.find((x) => x === "All") &&
            handlervalue !== "All" &&
            newEvent.lanes.length + 1 === newEvent.originalNumberOfLanes
          ) {
            arrLanes = [...newEvent.lanes, "All", handlervalue];
          } else {
            arrLanes = [...newEvent.lanes, handlervalue];
          }
        }
      } else {
        arrLanes.push(handlervalue);
        if (
          !newEvent.lanes.find((x) => x === "All") &&
          handlervalue !== "All" &&
          newEvent.originalNumberOfLanes === 1
        ) {
          arrLanes = ["All", "1"];
        }
      }

      if (handlervalue === "All") {
        if (arrLanes.find((x) => x === "All")) {
          switch (newEvent.originalNumberOfLanes) {
            case 1:
              arrLanes = ["All", "1"];
              break;
            case 2:
              arrLanes = ["All", "1", "2"];
              break;
            case 3:
              arrLanes = ["All", "1", "2", "3"];
              break;
            case 4:
              arrLanes = ["All", "1", "2", "3", "4"];
              break;
            case 5:
              arrLanes = ["All", "1", "2", "3", "4", "5"];
              break;
            case 6:
              arrLanes = ["All", "1", "2", "3", "4", "5", "6"];
              break;

            default:
              arrLanes = [];
              break;
          }
        } else {
          arrLanes = [];
        }
      }
      setNewEvent(name, arrLanes);
      this.checkImpact(arrLanes);
    }
  };

  disableButton = () => {
    const {
      disableInsertEventButton,
      enableInsertEventButton,
      newEventValid,
      newEvent,
    } = this.props;
    if (
      newEventValid.eventAttributes &&
      newEventValid.lanes &&
      newEventValid.impactLanes &&
      newEvent &&
      newEvent.stateEvent !== "closed"
    ) {
      return enableInsertEventButton();
    } else return disableInsertEventButton();
  };

  componentDidMount = () => {
    const {
      newEvent,
      selectModal,
      setNewEvent,
      typeModal,
      selectedLocalization,
    } = this.props;
    const { listAttributes } = this.state;
    this.apiService = createApiService(this.context);
    let type = "";
    let underType = "";
    type = selectModal.types[newEvent.typeEvent].typeId;
    underType =
      selectModal.types[newEvent.typeEvent].subTypes[newEvent.underTypeEvent]
        .subTypeId;
    this.subscriptions.push(
      this.apiService
        .getConfigAttributes(type, underType)
        .pipe(
          take(1),
          tap((x) => {
            if (x && !x.error) {
              setNewEvent("eventAttributes", x);
              if (newEvent.eventAttributes) {
                x.map((item, index) => {
                  let italianName = newEvent.eventAttributes[
                    index
                  ].attributeValues.find(
                    (item) =>
                      item.name === newEvent.eventAttributes[index].newValue
                  );
                  if (italianName) {
                    item.attributeValue = italianName.nameIT;
                  } else {
                    item.attributeValue =
                      newEvent.eventAttributes[index].newValue;
                  }
                  return item;
                });
              }
              this.setState({ listAttributes: x });
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
    if (
      (typeModal === "modifyEvent" && newEvent.checkIfEventIsModified) ||
      (typeModal !== "modifyEvent" && !newEvent.checkIfEventIsModified)
    ) {
      let maxoriginalNumberOfLanes = 0;
      let maxEstesa = 0;
      let maxPuntuale = 0;
      let maxRDSPuntuale = 0;

      let maxRDSEstesa = 0;
      setNewEvent("checkIfEventIsModified", false);
      if (
        newEvent.listOfConfirmedArcsEstesa.length > 0 &&
        selectedLocalization === "Cartografica Estesa"
      ) {
        maxEstesa = Math.max(
          ...newEvent.listOfConfirmedArcsEstesa.map(
            (o) => o.properties.lanes || 0
          ),
          0
        );
        maxoriginalNumberOfLanes = maxEstesa;
      }
      if (
        newEvent.confirmedArcPuntuale !== null &&
        newEvent.confirmedArcPuntuale.properties &&
        newEvent.confirmedArcPuntuale.properties.lanes &&
        selectedLocalization === "Cartografica Puntuale"
      ) {
        maxPuntuale = newEvent.confirmedArcPuntuale.properties.lanes;
        maxoriginalNumberOfLanes = maxPuntuale;
      }
      if (
        newEvent.arcListRDSPuntuale.length > 0 &&
        selectedLocalization === "Rete RDS-TMC Puntuale"
      ) {
        maxRDSPuntuale = Math.max(
          ...newEvent.arcListRDSPuntuale.map((o) => o.lane || 0),
          0
        );
        maxoriginalNumberOfLanes = maxRDSPuntuale;
      }
      if (
        newEvent.arcListRDSEstesa.length > 0 &&
        selectedLocalization === "Rete RDS-TMC Estesa"
      ) {
        maxRDSEstesa = Math.max(
          ...newEvent.arcListRDSEstesa.map((o) => o.lane || 0),
          0
        );
        maxoriginalNumberOfLanes = maxRDSEstesa;
      }
      if (maxoriginalNumberOfLanes === 0 && !newEvent.impactCalculations) {
        setNewEvent("lanes", []);
        this.checkImpact([]);
        setNewEvent("impactCalculations", true);
        setNewEvent("originalNumberOfLanes", maxoriginalNumberOfLanes);
      } else if (maxoriginalNumberOfLanes !== newEvent.originalNumberOfLanes) {
        setNewEvent("originalNumberOfLanes", maxoriginalNumberOfLanes);
        if (maxoriginalNumberOfLanes === 1) {
          setNewEvent("lanes", ["All", "1"]);
          this.checkImpact(["All", "1"]);
        } else if (maxoriginalNumberOfLanes === 2) {
          setNewEvent("lanes", ["All", "1", "2"]);
          this.checkImpact(["All", "1", "2"]);
        } else if (maxoriginalNumberOfLanes === 3) {
          setNewEvent("lanes", ["All", "1", "2", "3"]);
          this.checkImpact(["All", "1", "2", "3"]);
        } else if (maxoriginalNumberOfLanes === 4) {
          setNewEvent("lanes", ["All", "1", "2", "3", "4"]);
          this.checkImpact(["All", "1", "2", "3", "4"]);
        } else if (maxoriginalNumberOfLanes === 5) {
          setNewEvent("lanes", ["All", "1", "2", "3", "4", "5"]);
          this.checkImpact(["All", "1", "2", "3", "4", "5"]);
        } else if (maxoriginalNumberOfLanes === 6) {
          setNewEvent("lanes", ["All", "1", "2", "3", "4", "5", "6"]);
          this.checkImpact(["All", "1", "2", "3", "4", "5", "6"]);
        }
        setNewEvent("impactCalculations", false);
      }
    }
    let newListAttributes = [...listAttributes].map((item, index) => {
      if (
        newEvent &&
        newEvent.eventAttributes[index] &&
        newEvent.eventAttributes[index].newValue
      ) {
        item.attributeValue = newEvent.eventAttributes[index].newValue;
      }
      return item;
    });
    this.setState({ listAttributes: newListAttributes });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { typeModal, newEvent } = this.props;
    const { listAttributes } = this.state;

    if (
      typeModal === "modifyEvent" &&
      prevState.listAttributes.length !== listAttributes.length
    ) {
      let newListAttributes = [...listAttributes].map((item, index) => {
        if (
          newEvent &&
          newEvent.eventAttributes[index] &&
          newEvent.eventAttributes[index].newValue &&
          (item.attributeType === "enum" || item.attributeType === "boolean")
        ) {
          item.attributeValue =
            item.attributeValues &&
            item.attributeValues.find(
              (item) => item.name === newEvent.eventAttributes[index].newValue
            ).nameIT;
        } else if (
          newEvent &&
          newEvent.eventAttributes[index] &&
          newEvent.eventAttributes[index].newValue
        ) {
          item.attributeValue = newEvent.eventAttributes[index].newValue;
        }
        return item;
      });
      this.setState({ listAttributes: newListAttributes });
    }
  };

  render() {
    const { newEvent } = this.props;
    const { listAttributes } = this.state;
    this.disableButton();
    return (
      <div className="container-attributes">
        <form className="uk-form-horizontal padding-form small-form">
          <div className="title-attributes">ATTRIBUTI</div>
          {listAttributes.map((selected, index) => {
            return (
              <div
                className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin"
                key={index}
              >
                <div className="uk-width-3-4">
                  <label className="uk-form-label width-information-add">
                    <input
                      type="checkbox"
                      className="uk-checkbox uk-margin-right"
                      checked={
                        (newEvent &&
                          newEvent.eventAttributes &&
                          newEvent.eventAttributes[index] &&
                          newEvent.eventAttributes[index].newValue) ||
                        ""
                      }
                      readOnly={true}
                      key={index}
                      disabled={newEvent && newEvent.stateEvent === "closed"}
                    />
                    {selected.attributeNameIT}
                  </label>
                  <div className="uk-form-controls margin-information-add">
                    {selected.attributeType === "enum" ? (
                      <select
                        id={selected.attributeName}
                        name={selected.attributeNameIT}
                        className="uk-select"
                        onChange={(e) => this.handler(e, selected)}
                        key={index}
                        disabled={newEvent && newEvent.stateEvent === "closed"}
                        value={
                          selected.attributeValue ? selected.attributeValue : ""
                        }
                      >
                        <option></option>
                        {selected && selected.attributeValues
                          ? selected.attributeValues
                              // .split(",")
                              .map((item, index) => (
                                <option key={index} value={item.nameIT}>
                                  {item.nameIT}
                                </option>
                              ))
                          : ""}
                      </select>
                    ) : selected.attributeType === "boolean" ? (
                      <select
                        id={selected.attributeName}
                        name={selected.attributeNameIT}
                        className="uk-select"
                        onChange={(e) => this.handler(e, selected)}
                        key={index}
                        disabled={newEvent && newEvent.stateEvent === "closed"}
                        value={
                          selected.attributeValue ? selected.attributeValue : ""
                        }
                      >
                        <option></option>
                        {selected && selected.attributeValues
                          ? selected.attributeValues
                              // .split(",")
                              .map((item, index) => (
                                <option key={index} value={item.nameIT}>
                                  {item.nameIT}
                                </option>
                              ))
                          : ""}
                      </select>
                    ) : (
                      <input
                        type={
                          selected.attributeType === "string"
                            ? "text"
                            : "number"
                        }
                        id={selected.attributeName}
                        placeholder={
                          selected.attributeType === "string" ? "" : "0-1000"
                        }
                        name={selected.attributeNameIT}
                        className="uk-input"
                        min="0"
                        max="1000"
                        key={index}
                        onChange={(e) => this.handler(e, selected)}
                        value={
                          selected.attributeValue ? selected.attributeValue : ""
                        }
                        disabled={newEvent && newEvent.stateEvent === "closed"}
                      ></input>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="title-attributes uk-margin-large-top">IMPATTO</div>

          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-1">
              <label className="uk-form-label width-information-add uk-margin-remove-top">
                Corsie coinvolte
                <span
                  uk-tooltip="title: Le corsie sono numerate a partire dalla più esterna (vicino alla banchina) verso la più interna (vicino alla mediana)."
                  className="uk-icon height-width-24 uk-margin-small-left cursor-pointer"
                  uk-icon="icon: acr-interface--info"
                ></span>
              </label>
              <div className="uk-form-controls margin-information-add">
                {newEvent.originalNumberOfLanes === 1 ||
                newEvent.originalNumberOfLanes === 2 ||
                newEvent.originalNumberOfLanes === 3 ||
                newEvent.originalNumberOfLanes === 4 ||
                newEvent.originalNumberOfLanes === 5 ||
                newEvent.originalNumberOfLanes === 6 ? (
                  <label>
                    <input
                      type="checkbox"
                      className="uk-checkbox uk-margin-right"
                      checked={newEvent.lanes.find((x) => x === "All") || ""}
                      onChange={() => {
                        this.lanesFn("All", "lanes");
                      }}
                      disabled={newEvent && newEvent.stateEvent === "closed"}
                    />{" "}
                    Tutte le corsie
                  </label>
                ) : (
                  ""
                )}
                {newEvent.originalNumberOfLanes === 6 ? (
                  <label>
                    <input
                      type="checkbox"
                      className="uk-checkbox uk-margin-left uk-margin-right"
                      checked={newEvent.lanes.find((x) => x === "6") || ""}
                      onChange={() => {
                        this.lanesFn("6", "lanes");
                      }}
                      disabled={newEvent && newEvent.stateEvent === "closed"}
                    />{" "}
                    6^ corsia
                  </label>
                ) : (
                  ""
                )}
                {newEvent.originalNumberOfLanes === 5 ||
                newEvent.originalNumberOfLanes === 6 ? (
                  <label>
                    <input
                      type="checkbox"
                      className="uk-checkbox uk-margin-left uk-margin-right"
                      checked={newEvent.lanes.find((x) => x === "5") || ""}
                      onChange={() => {
                        this.lanesFn("5", "lanes");
                      }}
                      disabled={newEvent && newEvent.stateEvent === "closed"}
                    />{" "}
                    5^ corsia
                  </label>
                ) : (
                  ""
                )}
                {newEvent.originalNumberOfLanes === 4 ||
                newEvent.originalNumberOfLanes === 5 ||
                newEvent.originalNumberOfLanes === 6 ? (
                  <label>
                    <input
                      type="checkbox"
                      className="uk-checkbox uk-margin-left uk-margin-right"
                      checked={newEvent.lanes.find((x) => x === "4") || ""}
                      onChange={() => {
                        this.lanesFn("4", "lanes");
                      }}
                      disabled={newEvent && newEvent.stateEvent === "closed"}
                    />{" "}
                    4^ corsia
                  </label>
                ) : (
                  ""
                )}
                {newEvent.originalNumberOfLanes === 3 ||
                newEvent.originalNumberOfLanes === 4 ||
                newEvent.originalNumberOfLanes === 5 ||
                newEvent.originalNumberOfLanes === 6 ? (
                  <label>
                    <input
                      type="checkbox"
                      className="uk-checkbox uk-margin-left uk-margin-right"
                      checked={newEvent.lanes.find((x) => x === "3") || ""}
                      onChange={() => {
                        this.lanesFn("3", "lanes");
                      }}
                      disabled={newEvent && newEvent.stateEvent === "closed"}
                    />{" "}
                    3^ corsia
                  </label>
                ) : (
                  ""
                )}
                {newEvent.originalNumberOfLanes === 2 ||
                newEvent.originalNumberOfLanes === 3 ||
                newEvent.originalNumberOfLanes === 4 ||
                newEvent.originalNumberOfLanes === 5 ||
                newEvent.originalNumberOfLanes === 6 ? (
                  <label>
                    <input
                      type="checkbox"
                      className="uk-checkbox uk-margin-left uk-margin-right"
                      checked={newEvent.lanes.find((x) => x === "2") || ""}
                      onChange={() => {
                        this.lanesFn("2", "lanes");
                      }}
                      disabled={newEvent && newEvent.stateEvent === "closed"}
                    />{" "}
                    2^ corsia
                  </label>
                ) : (
                  ""
                )}
                {newEvent.originalNumberOfLanes === 1 ||
                newEvent.originalNumberOfLanes === 2 ||
                newEvent.originalNumberOfLanes === 3 ||
                newEvent.originalNumberOfLanes === 4 ||
                newEvent.originalNumberOfLanes === 5 ||
                newEvent.originalNumberOfLanes === 6 ? (
                  <label>
                    <input
                      type="checkbox"
                      className="uk-checkbox uk-margin-left uk-margin-right"
                      checked={newEvent.lanes.find((x) => x === "1") || ""}
                      onChange={() => {
                        this.lanesFn("1", "lanes");
                      }}
                      disabled={newEvent && newEvent.stateEvent === "closed"}
                    />{" "}
                    1^ corsia
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-3-4">
              <label className="uk-form-label width-information-add uk-margin-remove-top">
                Impatto sulle corsie
              </label>
              <div className="uk-form-controls uk-flex uk-flex-row">
                <label>
                  <input
                    type="radio"
                    className="uk-radio uk-margin-right"
                    checked={newEvent.impactLanes === "Totale" || false}
                    name="radioAttr1"
                    onChange={() => {
                      this.lanesImpact("Totale", "impactLanes");
                    }}
                    disabled={newEvent && newEvent.stateEvent === "closed"}
                  />
                  Totale
                </label>
                <label>
                  <input
                    type="radio"
                    className="uk-radio uk-margin-left uk-margin-right"
                    checked={newEvent.impactLanes === "Parziale" || false}
                    name="radioAttr2"
                    onChange={() => {
                      this.lanesImpact("Parziale", "impactLanes");
                    }}
                    disabled={newEvent && newEvent.stateEvent === "closed"}
                  />
                  Parziale
                </label>
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin-large-top">
            <div className="uk-width-1-3">
              <label className="uk-form-label width-residual-attributes">
                Capacità residua
              </label>
              <div className="uk-form-controls margin-residual-attributes">
                <input
                  type="number"
                  id="capacityRemaining"
                  name={"capacityRemaining"}
                  className="uk-input width-150 uk-margin-small-right"
                  min="0"
                  max="100"
                  value={Math.round(newEvent.capacityRemaining * 100)}
                  onChange={(e) => this.handler(e, "capacityRemaining")}
                ></input>
                %
              </div>
            </div>
            <div className="uk-width-1-3">
              <label className="uk-form-label width-residual-attributes">
                Velocità massima residua
              </label>
              <div className="uk-form-controls margin-residual-attributes">
                <input
                  type="number"
                  id="residualSpeed"
                  name={"residualSpeed"}
                  className="uk-input width-150 uk-margin-small-right"
                  min="0"
                  max="100"
                  value={Math.round(newEvent.residualSpeed * 100)}
                  onChange={(e) => this.handler(e, "residualSpeed")}
                ></input>
                %
              </div>
            </div>
            <div className="uk-width-1-3"></div>
          </div>
        </form>
      </div>
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

AttributesTab.contextType = EnvironmentContext;

const mapDispatchToProps = {
  disableInsertEventButton,
  enableInsertEventButton,
  setCurrentEvent,
  updateNewEvent,
  setNewEvent,
};

const mapStateToProps = (state) => ({
  newEvent: getNewEvent(state),
  newEventValid: getNewEventValid(state),
  currentEvent: getCurrentEvent(state),
  typeModal: getTypeModal(state),
  selectModal: getSelectModal(state),
  selectedLocalization: getTypeLocalization(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AttributesTab)
);
