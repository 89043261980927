import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
import createApiService from "services/api.service";
import { tap, catchError, finalize, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import EnvironmentContext from "environment-context";
import * as turf from "@turf/turf";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";

//COMPONENT
import ModalMap from "components/layout/modal/event/new-event/modal-map";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import SelectedArcsTable from "./selected-arcs-table";
import CloseArcsTable from "./close-arcs-table";
//ACTIONS
import {
  getNewEvent,
  getNewEventValid,
  getCurrentEvent,
  getTypeModal,
  getBoundingBoxCartografica,
} from "store";
import { setNewEvent, updateNewEvent } from "reducers/modal/modal.actions";
import {
  enableForwardEventButton,
  disableForwardEventButton,
} from "reducers/ui/modal/modal.actions";
// MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";

class LocalizationCartograficaPuntualeEstesa extends Component {
  subscriptions = [];
  apiService;
  constructor(props) {
    super(props);
    this.state = {
      modalMapWidth: true,
      listOfCloseArcs: [],
      filteredListOfCloseArcs: [],
      selectedArcToAdd: null,
      selectedArcToAddIndex: null,
      listOfConfirmedArcs: [],
      filterText: "",
      isRoutingLoading: false,
      shortestDistancePoints: [],
      btnRouting: true,
    };
  }

  componentDidMount = () => {
    const { newEvent } = this.props;

    // INITIALISE STATE FROM REDUCER
    if (newEvent) {
      this.setState({
        ...this.state,
        filterText: "",
        listOfCloseArcs: newEvent.listOfCloseArcsEstesa,
        filteredListOfCloseArcs: newEvent.listOfCloseArcsEstesa,
        listOfConfirmedArcs: newEvent.listOfConfirmedArcsEstesa,
        selectedArcToAdd: newEvent.selectedArcEstesa,
        selectedArcToAddIndex: newEvent.selectedArcToAddIndexEstesa,
      });
    }

    this.apiService = createApiService(this.context);
  };

  componentDidUpdate = (prevProps) => {
    const { newEvent, typeModal, setNewEvent } = this.props;

    if (
      (newEvent &&
        prevProps.newEvent &&
        prevProps.newEvent.positionEstesa &&
        newEvent.positionEstesa &&
        prevProps.newEvent.positionEstesa !== newEvent.positionEstesa &&
        newEvent.positionEstesa.longitude &&
        newEvent.positionEstesa.latitude) ||
      (!prevProps.newEvent.positionEstesa &&
        newEvent.positionEstesa &&
        newEvent.positionEstesa.longitude &&
        newEvent.positionEstesa.latitude)
    ) {
      this.setState({
        ...this.state,
        filterText: "",
      });
      let center = [
        newEvent.positionEstesa.longitude,
        newEvent.positionEstesa.latitude,
        newEvent.positionEstesa.longitude,
        newEvent.positionEstesa.latitude,
      ];
      let radius = 50; // in meters
      var options = {
        steps: 5,
        units: "meters",
        options: {},
      };
      var polygon = turf.circle(center, radius, options);
      const bbox = turf.bbox(polygon);
      this.getApiEstesaList(bbox);
    }

    if (
      newEvent.listOfConfirmedArcsEstesa.length !==
      prevProps.newEvent.listOfConfirmedArcsEstesa.length
    ) {
      this.setState({
        ...this.state,
        shortestDistancePoints: [],
      });
    }

    if (
      typeModal === "modifyEvent" &&
      prevProps.newEvent.listOfConfirmedArcsEstesa.length !==
        this.props.newEvent.listOfConfirmedArcsEstesa.length
    ) {
      setNewEvent("checkIfEventIsModified", true);
    }
  };

  getApiEstesaList = (point) => {
    if (point) {
      let bbox = point.map((item) => String(item)).join(",");
      bbox = bbox.concat(",EPSG:4326");

      this.subscriptions.push(
        this.apiService
          .getGeoserverFeatures("road_sections", null, null, null, null, bbox)
          .pipe(
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "road_sections"
              );
              let road_sections = [];
              if (featureCollection.numberReturned > 0) {
                road_sections = [...featureCollection.features];
              }
              road_sections = road_sections.map((item) => {
                return {
                  ...item,
                  properties: {
                    ...item.properties,
                    radiusOfInfluence: 1000,
                  },
                };
              });
              this.updateCloseArcList(road_sections);
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  updateCloseArcList = (road_sections) => {
    const { newEvent, setNewEvent } = this.props;

    this.setState({
      ...this.state,
      listOfCloseArcs: road_sections,
      filteredListOfCloseArcs: road_sections,
      selectedArcToAdd: null,
      selectedArcToAddIndex: null,
    });

    if (newEvent) {
      setNewEvent("listOfCloseArcsEstesa", road_sections);
      setNewEvent("selectedArcEstesa", null);
      setNewEvent("selectedArcToAddIndexEstesa", null);
    }
  };

  disableConfirmButton = () => {
    const { selectedArcToAdd } = this.state;
    //DISABLE CONFIRM BUTTON IF NO ARC TO ADD
    if (selectedArcToAdd) {
      return false;
    } else {
      return true;
    }
  };

  onClickConfirmArc() {
    const { selectedArcToAdd, listOfConfirmedArcs } = this.state;
    const { newEvent, setNewEvent } = this.props;

    if (selectedArcToAdd) {
      let newList = [...listOfConfirmedArcs];
      if (!listOfConfirmedArcs.includes(selectedArcToAdd)) {
        newList = [
          ...listOfConfirmedArcs,
          { ...selectedArcToAdd, indexToShow: listOfConfirmedArcs.length + 1 },
        ];
      }
      this.setState({
        ...this.state,
        listOfConfirmedArcs: newList,
        btnRouting: newList.length === 2 ? true : false,
      });

      if (newEvent) {
        setNewEvent("listOfConfirmedArcsEstesa", newList);
      }
    }
  }

  onClickDeleteArcRow = (index) => {
    const { listOfConfirmedArcs } = this.state;
    const { newEvent, setNewEvent } = this.props;
    let listWithoutRemovedElement = [...listOfConfirmedArcs];
    listWithoutRemovedElement.splice(index, 1);

    this.setState({
      ...this.state,
      listOfConfirmedArcs: listWithoutRemovedElement,
      btnRouting: true,
    });

    if (newEvent) {
      setNewEvent("listOfConfirmedArcsEstesa", listWithoutRemovedElement);
    }
  };

  onClickCompletaPercorso = () => {
    this.onClickCompletaPercorsoMap();
    this.onClickCompletaPercorsoList();
  };

  notif = (msg, pos) => {
    UIkit.notification({
      message: msg,
      status: "primary",
      pos: pos,
      timeout: 5000,
    });
  };
  onClickCompletaPercorsoList = () => {
    const { listOfConfirmedArcs } = this.state;
    const { setNewEvent } = this.props;

    if (listOfConfirmedArcs && listOfConfirmedArcs.length > 0) {
      this.setState({ isRoutingLoading: true });
      let arrNodes = [];
      let routing = [];
      listOfConfirmedArcs.forEach((arc, index) => {
        if (index !== 0) {
          arrNodes.push({
            sourceId: listOfConfirmedArcs[index - 1].properties.b,
            destinationid: arc.properties.a,
          });
        }
      });

      of(...arrNodes)
        .pipe(
          concatMap((node) =>
            this.apiService.getShortestPathRouteBetweenNodes(
              node.sourceId,
              node.destinationid
            )
          ),
          tap((x) => {
            if (x && !x.error) {
              routing.push(x);
            } else {
              console.log("error", x);
            }
          }),
          finalize(() => {
            let newList = this.addMultipleDistancePoints(
              listOfConfirmedArcs,
              routing
            );

            if (newList.length <= 50) {
              setNewEvent("listOfConfirmedArcsEstesa", newList);
              this.setState({
                isRoutingLoading: false,
                listOfConfirmedArcs: newList,
                btnRouting: false,
              });
            } else {
              this.setState({
                isRoutingLoading: false,
                btnRouting: false,
              });
              const string =
                "<span uk-icon='icon: acr-map-options-information' class='uk-icon'></span><label>&nbsp; Limite massimo di archi raggiunto, non si procederà al salvataggio </label>";
              this.notif(string, "bottom-center");
            }
          }),
          catchError((error) => {
            this.setState({ isRoutingLoading: false });
            console.error(error);
            return of(error);
          })
        )
        .subscribe();
    }
  };

  onClickCompletaPercorsoMap = () => {
    const { listOfConfirmedArcs } = this.state;

    if (listOfConfirmedArcs && listOfConfirmedArcs.length > 0) {
      this.setState({ isRoutingLoading: true });
      let arrNodes = [];
      let routing = [];
      listOfConfirmedArcs.forEach((arc, index) => {
        if (index !== 0) {
          arrNodes.push({
            sourceId: listOfConfirmedArcs[index - 1].properties.b,
            destinationid: arc.properties.a,
          });
        }
      });

      of(...arrNodes)
        .pipe(
          concatMap((node) =>
            this.apiService.getShortestPathBetweenNodes(
              node.sourceId,
              node.destinationid
            )
          ),
          tap((x) => {
            if (x && !x.error) {
              routing.push(x);
            } else {
              console.log("error", x);
            }
          }),
          finalize(() => {
            this.setState({
              isRoutingLoading: false,
              shortestDistancePoints: routing,
              btnRouting: false,
            });
          }),
          catchError((error) => {
            this.setState({ isRoutingLoading: false });
            console.error(error);
            return of(error);
          })
        )
        .subscribe();
    }
  };

  addMultipleDistancePoints = (initialList, listToMerge) => {
    let mergedList = [];

    initialList.forEach((item, index) => {
      mergedList.push({
        ...item,
      });
      if (
        listToMerge[index] &&
        listToMerge[index].type === "FeatureCollection"
      ) {
        listToMerge[index].features.forEach((item, index2) =>
          mergedList.push({
            ...item,
            properties: {
              ...item.properties,
              radiusOfInfluence: 1000,
            },
          })
        );
      } else if (listToMerge[index] && listToMerge[index].type === "Feature") {
        mergedList.push({
          ...listToMerge[index],
          properties: {
            ...item.properties,
            radiusOfInfluence: 1000,
          },
        });
      }
    });

    return mergedList;
  };

  setArcToAdd = (arc, index) => {
    const { setNewEvent } = this.props;

    this.setState({
      ...this.state,
      selectedArcToAdd: arc,
      selectedArcToAddIndex: index,
    });

    setNewEvent("selectedArcEstesa", arc);
    setNewEvent("selectedArcToAddIndexEstesa", index);
  };

  filterArcList = (e) => {
    const { listOfCloseArcs } = this.state;
    let filterString = e.target.value.toLowerCase();
    let filteredList = [...listOfCloseArcs];

    filteredList = filteredList.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.arcid &&
          item.properties.arcid.toString() &&
          item.properties.arcid
            .toString()
            .toLowerCase()
            .includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.name &&
          item.properties.name.toLowerCase().includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.direzione &&
          item.properties.direzione.toLowerCase().includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.distance.toString() &&
          item.properties.distance
            .toString()
            .toLowerCase()
            .includes(filterString))
    );

    this.setState({
      ...this.state,
      filterText: filterString,
      filteredListOfCloseArcs: filteredList,
    });
  };

  disableForwardButton = () => {
    const {
      enableForwardEventButton,
      disableForwardEventButton,
      newEventValid,
    } = this.props;

    if (
      newEventValid.listOfConfirmedArcsEstesa ||
      newEventValid.positionEstesa
    ) {
      return enableForwardEventButton();
    } else return disableForwardEventButton();
  };

  cancelSearchBar = () => {
    document.getElementById("form-cerca").value = "";
    const { listOfCloseArcs } = this.state;

    let filterString = document
      .getElementById("form-cerca")
      .value.toLowerCase();
    let filteredList = [...listOfCloseArcs];

    filteredList = filteredList.filter(
      (item) =>
        (item &&
          item.properties &&
          item.properties.arcid &&
          item.properties.arcid.toString() &&
          item.properties.arcid
            .toString()
            .toLowerCase()
            .includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.name &&
          item.properties.name.toLowerCase().includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.direzione &&
          item.properties.direzione.toLowerCase().includes(filterString)) ||
        (item &&
          item.properties &&
          item.properties.distance.toString() &&
          item.properties.distance
            .toString()
            .toLowerCase()
            .includes(filterString))
    );

    this.setState({
      ...this.state,
      filterText: filterString,
      filteredListOfCloseArcs: filteredList,
    });
  };

  changeRadiusEstesa = (event, arcID) => {
    const { setNewEvent } = this.props;
    const { listOfConfirmedArcs } = this.state;

    let newList = listOfConfirmedArcs.map((item) => {
      if (item.properties.arcid === arcID) {
        return {
          ...item,
          properties: {
            ...item.properties,
            radiusOfInfluence: event.target.value,
          },
        };
      } else {
        return item;
      }
    });

    setNewEvent("listOfConfirmedArcsEstesa", newList);

    this.setState({
      ...this.state,
      listOfConfirmedArcs: [...newList],
    });
  };

  render() {
    const { newEvent } = this.props;
    const {
      modalMapWidth,
      filteredListOfCloseArcs,
      selectedArcToAddIndex,
      filterText,
      shortestDistancePoints,
      btnRouting,
      isRoutingLoading,
      listOfConfirmedArcs,
    } = this.state;

    this.disableForwardButton();
    return (
      <div className="uk-flex uk-flex-column uk-position-relative height-90 ">
        <div className="uk-flex uk-flex-row">
          <ErrorBoundary>
            <div className="uk-width-2-3 uk-flex uk-margin-medium-left	mapBoundary">
              <ModalMap
                modeswitch="estesa"
                modalMapWidth={modalMapWidth}
                shortestDistancePoints={shortestDistancePoints}
              ></ModalMap>
            </div>
          </ErrorBoundary>
          <div className="uk-width-1-3 uk-flex uk-margin-large-right uk-flex-column ">
            <div className="uk-width-1-1 uk-flex uk-flex-left uk-inline">
              {filterText !== "" ? (
                <a
                  href="/#"
                  className="uk-form-icon uk-form-icon-flip"
                  uk-icon="icon: close"
                  onClick={(event) => {
                    event.preventDefault();
                    this.cancelSearchBar();
                  }}
                >
                  {""}
                </a>
              ) : (
                ""
              )}
              <input
                id="form-cerca"
                type="text"
                className="uk-input uk-form-small"
                placeholder="Cerca"
                onChange={this.filterArcList}
                value={filterText}
              />
            </div>

            <div className="event-table-list-arc uk-flex uk-width-1-1 uk-margin-small-top">
              <ErrorBoundary>
                <CloseArcsTable
                  listOfCloseArcs={
                    newEvent &&
                    newEvent.listOfCloseArcsEstesa &&
                    newEvent.listOfCloseArcsEstesa.length > 0
                      ? filteredListOfCloseArcs
                      : []
                  }
                  setArcToAdd={this.setArcToAdd}
                  selectedArcToAddIndex={selectedArcToAddIndex}
                />
              </ErrorBoundary>
            </div>
            <div className="uk-width-1-1 uk-flex uk-margin-small-top uk-flex-right">
              <button
                className="uk-button uk-button-default uk-button-small uk-margin-left"
                disabled={this.disableConfirmButton()}
                onClick={() => {
                  this.onClickConfirmArc();
                }}
              >
                Conferma Arco
              </button>
            </div>
          </div>
        </div>
        <ErrorBoundary>
          <SelectedArcsTable
            onClickDeleteArcRow={this.onClickDeleteArcRow}
            onClickCompletaPercorso={this.onClickCompletaPercorso}
            changeRadiusEstesa={this.changeRadiusEstesa}
            btnRouting={btnRouting}
            isRoutingLoading={isRoutingLoading}
            listOfArcs={listOfConfirmedArcs}
          />
        </ErrorBoundary>
      </div>
    );
  }

  componentWillUnmount = () => {
    this.subscriptions.forEach((sub$) => sub$.unsubscribe());
  };
}

LocalizationCartograficaPuntualeEstesa.contextType = EnvironmentContext;

const mapDispatchToProps = {
  enableForwardEventButton,
  disableForwardEventButton,
  setNewEvent,
  updateNewEvent,
};

const mapStateToProps = (state) => ({
  //MODAL REDUCER
  newEvent: getNewEvent(state),
  currentEvent: getCurrentEvent(state),
  newEventValid: getNewEventValid(state),
  boundingBox: getBoundingBoxCartografica(state),
  typeModal: getTypeModal(state),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LocalizationCartograficaPuntualeEstesa)
);
