import React, { Component } from "react";
import Loading from "components/shared/loading/loading";

class WorkspaceListBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      workspaces,
      isWsLoading,
      deleteWorkspace,
      setUpdateMode,
      markWorkspaceAsDefault,
      setCurrentWorkspaceFromList,
      isToggleSavingWorkspace,
      toggleSaveWorkspace,
    } = this.props;
    return (
      <div className="workspace-dropdown-container">
        <div className="uk-flex uk-flex-column uk-height-1-1 uk-width-1-1 uk-card uk-card-primary uk-overflow-auto">
          {isWsLoading ? (
            <Loading />
          ) : workspaces.length > 0 ? (
            workspaces.map((item) => {
              const isDefault = item && item.isDefault;

              return (
                <div
                  key={item.preferenceId}
                  className="uk-flex uk-flex-row uk-flex-between ws-list"
                >
                  <div
                    key={item.preferenceId}
                    className="uk-link"
                    onClick={(event) => {
                      event.preventDefault();
                      if (isToggleSavingWorkspace) toggleSaveWorkspace(false);
                      setCurrentWorkspaceFromList(event, item);
                    }}
                  >
                    {item && item.payload && item.payload.targetClass
                      ? item.payload.targetClass
                      : "No nome disponibile"}
                  </div>
                  <div className="uk-flex uk-flex-row">
                    <span
                      uk-icon="icon: pencil"
                      uk-tooltip={"title: Modifica"}
                      onClick={(event) => {
                        event.preventDefault();
                        setUpdateMode(event, item);
                      }}
                      className="pointer height-width-24 uk-margin-right uk-link"
                    />
                    <span
                      uk-icon="icon: cog"
                      uk-tooltip={
                        isDefault
                          ? "title: Rimuovi Default"
                          : "title: Imposta Default"
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        markWorkspaceAsDefault(event, item);
                      }}
                      className={
                        isDefault
                          ? "pointer height-width-24 uk-margin-right yellow-icon-preference"
                          : "pointer height-width-24 uk-margin-right uk-link"
                      }
                    />
                    <span
                      uk-icon="icon: acr-map-options-delete"
                      uk-tooltip={"title: Elimina"}
                      onClick={(event) => {
                        event.preventDefault();
                        deleteWorkspace(event, item);
                      }}
                      className="pointer height-width-24 uk-link"
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="ws-list">Nessun Work Space disponibile!</div>
          )}
        </div>
      </div>
    );
  }
}

export default WorkspaceListBox;
