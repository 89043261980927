import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.less";
import EnvironmentContext from "environment-context";
// TABLES
import EventReportTable from "components/layout/tables/event-table/event-report";
import EventHistoryTable from "components/layout/tables/event-table/event-history";
import ArcsTable from "components/layout/tables/graph-table/arcs-table";
import PointsTable from "components/layout/tables/graph-table/point-table";
import TmcTable from "components/layout/tables/graph-table/tmc-table";
import PmvTable from "components/layout/tables/pmv-table";
import StationsTable from "components/layout/tables/station-table/index";
import TrafficTable from "components/layout/tables/traffic-table/index";
import ParkingTable from "components/layout/tables/parking-table";
import CameraTable from "components/layout/tables/camera-table";
import UserActivityTable from "components/layout/tables/user-activity-table";
//STORE
import { getTypeOfToggledTable } from "store";

class GenericTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { typeOfToggledTable } = this.props;

    switch (typeOfToggledTable) {
      case "eventReportTable":
        return <EventReportTable />;
      case "eventHistoryTable":
        return <EventHistoryTable />;
      case "arcsTable":
        return <ArcsTable />;
      case "pointsTable":
        return <PointsTable />;
      case "tmcTable":
        return <TmcTable />;
      case "pmvTable":
        return <PmvTable />;
      case "stationsTable":
        return <StationsTable />;
      case "trafficTable":
        return <TrafficTable />;
      case "parkingTable":
        return <ParkingTable />;
      case "userActivityTable":
        return <UserActivityTable />;
      case "cameraTable":
        return <CameraTable />;
      default:
        return <div></div>;
    }
  }
}

GenericTableContainer.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  typeOfToggledTable: getTypeOfToggledTable(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GenericTableContainer)
);
