import React, { Component } from "react";
import { ResponsiveLine } from "@nivo/line";
import * as moment from "moment";
import { getTrafficMaxIntervalTime } from "utils/utils-traffic";

class LineChartTrafficMeasures extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, defaultMeasure } = this.props;

    const refsLayer = (props) => (
      <g>
        <rect
          y={props.yScale(0.9) - 2}
          width={props.width - 50}
          height={3}
          fill="rgba(255,0,0,1)"
        />
        <rect
          y={props.yScale(0.7) - 2}
          width={props.width - 50}
          height={3}
          fill="rgba(255, 102, 0,1)"
        />
        <rect
          y={props.yScale(0.5) - 2}
          width={props.width - 50}
          height={3}
          fill="rgba(255, 255, 0,1)"
        />
        <rect
          y={props.yScale(0.3) - 2}
          width={props.width - 50}
          height={3}
          fill="rgba(0, 255, 0,1)"
        />
        <rect
          y={props.yScale(0) - 2}
          width={props.width - 50}
          height={3}
          fill="rgba(50, 158, 168)"
        />
      </g>
    );
    const differentTime = getTrafficMaxIntervalTime(data);
    return (
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 10, bottom: 50, left: 50 }}
        xScale={{
          format: "%Y-%m-%dT%H:%M:%S%Z",
          type: "time",
        }}
        xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
        yScale={{
          type: "linear",
          min: 0,
          max: 1,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legendOffset: 45,
          legendPosition: "middle",
          format: "%H:%M",
          tickValues:
            differentTime <= 40
              ? "every 5 minutes"
              : differentTime <= 120
              ? "every 15 minutes"
              : differentTime <= 360
              ? "every 30 minutes"
              : differentTime <= 720
              ? "every 1 hour"
              : differentTime <= 2160
              ? "every 2 hours"
              : differentTime <= 6480
              ? "every 4 hours"
              : "every 8 hours",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend:
            defaultMeasure &&
            defaultMeasure.measureName +
              (defaultMeasure.measureUnitType
                ? " [" + defaultMeasure.measureUnitType + "]"
                : ""),
          legendOffset: -45,
          legendPosition: "middle",
          fill: "rgba(0, 0, 0, 0)",
          tickValues: [0, 0.2, 0.4, 0.6, 0.8, 1],
        }}
        colors={{ scheme: "greys" }}
        pointSize={10}
        pointColor="black"
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        areaOpacity={0}
        crosshairType="right"
        useMesh={true}
        theme={{
          textColor: "#ffffff",
          fontSize: "15px",
          tooltip: {
            container: {
              background: "#333",
            },
          },
          axis: {
            tickColor: "#eee",
            ticks: {
              line: {
                stroke: "#555555",
              },
              text: {
                fill: "#ffffff",
                fontSize: "15px",
              },
            },
            legend: {
              text: {
                fill: "#ffffff",
                fontSize: "15px",
              },
            },
          },
          grid: {
            line: {
              stroke: "#5c5c5c",
              strokeWidth: 1,
              strokeDasharray: "4 4",
            },
          },
        }}
        legends={[
          {
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: -40,
            translateY: -34,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 0,
            itemOpacity: 0.75,
            symbolSize: 18,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            itemTextColor: "#ffffff",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                  fontSize: "35px",
                },
              },
            ],
          },
        ]}
        tooltip={(slice) => {
          let half = data[0].data.length / 2;
          return (
            <div
              style={{
                background: "#0f0f0f",
                padding: "9px 12px",
                border: "1px solid #ccc",
                position: "absolute",
                right: slice.point.index > half ? 10 : -135,
              }}
            >
              x=
              {[slice.point.data].map((i) => {
                return moment(new Date(i.xFormatted)).format(
                  "DD/MM/YYYY-HH:mm"
                );
              })}
              <div>
                y=
                {[slice.point.data].map((i) => {
                  return i.y;
                })}
              </div>
            </div>
          );
        }}
        layers={[
          "grid",
          refsLayer,
          "markers",
          "axes",
          "areas",
          "crosshair",
          "lines",
          "points",
          "slices",
          "mesh",
          "legends",
        ]}
      />
    );
  }
}

export default LineChartTrafficMeasures;
