import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { tap, take, catchError } from "rxjs/operators";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { of } from "rxjs";
import "./style.less";
//COMPONENT
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import BottomControlsIcons from "components/shared/bottom-controls/bottom-controls-icons";
import PmvDetail from "components/layout/pmvs/pmv-detail/pmv-detail";
import PmvListComponent from "components/layout/pmvs/pmv-list/pmv-list";
//MODEL
import { PmvAnagraficaAndStatusModel } from "reducers/installations/pmv/pmv.model";
//
import { setCurrentPmv } from "reducers/installations/pmv/pmv.actions";
import {
  getFilteredPmvs,
  getCurrentPmv,
  getCurrentPmvGroup,
  getPermissions,
} from "store";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
//
import { createMsgUrl } from "utils/utils-pmv";

const mapStateToProps = (state) => ({
  //PARKING
  filteredPmvs: getFilteredPmvs(state),
  currentPmv: getCurrentPmv(state),
  currentPmvGroup: getCurrentPmvGroup(state),
  permissions: getPermissions(state),
});

const mapDispatchToProps = {
  setCurrentPmv,
  toggleGenericTable,
};

class PmvsContainer extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {
      openDetail: false,
      currentPmvStatus: null,
      isPmvLoading: false,
    };
  }

  componentDidMount = () => {
    this.apiService = createApiService(this.context);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { currentPmv } = this.props;

    if (
      (!prevProps.currentPmv &&
        currentPmv &&
        currentPmv.properties &&
        currentPmv.properties.anagrafica) ||
      (currentPmv &&
        currentPmv.properties &&
        currentPmv.properties.anagrafica &&
        prevProps.currentPmv &&
        prevProps.currentPmv.properties &&
        prevProps.currentPmv.properties.anagrafica &&
        prevProps.currentPmv.properties.anagrafica.id !==
          currentPmv.properties.anagrafica.id)
    ) {
      const jwt = sessionStorage.getItem("id_token");

      this.setState({ isPmvLoading: true });
      this.subscriptions$.push(
        this.apiService
          .getPmvStatusById(currentPmv.properties.anagrafica.id, jwt)
          .pipe(
            take(1),
            tap((data) => {
              let currentPmvStatus = PmvAnagraficaAndStatusModel.from(data);
              this.setState({ currentPmvStatus, isPmvLoading: false });
            }),
            catchError((error) => {
              this.setState({ currentPmvStatus: null, isPmvLoading: false });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  toggleDetails = (selectedDevice) => {
    const { setCurrentPmv } = this.props;

    if (
      selectedDevice &&
      selectedDevice.properties &&
      selectedDevice.properties.anagrafica &&
      selectedDevice.properties.anagrafica.id
    ) {
      setCurrentPmv(selectedDevice);
    } else {
      setCurrentPmv(null);
      this.setState({ ...this.state, currentPmvStatus: null });
    }
  };

  refreshPmvOnAir = (currentPmvStatus) => {
    if (
      currentPmvStatus &&
      currentPmvStatus.anagrafica &&
      currentPmvStatus.anagrafica.id
    ) {
      this.subscriptions$.push(
        this.apiService
          .getPmvStatusById(currentPmvStatus.anagrafica.id, null)
          .pipe(
            take(1),
            tap((data) => {
              let currentPmvStatus = PmvAnagraficaAndStatusModel.from(data);
              this.setState({
                ...this.state,
                currentPmvStatus: currentPmvStatus,
              });
            }),
            catchError((error) => {
              this.setState({ currentPmvStatus: null });
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  render() {
    const {
      currentPmv,
      filteredPmvs,
      permissions,
      currentPmvGroup,
      toggleGenericTable,
    } = this.props;
    const { currentPmvStatus, isPmvLoading } = this.state;

    const pmvMonitoringPermission = permissions.indexOf("PMV_MONITORING") >= 0;
    const pmvControlPermission = permissions.indexOf("PMV_CONTROL") >= 0;
    const pmvConfigurationPermission =
      permissions.indexOf("PMV_CONFIGURATION") >= 0;
    const pmvManualControl = permissions.indexOf("PMV_MANUAL_CMD") >= 0;
    const jwt = sessionStorage.getItem("id_token");
    const wso2token = sessionStorage.getItem("access_token");

    let showMultiPmvMessage =
      currentPmvGroup.length > 1 &&
      currentPmvGroup.every(
        (item) =>
          item.properties &&
          item.properties.anagrafica &&
          item.properties.anagrafica.gruppo ===
            currentPmvGroup[0].properties.anagrafica.gruppo
      );

    let controlsListPmvDetail = [];
    let controlsList = [];

    if (pmvManualControl) {
      controlsListPmvDetail.push({
        icon: "acr-event-nuovo-evento",
        tooltip: "Invia Messaggio Manuale",
        className: "eventi-icon",
        toggle: (e) => {
          e.preventDefault();
          const linkToMsgPage =
            currentPmv &&
            currentPmv.properties &&
            currentPmv.properties.anagrafica &&
            currentPmv.properties.anagrafica.paginaInvioMessggioUrl;

          if (linkToMsgPage) {
            window.open(
              this.context.endpointFamasFe +
                linkToMsgPage +
                "?OAUTH_TOKEN=" +
                jwt +
                "&WSO2_TOKEN=" +
                wso2token,
              "_blank"
            );
          }
        },
      });

      if (showMultiPmvMessage) {
        controlsList.push({
          icon: "acr-event-nuova-pratica",
          tooltip: "Invio Messaggio Multipannello",
          className: "multipmv-message",
          toggle: (e) => {
            e.preventDefault();
            const endpointToOpen = createMsgUrl(
              this.context.endpointFamasFe,
              currentPmvGroup
            );
            window.open(
              endpointToOpen +
                "?OAUTH_TOKEN=" +
                jwt +
                "&WSO2_TOKEN=" +
                wso2token,
              "_blank"
            );
          },
        });
      }
    }
    if (pmvConfigurationPermission) {
      controlsList.push(
        {
          icon: "acr-event-chiudi-pratica",
          tooltip: "Gestione Libreria",
          className: "multipmv-message",
          toggle: (e) => {
            e.preventDefault();
            window.open(
              this.context.endpointFamasLibreria +
                "?OAUTH_TOKEN=" +
                jwt +
                "&WSO2_TOKEN=" +
                wso2token,
              "_blank"
            );
          },
        },
        {
          icon: "acr-pmv-archivio",
          tooltip: "Configurazione PMV",
          className: "table-icon",
          toggle: (e) => {
            e.preventDefault();
            window.open(
              this.context.endpointFamasPmvConfiguration +
                "?OAUTH_TOKEN=" +
                jwt +
                "&WSO2_TOKEN=" +
                wso2token,
              "_blank"
            );
          },
        }
      );
    }

    if (pmvControlPermission) {
      controlsList.push({
        icon: "acr-pmv-nuovo-scenario",
        tooltip: "Configurazione scenari PMV",
        className: "table-icon",
        toggle: (e) => {
          e.preventDefault();
          window.open(
            this.context.endpointFamasPmvScenarioConfiguration +
              "?OAUTH_TOKEN=" +
              jwt +
              "&WSO2_TOKEN=" +
              wso2token,
            "_blank"
          );
        },
      });

      controlsListPmvDetail.push({
        icon: "acr-event-chiudi-pratica",
        tooltip: "Dettagli Pannello",
        className: "table-icon",
        toggle: (e) => {
          e.preventDefault();
          const paginaDiDettaglioUrl =
            currentPmv &&
            currentPmv.properties &&
            currentPmv.properties.anagrafica &&
            currentPmv.properties.anagrafica.paginaDiDettaglioUrl;
          if (paginaDiDettaglioUrl) {
            window.open(
              this.context.endpointFamasFe +
                paginaDiDettaglioUrl +
                "?OAUTH_TOKEN=" +
                jwt +
                "&WSO2_TOKEN=" +
                wso2token,
              "_blank"
            );
          }
        },
      });
    }

    if (pmvMonitoringPermission) {
      controlsList.push({
        icon: "acr-interface-list",
        tooltip: "Resoconto PMV",
        className: "table-icon",
        toggle: (e) => {
          e.preventDefault();
          toggleGenericTable("pmvTable");
        },
      });
    }

    return (
      <TabPanel className="overflow inherit-transition uk-height-1-1">
        <ErrorBoundary>
          {currentPmv ? (
            <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
              <div className="uk-height-1-1 overflow event-list-container">
                <PmvDetail
                  currentPmvStatus={currentPmvStatus}
                  toggleDetails={this.toggleDetails}
                  refreshPmvOnAir={this.refreshPmvOnAir}
                  isPmvLoading={isPmvLoading}
                ></PmvDetail>
              </div>
              <BottomControlsIcons controls={controlsListPmvDetail} />
            </div>
          ) : (
            <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
              <div className="uk-height-1-1 overflow event-list-container">
                <PmvListComponent
                  toggleDetails={this.toggleDetails}
                  filteredPmvs={filteredPmvs}
                ></PmvListComponent>
              </div>
              <BottomControlsIcons controls={controlsList} />
            </div>
          )}
        </ErrorBoundary>
      </TabPanel>
    );
  }
}
PmvsContainer.contextType = EnvironmentContext;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PmvsContainer)
);
