import { INITIAL_STATE_USER } from "./user.model";
import { UserActions } from "./user.actions";

export default function userReducer(state = INITIAL_STATE_USER, action) {
  switch (action.type) {
    case UserActions.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    case UserActions.SET_USERNAME:
      return {
        ...state,
        username: action.payload.username,
      };
    case UserActions.SET_USER_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
      };
    case UserActions.SET_ACTIVITY:
      return {
        ...state,
        activity: action.payload.activity,
      };
    case UserActions.ADD_ACTIVITY:
      return {
        ...state,
        activity: [...state.activity, action.payload.activity],
      };
    default:
      return state;
  }
}

export const getPermissions = (state) => state.permissions;
export const getUsername = (state) => state.username;
export const getUserActivity = (state) => state.activity;
export const getUserRoles = (state) => state.roles;
