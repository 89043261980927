import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "components/layout/modal/style.less";
//COMPONENTS
import CustomisedDatePicker from "components/shared/customised-date-time-picker/customised-datepicker";
// ACTIONS
import { togglePopup } from "reducers/ui/modal/modal.actions";
import { isOpenPopup, getCurrentEvent } from "store";
//PARAMETRI SELEZIONABILI
import { selectFields } from "enums/selectable-params";
import Popup from "components/shared/popup/popup";

class ClosePractice extends Component {
  subscriptions = [];
  constructor(props) {
    super(props);
    this.state = {
      validIDEvent: false,
      valueIDEvent: "",
      validSourcePractice: false,
      valueSourcePractice: "",
      validNote: false,
      valueNote: "",
      valueDateEndEvent: new Date(),
    };
  }

  componentDidMount = () => {
    var selectSource = document.getElementById("source-event");

    selectFields.FONTE.properties.forEach(
      (item, index) =>
        (selectSource.options[selectSource.options.length] = new Option(
          selectFields.FONTE.properties[index].name,
          index
        ))
    );
  };

  handler = (e, name) => {
    var handlervalue = e.target.value;

    if (
      name === "sourcePractice" &&
      handlervalue !== "-" &&
      handlervalue !== "undefined"
    ) {
      this.setState({
        validSourcePractice: true,
        valueSourcePractice: handlervalue,
      });
    } else if (name === "sourcePractice" && handlervalue === "-") {
      this.setState({
        validSourcePractice: false,
        valueSourcePractice: handlervalue,
      });
    }
  };

  onChange = (e, name) => {
    var onchangevalue = e.target.value;
    if (
      name === "noteEvent" &&
      onchangevalue !== "" &&
      onchangevalue !== "undefined"
    ) {
      this.setState({ validNote: true, valueNote: onchangevalue });
    }
    if (name === "noteEvent" && onchangevalue === "") {
      this.setState({
        validNote: false,
        valueNote: onchangevalue,
      });
    }
  };

  onClickDeleteNote = () => {
    this.setState({
      valueNote: "",
    });
  };

  closePractice = () => {
    const { togglePopup } = this.props;
    togglePopup();
  };

  closePopupPractice = () => {
    const { togglePopup, onClose } = this.props;
    togglePopup();
    onClose();
  };

  changeDate = (value, name) => {
    this.setState({ valueDateEndEvent: value });
  };

  buttonPractice = () => {
    const { currentEvent, onClose } = this.props;
    return (
      <div className="close-button-container uk-flex uk-flex-row uk-flex-between">
        <button
          className="uk-button uk-button-default uk-button-small"
          onClick={onClose}
        >
          Annulla
        </button>
        <button
          className="uk-button uk-button-primary uk-button-small btn-close"
          disabled={!currentEvent}
          onClick={(e) => {
            e.preventDefault();
            this.closePractice();
          }}
        >
          Chiudi Pratica
        </button>
      </div>
    );
  };

  childrenEvent = () => {
    //const { currentEvent } = this.props;
    let childrenEvents = [
      { idEvent: 1, status: 0 },
      { idEvent: 2, status: 1 },
    ];
    return (
      <div>
        {childrenEvents &&
          childrenEvents.map((event, index) => {
            return (
              <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
                <div className="uk-width-1-1">
                  <label className="uk-form-label">
                    Id Evento Figlio {index + 1}
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      value={event && event.idEvent ? event.idEvent : "-"}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="uk-width-1-1 uk-flex uk-flex-middle">
                  <label className="uk-form-label-1-2">
                    Stato Evento Figlio {index + 1}
                  </label>
                  <div className="uk-form-controls-1-2 eventStateSon">
                    <input
                      className="uk-input"
                      value={event && event.status ? event.status : "-"}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  render = () => {
    const { valueSourcePractice, valueNote, valueDateEndEvent } = this.state;
    const { currentEvent, isOpenPopup } = this.props;

    return (
      <div className="padding-top-form small-form">
        <form className="uk-form-horizontal padding-form ">
          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-1">
              <label className="uk-form-label">Id Pratica</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  value={
                    currentEvent && currentEvent.situationId
                      ? currentEvent.situationId
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-2">
              <label className="uk-form-label">Id Evento Padre</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  value={
                    currentEvent && currentEvent.situationRecordId
                      ? currentEvent.situationRecordId
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            </div>
            <div className="uk-width-1-2 uk-flex uk-flex-middle">
              <label className="uk-form-label-1-2">Stato Evento Padre</label>
              <div className="uk-form-controls-1-2 eventStateSon">
                <input
                  className="uk-input"
                  value={
                    currentEvent &&
                    currentEvent.validity &&
                    currentEvent.validity.validityStatus
                      ? currentEvent.validity.validityStatus
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>

          {this.childrenEvent()}

          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-1">
              <label className="uk-form-label">Fonte</label>
              <div className="uk-form-controls">
                <select
                  id="source-event"
                  name={"sourcePractice"}
                  className="uk-select"
                  value={valueSourcePractice}
                  onChange={(e) => this.handler(e, "sourcePractice")}
                ></select>
              </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-margin">
            <div className="uk-width-1-1">
              <label className="uk-form-label">Comunicazione</label>
              <div className="uk-form-controls">
                <CustomisedDatePicker
                  value={valueDateEndEvent}
                  callbackFromParent={(data) => this.changeDate(data)}
                  endDate={valueDateEndEvent}
                  width100={true}
                />
              </div>
            </div>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">Note</label>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea no-resize"
                value={valueNote}
                onChange={(e) => this.onChange(e, "noteEvent")}
              />
              <span
                className="iconRecycleBin"
                uk-icon="icon: acr-interface-cestino"
                onClick={(e) => {
                  e.preventDefault();
                  this.onClickDeleteNote();
                }}
              ></span>
            </div>
          </div>
        </form>
        {this.buttonPractice()}
        {isOpenPopup && (
          <Popup
            iconTypeError={false}
            textBody="Pratica chiusa con successo"
            titleHeader="Eventi: Chiudi Pratica"
            toggleClose={this.closePopupPractice}
          />
        )}
      </div>
    );
  };
}

const mapDispatchToProps = {
  togglePopup,
};

const mapStateToProps = (state) => ({
  isOpenPopup: isOpenPopup(state),
  currentEvent: getCurrentEvent(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClosePractice)
);
