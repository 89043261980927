export const TplMenuActions = {
  SET_TRANSPORT_VISIBILITY: "UI/TPL_MENU/SET_TRANSPORT_VISIBILITY",
  SET_CITY_BUS_VISIBILITY: "UI/TPL_MENU/SET_CITY_BUS_VISIBILITY",
  SET_REGION_BUS_VISIBILITY: "UI/TPL_MENU/SET_REGION_BUS_VISIBILITY",
  SET_IS_TPL_LOADING: "UI/TPL_MENU/SET_IS_TPL_LOADING",
};

//TPL
export const setTransportVisibility = (visible) => (dispatch) => {
  dispatch({
    type: TplMenuActions.SET_TRANSPORT_VISIBILITY,
    payload: { visible },
  });
};

export const setCityBusVisibility = (visible) => (dispatch) => {
  dispatch({
    type: TplMenuActions.SET_CITY_BUS_VISIBILITY,
    payload: { visible },
  });
};

export const setRegionBusVisibility = (visible) => (dispatch) => {
  dispatch({
    type: TplMenuActions.SET_REGION_BUS_VISIBILITY,
    payload: { visible },
  });
};

export const setIsTplLoading = ({
  isLinesLoading,
  isTripsLoading,
  isTripsAllLoading,
  isStopsLoading,
}) => (dispatch) => {
  dispatch({
    type: TplMenuActions.SET_IS_TPL_LOADING,
    payload: {
      isLinesLoading,
      isTripsLoading,
      isTripsAllLoading,
      isStopsLoading,
    },
  });
};
