import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
// STYLE
import "./style.less";
//COMPONENTS
import Permission from "components/shared/permission";
//UTIL
import { SENSOR_STATUS } from "utils/util-cameras";
import alarm_icon from "assets/icon/installations/alarm_icon.svg";
import { WebcamPin } from "../webcam-pin-camera";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class WebcamsListComponent extends Component {
  //using withRouter annotation needs to set environemnt directly in component
  static contextType = EnvironmentContext;

  render = () => {
    const { camerasList, pinnedCameras, pinCamera, toggleDetails } = this.props;

    const maxNrOfStreamingCameras = this.context.maxNrOfStreamingCameras;

    return (
      <div className="uk-list margin-bottom50">
        {camerasList.map((camera, index) => {
          const findIcon = SENSOR_STATUS.find(
            (status) =>
              camera &&
              camera.properties &&
              camera.properties.cctvWithLastStateAndOpenAlarms &&
              camera.properties.cctvWithLastStateAndOpenAlarms.last_state &&
              camera.properties.cctvWithLastStateAndOpenAlarms.last_state
                .diagnosticState &&
              typeof camera.properties.cctvWithLastStateAndOpenAlarms.last_state
                .diagnosticState === "string" &&
              status.name ===
                camera.properties.cctvWithLastStateAndOpenAlarms.last_state
                  .diagnosticState
          );

          const isPinned = pinnedCameras.find(
            (item) =>
              item.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId ===
              camera.properties.cctvWithLastStateAndOpenAlarms.cctv.deviceId
          );
          const showAlarmIcon =
            camera &&
            camera.properties &&
            camera.properties.cctvWithLastStateAndOpenAlarms &&
            camera.properties.cctvWithLastStateAndOpenAlarms.open_alarms &&
            camera.properties.cctvWithLastStateAndOpenAlarms.open_alarms
              .length > 0;
          return (
            <div
              key={index}
              className={
                "uk-flex uk-flex-row uk-flex-middle uk-flex-around uk-width-1-1 height-84"
              }
            >
              <li
                className={"uk-flex uk-flex-around uk-flex-middle uk-width-1-1"}
                onClick={(e) => {
                  e.preventDefault();
                  toggleDetails(camera);
                }}
              >
                <div className="uk-position-relative event-height-width-48">
                  {showAlarmIcon && (
                    <img
                      className={"img-alarm"}
                      alt={"alarm_img"}
                      src={alarm_icon}
                    />
                  )}
                  <img
                    className={"img-status"}
                    alt={"webcam_img"}
                    src={findIcon && findIcon.icon}
                  />
                </div>
                <div className="uk-flex uk-margin-small-left width-260">
                  <div className="uk-flex uk-flex-row">
                    <div className="uk-flex uk-flex-column headerName">
                      {camera &&
                      camera.properties &&
                      camera.properties.cctvWithLastStateAndOpenAlarms &&
                      camera.properties.cctvWithLastStateAndOpenAlarms &&
                      camera.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                      camera.properties.cctvWithLastStateAndOpenAlarms.cctv.name
                        ? camera.properties.cctvWithLastStateAndOpenAlarms.cctv
                            .name
                        : "-"}
                    </div>
                  </div>
                </div>
              </li>
              <Permission actions="CCTV-STREAMING">
                <div className="uk-flex">
                  <WebcamPin
                    pinCamera={pinCamera}
                    pinnedCameras={pinnedCameras}
                    isPinned={isPinned}
                    maxCamerasPinnable={maxNrOfStreamingCameras}
                    camera={camera}
                  />
                </div>
              </Permission>
            </div>
          );
        })}
      </div>
    );
  };
}

export default WebcamsListComponent;
