import React, { Component } from "react";
import "./style.less";
import { tap, take, catchError } from "rxjs/operators";
import { of } from "rxjs";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import * as moment from "moment";

class StopTooltip extends Component {
  apiService;
  subscriptions = [];
  constructor(props) {
    super(props);
    this.state = {
      coincidences: "",
      nextArrivals: "",
      width: 0,
      height: 0,
    };
  }
  componentDidMount() {
    this.apiService = createApiService(this.context);
    this.callApi();

    this.getWidthHeight();
  }

  callApi = () => {
    const { object } = this.props;
    if (object && object.id) {
      var timeEnd = moment().set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 59,
      });
      var startTimeBefore = moment().subtract(30, "minutes");
      var minutesForCall = timeEnd.diff(startTimeBefore, "minutes");
      this.subscriptions.push(
        this.apiService
          .getListaCorseFermate(object.id, startTimeBefore, minutesForCall)
          .pipe(
            take(1),
            tap((x) => {
              if (
                x &&
                !x.error &&
                x.monitoredStopVisits &&
                x.monitoredStopVisits instanceof Array
              ) {
                let coincArr = [];
                let nextArr = [];
                x.monitoredStopVisits.forEach((element) => {
                  coincArr.push(
                    element.monitoredVehicleJourney.publishedLineName
                  );
                  if (
                    element.monitoredVehicleJourney.monitoredCall &&
                    element.monitoredVehicleJourney.monitoredCall
                      .aimedArrivalTime &&
                    moment(
                      element.monitoredVehicleJourney.monitoredCall
                        .aimedArrivalTime
                    ).isBefore(moment().add(15, "minutes")) &&
                    element.monitoredVehicleJourney.monitoredCall
                      .aimedDepartureTime &&
                    moment(
                      element.monitoredVehicleJourney.monitoredCall
                        .aimedDepartureTime
                    ).isAfter(moment())
                  ) {
                    nextArr.push({
                      lName: element.monitoredVehicleJourney.publishedLineName,
                      timeStop: moment(
                        element.monitoredVehicleJourney.monitoredCall
                          .aimedArrivalTime
                      ).format("HH:mm"),
                    });
                  }
                });
                coincArr = coincArr.reduce((unique, item) => {
                  return unique.includes(item) ? unique : [...unique, item];
                }, []);
                this.setState({
                  ...this.state,
                  coincidences: coincArr.join(", "),
                  nextArrivals: nextArr
                    .map((item) => item.lName + " (" + item.timeStop + ")")
                    .join(", "),
                });
                this.getWidthHeight();
              } else {
                console.log(x);
              }
            }),
            catchError((error) => {
              console.log(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  getWidthHeight = () => {
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ ...this.state, height: height, width: width });
  };

  render() {
    const { x, y, object } = this.props;
    const { coincidences, nextArrivals, height, width } = this.state;

    return (
      <div
        className="show-tooltip-stop"
        ref={(divElement) => {
          this.divElement = divElement;
        }}
        style={{ left: x - width / 2, top: y - (height + 20) }}
      >
        <div className="uk-flex uk-flex-column">
          <div className="tooltip-text-title">
            {object && object.properties && object.properties.name
              ? object.properties.name
              : object.nameShort}
          </div>
          <div className="uk-flex uk-flex-row">
            <div className="tooltip-text-large">Prossimi arrivi: </div>
            <div className="tooltip-text-important">{nextArrivals}</div>
          </div>
          <div className="uk-flex uk-flex-row">
            <div className="tooltip-text-large">Coincidenze: </div>
            <div className="tooltip-text-important">{coincidences}</div>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

StopTooltip.contextType = EnvironmentContext;

export default StopTooltip;
