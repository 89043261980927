import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  take,
  tap,
  catchError,
  switchMap,
  debounceTime,
  concatMap,
} from "rxjs/operators";
import { BehaviorSubject, Observable, of } from "rxjs";
import * as moment from "moment";
import "../../style.less";
import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";

//COMPONENTS
import Loading from "components/shared/loading/loading";
import TableHead from "components/shared/table-components/table-head";
import TableBody from "components/shared/table-components/table-body";
import TableFilters from "components/shared/table-top-filters/table-filters";
import PageNumbers from "components/shared/page-numbers/page-number";
//STORE
import {
  isOpenNavTab,
  getIsReportDetailOpen,
  getSavedTableColumns,
  getSavedDateColumns,
  getSavedDateFrom,
  getSavedDateTo,
  getSavedSearchText,
  getSavedFilters,
  getSavedToggledFilters,
  getUsername,
  getUserRoles,
} from "store";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import {
  saveTableFilters,
  resetTableFilters,
} from "reducers/filter-table-selected/filter-table-selected.actions";
import { addActivity } from "reducers/user/user.actions";
// MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";
import { UserActivityModel } from "reducers/user/user.model";
//UTILS
import { TYPE_ARC, debounce } from "utils/utils";
import {
  TABLE_COLUMNS,
  DATE_COLUMNS,
  initColumns,
} from "utils/table-utils/util-arcs-table";
import * as FilterUtils from "utils/filter-utils";
import {
  handleFilterStringChange,
  handleClickIncreasePage,
  handleClickDecreasePage,
  requestSortOrder,
  resetChildrenForHeaderDropdown,
  onDropdownCheckboxFilterClick,
  getCurrentPageNumber,
  sortByDirectionTranslation,
  resetFiltersState,
  initializeTableState,
  getTableStateSortConfig,
} from "utils/table-utils/util-general-tables";
import { saveBlob } from "utils/hooks";

const FILTER_TARGET = "arcsFilters";

class ArcsTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      currentListArches: [],
      stateTableColumns: TABLE_COLUMNS,
      sortConfig: getTableStateSortConfig("arch"),
      ...initializeTableState,
    };
    this.getApiArchsListDebounced = debounce(this.getApiArchsList, 800);
    this.numberPage$ = new BehaviorSubject();
  }

  componentDidMount = () => {
    const { searchText } = this.props;
    this.apiService = createApiService(this.context);

    this.subscriptions.push(
      this.numberPage$
        .pipe(
          debounceTime(800),
          switchMap(
            (x) =>
              new Observable((observer) => {
                const { pageToDisplay } = this.state;
                if (pageToDisplay !== "") {
                  this.getApiArchsList();
                }
              })
          )
        )
        .subscribe()
    );

    if (searchText) {
      this.setState({
        ...this.state,
        filterStr: searchText,
        isTableDataLoading: true,
      });
    } else {
      this.setState({
        ...this.state,
        isTableDataLoading: true,
      });
    }
    this.tableConfigs();
  };

  tableConfigs = () => {
    const {
      tableColumns,
      dateColumns,
      saveTableFilters,
      searchText,
    } = this.props;

    const { columns, date } = initColumns(tableColumns, dateColumns);
    let sld = FilterUtils.constructFlatMultiSelect(columns);
    let obj = {};
    sld.forEach((x) => {
      obj[x.name] = { ...x };
    });
    saveTableFilters(
      {
        tableColumns: columns,
        dateColumns: date,
        dateFrom: null,
        dateTo: null,
        searchText: searchText || "",
        toggledFilters: obj || {},
      },
      FILTER_TARGET
    );
    this.setState({
      selected: sld,
      searchText: searchText || "",
    });
  };

  getApiArchsList = () => {
    const { count, startIndex, sortConfig } = this.state;

    //ASC/DESC DIRECTION, SEARCH FILTER AND TOGGLED FILTERS
    const sortBy = sortByDirectionTranslation(sortConfig);
    const cql_filter = this.checkToggledFiltersAndSearchString();

    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures(
          "road_sections",
          null,
          count,
          sortBy,
          null,
          null,
          cql_filter,
          startIndex
        )
        .pipe(
          take(1),
          tap((data) => {
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "road_sections"
            );
            let road_sections = [];
            let pageMax = Math.ceil(featureCollection.totalFeatures / count);
            road_sections = [...featureCollection.features];
            this.setState({
              ...this.state,
              isTableDataLoading: false,
              currentListArches: road_sections,
              pageMax: pageMax,
            });
          }),
          catchError((error) => {
            console.error(error);
            this.setState({
              ...this.state,
              isTableDataLoading: false,
            });
            return of(error);
          })
        )
        .subscribe()
    );
  };

  //CALL API TO OBTAIN NEW ARC LIST
  refreshData = () => {
    this.setState({
      ...this.state,
      currentListArches: [],
      isTableDataLoading: true,
    });
    this.getApiArchsList();
  };

  downloadExcel = () => {
    const { count, startIndex, sortConfig } = this.state;

    //ASC/DESC DIRECTION, SEARCH FILTER AND TOGGLED FILTERS
    const sortBy = sortByDirectionTranslation(sortConfig);
    const cql_filter = this.checkToggledFiltersAndSearchString();

    this.apiService
      .fetchArcCsv(count, sortBy, cql_filter, startIndex)
      .pipe(
        tap((blob) => {
          let filename =
            "grafo_archi_export_" +
            moment().format("DD-MM-YYYY HH:mm:ss") +
            ".csv";
          saveBlob(blob, filename);
        }),
        concatMap((data) => {
          return this.postArcDownloadActivity(data);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  postArcDownloadActivity = () => {
    const { username, addActivity, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "ROAD_GRAPH",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "DATA_EXPORTED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value: 'Tabella "Archi"',
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  checkToggledFiltersAndSearchString = () => {
    const { filterStr, stateTableColumns } = this.state;

    if (stateTableColumns || filterStr) {
      let filterString = "";

      if (
        stateTableColumns &&
        stateTableColumns["lanes"] &&
        stateTableColumns["lanes"].childrenForHeaderDropdown
      ) {
        let laneFilters = [];
        stateTableColumns["lanes"].childrenForHeaderDropdown.forEach((item) => {
          if (!item.checked && item.id !== "all-corsie") {
            return laneFilters.push({
              key: "lanes",
              value: item.id,
            });
          }
        });

        laneFilters.forEach((item, index) => {
          filterString = filterString + item.key + "<>" + item.value;
          if (index + 1 !== laneFilters.length) {
            filterString = filterString + " AND ";
          }
        });
      }

      if (filterStr !== "") {
        if (filterString !== "") {
          if (parseInt(filterStr)) {
            filterString =
              filterString +
              " AND (" +
              " a=" +
              filterStr +
              " OR b=" +
              filterStr +
              ")";
          } else {
            filterString =
              filterString +
              " AND (name ilike '%" +
              filterStr +
              "%' OR area_name ilike '%" +
              filterStr +
              "%')";
          }
        } else {
          if (parseInt(filterStr)) {
            filterString =
              filterString + " a=" + filterStr + " OR b=" + filterStr;
          } else {
            filterString =
              filterString +
              "name ilike '%" +
              filterStr +
              "%' OR area_name ilike '%" +
              filterStr +
              "%'";
          }
        }
      }
      return filterString;
    } else {
      return null;
    }
  };

  handleFilterChange = (e) => {
    handleFilterStringChange(
      this,
      e,
      FILTER_TARGET,
      this.getApiArchsListDebounced
    );
  };

  // GESTIONE CHECKBOX: VISUALIZZAZIONE COLONNE
  handleSelectChange = (visibleColumns) => {
    FilterUtils.handleSelectChange(this, visibleColumns, FILTER_TARGET);
  };

  getPageNumber = (pageNumber) => {
    getCurrentPageNumber(this, pageNumber);
  };

  handleClickIncrease = () => {
    handleClickIncreasePage(this);
  };

  handleClickDecrease = () => {
    handleClickDecreasePage(this);
  };

  requestSort = (key) => {
    requestSortOrder(this, key, this.getApiArchsList);
  };

  //GESTIONE CHECKBOX FILTRI SU COLONNE
  onCheckboxFilterClick = (newTableColumns) => {
    this.setState({
      ...this.state,
      isTableDataLoading: true,
    });
    onDropdownCheckboxFilterClick(
      this,
      this.getApiArchsList,
      FILTER_TARGET,
      newTableColumns
    );
  };

  resetFilters = (e) => {
    const { resetTableFilters, toggledFilters } = this.props;
    const { selected, stateTableColumns } = this.state;

    e.stopPropagation();

    let newStateTableColumns = resetChildrenForHeaderDropdown(
      stateTableColumns
    );

    resetTableFilters(
      {
        tableColumns: { ...newStateTableColumns },
        dateColumns: [...DATE_COLUMNS],
        toggledFilters: toggledFilters,
      },
      FILTER_TARGET
    );

    resetFiltersState(
      this,
      newStateTableColumns,
      selected,
      this.getApiArchsList,
      "arch"
    );
  };

  toTableColumns = (archs) => {
    let newList = [];
    archs.forEach((arc) => {
      let newEl = {};
      if (arc) {
        newEl = {
          id: arc.properties.arcid,
          name: arc.properties.name,
          a: arc.properties.a,
          b: arc.properties.b,
          lanes: arc.properties.lanes,
          capacity: arc.properties.capacity,
          class: TYPE_ARC[arc.properties.frc],
          distance: arc.properties.distance,
          area_name: arc.properties.area_name,
          speed: arc.properties.speed,
          fft:
            arc.properties && arc.properties.fft
              ? moment("1900-01-01 00:00:00")
                  .add(arc.properties.fft, "seconds")
                  .format("HH:mm:ss")
              : "-",
          bridge:
            !arc.properties.bridge || arc.properties.bridge === 0 ? "NO" : "SI",
          tunnel:
            !arc.properties.tunnel || arc.properties.tunnel === 0 ? "NO" : "SI",
          tolling:
            !arc.properties.tolling || arc.properties.tolling === 0
              ? "NO"
              : "SI",
          ztl: !arc.properties.ztl || arc.properties.ztl === 0 ? "NO" : "SI",
          coeff1: arc.properties.coeff1,
          coeff2: arc.properties.coeff2,
        };
      }
      newList = [...newList, newEl];
    });

    return newList;
  };

  render = () => {
    const {
      sortConfig,
      filterStr,
      selected,
      isTableDataLoading,
      currentListArches,
      pageMax,
      pageToDisplay,
    } = this.state;
    const {
      toggleGenericTable,
      isOpenNav,
      dateColumns,
      tableColumns,
    } = this.props;

    let listOfArcs = [];
    if (currentListArches) {
      //MAPPA PER VISUALIZZAZIONE SU TABELLA
      listOfArcs = this.toTableColumns(currentListArches);
    }

    return (
      <div className="acr-table uk-flex uk-flex-column">
        <div className="navbar-placeholder"></div>
        <div className="uk-flex uk-flex-row uk-flex-middle tab-external-container">
          <div className="grey-space"></div>
          <div className=" uk-width-1-2">
            <ul className=" uk-tab-page-secondary uk-tab uk-margin-remove">
              <li className="uk-active bottombar-title">ARCHI</li>
            </ul>
          </div>
          <div className=" uk-width-1-2 uk-flex uk-flex-row uk-flex-right uk-margin-right">
            <span
              className="pointer"
              uk-icon="icon: acr-interface-close"
              onClick={() => toggleGenericTable("arcsTable")}
            ></span>
          </div>
        </div>
        <TableFilters
          isOpenNav={isOpenNav}
          dateStart={{ date: null, name: "dateStartEvent" }}
          dateEnd={{ date: null, name: "dateEndEvent" }}
          handleFilterChange={this.handleFilterChange}
          handleSelectChange={this.handleSelectChange}
          resetFilters={this.resetFilters}
          filterStr={filterStr}
          filtrableDateColumns={dateColumns}
          selected={selected}
          refreshData={this.refreshData}
          disabledDataFilter={true}
        ></TableFilters>
        <div className="uk-flex uk-flex-right button-bar">
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.refreshData}
            >
              <span
                uk-icon="icon: refresh"
                uk-tooltip="Refresh tabella Archi"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle "
              type="button"
              onClick={this.downloadExcel}
            >
              <span
                uk-icon="icon: download"
                uk-tooltip="Download Excel"
                className="filterBarIcons borderWhite"
              ></span>
            </button>
          </div>
          <div className="uk-button-group uk-margin-right">
            <button
              className="custom-button-padding uk-button uk-button-default uk-flex uk-flex-middle uk-margin-right"
              onClick={this.resetFilters}
            >
              <span
                uk-icon="icon: acr-filter-remove"
                uk-tooltip="Cancella Filtri"
                className="filterBarIcons"
              ></span>
            </button>
          </div>
          <div className="uk-flex uk-flex-right button-bar uk-padding-remove-left">
            <PageNumbers
              handleClickDecrease={this.handleClickDecrease}
              handleClickIncrease={this.handleClickIncrease}
              getPageNumber={this.getPageNumber}
              currentPage={pageToDisplay}
              minVal={1}
              pageMax={pageMax}
            />
          </div>
        </div>
        {isTableDataLoading ? (
          <div className="uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left ">
            <table
              id="eventTable"
              className="uk-table uk-table-divider uk-table-hover"
            >
              <thead>
                <TableHead
                  onCheckboxFilterClick={this.onCheckboxFilterClick}
                  requestSort={this.requestSort}
                  tableColumns={tableColumns}
                  sortConfig={sortConfig}
                />
              </thead>
            </table>
            <div className="uk-width-1-1 uk-height-small">
              <Loading />
            </div>
          </div>
        ) : (
          <div className="uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left ">
            <table
              id="eventTable"
              className="uk-table uk-table-divider uk-table-hover"
            >
              <thead>
                <TableHead
                  onCheckboxFilterClick={this.onCheckboxFilterClick}
                  requestSort={this.requestSort}
                  tableColumns={tableColumns}
                  sortConfig={sortConfig}
                />
              </thead>
              <tbody>
                <TableBody list={listOfArcs} tableColumns={tableColumns} />
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

ArcsTable.contextType = EnvironmentContext;

const mapDispatchToProps = {
  toggleGenericTable,
  saveTableFilters,
  resetTableFilters,
  addActivity,
};

const mapStateToProps = (state) => ({
  isOpenNav: isOpenNavTab(state),
  isReportEventOpen: getIsReportDetailOpen(state),
  tableColumns: getSavedTableColumns(state, FILTER_TARGET),
  dateColumns: getSavedDateColumns(state, FILTER_TARGET),
  dateFrom: getSavedDateFrom(state, FILTER_TARGET),
  dateTo: getSavedDateTo(state, FILTER_TARGET),
  searchText: getSavedSearchText(state, FILTER_TARGET),
  tableFilters: getSavedFilters(state, FILTER_TARGET),
  toggledFilters: getSavedToggledFilters(state, FILTER_TARGET),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArcsTable)
);
