import React, { Component } from "react";
import LjsaAuthModule from "@almaviva/ljsa-react-module";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { tap, catchError, take, switchMap } from "rxjs/operators";
import { of, interval, combineLatest } from "rxjs";
//THEME
import theme from "./themes/theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
//COMPONENTS
import EnvironmentContext from "./environment-context";
import { Routes } from "./routes";
import Loading from "components/shared/loading/loading";
import PermissionService from "components/shared/permission-service";
//ACTIONS
import createApiService from "./services/api.service";
import {
  getAllVehiclesEvents,
  getAllActiveLines,
  getCurrentNetwork,
  openKpiDown,
  getUsername,
  getUserRoles,
} from "store";
import {
  setNetworks,
  setCurrentNetwork,
} from "./reducers/configurations/configurations.actions";
import { updateActiveLines } from "reducers/lines/lines.actions";
import { updateVehicleEvents } from "reducers/vehicles/vehicles.actions";
import { updateKpiList } from "reducers/kpi/kpi.actions";
import { addActivity } from "reducers/user/user.actions";
//MODEL
import ConfigurationsModel from "reducers/configurations/configurations.model";
import {
  VehicleJourneysAllCounters,
  VehicleJourneysIndicator,
} from "reducers/kpi/kpi.model";

import "./App.less";

class App extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {
      environment: null,
    };
  }

  componentDidMount() {
    this.loadEnvironment();
  }

  loadEnvironment() {
    fetch("/config.json")
      .then((res) => res.json())
      .then((environment) => {
        this.apiService = createApiService(environment);
        this.setState({ environment });
        this.getApiNetworks(environment);
        this.initKpi();
      });
  }

  initKpi = () => {
    const { networkCurrentReducer } = this.props;
    if (networkCurrentReducer && networkCurrentReducer.length > 0) {
      let arrNet = [];
      networkCurrentReducer.forEach((item) => arrNet.push(item.id));
      this.getKpiWS(arrNet);
    }
  };

  getApiNetworks = (environment) => {
    const { setNetworks } = this.props;

    this.apiService
      .getAllNetworks()
      .pipe(
        take(1),
        tap((x) => {
          if (x && !x.error) {
            let networks = ConfigurationsModel.fromArrayREST(x);
            setNetworks(networks);
            this.setCurrentNetwork(networks, environment);
          } else {
            console.log(x);
          }
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  setCurrentNetwork = (networks, environment) => {
    const { setCurrentNetwork, networkCurrentReducer } = this.props;
    let arrNet = [];
    if (networkCurrentReducer && networkCurrentReducer.length > 0) {
      arrNet = [...networkCurrentReducer];
    } else {
      arrNet = networks.filter((net) => net.id === environment.network);
    }
    if (arrNet && arrNet.length > 0) {
      let ids = [];
      arrNet = arrNet.map((curr) => {
        ids.push(curr.id);
        curr.checked = true;
        return curr;
      });
      setCurrentNetwork(arrNet);
      this.getKpiWS(ids);
    } else {
      if (networkCurrentReducer && networkCurrentReducer.length === 0)
        console.log(arrNet);
    }
  };

  getKpiWS = (ids) => {
    const { updateKpiList } = this.props;
    if (ids) {
      if (this.subscriptions && this.subscriptions.length > 0) {
        this.subscriptions.forEach((x) => x.unsubscribe());
      }
      const combined = combineLatest([
        this.apiService.getKpiOverall(ids),
        this.apiService.getKpiMonitoredIndicator(ids),
      ]).pipe(
        take(1),
        tap(([all, indicator]) => {
          if (all && !all.error) {
            delete all["monitoredVehicleJourneyOnTimeIndicator"];
            updateKpiList(VehicleJourneysAllCounters.from(all));
          } else {
            // console.log(all);
          }

          if (indicator && !indicator.error && !this.props.openKpiDown) {
            var tempIndicator = VehicleJourneysIndicator.from(indicator);
            tempIndicator["monitoredVehicleJourneyOnTimeIndicator"] =
              tempIndicator["indicator"];
            delete tempIndicator["indicator"];
            updateKpiList(tempIndicator);
          } else {
            console.log(indicator);
          }
        }),
        catchError((error) => {
          console.error(error);
          // updateKpiList(VehicleJourneysAllCounters.from(this.k));
          return of(error);
        })
      );

      combined.subscribe();

      const timer = interval(300000);

      timer
        .pipe(
          switchMap((x) =>
            combineLatest([
              this.apiService.getKpiOverall(ids),
              this.apiService.getKpiMonitoredIndicator(ids),
            ]).pipe(
              take(1),
              tap(([all, indicator]) => {
                if (all && !all.error && !this.props.openKpiDown) {
                  delete all["monitoredVehicleJourneyOnTimeIndicator"];
                  updateKpiList(VehicleJourneysAllCounters.from(all));
                } else {
                  // console.log(this.props.openKpiDown, all);
                }

                if (indicator && !indicator.error && !this.props.openKpiDown) {
                  var tempIndicator = VehicleJourneysIndicator.from(indicator);
                  tempIndicator["monitoredVehicleJourneyOnTimeIndicator"] =
                    tempIndicator["indicator"];
                  delete tempIndicator["indicator"];
                  updateKpiList(tempIndicator);
                } else {
                  // console.log(indicator);
                }
                const {
                  updateVehicleEvents,
                  vehiclesEventsReducer,
                  activeLines,
                  updateActiveLines,
                } = this.props;
                if (vehiclesEventsReducer && vehiclesEventsReducer.length > 0) {
                  let busMapPins = [];
                  [...vehiclesEventsReducer].forEach((item) => {
                    if (
                      item &&
                      item.vehicleActivityLocation &&
                      item.vehicleActivityLocation.monitoredVehicleJourney &&
                      item.vehicleActivityLocation.monitoredVehicleJourney
                        .onwardCalls &&
                      item.vehicleActivityLocation.monitoredVehicleJourney
                        .onwardCalls.length > 0
                    ) {
                      busMapPins.push(item);
                    } else if (
                      item &&
                      item.vehicleActivityLocation &&
                      item.vehicleActivityLocation.monitoredVehicleJourney &&
                      item.vehicleActivityLocation.monitoredVehicleJourney
                        .onwardCalls &&
                      item.vehicleActivityLocation.monitoredVehicleJourney
                        .onwardCalls.length === 0 &&
                      item.vehicleActivityLocation.progressBetweenStops &&
                      item.vehicleActivityLocation.progressBetweenStops
                        .percentage &&
                      item.vehicleActivityLocation.progressBetweenStops
                        .percentage < 94
                    ) {
                      busMapPins.push(item);
                    }
                  });
                  updateVehicleEvents(busMapPins);
                }
                if (activeLines && activeLines.length > 0) {
                  let busLinearizedPins = [];
                  let routesAct = [];
                  routesAct = [...activeLines].map((x) => {
                    x.vehicles.forEach((item) => {
                      if (
                        item &&
                        item.monitoredVehicleJourney &&
                        item.monitoredVehicleJourney.onwardCalls &&
                        item.monitoredVehicleJourney.onwardCalls.length > 0
                      ) {
                        busLinearizedPins.push(item);
                      } else if (
                        item &&
                        item.monitoredVehicleJourney &&
                        item.monitoredVehicleJourney.onwardCalls &&
                        item.monitoredVehicleJourney.onwardCalls.length === 0 &&
                        item.monitoredVehicleJourney.progressRate &&
                        item.monitoredVehicleJourney.progressRate < 94
                      ) {
                        busLinearizedPins.push(item);
                      }
                    });
                    x.vehicles = busLinearizedPins;
                    return x;
                  });
                  updateActiveLines(routesAct);
                }
              }),
              catchError((error) => {
                console.error(error);
                // updateKpiList(VehicleJourneysAllCounters.from(this.k));
                return of(error);
              })
            )
          )
        )
        .subscribe();
    }
  };

  render() {
    const { environment } = this.state;
    if (environment) {
      return (
        <EnvironmentContext.Provider value={environment}>
          <MuiThemeProvider theme={theme}>
            <LjsaAuthModule authConfig={environment.authConfig}>
              <PermissionService>
                <Router
                  children={<Routes env={environment} />}
                  basename={"/"}
                />
              </PermissionService>
            </LjsaAuthModule>
          </MuiThemeProvider>
        </EnvironmentContext.Provider>
      );
    }

    return <Loading />;
  }
}

const mapDispatchToProps = {
  setNetworks,
  setCurrentNetwork,
  updateKpiList,
  updateVehicleEvents,
  updateActiveLines,
  addActivity,
  // doLogin,
};

const mapStateToProps = (state) => ({
  vehiclesEventsReducer: getAllVehiclesEvents(state),
  activeLines: getAllActiveLines(state),
  networkCurrentReducer: getCurrentNetwork(state),
  openKpiDown: openKpiDown(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
