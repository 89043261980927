import { CompositeLayer, PathLayer } from "deck.gl";
class TmcSectionLayer extends CompositeLayer {
  renderLayers() {
    const { id, data, visible, color, onClick, isDrawingMode } = this.props;

    return new PathLayer(
      this.getSubLayerProps({
        id,
        data,
        getPath: (d) => d.geometry.coordinates,
        getColor: color,
        pickable: isDrawingMode ? false : true,
        widthScale: 20,
        widthMinPixels: 4,
        widthMaxPixels: 4,
        visible,
        onClick: onClick,
        rounded: true,
      })
    );
  }
}

TmcSectionLayer.layerName = "TmcSectionLayer";

export default TmcSectionLayer;
