import React, { Component } from "react";
import "components/layout/modal/style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { tap, take, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";

//ACTIONS
import {
  getTabModal,
  getInsertEventButtonDisabled,
  getForwardEventButtonDisabled,
  getNewEvent,
  getTypeModal,
  getCurrentEvent,
  getNewEventValid,
  getSelectModal,
  getTypeLocalization,
  getUsername,
  getUserRoles,
  getAllEvents,
} from "store";
import {
  addEventToList,
  modifyEvent,
  setCurrentEvent,
} from "reducers/events/events.actions";
import {
  setTabModal,
  togglePopup,
  disableInsertEventButton,
  enableInsertEventButton,
  setApiOnError,
} from "reducers/ui/modal/modal.actions";
import { addActivity } from "reducers/user/user.actions";

//MODEL
import { SituationRecord } from "reducers/events/models/situation-record";
import { UserActivityModel } from "reducers/user/user.model";
//UTIL
import { getTypeTranslation } from "utils/util-events";

class InnerHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  onClickTab = (type) => {
    const { setTabModal } = this.props;
    setTabModal(type);
  };

  goBack = () => {
    const { modalTabReducer } = this.props;
    if (modalTabReducer === "attributes") {
      this.onClickTab("localization");
    } else if (modalTabReducer === "localization") {
      this.onClickTab("general");
    }
  };

  goForward = () => {
    const { modalTabReducer } = this.props;
    if (modalTabReducer === "general") {
      this.onClickTab("localization");
    } else if (modalTabReducer === "localization") {
      this.onClickTab("attributes");
    }
  };

  createEvent = () => {
    const {
      addEventToList,
      newEvent,
      togglePopup,
      setCurrentEvent,
      typeModal,
      selectModal,
      currentLocalizationType,
      setApiOnError,
      listEvent,
      username,
    } = this.props;

    //FROM CONFIG FILE
    const localizationRdsTmcCountryCode = this.context
      .localizationRdsTmcCountryCode;
    const localizationRdsTmcTableNumber = this.context
      .localizationRdsTmcTableNumber;
    const localizationRdsTmcTableVersion = this.context
      .localizationRdsTmcTableVersion;

    let evMapped = SituationRecord.fromModal(
      { ...newEvent },
      { ...selectModal },
      null,
      typeModal,
      currentLocalizationType,
      localizationRdsTmcCountryCode,
      localizationRdsTmcTableNumber,
      localizationRdsTmcTableVersion,
      username
    );

    if (!evMapped.situationRecordId) {
      evMapped.situationRecordId =
        "event-" +
        new Date().valueOf().toString() +
        "-" +
        (Math.floor(Math.random() * (100000000 - 1 + 1)) + 1).toString();
    }
    if (typeModal === "newEvent") {
      let objEvent = {
        situationId: evMapped.situationId,
        headerInformation: {
          areaOfInterest: "CONTINENT_WIDE",
          confidentiality: "RESTRICTED_TO_AUTHORITIES",
          informationStatus: "REAL",
          urgency: "NORMAL_URGENCY",
        },
        situationVersion: "",
        situationVersionTime: new Date().toISOString(),
        situationRecordsIds: [],
        situationRecords: [evMapped],
      };

      this.apiService
        .addSituation(objEvent)
        .pipe(
          take(1),
          tap((x) => {
            if (
              x &&
              !x.error &&
              x.createdSituationRecord &&
              x.createdSituationRecord.length > 0
            ) {
              let roadSituations = SituationRecord.from(
                x.createdSituationRecord[0]
              );
              roadSituations.managedCause = true;

              addEventToList(roadSituations);
              setCurrentEvent(roadSituations);
            } else {
              console.log(x);
            }
          }),
          concatMap((data) => {
            if (
              data &&
              data.createdSituationRecord &&
              data.createdSituationRecord.length > 0
            ) {
              return this.postEventCreationActivity(
                data.createdSituationRecord[0],
                "add"
              );
            } else {
              return of([]);
            }
          }),
          catchError((error) => {
            console.error(error);
            setApiOnError(true);
            return of(error);
          })
        )
        .subscribe();
    }
    if (typeModal === "addAssociatedEvent") {
      let causeEvent = listEvent.find(
        (item) => item.managedCause && item.situationId === evMapped.situationId
      );
      if (causeEvent) {
        evMapped.cause.situationRecordType = causeEvent.situationRecordType;
        evMapped.cause.situationRecordId = causeEvent.situationRecordId;
        evMapped.cause.situationRecordVersion =
          causeEvent.situationRecordVersion;
      }
      this.apiService
        .associetedSituation(evMapped)
        .pipe(
          take(1),
          tap((x) => {
            if (x && !x.error) {
              let roadSituations = SituationRecord.from(x);
              addEventToList(roadSituations);
              setCurrentEvent(roadSituations);
            }
          }),
          concatMap((data) => {
            return this.postEventCreationActivity(data, "add");
          }),
          catchError((error) => {
            console.error(error);
            setApiOnError(true);
            return of(error);
          })
        )
        .subscribe();
    }

    togglePopup();
  };

  modifyEvent = () => {
    const {
      modifyEvent,
      newEvent,
      togglePopup,
      currentEvent,
      selectModal,
      typeModal,
      setCurrentEvent,
      currentLocalizationType,
      setApiOnError,
      username,
    } = this.props;

    //FROM CONFIG FILE
    const localizationRdsTmcCountryCode = this.context
      .localizationRdsTmcCountryCode;
    const localizationRdsTmcTableNumber = this.context
      .localizationRdsTmcTableNumber;
    const localizationRdsTmcTableVersion = this.context
      .localizationRdsTmcTableVersion;

    let evMapped = SituationRecord.fromModal(
      { ...newEvent },
      { ...selectModal },
      { ...currentEvent },
      typeModal,
      currentLocalizationType,
      localizationRdsTmcCountryCode,
      localizationRdsTmcTableNumber,
      localizationRdsTmcTableVersion,
      username
    );

    evMapped.cause.situationRecordType = currentEvent.cause.situationRecordType;
    evMapped.cause.situationRecordId = currentEvent.cause.situationRecordId;
    evMapped.cause.situationRecordVersion =
      currentEvent.cause.situationRecordVersion;

    this.apiService
      .updateSituationRecord(evMapped)
      .pipe(
        take(1),
        tap((x) => {
          if (x && !x.error) {
            let roadSituations = SituationRecord.from(x);
            currentEvent &&
              modifyEvent(
                roadSituations,

                currentEvent.validity && currentEvent.validity.validityStatus
              );
            setCurrentEvent(roadSituations);
          }
        }),
        concatMap((data) => {
          return this.postEventCreationActivity(data, "update");
        }),
        catchError((error) => {
          console.error(error);
          setApiOnError(true);
          return of(error);
        })
      )
      .subscribe();
    togglePopup();
  };

  postEventCreationActivity = (event, type) => {
    const { username, addActivity, selectModal, userRoles } = this.props;

    return this.apiService
      .postUserActivity({
        module: "SITUATION",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: type === "add" ? "DATA_INSERTED" : "DATA_UPDATED",
        actionType: "MANUAL",
        details: {
          values: [
            {
              value:
                'Evento "' +
                getTypeTranslation(event, selectModal) +
                '" con id: ' +
                event.situationRecordId,
              lang: "it",
            },
          ],
        },
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        tap((x) => {
          addActivity(UserActivityModel.fromREST(x));
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  checkClosedEvent = () => {
    const {
      enableInsertEventButton,
      disableInsertEventButton,
      newEventValid,
    } = this.props;
    if (
      newEventValid.iDEvent &&
      newEventValid.iDPractice &&
      newEventValid.stateEvent &&
      newEventValid.typeEvent &&
      newEventValid.underTypeEvent &&
      newEventValid.dateStartEvent &&
      newEventValid.timeStartEvent &&
      newEventValid.gravityEvent &&
      newEventValid.sourceOptionEvent &&
      newEventValid.probabilityEvent &&
      newEventValid.note &&
      newEventValid.supplierEvent
    ) {
      return enableInsertEventButton();
    } else return disableInsertEventButton();
  };

  closeEvent = () => {
    const { togglePopup } = this.props;
    this.checkClosedEvent();
    togglePopup();
  };

  render() {
    const {
      modalTabReducer,
      isInsertEventButtonDisabled,
      isForwardEventButtonDisabled,
      typeModal,
      newEvent,
    } = this.props;

    return (
      <div className="padding-tab">
        <ul className="uk-tab ">
          <li
            className={
              modalTabReducer === "general"
                ? "uk-active pointer-event-none"
                : "pointer-event-none"
            }
          >
            <a
              href="/#"
              /* onClick={(event) => {
          event.preventDefault();
          this.onClickTab("general");
        }} */
            >
              Generale
            </a>
          </li>
          <li
            className={
              modalTabReducer === "localization"
                ? "uk-active pointer-event-none"
                : "pointer-event-none"
            }
          >
            <a
              href="/#"
              /* onClick={(event) => {
          event.preventDefault();
          this.onClickTab("localization");
        }} */
            >
              Localizzazione
            </a>
          </li>
          <li
            className={
              modalTabReducer === "attributes"
                ? "uk-active pointer-event-none"
                : "pointer-event-none"
            }
          >
            <a
              href="/#"
              /* onClick={(event) => {
          event.preventDefault();
          this.onClickTab("attributes");
        }} */
            >
              Attributi
            </a>
          </li>
          {/* <li className={modalTabReducer === "info" ? "uk-active" : ""}>
            <a
              href="/#"
              onClick={(event) => {
                event.preventDefault();
                this.onClickTab("info");
              }}
            >
              Gestione info
            </a>
          </li> */}
        </ul>
        <div
          className={
            modalTabReducer === "general"
              ? "inserisci-button-container uk-flex uk-flex-row uk-flex-right"
              : "inserisci-button-container uk-flex uk-flex-row uk-flex-between"
          }
        >
          {modalTabReducer !== "general" && (
            <button
              className="uk-button uk-button-default uk-button-small"
              onClick={(e) => {
                e.preventDefault();
                this.goBack();
              }}
            >
              Indietro
            </button>
          )}
          {typeModal === "modifyEvent" &&
            modalTabReducer !== "localization" &&
            modalTabReducer !== "attributes" && (
              <button
                disabled={
                  isForwardEventButtonDisabled ||
                  (newEvent && newEvent.stateEvent === "closed")
                }
                className="uk-button uk-button-default uk-button-small uk-margin-right"
                onClick={(e) => {
                  e.preventDefault();
                  this.closeEvent();
                }}
              >
                Chiudi Evento
              </button>
            )}
          {modalTabReducer !== "attributes" && (
            <button
              disabled={isForwardEventButtonDisabled}
              className="uk-button uk-button-primary uk-button-small"
              onClick={(e) => {
                e.preventDefault();
                this.goForward();
              }}
            >
              Avanti
            </button>
          )}

          {modalTabReducer === "attributes" && typeModal !== "modifyEvent" && (
            <button
              className="uk-button uk-button-primary uk-button-small"
              disabled={
                isInsertEventButtonDisabled && modalTabReducer !== "attributes"
              }
              onClick={(e) => {
                e.preventDefault();
                this.createEvent();
              }}
            >
              Crea evento
            </button>
          )}

          {typeModal === "modifyEvent" && modalTabReducer === "attributes" && (
            <button
              disabled={
                isInsertEventButtonDisabled && modalTabReducer === "attributes"
              }
              className={
                newEvent && newEvent.stateEvent === "closed"
                  ? "uk-button uk-button-primary update-button-disabled"
                  : "uk-button uk-button-primary uk-button-small"
              }
              onClick={(e) => {
                e.preventDefault();
                this.modifyEvent();
              }}
            >
              Aggiorna Evento
            </button>
          )}
        </div>
      </div>
    );
  }
}
InnerHeader.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setTabModal,
  togglePopup,
  addEventToList,
  modifyEvent,
  disableInsertEventButton,
  enableInsertEventButton,
  setCurrentEvent,
  setApiOnError,
  addActivity,
};

const mapStateToProps = (state) => ({
  modalTabReducer: getTabModal(state),
  isInsertEventButtonDisabled: getInsertEventButtonDisabled(state),
  isForwardEventButtonDisabled: getForwardEventButtonDisabled(state),
  newEvent: getNewEvent(state),
  typeModal: getTypeModal(state),
  currentEvent: getCurrentEvent(state),
  newEventValid: getNewEventValid(state),
  selectModal: getSelectModal(state),
  currentLocalizationType: getTypeLocalization(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
  listEvent: getAllEvents(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InnerHeader)
);
