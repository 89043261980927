export const ModalActions = {
  RESET_MODAL_UI: "UI/MODAL/RESET_MODAL_UI",
  SET_TAB_MODAL: "UI/MODAL/SET_TAB_MODAL",
  DISABLE_INSERT_EVENT_BUTTON: "UI/MODAL/DISABLE_INSERT_EVENT_BUTTON",
  ENABLE_INSERT_EVENT_BUTTON: "UI/MODAL/ENABLE_INSERT_EVENT_BUTTON",
  DISABLE_FORWARD_EVENT_BUTTON: "UI/MODAL/DISABLE_FORWARD_EVENT_BUTTON",
  ENABLE_FORWARD_EVENT_BUTTON: "UI/MODAL/ENABLE_FORWARD_EVENT_BUTTON",
  SET_TYPE_MODAL: "UI/MODAL/SET_TYPE_MODAL",
  TOGGLE_POPUP: "UI/MODAL/TOGGLE_POPUP",
  TOGGLE_MODAL: "UI/MODAL/TOGGLE_MODAL",
  SET_TYPE_LOCALIZATION: "UI/MODAL/ SET_TYPE_LOCALIZATION",
  API_ON_ERROR: "UI/MODAL/API_ON_ERROR",
  SET_ZOOM_LOCALIZATION: "UI/MODAL/SET_ZOOM_LOCALIZATION",
};

export const resetModalUi = () => (dispatch) => {
  dispatch({
    type: ModalActions.RESET_MODAL_UI,
  });
};

export const setTabModal = (tab) => (dispatch) => {
  dispatch({
    type: ModalActions.SET_TAB_MODAL,
    payload: tab,
  });
};

export const disableInsertEventButton = () => (dispatch) => {
  dispatch({
    type: ModalActions.DISABLE_INSERT_EVENT_BUTTON,
    payload: null,
  });
};

export const enableInsertEventButton = () => (dispatch) => {
  dispatch({
    type: ModalActions.ENABLE_INSERT_EVENT_BUTTON,
    payload: null,
  });
};

export const disableForwardEventButton = () => (dispatch) => {
  dispatch({
    type: ModalActions.DISABLE_FORWARD_EVENT_BUTTON,
  });
};

export const enableForwardEventButton = () => (dispatch) => {
  dispatch({
    type: ModalActions.ENABLE_FORWARD_EVENT_BUTTON,
  });
};

export const setTypeModal = (type) => (dispatch) => {
  dispatch({
    type: ModalActions.SET_TYPE_MODAL,
    payload: type,
  });
};
export const setTypeLocalization = (type) => (dispatch) => {
  dispatch({
    type: ModalActions.SET_TYPE_LOCALIZATION,
    payload: type,
  });
};

export const togglePopup = () => (dispatch) => {
  dispatch({
    type: ModalActions.TOGGLE_POPUP,
  });
};

export const toggleModal = () => (dispatch) => {
  dispatch({
    type: ModalActions.TOGGLE_MODAL,
  });
};

export const setApiOnError = (isOnError) => (dispatch) => {
  dispatch({
    type: ModalActions.API_ON_ERROR,
    payload: isOnError,
  });
};

export const setZoomLocalization = (zoom) => (dispatch) => {
  dispatch({
    type: ModalActions.SET_ZOOM_LOCALIZATION,
    payload: zoom,
  });
};
