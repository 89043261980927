import React, { Component, forwardRef } from "react";
import "./style.less";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, setHours } from "date-fns";

class CustomisedTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      value,
      callbackFromParent,
      disabled,
      startTime,
      endTime,
      isEndTime,
      isSameDate,
    } = this.props;

    const CustomInput = forwardRef((props, ref) => {
      return (
        <div
          className={
            (disabled ? "text-disabled " : "") +
            "time-picker-input uk-flex uk-flex-middle"
          }
          ref={ref}
          onClick={props.onClick}
        >
          <div className="uk-margin-left">{props.value} </div>
          <span
            className="uk-icon uk-position-right uk-margin-right uk-flex uk-flex-middle"
            uk-icon="icon: acr-interface-hour"
          />
        </div>
      );
    });

    const minTime = isSameDate
      ? startTime && isEndTime
        ? setHours(
            setMinutes(startTime, startTime.getMinutes()),
            startTime.getHours()
          )
        : setHours(setMinutes(startTime, 0), 0)
      : setHours(setMinutes(startTime, 0), 0);

    const maxTime = isSameDate
      ? endTime && !isEndTime
        ? setHours(
            setMinutes(startTime, endTime.getMinutes()),
            endTime.getHours()
          )
        : setHours(setMinutes(startTime, 59), 23)
      : setHours(setMinutes(startTime, 59), 23);

    return (
      <DatePicker
        selected={value}
        onChange={(date) => callbackFromParent(date)}
        customInput={<CustomInput />}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        minTime={minTime}
        maxTime={maxTime}
        readOnly={disabled}
      />
    );
  }
}

export default CustomisedTimePicker;
