export const INITIAL_STATE_WORKSPACE = {
  workspaces: [],
  currentWorkspace: null,
};

export class UserModel {
  role;
  username;
  userId;

  constructor() {
    this.role = null;
    this.username = null;
    this.userId = null;
  }

  static fromREST(obj) {
    let user = new UserModel();
    user.role = obj.role ? obj.role : null;
    user.username = obj.username ? obj.username : null;
    user.userId = obj.userId ? obj.userId : null;
    return user;
  }
}

export class CategoryModel {
  categoryName;
  subCategoryName;
  categoryId;

  constructor() {
    this.categoryName = null;
    this.subCategoryName = null;
    this.categoryId = null;
  }

  static fromREST(obj) {
    let cat = new CategoryModel();
    cat.categoryName = obj.categoryName ? obj.categoryName : null;
    cat.subCategoryName = obj.subCategoryName ? obj.subCategoryName : null;
    cat.categoryId = obj.categoryId ? obj.categoryId : null;
    return cat;
  }
}

export class ValueModel {
  value;
  lang;
  detailsId;

  constructor() {
    this.value = null;
    this.lang = null;
    this.detailsId = null;
  }

  static fromREST(obj) {
    let val = new ValueModel();
    val.value = obj.value ? obj.value : null;
    val.lang = obj.lang ? obj.lang : null;
    val.detailsId = obj.detailsId ? obj.detailsId : null;
    return val;
  }

  static fromArrayREST(data) {
    return data.map(ValueModel.fromREST).filter((item) => item !== null);
  }
}

export class ListOfValuesModel {
  values;

  constructor() {
    this.values = null;
  }

  static fromREST(obj) {
    let val = new ListOfValuesModel();
    val.values = obj.values ? ValueModel.fromArrayREST(obj.values) : [];
    return val;
  }
}

export class PreferencePayloadModel {
  value;
  targetClass;
  payloadId;

  constructor() {
    this.value = null;
    this.targetClass = null;
    this.payloadId = null;
  }

  static fromREST(obj) {
    let val = new PreferencePayloadModel();
    val.value = obj.value ? obj.value : null;
    val.targetClass = obj.targetClass ? obj.targetClass : null;
    val.payloadId = obj.payloadId ? obj.payloadId : null;
    return val;
  }

  static fromArrayREST(data) {
    return data
      .map(PreferencePayloadModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class PreferenceActivityModel {
  preferenceId;
  preferenceCreationTime;
  preferenceLastUpdateTime;
  module;
  user;
  category;
  tags;
  isDefault;
  details;
  payload;

  constructor() {
    this.preferenceId = null;
    this.preferenceCreationTime = null;
    this.preferenceLastUpdateTime = null;
    this.module = null;
    this.user = UserModel;
    this.category = CategoryModel;
    this.tags = [];
    this.isDefault = null;
    this.details = ListOfValuesModel;
    this.payload = PreferencePayloadModel;
  }

  static fromREST(obj) {
    let preference = new PreferenceActivityModel();
    preference.preferenceId = obj.preferenceId ? obj.preferenceId : null;
    preference.preferenceCreationTime = obj.preferenceCreationTime
      ? obj.preferenceCreationTime
      : null;
    preference.preferenceLastUpdateTime = obj.preferenceLastUpdateTime
      ? obj.preferenceLastUpdateTime
      : null;
    preference.module = obj.module ? obj.module : null;
    preference.user = obj.user ? UserModel.fromREST(obj.user) : null;
    preference.category = obj.category
      ? CategoryModel.fromREST(obj.category)
      : null;
    preference.tags = obj.tags ? obj.tags : [];
    preference.isDefault = obj.isDefault ? obj.isDefault : null;
    preference.details = obj.details
      ? ListOfValuesModel.fromREST(obj.details)
      : null;
    preference.payload = obj.payload
      ? PreferencePayloadModel.fromREST(obj.payload)
      : null;
    return preference;
  }

  static fromArrayREST(data) {
    return data
      .map(PreferenceActivityModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class WorkspaceToSaveModel {
  workspaceName;
  layoutMap;
  zoom;
  mapCenter;
  selectedKpi;
  menu;
  bottombar;

  constructor() {
    this.workspaceName = null;
    this.layoutMap = null;
    this.zoom = null;
    this.mapCenter = null;
    this.selectedKpi = [];
    this.menu = null;
    this.bottombar = null;
  }

  static fromREST(obj) {
    let val = new WorkspaceToSaveModel();
    val.workspaceName = obj.workspaceName ? obj.workspaceName : null;
    val.layoutMap = obj.layoutMap ? obj.layoutMap : null;
    val.zoom = obj.zoom ? obj.zoom : null;
    val.mapCenter = obj.mapCenter ? obj.mapCenter : null;
    val.selectedKpi = obj.selectedKpi ? obj.selectedKpi : [];
    val.menu = obj.menu ? MenuModel.fromREST(obj.menu) : null;
    val.bottombar = obj.bottombar
      ? BottombarMenuModel.fromREST(obj.bottombar)
      : null;
    return val;
  }
}

export class MenuModel {
  graph;
  tpl;
  installations;

  constructor() {
    this.graph = null;
    this.tpl = null;
    this.installations = null;
  }

  static fromREST(obj) {
    let val = new MenuModel();
    val.graph = obj.graph ? GraphMenuModel.fromREST(obj.graph) : null;
    val.tpl = obj.tpl ? TplMenuModel.fromREST(obj.tpl) : null;
    val.installations = obj.installations
      ? InstallationMenuModel.fromREST(obj.installations)
      : null;
    return val;
  }

  static fromArrayREST(data) {
    return data.map(MenuModel.fromREST).filter((item) => item !== null);
  }
}

export class InstallationMenuModel {
  station;
  cameras;
  mobilitaCameras;
  pmv;
  solariPmv;
  aesysPmv;
  futuritPmv;
  spheraPmv;
  visualLabPmv;
  parking;

  constructor() {
    this.station = null;
    this.cameras = null;
    this.mobilitaCameras = null;
    this.pmv = null;
    this.solariPmv = null;
    this.aesysPmv = null;
    this.futuritPmv = null;
    this.visualLabPmv = null;
    this.parking = null;
    this.spheraPmv = null;
  }

  static fromREST(obj) {
    let val = new InstallationMenuModel();
    val.station = obj.station ? obj.station : null;
    val.cameras = obj.cameras ? obj.cameras : null;
    val.mobilitaCameras = obj.mobilitaCameras ? obj.mobilitaCameras : null;
    val.pmv = obj.pmv ? obj.pmv : null;
    val.solariPmv = obj.solariPmv ? obj.solariPmv : null;
    val.aesysPmv = obj.aesysPmv ? obj.aesysPmv : null;
    val.futuritPmv = obj.futuritPmv ? obj.futuritPmv : null;
    val.visualLabPmv = obj.visualLabPmv ? obj.visualLabPmv : null;
    val.parking = obj.parking ? obj.parking : null;
    val.spheraPmv = obj.spheraPmv? obj.spheraPmv : null
    return val;
  }

  static fromArrayREST(data) {
    return data
      .map(InstallationMenuModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class TplMenuModel {
  transportation;
  city;
  regional;
  events;
  activeEvents;
  plannedEvents;
  traffic;
  typicalTraffic;
  stateTraffic;

  constructor() {
    this.transportation = null;
    this.city = null;
    this.regional = null;
    this.events = null;
    this.activeEvents = null;
    this.plannedEvents = null;
    this.traffic = null;
    this.typicalTraffic = null;
    this.stateTraffic = null;
  }

  static fromREST(obj) {
    let val = new TplMenuModel();
    val.transportation = obj.transportation ? obj.transportation : null;
    val.city = obj.city ? obj.city : null;
    val.regional = obj.regional ? obj.regional : null;
    val.events = obj.events ? obj.events : null;
    val.activeEvents = obj.activeEvents ? obj.activeEvents : null;
    val.plannedEvents = obj.plannedEvents ? obj.plannedEvents : null;
    val.traffic = obj.traffic ? obj.traffic : null;
    val.typicalTraffic = obj.typicalTraffic ? obj.typicalTraffic : null;
    val.stateTraffic = obj.stateTraffic ? obj.stateTraffic : null;
    return val;
  }

  static fromArrayREST(data) {
    return data.map(TplMenuModel.fromREST).filter((item) => item !== null);
  }
}

export class GraphMenuModel {
  networkRdsTmc;
  graph;
  arc;
  node;
  centroid;

  constructor() {
    this.networkRdsTmc = null;
    this.graph = null;
    this.arc = null;
    this.node = null;
    this.centroid = null;
  }

  static fromREST(obj) {
    let val = new GraphMenuModel();
    val.networkRdsTmc = obj.networkRdsTmc ? obj.networkRdsTmc : null;
    val.graph = obj.graph ? obj.graph : null;
    val.arc = obj.arc ? obj.arc : null;
    val.node = obj.node ? obj.node : null;
    val.centroid = obj.centroid ? obj.centroid : null;
    return val;
  }

  static fromArrayREST(data) {
    return data.map(GraphMenuModel.fromREST).filter((item) => item !== null);
  }
}

export class BottombarMenuModel {
  cctv;
  tpl;

  constructor() {
    this.cctv = null;
    this.tpl = null;
  }

  static fromREST(obj) {
    let val = new BottombarMenuModel();
    val.cctv = obj.cctv ? obj.cctv : [];
    val.tpl = obj.tpl ? obj.tpl : [];
    return val;
  }

  static fromArrayREST(data) {
    return data
      .map(BottombarMenuModel.fromREST)
      .filter((item) => item !== null);
  }
}
