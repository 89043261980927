import { CompositeLayer, PathLayer } from "deck.gl";

class TmcSegmentLayer extends CompositeLayer {
  renderLayers() {
    const { data } = this.props;

    return new PathLayer(
      this.getSubLayerProps({
        id: "icon-segments-modal",
        data,
        getPath: (d) => d.geometry.coordinates,
        getColor: [0, 153, 51],
        pickable: true,
        widthScale: 20,
        widthMinPixels: 4,
        widthMaxPixels: 4,
        visible: true,
        rounded: true,
      })
    );
  }
}

TmcSegmentLayer.layerName = "TmcSegmentLayerModal";

export default TmcSegmentLayer;
