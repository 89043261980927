import { INITIAL_STATE_CONFIGURATIONS } from "./configurations.model";
import { ConfigurationsActions } from "./configurations.actions";

export default function configurationsReducer(
  state = INITIAL_STATE_CONFIGURATIONS,
  action
) {
  switch (action.type) {
    case ConfigurationsActions.SET_NETWORKS:
      let listNetworks = [];
      if (
        action.payload &&
        action.payload.length > 0 &&
        state.networks.current &&
        state.networks.current.length > 0
      ) {
        listNetworks = action.payload.map((x) => {
          state.networks.current.forEach((cur) => {
            if (cur.id === x.id) {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
          return x;
        });
      } else {
        listNetworks = action.payload;
      }
      return {
        ...state,
        networks: {
          ...state.networks,
          list: listNetworks,
        },
      };
    case ConfigurationsActions.SET_CURRENT_NETWORK:
      let current = [];
      let newList = [...state.networks.list];
      if (action.payload && action.payload.length > 0) {
        current = action.payload;
        newList = newList.map((net) => {
          action.payload.forEach((x) => {
            if (net.id === x.id) {
              net.checked = true;
            } else {
              net.checked = false;
            }
          });
          return net;
        });
      }
      return {
        ...state,
        networks: {
          ...state.networks,
          current,
          list: newList,
        },
      };
    case ConfigurationsActions.CHECK_NETWORK:
      let list = [];
      list = [...state.networks.list].map((net) => {
        if (net && action.payload && net.id === action.payload.id) {
          net.checked = true;
        } else {
          net.checked = false;
        }
        return net;
      });
      return {
        ...state,
        networks: {
          ...state.networks,
          list: list,
        },
      };
    default:
      return state;
  }
}

export const getCurrentNetwork = (state) => state.networks.current;
export const getNetworks = (state) => state.networks.list;
