export const StationsActions = {
  SET_LIST_STATIONS: "INSTALLATIONS/SET_LIST_STATIONS",
  SET_CURRENT_STATION: "INSTALLATIONS/SET_CURRENT_STATION",
  UPDATE_CURRENT_STATION: "INSTALLATIONS/UPDATE_CURRENT_STATION",
  SET_CURRENT_STATION_MAP: "INSTALLATIONS/SET_CURRENT_STATION_MAP",
  RESET_CURRENT_STATION: "INSTALLATIONS/RESET_CURRENT_STATION",
  OPEN_STATION_GRAPH: "INSTALLATIONS/OPEN_STATION_GRAPH",
  OPEN_STATION_TABLE: "INSTALLATIONS/OPEN_STATION_TABLE",
  UPDATE_STATION_STATE_WS: "INSTALLATIONS/UPDATE_STATION_STATE_WS",
  UPDATE_STATION_MEASURES_WS: "INSTALLATIONS/UPDATE_STATION_MEASURES_WS",
};

export const setStationsList = (list) => (dispatch) => {
  dispatch({
    type: StationsActions.SET_LIST_STATIONS,
    payload: { list },
  });
};

export const setCurrentStation = (current) => (dispatch) => {
  dispatch({
    type: StationsActions.SET_CURRENT_STATION,
    payload: current,
  });
};
export const updateCurrentStation = (current) => (dispatch) => {
  dispatch({
    type: StationsActions.UPDATE_CURRENT_STATION,
    payload: current,
  });
};
export const resetCurrentStation = () => (dispatch) => {
  dispatch({
    type: StationsActions.RESET_CURRENT_STATION,
  });
};

export const updateStationStatesWS = (states) => (dispatch) => {
  dispatch({
    type: StationsActions.UPDATE_STATION_STATE_WS,
    payload: states,
  });
};

export const updateStationMeasuresWS = (measures) => (dispatch) => {
  dispatch({
    type: StationsActions.UPDATE_STATION_MEASURES_WS,
    payload: measures,
  });
};
