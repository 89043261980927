import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
// COMPONENTS
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import LoadGraphData from "components/pages/load.data/load.graph.data";
import LoadNetworkData from "components/pages/load.data/load.network.data";
import LoadParkingData from "components/pages/load.data/load.parking.data";
import LoadCameraData from "components/pages/load.data/load.camera.data";
import LoadPmvData from "components/pages/load.data/load.pmv.data";
import Permission from "components/shared/permission";
import LoadEventData from "components/pages/load.data/load.event.data";
import LoadStationData from "components/pages/load.data/load.station.data";
import LoadTplData from "components/pages/load.data/load.tpl.data";

class LoadMapData extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ErrorBoundary>
        <LoadGraphData />
        <LoadNetworkData />
        <Permission actions="PARKING">
          <LoadParkingData />
        </Permission>
        <Permission actions="CCTV-MONITORING">
          <LoadCameraData />
        </Permission>
        <Permission actions="PMV_MONITORING">
          <LoadPmvData />
        </Permission>
        <Permission actions="EVENT">
          <LoadEventData />
        </Permission>
        <Permission actions="STATION">
          <LoadStationData />
        </Permission>
        <Permission actions="TPL Region">
          <LoadTplData />
        </Permission>
      </ErrorBoundary>
    );
  }

  componentWillUnmount() {}
}

LoadMapData.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadMapData)
);
