import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Callback } from "components/layout/auth/auth-callback";
import { PrivateRoute } from "@almaviva/ljsa-react-module";
/** Layouts **/
import DashboardLayoutRoute from "components/layout/dashboard";

/** Component Containers **/
import HomePage from "components/pages/home.page";
import TplPage from "components/pages/tpl.page";
import EventPage from "components/pages/event.page";
import GraphPage from "components/pages/graph.page";
import NetworkRdsTmcPage from "components/pages/network-rds-tmc.page";
import BasicPage from "components/pages/basic.page";
import TrafficPage from "components/pages/traffic.page";
import CamerasPage from "components/pages/cameras.page";
import StationsPage from "components/pages/stations.page";
import ParkingPage from "components/pages/parking.page";
import PmvPage from "components/pages/pmv.page";

export const Routes = (props) => (
  <Switch>
    <Route path="/signin" component={Callback} />
    <PrivateRoute
      exact
      path="/"
      component={() => <Redirect to="/home" />}
      order={0}
    />

    {sessionStorage.getItem("access_token") ? (
      <DashboardLayoutRoute path="/home" component={HomePage} />
    ) : (
      <Redirect to="/" />
    )}

    <DashboardLayoutRoute path="/installations/cctv" component={CamerasPage} />
    <DashboardLayoutRoute
      path="/installations/stations"
      component={StationsPage}
    />
    <DashboardLayoutRoute
      path="/installations/parking"
      component={ParkingPage}
    />
    <DashboardLayoutRoute path="/installations/pmv" component={PmvPage} />
    <DashboardLayoutRoute path="/installations" component={BasicPage} />

    <DashboardLayoutRoute path="/viability/traffic" component={TrafficPage} />
    <DashboardLayoutRoute path="/viability/tpl" component={TplPage} />
    <DashboardLayoutRoute path="/viability/events" component={EventPage} />
    <DashboardLayoutRoute path="/viability" component={BasicPage} />

    {/* MORE SPECIFIC URLS BEFORE GENERIC ONES */}
    <DashboardLayoutRoute path="/network/graph" component={GraphPage} />
    <DashboardLayoutRoute
      path="/network/rete-rds-tmc"
      component={NetworkRdsTmcPage}
    />
    <DashboardLayoutRoute path="/network" component={BasicPage} />
  </Switch>
);
