export function makeLayersList(mapComponent) {
  const {
    cityBusStops,
    regionBusStops,
    cityBusLines,
    regionBusLines,
    cityBusVisible,
    regionBusVisible,
    cityBusActiveLines,
    regionBusActiveLines,
    cityBusStopsActive,
    regionBusStopsActive,
    //event ui
    isActiveVisible,
    isPlannedVisible,
    //events
    activeEvents,
    plannedEvents,
    //graph ui
    isCentroidVisible,
    isNodeVisible,
    isNetworkRdsTmcVisible,
    isArcVisible,
    //graph
    centroidEvents,
    nodeEvents,
    pointsEvents,
    arcEvents,
    arcSelectedEvent,
    shortestDistanceOnGraphReducer,
    currentEvent,
    isStationVisible,
    //traffic
    stations,
    trafficFilteredArcs,
    allTrafficChecked,
    //installation ui
    isAllCamerasVisible,
    isParkingVisible,
    isPmvVisible,
    isAesysPanelVisible,
    isSolariPanelVisible,
    isFuturitPanelVisible,
    isVisuallabPanelVisible,
    isSfheraPanelVisible,
    //installations
    webcams,
    filteredParkings,
    filteredPmvs,
  } = mapComponent.props;

  return [
    /* mapComponent.createBusLineLayer(
          "graf-layer",
          graf,
          [59, 59, 178],
          mapComponent.onClickLine("region"),
          true,
          false
        ), */
    mapComponent.renderArcLayer(
      "arc-layer",
      arcEvents,
      [3, 140, 252],
      [128, 197, 253],
      isArcVisible
    ),
    mapComponent.renderTrafficArcLayer(
      "traffic-arc-layer",
      trafficFilteredArcs,
      allTrafficChecked
    ),
    mapComponent.renderArcLayerEvent(
      "arc-layer-event",
      arcSelectedEvent,
      [0, 0, 126],
      [128, 197, 253],
      currentEvent && (isActiveVisible || isPlannedVisible)
    ),
    mapComponent.renderShortestDistanceArcLayer(
      "shortest-distance-arc-layer",
      shortestDistanceOnGraphReducer,
      [168, 50, 76],
      true
    ),
    mapComponent.createBusLineLayer(
      "region-bus-line-layer",
      regionBusLines,
      [34, 98, 178],
      mapComponent.onClickLine("region"),
      regionBusVisible,
      false
    ),
    mapComponent.createBusLineLayer(
      "city-bus-line-layer",
      cityBusLines,
      [255, 56, 56],
      mapComponent.onClickLine("city"),
      cityBusVisible,
      false
    ),
    mapComponent.createBusLineLayer(
      "selected-region-bus-line-layer",
      regionBusActiveLines,
      [34, 98, 178],
      mapComponent.onClickLine("region"),
      regionBusVisible,
      true
    ),
    mapComponent.createBusLineLayer(
      "selected-city-bus-line-layer",
      cityBusActiveLines,
      [255, 56, 56],
      mapComponent.onClickLine("city"),
      cityBusVisible,
      true
    ),
    mapComponent.createBusStopLayer(
      "city-bus-stop-layer",
      cityBusStops,
      false,
      cityBusVisible
    ),
    mapComponent.createBusStopLayer(
      "region-bus-stop-layer",
      regionBusStops,
      false,
      regionBusVisible
    ),

    mapComponent.createBusStopLayer(
      "selected-region-bus-top-layer",
      regionBusStopsActive,
      true,
      regionBusVisible
    ),
    mapComponent.createBusStopLayer(
      "selected-city-bus-stop-layer",
      cityBusStopsActive,
      true,
      cityBusVisible
    ),
    mapComponent.renderNodeLayer("nodes-layer", nodeEvents, isNodeVisible),
    mapComponent.renderCentroidsLayer(
      "centroids-layer",
      centroidEvents,
      isCentroidVisible
    ),
    mapComponent.renderPointsTmcLayer(
      "points-tmc-layer",
      pointsEvents,
      isNetworkRdsTmcVisible
    ),
    mapComponent.renderStationsLayer(
      "stations-layer",
      stations,
      isStationVisible
    ),
    mapComponent.renderWebcamsLayer(
      "webcams-layer",
      webcams,
      isAllCamerasVisible
    ),
    mapComponent.renderEventsLayer(
      "active-event-layer",
      activeEvents,
      isActiveVisible
    ),
    mapComponent.renderEventsLayer(
      "planned-event-layer",
      plannedEvents,
      isPlannedVisible
    ),
    mapComponent.renderPmvsLayer(
      "pmv-layer",
      filteredPmvs,
      isPmvVisible ||
        isAesysPanelVisible ||
        isSolariPanelVisible ||
        isFuturitPanelVisible ||
        isVisuallabPanelVisible ||
        isSfheraPanelVisible
    ),
    mapComponent.renderParkingLayer(
      "parking-layer",
      filteredParkings,
      isParkingVisible
    ),
    mapComponent.drawingLayers,
    mapComponent.renderRealTimeLayer(),
    mapComponent.renderDrawPointsLayer(),
    mapComponent.renderMultipleDistanceLayer(),
    mapComponent.renderShortestDistanceOnGraph(),
  ];
}
