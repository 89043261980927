export const TABLE_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: true,
    order: true,
  },
  cameraId: {
    name: "ID",
    id: "cameraId",
    show: true,
    order: true,
  },
  cameraName: {
    name: "Nome",
    id: "cameraName",
    show: true,
    order: true,
  },
  cameraCoordinates: {
    name: "Localizzazione",
    id: "cameraCoordinates",
    show: true,
    order: false,
  },
  cameraDescription: {
    name: "Descrizione",
    id: "cameraDescription",
    show: true,
    order: false,
  },
  diagnosticState: {
    name: "Stato diagnostico",
    id: "diagnosticState",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Funzionante",
        checked: true,
        id: "FUNZIONANTE",
        isChild: true,
        name: "Funzionante",
      },
      {
        label: "Disconnesso",
        checked: true,
        id: "DISCONNESSO",
        isChild: true,
        name: "Disconnesso",
      },
      {
        label: "Avaria",
        checked: true,
        id: "AVARIA",
        isChild: true,
        name: "Avaria",
      },

      {
        label: "No Info",
        checked: true,
        id: "NO_INFO",
        isChild: true,
        name: "No Info",
      },
    ],
    show: true,
    order: true,
  },
  activeAlarms: {
    name: "Allarmi attivi",
    id: "activeAlarms",
  },
  type: {
    name: "Tipo",
    id: "type",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "Milestone",
        checked: true,
        id: "Milestone",
        isChild: true,
        name: "Milestone",
      },
    ],
    show: true,
    order: true,
  },
  group: {
    name: "Gruppo",
    id: "group",
    childrenForHeaderDropdown: [
      {
        label: "Seleziona tutto",
        checked: true,
        id: "all",
        isChild: true,
        name: "Seleziona tutto",
      },
      {
        label: "MOBILITA",
        checked: true,
        id: "MOBILITA",
        isChild: true,
        name: "MOBILITA",
      },
    ],
    show: true,
    order: true,
  },
};

export const DATE_COLUMNS = [];

export const initColumns = (tableColumns = null, dateColumns = null) => {
  let columns = {};
  let date = [];
  if (
    !tableColumns ||
    (tableColumns &&
      Object.keys(TABLE_COLUMNS)[1] !== Object.keys(tableColumns)[1])
  ) {
    columns = { ...TABLE_COLUMNS };
    date = [...DATE_COLUMNS];
  } else {
    columns = { ...tableColumns };
    date = dateColumns ? [...dateColumns] : [];
  }

  return { columns: columns, date: date };
};

export const updateValueByWS = (
  list,
  wsCameraStatus,
  wsCameraAlarm,
  resetListAlarmWs,
  resetListCameraWs
) => {
  if (wsCameraAlarm && wsCameraAlarm.length > 0 && list && list.length > 0) {
    wsCameraAlarm.forEach((item) => {
      let findAlarm = list.find((alarm) => item.cameraId === alarm.cctv.id);

      if (findAlarm) {
        list.map((alarm) => {
          if (alarm.cctv.id === findAlarm.cctv.id) {
            let oldAlarms = alarm.open_alarms.filter(
              (searchAlarm) => item.alarmCode === searchAlarm
            );

            if (oldAlarms && oldAlarms.length > 0 && item.status === "CLOSED") {
              alarm.open_alarms.filter((alarm) => alarm !== item.alarmCode);
            } else if (
              oldAlarms &&
              oldAlarms.length === 0 &&
              item.status !== "CLOSED"
            ) {
              alarm.open_alarms.push(item.alarmCode);
            }
          }
        });
      }
    });
    resetListAlarmWs();
  }
  if (wsCameraStatus && wsCameraStatus.length > 0 && list && list.length > 0) {
    wsCameraStatus.forEach((item) => {
      let findStatus = list.find((status) => item.cameraId === status.cctv.id);
      if (findStatus) {
        list.map((cameraStatus) => {
          if (cameraStatus.cctv.id === findStatus.cctv.id) {
            return (cameraStatus.last_state.diagnosticState =
              item.diagnosticState);
          }
        });
      }
    });
    resetListCameraWs();
  }
};
