export const UserActions = {
  SET_PERMISSIONS: "USER/SET_PERMISSIONS",
  SET_USERNAME: "USER/SET_USERNAME",
  SET_ACTIVITY: "USER/SET_ACTIVITY",
  ADD_ACTIVITY: "USER/ADD_ACTIVITY",
  SET_USER_ROLES: "USER/SET_USER_ROLES",
};

export const setPermissions = (permissions) => (dispatch) => {
  dispatch({
    type: UserActions.SET_PERMISSIONS,
    payload: { permissions },
  });
};

export const setUsername = (username) => (dispatch) => {
  dispatch({
    type: UserActions.SET_USERNAME,
    payload: { username },
  });
};

export const setUserRoles = (roles) => (dispatch) => {
  dispatch({
    type: UserActions.SET_USER_ROLES,
    payload: { roles },
  });
};

export const setActivityList = (activity) => (dispatch) => {
  dispatch({
    type: UserActions.SET_ACTIVITY,
    payload: { activity },
  });
};

export const addActivity = (activity) => (dispatch) => {
  dispatch({
    type: UserActions.ADD_ACTIVITY,
    payload: { activity },
  });
};
