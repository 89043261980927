import {
  lowerCaseAllWordsExceptFirstLetters,
  upperCaseFirstLetter,
  replaceComma,
} from "utils/utils";

export const INITIAL_STATE_STOPS = {
  stops: [],
  currentIdRouteForStop: [],
  currentIdTripForStop: [],
  current: null,
  cityBus: [],
  regionBus: [],
  cityBusActive: [],
  regionBusActive: [],
  cityBusFiltered: [],
  regionBusFiltered: [],
  cityBusFilteredSearch: [],
  regionBusFilteredSearch: [],
  cityBusFilteredMap: [],
  regionBusFilteredMap: [],
  searchText: "",
  searchType: "",
  bounds: [],
};

export default class StopModel {
  id;
  nameLong;
  nameShort;
  numberOfQuays;
  timeZone;
  isVia;
  isRoutePoint;
  isStopPoint;
  isTimingPoint;
  isReliefPoint;
  isParkingPoint;
  isGaragePoint;
  coordinates;
  facilityFeatures;
  transportMode;
  versionRef;
  networkId;
  lines;
  type;
  pdf;
  selected;
  fromMap;
  layoutMap; //
  constructor() {
    this.id = null;
    this.nameLong = null;
    this.nameShort = null;
    this.numberOfQuays = null;
    this.timeZone = null;
    this.isVia = null;
    this.isRoutePoint = null;
    this.isStopPoint = null;
    this.isTimingPoint = null;
    this.isReliefPoint = null;
    this.isParkingPoint = null;
    this.isGaragePoint = null;
    this.coordinates = ICoords;
    this.facilityFeatures = null;
    this.transportMode = null;
    this.versionRef = IVersionRef;
    this.networkId = null;
    this.lines = null;
    this.type = null;
    this.selected = false;
    this.fromMap = false;
    this.layoutMap = null;
  }
  static fromREST(obj) {
    var stop = new StopModel();
    stop.id = obj.id;
    stop.nameLong = upperCaseFirstLetter(
      lowerCaseAllWordsExceptFirstLetters(replaceComma(obj.nameLong))
    );
    stop.nameShort = upperCaseFirstLetter(
      lowerCaseAllWordsExceptFirstLetters(replaceComma(obj.nameShort))
    );
    stop.numberOfQuays = obj.numberOfQuays;
    stop.timeZone = obj.timeZone;
    stop.isVia = obj.isVia;
    stop.isRoutePoint = obj.isRoutePoint;
    stop.isStopPoint = obj.isStopPoint;
    stop.isTimingPoint = obj.isTimingPoint;
    stop.isReliefPoint = obj.isReliefPoint;
    stop.isParkingPoint = obj.isParkingPoint;
    stop.isGaragePoint = obj.isGaragePoint;
    stop.coordinates = obj.coordinates;
    stop.facilityFeatures = obj.facilityFeatures;
    if (obj.transportMode)
      stop.transportMode = obj.transportMode.includes("[")
        ? JSON.parse(obj.transportMode)
        : obj.transportMode.split();
    stop.versionRef = obj.versionRef;
    stop.networkId = obj.networkId;
    stop.lines = obj.lines;
    if (obj.selected) stop.selected = obj.selected;
    if (obj.fromMap) stop.fromMap = obj.fromMap;
    if (obj.type || obj.type === 0) stop.type = obj.type;
    return stop;
  }
  static fromArrayREST(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data.map(StopModel.fromREST).filter((item) => item !== null);
  }
}

const ICoords = {
  latitude: null,
  longitude: null,
};

const IVersionRef = {
  fromDate: null,
  toDate: null,
  versionId: null,
};
