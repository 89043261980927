import { StationsActions } from "./stations.actions";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";
import { MapActions } from "reducers/map/map.actions";
import { filterBoundsStations } from "utils/utils";
import { INITIAL_STATE_STATIONS } from "./stations.model";
import * as FilterUtils from "utils/filter-utils";

function searchStations(searchText) {
  return (station) =>
    station &&
    station.archName &&
    station.archName.toString() &&
    station.archName.toString().toLowerCase().includes(searchText);
}

export default function stationsReducer(
  state = INITIAL_STATE_STATIONS,
  action
) {
  let stationsListCopy = FilterUtils.deepCopyOfArraysOfObj(state.stations);
  let stationsFilteredListCopy = FilterUtils.deepCopyOfArraysOfObj(
    state.stationsFiltered
  );

  switch (action.type) {
    case StationsActions.SET_LIST_STATIONS:
      return {
        ...state,
        stations: action.payload.list,
        stationsFiltered: action.payload.list,
      };
    case StationsActions.UPDATE_CURRENT_STATION:
      return {
        ...state,
        current: action.payload,
      };
    case StationsActions.SET_CURRENT_STATION:
      if (state.stations) {
        stationsListCopy.forEach((item, index, arr) => {
          if (
            item &&
            item.properties &&
            action.payload &&
            action.payload.stationId &&
            item.stationId === action.payload.stationId
          ) {
            arr[index] = {
              ...item,
              selected: action.payload.selected ? false : true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      if (state.stationsFiltered) {
        stationsFilteredListCopy.forEach((item, index, arr) => {
          if (
            item &&
            item.properties &&
            action.payload &&
            action.payload.stationId &&
            item.stationId === action.payload.stationId
          ) {
            arr[index] = {
              ...item,
              selected: action.payload.selected ? false : true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      return {
        ...state,
        current:
          action.payload &&
          state.current &&
          state.current.stationId &&
          action.payload.stationId &&
          state.current.stationId === action.payload.stationId
            ? null
            : action.payload,
        stations: stationsListCopy,
        stationsFiltered: stationsFilteredListCopy,
      };

    case StationsActions.RESET_CURRENT_STATION:
      if (state.stations) {
        stationsListCopy.forEach((item, index, arr) => {
          if (item && item.selected) {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      if (state.stationsFiltered) {
        stationsFilteredListCopy.forEach((item, index, arr) => {
          if (item && item.selected) {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      return {
        ...state,
        stations: stationsListCopy,
        stationsFiltered: stationsFilteredListCopy,
        current: null,
      };

    case NavTabActions.SET_SEARCH:
      if (action.payload && action.payload.type === "stations") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const searchFn = searchStations(searchText);
        let stationsFiltered = state.stations.filter(searchFn);
        return {
          ...state,
          stationsFiltered,
          searchText,
          searchType: action.payload.type,
        };
      } else {
        return { ...state };
      }
    case MapActions.FILTER_BY_BOUNDS: {
      const { searchText } = state;
      const searchFn = searchStations(searchText);
      const { bounds } = action.payload;
      const filterFn = filterBoundsStations(bounds);
      if (searchText === "") {
        return {
          ...state,
          bounds: [...bounds],
          stationsFiltered: state.stations.filter(searchFn).filter(filterFn),
        };
      } else {
        return {
          ...state,
          bounds: [...bounds],
          stationsFiltered: state.stations.filter(searchFn),
        };
      }
    }

    case StationsActions.UPDATE_STATION_STATE_WS: {
      let foundSensor = null;
      let objState = { ...state };
      let newCurrent = state.current;

      //FOR EACH STATION IN THE LIST, SEARCH THROUGH ITS SENSORS AND UPDATE STATUS
      action.payload.forEach((newStatusItem) => {
        foundSensor = state.stations.forEach((station) => {
          return station.sensors.find(
            (sensor) => sensor.externalId === newStatusItem.externalId
          );
        });

        let newStations = objState.stations.map((station) => {
          let newSensors = station.sensors.map((sensor) => {
            let newItem = { ...sensor };
            if (sensor.externalId === newStatusItem.externalId) {
              newItem = {
                ...newStatusItem,
              };
            }
            return newItem;
          });
          return {
            ...station,
            sensors: newSensors,
          };
        });

        objState = {
          ...objState,
          stations: foundSensor ? newStations : objState.stations,
        };
      });

      //UPDATE ALSO CURRENT STATION
      if (state.current) {
        newCurrent = objState.stations.find(
          (item) => item.stationId === state.current.stationId
        );
      }

      return {
        ...objState,
        current: newCurrent,
      };
    }
    case StationsActions.UPDATE_STATION_MEASURES_WS: {
      let objState = { ...state };
      let newCurrent = state.current;

      //SEARCH FOR STATION IN THE LIST AND ADD NEW MEASURE
      action.payload.forEach((newMeasureItem) => {
        stationsListCopy.forEach((station, index, arr) => {
          if (station.externalId === newMeasureItem.externalIdStation) {
            let newMeasures = [...station.measures, newMeasureItem];
            arr[index] = {
              ...station,
              measures: newMeasures,
            };
          }
        });

        stationsFilteredListCopy.forEach((station, index, arr) => {
          if (station.externalId === newMeasureItem.externalIdStation) {
            let newMeasures = [...station.measures, newMeasureItem];
            arr[index] = {
              ...station,
              measures: newMeasures,
            };
          }
        });

        objState = {
          ...objState,
          stations: stationsListCopy,
          stationsFiltered: stationsFilteredListCopy,
        };
      });

      //UPDATE ALSO CURRENT STATION
      if (state.current) {
        newCurrent = objState.stations.find(
          (item) => item.stationId === state.current.stationId
        );
      }

      return {
        ...objState,
        current: newCurrent,
      };
    }
    default:
      return state;
  }
}

export const getStations = (state) => state.stations;
export const getCurrentStation = (state) => state.current;
export const getCurrentStationMap = (state) => state.currentMap;
export const getFilteredStations = (state) => state.stationsFiltered;
