import React, { Component } from "react";

class MinimisedWorkspace extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { toggleWorkspaceDimensions } = this.props;

    return (
      <div className="minimised-ws-container">
        <div className="uk-height-1-1 uk-width-1-1 uk-card uk-card-primary  uk-flex uk-flex-center uk-flex-middle">
          <span
            uk-icon="icon: thumbnails"
            uk-tooltip={"title: Workspace"}
            onClick={(event) => {
              event.preventDefault();
              toggleWorkspaceDimensions();
            }}
            className="pointer height-width-35"
          />
        </div>
      </div>
    );
  }
}

export default MinimisedWorkspace;
