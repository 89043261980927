import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../style.less";
import { toggleAlarmTable } from "reducers/ui/installations-menu/installations-menu.actions";

class TableBody extends Component {
  render = () => {
    const {
      tableColumns,
      list,
      getCurrentActiveAlarmsForSelectedCamera,
      toggleAlarmTable,
    } = this.props;
    return list.map((item, ind) => {
      return (
        <tr key={"column-tr-table-body" + ind}>
          {tableColumns &&
            Object.values(tableColumns).map((t, i) => {
              if (i !== 0) {
                if (t.id === "activeAlarms" && item.activeAlarms !== "0") {
                  return (
                    <td
                      uk-tooltip={"title: " + Object.values(item)[i - 1]}
                      key={"column-td-table-body" + i}
                      className={
                        tableColumns[t.id] && !tableColumns[t.id].show
                          ? "displayNone"
                          : ""
                      }
                    >
                      <div
                        className="underline-text"
                        value={item.activeAlarms}
                        onClick={() => {
                          getCurrentActiveAlarmsForSelectedCamera(
                            item.cameraId,
                            item.cameraName
                          );
                          toggleAlarmTable();
                        }}
                      >
                        {item.activeAlarms}
                      </div>
                    </td>
                  );
                } else {
                  return (
                    <td
                      uk-tooltip={"title: " + Object.values(item)[i - 1]}
                      key={"column-td-table-body" + i}
                      className={
                        tableColumns[t.id] && !tableColumns[t.id].show
                          ? "displayNone"
                          : ""
                      }
                    >
                      <p
                        className={
                          t.id === "diagnosticState" &&
                          item.diagnosticState === "FUNZIONANTE"
                            ? "green-text p-dot"
                            : t.id === "diagnosticState" &&
                              item.diagnosticState === "AVARIA"
                            ? "yellow-text p-dot"
                            : t.id === "diagnosticState" &&
                              item.diagnosticState === "NO_INFO"
                            ? "gray-text p-dot"
                            : t.id === "diagnosticState" &&
                              item.diagnosticState === "DISCONNESSO"
                            ? "red-text p-dot"
                            : t.id === "activeAlarms" &&
                              item.activeAlarms !== "0"
                            ? "underline-text"
                            : "p-dot"
                        }
                      >
                        {Object.values(item)[i - 1]}
                      </p>
                    </td>
                  );
                }
              }
              return null;
            })}
        </tr>
      );
    });
  };
}
const mapDispatchToProps = {
  toggleAlarmTable,
};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableBody)
);
