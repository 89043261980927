export const TABLE_COLUMNS = {
  all: {
    name: "Seleziona tutto",
    id: "all",
    show: true,
    order: true,
  },
  lcd: {
    name: "ID LCD",
    id: "lcd",
    show: true,
    order: true,
  },
  name1: {
    name: "Nome LCD",
    id: "name1",
    show: true,
    order: true,
  },
  latitude: {
    name: "Latitudine",
    id: "latitude",
    show: true,
    order: false,
  },
  longitude: {
    name: "Longitudine",
    id: "longitude",
    show: true,
    order: false,
  },
  road_number: {
    name: "Nome Tratta",
    id: "road_number",
    show: true,
    order: true,
  },
  urban: {
    name: "Urbano",
    id: "urban",
    show: true,
    order: false,
  },
};

export const DATE_COLUMNS = [];

export const initColumns = (tableColumns = null, dateColumns = null) => {
  let columns = {};
  let date = [];
  if (
    !tableColumns ||
    (tableColumns &&
      Object.keys(TABLE_COLUMNS)[1] !== Object.keys(tableColumns)[1])
  ) {
    columns = { ...TABLE_COLUMNS };
    date = [...DATE_COLUMNS];
  } else {
    columns = { ...tableColumns };
    date = dateColumns ? [...dateColumns] : [];
  }

  return { columns: columns, date: date };
};
