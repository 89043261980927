import { CompositeLayer, IconLayer } from "deck.gl";
import pointDraw from "assets/icon/pointDraw.svg";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";

class DrawPointsLayer extends CompositeLayer {
  renderLayers() {
    const { id, mapPolygonReducer, geoJson } = this.props;
    if (
      mapPolygonReducer &&
      mapPolygonReducer.center &&
      mapPolygonReducer.area === undefined
    ) {
      let listDraw = [];
      listDraw.push(mapPolygonReducer.center);
      return new IconLayer(
        this.getSubLayerProps({
          id: id,
          data: listDraw,
          pickable: true,
          ...DeckLayerUtil.loadOptions(),
          getIcon: () => ({
            url: pointDraw,
            width: 50,
            height: 40,
            anchorX: 25,
            anchorY: listDraw.length > 1 ? 20 : 40,
          }),
          sizeScale: 4,
          visible: true,
          getPosition: (d) => [d[0], d[1]],
          getSize: () => 4,
        })
      );
    }
    if (mapPolygonReducer && geoJson && geoJson.features.length > 0) {
      let listDraw = [];
      mapPolygonReducer.center
        ? listDraw.push(mapPolygonReducer.center)
        : (listDraw =
            geoJson.features[geoJson.features.length - 1].geometry
              .coordinates[0]);
      return new IconLayer(
        this.getSubLayerProps({
          id: id,
          data: listDraw,
          pickable: true,
          ...DeckLayerUtil.loadOptions(),
          getIcon: () => ({
            url: pointDraw,
            width: 50,
            height: 40,
            anchorX: 25,
            anchorY: listDraw.length > 1 ? 20 : 40,
          }),
          sizeScale: 4,
          visible: true,
          getPosition: (d) => [d[0], d[1]],
          getSize: () => 4,
        })
      );
    } else {
      return [];
    }
  }
}

DrawPointsLayer.layerName = "DrawPointsLayer";

export default DrawPointsLayer;
