import React, { Component } from "react";
import "./style.less";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  getOpeningStatusTranslation,
  getParkingPayment,
} from "utils/utils-parking";

class ParkingTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    this.getWidthHeight();
  }

  getWidthHeight = () => {
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ ...this.state, height: height, width: width });
  };

  render() {
    const { x, y, object } = this.props;
    const { height, width } = this.state;

    return (
      <div
        className="show-tooltip"
        style={{ left: x - width / 2, top: y - (height + 20) }}
        ref={(divElement) => {
          this.divElement = divElement;
        }}
      >
        <div className="uk-flex uk-flex-column">
          <div className="tooltip-text-title font-big">
            {object && object.properties && object.properties.parkingName
              ? object.properties.parkingName
              : "-"}
          </div>
          <div className="uk-flex uk-flex-column uk-margin-small-top">
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Indirizzo: </div>
              <div className="tooltip-text-important">
                {object && object.properties && object.properties.parkingAddress
                  ? object.properties.parkingAddress
                  : "-"}
              </div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Tipologia: </div>
              <div className="tooltip-text-important subType">
                {getParkingPayment(
                  object &&
                    object.properties &&
                    object.properties.parkingPayment
                )}
              </div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Stato: </div>
              <div className="tooltip-text-important">
                {getOpeningStatusTranslation(
                  object &&
                    object.properties &&
                    object.properties.parkingOpeningStatus
                    ? object.properties.parkingOpeningStatus
                    : null
                )}
              </div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Capacità: </div>
              <div className="tooltip-text-important">
                {object &&
                object.properties &&
                (object.properties.parkingNumberOfSpaces ||
                  object.properties.parkingNumberOfSpaces === 0)
                  ? object.properties.parkingNumberOfSpaces.toString()
                  : "-"}
              </div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Posti liberi: </div>
              <div className="tooltip-text-important">
                {object &&
                object.properties &&
                (object.properties.parkingNumberOfVacantSpaces ||
                  object.properties.parkingNumberOfVacantSpaces === 0)
                  ? object.properties.parkingNumberOfVacantSpaces
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {};
const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParkingTooltip)
);
