import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getAllActiveLines,
  isOpenNavTPL,
  isOpenNavEvents,
  isOpenNavTraffic,
  isCityBusVisible,
  isRegionBusVisible,
  isActiveVisible,
  isPlannedVisible,
  isTypicalVisible,
  isNetStateVisible,
  getPermissions,
  areAllTrafficVisible,
  areAllEventVisible,
  areAllVisible,
  getCurrentStop,
  getCurrentVehicle,
  getBottombarTabs,
} from "store";
import {
  resetActiveLines,
  setActiveLines,
  updateActiveLines,
} from "reducers/lines/lines.actions";
import {
  toggleNavTPL,
  toggleNavEvents,
  toggleNavTraffic,
} from "reducers/ui/nav/nav.actions";
import {
  setNavTabMultitab,
  toggleOpenNavTab,
  setNavTabSelectedTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import {
  setTrafficVisibility,
  setTypicalVisibility,
  setNetStateVisibility,
  resetTrafficVisibility,
} from "reducers/ui/traffic-menu/traffic-menu.actions";
import {
  setTrafficCurrentArc,
  setTrafficCurrentArcMap,
} from "reducers/traffic/traffic.actions";
import {
  setAllEventVisibility,
  setActiveVisibility,
  setPlannedVisibility,
} from "reducers/ui/event-menu/event-menu.actions";
import {
  setCurrentEvent,
  setCurrentEventGroup,
} from "reducers/events/events.actions";
import {
  setCityBusVisibility,
  setRegionBusVisibility,
  setTransportVisibility,
} from "reducers/ui/tpl-menu/tpl-menu.actions";
import { resetCurrentStop } from "reducers/stops/stops.actions";
import { resetCurrentVehicle } from "reducers/vehicles/vehicles.actions";
import {
  closeBottombar,
  setBottombarActiveTab,
  removeBottombarTab,
} from "reducers/ui/bottombar/bottombar.actions";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";

//COMPONENTS
import NavItemMobilityTraffic from "./nav-item-mobility-traffic";
import NavItemMobilityTpl from "./nav-item-mobility-tpl";
import NavItemMobilityEvent from "./nav-item-mobility-event";

const mapStateToProps = (state) => ({
  activeLines: getAllActiveLines(state),
  isOpenNavEvents: isOpenNavEvents(state),
  isOpenNavTPL: isOpenNavTPL(state),
  isOpenNavTraffic: isOpenNavTraffic(state),
  allChecked: areAllVisible(state),
  cityBusChecked: isCityBusVisible(state),
  regionBusChecked: isRegionBusVisible(state),
  allEventChecked: areAllEventVisible(state),
  activeChecked: isActiveVisible(state),
  plannedChecked: isPlannedVisible(state),
  allTrafficChecked: areAllTrafficVisible(state),
  tipicChecked: isTypicalVisible(state),
  netStateChecked: isNetStateVisible(state),
  permissions: getPermissions(state),
  stopsCurrentReducer: getCurrentStop(state),
  vehicleCurrentReducer: getCurrentVehicle(state),
  bottombarTabs: getBottombarTabs(state),
});

const mapDispatchToProps = {
  resetActiveLines,
  toggleNavTPL,
  toggleNavEvents,
  toggleNavTraffic,
  setNavTabMultitab,
  toggleOpenNavTab,
  setTrafficVisibility,
  setTypicalVisibility,
  setNetStateVisibility,
  setTrafficCurrentArc,
  setTrafficCurrentArcMap,
  resetTrafficVisibility,
  setActiveVisibility,
  setPlannedVisibility,
  setAllEventVisibility,
  setCurrentEvent,
  setCurrentEventGroup,
  setTransportVisibility,
  setCityBusVisibility,
  setRegionBusVisibility,
  setActiveLines,
  resetCurrentStop,
  resetCurrentVehicle,
  updateActiveLines,
  closeBottombar,
  setBottombarActiveTab,
  removeBottombarTab,
  setNavTabSelectedTab,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemMobility extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  clickOnTrafficoLabel = () => {
    const {
      goToRoadConditions,
      isOpenNavTraffic,
      toggleNavTraffic,
      setNavTabMultitab,
    } = this.props;

    toggleNavTraffic(!isOpenNavTraffic);
    setNavTabMultitab("Traffico");
    goToRoadConditions("/traffic");
  };

  clickOnTrafficoCheckbox = (name) => {
    const {
      toggleOpenNavTab,
      allTrafficChecked,
      setTrafficVisibility,
      tipicChecked,
      netStateChecked,
      toggleNavTraffic,
      setNavTabMultitab,
      goToRoadConditions,
      isOpenNavTraffic,
      permissions,
      setTypicalVisibility,
      setNetStateVisibility,
      setTrafficCurrentArc,
      resetTrafficVisibility,
    } = this.props;

    setNavTabMultitab("Traffico");
    goToRoadConditions("/traffic");
    const trafficPermission = permissions.indexOf("TRAFFIC") >= 0;

    if (trafficPermission) {
      if (name === "all") {
        toggleNavTraffic(!isOpenNavTraffic);
        toggleOpenNavTab(!allTrafficChecked);
        setTrafficCurrentArc({ arc: null });
        if (allTrafficChecked) {
          resetTrafficVisibility();
        } else {
          setNetStateVisibility(true);
        }
        setTrafficVisibility(!allTrafficChecked);
      } else {
        setTrafficVisibility(true);
        toggleOpenNavTab(true);
        toggleNavTraffic(true);

        if (name === "typical") {
          if (tipicChecked && !netStateChecked) {
            toggleOpenNavTab(false);
          }
          if (tipicChecked) {
            setTrafficCurrentArc({ arc: null });
          }
          setTypicalVisibility(!tipicChecked);
        } else if (name === "net-state") {
          if (!tipicChecked && netStateChecked) {
            toggleOpenNavTab(false);
          }
          if (netStateChecked) {
            setTrafficCurrentArc({ arc: null });
          }
          setNetStateVisibility(!netStateChecked);
        }
      }
    }
  };

  clickOnEventiLabel = () => {
    const {
      goToRoadConditions,
      isOpenNavEvents,
      toggleNavEvents,
      setNavTabMultitab,
    } = this.props;

    toggleNavEvents(!isOpenNavEvents);
    setNavTabMultitab("Eventi");
    goToRoadConditions("/events");
  };

  clickOnEventiCheckbox = (name) => {
    const {
      toggleOpenNavTab,
      allEventChecked,
      activeChecked,
      plannedChecked,
      toggleNavEvents,
      setNavTabMultitab,
      goToRoadConditions,
      isOpenNavEvents,
      setAllEventVisibility,
      setActiveVisibility,
      setPlannedVisibility,
      setCurrentEvent,
      setCurrentEventGroup,
    } = this.props;

    setNavTabMultitab("Eventi");
    goToRoadConditions("/events");

    if (name === "all") {
      if (!isOpenNavEvents && !allEventChecked) {
        toggleNavEvents(true);
      } else if (isOpenNavEvents && allEventChecked) {
        toggleNavEvents(false);
      }
      toggleOpenNavTab(!activeChecked || !plannedChecked);
      setAllEventVisibility(!allEventChecked);
      //IF DESELECT ALL EVENT LAYERS, REMOVE CURRENT ARC
      setCurrentEvent(null);
      setCurrentEventGroup([]);
    } else if (name === "active") {
      toggleOpenNavTab(!(activeChecked && !plannedChecked));
      toggleNavEvents(!(activeChecked && !plannedChecked));
      setActiveVisibility(!activeChecked);
      if (activeChecked) {
        setCurrentEvent(null);
        setCurrentEventGroup([]);
      }
    } else if (name === "planned") {
      toggleOpenNavTab(!(!activeChecked && plannedChecked));
      toggleNavEvents(!(!activeChecked && plannedChecked));
      setPlannedVisibility(!plannedChecked);
      if (plannedChecked) {
        setCurrentEvent(null);
        setCurrentEventGroup([]);
      }
    }
  };

  clickOnTrasportoLabel = () => {
    const {
      goToRoadConditions,
      isOpenNavTPL,
      toggleNavTPL,
      setNavTabMultitab,
    } = this.props;

    toggleNavTPL(!isOpenNavTPL);
    setNavTabMultitab("Trasporto Pubblico");
    goToRoadConditions("/tpl");
  };

  clickOnTrasportoCheckbox = (name) => {
    const {
      toggleOpenNavTab,
      allChecked,
      toggleNavTPL,
      setNavTabMultitab,
      goToRoadConditions,
      isOpenNavTPL,
      setNavTabSelectedTab,
      setTransportVisibility,
      cityBusChecked,
      regionBusChecked,
      activeLines,
      setActiveLines,
      resetActiveLines,
      stopsCurrentReducer,
      vehicleCurrentReducer,
      resetCurrentStop,
      resetCurrentVehicle,
      updateActiveLines,
      closeBottombar,
      setBottombarActiveTab,
      removeBottombarTab,
      bottombarTabs,
      permissions,
    } = this.props;

    setNavTabMultitab("Trasporto Pubblico");
    goToRoadConditions("/tpl");

    const regionPermission = permissions.indexOf("TPL Region") >= 0;
    const cityPermission = permissions.indexOf("TPL City") >= 0;
    let filtered = [];

    if (!allChecked && !cityBusChecked && !regionBusChecked) {
      resetActiveLines();
    }

    if (name === "all") {
      if (!isOpenNavTPL && !allChecked) {
        toggleNavTPL(true);
      } else if (isOpenNavTPL && allChecked) {
        toggleNavTPL(false);
      }
      toggleOpenNavTab(!cityBusChecked || !regionBusChecked);

      if (cityPermission && regionPermission) {
        setTransportVisibility({
          all: !allChecked,
          city: !allChecked,
          region: !allChecked,
        });
      } else if (cityPermission) {
        setTransportVisibility({
          all: !allChecked,
          city: !allChecked,
          region: false,
        });
      } else if (regionPermission) {
        setTransportVisibility({
          all: !allChecked,
          city: false,
          region: !allChecked,
        });
      }
      setNavTabSelectedTab(0);
      resetActiveLines();
      resetCurrentStop();
      resetCurrentVehicle();

      let newTab =
        bottombarTabs.filter((item) => item !== "Linearizzata").length > 0
          ? bottombarTabs[0]
          : null;
      setBottombarActiveTab(newTab);
      if (!newTab) {
        closeBottombar();
      }
      removeBottombarTab("Linearizzata");
    } else if (name === "city") {
      toggleOpenNavTab(!(cityBusChecked && !regionBusChecked));
      toggleNavTPL(!(cityBusChecked && !regionBusChecked));

      if (cityPermission && regionPermission) {
        setTransportVisibility({
          all: !cityBusChecked && regionBusChecked,
          city: !cityBusChecked,
          region: regionBusChecked,
        });
      } else if (cityPermission) {
        setTransportVisibility({
          all: allChecked,
          city: !cityBusChecked,
          region: false,
        });
      }

      filtered = activeLines.filter(
        (item) =>
          item.transportMode &&
          item.transportMode.subMode &&
          item.transportMode.subMode.code === TYPE_TRANSPORT_MODE["urb"]
      );
      if (filtered.length > 0) {
        filtered.forEach((item) => setActiveLines(item));
      } else {
        let filteredListCity = activeLines.filter(
          (item) =>
            item.transportMode &&
            item.transportMode.subMode &&
            item.transportMode.subMode.code !== TYPE_TRANSPORT_MODE["urb"]
        );
        updateActiveLines(filteredListCity);
        if (
          stopsCurrentReducer &&
          stopsCurrentReducer.transportMode &&
          stopsCurrentReducer.transportMode.length > 0 &&
          stopsCurrentReducer.transportMode.find(
            (x) => x === TYPE_TRANSPORT_MODE["urb"]
          )
        ) {
          resetCurrentStop();
        }
        if (
          vehicleCurrentReducer &&
          vehicleCurrentReducer.transportMode &&
          vehicleCurrentReducer.transportMode.subMode &&
          vehicleCurrentReducer.transportMode.subMode.code &&
          vehicleCurrentReducer.transportMode.subMode.code ===
            TYPE_TRANSPORT_MODE["urb"]
        ) {
          resetCurrentVehicle();
        }
      }
    } else if (name === "region") {
      toggleOpenNavTab(!(!cityBusChecked && regionBusChecked));
      toggleNavTPL(!(!cityBusChecked && regionBusChecked));

      if (cityPermission && regionPermission) {
        setTransportVisibility({
          all: cityBusChecked && !regionBusChecked,
          city: cityBusChecked,
          region: !regionBusChecked,
        });
      } else if (regionPermission) {
        setTransportVisibility({
          all: allChecked,
          city: false,
          region: !regionBusChecked,
        });
      } else if (cityPermission) {
      }
      filtered = activeLines.filter(
        (item) =>
          item.transportMode.subMode.code === TYPE_TRANSPORT_MODE["extraurb"]
      );
      if (filtered.length > 0) {
        filtered.forEach((item) => setActiveLines(item));
      } else {
        let filteredListRegion = activeLines.filter(
          (item) =>
            item.transportMode.subMode.code !== TYPE_TRANSPORT_MODE["extraurb"]
        );
        updateActiveLines(filteredListRegion);
        if (
          stopsCurrentReducer &&
          stopsCurrentReducer.transportMode &&
          stopsCurrentReducer.transportMode.length > 0 &&
          stopsCurrentReducer.transportMode.find(
            (x) => x === TYPE_TRANSPORT_MODE["extraurb"]
          )
        ) {
          resetCurrentStop();
        }
        if (
          vehicleCurrentReducer &&
          vehicleCurrentReducer.transportMode &&
          vehicleCurrentReducer.transportMode.subMode &&
          vehicleCurrentReducer.transportMode.subMode.code &&
          vehicleCurrentReducer.transportMode.subMode.code ===
            TYPE_TRANSPORT_MODE["extraurb"]
        ) {
          resetCurrentVehicle();
        }
      }
    }
  };

  render() {
    const {
      onClickMobility,
      location,
      checked,
      cityBusChecked,
      regionBusChecked,
      activeChecked,
      plannedChecked,
      tipicChecked,
      netStateChecked,
    } = this.props;
    const isActive = location.pathname.includes("viability");

    return (
      <li className={isActive ? "uk-parent uk-active" : "uk-parent"}>
        <a onClick={onClickMobility} href="/#">
          <span
            uk-icon="icon: acr-menu-routes-management; ratio: 1.4;"
            className={`uk-icon ${
              cityBusChecked ||
              regionBusChecked ||
              activeChecked ||
              plannedChecked ||
              tipicChecked ||
              netStateChecked
                ? "yellow-icon"
                : ""
            }`}
          ></span>
          Gestione mobilità
        </a>
        {checked ? (
          <ul className="uk-nav-sub uk-margin-small-top acr-transport-nav-sub">
            <div className="uk-checkbox-tree-large uk-margin-right acr-transport-checkbox-tree">
              {isActive && (
                <div className="uk-parent">
                  {/* TPL */}
                  <NavItemMobilityTpl
                    clickOnTrasportoLabel={this.clickOnTrasportoLabel}
                    clickOnTrasportoCheckbox={this.clickOnTrasportoCheckbox}
                  />
                  {/* TRAFFICO */}
                  <NavItemMobilityTraffic
                    clickOnTrafficoLabel={this.clickOnTrafficoLabel}
                    clickOnTrafficoCheckbox={this.clickOnTrafficoCheckbox}
                  />
                  {/* EVENTI */}
                  <NavItemMobilityEvent
                    clickOnEventiLabel={this.clickOnEventiLabel}
                    clickOnEventiCheckbox={this.clickOnEventiCheckbox}
                  />
                </div>
              )}
            </div>
          </ul>
        ) : null}
      </li>
    );
  }
}

export default NavItemMobility;
