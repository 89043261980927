import { CompositeLayer, PathLayer } from "deck.gl";
class ArcLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      visible,
      color,
      lighterColor,
      onClick,
      arcCurrentReducer,
      arcMapCurrentReducer,
      isDrawingMode,
    } = this.props;
    return new PathLayer(
      this.getSubLayerProps({
        id,
        data,
        getPath: (d) => d.geometry.coordinates,
        getColor: (data) =>
          (data && data.properties.selected) ||
          (arcCurrentReducer &&
            arcCurrentReducer.properties &&
            data.properties.arcid === arcCurrentReducer.properties.arcid &&
            arcCurrentReducer.properties.selected) ||
          (arcMapCurrentReducer &&
            arcMapCurrentReducer.find(
              (item) => item.properties.arcid === data.properties.arcid
            ))
            ? lighterColor
            : color,
        pickable: isDrawingMode ? false : true,
        widthMinPixels: 2,
        getWidth: (data) =>
          (data && data.properties.selected) ||
          (arcCurrentReducer &&
            arcCurrentReducer.properties &&
            data.properties.arcid === arcCurrentReducer.properties.arcid &&
            arcCurrentReducer.properties.selected) ||
          (arcMapCurrentReducer &&
            arcMapCurrentReducer.find(
              (item) => item.properties.arcid === data.properties.arcid
            ))
            ? 10
            : 2,
        onClick: onClick,
        visible,
        rounded: true,
        parameters: {
          depthTest: false,
        },
      })
    );
  }
}

ArcLayer.layerName = "ArcLayer";

export default ArcLayer;
