import { CompositeLayer, IconLayer } from "deck.gl";
import pointDraw from "assets/icon/pointDraw.svg";
import DeckLayerUtil from "utils/map-utils/deck-layer-util";

class MarkerPointsDistanceLayer extends CompositeLayer {
  renderLayers() {
    const { id, multipleDistance, geoJson } = this.props;
    if (
      multipleDistance &&
      multipleDistance.length > 0 &&
      geoJson &&
      geoJson.features.length > 0
    ) {
      return new IconLayer(
        this.getSubLayerProps({
          id: id,
          data: multipleDistance,
          pickable: true,
          ...DeckLayerUtil.loadOptions(),
          getIcon: () => ({
            url: pointDraw,
            width: 50,
            height: 40,
            anchorX: 25,
            anchorY: 20,
          }),
          sizeScale: 4,
          visible: true,
          getPosition: (d) => [d.coordsEnd[0], d.coordsEnd[1]],
          getSize: () => 4,
        })
      );
    } else {
      return [];
    }
  }
}

MarkerPointsDistanceLayer.layerName = "MarkerPointsDistanceLayer";

export default MarkerPointsDistanceLayer;
