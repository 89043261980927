import React, { Component, createRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { take, tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import "./style.less";
//UTILS
import { getSubtypeId, getTypeId } from "utils/util-events";
//ACTIONS
import { getAllEvents, getSelectModal, getCurrentEventGroup } from "store";
//COMPONENTS
import EventListItem from "./event-list-item/event-list-item";
import Loading from "components/shared/loading/loading";

class EventList extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.scrollDiv = createRef();
    this.state = {
      iconVisibile: false,
      listAttributes: [],
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }
  componentDidUpdate = (prevProps) => {
    const { selectModal, currentEvent } = this.props;
    this.scrollSmoothHandler();
    if (
      currentEvent &&
      prevProps.currentEvent &&
      prevProps.currentEvent.situationRecordId !==
        currentEvent.situationRecordId
    ) {
      if (
        getTypeId(currentEvent, selectModal) &&
        getSubtypeId(currentEvent, selectModal)
      ) {
        let type = getTypeId(currentEvent, selectModal);
        let underType = getSubtypeId(currentEvent, selectModal);

        this.apiService
          .getConfigAttributes(type, underType)
          .pipe(
            take(1),
            tap((x) => {
              if (x && !x.error) {
                this.setState({ listAttributes: x });
              }
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe();
      }
    }
  };
  scrollSmoothHandler = () => {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  barLinkingAssociatedEvents = () => {
    const { listEvent } = this.props;
    let allPractice = [];
    let practicesWithMoreEvents = [];
    listEvent.forEach((item) => {
      if (allPractice.indexOf(item.situationId) === -1) {
        allPractice.push(item.situationId);
      } else if (practicesWithMoreEvents.indexOf(item.situationId) === -1) {
        practicesWithMoreEvents.push(item.situationId);
      }
    });
    let count = 1;
    let listOfAllEvents = [...listEvent].map((item, index) => {
      if (practicesWithMoreEvents.includes(item.situationId) && index === 0) {
        return {
          ...item,
          isSelected: true,
          eventNumber: count++,
        };
      } else if (
        practicesWithMoreEvents.includes(item.situationId) &&
        index !== 0 &&
        listEvent[index].situationId === listEvent[index - 1].situationId
      ) {
        return {
          ...item,
          isSelected: true,
          eventNumber: count++,
        };
      } else if (
        practicesWithMoreEvents.includes(item.situationId) &&
        listEvent[index].situationId !== listEvent[index - 1].situationId &&
        listEvent[index].situationId === listEvent[index + 1].situationId
      ) {
        count = 1;
        return {
          ...item,
          isSelected: true,
          eventNumber: count++,
        };
      } else {
        count = 1;
        return {
          ...item,
          isSelected: false,
          eventNumber: count,
        };
      }
    });
    return listOfAllEvents;
  };

  render() {
    const {
      toggleDetails,
      selectModal,
      toggleDetailsGroupEvent,
      currentEventGroup,
      isEventsLoading,
    } = this.props;

    if (currentEventGroup) {
      currentEventGroup.forEach((item) => {
        if (item.selected) {
          this.scrollSmoothHandler();
        }
      });
    }
    let listOfAllEvents = this.barLinkingAssociatedEvents();

    let selectedEvent;
    return isEventsLoading ? (
      <Loading />
    ) : (
      <div className="uk-list margin-bottom50">
        {listOfAllEvents.map((event, index) => {
          if (currentEventGroup && currentEventGroup.length > 0) {
            currentEventGroup.forEach((item) => {
              if (item.situationRecordId === event.situationRecordId) {
                selectedEvent = event.situationRecordId;
              }
            });
          }

          return (
            <EventListItem
              key={index}
              event={event}
              index={index}
              selectedEvent={selectedEvent}
              toggleDetailsGroupEvent={toggleDetailsGroupEvent}
              toggleDetails={toggleDetails}
              selectModal={selectModal}
            />
          );
        })}
      </div>
    );
  }
}

EventList.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  eventActiveReducer: getAllEvents(state),
  selectModal: getSelectModal(state),
  currentEventGroup: getCurrentEventGroup(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventList)
);
