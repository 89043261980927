import React, { Component, forwardRef } from "react";
import "./style.less";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class CustomisedDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      width100,
      value,
      callbackFromParent,
      startDate,
      endDate,
      isStartDate,
      isEndDate,
      disabled,
    } = this.props;

    const CustomInput = forwardRef((props, ref) => {
      return (
        <div
          className={
            (disabled ? "text-disabled " : "") +
            (width100
              ? "date-picker-input uk-flex uk-flex-middle width100"
              : "date-picker-input uk-flex uk-flex-middle")
          }
          ref={ref}
          onClick={props.onClick}
        >
          <div className={width100 ? "text-date" : "uk-margin-left"}>
            {props.value}{" "}
          </div>
          <span
            className="uk-icon uk-position-right uk-margin-right uk-flex uk-flex-middle"
            uk-icon="icon: acr-interface-calendar"
          />
        </div>
      );
    });

    return (
      <DatePicker
        selected={value}
        onChange={(date) => callbackFromParent(date)}
        customInput={<CustomInput />}
        dateFormat="yyyy-MM-dd"
        startDate={startDate}
        minDate={isEndDate ? startDate : null}
        maxDate={isStartDate && endDate ? endDate : null}
        readOnly={disabled}
      />
    );
  }
}

export default CustomisedDatePicker;
