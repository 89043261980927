import * as moment from "moment";
import stations_img_red from "assets/icon/multitab-icns/Tab_StazioniMisuraRed.svg";
import stations_img_green from "assets/icon/multitab-icns/Tab_StazioniMisuraGreen.svg";
import stations_img_gray from "assets/icon/multitab-icns/Tab_StazioniMisuraGrey.svg";

export const getArcColorByLos = (los) => {
  const integerLos = Math.trunc(los);

  switch (integerLos) {
    case 1:
      return "lightblue";
    case 2:
      return "green";
    case 3:
      return "yellow";
    case 4:
      return "orange";
    case 5:
      return "red";
    default:
      return "gray";
  }
};

export const getArcColorRGBByLos = (los) => {
  const integerLos = Math.trunc(los);
  switch (integerLos) {
    case 1:
      return [50, 158, 168];
    case 2:
      return [0, 255, 0];
    case 3:
      return [255, 255, 0];
    case 4:
      return [255, 102, 0];
    case 5:
      return [255, 0, 0];
    default:
      return [179, 179, 179];
  }
};

export const getArcTrafficDescriptionByLos = (los) => {
  const integerLos = Math.trunc(los);

  switch (integerLos) {
    case 1:
      return "Scorrevole";
    case 2:
      return "Regolare";
    case 3:
      return "Rallentato";
    case 4:
      return "Congestionato";
    case 5:
      return "Bloccato";
    default:
      return "N/A";
  }
};

export const getMeasurePropertiesById = (list, id) => {
  let name = id.toString();
  let unitType = "";

  const property = list.find((item) => item.measureId === id);
  if (property) {
    name = property.measureName;
    unitType = property.measureUnitType;
  }
  return {
    name,
    unitType,
  };
};

export function getLineChartDataFromMeasuredState(inputData) {
  let newData = [];

  inputData.forEach((item) => {
    if (item.measurementTimeValue) {
      let yValue;
      if (
        item &&
        item.archs &&
        item.archs[0] &&
        item.archs[0].contexts &&
        item.archs[0].contexts[0] &&
        item.archs[0].contexts[0].vehicleClasses &&
        item.archs[0].contexts[0].vehicleClasses[0] &&
        item.archs[0].contexts[0].vehicleClasses[0].measures &&
        item.archs[0].contexts[0].vehicleClasses[0].measures.find(
          (item) => item.measureId === "flow_cap"
        )
      ) {
        if (
          item.archs[0].contexts[0].vehicleClasses[0].measures.find(
            (item) => item.measureId === "flow_cap"
          ).measureValue <= 1
        ) {
          yValue = item.archs[0].contexts[0].vehicleClasses[0].measures.find(
            (item) => item.measureId === "flow_cap"
          ).measureValue;
        } else {
          yValue = 1;
        }
      } else {
        yValue = null;
      }

      return newData.push({
        x: item.measurementTimeValue,
        y: yValue,
      });
    }
  });

  newData = newData && newData[0] && newData.map((obj) => JSON.stringify(obj));
  newData = [...new Set(newData)].map((u) => JSON.parse(u));

  let sortedNewData = newData.sort(function (a, b) {
    return a.x < b.x ? -1 : a.x > b.x ? 1 : 0;
  });

  return [
    {
      id: "Flusso/Capacità",
      data: [...sortedNewData],
    },
  ];
}

export function getLineChartDataFromMeasuredTypic(inputData) {
  let newData = [];

  inputData.forEach((item) => {
    if (item && item.measurementTimeValue) {
      let yValue;
      if (
        item &&
        item.archs &&
        item.archs[0] &&
        item.archs[0].profile &&
        item.archs[0].profile[0] &&
        item.archs[0].profile[0].measures &&
        item.archs[0].profile[0].measures.find(
          (item) => item.measureId === "flow_cap"
        )
      ) {
        if (
          item.archs[0].profile[0].measures.find(
            (item) => item.measureId === "flow_cap"
          ).measureValue <= 1
        ) {
          yValue = item.archs[0].profile[0].measures.find(
            (item) => item.measureId === "flow_cap"
          ).measureValue;
        } else {
          yValue = 1;
        }
      } else {
        yValue = null;
      }
      return newData.push({
        x: moment(item.measurementTimeValue).subtract(2, "hours").format(),
        y: yValue,
      });
    }
  });

  newData = newData && newData[0] && newData.map((obj) => JSON.stringify(obj));
  newData = [...new Set(newData)].map((u) => JSON.parse(u));

  let sortedNewData = newData.sort(function (a, b) {
    return a.x < b.x ? -1 : a.x > b.x ? 1 : 0;
  });

  return [
    {
      id: "Flusso/Capacità",
      data: [...sortedNewData],
    },
  ];
}

export function getArcWidth(
  arc,
  trafficArcCurrentReducer,
  trafficArcMapCurrentReducer
) {
  if (
    (arc && arc.properties && arc.properties.selected) ||
    (trafficArcCurrentReducer &&
      trafficArcCurrentReducer.properties &&
      arc.properties &&
      arc.properties.archId === trafficArcCurrentReducer.properties.archId &&
      trafficArcCurrentReducer.properties.selected) ||
    (trafficArcMapCurrentReducer &&
      trafficArcMapCurrentReducer.find(
        (item) => item.properties.archId === arc.properties.archId
      ))
  ) {
    return 10;
  } else {
    return 2;
  }
}

export function getContextAndDateFromApiCall(
  trafficCurrentStartTime,
  offSet,
  contexts,
  currentTime
) {
  let outputDate = null;
  let outputContext = null;

  const duration = moment.duration(
    moment(currentTime).diff(trafficCurrentStartTime)
  );
  const minutes = duration.asMinutes();
  if ((currentTime, trafficCurrentStartTime)) {
    if (moment(trafficCurrentStartTime).isBefore(moment(currentTime))) {
      if (minutes <= 60) {
        if (offSet > 0) {
          outputDate = trafficCurrentStartTime.toISOString();
          outputContext = contexts.find((item) => {
            return item.timeOffset === offSet;
          });
        } else if (offSet < 0) {
          outputDate = currentTime;
          outputContext = contexts.find((item) => {
            return item.timeOffset === null || item.timeOffset === 0;
          });
        } else if (offSet === 0) {
          outputDate = trafficCurrentStartTime.toISOString();
          outputContext = contexts.find((item) => {
            return item.timeOffset === null || item.timeOffset === 0;
          });
        }
      } else if (minutes > 60) {
        outputDate = currentTime.toISOString();
        outputContext = contexts.find((item) => {
          return item.timeOffset === null || item.timeOffset === 0;
        });
      }
    } else {
      outputDate = currentTime.toISOString();
      outputContext = contexts.find((item) => {
        return item.timeOffset === null || item.timeOffset === 0;
      });
    }
  }

  return { outputDate, outputContext };
}

export function getDeltaTimeOffSet(offSet) {
  let deltaTime;
  if (offSet === -15 || offSet === 15) {
    deltaTime = 0.25;
  } else if (offSet === -30 || offSet === 30) {
    deltaTime = 0.5;
  } else if (offSet === -45 || offSet === 45) {
    deltaTime = 0.75;
  } else if (offSet === -60 || offSet === 60) {
    deltaTime = 1;
  } else {
    deltaTime = 0.25;
  }

  return deltaTime;
}

export function getContestoReteToShow(currentStartDateTime, currentDateTime) {
  let currentContextName = null;

  if (moment(currentDateTime).isBefore(moment(currentStartDateTime))) {
    currentContextName = "Storico";
  } else if (moment(currentDateTime).isSame(moment(currentStartDateTime))) {
    currentContextName = "Corrente";
  } else if (moment(currentStartDateTime).isBefore(moment(currentDateTime))) {
    currentContextName = "Previsto";
  }

  return widgetContestsToShow.find((item) => item.value === currentContextName)
    ? widgetContestsToShow.find((item) => item.value === currentContextName)
    : null;
}

export function getDiffDuration(currentTime, date) {
  const newDateStart = moment(currentTime);
  const newDateEnd = moment(date);
  const diff = newDateEnd.diff(newDateStart);
  const diffDuration = moment.duration(diff);

  return diffDuration;
}

export function showContestDateAndHour(
  trafficCurrentStartTime,
  time,
  currentOffSet
) {
  let dateHourString = "";

  const diffDuration = getDiffDuration(trafficCurrentStartTime, time);

  if (
    diffDuration.minutes() === 0 &&
    diffDuration.hours() === 0 &&
    diffDuration.days() === 0 &&
    diffDuration.months() === 0 &&
    diffDuration.years() === 0
  ) {
    dateHourString =
      "oggi, " +
      moment(time).format("HH:mm") +
      " " +
      moment(time).add(15, "minutes").format("HH:mm");
  } else if (
    diffDuration.minutes() !== 0 &&
    diffDuration.hours() === 0 &&
    diffDuration.days() === 0 &&
    diffDuration.months() === 0 &&
    diffDuration.years() === 0
  ) {
    dateHourString =
      "oggi, " +
      moment(time).format("HH:mm") +
      " " +
      moment(time).add(15, "minutes").format("HH:mm") +
      " (" +
      diffDuration.minutes() +
      " min)";
  } else if (
    diffDuration.hours() !== 0 &&
    diffDuration.days() === 0 &&
    diffDuration.months() === 0 &&
    diffDuration.years() === 0
  ) {
    dateHourString =
      "oggi, " +
      moment(time).format("HH:mm") +
      " " +
      moment(time).add(15, "minutes").format("HH:mm") +
      " (" +
      diffDuration.hours() +
      " h)";
  } else if (
    diffDuration.days() !== 0 &&
    diffDuration.months() === 0 &&
    diffDuration.years() === 0
  ) {
    dateHourString =
      moment(time).format("DD/MM/YYYY ") +
      moment(time).format("HH:mm") +
      " " +
      moment(time).add(15, "minutes").format("HH:mm") +
      " (" +
      diffDuration.days() +
      " gg)";
  } else if (
    diffDuration.months() !== 0 &&
    (diffDuration.months() === 1 || diffDuration.months() === -1) &&
    diffDuration.years() === 0
  ) {
    dateHourString =
      moment(time).format("DD/MM/YYYY ") +
      moment(time).format("HH:mm") +
      " " +
      moment(time).add(15, "minutes").format("HH:mm") +
      " (" +
      diffDuration.months() +
      " mese)";
  } else if (diffDuration.months() !== 0 && diffDuration.years() === 0) {
    dateHourString =
      moment(time).format("DD/MM/YYYY ") +
      moment(time).format("HH:mm") +
      " " +
      moment(time).add(15, "minutes").format("HH:mm") +
      " (" +
      diffDuration.months() +
      " mesi)";
  } else if (diffDuration.years() === 1 || diffDuration.years() === -1) {
    dateHourString =
      moment(time).format("DD/MM/YYYY ") +
      moment(time).format("HH:mm") +
      " " +
      moment(time).add(15, "minutes").format("HH:mm") +
      " (" +
      diffDuration.years() +
      " anno)";
  } else if (diffDuration.years() !== 0) {
    dateHourString =
      moment(time).format("DD/MM/YYYY ") +
      moment(time).format("HH:mm") +
      " " +
      moment(time).add(15, "minutes").format("HH:mm") +
      " (" +
      diffDuration.years() +
      " anni)";
  }

  return dateHourString;
}

export function showTypicalDateandHour(time) {
  return (
    moment(time).format("HH:mm") +
    " " +
    moment(time).add(15, "minutes").format("HH:mm")
  );
}

export const dayTypeList = [
  {
    enum: "WORKING_SCHOOL",
    name: "Working School",
    nameIT: "Feriale Scolastico",
    shortName: "FS",
  },
  {
    enum: "WORKING_NO_SCHOOL",
    name: "Working No School",
    nameIT: "Feriale non Scolastico",
    shortName: "FNS",
  },
  { enum: "HOLIDAY", name: "Holiday", nameIT: "Festivo", shortName: "WE" },
];

export function getTrafficDataToBePlotted(
  newCurrentElaboratedData,
  contexts,
  measureType
) {
  let finalPlotMeasureCurrent = [];
  let finalPlotMeasure15minute = [];
  let finalPlotMeasure30minute = [];
  let finalPlotMeasure45minute = [];
  let finalPlotMeasure60minute = [];
  let profileMeasure = [];

  let contextCorrente = contexts.find((item) => {
    return item.contextName === "0";
  });
  let context15Min = contexts.find((item) => {
    return item.timeOffset === 15;
  });
  let context30Min = contexts.find((item) => {
    return item.timeOffset === 30;
  });
  let context45Min = contexts.find((item) => {
    return item.timeOffset === 45;
  });
  let context60Min = contexts.find((item) => {
    return item.timeOffset === 60;
  });
  const strArr =
    newCurrentElaboratedData &&
    newCurrentElaboratedData[0] &&
    newCurrentElaboratedData.map((obj) => JSON.stringify(obj));
  const uniq = [...new Set(strArr)].map((u) => JSON.parse(u));
  uniq &&
    uniq.length > 0 &&
    uniq.forEach((item) => {
      item.archs[0].contexts.forEach((context) => {
        if (context.contextId === contextCorrente.contextId) {
          finalPlotMeasureCurrent.push({
            x: item.measurementStartOfPeriod,
            y:
              context &&
              context.vehicleClasses &&
              context.vehicleClasses[0] &&
              context.vehicleClasses[0].measures &&
              context.vehicleClasses[0].measures[0]
                ? context.vehicleClasses[0].measures[0].measureValue &&
                  measureType === "%"
                  ? (
                      context.vehicleClasses[0].measures[0].measureValue * 100
                    ).toFixed(1)
                  : context.vehicleClasses[0].measures[0].measureValue.toFixed(
                      1
                    )
                : null,
          });
          profileMeasure.push({
            x: item.measurementTimeValue,
            y:
              item.archs[0].profile[0].measures[0].measureValue &&
              measureType === "%"
                ? (
                    item.archs[0].profile[0].measures[0].measureValue * 100
                  ).toFixed(1)
                : item.archs[0].profile[0].measures[0].measureValue.toFixed(1),
          });
        } else if (context.contextId === context15Min.contextId) {
          finalPlotMeasure15minute.push({
            x: item.measurementStartOfPeriod,
            y:
              context &&
              context.vehicleClasses &&
              context.vehicleClasses[0] &&
              context.vehicleClasses[0].measures &&
              context.vehicleClasses[0].measures[0]
                ? context.vehicleClasses[0].measures[0].measureValue &&
                  measureType === "%"
                  ? (
                      context.vehicleClasses[0].measures[0].measureValue * 100
                    ).toFixed(1)
                  : context.vehicleClasses[0].measures[0].measureValue.toFixed(
                      1
                    )
                : null,
          });
        } else if (context.contextId === context30Min.contextId) {
          finalPlotMeasure30minute.push({
            x: item.measurementStartOfPeriod,
            y:
              context &&
              context.vehicleClasses &&
              context.vehicleClasses[0] &&
              context.vehicleClasses[0].measures &&
              context.vehicleClasses[0].measures[0]
                ? context.vehicleClasses[0].measures[0].measureValue &&
                  measureType === "%"
                  ? (
                      context.vehicleClasses[0].measures[0].measureValue * 100
                    ).toFixed(1)
                  : context.vehicleClasses[0].measures[0].measureValue.toFixed(
                      1
                    )
                : null,
          });
        } else if (context.contextId === context45Min.contextId) {
          finalPlotMeasure45minute.push({
            x: item.measurementStartOfPeriod,
            y:
              context &&
              context.vehicleClasses &&
              context.vehicleClasses[0] &&
              context.vehicleClasses[0].measures &&
              context.vehicleClasses[0].measures[0]
                ? context.vehicleClasses[0].measures[0].measureValue &&
                  measureType === "%"
                  ? (
                      context.vehicleClasses[0].measures[0].measureValue * 100
                    ).toFixed(1)
                  : context.vehicleClasses[0].measures[0].measureValue.toFixed(
                      1
                    )
                : null,
          });
        } else if (context.contextId === context60Min.contextId) {
          finalPlotMeasure60minute.push({
            x: item.measurementStartOfPeriod,
            y:
              context &&
              context.vehicleClasses &&
              context.vehicleClasses[0] &&
              context.vehicleClasses[0].measures &&
              context.vehicleClasses[0].measures[0]
                ? context.vehicleClasses[0].measures[0].measureValue &&
                  measureType === "%"
                  ? (
                      context.vehicleClasses[0].measures[0].measureValue * 100
                    ).toFixed(1)
                  : context.vehicleClasses[0].measures[0].measureValue.toFixed(
                      1
                    )
                : null,
          });
        }
      });
    });

  let profileMeasureSorted = profileMeasure.sort(function (a, b) {
    return a.x < b.x ? -1 : a.x > b.x ? 1 : 0;
  });
  let finalPlotMeasureCurrentSorted = finalPlotMeasureCurrent.sort(function (
    a,
    b
  ) {
    return a.x < b.x ? -1 : a.x > b.x ? 1 : 0;
  });
  let finalPlotMeasure15minuteSorted = finalPlotMeasure15minute.sort(function (
    a,
    b
  ) {
    return a.x < b.x ? -1 : a.x > b.x ? 1 : 0;
  });
  let finalPlotMeasure30minuteSorted = finalPlotMeasure30minute.sort(function (
    a,
    b
  ) {
    return a.x < b.x ? -1 : a.x > b.x ? 1 : 0;
  });
  let finalPlotMeasure45minuteSorted = finalPlotMeasure45minute.sort(function (
    a,
    b
  ) {
    return a.x < b.x ? -1 : a.x > b.x ? 1 : 0;
  });
  let finalPlotMeasure60minuteSorted = finalPlotMeasure60minute.sort(function (
    a,
    b
  ) {
    return a.x < b.x ? -1 : a.x > b.x ? 1 : 0;
  });
  let arrayPlotted = [
    {
      id: "Profilo",
      color: "hsl(349, 70%, 50%)",
      data: profileMeasureSorted,
    },
    {
      id: "Previsto +60",
      color: "hsl(353, 70%, 50%)",
      data: finalPlotMeasure60minuteSorted,
    },
    {
      id: "Previsto +45",
      color: "hsl(232, 70%, 50%)",
      data: finalPlotMeasure45minuteSorted,
    },
    {
      id: "Previsto +30",
      color: "hsl(320, 70%, 50%)",
      data: finalPlotMeasure30minuteSorted,
    },
    {
      id: "Previsto +15",
      color: "hsl(261, 70%, 50%)",
      data: finalPlotMeasure15minuteSorted,
    },
    {
      id: "Corrente",
      color: "hsl(149, 70%, 50%)",
      data: finalPlotMeasureCurrentSorted,
    },
  ];
  return arrayPlotted;
}

export const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

export const widgetContestsToShow = [
  {
    key: "FORECAST",
    value: "Previsto",
  },
  {
    key: "ACTUAL",
    value: "Corrente",
  },
  {
    key: "HISTORY",
    value: "Storico",
  },
  {
    key: "TYPICAL",
    value: "Tipico",
  },
];

export const trafficSliderMarks = [
  {
    value: -60,
    label: "-60 min",
  },
  {
    value: -45,
    label: "-45 min",
  },
  {
    value: -30,
    label: "-30 min",
  },
  {
    value: -15,
    label: "-15 min",
  },
  {
    value: 0,
    label: "Ora",
  },
  {
    value: 15,
    label: "+15 min",
  },
  {
    value: 30,
    label: "+30 min",
  },
  {
    value: 45,
    label: "+45 min",
  },
  {
    value: 60,
    label: "+60 min",
  },
];

export function measureToShow(measure, unitType) {
  if (measure.measureValue || measure.measureValue === 0) {
    if (measure.measureId !== "los") {
      if (unitType === "%") {
        let multiplied = measure.measureValue * 100;
        return multiplied.toFixed(1);
      } else {
        let rounded = Math.round(measure.measureValue * 10) / 10;
        return rounded.toFixed(1);
      }
    } else if (measure.measureId === "los") {
      return getArcTrafficDescriptionByLos(measure.measureValue);
    }
  } else {
    return "-";
  }
}
export function getTrafficMaxIntervalTime(data) {
  let dates = [];
  data.forEach((item) => {
    item.data.forEach((i) => {
      dates.push(new Date(i.x));
    });
  });
  let maxDate = new Date(Math.max.apply(null, dates));
  let minDate = new Date(Math.min.apply(null, dates));

  let newDateStart = moment(minDate);
  let newDateEnd = moment(maxDate);
  let diff = newDateEnd.diff(newDateStart);
  let diffDuration = moment.duration(diff);
  let differentTime =
    diffDuration.days() * 60 * 24 +
    diffDuration.hours() * 60 +
    diffDuration.minutes();
  return differentTime;
}
export function getTimeRange(currentElaboratedData) {
  let rangeArrayNew = currentElaboratedData.map((item) => {
    return item.measurementStartOfPeriod;
  });
  let rangeArrayNewSorted = rangeArrayNew.sort(function (a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  });
  const strArr =
    rangeArrayNewSorted &&
    rangeArrayNewSorted[0] &&
    rangeArrayNewSorted.map((obj) => JSON.stringify(obj));
  const uniq = [...new Set(strArr)].map((u) => JSON.parse(u));
  return uniq;
}
export function getTrafficDataTableView(
  startTime,
  newCurrentElaboratedData,
  contexts,
  measureType
) {
  let finalPlotMeasureCurrent = null;
  let finalPlotMeasure15minute = null;
  let finalPlotMeasure30minute = null;
  let finalPlotMeasure45minute = null;
  let finalPlotMeasure60minute = null;
  let profileMeasure = [];

  let contextCorrente = contexts.find((item) => {
    return item.contextName === "0";
  });
  let context15Min = contexts.find((item) => {
    return item.timeOffset === 15;
  });
  let context30Min = contexts.find((item) => {
    return item.timeOffset === 30;
  });
  let context45Min = contexts.find((item) => {
    return item.timeOffset === 45;
  });
  let context60Min = contexts.find((item) => {
    return item.timeOffset === 60;
  });
  const strArr =
    newCurrentElaboratedData &&
    newCurrentElaboratedData[0] &&
    newCurrentElaboratedData.map((obj) => JSON.stringify(obj));
  const uniq = [...new Set(strArr)].map((u) => JSON.parse(u));

  uniq &&
    uniq.length > 0 &&
    uniq.forEach((item) => {
      item.archs[0].contexts.forEach((context) => {
        if (
          context.contextId === contextCorrente.contextId &&
          item.measurementStartOfPeriod === startTime
        ) {
          finalPlotMeasureCurrent =
            context &&
            context.vehicleClasses &&
            context.vehicleClasses[0] &&
            context.vehicleClasses[0].measures &&
            context.vehicleClasses[0].measures[0]
              ? context.vehicleClasses[0].measures[0].measureValue &&
                measureType === "%"
                ? context.vehicleClasses[0].measures[0].measureValue * 100
                : context.vehicleClasses[0].measures[0].measureValue
              : null;
          profileMeasure.push({
            value:
              item.archs[0].profile[0].measures[0].measureValue &&
              measureType === "%"
                ? item.archs[0].profile[0].measures[0].measureValue * 100
                : item.archs[0].profile[0].measures[0].measureValue,
            context: 1,
          });
        } else if (
          context.contextId === context15Min.contextId &&
          item.measurementStartOfPeriod === startTime
        ) {
          finalPlotMeasure15minute =
            context &&
            context.vehicleClasses &&
            context.vehicleClasses[0] &&
            context.vehicleClasses[0].measures &&
            context.vehicleClasses[0].measures[0]
              ? context.vehicleClasses[0].measures[0].measureValue &&
                measureType === "%"
                ? context.vehicleClasses[0].measures[0].measureValue * 100
                : context.vehicleClasses[0].measures[0].measureValue
              : null;
          profileMeasure.push({
            value:
              item.archs[0].profile[0].measures[0].measureValue &&
              measureType === "%"
                ? item.archs[0].profile[0].measures[0].measureValue * 100
                : item.archs[0].profile[0].measures[0].measureValue,
            context: 2,
          });
        } else if (
          context.contextId === context30Min.contextId &&
          item.measurementStartOfPeriod === startTime
        ) {
          finalPlotMeasure30minute =
            context &&
            context.vehicleClasses &&
            context.vehicleClasses[0] &&
            context.vehicleClasses[0].measures &&
            context.vehicleClasses[0].measures[0]
              ? context.vehicleClasses[0].measures[0].measureValue &&
                measureType === "%"
                ? context.vehicleClasses[0].measures[0].measureValue * 100
                : context.vehicleClasses[0].measures[0].measureValue
              : null;
          profileMeasure.push({
            value:
              item.archs[0].profile[0].measures[0].measureValue &&
              measureType === "%"
                ? item.archs[0].profile[0].measures[0].measureValue * 100
                : item.archs[0].profile[0].measures[0].measureValue,
            context: 3,
          });
        } else if (
          context.contextId === context45Min.contextId &&
          item.measurementStartOfPeriod === startTime
        ) {
          finalPlotMeasure45minute =
            context &&
            context.vehicleClasses &&
            context.vehicleClasses[0] &&
            context.vehicleClasses[0].measures &&
            context.vehicleClasses[0].measures[0]
              ? context.vehicleClasses[0].measures[0].measureValue &&
                measureType === "%"
                ? context.vehicleClasses[0].measures[0].measureValue * 100
                : context.vehicleClasses[0].measures[0].measureValue
              : null;
          profileMeasure.push({
            value:
              item.archs[0].profile[0].measures[0].measureValue &&
              measureType === "%"
                ? item.archs[0].profile[0].measures[0].measureValue * 100
                : item.archs[0].profile[0].measures[0].measureValue,
            context: 4,
          });
        } else if (
          context.contextId === context60Min.contextId &&
          item.measurementStartOfPeriod === startTime
        ) {
          finalPlotMeasure60minute =
            context &&
            context.vehicleClasses &&
            context.vehicleClasses[0] &&
            context.vehicleClasses[0].measures &&
            context.vehicleClasses[0].measures[0]
              ? context.vehicleClasses[0].measures[0].measureValue &&
                measureType === "%"
                ? context.vehicleClasses[0].measures[0].measureValue * 100
                : context.vehicleClasses[0].measures[0].measureValue
              : null;
          profileMeasure.push({
            value:
              item.archs[0].profile[0].measures[0].measureValue &&
              measureType === "%"
                ? item.archs[0].profile[0].measures[0].measureValue * 100
                : item.archs[0].profile[0].measures[0].measureValue,
            context: 5,
          });
        }
      });
    });

  let finalProfile = profileMeasure.filter((item) => {
    return item.value !== null;
  });
  let finalProfileSorted = finalProfile.sort(function (a, b) {
    return a.context < b.context ? -1 : a.context > b.context ? 1 : 0;
  });

  let arrayPrinted = [
    finalProfileSorted[0].value,
    finalPlotMeasure60minute,
    finalPlotMeasure45minute,
    finalPlotMeasure30minute,
    finalPlotMeasure15minute,
    finalPlotMeasureCurrent,
  ];

  return arrayPrinted;
}

export const STATION_STATUS = [
  {
    name: "No Info",
    value: 1,
    icon: stations_img_gray,
  },
  {
    name: "Down",
    value: 2,
    icon: stations_img_red,
  },
  {
    name: "Up",
    value: 3,
    icon: stations_img_green,
  },
];
