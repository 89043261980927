import React, { Component } from "react";
import "./style.less";

class PmvPanelBigText extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { text } = this.props;

    return (
      <div className="uk-position-relative uk-flex uk-flex-center uk-flex-middle">
        <div className="pmv-text-big">{text}</div>
      </div>
    );
  }
}

export default PmvPanelBigText;
