export const GraphMenuActions = {
  SET_GRAPH_VISIBILITY: "UI/GRAPH_MENU/SET_GRAPH_VISIBILITY",
  SET_ARC_VISIBILITY: "UI/GRAPH_MENU/SET_ARC_VISIBILITY",
  SET_NODE_VISIBILITY: "UI/GRAPH_MENU/SET_NODE_VISIBILITY",
  SET_CENTROID_VISIBILITY: "UI/GRAPH_MENU/SET_CENTROID_VISIBILITY",
  SET_NETWORK_RDS_TMC_VISIBILITY:
    "UI/GRAPH_MENU/SET_NETWORK_RDS_TMC_VISIBILITY",
  TOGGLE_ARCS_TABLE_OPEN: "UI/GRAPH_MENU/TOGGLE_ARCS_TABLE_OPEN",
  TOGGLE_POINTS_TABLE_OPEN: "UI/GRAPH_MENU/TOGGLE_POINTS_TABLE_OPEN",
  TOGGLE_TMC_TABLE_OPEN: "UI/GRAPH_MENU/TOGGLE_TMC_TABLE_OPEN",
  SET_IS_GRAPH_LOADING: "UI/GRAPH_MENU/SET_IS_GRAPH_LOADING",
  SET_IS_NETWORK_LOADING: "UI/GRAPH_MENU/SET_IS_NETWORK_LOADING",
};

export const setGraphVisibility = (visible) => (dispatch) => {
  dispatch({
    type: GraphMenuActions.SET_GRAPH_VISIBILITY,
    payload: { visible },
  });
};

export const setArcVisibility = (visible) => (dispatch) => {
  dispatch({
    type: GraphMenuActions.SET_ARC_VISIBILITY,
    payload: { visible },
  });
};

export const setNodeVisibility = (visible) => (dispatch) => {
  dispatch({
    type: GraphMenuActions.SET_NODE_VISIBILITY,
    payload: { visible },
  });
};

export const setCentroidVisibility = (visible) => (dispatch) => {
  dispatch({
    type: GraphMenuActions.SET_CENTROID_VISIBILITY,
    payload: { visible },
  });
};

export const setNetworkRdsTmcVisibility = (visible) => (dispatch) => {
  dispatch({
    type: GraphMenuActions.SET_NETWORK_RDS_TMC_VISIBILITY,
    payload: { visible },
  });
};

export const setIsGraphLoading = ({
  isArcsLoading,
  isNodesLoading,
  isCentroidsLoading,
}) => (dispatch) => {
  dispatch({
    type: GraphMenuActions.SET_IS_GRAPH_LOADING,
    payload: {
      isArcsLoading,
      isNodesLoading,
      isCentroidsLoading,
    },
  });
};

export const setIsNetworkLoading = ({ isPointsLoading, isSegmentsLoading }) => (
  dispatch
) => {
  dispatch({
    type: GraphMenuActions.SET_IS_NETWORK_LOADING,
    payload: {
      isPointsLoading,
      isSegmentsLoading,
    },
  });
};
