import React, { Component } from "react";
import OrderTableColumn from "components/shared/order-table-column/order-table-column";

class PracticeManagementHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { requestToSort } = this.props;
    return (
      <thead>
        <tr>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              ID Evento
              <OrderTableColumn requestSort={requestToSort} type="eventId" />
            </div>
          </th>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Evento Causa
            </div>
          </th>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Tipo Evento
              <OrderTableColumn requestSort={requestToSort} type="eventType" />
            </div>
          </th>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Sottotipo Evento
              <OrderTableColumn
                requestSort={requestToSort}
                type="eventUndertype"
              />
            </div>
          </th>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Data/ora Inizio
              <OrderTableColumn
                requestSort={requestToSort}
                type="eventOpenTime"
              />
            </div>
          </th>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Data/ora Fine
              <OrderTableColumn
                requestSort={requestToSort}
                type="eventClosingTime"
              />
            </div>
          </th>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Gravità
              <OrderTableColumn
                requestSort={requestToSort}
                type="eventSeverity"
              />
            </div>
          </th>
          <th>
            <div className="uk-flex uk-flex-row uk-flex-middle  uk-flex-between">
              Stato
              <OrderTableColumn requestSort={requestToSort} type="eventState" />
            </div>
          </th>
        </tr>
      </thead>
    );
  }
}

export default PracticeManagementHeader;
