import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//COMPONENTS
import EnvironmentContext from "environment-context";
//REDUX
import { getPinnedCameras } from "store";

export class WebcamPin extends Component {

    constructor(props) {
        super(props);
    }

    render = () => {
        const { pinCamera, pinnedCameras, isPinned, maxCamerasPinnable, camera } = this.props;
        const maxNumberOfStreamingReached = typeof maxCamerasPinnable == "number" ? pinnedCameras.length >= maxCamerasPinnable : 0;
        
        return (
            <span
                className={
                    isPinned
                        ? "pointer uk-icon-link uk-icon icon-selected"
                        : !isPinned && !maxNumberOfStreamingReached
                            ? "pointer uk-icon-link uk-icon"
                            : "uk-icon icon-not-selected"
                }
                uk-icon="icon: acr-interface-fixed"
                onClick={() => {
                    if (
                        isPinned ||
                        (!isPinned && !maxNumberOfStreamingReached)
                    ) {
                        pinCamera(camera);
                    }
                }}
            />)
    }
}

WebcamPin.contextType = EnvironmentContext;

const mapStateToProps = (state) => ({
    pinnedCameras: getPinnedCameras(state),
});

const mapDispatchToProps = {};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WebcamPin)
);
