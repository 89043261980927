import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { take, tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import * as moment from "moment";
import "../style.less";

import createApiService from "services/api.service";
import EnvironmentContext from "environment-context";
//COMPONENTS
import TableBody from "components/shared/table-components/table-body";
import TableHead from "components/shared/table-components/table-head";
//STORE
import { getCurrentStation } from "store";
//UTIL
import { TABLE_COLUMNS } from "utils/table-utils/util-station-table";
class ListStationTable extends Component {
  apiService;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      openStationTable: false,
      dataTable: [],
      defaultMeasure: null,
      sortConfig: { key: "", direction: "" },
    };
  }

  componentDidMount = () => {
    const { dateStart, dateEnd, currentStation, defaultMeasure } = this.props;
    this.apiService = createApiService(this.context);

    let newDateStart = moment(dateStart);
    let newDateEnd = moment(dateEnd);
    let diff = newDateEnd.diff(newDateStart);
    let diffDuration = moment.duration(diff);
    let differentTime =
      diffDuration.days() * 60 * 24 +
      diffDuration.hours() * 60 +
      diffDuration.minutes();

    this.subscriptions.push(
      this.apiService
        .getStationDetailTable(
          currentStation && currentStation.stationId
            ? currentStation.stationId
            : null,
          dateStart && dateStart.toISOString(),
          differentTime,
          defaultMeasure ? defaultMeasure.name : ""
        )
        .pipe(
          take(1),
          tap((data) => {
            if (data.length >= 0) {
              this.setState({ dataTable: data });
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { dateStart, defaultMeasure, dateEnd, currentStation } = this.props;
    if (
      prevProps.dateStart !== dateStart ||
      prevProps.dateEnd !== dateEnd ||
      prevProps.defaultMeasure.name !== defaultMeasure.name
    ) {
      let newDateStart = moment(dateStart);
      let newDateEnd = moment(dateEnd);
      let diff = newDateEnd.diff(newDateStart);
      let diffDuration = moment.duration(diff);
      let differentTime =
        diffDuration.days() * 60 * 24 +
        diffDuration.hours() * 60 +
        diffDuration.minutes();

      this.subscriptions.push(
        this.apiService
          .getStationDetailTable(
            currentStation && currentStation.stationId
              ? currentStation.stationId
              : null,
            dateStart.toISOString(),
            differentTime,
            defaultMeasure.name
          )
          .pipe(
            take(1),
            tap((data) => {
              if (data.length >= 0) {
                this.setState({ dataTable: data });
              }
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  toTableColumns = () => {
    const { defaultMeasure } = this.props;
    const { dataTable } = this.state;

    let newList = [];

    let dataTableSorted = dataTable.sort(function (a, b) {
      return a.measureTimeUtc < b.measureTimeUtc
        ? -1
        : a.measureTimeUtc > b.measureTimeUtc
        ? 1
        : 0;
    });

    dataTableSorted.forEach((item) => {
      let veicoliEquivalenti = "";
      let local_date = moment
        .utc(item.measureTimeUtc)
        .local()
        .format("YYYY-MM-DD HH:mm:ss");
      item.vehicleClassMeasurements.forEach((elemento) => {
        if (elemento.vehicleClassEnum === "EQUIVALENT") {
          veicoliEquivalenti = elemento.value;
        }
      });
      let time = moment(local_date).format("HH:mm:ss");
      let date = moment(local_date).format("YYYY-MM-DD");
      let interval5Minutes = moment(time, "HH:mm:ss")
        .subtract(5, "minutes")
        .format("LTS");
      let newEl = {
        beginning: date + " " + interval5Minutes,
        end: local_date,
        measure: defaultMeasure.nameIt + " [" + defaultMeasure.unit + "]",
        vehicles: veicoliEquivalenti.toString() ? veicoliEquivalenti : "-",
        reliability: item.accuracy ? item.accuracy : "-",
      };

      newList = [...newList, newEl];
    });
    return newList;
  };

  onCheckboxFilterClick = () => {
    return null;
  };

  requestSort = () => {
    return null;
  };

  render = () => {
    const { sortConfig, dataTable } = this.state;
    const { defaultMeasure } = this.props;
    let listOfData = [];
    if (dataTable && defaultMeasure) {
      listOfData = this.toTableColumns();
    }

    return (
      <div
        className={
          "uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container uk-margin-left "
        }
      >
        <table
          id="eventTable"
          className="uk-table uk-table-divider uk-table-hover"
        >
          <thead>
            <TableHead
              onCheckboxFilterClick={this.onCheckboxFilterClick}
              requestSort={this.requestSort}
              tableColumns={TABLE_COLUMNS}
              sortConfig={sortConfig}
            />
          </thead>
          <tbody>
            <TableBody list={listOfData} tableColumns={TABLE_COLUMNS} />
          </tbody>
        </table>
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

ListStationTable.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  currentStation: getCurrentStation(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListStationTable)
);
