import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import "./style.less";
import Topbar from "components/layout/topbar";
import Nav from "components/layout/nav/nav";
import Bottombar from "components/layout/bottombar";
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import MapContainer from "components/layout/map";
import LoadTrafficData from "components/pages/load.data/load.traffic.data";
import Permission from "components/shared/permission";
import ModalContainer from "components/layout/modal/modal-container";
import GenericTableContainer from "components/layout/tables/generic-table-container";
import { isOpenModal, getTypeModal, isGenericTableToggled } from "store";

const DashboardLayout = ({ children, ...rest }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
};

const renderContent = (rest) => {
  if (rest.isGenericTableToggled) return <GenericTableContainer />;
  else return <MapContainer />;
};

const DashboardLayoutRoute = ({
  component: Component,
  isOpenModal,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <Nav />
          <Permission actions="EVENT_MODIFY">
            {isOpenModal && <ModalContainer />}
          </Permission>
          <ErrorBoundary>
            <Topbar />
          </ErrorBoundary>
          <div className="wrapper">
            <div className="row">
              {Component && <Component {...matchProps} />}
              <div className="containerCenter">
                <ErrorBoundary>{renderContent(rest)}</ErrorBoundary>
                <ErrorBoundary>
                  <LoadTrafficData />
                </ErrorBoundary>
                <Bottombar />
              </div>
            </div>
          </div>
        </DashboardLayout>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  isOpenModal: isOpenModal(state),
  typeModal: getTypeModal(state),
  isGenericTableToggled: isGenericTableToggled(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardLayoutRoute);
