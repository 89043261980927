import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentCentroid } from "store";
import * as moment from "moment";
//ASSETS
import acr_centroid_mod from "assets/icon/graph/acr-centroid_mod.svg";
import icn_diamond from "assets/icon/graph/icn_diamond.svg";
class CentroidDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      60000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  header = () => {
    const { toggleCentroidDetails, currentCentroid } = this.props;

    return (
      <div
        id="floatingMenuEvent"
        className="uk-flex uk-flex-between uk-flex-middle header"
      >
        <div className="centroid-icon uk-flex uk-flex-middle uk-flex-center">
          <div className="parent uk-flex uk-flex-middle uk-flex-center">
            <div className="circle2"></div>

            <img
              alt="icn_diamond"
              src={icn_diamond}
              className="image1 uk-position-relative  uk-flex uk-flex-middle uk-flex-center"
            ></img>
            <img
              alt="acr_centroid_mod"
              src={acr_centroid_mod}
              className=" image2  height-width-48 uk-flex uk-flex-middle uk-flex-center centroid-icon-dimension"
            ></img>
          </div>
        </div>
        <div className=" uk-margin-left uk-margin-right font-18 text-line-width text-detail-emphasis-arc">
          {currentCentroid && currentCentroid.nome ? currentCentroid.nome : "-"}
        </div>
        <div
          uk-icon="icon: acr-interface-close-big"
          onClick={(event) => {
            event.preventDefault();
            toggleCentroidDetails(null);
          }}
          className="circle pointer uk-flex uk-flex-center uk-flex-middle"
        ></div>
      </div>
    );
  };

  tabs = () => {
    return (
      <div className="uk-flex stop-tabs">
        <ul className="uk-tab-page-default uk-tab uk-padding-remove">
          <li
            className="pointer uk-active"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <a href="/#">Dettagli</a>
          </li>
        </ul>
      </div>
    );
  };

  body = () => {
    const {
      currentCentroid,
      dayType,
      vehiclePerHours,
      handleChangeDate,
      currentDate,
    } = this.props;
    handleChangeDate(new Date());
    return (
      <div className="uk-flex uk-flex-column contentBodyEventDetail">
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">ID Centroide</div>
          <div className="text-detail-emphasis-arc">
            {currentCentroid && currentCentroid.id ? currentCentroid.id : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Nome</div>
          <div className="text-detail-emphasis-arc">
            {currentCentroid && currentCentroid.properties.name
              ? currentCentroid.properties.name
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Latitudine</div>
          <div className="text-detail-emphasis-arc">
            {currentCentroid && currentCentroid.geometry.coordinates[1]
              ? currentCentroid.geometry.coordinates[1]
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Longitudine</div>
          <div className="text-detail-emphasis-arc">
            {currentCentroid && currentCentroid.geometry.coordinates[0]
              ? currentCentroid.geometry.coordinates[0]
              : "-"}
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Veicoli in ingresso</div>
          <div className="text-detail-emphasis-arc">
            {vehiclePerHours.length > 0
              ? vehiclePerHours.find((item) => {
                  return item.measureId === "flow-IN";
                }).measureValue
              : "- veh/h "}
            <i>
              {"(" +
                dayType +
                ", " +
                moment(currentDate).format("HH") +
                "-" +
                moment(currentDate).add(1, "hours").format("HH") +
                ")"}
            </i>
          </div>
        </li>
        <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Veicoli in uscita</div>
          <div className="text-detail-emphasis-arc">
            {vehiclePerHours.length > 0
              ? vehiclePerHours.find((item) => {
                  return item.measureId === "flow-OUT";
                }).measureValue
              : "- veh/h "}{" "}
            <i>
              {"(" +
                dayType +
                ", " +
                moment(currentDate).format("HH") +
                "-" +
                moment(currentDate).add(1, "hours").format("HH") +
                ")"}
            </i>
          </div>
        </li>
        {/* <li className="uk-flex uk-flex-row">
          <div className="text-detail-muted-arc">Numero Connettori</div>
          <div className="text-detail-emphasis-arc">
            {currentCentroid && currentCentroid.properties.n
              ? currentCentroid.properties.n
              : "-"}
          </div>
        </li> */}
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.header()}
        {this.tabs()}
        {this.body()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCentroid: getCurrentCentroid(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CentroidDetail)
);
