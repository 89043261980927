import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import { ResponsiveLine } from "@nivo/line";
import "../style.less";
import EnvironmentContext from "environment-context";

//COMPONENTS
import Loading from "components/shared/loading/loading";
//UTILS
import { getTrafficMaxIntervalTime } from "utils/utils-traffic";

class TrafficGraph extends Component {
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      measureType: "",
      openStationGraph: false,
      trafficData: [],
      filteredTrafficData: [],
    };
  }

  componentDidMount = () => {
    const { data } = this.props;

    this.setState({
      ...this.state,
      trafficData: [...data],
      filteredTrafficData: [...data],
    });
  };

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;

    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      this.setState({
        ...this.state,
        trafficData: [...data],
        filteredTrafficData: [...data],
      });
    }
  };

  getFormatValue = (data, dataInput) => {
    const { dateStart } = this.state;
    if (
      data &&
      dataInput &&
      dataInput[0] &&
      dataInput[0].data.length <= 5 &&
      ((data && data.getHours() === 0) ||
        (data && data.getHours() === 1) ||
        (data &&
          data.getHours() === dateStart.getHours() &&
          data.getMinutes() === dateStart.getMinutes()) ||
        (data &&
          data.getHours() === dateStart.getHours() &&
          data.getMinutes() > dateStart.getMinutes() &&
          data.getMinutes() - 5 <= dateStart.getMinutes()) ||
        (data &&
          data.getHours() === dateStart.getHours() + 1 &&
          data.getMinutes() > dateStart.getMinutes() &&
          data.getMinutes() - 5 <= dateStart.getMinutes() &&
          moment(data).isSame(dateStart, "day")))
    ) {
      return false;
    } else if (
      data &&
      dataInput &&
      dataInput[0] &&
      dataInput[0].data.length > 24 &&
      ((data && data.getHours() === 0) ||
        data.getHours() === 1 ||
        data.getHours() === dateStart.getHours())
    ) {
      return false;
    } else {
      return true;
    }
  };

  handleOpenStationGraph = (open) => {
    this.setState({
      openStationGraph: open,
    });
  };

  filterDataFromLegend = (clicked) => {
    const { filteredTrafficData, trafficData } = this.state;

    let newFilteredData = [];

    if (
      filteredTrafficData.find((item) => item.id === clicked.id) &&
      filteredTrafficData.find((item) => item.id === clicked.id).data.length > 0
    ) {
      newFilteredData = filteredTrafficData.map((item) => {
        if (item.id === clicked.id) {
          return {
            ...item,
            data: [],
          };
        } else return item;
      });
    } else {
      let itemToPush = trafficData.find((item) => item.id === clicked.id);
      newFilteredData = filteredTrafficData.map((item) => {
        if (item.id === clicked.id) {
          return {
            ...item,
            data: [...itemToPush.data],
          };
        } else return item;
      });
    }

    this.setState({
      ...this.state,
      filteredTrafficData: newFilteredData,
    });
  };

  render = () => {
    const { data, defaultMeasure, measureUnit, isGraphLoading } = this.props;
    const { filteredTrafficData } = this.state;
    const isThereAnyNonNullValue =
      data &&
      data.length > 0 &&
      data.find((item1) => item1.data.find((item2) => item2.y !== null));
    const differentTime = getTrafficMaxIntervalTime(filteredTrafficData);

    return (
      <div className="uk-overflow-auto uk-overflow-container uk-padding uk-padding-remove-top uk-padding-remove-bottom table-external-container ">
        {isThereAnyNonNullValue ? (
          <ResponsiveLine
            data={filteredTrafficData ? filteredTrafficData : []}
            margin={{ top: 50, right: 60, bottom: 60, left: 60 }}
            xScale={{
              format: "%Y-%m-%dT%H:%M:%S%Z",
              type: "time",
            }}
            xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Data",
              legendOffset: 37,
              legendPosition: "middle",
              format: "%Y-%m-%d %H:%M",
              tickValues:
                differentTime <= 30
                  ? "every 5 minutes"
                  : differentTime <= 90
                  ? "every 15 minutes"
                  : differentTime <= 180
                  ? "every 30 minutes"
                  : differentTime <= 360
                  ? "every 1 hour"
                  : differentTime <= 720
                  ? "every 2 hours"
                  : differentTime <= 1440
                  ? "every 4 hours"
                  : differentTime <= 1920
                  ? "every 8 hours"
                  : differentTime <= 3840
                  ? "every 16 hours"
                  : differentTime <= 7680
                  ? "every 32 hours"
                  : "every 64 hours",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend:
                defaultMeasure && defaultMeasure + " [" + measureUnit + "]",
              legendOffset: -55,
              legendPosition: "middle",
              fill: "rgba(0, 0, 0, 0)",
            }}
            colors={{ scheme: "spectral" }}
            pointSize={10}
            pointColor="black"
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            areaOpacity={0}
            crosshairType="right"
            useMesh={true}
            lineWidth={5}
            theme={{
              textColor: "#ffffff",
              fontSize: "20px",
              tooltip: {
                container: {
                  background: "#333",
                },
              },
              axis: {
                tickColor: "#eee",
                ticks: {
                  line: {
                    stroke: "#555555",
                  },
                  text: {
                    fill: "#ffffff",
                    fontSize: "15px",
                  },
                },
                legend: {
                  text: {
                    fill: "#ffffff",
                    fontSize: "15px",
                  },
                },
              },
              grid: {
                line: {
                  stroke: "#5c5c5c",
                  strokeWidth: 1,
                  strokeDasharray: "4 4",
                },
              },
            }}
            legends={[
              {
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: -50,
                translateY: -34,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 150,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 18,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                itemTextColor: "#ffffff",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                      fontSize: "35px",
                    },
                  },
                ],
                onClick: (d) => this.filterDataFromLegend(d),
              },
            ]}
            tooltip={(slice) => {
              let half = data[0].data.length / 2;
              return (
                <div
                  style={{
                    background: "#0f0f0f",
                    padding: "9px 12px",
                    border: "1px solid #ccc",
                    position: "absolute",
                    right: slice.point.index > half ? 10 : -135,
                  }}
                >
                  x=
                  {[slice.point.data].map((i) => {
                    return moment(new Date(i.xFormatted)).format(
                      "DD/MM/YYYY-HH:mm"
                    );
                  })}
                  <div>
                    y=
                    {[slice.point.data].map((i) => {
                      return i.y;
                    })}
                  </div>
                </div>
              );
            }}
          />
        ) : (
          <div className="data-position">
            {isGraphLoading ? "NO DATA" : <Loading />}
          </div>
        )}
      </div>
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

TrafficGraph.contextType = EnvironmentContext;

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrafficGraph)
);
