import { ParkingActions } from "./parking.actions";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";
import { INITIAL_STATE_PARKINGS } from "./parking.model";
import * as FilterUtils from "utils/filter-utils";

function searchParkings(searchText) {
  return (parking) =>
    (parking &&
      parking.properties.parkingName &&
      parking.properties.parkingName &&
      parking.properties.parkingName.toString() &&
      parking.properties.parkingName
        .toString()
        .toLowerCase()
        .includes(searchText)) ||
    (parking &&
      parking.properties.parkingAddress &&
      parking.properties.parkingAddress.toString() &&
      parking.properties.parkingAddress
        .toString()
        .toLowerCase()
        .includes(searchText));
}

export default function parkingsReducer(
  state = INITIAL_STATE_PARKINGS,
  action
) {
  let parkingsListCopy = FilterUtils.deepCopyOfArraysOfObj(state.parkings);
  let parkingsFilteredListCopy = FilterUtils.deepCopyOfArraysOfObj(
    state.parkingsFiltered
  );

  switch (action.type) {
    case ParkingActions.SET_LIST_PARKINGS:
      let selectedItem = state.parkings.find((item) => {
        return item.selected;
      });

      let payloadList = FilterUtils.deepCopyOfArraysOfObj(action.payload.list);

      payloadList.forEach((item, index, arr) => {
        if (
          selectedItem &&
          selectedItem.properties &&
          selectedItem.properties.parkingRecordId &&
          item.properties &&
          item.properties.parkingRecordId &&
          selectedItem.properties.parkingRecordId.toString() ===
            item.properties.parkingRecordId.toString()
        ) {
          arr[index] = {
            ...item,
            selected: selectedItem.selected,
          };
        }
      });
      let parkingsFiltered = FilterUtils.deepCopyOfArraysOfObj(payloadList);

      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "parking"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchParkings(searchText);
        parkingsFiltered = payloadList.filter(searchFn);
      }

      return {
        ...state,
        parkings: payloadList,
        parkingsFiltered: parkingsFiltered,
      };
    case ParkingActions.UPDATE_CURRENT_PARKING:
      return {
        ...state,
        current: action.payload,
      };
    case ParkingActions.SET_CURRENT_PARKING:
      if (state.parkings) {
        parkingsListCopy.forEach((item, index, arr) => {
          if (
            item &&
            item.properties &&
            action.payload &&
            action.payload.properties &&
            action.payload.properties.parkingRecordId &&
            item.properties.parkingRecordId.toString() ===
              action.payload.properties.parkingRecordId.toString()
          ) {
            arr[index] = {
              ...item,
              selected: action.payload.selected ? false : true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      if (state.parkingsFiltered) {
        parkingsFilteredListCopy.forEach((item, index, arr) => {
          if (
            item &&
            item.properties &&
            item.properties.parkingRecordId &&
            action.payload &&
            action.payload.properties &&
            action.payload.properties.parkingRecordId &&
            item.properties.parkingRecordId.toString() ===
              action.payload.properties.parkingRecordId.toString()
          ) {
            arr[index] = {
              ...item,
              selected: action.payload.selected ? false : true,
            };
          } else {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }

      let newCurrent = action.payload;
      if (
        action.payload &&
        action.payload.properties &&
        action.payload.properties.parkingRecordId &&
        state.current &&
        state.current.properties &&
        state.current.properties.parkingRecordId &&
        state.current.properties.parkingRecordId.toString() ===
          action.payload.properties.parkingRecordId.toString()
      ) {
        newCurrent = null;
      } else if (action.payload) {
        newCurrent = {
          ...action.payload,
          selected: true,
        };
      }

      return {
        ...state,
        current: newCurrent,
        parkings: parkingsListCopy,
        parkingsFiltered: parkingsFilteredListCopy,
      };

    case ParkingActions.RESET_CURRENT_PARKING:
      if (state.parkings) {
        parkingsListCopy.forEach((item, index, arr) => {
          if (item && item.selected) {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      if (state.parkingsFiltered) {
        parkingsFilteredListCopy.forEach((item, index, arr) => {
          if (item && item.selected) {
            arr[index] = {
              ...item,
              selected: false,
            };
          }
        });
      }
      return {
        ...state,
        parkings: parkingsListCopy,
        parkingsFiltered: parkingsFilteredListCopy,
        current: null,
      };

    case NavTabActions.SET_SEARCH:
      if (action.payload && action.payload.type === "parking") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        let parkingsFiltered = state.parkings.filter(
          searchParkings(searchText)
        );
        return {
          ...state,
          parkingsFiltered,
          searchText,
          searchType: action.payload.type,
        };
      } else {
        return { ...state };
      }
    case ParkingActions.UPDATE_PARKING_STATE_WS: {
      let objState = { ...state };
      let newCurrent = state.current;

      //FOR EACH PARKING IN THE LIST, SEARCH CORRESPONDANCE AND UPDATE IT
      action.payload.forEach((newParkingStatusItem) => {
        parkingsListCopy.forEach((cam, index, arr) => {
          if (
            cam.properties.parkingRecordId.toString() ===
            newParkingStatusItem.parkingRecordId.toString()
          ) {
            arr[index] = {
              ...cam,
              properties: {
                ...cam.properties,
                ...newParkingStatusItem,
              },
            };
          }
        });

        parkingsFilteredListCopy.forEach((cam, index, arr) => {
          if (
            cam.properties.parkingRecordId.toString() ===
            newParkingStatusItem.parkingRecordId.toString()
          ) {
            arr[index] = {
              ...cam,
              properties: {
                ...cam.properties,
                ...newParkingStatusItem,
              },
            };
          }
        });

        if (
          newCurrent &&
          newCurrent.properties &&
          newCurrent.properties.parkingRecordId &&
          newParkingStatusItem &&
          newParkingStatusItem.parkingRecordId &&
          newCurrent.properties.parkingRecordId.toString() ===
            newParkingStatusItem.parkingRecordId.toString()
        ) {
          newCurrent = {
            ...newCurrent,
            properties: {
              ...newCurrent.properties,
              ...newParkingStatusItem,
            },
          };
        }

        objState = {
          ...objState,
          parkings: parkingsListCopy,
          parkingsFiltered: parkingsFilteredListCopy,
          current: newCurrent ? newCurrent : null,
        };
      });
      return {
        ...objState,
        wsLastParking: action.payload,
      };
    }
    case ParkingActions.RESET_PARKING_STATE_WS:
      return {
        ...state,
        wsLastParking: [],
      };

    default:
      return state;
  }
}

export const getParkings = (state) => state.parkings;
export const getFilteredParkings = (state) => state.parkingsFiltered;
export const getCurrentParking = (state) => state.current;
export const getCurrentParkingMap = (state) => state.currentMap;
export const getWsLastParking = (state) => state.wsLastParking;
