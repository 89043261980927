import React, { Component } from "react";
import "./style.less";
import { withRouter } from "react-router";
import { connect } from "react-redux";

class CamerasTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    this.getWidthHeight();
  }

  getWidthHeight = () => {
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ ...this.state, height: height, width: width });
  };

  render() {
    const { x, y, object } = this.props;
    const { height, width } = this.state;

    return (
      <div
        className="show-tooltip"
        style={{ left: x - width / 2, top: y - (height + 20) }}
        ref={(divElement) => {
          this.divElement = divElement;
        }}
      >
        <div className="uk-flex uk-flex-column">
          {/* <div className=" uk-flex uk-flex-center uk-width-1-1 uk-margin-top uk-position-relative">
              <img
                alt="webcamStatus"
                className="webcam-status-tooltip"
                src={DeckLayerUtil.getIconWebcam(object)}
              />
            </div> */}
          <div className="uk-flex uk-flex-center uk-width-1-1 uk-position-relative uk-margin-top uk-margin-bottom">
            <p className="legend-text-small uk-position-center">TELECAMERA</p>
          </div>
          <div className="uk-flex uk-flex-column uk-margin-small-top">
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">ID: </div>
              <div className="tooltip-text-important">
                {object &&
                object.properties &&
                object.properties.cctvWithLastStateAndOpenAlarms &&
                object.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                object.properties.cctvWithLastStateAndOpenAlarms.cctv.id
                  ? object.properties.cctvWithLastStateAndOpenAlarms.cctv.id
                  : "-"}
              </div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Nome: </div>
              <div className="tooltip-text-important subType">
                {object &&
                object.properties &&
                object.properties.cctvWithLastStateAndOpenAlarms &&
                object.properties.cctvWithLastStateAndOpenAlarms &&
                object.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                object.properties.cctvWithLastStateAndOpenAlarms.cctv.name
                  ? object.properties.cctvWithLastStateAndOpenAlarms.cctv.name
                  : "-"}
              </div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Gruppo: </div>
              <div className="tooltip-text-important">
                {object &&
                object.properties &&
                object.properties.cctvWithLastStateAndOpenAlarms &&
                object.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                object.properties.cctvWithLastStateAndOpenAlarms.cctv.group
                  ? object.properties.cctvWithLastStateAndOpenAlarms.cctv.group
                  : "-"}
              </div>
            </div>
            <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-top">
              <div className="tooltip-text-large">Tipologia: </div>
              <div className="tooltip-text-important">
                {object &&
                object.properties &&
                object.properties.cctvWithLastStateAndOpenAlarms &&
                object.properties.cctvWithLastStateAndOpenAlarms.cctv &&
                object.properties.cctvWithLastStateAndOpenAlarms.cctv.type
                  ? object.properties.cctvWithLastStateAndOpenAlarms.cctv.type
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {};
const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CamerasTooltip)
);
