import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { of } from "rxjs";
import { tap, take, catchError, concatMap } from "rxjs/operators";
import "./style.less";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
//model
import { PreferenceActivityModel } from "reducers/workspace/workspace.model";
//STORE
import {
  getUsername,
  getUserRoles,
  getWorkspaces,
  getCurrentWorkspace,
  getLayoutMap,
  getCurrentZoom,
  getBoundingBox,
  getListSelectedKpi,
  isNetworkRdsTmcVisible,
  isArcVisible,
  isNodeVisible,
  isCentroidVisible,
  areAllGraphVisible,
  isCityBusVisible,
  isRegionBusVisible,
  isActiveVisible,
  isPlannedVisible,
  isTypicalVisible,
  isNetStateVisible,
  areAllTrafficVisible,
  areAllEventVisible,
  areAllVisible,
  isAllCamerasVisible,
  isParkingVisible,
  isPmvVisible,
  isStationVisible,
  isMobilitaVisible,
  isAesysPanelVisible,
  isSolariPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
  getPinnedCameras,
  getAllActiveLines,
  isToggleSavingWorkspace,
} from "store";
import {
  setWorkspaces,
  setCurrentWorkspace,
} from "reducers/workspace/workspace.actions";
import { toggleSaveWorkspace } from "reducers/ui/table-menu/table-menu.actions";
//COMPONENTS
import WorkspaceVisualizationBox from "components/layout/map/workspace/workspace-visualization-box";
import WorkspaceCreationBox from "components/layout/map/workspace/workspace-creation-box";
import WorkspaceListBox from "components/layout/map/workspace/workspace-list-box";
//UTIL
import { createStringifiedPreferences } from "utils/utils-workspaces";
import * as FilterUtils from "utils/filter-utils";

class ExpandedWorkspace extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {
      isWorkSpaceBeingCreated: false,
      workspaceToEdit: null,
      newWorkspaceString: "",
    };
  }

  componentDidMount = () => {
    this.apiService = createApiService(this.context);
  };

  toggleCreation = () => {
    const { isWorkSpaceBeingCreated } = this.state;
    this.setState({
      ...this.state,
      isWorkSpaceBeingCreated: !isWorkSpaceBeingCreated,
      newWorkspaceString: "",
      workspaceToEdit: null,
    });
  };

  createWorkspace = () => {
    const { isWorkSpaceBeingCreated, newWorkspaceString } = this.state;
    const {
      username,
      userRoles,
      layoutMap,
      currentZoom,
      bbox,
      selectedKpi,
      isNetworkRdsTmcVisible,
      isArcVisible,
      isNodeVisible,
      isCentroidVisible,
      areAllGraphVisible,
      allTrasportoChecked,
      cityBusChecked,
      regionBusChecked,
      allEventChecked,
      activeChecked,
      plannedChecked,
      allTrafficChecked,
      tipicChecked,
      netStateChecked,
      isParkingVisible,
      isStationVisible,
      allCamerasVisible,
      isMobilitaVisible,
      isPmvVisible,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      pinnedCameras,
      activeLines,
      workspaces,
      setWorkspaces,
      setCurrentWorkspace,
      toggleSaveWorkspace,
    } = this.props;

    this.setState({
      ...this.state,
      isWorkSpaceBeingCreated: !isWorkSpaceBeingCreated,
    });

    let stringifiedPreferences = createStringifiedPreferences(
      newWorkspaceString,
      layoutMap,
      currentZoom,
      bbox,
      selectedKpi,
      isNetworkRdsTmcVisible,
      isArcVisible,
      isNodeVisible,
      isCentroidVisible,
      areAllGraphVisible,
      allTrasportoChecked,
      cityBusChecked,
      regionBusChecked,
      allEventChecked,
      activeChecked,
      plannedChecked,
      allTrafficChecked,
      tipicChecked,
      netStateChecked,
      isParkingVisible,
      isStationVisible,
      allCamerasVisible,
      isMobilitaVisible,
      isPmvVisible,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      pinnedCameras,
      activeLines
    );

    toggleSaveWorkspace(true);

    const body = {
      user: {
        username: username,
        role: userRoles ? userRoles.toString() : "",
      },
      isDefault: false,
      payload: {
        targetClass: newWorkspaceString,
        value: stringifiedPreferences,
      },
      details: {
        values: [{ value: "None", lang: "It" }],
      },
      category: {
        categoryName: "None",
        subCategoryName: "None",
      },
    };

    this.subscriptions$.push(
      this.apiService
        .saveWorkSpace(body)
        .pipe(
          take(1),
          tap((x) => {
            let newWorkspaces = [
              ...workspaces,
              PreferenceActivityModel.fromREST(x),
            ];
            setWorkspaces(newWorkspaces);
            //SET THIS AS CURRENT WORKSPACE
            setCurrentWorkspace(x);
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  deleteWorkspace = (event, wsToDelete) => {
    const {
      workspaces,
      setWorkspaces,
      currentWorkspace,
      setCurrentWorkspace,
    } = this.props;

    this.subscriptions$.push(
      this.apiService
        .deleteWorkSpace(wsToDelete.preferenceId)
        .pipe(
          take(1),
          tap((x) => {
            let filteredWorkspaces = workspaces.filter(
              (item) => item.preferenceId !== wsToDelete.preferenceId
            );
            setWorkspaces(filteredWorkspaces);
            if (wsToDelete.preferenceId === currentWorkspace.preferenceId) {
              setCurrentWorkspace(null);
            }
          }),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  updateWorkspace = () => {
    const {
      workspaces,
      setWorkspaces,
      layoutMap,
      currentZoom,
      bbox,
      selectedKpi,
      isNetworkRdsTmcVisible,
      isArcVisible,
      isNodeVisible,
      isCentroidVisible,
      areAllGraphVisible,
      allTrasportoChecked,
      cityBusChecked,
      regionBusChecked,
      allEventChecked,
      activeChecked,
      plannedChecked,
      allTrafficChecked,
      tipicChecked,
      netStateChecked,
      isParkingVisible,
      isStationVisible,
      allCamerasVisible,
      isMobilitaVisible,
      isPmvVisible,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      pinnedCameras,
      activeLines,
    } = this.props;
    const {
      workspaceToEdit,
      isWorkSpaceBeingCreated,
      newWorkspaceString,
    } = this.state;
    const id = workspaceToEdit.preferenceId;

    let stringifiedPreferences = createStringifiedPreferences(
      newWorkspaceString,
      layoutMap,
      currentZoom,
      bbox,
      selectedKpi,
      isNetworkRdsTmcVisible,
      isArcVisible,
      isNodeVisible,
      isCentroidVisible,
      areAllGraphVisible,
      allTrasportoChecked,
      cityBusChecked,
      regionBusChecked,
      allEventChecked,
      activeChecked,
      plannedChecked,
      allTrafficChecked,
      tipicChecked,
      netStateChecked,
      isParkingVisible,
      isStationVisible,
      allCamerasVisible,
      isMobilitaVisible,
      isPmvVisible,
      isSolariPanelVisible,
      isAesysPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      pinnedCameras,
      activeLines
    );

    const body = {
      ...workspaceToEdit,
      payload: {
        payloadId: workspaceToEdit.payload.payloadId,
        targetClass: newWorkspaceString,
        value: stringifiedPreferences,
      },
    };

    this.subscriptions$.push(
      this.apiService
        .updateWorkSpace(id, body)
        .pipe(
          take(1),
          tap((x) => {
            let workspacesListCopy = FilterUtils.deepCopyOfArraysOfObj(
              workspaces
            );
            workspacesListCopy.forEach((item, index, arr) => {
              if (item.preferenceId === x.preferenceId) {
                arr[index] = {
                  ...x,
                };
              }
            });
            setWorkspaces(workspacesListCopy);
            this.setState({
              ...this.state,
              isWorkSpaceBeingCreated: !isWorkSpaceBeingCreated,
              workspaceToEdit: null,
            });
          }),
          catchError((error) => {
            this.setState({
              ...this.state,
              isWorkSpaceBeingCreated: !isWorkSpaceBeingCreated,
              workspaceToEdit: null,
            });
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  markWorkspaceAsDefault = (event, wsToUpdate) => {
    const { workspaces, setWorkspaces, setCurrentWorkspace } = this.props;

    const body = {
      ...wsToUpdate,
      isDefault: !wsToUpdate.isDefault,
    };

    this.subscriptions$.push(
      this.apiService
        .updateWorkSpace(wsToUpdate.preferenceId, body)
        .pipe(
          take(1),
          tap((x) => {
            let workspacesListCopy = FilterUtils.deepCopyOfArraysOfObj(
              workspaces
            );
            workspacesListCopy.forEach((item, index, arr) => {
              if (item.preferenceId === x.preferenceId) {
                arr[index] = {
                  ...x,
                };
              }
            });
            setWorkspaces(workspacesListCopy);
            //SET THIS AS CURRENT WORKSPACE
            if (x.isDefault) {
              setCurrentWorkspace(x);
            } else {
              setCurrentWorkspace(null);
            }
          }),
          //REMOVE OTHER AS CURRENT WORKSPACES
          concatMap((data) => this.removeOtherWsAsDefault(data)),
          catchError((error) => {
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  removeOtherWsAsDefault = (newCurrent) => {
    const { workspaces, setWorkspaces } = this.props;

    let workspacesStillAsDefault = workspaces.filter(
      (item) => item.isDefault && item.preferenceId !== newCurrent.preferenceId
    );

    if (workspacesStillAsDefault.length > 0) {
      return of(...workspacesStillAsDefault).pipe(
        concatMap((ws) => {
          const newWs = {
            ...ws,
            isDefault: false,
          };
          return this.apiService.updateWorkSpace(ws.preferenceId, newWs);
        }),
        tap((x) => {
          let workspacesListCopy = FilterUtils.deepCopyOfArraysOfObj(
            workspaces
          );
          workspacesListCopy.forEach((item, index, arr) => {
            if (item.preferenceId === x.preferenceId) {
              arr[index] = {
                ...x,
              };
            }
          });
          setWorkspaces(workspacesListCopy);
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
    } else {
      return of([]);
    }
  };

  setCurrentWorkspaceFromList = (event, wsToUpdate) => {
    const { setCurrentWorkspace } = this.props;

    setCurrentWorkspace(wsToUpdate);
  };

  setUpdateMode = (event, wsToUpdate) => {
    let currentName = "";
    if (wsToUpdate.payload && wsToUpdate.payload.targetClass) {
      currentName = wsToUpdate.payload.targetClass;
    } else {
      currentName = "Nome Workspace";
    }

    this.setState({
      ...this.state,
      isWorkSpaceBeingCreated: true,
      workspaceToEdit: wsToUpdate,
      newWorkspaceString: currentName,
    });
  };

  handleWorkspaceNameChange = (e) => {
    this.setState({
      ...this.state,
      newWorkspaceString: e.target.value,
    });
  };

  render() {
    const {
      toggleWorkspaceDimensions,
      workspaces,
      currentWorkspace,
      isWsLoading,
      isToggleSavingWorkspace,
      toggleSaveWorkspace,
    } = this.props;
    const {
      isWorkSpaceBeingCreated,
      newWorkspaceString,
      workspaceToEdit,
    } = this.state;

    return (
      <div className="workspace-container-bottom uk-flex uk-flex-column">
        <div className="workspace-container ">
          <div className="uk-flex uk-flex-column uk-flex-center uk-height-1-1 uk-width-1-1 uk-card uk-card-primary">
            {!isWorkSpaceBeingCreated ? (
              <WorkspaceVisualizationBox
                toggleCreation={this.toggleCreation}
                toggleWorkspaceDimensions={toggleWorkspaceDimensions}
              />
            ) : (
              <WorkspaceCreationBox
                createWorkspace={this.createWorkspace}
                updateWorkspace={this.updateWorkspace}
                toggleCreation={this.toggleCreation}
                handleWorkspaceNameChange={this.handleWorkspaceNameChange}
                newWorkspaceString={newWorkspaceString}
                toggleWorkspaceDimensions={toggleWorkspaceDimensions}
                workspaceToEdit={workspaceToEdit}
                toggleSaveWorkspace={toggleSaveWorkspace}
                isToggleSavingWorkspace={isToggleSavingWorkspace}
              />
            )}
          </div>
        </div>
        <WorkspaceListBox
          workspaces={workspaces}
          isWsLoading={isWsLoading}
          deleteWorkspace={this.deleteWorkspace}
          setUpdateMode={this.setUpdateMode}
          markWorkspaceAsDefault={this.markWorkspaceAsDefault}
          currentWorkspace={currentWorkspace}
          setCurrentWorkspaceFromList={this.setCurrentWorkspaceFromList}
          toggleSaveWorkspace={toggleSaveWorkspace}
          isToggleSavingWorkspace={isToggleSavingWorkspace}
        />
      </div>
    );
  }

  componentWillUnmount = () => {
    this.subscriptions$.forEach((sub$) => sub$.unsubscribe());
  };
}

ExpandedWorkspace.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setWorkspaces,
  setCurrentWorkspace,
  toggleSaveWorkspace,
};

const mapStateToProps = (state) => ({
  workspaces: getWorkspaces(state),
  currentWorkspace: getCurrentWorkspace(state),
  username: getUsername(state),
  userRoles: getUserRoles(state),
  layoutMap: getLayoutMap(state),
  currentZoom: getCurrentZoom(state),
  bbox: getBoundingBox(state),
  selectedKpi: getListSelectedKpi(state),
  isNetworkRdsTmcVisible: isNetworkRdsTmcVisible(state),
  isArcVisible: isArcVisible(state),
  isNodeVisible: isNodeVisible(state),
  isCentroidVisible: isCentroidVisible(state),
  areAllGraphVisible: areAllGraphVisible(state),
  allTrasportoChecked: areAllVisible(state),
  cityBusChecked: isCityBusVisible(state),
  regionBusChecked: isRegionBusVisible(state),
  allEventChecked: areAllEventVisible(state),
  activeChecked: isActiveVisible(state),
  plannedChecked: isPlannedVisible(state),
  allTrafficChecked: areAllTrafficVisible(state),
  tipicChecked: isTypicalVisible(state),
  netStateChecked: isNetStateVisible(state),
  isParkingVisible: isParkingVisible(state),
  isStationVisible: isStationVisible(state),
  allCamerasVisible: isAllCamerasVisible(state),
  isMobilitaVisible: isMobilitaVisible(state),
  isPmvVisible: isPmvVisible(state),
  isSolariPanelVisible: isSolariPanelVisible(state),
  isAesysPanelVisible: isAesysPanelVisible(state),
  isFuturitPanelVisible: isFuturitPanelVisible(state),
  isVisuallabPanelVisible: isVisuallabPanelVisible(state),
  isSfheraPanelVisible: isSfheraPanelVisible(state),
  pinnedCameras: getPinnedCameras(state),
  activeLines: getAllActiveLines(state),
  isToggleSavingWorkspace: isToggleSavingWorkspace(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExpandedWorkspace)
);
