import { combineReducers } from "redux";
import * as navTabReducer from "./nav-tab/nav-tab.reducer";
import * as bottombarReducer from "./bottombar/bottombar.reducer";
import * as topbarReducer from "./topbar/topbar.reducer";
import * as modalReducer from "./modal/modal.reducer";
import * as tplMenuReducer from "./tpl-menu/tpl-menu.reducer";
import * as trafficMenuReducer from "./traffic-menu/traffic-menu.reducer";
import * as eventMenuReducer from "./event-menu/event-menu.reducer";
import * as installationsMenuReducer from "./installations-menu/installations-menu.reducer";
import * as navReducer from "./nav/nav.reducer";
import * as graphReducer from "./graph-menu/graph-menu.reducer";
import * as wsReducer from "./ws/ws.reducer";
import * as tableMenuReducer from "./table-menu/table-menu.reducer";

export default combineReducers({
  navTab: navTabReducer.default,
  bottombar: bottombarReducer.default,
  modal: modalReducer.default,
  topbar: topbarReducer.default,
  tplMenu: tplMenuReducer.default,
  trafficMenu: trafficMenuReducer.default,
  eventMenu: eventMenuReducer.default,
  installationsMenu: installationsMenuReducer.default,
  nav: navReducer.default,
  graph: graphReducer.default,
  ws: wsReducer.default,
  tableMenu: tableMenuReducer.default,
});

export const isOpenNavTab = (state) => navTabReducer.isOpenNavTab(state.navTab);
export const getNavTabSelectedTab = (state) =>
  navTabReducer.getNavTabSelectedTab(state.navTab);
export const getNavTabInfoRoute = (state) =>
  navTabReducer.getNavTabInfoRoute(state.navTab);
export const getNavTabSearch = (state) =>
  navTabReducer.getNavTabSearch(state.navTab);
export const getSelectedMultitab = (state) =>
  navTabReducer.getSelectedMultitab(state.navTab);

export const getBottombarTabs = (state) =>
  bottombarReducer.getBottombarTabs(state.bottombar);
export const getBottombarActiveTab = (state) =>
  bottombarReducer.getBottombarActiveTab(state.bottombar);
export const isBottombarExpanded = (state) =>
  bottombarReducer.isBottombarExpanded(state.bottombar);
export const isBottombarReduced = (state) =>
  bottombarReducer.isBottombarReduced(state.bottombar);

export const isKpiOpen = (state) => topbarReducer.isKpiOpen(state.topbar);
export const openKpiDown = (state) => topbarReducer.openKpiDown(state.topbar);
export const getListSelectedKpi = (state) =>
  topbarReducer.getListSelectedKpi(state.topbar);
export const getListAllKpi = (state) =>
  topbarReducer.getListAllKpi(state.topbar);

export const getTabModal = (state) => modalReducer.getTabModal(state.modal);
export const getTypeModal = (state) => modalReducer.getTypeModal(state.modal);
export const getTypeLocalization = (state) =>
  modalReducer.getTypeLocalization(state.modal);
export const isOpenPopup = (state) => modalReducer.isOpenPopup(state.modal);
export const isOpenModal = (state) => modalReducer.isOpenModal(state.modal);
export const getZoomLocalization = (state) =>
  modalReducer.getZoomLocalization(state.modal);
export const isApiOnError = (state) => modalReducer.isApiOnError(state.modal);

export const getInsertEventButtonDisabled = (state) =>
  modalReducer.getInsertEventButtonDisabled(state.modal);
export const getForwardEventButtonDisabled = (state) =>
  modalReducer.getForwardEventButtonDisabled(state.modal);

export const areAllTrafficVisible = (state) =>
  trafficMenuReducer.areAllTrafficVisible(state.trafficMenu);
export const isTypicalVisible = (state) =>
  trafficMenuReducer.isTypicalVisible(state.trafficMenu);
export const isNetStateVisible = (state) =>
  trafficMenuReducer.isNetStateVisible(state.trafficMenu);
export const isTimeSliderVisible = (state) =>
  trafficMenuReducer.isTimeSliderVisible(state.trafficMenu);
export const isTrafficLoading = (state) =>
  trafficMenuReducer.isTrafficLoading(state.trafficMenu);

export const areAllVisible = (state) =>
  tplMenuReducer.areAllVisible(state.tplMenu);
export const isCityBusVisible = (state) =>
  tplMenuReducer.isCityBusVisible(state.tplMenu);
export const isRegionBusVisible = (state) =>
  tplMenuReducer.isRegionBusVisible(state.tplMenu);

export const isLinesLoading = (state) =>
  tplMenuReducer.isLinesLoading(state.tplMenu);
export const isTripsLoading = (state) =>
  tplMenuReducer.isTripsLoading(state.tplMenu);
export const isTripsAllLoading = (state) =>
  tplMenuReducer.isTripsAllLoading(state.tplMenu);
export const isStopsLoading = (state) =>
  tplMenuReducer.isStopsLoading(state.tplMenu);

export const areAllEventVisible = (state) =>
  eventMenuReducer.areAllEventVisible(state.eventMenu);
export const isActiveVisible = (state) =>
  eventMenuReducer.isActiveVisible(state.eventMenu);
export const isPlannedVisible = (state) =>
  eventMenuReducer.isPlannedVisible(state.eventMenu);
export const getIsReportDetailOpen = (state) =>
  eventMenuReducer.isReportDetailOpen(state.eventMenu);
export const getIsHistoryDetailOpen = (state) =>
  eventMenuReducer.isHistoryDetailOpen(state.eventMenu);

export const isEventsLoading = (state) =>
  eventMenuReducer.isEventsLoading(state.eventMenu);
export const isEventsAllLoading = (state) =>
  eventMenuReducer.isEventsAllLoading(state.eventMenu);

export const isStationLoading = (state) =>
  installationsMenuReducer.isStationLoading(state.installationsMenu);
export const isPmvLoading = (state) =>
  installationsMenuReducer.isPmvLoading(state.installationsMenu);
export const isCctvLoading = (state) =>
  installationsMenuReducer.isCctvLoading(state.installationsMenu);
export const isParkingLoading = (state) =>
  installationsMenuReducer.isParkingLoading(state.installationsMenu);

//PARKING
export const isParkingVisible = (state) =>
  installationsMenuReducer.isParkingVisible(state.installationsMenu);
//STATION
export const isStationVisible = (state) =>
  installationsMenuReducer.isStationVisible(state.installationsMenu);
//PMV
export const isPmvVisible = (state) =>
  installationsMenuReducer.isPmvVisible(state.installationsMenu);
export const isAesysPanelVisible = (state) =>
  installationsMenuReducer.isAesysPanelVisible(state.installationsMenu);
export const isSolariPanelVisible = (state) =>
  installationsMenuReducer.isSolariPanelVisible(state.installationsMenu);
export const isFuturitPanelVisible = (state) =>
  installationsMenuReducer.isFuturitPanelVisible(state.installationsMenu);
export const isVisuallabPanelVisible = (state) =>
  installationsMenuReducer.isVisuallabPanelVisible(state.installationsMenu);
export const isSfheraPanelVisible = (state) =>
  installationsMenuReducer.isSfheraPanelVisible(state.installationsMenu);
export const getPmvHistoryTableOpen = (state) =>
  installationsMenuReducer.getPmvHistoryTableOpen(state.installationsMenu);

//CAMERAS
export const isAllCamerasVisible = (state) =>
  installationsMenuReducer.getIsAllCamerasVisible(state.installationsMenu);
export const isMobilitaVisible = (state) =>
  installationsMenuReducer.getIsCamerasMobilitaVisible(state.installationsMenu);
export const isAlarmTableOpen = (state) =>
  installationsMenuReducer.isAlarmTableOpen(state.installationsMenu);

export const isOpenDropdownNetwork = (state) =>
  navReducer.isOpenDropdownNetwork(state.nav);
export const isOpenNavGraph = (state) => navReducer.isOpenNavGraph(state.nav);
export const isOpenNavNetworkRdsTmc = (state) =>
  navReducer.isOpenNavNetworkRdsTmc(state.nav);
export const isOpenNavEvents = (state) => navReducer.isOpenNavEvents(state.nav);
export const isOpenNavTPL = (state) => navReducer.isOpenNavTPL(state.nav);
export const isOpenNavTraffic = (state) =>
  navReducer.isOpenNavTraffic(state.nav);
export const isOpenNavStation = (state) =>
  navReducer.isOpenNavStation(state.nav);
export const isOpenNavWebcams = (state) =>
  navReducer.isOpenNavWebcams(state.nav);
export const isOpenNavPmv = (state) => navReducer.isOpenNavPmv(state.nav);

export const areAllGraphVisible = (state) =>
  graphReducer.areAllGraphVisible(state.graph);
export const isArcVisible = (state) => graphReducer.isArcVisible(state.graph);
export const isNodeVisible = (state) => graphReducer.isNodeVisible(state.graph);
export const isCentroidVisible = (state) =>
  graphReducer.isCentroidVisible(state.graph);

export const isNetworkRdsTmcVisible = (state) =>
  graphReducer.isNetworkRdsTmcVisible(state.graph);

export const isArcsLoading = (state) => graphReducer.isArcsLoading(state.graph);
export const isNodesLoading = (state) =>
  graphReducer.isNodesLoading(state.graph);
export const isCentroidsLoading = (state) =>
  graphReducer.isCentroidsLoading(state.graph);
export const isPointsLoading = (state) =>
  graphReducer.isPointsLoading(state.graph);
export const isSegmentsLoading = (state) =>
  graphReducer.isSegmentsLoading(state.graph);

export const isWSTplOpen = (state) => wsReducer.isWSTplOpen(state.ws);
export const isWSTplLocationOpen = (state) =>
  wsReducer.isWSTplLocationOpen(state.ws);
export const isWSEventsOpen = (state) => wsReducer.isWSEventsOpen(state.ws);
export const isWSStationStatusOpen = (state) =>
  wsReducer.isWSStationStatusOpen(state.ws);
export const isWSStationMeasuresOpen = (state) =>
  wsReducer.isWSStationMeasuresOpen(state.ws);
export const isWSStationNotificationOpen = (state) =>
  wsReducer.isWSStationNotificationOpen(state.ws);
export const isWSParkingsOpen = (state) => wsReducer.isWSParkingsOpen(state.ws);
export const isWSCamerasStateOpen = (state) =>
  wsReducer.isWSCamerasStateOpen(state.ws);
export const isWSCamerasAlarmsOpen = (state) =>
  wsReducer.isWSCamerasAlarmsOpen(state.ws);

//GENERIC TABLE
export const isGenericTableToggled = (state) =>
  tableMenuReducer.isGenericTableToggled(state.tableMenu);
export const typeOfToggledTable = (state) =>
  tableMenuReducer.typeOfToggledTable(state.tableMenu);
export const isToggleSavingWorkspace = (state) =>
  tableMenuReducer.isToggleSavingWorkspace(state.tableMenu);
