import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Permission from "components/shared/permission";
import {
  areAllEventVisible,
  getPermissions,
  isActiveVisible,
  isPlannedVisible,
  isOpenNavEvents,
} from "store";

const mapStateToProps = (state) => ({
  allEventChecked: areAllEventVisible(state),
  permissions: getPermissions(state),
  activeChecked: isActiveVisible(state),
  plannedChecked: isPlannedVisible(state),
  isOpenNavEvents: isOpenNavEvents(state),
});

const mapDispatchToProps = {};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NavItemMobilityEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  event = () => {
    const {
      allEventChecked,
      activeChecked,
      plannedChecked,
      isOpenNavEvents,
      clickOnEventiLabel,
      clickOnEventiCheckbox,
    } = this.props;

    return (
      <Permission actions="EVENT">
        <div
          className={
            isOpenNavEvents
              ? "uk-checkbox-title uk-open acr-event-open"
              : "uk-checkbox-title"
          }
        >
          <span>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={allEventChecked || false}
              onChange={() => clickOnEventiCheckbox("all")}
            />
          </span>
          <label onClick={() => clickOnEventiLabel()}>Eventi</label>
        </div>

        <div className="uk-checkbox-tree-sub">
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={activeChecked || false}
              onChange={() => clickOnEventiCheckbox("active")}
            />
            Attivi
          </label>
          <label>
            <input
              className="uk-checkbox uk-checkbox-large"
              type="checkbox"
              checked={plannedChecked || false}
              onChange={() => clickOnEventiCheckbox("planned")}
            />
            Pianificati
          </label>
        </div>
      </Permission>
    );
  };

  render() {
    return this.event();
  }
}

export default NavItemMobilityEvent;
