import { PointCoordinates } from "../../events/models/situation-record";
import { geometryGeoJson } from "../../graph/graph-features.models";

export const INITIAL_STATE_PARKINGS = {
  parkings: [],
  parkingsFiltered: [],
  current: null,
  searchText: "",
  searchType: "",
  wsLastParking: [],
};

export default class ParkingRecordModel {
  parkingTableName;
  parkingRecordId;
  parkingUsageScenario;
  parkingName;
  parkingDescription;
  responsibleAuthorityName;
  parkingAddress;
  parkingCoordinates;
  parkingSiteType1;
  parkingTypeSite2;
  parkingPayment;
  parkingLayout;
  parkingNumberOfSpaces;

  constructor() {
    this.parkingTableName = null;
    this.parkingRecordId = null;
    this.parkingUsageScenario = null;
    this.parkingName = null;
    this.parkingDescription = null;
    this.responsibleAuthorityName = null;
    this.parkingAddress = null;
    this.parkingCoordinates = PointCoordinates;
    this.parkingSiteType1 = null;
    this.parkingTypeSite2 = null;
    this.parkingPayment = null;
    this.parkingLayout = null;
    this.parkingNumberOfSpaces = null;
  }

  static from(obj) {
    let parking = new ParkingRecordModel();
    if (obj.parkingTableName) parking.parkingTableName = obj.parkingTableName;
    if (obj.parkingRecordId) parking.parkingRecordId = obj.parkingRecordId;
    if (obj.parkingUsageScenario)
      parking.parkingUsageScenario = obj.parkingUsageScenario;
    if (obj.parkingName) parking.parkingName = obj.parkingName;
    if (obj.parkingDescription)
      parking.parkingDescription = obj.parkingDescription;
    if (obj.responsibleAuthorityName)
      parking.responsibleAuthorityName = obj.responsibleAuthorityName;
    if (obj.parkingAddress) parking.parkingAddress = obj.parkingAddress;
    if (obj.parkingCoordinates)
      parking.parkingCoordinates = PointCoordinates.from(
        obj.parkingCoordinates
      );
    if (obj.parkingSiteType1) parking.parkingSiteType1 = obj.parkingSiteType1;
    if (obj.parkingTypeSite2) {
      parking.parkingTypeSite2 = obj.parkingTypeSite2;
    }
    if (obj.parkingPayment) parking.parkingPayment = obj.parkingPayment;
    if (obj.parkingLayout) parking.parkingLayout = obj.parkingLayout;
    if (obj.parkingNumberOfSpaces)
      parking.parkingNumberOfSpaces = obj.parkingNumberOfSpaces;
    return parking;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }

    return data.map(ParkingRecordModel.from).filter((item) => item !== null);
  }
}

export class ParkingRecordStatusModel {
  parkingRecordId;
  latestParkingUpdate;
  parkingNumberOfSpacesOverride;
  parkingNumberOfVacantSpaces;
  parkingNumberOfOccupiedSpaces;
  parkingOccupancy;
  parkingOperationalStatus;
  parkingOpeningStatus;
  parkingSiteStatus;

  constructor() {
    this.parkingRecordId = null;
    this.latestParkingUpdate = null;
    this.parkingNumberOfSpacesOverride = null;
    this.parkingNumberOfVacantSpaces = null;
    this.parkingNumberOfOccupiedSpaces = null;
    this.parkingOccupancy = null;
    this.parkingOperationalStatus = null;
    this.parkingOpeningStatus = null;
    this.parkingSiteStatus = null;
  }
  static from(obj) {
    let parking = new ParkingRecordStatusModel();
    if (obj.parkingRecordId) parking.parkingRecordId = obj.parkingRecordId;
    if (obj.latestParkingUpdate)
      parking.latestParkingUpdate = obj.latestParkingUpdate;
    if (
      obj.parkingNumberOfSpacesOverride ||
      typeof obj.parkingNumberOfSpacesOverride === "number"
    )
      parking.parkingNumberOfSpacesOverride = obj.parkingNumberOfSpacesOverride;
    if (
      obj.parkingNumberOfVacantSpaces ||
      typeof obj.parkingNumberOfVacantSpaces === "number"
    )
      parking.parkingNumberOfVacantSpaces = obj.parkingNumberOfVacantSpaces;
    if (
      obj.parkingNumberOfOccupiedSpaces ||
      typeof obj.parkingNumberOfOccupiedSpaces === "number"
    )
      parking.parkingNumberOfOccupiedSpaces = obj.parkingNumberOfOccupiedSpaces;
    if (obj.parkingOccupancy || typeof obj.parkingOccupancy === "number")
      parking.parkingOccupancy = obj.parkingOccupancy;
    if (obj.parkingOperationalStatus)
      parking.parkingOperationalStatus = obj.parkingOperationalStatus;
    if (obj.parkingOpeningStatus)
      parking.parkingOpeningStatus = obj.parkingOpeningStatus;
    if (obj.parkingSiteStatus) {
      parking.parkingSiteStatus = obj.parkingSiteStatus;
    }
    return parking;
  }
  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(ParkingRecordStatusModel.from)
      .filter((item) => item !== null);
  }
}

export class ParkingRecordWithLatestStatusModel {
  //ANAGRAFICA
  parkingTableName;
  parkingRecordId;
  parkingUsageScenario;
  parkingName;
  parkingDescription;
  responsibleAuthorityName;
  parkingAddress;
  parkingCoordinates;
  parkingSiteType1;
  parkingTypeSite2;
  parkingPayment;
  parkingLayout;
  parkingNumberOfSpaces;
  //STATUS
  latestParkingUpdate;
  parkingNumberOfSpacesOverride;
  parkingNumberOfVacantSpaces;
  parkingNumberOfOccupiedSpaces;
  parkingOccupancy;
  parkingOperationalStatus;
  parkingOpeningStatus;
  parkingSiteStatus;

  constructor() {
    this.parkingTableName = null;
    this.parkingRecordId = null;
    this.parkingUsageScenario = null;
    this.parkingName = null;
    this.parkingDescription = null;
    this.responsibleAuthorityName = null;
    this.parkingAddress = null;
    this.parkingCoordinates = PointCoordinates;
    this.parkingSiteType1 = null;
    this.parkingTypeSite2 = null;
    this.parkingPayment = null;
    this.parkingLayout = null;
    this.parkingNumberOfSpaces = null;
    //
    this.latestParkingUpdate = null;
    this.parkingNumberOfSpacesOverride = null;
    this.parkingNumberOfVacantSpaces = null;
    this.parkingNumberOfOccupiedSpaces = null;
    this.parkingOccupancy = null;
    this.parkingOperationalStatus = null;
    this.parkingOpeningStatus = null;
    this.parkingSiteStatus = null;
  }

  static from(obj) {
    let parking = new ParkingRecordWithLatestStatusModel();
    if (obj.parkingTableName) parking.parkingTableName = obj.parkingTableName;
    if (obj.parkingRecordId) parking.parkingRecordId = obj.parkingRecordId;
    if (obj.parkingUsageScenario)
      parking.parkingUsageScenario = obj.parkingUsageScenario;
    if (obj.parkingName) parking.parkingName = obj.parkingName;
    if (obj.parkingDescription)
      parking.parkingDescription = obj.parkingDescription;
    if (obj.responsibleAuthorityName)
      parking.responsibleAuthorityName = obj.responsibleAuthorityName;
    if (obj.parkingAddress) parking.parkingAddress = obj.parkingAddress;
    if (obj.parkingCoordinates)
      parking.parkingCoordinates = PointCoordinates.from(
        obj.parkingCoordinates
      );
    if (obj.parkingSiteType1) parking.parkingSiteType1 = obj.parkingSiteType1;
    if (obj.parkingTypeSite2) {
      parking.parkingTypeSite2 = obj.parkingTypeSite2;
    }
    if (obj.parkingPayment) parking.parkingPayment = obj.parkingPayment;
    if (obj.parkingLayout) parking.parkingLayout = obj.parkingLayout;
    if (obj.parkingNumberOfSpaces)
      parking.parkingNumberOfSpaces = obj.parkingNumberOfSpaces;
    //
    if (obj.latestParkingUpdate)
      parking.latestParkingUpdate = obj.latestParkingUpdate;
    if (obj.parkingNumberOfSpacesOverride)
      parking.parkingNumberOfSpacesOverride = obj.parkingNumberOfSpacesOverride;
    if (obj.parkingNumberOfVacantSpaces)
      parking.parkingNumberOfVacantSpaces = obj.parkingNumberOfVacantSpaces;
    if (obj.parkingNumberOfOccupiedSpaces)
      parking.parkingNumberOfOccupiedSpaces = obj.parkingNumberOfOccupiedSpaces;
    if (obj.parkingOccupancy) parking.parkingOccupancy = obj.parkingOccupancy;
    if (obj.parkingOperationalStatus)
      parking.parkingOperationalStatus = obj.parkingOperationalStatus;
    if (obj.parkingOpeningStatus)
      parking.parkingOpeningStatus = obj.parkingOpeningStatus;
    if (obj.parkingSiteStatus) {
      parking.parkingSiteStatus = obj.parkingSiteStatus;
    }
    return parking;
  }

  static fromArray(data) {
    if (!data || !(data instanceof Array)) {
      return [];
    }
    return data
      .map(ParkingRecordWithLatestStatusModel.from)
      .filter((item) => item !== null);
  }
}

export class ParkingFeatureCollectionModel {
  type;
  features;
  totalFeatures;
  numberMatched;
  numberReturned;
  timestamp;

  constructor() {
    this.type = null;
    this.features = null;
    this.totalFeatures = null;
    this.numberMatched = null;
    this.numberReturned = null;
    this.timestamp = null;
  }

  static fromREST(obj) {
    var collection = new ParkingFeatureCollectionModel();
    collection.type = obj.type ? obj.type : null;
    collection.features = obj.features
      ? ParkingFeaturesModel.fromArrayREST(obj.features)
      : [];
    collection.totalFeatures = obj.totalFeatures ? obj.totalFeatures : null;
    collection.numberMatched = obj.numberMatched ? obj.numberMatched : null;
    collection.numberReturned = obj.numberReturned ? obj.numberReturned : null;
    collection.timestamp = obj.timestamp ? obj.timestamp : null;
    return collection;
  }

  static fromArrayREST(data) {
    return data
      .map(ParkingFeatureCollectionModel.fromREST)
      .filter((item) => item !== null);
  }
}

export class ParkingFeaturesModel {
  type;
  id;
  geometry;
  properties;

  constructor() {
    this.type = null;
    this.id = null;
    this.geometry = null;
    this.properties = null;
  }

  static fromREST(obj) {
    let features = new ParkingFeaturesModel();
    features.type = obj.type ? obj.type : null;
    features.id = obj.id ? obj.id : null;
    features.geometry = obj.geometry
      ? geometryGeoJson.fromREST(obj.geometry)
      : null;
    features.selected = obj.selected ? obj.selected : false;
    features.properties = ParkingRecordWithLatestStatusModel.from(
      obj.properties
    )
      ? obj.properties
      : null;
    return features;
  }
  static fromArrayREST(data) {
    return data
      .map(ParkingFeaturesModel.fromREST)
      .filter((item) => item !== null);
  }
}
