import React, { Component } from "react";
import { AuthContext } from "@almaviva/ljsa-react-module";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import createApiService from "services/api.service";
import { tap, catchError, take, concatMap, retryWhen } from "rxjs/operators";
import { of } from "rxjs";
//STORE AND REDUCERS
import { toggleDropdownNetwork } from "reducers/ui/nav/nav.actions";
import { setUsername, setUserRoles } from "reducers/user/user.actions";
import { getUsername, getUserRoles } from "store";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import { addActivity } from "reducers/user/user.actions";
//COMPONENT
import Permission from "components/shared/permission";
//UTIL
import { genericRetryStrategy } from "utils/utils";
class NavItemUser extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setUsername } = this.props;
    this.context.getUser().then((user) => {
      let uname = user.profile.sub;
      if (uname.indexOf("@") > -1) {
        let splitted = uname.split("@");
        uname = splitted[0];
      }
      setUsername(uname);
      this.loadEnvironment(uname);
    });
  }

  loadEnvironment(uname) {
    const { setUserRoles } = this.props;

    fetch("/config.json")
      .then((res) => res.json())
      .then((environment) => {
        this.apiService = createApiService(environment);
        if (uname) {
          // let roles = [];
          this.apiService
            .getUserRolesByUsername(uname)
            .pipe(
              tap((data) => {
                setUserRoles([...data]);
              }),
              concatMap((data) => {
                return this.postLoginActivity(data, environment);
              }),
              retryWhen(genericRetryStrategy()),
              catchError((error) => {
                setUserRoles([]);
                console.error(error);
                return of(error);
              })
            )
            .subscribe();
        }
      });
  }

  postLoginActivity = (roles, environment) => {
    const { username } = this.props;
    this.apiService = createApiService(environment);

    return this.apiService
      .postUserActivity({
        module: "NOT_SPECIFIED",
        user: {
          username: username,
          role: roles.toString(),
        },
        activityType: "USER_LOGIN",
        actionType: "MANUAL",
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  };

  logout = () => {
    this.context.logout();
    this.postLogoutActivity();
  };

  postLogoutActivity = () => {
    const { username, userRoles } = this.props;

    this.apiService
      .postUserActivity({
        module: "NOT_SPECIFIED",
        user: {
          username: username,
          role: userRoles ? userRoles.toString() : "",
        },
        activityType: "USER_LOGOUT",
        actionType: "MANUAL",
        groupOfActivitiesReference: null,
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  };

  chooseNetwork = () => {
    const { toggleDropdownNetwork } = this.props;
    toggleDropdownNetwork();
  };

  toggleReportUserActivity = () => {
    const { toggleGenericTable } = this.props;
    toggleGenericTable("userActivityTable");
  };

  render() {
    const { username } = this.props;

    return (
      <li className="uk-nav-user uk-flex uk-flex-middle uk-flex-between">
        <span
          onClick={(e) => {
            e.preventDefault();
            this.chooseNetwork();
          }}
          uk-icon="icon: acr-menu-profile"
          className="uk-icon; ratio: 1.4; minWidth80 cursor-pointer"
        ></span>
        <span className="uk-margin-large-right username-max-width">
          {username}
        </span>
        <div className="uk-flex uk-flex-row">
          <Permission actions="ACTIVITY_LOG">
            <span
              className="uk-icon-link uk-icon cursor-pointer right-icon-margin"
              uk-icon="icon:  file-text"
              uk-tooltip="Report attività utente"
              onClick={this.toggleReportUserActivity}
            ></span>
          </Permission>
          <span
            className="uk-icon-link uk-icon cursor-pointer right-icon-margin "
            uk-icon="icon: sign-out"
            uk-tooltip="Log out"
            onClick={this.logout}
          ></span>
        </div>
      </li>
    );
  }
}

NavItemUser.contextType = AuthContext;

const mapDispatchToProps = {
  setUsername,
  toggleDropdownNetwork,
  toggleGenericTable,
  addActivity,
  setUserRoles,
};

const mapStateToProps = (state) => ({
  username: getUsername(state),
  userRoles: getUserRoles(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavItemUser)
);
