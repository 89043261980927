import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";

//STORE AND ACTIONS
import {
  getBoundingBox,
  isOpenNavTab,
  getNavTabSearch,
  getNavTabSelectedTab,
  isPmvVisible,
  isAesysPanelVisible,
  isSolariPanelVisible,
  isFuturitPanelVisible,
  isVisuallabPanelVisible,
  isSfheraPanelVisible,
} from "store";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { PmvFeatureCollectionModel } from "reducers/installations/pmv/pmv.model";
import { setPmvList } from "reducers/installations/pmv/pmv.actions";
import { setIsPmvLoading } from "reducers/ui/installations-menu/installations-menu.actions";
//UTILS
import { getBoundingBoxFilter } from "components/pages/load.data/map.utils";
import { getCheckedTypesArray } from "utils/utils-pmv";
class LoadPmvData extends Component {
  subscriptions$ = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      boundingBox,
      setPmvList,
      isPmvVisible,
      isAesysPanelVisible,
      isSolariPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      setIsPmvLoading,
    } = this.props;

    this.apiService = createApiService(this.context);

    if (
      isPmvVisible ||
      isAesysPanelVisible ||
      isSolariPanelVisible ||
      isFuturitPanelVisible ||
      isVisuallabPanelVisible ||
      isSfheraPanelVisible
    ) {
      setIsPmvLoading(true);
      const bbox = getBoundingBoxFilter(boundingBox);
      const jwt = sessionStorage.getItem("id_token");
      const tipologia = getCheckedTypesArray(
        isAesysPanelVisible,
        isSolariPanelVisible,
        isFuturitPanelVisible,
        isVisuallabPanelVisible,
        isSfheraPanelVisible
      );
      this.subscriptions$.push(
        this.apiService
          .getPmvListByBbox(bbox, jwt, tipologia)
          .pipe(
            tap((data) => {
              setIsPmvLoading(false);
              let featureCollection = PmvFeatureCollectionModel.fromREST(data);
              let pmvs = [];
              if (featureCollection.features) {
                pmvs = [...featureCollection.features];
                setPmvList(pmvs);
              }
            }),
            catchError((error) => {
              setIsPmvLoading(false);
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      boundingBox,
      setPmvList,
      isPmvVisible,
      isAesysPanelVisible,
      isSolariPanelVisible,
      isFuturitPanelVisible,
      isVisuallabPanelVisible,
      isSfheraPanelVisible,
      setIsPmvLoading,
    } = this.props;

    if (
      (prevProps.isPmvVisible !== isPmvVisible ||
        prevProps.isAesysPanelVisible !== isAesysPanelVisible ||
        prevProps.isSolariPanelVisible !== isSolariPanelVisible ||
        prevProps.isFuturitPanelVisible !== isFuturitPanelVisible ||
        prevProps.isVisuallabPanelVisible !== isVisuallabPanelVisible ||
        prevProps.isSfheraPanelVisible !== isSfheraPanelVisible ||
        prevProps.boundingBox !== boundingBox) &&
      (isPmvVisible ||
        isAesysPanelVisible ||
        isSolariPanelVisible ||
        isFuturitPanelVisible ||
        isSfheraPanelVisible ||
        isVisuallabPanelVisible) &&
      boundingBox
    ) {
      if (prevProps.boundingBox === boundingBox) {
        setIsPmvLoading(true);
      }
      const bbox = getBoundingBoxFilter(boundingBox);
      const jwt = sessionStorage.getItem("id_token");
      const tipologia = getCheckedTypesArray(
        isAesysPanelVisible,
        isSolariPanelVisible,
        isFuturitPanelVisible,
        isVisuallabPanelVisible,
        isSfheraPanelVisible
      );

      this.subscriptions$.push(
        this.apiService
          .getPmvListByBbox(bbox, jwt, tipologia)
          .pipe(
            tap((data) => {
              setIsPmvLoading(false);
              let featureCollection = PmvFeatureCollectionModel.fromREST(data);
              let pmvs = [];
              if (featureCollection.features) {
                pmvs = [...featureCollection.features];
                setPmvList(pmvs);
              }
            }),
            catchError((error) => {
              setIsPmvLoading(false);
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  render() {
    return <div></div>;
  }

  componentWillUnmount() {
    this.subscriptions$.forEach((x) => x.unsubscribe());
  }
}

LoadPmvData.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSelectedTab,
  setNavTabSearch,
  toggleOpenNavTab,
  setPmvList,
  setIsPmvLoading,
};

const mapStateToProps = (state) => ({
  //UI
  menuNavTabSearch: getNavTabSearch(state),
  menuTabReducer: getNavTabSelectedTab(state),
  isOpenNavTab: isOpenNavTab(state),
  isPmvVisible: isPmvVisible(state),
  isSolariPanelVisible: isSolariPanelVisible(state),
  isAesysPanelVisible: isAesysPanelVisible(state),
  isFuturitPanelVisible: isFuturitPanelVisible(state),
  isVisuallabPanelVisible: isVisuallabPanelVisible(state),
  isSfheraPanelVisible: isSfheraPanelVisible(state),
  //BBOX
  boundingBox: getBoundingBox(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadPmvData)
);
