import { CentroidsActions } from "./centroids.actions";
import { NavTabActions } from "reducers/ui/nav-tab/nav-tab.actions";

const INITIAL_STATE = {
  centroids: [],
  centroidsFiltered: [],
  current: null,
  currentMap: null,
  searchText: "",
  searchType: "",
  bounds: [],
};

function searchCentroids(searchText) {
  return (centr) =>
    (centr &&
      centr.id &&
      centr.id.toString().toLowerCase().includes(searchText)) ||
    (centr &&
      centr.properties.name &&
      centr.properties.name.toLowerCase().includes(searchText));
}

export default function centroidsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CentroidsActions.SET_LIST_CENTROIDS:
      let centroidsFiltered = action.payload.list;
      //FILTER THE SEARCH IF SOMETHING IS WRITTEN IN SEARCHBAR
      if (
        state.searchText &&
        state.searchText !== "" &&
        state.searchType === "centroidi"
      ) {
        let searchText = state.searchText.toLowerCase();
        const searchFn = searchCentroids(searchText);
        centroidsFiltered = action.payload.list.filter(searchFn);
      }
      return {
        ...state,
        centroids: action.payload.list,
        centroidsFiltered: centroidsFiltered,
      };
    case CentroidsActions.SET_CURRENT_CENTROID:
      let newarrCentroid = [...state.centroids].map((item) => ({
        ...item,
        selected:
          item &&
          action.payload &&
          action.payload.centroid &&
          action.payload.centroid.id &&
          item.id === action.payload.centroid.id
            ? state.currentMap &&
              state.currentMap.id === action.payload.centroid.id
              ? item.selected
              : !item.selected
            : false,
      }));

      let newCurr;
      if (action.payload && action.payload.centroid) {
        newCurr = {
          ...action.payload.centroid,
          selected: !action.payload.centroid.selected,
        };
      } else {
        newCurr = action.payload.centroid;
      }
      return {
        ...state,
        current:
          state.current &&
          state.current.id &&
          action.payload &&
          action.payload.centroid &&
          action.payload.centroid.id &&
          state.current.id &&
          state.current.id === action.payload.centroid.id
            ? null
            : newCurr,
        centroids: newarrCentroid,
        currentMap: null,
      };
    case CentroidsActions.RESET_CURRENT_CENTROID_MAP:
      return {
        ...state,
        centroids: [],
        centroidsFiltered: [],
        current: null,
        currentMap: null,
      };
    case CentroidsActions.SET_CURRENT_CENTROID_MAP:
      let newarrCentroidsMap = [...state.centroids].map((item) => ({
        ...item,
        selected:
          item &&
          action.payload &&
          action.payload.centroid &&
          action.payload.centroid.id &&
          item.id === action.payload.centroid.id
            ? !item.selected
            : false,
      }));

      let newCurrMap = {
        ...action.payload.centroid,
        selected: !action.payload.centroid.selected,
      };

      return {
        ...state,
        currentMap:
          state.currentMap &&
          state.currentMap.id &&
          action.payload &&
          action.payload.centroid &&
          action.payload.centroid.id &&
          state.currentMap.id === action.payload.centroid.id
            ? null
            : state.current && state.current.id
            ? null
            : newCurrMap,
        centroids: newarrCentroidsMap,
        current:
          state.current &&
          state.current.id &&
          action.payload &&
          action.payload.centroid &&
          action.payload.centroid.id
            ? state.current.id === action.payload.centroid.id
              ? null
              : action.payload.centroid
            : action.payload.centroid,
      };
    case NavTabActions.SET_SEARCH: {
      if (action.payload && action.payload.type === "centroidi") {
        let searchText =
          action.payload && action.payload.text
            ? action.payload.text.toLowerCase()
            : "";
        const searchFn = searchCentroids(searchText);
        let centroidsFiltered = state.centroids.filter(searchFn);
        const searchType = action.payload.type;

        return {
          ...state,
          centroidsFiltered,
          searchText,
          searchType,
        };
      } else {
        return { ...state };
      }
    }
    default:
      return state;
  }
}

export const getCentroids = (state) => state.centroids;
export const getCurrentCentroid = (state) => state.current;
export const getCurrentCentroidMap = (state) => state.currentMap;
export const getFilteredCentroids = (state) => state.centroidsFiltered;
