import React, { Component } from "react";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EnvironmentContext from "environment-context";
import createApiService from "services/api.service";
// STORE
import {
  isArcVisible,
  isNodeVisible,
  isCentroidVisible,
  getCurrentArc,
  getCurrentCentroid,
  getCurrentNode,
  getBoundingBox,
  getMapPolygon,
  isOpenNavTab,
  getCurrentArcMap,
  getCurrentNodeMap,
  getCurrentCentroidMap,
  getCurrentZoom,
  isArcsLoading,
  isNodesLoading,
  isCentroidsLoading,
} from "store";
import { setCurrentArc, setArcsList } from "reducers/graph/arcs/arcs.actions";
import {
  setCurrentNode,
  setNodesList,
} from "reducers/graph/nodes/nodes.actions";
import {
  setCurrentCentroid,
  setCentroidsList,
} from "reducers/graph/centroids/centroids.actions";
import { toggleOpenNavTab } from "reducers/ui/nav-tab/nav-tab.actions";
import { setIsGraphLoading } from "reducers/ui/graph-menu/graph-menu.actions";
// MODEL
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";
//UTIL
import {
  getBoundingBoxStringForBboxParam,
  getBoundingBoxStringForCqlFilter,
  getCqlCircleString,
  getCqLPolygonString,
} from "./map.utils";
import { debounce } from "utils/utils";

class LoadGraphData extends Component {
  subscriptions = [];
  apiService;

  constructor(props) {
    super(props);
    this.state = {
      arcsList: [],
      nodesList: [],
      centroidsList: [],
    };
    this.setGraphListsDebounced = debounce(this.setGraphList, 300);
  }

  componentDidMount = () => {
    const {
      mapPolygonReducer,
      isArcVisible,
      isNodeVisible,
      isCentroidVisible,
      toggleOpenNavTab,
      isOpenNavTab,
      setIsGraphLoading,
      isArcsLoading,
      isNodesLoading,
      isCentroidsLoading,
    } = this.props;

    this.apiService = createApiService(this.context);

    //if when you open the page, there is already a drawn polygon, get the elements belonging to the polygon
    if (
      mapPolygonReducer &&
      mapPolygonReducer.center &&
      mapPolygonReducer.radius
    ) {
      let circleString = getCqlCircleString(mapPolygonReducer);
      if (isArcVisible) {
        setIsGraphLoading({
          isArcsLoading: true,
          isNodesLoading,
          isCentroidsLoading,
        });
        this.getApiRoadSectionsList(null, circleString);
      }
      if (isNodeVisible) {
        setIsGraphLoading({
          isArcsLoading,
          isNodesLoading: true,
          isCentroidsLoading,
        });
        this.getApiNodesList(null, circleString);
      }
      if (isCentroidVisible) {
        setIsGraphLoading({
          isArcsLoading,
          isNodesLoading,
          isCentroidsLoading: true,
        });
        this.getApiCentroidList(null, circleString);
      }
      //OPEN NAVTAB IF IT WAS CLOSED
      if (
        (isArcVisible || isNodeVisible || isCentroidVisible) &&
        !isOpenNavTab
      ) {
        toggleOpenNavTab(true);
      }
    } else if (mapPolygonReducer && mapPolygonReducer.polygoncoordinates) {
      let polygonString = getCqLPolygonString(mapPolygonReducer);
      if (isArcVisible) {
        setIsGraphLoading({
          isArcsLoading: true,
          isNodesLoading,
          isCentroidsLoading,
        });
        this.getApiRoadSectionsList(null, polygonString);
      }
      if (isNodeVisible) {
        setIsGraphLoading({
          isArcsLoading,
          isNodesLoading: true,
          isCentroidsLoading,
        });
        this.getApiNodesList(null, polygonString);
      }
      if (isCentroidVisible) {
        setIsGraphLoading({
          isArcsLoading,
          isNodesLoading,
          isCentroidsLoading: true,
        });
        this.getApiCentroidList(null, polygonString);
      }
      //OPEN NAVTAB IF IT WAS CLOSED
      if (
        (isArcVisible || isNodeVisible || isCentroidVisible) &&
        !isOpenNavTab
      ) {
        toggleOpenNavTab(true);
      }
    } else {
      //otherwise load all lists
      this.setGraphList();
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      boundingBox,
      mapPolygonReducer,
      toggleOpenNavTab,
      isArcVisible,
      isNodeVisible,
      isCentroidVisible,
      currentArc,
      currentNode,
      currentCentroid,
      setIsGraphLoading,
      isArcsLoading,
      isNodesLoading,
      isCentroidsLoading,
    } = this.props;

    //CHECK IF BOUNDING BOX HAS CHANGED (ZOOM) BUT NO POLYGON IS DRAWN ON THE MAP
    // OR IF POLYGON OR CIRCLE HAS BEEN REMOVED
    // OR IF A LAYER HAS BEEN SELECTED AND DATA SHOULD BE LOADED AGAIN

    //DO NOT MAKE CALL AGAIN FOR ARCS IF SHORTEST DISTANCE BETWEEN TWO POINTS IS DRAWN ON MAP
    if (
      (prevProps.boundingBox !== boundingBox && !mapPolygonReducer) ||
      (prevProps.mapPolygonReducer !== mapPolygonReducer &&
        !mapPolygonReducer) ||
      (!mapPolygonReducer &&
        (prevProps.isArcVisible !== isArcVisible ||
          prevProps.isNodeVisible !== isNodeVisible ||
          prevProps.isCentroidVisible !== isCentroidVisible))
    ) {
      //IF DETAIL IS OPEN, DO NOT MAKE CALL AGAIN
      if (!currentArc && !currentCentroid && !currentNode) {
        if (prevProps.boundingBox === boundingBox) {
          setIsGraphLoading({
            isArcsLoading: true,
            isNodesLoading: true,
            isCentroidsLoading: true,
          });
        }
        this.setGraphListsDebounced();
      }
    }
    //CHECK IF A NEW CIRCLE OR POLYGON HAS BEEN DRAWN AND FILTER SELECTED LAYERS BY POLYGON
    // DO SAME IF THE NETWORK HAS BEEN JUST SELECTED AND POLYGON WAS ALREADY DRAWN
    if (
      (prevProps.mapPolygonReducer !== mapPolygonReducer &&
        mapPolygonReducer) ||
      prevProps.isArcVisible !== isArcVisible ||
      prevProps.isNodeVisible !== isNodeVisible ||
      prevProps.isCentroidVisible !== isCentroidVisible ||
      (prevProps.boundingBox !== boundingBox && mapPolygonReducer)
    ) {
      if (mapPolygonReducer && mapPolygonReducer.polygoncoordinates) {
        let polygonString = getCqLPolygonString(mapPolygonReducer);
        if (isArcVisible) {
          if (prevProps.boundingBox === boundingBox) {
            setIsGraphLoading({
              isArcsLoading: true,
              isNodesLoading,
              isCentroidsLoading,
            });
          }
          this.getApiRoadSectionsList(null, polygonString);
        }
        if (isNodeVisible) {
          if (prevProps.boundingBox === boundingBox) {
            setIsGraphLoading({
              isArcsLoading,
              isNodesLoading: true,
              isCentroidsLoading,
            });
          }
          this.getApiNodesList(null, polygonString);
        }
        if (isCentroidVisible) {
          if (prevProps.boundingBox === boundingBox) {
            setIsGraphLoading({
              isArcsLoading,
              isNodesLoading,
              isCentroidsLoading: true,
            });
          }
          this.getApiCentroidList(null, polygonString);
        }
        //OPEN NAVTAB IF IT WAS CLOSED
        if (
          (isArcVisible || isNodeVisible || isCentroidVisible) &&
          !isOpenNavTab
        ) {
          toggleOpenNavTab(true);
        }
      } else if (
        mapPolygonReducer &&
        mapPolygonReducer.center &&
        mapPolygonReducer.radius
      ) {
        let circleString = getCqlCircleString(mapPolygonReducer);
        if (isArcVisible) {
          if (prevProps.boundingBox === boundingBox) {
            setIsGraphLoading({
              isArcsLoading: true,
              isNodesLoading,
              isCentroidsLoading,
            });
          }
          this.getApiRoadSectionsList(null, circleString);
        }
        if (isNodeVisible) {
          if (prevProps.boundingBox === boundingBox) {
            setIsGraphLoading({
              isArcsLoading,
              isNodesLoading: true,
              isCentroidsLoading,
            });
          }
          this.getApiNodesList(null, circleString);
        }
        if (isCentroidVisible) {
          if (prevProps.boundingBox === boundingBox) {
            setIsGraphLoading({
              isArcsLoading,
              isNodesLoading,
              isCentroidsLoading: true,
            });
          }
          this.getApiCentroidList(null, circleString);
        }
        //OPEN NAVTAB IF IT WAS CLOSED
        if (
          (isArcVisible || isNodeVisible || isCentroidVisible) &&
          !isOpenNavTab
        ) {
          toggleOpenNavTab(true);
        }
      }
    }

    //GET COMPLETE LIST WHEN DESELECT CURRENT ARC OR NODE
    if (prevProps.currentArc !== currentArc && currentArc === null) {
      if (prevProps.boundingBox === boundingBox) {
        setIsGraphLoading({
          isArcsLoading,
          isNodesLoading: true,
          isCentroidsLoading,
        });
      }
      let bbox = getBoundingBoxStringForBboxParam(boundingBox);
      this.getApiNodesList(bbox, null);
    }
    if (prevProps.currentNode !== currentNode && currentNode === null) {
      if (prevProps.boundingBox === boundingBox) {
        setIsGraphLoading({
          isArcsLoading: true,
          isNodesLoading,
          isCentroidsLoading,
        });
      }
      let bbox = getBoundingBoxStringForBboxParam(boundingBox);
      this.getApiRoadSectionsList(bbox, null);
    }
  };

  //LOAD DATA TO BE VISUALISED ON MAP

  setGraphList = () => {
    const {
      boundingBox,
      isArcVisible,
      isNodeVisible,
      isCentroidVisible,
      currentMapZoom,
    } = this.props;

    //BBOX AND FILTER BY FRC ARE MUTUALLY EXCLUSIVE, SO THIS MUST BE IMPLEMENTED
    let stringFilterByFrc = null;
    let newBounds = getBoundingBoxStringForCqlFilter(boundingBox);

    if (currentMapZoom <= 11) {
      stringFilterByFrc = `frc=0 AND INTERSECTS(geom,POLYGON((${newBounds})))`;
    } else if (currentMapZoom <= 15) {
      stringFilterByFrc = `(frc=0 OR frc=1 OR frc=2) AND INTERSECTS(geom,POLYGON((${newBounds})))`;
    } else if (currentMapZoom > 15 && currentMapZoom <= 16) {
      stringFilterByFrc = `(frc=0 OR frc=1 OR frc=2 OR frc=3 OR frc=4) AND INTERSECTS(geom,POLYGON((${newBounds})))`;
    } else {
      stringFilterByFrc = `INTERSECTS(geom,POLYGON((${newBounds})))`;
    }

    if (isArcVisible) {
      this.getApiRoadSectionsList(null, stringFilterByFrc);
    }
    if (isNodeVisible) {
      this.getApiNodesList(null, stringFilterByFrc);
    }
    if (isCentroidVisible) {
      let bbox = getBoundingBoxStringForBboxParam(boundingBox);
      this.getApiCentroidList(bbox, null);
    }
  };

  getApiCentroidList = (bbox, cqlFilter) => {
    const {
      setCentroidsList,
      setIsGraphLoading,
      isArcsLoading,
      isNodesLoading,
    } = this.props;

    setIsGraphLoading({
      isArcsLoading,
      isNodesLoading,
      isCentroidsLoading: true,
    });

    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures(
          "centroids",
          null,
          null,
          null,
          null,
          bbox,
          cqlFilter
        )
        .pipe(
          tap((data) => {
            setIsGraphLoading({
              isArcsLoading: false,
              isNodesLoading: false,
              isCentroidsLoading: false,
            });
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "centroids"
            );
            let centroids = [];
            if (featureCollection.numberReturned > 0) {
              centroids = [...featureCollection.features];
            }
            setCentroidsList(centroids);
          }),
          catchError((error) => {
            setIsGraphLoading({
              isArcsLoading: false,
              isNodesLoading: false,
              isCentroidsLoading: false,
            });
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  getApiNodesList = (bbox, cqlFilter) => {
    const {
      setNodesList,
      setIsGraphLoading,
      isArcsLoading,
      isCentroidsLoading,
    } = this.props;

    setIsGraphLoading({
      isArcsLoading,
      isNodesLoading: true,
      isCentroidsLoading,
    });

    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures("nodes", null, null, null, null, bbox, cqlFilter)
        .pipe(
          tap((data) => {
            setIsGraphLoading({
              isArcsLoading: false,
              isNodesLoading: false,
              isCentroidsLoading: false,
            });
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "nodes"
            );
            let nodes = [];
            if (data.numberReturned > 0) {
              nodes = [...featureCollection.features];
            }
            setNodesList(nodes);
          }),
          catchError((error) => {
            setIsGraphLoading({
              isArcsLoading: false,
              isNodesLoading: false,
              isCentroidsLoading: false,
            });
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  getApiRoadSectionsList = (bbox, cqlFilter) => {
    const { setArcsList, setIsGraphLoading } = this.props;

    this.subscriptions.push(
      this.apiService
        .getGeoserverFeatures(
          "road_sections",
          null,
          null,
          null,
          null,
          bbox,
          cqlFilter
        )
        .pipe(
          tap((data) => {
            setIsGraphLoading({
              isArcsLoading: false,
              isNodesLoading: false,
              isCentroidsLoading: false,
            });
            let featureCollection = FeatureCollectionModel.fromREST(
              data,
              "road_sections"
            );
            let road_sections = [];
            if (data.numberReturned > 0) {
              road_sections = [...featureCollection.features];
            }
            setArcsList(road_sections);
          }),
          catchError((error) => {
            setIsGraphLoading({
              isArcsLoading: false,
              isNodesLoading: false,
              isCentroidsLoading: false,
            });
            console.error(error);
            return of(error);
          })
        )
        .subscribe()
    );
  };

  render() {
    return <div></div>;
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}

LoadGraphData.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setCurrentArc,
  setCurrentCentroid,
  setCurrentNode,
  setCentroidsList,
  setNodesList,
  setArcsList,
  toggleOpenNavTab,
  setIsGraphLoading,
};

const mapStateToProps = (state) => ({
  isArcVisible: isArcVisible(state),
  isNodeVisible: isNodeVisible(state),
  isCentroidVisible: isCentroidVisible(state),
  boundingBox: getBoundingBox(state),
  currentArc: getCurrentArc(state),
  currentArcMap: getCurrentArcMap(state),
  currentNodeMap: getCurrentNodeMap(state),
  currentCentroidMap: getCurrentCentroidMap(state),
  currentCentroid: getCurrentCentroid(state),
  currentNode: getCurrentNode(state),
  mapPolygonReducer: getMapPolygon(state),
  currentMapZoom: getCurrentZoom(state),
  isArcsLoading: isArcsLoading(state),
  isNodesLoading: isNodesLoading(state),
  isCentroidsLoading: isCentroidsLoading(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadGraphData)
);
