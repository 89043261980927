import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { tap, catchError, take } from "rxjs/operators";
import createApiService from "services/api.service";
import { of } from "rxjs";
import * as moment from "moment";

//ACTIONS
import { setNavTabSearch } from "reducers/ui/nav-tab/nav-tab.actions";
import { toggleGenericTable } from "reducers/ui/table-menu/table-menu.actions";
import {
  getStations,
  getFilteredStations,
  getCurrentStation,
  getNavTabSelectedTab,
  isStationVisible,
  getBoundingBox,
  isWSStationStatusOpen,
  isWSStationMeasuresOpen,
  isOpenNavStation,
  isStationLoading,
  getCurrentWorkspace,
  getCurrentZoom,
} from "store";
import {
  setStationsList,
  updateCurrentStation,
  setCurrentStation,
  updateStationStatesWS,
  updateStationMeasuresWS,
} from "reducers/installations/stations/stations.actions";
import { setIsStationLoading } from "reducers/ui/installations-menu/installations-menu.actions";
import { setBounds, filterByMapBounds } from "reducers/map/map.actions";
import { wsOpen } from "reducers/ui/ws/ws.actions";
//COMPONENT
import ErrorBoundary from "components/shared/error-boundary/error-boundary";
import TabPanel from "components/layout/tabpanel";
import StationsList from "components/layout/stations-list/index";
import StationDetail from "components/layout/stations-list/stations-detail/index";

// PROJECT IMPORTS
import NavTab from "components/layout/nav-tab";
import EnvironmentContext from "environment-context";
import BottomControlsIcons from "components/shared/bottom-controls/bottom-controls-icons";

// MODEL
import StationModel from "reducers/installations/stations/stations.model";
import { FeatureCollectionModel } from "reducers/graph/graph-features.models";

//UTIL
import { filterBounds } from "components/layout/map/load-map/load-map";
import { filterDataByVehicle } from "utils/util-station-graph";
import Loading from "components/shared/loading/loading";

class StationsPage extends Component {
  subscriptions = [];
  bufferTabs = [];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      defaultMeasure: null,
      openDetail: false,
      currentArc: null,
    };
  }

  componentDidMount = () => {
    const { currentStation } = this.props;

    this.apiService = createApiService(this.context);

    //WS FOR SENSOR STATUS
    this.openWS();

    if (currentStation) {
      setIsStationLoading(true);
      let stringFilter = "arcid='" + currentStation.archId + "'";

      this.subscriptions.push(
        this.apiService
          .getStationDetail(
            currentStation && currentStation.stationId
              ? currentStation.stationId
              : null,
            moment().startOf("day").toISOString(),
            3600
          )
          .pipe(
            take(1),
            tap((data) => {
              setIsStationLoading(false);
              let stationsDetail = StationModel.from(data, 5);

              let defaultMeasureTrue = stationsDetail.supportedMeasure.find(
                (item) => {
                  return item.defaultMeasure;
                }
              );
              if (!defaultMeasureTrue) {
                defaultMeasureTrue = stationsDetail.supportedMeasure[0];
              }

              let vehicleDataArray = filterDataByVehicle(
                stationsDetail,
                defaultMeasureTrue
              );

              this.setState({
                data: vehicleDataArray,
                defaultMeasure: defaultMeasureTrue,
              });
            }),
            catchError((error) => {
              setIsStationLoading(false);
              console.error(error);
              return of(error);
            })
          )
          .subscribe(),
        this.apiService
          .getGeoserverFeatures(
            "road_sections",
            null,
            null,
            null,
            null,
            null,
            stringFilter
          )
          .pipe(
            take(1),
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "road_sections"
              );
              this.setState({
                currentArc: featureCollection,
              });
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { currentStation, updateCurrentStation } = this.props;
    const { defaultMeasure, openDetail } = this.state;

    if (
      (currentStation &&
        prevProps.currentStation &&
        currentStation.stationId !== prevProps.currentStation.stationId) ||
      (!prevProps.currentStation &&
        currentStation &&
        !prevState.openDetail &&
        !openDetail)
    ) {
      let stringFilter = "arcid='" + currentStation.archId + "'";

      this.subscriptions.push(
        this.apiService
          .getStationDetail(
            currentStation && currentStation.stationId
              ? currentStation.stationId
              : null,
            moment().startOf("day").toISOString(),
            3600
          )
          .pipe(
            take(1),
            tap((data) => {
              let stationsDetail = StationModel.from(data, 5);
              updateCurrentStation(stationsDetail);

              let defaultMeasureTrue = stationsDetail.supportedMeasure.find(
                (item) => {
                  return item.defaultMeasure;
                }
              );
              if (!defaultMeasureTrue) {
                defaultMeasureTrue = stationsDetail.supportedMeasure[0];
              }

              let vehicleDataArray = filterDataByVehicle(
                stationsDetail,
                defaultMeasureTrue
              );

              this.setState({
                data: vehicleDataArray,
                defaultMeasure: defaultMeasureTrue,
              });
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe(),
        this.apiService
          .getGeoserverFeatures(
            "road_sections",
            null,
            null,
            null,
            null,
            null,
            stringFilter
          )
          .pipe(
            take(1),
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "road_sections"
              );
              this.setState({
                currentArc: featureCollection,
              });
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    } else if (
      (currentStation &&
        currentStation.measures &&
        currentStation.measures[0] &&
        prevProps.currentStation &&
        prevProps.currentStation.measures &&
        prevProps.currentStation.measures[0] &&
        prevProps.currentStation.measures[0].measureTimeUtc !==
          currentStation.measures[0].measureTimeUtc) ||
      (prevProps.currentStation &&
        prevProps.currentStation.measures &&
        prevProps.currentStation.measures.length === 0 &&
        currentStation &&
        currentStation.measures &&
        currentStation.measures.length > 0) ||
      (prevProps.currentStation &&
        prevProps.currentStation.measures &&
        prevProps.currentStation.measures[0] &&
        prevProps.currentStation.measures.length > 0 &&
        currentStation &&
        currentStation.measures &&
        currentStation.measures[0] &&
        currentStation.measures.length === 0)
    ) {
      let vehicleDataArray = filterDataByVehicle(
        currentStation,
        defaultMeasure
      );

      this.setState({
        data: vehicleDataArray,
      });
    }
  };

  openWS = () => {
    const { isWSStationStatusOpen, isWSStationMeasuresOpen } = this.props;
    if (!isWSStationStatusOpen) {
      this.wsStationStatus();
    }
    if (!isWSStationMeasuresOpen) {
      this.wsStationMeasures();
    }
  };

  wsStationStatus = () => {
    this.apiService.getStationStatusRuntime(
      this.stationStatusSourceMessage(),
      this.stationStatusSourceErr(),
      this.stationStatusSourceOpen()
    );
  };

  wsStationMeasures = () => {
    this.apiService.getStationMeasuresRuntime(
      this.stationMeasuresSourceMessage(),
      this.stationMeasuresSourceErr(),
      this.stationMeasuresSourceOpen()
    );
  };

  stationStatusSourceOpen = (e) => {
    const { wsOpen, isWSStationStatusOpen } = this.props;
    if (!isWSStationStatusOpen) wsOpen("stationsStatus", true);
  };

  stationStatusSourceErr = (e) => {
    const { wsOpen, isWSStationStatusOpen } = this.props;
    if (isWSStationStatusOpen) wsOpen("stationsStatus", false);
  };

  stationMeasuresSourceOpen = (e) => {
    const { wsOpen, isWSStationMeasuresOpen } = this.props;
    if (!isWSStationMeasuresOpen) wsOpen("stationsMeasures", true);
  };

  stationMeasuresSourceErr = (e) => {
    const { wsOpen, isWSStationMeasuresOpen } = this.props;
    if (isWSStationMeasuresOpen) wsOpen("stationsMeasures", false);
  };

  stationStatusSourceMessage = (e) => {
    if (e) {
      let stationStatesWS = JSON.parse(e);
      if (
        stationStatesWS &&
        stationStatesWS instanceof Array &&
        stationStatesWS.length > 0
      ) {
        const { updateStationStatesWS } = this.props;
        updateStationStatesWS(stationStatesWS);
      }
    }
  };

  stationMeasuresSourceMessage = (e) => {
    if (e) {
      let stationMeasuresWS = JSON.parse(e);
      if (
        stationMeasuresWS &&
        stationMeasuresWS instanceof Array &&
        stationMeasuresWS.length > 0
      ) {
        const { updateStationMeasuresWS } = this.props;
        updateStationMeasuresWS(stationMeasuresWS);
      }
    }
  };

  handleSearch = (value) => {
    const { setNavTabSearch } = this.props;
    setNavTabSearch(value, "stations", null, null);
    if (value === "") {
      filterBounds(this);
    }
  };

  toggleDetails = (event) => {
    const { setCurrentStation } = this.props;

    if (event) {
      let stringFilter = "arcid='" + event.archId + "'";
      this.subscriptions.push(
        this.apiService
          .getStationDetail(
            event && event.stationId ? event.stationId : null,
            moment().startOf("day").toISOString(),
            3600
          )
          .pipe(
            take(1),
            tap((data) => {
              let stationsDetail = StationModel.from(data, 5);

              let defaultMeasureTrue = stationsDetail.supportedMeasure.find(
                (item) => {
                  return item.defaultMeasure;
                }
              );
              if (!defaultMeasureTrue) {
                defaultMeasureTrue = stationsDetail.supportedMeasure[0];
              }

              let vehicleDataArray = filterDataByVehicle(
                stationsDetail,
                defaultMeasureTrue
              );

              this.setState({
                data: vehicleDataArray,
                defaultMeasure: defaultMeasureTrue,
                openDetail: true,
              });
              setCurrentStation(stationsDetail);
            }),
            catchError((error) => {
              setCurrentStation(null);
              console.error(error);
              return of(error);
            })
          )
          .subscribe(),
        this.apiService
          .getGeoserverFeatures(
            "road_sections",
            null,
            null,
            null,
            null,
            null,
            stringFilter
          )
          .pipe(
            take(1),
            tap((data) => {
              let featureCollection = FeatureCollectionModel.fromREST(
                data,
                "road_sections"
              );
              this.setState({
                currentArc: featureCollection,
              });
            }),
            catchError((error) => {
              console.error(error);
              return of(error);
            })
          )
          .subscribe()
      );
    } else {
      setCurrentStation(null);
      this.setState({
        openDetail: false,
      });
    }
  };

  handleSelectMeasure = (value) => {
    const { currentStation } = this.props;
    if (currentStation && currentStation.supportedMeasure) {
      let newDefaultMeasure = currentStation.supportedMeasure.find((item) => {
        return item.name === value;
      });

      let vehicleDataArray = filterDataByVehicle(
        currentStation,
        newDefaultMeasure
      );

      this.setState({
        data: vehicleDataArray,
        defaultMeasure: newDefaultMeasure,
      });
    }
  };

  /* inject installation items to right sidebar */
  injectItems = () => {
    const {
      stationsFiltered,
      currentStation,
      isStationVisible,
      toggleGenericTable,
      isStationLoading,
    } = this.props;

    const { defaultMeasure, data, currentArc } = this.state;

    let controlsDetail = [
      {
        icon: "settings",
        tooltip: "Statistiche",
        toggle: (e) => toggleGenericTable("stationsTable"),
      },
    ];

    return isStationVisible && isStationLoading ? (
      <ErrorBoundary>
        <TabPanel className="tabsContainerTpl inherit-transition">
          <Loading />
        </TabPanel>
      </ErrorBoundary>
    ) : isStationVisible && !isStationLoading ? (
      <div className="uk-height-1-1 uk-width-1-1 inherit-transition uk-position-relative">
        <ErrorBoundary>
          <TabPanel className="tabsContainerTpl inherit-transition">
            <ul className="uk-tab uk-flex-between">
              <li className={"uk-active"}>
                <a href="/#">
                  {"Stazioni di misura (" +
                    (stationsFiltered && stationsFiltered.length) +
                    ")"}
                </a>
              </li>
            </ul>
            <TabPanel className="overflow inherit-transition uk-height-1-1">
              <ErrorBoundary>
                {currentStation ? (
                  <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
                    <div className="uk-height-1-1 overflow event-list-container">
                      <StationDetail
                        toggleDetails={this.toggleDetails}
                        listEvent={stationsFiltered}
                        data={data}
                        defaultMeasure={defaultMeasure}
                        handleSelectMeasure={this.handleSelectMeasure}
                        currentArc={currentArc}
                      ></StationDetail>
                    </div>
                    <BottomControlsIcons controls={controlsDetail} />
                  </div>
                ) : (
                  <div className="uk-height-1-1 uk-width-1-1 uk-position-relative">
                    <div className="uk-height-1-1 overflow event-list-container">
                      <StationsList
                        toggleDetails={this.toggleDetails}
                        listEvent={stationsFiltered}
                      ></StationsList>
                    </div>
                  </div>
                )}
              </ErrorBoundary>
            </TabPanel>
          </TabPanel>
        </ErrorBoundary>
      </div>
    ) : null;
  };

  render() {
    return (
      <NavTab
        isOpen={true}
        items={this.injectItems()}
        onSearch={this.handleSearch}
      />
    );
  }

  componentWillUnmount = () => {
    const { setNavTabSearch, location, menuTabReducer } = this.props;

    this.subscriptions.forEach((x) => x.unsubscribe());

    this.bufferTabs = [];
    setNavTabSearch("", null, location && location.pathname, menuTabReducer);
  };
}

StationsPage.contextType = EnvironmentContext;

const mapDispatchToProps = {
  setNavTabSearch,
  setStationsList,
  setCurrentStation,
  toggleGenericTable,
  setBounds,
  filterByMapBounds,
  updateCurrentStation,
  wsOpen,
  updateStationStatesWS,
  updateStationMeasuresWS,
  setIsStationLoading,
};

const mapStateToProps = (state) => ({
  stations: getStations(state),
  stationsFiltered: getFilteredStations(state),
  currentStation: getCurrentStation(state),
  menuTabReducer: getNavTabSelectedTab(state),
  isStationVisible: isStationVisible(state),
  boundsReducer: getBoundingBox(state),
  isWSStationStatusOpen: isWSStationStatusOpen(state),
  isWSStationMeasuresOpen: isWSStationMeasuresOpen(state),
  isOpenNavStation: isOpenNavStation(state),
  isStationLoading: isStationLoading(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
  currentMapZoom: getCurrentZoom(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StationsPage)
);
