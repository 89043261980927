export const defaultGeoJsonObj = {
  type: "FeatureCollection",
  features: [],
};

export const INITIAL_STATE_MAP = {
  selectedFeatureIndexes: [],
  geojson: defaultGeoJsonObj,
  mode: null,
  workspace: 0,
  layout: "dark",
  layers: {
    cityBus: [],
    regionBus: [],
    cityBusFiltered: [],
    regionBusFiltered: [],
    cityBusActiveLines: [],
    regionBusActiveLines: [],
  },
  polygon: null,
  controls: {
    openMapControls: false,
    openTabSearchStreet: false,
    openTabLegend: false,
    openTabChangeLayer: false,
    polygonSelected: false,
    circleSelected: false,
    rulerSelected: false,
    distanceSelected: false,
  },
  multipleDistance: [],
  shortestDistanceOnGraph: [],
  history: {
    prev: [],
    next: [],
  },
  hovered: {
    hoveredBus: {
      object: "",
      x: null,
      y: null,
      toBeFollowed: false,
    },
    hoveredStop: {
      object: "",
      x: null,
      y: null,
    },
    hoveredEvent: {
      object: "",
      x: null,
      y: null,
    },
    hoveredInstallation: {
      object: "",
      x: null,
      y: null,
    },
    hoveredStation: {
      object: "",
      x: null,
      y: null,
    },
    hoveredMultipleDistance: {
      object: "",
      x: null,
      y: null,
    },
    hoveredShortestDistanceOnGraph: {
      object: "",
      x: null,
      y: null,
    },
    hoveredRdsTmcPoint: {
      object: "",
      x: null,
      y: null,
    },
    hoveredParking: {
      object: "",
      x: null,
      y: null,
    },
    hoveredCamera: {
      object: "",
      x: null,
      y: null,
    },
    hoveredPMV: {
      object: "",
      x: null,
      y: null,
    },
  },
  boundingBox: [],
  zoom: null,
};
