import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  tap,
  take,
  map,
  mergeMap,
  catchError,
  concatMap,
  finalize,
} from "rxjs/operators";
import { merge, of } from "rxjs";
import * as moment from "moment";
import UIkit from "@almaviva/acr-uikit/dist/js/uikit";
import createApiService from "services/api.service";
//MODEL
import LinesModel from "reducers/lines/lines.model";
import StopsModel from "reducers/stops/stops.model";
import { VehicleRecords } from "reducers/trips/trips.model";
import { VehicleActivityModel } from "reducers/vehicles/vehicles.model";
//ACTIONS
import { getLines } from "reducers/lines/lines.actions";
import { getStops } from "reducers/stops/stops.actions";
import {
  setTransportVisibility,
  setIsTplLoading,
} from "reducers/ui/tpl-menu/tpl-menu.actions";
import { getTrips } from "reducers/trips/trips.actions";
import { getRoutes } from "reducers/routes/routes.actions";
import { setVehicleEvents } from "reducers/vehicles/vehicles.actions";
import {
  mapResetLayer,
  toggleTabLegend,
  setBounds,
  filterByMapBounds,
} from "reducers/map/map.actions";
import {
  setNavTabSelectedTab,
  setNavTabSearch,
  resetNavTabInfoRoute,
} from "reducers/ui/nav-tab/nav-tab.actions";
import { wsOpen } from "reducers/ui/ws/ws.actions";
import { addAllLines } from "reducers/map/map.actions";
import {
  getFilteredLines,
  getAllVehiclesEvents,
  getNavTabSelectedTab,
  getNavTabInfoRoute,
  getNavTabSearch,
  getAllLines,
  getCurrentNetwork,
  getAllActiveLines,
  getCurrentStop,
  getCurrentTrip,
  isCityBusVisible,
  isRegionBusVisible,
  getRegionBusStopsFiltered,
  getCityBusStopsFiltered,
  getCityBusTripsFiltered,
  getRegionBusTripsFiltered,
  getCityBusRoutesFiltered,
  getRegionBusRoutesFiltered,
  getAllRoutes,
  getCityBusTrips,
  getRegionBusTrips,
  getSearchTextRoutes,
  isOpenNavTab,
  getControls,
  getCurrentIdRouteForStop,
  getCurrentIdTripForStop,
  getCurrentIdRouteForVehicle,
  getCurrentIdStopForVehicle,
  getCurrentIdStopForRoute,
  getCurrentIdTripForRoute,
  getBoundingBox,
  isLinesLoading,
  isTripsLoading,
  isTripsAllLoading,
  isStopsLoading,
  getCurrentWorkspace,
  getCurrentZoom,
  isWSTplOpen,
  isWSTplLocationOpen,
} from "store";
//COMPONENTS
import { filterBounds } from "components/layout/map/load-map/load-map";
import EnvironmentContext from "environment-context";
//UTILS
import DeckLayerUtil from "utils/map-utils/deck-layer-util";
import { notifDetailError } from "utils/utils-filter-tpl";
import { TYPE_TRANSPORT_MODE } from "utils/urbSub_definitions";

class LoadTplData extends Component {
  subscriptions = [];
  apiService;
  lines = [];
  vehicles = [];
  timer = false;
  myTimer = null;
  constructor(props) {
    super(props);
    this.state = {
      notifyLines: false,
      notifyStops: false,
      notifyTrips: false,
      resetSearchbar: false,
    };
  }

  componentDidMount() {
    this.apiService = createApiService(this.context);
  }

  componentDidUpdate = (prevProps) => {
    const {
      cityBusVisible,
      regionBusVisible,
      networkCurrentReducer,
    } = this.props;
    if (
      (cityBusVisible !== prevProps.cityBusVisible && cityBusVisible) ||
      (regionBusVisible !== prevProps.regionBusVisible && regionBusVisible)
    ) {
      !networkCurrentReducer || networkCurrentReducer.length === 0
        ? this.getApiNetworkDetails()
        : this.tplCallApi(null);
    }
  };

  eventSourceOpenTpl = (e) => {
    const { wsOpen, isWSTplOpen } = this.props;
    if (!isWSTplOpen) wsOpen("tpl", true);
  };
  eventSourceErrTpl = (e) => {
    const { wsOpen, isWSTplOpen } = this.props;
    if (isWSTplOpen) wsOpen("tpl", false);
  };

  eventSourceOpenTplLoc = (e) => {
    const { wsOpen, isWSTplLocationOpen } = this.props;
    if (!isWSTplLocationOpen) wsOpen("tplLocation", true);
  };
  eventSourceErrTplLoc = (e) => {
    const { wsOpen, isWSTplLocationOpen } = this.props;
    if (isWSTplLocationOpen) wsOpen("tplLocation", false);
  };

  eventSourceMessage = (e) => {
    if (e && e.data) {
      const { setVehicleEvents, routesReducer } = this.props;
      let data = JSON.parse(e.data);
      if (
        data &&
        ((data.vehicleActivity &&
          data.vehicleActivity.monitoredVehicleJourney &&
          data.vehicleActivity.monitoredVehicleJourney.lineRef &&
          data.vehicleActivity.monitoredVehicleJourney.lineRef !== "*") ||
          (data.vehicleActivityLocation &&
            data.vehicleActivityLocation.monitoredVehicleJourney &&
            data.vehicleActivityLocation.monitoredVehicleJourney.lineRef &&
            data.vehicleActivityLocation.monitoredVehicleJourney.lineRef !==
              "*"))
      ) {
        !(data instanceof Array)
          ? this.vehicles.push(data)
          : (this.vehicles = data);
        if (!this.myTimer)
          this.myTimer = setTimeout(() => {
            if (this.vehicles && this.vehicles.length > 0) {
              this.myTimer = null;
              let v = VehicleActivityModel.fromArrayWS(this.vehicles);
              if (v && v.length > 0) {
                v.forEach((item) => {
                  if (
                    item &&
                    item.vehicleActivityLocation &&
                    item.vehicleActivityLocation.monitoredVehicleJourney &&
                    item.vehicleActivityLocation.monitoredVehicleJourney
                      .vehicleModeRef
                  ) {
                    switch (
                      item.vehicleActivityLocation.monitoredVehicleJourney
                        .vehicleModeRef
                    ) {
                      case TYPE_TRANSPORT_MODE["extraurb"]:
                        item.transportMode = {
                          code: "07.00",
                          label: "BUS",
                          description: "Bus Service",
                          isManaged: true,
                          subMode: {
                            code: "07.01",
                            label: "REGIONBUS",
                            description: "Regional Bus Service",
                            isManaged: true,
                          },
                        };
                        break;
                      case TYPE_TRANSPORT_MODE["urb"]:
                        item.transportMode = {
                          code: "07.00",
                          label: "BUS",
                          description: "Bus Service",
                          isManaged: true,
                          subMode: {
                            code: "07.70",
                            label: "CITYBUS",
                            description: "City Bus Service",
                            isManaged: true,
                          },
                        };
                        break;

                      default:
                        item.transportMode = null;
                        break;
                    }
                  }
                  if (
                    item &&
                    routesReducer &&
                    item.vehicleActivityLocation &&
                    item.vehicleActivityLocation.monitoredVehicleJourney &&
                    item.vehicleActivityLocation.monitoredVehicleJourney
                      .journeyPatternRef &&
                    !item.transportMode
                  ) {
                    let tempRoute = routesReducer.find(
                      (r) =>
                        r.id ===
                        item.vehicleActivityLocation.monitoredVehicleJourney
                          .journeyPatternRef
                    );
                    if (tempRoute && tempRoute.transportMode) {
                      item.transportMode = tempRoute.transportMode;
                    }
                  }
                });
              }

              setVehicleEvents(v);
              this.vehicles = [];
            }
          }, 5000);
      }
    }
  };

  getApiNetworkDetails = () => {
    fetch("/config.json")
      .then((res) => res.json())
      .then((environment) => {
        this.tplCallApi(environment.network);
      });
  };

  tplCallApi = (idNetwork) => {
    const {
      getLines,
      getRoutes,
      networkCurrentReducer,
      routesReducer,
      linesReducer,
      cityBusStopsFiltered,
      regionBusStopsFiltered,
      cityBusTripsFiltered,
      regionBusTripsFiltered,
      addAllLines,
      setIsTplLoading,
      isTripsLoading,
      isTripsAllLoading,
      isStopsLoading,
      isWSTplOpen,
      isWSTplLocationOpen,
    } = this.props;

    let idNetworks = [];
    networkCurrentReducer && networkCurrentReducer.length > 0
      ? networkCurrentReducer.forEach((x) => idNetworks.push(x.id))
      : idNetworks.push(idNetwork);
    if (
      idNetworks &&
      idNetworks.length > 0 &&
      ((linesReducer && linesReducer.length === 0) ||
        (routesReducer && routesReducer.length === 0) ||
        (cityBusStopsFiltered && cityBusStopsFiltered.length === 0) ||
        (regionBusStopsFiltered && regionBusStopsFiltered.length === 0) ||
        (cityBusTripsFiltered && cityBusTripsFiltered.length === 0) ||
        (regionBusTripsFiltered && regionBusTripsFiltered.length === 0))
    ) {
      setIsTplLoading({
        isLinesLoading: true,
        isTripsLoading: true,
        isTripsAllLoading: true,
        isStopsLoading: true,
      });

      idNetworks.forEach((idNet) => {
        this.subscriptions.push(
          this.apiService
            .getListaLinee(idNet)
            .pipe(
              tap((x) => {
                if (x && !x.error) {
                  let arrLinesId = [];
                  let routes = [];
                  this.lines = LinesModel.fromArrayREST(x);
                  getLines(this.lines);
                  this.lines.forEach((line) => {
                    if (line && line.routes && line.routes.length > 0) {
                      line.routes.map((detail) => {
                        detail["transportMode"] = line.transportMode;
                        detail["nameLong"] = detail.nameShort;
                        detail["lineId"] = line.id;
                        detail["routeRef"] = detail.id;
                        detail.nameShort = line.nameShort;
                        routes.push(detail);
                        return detail;
                      });
                    }
                    arrLinesId.push(line.id);
                  });
                  if (!isWSTplOpen) {
                    this.apiService.getVehicleEventsRuntime(
                      arrLinesId,
                      this.eventSourceMessage,
                      this.eventSourceErrTpl,
                      this.eventSourceOpenTpl
                    );
                  }
                  if (!isWSTplLocationOpen) {
                    this.apiService.getVehicleEventsLocationRuntime(
                      arrLinesId,
                      this.eventSourceMessage,
                      this.eventSourceErrTplLoc,
                      this.eventSourceOpenTplLoc
                    );
                  }
                  setIsTplLoading({
                    isLinesLoading: false,
                    isTripsLoading: isTripsLoading,
                    isTripsAllLoading: isTripsAllLoading,
                    isStopsLoading: isStopsLoading,
                  });
                  //routes = reduceDuplicatesInArrayForId(routes);
                  getRoutes(routes);
                  UIkit.notification.closeAll("lines-group");
                } else {
                  setIsTplLoading({
                    isLinesLoading: false,
                    isTripsLoading: isTripsLoading,
                    isTripsAllLoading: isTripsAllLoading,
                    isStopsLoading: isStopsLoading,
                  });
                  UIkit.notification.closeAll("lines-group");
                  console.log(x);
                }
              }),
              mergeMap((x) => merge(this.getGeoJsonLinea(idNet))),
              tap((data) => {
                if (data && data.features && data.features.length > 0) {
                  addAllLines(data);
                } else {
                  notifDetailError("Errore caricamento Linee");
                }
              }),
              mergeMap(() => merge(this.getStopsList(idNet))),
              mergeMap(() => merge(this.getTripsList(idNet))),
              catchError((error) => {
                setIsTplLoading({
                  isLinesLoading: false,
                  isTripsLoading: false,
                  isTripsAllLoading: isTripsAllLoading,
                  isStopsLoading: false,
                });
                console.error(error);
                return of(error);
              })
            )
            .subscribe()
        );
      });
    } else {
      filterBounds(this);
    }
  };

  getStopsList = (idNet) => {
    const {
      getStops,
      setIsTplLoading,
      isTripsLoading,
      isTripsAllLoading,
      isLinesLoading,
    } = this.props;

    if (idNet) {
      return this.apiService.getListaFermate(idNet).pipe(
        take(1),
        tap((x) => {
          if (x && !x.error) {
            let stops = StopsModel.fromArrayREST(x);
            getStops(stops);
            setIsTplLoading({
              isLinesLoading: isLinesLoading,
              isTripsLoading: isTripsLoading,
              isTripsAllLoading: isTripsAllLoading,
              isStopsLoading: false,
            });
            UIkit.notification.closeAll("stops-group");
            filterBounds(this);
          } else {
            console.log(x);
            setIsTplLoading({
              isLinesLoading: isLinesLoading,
              isTripsLoading: isTripsLoading,
              isTripsAllLoading: isTripsAllLoading,
              isStopsLoading: false,
            });
            UIkit.notification.closeAll("stops-group");
          }
        }),
        catchError((error) => {
          setIsTplLoading({
            isLinesLoading: isLinesLoading,
            isTripsLoading: isTripsLoading,
            isTripsAllLoading: isTripsAllLoading,
            isStopsLoading: false,
          });
          UIkit.notification.closeAll("stops-group");
          notifDetailError("Errore caricamento fermate");
          console.error(error);
          return of(error);
        })
      );
    }
  };

  getTripsList = (idNet) => {
    const {
      getTrips,
      setIsTplLoading,
      isTripsAllLoading,
      isStopsLoading,
      isLinesLoading,
    } = this.props;

    var timeEnd = moment().set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 59,
    });
    let before = moment().subtract(30, "minutes");
    //let minutesForCall = timeEnd.diff(before, "minutes");
    let arrayIntervals = [
      {
        fromTime: before.clone(),
        toTime: before.clone().add(60, "minutes"),
        minsForCall: 60,
      },
    ];

    for (let i = 1; i <= 100; i++) {
      let currentLeftmost = arrayIntervals[i - 1].toTime.clone();
      let nextLeftmost = currentLeftmost.clone().add(120, "minutes");
      if (timeEnd.diff(nextLeftmost) < 0) {
        let lastUntilMidnight = currentLeftmost
          .clone()
          .add(timeEnd.diff(currentLeftmost, "minutes"), "minutes");
        arrayIntervals.push({
          fromTime: currentLeftmost,
          toTime: lastUntilMidnight,
          minsForCall: timeEnd.diff(currentLeftmost, "minutes"),
        });
        break;
      } else {
        arrayIntervals.push({
          fromTime: currentLeftmost,
          toTime: nextLeftmost,
          minsForCall: nextLeftmost.diff(currentLeftmost, "minutes"),
        });
      }
    }

    return of(...arrayIntervals).pipe(
      concatMap((interval) =>
        this.apiService.getListaCorse(
          "network",
          idNet,
          interval.fromTime,
          interval.minsForCall
        )
      ),
      tap((x) => {
        if (x && !x.error) {
          let tr = VehicleRecords.fromArrayREST(x.vehicleRecords);
          tr.map((r) => {
            this.lines.forEach((line) => {
              if (line.id === r.monitoredVehicleJourney.lineRef) {
                r["transportMode"] = line.transportMode;
              }
            });
            return r;
          });
          getTrips(tr);
          setIsTplLoading({
            isLinesLoading: isLinesLoading,
            isTripsLoading: false,
            isTripsAllLoading: isTripsAllLoading,
            isStopsLoading: isStopsLoading,
          });
        } else {
          console.log(x);
          throw x.error;
        }
      }),
      finalize(() => {
        setIsTplLoading({
          isLinesLoading: isLinesLoading,
          isTripsLoading: false,
          isTripsAllLoading: isTripsAllLoading,
          isStopsLoading: isStopsLoading,
        });
        UIkit.notification.closeAll("trips-group");
      }),
      catchError((error) => {
        setIsTplLoading({
          isLinesLoading: isLinesLoading,
          isTripsLoading: false,
          isTripsAllLoading: false,
          isStopsLoading: isStopsLoading,
        });
        UIkit.notification.closeAll("trips-group");
        notifDetailError("Errore caricamento corse");
        console.error(error);
        return of(error);
      })
    );
  };

  getGeoJsonLinea = (idNet) => {
    const { linesReducer, routesReducer } = this.props;
    return this.apiService.getGeoJsonLinea(idNet).pipe(
      map((data) => {
        const linesArr = DeckLayerUtil.filterData("LineString")(data);
        if (linesArr && linesArr.features) {
          linesArr.features.map((elem) => {
            let tmpLine = [...linesReducer].filter(
              (item) => item.id === elem.properties.lineRef
            );
            if (tmpLine && tmpLine.length > 0 && tmpLine[0].transportMode) {
              elem.properties["transportMode"] = tmpLine[0].transportMode;
              elem.properties["nameLine"] = tmpLine[0].nameLong;
              elem.properties["nameShortLine"] = tmpLine[0].nameShort;
            }
            let tmpRoute = [...routesReducer].filter(
              (item) => item.id === elem.properties.routeRef
            );
            if (tmpRoute && tmpRoute.length > 0 && tmpRoute[0].nameLong) {
              elem.properties["nameRoute"] = tmpRoute[0].nameLong;
            }
            return elem;
          });
        }
        return linesArr;
      }),
      catchError((error) => {
        console.error(error);
        return of(error);
      })
    );
  };

  render() {
    return <div></div>;
  }
}

LoadTplData.contextType = EnvironmentContext;

const mapDispatchToProps = {
  getLines,
  getStops,
  getTrips,
  getRoutes,
  setVehicleEvents,
  setNavTabSelectedTab,
  mapResetLayer,
  setNavTabSearch,
  setTransportVisibility,
  toggleTabLegend,
  addAllLines,
  resetNavTabInfoRoute,
  setBounds,
  filterByMapBounds,
  setIsTplLoading,
  wsOpen,
};

const mapStateToProps = (state) => ({
  linesFilteredReducer: getFilteredLines(state),
  linesReducer: getAllLines(state),
  routesReducer: getAllRoutes(state),
  vehiclesReducer: getAllVehiclesEvents(state),
  menuTabReducer: getNavTabSelectedTab(state),
  menuInfoRouteReducer: getNavTabInfoRoute(state),
  menuNavTabSearch: getNavTabSearch(state),
  networkCurrentReducer: getCurrentNetwork(state),
  cityBusVisible: isCityBusVisible(state),
  regionBusVisible: isRegionBusVisible(state),
  cityBusStopsFiltered: getCityBusStopsFiltered(state),
  regionBusStopsFiltered: getRegionBusStopsFiltered(state),
  cityBusTripsFiltered: getCityBusTripsFiltered(state),
  regionBusTripsFiltered: getRegionBusTripsFiltered(state),
  cityBusTrips: getCityBusTrips(state),
  regionBusTrips: getRegionBusTrips(state),
  cityBusRoutesFiltered: getCityBusRoutesFiltered(state),
  regionBusRoutesFiltered: getRegionBusRoutesFiltered(state),
  activeLines: getAllActiveLines(state),
  currentStopReducer: getCurrentStop(state),
  tripsCurrentReducer: getCurrentTrip(state),
  searchRoutesReducer: getSearchTextRoutes(state),
  isOpenNavTab: isOpenNavTab(state),
  controlsReducer: getControls(state),
  currentIdRouteForStop: getCurrentIdRouteForStop(state),
  currentIdTripForStop: getCurrentIdTripForStop(state),
  currentIdRouteForVehicle: getCurrentIdRouteForVehicle(state),
  currentIdStopForVehicle: getCurrentIdStopForVehicle(state),
  currentIdStopForRoute: getCurrentIdStopForRoute(state),
  currentIdTripForRoute: getCurrentIdTripForRoute(state),
  boundsReducer: getBoundingBox(state),
  isLinesLoading: isLinesLoading(state),
  isTripsLoading: isTripsLoading(state),
  isTripsAllLoading: isTripsAllLoading(state),
  isStopsLoading: isStopsLoading(state),
  //workspace
  currentWorkspace: getCurrentWorkspace(state),
  currentMapZoom: getCurrentZoom(state),
  //ws
  isWSTplOpen: isWSTplOpen(state),
  isWSTplLocationOpen: isWSTplLocationOpen(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadTplData)
);
