import React, { Component } from "react";
import "./style.less";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// COMPONENTS
import PmvPanelBottombar from "components/layout/panels/pmv-panel/pmv-panel-bottombar";

import "./style.less";
import {
  imgStatusInstallations,
  imgStatusInstallationsColor,
} from "utils/utils";

//ACTIONS
import { setSelectedInstallations } from "reducers/installations/installations.actions";

class DockInstallation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panels: [
        {
          title: "Pannello PMV2",
          address: "corso Cavour",
          panelTxt: "PMV 6416 modbus",
          color: "red",
        },
        {
          title: "Pannello PMV4",
          address: "corso Cavour",
          panelTxt: "PMV 6416 modbus",
          color: "yellow",
        },
        {
          title: "Pannello PMV5",
          address: "corso Cavour",
          panelTxt: "PMV 6416 modbus",
          color: "green",
        },
        {
          title: "Pannello PMV7",
          address: "corso Cavour",
          panelTxt: "PMV 6416 modbus",
          color: "grey",
        },
        {
          title: "Pannello PMV2",
          address: "corso Cavour",
          panelTxt: "PMV 6416 modbus",
          color: "red",
        },
      ],
    };
  }

  closeDock = (item) => {
    const { setSelectedInstallations } = this.props;
    setSelectedInstallations(item.category.type.toLowerCase(), item);
  };

  render() {
    //TODO USE SELECTED CAMERA
    const selected = null;

    return (
      <div className="uk-flex uk-flex-row uk-flex-wrap uk-overflow-auto installation-container ">
        {selected &&
          selected.length > 0 &&
          selected.map((inst, ind) => {
            return (
              <div key={ind} className="card-container">
                <div className="uk-card uk-card-secondary">
                  <div className="uk-flex uk-flex-row card-title-container">
                    <div className=" uk-width-4-5 uk-flex uk-flex-row uk-position-relative">
                      <div
                        className={
                          "iconSystemManagementDock " +
                          imgStatusInstallationsColor(inst.deviceStatus)
                        }
                        uk-icon={
                          "icon: " +
                          imgStatusInstallations(
                            inst.deviceStatus,
                            inst.category.type
                          )
                        }
                      />
                      <div className="uk-flex uk-flex-column uk-margin-left uk-margin-left">
                        {inst.name}
                        <div className="card-sub-text">
                          Lat:{inst.location.latitude} - Lon:
                          {inst.location.longitude}
                        </div>
                      </div>
                    </div>
                    <div className=" uk-width-1-5 uk-flex uk-flex-right">
                      <span
                        className="pointer"
                        uk-icon="icon: acr-interface-close"
                        onClick={() => this.closeDock(inst)}
                      ></span>
                    </div>
                  </div>
                  <div className="panel-width-small">
                    <PmvPanelBottombar text={inst.description} />
                  </div>
                  {/* <video className="uk-width-1-2" autoPlay loop>
          <source src={video} type="video/mp4" />
        </video> */}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setSelectedInstallations,
};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DockInstallation)
);
