export const SegmentsActions = {
  SET_LIST_SEGMENTS: "GRAPH/SET_LIST_SEGMENTS",
  SET_CURRENT_SEGMENT: "GRAPH/SET_CURRENT_SEGMENT",
  SET_CURRENT_SEGMENT_MAP: "GRAPH/SET_CURRENT_SEGMENT_MAP",
  FILTER_SEGMENTS_BY_POINT: "GRAPH/FILTER_SEGMENTS_BY_POINT",
  RESET_CURRENT_SEGMENT_MAP: "GRAPH/RESET_CURRENT_SEGMENT_MAP",
};

export const setSegmentsRdsTmcList = (list) => (dispatch) => {
  dispatch({
    type: SegmentsActions.SET_LIST_SEGMENTS,
    payload: { list },
  });
};

export const setCurrentSegmentRdsTmc = (current) => (dispatch) => {
  dispatch({
    type: SegmentsActions.SET_CURRENT_SEGMENT,
    payload: current,
  });
};
export const setCurrentSegmentRdsTmcMap = (currentMap) => (dispatch) => {
  dispatch({
    type: SegmentsActions.SET_CURRENT_SEGMENT_MAP,
    payload: currentMap,
  });
};

export const filterSegmentsByPoint = (point) => (dispatch) => {
  dispatch({
    type: SegmentsActions.FILTER_SEGMENTS_BY_POINT,
    payload: point,
  });
};

export const resetCurrentSegmentMap = () => (dispatch) => {
  dispatch({
    type: SegmentsActions.RESET_CURRENT_SEGMENT_MAP,
  });
};
